import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CalendarPickerView } from "@mui/lab";
import { parseISO } from 'date-fns';
import moment from "moment";
import DateBox from "devextreme-react/date-box";
import { DatePickerType, DateType } from "devextreme/ui/date_box";
import { CalendarZoomLevel } from "devextreme/ui/calendar";
import { ApplyValueMode } from "devextreme/common";
interface IProps {
  label?: string;
  disabled: boolean;
  style?: any;
  value?: any;
  className?: string;
  type?: DateType | undefined;
  name: string;
  id?: string;
  onChange: (event: any) => void;
  views?: CalendarPickerView[];
  title?: string;
  minDate?: Date;
  maxDate?: Date;
  format?: 'dd-MM-yyyy' | 'dd-MM-yyyy HH:mm' | 'MM-yyyy' | 'yyyy' | 'monthAndYear' | 'month' | 'year' | 'MM';
  zoomLevel?: CalendarZoomLevel,
  minZoomLevel?: CalendarZoomLevel,
  maxZoomLevel?: CalendarZoomLevel,
  applyValueMode?: ApplyValueMode ,
  pickerType?: DatePickerType | undefined;
  hint?: string | undefined;
}
export default function DHSDateTimeComponentV2(props: IProps) {
  const format = props.type && props.type === 'date'
    ? "dd/MM/yyyy"
    : props.type && props.type === 'time'
      ? "HH:mm"
      : "dd/MM/yyyy, HH:mm";

  // let value: any = null;
  // if (props.value && props.value.toString() !== "Invalid Date") {
  //   let _v = new Date(props.value + '');
  //   value = new Date(Date.UTC(_v.getFullYear(), _v.getMonth(), _v.getDate(), _v.getUTCHours(), _v.getUTCMinutes(), 0, 0))
  //   value = moment(value).utc(true).toDate()
  // }

  return (
    <DateBox
      // defaultValue={value}
      value={props.value}
      // value={value}
      inputAttr={{ 'aria-label': 'Date Time' }}
      type={props.type ? props.type : 'datetime'}
      name={props.name}
      displayFormat={props.format ? props.format : format}
      onValueChange={(e: any) => {
        props.onChange({
          name: props.name,
          value: e
        });
      }}
      calendarOptions={
        {
          minZoomLevel: props.minZoomLevel,
          zoomLevel: props.zoomLevel,
          maxZoomLevel: props.maxZoomLevel
        }
      }
      max={props?.maxDate ? props.maxDate : undefined}
      min={props?.minDate ? props.minDate : undefined}
      disabled={props.disabled}
      pickerType={props.pickerType ? props.pickerType : 'calendar'}
      placeholder={props.format && props.format === 'yyyy' ? "Chọn năm" : "Chọn ngày"}
      dateOutOfRangeMessage="Thời gian vượt quá giới hạn"
      invalidDateMessage="Ngày không hợp lệ"
      useMaskBehavior={true}
      applyValueMode={props.applyValueMode ? props.applyValueMode : props.type === 'datetime' ? 'useButtons' : undefined}
      hint={props.hint ? props.hint : "Thời gian"}
      />
  );
}
