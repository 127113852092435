import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
    FormControlLabel,
    styled,
    Switch,
    Select,
    MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    HomeClient,
    RoleRightWebModel,
    HomePageClient,
    UserInfoModel,
    UserInfoGroupModel,
    IUserInfoModel,
    QLCCClient,
    QLCCApartmentBuildingModel,
    QLCCApartmentModel,
    QLCCBlockModel,
    QLCCApartmentTypesModel,
    SysActionType,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import '../ApartmentBuilding.css';
import notification from "../../../../common/notification/notification";
import CTXDateTime from "../../../../components/CTX/DateTimePicker";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

interface IAction {
    type: string;
    open: boolean;
    payload: any;
}
const defaultAction: IAction = {
    type: "",
    payload: null,
    open: false,
};


const status_message = ['success', 'error']

var appSession: AppSession;

const ResidentAccountAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();

    // 
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();


    const [data, setData] = React.useState<UserInfoModel>({} as UserInfoModel);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [reload, setReload] = React.useState<boolean>(false);



    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const homePageClient = new HomePageClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);


    // 

    const [groupOptions, setGroupOptions] = React.useState<any>([]);


    // 

    const [action, setAction] = React.useState<IProps>(props);
    const [actVt, setActVt] = React.useState<IAction>(defaultAction);


    const [isChangePassword, setIsChangePassword] = React.useState<boolean>(false);

    const [apartmentBuildings, setApartmentBuildings] = React.useState<QLCCApartmentBuildingModel[]>([] as QLCCApartmentBuildingModel[]);
    const [blocks, setBlocks] = React.useState<QLCCBlockModel[]>([] as QLCCBlockModel[]);
    const [apartments, setApartments] = React.useState<QLCCApartmentModel[]>([] as QLCCApartmentModel[]);

    const [block, setBlock] = React.useState<QLCCBlockModel>({} as QLCCBlockModel);
    const [fullScreen, setFullscreen] = React.useState<boolean>(false);

    const residenAccount = menu?.component?.toLocaleUpperCase() === 'RESIDENTACCOUNT';

    React.useEffect(() => {
        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            setData({
                ...props.itemSelected,
                password: "",
                employeecode: SessionLogin.employeeCode,
                cuser: SessionLogin.userName,
                luser: SessionLogin.userName
            } as UserInfoModel)


            setId(props.itemSelected.username);
        } else {
            setData(new UserInfoModel());
            setId(undefined)
        };
    }, [props.action]);


    React.useEffect(() => {



        setLoading(true);
        homeClient
            ?.roleRightWebGetAll({
                ...new RoleRightWebModel(),
                rolename: menu?.moduleid,
                username: SessionLogin.userName,
                menuid: menu?.menuid,
            } as RoleRightWebModel)
            .then((res) => {
                setRoles(res);
                setLoading(false);
            });
        setLoading(true);

        qLCCClient
            .qlccApartmentBuildingGet({
                ...new QLCCApartmentBuildingModel(),
                action_type: "GET"
            } as QLCCApartmentBuildingModel)
            .then(response => {
                setApartmentBuildings(response);
            })

    }, []);

    React.useEffect(() => {
        if (data.apartment_building_id !== "") {
            qLCCClient
                .qlccBlockGet({
                    ...new QLCCBlockModel(),
                    apartment_building_id: data.apartment_building_id,
                    action_type: "GET"
                } as QLCCBlockModel)
                .then(response => {
                    setBlocks(response);
                })
        }
    }, [data.apartment_building_id])

    React.useEffect(() => {
        if (block.id) {
            qLCCClient
                .qLCCApartmentsGet({
                    ...new QLCCApartmentModel(),
                    block_id: block.id,
                    apartment_building_id: block.apartment_building_id,
                    action_type: "GET"
                } as QLCCApartmentModel)
                .then(response => {
                    setApartments(response)
                })
        } else {
            qLCCClient
                .qLCCApartmentsGet({
                    ...new QLCCApartmentModel(),
                    apartment_building_id: data.apartment_building_id,
                    action_type: "GET"
                } as QLCCApartmentModel)
                .then(response => {
                    setApartments(response)
                })
        }
    }, [block]);

    React.useMemo(() => {
        setLoading(true);
        homePageClient.userInfoGroupGet({
            ...new UserInfoGroupModel(),
            id: 'BCE0CDF9-CD2D-4659-A4BE-2C2F967D50FE',
            action_type: 'GET'
        } as UserInfoGroupModel)
            .then(res => {
                setGroupOptions(res)
                setData(pre => ({ ...pre, group_id: res[0].id } as UserInfoModel))
                setLoading(false);
            });
    }, [reload]);


    const handleSubmit = () => {
        setLoading(true)

        switch (props.action) {
            case ACTIONS.EDIT: {

                homePageClient.userInfoWebUpd({
                    ...new UserInfoModel(),
                    ...data,
                    username: props.itemSelected.username,
                    action_type: isChangePassword ? "RESET-PASSWORD" : "UPDATE"
                } as UserInfoModel)
                    .then((response) => {
                        setLoading(false);
                        notification(status_message[response.status!], response.message);
                        response.status === 0 && props.onEditSuccess(true);
                    });
                break;
            }

            case ACTIONS.ADD: {

                homePageClient.userInfoWebIns({
                    ...new UserInfoModel({ isadmin: false } as IUserInfoModel),
                    ...data,
                } as UserInfoModel)
                    .then((response) => {
                        setLoading(false);
                        notification(status_message[response.status!], response.message)
                        if (response.status === 0) {
                            props.onEditSuccess(true);
                            setId(response.ref_id)
                            setAction({ ...action, itemSelected: { ...props.itemSelected, username: response.ref_id }, action: 'EDITACCOUNT' })
                        }
                    });
                break;
            }


            default:
                break;
        }

    };

    const handleChangeInput = (e: any) => {
        const { name, value, checked, type } = e.target;
        if (type === 'checkbox') {
            setData({ ...data, [name]: checked } as UserInfoModel);

        }
        else setData({ ...data, [name]: value } as UserInfoModel);
    };

    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.APPROVED:
                handleSubmit();
                break;
            case ACTIONS.CLOSE:
                props.onClose();
                break;
        }
    }

    return (
        <Dialog
            // id="sysMenu"
            open={props.open}
            onClose={props.onClose}
            // sx={{
            //     display: "flex",
            //     justifyContent: "flex-end",
            // }}

            TransitionComponent={Transition}
            maxWidth='lg'
            fullWidth
            fullScreen={fullScreen}


        >
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === ACTIONS.EDIT || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1.25 }}>
                        <IconButton
                            edge="end"

                            onClick={() => {
                                setFullscreen(pre => !pre)
                            }}
                            aria-label="full screen"
                        >
                            {fullScreen ? <FullscreenExitIcon sx={{ color: '#1f2937' }} /> : <FullscreenIcon sx={{ color: '#1f2937' }} />}
                        </IconButton>
                        <IconButton
                            edge="end"
                            onClick={() => {
                                props.onClose();
                            }}
                            aria-label="close"
                        >
                            <CloseIcon
                                sx={{ color: '#1f2937' }}
                            />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >


                <div className="row">
                    <div className="col-12 col-sm-4">

                        {/* Họ tên đầy đủ */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Họ tên đầy đủ</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                placeholder="Họ tên đầy đủ..."
                                name="fullname"
                                onChange={handleChangeInput}
                                value={data.fullname}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box>

                        {/* Số điện thoại */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số điện thoại</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                placeholder="Số điện thoại..."
                                type={'tel'}
                                name="phone"
                                autoComplete="off"
                                onChange={handleChangeInput}
                                value={data.phone}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box>

                        {/* Email */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Email</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                placeholder="Email..."
                                type={'email'}
                                name="email"
                                onChange={handleChangeInput}
                                value={data.email}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box>
                        <Divider style={{ margin: 20 }} />
                        {/* Dự án */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Dự án</InputLabel>
                            <Select
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                placeholder="Dự án"
                                value={data?.apartment_building_id}

                                disabled={props.action === ACTIONS.VIEW}
                            >
                                {
                                    apartmentBuildings
                                    && apartmentBuildings.map(item => (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </Box>
                        {/* Tòa nhà*/}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tòa nhà</InputLabel>
                            <Select
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                placeholder="Tòa nhà"
                                value={block?.id!}

                                disabled={props.action === ACTIONS.VIEW}
                            >
                                {
                                    blocks
                                    && blocks.map(item => (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </Box>

                        {/* Căn hộ*/}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Căn hộ</InputLabel>
                            <Select
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                placeholder="Căn hộ"
                                value={data?.apartment_id}

                                disabled={props.action === ACTIONS.VIEW}
                            >
                                {
                                    apartments
                                    && apartments.map(item => (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </Box>



                    </div>

                    <div className="col-12 col-sm-4">
                        {/* Tài khoản (username) */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tài khoản (username)</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                placeholder="Tài khoản (username)..."
                                name="username"
                                onChange={(e: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: e.target.name,
                                            value: e.target.value.toLocaleUpperCase()
                                        }
                                    })
                                }}
                                value={data.username}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box>

                        {props.action !== ACTIONS.ADD
                            && (
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày tạo</InputLabel>
                                    <CTXDateTime
                                        className="cc-input"
                                        style={{
                                            width: "100%",
                                        }}
                                        value={data.cdate}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />
                                </Box>

                            )
                        }
                        {props.action !== ACTIONS.ADD
                            && (
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày cập nhật</InputLabel>
                                    <CTXDateTime
                                        className="cc-input"
                                        style={{
                                            width: "100%",
                                        }}
                                        value={data.ldate}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />
                                </Box>

                            )
                        }

                        {/* Cấp lại mật khẩu */}
                        {
                            residenAccount
                            &&
                            (<Box style={{ width: "100%", margin: "5px 0px" }}>
                                {
                                    isChangePassword
                                    && (
                                        <>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mật khẩu mới (new password)</InputLabel>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                placeholder="Nhập mật khẩu"
                                                name="password"
                                                onChange={(e: any) => {
                                                    handleChangeInput({
                                                        target: {
                                                            name: e.target.name,
                                                            value: e.target.value.toLocaleUpperCase()
                                                        }
                                                    })
                                                }}
                                                value={data.password}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />
                                        </>
                                    )
                                }
                                {
                                    props.action !== ACTIONS.VIEW
                                    && (
                                        <Button
                                            className="w-100 mt-4"
                                            onClick={() =>
                                                setIsChangePassword(!isChangePassword)}
                                            variant="contained"
                                        >
                                            {isChangePassword ? "Thu nhỏ" : "Cấp mật khẩu mới"}
                                        </Button>
                                    )
                                }
                            </Box>)
                        }


                    </div>

                    <div className="col-12 col-sm-4">
                        {/* Admin */}
                        <Box className="row">



                            <Box className="col-6">


                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Vô hiệu hóa</InputLabel>

                                <FormControlLabel control={
                                    <Switch
                                        color="primary"
                                        onChange={handleChangeInput}
                                        name="disabled"
                                        value={data.disabled}
                                        checked={data.disabled}
                                        disabled={props.action === ACTIONS.VIEW} />}
                                    label={
                                        <Typography
                                            sx={{ fontSize: 14, color: '#333' }}
                                        >
                                            Không/Có
                                        </Typography>} />

                                {/* Khoá */}
                                <Box className="row">
                                    <Box className="col-6">


                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Khóa</InputLabel>

                                        <FormControlLabel control={
                                            <Switch
                                                color="primary"
                                                onChange={handleChangeInput}
                                                name="isblock"
                                                value={data.isblock}
                                                checked={data.isblock}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />}
                                            label={
                                                <Typography
                                                    sx={{ fontSize: 14, color: '#333' }}>
                                                    Không/Có
                                                </Typography>} />


                                    </Box>




                                </Box>
                            </Box>

                        </Box>



                    </div>
                </div>





            </DialogContent>
            <DialogActions>
                {/* {props.action !== ACTIONS.VIEW && (
                    <Button variant="contained" onClick={handleSubmit}>
                        Lưu
                    </Button>
                )}
                <Button onClick={props.onClose}>Hủy</Button> */}
                <DHSDialogAction
                    action={props.action}
                    menu={menu}
                    onClickAction={handleClickAction}


                />
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog >
    );
};

export default ResidentAccountAction;
