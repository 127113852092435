import React from "react";
import {
    Slide,
    Typography,
} from "@mui/material";

import {
    BaseformClient,
    HomeClient,
    ParaObjRolePermission,
    ProcessRolePermissionModel,
    QLCCClient,
    RolePermission,
    RolePermissionDel,
    RoleRightWebModel,
    SiRoleInfoClient,
    SysActionType,
    SysModel,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import '../../actions/ApartmentBuilding.css';
import notification from "../../../../common/notification/notification";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Checkbox } from "antd";
import { ColumnsType } from "antd/lib/table";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import _ from "lodash";
import DHSBreadcrumb from "../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import DataGrid from "devextreme-react/data-grid";
import dxDataGrid, { SelectionChangedInfo } from "devextreme/ui/data_grid";
import { EventInfo } from "devextreme/events";
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";


interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    title?: string,
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (data: SysModel) => void;
}

interface RoleMenu {
    id?: string,
    action?: string,
    is_allow?: boolean
}


interface IParams {
    _id?: string
}

const status_message = ['success', 'error']

var appSession: AppSession;

const RoleMenuPermission: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    // const [_id, setId] = React.useState<string>();
    // const history = useHistory();

    const { _id } = useParams<IParams>();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    // 

    const [data, setData] = React.useState<RolePermission[]>([]);
    const [rolePermissionData, setRolePermissionData] = React.useState<RolePermission[]>([]);
    const [updateData, setUpdateData] = React.useState<ProcessRolePermissionModel>(); // any là RoleMenu
    const [updateDataPre, setUpdateDataPre] = React.useState<ProcessRolePermissionModel>(); // any là RoleMenu
    const [isLoading, setLoading] = React.useState<boolean>(false);


    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const baseFormClient = new BaseformClient(appSession, BASE_URL_API);
    const siRoleInfo = new SiRoleInfoClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    // 


    const [checkAll, setCheckAll] = React.useState<boolean>(false);
    const [checkMaxAll, setCheckMaxAll] = React.useState<boolean>(false);


    // 

    const dataGridRef = React.useRef<DataGrid>(null);
    const dataGridActionRef = React.useRef<DataGrid>(null);

    const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>();
    const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>(-1);
    const columns: ColumnsType<RolePermission> = [

        // {
        //     key: 'action_code',
        //     title: 'Hành động',
        //     dataIndex: 'action_code',
        // },
        {
            key: 'action_name',
            title: 'Tên hành động',
            dataIndex: 'action_name',
        },
        {
            key: 'grant_access',
            title: 'Cho phép',
            dataIndex: 'grant_access',
            render(value, record, index) {
                return <Checkbox
                    id={record.bar}
                    style={{ padding: 0 }}
                    checked={value}
                    value={value}
                    name="grant_access"
                    onChange={(e) => handleCheckboxChange(e, e.target.checked, record, index)} />
            },
        },

    ];

    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>();



    // #region init



    React.useEffect(() => {
        
        setLoading(true);

        baseFormClient.getRolePermission({
            ...new ParaObjRolePermission(),
            rolename: _id
        } as ParaObjRolePermission)
            .then(res => {
               
                let countMaxCheckAll = 0;
               

                setCheckMaxAll(res.length === countMaxCheckAll);

                // 

                const data = res.filter((item, index) => {
                    return (
                        index ===
                        res!.findIndex((obj) => {
                            return obj.menuid === item.menuid;
                        })
                    )
                })
                setData(data);
                setUpdateData({ ...updateData, lstModelIns: [...res] } as ProcessRolePermissionModel);
                setUpdateDataPre({ ...updateDataPre, lstModelIns: JSON.parse(JSON.stringify(res)) } as ProcessRolePermissionModel);


            });

        //paging this array res

    }, []);


    // #endregion


    // #region listAction

    const handleListItemClick = (
        rolePermission: any,
        index?: number,
    ) => {
        let countRoleGrandAccess = 0;

        const tempData: RolePermission[] = [...updateData?.lstModelIns!].filter((item) => {
            if (item.grant_access === true && rolePermission.menuid === item.menuid) {
                ++countRoleGrandAccess;
            }
            return rolePermission.menuid === item.menuid;
        });
        if (countRoleGrandAccess === tempData.length) {
            setCheckAll(true)
        }
        else {
            setCheckAll(false)
        }
        setRolePermissionData(tempData)

    };

    React.useEffect(() => {

    }, [updateData?.lstModelIns])
    // #endregion

    // #region table
  

    const handleCheckboxChange = (e: any, checked: boolean, record: RolePermission, index: number) => {
    
        if (updateData?.lstModelIns) {
            const dataTemp = [...updateData?.lstModelIns];
            dataTemp.forEach((rp, index) => {
                if (rp.bar === record.bar) {
                    if (rp.action_code === record.action_code) {
                        dataTemp[index].grant_access = checked;
                    }
                }
            });

            // setIndeterminate(updateData.lstModelIns.length > lengthGrandAccess)
            setUpdateData(pre => ({
                ...pre,
                lstModelIns: dataTemp
            } as ProcessRolePermissionModel))

        }

        // setRoleMenu(dataTemp);
    }
    // #endregion


    const handleSubmit = () => {
        setLoading(true);
        var countDiff = 0;
        const tempUpdateDataPre = [...updateDataPre?.lstModelIns!]
        const grantAccessFilter = [...updateData?.lstModelIns!].filter((x, index) => {
            x.rolename = _id;
            x.cuser = SessionLogin.userName;
            x.luser = SessionLogin.userName;
            if (x.grant_access !== tempUpdateDataPre[index].grant_access) {
                countDiff++;
            }
            return x.grant_access === true;
        });
        const delFilter = {
            rolename: _id,
            menuid: ""
        } as RolePermissionDel
        // grantAccessFilter.filter((item, index) => {
        //     return (
        //       index ===
        //       grantAccessFilter!.findIndex((obj) => {
        //         return obj.menuid === item.menuid;
        //       })
        //     )}) ;

        // if (countDiff > 0) {
            siRoleInfo.updateRolePermission({
                ...updateData,
                modeldel: delFilter,
                lstModelIns: [
                    ...grantAccessFilter
                ] as RolePermission[]
            } as ProcessRolePermissionModel)
                .then(res => {
                    setLoading(false);
                    notification(status_message[res ? 0 : 1], STATUS_MESSAGE[`SAVE_${res ? 0 : 1}`])
                })
                .catch(error => {
                    setLoading(false);
                    notification(TYPE_MESSAGE.ERRORR, "Có lỗi xảy ra" + error.status_code)
                })
        // }
    }

    const onChangeCheckAll = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            const tempRolePermission = [...rolePermissionData];
            tempRolePermission.map(role => {
                role.grant_access = true;
            });

            setRolePermissionData(tempRolePermission);
            setCheckAll(true);
            // setIndeterminate(false)
        }
        else {
            const tempRolePermission = [...rolePermissionData];
            tempRolePermission.map(role => {
                role.grant_access = false;
            });

            setRolePermissionData(tempRolePermission);
            setCheckAll(false);
            // setIndeterminate(false)

        }
    }

    const onChangeCheckMaxAll = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            const tempRolePermission = updateData?.lstModelIns && [...updateData?.lstModelIns];
            if (tempRolePermission) {
                tempRolePermission.map(role => {
                    role.grant_access = true;
                });
                // Khi checkAllMax thì rolepermission đang chọn cũng check theo luôn
                const tempRolePermissionData = [...rolePermissionData]

                tempRolePermission.map(role => {
                    role.grant_access = true
                });

                setRolePermissionData(tempRolePermissionData);
                setCheckAll(true);
                setCheckMaxAll(true);
            }

            // setIndeterminate(false)
        }
        else {
            const tempRolePermission = updateData?.lstModelIns && [...updateData?.lstModelIns];
            if (tempRolePermission) {
                tempRolePermission.map(role => {
                    role.grant_access = false;
                });

                // Khi unCheckAllMax thì rolepermission đang chọn cũng uncheck theo luôn

                const tempRolePermissionData = [...rolePermissionData]

                tempRolePermission.map(role => {
                    role.grant_access = false;
                });

                setRolePermissionData(tempRolePermissionData);
                // 
                setCheckAll(false);
                setCheckMaxAll(false);

            }

            // setIndeterminate(false)

        }
    }

    const handleClickAction = (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code?.toUpperCase()) {

            case ACTIONS.SAVE:
                handleSubmit();
                break;
        }
    }

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {

                const index = e.component.getRowIndexByKey(keys[0]);
                handleListItemClick(keys[0], index);
                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
            });
        }
    };

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };

    const handleRowUpdating = (e: any) => {
        console.log(e, "eeee")
    }

    return (
        <div className='container-fluid'>


            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location}
                    is_paramater
                    textExpand={`(${urlParams.get("fullname")})` ?? undefined}
                />
            </div>

            <div className="row mb-4">
                <div className="col-12">
                    {/* <Button startIcon={<SaveIcon />} variant="contained" onClick={handleSubmit}>Lưu</Button> */}
                    <DHSToolbarRole
                        id={"true"}
                        menu={menu}
                        customMenu={[]}
                        onClickAction={handleClickAction}
                        isDLink
                        isDialogAction
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="d-flex align-items-center pb-2" style={{ gap: 10, justifyContent: 'space-between' }}>

                        <Typography variant="subtitle1" sx={{ fontSize: 16, fontWeight: 600, marginBottom: '0.325rem', color: '#333' }}>
                            Danh sách chức năng
                        </Typography>

                        <Checkbox checked={checkMaxAll} onChange={onChangeCheckMaxAll}>
                            <span style={{ fontWeight: 600 }}>Phân quyền tất cả</span>
                        </Checkbox>

                    </div>
                    
                    <DHSDataGridEdit
                        data={data}
                        table_name={"RoleMenuPermission"}
                        // key="so_ct"
                        mode="popup"
                        dataGridRef={dataGridRef}
                        allowEdit={false}
                        pageSize={menu?.page_size}
                        isPagination={menu?.is_pagination}
                        isMultiple={menu?.is_multiple}
                        groupingAutoExpandAll={true}

                        handleSelectionChanged={handleSelectionChanged}
                        handlePageChange={handlePageChange}

                    // handleExporting={handleExporting}
                    />

                </div>

                <div className="col-md-5 col-12 mt-3 mt-md-0">
                    <div>

                        <div className="d-flex align-items-center pb-2" style={{ gap: 10, justifyContent: 'space-between' }}>
                            <Typography variant="subtitle1" sx={{ fontSize: 16, fontWeight: 600, marginBottom: '0.325rem', color: '#333' }}>
                                Cấp quyền
                            </Typography>

                            <Checkbox checked={checkAll} onChange={onChangeCheckAll}>
                                <span style={{ fontWeight: 600 }}>Phân quyền tất cả</span>
                            </Checkbox>
                        </div>


                        <DHSDataGridEdit
                            data={rolePermissionData}
                            table_name={"ActionRolePermission"}
                            // key="so_ct"
                            mode="cell"
                            dataGridRef={dataGridActionRef}
                            allowEdit={true}
                            isPagination={false}
                            // handleSelectionChanged={handleSelectionActionChanged}
                            // handlePageChange={handlePageActionChange}
                            handleRowUpdating={handleRowUpdating}

                        // handleExporting={handleExporting}
                        />
                       
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoleMenuPermission;


