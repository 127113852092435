export const enum ACTIONS {
  EMPTY = "",
  VIEW = "VIEW",
  ADD = "ADD",
  ADD_NEW = "ADD_NEW",
  SAVE_NEW = "SAVE_NEW",
  EDIT = "EDIT",
  EDIT_2 = "EDIT_2",
  DELETE = "DELETE",
  CLOSE = "CLOSE",
  PRINT = "PRINT",
  ATTACHFILE = "ATTACHFILE",
  SEARCH = "SEARCH",
  APPROVED = "APPROVED",
  CANCELAPPROVAL = "CANCELAPPROVAL",
  REJECT = "REJECT",
  REVISION = "REVISION",
  PENDING = "PENDING",
  SENDMAIL = "SENDMAIL",
  CONFIRM = "CONFIRM",
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  GEN_PASSWORD = 'GEN_PASSWORD',
  POST = "POST",
  UNPOST = "UNPOST",
  EXPORTEXCEL = "EXPORTEXCEL",
  EDITEMPREPORT = "EDITEMPREPORT",
  HISTOTY = "HISTORY",
  COPY = "COPY",
  VALIDATE = "VALIDATE",
  UNLOCK = "UNLOCK",
  REVERSE = "REVERSE",
  IMPORT = "IMPORT",
  EXPORT = "EXPORT",
  HELP = "HLEP",
  AUDITTRAIL = "AUDITTRAIL",
  OPENSETTLE = "OPENSETTLE",
  CLOSESETTLE = "CLOSESETTLE",
  BALANCE = "BALANCE",
  TRANSACTION = "TRANSACTION",
  UNDO = "UNDO",
  ASSIGNTO = "ASSIGNTO",
  FILTER = "FILTER",
  SAVE = "SAVE",
  PERMISSION = "PERMISSION",
  LOCK = "LOCK",
  OK = "OK",
  IMPORTEXCEL = "IMPORTEXCEL",
  CREATE = "CREATE",
  INSERT = "INSERT",
  SENTNOTIFY = "SENTNOTIFY",
  SENTTASK = "SENTTASK",
  SENTTASK_2 = "SENTTASK_2",
  UPDATETASK = "UPDATETASK",
  ADDQUICK = "ADDQUICK",
  PRINTWITHACCOUNT = "PRINTWITHACCOUNT",
  PRINTBILLLAN2 = "PRINTBILLLAN2",
  HOADONDT = "HOADONDT",
}

export const ACTIONS_NAME: { [key: string]: string } = {
  EMPTY: "Trống",
  VIEW: "Xem chi tiết",
  ADD: "Thêm mới",
  EDIT: "Cập nhật",
  DELETE: "Xóa",
  CLOSE: "CLOSE",
  PRINT: "PRINT",
  ATTACHFILE: "Đính kèm file(s)",
  SEARCH: "SEARCH",
  APPROVED: "APPROVED",
  CANCELAPPROVAL: "CANCELAPPROVAL",
  REJECT: "REJECT",
  REVISION: "REVISION",
  PENDING: "PENDING",
  SENDMAIL: "SENDMAIL",
  CONFIRM: "CONFIRM",
  POST: "POST",
  UNPOST: "UNPOST",
  EXPORTEXCEL: "EXPORTEXCEL",
  EDITEMPREPORT: "EDITEMPREPORT",
  HISTOTY: "HISTORY",
  COPY: "Sao chép",
  VALIDATE: "VALIDATE",
  UNLOCK: "UNLOCK",
  REVERSE: "REVERSE",
  IMPORT: "IMPORT",
  EXPORT: "EXPORT",
  HELP: "HLEP",
  AUDITTRAIL: "AUDITTRAIL",
  OPENSETTLE: "OPENSETTLE",
  CLOSESETTLE: "CLOSESETTLE",
  BALANCE: "BALANCE",
  TRANSACTION: "TRANSACTION",
  UNDO: "UNDO",
  ASSIGNTO: "ASSIGNTO",
  FILTER: "FILTER",
  SAVE: "SAVE",
  PERMISSION: "PERMISSION",
  LOCK: "LOCK",
  OK: "OK",
  IMPORTEXCEL: "IMPORTEXCEL",
  CREATE: "CREATE",
  INSERT: "INSERT",
  SENTNOTIFY: "SENTNOTIFY",
  SENTTASK: "SENTTASK",
  SENTTASK_2: "SENTTASK_2",
  CHOOSE: "Chọn",
  VIEW_DRAWING: "Xem bản vẽ",
};

interface IERROR_PERMISSION_MESSAGE {
  [key: string]: string;
}

export const ERROR_PERMISSION_MESSAGE: IERROR_PERMISSION_MESSAGE = {
  VIEW: "Bạn không có quyền xem",
  ADD: "Bạn không có quyền thêm",
  EDIT: "Bạn không có quyền sửa",
  DELETE: "Bạn không có quyền xóa",
  PRINT: "Bạn không có quyền in",
  ATTACHFILE: "Bạn không có quyền Đính kèm file",
  SEARCH: "Bạn không có quyền Tìm kiếm",
  APPROVED: "Bạn không có quyền Duyệt",
  REJECT: "Bạn không có quyền Từ chối",
  REVISION: "Bạn không có quyền Sửa đổi",
  PENDING: "Bạn không có quyền Chờ xử lý",
  SENDMAIL: "Bạn không có quyền Gửi mail",
  CONFIRM: "Bạn không có quyền Xác nhận",
  POST: "Bạn không có quyền Ghi sổ",
  UNPOST: "Bạn không có quyền Bỏ ghi sổ",
  EXPORTEXCEL: "Bạn không có quyền Xuất Excel",
  EDITEMPREPORT: "Bạn không có quyền Sửa mẫu",
  HISTOTY: "Bạn không có quyền Lịch sử",
  COPY: "Bạn không có quyền Sao chép",
  VALIDATE: "Bạn không có quyền Xác thực",
  UNLOCK: "Bạn không có quyền Mở khóa",
  REVERSE: "Bạn không có quyền Đảo ngược",
  IMPORT: "Bạn không có quyền Nhập khẩu",
  EXPORT: "Bạn không có quyền Xuất khẩu",
  HELP: "Bạn không có quyền Giúp đỡ",
  AUDITTRAIL: "Bạn không có quyền Kiểm tra",
  OPENSETTLE: "Bạn không có quyền Phân bổ",
  CLOSESETTLE: "Bạn không có quyền Hủy phân bổ",
  BALANCE: "Bạn không có quyền Số dư",
  TRANSACTION: "Bạn không có quyền Giao dịch",
  UNDO: "Bạn không có quyền Hoàn tác",
  ASSIGNTO: "Bạn không có quyền Giao quyền",
  FILTER: "Bạn không có quyền Lọc",
  SAVE: "Bạn không có quyền Lưu",
  PERMISSION: "Bạn không có quyền Phân quyền",
  LOCK: "Bạn không có quyền Khóa",
  CREATE: "Bạn không có quyền tạo",
  UPDATETASK: "Bạn không có quyền Cập nhật tiến độ",
  NOT_PERMISSION: "Bạn không có quyền thao tác chức năng này",
};
