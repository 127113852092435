import React, { useEffect } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Box,
    Paper,
    InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    HomeClient,
    QLCCClient,
    QLCCTaskModel,
    QLCCTaskDetailModel,
    ReportInfo,
    SysActionType,
    DepartmentClient,
    LstDepartment,
    SysModel,
    UserInfo,
    CommonClient,
    ParaObjTableList,
    QLCCTaskStatusModel,
    QLCCTaskGroupModel,
    QLCCTaskTypeModel,
    HomePageClient,
    UserInfoModel,
    UserInfoFilterModel,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import { Notification } from "../../../../components/layouts";
import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { EventInfo } from "devextreme/events";
import DataGrid from 'devextreme-react/data-grid';
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { MESSAGE_TYPE, STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import { getIndexRowSelected, getKeyRowSelected } from "../../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import notification from "../../../../common/notification/notification";
import DHSSelectBox from "../../../../components/DHS/DHS_SelectBox";
import DHSTextBox from "../../../../components/DHS/DHS_TextBox";
import TaskChild, { NEWID } from "./task-child/task-child";
import { TransDateTime } from "../../../../components/utils/date.util";
import moment from "moment";
import DHSDateTimeComponentV2 from "../../../../components/DHS/DHS-DateTime/DHSDateTimeComponentV2";

import './index.css'
import Slider, { Tooltip } from "devextreme-react/slider";
import CheckBox from "devextreme-react/check-box";
import { httpPost } from "../../../../common/httpService";
import DHSTagBox from "../../../../components/DHS/DHS_TagBox";

import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import DHSToolbarRoleActionType2 from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRoleChild";
import TaskForum from "./conponent-task/task-forum";
import { TaskFileAttach } from "./conponent-task/task-file-attach";
import TaskFollower from "./conponent-task/task-follower";
import { Tooltip as TooltipMUI } from '@mui/material';
import TaskChildForum from "./conponent-task/task-child-forum";
import { HintCode } from "../../../../common/enums/DHSInputDetail";
import TaskFollowerInput from "./conponent-task/task-follower/index2";
import { LABEL_TASK } from "./task-check-list-action";

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    data?: any;
    onClose: () => void;
    onEditSuccess: (is_success: boolean) => void;
    titleFeedBack?: string | undefined;
    contentFeedBack?: string | undefined;
    feedback_id?: string | undefined;
    apartmentBuildingId?: string | undefined;
    status?: number | undefined;
}



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface IActions {
    open: boolean;
    id: string;
    actions: string;
    data: QLCCTaskDetailModel,
    start_time: Date;
}

const defaultAction = {
    open: false,
    id: "",
    actions: "",
    data: {} as QLCCTaskDetailModel,
    start_time: new Date(),
};


// hàm get date min or max in lst task detail
const GET_DATE = (data: QLCCTaskDetailModel[], EQUAL: number) => {
    /**
     * EQUAL = 0 EQUAL 
     * EQUAL < LESS THAN
     * EQUAL > 0 MORE THAN
     */

    if (data.length === 1) {
        const item = data[0];
        if (EQUAL > 0) {
            return new Date(item.end_time + '');
        } else if (EQUAL < 0) {
            return new Date(item.start_time + '');
        }
    }
    else if (data.length > 1) {
        if (EQUAL > 0) {
            const d: any = data.flatMap(item => item.end_time)
            const maxDate = d.reduce((a: Date, b: Date) => a > b ? a : b)
            return new Date(maxDate.toString());
        } else if (EQUAL < 0) {
            const d: any = data.flatMap(item => item.start_time)
            const minDate = d.reduce((a: Date, b: Date) => a < b ? a : b)
            return new Date(minDate.toString());
        }
    }
    else if (data.length === 0) {
        return moment().startOf('day').toDate();
    }
    if (EQUAL === 0) {
        return new Date();
    } else if (EQUAL < 0) {
        return moment().startOf('day').toDate();
    }
    else if (EQUAL > 0) {
        return moment().endOf('day').toDate();
    }
    return moment().toDate();
}

var appSession: AppSession;
var htmlString: string;
const menuComponentFeedback: string = 'QLCCFeedbacks'
const TYPE_CODE_TASK_SCRIPT: string = "2";
const TYPE_CODE_TASK: string = "1";
const TASK_STATUS_DONE: number = 3;
const TASK_STATUS_CHUA_NHAN_VIEC: number = 5;
const TASK_STATUS_NHAN_VIEC: number = 4;
const TASK_STATUS_DANG_LAM: number = 2;



const TaskListAction = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    const departmentClient = new DepartmentClient(appSession, BASE_URL_API);
    const qLCCHome = new HomeClient(appSession, BASE_URL_API);
    const commonClient = new CommonClient(appSession, BASE_URL_API);
    const qlccHomePage = new HomePageClient(appSession, BASE_URL_API);

    const [data, setData] = React.useState<QLCCTaskModel>({} as QLCCTaskModel);
    const [dataDetails, setDataDetails] = React.useState<QLCCTaskDetailModel[]>([] as QLCCTaskDetailModel[]);
    const [dataDetail, setDataDetail] = React.useState<QLCCTaskDetailModel[]>([] as QLCCTaskDetailModel[]);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [departments, setDepartments] = React.useState<LstDepartment[]>([]);
    const [config, setConfig] = React.useState<any>();
    const [users, setUsers] = React.useState<UserInfo[]>([]);
    const [actionPopup, setActionPopup] = React.useState<IActions>({ ...defaultAction });
    const [isFull, setIsFull] = React.useState<boolean>(true);
    const [statusList, setStatusList] = React.useState<QLCCTaskStatusModel[]>([]);
    const [Groups, setGroups] = React.useState<QLCCTaskGroupModel[]>([]);
    const [Types, setTypes] = React.useState<QLCCTaskTypeModel[]>([]);
    const [taskScripts, setTaskScripts] = React.useState<QLCCTaskModel[]>([]);
    const [taskScriptId, setTaskScriptId] = React.useState<string[]>([]);
    const [follower, setFollower] = React.useState<UserInfoModel[]>([]);


    const [reload, setReload] = React.useState<boolean>(false);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const disable = props.action === ACTIONS.VIEW ? true : false;

    const [loadDataContact, setLoadDataContact] = React.useState<boolean>(false); // load tin nhắn
    const [loadDataDetail, setLoadDataDetail] = React.useState<boolean>(false); // load lại deltail khi thay đổi, vì bảng kh tự reload lại được : ((( Khổ quá mà
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)


    const onloadUser = () => {
        // qLCCHome.userInfoGet({
        //     ... new UserInfo(),
        //     department_id: data.department_id,
        //     ma_cty: SessionLogin.ma_cty,
        //     action_type: "LOAD WHEN",
        //     apartment_building_id: apartment_building_id
        // } as UserInfo)
        //     .then(res => {
        //         setUsers(res);
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     })

        qlccHomePage.userInfoWebFilterAdmin({
            ... new UserInfoFilterModel(),
            ma_cty: SessionLogin.ma_cty,
            apartment_building_id: apartment_building_id,
            department_id: data.department_id,
        } as UserInfoFilterModel)
            .then(res => {
                setUsers(res);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const [path, setPath] = React.useState<string[]>([]);

    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const body = {
                    ... new ParaObjTableList(),
                    ma_cty: SessionLogin.ma_cty,
                    tablelist: 'dm_bp'
                } as ParaObjTableList

                httpPost<string>('/api/Common/LoadDatasourceBindControlByApartmentBuilding', {
                    ma_cty: SessionLogin?.ma_cty,
                    tablelist: body?.tablelist,
                    apartment_building_id: apartment_building_id
                })
                    .then(res => {
                        console.log(JSON.parse(res.data))
                        const serviceGroups: any = JSON.parse(res.data)
                        if (serviceGroups) {
                            setDepartments([...serviceGroups?.Table1]);
                        }
                    })
                    .catch((ex) => {
                        console.log(ex)
                    })


                // httpPost<string>('api/Common/LoadFilterList', body)
                //     .then(result => {
                //         const deapartmentData: LstDepartment[] = JSON.parse(result.data);
                //         setDepartments([...deapartmentData]);
                //     });

                const ResType = await qLCClient.qLCCTaskTypeGet({
                    ...new QLCCTaskTypeModel(),
                    ma_cty: SessionLogin.ma_cty,
                    apartment_building_id: apartment_building_id
                } as QLCCTaskTypeModel);

                const ResGroup = await qLCClient.qLCCTaskGroupGet({
                    ...new QLCCTaskGroupModel(),
                    ma_cty: SessionLogin.ma_cty,
                    apartment_building_id: apartment_building_id
                } as QLCCTaskGroupModel);

                const ResScript = await qLCClient.qLCCTaskGet({
                    ...new QLCCTaskModel(),
                    type_code: TYPE_CODE_TASK_SCRIPT,
                    ma_cty: SessionLogin.ma_cty,
                    username_login: SessionLogin.userName,
                    apartment_building_id: apartment_building_id
                } as QLCCTaskModel);

                const ResStatus = await qLCClient.qLCCTaskStatusGet({
                    ...new QLCCTaskStatusModel(),
                    ma_cty: SessionLogin.ma_cty,
                    apartment_building_id: apartment_building_id
                } as QLCCTaskStatusModel);

                setTypes(ResType);
                setGroups(ResGroup);
                setTaskScripts(ResScript);
                setStatusList(ResStatus);

            } catch (error) {
                setDepartments([]);
            } finally {
                setLoading(false);
            }
        })();
    }, [])

    const onloadStatus = async () => {
        const ResStatus = await qLCClient.qLCCTaskStatusGet({
            ...new QLCCTaskStatusModel(),
            ma_cty: SessionLogin.ma_cty,
            apartment_building_id: apartment_building_id
        } as QLCCTaskStatusModel);
        return ResStatus;
    }

    React.useEffect(() => {
        if (props.open) {
            (async () => {
                // Thông tin người theo dỗi của một dự án
                const ResFollowInApartmentBuilding = await qlccHomePage.userInfoWebFilterAdmin({
                    ... new UserInfoFilterModel(),
                    ma_cty: SessionLogin.ma_cty,
                    // username: SessionLogin.userName,
                    apartment_building_id: apartment_building_id,
                    // action_type: "GET_USER_MANAGER"
                } as UserInfoFilterModel)

                if (ResFollowInApartmentBuilding) {
                    setFollower([...ResFollowInApartmentBuilding]);
                }
            })();


            if (props.action === ACTIONS.ADD) {
                (async () => {

                    const ResMyInfo = await qlccHomePage.userInfoWebGet({
                        ... new UserInfo(),
                        ma_cty: SessionLogin.ma_cty,
                        user_login: SessionLogin.userName,
                        username: SessionLogin.userName,
                        action_type: "GET-USER",
                        apartment_building_id: apartment_building_id,
                    } as UserInfo)

                    const myInfo = ResMyInfo[0];
                    console.log(ResMyInfo)

                    try {
                        setUsers([...ResMyInfo])

                        const statusListTemp = async () => {
                            if (!statusList || statusList.length === 0) {
                                return await onloadStatus()
                            }
                            return statusList
                        }

                        const getStatus = await statusListTemp();

                        const statusItem = props.status
                            ? getStatus.find(itemStatus => itemStatus.stt === props.status) : getStatus[0];

                        setData({
                            ... new QLCCTaskModel(),
                            ma_cty: SessionLogin.ma_cty,
                            cuser: SessionLogin.userName,
                            cdate: new Date(),
                            ldate: new Date(),
                            start_time: new Date(),
                            end_time: new Date(new Date().getTime() + 60 * 1000),
                            luser: SessionLogin.userName,
                            sender: SessionLogin.userName,
                            percent: 0,
                            is_done: false,
                            type_code: TYPE_CODE_TASK,
                            title: props.titleFeedBack ? props.titleFeedBack : "",
                            name: props.titleFeedBack ? props.titleFeedBack : "",
                            feedback_id: props.feedback_id ? props.feedback_id : "",
                            description: props.contentFeedBack ? props.contentFeedBack : "",
                            status_id: statusItem && statusItem.id ? statusItem.id : "",
                            status_code: statusItem && statusItem.stt ? statusItem.stt : "",
                            status_name: statusItem && statusItem.name ? statusItem.name : "",
                            department_id: myInfo && myInfo.department_id ? myInfo.department_id : "",
                            department_name: myInfo && myInfo.department_name ? myInfo.department_name : "",
                            receiver: myInfo && myInfo.username ? myInfo.username : '',
                            apartment_building_id: apartment_building_id,
                            follower: SessionLogin.userName,
                            date_done: moment().toDate(),
                        } as QLCCTaskModel);

                        setId(undefined);
                        setDataDetails([] as QLCCTaskDetailModel[]);

                        htmlString = props.contentFeedBack ? props.contentFeedBack : ""
                        setPath([]); // tập tin đính kèm

                    } catch (e) {
                        console.error(e);
                        alert("Vui lòng tải lại trang")
                    }
                    setLoadDataContact(pre => !pre);

                })();
            } else {
                setId(props.itemSelected);
                qLCClient
                    .qLCCTaskGet({
                        ... new QLCCTaskModel(),
                        ...props.data,
                        ma_cty: SessionLogin.ma_cty,
                        apartment_building_id: apartment_building_id,
                        cuser: SessionLogin.userName,
                        luser: SessionLogin.userName,
                        username_login: SessionLogin.userName,
                        follower: SessionLogin.userName, // mới
                        start_time: undefined,
                        end_time: undefined,
                        // action_type: "FILTER_DM"
                    } as QLCCTaskModel)
                    .then((res) => {
                        if (res[0]) {

                            const lstDanhSachTheoDoi = props.action === ACTIONS.SENTTASK ? getLisstUserFolow(res[0]) : res[0].follower!

                            setData({
                                ...res[0],
                                ldate: new Date(),
                                luser: SessionLogin.userName,
                                type_code: TYPE_CODE_TASK,
                                follower: lstDanhSachTheoDoi,
                                receiver: props.action === ACTIONS.SENTTASK ? "" : res[0].receiver!,
                                sender: props.action === ACTIONS.SENTTASK ? SessionLogin.userName! : res[0].sender!,
                            } as QLCCTaskModel)

                            if (res[0].qlccTaskDetails) {
                                setDataDetails(res[0].qlccTaskDetails);

                            } else {
                                setDataDetails([] as QLCCTaskDetailModel[])
                            }

                            const file = res[0].attach_files;
                            if (file) {
                                setPath(file.split(';'))
                            } else {
                                setPath([]); // tập tin đính kèm
                            }

                            const resContent = res[0].description;
                            if (resContent) {
                                htmlString = resContent;
                            }
                            setLoadDataContact(pre => !pre);
                        }
                    })
            }

        }
    }, [props.itemSelected, props.action, props.open]);


    const getLisstUserFolow = (res: QLCCTaskModel) => {
        const sender = res.sender;
        const receiver = res.receiver;
        const follower = res.follower;
        if (follower && sender && receiver) {
            const lstFl = follower.split(';');
            const issetCuser = lstFl.includes(sender);
            const issetReceiver = lstFl.includes(receiver);
            const issetUserDoing = lstFl.includes(SessionLogin.userName);
            if (!issetCuser) {
                lstFl.push(sender);
            }
            if (!issetReceiver) {
                lstFl.push(receiver);
            }
            if (!issetUserDoing) {
                lstFl.push(SessionLogin.userName)
            }

            // return lstFl.join(';');

            return Array.from(new Set(lstFl)).join(';');
        }
        return follower;
    }

    // TODO: THAY ĐỔI TRẠNG THÁI CÔNG VIỆC
    /**
     *  NẾU CÔNG VIỆC HOÀN THÀNH THÌ => CÔNG VIỆC CON SẼ HOÀN THÀNH
     *  NGƯỢC LẠI => CHƯA CÓ
     */
    React.useEffect(() => {
        if (data.status_id) {
            const status = statusList.find(item => item.stt! === TASK_STATUS_DONE);
            if (status &&
                data.status_id === status.id) {
                const lst = dataDetails;
                const updTaskDetails: QLCCTaskDetailModel[] = [];
                lst.map(item => {
                    updTaskDetails.push({
                        ...item,
                        is_done: true,
                        percent: 100
                    } as QLCCTaskDetailModel)
                })
                setDataDetails([...updTaskDetails]);
                ChangeIsDone();
            }
        }
    }, [data.status_id])

    // TODO: Thay đổi trạng thái công việc khi check đã hoàn thành
    React.useEffect(() => {
        if (data.is_done) {
            const status = statusList.find(item => item.stt! === TASK_STATUS_DONE)
            if (status) {
                setData({
                    ...data,
                    status_id: status.id,
                } as QLCCTaskModel)
            }
        } else {
            const status = statusList.find(item => item.stt! !== TASK_STATUS_DONE)
            if (status) {
                setData({
                    ...data,
                    status_id: status.id,
                } as QLCCTaskModel)
            }
        }
    }, [data.is_done])


    //TODO: CẬP NHẬT DANH SÁCH KHI HOÀN THÀNH HAY CHƯA - DỰA VÀO 'TIẾN ĐỘ CÔNG VIỆC'
    const ChangeIsDone = () => {
        if (dataDetails.length > 0 && !disable) {

            console.log('hi')

            const lstDetails = dataDetails.flatMap(item => item.percent);
            let totalProgress = 0;

            lstDetails.map(item => totalProgress += item!);

            const PERCENT = isNaN(totalProgress) ? 0 : totalProgress / lstDetails.length;

            // const dataDetailsLength = dataDetails.length;
            // const dataDetailsDone = dataDetails.filter(item => item.is_done === true).length || 0;
            // const percent = dataDetailsLength !== 0 ? dataDetailsDone / dataDetailsLength * 100 : 0;

            const getStatus = GetStatus(PERCENT);

            setData({
                ...data,
                percent: Number(PERCENT.toFixed(2)),
                // is_done: dataDetailsDone === dataDetailsLength
                is_done: PERCENT === 100,
                status_code: getStatus && getStatus.stt ? getStatus.stt : undefined,
                status_id: getStatus && getStatus.id ? getStatus.id : undefined,
                status_name: getStatus && getStatus.name ? getStatus.name : undefined
            } as QLCCTaskModel)
        }
    }

    const GetStatus = (percent: number) => {
        const CHUA_LAM = 1;
        const DANG_LAM = 2;
        const HOAN_THANH = 3;

        const TIEN_DO_CHUA_LAM = 0;
        const TIEN_DO_HOAN_THANH = 100;

        const IS_CHUA_LAM = percent === TIEN_DO_CHUA_LAM;
        const IS_HOAN_THANH = percent === TIEN_DO_HOAN_THANH;



        if (statusList && statusList.length > 0) {
            const STATUS_DEFAULT = statusList[0];
            if (IS_CHUA_LAM) {
                return statusList.find(item => item.stt === CHUA_LAM) || STATUS_DEFAULT;
            }
            else if (IS_HOAN_THANH) {
                return statusList.find(item => item.stt === HOAN_THANH) || STATUS_DEFAULT;
            } else {
                return statusList.find(item => item.stt === DANG_LAM) || STATUS_DEFAULT;
            }
        } else {
            return { ... new QLCCTaskStatusModel() } as QLCCTaskStatusModel;
        }

    }

    // DONE CÔNG VIỆC KHI CHECK ĐÃ HOÀN THÀNH
    const doneTask = (value: boolean) => {

        const lst: QLCCTaskDetailModel[] = [];
        const lstDetails: QLCCTaskDetailModel[] = dataDetails;
        lstDetails.map(item => {
            lst.push({
                ...item,
                is_done: value
            } as QLCCTaskDetailModel)
        })
        setDataDetails([...lst]);

    }

    const handleChangeInput = async (e: any) => {
        if (!disable) {

            const { name, value } = e.target;
            const DONE_PERCENT = 100;
            const DOING_PERCENT = 0;


            if (name === 'is_done') {

                const findStatus = statusList.find(item => item.stt === (value ? 3 : 2));

                await setData({
                    ...data,
                    [name]: value,
                    percent: value ? DONE_PERCENT : DOING_PERCENT,
                    status_id: findStatus ? findStatus.id : data.status_id,
                    status_code: findStatus ? findStatus.stt : data.status_code,
                    status_name: findStatus ? findStatus.name : data.status_name
                } as QLCCTaskModel);

                // if (value) {
                //     await setData({
                //         ...data,
                //         [name]: value,
                //         percent: DONE_PERCENT,
                //     } as QLCCTaskDetailModel);
                // } else {
                //     await setData({
                //         ...data,
                //         [name]: value,
                //         percent: DOING_PERCENT
                //     } as QLCCTaskDetailModel);
                // }

                doneTask(value);

            } else if (name === 'status_code') {
                const findStatus = statusList.find(item => item.stt === value);
                if (findStatus) {
                    await setData({
                        ...data,
                        status_id: findStatus.id,
                        status_code: findStatus.stt,
                        status_name: findStatus.name
                    } as QLCCTaskModel);
                }
            } else {
                if (name === 'department_id') {
                    await onloadUser();
                }
                await setData({ ...data, [name]: value } as QLCCTaskModel);
            }
        }
    };

    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        setData({ ...data, [name]: moment(value).toDate() } as QLCCTaskModel);
    }

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>(-1);
    const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>();
    const [reportInfo, setReportInfo] = React.useState<ReportInfo>();
    const [isOnChange, setIsOnChange] = React.useState<boolean>(false);


    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };

    // TODO: CHECK LIST
    const handleRowUpdating = async (e: any) => {
        if (!disable) {

            const PERCENT_DONE = 100;
            const PERCENT_DOING = 0;
            const DONE_CODE_STATUS = 3;
            const DOING_CODE_STATUS = 2;

            const { key, newData } = e;

            if (key) {
                const taskDetail = dataDetails.find(item => item.id === key);
                const taskDetailIndex = dataDetails.findIndex(item => item.id === key);
                const tempLst = dataDetails;

                const statusFilter = statusList.find(item => item.stt === (newData.is_done ? DONE_CODE_STATUS : DOING_CODE_STATUS));

                const dateDone: Date | undefined = newData.date_done;
                if (taskDetail) {

                    const taskDetailUpd = await {
                        ...taskDetail,
                        ...newData,
                        percent: newData.date_done ? data.percent : newData.is_done ? PERCENT_DONE : PERCENT_DOING,
                        status_id: newData.date_done ? data.is_done : statusFilter ? statusFilter.id : undefined,
                        status_name: statusFilter ? statusFilter.name : undefined,
                        date_done: dateDone ? dateDone : taskDetail.date_done,
                    } as QLCCTaskDetailModel;

                    tempLst.splice(taskDetailIndex, 1, taskDetailUpd);

                    console.log(taskDetailUpd)
                    setDataDetails(tempLst);

                    ChangeIsDone();

                    // await setIsOnChange(pre => !pre)
                    // await setIsOnChange(pre => !pre)
                }
            } else {
                Notification(
                    TYPE_MESSAGE.WARNING,
                    STATUS_MESSAGE[`UNAVAILABLE_FEATURE`]
                );
            }
        }
    }


    React.useEffect(() => {
        if (isOnChange) {
            ChangeIsDone();
        }
    }, [isOnChange])

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {

        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {

                const index = e.component.getRowIndexByKey(keys[0]);

                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
                // setDataDetail(keys[0])
                // setIdItemSelected(keys[0].id || "");
            });
        }
    };

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {

        // nếu action tồn tại trong action này thì trả về true và ngược lại
        const checkActionType = (lstActions: string[]) => {
            const action = props.action;
            return lstActions.includes(action);
        }

        const messageNotForAction = () => {

            let text = '';
            switch (props.action) {
                case ACTIONS.ADD:
                    text = 'thêm mới';
                    break;
                case ACTIONS.EDIT:
                    text = 'chỉnh sửa';
                    break;
                case ACTIONS.VIEW:
                    text = 'xem chi tiết';
                    break;
                case ACTIONS.SENTTASK:
                    text = 'chuyển';
                    break;
                case ACTIONS.UPDATETASK:
                    text = 'cập nhật tiến độ';
                    break;
            }

            Notification(
                TYPE_MESSAGE.WARNING,
                `Chức năng không khả dụng trong trường hợp đang ${text} công việc`
            );
        }

        if (!checkActionType([ACTIONS.VIEW])) {
            switch (action?.action_code) {
                case ACTIONS.VIEW:
                    if (dataGridRef.current) {
                        try {
                            const key = await getKeyRowSelected(dataGridRef);
                            const index = await getIndexRowSelected(dataGridRef);
                            if (dataGridRef.current && key && index >= 0) {
                                const ItemChoose = dataDetails.find(item => item.id === key);

                                setActionPopup({
                                    ...actionPopup,
                                    open: true,
                                    actions: action?.action_code,
                                    data: ItemChoose || {} as QLCCTaskDetailModel
                                })
                            } else {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                );
                            }
                        } catch (error) {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    }
                    break;
                case ACTIONS.INSERT:
                    if (checkActionType([ACTIONS.ADD, ACTIONS.EDIT])) {
                        if (dataGridRef.current) {
                            try {
                                const key = await getKeyRowSelected(dataGridRef);
                                const index = await getIndexRowSelected(dataGridRef);
                                if (dataGridRef.current && key && index >= 0) {
                                    const detailSelect = dataDetails[selectedRowIndex];
                                    setActionPopup({
                                        ...actionPopup,
                                        open: true,
                                        actions: action?.action_code,
                                        start_time: GET_DATE([detailSelect], -1) || new Date()
                                    })
                                } else {
                                    Notification(
                                        TYPE_MESSAGE.WARNING,
                                        STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                    );
                                }
                            } catch (error) {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                );
                            }
                        }
                    } else {
                        messageNotForAction();
                    }
                    break;
                case ACTIONS.ADD:
                    if (checkActionType([ACTIONS.ADD, ACTIONS.EDIT])) {
                        setActionPopup({
                            ...actionPopup,
                            open: true,
                            actions: action?.action_code,
                            start_time: GET_DATE(dataDetails, 1) || new Date(),
                            data: { ...new QLCCTaskDetailModel() } as QLCCTaskDetailModel,
                        })
                    }
                    else {
                        messageNotForAction();
                    }
                    break;
                case ACTIONS.DELETE:
                    if (checkActionType([ACTIONS.ADD, ACTIONS.EDIT])) {
                        if (dataGridRef.current) {
                            try {
                                const key = await getKeyRowSelected(dataGridRef);
                                const index = await getIndexRowSelected(dataGridRef);
                                if (dataGridRef.current && key && index >= 0) {
                                    const newDatas = dataDetails;
                                    newDatas.splice(index, 1)
                                    setDataDetails([...newDatas]);
                                } else {
                                    Notification(
                                        TYPE_MESSAGE.WARNING,
                                        STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                    );
                                }
                            } catch (error) {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                );
                            }
                        }
                    } else {
                        messageNotForAction();
                    }
                    break;
                case ACTIONS.COPY:
                    if (checkActionType([ACTIONS.ADD, ACTIONS.EDIT])) {
                        if (dataGridRef.current) {
                            try {
                                const key = await getKeyRowSelected(dataGridRef);
                                const index = await getIndexRowSelected(dataGridRef);
                                if (dataGridRef.current && key && index >= 0) {
                                    const ItemChoose = dataDetails.find(item => item.id === key);
                                    setActionPopup({
                                        ...actionPopup,
                                        open: true,
                                        actions: action?.action_code,
                                        data: ItemChoose || {} as QLCCTaskDetailModel,
                                        start_time: GET_DATE(dataDetails, 1) || new Date()
                                    })
                                } else {
                                    Notification(
                                        TYPE_MESSAGE.WARNING,
                                        STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                    );
                                }
                            } catch (error) {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                );
                            }
                        }
                    } else {
                        messageNotForAction();
                    }
                    break;
                case ACTIONS.EDIT:
                    if (checkActionType([ACTIONS.ADD, ACTIONS.EDIT])) {
                        if (dataGridRef.current) {
                            try {
                                const key = await getKeyRowSelected(dataGridRef);
                                const index = await getIndexRowSelected(dataGridRef);
                                if (dataGridRef.current && key && index >= 0) {
                                    const ItemChoose = dataDetails.find(item => item.id === key);
                                    setActionPopup({
                                        ...actionPopup,
                                        open: true,
                                        actions: action?.action_code,
                                        data: ItemChoose || {} as QLCCTaskDetailModel
                                    })
                                } else {
                                    Notification(
                                        TYPE_MESSAGE.WARNING,
                                        STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                    );
                                }
                            } catch (error) {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                );
                            }
                        }
                    } else {
                        messageNotForAction();
                    }
                    break;
                case ACTIONS.UPDATETASK:
                    if (checkActionType([ACTIONS.UPDATETASK])) {
                        if (dataGridRef.current) {
                            try {
                                const key = await getKeyRowSelected(dataGridRef);
                                const index = await getIndexRowSelected(dataGridRef);
                                if (dataGridRef.current && key && index >= 0) {
                                    const ItemChoose = dataDetails.find(item => item.id === key);
                                    setActionPopup({
                                        ...actionPopup,
                                        open: true,
                                        actions: action?.action_code,
                                        data: ItemChoose || {} as QLCCTaskDetailModel
                                    })

                                } else {
                                    Notification(
                                        TYPE_MESSAGE.WARNING,
                                        STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                    );
                                }
                            } catch (error) {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                );
                            }
                        }
                    } else {
                        messageNotForAction();
                    }
                    break;
                case ACTIONS.SAVE:
                case ACTIONS.CLOSE:
                case ACTIONS.SEARCH:
                    Notification(
                        TYPE_MESSAGE.INFO,
                        "Chức năng không khả dụng"
                    );
            }
        } else if (action.action_code === ACTIONS.VIEW) {
            if (dataGridRef.current) {
                try {
                    const key = await getKeyRowSelected(dataGridRef);
                    const index = await getIndexRowSelected(dataGridRef);
                    if (dataGridRef.current && key && index >= 0) {
                        const ItemChoose = dataDetails.find(item => item.id === key);
                        setActionPopup({
                            ...actionPopup,
                            open: true,
                            actions: ACTIONS.VIEW,
                            data: ItemChoose || {} as QLCCTaskDetailModel
                        })
                    } else {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                } catch (error) {
                    Notification(
                        TYPE_MESSAGE.WARNING,
                        STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                    );
                }
            }
        } else {
            Notification(
                TYPE_MESSAGE.INFO,
                "Chức năng không khả dụng trong trường hợp này"
            );
        }
    }

    const _validate = () => {
        return true;
    }

    const handleSubmit = async () => {
        if (_validate()) {

            const TranslateTime: QLCCTaskDetailModel[] = [];
            dataDetails.map(item => {

                // const startTime: Date = data.ngay_ct ? moment(`${moment(data.ngay_ct).format('yyyy-MM-dd')} ${moment(item.start_time).format('HH:mm')}`).toDate() : moment(item.start_time).utc(true).toDate();
                // const endTime: Date = data.end_time ? moment(`${moment(data.end_time).format('yyyy-MM-dd')} ${moment(item.end_time).format('HH:mm')}`).toDate() : moment(item.end_time).utc(true).toDate();

                TranslateTime.push({
                    ...item,
                    // start_time: TransDateTime(item.start_time || new Date()),
                    // end_time: TransDateTime(item.end_time || new Date()),
                    start_time: moment(item.start_time).utc(true).toDate(),
                    end_time: moment(item.end_time).utc(true).toDate(),
                    cdate: moment().toDate(),
                    sender: SessionLogin.userName,
                    sender_name: SessionLogin.fullName,
                    receiver: data.receiver ? data.receiver : undefined,
                    receiver_name: users.find(item => item.username === data.receiver)?.fullname || undefined,
                } as QLCCTaskDetailModel);
            })

            switch (props.action) {
                case ACTIONS.ADD:

                    const result: SysModel = await qLCClient.qLCCTaskIns({
                        ...new QLCCTaskModel(),
                        ...data,
                        name: data.title ? data.title : undefined,
                        description: htmlString,
                        qlccTaskDetails: [...TranslateTime],
                        ldate: TransDateTime(new Date()),
                        cdate: TransDateTime(data.cdate || new Date),
                        start_time: TransDateTime(data.start_time || new Date()),
                        end_time: TransDateTime(data.end_time || new Date()),
                        date_done: TransDateTime(new Date()),
                        type_code: TYPE_CODE_TASK,
                        sender_name: SessionLogin.fullName,
                        receiver_name: users.find(item => item.username === data.receiver)?.fullname || undefined,
                        attach_files: path.join(';'),
                        ngay_ct: moment(data.ngay_ct).utc(true).toDate(),
                        apartment_building_id: apartment_building_id,
                    } as QLCCTaskModel);

                    if (result) {
                        notification(MESSAGE_TYPE[result.status ?? 0], result.message);
                        if (result.status === 0) {
                            props.onEditSuccess(true);
                            props.onClose();
                        }
                    }

                    break;
                case ACTIONS.UPDATETASK:
                case ACTIONS.EDIT:
                    const resultUpd = await qLCClient.qLCCTaskUpd({
                        ...new QLCCTaskModel(),
                        ...props.data,
                        ...data,
                        description: htmlString,
                        date_done: TransDateTime(data.date_done || new Date()),
                        ldate: TransDateTime(new Date()),
                        cdate: TransDateTime(data.cdate || new Date),
                        start_time: TransDateTime(data.start_time || new Date()),
                        end_time: TransDateTime(data.end_time || new Date()),
                        qlccTaskDetails: [...TranslateTime],
                        attach_files: path.join(';'),
                        ngay_ct: moment(data.ngay_ct).utc(true).toDate(),
                        sender_name: SessionLogin.fullName,
                        receiver_name: users.find(item => item.username === data.receiver)?.fullname || undefined,
                        ma_cty: SessionLogin.ma_cty,
                        username_login: SessionLogin.userName,
                    } as QLCCTaskModel)

                    if (resultUpd) {
                        notification(MESSAGE_TYPE[resultUpd.status ?? 0], resultUpd.message);
                        if (resultUpd.status === 0) {
                            props.onClose();
                            props.onEditSuccess(true);
                        }
                    }
                    break;
                case ACTIONS.SENTTASK:
                    const resultSentTask = await qLCClient.qLCCTaskUpd({
                        ...new QLCCTaskModel(),
                        ...props.data,
                        ...data,
                        description: htmlString,
                        date_done: TransDateTime(data.date_done || new Date()),
                        ldate: TransDateTime(new Date()),
                        cdate: TransDateTime(data.cdate || new Date),
                        start_time: TransDateTime(data.start_time || new Date()),
                        end_time: TransDateTime(data.end_time || new Date()),
                        qlccTaskDetails: [...TranslateTime],
                        attach_files: path.join(';'),
                        ngay_ct: moment(data.ngay_ct).utc(true).toDate(),
                        sender_name: SessionLogin.fullName,
                        receiver_name: users.find(item => item.username === data.receiver)?.fullname || undefined,
                        ma_cty: SessionLogin.ma_cty,
                        username_login: SessionLogin.userName,
                    } as QLCCTaskModel)

                    if (resultSentTask) {
                        notification(MESSAGE_TYPE[resultSentTask.status ?? 0], resultSentTask.message);
                        if (resultSentTask.status === 0) {
                            props.onEditSuccess(true);
                            props.onClose();
                        }
                    }
                    break;
            }
        }
    }

    const handleTakeTask = async () => {
        const resultUpd = await qLCClient.qLCCTaskUpd({
            ...new QLCCTaskModel(),
            id: data.id,
            qlccTaskDetails: dataDetails,
            action_type: "UPDATE_STATUS",
            status_code: TASK_STATUS_NHAN_VIEC,
            username_login: SessionLogin.userName,
            ma_cty: SessionLogin.ma_cty,
        } as QLCCTaskModel)

        if (resultUpd) {
            notification(MESSAGE_TYPE[resultUpd.status ?? 0], resultUpd.message);
        }

        const statusDone = statusList.find(item => item.stt === TASK_STATUS_NHAN_VIEC);
        if (statusDone) {
            handleChangeInput({ target: { name: 'status_code', value: TASK_STATUS_NHAN_VIEC } })
        } else {
            props.onClose();
            props.onEditSuccess(true);
        }
    }

    const handleDoTask = async () => {
        const resultUpd = await qLCClient.qLCCTaskUpd({
            ...new QLCCTaskModel(),
            id: data.id,
            qlccTaskDetails: dataDetails,
            action_type: "UPDATE_STATUS",
            status_code: TASK_STATUS_DANG_LAM,
            username_login: SessionLogin.userName,
            ma_cty: SessionLogin.ma_cty,
        } as QLCCTaskModel)

        if (resultUpd) {
            notification(MESSAGE_TYPE[resultUpd.status ?? 0], resultUpd.message);
        }

        const statusDone = statusList.find(item => item.stt === TASK_STATUS_DANG_LAM);
        if (statusDone) {
            handleChangeInput({ target: { name: 'status_code', value: TASK_STATUS_DANG_LAM } })
        } else {
            props.onClose();
            props.onEditSuccess(true);
        }
    }

    const handleTadChild = async (e: any, actions: string) => {
        debugger
        const reload = () => {
            setLoadDataDetail(pre => !pre);
            setLoadDataDetail(pre => !pre);
        }

        switch (actions.toLocaleUpperCase()) {
            case ACTIONS.ADD:
            case ACTIONS.COPY:
                const arr = [...dataDetails, e]
                // setDataDetail(arr)
                setDataDetails(arr)
                reload();
                break;
            case ACTIONS.EDIT:
                const dataEdit = dataDetails;
                const newData = {
                    ...dataEdit[selectedRowIndex],
                    ...e
                } as QLCCTaskDetailModel;
                const NUMBER_DELETE = 1;
                dataEdit.splice(selectedRowIndex, NUMBER_DELETE, newData)
                // setDataDetail(dataEdit);
                setDataDetails([...dataEdit]);

                reload();
                break;
            case ACTIONS.INSERT:
                const data = e;
                const INDEX = selectedRowIndex;
                const dataInsert = dataDetails;
                dataInsert.splice(INDEX, 0, data); // Insert Element in Array
                // setDataDetail(dataInsert);
                setDataDetails([...dataInsert]);

                reload();
                break;
            case ACTIONS.UPDATETASK:
                const dateUpd = dataDetails;
                const newData2 = {
                    ...dateUpd[selectedRowIndex],
                    ...e
                } as QLCCTaskDetailModel;
                const NUMBER_DELETE_2 = 1;
                dateUpd.splice(selectedRowIndex, NUMBER_DELETE_2, newData2)
                // setDataDetail(dateUpd);
                setDataDetails([...dateUpd]);

                reload();
                break;
        }
    }

    React.useEffect(() => {
        if (loadDataDetail) {
            setDataDetails([...dataDetail]);
        }
    }, [loadDataDetail])

    // TODO: Người theo dõi
    const handleChangeFollowers = async (e: any) => {
        const { value } = e.target;
        setData({
            ...data,
            follower: value.join(';')
        } as QLCCTaskModel)
    }


    //   TODO: CHỌN KẾ HOẠCH CÔNG VIỆC
    const handleChangeTask = async (e: any) => {
        setLoading(true);

        const { value } = e.target;
        const lstDetails: QLCCTaskDetailModel[] = dataDetails;
        const lst: QLCCTaskDetailModel[] = []

        // LỌC LẠI DANH SÁCH -> BỎ NHỮNG TASK_ID KHÔNG TỒN TẠI TRONG @VALUE
        lst.push(
            ...lstDetails
                .filter(
                    item =>
                        !item.task_id
                        || (
                            props.action === ACTIONS.EDIT
                            && item.task_id === data.id
                        )
                )
        );

        // const lst: QLCCTaskDetailModel[] = [...lstDetails];
        // const lstIdParent = lst.flatMap(item => item.task_id); // DANH SÁCH ID CHA

        if (value && value.length > 0) {

            const Resdata = await qLCClient.qLCCTaskGet({
                ... new QLCCTaskModel(),
                id: [...value].join(';'),
                ma_cty: SessionLogin.ma_cty,
                type_code: TYPE_CODE_TASK_SCRIPT,
                username_login: SessionLogin.userName,
                action_type: "INFO_DETAIL",
                apartment_building_id: apartment_building_id,
            } as QLCCTaskModel);


            if (Resdata.length > 0) {

                const lstTaskDetail: QLCCTaskDetailModel[] = [];

                Resdata.map(p => {
                    if (p.qlccTaskDetails) {
                        p.qlccTaskDetails.map(taskDetail => {
                            lstTaskDetail.push({
                                ...taskDetail,
                                receiver: data && data.receiver ? data.receiver : SessionLogin.userName,
                                receiver_name: users.find(item => item.username === data.receiver)?.fullname || undefined,
                                sender: SessionLogin.userName,
                                sender_name: SessionLogin.fullName,
                                id: NEWID()
                            } as QLCCTaskDetailModel);
                        })
                    }
                })

                setDataDetails([...lst, ...lstTaskDetail]);

            }
        } else {
            setDataDetails([...lst]);
        }

        setTaskScriptId(value);
        setLoading(false);
    }

    React.useEffect(() => {
        if (dataDetails && dataDetails.length > 0) {
            setData({
                ...data,
                start_time: GET_DATE([...dataDetails], -1),
                end_time: GET_DATE([...dataDetails], 1),
            } as QLCCTaskModel)
        }
    }, [dataDetails]);

    const handleChangeRichText = (newContent: string) => {
        htmlString = newContent;
    }


    React.useEffect(() => {
        if (data.department_id && data.department_id !== '') {
            onloadUser();
        }
    }, [data.department_id])

    const titleGetAction = () => {
        switch (props.action) {
            case ACTIONS.ADD:
                if (menu?.component === menuComponentFeedback)
                    return 'Giao việc';
                else
                    return 'Thêm mới'
            case ACTIONS.EDIT:
                return 'Chỉnh sửa';
            case ACTIONS.UPDATETASK:
                return 'Cập nhật tiến độ';
            case ACTIONS.SENTTASK_2:
                return 'Giao việc';
            case ACTIONS.SENTTASK:
                return 'Chuyển tiếp việc';
            case ACTIONS.VIEW:
                return 'Chi tiết';
            default:
                return 'Thêm mới';
        }
    }

    // Kiểm tra action truyền vào có tồn tại trong props.action ko
    const CheckDisableInput = (actions: string[]) => {
        if (props.action) {
            const isInclude = actions.includes(props.action);
            return !isInclude;
        }
        else {
            return !false;
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            // className="d-flex justify-content-center"
            TransitionComponent={Transition}
            maxWidth="xl"
            fullWidth
            fullScreen={isFull}
            keepMounted
        >
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {"Kế hoạch công việc" +
                            " " +
                            `[${titleGetAction()}]`}
                    </Typography>
                    <IconButton
                        className="mr-2"
                        edge="end"
                        color="info"
                        onClick={() => setIsFull(pre => !pre)}
                        aria-label="close"
                    >
                        {isFull ? <CloseFullscreenIcon sx={{ color: '#1f2937' }} /> : <FullscreenIcon sx={{ color: '#1f2937' }} />}
                    </IconButton>
                    <IconButton
                        edge="end"
                        color="error"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon sx={{ color: '#1f2937' }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent className="task-DialogContent">
                <Grid container spacing={2} >
                    <Grid item xs={12} md={9}>
                        <Paper
                            variant="outlined"
                            className="shadow-taskK paper-taskK mb-3"
                        >
                            <InputLabel className="text-center text-uppercase" sx={{ fontSize: 18, color: '#333' }}>Công việc</InputLabel>
                            <Box className="box-around">
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tên công việc</InputLabel>
                                <DHSTextBox
                                    value={data?.title}
                                    name='title'
                                    onChange={handleChangeInput}
                                    disabled={CheckDisableInput([ACTIONS.ADD, ACTIONS.EDIT])}
                                    hint={HintCode.TASK_TITLE}
                                />
                            </Box>

                            <Box className="box-around">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Nhóm công việc</InputLabel>
                                        <DHSSelectBox
                                            dataSource={Groups}
                                            name="group_id"
                                            onValueChanged={handleChangeInput}
                                            displayExpr="name"
                                            searchExpr="name"
                                            valueExpr="id"
                                            value={data?.group_id}
                                            disabled={CheckDisableInput([ACTIONS.ADD, ACTIONS.EDIT])}
                                            hint={HintCode.TASK_GROUP}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Trạng thái</InputLabel>
                                        <DHSSelectBox
                                            dataSource={statusList}
                                            name="status_id"
                                            onValueChanged={handleChangeInput}
                                            displayExpr="name"
                                            searchExpr="name"
                                            valueExpr="id"
                                            defaultValue={data?.status_id}
                                            value={data?.status_id}
                                            disabled={true}
                                            hint={HintCode.TASK_STATUS}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className="box-around">
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mô tả</InputLabel>
                                <RichTextEditor
                                    value={htmlString}
                                    height={350}
                                    onChange={(newContent) => handleChangeRichText(newContent)}
                                    readOnly={CheckDisableInput([ACTIONS.ADD, ACTIONS.EDIT])}
                                />
                            </Box>
                        </Paper>

                        {
                            // menu?.component !== menuComponentFeedback
                            !data.feedback_id
                            && <>
                                <Paper
                                    variant="outlined"
                                    className="shadow-taskK paper-taskK mt-3"
                                >
                                    <InputLabel sx={{ fontSize: 18, color: '#333', fontWeight: 600 }}>Công việc con</InputLabel>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <DHSToolbarRoleActionType2
                                                id={" "}
                                                menu={menu}
                                                customMenu={[]}
                                                onClickAction={handleClickAction}
                                            />
                                            <DHSDataGridEdit
                                                data={dataDetails}
                                                table_name={"QLCCTaskDetailList"}
                                                key="id"
                                                keyExpr="id"
                                                mode="cell"
                                                // mode="bath"
                                                dataGridRef={dataGridRef}
                                                allowEdit={true}
                                                pageSize={menu?.page_size}
                                                isPagination={menu?.is_pagination}
                                                isMultiple={false}
                                                groupingAutoExpandAll={false}
                                                handlePageChange={handlePageChange}
                                                handleSelectionChanged={handleSelectionChanged}
                                                height={40}
                                                handleRowUpdating={handleRowUpdating}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </>
                        }

                        <Paper
                            variant="outlined"
                            className="shadow-taskK paper-taskK mt-3"
                        >
                            <Box className="box-around w-100">
                                <InputLabel sx={{ fontSize: 18, color: '#333' }}>Tập tin đính kèm</InputLabel>
                                <TaskFileAttach
                                    setPath={function (path_: []): void {
                                        setPath([...path_]);
                                    }}
                                    path={path}
                                    action={props.action}
                                    title="Tập tin"
                                />
                            </Box>
                        </Paper>
                        {
                            !CheckDisableInput([ACTIONS.VIEW, ACTIONS.EDIT, ACTIONS.SENTTASK, ACTIONS.UPDATETASK])
                            && (
                                <Paper
                                    variant="outlined"
                                    className="shadow-taskK paper-taskK mt-3"
                                >

                                    <TaskForum
                                        task_id={data.id}
                                        action={props.action}
                                        open={loadDataContact}
                                    />
                                </Paper>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md={3}  >
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='task-AccordionSummary-icon-color' />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                // sx={{ backgroundColor: "#FBBC05" }}
                                className="task-AccordionSummary "

                            >
                                <Typography sx={{ fontWeight: 600, color: "#fff" }}>{LABEL_TASK.TASK_ASSIGNMENT}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box className="box-around">
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>{LABEL_TASK.DEPARTMENT}</InputLabel>
                                    <DHSSelectBox
                                        dataSource={departments}
                                        name="department_id"
                                        onValueChanged={handleChangeInput}
                                        displayExpr="ten_bp"
                                        searchExpr="ten_bp"
                                        valueExpr="ma_bp"
                                        value={data?.department_id}
                                        disabled={CheckDisableInput([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.SENTTASK])}
                                        hint={HintCode.DEPARTMENT}

                                    />
                                </Box>

                                <Box className="box-around">
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>{LABEL_TASK.WORKER}</InputLabel>
                                    <DHSSelectBox
                                        dataSource={users}
                                        name="receiver"
                                        onValueChanged={handleChangeInput}
                                        displayExpr="fullname"
                                        searchExpr="fullname"
                                        valueExpr="username"
                                        value={data?.receiver}
                                        disabled={CheckDisableInput([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.SENTTASK])}
                                        hint={HintCode.TASK_RECEIVER}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='task-AccordionSummary-icon-color' />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                // sx={{ backgroundColor: "#FBBC05" }}
                                className="task-AccordionSummary "
                            >
                                <Typography sx={{ fontWeight: 600, color: "#fff" }}>Tiến độ công việc</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <InputLabel className="text-center text-uppercase" sx={{ fontSize: 14, color: '#333', fontWeight: "bold" }}>Tiến độ công việc</InputLabel>
                                <Box className="box-around">
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>{LABEL_TASK.START_TIME}</InputLabel>
                                    <DHSDateTimeComponentV2
                                        name="start_time"
                                        value={data?.start_time}
                                        onChange={(e: any) => {
                                            handleChangeDate(e)
                                        }}
                                        disabled={CheckDisableInput([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.SENTTASK, ACTIONS.UPDATETASK])}
                                        maxDate={data?.end_time}
                                        hint={HintCode.TASK_START_TIME}
                                    />
                                </Box>
                                <Box className="box-around">
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>{LABEL_TASK.END_TIME}</InputLabel>
                                    <DHSDateTimeComponentV2
                                        name="end_time"
                                        type="datetime"
                                        value={data?.end_time}
                                        onChange={(e: any) => {
                                            handleChangeDate(e)
                                        }}
                                        disabled={CheckDisableInput([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.SENTTASK, ACTIONS.UPDATETASK])}
                                        minDate={data?.start_time}
                                        hint={HintCode.TASK_END_TIME}
                                    />
                                </Box>
                                <Box className="d-flex box-around pt-2">
                                    <CheckBox
                                        // defaultValue={data.is_done ? data.is_done : false}
                                        value={data.is_done}
                                        id="is_done"
                                        name="is_done"
                                        onValueChange={e => {
                                            handleChangeInput({
                                                target: {
                                                    name: "is_done",
                                                    value: e
                                                }
                                            })
                                        }}
                                        disabled={CheckDisableInput([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.UPDATETASK])}
                                    />
                                    <InputLabel htmlFor="is_done" className="cc-label ml-2">Đã hoàn thành</InputLabel>
                                </Box>
                                {
                                    props.action !== ACTIONS.ADD && (
                                        <>
                                            <Box className="box-around">
                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày hoàn thành</InputLabel>
                                                <DHSDateTimeComponentV2
                                                    name="date_ done"
                                                    type="datetime"
                                                    value={data?.date_done}
                                                    onChange={(e: any) => {
                                                        handleChangeDate(e)
                                                    }}
                                                    disabled={CheckDisableInput([ACTIONS.EDIT, ACTIONS.UPDATETASK])}
                                                    hint={HintCode.TASK_DATE_DONE}
                                                />
                                            </Box>
                                        </>
                                    )
                                }
                                <Box className="box-around pt-2">
                                    <InputLabel className="cc-label">{`Tiến độ công việc (${data.percent}%)`}</InputLabel>
                                    <Slider
                                        min={0}
                                        max={100}
                                        defaultValue={data.percent}
                                        value={data.percent}
                                        onValueChange={(e: number) => handleChangeInput({
                                            target: {
                                                name: 'percent',
                                                value: e
                                            }
                                        })}
                                        disabled={true}

                                    >
                                        <Tooltip enabled={true} position="bottom" format={(e: number) => `${e}%`} />
                                    </Slider>
                                </Box>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='task-AccordionSummary-icon-color' />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                // sx={{ backgroundColor: "#e0f2fe" }}
                                // sx={{ backgroundColor: "#FBBC05" }}
                                className="task-AccordionSummary "
                            >
                                <Typography sx={{ fontWeight: 600, color: "#fff" }}>{LABEL_TASK.LIST_FOLLOWER}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TaskFollower
                                    follower={follower}
                                    data={data.follower}
                                    removeFolloer={(newdata: string) => {
                                        if (props.action !== ACTIONS.VIEW) {
                                            setData({ ...data, follower: newdata } as QLCCTaskModel)
                                        }
                                    }}
                                    action={props.action}
                                    enabled={[ACTIONS.ADD, ACTIONS.EDIT]}
                                />

                                <TaskFollowerInput
                                    follower={follower}
                                    data={data}
                                    removeFolloer={(newdata: string) => {
                                        if (props.action !== ACTIONS.VIEW) {
                                            setData({ ...data, follower: newdata } as QLCCTaskModel)
                                        }
                                    }}
                                    action={props.action}
                                    handleChangeFollowers={handleChangeFollowers}
                                    enabled={[ACTIONS.ADD, ACTIONS.EDIT]}
                                />

                            </AccordionDetails>
                        </Accordion>

                        {
                            !CheckDisableInput([ACTIONS.VIEW, ACTIONS.EDIT, ACTIONS.SENTTASK, ACTIONS.UPDATETASK]) &&
                            data &&
                            data.qlccTaskDetails &&
                            data.qlccTaskDetails?.length > 0 && <>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className='task-AccordionSummary-icon-color' />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                        // sx={{ backgroundColor: "#FBBC05" }}
                                        className="task-AccordionSummary"
                                    >
                                        {/* <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Trạng thái</Typography> */}
                                        <Typography sx={{ fontWeight: 600, color: "#fff" }}>Trao đổi công việc</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="task-child-forum-chat-master-block">
                                        <TaskChildForum
                                            data={data}
                                            action={props.action}
                                            open={loadDataContact}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        }
                    </Grid>
                </Grid>

                <TaskChild
                    open={actionPopup.open}
                    onClose={() => setActionPopup({
                        ...actionPopup,
                        open: false
                    })}
                    data={actionPopup.data}
                    action={actionPopup.actions}
                    onEditSuccess={() => setReload(pre => !pre)}
                    onSubmit={handleTadChild}
                    start_time={actionPopup.start_time}
                    ma_bp={data.department_id}
                    receiver={data.receiver}
                    groups={Groups}
                    types={Types}
                    statusList={statusList}
                    typeCode={data.type_code}
                    groupTask={data.group_id}
                    apartmentBuildingId={apartment_building_id}
                />
            </DialogContent>
            <DialogActions>
                {!CheckDisableInput([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.SENTTASK, ACTIONS.UPDATETASK]) && (
                    <Button variant="contained" onClick={handleSubmit}>
                        Lưu
                    </Button>
                )}

                {
                    data.status_code === TASK_STATUS_CHUA_NHAN_VIEC &&
                    !CheckDisableInput([ACTIONS.EDIT, ACTIONS.UPDATETASK]) &&
                    (
                        <Button variant="contained" color="success" onClick={handleTakeTask}>
                            Nhận công việc
                        </Button>
                    )
                }
                {
                    (data.status_code === TASK_STATUS_CHUA_NHAN_VIEC ||
                        data.status_code === TASK_STATUS_NHAN_VIEC) &&
                    !CheckDisableInput([ACTIONS.EDIT, ACTIONS.UPDATETASK]) &&
                    (
                        <Button variant="contained" color="success" onClick={handleDoTask}>
                            Bắt đầu làm
                        </Button>
                    )
                }

                <Button onClick={props.onClose}>Đóng</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog >
    );
};

export default TaskListAction;

