import React, { Fragment, forwardRef, useEffect, useState } from 'react'
import DHSList from '../../../../../../components/DHSComponents/actions-and-list/DHSList/DHSList'
import { useForwardRef } from '../../../../../../components/utils/hooks/useForwardRef';
import { ItemClickEvent } from 'devextreme/ui/list';
import { Avatar, Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { QLCCTaskModel, UserInfoFilterModel } from '../../../../../shared/service-proxies/api-shared';
import { debounce } from 'lodash';

interface IProps {
    dataSource: any[];
    height: string | number;
    searchExpr: string;
    itemRender?: ((...params: any) => React.ReactNode) | undefined;
    displayExpr?: string;
    keyExpr?: string;
    onItemClick?: (e: ItemClickEvent<any, any>) => void;
    selectedItemKeys?: any[];
}

const WorkReportStackUser = forwardRef<any, IProps>((props, ref) => {

    function itemRender(e: UserInfoFilterModel) {
        return <>
            <ListItem sx={{ padding: 0, width: '100%' }} >
                <ListItemButton>
                    <ListItemIcon>
                        <Avatar sx={{ bgcolor: stringToColor(e.username ?? "UNKNOW") }}>{e.fullname?.slice(0, 1) ?? e.username?.slice(0, 1)}</Avatar>
                    </ListItemIcon>
                    <ListItemText >
                        {e.fullname ?? e.username}
                    </ListItemText>
                </ListItemButton>
            </ListItem>
        </>
    }

    return (
        <Fragment>
            <Stack direction={"row"} width={"100%"} >
                <DHSList
                    ref={ref}
                    dataSource={props.dataSource}
                    height={props.height}
                    itemRender={itemRender}
                    searchExpr={props.searchExpr}
                    searchEnabled={true}
                    searchMode={'contains'}
                    displayExpr={props?.displayExpr}
                    keyExpr={props?.keyExpr}
                    onItemClick={props?.onItemClick}
                    showScrollbar="onScroll"
                    selectionMode="single"
                    width={"100%"}
                    searchEditorOptions={{
                        placeholder: 'Tìm kiếm nhân viên',
                    }}
                    focusStateEnabled={true}
                    hoverStateEnabled={true}
                    activeStateEnabled={true}
                    // selectedItemKeys={['NGUYENDUNG']}
                    useNativeScrolling
                />
            </Stack>
        </Fragment>
    )
})

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}



export default WorkReportStackUser