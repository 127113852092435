import React, { useEffect, useRef, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  InputLabel,
  TextField,
} from "@mui/material";
import { EventInfo } from "devextreme/events";
import dxDataGrid, {
  CellPreparedEvent,
  DataChangeInfo,
  RowInsertedInfo,
  RowInsertingInfo,
  RowRemovedInfo,
  RowRemovingInfo,
  RowUpdatedInfo,
  RowUpdatingInfo,
  SavingInfo,
  SelectionChangedInfo,
} from "devextreme/ui/data_grid";
import DataGrid from "devextreme-react/data-grid";
import { AppSession } from "../../shared/app-session/app-session";
import {
  HomeClient,
  QLCCClient,
  SysActionType,
  SysGenRowTableModel,
  SysMenu,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import { IReducer, Notification } from "../../../components/layouts";
import { ACTIONS } from "../../../common/enums/DHSToolbarRoleAction";
import DHSToolbarRole from "../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
// import DHSDataGridEdit from "../../../DHS/DHS_DataGridEdit/DataGridEdit";
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DialogCreateTable from "./DialogCreateTable";
import BlockUI from "../../../components/Block-UI/Block-ui";
import DHSBreadcrumb from "../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import {
  onAddRow,
  onDeleteRow,
  onEditRow,
  onViewRow,
} from "../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import notification from "../../../common/notification/notification";
import {
  STATUS_MESSAGE,
  TYPE_MESSAGE,
} from "../../../common/Interfaces/StatusMessage";
import DHSAutoCompleteWithDataSource from "../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";
type Props = {};

type FilterType = {
  isShowSearch: boolean;
  isShowGroup: boolean;
  isShowSort: boolean;
  isShowFilter: boolean;
  isShowHideColumn: boolean;
  isShowFixing: boolean;
};

let appSession: AppSession;
const DataGridSetting = (props: Props) => {
  const history = useHistory();
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const qlccClient = new QLCCClient(appSession, BASE_URL_API);
  const dataGridRef = useRef<DataGrid>(null);
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const [data, setData] = useState<SysGenRowTableModel[]>([]);
  const [action, setAction] = useState<SysActionType>();
  const location = useLocation();
  const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
  const [allowEdit, setAllowEdit] = useState<boolean>(true);
  const [visibleDialogCreateTable, setVisibleDialogCreateTable] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedItemRowIndexs, setSelectedItemRowIndexs] = useState<number[]>(
    []
  );

  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
 
  const [menuSelected, setMenuSelected] = useState<SysMenu>();
  const [tableName, setTableName] = useState<string>("");

  const listMenu = useSelector(
    (state: IReducer) => state.RoleReducer?.listMenu
  );

  // useEffect(() => {
  //   (async () => {
  //     if (menuSelected) {
  //       try {
  //         const res = await homeClient.sysGenRowTableGet({
  //           ...new SysGenRowTableModel(),
  //           menuid: menuSelected.menuid,
  //           // menuid: '',
  //           table_name: "",
  //         } as SysGenRowTableModel);
  //         setData([...res]);
  //       } catch (error) {
  //         setData([]);
  //       }
  //     }
  //   })();
  // }, [menuSelected]);

  const handleGetData = async () => {
    (async () => {
      try {
        setLoading(true);
        const res = await homeClient.sysGenRowTableGet({
          ...new SysGenRowTableModel(),
          menuid: menuSelected ? menuSelected.menuid : "",
          table_name: tableName || "",
        } as SysGenRowTableModel);
        setData([...res]);
      } catch (error) {
        setData([]);
      } finally {
        setLoading(false);
      }
    })();
  };

  // useEffect(() => {
  //   (async() => {
  //     const res = await qlccClient.qLCCCitizenIdentificationCardsActions({
  //       ...new QLCCCitizenIdentificationCardsModel(),
  //       action_type: 'GET'
  //     } as QLCCCitizenIdentificationCardsModel);
  //     setData([...res]);
  //   })()
  // }, [])

  const handleSelectionChanged = (
    e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
  ) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
        console.log(keys);

        const index = e.component.getRowIndexByKey(keys[0]);

        console.log(index);

        setSelectedRowIndex(index >= 0 ? index : -1);
        setSelectedItemKeys(keys[0]);
      });
    }
  };

  const handlePageChange = () => {
    setSelectedRowIndex(-1);
    setSelectedItemKeys(undefined);
  };

  const handleRowInserting = async (
    event: EventInfo<dxDataGrid<any, any>> & RowInsertingInfo<any>
  ) => {
    try {
      event.cancel = true;
      setLoading(true);
      const response = await homeClient.sysGenRowTableIns({
        ...event.data,
        table_name: event.data.table_name || "",
        menuid: event.data.menuid || "",
      } as SysGenRowTableModel);

      if (response[0].status === 0) {
        setData([{ id: response[0].id, ...event.data }, ...data]);
        dataGridRef.current?.instance.cancelEditData();
        notification(
          TYPE_MESSAGE.SUCCESS,
          STATUS_MESSAGE[`${ACTIONS.ADD}_${response[0]!.status}`]
        );
      } else {
        event.cancel = true;
        notification(
          TYPE_MESSAGE.ERRORR,
          STATUS_MESSAGE[`${ACTIONS.ADD}_${response[0]!.status}`]
        );
      }
    } catch (error) {
      notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
    } finally {
      setLoading(false);
    }
  };

  const handleRowUpdating = async (
    event: EventInfo<dxDataGrid<any, any>> & RowUpdatingInfo<any, any>
  ) => {
    try {
      event.cancel = true;
      setLoading(true);
      const response = await homeClient.sysGenRowTableUpd({
        ...event.oldData,
        ...event.newData,
      } as SysGenRowTableModel);


      if (response[0].status === 0) {
        const dataLastUpdated = data.map((item) => {
          if (item.id === event.key) {
            return {
              ...new SysGenRowTableModel(),
              ...event.oldData,
              ...event.newData,
            };
          } else {
            return item;
          }
        });

        setData([...dataLastUpdated]);
        dataGridRef.current?.instance.cancelEditData();
        notification(
          TYPE_MESSAGE.SUCCESS,
          STATUS_MESSAGE[`${ACTIONS.EDIT}_${response[0]!.status}`]
        );
      } else {
        notification(
          TYPE_MESSAGE.ERRORR,
          STATUS_MESSAGE[`${ACTIONS.EDIT}_${response[0]!.status}`]
        );
      }
    } catch (error) {
      notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
    } finally {
      setLoading(false);
    }
  };

  const handleRowRemoving = async (
    event: EventInfo<dxDataGrid<any, any>> & RowRemovingInfo<any, any>
  ) => {
    event.cancel = true;

    try {
      setLoading(true);
      const response = await homeClient.sysGenRowTableDel({
        id: event.data.id,
      } as SysGenRowTableModel);

      if (response[0].status === 0) {
        setData([...data.filter((item) => item.id !== event.key)]);
        notification(
          TYPE_MESSAGE.SUCCESS,
          STATUS_MESSAGE[`${ACTIONS.DELETE}_${response[0]!.status}`]
        );
      } else {
        notification(
          TYPE_MESSAGE.ERRORR,
          STATUS_MESSAGE[`${ACTIONS.DELETE}_${response[0]!.status}`]
        );
      }
    } catch (error) {
      notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
    } finally {
      setLoading(false);
    }
  };

  const handleClickAction = async (action: SysActionType) => {
    switch (action?.action_code?.toLocaleUpperCase()) {
      case ACTIONS.ADD:
        await onAddRow(dataGridRef, setAllowEdit);
        break;
      case ACTIONS.DELETE:
        await onDeleteRow(dataGridRef);
        break;
      case ACTIONS.EDIT:
        await onEditRow(dataGridRef, setAllowEdit);
        break;
      case ACTIONS.VIEW:
        await onViewRow(dataGridRef, setAllowEdit);
        break;

      default:
        break;
    }
  };

  // const handleChangeColorCell = (e: CellPreparedEvent<any, any>) => {
  //   if(e.data && e.column && e.column.dataField === 'column_name') {
  //     switch (e.data['column_name']) {
  //       case "approve_status_name":
  //         // e.cellElement.className += 'backgound-color-row-datagrid2'
  //         e.cellElement.className += 'backgound-color-row-datagrid2'
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // };

  return (
    <Box sx={{ p: 2 }}>
      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          marginTop: 15,
          marginBottom: 15,
          fontSize: 22,
          fontWeight: 550,
        }}
      >
        <DHSBreadcrumb location={location} />
      </div>
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <Box style={{ width: 250, marginRight: 10 }}>
          <InputLabel className="cc-input">Tính năng</InputLabel>
          {/* <DHSAutoCompleteWithDataSource
            id="menu-select"
            className="cc-input"
            dataSource={listMenu || []}
            getOptionLabel={(option: any) =>
              `${option.menuid} - ${option?.bar}`
            }
            value={''}
            valueMember="id"
            onValueChanged={(e, newValue: any) => {
              setMenuSelected(newValue);
            }}
          /> */}
          <Autocomplete
            id="menu-select"
            sx={{ width: "100%" }}
            options={listMenu || []}
            autoHighlight
            getOptionLabel={(option: any) =>
              `${option.menuid} - ${option?.bar}`
            }
            // value={menuSelected
            // }
            onChange={(e, newValue: any) => {
              // console.log(newValue);
              setMenuSelected(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className="cc-input"
                placeholder="Tìm kiếm..."
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Box>
        <Box style={{ width: 150 }}>
          <InputLabel className="cc-input">Tên bảng</InputLabel>
          <TextField
            value={tableName}
            onChange={(e) => setTableName(e.target.value)}
            placeholder="Nhập tên bảng"
          />
        </Box>
        <Button
          variant="contained"
          onClick={handleGetData}
          sx={{ ml: 2 }}
          size="small"
        >
          Tìm kiếm
        </Button>
        <Button
          variant="contained"
          onClick={() => setVisibleDialogCreateTable(true)}
          sx={{ ml: 2 }}
          size="small"
        >
          Tạo nhanh
        </Button>
      </Box>
      <DHSToolbarRole id={" "} menu={menu} onClickAction={handleClickAction} />
      <DHSDataGridEdit
        data={data}
        table_name="sysGenRowTable"
        keyExpr="id"
        mode="popup"
        dataGridRef={dataGridRef}
        allowEdit={allowEdit}
        pageSize={menu?.page_size}
        isPagination={menu?.is_pagination}
        isMultiple={menu?.is_multiple}
        groupingAutoExpandAll={false}
        height={70}
        handlePageChange={handlePageChange}
        handleRowInserting={handleRowInserting}
        handleRowUpdating={handleRowUpdating}
        handleRowRemoving={handleRowRemoving}
        // handleCellPrepared={handleChangeColorCell}
      />
      <DialogCreateTable
        visibleDialogCreateTable={visibleDialogCreateTable}
        setVisibleDialogCreateTable={setVisibleDialogCreateTable}
      />
      <BlockUI blocking={loading} title={""}></BlockUI>
    </Box>
  );
};

export default DataGridSetting;
