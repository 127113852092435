import React, { useContext, useEffect, useRef, useState } from 'react'
import { QLCCClient, QLCCDomesticWaterCycleModel, QLCCDomesticWaterModel, QLCCDomesticWaterParam } from '../../../../../shared/service-proxies/api-shared';
import { Box, Button, DialogActions, DialogContent, Grid, InputLabel, TextField } from '@mui/material';
import DataGridEdit from '../../../../../../components/DHS/DHS_DataGridEdit/DataGridEdit';
import { getListDataSource } from '../../../../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';
import { Notification } from '../../../../../../components/layouts';
import { MESSAGE_TYPE } from '../../../../../../common/Interfaces/StatusMessage';
import DHSDateTimeComponentV2 from '../../../../../../components/DHS/DHS-DateTime/DHSDateTimeComponentV2';
import moment from 'moment';
import DHSDateBox from '../../../../../../components/DHSComponents/editors/DHSDateBox/DHSDateBox';
import { createContext } from 'vm';
import { IUpdateDomesticWaterContext, ProviderUpdateDomesticWaterMoreThanOneMeter } from './context/update-domestic-water-more-than-meter-context';
import UpdateDomesticWaterMoreThanMeterUpdateFile from './update-load-file';

interface IProps {
    dataSource: QLCCDomesticWaterModel[],
    setLoading: () => void;
    domesWaterCycleDefault: QLCCDomesticWaterCycleModel;
    active: boolean; // kích hoạt
    apartment_building_id: string,
    onCloseWindow: () => void;
    onSubmit: (dataSourceParams: QLCCDomesticWaterModel[]) => void;
}

const DomesticWaterUpdateMoreThanOneMeter = (props: IProps) => {
    const { domesWaterCycleDefault, apartment_building_id } = props;
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [dataSource, setDataSource] = React.useState<QLCCDomesticWaterModel[]>([]);
    const dataGridRef = useRef(null);
    const [enter_date, setEnterDate] = useState<Date>();
    const [allowEdit, setAllowEdit] = useState<boolean>(true);



    async function OnSetDataSource() {
        if (props.active) {
            const propsDataSource = props.dataSource;
            setDataSource([...propsDataSource]);
            const getFirstItem = propsDataSource.pop();
            setEnterDate(moment(getFirstItem?.enter_date).toDate())
        } else {
            setDataSource([])
        }
    }

    function getApartmentName(dataParams: QLCCDomesticWaterModel) {
        const findNameApartment = dataParams.apartment_name;
        const findNameFloor = dataParams.floor_name;
        return `${findNameFloor}/ căn hộ ${findNameApartment}`
    }

    async function handleSubmit() {
        const newDataSource: any = await getListDataSource(dataGridRef);
        try {

            const newDataSource2: QLCCDomesticWaterModel[] = []
            newDataSource.map((item: QLCCDomesticWaterModel) => {
                newDataSource2.push({
                    ...item,
                    enter_date: moment(enter_date).toDate()
                } as QLCCDomesticWaterModel);
            })

            props.onSubmit(newDataSource2);
        } catch (ex) {
            console.log(ex);
            props.onSubmit([])
        }
    }

    useEffect(() => {
        OnSetDataSource()
    }, [props.active])


    function payStatus() {
        const newDataSource = props.dataSource;
        try {
            newDataSource.map(item => {
                if (item && item.pay_status === true) {
                    return true; // đã thanh toán
                }
            })
            return false; // chưa thanh toán
        } catch (ex) {
            console.log(ex);
            return false;
        }
    }

    return (
        <>
            <ProviderUpdateDomesticWaterMoreThanOneMeter.Provider value={{
                dataSource: dataSource,
                setDataSource: setDataSource
            } as IUpdateDomesticWaterContext}>

                <DialogContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}
                            sx={{
                                fontSize: 14, color: '#333', fontWeight: '500'
                            }}
                        >
                            {
                                dataSource && dataSource.length > 0 ? getApartmentName(dataSource[0]) : <></>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Chu Kỳ</InputLabel>
                                        <TextField
                                            className="ksc-input"
                                            sx={{ width: "100%" }}
                                            name="domestic_water_cycle_id"
                                            value={domesWaterCycleDefault?.title || ""}
                                            disabled={domesWaterCycleDefault?.is_block}
                                        >
                                        </TextField>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày nhập</InputLabel>
                                        <DHSDateBox
                                            value={enter_date}
                                            onValueChange={e => {
                                                setEnterDate(e);
                                            }}
                                            type='date'
                                            showAnalogClock={false}
                                            showClearButton={false}
                                            pickerType='calendar'
                                            disabled={domesWaterCycleDefault?.is_block}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <DataGridEdit
                                data={dataSource}
                                table_name={"DomesticWaterUpdate"}
                                keyExpr="id"
                                mode="cell"
                                dataGridRef={dataGridRef}
                                allowEdit={!domesWaterCycleDefault?.is_block}
                                isMultiple={false}
                                groupingAutoExpandAll={false}
                                height={40}
                                ma_cty={SessionLogin?.ma_cty}
                                currency="VND"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <UpdateDomesticWaterMoreThanMeterUpdateFile domesWaterCycleDefault={domesWaterCycleDefault}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {
                        (domesWaterCycleDefault && !domesWaterCycleDefault.is_block) &&
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Lưu
                        </Button>
                    }
                    <Button onClick={props.onCloseWindow}>Đóng</Button>
                </DialogActions>
            </ProviderUpdateDomesticWaterMoreThanOneMeter.Provider>
        </>
    )
}

export default DomesticWaterUpdateMoreThanOneMeter