import React, { useMemo } from "react";
import {
    Button,
    TextField,
    MenuItem,
    Dialog,
    DialogContent,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
    Grid,
    Checkbox,
    ListItemText,
    Select,
    Paper,
    DialogActions,
} from "@mui/material";
import "./NotificationsActions.css";
import {
    HomePageClient,
    ParaObjTableList,
    QLCCApartmentBuildingModel,
    QLCCApartmentModel,
    QLCCBlockModel,
    QLCCCategoriesModel,
    QLCCClient,
    QLCCFloorModel,
    QLCCNotificationsModel,
    UserInfoGroupModel,
    UserInfoModel,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import JoditEditor from "jodit-react";
import { UploadFile } from "./UploadFile";
import CategoriesActions from "../notification-category-action/CategoriesActions";
import { Tabs, TabsProps } from "antd";
import { Notification } from "../../../../components/layouts";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import NotificationPopupResident from "./notification-popup-resident";
import NotificationPopupHouse from "./notification-popup-house-icon";
import CTXDate from "../../../../components/CTX/DatePicker";
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import moment from "moment";
import { httpPost } from "../../../../common/httpService";
import DHSSelectBox from "../../../../components/DHS/DHS_SelectBox";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";
import DHSTagBox from "../../../../components/DHSComponents/editors/DHSTagBox/DHSTagBox";

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
    apartmentBuildings?: any;
    notify: any;
}

interface IActions {
    type: string,
    open: boolean,
    payload: string,
}
interface IPopupResident {
    open: boolean,
    listResident: string[],
    action: ACTIONS.ADD | ACTIONS.EDIT | ACTIONS.VIEW
}

interface IStatus {
    id: string | undefined,
    code: string | undefined,
    name: string | undefined,
    name_us: string | undefined,
}


const defaultPopupResident: IPopupResident = {
    open: false,
    listResident: [],
    action: ACTIONS.ADD
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

var appSession: AppSession;

const convert = ['success', 'error', 'warning'];
const NotificationsActions = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const [_id, setId] = React.useState<string>();
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [reload, setReload] = React.useState(false);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [config, setConfig] = React.useState<any>();
    const [data, setData] = React.useState<QLCCNotificationsModel>({} as QLCCNotificationsModel);
    const [apartmentList, setApartmentList] = React.useState<QLCCApartmentModel[]>([] as QLCCApartmentModel[])
    const [category, setCategory] = React.useState<QLCCCategoriesModel[]>([])
    //=================> Open Dialog Category  <=============================//

    const [actt, setActt] = React.useState<IActions>({
        type: "",
        open: false,
        payload: ""
    })


    const homePageCLient = new HomePageClient(appSession, BASE_URL_API);
    const [blocks, setBlocks] = React.useState<QLCCBlockModel[]>([] as QLCCBlockModel[]);
    const [floors, setFloors] = React.useState<QLCCFloorModel[]>([] as QLCCFloorModel[]);

    // người dùng chọn
    const [apartmentBuilding, setApartmentBuilding] = React.useState<QLCCApartmentBuildingModel>();
    const [floor, setFloor] = React.useState<QLCCFloorModel>({} as QLCCFloorModel);
    const [block, setBlock] = React.useState<QLCCBlockModel>({} as QLCCBlockModel);

    // người dùng chọn
    const [floorStr, setFloorStr] = React.useState<string>("");
    const [apartment, setApartment] = React.useState<any>();
    const [userInfo, setUserInfo] = React.useState<UserInfoModel[]>([] as UserInfoModel[]);

    const [path, setPath] = React.useState<string[]>([]);

    const [recipients, setRecipients] = React.useState<string[]>([]);
    const [popupResident, setPopupResident] = React.useState<IPopupResident>(defaultPopupResident);

    const [userInfoGroupOptions, setUserInfoGroupOptions] = React.useState<any>([]);

    const [status, setStatus] = React.useState<IStatus[]>([]);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
    const [newContent, setNewContext] = React.useState<string>();


    React.useEffect(() => {
        setLoading(true);
        homePageCLient
            .userInfoWebGet({
                ... new UserInfoModel(),
                action_type: "GET",
                ma_cty: SessionLogin.ma_cty,
                apartment_building_id: apartment_building_id,
            } as UserInfoModel)
            .then(res => {
                setUserInfo(res);
            })

        homePageCLient.userInfoGroupGet({
            ...new UserInfoGroupModel(),
            action_type: 'GET',
            // ma_cty: SessionLogin.ma_cty,
            // apartment_building_id: apartment_building_id,
        } as UserInfoGroupModel)
            .then(res => {
                const find = res.filter(item => item.is_resident === false);
                setUserInfoGroupOptions(find)
                setLoading(false);
            });

        // const body = {
        //     ... new ParaObjTableList(),
        //     ma_cty: SessionLogin.ma_cty,
        //     tablelist: 'dm_trangthai_notify'
        // } as ParaObjTableList

        // httpPost<string>('api/Common/LoadFilterList', body)
        //     .then(result => {
        //         const lstStatus: any = JSON.parse(result.data);
        //         if (lstStatus) {
        //             setStatus(lstStatus);
        //         }
        //     });

    }, [])

    React.useEffect(() => {
        if (props.open) {
            if (props.action !== ACTIONS.ADD && props.itemSelected) {
                setLoading(true)
                //=====> tải thông tin 
                qLCCClient
                    ?.qLCCNotificationGet({
                        ...new QLCCNotificationsModel(),
                        id: props.itemSelected,
                        action_type: "GETBYID",
                        ma_cty: SessionLogin.ma_cty,
                        apartment_building_id: apartment_building_id
                    } as QLCCNotificationsModel)
                    .then((res) => {
                        if (res[0]) {
                            setNewContext(res[0].content);
                            setData(res[0]);
                        }
                        setLoading(false)
                        if (res[0] && res[0]?.file_id) {
                            setPath(res[0].file_id?.split(";"))
                        }
                        if (res[0] && res[0].apartment_building_id) {
                            setApartmentBuilding(apartmentList.find(p => p.apartment_building_id === res[0].apartment_building_id))
                        }

                        if (res[0] && res[0].recipient_id) {
                            const lst = res[0].recipient_id.split(';');
                            setRecipients(lst);
                        }
                        if (res[0] && res[0].apartment_id) {
                            setApartment(res[0].apartment_id);
                        }
                    })
                    .catch(e => console.log(e))
            } else {
                setData({
                    ...new QLCCNotificationsModel(),
                    status_id: '0',
                    ngay_ct: new Date(),
                    group_id: userInfoGroupOptions ? userInfoGroupOptions[0].group_code : undefined,
                    apartment_building_id: apartment_building_id
                } as QLCCNotificationsModel);
                setPath([]);
            }
        } else {
            setNewContext(undefined);
            setData({} as QLCCNotificationsModel);
        }
    }, [props.open])

    React.useEffect(() => {
        setLoading(true);
        qLCCClient
            ?.qLCCCategoriesActions({
                ... new QLCCCategoriesModel(),
                status_id: "1",
                action_type: "GET-NOTIFY",
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCCategoriesModel)
            .then((res) => {
                setCategory(res);
                setLoading(false);
            });
    }, [props.action, reload])





    const onloadBlocks = (_id: string = "") => {
        if (_id !== "") {
            qLCCClient
                .qlccBlockGet({
                    ... new QLCCBlockModel(),
                    apartment_building_id: _id,
                    // action_type: "GET"
                    ma_cty: SessionLogin.ma_cty,
                } as QLCCBlockModel)
                .then(res => {
                    setBlocks(res);
                    if (res.length > 0) {
                        setBlock(res[0]);
                        onloadFloors(res[0].id);
                        onloadApartments(res[0].id);
                    }
                })
        }
    }

    const onloadApartments = (_id: string = "") => {
        if (_id !== "") {
            qLCCClient
                .qLCCApartmentsGet({
                    ... new QLCCApartmentModel(),
                    block_id: _id,
                    ma_cty: SessionLogin.ma_cty,
                    apartment_building_id: apartment_building_id,
                    action_type: "GET",
                } as QLCCApartmentModel)
                .then(res => {
                    const arrApartmentList: any = res.filter(item => item.owner_resident_id !== null);
                    setApartmentList(arrApartmentList);
                });
        }
    }

    const onloadFloors = (_id: string = "") => {
        if (_id !== "") {
            qLCCClient
                .qLCCFloorGet({
                    ... new QLCCFloorModel(),
                    block_id: _id,
                    ma_cty: SessionLogin.ma_cty,
                    apartment_building_id: apartment_building_id,
                    action_type: 'GET'
                } as QLCCFloorModel)
                .then(res => {
                    setFloors(res);
                    if (res) {
                        setFloor(res[0]);
                    }
                });
        }
    }

    React.useEffect(() => {
        if (apartmentBuilding) {
            onloadBlocks(apartmentBuilding.id);
        }
    }, [apartmentBuilding]);





    const handleChangeInput = (e: any) => {
        if (props.action !== ACTIONS.VIEW) {
            const { name, value } = e.target;
            if (name.toLocaleUpperCase() === "BLOCKID") {
                const tempBlock = blocks.find((item: any) => item.id === value);
                setBlock(tempBlock as QLCCBlockModel);
                if (tempBlock) {
                    onloadFloors(tempBlock.id); // DS tầng trong tòa nhà
                    onloadApartments(tempBlock.id); // căn hộ theo tòa nhà
                }
            } else if (name.toLocaleUpperCase() === "APARTMENTBUILDINGID") {
                setApartmentBuilding(props.apartmentBuildings.find((item: any) => item.id === value));
            }
            else if (name.toLocaleUpperCase() === "FLOORID") {
                const tempFloor = floors.find(item => item.id === value);
                setFloor(tempFloor as QLCCFloorModel);
                if (tempFloor) {
                    const listApartmentID = apartmentList.filter(item => item.floor_id === tempFloor.id).map(apartment => apartment.id).join(";");
                    setApartment(listApartmentID);
                }
            }
            else {
                setData({
                    ...data,
                    [name]: value
                } as QLCCNotificationsModel);
            }
        }
    }

    const refValidation = React.useRef<HTMLInputElement>(null);

    const validate = (actionChild: string) => {
        /**
         * 1. tiêu đề thông báo
         * 2. Nội dung
         * 3. Người gửi
         */

        if (!data.title
            || (data.title && data.title.length === 0)) {

            Notification("warning", "Tiêu đề không được để trống")
            return false;
        }
        if (!data.group_id
            || (data.group_id && data.group_id.length === 0)) {

            Notification("warning", "Người theo dõi không được để trống")
            return false;
        }

        if (props.action === ACTIONS.ADD && actionChild !== ACTIONS.CREATE) {
            if (
                !data.apartment_id
                || (data.apartment_id && data.apartment_id.length === 0)
            ) {
                Notification("warning", "Chưa chọn người gửi đi")
                return false;
            }
        }
        return true
    }

    const Save = (data: QLCCNotificationsModel, userName: any[]) => {
        qLCCClient
            ?.qLCCNotificationUpd(
                {
                    ...data,
                    ma_cty: SessionLogin.ma_cty,
                    recipient_id: userName && userName.length > 0 ? userName.join(";") : data.recipient_id,
                    id: props.itemSelected,
                    is_login: true,
                    file_id: path.join(";"),
                    sender_id: SessionLogin.userName,
                    apartment_id: data.apartment_id,
                    action_type: "UPDATE",
                    // content: htmlString,
                    ngay_ct: moment(data.ngay_ct).utc(true).toDate(),
                    apartment_building_id: apartment_building_id,

                } as QLCCNotificationsModel
            )
            .then((res) => {
                setLoading(false);
                props.onEditSuccess(true);
                if (res.status === 0) {
                    props.onClose();
                }
                if (res && res.status) {
                    Notification(convert[res.status], res.message);
                }
            }).catch((err) => {
                Notification(convert[err.status!], err.message!);
            })
    }


    const Sent = (data: QLCCNotificationsModel, userName: any[]) => {
        const newModel = {
            ...new QLCCNotificationsModel(),
            ...data,
            ma_cty: SessionLogin.ma_cty,
            recipient_id: userName ? userName.join(";") : data.recipient_id,
            is_login: true,
            file_id: path.join(";"),
            sender_id: SessionLogin.userName,
            apartment_id: data.apartment_id,
            // content: htmlString,
        }
        qLCCClient.qLCCPushNotification({
            ...newModel,
            action_type: 'RECIPIENT',
            ma_cty: SessionLogin.ma_cty,
            apartment_building_id: apartment_building_id,
        } as QLCCNotificationsModel)
            .then(res => {
                Notification(convert[res.status!], res.message!);
                ChangeStatus(newModel);
                props.onEditSuccess(true);
            }).catch(err => {
                Notification("error", "Vui lòng kiểm tra lại.");
            })
    }

    const SentAndSave = (data: QLCCNotificationsModel, userName: any[]) => {

        const newModel = {
            ...new QLCCNotificationsModel(),
            ...data,
            ma_cty: SessionLogin.ma_cty,
            recipient_id: userName ? userName.join(";") : data.recipient_id,
            is_login: true,
            file_id: path.join(";"),
            sender_id: SessionLogin.userName,
            apartment_id: data.apartment_id,
            // content: htmlString,
            ngay_ct: moment(data.ngay_ct).utc(true).toDate(),
        } as QLCCNotificationsModel;

        qLCCClient
            ?.qLCCNotificationIns({
                ...newModel,
                action_type: "INSERT",
                ngay_ct: moment(data.ngay_ct).utc(true).toDate(),
                ma_cty: SessionLogin.ma_cty,
                apartment_building_id: apartment_building_id,
            } as QLCCNotificationsModel)
            .then((res) => {
                setLoading(false);
                if (res.status === 0) {
                    const ref_id = res.ref_id;
                    if (ref_id) {
                        qLCCClient.qLCCPushNotification({
                            ...newModel,
                            id: ref_id,
                            action_type: 'RECIPIENT',
                            ma_cty: SessionLogin.ma_cty,
                        } as QLCCNotificationsModel)
                            .then(rel => {
                                Notification(convert[rel.status!], rel.message);
                                ChangeStatus({ ...newModel, id: ref_id, });
                                props.onEditSuccess(true);
                                props.onClose();
                            }).catch(err => {
                                Notification("error", "Vui lòng kiểm tra lại.");
                            })
                    }
                } else {
                    Notification(convert[res.status!], res.message);
                }
            }).catch((err) => {
                Notification("error", "Vui lòng kiểm tra lại.");
            })
    }

    const Create = (data: QLCCNotificationsModel, userName: any[]) => {

        const newModel = {
            ...new QLCCNotificationsModel(),
            ...data,
            ma_cty: SessionLogin.ma_cty,
            recipient_id: userName ? userName.join(";") : data.recipient_id,
            is_login: true,
            file_id: path.join(";"),
            sender_id: SessionLogin.userName,
            apartment_id: data.apartment_id,
            // content: htmlString,
        }
        qLCCClient
            ?.qLCCNotificationIns({
                ...newModel,
                action_type: "INSERT",
                ngay_ct: moment(data.ngay_ct).utc(true).toDate(),
                ma_cty: SessionLogin.ma_cty,
                apartment_building_id: apartment_building_id,
            } as QLCCNotificationsModel)
            .then((res) => {
                setLoading(false);
                if (res.status === 0) {
                    Notification(convert[res.status!], res.message);
                    props.onClose();
                    props.onEditSuccess(true);
                } else {
                    Notification("error", "Vui lòng kiểm tra lại.");
                }
            }).catch((err) => {
                Notification("error", "Vui lòng kiểm tra lại.");
            })
    }

    const ChangeStatus = (newData: any) => {
        qLCCClient
            ?.qLCCNotificationUpd(
                {
                    ...newData,
                    action_type: "STATUS_PUSH",
                    ma_cty: SessionLogin.ma_cty,
                    apartment_building_id: apartment_building_id,
                } as QLCCNotificationsModel
            )
            .then((res) => {
                console.log(res);
                props.onClose();
                props.onEditSuccess(true);
            })
            .catch((err: any) => {
                Notification("error", "Vui lòng kiểm tra lại.");
            })
    }

    const handleSubmit = (actions: string) => {

        const userName = [];
        if (data.apartment_id) {
            const lstA = data.apartment_id.split(';') || [];
            userName.push([...userInfo.filter((item: any) => {
                return lstA.includes(item.apartment_id);
            }).map(item => item.username)]);
        }

        if (!validate(actions)) {
            return;
        }


        const newData = {
            ...new QLCCNotificationsModel(),
            ...data,
            ma_cty: SessionLogin.ma_cty,
            category_id: "QLCCNotifi",
            module_id: "QLCCNotifi",
            module_name: "Hóa đơn",
            feature_id: "Notifi",
            feature_name: "Notifi",
            link: '/admin/notification-list',
            apartment_building_id: apartment_building_id,
            content: newContent
        } as QLCCNotificationsModel

        if (actions === ACTIONS.ADD) {
            setLoading(true);
            SentAndSave(newData, userName);
        }
        else if (actions === ACTIONS.CREATE) {
            Create(newData, userName)
        }
        else if (actions === ACTIONS.SAVE) {
            setLoading(true);
            Save(newData, userName);
        } else if (actions === ACTIONS.SENDMAIL) {
            setLoading(true);
            Sent(newData, userName);
        }
        setLoading(false);
    }

    const handleChangeMultipleInput = (e: any) => {
        if (props.action !== ACTIONS.VIEW) {
            const { name, value } = e.target;
            if (name === 'apartment_id') {
                const valueSplit = value.map((value: QLCCApartmentModel) => value.id).join(";")
                setApartment(valueSplit);
            } else if (name === 'floor_id') {

                const selected = value.length > 1 ? value[value.length - 1] : value[0];


                const valueSplit = floorStr.split(";");



                if (selected && !valueSplit.includes(selected)) {
                    valueSplit.push(selected)
                } else if (valueSplit.includes(selected)) {
                    valueSplit.splice(valueSplit.indexOf(value), 1);
                }

                setFloorStr(valueSplit.join(";"));

                const apartmentObj: QLCCApartmentModel[] = apartmentList.filter(item => {
                    return valueSplit.includes(item?.floor_id!);
                });

                const list = apartmentObj.map(item => item.id);

                setApartment(list.join(';'))
            }
        }
    }

    const handleListSent = () => {
        setPopupResident({
            ...popupResident,
            open: true,
            listResident: data.recipient_id ? data.recipient_id.split(';') : [],
            action: props.action
        })
    }


    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        setData({
            ...data,
            [name]: new Date(value)
        } as QLCCNotificationsModel);
    }

    return (
        <div>
            <Dialog
                id="sysMenu5"
                open={props.open}
                onClose={props.onClose}
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
                TransitionComponent={Transition}
                maxWidth="md"
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            {menu?.bar +
                                " " +
                                `${props.action === ACTIONS.VIEW
                                    ? "[Chi tiết]"
                                    : props.action === "EDIT" || _id
                                        ? "[Cập nhật]"
                                        : "[Thêm mới]"
                                }`}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent sx={{
                    backgroundColor: '#e2e8f0'
                }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={9} >
                            <Paper elevation={3} className="p-3" >
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 16, color: '#333', fontWeight: '500' }}>Tiêu đề</InputLabel>
                                    <TextField
                                        ref={refValidation}
                                        className="cc-input"
                                        sx={{ width: "100%" }}
                                        name="title"
                                        onChange={handleChangeInput}
                                        value={data?.title}
                                        disabled={props.action === ACTIONS.VIEW}
                                    // placeholder={"Tiêu đề thông báo"}
                                    />
                                </Box>
                                {/* Link demo */}
                                <Box style={{ width: "100%", margin: "15px 0px" }}>
                                    <InputLabel sx={{ fontSize: 16, color: '#333', fontWeight: '500' }}>Nội dung</InputLabel>
                                    {
                                        useMemo(() => {
                                            return <>
                                                <RichTextEditor
                                                    value={newContent}
                                                    onChange={setNewContext}
                                                    height={650}
                                                    readOnly={props.action === ACTIONS.VIEW}
                                                />
                                            </>
                                        }, [data])
                                    }

                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={3} rowSpacing={4}>
                            <Paper elevation={3} className="p-3" >
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "auto",
                                    }}
                                >
                                    <InputLabel className='text-center' sx={{ fontSize: 16, color: '#333', fontWeight: '500', margin: "5px 0" }}>Đăng thông báo</InputLabel>
                                    <Grid item xs={12} style={{ margin: "10px 0" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333', fontWeight: '600' }}>Ngày thông báo</InputLabel>
                                        <CTXDate
                                            className="cc-input w-100"
                                            value={data.ngay_ct}
                                            name={'ngay_ct'}
                                            onChange={handleChangeDate}
                                            disabled={props.action === ACTIONS.VIEW}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ margin: "10px 0" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333', fontWeight: '600' }}>Người theo dõi</InputLabel>
                                        {/* <DHSAutoCompleteWithDataSource
                                            id="vehicle-type-select"
                                            className="cc-input"
                                            dataSource={userInfoGroupOptions}
                                            displayMember="group_name"
                                            valueMember="group_code"
                                            value={data?.group_id}
                                            onValueChanged={(e, newValue: any) => {
                                                handleChangeInput({
                                                    target: {
                                                        name: "group_id",
                                                        value: newValue?.group_code ? newValue.group_code : null,
                                                    },
                                                });
                                            }}
                                            disabled={props.action === ACTIONS.VIEW}
                                        /> */}
                                        <DHSTagBox
                                            displayExpr="group_name"
                                            valueExpr="group_code"
                                            dataSource={userInfoGroupOptions}
                                            className="cc-input"
                                            value={data?.group_id?.split(';')}
                                            onValueChange={e => {
                                                const newValue = e.join(';')
                                                handleChangeInput({
                                                    target: {
                                                        name: "group_id",
                                                        value: newValue ? newValue : null,
                                                    },
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ margin: "10px 0" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333', fontWeight: '600' }}>Người gửi</InputLabel>
                                        <TextField
                                            className="cc-input"
                                            sx={{ width: "100%" }}
                                            value={SessionLogin.userName}
                                            disabled={props.action === ACTIONS.VIEW}
                                        />
                                    </Grid>
                                    <hr />
                                    <Grid item xs={12} style={{ margin: "10px 0" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333', fontWeight: '600' }}>Gửi đến</InputLabel>
                                        <Button className="w-100" variant="outlined" color="info" onClick={handleListSent}>{props.action === ACTIONS.VIEW ? "Xem" : ""} danh sách gửi</Button>
                                    </Grid>
                                </Box>
                            </Paper>

                            <Paper elevation={3} className="p-3 mt-3" >
                                <Box >
                                    <Box>
                                        <InputLabel
                                            sx={{ fontSize: 14, fontWeight: "600", color: '#333', }}
                                        >
                                            Tập tin
                                        </InputLabel>
                                        {
                                            React.useMemo(() => {
                                                return (
                                                    <UploadFile
                                                        setPath={function (path_: []): void {
                                                            setPath([...path_]);
                                                        }}
                                                        path={path}
                                                        action={props.action}
                                                    />
                                                )
                                            }, [path])
                                        }
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    <NotificationPopupHouse
                        open={popupResident.open}
                        onClose={() => {
                            setPopupResident(defaultPopupResident);
                        }}
                        onSubmit={(value: string) => {
                            setApartment(value);
                        }}
                        action={props.action}
                        setData={(newData: any) => {
                            setData({
                                ...data,
                                ...newData
                            })
                        }}
                        data={data}
                    />
                </DialogContent>
                <DialogActions >
                    {
                        props.action === ACTIONS.EDIT && (
                            <Button variant="contained" color="success" onClick={() => handleSubmit(ACTIONS.SAVE)}>Lưu</Button>
                        )
                    }
                    {
                        props.action === ACTIONS.ADD && (
                            <Button variant="contained" color="success" onClick={() => handleSubmit(ACTIONS.CREATE)}>Lưu</Button>
                        )
                    }
                    {/* Ẩn đi theo ý kiến ngày 30/12/2023 */}
                    {/* {
                        props.action !== ACTIONS.VIEW ? (
                            <Button variant="contained" onClick={() => handleSubmit(ACTIONS.ADD)}>Gửi thông báo</Button>
                        ) : (
                            <>
                                <Button variant="contained" onClick={() => handleSubmit(ACTIONS.SENDMAIL)}>Gửi thông báo</Button>
                            </>
                        )
                    } */}
                    {/* Hiển thị theo ý kiến ngày 30/12/2023 */}
                    {
                        props.action === ACTIONS.VIEW &&
                        <Button variant="contained" onClick={() => handleSubmit(ACTIONS.SENDMAIL)}>Gửi thông báo</Button>
                    }
                    <Button
                        variant="outlined"
                        onClick={props.onClose}
                    >
                        Đóng
                    </Button>
                </DialogActions>
                <BlockUI blocking={isLoading} title={""}></BlockUI>
            </Dialog>
        </div>
    );
};

export default NotificationsActions;
