import _ from "lodash";
import { parseStringToBoolean } from "./boolean.util";
import { TransDateTime } from "./date.util";

class ObjectUtility {

  public static removePropertiesWhenNull = <T extends object = any>(objects?: T): T | undefined => {
    if (objects) {
      if (Array.isArray(objects)) {
        return objects.filter(object =>
          Object.keys(object).some(key => object[key] !== null && object[key] !== undefined)
        ) as T;
      } else {
        const newObj: Record<string, any> = {};

        for (const key in objects) {
          if (objects[key] !== null && objects[key] !== undefined) {
            newObj[key] = objects[key];
          }
        }
        return newObj as T;
      }
    }
    else {
      return objects;

    }
  }

  public static checkRequiredField = <T extends object = Record<string, any>>(object: T | undefined, fields: (keyof T | string)[], isNumber?: boolean): boolean => {
    for (const field of fields) {
      if (object && object.hasOwnProperty(field)) {
        // if (!object[field as keyof T]) {
        //   if (typeof object[field as keyof T] === "number" && object[field as keyof T] === 0) continue;
        //   return false;
        // }
        // continue;
        if (this.isNullOrUndefinedOrEmpty(object[field as keyof T])) {
          if (isNumber) object[field as keyof T] = 0 as any;
          return false;
        }
      }
      else {
        return false;
      }

    }
    return true;
  }
  public static fillValue = <T extends object = Record<string, any>>(object: T | undefined): T | undefined => {
    for (const field in object) {
      if (object && object.hasOwnProperty(field)) {
        // if (!object[field as keyof T]) {
        //   if (typeof object[field as keyof T] === "number" && object[field as keyof T] === 0) continue;
        //   return false;
        // }
        // continue;
        if (this.isNullOrUndefinedOrEmpty(object[field])) {
          this.setDefaultDataField(object, field, typeof object[field as keyof T]);
        }
      }
    }
    return object;
  }

  public static setValuesToUndefined = (obj: Record<string, any>): void => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = undefined;
      }
    }
  }
  public static setDefaultDataField = (result: Record<string, any>, data_field: string, data_type: string, default_value?: any): void => {

    switch (data_type) {

      case "number": {
        result[data_field!] = Number.parseInt(default_value ?? "0");
        break;
      }
      case "boolean": {
        result[data_field!] = parseStringToBoolean(default_value ?? "");
        break;
      }

      case "string": {
        result[data_field!] = typeof default_value === "string" ? default_value.split(/""|''/).join('') : "";
        break;
      }

      case "date": {
        result[data_field!] = default_value ? new Date(default_value) : null;
        break;
      }
      default: {
        result[data_field] = null;
        break;
      }

    }
  }
  public static setDefaultDataFieldIgnoreNull = (result: Record<string, any>, data_field: string, data_type: string, default_value?: any): void => {
    if (!this.isNullOrUndefinedOrEmpty(default_value)) {
      this.setDefaultDataField(result, data_field, data_type, default_value);
    }
  }

  public static isNullOrUndefinedOrEmpty = (value: any): boolean => {
    // Kiểm tra xem giá trị có là null hoặc undefined
    if (_.isNull(value) || _.isUndefined(value)) {
      return true;
    }

    // Kiểm tra xem giá trị có là một chuỗi rỗng (empty string)
    if (_.isString(value) && _.isEmpty(value)) {
      return true;
    }

    // Kiểm tra xem giá trị có là một mảng rỗng (empty array)
    if (_.isArray(value) && _.isEmpty(value)) {
      return true;
    }

    // Kiểm tra xem giá trị có là một đối tượng Date và không có giá trị
    if (_.isDate(value) && isNaN(value.getTime())) {
      return true;
    }

    // Kiểm tra xem giá trị có là một đối tượng rỗng (empty object)
    if (_.isObject(value) && _.isEmpty(value) && !(_.isDate(value) || _.isBoolean(value) || _.isFunction(value))) {
      return true;
    }



    return false;
  }


  public static combineWithOverwrite = <T extends Record<string, any>, U extends Record<string, any>>(obj1: T, obj2?: U): T => {
    const combined: Record<any, any> = { ...obj1 };
    for (const key in obj2) {
      if (Object.prototype.hasOwnProperty.call(obj2, key) && Object.prototype.hasOwnProperty.call(obj1, key)) {
        combined[key as keyof T] = obj2[key as keyof U];
      }
    }
    return combined;
  }


  public static convertUTCDate = <T extends object = any>(obj?: T): T => {
    if (obj) {
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) && (obj as any)[key] instanceof Date) {
          (obj as any)[key] = TransDateTime(obj[key] as Date); // Chuyển đổi ngày thàng thành chuỗi ISO
          // Hoặc bạn có thể sử dụng các phương thức khác như .toLocaleDateString() để định dạng ngày thàng theo yêu cầu của bạn
        }
      }

      return obj;
    } else {
      return {} as T
    }

  }

  public static convertArrayToDict = <T extends object = any>(array: T[], key: string): Record<string, T> => {
    return [...array].reduce((acc, cur) => {
      acc[cur[key as keyof T] as string] = cur;
      return acc;
    }, {} as Record<string, T>)
  }


  public static areAllPropertiesFilled = <T extends object = any>(object: T, exceptions: Array<keyof T> = []): boolean => {
    for (const key in object) {
      if (object.hasOwnProperty(key) && !exceptions.includes(key)) {
        const value = object[key];
        if (this.isNullOrUndefinedOrEmpty(value)) {
          return false;
        }
      }
    }
    return true;
  }
}

export default ObjectUtility;