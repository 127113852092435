import { Card } from "@mui/material";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { components } from "../../../common/ComponentsConfig/components";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import notification from "../../../common/notification/notification";
import DHSDate from "../../../components/DHS/DHS-Date/DHSDateComponent";
import DHSToolbar from "../../../components/DHS/DHS-Toolbar/DHSToolbar";
import Upload from "../../../components/DHS/DHS_Upload/Upload";
import DHSGridView from "../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import DrawerActivity from "../../../components/layouts/DrawerActivity/DrawerActivity";
import { getRole, getRoles } from "../../../redux/actions/RoleActions";
import { AppSession } from "../../shared/app-session/app-session";
import {
  CRMClient,
  CrmCustomerInfomationDetail,
  HomeClient,
  RoleRightWebModel,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import IconTask from "../../../Asset/image/icons8-task-64.png";
import IconComingSoon from "../../../Asset/image/icons8-coming-soon-64.png";
import IconAttendance from "../../../Asset/image/icons8-immigration-64.png";
import CustomerInfomation from "./Childs/CustomerInfomation";

let appSession: AppSession;
const menuRoleName = "CRM";
const PortalSaleGrid = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  // const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
  const [customerDetail, setCusomterDetail] =
    React.useState<CrmCustomerInfomationDetail>({
      ...new CrmCustomerInfomationDetail(),
      time_add: new Date(),
      account_id: SessionLogin.userName,
    } as CrmCustomerInfomationDetail);
  const { path, url } = useRouteMatch();
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const crmClient = new CRMClient(appSession, BASE_URL_API);
  const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
  const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
  const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
  const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
  const [isShowSort, setShowSort] = React.useState<boolean>(false);
  const [isShowSelect, setShowSelect] = React.useState<boolean>(false);
  const [back, setBack] = React.useState<number>(-1);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [customerDetailView, setCusomterDetailView] = React.useState<
    CrmCustomerInfomationDetail[]
  >([]);
  const [workDay, setWorkDay] = React.useState<number>(0);
  const [leaveDay, setLeaveDay] = React.useState<number>(0);
  const [resources, setResources] = React.useState<any[]>([]);
  const customMenu = [
    {
      menuName: isShowSelect ? "Ẩn Select Box" : "Hiện Select Box",
      onClick: () => {
        setShowSelect(!isShowSelect);
      },
    },
    {
      menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
      onClick: () => {
        setShowFilter(!isShowFilter);
      },
    },
    {
      menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
      onClick: () => {
        setShowSearch(!isShowSearch);
      },
    },
    {
      menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
      onClick: () => {
        setShowGroup(!isShowGroup);
      },
    },
    {
      menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
      onClick: () => {
        setShowSort(!isShowSort);
      },
    },
  ];

  React.useEffect(() => {
    homeClient
      ?.roleRightWebGetAll({
        ...new RoleRightWebModel(),
        rolename: menuRoleName,
        menuid: menu?.menuid,
      } as RoleRightWebModel)
      .then((res) => {
        dispatch(getRoles(res));
        setRoles(res);
      });
  }, []);
  React.useEffect(() => {
    try {
      if (customerDetail.id_master) {
        let tempInfo = new CrmCustomerInfomationDetail();
        tempInfo.id_master = customerDetail.id_master;
        crmClient.customerInfomationDetailGet(tempInfo).then((response) => {
          setCusomterDetailView(response);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [customerDetail.id_master, trigger]);

  const handleClickAction = (action: RoleRightWebModel) => {
    if (action.class_name?.toLocaleUpperCase() === "DELETE") {
      if (window.confirm("Bạn chắc chắn muốn thực hiện hành động này chứ")) {
        switch (action.component) {
          case "CustomerInfomation":
            crmClient
              .crmCustomerInfomationDel(customerDetail.id_master)
              .then((res) => {
                result(res[0].ret, res[0].message);
              });
            break;
          case "CustomerStatus":
            crmClient
              .crmCustomerStatusDel(customerDetail.id_master)
              .then((res) => {
                result(res[0].ret, res[0].message);
              });
            break;
          case "ContactPersonPosition":
            crmClient
              .crmContactPersonPositionDel(customerDetail.id_master)
              .then((res) => {
                result(res[0].ret, res[0].message);
              });
            break;
          case "Major":
            crmClient.crmMajorDel(customerDetail.id_master).then((res) => {
              result(res[0].ret, res[0].message);
            });
            break;
          default:
            result(0, "Có lỗi xảy ra");
        }
      }
    } else dispatch(getRole(action));
  };
  const result = (data: number | undefined, message: string | undefined) => {
    if (data === 0) {
      setBack((item) => (item -= 1));
      notification("success", message);
      history.go(0);
    } else notification("error", message);
  };
  const handleChangeInfoCalendar = (e: any) => {
    setCusomterDetail({
      ...customerDetail,
      time_add: e,
    } as CrmCustomerInfomationDetail);
  };
  const handleChangeInfoInput = (e: any) => {
    const { name, value } = e.target;
    setCusomterDetail({
      ...customerDetail,
      [name]: value,
    } as CrmCustomerInfomationDetail);
  };
  const getUrl = (url: string) => {
    setCusomterDetail({
      ...customerDetail,
      attach_files: url,
    } as CrmCustomerInfomationDetail);
  };
  const handleSubmit = () => {
    if (
      customerDetail.account_id &&
      customerDetail.notes &&
      customerDetail.time_add
    ) {
      crmClient
        .customerInfomationDetailIns(
          customerDetail as CrmCustomerInfomationDetail
        )
        .then((response) => {
          if (response[0].ret === 0) {
            setTrigger(!trigger);
            alert("Thêm thành công");
          } else alert("Thêm thất bại");
        });
    } else {
      alert("Vui lòng nhập đủ thông tin");
    }
  };
  return (
    <div className="container-fluid p-1">
      <Switch>
        <Route path={path} exact>
          <div
            className="d-flex title-color"
            style={{
              height: 30,
              color: "rgb(0 78 255 / 88%)",
              marginTop: 15,
              marginBottom: 15,
              fontSize: 22,
              fontWeight: 550,
            }}
          >
            <i
              className="bi bi-arrow-left"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.go(back);
              }}
            ></i>
            &nbsp;&nbsp;
            {menu?.bar}
          </div>{" "}
          <DHSToolbar
            id={customerDetail.id_master}
            list={
              roles
                ? roles.filter(
                    (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
                  )
                : []
            }
            onClickAction={handleClickAction}
            customMenu={customMenu}
          />
          {menu?.component === "crmCustomerInfomation" && (
            <div className="row card-container">
              <div className="col-md-3 card-body-timesheet">
                <Card className="card-item">
                  <div>Tổng công hưởng lương</div>
                  <div className="d-flex align-items-center">
                    <div className="col-9 value">{workDay}</div>
                    <div className="col-3">
                      <img src={IconTask} alt="WorkDay" />
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-md-3 card-body-timesheet">
                <Card className="card-item">
                  <div>Đang phát triển</div>
                  <div className="d-flex align-items-center">
                    <div className="col-9 value"></div>
                    <div className="col-3">
                      <img src={IconComingSoon} alt="Coming Soon" />
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-md-3 card-body-timesheet">
                <Card className="card-item">
                  <div>Đang phát triển</div>
                  <div className="d-flex align-items-center">
                    <div className="col-9 value"></div>
                    <div className="col-3">
                      <img src={IconComingSoon} alt="Coming Soon" />
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-md-3 card-body-timesheet">
                <Card className="card-item">
                  <div>Số ngày nghỉ phép</div>
                  <div className="d-flex align-items-center">
                    <div className="col-9 value">{leaveDay}</div>
                    <div className="col-3">
                      <img src={IconAttendance} alt="On leave" />
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          )}
          <div className="d-flex row">
            <div
              className={
                menu?.component === "crmCustomerInfomation"
                  ? "col-lg-9"
                  : "col-lg-12"
              }
            >
              {/* <DHSGridView
                onSelectRow={(row) => {
                  setCusomterDetail({
                    ...customerDetail,
                    id_master: row,
                  } as CrmCustomerInfomationDetail);
                }}
                // onListSelectRow={handleListOnselectRow}
                itemClicked={function (): void {
                  throw new Error("Function not implemented.");
                }}
                table_name={
                  menu?.component
                    ? menu.component
                    : menu?.menuid
                    ? menu.menuid
                    : ""
                }
                show_form_list={true}
                showFilterPanel={isShowFilter}
                showSortPanel={isShowSort}
                showSearchPanel={isShowSearch}
                showGroupPanel={isShowGroup}
                showSelectBox={isShowSelect}
              /> */}
            </div>
            {/*Activity Layout */}
            <div
              className={
                menu?.component === "crmCustomerInfomation"
                  ? "col-lg-3 container activity-container"
                  : "d-none"
              }
              style={{ overflow: "hidden" }}
            >
              <div className="form-group" style={{ fontSize: 25 }}>
                <h3 style={{ textAlign: "center" }}>Hoạt động</h3>
              </div>
              <div className="form-group">
                <DHSDate
                  label="Ngày tương tác"
                  className="flex-grow-1 form-control"
                  type="text"
                  name="time_add"
                  onChange={handleChangeInfoCalendar}
                  value={customerDetail.time_add}
                  disabled={!customerDetail.id_master ? true : false}
                  // id={data.id ? data.id : ""}
                />
              </div>
              <div className="form-group">
                <label className="label-input">Ghi chú</label>
                <textarea
                  className="flex-grow-1 form-control"
                  name="notes"
                  onChange={handleChangeInfoInput}
                  value={customerDetail.notes}
                  disabled={!customerDetail.id_master ? true : false}
                  title={customerDetail.notes}
                />
              </div>
              <div className="form-group">
                <Upload
                  fileExtension="/*"
                  callbackURL={getUrl}
                  haveIcon={true}
                />
              </div>
              <div className="form-group d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={!customerDetail.id_master ? true : false}
                >
                  Cập nhật
                </button>
              </div>

              <ul className="container">
                {(customerDetailView as CrmCustomerInfomationDetail[]).map(
                  (detail) => {
                    return (
                      <li className="border-bottom p-1">
                        <p>
                          <b>{`${detail.account_id} `}</b>
                          {`${detail.notes} vào `}
                          <i>{`${detail.time_add_f}`}</i>
                        </p>
                        {detail.attach_files && (
                          <a
                            href={detail.attach_files}
                            className="d-flex"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="bi bi-paperclip"></i>
                            {
                              detail.attach_files.split("/")[
                                detail.attach_files.split("/").length - 1
                              ]
                            }
                          </a>
                        )}
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
          </div>
        </Route>
        {roles &&
          roles.map((role, index) => {
            return (
              role.link &&
              role.link.length > 1 &&
              role.component && (
                <Route
                  key={index}
                  path={role.is_parameter ? role.link + ":_id" : role.link}
                  component={components[role.component.toLocaleUpperCase()]}
                  exact
                ></Route>
              )
            );
          })}
      </Switch>
    </div>
  );
};

export default PortalSaleGrid;
