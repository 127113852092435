import SelectBox from 'devextreme-react/select-box';
import TagBox from 'devextreme-react/tag-box';
import { NativeEventInfo } from 'devextreme/events';
import { SelectBoxInstance } from 'devextreme/ui/select_box';
import React, { useEffect } from 'react'


interface IProps {
    dataSource: any[];
    displayExpr: string;
    searchExpr: string;
    valueExpr: string;
    onValueChanged?: (value: any) => void;
    onValueChange?: (value: any) => void;
    name: string;
    value: string[] | undefined;
    class?: string | undefined;
    inputAttr?: ["Minumum Search length", "Search Timeout", "Search Expression", "Search Mode", "Product", "Simple Product"]
    placeholder?: string | undefined;
    showDataBeforeSearch?: boolean;
    minSearchLength?: number;
    searchTimeout?: number;
    searchEnabled?: boolean;
    noDataText?: string
    defaultValue?: string[] | undefined;
    applyValueMode?: 'useButtons' | 'instantly';
    showSelectionControls?: boolean;
    disabled?: boolean;
    multiline?: boolean | undefined;
    maxLength?: number | undefined;
    hideSelectedItems?: boolean | undefined;
}

const DHSTagBox = (props: IProps) => {

    const handleChangeInput = (e: any) => {
        const { value } = e;
        console.log(value)
        if (props.onValueChanged) {
            props.onValueChanged({
                target: {
                    name: props.name,
                    value: value ? value : null
                },
            })
        } else if (props.onValueChange) {
            props.onValueChange({
                target: {
                    name: props.name,
                    value: e ? e : null
                },
            })
        }
    }

    return (
        <TagBox
            displayExpr={props.displayExpr}
            searchEnabled
            searchExpr={props.searchExpr ? props.searchExpr : undefined}
            valueExpr={props.valueExpr}
            defaultValue={props.defaultValue ? props.defaultValue : undefined}
            value={props.value ? props.value : undefined}
            items={props.dataSource}
            showSelectionControls={props.showSelectionControls ? props.showSelectionControls : true}
            inputAttr={{ 'aria-label': props.inputAttr ? props.inputAttr : "Product" }}
            applyValueMode={props.applyValueMode ? props.applyValueMode : "useButtons"}
            onValueChanged={(e: any) => props.onValueChanged ? handleChangeInput(e) : undefined}
            onValueChange={(e: any) => props.onValueChange ? handleChangeInput(e) : undefined}
            disabled={props.disabled ? props.disabled : false}
            placeholder={props.placeholder ? props.placeholder : ""}
            multiline={props.multiline ? props.multiline : false}
            maxLength={props.maxLength ? props.maxLength : 800}
            hideSelectedItems={props.hideSelectedItems ? props.hideSelectedItems : undefined}
    
        />
    )
}

export default DHSTagBox