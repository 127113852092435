import * as React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AppSession } from "../../shared/app-session/app-session";
import {
  ChoThueXeClient,
  CtxBaoCaoDoanhSoModel,
  CtxPhieuThueXeModel,
  CtxThongTinXeModel,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";

interface IData {
  name: string;
  [key: string]: any;
}

let appSession: AppSession;
const colors = [
  "#8834d8",
  "#7884d8",
  "#88f4d8",
  "#5284d8",
  "#8802d8",
  "#881df8",
];
const BaoCaoDoanhSo = () => {
  const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);
  const [data, setData] = React.useState<IData[]>();

  React.useEffect(() => {
    ctxClient.ctxBaoCaoDoanhSo(new CtxBaoCaoDoanhSoModel()).then((response) => {
      let tempArr: IData[] = [];
      response.forEach((statistic) => {
        tempArr.push({
          name: statistic.from_date
            ? `${statistic.from_date?.getDate()}/${statistic.from_date?.getDate()}`
            : "Unknown",
          "Số lượt": statistic.sl,
          "Tổng tiền thuê": statistic.value,
        });
      });
      setData([...tempArr]);
    });
  }, []);

  const getDaysInMonth = (month: number, year: number) => {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
        <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
        <Tooltip />
        <Legend />
        <Bar yAxisId="left" dataKey="Số lượt" fill="#8884d8" />
        <Bar yAxisId="right" dataKey="Tổng tiền thuê" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  );
};
export default BaoCaoDoanhSo;
