import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer, Notification } from '../../../components/layouts';
import { getRoles } from '../../../redux/actions/RoleActions';
import { AppSession } from '../../shared/app-session/app-session';
import {
    HomeClient,
    RoleRightWebModel,
    QLCCClient,
    QLCCDomesticWaterCycleModel,
    QLCCApartmentBuildingModel,
    SysActionType
} from '../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../shared/service-proxies/config';

import { useHistory, useLocation } from 'react-router-dom';
import BlockUI from '../../../components/Block-UI/Block-ui';
import moment from 'moment';
import { Grid, } from '@mui/material';
import DomesticWaterCycleAction from '../actions/domestic-water-action/DomesticWaterCycleAction';

import dxDataGrid, { SelectionChangedInfo, } from "devextreme/ui/data_grid";
import { EventInfo } from "devextreme/events";
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DataGrid from 'devextreme-react/data-grid';
import DHSBreadcrumb from '../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import DHSToolbarRole from '../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import { ACTIONS } from '../../../common/enums/DHSToolbarRoleAction';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../common/Interfaces/StatusMessage';
import { getIndexRowSelected, getKeyRowSelected } from '../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';
import DHSSelectBox from '../../../components/DHS/DHS_SelectBox';
import { HintCode } from '../../../common/enums/DHSInputDetail';
import { CALL_API_LOAD_APARTMENT_BUILDING_LIST } from '../apartment-building/apartment-building-list';

const convert = ["success", "error", "warning"];
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

interface IPayment {
    paymentAmount: string | undefined,
    paymentType: string | undefined
}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

const roleMenu: string = 'CC';
const DomesticWaterCycle: React.FC<{}> = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qlccClient = new QLCCClient(appSession, BASE_URL_API);
    const [dataTable, setDataTable] = React.useState<QLCCDomesticWaterCycleModel[]>([] as QLCCDomesticWaterCycleModel[]);
    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const [apartmentBuildings, setApartmentBuildings] = React.useState<QLCCApartmentBuildingModel[]>([] as QLCCApartmentBuildingModel[]);
    // const [apartmentBuilding, setApartmentBuilding] = React.useState<QLCCApartmentBuildingModel>({} as QLCCApartmentBuildingModel);

    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
    const [isShowSort, setShowSort] = React.useState<boolean>(false);
    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);

    const customMenu = [
        {
            menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
            onClick: () => {
                setShowSearch(!isShowSearch);
            },
        },
        {
            menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
            onClick: () => {
                setShowGroup(!isShowGroup);
            },
        },
        {
            menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
            onClick: () => {
                setShowSort(!isShowSort);
            },
        },
        {
            menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
            onClick: () => {
                setShowFilter(!isShowFilter);
            },
        },
    ];

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
    const [allowEdit, setAllowEdit] = useState<boolean>(true);
    const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const onLoadApartmentBuilding = () => {
        CALL_API_LOAD_APARTMENT_BUILDING_LIST().then((res) => {
            setApartmentBuildings(res);
            if (res) {
                // setApartmentBuilding(res[0]);
            }
            setLoading(false);
        });
    }

    const onloadCycle = () => {
        qlccClient
            .qLCCDomesticWaterCycleGet({
                ... new QLCCDomesticWaterCycleModel(),
                apartment_building_id: apartment_building_id,
                action_type: "GET",
            } as QLCCDomesticWaterCycleModel)
            .then(data => {
                const item: any = [];
                data.map(e => {
                    item.push({
                        ...e,
                        apartment_building_name: apartmentBuildings.find(apartmentBuilding => apartmentBuilding.id === e.apartment_building_id)?.name,
                        create_date: moment(e.create_date).format("DD/MM/YYYY")
                    })
                })
                setDataTable(item);
                setLoading(false);
            })
    }

    useEffect(() => {
        onLoadApartmentBuilding();
    }, [])

    useEffect(() => {
        onloadCycle();
    }, [apartment_building_id])

    const handleChangeInput = (e: any) => {
        const apartmentBuildingTemp = apartmentBuildings.find(item => item.id === e.target.value);
        if (apartmentBuildingTemp) {
            // setApartmentBuilding(apartmentBuildingTemp);
            // onLoad(apartmentBuildingTemp.id);
        }
    }


    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    }

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys: any) => {
                console.log(keys);

                const index = e.component.getRowIndexByKey(keys[0]);


                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
                setIdItemSelected(keys[0].id);
            });
        }
    };


    const handleClickAction = async (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code?.toLocaleUpperCase()) {
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const key: any = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            if (window.confirm("Are you sure you want to delete?")) {
                                qlccClient
                                    ?.qlccDomesticWaterCycleAction({
                                        ... new QLCCDomesticWaterCycleModel(),
                                        id: idItemSelected,
                                        action_type: "DELETE",
                                        apartment_building_id: apartment_building_id,

                                    } as QLCCDomesticWaterCycleModel)
                                    .then((res) => {
                                        Notification(convert[res.status || 0], res.message);
                                        setLoading(false);
                                        onloadCycle();
                                    })
                                    .catch((err) => {
                                        Notification(convert[err.status || 0], err.message);
                                    });
                            }
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: ACTIONS.ADD,
                })
                break;
            case ACTIONS.EDIT:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: ACTIONS.EDIT,
                                payload: key
                            })
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.VIEW:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: ACTIONS.VIEW,
                                payload: key,
                            })
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.PRINT:
                break;
            default:
                break;
        }
    };

    return (
        <div className='container-fluid'>
            {
                (React.useMemo(() => {
                    return <DomesticWaterCycleAction
                        open={actCx.open}
                        onClose={function (): void {
                            setActCx(defaultAction);
                        }}
                        onEditSuccess={function (is_success): void {
                            onloadCycle()
                        }}
                        action={actCx.type}
                        data={actCx.payload}
                        itemSelected={actCx.payload}
                        apartmentBuilding={apartmentBuildings.find(item => item.id === apartment_building_id)!}
                    />
                }, [actCx.open]))
            }

            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb location={location} />
            </div>

            <DHSToolbarRole
                id={' '}
                menu={menu}
                onClickAction={handleClickAction}
            />

            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                key="id"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={allowEdit}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handlePageChange={handlePageChange}
                handleSelectionChanged={handleSelectionChanged}
            />
            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default DomesticWaterCycle;


