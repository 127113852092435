import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import BetweenTwoDate from "./BetweenTwoDate";
import {
  BaseformClient,
  CommonClient,
  IReportInfo,
  ParaObjReportInfo,
  ParasysActionType,
  ReportInfo,
  SysActionType,
} from "../../../app/shared/service-proxies/api-shared";
import { AppSession } from "../../../app/shared/app-session/app-session";
import BASE_URL_API from "../../../app/shared/service-proxies/config";
import { useSelector } from "react-redux";
import { IReducer } from "../../layouts";
import DHSToolbarRole from "../DHS-Toolbar-Role/DHSToolbarRole";
import DHSDialogAction from "../DHS_DialogAction/DialogAction";
import { ACTIONS } from "../../../common/enums/DHSToolbarRoleAction";
import { Box, InputLabel, MenuItem, TextField } from "@mui/material";
import DHSTextBox from "../../DHSComponents/editors/DHSTextBox/DHSTextBox";
import DHSSelectBox from "../../DHSComponents/editors/DHSSelectBox/DHSSelectBox";

type IDHSPopupReportProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fromDate: Date;
  setFromDate: React.Dispatch<React.SetStateAction<Date>>;
  toDate: Date;
  setToDate: React.Dispatch<React.SetStateAction<Date>>;
  refreshData: () => void;
  // reportInfoList: ReportInfo[];
  // setReportInfoList: React.Dispatch<React.SetStateAction<ReportInfo[]>>;
  setReportInfo: React.Dispatch<React.SetStateAction<ReportInfo | undefined>>;
  reportInfo: ReportInfo | undefined;
};
let appSession: AppSession;
export default function DHSPopupReport(props: IDHSPopupReportProps) {
  const baseFormClient = new BaseformClient(appSession, BASE_URL_API);
  const commonClient = new CommonClient(appSession, BASE_URL_API);
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const [reportInfoList, setReportInfoList] = React.useState<ReportInfo[]>([]);
  // const [actions, setActions] = React.useState<SysActionType[]>([])
  // const [reportInfo, setReportInfo] = React.useState<ReportInfo>()
  const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

  const handleClose = () => {
    props.setIsOpen(false);
  };

  const handleSubmit = () => {
    props.refreshData();
    props.setIsOpen(false);
  };

  React.useEffect(() => {
    (async () => {
      try {
        // setLoading(true)
        const res = await commonClient.reportInfoGet({
          ...new ParaObjReportInfo(),
          menuid: menu?.menuid,
          language: "vi-VN",
          apartment_building_id: apartment_building_id,
        } as ParaObjReportInfo);

        if (res.length > 0 && res[0]?.formateD_COL_LIST) {
          props.setReportInfo({
            ...props.reportInfo,
            ...res[0],
            header: res[0]?.formateD_COL_LIST.split(","),
          } as unknown as ReportInfo);
          setReportInfoList([...res]);
        } else {
          props.setReportInfo({} as ReportInfo);
          setReportInfoList([]);
        }

      } catch (error) {
        setReportInfoList([]);
      } finally {
        // setLoading(false)
      }
    })();
  }, [apartment_building_id]);

  // React.useEffect(() => {
  //   baseFormClient.getActionType({
  //     ...new ParasysActionType(),
  //     action_type: 'RPTOKCLOSE',
  //     language: 'vi-VN'
  // } as ParasysActionType)
  //     .then(res => {
  //         // setActionTypes(res);
  //         // console.log(res);
  //         setActions([...res])

  //     })
  // }, [])
  const handleClickAction = (action: SysActionType, nowTime: Date) => {
    if (action.action_code === ACTIONS.FILTER) {
    } else if (action.action_code === ACTIONS.EXPORTEXCEL) {
    } else if (action.action_code === ACTIONS.OK) {
      props.refreshData();
      props.setIsOpen(false);
    } else if (action.action_code === ACTIONS.CLOSE) {
      props.setIsOpen(false);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "reportInfo") {
      const item = reportInfoList.find((e: any) => e.ma_mau === value);
      if (item) {
        props.setReportInfo(item);
      }
    }
  };


  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        keepMounted
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box className="w-100 mt-2">
            <InputLabel className="cc-label">Mẫu báo cáo</InputLabel>
            <DHSSelectBox
              value={props.reportInfo?.ma_mau || ""}
              onValueChange={(e) => {
                const obj = { target: { name: "reportInfo", value: e } };
                handleChange(obj);
              }}
              dataSource={reportInfoList}
              valueExpr={'ma_mau'}
              displayExpr={'ten_mau'}
            />
            {/* <TextField
              className="cc-input"
              select
              style={{ width: "100%" }}
              name="reportInfo"
              value={props.reportInfo?.spname || ""}
              onChange={handleChange}
            >
              {reportInfoList &&
                reportInfoList.map((row: any, idx: number) => (
                  <MenuItem key={idx} value={row.spname}>{row.ten_mau}</MenuItem>
                ))}
            </TextField> */}
          </Box>
          <Box className="w-100 mt-4">
            <InputLabel className="cc-label">Tiêu đề</InputLabel>
            <DHSTextBox
              value={props.reportInfo?.ten_mau}
            />
            {/* <TextField
              className="cc-input"
              style={{ width: "100%" }}
              value={props.reportInfo?.title}
            /> */}
          </Box>
          <BetweenTwoDate
            fromDate={(newDate: any) => props.setFromDate(newDate)}
            toDate={(newDate: any) => props.setToDate(newDate)}
            reload={() => console.log('Hè lô')}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Hủy</Button>
          <Button onClick={handleSubmit} autoFocus>
            Xác nhận
          </Button> */}
          <DHSDialogAction menu={menu} onClickAction={handleClickAction} />
        </DialogActions>
      </Dialog>
    </div>
  );
}
