import React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Switch,
    styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
    QLCCFloorModel,
    QLCCClient,
    QLCCBlockModel,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../ApartmentBuilding.css";
import DHSGridView from "../../../../components/layouts/Admin/Grid/Grid-view/Grid-View";

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

var appSession: AppSession;
const FloorsListAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const convert = ["success", "error", "warning"];
    const [_id, setId] = React.useState<string>();
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);

    const [data, setData] = React.useState<QLCCFloorModel[]>([] as QLCCFloorModel[]);
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
    const [isShowSort, setShowSort] = React.useState<boolean>(false);
    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);

    const customMenu = [
        {
            menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
            onClick: () => {
                setShowSearch(!isShowSearch);
            },
        },
        {
            menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
            onClick: () => {
                setShowGroup(!isShowGroup);
            },
        },
        {
            menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
            onClick: () => {
                setShowSort(!isShowSort);
            },
        },
        {
            menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
            onClick: () => {
                setShowFilter(!isShowFilter);
            },
        },
    ];

    React.useEffect(() => {
        if(props.open){
            setLoading(true)
        qLCClient
            .qlccBlockGet({
                ...new QLCCBlockModel()
            } as QLCCBlockModel)
            .then((res_) => {
                qLCClient
                    .qLCCFloorGet({
                        ...new QLCCFloorModel(),
                        block_id: props.itemSelected,
                        // apartment_building_id: apartment_building_id,
                        ma_cty: SessionLogin.ma_cty,
                    } as QLCCFloorModel)
                    .then((res) => {
                        const arrItem: any = [];
                        res.forEach(item => {
                            arrItem.push({
                                ...item,
                                block_name: res_.find(i => i.id == item.block_id)?.name
                            })
                        });
                        setData(arrItem)
                        setLoading(false);
                    })
            })
        }
    }, [props.open]);

    return (
        <div>
            <Dialog
                id="sysMenu5"
                open={props.open}
                onClose={props.onClose}
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
                TransitionComponent={Transition}
                maxWidth="xs"
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            {
                                menu?.bar +
                                " " +
                                `${props.action === "DETAIL"
                                    ? "[Xem chi tiết]"
                                    : props.action === "UPDATE" || _id
                                        ? "[Cập nhật]"
                                        : "[Thêm mới]"
                                }`
                            }
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent >
                    <DHSGridView
                        onSelectRow={() => console.log('show')}
                        itemClicked={function (): void {
                            throw new Error("Function not implemented.");
                        }}
                        defaultPageSize={10}
                        table_name={menu?.component ?? ''}
                        show_form_list={true}
                        showSelectBox={false}
                        data={data}
                        showSortPanel={isShowSort}
                        showSearchPanel={isShowSearch}
                        showGroupPanel={isShowGroup}
                        showFilterPanel={isShowFilter}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="outlined">Hủy</Button>
                </DialogActions>
                <BlockUI blocking={isLoading} title={""}></BlockUI>
            </Dialog>
        </div>
    );
};

export default FloorsListAction;
