import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from '../../../components/layouts';
import { getRoles } from '../../../redux/actions/RoleActions';
import { AppSession } from '../../shared/app-session/app-session';
import {
    CaVch02Client,
    CaVch02D0,
    CaVch02D1,
    CaVch02M,
    Cavch02MFiltModel,
    HomeClient,
    ModelProcessca02,
    QLCCApartmentBuildingModel,
    QLCCClient,
    RoleRightWebModel,
    SysActionType
} from '../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../shared/service-proxies/config';

import { useLocation } from 'react-router-dom';
import BlockUI from '../../../components/Block-UI/Block-ui';
import notification from '../../../common/notification/notification';
import { Autocomplete, Box, TextField } from '@mui/material';
import DHSToolbarRole from '../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import PaymentCashAction from './actions/PaymentCashAction';
import { ACTIONS } from '../../../common/enums/DHSToolbarRoleAction';
import PaymentCashPrintAction from './actions/PaymentCashPrintAction';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../common/Interfaces/StatusMessage';
import DHSBreadcrumb from '../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import PaymentCashSearchAction from './payment-cash-search';
import DataGrid from 'devextreme-react/data-grid';
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { EventInfo } from 'devextreme/events';
import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import { getDataRowSelected, getIndexRowSelected } from '../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';

var appSession: AppSession;

interface IAction {
    open: boolean,
    type: ACTIONS | string,
    payload: any

}

const defaultAction: IAction = {
    open: false,
    type: ACTIONS.EMPTY,
    payload: null
}

interface IFilter {
    apartment_building_id?: string | null,
    block_id?: string | null,
    floor_id?: string | null,
    apartment_id?: string | null
}

const defaultFilter: IFilter = {
    apartment_building_id: null,
    block_id: null,
    floor_id: null,
    apartment_id: null
}

const status_message = ['success', 'error']
const action_not_allow_when_lock = [ACTIONS.EDIT, ACTIONS.DELETE]
const roleMenu: string = 'CC';
const QLCCPaymentCashM: React.FC<{}> = () => {

    const dispatch = useDispatch();
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
    const location = useLocation();
    // const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    // const [idItemSelected, setIdItemSelected] = React.useState<any>();
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);

    const cavch02client = new CaVch02Client(appSession, BASE_URL_API);
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    const homeClient = new HomeClient(appSession, BASE_URL_API);

    const [dataTable, setDataTable] = React.useState<CaVch02M[]>([] as CaVch02M[]);
    // 
    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const [actPrint, setActPrint] = React.useState<IAction>(defaultAction);
    const [actSearch, setActSearch] = React.useState<IAction>(defaultAction);
    // 
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");


    // 

    const [filter, setFilter] = useState<IFilter>(defaultFilter);

    const [apartmentBuildingOptions, setApartmentBuildingOptions] = React.useState<any[]>([]);

    // 

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

    // 

    // React.useEffect(() => {
    //     setLoading(true);
    //     homeClient
    //         .roleRightWebGetAll({
    //             ...new RoleRightWebModel(),
    //             menuid: menu?.menuid,
    //             rolename: roleMenu,
    //             username: SessionLogin.userName,
    //         } as RoleRightWebModel)
    //         .then((res) => {
    //             dispatch(getRoles(res));
    //             setRoles(res);
    //             setLoading(false);
    //         })
    //         .catch((err) => {
    //             setLoading(false)
    //         });



    // }, [menu?.component])

    //#region handleGetDetailData

    React.useEffect(() => {
        setLoading(true)
        cavch02client
            .caVch02Get({
                ...new ModelProcessca02(),
                // action_type: "DS-PHIEUTHU",
                modelM: {
                    apartment_building_id: apartment_building_id,
                    mA_CTY: SessionLogin.ma_cty,
                    mA_CT: menu?.code_name
                }

            } as ModelProcessca02)
            .then(response => {
                const tempArr: any = [];
                response.listCaVch02Ms && response.listCaVch02Ms.forEach((item) => {
                    tempArr.push({
                        ...item,
                        // ngaY_CT: moment.utc(item.ngaY_CT).toDate(),
                        // ngaY_LCT: moment.utc(item.ngaY_LCT).toDate(),
                    })
                });
                setDataTable(tempArr);
                setLoading(false);
            })
        // onHandleFilter(filterString);
    }, [apartment_building_id, reload]);

    //#endregion

    //#endregion handleGetOptions
    // useEffect(() => {
    //     setLoading(true);
    //     qLCClient.qlccApartmentBuildingGet({
    //         ...new QLCCApartmentBuildingModel(),
    //         ma_cty: SessionLogin.ma_cty,
    //         action_type: 'GET'
    //     } as QLCCApartmentBuildingModel)
    //         .then(response => {


    //             setApartmentBuildingOptions(response)
    //             if (response) {
    //                 setFilter(pre => ({ ...pre, apartment_building_id: response[0].id }))
    //             }
    //             setLoading(false);
    //         });

    // }, []);

    React.useEffect(() => {
        setFilter(pre => ({ ...pre, apartment_building_id: apartment_building_id }))

    }, [apartment_building_id])

    //#endregion

    //#region handleClickAction
    const handleClickAction = async (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code?.toUpperCase()) {
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<CaVch02M>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            // kiểm tra dữ liệu đã khóa hay chưa
                            if (action_not_allow_when_lock.includes(ACTIONS.DELETE) && dataSelected.islock === true) {
                                notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE[`NOT_ALLOW_${ACTIONS.DELETE}_WHEN_LOCK`])
                            }
                            else {
                                if (window.confirm("Bạn chắc chắn muốn xóa?") === true) {
                                    cavch02client.deleteCommand2({
                                        ...new ModelProcessca02(),
                                        modelM: { ...new CaVch02M(), stT_REC: dataSelected.stT_REC, mA_CT: dataSelected.mA_CT, mA_CTY: dataSelected.mA_CTY },
                                        listCaVch02Ms: [] as CaVch02M[],
                                        arrayCt: [] as CaVch02D0[],
                                        arrayCt1: [] as CaVch02D1[]
                                    } as ModelProcessca02)
                                        .then(res => {
                                            setLoading(false);
                                            setReload(pre => !pre);
                                            notification(status_message[res.status!], STATUS_MESSAGE[`${action?.action_code?.toUpperCase()}_${res.status}`]);

                                        })
                                        .catch(error => {
                                            notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                                            setLoading(false);
                                        });
                                }
                            }
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.PRINT:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<CaVch02M>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            setActPrint({
                                ...actPrint,
                                open: true,
                                type: "",
                                payload: dataSelected // pass the data here
                            })
                        }
                        else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }

                break;
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: action?.action_code?.toUpperCase() ?? "",
                    // payload: selectedItemKeys
                });
                break;
            case ACTIONS.EDIT:

                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<CaVch02M>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            if (action_not_allow_when_lock.includes(ACTIONS.EDIT) && dataSelected.islock === true) {
                                notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE[`NOT_ALLOW_${ACTIONS.EDIT}_WHEN_LOCK`])

                            }
                            else {
                                setActCx({
                                    ...actCx,
                                    open: true,
                                    type: action?.action_code?.toUpperCase() ?? "",
                                    payload: dataSelected
                                });
                            }
                        }
                        else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }


                break;
            case ACTIONS.VIEW:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<CaVch02M>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: action?.action_code?.toUpperCase() ?? "",
                                payload: dataSelected
                            });
                        }
                        else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.SEARCH:

                setActSearch({
                    ...actSearch,
                    open: true,
                    type: ACTIONS.EMPTY,
                    // payload: selectedItemKeys // pass the data here
                })
                break;
            default:
                // setActCx({
                //     ...actCx,
                //     open: true,
                //     type: action?.class_name?.toUpperCase() ?? "",
                //     payload: idItemSelected
                // })
                break;
            // handle delete here
        }


    }

    //#endregion


    //#region hanldeChangeInput
    const handleChangeFilterInput = (e: any) => {
        const { name, value } = e.target;

        setFilter(pre => ({ ...filter, [name]: value } as IFilter));
    };

    //#endregion

    //#region handleApplyFilter
    const onHandleFilter = (filterString: string) => {
        setLoading(true)
        cavch02client.filtAll2({
            ...new Cavch02MFiltModel(),
            sKeyM: `ma_cty = '${SessionLogin.ma_cty}' AND ma_ct = '${menu?.code_name}' AND ${filterString}`,
            sKeyD: "1 = 1",
            ma_cty: SessionLogin.ma_cty
        } as Cavch02MFiltModel).then(res => {
            const tempArr: any = [];
            res.forEach((item) => {
                tempArr.push({
                    ...item,
                    // ngaY_CT: moment(item.ngaY_CT).format('DD/MM/YYYY'),
                })
            });
            setDataTable(tempArr);
            setLoading(false)
        })
    }

    //#endregion

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                console.log(keys);

                const index = e.component.getRowIndexByKey(keys[0]);

                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
            });
        }
    };

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };


    return (
        <div className='container-fluid'>
            {
                (React.useMemo(() => {
                    return <PaymentCashAction
                        open={actCx.open}
                        onClose={function (): void {
                            setActCx(defaultAction)
                        }}
                        onAddSuccess={function (data_success): void {
                            // setReload(pre => !pre)
                            const tempDataM = [...dataTable];
                            tempDataM.unshift(data_success);
                            setDataTable(tempDataM)

                        }}
                        onEditSuccess={function (data_success): void {
                            // setReload(pre => !pre)
                            const tempDataM = [...dataTable];
                            const index = tempDataM.findIndex(x => x.stT_REC === data_success?.stT_REC);
                            tempDataM[index] = data_success;
                            setDataTable(tempDataM)

                        }}
                        filter={filter}
                        action={actCx.type}
                        itemSelected={actCx.payload}
                        payload={{
                            apartment_building_id: filter?.apartment_building_id
                        }}
                    >
                    </PaymentCashAction>
                }, [actCx.open, setReload]))
            }

            {/* In phiếu thu action */}
            {
                actPrint.open && <PaymentCashPrintAction
                    open={actPrint.open}
                    onClose={function (): void {
                        setActPrint(defaultAction)
                    }}
                    onEditSuccess={function (is_success): void {
                        setReload(pre => !pre)
                    }}
                    action={actPrint.type}
                    itemSelected={actPrint.payload} >

                </PaymentCashPrintAction>

            }

            {/* Tìm phiếu chi action */}
            {
                (React.useMemo(() => <PaymentCashSearchAction
                    open={actSearch.open}
                    onClose={function (): void {
                        setActSearch(defaultAction);
                        // setReload(pre => !pre);

                    }}
                    onApply={function (_filterString): void {
                        onHandleFilter(_filterString);
                    }}

                    action={actSearch.type}
                    itemSelected={actSearch.payload} >

                </PaymentCashSearchAction>, [actSearch.open]))

            }





            <div className='row no-gutters my-1 align-items-center'>

                {/* Go back */}

                <div
                    className="d-flex title-color"
                    style={{
                        height: 30,
                        color: "rgb(0 78 255 / 88%)",
                        marginTop: 15,
                        marginBottom: 15,
                        fontSize: 22,
                        fontWeight: 550,
                    }}
                >
                    <DHSBreadcrumb
                        location={location} />
                </div>
                {/* Dự án */}
                {/* <div className="col-md-12 col-xl-3 pr-1" style={{ marginLeft: 'auto' }}>
                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Chung cư</InputLabel>
                        {
                            <Autocomplete
                                id="apartment-building-select"
                                sx={{ width: "100%" }}
                                options={apartmentBuildingOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => `${option?.name}`}
                                value={
                                    filter?.apartment_building_id
                                        ? apartmentBuildingOptions.find(
                                            (item: any) => item.id === filter.apartment_building_id
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeFilterInput({
                                        target: {
                                            name: "apartment_building_id",
                                            value: newValue?.id ?? null,
                                        },
                                    });

                                    // 


                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option?.id}>
                                            {option?.name}
                                        </li>
                                    );
                                }}

                                renderInput={(params) => (
                                    <TextField


                                        {...params}

                                        placeholder='Chọn chung cư'
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}

                            />

                        }
                    </Box>
                </div> */}
            </div>


            <DHSToolbarRole
                id={' '}
                menu={menu}
                customMenu={[]}
                onClickAction={handleClickAction}
            />

            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                keyExpr="stT_REC"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handleSelectionChanged={handleSelectionChanged}
                handlePageChange={handlePageChange}

            // handleExporting={handleExporting}
            />

            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default QLCCPaymentCashM;


