export const HintCode = {
    "TASK_START_TIME": "Thời gian bắt đầu công việc",
    "TASK_END_TIME": "Thời gian kết thúc công việc",
    "DEPARTMENT": "Chọn bộ phận trong công ty",
    "TASK_GROUP": "Chọn nhóm công việc",
    "TASK_RECEIVER": "Người thực hiện công việc",
    "TASK_SCRIPT": "Danh sách kế hoạch công việc của checklist",
    "TASK_CYCLE": "Chu kỳ của công việc",
    "TASK_NGAY_CT": "Ngày báo cáo công việc",
    "TASK_DATE_DONE": "Ngày công việc hoàn thành",
    "TASK_ATTACH_FILE": "Tập tin đính kèm theo công việc",
    "TASK_STATUS": "Trạng thái của công việc hiện tại",
    "TASK_FOLLOWER": "Tùy chọn danh sách người theo dõi công việc",
    "TASK_CHAT_ATTACH_FILE": "Tải tập tin",
    "TASK_CHAT_SENT": "Gửi đi",
    "TASK_REMOVE_FOLLOWER": "Loại bỏ người theo dõi",
    "TASK_TITLE": "Tên công của công việc",
    "APARTMENT_BUILDING": "Chọn dự án chung cư của bạn",
    "TASK_TYPE": "Loại công việc",
    "TASK_OPTION_FIND": "Tìm kiếm theo các lựa chọn",
    "BUTTON_FIND": "Thực hiện tìm kiếm",
    "BUTTON_CLOSE": "Đóng cửa sổ này đi",
    "FROM_DATE": 'Thời gian bắt đầu',
    "TO_DATE": 'Thời gian kết thúc',
}
