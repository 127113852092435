import React, { useRef, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { EventInfo } from "devextreme/events";
import dxDataGrid, { SelectionChangedInfo } from "devextreme/ui/data_grid";
import DataGrid from "devextreme-react/data-grid";

import { IReducer, Notification } from "../../../../components/layouts";
import {
  CaReportClient,
  ReportInfo,
  SYS_Token_AuthenticationModel,
  SysActionType,
} from "../../../shared/service-proxies/api-shared";
import { httpPost } from "../../../../common/httpService";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { exportExcelReport } from "../../../../components/DHS/DHS_ExportExcelReport/exportExcelReport";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import DHSBreadcrumb from "../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import SearchOperation from "../../actions/report-action/searchOperationAction";
import { setApartmentBuilding } from "../../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction";
import moment from "moment";

interface ICAReport {
  ma_cty: string | undefined;
  ngay_Ct1: Date;
  ngay_ct2: Date;
  tk: string | undefined;
  ma_nt: string | undefined;
  apartment_building_id: string | undefined;
  spname: string | undefined;
}

const HistoryDomesticWater = () => {
  const SessionLogin: SYS_Token_AuthenticationModel = JSON.parse(
    localStorage.getItem("SessionLogin") || "{}"
  );
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const location = useLocation();
  const dataGridRef = useRef<DataGrid>(null);
  const [data, setData] = useState<any[]>([]);

  const [filter, setFilter] = useState<ICAReport>({
    ngay_Ct1: moment().startOf('month').utc(true).toDate(),
    ngay_ct2: moment().endOf('month').utc(true).toDate(),
    apartment_building_id: "",
    ma_cty: "",
    ma_nt: "",
    spname: "",
    tk: "",
  });

  const [reportInfo, setReportInfo] = React.useState<ReportInfo>();

  const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

  React.useEffect(() => {
    (async () => {
      try {
        if (reportInfo) {
          setLoading(true);

          if (filter.apartment_building_id !== apartment_building_id) {
            setFilter({
              ...filter,
              apartment_building_id: apartment_building_id
            })
          }

          if (reportInfo && reportInfo.spname) {
            const res = await httpPost<string>("api/caReport/CAReport", {
              ma_cty: SessionLogin.ma_cty,
              apartment_building_id: apartment_building_id,
              tk: "",
              ma_nt: "vnd",
              ngay_Ct1: moment(filter.ngay_Ct1).startOf('day').utc(true).toDate(),
              ngay_ct2: moment(filter.ngay_ct2).endOf('day').utc(true).toDate(),
              spname: reportInfo?.spname || "",
            });
            const dataAfterParse = JSON.parse(res.data);

            setData([...dataAfterParse.Table]);
          } else {
            setData([]);
          }
        }
      } catch (error) {
        setData([]);
      } finally {
        setLoading(false);
      }
    })();
  }, [reload, reportInfo, apartment_building_id]);

  const handleSelectionChanged = (
    e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
  ) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
        console.log(keys);
        const index = e.component.getRowIndexByKey(keys[0]);
        setSelectedRowIndex(index >= 0 ? index : -1);
        setSelectedItemKeys(keys[0]);
      });
    }
  };

  const handlePageChange = () => {
    setSelectedRowIndex(-1);
    setSelectedItemKeys(undefined);
  };

  const handleClickAction = (action: SysActionType, nowTime: Date) => {
    switch (action?.action_code?.toLocaleUpperCase()) {
      case ACTIONS.SEARCH:
        setIsOpen(true);
        break;
      case ACTIONS.EXPORTEXCEL:
        debugger
        if (dataGridRef.current && reportInfo) {
          if (reportInfo) {
            exportExcelReport(
              dataGridRef,
              reportInfo,
              filter.ngay_Ct1,
              filter.ngay_ct2
            );
          } else {
            Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
          }
          //   exportExcelReport(dataGridRef, reportInfo, fromDate, toDate);
        }
        break;
      case ACTIONS.PRINT:
        break;
      default:
        break;
    }
  };

  const handleExporting = (e: any) => {
    if (reportInfo) {
      try {
        exportExcelReport(
          dataGridRef,
          reportInfo,
          filter.ngay_Ct1,
          filter.ngay_ct2
        );
      } catch (error) {
        Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
      }
    } else {
      Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          marginTop: 15,
          marginBottom: 15,
          fontSize: 22,
          fontWeight: 550,
        }}
      >
        <DHSBreadcrumb location={location} />
      </div>
      <DHSToolbarRole
        id={selectedItemKeys ? selectedItemKeys?.id : undefined}
        menu={menu}
        onClickAction={handleClickAction}
      />
      <DHSDataGridEdit
        data={data}
        table_name={menu?.menuid}
        // key="so_ct"
        mode="popup"
        dataGridRef={dataGridRef}
        allowEdit={false}
        pageSize={menu?.page_size}
        isPagination={menu?.is_pagination}
        isMultiple={menu?.is_multiple}
        groupingAutoExpandAll={false}
        handlePageChange={handlePageChange}
        handleSelectionChanged={handleSelectionChanged}
        handleExporting={handleExporting}
      />

      <SearchOperation
        isOpen={isOpen}
        setIsOpen={(status: any) => setIsOpen(status)}
        reportInfo={reportInfo}
        setReportInfo={(data: ReportInfo) => {
          setReportInfo(data as ReportInfo);
        }}
        setReload={() => setReload(!reload)}
        data={filter}
        setData={(data: any) => {
          console.log(data);
          setFilter({
            ...data,
          } as ICAReport);
        }}
      />
      <BlockUI blocking={loading} title={""}></BlockUI>
    </Box>
  );
};

export default HistoryDomesticWater;

// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import DHSToolbar from '../../../../components/DHS/DHS-Toolbar/DHSToolbar';
// import { IReducer } from '../../../../components/layouts';
// import DHSGridView from '../../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
// import { getRoles } from '../../../../redux/actions/RoleActions';
// import { AppSession } from '../../../shared/app-session/app-session';
// import {
//     BaseformClient,
//     CaVch01Client,
//     CaVch01M,
//     HomeClient,
//     ParasysActionType,
//     QLCCApartmentBuildingModel,
//     RoleRightWebModel,
//     RptCATMNH01,
//     RptCATMNH01Model,
//     SoVch01Client,
//     SoVch05M,
// } from '../../../shared/service-proxies/api-shared';
// import BASE_URL_API from '../../../shared/service-proxies/config';

// import { useHistory } from 'react-router-dom';
// import BlockUI from '../../../../components/Block-UI/Block-ui';
// import notification from '../../../../common/notification/notification';
// import { SoVch05Client } from '../../../shared/service-proxies/api-shared';
// import moment from 'moment';
// import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputLabel, MenuItem, Modal, Select, Slide, TextField, Toolbar, Typography } from '@mui/material';
// import CTXDate from '../../../../components/CTX/DatePicker';
// import { callApiToken } from '../../../../routes/configApi';
// import '../../actions/ApartmentBuilding.css';
// import { TransitionProps } from '@mui/material/transitions';
// import SearchIcon from '@mui/icons-material/Search';
// import CCGridView from '../cash-book-report/component/CCGridView';
// import BetweenTwoDate from '../cash-book-report/component/between-two-date-X';
// import { isBuffer } from 'lodash';
// import DHSToolbarRole from '../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
// import { ACTIONS } from '../../../../common/enums/DHSToolbarRoleAction';
// import { SysActionType } from '../../../shared/service-proxies/api-shared';
// import { useHotkeys } from 'react-hotkeys-hook';
// import SearchOperation from '../../actions/report-action/searchOperationAction';

// const convert = ["success", "error", "warning"];
// var appSession: AppSession;

// interface IAction {
//     open: boolean,
//     type: string,
//     payload: any

// }

// interface IPayment {
//     paymentAmount: string | undefined,
//     paymentType: string | undefined
// }

// const defaultAction: IAction = {
//     open: false,
//     type: "",
//     payload: null
// }

// interface IReportInfo {
//     spname: string | undefined,
//     title: string | undefined,
//     header: string[] | undefined,
// }

// const roleMenu: string = 'CC';
// const HistoryDomesticWater: React.FC<{}> = () => {

//     const dispatch = useDispatch();
//     const history = useHistory();
//     const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
//     const [loading, setLoading] = React.useState<boolean>(false);
//     const [idItemSelected, setIdItemSelected] = React.useState<string>("");
//     const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
//     const [reload, setReload] = React.useState<boolean>(false);
//     const vch05Client = new SoVch05Client(appSession, BASE_URL_API);
//     const vch01Client = new SoVch01Client(appSession, BASE_URL_API);
//     const caVch01Client = new CaVch01Client(appSession, BASE_URL_API);
//     const homeClient = new HomeClient(appSession, BASE_URL_API);
//     const [dataTable, setDataTable] = React.useState<RptCATMNH01[]>([] as RptCATMNH01[]);
//     const [actCx, setActCx] = useState<IAction>(defaultAction);
//     const [isShowModal, setIsShowModal] = React.useState<boolean>(false);
//     const [payment, setPayment] = React.useState<IPayment>({} as IPayment);
//     const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

//     const Token = {
//         Token: SessionLogin.Token
//     }
//     const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
//     const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
//     const [isShowSort, setShowSort] = React.useState<boolean>(false);
//     const [isShowFilter, setShowFilter] = React.useState<boolean>(false);

//     const [isShowDate, setIsShowDate] = React.useState<boolean>(true);
//     const [fromDate, setFromDate] = React.useState<Date>(new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), 2));
//     const [toDate, setToDate] = React.useState<Date>(new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 1));
//     const customMenu = [
//         {
//             menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
//             onClick: () => {
//                 setShowSearch(!isShowSearch);
//             },
//         },
//         {
//             menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
//             onClick: () => {
//                 setShowGroup(!isShowGroup);
//             },
//         },
//         {
//             menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
//             onClick: () => {
//                 setShowSort(!isShowSort);
//             },
//         },
//         {
//             menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
//             onClick: () => {
//                 setShowFilter(!isShowFilter);
//             },
//         },
//     ];

//     const [reportInfoList, setReportInfoList] = React.useState<any>([]);
//     const [reportInfo, setReportInfo] = React.useState<IReportInfo>({} as IReportInfo)
//     const [isOpen, setIsOpen] = React.useState<boolean>(false);
//     const [isExport, setIsExport] = React.useState<boolean>(false);

//     const [actionTypes, setActionTypes] = React.useState<ParasysActionType[]>();
//     const baseFormClient = new BaseformClient(appSession, BASE_URL_API);
//     const [apartmentBuilding, setApartmentBuilding] = React.useState<QLCCApartmentBuildingModel>({} as QLCCApartmentBuildingModel);

//     useHotkeys<HTMLParagraphElement>('ctrl+f1', () => setIsOpen(!isOpen));

//     useHotkeys<HTMLParagraphElement>('alt+1', () => setShowSearch(!isShowSearch));

//     useHotkeys<HTMLParagraphElement>('alt+2', () => setShowGroup(!isShowGroup));

//     useHotkeys<HTMLParagraphElement>('alt+3', () => setShowSort(!isShowSort));

//     useHotkeys<HTMLParagraphElement>('alt+4', () => setShowFilter(!isShowFilter));

//     const onloadData = () => {
//         if (apartmentBuilding.id) {
//             callApiToken("api/QLCCReport/WaterReport", "POST", {
//                 ma_cty: SessionLogin.ma_cty,
//                 ngay_Ct1: fromDate,
//                 ngay_ct2: toDate,
//                 apartment_building_id: apartmentBuilding?.id,
//                 spname: reportInfo.spname || ""
//             }, Token)
//                 .then((response: any) => {
//                     // const data = response.data;
//                     // const arrayData: any = [];
//                     // if (data?.Table) {
//                     //     data.Table.map((item: any) => {
//                     //         arrayData.push({
//                     //             ...item,
//                     //             ngay_ct: moment(item.ngay_ct ? item.ngay_ct : new Date()).format('DD/MM/YYYY'),
//                     //         })
//                     //     });
//                     //     setDataTable(arrayData);
//                     //}
//                     setDataTable(response.data.Table);
//                 }).catch(error => {
//                     console.log(error)
//                 })
//         }
//     }

//     React.useEffect(() => {
//         setLoading(true);
//         homeClient
//             .roleRightWebGetAll({
//                 ...new RoleRightWebModel(),
//                 menuid: menu?.menuid,
//                 rolename: roleMenu,
//                 username: SessionLogin.userName,
//             } as RoleRightWebModel)
//             .then((res) => {
//                 dispatch(getRoles(res));
//                 setRoles(res);
//                 setLoading(false);
//             })
//             .catch((err) => {
//                 setLoading(false)
//             })

//         baseFormClient.getActionType({
//             ...new ParasysActionType(),
//             action_type: menu?.action_type,
//             language: 'vi-VN'
//         } as ParasysActionType)
//             .then(res => {
//                 setActionTypes(res);
//             })

//         callApiToken("api/Common/ReportInfoGet", "POST", {
//             menuid: menu?.menuid,
//             language: "vi-VN"
//         }, Token)
//             .then(response => {
//                 setReportInfoList(response.data);
//                 if (response.data.length > 0) {
//                     setReportInfo({
//                         ...reportInfo,
//                         ...response.data[0],
//                         header: response.data[0]?.formateD_COL_LIST.split(',')
//                     } as IReportInfo);
//                 }
//             }).catch(error => {
//                 console.log(error)
//             })

//     }, [menu?.component])

//     React.useEffect(() => {
//         onloadData();
//     }, [reload]);
//     React.useEffect(() => {
//         onloadData();
//     }, [reportInfo, apartmentBuilding]);

//     const handleClickAction = (action: SysActionType, nowTime: Date) => {
//         if (action.action_code === ACTIONS.SEARCH) {
//             setIsOpen(true);
//         } else if (action.action_code === ACTIONS.EXPORTEXCEL) {
//             setIsExport(!isExport);
//         }
//     }

//     const handleSubmit = () => {
//         setReload(!reload);
//         setIsOpen(false);
//     }

//     const handleChange = (e: any) => {
//         const { name, value } = e.target;
//         if (name === 'reportInfo') {
//             const item = reportInfoList.find((e: any) => e.spname === value);
//             if (item) {
//                 setReportInfo({
//                     ...reportInfo,
//                     spname: value,
//                     header: item.formateD_COL_LIST.split(','),
//                     title: item.title,
//                 } as IReportInfo);
//             }
//         }
//     }

//     return (
//         <div className='container-fluid'>

//             {/* Lọc dữ liệu */}

//             <SearchOperation
//                 isOpen={isOpen}
//                 setIsOpen={(status: any) => setIsOpen(status)}
//                 reportInfo={reportInfo}
//                 handleChange={handleChange}
//                 reportInfoList={reportInfoList}
//                 setReload={() => setReload(!reload)}
//                 setFromDate={(data: any) => setFromDate(data)}
//                 setToDate={(data: any) => setToDate(data)}
//                 setApartmentBuilding={(data: any) => setApartmentBuilding(data)}
//                 apartmentBuilding={apartmentBuilding}
//             />

//             <div
//                 className="d-flex title-color"
//                 style={{
//                     height: 30,
//                     color: "rgb(0 78 255 / 88%)",
//                     marginTop: 15,
//                     marginBottom: 15,
//                     fontSize: 22,
//                     fontWeight: 550,
//                 }}
//             >
//                 <i
//                     className="bi bi-arrow-left-short"
//                     style={{ cursor: "pointer" }}
//                     onClick={() => history.goBack()}
//                 ></i>
//                 &nbsp;{menu?.bar}
//             </div>

//             {/* Control */}
//             <DHSToolbarRole
//                 id={idItemSelected as string}
//                 menu={menu}
//                 customMenu={customMenu}
//                 onClickAction={handleClickAction}
//             />

//             <CCGridView
//                 dataSource={dataTable}
//                 selection={false} // ẩn chon nhiều đi
//                 id='name'
//                 header={reportInfo.header || []}
//                 setIdItemSelected={(lstId: string) => setIdItemSelected(lstId)}
//                 isShowFilter={isShowFilter}
//                 isShowGroup={isShowGroup}
//                 isShowSearch={isShowSearch}
//                 isShowSort={isShowSort}
//                 table_name='Danh sách căn hộ sử dụng nước'
//                 isExport={isExport}
//                 setIsExport={() => setIsExport(!isExport)} // tự động tắt xuất excel

//             />

//             {/* <DHSGridView
//                 onSelectRow={(row) => {
//                     setIdItemSelected(row);
//                 }}
//                 // onListSelectRow={handleListOnselectRow}
//                 itemClicked={function (): void {
//                     throw new Error("Function not implemented.");
//                 }}
//                 table_name={menu?.component ?? ''}
//                 show_form_list={true}
//                 showSelectBox={false}
//                 data={dataTable}
//                 showSortPanel={isShowSort}
//                 showSearchPanel={isShowSearch}
//                 showGroupPanel={isShowGroup}
//                 showFilterPanel={isShowFilter}
//                 defaultPageSize={50}
//                 grid_name={"Danh sách dịch vụ"}
//                 excel={false}
//             /> */}

//             <BlockUI blocking={loading} title={""}></BlockUI>
//         </div>
//     )
// }

// export default HistoryDomesticWater;
