import React, { ChangeEvent, ComponentType, useState } from "react";
import Paper from "@mui/material/Paper";
import {
  ChangeSet,
  Column,
  DataTypeProvider,
  EditingState,
  FilteringState,
  IntegratedPaging,
  IntegratedSorting,
  IntegratedSummary,
  PagingState,
  SelectionState,
  SortingState,
  SummaryState,

} from "@devexpress/dx-react-grid";
import {
  Grid,
  PagingPanel,
  Table,
  TableColumnVisibility,
  TableEditColumn,
  TableEditRow,
  TableHeaderRow,
  TableInlineCellEditing,
  TableRowDetail,
  TableSelection,
  TableSummaryRow,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  TableCell,
  TableCellProps,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import MuiGrid from "@mui/material/Grid";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";
import { ToolbarFilterComponent } from "../Toolbar-filter/ToolbarFilter";
// MUI Date Picker date parser
import _ from "lodash";
import "./Grid-edit.css";
import DHSInputText from "../../../../DHS/DHS_InputText/InputText";
import DHSCheckBox from "../../../../DHS/DHS_CheckBox/CheckBox";
import DHSInputMoney from "../../../../DHS/DHS_InputMoney/Input-money";
import DHSSelect from "../../../../DHS/DHS_Select";
import DHSSelectTable from "../../../../DHS/DHS_SelectTable/SelectTable";
import DHSDate from "../../../../DHS/DHS-Date/DHSDateComponent";
import DHSDateTime from "../../../../DHS/DHS-DateTime/DHSDateTimeComponent";
import DHSTimePicker from "../../../../DHS/DHS-TimePicker/TimePicker";
import {
  HomeClient,
  SysGenRowTableModel,
} from "../../../../../app/shared/service-proxies/api-shared";
import { AppSession } from "../../../../../app/shared/app-session/app-session";
import BASE_URL_API from "../../../../../app/shared/service-proxies/config";
import { ToVietNamCurrency } from "../../../../utils/number.util";
import { error } from "console";
import { Button } from "antd";
import { PlusCircleFilled, DeleteFilled } from '@ant-design/icons';
import { ACTIONS } from "../../../../../common/enums/DHSToolbarRoleAction";



interface IRow {
  id: number;
  name: string;
  gender: string;
  city: string;
  birth: string;
  car: string;
}
interface IProps {
  itemClicked: () => void;
  onSelectRow: (v: any) => void;
  onListSelectRow: (v: any[]) => void;
  onChangeData: (v: any[]) => void;
  onError?: (errors: IErrorHelper[] | undefined) => void;
  blurCurrencyInput?: (e: any, column: any, row: any) => void;
  action?: string;
  table_name: string;
  show_form_list: boolean;

  showSearchPanel?: boolean;
  showFilterPanel?: boolean;
  showGroupPanel?: boolean;
  showSortPanel?: boolean;
  showSelectBox?: boolean;
  data: any[];
}
interface Product {
  PRODUCTID?: string;
  PARENTID?: string;
  LEVEL?: number;
  PRODUCTNAME?: string;
  PRODUCTNAMECOLOR?: string;
  LINKURL?: string;
  LANGUAGEID?: string;
  BOLD?: string;
  MENUID?: string;
}
interface IDefautWidths {
  columnName: string;
  width: number;
}

interface IError {
  status: number,
  message: string
}


interface IErrorHelper {
  name: string,
  errors: IError[]
}

let appSession: AppSession;
let rowsChanged: any[] | undefined;
let inputMoneyEdit: number | null = null;

const DHSGridEdit = (props: IProps) => {
  const [selection, setSelection] = React.useState<any[]>([]);
  const [selectionid, setSelectionid] = React.useState<any[]>([]);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const [rows, setRows] = React.useState<any[]>([]);
  // const [rowsChanged, setRowChanged] = React.useState<any[]>([]);
  const [columns, setColumns] = React.useState<any[]>([]);
  const [tableColumnExtensions, setTableColumnExtensions] = useState<Table.ColumnExtension[]>([]);
  React.useEffect(() => {
    setRows(props.data);
    rowsChanged = props.data;
  }, [props.data]);






  React.useEffect(() => {
    let list_hide: string[] = [];
    let list_resing: IDefautWidths[] = [];
    let pr = new SysGenRowTableModel();
    pr.table_name = props.table_name;
    pr.show_form_list = props.show_form_list;
    if (columns.length <= 0) {
      homeClient?.sysGenRowTable(pr).then((res) => {
        let currency: any[] = [];
        let totalSummary: any[] = [];
        let extensions: any[] = [];
        const dataColumn = res.map((row) => {
          if (row.default_hiden) list_hide.push(row.name ? row.name : "");
          list_resing.push({
            columnName: row.name ? row.name : "",
            width: row.width ? row.width : 150,
          });

          if (row.data_type === "money") {
            currency.push(row.column_name);
            totalSummary.push({ columnName: row.column_name, type: "sum" });
            extensions.push({ columnName: row.column_name, align: 'right' });

          }

          return row;
        });
        setcurrencyColumns(currency);
        setTableColumnExtensions(extensions);
        setColumns(dataColumn);
      });

    }

    //   setDefaultHiddenColumnNames(list_hide);
    //   setDefaultColumnWidths(list_resing);
  }, []);
  const Cell = (props: any) => {
    const { column } = props;
    return <VirtualTable.Cell {...props} />;
  };
  const pageSizes = [5, 10, 15, 0];
  // const tableColumnExtensions: Table.ColumnExtension[] = [
  //   { columnName: "gender", width: 100 },
  // ];
  const filters = [];
  const setFilters = [];
  const handleRowChange = (event: any[]) => {
    setSelection(event);
    let listid: any[] = [];
    event.forEach(element => {
      listid.push(rows[element]['stt_rec_hd'])
    });
    setSelectionid(listid);
    props.onListSelectRow(listid);
  };
  const commitChanges = (e: ChangeSet) => {
    let changedRows:
      | any[]
      | ((
        prevState: {
          id: number;
          sex: string;
          name: string;
          birth: string;
        }[]
      ) => { id: number; sex: string; name: string; birth: string }[]) = [];
    if (e.added) {
      const startingAddedId =
        rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...e.added.map((row: any, index: any) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
    }
    if (e.changed) {
      //  changedRows = rows.map(row => (e.changed['id'] ? { ...row, ...e.changed[row.id] } : row));
    }
    if (e.deleted) {
      const deletedSet = new Set(e.deleted);
      changedRows = rows.filter((row) => !deletedSet.has(row.id));
    }
    // setRows(changedRows);
  };
  const arr: any[] = [];
  const [startEditAction, setStartEditAction] = React.useState("click");
  const [selectTextOnEditStart, setSelectTextOnEditStart] =
    React.useState(true);
  const [lookup, setlookup] = React.useState<any[]>([]);
  //  const [lookupColumns] = React.useState(["ten_kh_vat", "ma_nt", "ty_gia", "t_tien_nt2", "t_tt"]);
  const [lookupColumns] = React.useState(["tt_nt"]);
  const [currencyColumns, setcurrencyColumns] = React.useState<any[]>([]);
  const [defaultHiddenColumnNames, setDefaultHiddenColumnNames] = useState<string[]>([]);
  const [editingRowIds, setEditingRowIds] = React.useState(arr);
  const [addedRows, setAddedRows] = React.useState([]);
  const [rowChanges, setRowChanges] = React.useState<any>({});
  const [columnEditing, setColumnEditing] = React.useState<any>();
  const [errors, setErrors] = React.useState<IErrorHelper[]>([]);

  // disable editing on this column
  const [editingStateColumnExtensions] = React.useState([
    { columnName: "so_ct", editingEnabled: false },
    { columnName: "ngay_ct", editingEnabled: false },
    { columnName: "so_hd", editingEnabled: false },
    { columnName: "ten_kh_vat", editingEnabled: false },
    { columnName: "dien_giai", editingEnabled: false },
    { columnName: "ngay_hd", editingEnabled: false },
    { columnName: "ma_nt", editingEnabled: false },
    { columnName: "ty_gia", editingEnabled: false },
    { columnName: "tien_tt_nt", editingEnabled: false },
    { columnName: "old_debt", editingEnabled: false },
    { columnName: "tien_tt", editingEnabled: false },
    { columnName: "tt_nt", editingEnabled: true },
    { columnName: "tt", editingEnabled: false },

  ]);



  React.useEffect(() => {
    props.onError && props.onError(errors);
  }, [errors])

  React.useEffect(() => {
    if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
      const defaultColumnNamesTemp = [...defaultHiddenColumnNames];
      defaultColumnNamesTemp.push('tien_tt')
      setDefaultHiddenColumnNames(defaultColumnNamesTemp);
    }
  }, [props.action])

  const changeAddedRows = (value: any) => {
    setAddedRows(value);
  };
  const LookupEditor = React.useMemo(() => (event: any, onValueChange: any) => {
    // console.log(event, "event.column.data_type")

    // Hàm cập nhật item trong row khi cell trong row thay đổi
    function updateDataItemGrid() {
      const updatedItem = [...rows].reduce((acc, item) => {
        const updatedItem = rowsChanged?.find(i => i.stt_rec_hd === item.stt_rec_hd);
        if (updatedItem) {
          acc.push({
            ...updatedItem,
            tt: updatedItem.tt_nt * updatedItem.ty_gia
          });
        } else {
          acc.push(item);
        }
        return acc;
      }, [])
      setRows(updatedItem)
    }


    return (


      event.column.data_type && event.column.data_type === "money" ? (



        <DHSInputMoney
          pattern="^[0-9]*$"
          currency={"VND"}
          value={event.value}
          id={event.row.stt_rec_hd}
          name={event.column?.column_name}
          className={`inputMoney`}
          disabled={false}
          readOnly={false}
          error={errors.some(item => item.name === event.column?.column_name) ? true : false}
          // errorMessage={undefined}
          currencyPosition="right"
          numbersAfterDecimal={5}
          onChange={(v: number) => {
            inputMoneyEdit = v;
            handleChangeValue(v, event.row, event.column)
          }}
          handleKeyPress={(e) => {

            if (e.key === 'Enter') {
              const columnEditingTemp: any = Object.assign({}, columnEditing, {
                columnName: event.column.name,
                value: event.row.tien_tt
              } as any);

              if (!columnEditing) {
                setColumnEditing(columnEditingTemp)
              }
              const valueColumnEditing = columnEditing?.value ?? event.row.tien_tt;


              if (inputMoneyEdit! > valueColumnEditing && valueColumnEditing) {

                if (!errors.find(item => item.name === event.column?.column_name)) {
                  const tempError: IErrorHelper = {
                    name: event.column?.column_name,
                    errors: [] as IError[]
                  } as IErrorHelper

                  tempError.errors.push({
                    message: "Bạn đã nhập dư số tiền cần thanh toán",
                    status: 1
                  } as IError)



                  const tempErrorHelper = [...errors];
                  tempErrorHelper.push(tempError)
                  setErrors(tempErrorHelper)

                }

                const updatedItem = [...rows].map((item) => {

                  if (event.row.stt_rec_hd === item.stt_rec_hd) {
                    return {
                      ...event.row,
                      tien_tt: columnEditing?.value
                    }
                  } else {
                    return item;

                  }
                })

                setRows(updatedItem)
              }
              else {
                const errorTemp = [...errors];
                const errorIndex = errorTemp.findIndex(item => item.name === event.column?.column_name)
                if (errorIndex > -1) {
                  errorTemp.splice(errorIndex, 1);
                  setErrors(errorTemp)
                }
                updateDataItemGrid()

                // gọi khi nhấn enter

                props.blurCurrencyInput && props.blurCurrencyInput(e, event.column, event.row);
              };
              console.log(rows, "keydown")

            }
          }}
          handleBlur={(e) => {
            if (!columnEditing) {
              setColumnEditing({
                columnName: event.column.name,
                value: event.row.tien_tt
              })
            }
            const valueColumnEditing = columnEditing?.value ?? event.row.tien_tt;

            if (inputMoneyEdit! > valueColumnEditing && valueColumnEditing) {

              if (!errors.find(item => item.name === event.column?.column_name)) {
                const tempError: IErrorHelper = {
                  name: event.column?.column_name,
                  errors: [] as IError[]
                } as IErrorHelper

                tempError.errors.push({
                  message: "Bạn đã nhập dư số tiền cần thanh toán",
                  status: 1
                } as IError)



                const tempErrorHelper = [...errors];
                tempErrorHelper.push(tempError)
                setErrors(tempErrorHelper)

              }

              const updatedItem = [...rows].map((item) => {

                if (event.row.stt_rec_hd === item.stt_rec_hd) {
                  return {
                    ...event.row,
                    tien_tt: columnEditing?.value
                  }
                } else {
                  return item;

                }
              })

              setRows(updatedItem)
            }
            else {
              const errorTemp = [...errors];
              const errorIndex = errorTemp.findIndex(item => item.name === event.column?.column_name)
              if (errorIndex > -1) {
                errorTemp.splice(errorIndex, 1);
                setErrors(errorTemp)
              }
              updateDataItemGrid();
              // gọi khi blur input
              props.blurCurrencyInput && props.blurCurrencyInput(e, event.column, event.row);
            };
          }}
          objDataChangeGrid={rowsChanged}
        />

      ) : event.column.data_type === "nvarchar" ? (
        <DHSInputText
          row={event.row}
          column={event.column}
          placeholder={event.column.name_vn}
          value={event.value}
          onChange={handleChangeValue}
          handleEnterPress={(event) => {
            if (event.key === 'Enter') {
              updateDataItemGrid();
              console.log(rows, "keydown")

            }
          }}
          handleBlur={(even) => {
            updateDataItemGrid();
            console.log(rows, "blur")
          }}
          className={"dhs-input-text"}
        />
      ) : event.column.data_type === "date" ? (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DHSDate
            id={event.row.id}
            name={event.column?.column_name}
            value={event.value}
            label={""}
            disabled={false}
            className={""}
            onChange={(v: any | null) => handleChangeValue(v, event.row, event.column)}
            objDataChangeGrid={rowsChanged}
          />
        </LocalizationProvider>

      ) : (
        <></>
      )

    );
  }, [rows, columnEditing]);
  // data grid change
  const handleDataGridChange = (rowId: string, colName: string, value: any) => {
    var index = rows.findIndex((e) => e.id === rowId);
    rows[index][colName] = value;
    // setRows([...rows]);
  };
  const handleChangeValue = (v: any, e: any, column: any) => {
    let colName = column.column_name;
    // if (column.column_name.endsWith('_f')) colName = column.column_name.slice(0, -2);
    let changedRows: any = rowsChanged?.map((row) =>
      e.stt_rec_hd === row.stt_rec_hd ? { ...row, [colName]: v } : row
    );

    // setRowChanged(changedRows);
    rowsChanged = changedRows;
    props.onChangeData(changedRows);
  };
  const DetailContent = (row: any, rest: any) => {
    return (
      <TextField
        margin="normal"
        name="Prefix"
        label="Title"
        value={'row.Prefix'}
      />
    );
  };

  const CurrencyFormatter = (value: any) => (
    // <div style={{ textAlign: 'right', paddingRight: "0.5rem" }}>{value.value ? value.value.toLocaleString("en-US") : 0}</div>
    <div style={{ textAlign: 'right', paddingRight: "0.5rem" }}>{value.value ? ToVietNamCurrency(value.value) : 0}</div>
  );

  const CurrencyTypeProvider = (props: any) => (
    <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />
  );

  // ImputText component custom
  //   const LookupEditorIT = (e: any, onValueChange: any) => {
  //     const handleChange = (data: string) => {
  //       handleDataGridChange(e.row.id, e.column.name, data);
  //     };

  //     return (
  //       <LocalizationProvider dateAdapter={AdapterMoment}>
  //         <DHSInputText
  //           placeholder={e.column.name}
  //           value={e.value}
  //           onChange={handleChange}
  //           row={e.row}
  //         />
  //       </LocalizationProvider>
  //     );
  //   };

  //   const Lookup1EditorIT = (e: any, onValueChange: any) => {
  //     const handleChange = (data: string) => {
  //       handleDataGridChange(e.row.id, e.column.name, data);
  //     };

  //     return (
  //       <LocalizationProvider dateAdapter={AdapterMoment}>
  //         <DHSInputText
  //           placeholder={e.column.name}
  //           value={e.value}
  //           onChange={handleChange}
  //         />
  //       </LocalizationProvider>
  //     );
  //   };

  //CheckBox component custom
  //   const LookupEditorCB = (event: any, onValueChange: any) => {
  //     let i = 0;
  //     const [checked, setChecked] = useState<any | null>(rows[i].have_family);

  //     const onCheckBoxChange = (e: ChangeEvent<HTMLInputElement>): void => {
  //       if (checked || null) {
  //         setChecked(false);
  //         // console.log(checked, event);
  //         handleDataGridChange(event.row.id, event.column.name, false);
  //       }
  //       if (checked === false) {
  //         setChecked(true);
  //         // console.log(checked, event);
  //         handleDataGridChange(event.row.id, event.column.name, true);
  //       }
  //     };
  //     return (
  //       <LocalizationProvider dateAdapter={AdapterMoment}>
  //         <DHSCheckBox
  //           id="checkbox"
  //           name="checkbox"
  //           className="checkbox"
  //           // label='label?'
  //           checked={checked ? checked : null}
  //           disabled={false}
  //           labelPosition="right"
  //           onChange={onCheckBoxChange}
  //         />
  //       </LocalizationProvider>
  //     );
  //   };

  //   // ImputMoney component custom
  //   const LookupEditorIM = (event: any, onValueChange: any) => {
  //     console.log(event.column.data_type);
  //     const [value, setMoney] = useState<any | null>(event.value);
  //     const i = 0;
  //     return (
  //       <LocalizationProvider dateAdapter={AdapterMoment}>
  //         {event.column.data_type && event.column.data_type === "money" ? (
  //           <DHSInputMoney
  //             currency={"VND"}
  //             value={event.value}
  //             id={event.row.id}
  //             name={event.column?.column_name}
  //             className="inputMoney"
  //             disabled={false}
  //             readOnly={false}
  //             error={false}
  //             errorMessage="da co loi xay ra"
  //             currencyPosition="right"
  //             numbersAfterDecimal={5}
  //           />
  //         ) : event.column.data_type === "nvarchar" ? (
  //           <DHSInputText
  //             column={event.column}
  //             placeholder={event.column.name_vn}
  //             value={event.value}
  //             onChange={handleChangeValue}
  //             row={event.row}
  //           />
  //         ) : (
  //           <></>
  //         )}
  //       </LocalizationProvider>
  //     );
  //   };

  //   // Select component custom

  //   const LookupEditorSLs = (e: any, onValueChange: any) => {
  //     const [products, setProducts] = useState<Product[]>([
  //       {
  //         PRODUCTID: "ERP1",
  //         PRODUCTNAME: "Giải pháp Quản trị doanh nghiệp (DHSoft ERP)1",
  //       },
  //       {
  //         PRODUCTID: "ERP2",
  //         PRODUCTNAME: "Giải pháp Quản trị doanh nghiệp (DHSoft ERP)2",
  //       },
  //       {
  //         PRODUCTID: "ERP3",
  //         PRODUCTNAME: "Giải pháp Quản trị doanh nghiệp (DHSoft ERP)3",
  //       },
  //       {
  //         PRODUCTID: "ERP4",
  //         PRODUCTNAME: "Giải pháp Quản trị doanh nghiệp (DHSoft ERP)4",
  //       },
  //       {
  //         PRODUCTID: "ERP5",
  //         PRODUCTNAME: "Giải pháp Quản trị doanh nghiệp (DHSoft ERP)5",
  //       },
  //     ]);
  //     const [value, setValue] = useState<string>("");
  //     const [value2, setValue2] = useState<string[]>([]);
  //     const [productsLv2, setProductsLv2] = useState<Product[]>([]);

  //     const onChange = (_value: string | string[]) => {
  //       setValue(_value as string);
  //       const newAr: Product[] = products.filter(
  //         (item) => item.PARENTID === _value && item.LEVEL === 2
  //       );
  //       setProductsLv2(newAr);
  //       setValue2([]);
  //       // console.log(e);
  //       // handleChangeSelect(e.row.id, e.column.name, _value);
  //       handleDataGridChange(e.row.id, e.column.name, _value);
  //     };
  //     const onChange1 = (_value: string | string[]) => {
  //       if (value2.some((item) => item === (_value as string))) {
  //         setValue2(value2.filter((item) => item !== (_value as string)));
  //       } else {
  //         setValue2([...value2, _value as string]);
  //       }
  //     };
  //     return (
  //       <LocalizationProvider dateAdapter={AdapterMoment}>
  //         <DHSSelect
  //           options={products.map((item) => ({
  //             value: item.PRODUCTID,
  //             label: item.PRODUCTID,
  //           }))}
  //           label={""}
  //           onChange={onChange}
  //           fieldValue={""}
  //           fieldDisplay={""}
  //         />
  //       </LocalizationProvider>
  //     );
  //   };

  //   const LookupEditorSLm = (e: any, onValueChange: any) => {
  //     const [products, setProducts] = useState<Product[]>([
  //       {
  //         PRODUCTID: "ERP1",
  //         PRODUCTNAME: "Giải pháp Quản trị doanh nghiệp (DHSoft ERP)1",
  //       },
  //       {
  //         PRODUCTID: "ERP2",
  //         PRODUCTNAME: "Giải pháp Quản trị doanh nghiệp (DHSoft ERP)2",
  //       },
  //       {
  //         PRODUCTID: "ERP3",
  //         PRODUCTNAME: "Giải pháp Quản trị doanh nghiệp (DHSoft ERP)3",
  //       },
  //       {
  //         PRODUCTID: "ERP4",
  //         PRODUCTNAME: "Giải pháp Quản trị doanh nghiệp (DHSoft ERP)4",
  //       },
  //       {
  //         PRODUCTID: "ERP5",
  //         PRODUCTNAME: "Giải pháp Quản trị doanh nghiệp (DHSoft ERP)5",
  //       },
  //     ]);
  //     const [value, setValue] = useState<string>("");
  //     const [value2, setValue2] = useState<string[]>([]);
  //     const [productsLv2, setProductsLv2] = useState<Product[]>([]);
  //     //
  //     // const currentValue = useRef<string>();

  //     const onChange = (_value: string | string[]) => {
  //       setValue(_value as string);
  //       const newAr: Product[] = products.filter(
  //         (item) => item.PARENTID === _value && item.LEVEL === 2
  //       );
  //       setProductsLv2(newAr);
  //       setValue2([]);
  //     };
  //     const onChange1 = (_value: string | string[]) => {
  //       if (value2.some((item) => item === (_value as string))) {
  //         setValue2(value2.filter((item) => item !== (_value as string)));
  //       } else {
  //         setValue2([...value2, _value as string]);
  //       }

  //       handleDataGridChange(e.row.id, e.column.name, _value);
  //     };
  //     return (
  //       <LocalizationProvider dateAdapter={AdapterMoment}>
  //         <DHSSelect
  //           options={products.map((item) => ({
  //             value: item.PRODUCTID,
  //             label: item.PRODUCTID,
  //           }))}
  //           label={""}
  //           onChange={onChange1}
  //           multiple
  //           fieldValue={""}
  //           fieldDisplay={""} // disabled
  //         />
  //       </LocalizationProvider>
  //     );
  //   };

  // select table

  //   const LookupEditorSLTBs = (e: any, onValueChange: any) => {
  //     const [products, setProducts] = useState<Column[]>([
  //       { name: "name", title: "Name" },
  //       { name: "gender", title: "Gender" },
  //       { name: "city", title: "City" },
  //       { name: "car", title: "Car" },
  //     ]);

  //     const [value3, setValue3] = useState<string | string[] | null>(null);
  //     const onChange3 = (_value: string | string[] | null, _col: string) => {
  //       setValue3(_value);
  //       // console.log(_value);
  //     };
  //     return (
  //       <LocalizationProvider dateAdapter={AdapterMoment}>
  //         <DHSSelectTable
  //           label=""
  //           data={products}
  //           keyDisplayValue={"PRODUCTID"}
  //           keyValue={"PRODUCTID"}
  //           onChange={onChange3}
  //           rows={rows}
  //           columns={columns}
  //         />
  //       </LocalizationProvider>
  //     );
  //   };
  //   const LookupEditorSLTBm = (e: any, onValueChange: any) => {
  //     const [products, setProducts] = useState<Column[]>([
  //       { name: "name", title: "Name" },
  //       { name: "gender", title: "Gender" },
  //       { name: "city", title: "City" },
  //       { name: "car", title: "Car" },
  //     ]);

  //     const [value3, setValue3] = useState<string | string[] | null>(null);
  //     const onChange3 = (_value: string | string[] | null, _col: string) => {
  //       setValue3(_value);
  //     };
  //     return (
  //       <LocalizationProvider dateAdapter={AdapterMoment}>
  //         <TextField margin="normal" label="State" select name="StateID">
  //           <DHSSelectTable
  //             label=""
  //             data={products}
  //             keyDisplayValue={"PRODUCTID"}
  //             keyValue={"PRODUCTID"}
  //             onChange={onChange3}
  //             multiple
  //             rows={rows}
  //             columns={columns}
  //           />
  //         </TextField>
  //       </LocalizationProvider>
  //     );
  //   };

  // DatePicker Component custom

  // const Lookup1Editor = (e: any, onValueChange: any) => {
  //   var columnName = "birth_datetime" + new Date().getTime();
  //   if (e.row) columnName = "birth_datetime" + e.row.id.toString();

  //   const handleChange = (data: string) => {
  //     // handleChangeDateTime(e.row.id, dateTime);
  //     const dateTime = moment(data, "DD/MM/YYYY HH:mm").format(
  //       "YYYY/MM/DD HH:mm:ss"
  //     );
  //     handleDataGridChange(e.row.id, e.column.name, dateTime);
  //   };

  //   return (
  //     <LocalizationProvider dateAdapter={AdapterMoment}>
  //       <DHSDateTime
  //         label={""}
  //         disabled={false}
  //         className={""}
  //         name={""}
  //         onChange={function (v: any): void {
  //           throw new Error("Function not implemented.");
  //         }}
  //       />
  //     </LocalizationProvider>
  //   );
  // };
  // const Lookup2Editor = (e: any, onValueChange: any) => {
  //   var columnName = "birth_time" + new Date().getTime();
  //   if (e.row) columnName = "birth_time" + e.row.id.toString();

  //   const handleChange = (data: any) => {
  //     // handleChangeTime(e.row.id, time);
  //     const time = moment(data, "HH:mm").format("HH:mm:ss");
  //     handleDataGridChange(e.row.id, e.column.name, time);
  //   };

  //   return (
  //     <LocalizationProvider dateAdapter={AdapterMoment}>
  //       <DHSTimePicker
  //         handleChange={handleChange}
  //         value={e.value}
  //         columnName={columnName}
  //         tbName={"tbName-in-Sql"}
  //         code={"code-in-Sql"}
  //       />
  //     </LocalizationProvider>
  //   );
  // };

  //   // DataTypeProvider for the InputText Component
  //   const LookupFormatterIT = (props: any) => (
  //     <DataTypeProvider
  //       editorComponent={(params) => <LookupEditorIT {...params} />}
  //       {...props}
  //     />
  //   );

  //   const Lookup1FormatterIT = (props: any) => (
  //     <DataTypeProvider
  //       editorComponent={(params) => <Lookup1EditorIT {...params} />}
  //       {...props}
  //     />
  //   );

  //   // DataTypeProvider for the CheckBox Component
  //   const LookupFormatterCB = (props: any) => (
  //     <DataTypeProvider
  //       editorComponent={(params) => <LookupEditorCB {...params} />}
  //       {...props}
  //     />
  //   );

  //   // DataTypeProvider for the Select Component
  //   const LookupFormatterSLs = (props: any) => (
  //     <DataTypeProvider
  //       editorComponent={(params) => <LookupEditorSLs {...params} />}
  //       {...props}
  //     />
  //   );
  //   const LookupFormatterSLm = (props: any) => (
  //     <DataTypeProvider
  //       editorComponent={(params) => <LookupEditorSLm {...params} />}
  //       {...props}
  //     />
  //   );

  // DataTypeProvider for the Select table Component

  //   const LookupFormatterSLTBs = (props: any) => (
  //     <DataTypeProvider
  //       editorComponent={(params) => <LookupEditorSLTBs {...params} />}
  //       {...props}
  //     />
  //   );
  //   const LookupFormatterSLTBm = (props: any) => (
  //     <DataTypeProvider
  //       editorComponent={(params) => <LookupEditorSLTBm {...params} />}
  //       {...props}
  //     />
  //   );
  //   // DataTypeProvider for the InputMoney Component
  //   const LookupFormatterIM = (props: any) => (
  //     <DataTypeProvider
  //       editorComponent={(params) => <LookupEditorIM {...params} />}
  //       {...props}
  //     />
  //   );
  //   // DataTypeProvider for the DatePicker Component

  //   const Lookup1Formatter = (props: any) => (
  //     <DataTypeProvider
  //       editorComponent={(params) => <Lookup1Editor {...params} />}
  //       {...props}
  //     />
  //   );
  //   const Lookup2Formatter = (props: any) => (
  //     <DataTypeProvider
  //       editorComponent={(params) => <Lookup2Editor {...params} />}
  //       {...props}
  //     />
  //   );
  //   const Popup = (
  //     row: any,
  //     onChange: any,
  //     onApplyChanges: any,
  //     onCancelChanges: any,
  //     open: any
  //   ) => (
  //     <Dialog
  //       open={open}
  //       onClose={onCancelChanges}
  //       aria-labelledby="form-dialog-title"
  //     >
  //       <DialogTitle id="form-dialog-title">Employee Details</DialogTitle>
  //       <DialogContent>
  //         <MuiGrid container spacing={3}>
  //           <MuiGrid item xs={6}>
  //             <FormGroup>
  //               <TextField
  //                 margin="normal"
  //                 name="firstName"
  //                 label="First Name"
  //                 value={row.firstName || ""}
  //                 onChange={onChange}
  //               />
  //               <TextField
  //                 margin="normal"
  //                 name="prefix"
  //                 label="Title"
  //                 value={row.prefix || ""}
  //                 onChange={onChange}
  //               />
  //               <TextField
  //                 margin="normal"
  //                 name="position"
  //                 label="Position"
  //                 value={row.position || ""}
  //                 onChange={onChange}
  //               />
  //             </FormGroup>
  //           </MuiGrid>
  //           <MuiGrid item xs={6}>
  //             <FormGroup>
  //               <TextField
  //                 margin="normal"
  //                 name="lastName"
  //                 label="Last Name"
  //                 value={row.lastName || ""}
  //                 onChange={onChange}
  //               />
  //               <LocalizationProvider dateAdapter={AdapterMoment}>
  //                 <DatePicker
  //                   renderInput={(props) => (
  //                     <TextField margin="normal" {...props} />
  //                   )}
  //                   label="Birth Date"
  //                   value={row.BirthDate}
  //                   onChange={(value) =>
  //                     onChange({
  //                       target: {
  //                         name: "BirthDate",
  //                         value,
  //                       },
  //                     })
  //                   }
  //                   inputFormat="DD/MM/YYYY"
  //                 />
  //               </LocalizationProvider>
  //               <TextField
  //                 margin="normal"
  //                 name="phone"
  //                 label="Phone"
  //                 value={row.phone || ""}
  //                 onChange={onChange}
  //               />
  //             </FormGroup>
  //           </MuiGrid>
  //         </MuiGrid>
  //       </DialogContent>
  //       <DialogActions>
  //         <Button onClick={onCancelChanges} color="secondary">
  //           Cancel
  //         </Button>
  //         <Button onClick={onApplyChanges} color="primary">
  //           Save
  //         </Button>
  //       </DialogActions>
  //     </Dialog>
  //   );
  const LookupFormatter = (props: any) => (
    <DataTypeProvider

      editorComponent={(params) => <LookupEditor {...params} />}
      {...props}
    />
  );

  const summaryItems = [
    { columnName: 'tien_tt_nt', type: 'sum', alignByColumn: false },
    { columnName: 'tien_tt', type: 'sum', alignByColumn: false },
    { columnName: "tt_nt", type: 'sum', alignByColumn: false },
    { columnName: "tt", type: 'sum', alignByColumn: false },
  ];


  React.useEffect(() => {

    const tempData = [...rows];

    const selectionIdTemp = [...selectionid];
    const selectionTemp = [...selection];

    selectionid.forEach((idselect, idx) => {
      const index = tempData.findIndex(x => x.stt_rec_hd === idselect);
      if (index > -1) {
        tempData.splice(index, 1);
        selectionIdTemp.splice(idx, 1)
        const idxSelection = selectionTemp.findIndex(x => x === index)
        selectionTemp.splice(idxSelection, 1)
      }
    })

    setSelectionid(selectionIdTemp);
    setSelection(selectionTemp);





    // const tempData01D0 = [...dataTable01D0];

    // if (tempData01D1.length === 0) {
    //     const index = tempData01D0.findIndex(x => x.stT_REC === selectionid[0]);
    //     tempData01D0.splice(index, 1)
    //     setDataTable01D0(tempData01D0);


    // }

    // setDataTable05M(tempData01D1);




  }, [props.data]);

  return (
    <Paper>
      {columns.length > 0 ? (
        <Grid
          rows={rows}
          columns={columns}


        >
          <FilteringState />
          <PagingState defaultCurrentPage={0} defaultPageSize={10} />
          <SelectionState
            selection={selection}
            onSelectionChange={handleRowChange}
          />
          <LookupFormatter for={lookupColumns} />
          <CurrencyTypeProvider for={currencyColumns} />
          <EditingState
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={setEditingRowIds}
            rowChanges={rowChanges}
            onRowChangesChange={setRowChanges}
            addedRows={addedRows}
            onAddedRowsChange={changeAddedRows}
            onCommitChanges={commitChanges}
            columnExtensions={editingStateColumnExtensions}

          />
          <IntegratedPaging />
          <SortingState />
          <IntegratedSorting />
          <Table columnExtensions={tableColumnExtensions} />
          <TableHeaderRow showSortingControls
          // cellComponent={(props) => {
          //   // const { column, tableRow } = props;
          //   console.log(props, "TableHeaderRow")
          //   if ((props.column as SysGenRowTableModel).data_type === "money") {
          //     // alert((props.column as SysGenRowTableModel).data_type)
          //     return <TableCell style={{ textAlign: 'right' }}>
          //       {(props.column as SysGenRowTableModel).title}
          //     </TableCell>
          //   }
          //   else {
          //     return <TableCell style={{ textAlign: 'center' }}>
          //       {(props.column as SysGenRowTableModel).title}
          //     </TableCell>
          //   }
          // }}
          />
          {/* <TableFilterRow /> */}
          <TableColumnVisibility
            defaultHiddenColumnNames={defaultHiddenColumnNames}
          />
          <TableSelection />
          <TableInlineCellEditing
            startEditAction="click"
            selectTextOnEditStart={selectTextOnEditStart}
          />
          {/* <VirtualTable
                            columnExtensions={tableColumnExtensions}
                            cellComponent={Cell}
                        /> */}
          <ToolbarFilterComponent param={""} />
          <PagingPanel pageSizes={pageSizes} />
          <TableEditRow />
          {/* <TableEditColumn
            showAddCommand
            showEditCommand
            showDeleteCommand
            cellComponent={CommandCell}
            commandComponent={Command}
          /> */}

          <SummaryState
            totalItems={summaryItems}
          />
          <IntegratedSummary />
          <TableSummaryRow messages={{ sum: "Tổng tiền" }} itemComponent={(props) => <div className="w-100" style={{ textAlign: 'right', fontSize: "14px", fontWeight: 700 }}>{`${typeof props.value === 'string' ? ToVietNamCurrency(Number.parseInt(props.value as string)) : ToVietNamCurrency(props.value)}`}</div>} />

        </Grid>
      ) : (
        ""
      )}
    </Paper>
  );
};

export default DHSGridEdit;
