import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { getFileExtension, isImageFile, isPDFFile } from "../../utils/file.util";
import DHSButton from "../../DHSComponents/actions-and-list/DHSButton/DHSButton";
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import { IAction } from "../../../common/Interfaces/Interfaces";
import DHSZoomFile from "../DHSZoomFile/DHSZoomFile";
import DHSTextArea from "../../DHSComponents/editors/DHSTextArea/DHSTextArea";
import ModeEditOutlineFilledIcon from '@mui/icons-material/ModeEditOutline';
import VisibilityFilledIcon from '@mui/icons-material/Visibility';
import { isNullOrUndefinedOrEmpty } from "../../utils/object.util";
import { ACTIONS } from "../../../common/enums/DHSToolbarRoleAction";

const defaultAction: IAction = {
    open: false,
    type: "",
    index: 0,
    payload: null
}


const cancelButtonStyle: React.CSSProperties = {
    position: "absolute",
    top: 1,
    right: 1,
    cursor: "pointer",
};

const uploadButtonStyle: React.CSSProperties = {
    position: "absolute",
    top: 1,
    right: "12%",
    cursor: "pointer",
};

const fileInputStyle: React.CSSProperties = {
    display: "none",
};

const dropzoneMessage: { [key: string]: string } = {
    "": "Kéo thả, click hoặc dán vào để thêm mới...",
    "paste": "Click hoặc dán hình vào để thêm mới...",
    "drag": "Thả hình vào để thêm mới...",
    "upload": "Chọn hình để thêm mới..."
}
const dropzoneIcon: { [key: string]: JSX.Element } = {
    "": <i className="bi bi-cloud-upload" style={{ fontSize: 42, display: "block", textAlign: "center" }} />,
    "paste": <i className="bi bi-clipboard2-plus" style={{ fontSize: 42, display: "block", textAlign: "center" }}></i>,
    "drag": <i className="bi bi-cloud-plus" style={{ fontSize: 42, display: "block", textAlign: "center" }}></i>,
    "upload": <i className="bi bi-upload" style={{ fontSize: 42, display: "block", textAlign: "center" }}></i>
}


export interface IAttachMultipleImage {
    id?: string,
    file?: File,
    fileUrl?: string,
    fileName?: string,
    filePath?: string,
    extensionData?: IAttachMultipleImageExtensionData
}
export interface IAttachMultipleImageExtensionData {
    desctiption?: string
}

interface IProps {
    accept?: string,
    dataFile?: IAttachMultipleImage[],
    name: string,
    handleRemoveImage?: (data: any) => void,
    handleChangeImage?: (data: any, index: number) => void,
    handleAddNewImage?: (data: any) => void,
    handleChangeExtensionData?: (data: IAttachMultipleImageExtensionData, index: number) => void,
    width?: string | number,
    height?: string | number,
    imageDisplayWidth?: string | number,
    imageDisplayHeight?: string | number,
    disable?: boolean,
    isExtensionData?: boolean,
    action?: ACTIONS
}

const disableIfActions = [ACTIONS.EDIT]
const DHSUploadMultipleImage: React.FC<IProps> = (props) => {
    const dropZoneRef = React.useRef<HTMLDivElement>(null);
    const [dropzoneType, setDropzoneType] = React.useState<"" | "paste" | "drag" | "upload">("");

    // 
    const [actVd, setActVd] = React.useState<IAction<IAttachMultipleImage>>(defaultAction);

    // 

    const [exDataFileEditMode, setExDataFileEditMode] = React.useState<boolean>(true);

    // 

    const imageStyle: React.CSSProperties = {
        position: "relative",
        width: props?.width ?? "213px",
        minWidth: props?.width ?? "213px",
        maxWidth: props?.width ?? "213px",
        height: props?.height ?? "154px",
        minHeight: props?.height ?? "154px",
        maxHeight: props?.height ?? "154px",
    };

    const displayChangeAndRemoveFile = (index: number) => {
        return (
            <>
                <div className="position-absolute d-flex justify-content-end" style={cancelButtonStyle}>
                    <IconButton
                        color="error"
                        aria-label="upload picture"
                        component="label"
                        onClick={() => {
                            if(!props?.action || (props.action && !disableIfActions.includes(props.action))){
                                props?.handleRemoveImage && props?.handleRemoveImage({
                                    index: index,
                                    data: props?.dataFile ? props.dataFile[index]  : undefined,
                                    name: props?.name
                                });
                                setDropzoneType("")
                            }
                        }}
                        size="small"
                        disabled={props?.disable ?? (props?.action ? disableIfActions.includes(props?.action) : undefined)}
                    >
                        <CancelIcon sx={{ fontSize: 24, backgroundColor: "#fff", borderRadius: "50%" }} />
                    </IconButton>
                </div>
                <div className="position-absolute d-flex justify-content-end" style={uploadButtonStyle}>
                    <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                        size="small"
                        disabled={props.disable }
                    >
                        <input
                            id={`uploadImageUpdate`}
                            className="flex-grow-1 form-control"
                            type="file"
                            accept={props?.accept ?? "image/png, image/gif, image/jpeg"}
                            name={props?.name}
                            onChange={(e) => props?.handleChangeImage && props?.handleChangeImage(e, index)}
                            style={fileInputStyle}
                        />
                        <ChangeCircleIcon sx={{ fontSize: 24, backgroundColor: "#fff", borderRadius: "50%" }} />
                    </IconButton>
                </div></>
        )
    }

    const displayExDataFile = (index: number, extensionData?: IAttachMultipleImageExtensionData) => {
        return exDataFileEditMode ? <div>
            <DHSTextArea
                label="Mô tả"
                labelMode="floating"
                value={extensionData?.desctiption}
                onValueChanged={(e) => props?.handleChangeExtensionData && props?.handleChangeExtensionData({ desctiption: e.value } as IAttachMultipleImageExtensionData, index)}
            />
        </div> : <Stack direction={"row"} columnGap={"5px"}>
        <Typography variant="body1" color={"#333"} fontWeight={500}>{"Mô tả: "}</Typography>

            <Typography variant="body1" color={"#afafb0"}>{!isNullOrUndefinedOrEmpty(extensionData?.desctiption) ? extensionData?.desctiption : "Không có mô tả"}</Typography>
        </Stack>
    }


    const imageFileDisplay = (fileUrl: string, fileName: string, index: number, extensionData?: IAttachMultipleImageExtensionData,) => {
       return <div className="d-flex flex-column" style={{ width: "inherit", height: "inherit", gap: "5px" }}>
            <div>
                <span style={{ fontSize: "15px", fontWeight: 500 }}>{fileName}</span>
            </div>
            {
                props?.isExtensionData ? displayExDataFile(index, extensionData) : <></>
            }
            <div
                className="image-shadow"
                style={{
                    position: "relative",
                    width: props?.imageDisplayWidth ?? "320px",
                    height: props?.imageDisplayHeight ?? "410px",
                    objectFit: 'contain',
                    backgroundImage: `url("${fileUrl ?? ""}")`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "10px",
                }}
            >
                {
                    <div className="position-absolute">
                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                setActVd(pre => ({
                                    ...pre, open: true, payload: {
                                        fileName: fileName,
                                        fileUrl: fileUrl
                                    }
                                }))
                            }}
                        >
                            <CenterFocusWeakIcon sx={{ fontSize: 24, backgroundColor: "#fff", borderRadius: "5px" }} />
                        </IconButton>
                    </div>
                }
                {
                    displayChangeAndRemoveFile(index)
                }
            </div>

        </div>
    }

   

    const addNewFileDisplay = () => {
        return (
            <div className="m-1" style={{ width: "inherit", height: "inherit", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <label
                    htmlFor={`uploadImageAdd${props?.name}`}
                    style={imageStyle}
                    className="label-file-media"
                    onClick={() => {
                        const el = dropZoneRef.current;
                        if (el) {
                            el.style.backgroundColor = "#fff"
                            el.style.border = "3px double #a3c4e7"
                            el.style.outline = "none"
                            setDropzoneType("upload")
                        }
                    }}
                >
                    <div
                        ref={dropZoneRef}
                        className="c-img-card"
                        contentEditable
                        suppressContentEditableWarning
                        style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: '5px',
                            caretColor: "transparent",
                           
                        }}
                        
                        onInput={(e) => e.preventDefault()}
                        onBeforeInput={(e) => e.preventDefault()}
                        onDragEnter={(e) => {
                            e.preventDefault()
                            const el = dropZoneRef.current;
                            if (el) {
                                el.style.border = "3px dashed #a3c4e7"
                                el.style.backgroundColor = "#fff"
                                setDropzoneType("drag")
                            }
                        }}
                        onDragOver={(e) => {
                            e.preventDefault()
                            const el = dropZoneRef.current;
                            if (el) {
                                el.style.border = "3px dashed #a3c4e7"
                                el.style.backgroundColor = "#fff"
                                setDropzoneType("drag")
                            }
                        }}
                        onDragLeave={(e) => {
                            e.preventDefault();
                            const el = dropZoneRef.current;
                            if (el && dropzoneType === "drag") {
                                el.style.border = "3px dashed #a3c4e7"
                                el.style.backgroundColor = "#f3f8fe"
                                setDropzoneType("")
                            }
                        }}
                        onDrop={(e) => {
                            e.preventDefault()
                            const files = e.dataTransfer.files;
                            console.log(files)
                            props?.handleAddNewImage && props?.handleAddNewImage({
                                target: {
                                    name: props?.name,
                                    files,
                                    value: files[0].name
                                }
                            })
                        }}
                        onPaste={(e) => {
                            e.preventDefault()
                            const clipboardData = e.clipboardData || (window as any).clipboardData;
                            if (clipboardData) {
                                const items = clipboardData.items;
                                for (let i = 0; i < items.length; i++) {
                                    const item = items[i];
                                    if (item.kind === 'file') {
                                        const file = item.getAsFile();
                                        props?.handleAddNewImage && props?.handleAddNewImage({
                                            target: {
                                                name: props?.name,
                                                files: [file],
                                                value: file?.name
                                            }
                                        })
                                    }
                                }
                            }
                        }}
                        onMouseEnter={(e) => {
                            const el = dropZoneRef.current;
                            if (el) {
                                el.focus()
                                el.style.backgroundColor = "#fff"
                                el.style.border = "3px dotted #a3c4e7"
                                el.style.outline = "none"
                                setDropzoneType("paste")
                            }
                        }}
                        onMouseLeave={(e) => {
                            const el = dropZoneRef.current;
                            if (el && dropzoneType === "upload") {
                                el.style.backgroundColor = "#f3f8fe"
                                el.style.border = "3px dashed #a3c4e7"
                                setDropzoneType("")
                            }
                        }}
                        onBlur={(e) => {
                            const el = dropZoneRef.current;
                            if (el && dropzoneType === "paste") {
                                el.style.backgroundColor = "#f3f8fe"
                                el.style.border = "3px dashed #a3c4e7"
                                setDropzoneType("")
                            }
                        }}
                    >
                        <div className="d-flex flex-column justify-content-center">
                            {dropzoneIcon[dropzoneType]}
                            <span style={{ fontSize: 13, textAlign: "center", display: "block", padding: "0.25rem 1.25rem" }}>&nbsp;{
                                dropzoneMessage[dropzoneType]
                            }</span>
                        </div>
                    </div>
                </label>
                <input
                    id={`uploadImageAdd${props?.name}`}
                    className="flex-grow-1 form-control"
                    type="file"
                    accept={props?.accept ?? "image/png, image/gif, image/jpeg"}
                    name={props?.name}
                    multiple
                    onChange={(e) => props?.handleAddNewImage && props?.handleAddNewImage(e)}
                    style={fileInputStyle}
                    disabled={props?.disable}
                />
            </div>
        )
    }

    return (
        <div className="row">
            {
                <DHSZoomFile
                    open={actVd.open}
                    onClose={() => {
                        setActVd(defaultAction)
                    }}
                    data={actVd.payload}
                />
            }
            <div className="col-12">
                {!props?.action || (props?.action && !disableIfActions.includes(props?.action)) ?  addNewFileDisplay() : <></>}


            </div>
            <div className="col-12 py-2 d-flex flex-row align-items-center">
                <span className="d-block" style={{ fontSize: 14, fontWeight: 500 }}>Hình đã upload
                    <span style={{ color: "#f59e0b" }}> {props?.dataFile?.length} image(s)</span>
                </span>
                <div className="mx-auto"></div>
                {
                    (props?.isExtensionData && props?.dataFile?.length) ? <div>
                    <IconButton
                        sx={{color: "#fff"}}
                        aria-label="upload picture"
                        component="label"
                        size="medium"
                        onClick={(e) => {
                            setExDataFileEditMode(pre => !pre)
                        }}
                    >
                        {
                            exDataFileEditMode ? <VisibilityFilledIcon  sx={{ fontSize: 28, backgroundColor: "#333", padding: "4px", borderRadius: "50%" }} /> : <ModeEditOutlineFilledIcon sx={{ fontSize: 28, backgroundColor: "#fd7a4d", padding: "4px", borderRadius: "50%" }} />
                        }
                    </IconButton>
                </div> : <></>
                }
            </div>
            <div style={{ width: "100%" }}>
                <hr style={{ width: "100%", margin: "0", padding: "0.25rem 0rem" }} />
            </div>
            <div className="col-12">
                <div className="row">  {props.dataFile?.length ? props.dataFile.map((file, index) => {
                    const fileName = file?.fileName ?? "";
                    const fileUrl = file?.fileUrl ?? "";
                    const extensionData = file?.extensionData;
                    return (
                        <div key={index} className={`col-12 col-md-auto py-1`}>
                            {
                                isImageFile(fileName) ?
                                    imageFileDisplay(fileUrl, fileName, index, extensionData): <></>
                            }
                        </div>
                    )
                }) : <div className="text-center w-100 pt-3" style={{ fontWeight: 500, fontSize: 16 }}>Không có file(s)</div>
                    // display add new file

                }
                </div>
            </div>

        </div>
    );

}

export default DHSUploadMultipleImage;