import React, { useState } from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    HomeClient,
    KscPhieuKiemSoatModel,
    QLCCCitizenIdentificationCardsModel,
    QLCCClient,
    RoleRightWebModel,
    SysActionType,
} from "../../../shared/service-proxies/api-shared";

import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";


import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import '../ApartmentBuilding.css';
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Webcam from "react-webcam";
import CTXDate from "../../../../components/CTX/DatePicker";
import notification from "../../../../common/notification/notification";
import { parseISO } from 'date-fns';
import moment from "moment";
import axios from "axios";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import { TransDateTime } from "../../../../components/utils/date.util";
import DHSUploadImage from "../../../../components/DHS/DHS-Upload-Image/DHSUploadImage";

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    action_type?: any,
    title?: any,
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess?: (id?: string, data?: QLCCCitizenIdentificationCardsModel) => void;
    onAddedSuccess?: (id?: string, data?: QLCCCitizenIdentificationCardsModel) => void;

}
interface IOpenCamera {
    pic: any;
    open: boolean;
}

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
};

const image_size_citizen_identification_card = {
    width: 540,
    height: 360,
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
interface IAction {
    type: string;
    open: boolean;
    payload: any;
}
const defaultAction: IAction = {
    type: "",
    payload: null,
    open: false,
};




var appSession: AppSession;
const status_message = ['success', 'error']
const CitizenIdentificationCardAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const [_id, setId] = React.useState<string>();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();

    const [error, setError] = React.useState<string>();

    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);

    const [data, setData] = React.useState<QLCCCitizenIdentificationCardsModel>(new QLCCCitizenIdentificationCardsModel());
    const [frontSideImage, setFrontSideImage] = React.useState<string>();
    const [backSideImage, setBackSideImage] = React.useState<string>();
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const [errorText, setErrorText] = React.useState<any>();



    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    // 

    const webcamRef = React.useRef<any>(null);

    const [openCamera, setOpenCamera] = React.useState<IOpenCamera>({
        pic: "",
        open: false,
    });

    const handleCloseCamera = () => {
        setOpenCamera({ ...openCamera, pic: "", open: false });
    };

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef?.current?.getScreenshot({ width: 324, height: 204 });
        // fileClient
        //     .postFile_v4([imageSrc.replace("data:image/jpeg;base64,", "")])
        //     .then((res) => {
        //         if (res) {
        //             let tempArr: string[] = [];
        //             res.forEach((path: any, pathIndex: number) => {
        //                 tempArr.push(path);
        //             });
        //             switch (openCamera.pic) {
        //                 case "hinh_luc_vao":
        //                     setListHinhLucVao([...listHinhLucVao].concat(tempArr));
        //                     break;
        //                 case "hinh_luc_ra":
        //                     setListHinhLucRa([...listHinhLucRa].concat(tempArr));
        //                     break;
        //             }
        //         } else notification("warning", "Không lấy được hình ảnh");
        //     });

        if (imageSrc) {
            setData({
                ...data,
                [openCamera.pic]: imageSrc
            } as QLCCCitizenIdentificationCardsModel)
        }


        // setOpenCamera({ ...openCamera, pic: "", open: false });
    }, [webcamRef, openCamera.pic]);

    React.useEffect(() => {
        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {

            qLCCClient.qLCCCitizenIdentificationCardsActions({
                ...new QLCCCitizenIdentificationCardsModel(),
                id: props.itemSelected,
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: apartment_building_id,
                action_type: "GET"
            } as QLCCCitizenIdentificationCardsModel)
                .then((res) => {
                    setData(res[0]);
                })
                .catch(() => alert("Không lấy được dữ liệu"));
            setId(props.itemSelected);
        }
        else {
            setData({
                ...new QLCCCitizenIdentificationCardsModel(),
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: apartment_building_id
            } as QLCCCitizenIdentificationCardsModel)
            setId(undefined);
        }
    }, [props.action, apartment_building_id]);


    React.useEffect(() => {

        setLoading(true);
        homeClient
            ?.roleRightWebGetAll({
                ...new RoleRightWebModel(),
                rolename: "CC",
                username: SessionLogin.userName,
                menuid: menu?.menuid,
            } as RoleRightWebModel)
            .then((res) => {
                setRoles(res);
                setLoading(false);
            });
        setLoading(true);

    }, []);

    React.useMemo(() => {
        if (data.front_side_image)
            setFrontSideImage(data.front_side_image)
    }, [data.front_side_image])

    React.useMemo(() => {
        if (data.back_side_image)
            setBackSideImage(data.back_side_image)

    }, [data.back_side_image])

    // React.useMemo(() => {
    //     setData({
    //         ...data,
    //         front_side_image: frontSideImage
    //     } as QLCCCitizenIdentificationCardsModel)
    // }, [data.front_side_image])

    // React.useEffect(() => {
    //     setLoading(true);

    // }, [actTtt.open]);
    const showCamera = (e: any, file: any) => {
        setOpenCamera({
            ...openCamera,
            pic: file,
            open: true,
        });
    };

    const handleSubmit = (e: any) => {


        const { action } = e;


        setLoading(true)
        switch (props.action) {
            case ACTIONS.EDIT: {
                if (action === "SaveAndClose") {
                    qLCCClient.qLCCCitizenIdentificationCardsActions({
                        ...new QLCCCitizenIdentificationCardsModel(),
                        ...data,
                        date_issue: data.date_issue ? TransDateTime(data.date_issue) : undefined,
                        id: props.itemSelected,
                        action_type: 'UPDATE'
                    } as QLCCCitizenIdentificationCardsModel)
                        .then((response) => {
                            setLoading(false);
                            notification(status_message[response[0].status!], response[0].message);
                            if (response[0]?.status === 0) {

                                props.onEditSuccess && props.onEditSuccess(response[0].id, data);
                                props.onClose();

                            }

                        }).catch(error => {
                            notification(status_message[1], "Có lỗi xãy ra")
                            setLoading(false)
                        });



                }
                break;

            }

            case ACTIONS.ADD: {
                if (action === "SaveAndAdd") {
                    qLCCClient.qLCCCitizenIdentificationCardsActions({
                        ...new QLCCCitizenIdentificationCardsModel(),
                        ...data,
                        date_issue: data?.date_issue ? TransDateTime(data?.date_issue) : undefined,
                        ma_cty: SessionLogin?.ma_cty,
                        apartment_building_id: apartment_building_id,
                        action_type: 'INSERT'
                    } as QLCCCitizenIdentificationCardsModel)
                        .then((response) => {
                            setLoading(false);
                            notification(status_message[response[0].status!], response[0].message);
                            if (response[0]?.status === 0) {
                                props.onAddedSuccess && props.onAddedSuccess(response[0].id, { ...data, id: response[0].id } as QLCCCitizenIdentificationCardsModel);
                                setData(pre => ({ ...pre, ...new QLCCCitizenIdentificationCardsModel() } as QLCCCitizenIdentificationCardsModel));
                                props.onClose();

                            }
                        }).catch(error => {
                            notification(status_message[1], "Có lỗi xãy ra")
                            setLoading(false)
                        });
                }
                else {
                    qLCCClient.qLCCCitizenIdentificationCardsActions({
                        ...new QLCCCitizenIdentificationCardsModel(),
                        ...data,
                        date_issue: data?.date_issue ? TransDateTime(data?.date_issue) : undefined,
                        ma_cty: SessionLogin?.ma_cty,
                        apartment_building_id: apartment_building_id,
                        action_type: 'INSERT'
                    } as QLCCCitizenIdentificationCardsModel)
                        .then((response) => {
                            setLoading(false);
                            notification(status_message[response[0].status!], response[0].message);
                            if (response[0]?.status === 0) {
                                props.onAddedSuccess && props.onAddedSuccess(response[0].id, { ...data, id: response[0].id } as QLCCCitizenIdentificationCardsModel);
                                setData(new QLCCCitizenIdentificationCardsModel());
                                props.onClose();
                            }

                        }).catch(error => {
                            notification(status_message[1], "Có lỗi xãy ra")
                            setLoading(false)
                        });

                }


                break;
            }


            default:
                break;
        }

    };

    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value } as QLCCCitizenIdentificationCardsModel);
    };

    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        const date = moment(value).toDate();
        if (date instanceof Date && !isNaN(date.valueOf())) {

            setData({ ...data, [name]: new Date(value) } as QLCCCitizenIdentificationCardsModel);
        }
    }

    const handleRemoveImage = (e: any) => {
        const { name } = e;

        if (name) {
            setData({
                ...data,
                [name]: ''
            } as QLCCCitizenIdentificationCardsModel)
        }
    }

    const handleChangeImage = async (e: any) => {

        const { name, files } = e.target;
        const formData: FormData = new FormData();

        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };

        for (const file of files) {
            formData.append("MyFile", file);
        }
        setLoading(true)
        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {


                    setData({
                        ...data,
                        [name]: res.data[0].path
                    } as QLCCCitizenIdentificationCardsModel);
                    setLoading(false)
                    // reset lại file input
                    e.target.value = '';

                } else notification("warning", "Không lấy được hình ảnh");
                setLoading(false);
            });

    }

    const handleAddNewImage = async (e: any) => {

        const { name, files } = e.target;
        const formData: FormData = new FormData();

        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };

        for (const file of files) {
            formData.append("MyFile", file);
        }
        setLoading(true)
        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {


                    setData({
                        ...data,
                        [name]: res.data[0].path
                    } as QLCCCitizenIdentificationCardsModel);
                    setLoading(false)
                    // reset lại file input
                    e.target.value = '';

                } else notification("warning", "Không lấy được hình ảnh");
                setLoading(false);
            });

    }

    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                // handleSubmit();             
                handleSubmit({
                    action: "SaveAndClose"
                })
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
        }
    }


    return (
        <Dialog
            id="sysMenu3"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            TransitionComponent={Transition}

        >
            {/* {React.useMemo(() => {
                return (
                    <ThongTinTau
                        open={actTtt.open}
                        title="Thông tin tàu [Thêm mới]"
                        onClose={() => {
                            setActTtt(defaultAction);
                        }}
                        itemSelected={actTtt.payload}
                        action={actTtt.type}
                    />
                );
            }, [actTtt.open])} */}

            <Dialog
                open={openCamera.open}
                onClose={handleCloseCamera}
                sx={{ height: "inherit" }}
            >
                <DialogTitle>Camera</DialogTitle>
                <DialogContent>
                    <div className="webcam-container">
                        <Webcam
                            audio={false}
                            height={360}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            width={540}
                            mirrored
                            videoConstraints={videoConstraints}
                        />
                        <div className="citizen-card-placeholder"></div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={capture}>Chụp</Button>
                    <Button onClick={handleCloseCamera}>Đóng</Button>
                </DialogActions>
            </Dialog>

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {`${props.title ?? menu?.bar}
                            ${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === ACTIONS.EDIT || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >

                <Box style={{ width: "100%", margin: "5px 0px" }}>
                    <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Số CCCD/CMT</InputLabel>
                    <TextField
                        className="cc-input"
                        sx={{
                            width: "100%",
                            marginRight: "5px",
                        }}
                        error={data?.citizen_identification_number && data?.citizen_identification_number.length > 12 ? true : false}
                        helperText={errorText}

                        autoFocus
                        type="number"
                        name="citizen_identification_number"
                        onChange={(e) => {
                            if (e.target.value.length > 12) {
                                // setErrorText("Số CMT/CCCD lớn hơn 12.");
                            } else {
                                handleChangeInput(e);

                            }
                        }}
                        value={data?.citizen_identification_number ?? ''}
                        disabled={props.action === ACTIONS.VIEW}
                    />

                </Box>

                {/* Nơi cấp */}
                <Box style={{ width: "100%", margin: "5px 0px" }}>
                    <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Nơi cấp</InputLabel>
                    <TextField
                        className="cc-input"
                        sx={{
                            width: "100%",
                            marginRight: "5px",
                        }}
                        name="place_issue"
                        onChange={handleChangeInput}
                        value={data?.place_issue ?? ''}
                        disabled={props.action === ACTIONS.VIEW}
                    />

                </Box>

                {/* Ngày cấp */}
                <Box sx={{ width: '100%', margin: '15px 0' }}>
                    <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Ngày cấp</InputLabel>
                    <CTXDate
                        className="cc-input"
                        style={{ width: '100%', padding: '0 5px !important' }}
                        name="date_issue"
                        onChange={handleChangeDate}
                        value={data?.date_issue ?? null}
                        disabled={props.action === ACTIONS.VIEW}
                    />
                </Box>

                {/* Hình chụp mặt trước */}
                <Box style={{ width: "100%", margin: "5px 0px" }}>
                    <div className="d-flex align-items-center">
                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>
                            Hình chụp mặt trước
                        </InputLabel>
                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                            disabled={props.action === ACTIONS.VIEW}
                        >
                            <PhotoCamera
                                id="front_side_image"
                                name="front_side_image"
                                onClick={(e: any) => showCamera(e, "front_side_image")}
                            />
                        </IconButton>
                    </div>
                    <DHSUploadImage
                        name="front_side_image"
                        value={data?.front_side_image}
                        width={"360px"}
                        height={"204px"}
                        handleAddNewImage={handleAddNewImage}
                        handleChangeImage={handleChangeImage}
                        handleRemoveImage={handleRemoveImage}
                        disable={props?.action === ACTIONS.VIEW}
                    />
                </Box>

                {/* Hình chụp mặt sau */}
                <Box style={{ width: "100%", margin: "5px 0px" }}>
                    <div className="d-flex align-items-center">
                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>
                            Hình chụp mặt sau
                        </InputLabel>
                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                            disabled={props.action === ACTIONS.VIEW}
                        >
                            <PhotoCamera
                                id="back_side_image"
                                name="back_side_image"
                                onClick={(e: any) => showCamera(e, "back_side_image")}
                            />
                        </IconButton>
                    </div>
                    <DHSUploadImage
                        name="back_side_image"
                        value={data?.back_side_image}
                        width={"360px"}
                        height={"204px"}
                        handleAddNewImage={handleAddNewImage}
                        handleChangeImage={handleChangeImage}
                        handleRemoveImage={handleRemoveImage}
                        disable={props?.action === ACTIONS.VIEW}
                    />
                </Box>



            </DialogContent>
            <DialogActions>


                <DHSDialogAction

                    action={props.action}
                    menu={menu}
                    onClickAction={handleClickAction}
                />
                {/* <Button sx={{ textTransform: 'none' }} onClick={props.onClose}>Hủy</Button> */}
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default CitizenIdentificationCardAction;


