import React from "react";
import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { formatNumber } from "devextreme/localization";
import { FinanceDashBoardGetDTO } from "../../../../../app/shared/service-proxies/api-shared";

interface IProps {
    dataSource: FinanceDashBoardGetDTO;
}

interface IGeneralCard {
    title: string,
    quantity: number,
    amount?: number,
    amountText?: string,
    amountFormat?: string,
    colorIcon: string,
    icon: () => JSX.Element;
}

const GeneralInfoDashboard: React.FC<IProps> = (props) => {
    const { dataSource } = props;
    return <Card elevation={0} sx={{ marginBottom: "0.5rem", overflow: "visible !important" }}>
        <CardContent sx={{ pb: "0  !important", pt: "0 !important", px: "0 !important" }}>

            <Grid container spacing={1} justifyContent={"space-evenly"}>
                <Grid item md={"auto"}>
                    <GeneralCard
                        title="Tòa nhà"
                        quantity={dataSource?.toa ?? 0}
                        // amount={props?.tien_da_quyet_toan ?? 0}
                        colorIcon={"#219C90"}
                        icon={() => <ApartmentIcon sx={{ bgcolor: "transparent", color: "#fff", fontSize: "32px" }} />
                        }
                    />
                </Grid>
                
                <Grid item md={"auto"}>
                    <GeneralCard
                        title="Tầng"
                        quantity={dataSource?.tang ?? 0}
                        // amount={props?.tien_da_thu ?? 0}
                        colorIcon={"#219C90"}
                        icon={() => <CorporateFareIcon sx={{ bgcolor: "transparent", color: "#fff", fontSize: "32px" }} />
                        }
                    />
                </Grid>
                <Grid item md={"auto"}>
                    <GeneralCard
                        title="Căn hộ"
                        quantity={dataSource?.can_ho ?? 0}
                        // amount={props?.tien_doanh_so ?? 0}
                        colorIcon={"#219C90"}
                        icon={() => <HomeWorkIcon sx={{ bgcolor: "transparent", color: "#fff", fontSize: "32px" }} />
                        }
                    />
                </Grid>
                <Grid item md={"auto"}>
                    <GeneralCard
                        title="Xe"
                        quantity={dataSource?.xe ?? 0}
                        // amount={props?.tien_da_thu ?? 0}
                        colorIcon={"#219C90"}
                        icon={() => <DirectionsCarIcon sx={{ bgcolor: "transparent", color: "#fff", fontSize: "32px" }} />
                        }
                    />
                </Grid>
            </Grid>
        </CardContent>
    </Card>
}

const GeneralCard: React.FC<IGeneralCard> = (props) => {
    return <Stack
        direction={"column"}
        spacing={0.3}
        position={"relative"}
        sx={{
            bgcolor: "#FFF",
            p: 2,
            mb:1,
            borderRadius: "5px",
            alignItems: "baseline",
            width: "300px",
            border: "1px solid #E0E0E0",
        }}>
        <Stack sx={{
            position: "absolute",
            top: 10,
            right: 10,
            bgcolor: props.colorIcon,
            // p: 0.8,
            width: "42px",
            height: "42px",
            borderRadius: "5px",
            justifyContent: "center",
            px: 0.8
        }}>
            {props.icon()}
        </Stack>
        <Grid container spacing={1} borderLeft={`4px solid ${props.colorIcon}`}>
            <Grid item xs={12} py={"0 !important"}>
                <Typography fontSize={16} fontWeight={600} color={"#333"}>{props.title}</Typography>
            </Grid>
            <Grid item xs={6} pt={"0.2rem !important"}>
                <Stack>
                    <Typography fontSize={14} fontWeight={600} color={"#ABAEB4"}>Số lượng</Typography>
                </Stack>
            </Grid>
            {/* <Grid item xs={6} pt={"0.2rem !important"}>
                <Stack>
                    <Typography fontSize={14} fontWeight={600} color={"#ABAEB4"}>{props?.amountText ?? "Tổng tiền"}</Typography>
                </Stack>
            </Grid> */}
        </Grid>
        <Grid container spacing={1}>

            <Grid item xs={6} pt={"0.2rem !important"}>
                <Stack>
                    <Typography fontSize={16} fontWeight={600} color={props.colorIcon}>{formatNumber(props.quantity, "fixedPoint")}</Typography>
                </Stack>
            </Grid>
            {/* <Grid item xs={6} pt={"0.2rem !important"}>
                <Stack>
                    <Typography fontSize={16} fontWeight={600} color={props.colorIcon}>{formatNumber(props.amount, props?.amountFormat ?? "fixedPoint")}</Typography>
                </Stack>
            </Grid> */}
        </Grid>
    </Stack>
}

export default GeneralInfoDashboard;