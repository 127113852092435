import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DHSToolbar from '../../../components/DHS/DHS-Toolbar/DHSToolbar';
import { IReducer } from '../../../components/layouts';
import DHSGridView from '../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
import { getRoles } from '../../../redux/actions/RoleActions';
import { AppSession } from '../../shared/app-session/app-session';
import { HomeClient, QLCCVehicleTypeModel, QLCCClient, RoleRightWebModel, SysMenu, QLCCApartmentBuildingModel } from '../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../shared/service-proxies/config';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import BlockUI from '../../../components/Block-UI/Block-ui';
import ResidentAction from '../actions/resident-action/ResidentAction';
import VehicleTypeAction from '../actions/vehicle-action/VehicleTypeAction';
import { Form, AutoComplete } from 'antd';
import notification from '../../../common/notification/notification';
import RoleMenuAction from './action/RoleMenuAction';
import { components } from '../../../common/ComponentsConfig/components';
const convert = ["success", "error", "warning"];
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

const roleMenu: string = 'CC';
const RoleMenuList: React.FC<{}> = () => {

    const dispatch = useDispatch();
    const { path, url } = useRouteMatch();

    const history = useHistory();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    // const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<QLCCVehicleTypeModel[]>([]);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);

    const [actCx, setActCx] = useState<IAction>(defaultAction);

    // 

    const [apartmentBuildingOptions, setApartmentBuildingOptions] = React.useState<any[]>([]);
    const [form] = Form.useForm();

    // 

    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
    const [isShowSort, setShowSort] = React.useState<boolean>(false);
    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);

    // 

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const [apartmentBuildingSelected, setApartmentBuildingSelected] = React.useState<any>();

    const customMenu = [
        {
            menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
            onClick: () => {
                setShowSearch(!isShowSearch);
            },
        },
        {
            menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
            onClick: () => {
                setShowGroup(!isShowGroup);
            },
        },
        {
            menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
            onClick: () => {
                setShowSort(!isShowSort);
            },
        },
        {
            menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
            onClick: () => {
                setShowFilter(!isShowFilter);
            },
        },
    ];


    // 

    useEffect(() => {
        setLoading(true)
        homeClient.roleRightWebGetAll({
            ...new RoleRightWebModel(),
            menuid: menu?.menuid,
            rolename: roleMenu,
            username: 'DHSOFT',
        } as RoleRightWebModel)
            .then((res) => {
                dispatch(getRoles(res));
                setRoles(res);
                setLoading(false);
            })

    }, [menu?.component])

    useEffect(() => {


        setLoading(true);
        qLCClient.qlccApartmentBuildingGet({
            ...new QLCCApartmentBuildingModel(),
            ma_cty: SessionLogin.ma_cty,
            action_type: 'GET'
        } as QLCCApartmentBuildingModel)
            .then(response => {

                let tempData: any = [];
                tempData = response.map(apartment_building => {
                    return {
                        key: apartment_building.id,
                        value: apartment_building.name
                    }
                })
                setApartmentBuildingOptions(tempData)
                setApartmentBuildingSelected(tempData[0])
                setLoading(false);
            });


    }, []);

    React.useEffect(() => {
        setLoading(true)
        qLCClient.qLCCVehicleTypeGet({
            ...new QLCCVehicleTypeModel(),
            apartment_building_id: apartmentBuildingSelected?.key,
        } as QLCCVehicleTypeModel).then((res) => {
            setLoading(false)
            setDataTable(res)
        })
    }, [reload, apartmentBuildingSelected?.key])

    const handleClickAction = (action: RoleRightWebModel, nowTime: Date) => {

        if (!apartmentBuildingSelected?.key) {
            notification(convert[1], "Vui lòng chọn dự án!")
        }
        else {
            if (action?.class_name?.toUpperCase() === "DELETE") {
                if (window.confirm("Bạn có chắc chắn muốn xóa?")) {
                    setLoading(true)
                    qLCClient.qLCCVehicleTypeAction({
                        ...new QLCCVehicleTypeModel(),
                        id: idItemSelected,
                        action_type: action.class_name.toUpperCase(),
                    } as QLCCVehicleTypeModel)
                        .then((res) => {
                            setLoading(false)
                            notification(convert[Number(res.status!)], res.message);
                            setReload(!reload)
                        });
                }
            }
            else {
                setActCx({
                    ...actCx,
                    open: true,
                    type: action?.class_name?.toUpperCase() ?? "",
                    payload: {
                        vehicle_type_id: idItemSelected,
                        apartment_building_id: apartmentBuildingSelected?.key
                    }
                })
            }
        }
    }

    const onFinish = React.useCallback(async (values: QLCCVehicleTypeModel) => {
        // 

        // const body = Object.assign({}, data,);


    }, []);

    const onFinishFailed = (errorInfo: any) => {

        // api["error"]({
        //     message: "Đăng ký không thành công",
        //     description: "Vui lòng xem lại các ô nhập!"

        // });

    }

    const onApartmentBuildingSelect = (data: string, option: any) => {

        // setApartmentBuildingSelected({
        //     key: option.key,
        //     value: option.value
        // });



    };

    const onApartmentBuildingChange = (data: any, option: any) => {

        setApartmentBuildingSelected({
            key: option.key,
            value: option.value
        });
    };


    return (

        <Switch>
            <Route path={path} exact>
                <div className='container-fluid'>

                    {/* {
                actCx.open && <RoleMenuAction
                    open={actCx.open}
                    onClose={function (): void {
                        setActCx(defaultAction);
                    }}
                    action={actCx.type}
                    itemSelected={actCx.payload}
                    onEditSuccess={function (data_response: SysMenu): void {
                        setReload(pre => !pre)
                    }} >

                </RoleMenuAction>
            } */}

                    <div
                        className="d-flex title-color"
                        style={{
                            height: 30,
                            color: "rgb(0 78 255 / 88%)",
                            marginTop: 15,
                            marginBottom: 15,
                            fontSize: 22,
                            fontWeight: 550,
                        }}
                    >
                        <i
                            className="bi bi-arrow-left-short"
                            style={{ cursor: "pointer" }}
                            onClick={() => history.push("/admin/quan-ly-chi-phi")}
                        ></i>
                        &nbsp;{menu?.bar}
                    </div>

                    {/* <div className='d-flex justify-content-end'>
                <div className='col-4 '>

                    <Form
                        name="basic"
                        form={form}
                        layout="horizontal"
                        labelAlign="left"
                        // initialValues={{ requiredMarkValue: requiredMark }}
                        // onValuesChange={onRequiredTypeChange}

                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    // requiredMark={requiredMark}
                    // labelCol={{ span: 8 }}
                    >
                        <Form.Item
                            label={<span style={{ fontWeight: 700, fontSize: 15 }}>Chọn dự án</span>}
                            name="apartment_building_id"
                            hasFeedback
                            // required 
                            // tooltip="Thông tin mã khách hàng là bắt buộc"
                            rules={[
                                { required: true, message: 'Dự án là bắt buộc!' },
                                // {
                                //     validator(rule, value, callback) {

                                //         if (apartmentBuildingOptions.find((item: QLCCApartmentBuildingModel) => item.id === value)) {
                                //             callback();
                                //         }
                                //         else {
                                //             callback("Giá trị hiện tại không phải Dự án")
                                //         }

                                //     },
                                // }
                            ]}

                        >

                            {
                                apartmentBuildingSelected && <AutoComplete

                                size="middle"
                                defaultValue={apartmentBuildingSelected ?? null}
                                value={apartmentBuildingSelected ? apartmentBuildingOptions.find(item => {
                                    return item.key === apartmentBuildingSelected?.key
                                }) :  null}
                                options={apartmentBuildingOptions}
                                onSelect={onApartmentBuildingSelect}
                                // onSearch={ApartmentSearchInput}
                                onChange={onApartmentBuildingChange}
                                placeholder="Chọn dự án"
                            />
                            }
                        </Form.Item>
                    </Form>



                </div>
            </div> */}

                    <DHSToolbar
                        id={idItemSelected}
                        list={roles
                            ? roles.filter(
                                (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
                            )
                            : []}
                        customMenu={customMenu}
                        onClickAction={handleClickAction}
                    />


                    <DHSGridView
                        onSelectRow={(row) => {
                            setIdItemSelected(row);
                        }}
                        // onListSelectRow={handleListOnselectRow}
                        itemClicked={function (): void {
                            throw new Error("Function not implemented.");
                        }}
                        table_name={menu?.component ?? ''}
                        show_form_list={true}
                        showSelectBox={false}
                        // data={dataTable}
                        data={[]}
                        showSortPanel={isShowSort}
                        showSearchPanel={isShowSearch}
                        showGroupPanel={isShowGroup}
                        showFilterPanel={isShowFilter}
                        defaultPageSize={2000}
                        grid_name={"Danh sách xe"}
                        excel={false}
                    />
                    {/* <BlockUI blocking={loading} title={""}></BlockUI> */}
                </div>
            </Route>
            {roles &&
                roles.map((role, index) => {
                    return (
                        role.link &&
                        role.link.length > 1 &&
                        role.component && (
                            <Route
                                key={index}
                                path={role.is_parameter ? role.link + ":_id" : role.link}
                                component={components[role.component.toLocaleUpperCase()]}
                                exact
                            ></Route>
                        )
                    );
                })}
        </Switch>
    )
}

export default RoleMenuList;


