import * as types from "../constants/ActionTypes";
import { AnyAction } from "redux";
var initialState = {
  userInfo: null,
  userName: "",
  ma_cty: "",
  isLogin: null,
  isAdmin: true,
  isSetIdxDB: false,
  lstCusRequest: [],
};

var AdminPageReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.LOGIN: {
      if(action.payload.status != 0) {
        alert('error '+action.payload.status +': '+action.payload.message)
        return;
      }
      return {
        ...state,
        userInfo: action.payload,
        isLogin: action.payload.status === 0 ? true : null,
        isAdmin: true
         //isAdmin: action.payload.group_code !== "CN" ? true : null
      };
    }
    case types.SET_IDX_DB:{
      return {
        ...state,
        isSetIdxDB: action.payload
      }
    }
    // case types.SESSION_LOGIN: {
    //   return {
    //     ...state,
    //     userName: action.payload.USERNAME,
    //     ma_cty: action.payload.MA_CTY,
    //   };
    // }
    case types.DHS_CUS_REQUEST_GET: {
      return {
        ...state,
        lstCusRequest: action.payload,
      };
    }
    case types.CLEAR_LOGIN: {
      return {
        ...state,
        userInfo: null,
        userName: "",
        ma_cty: "",
        isLogin: null,
        isSetIdxDB: false
      };
    }
    default:
      return state;
  }

};

export default AdminPageReducer;
