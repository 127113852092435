import axios from "axios";
import BASE_URL_API from "../app/shared/service-proxies/config";

export default  axios.create({
    baseURL: BASE_URL_API,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        // 'WithCredentials': "true",
        // 'CrossOrigin': "true",
        // 'Mode': 'cors',
        'Authorization': 'DHSJWT-ADD55F36-CE51-4902-B2F6-62DEA2F46B04'
    }
});