import { IconButton } from "@mui/material";
import React from "react";
import BASE_URL_API from "../../../app/shared/service-proxies/config";

import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { getFileExtension, isImageFile, isPDFFile } from "../../utils/file.util";
import DHSButton from "../../DHSComponents/actions-and-list/DHSButton/DHSButton";




const cancelButtonStyle: React.CSSProperties = {
    position: "absolute",
    top: 1,
    right: 1,
    cursor: "pointer",
};

const uploadButtonStyle: React.CSSProperties = {
    position: "absolute",
    top: 35,
    right: 1,
    cursor: "pointer",
};

const fileInputStyle: React.CSSProperties = {
    display: "none",
};

const dropzoneMessage: { [key: string]: string } = {
    "": "Kéo thả, click hoặc dán vào để thêm mới...",
    "paste": "Click hoặc dán hình vào để thêm mới...",
    "drag": "Thả hình vào để thêm mới...",
    "upload": "Chọn hình để thêm mới..."
}
const dropzoneIcon: { [key: string]: JSX.Element } = {
    "": <i className="bi bi-cloud-upload" style={{ fontSize: 42, display: "block", textAlign: "center" }} />,
    "paste": <i className="bi bi-clipboard2-plus" style={{ fontSize: 42, display: "block", textAlign: "center" }}></i>,
    "drag": <i className="bi bi-cloud-plus" style={{ fontSize: 42, display: "block", textAlign: "center" }}></i>,
    "upload": <i className="bi bi-upload" style={{ fontSize: 42, display: "block", textAlign: "center" }}></i>
}

interface IProps {
    accept?: string,
    value?: string,
    fileName: string,
    name: string,
    handleRemoveImage?: (data: any) => void,
    handleChangeImage?: (data: any) => void,
    handleAddNewImage?: (data: any) => void,
    width?: string | number,
    height?: string | number,
    disable?: boolean
}

const DHSUploadFile: React.FC<IProps> = (props) => {
    const dropZoneRef = React.useRef<HTMLDivElement>(null);
    const [dropzoneType, setDropzoneType] = React.useState<"" | "paste" | "drag" | "upload">("");

    const imageStyle: React.CSSProperties = {
        position: "relative",
        width: props?.width ?? "213px",
        minWidth: props?.width ?? "213px",
        maxWidth: props?.width ?? "213px",
        height: props?.height ?? "154px",
        minHeight: props?.height ?? "154px",
        maxHeight: props?.height ?? "154px",
    };

    const displayChangeAndRemoveFile = () => {
        return (
            <>
                <div className="position-absolute w-100 d-flex justify-content-end" style={cancelButtonStyle}>
                    <IconButton
                        color="error"
                        aria-label="upload picture"
                        component="label"
                        onClick={() => {
                            props?.handleRemoveImage && props?.handleRemoveImage({
                                name: props?.name
                            });
                            setDropzoneType("")
                        }}
                        size="small"
                        disabled={props?.disable}
                    >
                        <CancelIcon sx={{ fontSize: 28, backgroundColor: "#fff", borderRadius: "50%" }} />
                    </IconButton>
                </div>
                <div className="position-absolute w-100 d-flex justify-content-end" style={uploadButtonStyle}>
                    <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                        size="small"
                        disabled={props.disable}
                    >
                        <input
                            id={`uploadImageUpdate`}
                            className="flex-grow-1 form-control"
                            type="file"
                            accept={props?.accept ?? "*"}
                            name={props?.name}
                            onChange={(e) => props?.handleChangeImage && props?.handleChangeImage(e)}
                            style={fileInputStyle}
                        />
                        <ChangeCircleIcon sx={{ fontSize: 28, backgroundColor: "#fff", borderRadius: "50%" }} />
                    </IconButton>
                </div></>
        )
    }


    const imageFileDisplay = () => {
        return <div style={{ width: "inherit", height: "inherit", }}>
            <div
                className="image-shadow"
                style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    objectFit: 'contain',
                    backgroundImage: `url("${props?.value ?? ""}")`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "10px",
                }}
            >
                {
                    displayChangeAndRemoveFile()
                }
            </div>

        </div>
    }

    const pdfFileDisplay = () => {
        return <div style={{ height: "inherit", width: "inherit" }}>
            <div className="row m-0" style={{ height: "inherit", width: "inherit" }}>
                <div className="col-auto pr-1">
                    <DHSButton
                        icon="bi bi-x-lg"
                        type="danger"
                        text="Xóa file"
                        onClick={(e) => props?.handleRemoveImage && props?.handleRemoveImage({
                            name: props?.name
                        })} />
                </div>
                <div className="col-auto px-1">
                    <DHSButton
                        icon="bi bi-arrow-clockwise"
                        type="default"
                        text="Đổi file"
                        onClick={(e) => {
                            document.querySelector<HTMLInputElement>("#uploadImageUpdate2")?.click();
                        }}
                    >

                    </DHSButton>
                    <input
                        id={`uploadImageUpdate2`}
                        className="flex-grow-1 form-control"
                        type="file"
                        accept={props?.accept ?? "*"}
                        name={props?.name}
                        onChange={(e) => props?.handleChangeImage && props?.handleChangeImage(e)}
                        style={fileInputStyle}
                    />
                </div>
                {/* <div className="col-3"></div> */}
                <div className="col-12 py-2" style={{ height: "inherit" }}>
                    <iframe src={props?.value} title="PDF Viewer" style={{ overflow: "hidden", height: "100%", width: "100%" }} height="100%" width="100%"></iframe>

                </div>
            </div>

        </div>
    }

    const fileNotSupportDisplay = () => {
        return <div className="h-100 d-flex align-items-center position-relative"><div className="row" style={{ rowGap: "15px" }} >
            <div className="col-12" style={{ textAlign: "center", fontSize: "1rem", fontWeight: 500 }}>Loại tệp không được hỗ trợ (Xem trên web) ".{getFileExtension(props?.fileName)}"</div>
            <div className="col-12" style={{ textAlign: "center" }}>
                <a href={props?.value} className="text-center" download target="_blank" rel="noreferrer">
                    <DHSButton
                        icon="bi bi-cloud-arrow-down"
                        type="default"
                        text="Tải về máy"
                    // onClick={(e) => props?.handleRemoveImage && props?.handleRemoveImage({
                    //     name: props?.name
                    // })}
                    />
                </a>

            </div>
            {
                displayChangeAndRemoveFile()
            }
        </div >
        </div>
    }

    const addNewFileDisplay = () => {
        return (
            <div className="m-1" style={{ width: "inherit", height: "inherit", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <label
                    htmlFor={`uploadImageAdd`}
                    style={imageStyle}
                    className="label-file-media"
                    onClick={() => {
                        const el = dropZoneRef.current;
                        if (el) {
                            el.style.backgroundColor = "#fff"
                            el.style.border = "3px double #a3c4e7"
                            el.style.outline = "none"
                            setDropzoneType("upload")
                        }
                    }}
                >
                    <div
                        ref={dropZoneRef}
                        className="c-img-card"
                        contentEditable
                        suppressContentEditableWarning
                        style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: '5px',
                            caretColor: "transparent"
                        }}
                        onInput={(e) => e.preventDefault()}
                        onBeforeInput={(e) => e.preventDefault()}
                        onDragEnter={(e) => {
                            e.preventDefault()
                            const el = dropZoneRef.current;
                            if (el) {
                                el.style.border = "3px dashed #a3c4e7"
                                el.style.backgroundColor = "#fff"
                                setDropzoneType("drag")
                            }
                        }}
                        onDragOver={(e) => {
                            e.preventDefault()
                            const el = dropZoneRef.current;
                            if (el) {
                                el.style.border = "3px dashed #a3c4e7"
                                el.style.backgroundColor = "#fff"
                                setDropzoneType("drag")
                            }
                        }}
                        onDragLeave={(e) => {
                            e.preventDefault();
                            const el = dropZoneRef.current;
                            if (el && dropzoneType === "drag") {
                                el.style.border = "3px dashed #a3c4e7"
                                el.style.backgroundColor = "#f3f8fe"
                                setDropzoneType("")
                            }
                        }}
                        onDrop={(e) => {
                            e.preventDefault()
                            const files = e.dataTransfer.files;
                            console.log(files)
                            props?.handleAddNewImage && props?.handleAddNewImage({
                                target: {
                                    name: props?.name,
                                    files,
                                    value: files[0].name
                                }
                            })
                        }}
                        onPaste={(e) => {
                            e.preventDefault()
                            const clipboardData = e.clipboardData || (window as any).clipboardData;
                            if (clipboardData) {
                                const items = clipboardData.items;
                                for (let i = 0; i < items.length; i++) {
                                    const item = items[i];
                                    if (item.kind === 'file') {
                                        const file = item.getAsFile();
                                        props?.handleAddNewImage && props?.handleAddNewImage({
                                            target: {
                                                name: props?.name,
                                                files: [file],
                                                value: file?.name
                                            }
                                        })
                                    }
                                }
                            }
                        }}
                        onMouseEnter={(e) => {
                            const el = dropZoneRef.current;
                            if (el) {
                                el.focus()
                                el.style.backgroundColor = "#fff"
                                el.style.border = "3px dotted #a3c4e7"
                                el.style.outline = "none"
                                setDropzoneType("paste")
                            }
                        }}
                        onMouseLeave={(e) => {
                            const el = dropZoneRef.current;
                            if (el && dropzoneType === "upload") {
                                el.style.backgroundColor = "#f3f8fe"
                                el.style.border = "3px dashed #a3c4e7"
                                setDropzoneType("")
                            }
                        }}
                        onBlur={(e) => {
                            const el = dropZoneRef.current;
                            if (el && dropzoneType === "paste") {
                                el.style.backgroundColor = "#f3f8fe"
                                el.style.border = "3px dashed #a3c4e7"
                                setDropzoneType("")
                            }
                        }}
                    >
                        <div className="d-flex flex-column justify-content-center">
                            {dropzoneIcon[dropzoneType]}
                            <span style={{ fontSize: 13, textAlign: "center", display: "block", padding: "0.25rem 1.25rem" }}>&nbsp;{
                                dropzoneMessage[dropzoneType]
                            }</span>
                        </div>
                    </div>
                </label>
                <input
                    id="uploadImageAdd"
                    className="flex-grow-1 form-control"
                    type="file"
                    accept={props?.accept ?? "*"}
                    name={props?.name}
                    onChange={(e) => props?.handleAddNewImage && props?.handleAddNewImage(e)}
                    style={fileInputStyle}
                    disabled={props?.disable}
                />
            </div>
        )
    }

    return (
        <>
            {props?.value ? (
                <>
                    {
                        isImageFile(props.fileName) ?
                            imageFileDisplay() : // display image
                            isPDFFile(props?.fileName) ?
                                pdfFileDisplay() : // display pdf
                                fileNotSupportDisplay() // display file not support
                    }
                </>
            ) : addNewFileDisplay() // display add new file
            }
        </>
    );

}

export default DHSUploadFile;