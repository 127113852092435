import React, { Component } from "react";
import * as actions from "../../../../../redux/actions/Manufacturing/MachineRpt/index";
import DateBox from "devextreme-react/date-box";
import { Button } from "devextreme-react/button";
import SelectBox from "devextreme-react/select-box";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import "./index.css";
import { Row, Col, Divider, Collapse, InputNumber } from "antd";
import TimeLineChart from "../../ChartType/timeLineChart";
import Reports from "./Reports/reports";
import { periodData, shiftData } from "../../../../controller/DataSample";
import { onPeriodChange } from "../../../../controller/Format";
import { MachineRptClient, ModelHandlePD, ModelHandle_PDMachineStatus, PDMachineStatusClient } from "../../../../../app/shared/service-proxies/api-shared";
import { AppSession } from "../../../../../app/shared/app-session/app-session";
import BASE_URL_API from "../../../../../app/shared/service-proxies/config";
import DHS_Select from "../../../../DHS/DHS_Select";
import { IReducer } from "../../../../../common/Interfaces/Interfaces";
const { Panel } = Collapse;
let appSession: AppSession;
const MachineTimeLine = (props: any) => {
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const data = useSelector((state: IReducer) => state.MachineRptReducer);
  const [model, setModel] = React.useState<any>({
    dataSource: [],
    period: "formTo",
    periodReport: "formTo",
    time_from: moment()
      .subtract("1", "days")
      .set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
    time_to: moment().set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
    time_from_display: moment()
      .subtract("1", "days")
      .set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
    time_to_display: moment().set({
      hour: 6,
      minute: 0,
      second: 0,
      millisecond: 0,
    }),
    machineID: "",
    machineName: "",
    displayName: "",
    shift: 0,
  });
  const [machineTimeLine, setmachineTimeLine] = React.useState<any>();
  const [containDataRptMachine, setcontainDataRptMachine] = React.useState<any>();
  const [listMachine, setlistMachine] = React.useState<any>();
  const [machineWorkShift, setmachineWorkShift] = React.useState<any>();
  const machineRptClient = new MachineRptClient(appSession, BASE_URL_API);
  const pDMachineStatusClient = new PDMachineStatusClient(appSession, BASE_URL_API);
  React.useEffect(() => {
    pDMachineStatusClient.getLstMachinesState({ ...new ModelHandle_PDMachineStatus(), mA_CTY: SessionLogin.ma_cty } as ModelHandle_PDMachineStatus).then(res => {
      setlistMachine(res);
    })

    if (data?.machineID !== "") {
      setModel({
        ...data,
        time_from: data?.time_from,
        time_to: data?.time_to,
        time_from_display: data?.time_from,
        time_to_display: data?.time_to,
        displayName: data?.machineName,
        machineID: data?.machineID,
      });
      getRptMachineTimeLine(data?.machineID, data?.time_from, data?.time_to);
      getRptMachineWorkShift(data?.machineID, data?.time_from, data?.time_to);
    } else {
      getRptMachineWorkShift();
    }

  }, []);
  const componentDidMount = () => {
    // const {
    //   time_from,
    //   time_to,
    //   machine_name,
    //   machineID,
    // } = props.containDataRptMachine;


  };

  // const componentDidUpdate = (prevProps:any) => {
  //   const { machineTimeLine } = this.props;
  //   if (!_.isEqual(machineTimeLine, prevProps.machineTimeLine)) {
  //     this.setState({ dataSource: machineTimeLine });
  //   }
  // };

  // const componentWillUnmount = () => {
  //   if (this.props.machineTimeLine !== null) {
  //     this.props.machineTimeLine.length = 0;
  //     this.props.machineWorkShift.length = 0;
  //   }
  //   this.setState({ dataSource: [] });
  // };

  const onValueChanged = (name: any) => (e: any) => {
    setModel({ ...model, name: e.value });
  };

  const onSelectionChanged = (e: any) => {
    setModel({
      ...model,
      machineID: e.selectedItem.machine_id,
      machineName: e.selectedItem.machine_name,
    });
  };
  const selectShift = (e: any) => {
    setModel({
      ...model,
      shift: e.selectedItem.value,
    });
  };
  const onClickSearch = (type: any) => {
    setModel({
      ...model, time_from_display: props.time_from, time_to_display: props.time_to
    });
    if (type === 0) {
      setModel({
        ...model, displayName: props.machineName
      });
      getRptMachineTimeLine(props.machineID, props.time_from, props.time_to);
    }
    getRptMachineWorkShift(props.machineID, props.time_from, props.time_to, props.shift);
  };
  const customizeTooltip = (arg: any) => {
    let value = moment(arg.originalValue).diff(
      moment(arg.originalMinValue),
      "seconds"
    );
    let convert = (value: any) => {
      return value >= 10 ? value : "0" + value;
    };
    let hours = Math.floor(value / 60 / 60);
    let minutes = Math.floor((value / 60) % 60);
    let seconds = value % 60;
    return {
      text: `Time ${arg.seriesName} :${convert(hours) + ":" + convert(minutes) + ":" + convert(seconds)
        }
      Start time: ${moment(arg.originalMinValue).format(
          "DD/MM/yyyy HH:mm:ss"
        )}`,
    };
  }
  const onHandleFilter = (params: any, type: any) => {
    const result = onPeriodChange(params, moment().weeks());
    setModel({
      ...model,
      period: result.period,
      time_to: result.toDate,
      time_from: result.formDate,
    });
  };
  const onChangeWeek = (value: any, type: any) => {
    const result = onPeriodChange("week", value);
    setModel({
      ...model,
      period: result.period,
      time_to: result.toDate,
      time_from: result.formDate,
    });
  };
  const getRptMachineTimeLine = (
    machine_id = "",
    time_from = new Date((new Date()).getDate() - 1, (new Date()).getMonth(), (new Date()).getFullYear(), 13, 0, 0)// hour: 6, minute: 0, second: 0, millisecond: 0 ),
    , time_to = new Date((new Date()).getDate(), (new Date()).getMonth(), (new Date()).getFullYear(), 13, 0, 0),
    ma_cty = SessionLogin.ma_cty
  ) => {
    var d = (new Date()).getDate() - 1;
    // const query = {
    //   ma_cty,
    //   time_from:  Date().setHours((Date(time_from)).getHours() + 7)),
    //   time_to: Date(time_to).add(7, "hours"),
    //   machine_id,
    // };
    let pr = new ModelHandlePD();
    pr.mA_CTY = SessionLogin.ma_cty
    pr.timE_FROM = time_to
    pr.timE_TO = new Date()
    pr.machinE_ID = '001AA000000000000045'
    machineRptClient.getRptMachineTimeline(pr).then(res => {
      setmachineTimeLine(res);
    })
  };
  const getRptMachineWorkShift = (
    machine_id = "",
    time_from = moment(),
    time_to = moment(),
    shift = 0,
    ma_cty: string = ''
  ) => {
    const query = {
      ma_cty,
      time_from: moment(time_from).add(7, "hours"),
      time_to: moment(time_to).add(7, "hours"),
      shift,
      machine_id,
    };
    machineRptClient.getRptMachineWorkShift({
      ...new ModelHandlePD(),
      mA_CTY: SessionLogin.ma_cty,
      timE_FROM: new Date(query.time_from.date(), query.time_from.month(), query.time_from.year(), query.time_from.hour(), 0, 0),
      timE_TO: new Date(query.time_to.date(), query.time_to.month(), query.time_to.year(), query.time_to.hour(), 0, 0),
      shift: shift,
      machinE_ID: machine_id
    } as ModelHandlePD).then(res => {
      setmachineWorkShift(res);
    })
  };
  // const {
  //   dataSource,
  //   time_from,
  //   time_to,
  //   time_from_display,
  //   time_to_display,
  //   displayName,
  //   machineID,
  //   shift,
  //   period,
  // } = this.state;
  // const { containDataRptMachine, listMachine } = this.props;
  return (
    <div className="machineTimeLine-container">
      <Collapse defaultActiveKey={["1"]} expandIconPosition="right" ghost>
        <Panel
          header={<Divider orientation="left">Machine Timeline</Divider>}
          key="1"
        >
          {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24} xs={24} xl={6}>
              <div className="textTime">Mã máy</div>
              <div>
                <SelectBox
                  searchEnabled={true}
                  searchExpr="machine_code"
                  items={listMachine}
                  onSelectionChanged={onSelectionChanged}
                  valueExpr="machine_id"
                  displayExpr="machine_code"
                  defaultValue={data?.machineID}
                />
              </div>
            </Col>
            <Col span={24} xs={24} xl={4}>
              <div className="textTime">Lọc</div>
              <div>
                <DHS_Select
                  label="Chọn điều kiện lọc"
                  options={periodData}
                  value={model.period}
                  fieldValue="value"
                  fieldDisplay="name"
                  onChange={(value: any) => onHandleFilter(value, 1)}
                />
              </div>
            </Col>
            <Col span={24} xs={24} xl={2}>
              <div className="textTime">Số tuần</div>
              <div>
                <InputNumber
                  min={1}
                  max={52}
                  defaultValue={moment().weeks()}
                  onChange={(value) => onChangeWeek(value, 1)}
                  disabled={model.period === "week" ? false : true}
                  maxLength={2}
                />
              </div>
            </Col>
            <Col span={24} xs={24} xl={4}>
              <div className="textTime">Thời gian từ</div>
              <div>
                <DateBox
                  value={model.time_from}
                  defaultValue={model.time_from}
                  type="datetime"
                  onValueChanged={onValueChanged("time_from")}
                  displayFormat={"dd/MM/yyyy HH:mm"}
                  disabled={model.period === "formTo" ? false : true}
                />
              </div>
            </Col>
            <Col span={24} xs={24} xl={4}>
              <div className="textTime">Thời gian đến</div>
              <div>
                <DateBox
                  value={model.time_to}
                  defaultValue={model.time_to}
                  type="datetime"
                  min={model.time_from}
                  onValueChanged={onValueChanged("time_to")}
                  displayFormat={"dd/MM/yyyy HH:mm"}
                  disabled={model.period === "formTo" ? false : true}
                />
              </div>
            </Col>
            <Col span={8} xs={24} xl={4}>
              <Button
                style={{ marginTop: 22 }}
                width={120}
                text="Tìm kiếm"
                type="normal"
                stylingMode="contained"
                disabled={model.machineID === "" ? true : false}
                onClick={() => onClickSearch(0)}
              />
            </Col>
          </Row> */}
          <div className="machineTimeLineChart">
            <TimeLineChart
              dataSource={machineTimeLine}
              machineName={model?.displayName}
              time_to={model?.time_to_display}
              time_from={model?.time_from_display}
              customizeTooltip={customizeTooltip}
            />
          </div>
        </Panel>
      </Collapse>
      <Collapse defaultActiveKey={["2"]} expandIconPosition="right" ghost>
        <Panel header={<Divider orientation="left" style={{ color: '#fff' }}>Reports</Divider>} key="2">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24} xs={24} xl={6}>
              <div className="textTime">Ca làm việc</div>
              <div>
                <SelectBox
                  searchEnabled={true}
                  searchExpr="name"
                  items={shiftData}
                  onSelectionChanged={selectShift}
                  valueExpr="value"
                  displayExpr="name"
                  defaultValue={model.shift}
                />
              </div>
            </Col>
            <Col span={8} xs={24} xl={4}>
              <Button
                style={{ marginTop: 22 }}
                width={120}
                text="Tìm kiếm"
                type="normal"
                stylingMode="contained"
                onClick={() => onClickSearch(1)}
              />
            </Col>
          </Row>
          <Reports machineName={model.displayName} machineID={model.machineID} />
        </Panel>
      </Collapse>
    </div>
  );
}


export default MachineTimeLine;
