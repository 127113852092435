import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,

    Divider,
    DialogTitle,
    Autocomplete,
    Select,
    MenuItem,
    DialogContentText,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tooltip,
    Box,
} from "@mui/material";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from '@mui/icons-material/Help';
import {
    HomeClient,
    QLCCClient,
    RoleRightWebModel,
    QLCCApartmentBuildingModel,
    QLCCApartmentTypesModel,
    LstUnitModel,
    SysActionType,
    QLCCUtilityBookingModel,
    QLCCUtilityModel,
    QLCCUtilityBookingStatusModel,
    CommonClient,
    ParaObj,
    QLCCResidentsModel,
    HomePageClient,
    UserInfoModel,
    QLCCUtilityBookingDuplicateModel,
} from "../../../shared/service-proxies/api-shared";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VerifiedIcon from '@mui/icons-material/Verified';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DoNotTouchIcon from '@mui/icons-material/DoNotTouch';
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { ICustomer, IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../../actions/ApartmentBuilding.css";
import notification from "../../../../common/notification/notification";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import DHSAutoCompleteWithDataGrid from "../../../../components/DHS/DHS_AutoCompleteCustomer/DHSAutoCompleteWithDataGrid";
import { httpPost } from "../../../../common/httpService";
import DateBox from "devextreme-react/date-box";
import { TransDateTime } from "../../../../components/utils/date.util";
import DataGrid from "devextreme-react/data-grid";
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DHSDialogAction2 from "../../../../components/DHS/DHS_DialogAction2/DialogAction2";
import { getListKeyRowSelected } from "../../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import { CellPreparedEvent } from "devextreme/ui/data_grid";
import NumberBox from "devextreme-react/number-box";
import moment from "moment";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";
import DHSDataGridFilterListByProjectSelect from "../../../../components/DHSUtilComponent/DHSDataGridFilterListByProjectSelect/DHSDataGridFilterListByProjectSelect";
import DHSAutocompleteFilterListSelect from "../../../../components/DHSUtilComponent/DHSAutocompleteFilterListSelect/DHSAutocompleteFilterListSelect";


interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (id?: string, dataEdited?: QLCCUtilityBookingModel) => void;
    onAddSuccess: (id?: string, dataAdded?: QLCCUtilityBookingModel) => void;
}
// interface IOpenCamera {
//     pic: any;
//     open: boolean;
// }

// const videoConstraints = {
//     width: 1280,
//     height: 720,
//     facingMode: "user",
// };

// const image_size_citizen_identification_card = {
//     width: 540,
//     height: 360,
// };

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const status_message = ["success", "error"]

//#region statusName

// 1: Đang đợi duyệt
// 2: Đã đặt thành công
// 5: Bị từ chối

//#endregion
const status_approved_color: { [key: string]: string } = {
    "1": "#FBBC05",
    "5": "#ef4444",
    "2": "#34A853"
}
const status_approved_icon: { [key: string]: React.ReactNode } = {
    "null": <PendingActionsIcon sx={{ fontSize: 28 }} />,
    "1": <PendingActionsIcon sx={{ fontSize: 28 }} />,
    "2": <VerifiedIcon sx={{ fontSize: 28 }} />,
    "5": <DoNotTouchIcon sx={{ fontSize: 28 }} />
}

const status_code_for_show_reason = ["2", "5"]
var appSession: AppSession;
var htmlString: string;
const ServiceBookingAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    // truyền id dự án
    const [data, setData] = React.useState<QLCCUtilityBookingModel>({} as QLCCUtilityBookingModel);
    const [dataCheck, setDataCheck] = React.useState<QLCCUtilityBookingModel[]>([] as QLCCUtilityBookingModel[]);
    const [dataTable, setDataTable] = React.useState<QLCCUtilityBookingModel[]>([]);

    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const [isDefaultPre, setIsDefaultPre] = React.useState<boolean>();

    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const homePageClient = new HomePageClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [duplicateIds, setDuplicateIds] = React.useState<string[]>([]);

    const [totalApartmentMessage, setTotalApartmentMessage] = React.useState<JSX.Element>();
    const [actionUpdateService, setActionUpdateService] = React.useState<string>();

    const [apartmentBuildingOption, setApartmentBuildingOption] = React.useState<any>([]);
    const [customerOptions, setCustomerOptions] = React.useState<ICustomer[]>([]);


    const [utilityOptions, setUtilityOptions] = React.useState<QLCCUtilityModel[]>([]);
    const [unitOptions, setUnitOptions] = React.useState<any>([]);

    // 


    const [isExpandUtilityBookingAction, setIsExpandUtilityBookingAction] = React.useState<boolean>(true);
    const [isCalledReceiptAction, setIsCalledReceiptAction] = React.useState<boolean>(true);
    const [isExpandUtilityBookingRejectAction, setIsExpandUtilityBookingRejectAction] = React.useState<boolean>(true);
    const [fullScreen, setFullscreen] = React.useState<boolean>(false);

    // 

    const dataGridRef = React.useRef<DataGrid>(null);

    React.useEffect(() => {

        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW || props.action === ACTIONS.APPROVED) {
            qLCClient
                .qLCCUtilityBookingGet({
                    ...new QLCCUtilityBookingModel(),
                    ma_cty: props?.itemSelected?.ma_cty,
                    id: props.itemSelected.utility_booking_id,
                    apartment_building_id: props?.itemSelected?.apartment_building_id,
                    username_login: SessionLogin.userName,
                    type_id: "814C6394-7E12-4605-A865-070F3448D817"
                } as QLCCUtilityBookingModel)
                .then((res) => {
                    setData(res[0]);

                    // setIsDefaultPre(res[0]?.is_default);
                    if (!res[0].so_ct) {
                        // create_vocher_no()
                    }

                    qLCClient
                        .qLCCUtilityBookingGet({
                            ...new QLCCUtilityBookingModel(),
                            ma_cty: props?.itemSelected?.ma_cty,
                            apartment_building_id: props?.itemSelected?.apartment_building_id,
                            type_id: "814C6394-7E12-4605-A865-070F3448D817",
                            start_time: TransDateTime(res[0].start_time!),
                            end_time: TransDateTime(res[0].end_time!),
                            utility_id: res[0].utility_id,
                            username_login: SessionLogin.userName,

                            // action_type: "GET"
                        } as QLCCUtilityBookingModel)
                        .then((response) => {
                            setLoading(false);
                            setDataTable(response.filter(x => x.id !== res[0].id));


                        }).catch(() => alert("Không lấy được dữ liệu"));;

                })
                .catch(() => alert("Không lấy được dữ liệu"));
            // 

            // setId(props.itemSelected);

        } else {


            // create_vocher_no()
            setData({
                ...new QLCCUtilityBookingModel(),
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: props?.itemSelected?.apartment_building_id
            } as QLCCUtilityBookingModel);
            setId(undefined)
        };
    }, [props.action]);


    React.useEffect(() => {

        setLoading(true);
        homeClient
            ?.roleRightWebGetAll({
                ...new RoleRightWebModel(),
                rolename: menu?.moduleid,
                username: SessionLogin.userName,
                menuid: menu?.menuid,
            } as RoleRightWebModel)
            .then((res) => {
                setRoles(res);
                setLoading(false);
            });
        // setLoading(true);
        // qLCClient
        //     .qlccApartmentBuildingGet({
        //         ...new QLCCApartmentBuildingModel(),
        //         ma_cty: SessionLogin.ma_cty,
        //         action_type: 'GET'
        //     } as QLCCApartmentBuildingModel)
        //     .then(res => {
        //         setApartmentBuildingOption(res);
        //     })
        // setLoading(true);

        // qLCClient
        //     .qLCCUtilityBookingStatusGet({
        //         ...new QLCCUtilityBookingStatusModel(),
        //     } as QLCCUtilityBookingStatusModel)
        //     .then(res => {
        //         setUtilityBookingStatusOptions(res)
        //         setLoading(false);
        //     });
        setLoading(true);

        qLCClient.qlccLstUnitGet({
            ...new LstUnitModel(),
            ma_cty: SessionLogin.ma_cty
        } as LstUnitModel)
            .then(res => {
                setLoading(false);
                setUnitOptions(res)
            })

        // 
        // setLoading(true)
        // const body = {
        //     ma_cty: SessionLogin.ma_cty,
        //     tablelist: "dm_kh"
        // }
        // httpPost<string>('api/Common/LoadFilterList', body)
        //     .then(res => {

        //         const customers: ICustomer[] = JSON.parse(res.data);
        //         setCustomerOptions(customers)
        //         setLoading(false)

        //     });
        // 
        // httpPost<string>('api/Common/LoadFilterList', {
        //     tablelist: "dm_dich_vu"
        // })
        //     .then(res => {

        //         const utilities: QLCCUtilityModel[] = JSON.parse(res.data);
        //         setUtilityOptions(utilities)
        //         setLoading(false)

        //     });


    }, []);

    const handleValidateField = (): boolean => {
        if (!data?.id_customer) {
            return false
        }
        if (!data?.utility_id) {
            return false
        }
        if (!data?.title) {
            return false
        }
        if (!data?.start_time) {
            return false
        }
        if (!data?.end_time) {
            return false
        }
        if (!data?.quantity) {
            return false
        }

        return true;
    }

    const handleApproved = async () => {
        setLoading(true)
        const id_reject = (await getListKeyRowSelected(dataGridRef)).join(";");
        qLCClient.qLCCUtilityBookingApprove({
            ...new QLCCUtilityBookingModel(),
            ...data,
            start_time: data.start_time ? TransDateTime(data.start_time) : null,
            end_time: data.end_time ? TransDateTime(data.end_time) : null,
            ma_cty: SessionLogin.ma_cty,
            id_reject: id_reject
        } as QLCCUtilityBookingModel)
            .then(response => {
                setLoading(false);
                // notification(status_message[response.status!], STATUS_MESSAGE[`${props.action}_${response.status}`]);
                notification(status_message[response.status!], response.message);

                if (response.status === 0) {
                    props.onEditSuccess(response.id, data);
                    props.onClose();
                }
            }).catch(error => {
                setLoading(false)
                notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
            })
    }

    const handleCancelApproval = async () => {
        setLoading(true)
        qLCClient.qLCCUtilityBookingReject({
            ...new QLCCUtilityBookingModel(),
            ...data,
            start_time: data.start_time ? TransDateTime(data.start_time) : null,
            end_time: data.end_time ? TransDateTime(data.end_time) : null,
            ma_cty: SessionLogin.ma_cty,
            id_reject: data?.id
        } as QLCCUtilityBookingModel)
            .then(response => {
                setLoading(false);
                notification(status_message[response.status!], STATUS_MESSAGE[`${ACTIONS.CANCELAPPROVAL}_${response.status}`]);
                if (response.status === 0) {
                    props.onEditSuccess(response.id, data);
                    props.onClose();
                }
            })
            .catch(error => {
                setLoading(false)
                notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
            })
    }
    const handleInsert = async () => {
        if (open) {
            setOpen(false)
        }
        // if (!handleValidateField()) {
        //     notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["FIELD_REQUIRED"])

        //     return;
        // }

        setLoading(true)
        const response = await qLCClient.qLCCUtilityBookingIns({
            ...new QLCCUtilityBookingModel(),
            ...data,
            ma_cty: SessionLogin.ma_cty,
            start_time: data.start_time ? TransDateTime(data.start_time) : null,
            end_time: data.end_time ? TransDateTime(data.end_time) : null,
            type_id: "814C6394-7E12-4605-A865-070F3448D817"
            // description: htmlString,
        } as QLCCUtilityBookingModel);

        setLoading(false);
        notification(status_message[response.status!], STATUS_MESSAGE[`${props.action}_${response.status}`])

        if (response.status === 0) {

            props.onAddSuccess(response?.ref_id, data);
            props.onClose();
            return response?.ref_id ?? null
        }

        return null;
    }

    const handleDuplicateUpdate = (ref_id: string) => {
        if (duplicateIds.length > 0) {
            qLCClient.qLCCUtilityBookingDuplicateUpd({
                ...new QLCCUtilityBookingDuplicateModel(),
                id: duplicateIds.join(";") + `;${ref_id}`,
                is_duplicate: true
            } as QLCCUtilityBookingDuplicateModel)
                .then(res => {
                    console.log(res)
                })
                .catch(error => {
                    console.error(error)
                });
        }
    }

    const handleUpdate = async () => {
        if (open) {
            setOpen(false)
        }
        setLoading(true)
        const response = await qLCClient.qLCCUtilityBookingUpd({
            ...new QLCCUtilityBookingModel(),
            ...data,
            ma_cty: SessionLogin.ma_cty,
            start_time: data.start_time ? TransDateTime(data.start_time) : null,
            end_time: data.end_time ? TransDateTime(data.end_time) : null,
            type_id: "814C6394-7E12-4605-A865-070F3448D817"
            // description: htmlString,
        } as QLCCUtilityBookingModel);

        setLoading(false);
        notification(status_message[response.status!], STATUS_MESSAGE[`${props.action}_${response.status}`]);
        if (response.status === 0) {
            props.onEditSuccess(response.id, data);
            props.onClose();

            return response?.ref_id ?? null
        }

        return null;

    }

    const handleSubmit = () => {
        if (!handleValidateField()) {
            notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["FIELD_REQUIRED"])

            return;
        }
        setLoading(true)
        if (props.action === ACTIONS.EDIT) {

            setLoading(true)
            qLCClient.qLCCUtilityBookingCheck({
                ...new QLCCUtilityBookingModel(),
                ...data,
                start_time: data.start_time ? TransDateTime(data.start_time) : null,
                end_time: data.end_time ? TransDateTime(data.end_time) : null,
            } as QLCCUtilityBookingModel)
                .then(res => {
                    setLoading(false)
                    setDataCheck(res)
                    const excludedSefl = res.filter(x => x.id !== data?.id);
                    if (data?.status_code !== "2") {
                        if (excludedSefl.length > 0) {
                            handleOpen()
                            setDuplicateIds(res.filter(x => !x.is_duplicate).map(x => x.id ?? ""));
                        }
                        else {
                            handleUpdate()
                        }
                    }
                    else {
                        notification(TYPE_MESSAGE.WARNING, `Không thể chỉnh sửa ` + STATUS_MESSAGE["UTILITY_APPROVED"])

                    }
                })


        }
        else {

            setLoading(true)
            qLCClient.qLCCUtilityBookingCheck({
                ...new QLCCUtilityBookingModel(),
                ...data,
                start_time: data.start_time ? TransDateTime(data.start_time) : null,
                end_time: data.end_time ? TransDateTime(data.end_time) : null,
            } as QLCCUtilityBookingModel)
                .then(res => {
                    setLoading(false)
                    setDataCheck(res)

                    if (res.length > 0) {
                        handleOpen()
                        setDuplicateIds(res.filter(x => !x.is_duplicate).map(x => x.id ?? ""));

                    }
                    else {
                        handleInsert()
                    }

                })

            // 

        }
    };

    const handleChangeInput = (e: any) => {
        const { name, value, checked, type } = e.target;

        if (type === 'checkbox') {
            setData({ ...data, [name]: checked } as QLCCUtilityBookingModel);

        }
        else setData({ ...data, [name]: value } as QLCCUtilityBookingModel);
    };

    const handleChangeInputSelect = (e: any) => {
        const { value } = e.target;
        setData({ ...data, unit: value } as QLCCUtilityBookingModel);
    }





    const handleChangeDate = (e: any) => {
        const { value, name } = e.target;

        if (value instanceof Date && !isNaN(value.valueOf())) {
            setData(pre => ({
                ...pre, [name]: value
            } as QLCCUtilityBookingModel));
        }
        // setData({ ...data, [name]: new Date(value) } as SoVch05M);
    }

    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = () => {


        // if (isDefaultPre === true && data?.is_default === false) {

        //     qLCClient.qLCCApartmentsGet({
        //         ...new QLCCApartmentModel(),
        //         service_id: data?.id,
        //         apartment_building_id: data?.apartment_building_code,
        //         apartment_type_id: data?.apartment_type_id,
        //         action_type: 'GET_TOTAL_SERVICE_APARTMENT_BY_SERVICEID'
        //     } as QLCCApartmentModel)
        //         .then(res => {
        //             setLoading(false);
        //             const totalApartmentMessage = (
        //                 <div>
        //                     Có tổng <span style={{ fontWeight: 700, fontSize: "16px", color: 'red' }}>
        //                         {Number.parseInt(res[0].message!)}
        //                     </span> căn hộ đang sử dụng phí này. Bạn chăc chắn muốn xóa mặt định?
        //                 </div>
        //             );
        //             setActionUpdateService(ACTIONS.EDIT)

        //             setTotalApartmentMessage(totalApartmentMessage)
        //         });
        // }
        // else {

        setTotalApartmentMessage(<>Bạn chắc chắn muốn đăng ký?</>);
        setActionUpdateService("UPDATE-BUT-NOT-DELETE")

        // }

        setOpen(true)
    }

    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                handleSubmit();
                // handleOpen()
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
            case ACTIONS.APPROVED:
                handleApproved();
                break;
            case ACTIONS.CANCELAPPROVAL:
                handleCancelApproval();
                break;
            default: break;
        }
    }

    const handleChangeCellColor = (e: CellPreparedEvent<QLCCUtilityBookingModel, any>) => {
        // console.log(e.data?.status_id)
        if (e.column.dataField === "status_name" && e.data) {
            switch (e.data?.status_code) {

                case null:
                case "1":
                    e.cellElement.className += "utility-status utility-pending-status"
                    break;
                case "5":
                    e.cellElement.className += "utility-status utility-refuse-status"
                    break;
                case "2":
                    e.cellElement.className += "utility-status utility-approved-status"
                    break;
            }
        }


    }
    return (
        <Dialog
            // id="sysMenu"
            open={props.open}
            onClose={props.onClose}
            // sx={{
            //     display: "flex",
            //     justifyContent: "flex-end",
            // }}
            PaperProps={{
                sx: !fullScreen ? {
                    minHeight: "90vh",
                    height: "90vh"
                } : undefined
            }}
            TransitionComponent={Transition}
            maxWidth="lg"
            fullWidth
            fullScreen={fullScreen}
            className="Asset-control"
        >
            {/* <Dialog
                open={openCamera.open}
                onClose={handleCloseCamera}
                sx={{ height: "inherit" }}
            >
                
            </Dialog> */}

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === ACTIONS.EDIT || _id
                                    ? "[Cập nhật]"
                                    : props.action === ACTIONS.APPROVED
                                        ? "[Duyệt]"
                                        : "[Thêm mới]"
                            }`}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1.25 }}>
                        <IconButton
                            edge="end"

                            onClick={() => {
                                setFullscreen(pre => !pre)
                            }}
                            aria-label="full screen"
                        >
                            {fullScreen ? <FullscreenExitIcon sx={{ color: '#1f2937' }} /> : <FullscreenIcon sx={{ color: '#1f2937' }} />}
                        </IconButton>
                        <IconButton
                            edge="end"
                            onClick={() => {
                                props.onClose();
                            }}
                            aria-label="close"
                        >
                            <CloseIcon
                                sx={{ color: '#1f2937' }}
                            />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <Accordion className="Asset-control" color="#1565C0" expanded={isExpandUtilityBookingAction} onChange={() => { setIsCalledReceiptAction(true); setIsExpandUtilityBookingAction(pre => !pre) }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ backgroundColor: "#e0f2fe" }}
                    >
                        <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Đăng ký hiện tại</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">
                            <div className="col-xl-6">
                                {/* Mã phiếu */}
                                {/* <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel

                                            sx={{ fontSize: 14, color: "#333", }}>
                                            Mã phiếu
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            autoFocus
                                            placeholder="Mã phiếu ..."
                                            name="so_ct"
                                            onChange={handleChangeInput}
                                            value={data?.so_ct ?? null}
                                            disabled={props.action === ACTIONS.VIEW }
                                        />
                                    </Grid>
                                </Grid> */}
                                <div className="card">

                                    <div className="card-body pt-2">
                                        <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                            Chọn dịch vụ
                                        </div>
                                        {/* Chọn khách hàng */}

                                        <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                            <Grid item xs={12} md={4}>
                                                <InputLabel required htmlFor="resident-select" sx={{ fontSize: 14, color: '#333' }}>Chọn khách hàng</InputLabel>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <DHSDataGridFilterListByProjectSelect
                                                    id="customer-select"
                                                    value={data?.id_customer ?? null}
                                                    table_list="dm_kh"
                                                    // placeholder="Chọn NCC/NV"
                                                    // filter={props.payload}
                                                    name="idcustomer"
                                                    dataGridOnSelectionChanged={async (e, newValue: any) => {

                                                        const resident = await qLCClient.qLCCResidentsGet({
                                                            ...new QLCCResidentsModel(),
                                                            ma_cty: props?.itemSelected?.ma_cty,
                                                            apartment_building_id: props?.itemSelected?.apartment_building_id,
                                                            id: newValue?.idcustomer
                                                        } as QLCCResidentsModel);
                                                        if (resident.length === 1) {
                                                            const userInfo = await homePageClient.userInfoWebGet({
                                                                ...new UserInfoModel(),
                                                                apartment_id: resident[0]?.apartment_id,
                                                                action_type: 'GET-USER'
                                                            } as UserInfoModel)

                                                            if (userInfo.length > 0) {
                                                                var userActive: UserInfoModel | null = null;
                                                                userInfo.forEach(user => {
                                                                    if (!user.isblock || !user.disabled) {
                                                                        userActive = user;
                                                                        return;
                                                                    }
                                                                })
                                                                if (userActive !== null) {
                                                                    handleChangeInput({
                                                                        target: {
                                                                            name: "id_customer",
                                                                            value: newValue?.idcustomer ?? null,
                                                                        },
                                                                    });
                                                                    setData(pre => ({
                                                                        ...pre,
                                                                        username: userActive?.username,
                                                                        apartment_id: resident[0]?.apartment_id
                                                                    } as QLCCUtilityBookingModel))
                                                                }
                                                            }
                                                            else {
                                                                notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE[`USER_NOT_EXISTS`])
                                                            }
                                                        }


                                                    }}
                                                    syncDataGridSelection={function (e): void {
                                                        setData(pre => ({ ...pre, id_customer: e.value } as QLCCUtilityBookingModel));
                                                    }}
                                                    gridBoxDisplayExpr={function (item: any): string {
                                                        return item && `${item.ma_kh} - ${item.ten_kh}`;
                                                    }}

                                                    disabled={props.action === ACTIONS.VIEW}
                                                    readOnly={props.action === ACTIONS.APPROVED}
                                                />


                                            </Grid>
                                        </Grid>
                                        {/* Chọn dịch vụ */}
                                        <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                            <Grid item xs={12} md={4}>
                                                <InputLabel
                                                    required
                                                    sx={{ fontSize: 14, color: "#333", }}
                                                >
                                                    Chọn dịch vụ
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <DHSAutocompleteFilterListSelect
                                                    id="main-direction-select"
                                                    table_list="dm_dich_vu"
                                                    value={
                                                        data?.utility_id
                                                    }
                                                    
                                                    onValueChanged={(e, newValue: any) => {
                                                        handleChangeInput({
                                                            target: {
                                                                name: "utility_id",
                                                                value: newValue?.id ? newValue.id : null,
                                                            },
                                                        });


                                                        setData(pre => ({
                                                            ...pre,
                                                            amount: newValue?.prices ?? 0,
                                                            unit: newValue?.unit ?? ""
                                                        } as QLCCUtilityBookingModel))

                                                        if (data?.quantity && newValue?.prices !== null) {
                                                            setData(pre => ({
                                                                ...pre,
                                                                total_amount: data.quantity! * newValue?.prices!
                                                            } as QLCCUtilityBookingModel))
                                                        }
                                                    }}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                    readOnly={props.action === ACTIONS.APPROVED}
                                                />

                                                {/* <Autocomplete
                                                    id="main-direction-select"
                                                    sx={{ width: "100%" }}
                                                    options={utilityOptions}
                                                    autoHighlight
                                                    getOptionLabel={(option: any) => option?.name}
                                                    value={
                                                        data?.utility_id
                                                            ? utilityOptions.find(
                                                                (item: any) => item.id === data.utility_id
                                                            )
                                                            : null
                                                    }
                                                    onChange={(e, newValue: any) => {
                                                        handleChangeInput({
                                                            target: {
                                                                name: "utility_id",
                                                                value: newValue?.id ? newValue.id : null,
                                                            },
                                                        });


                                                        setData(pre => ({
                                                            ...pre,
                                                            amount: newValue?.prices ?? 0,
                                                            unit: newValue?.unit ?? ""
                                                        } as QLCCUtilityBookingModel))

                                                        if (data?.quantity && newValue?.prices !== null) {
                                                            setData(pre => ({
                                                                ...pre,
                                                                total_amount: data.quantity! * newValue?.prices!
                                                            } as QLCCUtilityBookingModel))
                                                        }

                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            className="cc-input"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "new-password",
                                                                // type: "search" // disable autocomplete and autofill
                                                            }}
                                                        />
                                                    )}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                    readOnly={props.action === ACTIONS.APPROVED}
                                                /> */}
                                            </Grid>
                                        </Grid>
                                        {/* Mô tả */}
                                        <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                            <Grid item xs={12} md={4}>
                                                <InputLabel
                                                    required
                                                    sx={{ fontSize: 14, color: "#333", }}>
                                                    Mô tả
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <TextField
                                                    className="cc-input"
                                                    sx={{
                                                        width: "100%",
                                                        marginRight: "5px",
                                                    }}
                                                    autoFocus
                                                    // placeholder="Tiêu đề ..."
                                                    name="title"
                                                    onChange={handleChangeInput}
                                                    value={data?.title ?? null}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                    inputProps={{
                                                        readOnly: props.action === ACTIONS.APPROVED
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>



                                        {/* Giá tiện ích */}

                                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Chi phí</InputLabel>
                            <CurrencyTextField
                                className="flex-grow-1 form-control currency-input"
                                style={{ width: "100%", textAlign: "right" }}
                                allowDecimals
                                decimalsLimit={2}
                                decimalSeparator="."
                                groupSeparator=","
                                decimalScale={0}
                                // placeholder="Chi phí"
                                intlConfig={{ locale: 'vi-VN', currency: 'VND' }}
                                name="prices"
                                onValueChange={handleChangeCurrentInput}
                                value={data?.prices ?? undefined}
                                disabled={props.action === ACTIONS.VIEW }
                            />

                        </Box> */}

                                    </div>
                                </div>

                                <div className="card mt-2">

                                    <div className="card-body pt-2">
                                        <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                            Thời gian sử dụng
                                        </div>

                                        {/*Sử dụng từ */}
                                        <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                            <Grid item xs={12} md={4}>
                                                <InputLabel
                                                    required
                                                    sx={{ fontSize: 14, color: "#333", }}
                                                >
                                                    Sử dụng từ
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <DateBox applyValueMode="useButtons"
                                                    defaultValue={data?.start_time ?? undefined}
                                                    value={data?.start_time ?? undefined}
                                                    type="datetime"
                                                    // min={props.action === ACTIONS.ADD ? moment().startOf("date").toDate() : undefined}

                                                    displayFormat={"dd/MM/yyyy HH:mm:ss"}
                                                    // min={this.min}
                                                    // max={this.now}
                                                    inputAttr={{ 'aria-label': 'Date Time', }}
                                                    onValueChanged={(e) => handleChangeDate({
                                                        target: {
                                                            name: "start_time",
                                                            value: e.value
                                                        }
                                                    })}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                    readOnly={props.action === ACTIONS.APPROVED} />
                                            </Grid>
                                        </Grid>
                                        {/*Sử dụng đến */}
                                        <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                            <Grid item xs={12} md={4}>
                                                <InputLabel
                                                    required
                                                    sx={{ fontSize: 14, color: "#333", }}
                                                >
                                                    Sử dụng đến
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <DateBox
                                                    applyValueMode="useButtons"
                                                    defaultValue={data?.end_time ?? undefined}
                                                    value={data?.end_time ?? undefined}
                                                    type="datetime"
                                                    // min={props.action === ACTIONS.ADD ? moment().startOf("date").toDate() : undefined}

                                                    displayFormat={"dd/MM/yyyy HH:mm:ss"}

                                                    // min={this.min}
                                                    // max={this.now}
                                                    pickerType="calendar"
                                                    inputAttr={{ 'aria-label': 'Date Time', }}
                                                    onValueChanged={(e) => {
                                                        handleChangeDate({
                                                            target: {
                                                                name: "end_time",
                                                                value: e.value
                                                            }
                                                        });


                                                        setData(pre => ({
                                                            ...pre,
                                                            quantity: moment(pre.start_time).diff(e.value, "hours")
                                                        } as QLCCUtilityBookingModel))
                                                    }}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                    readOnly={props.action === ACTIONS.APPROVED}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>


                            </div>
                            {/* chỗ này cắt div*/}
                            <div className="col-xl-6">
                                {/* Người sử dụng */}
                                {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Người sử dụng
                            </InputLabel>

                            <Autocomplete
                                id="main-direction-select"
                                sx={{ width: "100%" }}
                                options={mainDirectionOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.apartment_id
                                        ? mainDirectionOptions.find(
                                            (item: any) => item.id === data.apartment_id
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "apartment_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password",
                                            // type: "search" // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === ACTIONS.VIEW }
                            />
                        </Box> */}

                                <div className="card">

                                    <div className="card-body pt-2">
                                        <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                            Thanh toán
                                        </div>
                                        {/*Đơn vị tính */}
                                        <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                            <Grid item xs={12} md={4}>
                                                <InputLabel
                                                    sx={{ fontSize: 14, color: "#333", }}
                                                >
                                                    Đơn vị tính
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <TextField
                                                    className="cc-input"
                                                    sx={{
                                                        width: "100%",
                                                        marginRight: "5px",
                                                    }}
                                                    // placeholder="Đơn giá..."
                                                    // inputProps={{
                                                    //     style: {
                                                    //         textAlign: "right"

                                                    //     }
                                                    // }}
                                                    name="unit"
                                                    // onChange={handleChangeInput}
                                                    value={data?.unit ?? null}
                                                    type="text"
                                                    disabled={props.action === ACTIONS.VIEW}
                                                    inputProps={{
                                                        readOnly: props.action === ACTIONS.APPROVED
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        {/* Số lượng */}
                                        <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                            <Grid item xs={12} md={4}>
                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số lượng</InputLabel>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <NumberBox
                                                    // className="cc-input"
                                                    format="0,###"
                                                    inputAttr={{ 'aria-label': 'Fixed Poing Format', 'style': 'text-align:right' }}
                                                    defaultValue={data?.quantity ?? undefined}
                                                    value={data?.quantity ?? undefined}

                                                    // style={{
                                                    //     textAlign: "right"
                                                    // }}
                                                    onValueChange={(value) => {
                                                        handleChangeInput({
                                                            target: {
                                                                name: "quantity",
                                                                value: value
                                                            }
                                                        });
                                                        if (data?.amount) {
                                                            setData(pre => ({
                                                                ...pre,
                                                                total_amount: data.amount! * value
                                                            } as QLCCUtilityBookingModel))
                                                        }
                                                    }}

                                                    disabled={props.action === ACTIONS.VIEW}
                                                    readOnly={props.action === ACTIONS.APPROVED}

                                                />
                                                {/* <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            // placeholder="Đơn giá..."
                                            inputProps={{
                                                style: {
                                                    textAlign: "right"

                                                }
                                            }}
                                            name="amount"
                                            onChange={handleChangeInput}
                                            value={data.amount}
                                            type="number"
                                            disabled={props.action === ACTIONS.VIEW }
                                        /> */}
                                            </Grid>
                                        </Grid>
                                        {/* Đơn giá */}
                                        <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                            <Grid item xs={12} md={4}>
                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Đơn giá</InputLabel>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <NumberBox
                                                    // className="cc-input"
                                                    format="#,###"
                                                    inputAttr={{ 'aria-label': 'Fixed Poing Format', 'style': 'text-align:right' }}
                                                    defaultValue={data?.amount ?? undefined}
                                                    value={data?.amount ?? undefined}

                                                    // style={{
                                                    //     textAlign: "right"
                                                    // }}
                                                    onValueChange={(value) => {
                                                        handleChangeInput({
                                                            target: {
                                                                name: "amount",
                                                                value: value
                                                            }
                                                        })
                                                        if (data?.quantity) {
                                                            setData(pre => ({
                                                                ...pre,
                                                                total_amount: data.quantity! * value
                                                            } as QLCCUtilityBookingModel))
                                                        }

                                                    }}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                    readOnly={props.action === ACTIONS.APPROVED}
                                                />
                                                {/* <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            // placeholder="Đơn giá..."
                                            inputProps={{
                                                style: {
                                                    textAlign: "right"

                                                }
                                            }}
                                            name="amount"
                                            onChange={handleChangeInput}
                                            value={data.amount}
                                            type="number"
                                            disabled={props.action === ACTIONS.VIEW }
                                        /> */}
                                            </Grid>
                                        </Grid>
                                        {/* Thành tiền */}
                                        <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                            <Grid item xs={12} md={4}>
                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Thành tiền</InputLabel>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <NumberBox
                                                    // className="cc-input"
                                                    format="#,###"
                                                    inputAttr={{ 'aria-label': 'Fixed Poing Format', 'style': 'text-align:right' }}
                                                    defaultValue={data?.total_amount ?? undefined}
                                                    value={data?.total_amount ?? undefined}

                                                    // style={{
                                                    //     textAlign: "right"
                                                    // }}
                                                    onValueChange={(value) => handleChangeInput({
                                                        target: {
                                                            name: "total_amount",
                                                            value: value
                                                        }
                                                    })}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                    readOnly={props.action === ACTIONS.APPROVED}
                                                />
                                                {/* <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            // placeholder="Đơn giá..."
                                            inputProps={{
                                                style: {
                                                    textAlign: "right"

                                                }
                                            }}
                                            name="amount"
                                            onChange={handleChangeInput}
                                            value={data.amount}
                                            type="number"
                                            disabled={props.action === ACTIONS.VIEW }
                                        /> */}
                                            </Grid>
                                        </Grid>

                                    </div>
                                </div>
                                <div className="card mt-2">

                                    <div className="card-body pt-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-4">
                                                <div className="pb-0" style={{ fontSize: "16px" }}>
                                                    Trạng thái
                                                </div>
                                            </div>
                                            <div className={`${((data?.status_code && status_code_for_show_reason.includes(data?.status_code)) && (props.action === ACTIONS.APPROVED || props.action === ACTIONS.VIEW)) ? "col-8" : "col-12"}`}>

                                                {
                                                    props.action !== ACTIONS.ADD && <div className="d-flex flex-row align-items-center">
                                                        <IconButton disableTouchRipple disableRipple aria-label="approved" sx={{ color: status_approved_color[data?.status_code ? data?.status_code!.toString() : ""], justifyContent: "start", paddingLeft: 0 }}>
                                                            {
                                                                status_approved_icon[data?.status_code ? data?.status_code!.toString() : "null"]
                                                            }

                                                        </IconButton>
                                                        <Typography fontSize={13} fontWeight={600} color={status_approved_color[data?.status_code ? data?.status_code!.toString() : "null"]} textAlign={"start"} variant="button">
                                                            {
                                                                data?.status_name
                                                            }
                                                        </Typography>
                                                    </div>


                                                }

                                            </div>
                                            {
                                                props.action === ACTIONS.VIEW && <div className="col-12">

                                                    <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                        <Grid item xs={12} md={4}>
                                                            <InputLabel
                                                                sx={{ fontSize: 14, color: "#333", }}
                                                            >
                                                                Lý do
                                                            </InputLabel>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <TextField
                                                                className="cc-input"
                                                                sx={{
                                                                    width: "100%",
                                                                    marginRight: "5px",
                                                                }}
                                                                // placeholder="Đơn giá..."
                                                                // inputProps={{
                                                                //     style: {
                                                                //         textAlign: "right"

                                                                //     }
                                                                // }}
                                                                name="reason"
                                                                onChange={handleChangeInput}
                                                                value={data?.reason ?? null}
                                                                type="text"
                                                                disabled={props.action === ACTIONS.VIEW}
                                                            // inputProps={{
                                                            //     readOnly: props.action === ACTIONS.APPROVED
                                                            // }}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                </div>
                                            }
                                        </div>
                                        {
                                            (data?.status_code === "2" && (props.action === ACTIONS.APPROVED)) && <div className="row">
                                                {/* <div className="col-12 col-xl-4">
       
    </div> */}
                                                <div className="col-12">
                                                    <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                        <Grid item xs={12} md={4}>
                                                            <InputLabel
                                                                sx={{ fontSize: 14, color: "#333", }}
                                                            >
                                                                Lý do hủy
                                                            </InputLabel>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <TextField
                                                                className="cc-input"
                                                                sx={{
                                                                    width: "100%",
                                                                    marginRight: "5px",
                                                                }}
                                                                // placeholder="Đơn giá..."
                                                                // inputProps={{
                                                                //     style: {
                                                                //         textAlign: "right"

                                                                //     }
                                                                // }}
                                                                name="reason"
                                                                onChange={handleChangeInput}
                                                                value={data?.reason ?? null}
                                                                type="text"
                                                                disabled={props.action === ACTIONS.VIEW}
                                                            // inputProps={{
                                                            //     readOnly: props.action === ACTIONS.APPROVED
                                                            // }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                        }



                                    </div>
                                </div>


                                {/* Trạng thái tiện ích*/}
                                {/* <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                            <Grid item xs={12} md={4}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Trạng thái tiện ích
                            </InputLabel>
                            </Grid>
                            <Grid item xs={12} md={8}>
                            <Autocomplete
                                id="main-direction-select"
                                sx={{ width: "100%" }}
                                options={utilityBookingStatusOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.status_id
                                        ? utilityBookingStatusOptions.find(
                                            (item: any) => item.id === data.status_id
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "status_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === ACTIONS.VIEW }
                            />
                            </Grid>
                        </Grid> */}
                                {/* Sự dụng cho dự án */}
                                {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333" }}
                            >
                                Sử dụng cho dự án
                            </InputLabel>
                            <Autocomplete
                                id="apartment-select"
                                sx={{ width: "100%" }}
                                multiple
                                filterSelectedOptions
                                options={apartmentBuildingOption}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.apartment_building_code
                                        ? apartmentBuildingOption.filter(
                                            (item: any) => {
                                                return data.apartment_building_code?.split(";").includes(item.id)
                                            }

                                        )
                                        : []
                                }
                                onChange={(e, newValue: any) => {


                                    handleChangeMultipleInput({
                                        target: {
                                            name: "apartment_building_code",
                                            value: newValue ?? null,
                                        },
                                    });
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password"// disable autocomplete and autofill
                                        }}
                                        placeholder="Dự án ..."


                                    />
                                )}
                                disabled={props.action === ACTIONS.VIEW }
                            />
                        </Box> */}


                            </div>
                            {/* <div className="col-xl-6">
                       
                    </div> */}

                            {/* <div className="col-xl-6"> */}
                            {/* Loại căn hộ */}
                            {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Loại căn hộ
                            </InputLabel>
                            <Autocomplete
                                id="apartment-select"
                                sx={{ width: "100%" }}
                                multiple
                                filterSelectedOptions
                                options={apartmentTypeOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.apartment_type_name}
                                value={
                                    data?.apartment_type_id
                                        ? apartmentTypeOptions.filter(
                                            (item: any) => {
                                                return data.apartment_type_id?.split(";").includes(item.id)
                                            }
                                        )
                                        : []
                                }
                                onChange={(e, newValue: any) => {


                                    handleChangeMultipleInput({
                                        target: {
                                            name: "apartment_type_id",
                                            value: newValue ?? null,
                                        },
                                    });
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password"// disable autocomplete and autofill
                                        }}
                                        placeholder="Áp dụng cho loại căn hộ ..."


                                    />
                                )}
                                disabled={props.action === ACTIONS.VIEW }
                            />
                        </Box> */}


                            {/* </div> */}
                            {/* 
                    <div className="col-xl-12 mt-3">
                        <Accordion expanded={isExpandApartmentTypeService} onChange={() => { setIsCalledApartmentTypeService(true); setIsExpandApartmentTypeService(pre => !pre) }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>tiện ích loại căn hộ</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ApartmentTypeServiceList />
                            </AccordionDetails>
                        </Accordion>
                    </div> */}
                            {/* <div className="col-12">
                                
                            </div> */}

                        </div>
                    </AccordionDetails>
                </Accordion>
                {props.action === ACTIONS.APPROVED && <Accordion className="Asset-control" expanded={isExpandUtilityBookingRejectAction} onChange={() => { setIsExpandUtilityBookingRejectAction(pre => !pre) }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        sx={{ backgroundColor: "#e0f2fe" }}
                    >
                        <Box className="d-flex flex-row position-relative">
                            <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Đăng ký khác</Typography>
                            <Tooltip title="Chọn những đăng kí muốn từ chối." arrow placement="top">
                                <IconButton
                                    disableRipple
                                    edge="end"
                                    color="inherit"
                                    className="position-absolute"
                                    sx={{
                                        right: 0,
                                        top: 0,
                                        padding: 0,
                                        margin: 0,
                                        transform: "translate(100%, -40%)"
                                    }}
                                    onClick={props.onClose}
                                    aria-label="close"
                                >
                                    <HelpIcon sx={{ fontSize: 16, color: "#fff", backgroundColor: "#1565C0", borderRadius: "50%" }} />
                                </IconButton>
                            </Tooltip>

                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            data?.status_code !== "2" && <div className="row">
                                {/* <div className="col-12 col-xl-4">
                               
                            </div> */}
                                <div className="col-6">
                                    <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                        <Grid item xs={12} md={4}>
                                            <InputLabel
                                                sx={{ fontSize: 14, color: "#333", }}
                                            >
                                                Lý do từ chối
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                // placeholder="Đơn giá..."
                                                // inputProps={{
                                                //     style: {
                                                //         textAlign: "right"

                                                //     }
                                                // }}
                                                name="reason"
                                                onChange={handleChangeInput}
                                                value={data?.reason ?? null}
                                                type="text"
                                                disabled={props.action === ACTIONS.VIEW}
                                            // inputProps={{
                                            //     readOnly: props.action === ACTIONS.APPROVED
                                            // }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        }
                        <DHSDataGridEdit
                            data={dataTable}
                            table_name={menu?.component}
                            keyExpr="id"
                            mode="popup"
                            dataGridRef={dataGridRef}
                            allowEdit={false}
                            pageSize={menu?.page_size}
                            isPagination={menu?.is_pagination}
                            isMultiple={true}
                            groupingAutoExpandAll={true}
                            handleCellPrepared={handleChangeCellColor}
                        // handleSelectionChanged={handleSelectionChanged}
                        // handlePageChange={handlePageChange}

                        // handleExporting={handleExporting}
                        />
                    </AccordionDetails>
                </Accordion>}
            </DialogContent>
            <DialogActions>



                {
                    props.action === ACTIONS.APPROVED ? <DHSDialogAction2
                        isDialogAction
                        action={props.action}
                        menu={menu}
                        onClickAction={handleClickAction}
                        condition_actions={{
                            "APPROVED": data?.status_code !== "2",
                            "CANCELAPPROVAL": data?.status_code === "2"
                        }}
                    /> : <DHSDialogAction

                        action={props.action}
                        menu={menu}
                        onClickAction={handleClickAction}
                    />
                }


            </DialogActions>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Đã có người đăng ký trong thời gian này. Bạn có muốn đăng ký?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Có ${dataCheck.filter(x => x.id !== data?.id).length} người đã đăng ký trước đó. Thông tin chi tiết:`}
                    </DialogContentText>
                    <Box className="row">
                        {
                            dataCheck.length > 0 &&
                            dataCheck.filter(x => x.id !== data?.id).map(data => (
                                <Box className="col-12">
                                    <div className="card mt-1">

                                        <div className="card-body pt-2">
                                            <div className="pb-0" style={{ fontSize: "16px", fontWeight: "600 " }}>
                                                {data?.name_customer}
                                            </div>

                                            <div className="row" style={{ fontSize: "13px" }}>
                                                <div className="col-12 col-md-3">Sử dụng từ: </div>
                                                <div className="col-12 col-md-9" style={{ fontWeight: 600 }}>{moment(data?.start_time).format("DD/MM/yyyy HH:mm")}</div>
                                            </div>
                                            <div className="row" style={{ fontSize: "13px" }}>
                                                <div className="col-12 col-md-3">Sử dụng đến: </div>
                                                <div className="col-12 col-md-9" style={{ fontWeight: 600 }}>{moment(data?.end_time).format("DD/MM/yyyy HH:mm")}</div>
                                            </div>

                                        </div>
                                    </div>
                                </Box>
                            ))
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation sx={{ textTransform: "capitalize" }} autoFocus variant="contained" color="primary" onClick={async () => {
                        switch (props.action) {

                            case ACTIONS.ADD:
                                const ref_id_add = await handleInsert();
                                if (ref_id_add) {
                                    handleDuplicateUpdate(ref_id_add);

                                }
                                break;
                            case ACTIONS.EDIT:
                                const ref_id_upd = await handleUpdate();

                                if (ref_id_upd) {
                                    handleDuplicateUpdate(ref_id_upd);

                                }

                                break;
                        }
                    }}>
                        Chập nhận
                    </Button>

                    <Button disableElevation sx={{ textTransform: "capitalize" }} onClick={handleClose}>Hủy</Button>
                </DialogActions>
            </Dialog>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default ServiceBookingAction;
