import * as React from "react";
import { Box } from "@mui/material";
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, { Column, FilterRow, Paging, RemoteOperations, Scrolling, Selection } from "devextreme-react/data-grid";
import dxDataGrid, { DataType, SelectionChangedInfo } from "devextreme/ui/data_grid";
import { EventInfo, NativeEventInfo } from "devextreme/events";
import dxDropDownBox from "devextreme/ui/drop_down_box";
import { ValueChangedInfo } from "devextreme/ui/editor/editor";
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { HomeClient, SysGenRowTableModel } from "../../../app/shared/service-proxies/api-shared";
import { ToVietNamCurrency } from "../../utils/number.util";
import BASE_URL_API from "../../../app/shared/service-proxies/config";
import { AppSession } from "../../../app/shared/app-session/app-session";


interface Iprops {
    id?: string;
    name?: string;
    className?: any;
    style?: any;
    tabIndex?: any,
    options?: any[];
    dataGridOnSelectionChanged?: (e: any, newValue: any) => void;
    gridBoxDisplayExpr?: (item: any) => string;
    // renderOption?: ((props: React.HTMLAttributes<HTMLLIElement>, option: ICustomer, state: AutocompleteRenderOptionState) => React.ReactNode) | undefined
    syncDataGridSelection?: (e: NativeEventInfo<dxDropDownBox, Event> & ValueChangedInfo) => void;
    value?: any;
    width?: any;
    valueExpr?: any;
    disabled?: boolean;
    readOnly?: boolean;
    placeholder?: string,
    table_name?: string;
    filter?: any
}


var appSession: AppSession;

export default function DHSAutoCompleteWithDataGrid(props: Iprops) {


    const [isGridBoxOpened, setIsGridBoxOpened] = React.useState<boolean>(false);

    // 
    const dropDownRef = React.useRef<DropDownBox>(null);
    const dataGridRef = React.useRef<DataGrid>(null);
    // const [isDataGridReady, setIsDataGridReady] = React.useState<boolean>(false);
    //   
    const [columns, setColumns] = React.useState<SysGenRowTableModel[]>([]);
    const homeClient = new HomeClient(appSession, BASE_URL_API);




    React.useEffect(() => {

        let pr = new SysGenRowTableModel();
        pr.table_name = props.table_name //props.table_name;
        pr.show_form_list = true // props.show_form_list;
        homeClient?.sysGenRowTable(pr)
            .then((res) => {

                setColumns(res);
            });


    }, []);


    // const handleDataGridInitialized = (e: any) => {
    //     console.log(e)
    //     setIsDataGridReady(true);
    // };

    // const formatDataType = (format_type?: string, value?: any) => {
    //     switch (format_type) {
    //         case 'MONEY_VND':
    //             return ToVietNamCurrency(value);
    //         default:
    //             return value;


    //     }
    // }



    const onGridBoxOpened = (e: any) => {
        if (e.name === 'opened') {
            setIsGridBoxOpened(e.value)
        }
    }

    const dataGridOnSelectionChanged = (e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>) => {
        // this.setState({
        //   gridBoxValue: e.selectedRowKeys,
        //   isGridBoxOpened: false,
        // });
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                console.log(keys);

                props.dataGridOnSelectionChanged && props.dataGridOnSelectionChanged({}, keys[0])
                setIsGridBoxOpened(false);
            });
        }

    }
    const dataGridRender = () => {
        return (
            // <DataGrid
            //     ref={dataGridRef}
            //     width={props.width}
            //     dataSource={props.options}
            //     hoverStateEnabled={true}
            //     onSelectionChanged={dataGridOnSelectionChanged}


            // >
            //     {
            //         columns.map((row, index) => {

            //             return <Column
            //                 key={index}
            //                 caption={row.name_vn}
            //                 dataField={row.column_name ? row.column_name : ""}
            //                 dataType={row.data_type as DataType}
            //                 format={(value: any) => formatDataType(row.format_type, value)}
            //                 width={row.width ? row.width : 150}
            //                 visible={row.column_name?.toLocaleLowerCase() !== "Id".toLocaleLowerCase()}
            //             >

            //             </Column>
            //         })
            //     }
            //     <Selection mode="single" />
            //     <Scrolling mode="virtual" />
            //     <Paging enabled={true} pageSize={10} />
            //     <FilterRow visible={true} />
            //     <RemoteOperations paging={true}>

            //     </RemoteOperations>
            // </DataGrid >
            <DHSDataGridEdit
                dataGridRef={dataGridRef}
                // width={props.width}
                table_name={props.table_name}
                data={props.options ?? []}
                // columns={columns}
                // hoverStateEnabled={true}
                // selectedRowKeys={selectedRowKeys}
                height={40}
                defaultFilter
                allowEdit={false}
                isPagination={false}
                isMultiple={false}
                groupingAutoExpandAll={false}
                handleSelectionChanged={dataGridOnSelectionChanged}


            />
            // {/* {
            //     columns.map((row, index) => {



            //         return <Column
            //             key={index}
            //             caption={row.name_vn}
            //             dataField={row.column_name ? row.column_name : ""}
            //             dataType={row.data_type as DataType}
            //             format={(value: any) => formatDataType(row.format_type, value)}
            //             width={row.width ? row.width : 150}
            //             visible={row.column_name?.toLocaleLowerCase() !== "Id".toLocaleLowerCase()}
            //         >

            //         </Column>
            //     })
            // }
            // <Selection mode="single" />
            // <Scrolling mode="virtual" />
            // <Paging enabled={true} pageSize={10} />
            // <FilterRow visible={true} />
            // <RemoteOperations paging={true}>

            // </RemoteOperations> */}

        );
    }

    return (
        <>

            {
                //     <TextField
                //     className="cc-input"
                //     sx={{
                //         width: "100%",
                //         marginRight: "5px",
                //     }}
                //     onMouseDown={(e) => setActCx({
                //               ...actCx,
                //               open: true,
                //               type: "",
                //               payload: {
                //                 filter: props?.filter
                //               }
                //             })}

                //     value={valueGet ? customerOptions.find(x => x.id === valueGet)?.full_name : null}
                // />


                <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', width: "100%" }}>
                    <DropDownBox
                        ref={dropDownRef}
                        value={props.value}
                        opened={isGridBoxOpened}
                        valueExpr={props.valueExpr}
                        deferRendering={false}
                        displayExpr={props.gridBoxDisplayExpr}
                        placeholder={props.placeholder || ""}
                        showClearButton={true}
                        dataSource={props.options}
                        onValueChanged={props.syncDataGridSelection}
                        onOptionChanged={onGridBoxOpened}
                        contentRender={dataGridRender}
                        onKeyUp={(e) => {
                            if (e.event?.code === "Space" || e.event?.key === ' ') {
                                if (!isGridBoxOpened) {
                                    setIsGridBoxOpened(true)

                                }
                            }
                        }}
                        dropDownOptions={{
                            width: "auto",
                            height: "auto"

                        }}
                        tabIndex={props.tabIndex}
                        disabled={props.disabled}
                        readOnly={props.readOnly}
                    />
                </Box>


            }
        </>
    );
}
