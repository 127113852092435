import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { components } from "../../../common/ComponentsConfig/components";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import notification from "../../../common/notification/notification";
import DHSDate from "../../../components/DHS/DHS-Date/DHSDateComponent";
import DHSToolbar from "../../../components/DHS/DHS-Toolbar/DHSToolbar";
import Select from "../../../components/DHS/DHS_Select";
import Upload from "../../../components/DHS/DHS_Upload/Upload";
import DHSGridView from "../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import DrawerActivity from "../../../components/layouts/DrawerActivity/DrawerActivity";
import { getRole, getRoles } from "../../../redux/actions/RoleActions";
import { AppSession } from "../../shared/app-session/app-session";
import {
  CRMClient,
  CrmCustomerInfomationDetail,
  CustomerInfomation,
  HomeClient,
  RoleRightWebModel,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";

interface ISearchInfo {
  companyName: string;
  companyAddress: string;
  contactPerson: string;
}
let appSession: AppSession;
const menuRoleName = "CRM";
const PortalSaleGrid = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  // const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
  const [customerDetail, setCusomterDetail] =
    React.useState<CrmCustomerInfomationDetail>({
      ...new CrmCustomerInfomationDetail(),
      time_add: new Date(),
      account_id: SessionLogin.userName,
    } as CrmCustomerInfomationDetail);

  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const crmClient = new CRMClient(appSession, BASE_URL_API);
  const [searchInfo, setSearchInfo] = React.useState<ISearchInfo>({
    companyName: "",
    companyAddress: "",
    contactPerson: "",
  });
  const [dataTable, setDataTable] = React.useState<CustomerInfomation[]>([]);
  const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
  const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
  const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
  const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
  const [isShowSort, setShowSort] = React.useState<boolean>(false);
  const [isShowSelect, setShowSelect] = React.useState<boolean>(false);
  const [back, setBack] = React.useState<number>(-1);
  const [customerDetailView, setCusomterDetailView] = React.useState<
    CrmCustomerInfomationDetail[]
  >([]);
  const customMenu = [
    {
      menuName: isShowSelect ? "Ẩn Select Box" : "Hiện Select Box",
      onClick: () => {
        setShowSelect(!isShowSelect);
      },
    },
    {
      menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
      onClick: () => {
        setShowFilter(!isShowFilter);
      },
    },
    {
      menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
      onClick: () => {
        setShowSearch(!isShowSearch);
      },
    },
    {
      menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
      onClick: () => {
        setShowGroup(!isShowGroup);
      },
    },
    {
      menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
      onClick: () => {
        setShowSort(!isShowSort);
      },
    },
  ];

  React.useEffect(() => {
    homeClient
      ?.roleRightWebGetAll({
        ...new RoleRightWebModel(),
        rolename: menuRoleName,
        menuid: menu?.menuid,
      } as RoleRightWebModel)
      .then((res) => {
        dispatch(getRoles(res));
        setRoles(res);
      });
  }, []);
  React.useEffect(() => {
    try {
      if (customerDetail.id_master) {
        let tempInfo = new CrmCustomerInfomationDetail();
        tempInfo.id_master = customerDetail.id_master;
        crmClient.customerInfomationDetailGet(tempInfo).then((response) => {
          setCusomterDetailView(response);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [customerDetail.id_master]);

  const handleClickAction = (action: any) => {
    setBack((item) => (item -= 1));
    if (action.class_name.toUpperCase() === "SEARCH") {
      crmClient
        .crmCustomerInfomationGetAll({
          ...new CustomerInfomation(),
          ten_cty: searchInfo.companyName,
          dia_chi_cty: searchInfo.companyAddress,
          nguoi_lien_he: searchInfo.contactPerson,
        } as CustomerInfomation)
        .then((res) => {
          setDataTable(res);
        })
        .catch((err) => console.log(err));
    }
  };
  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setSearchInfo({ ...searchInfo, [name]: value });
  };
  return (
    <div className="container-fluid p-1">
      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          marginTop: 15,
          marginBottom: 15,
          fontSize: 22,
          fontWeight: 550,
        }}
      >
        <i
          className="bi bi-arrow-left"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.go(back);
          }}
        ></i>
        &nbsp;&nbsp;
        {menu?.bar}
      </div>{" "}
      <DHSToolbar
        id={customerDetail.id_master}
        list={
          roles
            ? roles.filter(
                (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
              )
            : []
        }
        onClickAction={(item) => handleClickAction(item)}
        customMenu={customMenu}
      />
      <div className="p-3">
        <div className="row">
          <div className="col-lg-2">
            <div className="form-group">
              <label className="label-input">Tên công ty</label>
              <input
                className="flex-grow-1 form-control"
                type="text"
                name="companyName"
                onChange={handleChangeInput}
              />
            </div>
          </div>
          <div className="col-lg-2">
            <div className="form-group">
              <label className="label-input">Địa chỉ công ty</label>
              <input
                className="flex-grow-1 form-control"
                type="text"
                name="companyAddress"
                onChange={handleChangeInput}
              />
            </div>
          </div>
          <div className="col-lg-2">
            <div className="form-group">
              <label className="label-input">Người liên hệ</label>
              <input
                className="flex-grow-1 form-control"
                type="text"
                name="contactPerson"
                onChange={handleChangeInput}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex row">
        <div className={"col-lg-9"}>
          <DHSGridView
            data={dataTable}
            onSelectRow={(row) => {
              setCusomterDetail({
                ...customerDetail,
                id_master: row,
              } as CrmCustomerInfomationDetail);
            }}
            // onListSelectRow={handleListOnselectRow}
            itemClicked={function (): void {
              throw new Error("Function not implemented.");
            }}
            table_name={
              menu?.component ? menu.component : menu?.menuid ? menu.menuid : ""
            }
            show_form_list={true}
            showFilterPanel={isShowFilter}
            showSortPanel={isShowSort}
            showSearchPanel={isShowSearch}
            showGroupPanel={isShowGroup}
            showSelectBox={isShowSelect}
          />
        </div>
        {/*Activity Layout */}
        <div
          className={"col-lg-3 container activity-container"}
          style={{ overflow: "hidden" }}
        >
          <div className="form-group" style={{ fontSize: 25 }}>
            <h3 style={{ textAlign: "center" }}>Hoạt động</h3>
          </div>

          <ul className="container">
            {(customerDetailView as CrmCustomerInfomationDetail[]).map(
              (detail) => {
                return (
                  <li className="border-bottom p-1">
                    <p>
                      <b>{`${detail.account_id} `}</b>
                      {`${detail.notes} vào `}
                      <i>{`${detail.time_add_f}`}</i>
                    </p>
                    {detail.attach_files && (
                      <a
                        href={detail.attach_files}
                        className="d-flex"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bi bi-paperclip"></i>
                        {
                          detail.attach_files.split("/")[
                            detail.attach_files.split("/").length - 1
                          ]
                        }
                      </a>
                    )}
                  </li>
                );
              }
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PortalSaleGrid;
