import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer, Notification } from '../../../../components/layouts';
import notification from "../../../../common/notification/notification";

import DHSGridView from '../../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
import { getRoles } from '../../../../redux/actions/RoleActions';
import { AppSession } from '../../../shared/app-session/app-session';
import {
    ApBookClient,
    HomeClient,
    OBCashBankClient,
    QLCCApartmentBuildingModel,
    QLCCClient,
    QLCCVehicleCardModel,
    QLCCVehicleCardStatusModel,
    RoleRightWebModel,
    SysActionType,
} from '../../../shared/service-proxies/api-shared';

import BASE_URL_API from '../../../shared/service-proxies/config';

import { useHistory, useLocation } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import { AppBar, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, Divider, IconButton, InputLabel, MenuItem, Select, Slide, TextField, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import moment from 'moment';
import { ACTIONS } from '../../../../common/enums/DHSToolbarRoleAction';
import DHSToolbarRole from '../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import BlockUI from '../../../../components/Block-UI/Block-ui';
import CTXDate from '../../../../components/CTX/DatePicker';
import DHSDialogAction from '../../../../components/DHS/DHS_DialogAction/DialogAction';
import AllVehicelCardAction from '../../actions/category-vehicle-card-action/all-vehicle-card-action';
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DataGrid from 'devextreme-react/data-grid';
import DHSBreadcrumb from '../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import BetweenTwoDate from '../../report/cash-book-report/component/between-two-date-X';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../../common/Interfaces/StatusMessage';
import { getIndexRowSelected, getKeyRowSelected } from '../../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';
import { EventInfo } from "devextreme/events";
import dxDataGrid, { SelectionChangedInfo } from "devextreme/ui/data_grid";
import { httpPost } from '../../../../common/httpService';
import DHSDialogAction2 from '../../../../components/DHS/DHS_DialogAction2/DialogAction2';
import { TransDateTime } from '../../../../components/utils/date.util';
import { setApartmentBuilding as setApartmentBuildingId } from '../../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction';
import DHSAutocompleteFilterListSelect from '../../../../components/DHSUtilComponent/DHSAutocompleteFilterListSelect/DHSAutocompleteFilterListSelect';
import DHSAutoCompleteWithDataSource from '../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource';



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const convert = ["success", "error", "warning"];
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any,
    obcash: any;

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null,
    obcash: {},
}

interface IFilter {
    apartment_building_id?: string | null,
    block_id?: string | null,
    floor_id?: string | null,
    apartment_id?: string | null,
    status_id?: string | null,
    status_code?: string | null,
}

const defaultFilter: IFilter = {
    apartment_building_id: null,
    block_id: null,
    floor_id: null,
    apartment_id: null,
    status_id: undefined,
    status_code: undefined,
}


const action_todo: { [key: number]: string } = {
    1: "is_return",
    2: "is_cancel",
    3: "is_renew",
    4: "is_broken",
    5: "is_sell_available",
    6: "is_sell",
    7: "is_change",
}

const roleMenu: string = 'CC';
const AllVehicleCard: React.FC<{}> = () => {
    const location = useLocation();

    const dispatch = useDispatch();
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const history = useHistory();

    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();

    const [loading, setLoading] = React.useState<boolean>(false);

    const [idItemSelected, setIdItemSelected] = React.useState<any>();

    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [reload, setReload] = React.useState<boolean>(false);

    const homeClient = new HomeClient(appSession, BASE_URL_API);

    const qlccClient = new QLCCClient(appSession, BASE_URL_API);

    const apBookClient = new ApBookClient(appSession, BASE_URL_API);

    const obCashBankClient = new OBCashBankClient(appSession, BASE_URL_API);

    const [dataTable, setDataTable] = React.useState<QLCCVehicleCardModel[]>([] as QLCCVehicleCardModel[]);

    const [vehicleCard, setVehicleCard] = React.useState<QLCCVehicleCardModel[]>([] as QLCCVehicleCardModel[]);

    const [actCx, setActCx] = useState<IAction>(defaultAction);

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);

    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);

    const [isShowSort, setShowSort] = React.useState<boolean>(false);

    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);

    const [fromDate, setFromDate] = React.useState<Date>();
    const [toDate, setToDate] = React.useState<Date>();

    const [apartmentBuildings, setApartmentBuildings] = React.useState<QLCCApartmentBuildingModel[]>([] as QLCCApartmentBuildingModel[]);

    const [apartmentBuilding, setApartmentBuilding] = React.useState<QLCCApartmentBuildingModel>({} as QLCCApartmentBuildingModel);

    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const [date, setDate] = React.useState<Date>(new Date());

    const [optionFindListVehicle, setOptionFindListVehicle] = React.useState<number>(1);

    // 
    const [filter, setFilter] = useState<IFilter>(defaultFilter);


    // 

    const [vehicleCardStatusOptions, setVehicleCardStatusOptions] = React.useState<QLCCVehicleCardStatusModel[]>([]);

    // 




    const onloadData = () => {
       
        setLoading(true)

        qlccClient.qLCCVehicleCardGet({
            ...new QLCCVehicleCardModel(),
            apartment_building_id: filter.apartment_building_id,
            ma_cty: SessionLogin?.ma_cty,
            //  điều kiện lọc
            // [action_todo[optionFindListVehicle!]]: true,
            status_id: filter?.status_id,
            date_modified: fromDate ? TransDateTime(fromDate) : null,
            date_modified1: toDate ? TransDateTime(toDate) : null,
            // ...DateFilter,

        } as QLCCVehicleCardModel)
            .then(res => {

                setVehicleCard(res);
                setDataTable(res);
                setLoading(false);
            }).catch(err => {
                console.log(err)
            })
       

    }


    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>();
    const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>(-1);

    useHotkeys<HTMLParagraphElement>('alt+1', () => setShowSearch(!isShowSearch));

    useHotkeys<HTMLParagraphElement>('alt+2', () => setShowGroup(!isShowGroup));

    useHotkeys<HTMLParagraphElement>('alt+3', () => setShowSort(!isShowSort));

    useHotkeys<HTMLParagraphElement>('alt+4', () => setShowFilter(!isShowFilter));

    React.useEffect(() => {
        setLoading(true);

        homeClient
            .roleRightWebGetAll({
                ...new RoleRightWebModel(),
                menuid: menu?.menuid,
                rolename: roleMenu,
                username: SessionLogin.userName,
            } as RoleRightWebModel)
            .then((res) => {
                dispatch(getRoles(res));
                setRoles(res);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            })


        qlccClient.qlccApartmentBuildingGet({
            ... new QLCCApartmentBuildingModel(),
            ma_cty: SessionLogin.ma_cty
        } as QLCCApartmentBuildingModel).then(res => {

            setApartmentBuildings(res);

            // setApartmentBuilding(res[0]);

            // if (res) {
            //     setFilter(pre => ({ ...pre, apartment_building_id: res[0].id }))
            // }

            setLoading(false);

        }).catch((err) => {
            console.log(err);
        });

        // setLoading(false)
        // httpPost<string>('api/Common/LoadFilterList', {
        //     tablelist: "dm_vehicle_card_status"
        // })
        //     .then(res => {

        //         const vehicleCardStatus: QLCCVehicleCardStatusModel[] = JSON.parse(res.data);
        //         setVehicleCardStatusOptions(vehicleCardStatus)
        //         setLoading(false)

        //     });



    }, [])

    React.useEffect(() => {
        setFilter(pre => ({ ...pre, apartment_building_id: apartment_building_id }))
    }, [apartment_building_id])

    //#region hanldeChangeInput
    const handleChangeFilterInput = (e: any) => {
        const { name, value } = e.target;

        setFilter(pre => ({ ...pre, [name]: value } as IFilter));

    };

    //#endregion

    React.useEffect(() => {


        if (filter && filter?.apartment_building_id) {

            setLoading(true)

            qlccClient.qLCCVehicleCardGet({
                ... new QLCCVehicleCardModel(),
                apartment_building_id: filter.apartment_building_id,
                ma_cty: SessionLogin?.ma_cty,
                //  điều kiện lọc
                // [action_todo[optionFindListVehicle!]]: true,
                status_id: filter?.status_id,
                date_modified: fromDate ? TransDateTime(fromDate) : null,
                date_modified1: toDate ? TransDateTime(toDate) : null,
                // ...DateFilter,

            } as QLCCVehicleCardModel)
                .then(res => {

                    setVehicleCard(res);
                    setDataTable(res);
                    setLoading(false);
                }).catch(err => {
                    console.log(err)
                })
        }

    }, [reload, filter?.apartment_building_id]);

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {

        const obj: string = idItemSelected;
        switch (action?.action_code) {
            case ACTIONS.VIEW:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: "VIEW",
                                payload: obj,
                                obcash: vehicleCard.find(data => data.id === obj)
                            })
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: "ADD"
                })
                break;
            case ACTIONS.EDIT:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: "EDIT",
                                payload: obj,
                                obcash: vehicleCard.find(data => data.id === obj)
                            })
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }


                break;
            case ACTIONS.COPY:
                setActCx({
                    ...actCx,
                    open: true,
                    type: "COPY",
                    payload: obj,
                    obcash: vehicleCard.find(data => data.id === obj)
                })
                break;
            case ACTIONS.DELETE:

                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        if (dataGridRef.current && key) {
                            if (window.confirm("Xác nhận xóa")) {
                                setLoading(true);
                                const obcashBank = dataTable.find(p => p.id === obj);
                                qlccClient.qLCCVehicleCardDel({
                                    ...new QLCCVehicleCardModel(),
                                    ma_cty: SessionLogin?.ma_cty,
                                    apartment_building_id: apartment_building_id,
                                    id: obj,
                                } as QLCCVehicleCardModel)
                                    .then(res => {
                                        Notification(convert[res.status === 0 ? 0 : 1], res.message)
                                        setLoading(false);
                                        if (res.status === 0) {
                                            setReload(pre => !pre)
                                        }
                                    })
                            }
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.CLOSE:
                setIsOpen(!isOpen);
                break;
            case ACTIONS.SEARCH:
                setIsOpen(!isOpen);
                break;
            case ACTIONS.OK:
                if (filter && filter?.apartment_building_id) {
                    onloadData();
                    setIsOpen(false);
                }
                break;
        }
    }


    const handleChangeInput = (e: any) => {

        const { name, value } = e.target;

        if (name === 'apartmentBuilding') {

            const apartmentBuildingSelect: any = apartmentBuildings.find(item => item.id === value);

            if (apartmentBuildingSelect) {
                setApartmentBuilding(apartmentBuildingSelect);
            }

        }

    };

    const handleChangeDate = (e: any) => {

        const { name, value } = e;

        if (name === 'date') {

            setDate(new Date(value));

        }

    };

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                const index = e.component.getRowIndexByKey(keys[0]);

                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
                setIdItemSelected(keys[0])
            });
        }
    };

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
        setIdItemSelected("")
    };


    return (
        <div className='container-fluid'>


            <AllVehicelCardAction
                open={actCx.open}
                onEditSuccess={() => setReload(!reload)}
                onClose={() => setActCx({ ...defaultAction })}
                action={actCx.type}
                itemSelected={actCx.payload}
                vehicleCard={actCx.obcash}
                apartmentBuildings={apartmentBuildings}
                payload={{
                    apartment_building_id: filter.apartment_building_id
                }}
            />

            <div className='row no-gutters my-1 align-items-center'>

                <div
                    className="d-flex title-color"
                    style={{
                        height: 30,
                        color: "rgb(0 78 255 / 88%)",
                        marginTop: 15,
                        marginBottom: 15,
                        fontSize: 22,
                        fontWeight: 550,
                    }}
                >
                    <DHSBreadcrumb
                        location={location}
                    />
                </div>
                {/* Dự án */}
           
            </div>

            {/* Control */}
            <DHSToolbarRole
                id={" "}
                menu={menu}
                onClickAction={handleClickAction}
            />

            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                keyExpr="id"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handleSelectionChanged={handleSelectionChanged}
                handlePageChange={handlePageChange}

            // handleExporting={handleExporting}
            />
            <BlockUI blocking={loading} title={""}></BlockUI>

            {/* Lọc dữ liệu */}
            <Dialog
                id='sysMenu4'
                open={isOpen}
                TransitionComponent={Transition}
                onClose={() => setIsOpen(false)}
                aria-describedby="alert-dialog-slide-description"
                className='d-flex justify-content-end'
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            Lọc dữ liệu bảng
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setIsOpen(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent>
                    {/* Mẫu báo cáo */}
                    {/* <Box className='w-100 mt-2'>
                        <InputLabel className='cc-label'>Dự án</InputLabel>
                        <DHSAutoCompleteWithDataSource
                            id="gender-select"
                           dataSource={apartmentBuildings}
                            displayMember='name'
                            valueMember='id'
                            value={ apartment_building_id }
                            placeholder='Chọn dự án'
                            onValueChanged={(e, newValue: any) => {
                                dispatch(setApartmentBuildingId(newValue?.id ?? ""))
                                // handleChangeFilterInput({
                                //     target: {
                                //         name: "apartment_building_id",
                                //         value: newValue?.id ? newValue.id : null,
                                //     },
                                // });
                            }}

                        /> 
                    </Box> */}

                    <Box className='w-100 mt-2'>
                        <InputLabel className='cc-label'>Nghiệp vụ</InputLabel>
                        <DHSAutocompleteFilterListSelect
                            table_list='dm_vehicle_card_status'
                            value={filter?.status_code}
                            onValueChanged={(e, newValue: any) => {
                                setOptionFindListVehicle(newValue?.code);
                                // handleChangeFilterInput({
                                //     target: {
                                //         name: "status_id",
                                //         value: newValue?.id
                                //     }
                                // })
                                setFilter(pre=> ({
                                    ...pre,
                                    status_id: newValue?.id,
                                    status_code: newValue?.code,
                                }))
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option?.code}>
                                        {option?.name1}
                                    </li>
                                );
                            }}

                        />
                        {/* <Select
                            className='cc-input w-100'
                            value={optionFindListVehicle}
                            onChange={(e: any) => {
                                setOptionFindListVehicle(e.target.value);
                            }}
                            defaultValue="_"
                        >
                            {
                                actionsTodo
                                && actionsTodo.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                ))
                            }
                        </Select> */}
                    </Box>

                    {/* Giữa 2 ngày */}
                    <Box className='w-100 mt-2'>
                        <BetweenTwoDate
                            fromDate={setFromDate}
                            toDate={setToDate}
                            reload={() => setReload(!reload)}
                        />
                    </Box>

                </DialogContent>
                <DialogActions>
                    <DHSDialogAction2

                        menu={menu}
                        onClickAction={handleClickAction}

                    />



                </DialogActions>
            </Dialog>

        </div >
    )
}

export default AllVehicleCard;


