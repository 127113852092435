import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { components } from "../../../common/ComponentsConfig/components";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import notification from "../../../common/notification/notification";
import DHSToolbar from "../../../components/DHS/DHS-Toolbar/DHSToolbar";
import DHSGridView from "../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { getRole, getRoles } from "../../../redux/actions/RoleActions";
import { AppSession } from "../../shared/app-session/app-session";
import {
  HomeClient,
  HRClient,
  LeaveForm,
  OvertimeForm,
  RoleRightWebModel,
} from "../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../shared/service-proxies/config";
import LeaveFormDialog from "./Childs/LeaveForm/LeaveFormDialog";
import OvertimeFormDialog from "./Childs/OvertimeForm/OvertimeFormDialog";

let appSession: AppSession;
const menuRoleName = "HR";
const PortalTimeKeepingGrid = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [action, setAction] = React.useState<any>({
    type: "",
    openDialog: false,
    component: ""
  });
  const [dataTable, setDataTable] = React.useState<any[]>([]);
  // const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
  const { path, url } = useRouteMatch();
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const hrClient = new HRClient(appSession, BASE_URL_API);
  const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
  const [itemSelected, selectItem] = React.useState<string>();
  const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
  const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
  const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
  const [isShowSort, setShowSort] = React.useState<boolean>(false);
  const [isShowSelect, setShowSelect] = React.useState<boolean>(false);
  const [back, setBack] = React.useState<number>(-1);
  const customMenu = [
    {
      menuName: isShowSelect ? "Ẩn Select Box" : "Hiện Select Box",
      onClick: () => {
        setShowSelect(!isShowSelect);
      },
    },
    {
      menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
      onClick: () => {
        setShowFilter(!isShowFilter);
      },
    },
    {
      menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
      onClick: () => {
        setShowSearch(!isShowSearch);
      },
    },
    {
      menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
      onClick: () => {
        setShowGroup(!isShowGroup);
      },
    },
    {
      menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
      onClick: () => {
        setShowSort(!isShowSort);
      },
    },
  ];

  React.useEffect(() => {
    homeClient
      ?.roleRightWebGetAll({
        ...new RoleRightWebModel(),
        rolename: menuRoleName,
        menuid: menu?.menuid,
      } as RoleRightWebModel)
      .then((res) => {
        dispatch(getRoles(res));
        setRoles(res);
      });
  }, []);

  React.useEffect(() => {
    switch (menu?.component) {
      case "TKOvertimeForm":
        hrClient
          ?.hROvertimeGet({
            ...new OvertimeForm(),
            ma_cty: SessionLogin.ma_cty,
            sender_code: SessionLogin.employeeCode,
            action_type: 1,
          } as OvertimeForm)
          .then((respond) => {
            setDataTable(respond);
          });
        break;
      case "TKLeaveForm":
        hrClient
          ?.hROnLeaveGet({
            ...new LeaveForm(),
            ma_cty: SessionLogin.ma_cty,
            sender_code: SessionLogin.employeeCode,
            action_type: 1,
          } as LeaveForm)
          .then((respond) => {
            setDataTable(respond);
          });
        break;
    }
  }, [menu?.component]);

  const handleClickAction = (action: RoleRightWebModel) => {
    switch (action.class_name?.toLocaleUpperCase()) {
      case "ADD":
        setAction({
          ...action,
          type: "ADD",
          openDialog: true,
          component: action.component,
        });
        break;
      case "EDIT":
        setAction({
          ...action,
          type: "EDIT",
          openDialog: true,
          component: action.component,
        });
        break;
      case "VIEWDETAIL":
        setAction({
          ...action,
          type: "VIEWDETAIL",
          openDialog: true,
          component: action.component,
        });
        break;
      case "DELETE":
        break;
    }
  };

  const result = (data: number | undefined, message: string | undefined) => {
    if (data === 0) {
      notification("success", message);
      window.location.reload();
    } else notification("error", message);
  };
  return (
    <div>
      {action.component.toUpperCase() === "LEAVEFORM" ? (
        <LeaveFormDialog
          open={action.openDialog}
          actionType={action.type}
          onClose={() => {
            setAction({ ...action, type: "", openDialog: false});
          }}
          itemClicked={itemSelected}
        />
      ) : action.component.toUpperCase() === "OVERTIMEFORM" ? (
        <OvertimeFormDialog
        open={action.openDialog}
        actionType={action.type}
        onClose={() => {
          setAction({ ...action, type: "", openDialog: false});
        }}
        itemClicked={itemSelected}
      />
      ) : <></>}
      <div className="container-fluid p-1">
        <DHSToolbar
          id={itemSelected}
          list={
            roles
              ? roles.filter(
                  (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
                )
              : []
          }
          onClickAction={handleClickAction}
          customMenu={customMenu}
        />
        <div
          className="d-flex title-color"
          style={{
            height: 30,
            color: "rgb(0 78 255 / 88%)",
            marginTop: 15,
            marginBottom: 15,
            fontSize: 22,
            fontWeight: 550,
          }}
        >
          <i
            className="bi bi-arrow-left"
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.go(back);
            }}
          ></i>
          &nbsp;&nbsp;
          {menu?.bar}
        </div>

        <DHSGridView
          onSelectRow={(row) => {
            selectItem(row);
          }}
          // onListSelectRow={handleListOnselectRow}
          itemClicked={function (): void {
            throw new Error("Function not implemented.");
          }}
          table_name={menu?.component ? menu.component : ""}
          data={dataTable}
          show_form_list={true}
          showFilterPanel={isShowFilter}
          showSortPanel={isShowSort}
          showSearchPanel={isShowSearch}
          showGroupPanel={isShowGroup}
          showSelectBox={isShowSelect}
        />
        {/* <Switch>
          <Route path={path} exact>
            <DHSToolbar
              id={itemSelected}
              list={
                roles
                  ? roles.filter(
                      (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
                    )
                  : []
              }
              onClickAction={handleClickAction}
              customMenu={customMenu}
            />
            <div
              className="d-flex title-color"
              style={{
                height: 30,
                color: "rgb(0 78 255 / 88%)",
                marginTop: 15,
                marginBottom: 15,
                fontSize: 22,
                fontWeight: 550,
              }}
            >
              <i
                className="bi bi-arrow-left"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.go(back);
                }}
              ></i>
              &nbsp;&nbsp;
              {menu?.bar}
            </div>

            <DHSGridView
              onSelectRow={(row) => {
                selectItem(row);
              }}
              // onListSelectRow={handleListOnselectRow}
              itemClicked={function (): void {
                throw new Error("Function not implemented.");
              }}
              table_name={menu?.component ? menu.component : ""}
              data={dataTable}
              show_form_list={true}
              showFilterPanel={isShowFilter}
              showSortPanel={isShowSort}
              showSearchPanel={isShowSearch}
              showGroupPanel={isShowGroup}
              showSelectBox={isShowSelect}
            />
          </Route>
          {roles &&
            roles.map((role, index) => {
              return (
                role.link &&
                role.link.length > 1 &&
                role.component && (
                  <Route
                    key={index}
                    path={role.is_parameter ? role.link + ":_id" : role.link}
                    component={components[role.component.toLocaleUpperCase()]}
                    exact
                  ></Route>
                )
              );
            })}
        </Switch> */}
      </div>{" "}
    </div>
  );
};

export default PortalTimeKeepingGrid;
