import { Box, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DHSButton from '../../../../../../components/DHSComponents/actions-and-list/DHSButton/DHSButton'
import { QLCCTaskModel } from '../../../../../shared/service-proxies/api-shared'
import { HintCode } from '../../../../../../common/enums/DHSInputDetail'
import DHSSelectBox from '../../../../../../components/DHSComponents/editors/DHSSelectBox/DHSSelectBox'
import moment from 'moment'

export interface IDataFilter extends QLCCTaskModel {
    views?: TYPE_VIEW,
}

interface IProps {
    zoom: boolean,
    onZoomDisplay: (e: any) => void,
    onValueChange?: (e: IDataFilter) => void;
    onInit?: (e: IDataFilter) => void;
    isFeedBack?: boolean
}

const WorkFilterData = (props: IProps) => {

    const [data, setData] = useState<IDataFilter>()
    const options = [
        {
            id: "cdate",
            title: "Ngày tạo công việc"
        },
        {
            id: "date_done",
            title: "Ngày hoàn thành"
        },
        {
            id: "ngay_ct",
            title: "Ngày báo cáo"
        },
    ]

    const options_views = [{ code: TYPE_VIEW.CALENDAR, name: TYPE_VIEW.CALENDAR }, { code: TYPE_VIEW.TABLE, name: TYPE_VIEW.TABLE },]

    const handleChange = async (e: any) => {
        const { name, value } = e.target;
        const obj = {
            ...data,
            [name]: value
        } as QLCCTaskModel
        setData(obj);

        if (props.onValueChange) {
            props.onValueChange(obj)
        }
    }

    useEffect(() => {
        const obj = {
            ... new QLCCTaskModel(),
            start_time: moment().startOf('month').toDate(),
            ngay_ct: moment().startOf('month').toDate(),
            end_time: moment().endOf('month').toDate(),
            views: TYPE_VIEW.CALENDAR,
            type_code: props.isFeedBack ? '1' : '3',
        } as IDataFilter;
        setData(obj);
        if (props.onInit) {
            props.onInit(obj)
        }
    }, [])

    return (
        <>
            <Stack direction={'row'} width={'100%'} justifyContent={'start'} spacing={2}>
                <Box className=''>
                    <DHSButton
                        icon={props.zoom ? 'showpanel' : 'hidepanel'}
                        onClick={props.onZoomDisplay}
                        height={'auto'}
                    />
                </Box>
                <DHSSelectBox
                    dataSource={[{ code: "3", name: "Công việc kế hoạch" }, { code: "1", name: "Công việc phát sinh" },]}
                    name="type_code"
                    onValueChange={e => handleChange({ target: { name: 'type_code', value: e } })}
                    displayExpr="name"
                    searchExpr="name"
                    valueExpr="code"
                    value={data?.type_code}
                    stylingMode='underlined'
                    hint={HintCode.TASK_TYPE}
                    disabled={props.isFeedBack}
                />
                <DHSSelectBox
                    dataSource={options}
                    name="option"
                    // onValueChanged={(e: any) => setOption(e.target.value)}
                    displayExpr="title"
                    searchExpr="title"
                    valueExpr="id"
                    value={'cdate'}
                    hint={HintCode.TASK_OPTION_FIND}
                    stylingMode='underlined'
                    disabled
                />
                <DHSSelectBox
                    dataSource={options_views}
                    name="views"
                    onValueChange={e => handleChange({ target: { name: 'views', value: e } })}
                    displayExpr="name"
                    searchExpr="name"
                    valueExpr="code"
                    value={data?.views}
                    stylingMode='underlined'
                    hint={HintCode.TASK_TYPE}
                />
            </Stack>
        </>
    )
}

export default WorkFilterData

export enum TYPE_VIEW {
    CALENDAR = 'CALENDAR',
    TABLE = 'TABLE'
}