import { TextField } from "@mui/material";
import Input from "@mui/material/Input";
import { useState } from "react";

interface IProps {
    onChange: (value: string, row: any, column: any) => void;
    handleEnterPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
    handleBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void,
    value?: string;
    placeholder?: string,
    label?: string;
    id?: string;
    name?: string;
    className?: string;
    disabled?: boolean;
    readOnly?: boolean;
    error?: boolean;
    errorMessage?: string;
    currencyPosition?: "left" | "right";
    numbersAfterDecimal?: number;
    step?: number;
    row?: any;
    column?: any;
    autoFocus?: boolean
}

const DHSInputText: React.FC<IProps> = (props) => {
    const {
        onChange,
        handleEnterPress,
        handleBlur,
        value,
        placeholder,
        error,
        numbersAfterDecimal = 2,
        errorMessage,
        className,
        disabled,
        id,
        name,
        readOnly,
        label,
        currencyPosition = "right",
    } = props;

    const [currentValue, setCurrentValue] = useState(value);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(e.currentTarget.value)
        onChange(e.currentTarget.value, props.row, props.column);
    };

    return (
        <>
            <TextField
                className={className}
                value={currentValue}
                placeholder={placeholder ? placeholder : ''}
                onChange={handleChange} disabled={disabled}
                onKeyDown={handleEnterPress}
                onBlur={handleBlur}
                autoFocus={props.autoFocus}
                sx={{"& input::placeholder": {
                    fontSize: "12px"
                  }}}/>
        </>
    );
};

export default DHSInputText;
