import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer, Notification } from '../../../components/layouts';
import DHSGridView from '../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
import { getRoles } from '../../../redux/actions/RoleActions';
import { AppSession } from '../../shared/app-session/app-session';
import {
    HomeClient,
    OBCashBankClient,
    ObCustomer,
    QLCCApartmentBuildingModel,
    QLCCClient,
    RoleRightWebModel,
    SysActionType,
    OBCustomerClient,
    ReportInfo,
} from '../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../shared/service-proxies/config';

import { useHistory, useLocation } from 'react-router-dom';
import BlockUI from '../../../components/Block-UI/Block-ui';
import { useHotkeys } from 'react-hotkeys-hook';
import DHSToolbarRole from '../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import { ACTIONS } from '../../../common/enums/DHSToolbarRoleAction';
import { AppBar, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, Divider, IconButton, InputLabel, Slide, TextField, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DHSDialogAction from '../../../components/DHS/DHS_DialogAction/DialogAction';
import { TransitionProps } from '@mui/material/transitions';
import CTXDate from '../../../components/CTX/DatePicker';
import moment from 'moment';
import ReceiableCustomerAction from '../actions/ob-customer-action/Receivable-customer-action';


import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import DHSPopupReportDate from '../../../components/DHS/DHS_Popup_Report/DHSPopupReportDate';
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { EventInfo } from "devextreme/events";
import { exportExcelReportNoFooter } from '../../../components/DHS/DHS_ExportExcelReport/exportExcelReportNoFooter';
import DataGrid from 'devextreme-react/data-grid';
import DHSBreadcrumb from '../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import { getIndexRowSelected, getKeyRowSelected } from '../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../common/Interfaces/StatusMessage';
import DHSAutoCompleteWithDataSource from '../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource';
import { CALL_API_LOAD_APARTMENT_BUILDING_LIST } from '../apartment-building/apartment-building-list';
import { setApartmentBuilding } from '../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const convert = ["success", "error", "warning"];
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any,
    obcash: any;

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null,
    obcash: {},
}

const roleMenu: string = 'CC';
const ReceivableCustomer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    const history = useHistory();

    const location = useLocation();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<any>();
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qlccClient = new QLCCClient(appSession, BASE_URL_API);
    const oBClient = new OBCustomerClient(appSession, BASE_URL_API);
    const obCashBankClient = new OBCashBankClient(appSession, BASE_URL_API);
    const [dataTable, setDataTable] = React.useState<ObCustomer[]>([] as ObCustomer[]);
    const [oBcustomers, setObcustomers] = React.useState<ObCustomer[]>([] as ObCustomer[]);
    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
    const [isShowSort, setShowSort] = React.useState<boolean>(false);
    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
    const customMenu = [
        {
            menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
            onClick: () => {
                setShowSearch(!isShowSearch);
            },
        },
        {
            menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
            onClick: () => {
                setShowGroup(!isShowGroup);
            },
        },
        {
            menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
            onClick: () => {
                setShowSort(!isShowSort);
            },
        },
        {
            menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
            onClick: () => {
                setShowFilter(!isShowFilter);
            },
        },
    ];

    const [apartmentBuildings, setApartmentBuildings] = React.useState<QLCCApartmentBuildingModel[]>([] as QLCCApartmentBuildingModel[]);
    // const [apartmentBuilding, setApartmentBuilding] = React.useState<QLCCApartmentBuildingModel>({} as QLCCApartmentBuildingModel);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [date, setDate] = React.useState<Date>(new Date());
    const [typeObject, setTypeObject] = React.useState<boolean>(true);
    const tk = '131'
    const onloadData = () => {
        oBClient
            .obCustomerGet({
                ... new ObCustomer(),
                ma_cty: SessionLogin.ma_cty,
                nam: date.getFullYear(),
                type: false,
                tk: tk,
                apartment_building_id: apartment_building_id,
            } as ObCustomer | any)
            .then(res => {
                if (res) {
                    setObcustomers(res);
                    // const arr: any = [];
                    // res.map(item => {
                    //     arr.push({
                    //         ...item,
                    //         cdate: moment(item.cdate).format("DD/MM/YYYY"),
                    //         ldate: moment(item.ldate).format("DD/MM/YYYY"),
                    //     })
                    // })
                    // setDataTable([...arr]);
                    setDataTable(res);
                }
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    }

    useHotkeys<HTMLParagraphElement>('alt+1', () => setShowSearch(!isShowSearch));

    useHotkeys<HTMLParagraphElement>('alt+2', () => setShowGroup(!isShowGroup));

    useHotkeys<HTMLParagraphElement>('alt+3', () => setShowSort(!isShowSort));

    useHotkeys<HTMLParagraphElement>('alt+4', () => setShowFilter(!isShowFilter));
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    React.useEffect(() => {
        setLoading(true);

        homeClient
            .roleRightWebGetAll({
                ...new RoleRightWebModel(),
                menuid: menu?.menuid,
                rolename: roleMenu,
                username: SessionLogin.userName,
            } as RoleRightWebModel)
            .then((res) => {
                dispatch(getRoles(res));
                setRoles(res);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            })


        CALL_API_LOAD_APARTMENT_BUILDING_LIST().then(res => {
            setApartmentBuildings(res);
            // setApartmentBuilding(res[0]);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
        // qlccClient.qlccApartmentBuildingGet({
        //     ... new QLCCApartmentBuildingModel(),
        //     ma_cty: SessionLogin.ma_cty
        // } as QLCCApartmentBuildingModel).then(res => {

        //     setApartmentBuildings(res);

        //     setApartmentBuilding(res[0]);

        //     setLoading(false);

        // }).catch((err) => {
        //     console.log(err);
        // })

    }, [menu?.component])

    React.useEffect(() => {
        onloadData();
    }, [reload, apartment_building_id]);

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {

        const obj: any = idItemSelected;

        switch (action?.action_code) {
            case ACTIONS.VIEW:

                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: ACTIONS.VIEW,
                                payload: obj.idob,
                                obcash: oBcustomers.find(p => p.idob === obj.idob)
                            })
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: "ADD"
                })
                break;
            case ACTIONS.EDIT:

                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: ACTIONS.EDIT,
                                payload: obj.id,
                                obcash: oBcustomers.find(p => p.idob === obj.idob)
                            })
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.COPY:

                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: ACTIONS.COPY,
                                payload: obj.id,
                                obcash: oBcustomers.find(p => p.idob === obj.idob)
                            })
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.DELETE:

                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            if (window.confirm("Xác nhận xóa")) {
                                setLoading(true);
                                const obcashBank = dataTable.find(p => p.idob === obj.idob);
                                oBClient.obCustomerDel({
                                    ...new ObCustomer(),
                                    ma_cty: SessionLogin.ma_cty,
                                    iDcustomer: obcashBank?.iDcustomer,
                                    tk: obcashBank?.tk,
                                    ma_nt: obcashBank?.ma_nt,
                                    ma_spct: obcashBank?.ma_spct,
                                    ma_bp: obcashBank?.ma_bp,
                                    ma_hd: obcashBank?.ma_hd,
                                    nam: obcashBank?.nam
                                } as ObCustomer).then(res => {
                                    Notification(convert[res.status === 0 ? 0 : 1], res.message)
                                    if (res.status === 0) {
                                        setReload(!reload);
                                    }
                                    setLoading(false);
                                })
                            }
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.CLOSE:
                setIsOpen(!isOpen);
                break;
            case ACTIONS.SEARCH:
                setIsOpen(!isOpen);
                break;

            case ACTIONS.SAVE:
                setLoading(true);
                onloadData();
                setIsOpen(false);
                break;

        }
    }


    // const handleChangeInput = (e: any) => {

    //     const { name, value } = e.target;

    //     if (name === 'apartmentBuilding') {

    //         const apartmentBuildingSelect: any = apartmentBuildings.find(item => item.id === value);

    //         if (apartmentBuildingSelect) {
    //             setApartmentBuilding(apartmentBuildingSelect);
    //         }

    //     }

    // };

    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        if (name === 'date') {
            setDate(new Date(value));
        }
    };

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
    const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
    const [reportInfo, setReportInfo] = React.useState<ReportInfo>();

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {

                const index = e.component.getRowIndexByKey(keys[0]);

                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
                setIdItemSelected(keys[0]);
            });
        }
    };



    return (
        <div className='container-fluid'>


            <ReceiableCustomerAction

                open={actCx.open}
                onEditSuccess={() => setReload(!reload)}
                onClose={() => setActCx({ ...defaultAction })}
                action={actCx.type}
                itemSelected={actCx.payload}
                obcash={actCx.obcash}
                apartmentBuildings={apartmentBuildings}
            // payload={apartmentBuilding}
            />

            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location}
                />
            </div>

            {/* Control */}
            <DHSToolbarRole
                id={" "}
                menu={menu}
                customMenu={customMenu}
                onClickAction={handleClickAction}
            />



            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                // key="so_ct"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handlePageChange={handlePageChange}
                handleSelectionChanged={handleSelectionChanged}
            />
            <BlockUI blocking={loading} title={""}></BlockUI>


            {/* Lọc dữ liệu */}
            <Dialog
                id='sysMenu4'
                open={isOpen}
                TransitionComponent={Transition}
                onClose={() => setIsOpen(false)}
                aria-describedby="alert-dialog-slide-description"
                className='d-flex justify-content-end'
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            Lọc báo cáo
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setIsOpen(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent>
                    {/* Mẫu báo cáo */}
                    <Box className='w-100 mt-2'>
                        <InputLabel className='cc-label'>Dự án</InputLabel>
                        <DHSAutoCompleteWithDataSource
                            id="item-select"
                            className="cc-input"
                            dataSource={apartmentBuildings}
                            value={
                                // apartmentBuilding?.id
                                apartment_building_id
                            }
                            displayMember='name'
                            valueMember="id"
                            disabled={true}
                            onValueChanged={(e, newValue: any) => {
                                // handleChangeInput({
                                //     target: {
                                //         name: "apartmentBuilding",
                                //         value: newValue?.id ? newValue.id : null,
                                //     },
                                // });
                                if (newValue && newValue.id) {
                                    dispatch(setApartmentBuilding(newValue))
                                }
                            }}
                        />
                        {/* <Autocomplete
                            id="gender-select"
                            sx={{ width: "100%" }}
                            options={apartmentBuildings}
                            autoHighlight
                            getOptionLabel={(option: any) => option?.name}
                            value={
                                apartmentBuilding?.id
                                    ? apartmentBuildings.find(
                                        (item: any) => item.id === apartmentBuilding.id
                                    )
                                    : null
                            }
                            onChange={(e, newValue: any) => {
                                handleChangeInput({
                                    target: {
                                        name: "apartmentBuilding",
                                        value: newValue?.id ? newValue.id : null,
                                    },
                                });
                            }}

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className="cc-input"
                                    // label="Dự án"
                                    placeholder='Chọn dự án'
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        /> */}
                    </Box>

                    {/* Giữa 2 ngày */}
                    <Box className='w-100 mt-4'>
                        <InputLabel className='cc-label'>Năm</InputLabel>
                        <CTXDate
                            name='date'
                            value={date}
                            onChange={handleChangeDate}
                            className='cc-input w-100'
                            views={["year"]}
                        />
                    </Box>



                </DialogContent>
                <DialogActions>
                    <DHSDialogAction

                        menu={menu}
                        onClickAction={handleClickAction}

                    />
                </DialogActions>
            </Dialog>

        </div >
    )
}

export default ReceivableCustomer;


