import DataGrid from "devextreme-react/data-grid";
// import notification from "../../../../common/notification/notification";
import notification from "../../../common/notification/notification";
import {
  STATUS_MESSAGE,
  TYPE_MESSAGE,
} from "../../../common/Interfaces/StatusMessage";

export const getIndexRowSelected = async (
  dataGridRef: React.RefObject<DataGrid<any, any>>
): Promise<number> => {
  if (dataGridRef.current) {
    try {
      const keys = await dataGridRef.current.instance.getSelectedRowKeys();
      const index = dataGridRef.current?.instance.getRowIndexByKey(keys[0]);
      const indexSelected = index !== undefined && index >= 0 ? index : -1;
      return indexSelected;
    } catch (error) {
      return -1;
    }
  } else {
    return -1;
  }
};

export const getListIndexRowSelected = async (
  dataGridRef: React.RefObject<DataGrid<any, any>>
): Promise<number[]> => {
  if (dataGridRef.current) {
    try {
      const keys = await dataGridRef.current.instance.getSelectedRowKeys();
      const listIndex = keys.map((key) => {
        const index = dataGridRef.current?.instance.getRowIndexByKey(key);
        const indexSelected = index !== undefined && index >= 0 ? index : -1;
        return indexSelected;
      });
      return listIndex;
    } catch (error) {
      return [];
    }
  } else {
    return [];
  }
};

export const getKeyRowSelected = async (
  dataGridRef: React.RefObject<DataGrid<any, any>>
): Promise<string | number> => {
  if (dataGridRef.current) {
    try {
      const keys = await dataGridRef.current.instance.getSelectedRowKeys();
      return keys[0];
    } catch (error) {
      return "";
    }
  } else {
    return "";
  }
};

export const getListKeyRowSelected = async (
  dataGridRef: React.RefObject<DataGrid<any, any>>
): Promise<string[] | number[]> => {
  if (dataGridRef.current) {
    try {
      const keys = await dataGridRef.current.instance.getSelectedRowKeys();
      return keys;
    } catch (error) {
      return [];
    }
  } else {
    return [];
  }
};

export const getDataRowSelected = async <T>(
  dataGridRef: React.RefObject<DataGrid<any, any>>
): Promise<T | undefined> => {
  if (dataGridRef.current) {
    try {
      const data = await dataGridRef.current.instance.getSelectedRowsData();
      return data[0];
    } catch (error) {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const getListDataRowSelected = async <T>(
  dataGridRef: React.RefObject<DataGrid<any, any>>
): Promise<T[] | undefined> => {
  if (dataGridRef.current) {
    try {
      const data = await dataGridRef.current.instance.getSelectedRowsData();
      return data;
    } catch (error) {
      return [];
    }
  } else {
    return [];
  }
};

export const getListDataSource = async <T>(
  dataGridRef: React.RefObject<DataGrid<any, any>>
): Promise<T[] | undefined> => {
  if (dataGridRef.current) {
    try {
      // const data= await dataGridRef.current.instance.getDataSource().items();
      // return data;
      const data = await dataGridRef.current?.instance.getDataSource().store().load(Option as any) as any[]
      return data;
    } catch (error) {
      return [];
    }
  } else {
    return [];
  }
};

export const onEditRow = async (
  dataGridRef: React.RefObject<DataGrid<any, any>>,
  setAllowEdit: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (dataGridRef.current) {
    try {
      const indexSelected = await getIndexRowSelected(dataGridRef);
      if (dataGridRef.current && indexSelected >= 0) {
        dataGridRef.current.instance.editRow(indexSelected);
        setAllowEdit(true);
      } else {
        notification(
          TYPE_MESSAGE.WARNING,
          STATUS_MESSAGE[`WARNING_SELECT_ROW`]
        );
      }
    } catch (error) {
      notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE[`WARNING_SELECT_ROW`]);
    }
  }
};

export const onViewRow = async (
  dataGridRef: React.RefObject<DataGrid<any, any>>,
  setAllowEdit: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (dataGridRef.current) {
    try {
      const indexSelected = await getIndexRowSelected(dataGridRef);
      if (dataGridRef.current && indexSelected >= 0) {
        dataGridRef.current.instance.editRow(indexSelected);
        setAllowEdit(false);
      } else {
        notification(
          TYPE_MESSAGE.WARNING,
          STATUS_MESSAGE[`WARNING_SELECT_ROW`]
        );
      }
    } catch (error) {
      notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE[`WARNING_SELECT_ROW`]);
    }
  }
};

export const onCoppyRow = async (
  dataGridRef: React.RefObject<DataGrid<any, any>>,
  setAllowEdit: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (dataGridRef.current) {
    try {
      const indexSelected = await getIndexRowSelected(dataGridRef);
      if (dataGridRef.current && indexSelected >= 0) {
        dataGridRef.current.instance.editRow(indexSelected);
        setAllowEdit(true);
      } else {
        notification(
          TYPE_MESSAGE.WARNING,
          STATUS_MESSAGE[`WARNING_SELECT_ROW`]
        );
      }
    } catch (error) {
      notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE[`WARNING_SELECT_ROW`]);
    }
  }
};

export const onAddRow = async (dataGridRef: React.RefObject<DataGrid<any, any>>, setAllowEdit?: React.Dispatch<React.SetStateAction<boolean>>) => {
  if (dataGridRef.current) {
    dataGridRef.current.instance.addRow();
    if(setAllowEdit)
      setAllowEdit(true);
  }
}

export const onDeleteRow = async (
  dataGridRef: React.RefObject<DataGrid<any, any>>
) => {
  if (dataGridRef.current) {
    try {
      const indexSelected = await getIndexRowSelected(dataGridRef);
      if (dataGridRef.current && indexSelected >= 0) {
        dataGridRef.current.instance.deleteRow(indexSelected);
      } else {
        notification(
          TYPE_MESSAGE.WARNING,
          STATUS_MESSAGE[`WARNING_SELECT_ROW`]
        );
      }
    } catch (error) {
      notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE[`WARNING_SELECT_ROW`]);
    }
  }
};

export const onDeleteMultiRow = async (
  dataGridRef: React.RefObject<DataGrid<any, any>>
) => {
  if (dataGridRef.current) {
    try {
      // const indexSelected = await getIndexRowSelected(dataGridRef);
      const listIndexSelected = await getListIndexRowSelected(dataGridRef);

      if (listIndexSelected.find((item) => item < 0)) {
        notification(
          TYPE_MESSAGE.WARNING,
          STATUS_MESSAGE[`WARNING_SELECT_ROW`]
        );
      } else {
        listIndexSelected.map((indexSelected) => {
          if (dataGridRef.current) {
            dataGridRef.current.instance.deleteRow(indexSelected);
          } 
        });
      }
    } catch (error) {
      notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE[`WARNING_SELECT_ROW`]);
    }
  }
};






// import DataGrid from "devextreme-react/data-grid";
// import notification from "../../../common/notification/notification";
// import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../common/Interfaces/StatusMessage";

// export const getIndexRowSelected = async (
//   dataGridRef: React.RefObject<DataGrid<any, any>>
// ): Promise<number> => {
//   if (dataGridRef.current) {
//     try {
//       const keys = await dataGridRef.current.instance.getSelectedRowKeys();
//       const index = dataGridRef.current?.instance.getRowIndexByKey(keys[0]);
//       const indexSelected = index !== undefined && index >= 0 ? index : -1;
//       return indexSelected;
//     } catch (error) {
//       return -1;
//     }
//   } else {
//     return -1;
//   }
// };

// export const getListIndexRowSelected = async (
//     dataGridRef: React.RefObject<DataGrid<any, any>>
//   ): Promise<number[]> => {
//     if (dataGridRef.current) {
//       try {
//         const keys = await dataGridRef.current.instance.getSelectedRowKeys();
//         const listIndex = keys.map((key) => {
//             const index = dataGridRef.current?.instance.getRowIndexByKey(key);
//             const indexSelected = index !== undefined && index >= 0 ? index : -1;
//             return indexSelected;
//         })
//         return listIndex;
//       } catch (error) {
//         return [];
//       }
//     } else {
//       return [];
//     }
//   };

// export const getKeyRowSelected = async (
//   dataGridRef: React.RefObject<DataGrid<any, any>>
// ): Promise<string | number> => {
//   if (dataGridRef.current) {
//     try {
//       const keys = await dataGridRef.current.instance.getSelectedRowKeys();
//       return keys[0];
//     } catch (error) {
//       return "";
//     }
//   } else {
//     return "";
//   }
// };

// export const getListKeyRowSelected = async (
//     dataGridRef: React.RefObject<DataGrid<any, any>>
//   ): Promise<string[] | number[]> => {
//     if (dataGridRef.current) {
//       try {
//         const keys = await dataGridRef.current.instance.getSelectedRowKeys();
//         return keys;
//       } catch (error) {
//         return [];
//       }
//     } else {
//       return [];
//     }
//   };

// export const getDataRowSelected = async <T>(
//   dataGridRef: React.RefObject<DataGrid<any, any>>
// ): Promise<T | undefined> => {
//   if (dataGridRef.current) {
//     try {
//       const data = await dataGridRef.current.instance.getSelectedRowsData();
//       return data[0];
//     } catch (error) {
//       return undefined;
//     }
//   } else {
//     return undefined;
//   }
// };

// export const getListDataRowSelected = async <T>(
//     dataGridRef: React.RefObject<DataGrid<any, any>>
//   ): Promise<T[]| undefined> => {
//     if (dataGridRef.current) {
//       try {
//         const data = await dataGridRef.current.instance.getSelectedRowsData();
//         return data;
//       } catch (error) {
//         return [];
//       }
//     } else {
//       return [];
//     }
//   };
  

//   export const onEditRow = async (dataGridRef: React.RefObject<DataGrid<any, any>>, setAllowEdit: React.Dispatch<React.SetStateAction<boolean>>) => {
//     if (dataGridRef.current) {
//       try {
//         const indexSelected = await getIndexRowSelected(dataGridRef);
//         if (dataGridRef.current && indexSelected >= 0) {
//           dataGridRef.current.instance.editRow(indexSelected);
//           setAllowEdit(true);
//         } else {
//           notification(TYPE_MESSAGE.WARNING,  STATUS_MESSAGE[`WARNING_SELECT_ROW`]);
//         }
//       } catch (error) {
//         notification(TYPE_MESSAGE.WARNING,  STATUS_MESSAGE[`WARNING_SELECT_ROW`]);
//       }
//     }
//   }

//   export const onViewRow = async (dataGridRef: React.RefObject<DataGrid<any, any>>, setAllowEdit: React.Dispatch<React.SetStateAction<boolean>>) => {
//     if (dataGridRef.current) {
//       try {
//         const indexSelected = await getIndexRowSelected(dataGridRef);
//         if (dataGridRef.current && indexSelected >= 0) {
//           dataGridRef.current.instance.editRow(indexSelected);
//           setAllowEdit(false);
//         } else {
//           notification(TYPE_MESSAGE.WARNING,  STATUS_MESSAGE[`WARNING_SELECT_ROW`]);
//         }
//       } catch (error) {
//         notification(TYPE_MESSAGE.WARNING,  STATUS_MESSAGE[`WARNING_SELECT_ROW`]);
//       }
//     }
//   }

//   export const onAddRow = async (dataGridRef: React.RefObject<DataGrid<any, any>>, setAllowEdit: React.Dispatch<React.SetStateAction<boolean>>) => {
//     if (dataGridRef.current) {
//       dataGridRef.current.instance.addRow();
//       setAllowEdit(true);
//     }
//   }

//   export const onDeleteRow = async (dataGridRef: React.RefObject<DataGrid<any, any>>) => {
//     if (dataGridRef.current) {
//       try {
//         const indexSelected = await getIndexRowSelected(dataGridRef);
//         if (dataGridRef.current && indexSelected >= 0) {
//           dataGridRef.current.instance.deleteRow(indexSelected);
//         } else {
//           notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE[`WARNING_SELECT_ROW`]);
//         }
//       } catch (error) {
//         notification(TYPE_MESSAGE.WARNING,  STATUS_MESSAGE[`WARNING_SELECT_ROW`]);
//       }
//     }
//   }
