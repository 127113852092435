import React from "react";
import { useParams } from "react-router-dom";
import notification from "../../../../../common/notification/notification";
import Upload from "../../../../../components/DHS/DHS_Upload/Upload";
import { AppSession } from "../../../../shared/app-session/app-session";
import {
  DOCClient,
  DocUserGuideProduction,
  RoleRightWebModel,
} from "../../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../shared/service-proxies/config";

interface IProps {
  id?: string;
  action: RoleRightWebModel | undefined;
  nowTime: Date;
  onDestroyAction?: (isDestroy: boolean, goBack?: number) => void;
}
var appSession: AppSession;
const ProductActionPage = (props: IProps) => {
  const docClient = new DOCClient(appSession, BASE_URL_API);
  const [data, setData] = React.useState<DocUserGuideProduction>(
    new DocUserGuideProduction() as DocUserGuideProduction
  );
  const [{ _id }, setId] = React.useState(useParams<{ _id: string }>());
  const [back, setBack] = React.useState<number>(-1);

  React.useEffect(() => {
    if (_id) {
      docClient
        .docUserGuideProductionGet({
          ...new DocUserGuideProduction(),
          id: _id,
        } as DocUserGuideProduction)
        .then((response) => {
          setData(response[0] as DocUserGuideProduction);
        });
    }
  }, []);

  React.useEffect(() => {
    if (
      props.action &&
      props.action.class_name?.toLocaleUpperCase() === "SAVE"
    ) {
      try {
        if (_id) {
          docClient
            .docUserGuideProductionUpd({
              ...data,
              id: _id,
            } as DocUserGuideProduction)
            .then((res) => {
              setBack((item) => (item -= 1));
              if (res.ret === 0) {
                notification("success", "Cập nhật thành công");
              } else notification("error", "Cập nhật thất bại");
            });
        } else {
          docClient
            .docUserGuideProductionIns(data as DocUserGuideProduction)
            .then((res) => {
              if (res.ret === 0 && res.id) {
                setId({ _id: res.id });
                notification("success", "Thêm thành công");
              } else notification("error", "Thêm thất bại");
            });
        }
        if (props.onDestroyAction) props.onDestroyAction(true, back - 1);
      } catch (error) {
        console.log(error);
      }
    }
  }, [props.nowTime]);

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value } as DocUserGuideProduction);
  };
  const handleUpload = (url: string) => {
    setData({ ...data, icon: url } as DocUserGuideProduction);
  };
  return (
    <>
      <div
        className="d-flex p-3 justify-content-left"
        style={{ backgroundColor: "#FFF" }}
      >
        <div className="w-100" style={{ maxWidth: 800 }}>
          <div className="form-group">
            <label>Tên</label>
            <input
              className="flex-grow-1 form-control"
              type="text"
              name="name"
              placeholder="Tên sản phẩm"
              onChange={handleChangeInput}
              value={data.name}
            />
          </div>
          <div className="form-group">
            <label>Icon</label>
            <Upload value={data.icon} callbackURL={handleUpload} />
          </div>
          <div className="form-group">
            <label>Mô tả</label>
            <textarea
              className="flex-grow-1 form-control"
              name="description"
              placeholder="Mô tả"
              onChange={handleChangeInput}
              value={data.description}
              style={{ minHeight: 150 }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductActionPage;
