import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Chart,
  CommonSeriesSettings,
  Legend,
  Border,
  Label,
  Format,
  Tooltip,
  ScrollBar,
  ArgumentAxis,
  ZoomAndPan,
  SeriesTemplate,
  Title,
  Series,
  Size,
} from "devextreme-react/chart";
import _ from "lodash";
import moment from "moment";
import { AppSession } from "../../../../app/shared/app-session/app-session";
import BASE_URL_API from "../../../../app/shared/service-proxies/config";
import { MachineRptClient, ModelHandlePD, PDMachineStatusClient } from "../../../../app/shared/service-proxies/api-shared";
let appSession: AppSession;
const Bar = (props: any) => {
  const machineRptClient = new MachineRptClient(appSession, BASE_URL_API);
  const pDMachineStatusClient = new PDMachineStatusClient(appSession, BASE_URL_API);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [listMachineActionState, setListMachineActionState] = React.useState<any>();
  React.useEffect(() => {
    machineRptClient.getLstActionState({ ...new ModelHandlePD(), mA_CTY: SessionLogin.ma_cty } as ModelHandlePD).then(res => {
      setListMachineActionState(res)
    })

  }, []);

  const onPointClick = (point: any) => {
    props.onClickCol(point.target.data);
  };
  const customizeSeries = (valueFromNameField: any) => {
    var color = "";
    if (props.listMachineActionState.length > 0) {
      props.listMachineActionState.map((item: any, index: any) => {
        if (item.DESCRIPTION === valueFromNameField) {
          color = item.BACKCOLOR;
        }
      });
    }
    return color !== "" ? { color: color } : {};
  };
  // const {
  //   dataSource,
  //   type,
  //   title,
  //   descriptionField,
  //   argumentField,
  //   valueField,
  //   scroll,
  //   onClickCol,
  //   legendVisible,
  //   displayLabelMode,
  //   customizeTooltip,
  //   customerText,
  // } = this.props;
  return (
    <Chart
      id="chart"
      dataSource={props.dataSource}
      onPointClick={props.onClickCol ? onPointClick : ""}
    // resolveLabelOverlapping="stack"
    >
      <Title text={props.title} font={{ size: 18, color: "#1890ff" }} />
      <SeriesTemplate
        nameField={props.descriptionField}
        customizeSeries={customizeSeries}
      />
      <CommonSeriesSettings
        argumentField={props.argumentField}
        type={props.type}
        valueField={props.valueField}
      // ResolveOverlappingMode="none"
      // type="stackedBar"
      >
        <Label visible={true} customizeText={props.customerText}>
          <Format type="fixedPoint" precision={0} />
        </Label>
      </CommonSeriesSettings>
      <Legend
        verticalAlignment="center"
        horizontalAlignment="center"
        visible={props.legendVisible}
      >
        <Border visible={true} />
      </Legend>
      <ArgumentAxis>
        <Label
          staggeringSpacing={1}
          rotationAngle={45}
          displayMode={!_.isNil(props.displayLabelMode) ? props.displayLabelMode : ""}
        />
      </ArgumentAxis>
      {props.scroll ? <ScrollBar visible={true} position="bottom" /> : <></>}
      <ZoomAndPan argumentAxis="both" allowTouchGestures={true} />
      <Tooltip
        enabled={true}
        location="edge"
        customizeTooltip={props.customizeTooltip}
      />
    </Chart>
  );

}


export default Bar;
