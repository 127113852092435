import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Select from "../../../../components/DHS/DHS_Select";
import DHSDate from "../../../../components/DHS/DHS-Date/DHSDateComponent";
import { TransitionProps } from "@mui/material/transitions";
import "./index.css";
import { AppSession } from "../../../shared/app-session/app-session";
import {
  HRClient,
  LeaveForm,
  LstHRLeaveType,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppBar, Button, IconButton, Toolbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import notification from "../../../../common/notification/notification";

interface IProps {
  open: boolean;
  actionType: string;
  itemClicked: any;
  onClose: (open: any) => void;
  onSubmit: (data: any) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});
var appSession: AppSession;

export default function SupplyRequestFilterDialog(props: IProps) {
  const hrClient = new HRClient(appSession, BASE_URL_API);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [_id, setId] = React.useState<string | undefined>();
  const [data, setData] = React.useState<LeaveForm>({
    ...new LeaveForm(),
    fromdate: new Date(),
    todate: new Date(),
  } as LeaveForm);
  const [typeOptions, setTypeOptions] = React.useState<any[]>([]);

  React.useEffect(() => {
    hrClient
      .lstHRLeaveTypeGet({
        ...new LstHRLeaveType(),
        ma_cty: SessionLogin.ma_cty,
      } as LstHRLeaveType)
      .then((response) => {
        setTypeOptions(response);
      });
  }, []);
  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value } as LeaveForm);
  };

  const handleSubmit = () => {
    if (_id && _id.length > 0) {
      hrClient
        .hROnLeaveUpd({
          ...data,
          id: _id,
          sender_code: SessionLogin.employeeCode,
        } as LeaveForm)
        .then((res) => {
          if (res[0].ret === 0) {
            notification("success", res[0].message);
          } else if (res[0].ret === 1) {
            notification("error", res[0].message);
          } else {
            notification("warning", res[0].message);
          }
        });
    } else {
      hrClient
        .hROnLeaveIns({
          ...data,
          ma_cty: SessionLogin.ma_cty,
          sender_code: SessionLogin.employeeCode,
        } as LeaveForm)
        .then((res) => {
          if (res[0].ret === 0) {
            setId(res[0].id);
            notification("success", res[0].message);
          } else if (res[0].ret === 1) {
            notification("error", res[0].message);
          } else {
            notification("warning", res[0].message);
          }
        });
    }
  };
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        TransitionComponent={Transition}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Điều kiện lọc
            </Typography>
            <Button autoFocus color="inherit" onClick={props.onSubmit}>
              Thực hiện
            </Button>
          </Toolbar>
        </AppBar>
        <div className="d-flex">
          <div className="w-100 p-4">
            <div style={{ backgroundColor: "white" }} className="p-3 d-flex">
              <div className="w-100 h-100">
                <div className="row">
                  <div className="form-group col-md-4">
                    <Select
                      label="Khoảng thời gian"
                      value={data.type}
                      options={typeOptions}
                      onChange={handleChangeInput}
                      fieldValue={"leave_type_id"}
                      fieldDisplay={"leave_type_name"}
                      name="type"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <DHSDate
                      label="Từ ngày"
                      value=""
                      onChange={handleChangeInput}
                      name="type"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <DHSDate
                      label="Đến ngày"
                      value=""
                      onChange={handleChangeInput}
                      name="type"
                    />
                  </div>
                  <div className="form-group col-xl-6">
                    <label>Chứng từ số</label>
                    <input
                      className="flex-grow-1 form-control"
                      value=""
                    />
                  </div>
                  <div className="form-group col-xl-6">
                    <label>Đến số</label>
                    <input
                      className="flex-grow-1 form-control"
                      value=""
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <Select
                      label="Trạng thái"
                      value={data.type}
                      options={typeOptions}
                      onChange={handleChangeInput}
                      fieldValue={"leave_type_id"}
                      fieldDisplay={"leave_type_name"}
                      name="type"
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <Select
                      label="Khách hàng"
                      value={data.type}
                      options={typeOptions}
                      onChange={handleChangeInput}
                      fieldValue={"leave_type_id"}
                      fieldDisplay={"leave_type_name"}
                      name="type"
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <Select
                      label="Bộ phận"
                      value={data.type}
                      options={typeOptions}
                      onChange={handleChangeInput}
                      fieldValue={"leave_type_id"}
                      fieldDisplay={"leave_type_name"}
                      name="type"
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <Select
                      label="Nhân viên"
                      value={data.type}
                      options={typeOptions}
                      onChange={handleChangeInput}
                      fieldValue={"leave_type_id"}
                      fieldDisplay={"leave_type_name"}
                      name="type"
                    />
                  </div>
                  <div className="form-group col-xl-12">
                    <label>Số LSX</label>
                    <input
                      className="flex-grow-1 form-control"
                      value=""
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <Select
                      label="Nhân viên"
                      value={data.type}
                      options={typeOptions}
                      onChange={handleChangeInput}
                      fieldValue={"leave_type_id"}
                      fieldDisplay={"leave_type_name"}
                      name="type"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
