export const GET_LIST_HOME_PAGE = "GET_LIST_HOME_PAGE";
export const GET_LIST_DETAIL_PAGE = "GET_LIST_DETAIL_PAGE";
export const INSERT_CUSTOMER_REQUEST = "INSERT_CUSTOMER_REQUEST";
export const INITIAL_STATE = "INITIAL_STATE";
export const LANGUAGE_ID = "LANGUAGE_ID";
export const GET_LIST_PRODUCT_PAGE = "GET_LIST_PRODUCT_PAGE";
export const GET_LIST_RELATED_LINK = "GET_LIST_RELATED_LINK";
//admin
export const LOGIN = "LOGIN";
export const SET_IDX_DB = "SET_IDX_DB";
export const SESSION_LOGIN = "SESSION_LOGIN";
export const CLEAR_LOGIN = "CLEAR_LOGIN";
//Machine report
export const GET_RPT_MACHINE_UPTIME_TOTALALL = "GET_RPT_MACHINE_UPTIME_TOTALALL";
export const GET_RPT_MACHINE_UPTIME = "GET_RPT_MACHINE_UPTIME";
export const GET_RPT_MACHINE_TIMELINE = "GET_RPT_MACHINE_TIMELINE";
export const CONTAIN_RPT_MACHINE = "CONTAIN";
export const GET_LIST_MACHINE = "GET_LIST_MACHINE";
export const GET_RPT_MACHINE_WORK_SHIFT = "GET_RPT_MACHINE_WORK_SHIFT";
export const GET_MACHINE_ACTION_STATE = "GET_MACHINE_ACTION_STATE";
export const GET_GROUP_MACHINE = "GET_GROUP_MACHINE";
//Machine status
export const GET_MACHINE_STATE = "GET_MACHINE_STATE";
export const GET_MACHINE_STATE_NEW_UPDATE = "GET_MACHINE_STATE_NEW_UPDATE";
export const CHANGE_STATE_UPDATE = "CHANGE_STATE_UPDATE";
export const GET_TIME_UPDATE = "GET_TIME_UPDATE";
export const GET_MACHINE_STATE_COLOR = "GET_MACHINE_STATE_COLOR";
//dhs detail page
export const DHS_DETAIL_INS = "DHS_DETAIL_INS";
export const DHS_DETAIL_UPD = "DHS_DETAIL_UPD";
export const DHS_DETAIL_DEL = "DHS_DETAIL_DEL";
export const CLEAR_DETAIL = "CLEAR_DETAIL";

//dhs home page
export const DHS_HOME_INS = "DHS_HOME_INS";
export const DHS_HOME_UPD = "DHS_HOME_UPD";
export const DHS_HOME_DEL = "DHS_HOME_DEL";
export const CLEAR_HOME = "CLEAR_HOME";

export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const GET_ALL_IMAGE = "GET_ALL_IMAGE";

//dhs cus request
export const DHS_CUS_REQUEST_GET = "DHS_CUS_REQUEST_GET";

//dhs toolbar
export const DHS_TOOLBAR_GET = "DHS_TOOLBAR_GET"
export const MENU_ACTIVE = "MENU_ACTIVE"
export const CLEAR_ACTIVE = "CLEAR_ACTIVE"
//dhs role
export const GET_ALL_MENU = "GET_ALL_MENU"
export const GET_MENU = "GET_MENU"
export const GET_MENU_BY_WORKER = "GET_MENU_BY_WORKER"
export const GET_MENU_SHOW_GRID = "GET_MENU_SHOW_GRID"
export const CLEAR_MENU = "CLEAR_MENU"
export const GET_ROLE = "GET_ROLE_BY_ID"
export const GET_ROLES = "GET_ROLES"
export const CLEAR_ROLE = "CLEAR_ROLE"
export const CLEAR_ROLES = "CLEAR_ROLES"
// path
export const PUSH_URL_LEVEL_1 = "PUSH_URL_LEVEL_1"
export const PUSH_URL_LEVEL_2 = "PUSH_URL_LEVEL_2"
// notification
export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION'
export const SET_IS_READ_NOTIFICATION = 'SET_IS_READ_NOTIFICATION'
export const SET_TOTAL_READ_NOTIFICATION = 'SET_TOTAL_READ_NOTIFICATION'
export const SET_TOTAL_VIEW_NOTIFICATION = 'SET_TOTAL_VIEW_NOTIFICATION'
// serviceBooking
export const SAVE_CHANGE_SERVICE_RECEIPTM = "SAVE_CHANGE_SERVICE_RECEIPT"
export const SAVE_CHANGE_SERVICE_RECEIPTD0 = "SAVE_CHANGE_SERVICE_RECEIPTD0"
export const SAVE_CHANGE_SERVICE_RECEIPTD1 = "SAVE_CHANGE_SERVICE_RECEIPTD1"
export const SAVE_CHANGE_SERVICE_BOOKING_MASTER = "SAVE_CHANGE_SERVICE_BOOKING_MASTER"
export const CLEAR_SERVICE_BOOKING_MASTER = "CLEAR_SERVICE_BOOKING_MASTER"
export const CLEAR_SERVICE_BOOKING_RECEIPT = "CLEAR_SERVICE_BOOKING_RECEIPT"
// Option
export const PUSH_PAYMENT_TERM_OPTIONS = "PUSH_PAYMENT_TERM_OPTIONS"
export const PUSH_BLANK_OPTIONS = "PUSH_BLANK_OPTIONS"
export const PUSH_CUSTOMER_OPTIONS = "PUSH_CUSTOMER_OPTIONS"
export const PUSH_EXCHANGE_OPTIONS = "PUSH_EXCHANGE_OPTIONS"
export const PUSH_CURRENCY_OPTIONS = "PUSH_CURRENCY_OPTIONS"
export const PUSH_UNIT_OPTIONS = "PUSH_UNIT_OPTIONS"
export const PUSH_APARTMENT_BUILDING_OPTIONS = "PUSH_APARTMENT_BUILDING_OPTIONS"
export const PUSH_UTILITY_OPTIONS = "PUSH_UTILITY_OPTIONS"
// apartment building

export const SET_APARTMENT_BUILDING = "SET_APARTMENT_BUILDING"

// setting
export const SET_LANGUAGE = "SET_LANGUAGE"
