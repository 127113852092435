import React from "react";
import { ISwitchOptions, Switch } from 'devextreme-react/switch';

interface IProps extends ISwitchOptions{

}

const DHSSwitch : React.FC<IProps> = (props) => {
    return <Switch
                {...props}
              />
}

export default DHSSwitch;