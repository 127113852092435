import React from "react";
import TextArea, { ITextAreaOptions } from "devextreme-react/text-area";
import Validator, { RequiredRule } from "devextreme-react/validator";

interface IProps extends ITextAreaOptions {
  required?: boolean,
  requiredMessage?: string
}

const DHSTextArea = React.forwardRef<TextArea, IProps>((props, ref) => {
  return <TextArea
    {...props}
    ref={ref}
  >
    <Validator>
      {props?.required && <RequiredRule message={props?.requiredMessage ?? "Vui lòng không để trống."} />}
    </Validator>
  </TextArea>
})

export default DHSTextArea;