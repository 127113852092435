import React, { useEffect } from "react";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import AdapterMoment from "@mui/lab/AdapterMoment";
// import 'jquery.ui.datetimepicker'j
interface IProps {
  label?: string;
  disabled?: boolean;
  value?: any;
  className?: string;
  type?: string;
  name: string;
  id?: string;
  onChange?: (v: any) => void;
  objDataChangeGrid?: any;
}
const DHSDate = (props: IProps) => {
  const format = "DD/MM/YYYY";
  const [value, setValue] = React.useState<any>(props.value);
  const handleChangeDate = (e: any) => {
    if (e && props.onChange) {
      setValue(e._d);
      props.onChange(e._d);
    }
  };
  React.useEffect(() => {
    try {
      let newvalue: any | undefined;
      for (var i = 0; i < props.objDataChangeGrid.length; i++) {
        if (props.objDataChangeGrid[i].id === props.id) {
          if (props.name.endsWith("_f"))
            newvalue = props.objDataChangeGrid[i][props.name.replace("_f", "")];
          else newvalue = props.objDataChangeGrid[i][props.name];
          break;
        }
      }
      setValue(newvalue);
    } catch { }
  }, [props.value]);
  return (
    <div className="form-group">
      <label className="label-input">{props.label}</label>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          renderInput={(params: any) => <TextField margin="normal" {...params} />}
          label=""
          value={value}
          onChange={handleChangeDate}
          inputFormat={format}
          disabled={props.disabled}
        />
      </LocalizationProvider>
      {/* <br />
      <div className="input-group date" id="datepicker1">
        <input
          type="text"
          data-date-format={format}
          className="form-control"
          id={columnName}
          readOnly={disabled}
          defaultValue={myDate}
          style={{
            borderRadius: "5px",
            color: "black",
            textAlign: "left",
          }}
          disabled={props.disabled}
        ></input>

        <span className="input-group-addon" style={{ display: "none" }}>
          <span className="glyphicon glyphicon-calendar"></span>
        </span>
      </div> */}
    </div>
  );
};
export default DHSDate;
