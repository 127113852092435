import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";
import appReducers from "../src/redux/reducer/storeReducer";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import "./Asset/bootstrap-icons/bootstrap-icons.css";
import { ThemeProvider, createMuiTheme, createTheme } from "@mui/material";


const persistConfig = {
  key: "root",
  storage,
  blacklist: ['ServiceBookingReceiptReducer', 'ServiceBookingMasterReducer', 'OptionsReducer']
};
const pReducer = persistReducer(persistConfig, appReducers);
const composeEnhancer = compose;
const pStore = createStore(pReducer, composeEnhancer(applyMiddleware(thunk)));
const store = createStore(appReducers, composeEnhancer(applyMiddleware(thunk)));
const theme = createTheme({
  typography: {
    fontFamily: `"Segoe UI","SegoeUI","Helvetica Neue","Helvetica","Arial",sans-serif`,
  }
});

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker
//       .register('/firebase.js')
//       .then((registration) => {
//         console.log('Service worker đã được đăng ký:', registration);
//       })
//       .catch((error) => {
//         console.log('Đăng ký service worker thất bại:', error);
//       });
//   });
// }

ReactDOM.render(
  <Provider store={pStore}>
     <ThemeProvider theme={theme}>
    <PersistGate loading={null} persistor={persistStore(pStore)}>
      <Router>
        <App />
      </Router>
    </PersistGate>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
