import React, { useEffect, useMemo, useState } from 'react';
import Button, { ButtonTypeMap } from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { AppBar, Divider, ExtendButtonBase, Toolbar, Typography } from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { isNullOrUndefinedOrEmpty } from '../../utils/object.util';
import DHSCheckBox from '../../DHSComponents/editors/DHSCheckBox/DHSCheckBox';

export interface IActionSubmitOptionMoreThanTwoButton {
    type: string, //  type of show button
    textButton: string,
    status: boolean | undefined,
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface IExtendButtonBaseButtonTypeMap extends ButtonTypeMap<{}, "button"> {
    text: string | undefined,
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    className?: string | undefined,
    id?: string | undefined
}

// <DHSConfirmWithMessage
//      message='Không có gì'
//      onClosed={() => console.log(`first`)}
//      open={true}
//      showButton='any_button'
//      title='hi'
//      buttons={[{
//          defaultComponent: 'button',
//          props: {
//              variant: 'contained',
//          },
//          className: 'hi?',
//          id: 'hi?',
//          onClick: (e) => {
//              console.log(e)
//          },
//          text: 'Tùy',
//      }]}
//      Không hiển thị lại
//      isKeepMounted={props.open}
//      showIsOpenAgain={true}
//  />

interface IMemoryButton {
    button?: IExtendButtonBaseButtonTypeMap,
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type?: string,
    text?: string
}

interface IProps {
    open: boolean;
    onClosed: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;

    title: string;
    message?: string;
    renderContent?: JSX.Element | (() => JSX.Element),
    showButton: 'submit' | 'ok' | 'ok_cancel' | 'submit_cancel' | 'submit_close' | 'any_button' | 'three_button';
    textButton?: string[]; //  chỉnh chữ
    zIndex?: number,
    onSubmit?: (result: boolean) => void;
    onSubmitButtonInfo?: (buttonInformation: IActionSubmitOptionMoreThanTwoButton) => void   // TH Chọn 3 button hoặc nhiều hơn, để tiện custom sau này
    buttons?: IExtendButtonBaseButtonTypeMap[]// button custom

    // Không hiển thị ở lần sau
    showIsOpenAgain?: boolean, // mở hặc tắt checkbox
    isKeepMounted?: boolean, //  false => reset lại những gì đã thiết lập
}

export enum TYPE_BUTTON_CONFIRM_WITH_MESSAGE {
    SUBMIT = 'submit',
    OK = 'ok',
    CANCEL = 'cancel',
    CLOSE = 'close',
    THREE_BUTTON_FIRST = 'three_button_first',
    THREE_BUTTON_SECOND = 'three_button_second',
    ANY_BUTTON = 'any_button',
}



export default function DHSConfirmWithMessage(props: IProps) {

    const [isShowAgain, setIsShowAgain] = useState<boolean>(false);
    const [memoryShowAgain, setMemoryShowAgain] = useState<IMemoryButton>({} as IMemoryButton);
    // const language = useSelector((state: IReducer) => state.SettingReducer.language); // Hi hi ha ha 

    function translate(text_vn: string, text_orther: string) {
        if (true)
            return text_vn
        return text_orther
    }

    async function onHandleIsShowAgain(e: any) {
        setIsShowAgain(e)
    }

    async function onClickProps(type: string, text: string) {
        if (props.onSubmit) {
            props.onSubmit(true);
        }
        if (props.onSubmitButtonInfo) {
            const data = {
                textButton: text,
                type: type,
                status: undefined
            } as IActionSubmitOptionMoreThanTwoButton
            props.onSubmitButtonInfo(data);
        }
    }

    const onSubmit = async (type: string, text: string) => {
        setMemoryShowAgain({
            type, text
        } as IMemoryButton);
        await onClickProps(type, text);
        props.onClosed();
    }

    const onClickButtons = async (button: IExtendButtonBaseButtonTypeMap, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setMemoryShowAgain({
            button, e
        } as IMemoryButton);

        onClickProps(props.showButton, button.text ? button.text : '');
        if (button.onClick) {
            button.onClick(e);
        }
    }

    const onCancel = async (type: string, text: string) => {
        if (props.onSubmit) {
            props.onSubmit(false);
        }

        if (props.onSubmitButtonInfo) {
            const data = {
                textButton: text,
                type: type,
                status: false
            } as IActionSubmitOptionMoreThanTwoButton
            props.onSubmitButtonInfo(data);
        }

        props.onClosed();
    }


    const loadButton = () => {

        if (props.open) {
            const stringTitle = props.textButton ? props.textButton : [];

            switch (props.showButton) {
                case 'any_button':
                    if (props.buttons !== undefined && props.buttons.length > 0) {
                        return <>
                            {
                                props.buttons.map(button => {
                                    if (button.onClick) {
                                        return <Button {...button.props} id={button.id} className={button.className} onClick={(e) => onClickButtons(button, e)}>{button.text}</Button>

                                    }
                                    return <Button {...button.props} id={button.id} className={button.className} >{button.text}</Button>
                                })
                            }
                        </>
                    }
                    return <></>
                case 'submit':
                    if (stringTitle.length === 0) {
                        stringTitle.push(translate("Xác nhận", "Confirm"))
                    }
                    return <>
                        <Button variant="contained" color="info" onClick={() => onSubmit('submit', stringTitle[0])}>{stringTitle[0]}</Button>
                    </>
                case 'ok':
                    if (stringTitle.length === 0) {
                        stringTitle.push(translate("Đồng ý", "Agree"))
                    }
                    return <>
                        <Button variant="contained" color="info" onClick={() => onSubmit('ok', stringTitle[0])}>{stringTitle[0]}</Button>
                    </>
                case 'ok_cancel':
                    if (stringTitle.length === 0) {
                        stringTitle.push(translate("Đồng ý", "Agree"), translate("Hủy", "Cancel"))
                    } else if (stringTitle.length === 1) {
                        stringTitle.push(translate("Hủy", "Cancel"))
                    }
                    return <>
                        <Button variant="contained" color="info" onClick={() => onSubmit('ok', stringTitle[0])}>{stringTitle[0]}</Button>
                        <Button onClick={() => onCancel('cancel', stringTitle[1])}>{stringTitle[1]}</Button>
                    </>
                case 'submit_cancel':
                    if (stringTitle.length === 0) {
                        stringTitle.push(translate("Xác nhận", "Confirm"), translate("Hủy", "Cancel"))
                    } else if (stringTitle.length === 1) {
                        stringTitle.push(translate("Hủy", "Cancel"))
                    }
                    return <>
                        <Button variant="contained" color="info" onClick={() => onSubmit('submit', stringTitle[0])}>{stringTitle[0]}</Button>
                        <Button onClick={() => onCancel('cancel', stringTitle[1])}>{stringTitle[1]}</Button>
                    </>
                case 'submit_close':
                    if (stringTitle.length === 0) {
                        stringTitle.push(translate("Xác nhận", "Confirm"), translate("Đóng", "Close"))
                    } else if (stringTitle.length === 1) {
                        stringTitle.push(translate("Đóng", "Close"))
                    }
                    return <>
                        <Button variant="contained" color="info" onClick={() => onSubmit('submit', stringTitle[0])}>{stringTitle[0]}</Button>
                        <Button onClick={() => onCancel('close', stringTitle[1])}>{stringTitle[1]}</Button>
                    </>
                case 'three_button':
                    if (stringTitle.length === 0) {
                        stringTitle.push(translate("Xác nhận 1", "Confirm 1"), translate("Xác nhận 2", "Confirm 2"), translate("Đóng", "Close"))
                    } else if (stringTitle.length === 1) {
                        stringTitle.push(translate("Xác nhận 2", "Confirm 2"))
                    } else if (stringTitle.length === 2) {
                        stringTitle.push(translate("Đóng", "Close"))
                    }
                    return <>
                        <Button variant="contained" color="info" onClick={() => onSubmit('three_button_first', stringTitle[0])}>{stringTitle[0]}</Button>
                        <Button variant="contained" color="info" onClick={() => onSubmit('three_button_second', stringTitle[1])}>{stringTitle[1]}</Button>
                        <Button onClick={() => onCancel('close', stringTitle[2])}>{stringTitle[2]}</Button>
                    </>
            }
        }
    }

    useEffect(() => {
        if (props.open) {
            if (props.isKeepMounted && isShowAgain) {
                props.onClosed();
                if (memoryShowAgain.type && memoryShowAgain.text) {
                    onSubmit(memoryShowAgain?.type, memoryShowAgain?.text);
                }
            }
        }
        if (!props.isKeepMounted) {
            setIsShowAgain(false);
        }
    }, [props.open, props.isKeepMounted])

    return (
        <div>
            <Dialog
                open={props.open}
                // TransitionComponent={Transition}
                onClose={props.onClosed}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="sm"
                fullWidth
                sx={{
                    zIndex: props?.zIndex ?? 2000
                }}

                keepMounted={props.isKeepMounted}
            >
                {/* <DialogTitle className='text-white p-1 px-3'>{props.title}</DialogTitle> */}
                <AppBar sx={{ position  : "relative", backgroundColor: true ? '#2167a3' : "#FFF", color:  true ? undefined : "#1890ff", boxShadow: 0, }} >
                    <Toolbar>
                        <Typography sx={{ flex: 1, fontWeight: 700, fontSize: '14px'  }} variant="h6" component="div" className={`app-bar-dialog`}  >
                            {props.title}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent >
                    {
                        !isNullOrUndefinedOrEmpty(props.message) ? <div className='p-3'>
                            <b>{props.message}</b>
                        </div> : <></>
                    }
                    {
                        props?.renderContent ? <div className='px-3'>
                            {
                                _.isFunction(props.renderContent) ? props.renderContent() : props.renderContent
                            }
                        </div> : <></>
                    }

                </DialogContent>
                {
                    props.showIsOpenAgain && <div className='w-100 px-3 py-2'>
                        <DHSCheckBox
                            style={{
                                fontSize: 'small'
                            }}
                            value={isShowAgain}
                            onValueChange={onHandleIsShowAgain}
                            text={translate("Không hiển thị lại ở các lần thao tác sau", "Do not show again in subsequent actions")}
                            hoverStateEnabled
                            focusStateEnabled
                            activeStateEnabled
                        />
                    </div>
                }
                <Divider />
                <DialogActions style={{
                    backgroundColor: '#e2e8f0' // nếu không ổn thì thêm prop ở đây
                }}>
                    {
                        loadButton()
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}