import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { components } from "../../../common/ComponentsConfig/components";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import notification from "../../../common/notification/notification";
import BlockUI from "../../../components/Block-UI/Block-ui";
import CTXFormDialog from "../../../components/CTX/Dialog";
import CTXToolbar from "../../../components/CTX/Toolbar";
import DHSToolbar from "../../../components/DHS/DHS-Toolbar/DHSToolbar";
import { Notification } from "../../../components/layouts";
import DHSGridView from "../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { getRole, getRoles } from "../../../redux/actions/RoleActions";
import { AppSession } from "../../shared/app-session/app-session";
import {
  ChoThueXeClient,
  CtxDanhMucXeModel,
  CtxLoaiXeModel,
  HomeClient,
  HRClient,
  HRWorkShift,
  LstInventory,
  LstInventoryClient,
  RoleRightWebModel,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
// import "./style.css";
interface IAction {
  open: boolean; //Mở Dialog
  type: string | undefined; //Loại action (keyword)
  name: string | undefined; //Tên action (thêm, sửa, xóa)
  table: string | undefined; //Tên bảng thay đổi khi thực hiện action
}
let appSession: AppSession;
const convert = ["success", "error", "warning"];
const menuRoleName = "KS";
const DanhMucKSC = () => {
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

  const history = useHistory();
  const dispatch = useDispatch();
  // const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
  const { path, url } = useRouteMatch();
  const hrClient = new HRClient(appSession, BASE_URL_API);
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);
  const invClient = new LstInventoryClient(appSession, BASE_URL_API);
  const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
  const [itemSelected, selectItem] = React.useState<string>();
  const [action, setAction] = React.useState<IAction>({
    open: false,
    type: "",
    name: "",
    table: "",
  });
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
  const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
  const [isShowSort, setShowSort] = React.useState<boolean>(false);
  const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
  const [dataTable, setDataTable] = React.useState<any[]>([]);
  const [reload, setReload] = React.useState(false);
  const customMenu = [
    {
      menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
      onClick: () => {
        setShowSearch(!isShowSearch);
      },
    },
    {
      menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
      onClick: () => {
        setShowGroup(!isShowGroup);
      },
    },
    {
      menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
      onClick: () => {
        setShowSort(!isShowSort);
      },
    },
    {
      menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
      onClick: () => {
        setShowFilter(!isShowFilter);
      },
    },
  ];
  React.useEffect(() => {
    setLoading(true);
    // control add remove and edit
    homeClient
      ?.roleRightWebGetAll({
        ...new RoleRightWebModel(),
        username: SessionLogin.userName,
        rolename: menuRoleName,
        menuid: menu?.menuid,
      } as RoleRightWebModel)
      .then((res) => {
        dispatch(getRoles(res));
        setRoles(res);
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    // tải bảng lại mỗi lần loadpage
    if (menu?.component?.toUpperCase() === "KSCDANHSACHKHO") {
      invClient
        .getLstInventory({
          ...new LstInventory(),
          mA_CTY: "DHS",
        } as LstInventory)
        .then((res) => {
          setDataTable(res);
          setLoading(false);
        });
    } else if (menu?.component?.toUpperCase() === "KSCDANHSACHCA") {
      hrClient
        .list_work_shift_get({
          ...new HRWorkShift(),
          ma_cty: "DHS",
        } as HRWorkShift)
        .then((res) => {
          setDataTable(res);
          setLoading(false);
        });
    } else
      ctxClient
        ?.ctxDanhMucXeActions({
          ...new CtxDanhMucXeModel(),
          tbname: menu?.component,
          action: "GET",
        } as CtxDanhMucXeModel)
        .then((res) => {
          setDataTable(res);
          setLoading(false);
        });
  }, [reload]);

  const handleClickAction = (action: RoleRightWebModel) => {
    if (action?.class_name?.toUpperCase() === "DELETE") {
      if (window.confirm("Bạn có chắc muốn xóa")) {
        setLoading(true)
        ctxClient
          .ctxDanhMucXeActions({
            ...new CtxDanhMucXeModel(),
            id: itemSelected,
            tbname: menu?.component,
            action: "DELETE",
          } as CtxDanhMucXeModel)
          .then((response) => {
            setLoading(false);
            Notification(convert[Number(response[0].ret)], response[0].message);
            setReload(!reload);
          });
      }
    } else if (action?.class_name?.toUpperCase() !== "BACK") {
      setAction({
        ...action,
        type: action.class_name?.toUpperCase(),
        name: action.name_vn?.toUpperCase(),
        open: true,
        table: action.component,
      });
      // setOpen(true);
    } else history.goBack();
  };

  const handleSubmit = (data: any) => {
    setLoading(true)
    switch (action?.table?.toUpperCase()) {
      case "CTXCHOTHUEXE":
        if (!itemSelected) {
          ctxClient.ctxLoaiXeIns(data).then((response) => {
            setLoading(false)
            Notification(convert[Number(response[0].ret)], response[0].message);
          });
        } else {
          ctxClient.ctxLoaiXeUpd(data).then((response) => {
            setLoading(false)
            Notification(convert[Number(response[0].ret)], response[0].message);
          });
        }
        break;
      default:
        setLoading(true)

        ctxClient
          .ctxDanhMucXeActions({
            ...new CtxDanhMucXeModel(),
            id: itemSelected,
            name: data,
            tbname: menu?.component,
            action: action?.type,
          } as CtxDanhMucXeModel)
          .then((response) => {
            setLoading(false)

            Notification(convert[Number(response[0].ret)], response[0].message);
            setReload(!reload);
          });
        break;
    }
    setAction({
      ...action,
      open: false,
      type: "",
      name: "",
      table: "",
    });
  };

  return (
    <div
      className="container-fluid p-2"
      style={{ maxHeight: "94vh !important", overflowY: "auto" }}
    >
      {/* <Switch> */}
      {/* <Route path={path} exact> */}
      {React.useMemo(() => {
        return (
          <CTXFormDialog
            title={`${action.name} ${menu?.short_name?.toUpperCase()}`}
            row={itemSelected}
            table={menu?.component}
            open={action.open}
            onClose={() => {
              setAction({
                ...action,
                open: false,
                type: "",
                // name: "",
                table: "",
              });
            }}
            onSubmit={handleSubmit}
          />
        );
      }, [action.open])}

      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          marginTop: 15,
          marginBottom: 15,
          fontSize: 22,
          fontWeight: 550,
        }}
      >
        <i
          className="bi bi-arrow-left-short"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/admin/danh-muc")}
        ></i>
        &nbsp;{menu?.bar}
      </div>
      <CTXToolbar
        id={itemSelected}
        list={
          roles
            ? roles.filter(
                (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
              )
            : []
        }
        onClickAction={handleClickAction}
        customMenu={customMenu}
      />
      <DHSGridView
        onSelectRow={(row) => {
          selectItem(row);
        }}
        defaultPageSize={20}
        // onListSelectRow={handleListOnselectRow}
        itemClicked={function (): void {
          throw new Error("Function not implemented.");
        }}
        table_name={menu?.component ? menu.component : ""}
        show_form_list={true}
        showSelectBox={false}
        showSortPanel={isShowSort}
        showSearchPanel={isShowSearch}
        showGroupPanel={isShowGroup}
        showFilterPanel={isShowFilter}
        data={dataTable}
      />
      {/* </Route> */}
      {/* {roles &&
          roles.map((role, index) => {
            return (
              role.link &&
              role.link.length > 1 &&
              role.component && (
                <Route
                  key={index}
                  path={role.is_parameter ? role.link + ":_id" : role.link}
                  component={components[role.component.toLocaleUpperCase()]}
                  exact
                ></Route>
              )
            );
          })} */}
      {/* </Switch> */}
      <BlockUI blocking={isLoading} title={""}></BlockUI>
    </div>
  );
};

export default DanhMucKSC;
