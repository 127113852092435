import React from "react";
import Accordion, { IAccordionOptions, Item } from 'devextreme-react/accordion';
import { EventInfo } from "devextreme/events";
import dxAccordion from "devextreme/ui/accordion";
import { SelectionChangedInfo } from "devextreme/ui/collection/ui.collection_widget.base";
export interface IProps extends React.PropsWithChildren<IAccordionOptions> {
  defaultShowAll?: boolean,
  defaultShowFirstItem?: boolean,
  setSelectedItems?: (value: any[]) => void
}

const DHSAccordion = React.forwardRef<Accordion, IProps>((props, ref) => {
  const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (props?.selectedItems) {
      setSelectedItems(props?.selectedItems ?? [])
    }
  }, [props?.selectedItems])

  const onSelectionChanged = (e: EventInfo<dxAccordion<any, any>> & SelectionChangedInfo<any>) => {

    let newItems = selectedItems ? [...selectedItems] : [];
    e.removedItems.forEach((item) => {
      const index = newItems.indexOf(item);
      if (index >= 0) {
        newItems.splice(index, 1);
      }
    });
    if (e.addedItems.length) {
      newItems = [...newItems, ...e.addedItems];
    }

    const setSelectedItemsFunc = props?.setSelectedItems || setSelectedItems;

    if (!props?.selectedItems) {
      setSelectedItemsFunc(newItems);
    } else {
      props?.setSelectedItems && props?.setSelectedItems(newItems);
    }

  }
  return <Accordion
    {...props}
    onSelectionChanged={onSelectionChanged}
    selectedItems={selectedItems}
    onContentReady={(e) => {
      const items = e.component.option('items');
      const setSelectedItemsFunc = props?.setSelectedItems || setSelectedItems;

      if (props?.defaultShowAll && items) {
        setSelectedItemsFunc(items);
      }

      if (props?.defaultShowFirstItem && items && items.length > 0) {
        setSelectedItemsFunc([items[0]]);
      }
    }}

    ref={ref}
  >
    {props?.children}
  </Accordion>
})

export default DHSAccordion;