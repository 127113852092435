import React from "react";
import { useSelector } from "react-redux";
import { EventInfo } from "devextreme/events";
import { useLocation } from "react-router-dom";
import DataGrid from 'devextreme-react/data-grid';
import { Notification } from "../../../../components/layouts";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import NewsActions from "../../actions/news-action/NewsActions";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { AppSession } from "../../../shared/app-session/app-session";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import DHSBreadcrumb from "../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import { getIndexRowSelected, getKeyRowSelected } from "../../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import { QLCCCategoriesModel, QLCCClient, QLCCNewsModel, ReportInfo, SysActionType, } from "../../../shared/service-proxies/api-shared";

interface IAction {
    open: boolean; //Mở Dialog
    type: string | undefined; //Loại action (keyword)
    name: string | undefined; //Tên action (thêm, sửa, xóa)
    table: string | undefined; //Tên bảng thay đổi khi thực hiện action
}

let appSession: AppSession;
const News = () => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const location = useLocation();
    const [itemSelected, setIdItemSelected] = React.useState<string>();
    const [action, setAction] = React.useState<IAction>({
        open: false,
        type: "",
        name: "",
        table: "",
    });
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<QLCCNewsModel[]>([]);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    async function onloadDataSource() {
        const res = await qLCCClient.qLCCNewsGet({
            ...new QLCCNewsModel(),
            action_type: 'GET_ALL',
            apartment_building_id: apartment_building_id,
            ma_cty: SessionLogin.ma_cty,
        } as QLCCNewsModel);
        setDataTable(res);
    }

    React.useEffect(() => {
        onloadDataSource();
    }, [apartment_building_id])

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code) {
            case ACTIONS.ADD:
                setAction({
                    ...action,
                    type: action?.action_code,
                    open: true,
                } as IAction);
                break;
            case ACTIONS.VIEW:
            case ACTIONS.EDIT:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setAction({
                                ...action,
                                type: action?.action_code,
                                open: true,
                            } as IAction);
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }

                break;
            case ACTIONS.COPY:
                break;
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            if (window.confirm("Xác nhận xóa")) {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`UNAVAILABLE_FEATURE`]
                                );
                            }
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.CLOSE:
                break;
            case ACTIONS.SEARCH:
                break;
            case ACTIONS.SAVE:
                break;
        }
    }

    const handleSubmit = (data: any) => {
        setLoading(true)

        setAction({
            ...action,
            open: false,
            type: "",
            name: "",
            table: "",
        });
    };

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>(-1);
    const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>();
    const [reportInfo, setReportInfo] = React.useState<ReportInfo>();

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {

                const index = e.component.getRowIndexByKey(keys[0]);

                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
                setIdItemSelected(keys[0].id || "");
            });
        }
    };

    return (
        <div
            className="container-fluid p-2"
            style={{ maxHeight: "94vh !important", overflowY: "auto" }}
        >

            {React.useMemo(() => {
                return (
                    <NewsActions
                        open={action.open}
                        onClose={() => {
                            setAction({
                                ...action,
                                open: false,
                                type: "",
                                // name: "",
                                table: "",
                            });
                        }}
                        action={action.type}
                        onSubmit={handleSubmit}
                        itemSelected={itemSelected}
                        onEditSuccess={function (is_success: any): void {
                            onloadDataSource();
                        }}
                    />
                );
            }, [action.open])}

            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location}
                />
            </div>

            <DHSToolbarRole
                id={" "}
                menu={menu}
                onClickAction={handleClickAction}
            />


            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                // key="so_ct"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handlePageChange={handlePageChange}
                handleSelectionChanged={handleSelectionChanged}
            />

            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </div>
    );
};

export default News;
