import React, { useEffect, useState } from 'react'
import DHSDateTimeComponentV2 from '../../../../../components/DHS/DHS-DateTime/DHSDateTimeComponentV2';
import moment from 'moment';
import DHSSelectBox from '../../../../../components/DHS/DHS_SelectBox';
import { InputLabel } from '@mui/material';

interface IProps {
    cycle_code: number; // 1: ngày, 2: Tuần 3:Tháng 4: Quý  5:năm
    // ase default but you need to choose other case . Why? Program will show error
    value?: Date;
    setValue?: (value: Date) => void;
    //case 1 : setData
    onSubmit?: (e: IDateCheckListModel) => void;
    //case 2: set start - end time
    setToDate?: (e: any) => void;
    setFromDate?: (e: any) => void;

    case: 'SET_DATA' | 'SET_START_END_TIME'; // option case 1 | case 2

    disable?: boolean;
}

interface IDate {
    code: number;
    name: string;
}

export interface IDateCheckListModel {
    startTime: Date,
    endTime: Date,
}

const cycle = [
    { code: 1, name: 'Theo ngày', code_2: 'date' },
    { code: 2, name: 'Theo tuần', code_2: 'week' },
    { code: 3, name: 'Theo tháng', code_2: 'month' },
    { code: 4, name: 'Theo quý', code_2: 'quarter' },
    { code: 5, name: 'Theo Năm', code_2: 'year' }
]

const QUYS = [
    {
        code: 41,
        name: 'Quý 1'
    },
    {
        code: 42,
        name: 'Quý 2'
    },
    {
        code: 43,
        name: 'Quý 3'
    },
    {
        code: 44,
        name: 'Quý 4'
    },
]

const Thang = [
    {
        code: 20,
        name: 'Tháng 1'
    },
    {
        code: 21,
        name: 'Tháng 2'
    },
    {
        code: 22,
        name: 'Tháng 3'
    },
    {
        code: 23,
        name: 'Tháng 4'
    },
    {
        code: 24,
        name: 'Tháng 5'
    },
    {
        code: 25,
        name: 'Tháng 6'
    },
    {
        code: 26,
        name: 'Tháng 7'
    },
    {
        code: 27,
        name: 'Tháng 8'
    },
    {
        code: 28,
        name: 'Tháng 9'
    },
    {
        code: 29,
        name: 'Tháng 10'
    },
    {
        code: 30,
        name: 'Tháng 11'
    },
    {
        code: 31,
        name: 'Tháng 12'
    },
];

// GET TOTAL WEEKS IN A YEAR
function GetISOWeeks(y: number) {
    console.log(y)
    const MIN_YEAR = 1990;
    const MAX_YEAR = 2199;
    const MONTH_INDEX_0 = 0;
    const MONTH_INDEX_1 = 1;
    const DATE_INDEX_0 = 29
    const DATE_INDEX_1 = 1;
    const DAY_INDEX_3 = 3;
    const DAY_INDEX_4 = 4;
    const WEEK_INEX_LEAD = 53;
    const WEEK_INEX_UNLEAD = 52;

    if (y >= MIN_YEAR && y <= MAX_YEAR) {
        var d: Date,
            isLeap: boolean;

        d = new Date(y, MONTH_INDEX_0, DATE_INDEX_1);
        isLeap = new Date(y, MONTH_INDEX_1, DATE_INDEX_0).getMonth() === MONTH_INDEX_1;

        // check for a Jan 1 that's a Thursday or a leap year that has a 
        // Wednesday jan 1. Otherwise it's 52
        return d.getDay() === DAY_INDEX_4 || isLeap && d.getDay() === DAY_INDEX_3 ? WEEK_INEX_LEAD : WEEK_INEX_UNLEAD
    } else {
        return 0;
    }
}

const ConvertDateStart = (value: Date, type: 'month' | 'year' | 'week' | 'date' | 'quarter' | string) => {

    if (type === 'week') {
        return moment(value).startOf('week').set('millisecond', 0).toDate();
    } else if (type === 'month') {
        return moment(value).startOf('month').set('millisecond', 0).toDate();
    } else if (type === 'year') {
        return moment(value).startOf('year').set('millisecond', 0).toDate();
    } else if (type === 'quarter') {
        return moment(value).startOf('quarter').set('millisecond', 0).toDate();
    } else {
        return moment(value).startOf('day').set('millisecond', 0).toDate();
    }

}

const ConvertDateEnd = (value: Date, type: 'month' | 'year' | 'week' | 'date' | 'quarter' | string) => {
    if (type === 'week') {
        return moment(value).endOf('week').set('millisecond', 0).toDate();
    } else if (type === 'month') {
        return moment(value).endOf('month').set('millisecond', 0).toDate();
    } else if (type === 'year') {
        return moment(value).endOf('year').set('millisecond', 0).toDate();
    } else if (type === 'quarter') {
        return moment(value).endOf('quarter').set('millisecond', 0).toDate();
    } else {
        return moment(value).endOf('day').set('millisecond', 0).toDate();
    }
}

const DEFAULT_NUMBER_QUYS = 40;
const DEFAULT_NUMBER_THANG = 20;
const DEFAULT_NUMBER_NAM = 1990;
const DEFAULT_NUMBER_TUAN = 59;

const Tuan_53: IDate[] = [
    {
        "code": 60,
        "name": "Tuần 1"
    },
    {
        "code": 61,
        "name": "Tuần 2"
    },
    {
        "code": 62,
        "name": "Tuần 3"
    },
    {
        "code": 63,
        "name": "Tuần 4"
    },
    {
        "code": 64,
        "name": "Tuần 5"
    },
    {
        "code": 65,
        "name": "Tuần 6"
    },
    {
        "code": 66,
        "name": "Tuần 7"
    },
    {
        "code": 67,
        "name": "Tuần 8"
    },
    {
        "code": 68,
        "name": "Tuần 9"
    },
    {
        "code": 69,
        "name": "Tuần 10"
    },
    {
        "code": 70,
        "name": "Tuần 11"
    },
    {
        "code": 71,
        "name": "Tuần 12"
    },
    {
        "code": 72,
        "name": "Tuần 13"
    },
    {
        "code": 73,
        "name": "Tuần 14"
    },
    {
        "code": 74,
        "name": "Tuần 15"
    },
    {
        "code": 75,
        "name": "Tuần 16"
    },
    {
        "code": 76,
        "name": "Tuần 17"
    },
    {
        "code": 77,
        "name": "Tuần 18"
    },
    {
        "code": 78,
        "name": "Tuần 19"
    },
    {
        "code": 79,
        "name": "Tuần 20"
    },
    {
        "code": 80,
        "name": "Tuần 21"
    },
    {
        "code": 81,
        "name": "Tuần 22"
    },
    {
        "code": 82,
        "name": "Tuần 23"
    },
    {
        "code": 83,
        "name": "Tuần 24"
    },
    {
        "code": 84,
        "name": "Tuần 25"
    },
    {
        "code": 85,
        "name": "Tuần 26"
    },
    {
        "code": 86,
        "name": "Tuần 27"
    },
    {
        "code": 87,
        "name": "Tuần 28"
    },
    {
        "code": 88,
        "name": "Tuần 29"
    },
    {
        "code": 89,
        "name": "Tuần 30"
    },
    {
        "code": 90,
        "name": "Tuần 31"
    },
    {
        "code": 91,
        "name": "Tuần 32"
    },
    {
        "code": 92,
        "name": "Tuần 33"
    },
    {
        "code": 93,
        "name": "Tuần 34"
    },
    {
        "code": 94,
        "name": "Tuần 35"
    },
    {
        "code": 95,
        "name": "Tuần 36"
    },
    {
        "code": 96,
        "name": "Tuần 37"
    },
    {
        "code": 97,
        "name": "Tuần 38"
    },
    {
        "code": 98,
        "name": "Tuần 39"
    },
    {
        "code": 99,
        "name": "Tuần 40"
    },
    {
        "code": 100,
        "name": "Tuần 41"
    },
    {
        "code": 101,
        "name": "Tuần 42"
    },
    {
        "code": 102,
        "name": "Tuần 43"
    },
    {
        "code": 103,
        "name": "Tuần 44"
    },
    {
        "code": 104,
        "name": "Tuần 45"
    },
    {
        "code": 105,
        "name": "Tuần 46"
    },
    {
        "code": 106,
        "name": "Tuần 47"
    },
    {
        "code": 107,
        "name": "Tuần 48"
    },
    {
        "code": 108,
        "name": "Tuần 49"
    },
    {
        "code": 109,
        "name": "Tuần 50"
    },
    {
        "code": 110,
        "name": "Tuần 51"
    },
    {
        "code": 111,
        "name": "Tuần 52"
    },
    {
        "code": 112,
        "name": "Tuần 53"
    }
];

const Tuan_52: IDate[] = [
    {
        "code": 60,
        "name": "Tuần 1"
    },
    {
        "code": 61,
        "name": "Tuần 2"
    },
    {
        "code": 62,
        "name": "Tuần 3"
    },
    {
        "code": 63,
        "name": "Tuần 4"
    },
    {
        "code": 64,
        "name": "Tuần 5"
    },
    {
        "code": 65,
        "name": "Tuần 6"
    },
    {
        "code": 66,
        "name": "Tuần 7"
    },
    {
        "code": 67,
        "name": "Tuần 8"
    },
    {
        "code": 68,
        "name": "Tuần 9"
    },
    {
        "code": 69,
        "name": "Tuần 10"
    },
    {
        "code": 70,
        "name": "Tuần 11"
    },
    {
        "code": 71,
        "name": "Tuần 12"
    },
    {
        "code": 72,
        "name": "Tuần 13"
    },
    {
        "code": 73,
        "name": "Tuần 14"
    },
    {
        "code": 74,
        "name": "Tuần 15"
    },
    {
        "code": 75,
        "name": "Tuần 16"
    },
    {
        "code": 76,
        "name": "Tuần 17"
    },
    {
        "code": 77,
        "name": "Tuần 18"
    },
    {
        "code": 78,
        "name": "Tuần 19"
    },
    {
        "code": 79,
        "name": "Tuần 20"
    },
    {
        "code": 80,
        "name": "Tuần 21"
    },
    {
        "code": 81,
        "name": "Tuần 22"
    },
    {
        "code": 82,
        "name": "Tuần 23"
    },
    {
        "code": 83,
        "name": "Tuần 24"
    },
    {
        "code": 84,
        "name": "Tuần 25"
    },
    {
        "code": 85,
        "name": "Tuần 26"
    },
    {
        "code": 86,
        "name": "Tuần 27"
    },
    {
        "code": 87,
        "name": "Tuần 28"
    },
    {
        "code": 88,
        "name": "Tuần 29"
    },
    {
        "code": 89,
        "name": "Tuần 30"
    },
    {
        "code": 90,
        "name": "Tuần 31"
    },
    {
        "code": 91,
        "name": "Tuần 32"
    },
    {
        "code": 92,
        "name": "Tuần 33"
    },
    {
        "code": 93,
        "name": "Tuần 34"
    },
    {
        "code": 94,
        "name": "Tuần 35"
    },
    {
        "code": 95,
        "name": "Tuần 36"
    },
    {
        "code": 96,
        "name": "Tuần 37"
    },
    {
        "code": 97,
        "name": "Tuần 38"
    },
    {
        "code": 98,
        "name": "Tuần 39"
    },
    {
        "code": 99,
        "name": "Tuần 40"
    },
    {
        "code": 100,
        "name": "Tuần 41"
    },
    {
        "code": 101,
        "name": "Tuần 42"
    },
    {
        "code": 102,
        "name": "Tuần 43"
    },
    {
        "code": 103,
        "name": "Tuần 44"
    },
    {
        "code": 104,
        "name": "Tuần 45"
    },
    {
        "code": 105,
        "name": "Tuần 46"
    },
    {
        "code": 106,
        "name": "Tuần 47"
    },
    {
        "code": 107,
        "name": "Tuần 48"
    },
    {
        "code": 108,
        "name": "Tuần 49"
    },
    {
        "code": 109,
        "name": "Tuần 50"
    },
    {
        "code": 110,
        "name": "Tuần 51"
    },
    {
        "code": 111,
        "name": "Tuần 52"
    }
];

const Nam: IDate[] = [
    {
        "code": 1990,
        "name": "1990"
    },
    {
        "code": 1991,
        "name": "1991"
    },
    {
        "code": 1992,
        "name": "1992"
    },
    {
        "code": 1993,
        "name": "1993"
    },
    {
        "code": 1994,
        "name": "1994"
    },
    {
        "code": 1995,
        "name": "1995"
    },
    {
        "code": 1996,
        "name": "1996"
    },
    {
        "code": 1997,
        "name": "1997"
    },
    {
        "code": 1998,
        "name": "1998"
    },
    {
        "code": 1999,
        "name": "1999"
    },
    {
        "code": 2000,
        "name": "2000"
    },
    {
        "code": 2001,
        "name": "2001"
    },
    {
        "code": 2002,
        "name": "2002"
    },
    {
        "code": 2003,
        "name": "2003"
    },
    {
        "code": 2004,
        "name": "2004"
    },
    {
        "code": 2005,
        "name": "2005"
    },
    {
        "code": 2006,
        "name": "2006"
    },
    {
        "code": 2007,
        "name": "2007"
    },
    {
        "code": 2008,
        "name": "2008"
    },
    {
        "code": 2009,
        "name": "2009"
    },
    {
        "code": 2010,
        "name": "2010"
    },
    {
        "code": 2011,
        "name": "2011"
    },
    {
        "code": 2012,
        "name": "2012"
    },
    {
        "code": 2013,
        "name": "2013"
    },
    {
        "code": 2014,
        "name": "2014"
    },
    {
        "code": 2015,
        "name": "2015"
    },
    {
        "code": 2016,
        "name": "2016"
    },
    {
        "code": 2017,
        "name": "2017"
    },
    {
        "code": 2018,
        "name": "2018"
    },
    {
        "code": 2019,
        "name": "2019"
    },
    {
        "code": 2020,
        "name": "2020"
    },
    {
        "code": 2021,
        "name": "2021"
    },
    {
        "code": 2022,
        "name": "2022"
    },
    {
        "code": 2023,
        "name": "2023"
    },
    {
        "code": 2024,
        "name": "2024"
    },
    {
        "code": 2025,
        "name": "2025"
    },
    {
        "code": 2026,
        "name": "2026"
    },
    {
        "code": 2027,
        "name": "2027"
    },
    {
        "code": 2028,
        "name": "2028"
    },
    {
        "code": 2029,
        "name": "2029"
    },
    {
        "code": 2030,
        "name": "2030"
    },
    {
        "code": 2031,
        "name": "2031"
    },
    {
        "code": 2032,
        "name": "2032"
    },
    {
        "code": 2033,
        "name": "2033"
    },
    {
        "code": 2034,
        "name": "2034"
    },
    {
        "code": 2035,
        "name": "2035"
    },
    {
        "code": 2036,
        "name": "2036"
    },
    {
        "code": 2037,
        "name": "2037"
    },
    {
        "code": 2038,
        "name": "2038"
    },
    {
        "code": 2039,
        "name": "2039"
    },
    {
        "code": 2040,
        "name": "2040"
    },
    {
        "code": 2041,
        "name": "2041"
    },
    {
        "code": 2042,
        "name": "2042"
    },
    {
        "code": 2043,
        "name": "2043"
    },
    {
        "code": 2044,
        "name": "2044"
    },
    {
        "code": 2045,
        "name": "2045"
    },
    {
        "code": 2046,
        "name": "2046"
    },
    {
        "code": 2047,
        "name": "2047"
    },
    {
        "code": 2048,
        "name": "2048"
    },
    {
        "code": 2049,
        "name": "2049"
    },
    {
        "code": 2050,
        "name": "2050"
    },
    {
        "code": 2051,
        "name": "2051"
    },
    {
        "code": 2052,
        "name": "2052"
    },
    {
        "code": 2053,
        "name": "2053"
    },
    {
        "code": 2054,
        "name": "2054"
    },
    {
        "code": 2055,
        "name": "2055"
    },
    {
        "code": 2056,
        "name": "2056"
    },
    {
        "code": 2057,
        "name": "2057"
    },
    {
        "code": 2058,
        "name": "2058"
    },
    {
        "code": 2059,
        "name": "2059"
    },
    {
        "code": 2060,
        "name": "2060"
    },
    {
        "code": 2061,
        "name": "2061"
    },
    {
        "code": 2062,
        "name": "2062"
    },
    {
        "code": 2063,
        "name": "2063"
    },
    {
        "code": 2064,
        "name": "2064"
    },
    {
        "code": 2065,
        "name": "2065"
    },
    {
        "code": 2066,
        "name": "2066"
    },
    {
        "code": 2067,
        "name": "2067"
    },
    {
        "code": 2068,
        "name": "2068"
    },
    {
        "code": 2069,
        "name": "2069"
    },
    {
        "code": 2070,
        "name": "2070"
    },
    {
        "code": 2071,
        "name": "2071"
    },
    {
        "code": 2072,
        "name": "2072"
    },
    {
        "code": 2073,
        "name": "2073"
    },
    {
        "code": 2074,
        "name": "2074"
    },
    {
        "code": 2075,
        "name": "2075"
    },
    {
        "code": 2076,
        "name": "2076"
    },
    {
        "code": 2077,
        "name": "2077"
    },
    {
        "code": 2078,
        "name": "2078"
    },
    {
        "code": 2079,
        "name": "2079"
    },
    {
        "code": 2080,
        "name": "2080"
    },
    {
        "code": 2081,
        "name": "2081"
    },
    {
        "code": 2082,
        "name": "2082"
    },
    {
        "code": 2083,
        "name": "2083"
    },
    {
        "code": 2084,
        "name": "2084"
    },
    {
        "code": 2085,
        "name": "2085"
    },
    {
        "code": 2086,
        "name": "2086"
    },
    {
        "code": 2087,
        "name": "2087"
    },
    {
        "code": 2088,
        "name": "2088"
    },
    {
        "code": 2089,
        "name": "2089"
    },
    {
        "code": 2090,
        "name": "2090"
    },
    {
        "code": 2091,
        "name": "2091"
    },
    {
        "code": 2092,
        "name": "2092"
    },
    {
        "code": 2093,
        "name": "2093"
    },
    {
        "code": 2094,
        "name": "2094"
    },
    {
        "code": 2095,
        "name": "2095"
    },
    {
        "code": 2096,
        "name": "2096"
    },
    {
        "code": 2097,
        "name": "2097"
    },
    {
        "code": 2098,
        "name": "2098"
    },
    {
        "code": 2099,
        "name": "2099"
    },
    {
        "code": 2100,
        "name": "2100"
    },
    {
        "code": 2101,
        "name": "2101"
    },
    {
        "code": 2102,
        "name": "2102"
    },
    {
        "code": 2103,
        "name": "2103"
    },
    {
        "code": 2104,
        "name": "2104"
    },
    {
        "code": 2105,
        "name": "2105"
    },
    {
        "code": 2106,
        "name": "2106"
    },
    {
        "code": 2107,
        "name": "2107"
    },
    {
        "code": 2108,
        "name": "2108"
    },
    {
        "code": 2109,
        "name": "2109"
    },
    {
        "code": 2110,
        "name": "2110"
    },
    {
        "code": 2111,
        "name": "2111"
    },
    {
        "code": 2112,
        "name": "2112"
    },
    {
        "code": 2113,
        "name": "2113"
    },
    {
        "code": 2114,
        "name": "2114"
    },
    {
        "code": 2115,
        "name": "2115"
    },
    {
        "code": 2116,
        "name": "2116"
    },
    {
        "code": 2117,
        "name": "2117"
    },
    {
        "code": 2118,
        "name": "2118"
    },
    {
        "code": 2119,
        "name": "2119"
    },
    {
        "code": 2120,
        "name": "2120"
    },
    {
        "code": 2121,
        "name": "2121"
    },
    {
        "code": 2122,
        "name": "2122"
    },
    {
        "code": 2123,
        "name": "2123"
    },
    {
        "code": 2124,
        "name": "2124"
    },
    {
        "code": 2125,
        "name": "2125"
    },
    {
        "code": 2126,
        "name": "2126"
    },
    {
        "code": 2127,
        "name": "2127"
    },
    {
        "code": 2128,
        "name": "2128"
    },
    {
        "code": 2129,
        "name": "2129"
    },
    {
        "code": 2130,
        "name": "2130"
    },
    {
        "code": 2131,
        "name": "2131"
    },
    {
        "code": 2132,
        "name": "2132"
    },
    {
        "code": 2133,
        "name": "2133"
    },
    {
        "code": 2134,
        "name": "2134"
    },
    {
        "code": 2135,
        "name": "2135"
    },
    {
        "code": 2136,
        "name": "2136"
    },
    {
        "code": 2137,
        "name": "2137"
    },
    {
        "code": 2138,
        "name": "2138"
    },
    {
        "code": 2139,
        "name": "2139"
    },
    {
        "code": 2140,
        "name": "2140"
    },
    {
        "code": 2141,
        "name": "2141"
    },
    {
        "code": 2142,
        "name": "2142"
    },
    {
        "code": 2143,
        "name": "2143"
    },
    {
        "code": 2144,
        "name": "2144"
    },
    {
        "code": 2145,
        "name": "2145"
    },
    {
        "code": 2146,
        "name": "2146"
    },
    {
        "code": 2147,
        "name": "2147"
    },
    {
        "code": 2148,
        "name": "2148"
    },
    {
        "code": 2149,
        "name": "2149"
    },
    {
        "code": 2150,
        "name": "2150"
    },
    {
        "code": 2151,
        "name": "2151"
    },
    {
        "code": 2152,
        "name": "2152"
    },
    {
        "code": 2153,
        "name": "2153"
    },
    {
        "code": 2154,
        "name": "2154"
    },
    {
        "code": 2155,
        "name": "2155"
    },
    {
        "code": 2156,
        "name": "2156"
    },
    {
        "code": 2157,
        "name": "2157"
    },
    {
        "code": 2158,
        "name": "2158"
    },
    {
        "code": 2159,
        "name": "2159"
    },
    {
        "code": 2160,
        "name": "2160"
    },
    {
        "code": 2161,
        "name": "2161"
    },
    {
        "code": 2162,
        "name": "2162"
    },
    {
        "code": 2163,
        "name": "2163"
    },
    {
        "code": 2164,
        "name": "2164"
    },
    {
        "code": 2165,
        "name": "2165"
    },
    {
        "code": 2166,
        "name": "2166"
    },
    {
        "code": 2167,
        "name": "2167"
    },
    {
        "code": 2168,
        "name": "2168"
    },
    {
        "code": 2169,
        "name": "2169"
    },
    {
        "code": 2170,
        "name": "2170"
    },
    {
        "code": 2171,
        "name": "2171"
    },
    {
        "code": 2172,
        "name": "2172"
    },
    {
        "code": 2173,
        "name": "2173"
    },
    {
        "code": 2174,
        "name": "2174"
    },
    {
        "code": 2175,
        "name": "2175"
    },
    {
        "code": 2176,
        "name": "2176"
    },
    {
        "code": 2177,
        "name": "2177"
    },
    {
        "code": 2178,
        "name": "2178"
    },
    {
        "code": 2179,
        "name": "2179"
    },
    {
        "code": 2180,
        "name": "2180"
    },
    {
        "code": 2181,
        "name": "2181"
    },
    {
        "code": 2182,
        "name": "2182"
    },
    {
        "code": 2183,
        "name": "2183"
    },
    {
        "code": 2184,
        "name": "2184"
    },
    {
        "code": 2185,
        "name": "2185"
    },
    {
        "code": 2186,
        "name": "2186"
    },
    {
        "code": 2187,
        "name": "2187"
    },
    {
        "code": 2188,
        "name": "2188"
    },
    {
        "code": 2189,
        "name": "2189"
    },
    {
        "code": 2190,
        "name": "2190"
    },
    {
        "code": 2191,
        "name": "2191"
    },
    {
        "code": 2192,
        "name": "2192"
    },
    {
        "code": 2193,
        "name": "2193"
    },
    {
        "code": 2194,
        "name": "2194"
    },
    {
        "code": 2195,
        "name": "2195"
    },
    {
        "code": 2196,
        "name": "2196"
    },
    {
        "code": 2197,
        "name": "2197"
    },
    {
        "code": 2198,
        "name": "2198"
    },
    {
        "code": 2199,
        "name": "2199"
    }
];

const TaskCheckListNgayCT = (props: IProps) => {

    const { cycle_code } = props;

    const [fromDate, setFromDate] = useState<Date>()
    const [toDate, setToDate] = useState<Date>()
    const [date, setDate] = useState<Date>(new Date())
    const [quy, setQuy] = useState<number>(DEFAULT_NUMBER_QUYS);
    const [nam, setNam] = useState<number>(moment(props.value).year());
    const [tuan, setTuan] = useState<number>(moment(props.value).week() + 60);
    const [thang, setThang] = useState<number>(moment(props.value).month() + 20);

    const [reload, setReload] = useState<boolean>(false)

    const onChangeDate = (date: Date, type: string) => {
        setFromDate(ConvertDateStart(date, type));
        setToDate(ConvertDateEnd(date, type));
    }

    const handleChangeDate = (e: any) => {
        if (props.disable) return;
        const { name, value } = e;
        setDate(value);
    }

    const handleChangeInput = (e: any) => {
        if (props.disable) return;
        const { name, value } = e.target;
        switch (name) {
            case 'nam':
                setNam(value);
                break;
            case 'tuan':
                setTuan(value);
                break;
            case 'thang':
                setThang(value);
                break;
            case 'quy':
                setQuy(value);
                break;
        };
    }

    useEffect(() => {
        if (props.disable) return;
        if (nam) {
            let newDate = moment().toDate();
            let typeDate = 'day';
            switch (cycle_code) {
                case 1: break;
                case 2:
                    newDate = moment().year(nam).isoWeek(tuan - DEFAULT_NUMBER_TUAN).endOf('week').toDate();
                    typeDate = "week";
                    break;
                case 3:
                    newDate = moment().year(nam).month(thang - DEFAULT_NUMBER_THANG).endOf('month').toDate();
                    typeDate = "month";
                    break;
                case 4:
                    newDate = moment().year(nam).quarter(quy - DEFAULT_NUMBER_QUYS).endOf('quarter').toDate();
                    typeDate = "quarter";
                    break;
                case 5:
                    newDate = moment().year(nam).endOf('year').toDate();
                    typeDate = "year";
                    break;
            };
            handleChangeDate({ name: 'date', value: newDate }); // Date
        }
    }, [nam, tuan, thang, quy])

    useEffect(() => {
        const type = cycle.find(item => item.code === cycle_code);
        onChangeDate(date, type ? type.code_2! : 'date');
        console.log(type);
        (async () => {
            if (props.setValue) {
                await props.setValue(date)
            }
        })();

    }, [date])

    useEffect(() => {
        (async () => {
            if (props.value) {
                await setDate(props.value);
            }
            else {
                await setDate(new Date());
            }
            const type = cycle.find(item => item.code === cycle_code);
            onChangeDate(date, type ? type.code_2! : 'date');
            if (cycle_code === 4) {

                switch (moment(date).quarter()) {
                    case 1:
                        setQuy(41);
                        break;
                    case 2:
                        setQuy(42);
                        break;
                    case 3:
                        setQuy(43);
                        break;
                    case 4:
                        setQuy(44);
                        break
                }

                setReload(pre => !pre);
            }
        })();
    }, [cycle_code])

    useEffect(() => {
        if (props.case === 'SET_START_END_TIME') {
            if (props.setFromDate) {
                props.setFromDate(fromDate);
            }
            if (props.setToDate) {
                props.setToDate(toDate);
            }
        } else if (props.case === 'SET_DATA') {
            if (props.onSubmit) {
                props.onSubmit({
                    startTime: fromDate,
                    endTime: toDate
                } as IDateCheckListModel)
            }
        } else {
            console.log('PLEASE TEST CASE SELECT!')
        }
    }, [fromDate, toDate])

    return (
        <>
            {
                cycle_code > 0 && cycle_code < 2 ?
                    (
                        <>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày</InputLabel>
                            <DHSDateTimeComponentV2
                                name="week"
                                type="date"
                                // format={'dd-MM-yyyy'}
                                value={date}
                                onChange={cycle_code === 1 ? handleChangeDate : handleChangeDate}
                                disabled={props.disable ? props.disable : false}
                                // applyValueMode='instantly'
                                hint='Chọn thời gian'
                            />
                        </>
                    ) :
                    cycle_code === 2 ?
                        (<>
                            <div className='mt-1'>
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Năm</InputLabel>
                                <DHSSelectBox
                                    dataSource={Nam}
                                    name="nam"
                                    onValueChanged={handleChangeInput}
                                    displayExpr="name"
                                    searchExpr="name"
                                    valueExpr="code"
                                    value={nam}
                                    disabled={props.disable ? props.disable : false}
                                    hint='Chọn năm'
                                />
                            </div>
                            <div className='mt-1'>
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tuần</InputLabel>
                                <DHSSelectBox
                                    dataSource={GetISOWeeks(nam) === 53 ? Tuan_53 : Tuan_52}
                                    // dataSource={Tuan_53}
                                    name="tuan"
                                    onValueChanged={handleChangeInput}
                                    displayExpr="name"
                                    searchExpr="name"
                                    valueExpr="code"
                                    value={tuan}
                                    disabled={props.disable ? props.disable : false}
                                    hint='Chọn tuần'
                                />
                            </div>
                        </>)
                        :
                        cycle_code === 3 ?
                            (
                                <>
                                    <div className='mt-1'>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Năm</InputLabel>
                                        <DHSSelectBox
                                            dataSource={Nam}
                                            name="nam"
                                            onValueChanged={handleChangeInput}
                                            displayExpr="name"
                                            searchExpr="name"
                                            valueExpr="code"
                                            value={nam}
                                            disabled={props.disable ? props.disable : false}
                                            hint='Chọn năm'
                                        />
                                    </div>
                                    <div className='mt-1'>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tháng</InputLabel>
                                        <DHSSelectBox
                                            dataSource={Thang}
                                            name="thang"
                                            onValueChanged={handleChangeInput}
                                            displayExpr="name"
                                            searchExpr="name"
                                            valueExpr="code"
                                            value={thang}
                                            disabled={props.disable ? props.disable : false}
                                            hint='Chọn tháng'
                                        />
                                    </div>

                                </>
                            ) :
                            cycle_code === 4 ?
                                (
                                    <>
                                        <div className='mt-1'>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Năm</InputLabel>
                                            <DHSSelectBox
                                                dataSource={Nam}
                                                name="nam"
                                                onValueChanged={handleChangeInput}
                                                displayExpr="name"
                                                searchExpr="name"
                                                valueExpr="code"
                                                value={nam}
                                                disabled={props.disable ? props.disable : false}
                                                hint='Chọn năm'
                                            />
                                        </div>
                                        <div className='mt-1'>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Quý</InputLabel>
                                            <DHSSelectBox
                                                dataSource={QUYS}
                                                name="quy"
                                                onValueChanged={handleChangeInput}
                                                displayExpr="name"
                                                searchExpr="name"
                                                valueExpr="code"
                                                value={quy}
                                                disabled={props.disable ? props.disable : false}
                                                hint='Chọn quý'
                                            />
                                        </div>
                                    </>
                                ) :
                                cycle_code === 5 ?
                                    (
                                        <>
                                            <div className='mt-1'>
                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Năm</InputLabel>
                                                <DHSSelectBox
                                                    dataSource={Nam}
                                                    name="nam"
                                                    onValueChanged={handleChangeInput}
                                                    displayExpr="name"
                                                    searchExpr="name"
                                                    valueExpr="code"
                                                    value={nam}
                                                    disabled={props.disable ? props.disable : false}
                                                    hint='Chọn năm'
                                                />
                                            </div>
                                        </>
                                    ) :
                                    (
                                        <></>
                                    )
            }
        </>
    )
}

export default TaskCheckListNgayCT