import React from 'react'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IReducer } from '../../../../../common/Interfaces/Interfaces';
import { getRoles } from '../../../../../redux/actions/RoleActions';
import { AppSession } from '../../../../shared/app-session/app-session';
import {
    HomeClient,
    QLCCApartmentBuildingModel,
    QLCCApartmentModel,
    QLCCBlockModel,
    QLCCClient,
    QLCCDomesticWaterCycleModel,
    QLCCDomesticWaterModel,
    QLCCFloorModel,
    RoleRightWebModel,
    SysActionType
} from '../../../../shared/service-proxies/api-shared';
import BASE_URL_API from "../../../../shared/service-proxies/config";
import HomeIcon from '@mui/icons-material/Home';
import '../../../cost-management/container/domestic-water-floor.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Notification } from '../../../../../components/layouts';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import ApartmentAction from '../../../actions/apartment-action/ApartmentAction';
import { Tooltip } from 'antd';
import { ToVietNamCurrency } from '../../../../../components/utils/number.util';
import ApartmentIcon from '@mui/icons-material/Apartment';
import NightShelterIcon from '@mui/icons-material/NightShelter';
import InfoIcon from '@mui/icons-material/Info';
import DHSBreadcrumb from '../../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import { ACTIONS } from '../../../../../common/enums/DHSToolbarRoleAction';
import { DHSToolbarCheckRole } from '../../../../../components/DHS/DHS-Toolbar-Role/DHSTollbarCheckRole';
import { setApartmentBuilding } from '../../../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction';
import { CALL_API_LOAD_APARTMENT_BUILDING_LIST, CALL_API_LOAD_INFO_DASHBOARD_APARTMENT_BUILDING, IInfoDashboardApartmentBuilding } from '../../apartment-building-list';

interface IProps {
    isSelected: string;
}

interface IAction {
    open: boolean;
    type: string | undefined;
    payload: any | undefined;
}


const defaultAction: IAction = {
    open: false,
    type: "",
    payload: ""
}


let appSession: AppSession;

const menuRoleName = "CC";

const ApartmentListVrII = (Props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    // const [{ _id }, setId] = React.useState(useParams<{ _id: string }>());
    const [_id, setId] = React.useState("");
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const [qlccFloorList, setQLCCFloorList] = React.useState<QLCCFloorModel[]>([] as QLCCFloorModel[]);
    const [qlccBlockList, setQLCCBlockList] = React.useState<QLCCBlockModel[]>([] as QLCCBlockModel[]);
    const [blockID_, setBlockID_] = React.useState<string>("");
    const [apartmentBuilding, setApartmentBuilding1] = React.useState<QLCCApartmentBuildingModel[]>([] as QLCCApartmentBuildingModel[]);
    const [apartmentBuildingName, setAPartmentBuildingName] = React.useState<QLCCApartmentBuildingModel>({} as QLCCApartmentBuildingModel)
    const [floorSelect, setFloorSelect] = React.useState<QLCCFloorModel>({} as QLCCFloorModel);
    const [bLockSelect, setBLockSelect] = React.useState<QLCCBlockModel>({} as QLCCBlockModel);
    /* danh sách căn hộ */
    const [apartmentList, setAPartmentList] = React.useState<QLCCApartmentModel[]>([] as QLCCApartmentModel[]);

    const [reload, setReload] = React.useState(false);
    const [actCx, setActCx] = React.useState<IAction>(defaultAction);

    /* load hiệu ứng */
    const [onloadBlock, setOnloadBlock] = React.useState<boolean>(false);
    const [refresh, setRefresh] = React.useState(false);

    const [idItemSelected, setIdItemSelected] = React.useState<string>("");

    const [action, setAction] = React.useState<IAction>({
        open: false,
        type: "",
        payload: ""
    });

    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
    const [infoDashboardApartmentBuilding, setInfoDashboardApartmentBuilding] = React.useState<IInfoDashboardApartmentBuilding>();

    async function OnLoadDashBoard() {
        CALL_API_LOAD_INFO_DASHBOARD_APARTMENT_BUILDING(apartment_building_id).then(res => {
            setInfoDashboardApartmentBuilding(res);
            // console.log(res)
        })
    }

    React.useEffect(() => {
        // qLCCClient
        //     .qlccApartmentBuildingGet({
        //         ...new QLCCApartmentBuildingModel(),
        //         ma_cty: SessionLogin.ma_cty,
        //     } as QLCCApartmentBuildingModel)
        //     .then((res) => {
        //         setApartmentBuilding1(res);
        //     });
        CALL_API_LOAD_APARTMENT_BUILDING_LIST().then((res) => {
            setApartmentBuilding1(res);
        });

    }, [])

    React.useEffect(() => {
        if (apartment_building_id) {
            setId(apartment_building_id);
        }
    }, [apartment_building_id])

    React.useEffect(() => {
        if (_id) {
            setLoading(true);
            qLCCClient
                .qlccBlockGet({
                    ... new QLCCBlockModel(),
                    apartment_building_id: _id,
                    ma_cty: SessionLogin.ma_cty,
                } as QLCCBlockModel)
                .then(res => {
                    if (res.length > 0) {
                        setQLCCBlockList(res);
                        setBlockID_(res[0].id || "");
                        setBLockSelect(res[0] || {})
                        onloadFloor(res[0].id || "");
                    } else {
                        setQLCCBlockList(res);
                        setBlockID_("");
                        onloadFloor("");
                        setBLockSelect({} as any);
                    }
                    setLoading(false);
                });
            const tempApartmentBuilding = apartmentBuilding.find(itemApartmentBuilding => itemApartmentBuilding.id === _id);
            if (tempApartmentBuilding) {
                setAPartmentBuildingName(tempApartmentBuilding);
            }
        }
    }, [_id])

    const onloadApartmentList = (blockID: string) => {
        if (blockID !== "") {
            setLoading(true);
            qLCCClient
                .qLCCApartmentsGet({
                    ...new QLCCApartmentModel(),
                    block_id: blockID,
                    apartment_building_id: _id,
                    ma_cty: SessionLogin.ma_cty,
                } as QLCCApartmentModel)
                .then((res) => {
                    setTimeout(() => {
                        setRefresh(false);
                    }, 300);
                    setAPartmentList(res);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else {
            setAPartmentList([]);
        }
    }

    const onloadFloor = (blockID: string) => {
        if (blockID !== "") {
            setLoading(true);
            qLCCClient
                .qLCCFloorGet({
                    ...new QLCCFloorModel(),
                    block_id: blockID,
                    apartment_building_id: _id,
                    ma_cty: SessionLogin.ma_cty,
                } as QLCCFloorModel)
                .then((res) => {
                    let arrTemp = res;
                    arrTemp.sort((a: any, b: any) => a.number_floor - b.number_floor);
                    setTimeout(() => {
                        setOnloadBlock(false);
                    }, 300);
                    setLoading(false);
                    setQLCCFloorList(arrTemp);

                }).catch((err) => {
                    setLoading(false);
                })
        } else {
            setQLCCFloorList([]);
        }
    }

    React.useEffect(() => {
        onload();
    }, [qlccFloorList, reload])

    const onload = () => {
        if (qlccFloorList.length > 0) {
            if (blockID_ && blockID_ !== "") {
                onloadApartmentList(blockID_);
                OnLoadDashBoard();
            }
            else {
                var blockID = qlccFloorList[0].block_id;
                if (blockID) onloadApartmentList(blockID);
            }
        }
    }

    const hanldeClickBlock = (e: any) => {
        setOnloadBlock(true);
        const blockID = e.target.id;
        setBlockID_(blockID);
        onloadFloor(blockID);
        if (onloadBlock) setOnloadBlock(false);
    }

    const selectBlock = (floor_id: string) => {
        return `${blockID_ === floor_id ? "selected" : "unselected"} btn-hover block-btn`;
    }

    const handlerefresh = () => {
        setRefresh(true);
        onload()
        setTimeout(() => {
            Notification("success", "Làm mới danh sách");
        }, 1000);
    }

    /* đợi phát triển sau */
    const calTotalsInfor = () => {
        const apartmentTotal = apartmentList.length;
        return {
            apartmentTotal: apartmentTotal,
        }
    }

    // const handleClickAction = (action: string, id: string = "") => {
    //     switch (action.toUpperCase()) {
    //         case "ADD":
    //             setActCx({
    //                 ...actCx,
    //                 open: true,
    //                 type: action.toUpperCase() ?? "",
    //                 payload: ""
    //             })
    //             break;
    //         case "EDIT":
    //             setActCx({
    //                 ...actCx,
    //                 open: true,
    //                 type: action.toUpperCase() ?? "",
    //                 payload: id
    //             })
    //             break;
    //     }
    // }

    const handleClick = async (action: string, id: string, ma_cty?: string, apartment_building_id?: string) => {

        DHSToolbarCheckRole({
            action_code: action,
            menu: menu,
            id: id,
            ma_cty: ma_cty,
            apartment_building_id: apartment_building_id,
            onClickAction: handleClickAction
        } as any);

    }

    const handleClickAction = (action: SysActionType, nowTime: Date, id: string, ma_cty?: string, apartment_building_id?: string) => {
        switch (action?.action_code) {
            case ACTIONS.VIEW:
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: ACTIONS.ADD,
                    payload: ""
                })
                break;
            case ACTIONS.EDIT:
                setActCx({
                    ...actCx,
                    open: true,
                    type: ACTIONS.EDIT,
                    payload: { id, ma_cty, apartment_building_id }
                })
                break;
            case ACTIONS.COPY:
                break;
            case ACTIONS.DELETE:
                break;
            case ACTIONS.CLOSE:
                break;
            case ACTIONS.SEARCH:
                break;
            case ACTIONS.SAVE:
                break;
        }
    }

    const handleChangeIntput = (e: any) => {
        const { name, value } = e.target;
        if (name === 'apartmentBuilding') {
            dispatch(setApartmentBuilding({
                    id: value
                } as QLCCApartmentBuildingModel))
        }
    }

    const Dashboard = (titles: string, total: number = 0, icon: any, type: string = "", color: string = "black", textColor: string = "white") => {
        return (
            <Grid
                item xs={12} sm={6} md={3} lg={3}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Box className='display-none-500'
                    sx={{ width: '100%', padding: '10px', borderRadius: 2, backgroundColor: color, color: textColor }}
                >
                    <Box className='d-flex justify-content-between'>
                        <Typography className="font-weight-600">{titles}</Typography>
                        {icon}
                    </Box>
                    <Box >
                        <Typography sx={{ fontSize: '20px', fontweight: '600', textAlign: "right" }}  >
                            {type === 'MONEY' ? ToVietNamCurrency(total) : `${total} ${type}`}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        )
    }


    const dataDashboard = () => {
        // const apartmentBuilding_ = apartmentBuilding.find(item => item.id === _id);
        const apartmentBuilding_ = qlccBlockList.find(item => item.id === blockID_);
        let apartmentUnActive = 0;
        let apartmentTotal = 0;
        let conditionalTotal = 0; //  tổng căn hộ cho thuê
        let apartmentActive = 0;
        if (apartmentBuilding_) {
            if (apartmentBuilding_?.apartment_total) apartmentTotal = apartmentBuilding_?.apartment_total;
            if (apartmentList && apartmentList.length > 0) {
                conditionalTotal = apartmentList.filter(item => item?.condition === true).length;
                apartmentActive = apartmentList.filter(item => item?.status_id === '1').length;
                apartmentUnActive = apartmentList.filter(item => item?.status_id === '0').length;
            }
        }

        return {
            apartmentActive,
            apartmentTotal,
            conditionalTotal,
            apartmentUnActive
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid container spacing={2}
                    className="title-color"
                    sx={
                        {
                            height: 10,
                            color: "rgb(0 78 255 / 88%)",
                            marginTop: 2,
                            marginBottom: 12,
                        }}
                >
                    <Grid item xs={12} sm={12} md={12} lg={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div
                                    className="d-flex title-color"
                                    style={{
                                        fontSize: 22,
                                        fontWeight: 550,
                                        paddingLeft: 15
                                    }}
                                >
                                    <DHSBreadcrumb
                                        location={location}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex justify-content-end pr-3">
                                <Button
                                    variant="outlined"
                                    // style={{ marginLeft: 10, paddingTop: 0, paddingBottom: 0, minWidth: 100 }}
                                    onClick={handlerefresh}
                                >
                                    {refresh ? <CircularProgress size={18} /> : "Làm mới"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={8.5} style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Grid container spacing={2}>
                            {/* {
                                Dashboard('Tổng căn hộ', dataDashboard().apartmentTotal, <ApartmentIcon
                                    sx={{
                                        fontSize: "32px",
                                        marginRight: '10px',
                                    }}
                                />,
                                    "Căn hộ",
                                    "#f87171"
                                )
                            }
                            {
                                Dashboard('Đang sử dụng', dataDashboard().apartmentActive, <HomeIcon
                                    sx={{
                                        fontSize: "32px",
                                        marginRight: '10px',
                                    }}
                                />,
                                    "Căn hộ",
                                    "#198754"

                                )
                            }
                            {
                                Dashboard('Không sử dụng', dataDashboard().apartmentUnActive, <OtherHousesIcon
                                    sx={{
                                        fontSize: "32px",
                                        marginRight: '10px',
                                    }}
                                />,
                                    "Căn hộ",
                                    "#94a3b8"
                                )
                            }
                            {
                                Dashboard('Đang cho thuê', dataDashboard().conditionalTotal, <NightShelterIcon
                                    sx={{
                                        fontSize: "32px",
                                        marginRight: '10px',
                                    }}
                                />,
                                    "Căn hộ",
                                    "#0083ce"
                                )
                            } */}
                            {
                                Dashboard('Tổng căn hộ', infoDashboardApartmentBuilding?.aparment, <ApartmentIcon
                                    sx={{
                                        fontSize: "32px",
                                        marginRight: '10px',
                                    }}
                                />,
                                    "Căn hộ",
                                    "#f87171"
                                )
                            }
                            {
                                Dashboard('Đang sử dụng', infoDashboardApartmentBuilding?.apartmentTypeUse, <HomeIcon
                                    sx={{
                                        fontSize: "32px",
                                        marginRight: '10px',
                                    }}
                                />,
                                    "Căn hộ",
                                    "#198754"

                                )
                            }
                            {
                                Dashboard('Không sử dụng', infoDashboardApartmentBuilding?.apartmentTypeNotUse, <OtherHousesIcon
                                    sx={{
                                        fontSize: "32px",
                                        marginRight: '10px',
                                    }}
                                />,
                                    "Căn hộ",
                                    "#94a3b8"
                                )
                            }
                            {
                                Dashboard('Đang cho thuê', infoDashboardApartmentBuilding?.apartmentCondition, <NightShelterIcon
                                    sx={{
                                        fontSize: "32px",
                                        marginRight: '10px',
                                    }}
                                />,
                                    "Căn hộ",
                                    "#0083ce"
                                )
                            }

                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <Box
                                    className="d-flex justify-content-center"
                                >
                                    <Box
                                        sx={{
                                            width: '80%',
                                        }}
                                    >
                                        <Box className="mb-3">
                                            <InputLabel htmlFor="input-with-domestic-water-block">
                                                Bước 1. Chọn dự án ({apartmentBuilding && (`${apartmentBuilding.length}`)})
                                            </InputLabel>
                                            <Select
                                                className="text-center"
                                                sx={{
                                                    width: '98%',
                                                }}
                                                size='small'
                                                id="input-with-domestic-water-cycle"
                                                name="apartmentBuilding"
                                                onChange={handleChangeIntput}
                                                value={_id || ""}
                                            >
                                                {
                                                    apartmentBuilding &&
                                                    apartmentBuilding.map((item, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={item.id}
                                                        >
                                                            <span style={{ fontSize: 14 }}>
                                                                {item.name}
                                                            </span>
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </Box>
                                        <Box>
                                            <InputLabel htmlFor="input-with-domestic-water-block">
                                                Bước 2. Chọn tòa nhà ({qlccBlockList && (`${qlccBlockList.length}`)})
                                            </InputLabel>
                                            <Box
                                                id="input-with-domestic-water-block"
                                                className='hidden-scrollbar width-scrollbar-hoa-don'
                                            >
                                                {
                                                    qlccBlockList &&
                                                    qlccBlockList.map((floor) => (
                                                        <Button
                                                            className={selectBlock(floor.id || "")}
                                                            key={floor.id}
                                                            id={floor.id}
                                                            onClick={(e) => {
                                                                hanldeClickBlock(e);
                                                                setBLockSelect(floor);
                                                            }}
                                                        >
                                                            {(onloadBlock && blockID_ === floor.id) ? <CircularProgress size={18} color='inherit' /> : floor.name}
                                                        </Button>
                                                    ))
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={8} lg={9} >
                                <InputLabel
                                    htmlFor="input-with-domestic-water-block"
                                    className='label-input-domestic-water'
                                >
                                    Bước 3. Danh sách tầng {blockID_ && qlccFloorList && (`(${qlccFloorList.length})`)}
                                    <Tooltip title={
                                        () => {
                                            return (
                                                <>
                                                    <p>
                                                        <HomeIcon style={{ color: "#0083ce" }} /> Đang cho thuê
                                                    </p>
                                                    <p>
                                                        <HomeIcon style={{ color: "#94a3b8" }} /> Trạng thái trống
                                                    </p>
                                                </>
                                            )
                                        }
                                    }>
                                        <IconButton>
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputLabel>
                                <Box
                                    className='hidden-scrollbar width-scrollbar-floor'
                                >
                                    {
                                        qlccFloorList &&
                                        qlccFloorList.map(item => (
                                            <Accordion
                                                key={item.id}
                                                sx={{
                                                    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;',
                                                }}
                                                style={{ backgroundColor: '#198754' }}
                                                onClick={() => setFloorSelect(item)}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: '#e4eefc'
                                                        }}
                                                    >
                                                        <div
                                                            className='minWidth_150-maxWidth_300-inline_block-fontWeight_500'
                                                        >
                                                            {item.name}
                                                        </div>
                                                        <span
                                                            className='icon-border-left-1-padding-5 icon-inline-block-100'
                                                        >
                                                            <Tooltip
                                                                placement="bottom"
                                                                title={`${apartmentList.filter(element => element.floor_id === item.id).length} căn hộ`}
                                                                color="gray"
                                                            >
                                                                <Typography>
                                                                    <OtherHousesIcon
                                                                        sx={{
                                                                            fontSize: "24px",
                                                                            marginRight: "10px",
                                                                        }}
                                                                    />
                                                                    {apartmentList &&
                                                                        (
                                                                            `${apartmentList.filter(element => element.floor_id === item.id).length}`
                                                                        )
                                                                    }
                                                                </Typography>
                                                            </Tooltip>
                                                        </span>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    sx={{
                                                        backgroundColor: "#F0F5F9"
                                                    }}
                                                >
                                                    <Typography>
                                                        <Box>
                                                            <div className='house-around '>
                                                                <div className='house-around-second'>
                                                                    <div
                                                                        id={item.id}
                                                                        onClick={() => handleClick(ACTIONS.ADD, item.id || "")}>
                                                                        <p
                                                                            className="house-icon select-all-icon"
                                                                        >
                                                                            <AddCircleIcon style={{ fontSize: '40px' }} />
                                                                        </p>
                                                                        <p
                                                                            className="house-title-add select-all-title"
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                            }}
                                                                        >
                                                                            <span className='line-clamp-1'>
                                                                                Thêm mới
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                apartmentList &&
                                                                apartmentList.filter(element => element.floor_id === item.id).map(e => (
                                                                    <div
                                                                        key={e.id}
                                                                        className='house-around'
                                                                    >
                                                                        <div className='house-around-second'
                                                                            onClick={() => handleClick(ACTIONS.EDIT, e.id || "", e?.ma_cty, e?.apartment_building_id)}
                                                                        >
                                                                            <div>
                                                                                <p
                                                                                    id={e.id}
                                                                                    className=
                                                                                    {
                                                                                        e.status_id === "0"
                                                                                            ? "house-icon house-color-unactive"
                                                                                            : !e.condition
                                                                                                ? "house-icon house-color-pay-status-true"
                                                                                                : "house-icon house-color-condition-false"
                                                                                    }
                                                                                >
                                                                                    <HomeIcon style={{ fontSize: '40px' }} />
                                                                                </p>
                                                                                <p
                                                                                    className='house-title'
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        className='line-clamp-1'
                                                                                    >
                                                                                        {e.name}
                                                                                    </span>
                                                                                </p>
                                                                                {
                                                                                    e.owner_resident_id
                                                                                    && (
                                                                                        <p
                                                                                            className='house-title-sup line-clamp-1'
                                                                                            style={{ margin: 0 }}
                                                                                        >
                                                                                            {e.owner_resident_name}
                                                                                        </p>
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                            {
                                                                !apartmentList ||
                                                                (apartmentList &&
                                                                    apartmentList.filter(element => element.floor_id === item.id).length === 0) && (
                                                                    <div
                                                                        style={{
                                                                            textAlign: 'center'
                                                                        }}
                                                                    >
                                                                        {
                                                                            blockID_ &&
                                                                                blockID_ === "" ? (
                                                                                <h4 style={{ color: '#D8D8D8' }}>Vui lòng chọn tòa nhà!</h4>
                                                                            ) : (
                                                                                <h5 style={{ color: '#D8D8D8' }}>Không có căn hộ</h5>
                                                                            )
                                                                        }

                                                                    </div>
                                                                )
                                                            }
                                                        </Box>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))
                                    }
                                    {
                                        (qlccFloorList &&
                                            qlccFloorList.length === 0
                                        ) && (
                                            <div
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {
                                                    blockID_ &&
                                                    blockID_ != "" && (
                                                        <h5 style={{ color: '#D8D8D8' }}>Tầng trống</h5>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        !blockID_ &&
                                        (
                                            <div
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <h5>Chọn tòa nhà</h5>
                                            </div>
                                        )
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {
                actCx.open
                && (
                    <ApartmentAction
                        open={actCx.open}
                        onClose={function (): void {
                            setActCx(defaultAction);
                        }}
                        action={actCx.type}
                        itemSelected={actCx.payload}
                        onEditSuccess={function (is_success: boolean): void {
                            onload();
                        }}
                        floorSelect={floorSelect}
                        bLockSelect={bLockSelect}
                    />
                )
            }

        </Grid>
    )
}

export default ApartmentListVrII;