import React from "react";
import SupplyRequestFilterDialog from "./SupplyRequestFilterDialog/SupplyRequestFilterDialog";

import { Content } from "antd/lib/layout/layout";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { components } from "../../../common/ComponentsConfig/components";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import notification from "../../../common/notification/notification";
import DHSToolbar from "../../../components/DHS/DHS-Toolbar/DHSToolbar";
import DHSGridView from "../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { getRole, getRoles } from "../../../redux/actions/RoleActions";
import { AppSession } from "../../shared/app-session/app-session";
import {
  HomeClient,
  HRClient,
  LeaveForm,
  OvertimeForm,
  // PdVchDeliveryTime4PD5,
  // PdVchDeliveryTime4PD5Client,
  RoleRightWebModel,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import SurfaceTreatmentRequestFilterDialog from "./SurfaceTreatementRequestFilterDialog/SurfaceTreatmentRequestFilterDialog";

let appSession: AppSession;

const RequestApproveGrid = () => {
  const [dataTable, setDataTable] = React.useState<any[]>([]);
  // const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
  const { path, url } = useRouteMatch();
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const history = useHistory();

  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const hrClient = new HRClient(appSession, BASE_URL_API);
  // const pdClient = new PdVchDeliveryTime4PD5Client(appSession, BASE_URL_API);
  const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
  const [itemSelected, selectItem] = React.useState<string>();
  const dispatch = useDispatch();
  const [back, setBack] = React.useState<number>(-1);

  const [action, setAction] = React.useState<any>({
    type: "",
    openDialog: false,
    component: "",
  });
  const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
  const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
  const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
  const [isShowSort, setShowSort] = React.useState<boolean>(false);
  const [isShowSelect, setShowSelect] = React.useState<boolean>(false);

  React.useEffect(() => {
    homeClient
      ?.roleRightWebGetAll({
        ...new RoleRightWebModel(),
        rolename: "PD",
        menuid: menu?.menuid,
      } as RoleRightWebModel)
      .then((res) => {
        dispatch(getRoles(res));
        setRoles(res);
      });
  }, []);

  React.useEffect(() => {
    switch (menu?.component) {
      case "ERPSupplyRequest":
        // pdClient
        //   ?.pdVchDeliveryTime4PD5Get({
        //     ...new PdVchDeliveryTime4PD5(),
        //     // ma_cty: SessionLogin.ma_cty,
        //     ma_cty: "001",
        //   } as PdVchDeliveryTime4PD5)
        //   .then((respond) => {
        //     setDataTable(respond);
        //   });
        break;
      case "ERPSurfaceTreatmentRequest":
        hrClient
          ?.hROnLeaveGet({
            ...new LeaveForm(),
            ma_cty: "001",
          } as LeaveForm)
          .then((respond) => {
            setDataTable(respond);
          });
        break;
    }
  }, [menu?.component]);

  const handleClickAction = (action: RoleRightWebModel) => {
    switch (action.class_name?.toLocaleUpperCase()) {
      case "FILTER":
        setAction({
          ...action,
          type: "FILTER",
          openDialog: true,
          component: action.component,
        });
        break;
    }
  };
  const handleExecuteFilter = (data: any) => {};
  return (
    <div>
      {action.component.toUpperCase() === "ERPSUPPLYREQUEST" ? (
        <SupplyRequestFilterDialog
          open={action.openDialog}
          actionType={action.type}
          onClose={() => {
            setAction({ ...action, type: "", openDialog: false });
          }}
          itemClicked={itemSelected}
          onSubmit={handleExecuteFilter}
        />
      ) : action.component.toUpperCase() === "ERPSURFACETREATMENTREQUEST" ? (
        <SurfaceTreatmentRequestFilterDialog
          open={action.openDialog}
          actionType={action.type}
          onClose={() => {
            setAction({ ...action, type: "", openDialog: false });
          }}
          itemClicked={itemSelected}
        />
      ) : (
        <></>
      )}
      <div className="container-fluid p-1">
        <DHSToolbar
          id={itemSelected}
          list={
            roles
              ? roles.filter(
                  (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
                )
              : []
          }
          onClickAction={handleClickAction}
        />
        <div
          className="d-flex title-color"
          style={{
            height: 30,
            color: "rgb(0 78 255 / 88%)",
            marginTop: 15,
            marginBottom: 15,
            fontSize: 22,
            fontWeight: 550,
          }}
        >
          <i
            className="bi bi-arrow-left"
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.go(back);
            }}
          ></i>
          &nbsp;&nbsp;
          {menu?.bar}
        </div>
        <div
          style={{
            paddingLeft: 20,
            color: "#595857",
            width: "100%",
            backgroundColor: "#a3a2a0",
          }}
        >
          Thông tin chi tiết đề nghị
        </div>
        <DHSGridView
          onSelectRow={(row) => {
            selectItem(row);
          }}
          // onListSelectRow={handleListOnselectRow}
          itemClicked={function (): void {
            throw new Error("Function not implemented.");
          }}
          table_name={menu?.component ? menu.component : ""}
          data={dataTable}
          show_form_list={true}
          showFilterPanel={isShowFilter}
          showSortPanel={isShowSort}
          showSearchPanel={isShowSearch}
          showGroupPanel={isShowGroup}
          showSelectBox={isShowSelect}
        />
        <div
          style={{
            paddingLeft: 20,
            color: "#595857",
            width: "100%",
            backgroundColor: "#a3a2a0",
          }}
        >
          Thời gian giao hàng
        </div>
        <DHSGridView
          onSelectRow={(row) => {
            selectItem(row);
          }}
          // onListSelectRow={handleListOnselectRow}
          itemClicked={function (): void {
            throw new Error("Function not implemented.");
          }}
          table_name={menu?.component ? menu.component : ""}
          data={dataTable}
          show_form_list={true}
          showFilterPanel={isShowFilter}
          showSortPanel={isShowSort}
          showSearchPanel={isShowSearch}
          showGroupPanel={isShowGroup}
          showSelectBox={isShowSelect}
        />
      </div>
    </div>
  );
};

export default RequestApproveGrid;
