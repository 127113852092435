import React, { RefObject, useEffect, useState } from "react";

import DataGrid, {
  Column,
  Editing,
  Paging,
  Selection,
  Lookup,
  FilterRow,
  GroupPanel,
  Pager,
  Grouping,
  SearchPanel,
  ColumnChooser,
  Popup,
  RequiredRule,
  Export,
  HeaderFilter,
  FilterPanel,
  Summary,
  TotalItem,
  ColumnFixing,
  Sorting,
  NumericRule,
  Scrolling,
  ToolbarItem,
  Label,
  GroupItem,
  MasterDetail,
} from "devextreme-react/data-grid";

import TagBox from "devextreme-react/tag-box";

import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { useSelector } from "react-redux";
import { Box, Chip } from "@mui/material";
import { IReducer, Notification } from "../../layouts";
import {
  HomeClient,
  ParaSysGenRowTableModel,
  SYS_Token_AuthenticationModel,
  SysGenRowTableModel,
} from "../../../app/shared/service-proxies/api-shared";
import { AppSession } from "../../../app/shared/app-session/app-session";
import BASE_URL_API from "../../../app/shared/service-proxies/config";
import { Cancelable, EventInfo } from "devextreme/events";
import dxDataGrid, {
  CellPreparedEvent,
  DataChangeInfo,
  EditingStartEvent,
  ExportingEvent,
  NewRowInfo,
  RowDblClickEvent,
  RowInsertedInfo,
  RowInsertingInfo,
  RowPreparedEvent,
  RowRemovedInfo,
  RowRemovingInfo,
  RowUpdatedInfo,
  RowUpdatingInfo,
  SavingInfo,
  SelectionChangedInfo,
} from "devextreme/ui/data_grid";
import { httpPost } from "../../../common/httpService";
import { useHotkeys } from "react-hotkeys-hook";
import { SortingState } from "@devexpress/dx-react-grid";
import HtmlEditor, {
  Toolbar,
  MediaResizing,
  ImageUpload,
  Item,
} from "devextreme-react/html-editor";
import NumberBox from "devextreme-react/number-box";
import "./style_datagrid_edit.css";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import CheckBox from "devextreme-react/check-box";
import Validator, { CustomRule } from "devextreme-react/validator";
import TextArea from "devextreme-react/text-area";
import ColorBox from "devextreme-react/color-box";
import Slider from "devextreme-react/slider";
import Switch from "devextreme-react/switch";
import FileUploader from "devextreme-react/file-uploader";
import DataGridSelectBox from "./DataGridSelectBox";
import DHSHtmlEditor from "../../DHS/DHS_HTML_Editor/DHSHtmlEditor";
import Bullet, { Font, Margin, Size, Tooltip } from "devextreme-react/bullet";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import dxDropDownBox from "devextreme/ui/drop_down_box";
import DataGridCustomeDataFilter from "./DataGridCustomeDataFilter";
// import DHSLoadPanel from "../dialogs-and-notifications/DHSLoadPanel/DHSLoadPanel";
import { handleFormulerDataGridEdit } from "./utils/DataGridEditUtils";
import DHSMathUtils from "../../../utils/DHSMathUtils";
// import DataGridNumberBox from "./DataGridNumberBox";

interface IProps {
  className?: string;
  table_name?: string;
  data: any[];
  keyExpr?: string | string[];
  // mode?: string;
  mode?: "popup" | "cell" | "form" | "batch" | undefined;
  dataGridRef: RefObject<DataGrid<any, any>>;
  allowEdit: boolean;
  pageSize?: number;
  groupingAutoExpandAll?: boolean;
  popupFullScreen?: boolean;
  isPagination?: boolean;
  isMultiple?: boolean;
  height?: number;
  defaultFilter?: boolean;
  isParamsMaCtyFilterList?: boolean;
  view_type_report?: string;
  // doubleClickEdit?: boolean;

  handlePageChange?: any;
  handleEditingStar?: ((e: EditingStartEvent<any, any>) => void) | undefined;
  handleInitNewRow?:
    | ((e: EventInfo<dxDataGrid<any, any>> & NewRowInfo<any>) => void)
    | undefined;
    handleRowInserting?:
    | ((e: EventInfo<dxDataGrid<any, any>> & RowInsertingInfo<any>) => void)
    | undefined;
    handleRowInserted?:
    | ((e: EventInfo<dxDataGrid<any, any>> & RowInsertedInfo<any, any>) => void)
    | undefined;
    handleRowUpdating?:
    | ((e: EventInfo<dxDataGrid<any, any>> & RowUpdatingInfo<any, any>) => void)
    | undefined;
    handleRowUpdated?:
    | ((e: EventInfo<dxDataGrid<any, any>> & RowUpdatedInfo<any, any>) => void)
    | undefined;
    handleRowRemoving?:
    | ((e: EventInfo<dxDataGrid<any, any>> & RowRemovingInfo<any, any>) => void)
    | undefined;
    handleRowRemoved?:
    | ((e: EventInfo<dxDataGrid<any, any>> & RowRemovedInfo<any, any>) => void)
    | undefined;
    handleSaving?:
    | ((e: EventInfo<dxDataGrid<any, any>> & SavingInfo<any, any>) => void)
    | undefined;
    handleSaved?:
    | ((e: EventInfo<dxDataGrid<any, any>> & DataChangeInfo<any, any>) => void)
    | undefined;
    handleEditCanceling?:
    | ((
        e: Cancelable &
          EventInfo<dxDataGrid<any, any>> &
          DataChangeInfo<any, any>
      ) => void)
    | undefined;
    handleEditCanceled?:
    | ((e: EventInfo<dxDataGrid<any, any>> & DataChangeInfo<any, any>) => void)
    | undefined;
    handleSelectionChanged?:
    | ((
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
      ) => void)
    | undefined;
    handlePrint?: any;
    handleExporting?: ((e: ExportingEvent<any, any>) => void) | undefined;
    handleRowPrepared?: ((e: RowPreparedEvent<any, any>) => void) | undefined;
    handleCellPrepared?: ((e: CellPreparedEvent<any, any>) => void) | undefined;
  onDoubleClickRow?: ((e: RowDblClickEvent<any, any>) => void) | undefined;
  isMasterDetail?: boolean;
  detailsComponent?: React.ComponentType<any> | undefined;
  dataGenRowTable?: SysGenRowTableModel[];
  menuid?: string;

  onOpenedDataGridFilter?:
    | ((
        isopen: boolean,
        datasource: any[],
        objectEditing?: any
      ) => Promise<any[]>)
    | undefined;

  keysSelected?: string[];
  showControlDataGridEdit?: boolean;
  currency?: string;
  ma_cty?: string;
  showColumnLines?: boolean;
  onCellCustomizeRender?: (cell: any, itemGenRow: SysGenRowTableModel, dataRow: any) => JSX.Element
  // updatedListDataSourceFilterList?: (listDataSource: DataSourceFilterList[]) => void;
  // listDataSource?: DataSourceFilterList[]
}
export type DataSourceFilterList = {
  cloumn_name?: string;
  data: any[];
  ValueMember: string;
  DisplayMember: string;
  TableDesign: string;
};

type ListDataSource = {
  cloumn_name?: string;
  data: any[];
  ValueMember: string;
  DisplayMember: string;
}[];

enum INPUT_TYPE {
  // HTML_EDITOR = "html editor",
  SELECT_BOX = "select box",
  TAGS_BOX = "tag box",
  NUMBER_BOX = "number box",
  CHECK_BOX = "check box",
  HTML_EDITOR = "html editor",
  // SELECT_BOX = "select single",
  // TAGS_BOX = "select multiple",
  // NUMBER_BOX = 'input text number',
  // CHECK_BOX = "input boolean",
  IMAGE = "image",
  TEXT_AREA = "text area",
  COLOR_BOX = "color box",
  SLIDER = "slider",
  DATA_GRID = "data grid",
  SWITCH = "switch",
  CHIP_RENDER = "chip render",
  PERCENT = "percent",
  STATUS = "status",
  UP_DOWN = "up_down",
  UP_DOWN_NUMBER = "up_down_number",
  CHIP_RENDER_STATUS = "chip render status",
  DATA_GRID_CUSTOME_DATASOURCE_FILTER = "data grid custome datasource filter",
  CUSTOME_COLOR_COLUMN_VIEW = "custome_color_column_view",
  LINK = "link",
  CALCULATE = "calculate",
  FORMULE = "formule",
  AMOUNT_CURRENCY = "amount_currency",
  CELL_CUSTOMIZE = "cell_customize",
}

const dataFixedPosition = ["left", "right"];

const SysLanguages: { [key: string]: string } = {
  "vi-VN": "name_vn",
  "en-US": "name_en",
};

enum CELL_STATUS_TYPE {
  // HTML_EDITOR = "html editor",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  INFO = "info",
}

let keyExpend: string | undefined = undefined;

let appSession: AppSession;
const DHSDataGridEdit = (props: IProps) => {
  // const { selectedItemRowIndexs = [] } = props;
  // const languauge = useSelector(
  //   (state: IReducer) => state.SettingReducer.language
  // );
  const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const sessionLogin: SYS_Token_AuthenticationModel = JSON.parse(
    localStorage.getItem("SessionLogin") || "{}"
  );
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const [columns, setColumns] = useState<SysGenRowTableModel[]>([]);
  const [columnsFormList, setColumnsFormList] = useState<SysGenRowTableModel[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [keysSelected, setKeysSelected] = useState<string[]>([]);

  // const dataGridRef = useRef<DataGrid>(null);
  const [dataSource, setDataSource] = useState<DataSource>(
    new DataSource({
      store: new ArrayStore({
        data: [],
        key: props.keyExpr,
      }),
    })
  );
  const [listDataSource, setListDataSource] = useState<DataSourceFilterList[]>(
    []
  );

  // const [allowEdit, setAllowEdit] = useState<boolean>(props.allowEdit || false)
  const [isHiddenGroup, setIsHiddenGroup] = React.useState<boolean>(false);
  const [isHiddenSearch, setIsHiddenSearch] = React.useState<boolean>(false);
  const [isHiddenFilter, setIsHiddenFilter] = React.useState<boolean>(
    props.defaultFilter || false
  );
  const [isFullSizePopup, setIsFullSizePopup] = useState<boolean>(
    menu?.maximized || false
  );
  const [isHiddenExportData, setIsHiddenExportData] =
    React.useState<boolean>(false);
  const [isHiddenAdvantageFilter, setIsHiddenAdvantageFilter] =
    React.useState<boolean>(false);
  const [isHiddenColumnChooser, setIsHiddenColumnChooser] =
    React.useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (props.table_name && !props.dataGenRowTable) {
        try {
          // setLoading(true);
          const res = await homeClient.sysGenRowTable({
            ...new ParaSysGenRowTableModel(),
            table_name: props.table_name,
            view_type_report: props.view_type_report,
            menuid: props.menuid,
          } as unknown as ParaSysGenRowTableModel);
          // const temp = res.map((item) => {
          //   return {
          //     ...item,
          //     column_name: item.column_name?.toLowerCase(),
          //   };
          // });
          setColumnsFormList([...res.filter((item) => item.show_form_list)]);
          setColumns([...res]);
        } catch (error) {
          setColumns([]);
        } finally {
          // setLoading(false);
        }
      }
    })();
  }, [props.table_name, props.view_type_report]);

  useEffect(() => {
    if (props.dataGenRowTable) {
      setColumnsFormList([
        ...props.dataGenRowTable.filter((item) => item.show_form_list),
      ]);
      setColumns([...props.dataGenRowTable]);
    } else {
      setColumns([]);
    }
  }, [props.dataGenRowTable]);

  useEffect(() => {
    if (columns.length > 0 && props.data && props.data.length >= 0) {
      setDataSource(
        new DataSource({
          store: new ArrayStore({
            data: [...props.data],
            key: props.keyExpr,
          }),
        })
      );
    }
  }, [columns, props.data]);

  useEffect(() => {
    (async () => {
      if (columns.length > 0) {
        // setLoading(true);
        const temp: string[] = [];

        const promises = columns
          .filter((item) => item.is_datasource)
          .map((item, index) => {
            temp.push(item.column_name as string);
            return httpPost<string>("api/Common/LoadDatasourceBindControlByApartmentBuilding", {
              ma_cty: sessionLogin.ma_cty,
              tablelist: item.table_list,
              apartment_building_id: apartment_building_id
            });
          });

        const result = await Promise.all(promises);

        const values = result.map((item: any, index) => {
          const data = JSON.parse(item.data);

          return {
            cloumn_name: temp[index],
            data: data.Table1,
            ValueMember: data.Table[0].ValueMember,
            DisplayMember: data.Table[0].DisplayMember,
            TableDesign: data.Table[0].TableDesign,
          } as DataSourceFilterList;
        });

        setListDataSource([...values]);
        // setLoading(false);

        // if(props.updatedListDataSourceFilterList) {
        //   props.updatedListDataSourceFilterList([...values])
        // }
      }
    })();
  }, [columns]);

  const handleContextMenuItems = (items: any) => {
    console.log(items);

    if (!items) {
      return;
    }

    items.forEach((item: any) => {
      if (item.items) {
        // Xử lý mục menu lồng nhau
        handleContextMenuItems(item.items);
      }

      switch (item.text) {
        case "Sort Ascending":
          item.text = "Sắp xếp tăng dần";
          break;
        case "Sort Descending":
          item.text = "Sắp xếp giảm dần";
          break;
        case "Clear Sorting":
          item.text = "Hủy sắp xếp";
          break;
        case "Group by This Column":
          item.text = "Group cột này";
          break;
        case "Ungroup All":
          item.text = "Hủy bỏ group tất cả";
          break;
        case "Fix":
          item.text = "Cố định";
          break;
        case "To the left":
          item.text = "Từ trái sang";
          break;
        case "To the right":
          item.text = "Từ phải sang";
          break;
        case "Unfix":
          item.text = "Không cố định";
          break;
      }
    });
  };

  const handleContextMenuPreparing = (e: any) => {
    const { row, column } = e;

    // Xác định loại ContextMenu (dành cho hàng hoặc cột)
    if (row) {
      // ContextMenu cho hàng
      const rowData = row.data;
      // Tùy chỉnh ContextMenu cho hàng dựa trên rowData
      e.items?.push(
        {
          text: isHiddenGroup ? "Ẩn nhóm ( alt + 1 )" : "Hiện nhóm ( alt + 1 )",
          onItemClick: () => setIsHiddenGroup((pre) => !pre),
          beginGroup: true,
        },
        {
          text: isHiddenSearch
            ? "Ẩn tìm kiếm ( alt + 2 )"
            : "Hiện tìm kiếm ( alt + 2 )",
          onItemClick: () => setIsHiddenSearch((pre) => !pre),
        },
        {
          text: isHiddenFilter ? "Ẩn lọc ( alt + 3 )" : "Hiện lọc ( alt + 3 )",
          onItemClick: () => setIsHiddenFilter((pre) => !pre),
        },
        {
          text: isHiddenAdvantageFilter
            ? "Ẩn lọc nâng cao ( alt + 4 )"
            : "Hiện lọc nâng cao ( alt + 4 )",
          onItemClick: () => setIsHiddenAdvantageFilter((pre) => !pre),
        },
        {
          text: isHiddenColumnChooser
            ? "Ẩn ẩn cột ( alt + 5 )"
            : "Hiện ẩn cột ( alt + 5 )",
          onItemClick: () => setIsHiddenColumnChooser((pre) => !pre),
        },
        {
          text: isHiddenExportData ? "Ẩn xuất dữ liệu" : "Hiện xuất dữ liệu",
          onItemClick: () => setIsHiddenExportData((pre) => !pre),
        },
        {
          text: "Hủy lọc",
          onItemClick: () => props.dataGridRef.current?.instance.clearFilter(),
          beginGroup: true,
        },
        {
          text: "Hủy nhóm",
          onItemClick: () =>
            props.dataGridRef.current?.instance.clearGrouping(),
        },
        {
          text: "Hủy chọn",
          onItemClick: () =>
            props.dataGridRef.current?.instance.clearSelection(),
        }
        // Các mục menu khác cho hàng
      );
    } else if (column) {
      // ContextMenu cho cột
      const columnData = column.dataField;
      // Tùy chỉnh ContextMenu cho cột dựa trên columnData
      e.items.push(
        {
          text: isHiddenGroup ? "Ẩn group" : "Hiện group",
          onItemClick: () => setIsHiddenGroup((pre) => !pre),
        }
        // Các mục menu khác cho cột
      );
    }

    // Thay đổi văn bản của các mục menu trong e.items
    handleContextMenuItems(e.items);
  };

  //Phim tắt
  useHotkeys<HTMLParagraphElement>("alt+2", () =>
    setIsHiddenSearch((pre) => !pre)
  );
  useHotkeys<HTMLParagraphElement>("alt+1", () =>
    setIsHiddenGroup((pre) => !pre)
  );

  useHotkeys<HTMLParagraphElement>("alt+3", () =>
    setIsHiddenFilter((pre) => !pre)
  );
  useHotkeys<HTMLParagraphElement>("alt+4", () =>
    setIsHiddenAdvantageFilter((pre) => !pre)
  );
  useHotkeys<HTMLParagraphElement>("alt+5", () =>
    setIsHiddenColumnChooser((pre) => !pre)
  );

  useHotkeys<HTMLParagraphElement>("Esc", () => {
    props.dataGridRef.current?.instance.cancelEditData();
  });

  const handleDoubleClickRow = (e: RowDblClickEvent<any, any>) => {
    if (props.onDoubleClickRow) {
      props.onDoubleClickRow(e);
    }

    // if (props.isViewDataRowPopup && props.dataGridRef.current) {
    //   props.dataGridRef.current.instance
    //     .getSelectedRowKeys()
    //     .then((keys) => {
    //       const index = props.dataGridRef.current?.instance.getRowIndexByKey(
    //         keys[0]
    //       );
    //       const indexSelected = index !== undefined && index >= 0 ? index : -1;
    //       if (props.dataGridRef.current && indexSelected >= 0) {
    //         props.dataGridRef.current.instance.editRow(indexSelected);
    //         // setAllowEdit(true);
    //       }
    //     })
    //     .catch((e) => {});
    // }
  };

  const handlePageChange = () => {
    if (props.handlePageChange) {
      props.handlePageChange();
    }
  };
  const handleSelectionChanged = (
    e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
  ) => {
    if (props.handleSelectionChanged) {
      props.handleSelectionChanged(e);
    }
    if (props.isMasterDetail) {
      selectionChanged(e);
    }
  };

  const selectionChanged = async (
    e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
  ) => {
    const keys = await e.component.getSelectedRowKeys();
    if (keyExpend === keys[0]) {
      e.component.collapseAll(-1);
      keyExpend = undefined;
    } else {
      keyExpend = keys[0];
      e.component.collapseAll(-1);
      e.component.expandRow(keys[0]);
    }
  };

  useEffect(() => {
    if (props.keysSelected && props.data && dataSource && props.keyExpr) {
      let temp: string[] = props.keysSelected.filter((item) => {
        if (typeof props.keyExpr === "string") {
          const temp: string = props.keyExpr;
          return props.data.find((item2) => item === item2[temp || "id"]);
        }
      });

      console.log(temp);

      if (temp.length > 0) {
        setKeysSelected([...temp]);
      }
    }
  }, [props.keysSelected, props.data, dataSource]);

  return (
    <div style={{}}>
      <DataGrid
        id={"gridContainer"}
        className={props?.className}
        ref={props.dataGridRef}
        dataSource={dataSource}
        keyExpr={props.keyExpr}
        allowColumnReordering={true}
        repaintChangesOnly
        showBorders={true}
        hoverStateEnabled={true}
        allowColumnResizing={true}
        onExporting={props.handleExporting}
        rowAlternationEnabled={true}
        columnResizingMode="widget"
        onSelectionChanged={handleSelectionChanged}
        onEditingStart={props.handleEditingStar}
        onInitNewRow={props.handleInitNewRow}
        onRowInserting={props.handleRowInserting}
        onRowInserted={props.handleRowInserted}
        onRowUpdating={props.handleRowUpdating}
        onRowUpdated={props.handleRowUpdated}
        onRowRemoving={props.handleRowRemoving}
        onRowRemoved={props.handleRowRemoved}
        onRowDblClick={handleDoubleClickRow}
        onSaving={props.handleSaving}
        onSaved={props.handleSaved}
        onEditCanceling={props.handleEditCanceling}
        onEditCanceled={props.handleEditCanceled}
        height={props.height ? `${props.height}vh` : "80vh"}
        showRowLines={true}
        showColumnLines={props.showColumnLines === false ? false : true}
        onContextMenuPreparing={handleContextMenuPreparing}
        onEditorPreparing={(e) => {}}
        selectedRowKeys={keysSelected}
        defaultSelectedRowKeys={[]}
        onRowPrepared={(e) => {
          if (props.handleRowPrepared) {
            props.handleRowPrepared(e);
          }

          let result: boolean = false;
          if (e.data && e.data["bold"]) {
            // result = e.data["bold"] === e.data["bold"] ? true : false;
            switch (e.data["bold"]) {
              case "1":
                result = true;
                break;
              case 1:
                result = true;
                break;
              case true:
                result = true;
                break;
              default:
                result = false;
                break;
            }
          }

          // e.rowElement.style.backgroundColor ='red'

          if (e.data && e.data["color_rowstyle_grid"]) {
            e.rowElement.style.color = e.data["color_rowstyle_grid"];
          }

          // if(e.data && e.data['back_color_rowstyle_grid']) {
          //   e.rowElement.style.backgroundColor = e.data['back_color_rowstyle_grid'];
          // }

          if (result) {
            e.rowElement.classList.add("bold-row-datagrid");
          }

          // let result: boolean = false;
          // columns.length > 0 &&
          //   columns.map((item) => {
          //     if (
          //       item.condition_column_name &&
          //       item.condition_logic &&
          //       item.condition_value &&
          //       e.data
          //     ) {
          //       try {
          //         if (typeof e.data[item.condition_column_name] === "number") {
          //           const value = parseInt(item.condition_value);
          //           result = eval(
          //             `${e.data[item.condition_column_name]} ${
          //               item.condition_logic
          //             } ${value}`
          //           );
          //         } else {
          //           result = eval(
          //             `'${e.data[item.condition_column_name]}' ${
          //               item.condition_logic
          //             } '${item.condition_value}'`
          //           );
          //         }
          //       } catch (error) {}
          //     }
          //   });

          if (result) {
            e.rowElement.classList.add("backgound-color-row-datagrid");
          }
        }}
        onCellPrepared={(e) => {
          if (props.handleCellPrepared) {
            props.handleCellPrepared(e);
          }

          //   if (e.rowType === "data"){
          //     e.cellElement.className += 'backgound-color-row-datagrid'
          // }
        }}
      >
        <Selection
          mode={props.isMultiple ? "multiple" : "single"}
          deferred={true}
          // deferred={props.isMultiple ? false : true}
          // allowSelectAll={props.isMultiple ? true : false}
        />
        <Grouping autoExpandAll={props.groupingAutoExpandAll || false} />
        <GroupPanel
          visible={isHiddenGroup}
          emptyPanelText={"Kéo thả cột muốn group vào đây"}
        />

        <ColumnFixing enabled={true} />

        <SearchPanel visible={isHiddenSearch} />
        <ColumnChooser
          enabled={isHiddenColumnChooser}
          emptyPanelText={"Kéo và thả cột muốn ẩn vào đây"}
          title={"Chọn cột ẩn"}
        />

        <Editing
          mode={props.mode}
          allowUpdating={
            props.mode && props.mode !== "popup" && props.allowEdit
              ? true
              : false
          }
          allowDeleting={
            props.showControlDataGridEdit &&
            props.mode &&
            props.mode !== "popup" &&
            props.allowEdit
              ? true
              : false
          }
          allowAdding={
            props.showControlDataGridEdit &&
            props.mode &&
            props.mode !== "popup" &&
            props.allowEdit
              ? true
              : false
          }
          // allowAdding={false}
          useIcons={true}
        >
          <Popup
            title={menu?.bar}
            showTitle={true}
            // fullScreen={isFullSizePopup}
            fullScreen={menu?.maximized}
            resizeEnabled={true}
            // toolbarItems={[
            //   {
            //     location: "after",
            //     widget: "dxButton",
            //     showText: "always",
            //     render: "",
            //     options: {
            //       icon: "fullscreen",
            //     },
            //     onClick: () => {
            //       setIsFullSizePopup(!isFullSizePopup)
            //     }
            //   }
            // ]}
            // toolbarItems={[
            //   {
            //     location: "after",
            //     widget: "dxButton",
            //     toolbar: "bottom",
            //     showText: "always",
            //     cssClass: "toolbar-bottom-action",
            //     render: "",
            //     options: {
            //       text: "Lưu",
            //     },
            //     onClick: () => {
            //       // props.dataGridRef.current?.instance.;
            //     }
            //   },
            //   {
            //     location: "after",
            //     widget: "dxButton",
            //     toolbar: "bottom",
            //     options: {
            //       text: "Quay lại",
            //     },
            //     onClick: () => {
            //       props.dataGridRef.current?.instance.cancelEditData();
            //     }
            //   },
            // ]}
          ></Popup>
        </Editing>
        <FilterRow visible={isHiddenFilter} />
        <HeaderFilter visible={true} allowSearch={true} />

        {props.isPagination && (
          <Pager
            visible={props.isPagination}
            displayMode={false}
            showPageSizeSelector={true}
            showInfo={true}
            allowedPageSizes={true}
            showNavigationButtons={true}
            // infoText={'Dòng mỗi trang'}
          />
        )}
        {props.isPagination && (
          <Paging
            defaultPageSize={props.pageSize || 40}
            onPageSizeChange={handlePageChange}
            onPageIndexChange={handlePageChange}
          />
        )}

        {!props.isPagination && <Scrolling mode="virtual" />}
        <Export enabled={isHiddenExportData} allowExportSelectedData={true} />
        <FilterPanel visible={isHiddenAdvantageFilter} />

        {columnsFormList.length > 0 ? (
          // eslint-disable-next-line array-callback-return
          columnsFormList.map((item, index) => {
            if (item.is_datasource) {
              switch (item.input_type) {
                case INPUT_TYPE.SELECT_BOX:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      minWidth={item.width}
                      // allowResizing={true}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      alignment={item.align}
                      // calculateDisplayValue={(dataRow: any) => {
                      //   return dataRow[listDataSource.find(
                      //     (i) => i.cloumn_name === item.column_name
                      //   )?.DisplayMember || '']
                      // }}
                      formItem={{ visible: item.show_form_edit || false }}
                      cssClass={item.is_bold ? "bold-row-datagrid" : ""}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                      <Lookup
                        dataSource={
                          listDataSource.find(
                            (i) => i.cloumn_name === item.column_name
                          )?.data
                        }
                        allowClearing={true}
                        valueExpr={
                          listDataSource.find(
                            (i) => i.cloumn_name === item.column_name
                          )?.ValueMember
                        }
                        displayExpr={
                          listDataSource.find(
                            (i) => i.cloumn_name === item.column_name
                          )?.DisplayMember
                        }
                      />
                    </Column>
                  );
                  break;
                case INPUT_TYPE.TAGS_BOX:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      // calculateDisplayValue={(dataRow: any) => {
                      //   return dataRow[listDataSource.find(
                      //     (i) => i.cloumn_name === item.column_name
                      //   )?.DisplayMember || '']
                      // }}
                      cssClass={item.is_bold ? "bold-row-datagrid" : ""}
                      formItem={{ visible: item.show_form_edit || false }}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      editCellComponent={(event) => (
                        <TagBox
                          dataSource={
                            listDataSource.find(
                              (i) => i.cloumn_name === item.column_name
                            )?.data
                          }
                          valueExpr={
                            listDataSource.find(
                              (i) => i.cloumn_name === item.column_name
                            )?.ValueMember
                          }
                          displayExpr={
                            listDataSource.find(
                              (i) => i.cloumn_name === item.column_name
                            )?.DisplayMember
                          }
                          disabled={item.is_readonly ? true : !props.allowEdit}
                          showSelectionControls={true}
                          maxDisplayedTags={10}
                          showMultiTagOnly={false}
                          applyValueMode="useButtons"
                          searchEnabled={true}
                          onValueChanged={(e) => {
                            event.data.setValue(e.value.join(";"));
                          }}
                          defaultValue={
                            event.data.value ? event.data.value.split(";") : []
                          }
                          onSelectionChanged={() =>
                            event.data.component.updateDimensions()
                          }
                        />
                      )}
                      cellRender={(cell) => {
                        // debugger
                        return (
                          <div>
                            {cell.value ? (
                              cell.value
                                .split(";")
                                .map((item: any, index: any) => (
                                  <Chip
                                    style={{
                                      backgroundColor: "#2590C3",
                                      color: "#fff",
                                    }}
                                    size="small"
                                    label={item}
                                    key={index}
                                  />
                                ))
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      }}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.DATA_GRID:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      // calculateDisplayValue={(dataRow: any) => {
                      //   return dataRow['name_customer']
                      // }}
                      cssClass={item.is_bold ? "bold-row-datagrid" : ""}
                      formItem={{ visible: item.show_form_edit || false }}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      editCellComponent={(event) =>
                        DataGridSelectBox(
                          event,
                          listDataSource.find(
                            (i) => i.cloumn_name === item.column_name
                          )?.ValueMember,
                          listDataSource.find(
                            (i) => i.cloumn_name === item.column_name
                          )?.DisplayMember,
                          listDataSource.find(
                            (i) => i.cloumn_name === item.column_name
                          )?.TableDesign,
                          item.is_readonly,
                          props.allowEdit
                        )
                      }
                    >
                      <Lookup
                        dataSource={
                          listDataSource.find(
                            (i) => i.cloumn_name === item.column_name
                          )?.data
                        }
                        allowClearing={true}
                        valueExpr={
                          listDataSource.find(
                            (i) => i.cloumn_name === item.column_name
                          )?.ValueMember
                        }
                        displayExpr={
                          listDataSource.find(
                            (i) => i.cloumn_name === item.column_name
                          )?.DisplayMember
                        }
                      />
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.DATA_GRID_CUSTOME_DATASOURCE_FILTER:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      // calculateDisplayValue={(dataRow: any) => {
                      //   return dataRow['name_customer']
                      // }}
                      cssClass={item.is_bold ? "bold-row-datagrid" : ""}
                      formItem={{ visible: item.show_form_edit || false }}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      editCellComponent={(event) => {
                        console.log("==========");

                        // return <DataGridCustomeDataFilter  />

                        return DataGridCustomeDataFilter(
                          event,
                          listDataSource.find(
                            (i) => i.cloumn_name === item.column_name
                          )?.ValueMember,
                          listDataSource.find(
                            (i) => i.cloumn_name === item.column_name
                          )?.DisplayMember,
                          listDataSource.find(
                            (i) => i.cloumn_name === item.column_name
                          )?.TableDesign,
                          item.is_readonly,
                          props.allowEdit,
                          props.onOpenedDataGridFilter
                        );
                      }}
                    >
                      <Lookup
                        dataSource={
                          listDataSource.find(
                            (i) => i.cloumn_name === item.column_name
                          )?.data
                        }
                        allowClearing={true}
                        valueExpr={
                          listDataSource.find(
                            (i) => i.cloumn_name === item.column_name
                          )?.ValueMember
                        }
                        displayExpr={
                          listDataSource.find(
                            (i) => i.cloumn_name === item.column_name
                          )?.DisplayMember
                        }
                      />
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                default:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      minWidth={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      formItem={{ visible: item.show_form_edit || false }}
                      cssClass={"bold-row-datagrid"}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
              }
            } else {
              switch (item.input_type) {
                case INPUT_TYPE.CHIP_RENDER:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      formItem={{ visible: item.show_form_edit || false }}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      cellRender={(cell) => {
                        return (
                          <div style={{}}>
                            {cell.value || cell.value == 0 ? (
                              <span
                                style={{
                                  backgroundColor:
                                    item.background_color_web || "#2590C3",
                                  color: item.font_color_web || "#fff",
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  paddingTop: 0,
                                  paddingBottom: 2,
                                  borderRadius: 20,
                                  lineHeight: 0,
                                  fontWeight: item.is_bold ? 'bold' : undefined,
                                }}
                              >
                                {cell.value}
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      }}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.NUMBER_BOX:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={{
                        type: item.format_type || "#,##0.###",
                        // type: "#,##0.###",
                        precision: item.precision || undefined,
                      }}
                      alignment={item.align}
                      formItem={{ visible: item.show_form_edit || false }}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      editCellComponent={(e) => {
                        return (
                          <NumberBox
                            readOnly={
                              item.is_readonly ? true : !props.allowEdit
                            }
                            // value={e.data.value}
                            defaultValue={e.data.value}
                            // format="#,###"
                            format={{
                              type: item.format_type || "#,##0.###",
                              // type: 'decimal',
                              // precision: item.precision || 0,
                            }}
                            onValueChange={(value) => {
                              e.data.setValue(value);
                            }}
                          />
                        );
                      }}
                      cssClass={item.is_bold ? "bold-row-datagrid" : ""}
                      // editCellComponent={(e) => {
                      //   return (
                      //     <DataGridNumberBox e={e} allowEdit={props.allowEdit} item={item} />
                      //   )
                      // }}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                      {item.condition_logic &&
                        item.condition_logic !== "" &&
                        props.allowEdit && (
                          <CustomRule
                            message={item.requireinputtext}
                            validationCallback={(params: any) => {
                              try {
                                let tempResult: boolean = true;

                                if (typeof params.value === "number") {
                                  tempResult = eval(
                                    `${params.value} ${item.condition_logic}`
                                  );
                                } else if (typeof params.value === "string") {
                                  tempResult = eval(
                                    `'${params.value}' ${item.condition_logic}`
                                  );
                                }

                                return tempResult;
                              } catch (error) {
                                return true;
                              }
                            }}
                          />
                        )}

                      {item.condition_column_name &&
                        item.condition_column_name !== "" &&
                        props.allowEdit && (
                          <CustomRule
                            message={item.requireinputtext}
                            validationCallback={(params: any) => {
                              try {
                                let tempResult: boolean = true;
                                const object: any = params.data;
                                const value: any = params.value;
                                if (typeof value === "number") {
                                  tempResult = eval(
                                    `${value} ${item.condition_column_name}`
                                  );
                                } else if (typeof value === "string") {
                                  tempResult = eval(
                                    `'${value}' ${item.condition_column_name}`
                                  );
                                }

                                return tempResult;
                              } catch (error) {
                                return true;
                              }
                            }}
                          />
                        )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.CHECK_BOX:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      formItem={{ visible: item.show_form_edit || false }}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      // editCellComponent={(event) => (
                      //   <CheckBox
                      //     readOnly={item.is_readonly ? true : !props.allowEdit}
                      //     defaultValue={event.data.value}
                      //     onValueChange={(value) => {
                      //       event.data.setValue(value);

                      //       console.log(item.requireinput);
                      //       console.log(event.data.value === undefined);
                      //     }}
                      //     // isValid={false}
                      //     validationError={{
                      //       message: item.requireinputtext || "",
                      //     }}
                      //     validationStatus={
                      //       item.requireinput && event.data.value === undefined
                      //         ? "invalid"
                      //         : "valid"
                      //     }
                      //     // className={item.requireinput ? "require_check" : ''}
                      //   />
                      // )}
                      // cellRender={(cell) => {
                      //   return (
                      //     <CheckBox readOnly={true} defaultValue={cell.value} />
                      //   );
                      // }}
                    >
                      {item.requireinput && (
                        <RequiredRule
                          message={item.requireinputtext}
                        ></RequiredRule>
                      )}
                      {/* {item.requireinput && (
                        <CustomRule
                          type="custom"
                          message={item.requireinputtext}
                          validationCallback={(e: { value: undefined }) => {
                            return e.value !== undefined;
                            // return false;
                          }}
                        />
                      )} */}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.SWITCH:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      formItem={{ visible: item.show_form_edit || false }}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      editCellComponent={(e) => {
                        return (
                          // <NumberBox
                          //   readOnly={
                          //     item.is_readonly ? true : !props.allowEdit
                          //   }
                          //   // value={e.data.value}
                          //   defaultValue={e.data.value}
                          //   format="#,###"
                          //   onValueChange={(value) => {
                          //     e.data.setValue(value);
                          //   }}
                          // />
                          <Switch
                            // value={this.state.value}
                            defaultValue={e.data.value || false}
                            onValueChanged={(event) => {
                              e.data.setValue(event.value);
                            }}
                            readOnly={
                              item.is_readonly ? true : !props.allowEdit
                            }
                          />
                        );
                      }}
                      cellRender={(cell) => {
                        // debugger
                        return (
                          <div>
                            <Switch
                              defaultValue={cell.value || false}
                              readOnly={true}
                            />
                          </div>
                        );
                      }}
                    >
                      {/* {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )} */}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.TEXT_AREA:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      // cssClass={}
                      formItem={{ visible: item.show_form_edit || false }}
                      editCellComponent={(event) => (
                        <TextArea
                          readOnly={item.is_readonly ? true : !props.allowEdit}
                          defaultValue={event.data.value}
                          onValueChange={(value) => {
                            event.data.setValue(value);
                          }}
                        />
                      )}
                      cssClass={item.is_bold ? "bold-row-datagrid" : ""}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.COLOR_BOX:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      // cssClass={}
                      formItem={{ visible: item.show_form_edit || false }}
                      editCellComponent={(event) => (
                        <ColorBox
                          readOnly={item.is_readonly ? true : !props.allowEdit}
                          defaultValue={event.data.value}
                          onValueChange={(value) => {
                            event.data.setValue(value);
                          }}
                        />
                      )}
                      cssClass={item.is_bold ? "bold-row-datagrid" : ""}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.SLIDER:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      // cssClass={}
                      formItem={{ visible: item.show_form_edit || false }}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      editCellComponent={(event) => (
                        <Slider
                          readOnly={item.is_readonly ? true : !props.allowEdit}
                          defaultValue={event.data.value}
                          onValueChange={(value) => {
                            event.data.setValue(value);
                          }}
                          tooltip={{
                            enabled: true,
                            position: "top",
                            showMode: "onHover",
                          }}
                          min={1}
                          // max={1000}
                        />
                      )}
                      cssClass={item.is_bold ? "bold-row-datagrid" : ""}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.HTML_EDITOR:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      // cssClass={}
                      editCellComponent={(event) => (
                        <DHSHtmlEditor
                          readonly={item.is_readonly ? true : !props.allowEdit}
                          defaultValue={event.data.value}
                          onchangeHTMLValue={(e) => {
                            event.data.setValue(e.value);
                            // if( event.data.setValue) {
                            //   event.data.setValue(e);
                            // }
                          }}
                        />
                      )}
                      cssClass={item.is_bold ? "bold-row-datagrid" : ""}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.PERCENT:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={"number"}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={"percent"}
                      alignment={"right"}
                      formItem={{ visible: item.show_form_edit || false }}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      cssClass="bullet"
                      cellRender={(cell) => {
                        return (
                          <div>
                            {cell.value ? (
                              <Bullet
                                showTarget={false}
                                showZeroLevel={true}
                                value={cell.value * 100}
                                startScaleValue={0}
                                endScaleValue={100}
                              >
                                <Size width={150} height={35} />
                                <Margin top={5} bottom={0} left={5} />
                                <Tooltip
                                  enabled={true}
                                  paddingTopBottom={2}
                                  zIndex={5}
                                  customizeTooltip={(data: any) => {
                                    return {
                                      text: `${parseInt(data.value, 10)}%`,
                                    };
                                  }}
                                >
                                  <Font size={18} />
                                </Tooltip>
                              </Bullet>
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      }}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.IMAGE:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={"string"}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      alignment={"right"}
                      formItem={{ visible: item.show_form_edit || false }}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      cellRender={(cell) => {
                        return (
                          <div>
                            {cell.value ? (
                              <div style={{ display: "flex" }}>
                                {cell.value
                                  .split(";")
                                  .filter((i: string) => i !== "")
                                  .map((item_image: string) => (
                                    <div
                                      className="dx-tile-image"
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        display: "block",
                                        backgroundImage: `url("${BASE_URL_API}${item_image}")`,
                                        backgroundSize: "contain",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                      }}
                                    ></div>
                                  ))}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      }}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.STATUS:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={"string"}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      alignment={"center"}
                      formItem={{ visible: item.show_form_edit || false }}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      cellRender={(cell) => {
                        return (
                          <div>
                            {cell.value ? (
                              <div>
                                {cell.value === CELL_STATUS_TYPE.SUCCESS ? (
                                  <CheckCircleIcon color={"success"} />
                                ) : // <div style={{display: 'flex', justifyContent: 'center'}}>
                                //   <ArrowUpwardIcon color={"success"} />
                                //   <p>{cell.value}</p>
                                // </div>

                                cell.value === CELL_STATUS_TYPE.WARNING ? (
                                  <ErrorIcon color={"warning"} />
                                ) : cell.value === CELL_STATUS_TYPE.ERROR ? (
                                  <CancelIcon color={"error"} />
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      }}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.UP_DOWN:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={"string"}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      alignment={"center"}
                      formItem={{ visible: item.show_form_edit || false }}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      cellRender={(cell) => {
                        return (
                          <div>
                            {cell.value ? (
                              <div>
                                {cell.value === "up" ? (
                                  <ArrowUpwardIcon color={"success"} />
                                ) : // <div style={{display: 'flex', justifyContent: 'center'}}>
                                //   <ArrowUpwardIcon color={"success"} />
                                //   <p>{cell.value}</p>
                                // </div>

                                cell.value === "down" ? (
                                  <ArrowDownwardIcon color={"error"} />
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      }}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.UP_DOWN_NUMBER:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={"string"}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      alignment={"center"}
                      formItem={{ visible: item.show_form_edit || false }}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      cellRender={(cell) => {
                        let arr: any = [];
                        if (cell.value) {
                          arr = [...cell.value.split("_")];
                        }

                        return (
                          <div>
                            {arr[0] && arr[1] ? (
                              <div>
                                {arr[0] === "up" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <span>{arr[1]}</span>
                                    <ArrowUpwardIcon color={"success"} />
                                  </div>
                                ) : arr[0] === "down" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <span>{arr[1]}</span>
                                    <ArrowDownwardIcon color={"error"} />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      }}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.CHIP_RENDER_STATUS:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      formItem={{ visible: item.show_form_edit || false }}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      cellRender={(cell) => {
                        return (
                          <div style={{}}>
                            {cell.value ? (
                              <span
                                style={{
                                  backgroundColor:
                                    cell.data["status_backgroup_code"],
                                  color: cell.data["status_color_code"],
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  paddingTop: 0,
                                  paddingBottom: 1,
                                  borderRadius: 20,
                                  lineHeight: 0,
                                }}
                              >
                                {cell.value}
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      }}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.CUSTOME_COLOR_COLUMN_VIEW:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      formItem={{ visible: item.show_form_edit || false }}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      cssClass={"cell_reset_pm"} //note
                      cellRender={(cell) => {
                        return (
                          <div
                            style={{
                              backgroundColor: item.background_color_web,
                              width: "100%",
                              height: "33px",
                              padding: "7px",
                            }}
                          >
                            {cell.value ? (
                              <span style={{ color: item.font_color_web }}>
                                {cell.value}
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      }}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.LINK:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      formItem={{ visible: item.show_form_edit || false }}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      cellRender={(cell) => {
                        return (
                          <div style={{}}>
                            {cell.value ? (
                              <a href={cell.value} target="_blank">
                                {cell.value}
                              </a>
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      }}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.CALCULATE:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      minWidth={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      // formItem={{ visible: item.show_form_edit || false }}
                      cssClass={item.is_bold ? "bold-row-datagrid" : ""}
                      // customizeText={custometext}
                      // calculateCellValue={} first name + lastname = fullname
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      calculateCellValue={(object: any) => {
                        if (
                          item.condition_value &&
                          item.condition_value !== ""
                        ) {
                          try {
                            let tempResult: any;
                            tempResult = eval(`${item.condition_value}`);
                            return tempResult;
                          } catch (error) {}
                        }
                      }}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.FORMULE:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      minWidth={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={{
                        type: item.data_type === 'number' ? '#,##0.###' : undefined
                      }}
                      alignment={item.align}
                      // formItem={{ visible: item.show_form_edit || false }}
                      cssClass={item.is_bold ? "bold-row-datagrid" : ""}
                      // customizeText={custometext}
                      // calculateCellValue={} first name + lastname = fullname
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      setCellValue={(newData: any, value: any, object: any) => {
                        handleFormulerDataGridEdit(
                          item,
                          newData,
                          value,
                          object,
                          props.currency,
                          props.ma_cty
                        );
                      }}
                      // editCellComponent={(event) => {
                      //   return (
                      //     <NumberBox
                      //       readOnly={
                      //         item.is_readonly ? true : !props.allowEdit
                      //       }
                      //       format={{
                      //         type: 'fixedPoint',
                      //         precision: 4
                      //       }}
                      //       defaultValue={event.data.value}
                      //       onValueChange={(value) => {
                      //         event.data.setValue(value);
                      //       }}
                      //     />
                      //   );
                      // }}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}

                      {item.condition_logic &&
                        item.condition_logic !== "" &&
                        props.allowEdit && (
                          <CustomRule
                            message={item.requireinputtext}
                            validationCallback={(params: any) => {
                              try {
                                let tempResult: boolean = true;

                                if (typeof params.value === "number") {
                                  tempResult = eval(
                                    `${params.value} ${item.condition_logic}`
                                  );
                                } else if (typeof params.value === "string") {
                                  tempResult = eval(
                                    `'${params.value}' ${item.condition_logic}`
                                  );
                                }

                                return tempResult;
                              } catch (error) {
                                return true;
                              }
                            }}
                          />
                        )}

                      {item.condition_column_name &&
                        item.condition_column_name !== "" &&
                        props.allowEdit && (
                          <CustomRule
                            message={item.requireinputtext}
                            validationCallback={(params: any) => {
                              try {
                                let tempResult: boolean = true;
                                const object: any = params.data;
                                const value: any = params.value;
                                if (typeof value === "number") {
                                  tempResult = eval(
                                    `${value} ${item.condition_column_name}`
                                  );
                                } else if (typeof value === "string") {
                                  tempResult = eval(
                                    `'${value}' ${item.condition_column_name}`
                                  );
                                }

                                return tempResult;
                              } catch (error) {
                                return true;
                              }
                            }}
                          />
                        )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.AMOUNT_CURRENCY:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      minWidth={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      // formItem={{ visible: item.show_form_edit || false }}
                      cssClass={item.is_bold ? "bold-row-datagrid" : ""}
                      // customizeText={custometext}
                      // calculateCellValue={} first name + lastname = fullname
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      setCellValue={(newData: any, value: any, object: any) => {
                        console.log("vao");

                        handleFormulerDataGridEdit(
                          item,
                          newData,
                          value,
                          object
                        );
                      }}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}

                      {item.condition_logic &&
                        item.condition_logic !== "" &&
                        props.allowEdit && (
                          <CustomRule
                            message={item.requireinputtext}
                            validationCallback={(params: any) => {
                              try {
                                let tempResult: boolean = true;

                                if (typeof params.value === "number") {
                                  tempResult = eval(
                                    `${params.value} ${item.condition_logic}`
                                  );
                                } else if (typeof params.value === "string") {
                                  tempResult = eval(
                                    `'${params.value}' ${item.condition_logic}`
                                  );
                                }

                                return tempResult;
                              } catch (error) {
                                return true;
                              }
                            }}
                          />
                        )}

                      {item.condition_column_name &&
                        item.condition_column_name !== "" &&
                        props.allowEdit && (
                          <CustomRule
                            message={item.requireinputtext}
                            validationCallback={(params: any) => {
                              try {
                                let tempResult: boolean = true;
                                const object: any = params.data;
                                const value: any = params.value;
                                if (typeof value === "number") {
                                  tempResult = eval(
                                    `${value} ${item.condition_column_name}`
                                  );
                                } else if (typeof value === "string") {
                                  tempResult = eval(
                                    `'${value}' ${item.condition_column_name}`
                                  );
                                }

                                return tempResult;
                              } catch (error) {
                                return true;
                              }
                            }}
                          />
                        )}
                    </Column>
                  );
                  break;
                case INPUT_TYPE.CELL_CUSTOMIZE:
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      width={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      formItem={{ visible: item.show_form_edit || false }}
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                      cellRender={(cell) => {
                        return (
                          <div style={{}}>
                            {cell.value && props.onCellCustomizeRender ? (
                               props.onCellCustomizeRender(cell, item, cell.data)
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      }}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                    </Column>
                  );
                  break;
                default: {
                  return (
                    <Column
                      key={index}
                      dataField={item.column_name}
                      caption={
                        item.name_vn
                      }
                      minWidth={item.width}
                      dataType={item.data_type}
                      allowEditing={item.is_readonly ? false : props.allowEdit}
                      grouped={item.showgrouping}
                      format={item.format_type}
                      alignment={item.align}
                      // formItem={{ visible: item.show_form_edit || false }}
                      cssClass={item.is_bold ? "bold-row-datagrid" : ""}
                      // customizeText={custometext}
                      // calculateCellValue={} first name + lastname = fullname
                      fixed={item.is_fixed || false}
                      fixedPosition={item.fixed_position || "left"}
                    >
                      {item.requireinput && (
                        <RequiredRule message={item.requireinputtext} />
                      )}
                      {item.condition_logic &&
                        item.condition_logic !== "" &&
                        props.allowEdit && (
                          <CustomRule
                            message={item.requireinputtext}
                            validationCallback={(params: any) => {
                              try {
                                let tempResult: boolean = true;

                                if (typeof params.value === "number") {
                                  tempResult = eval(
                                    `${params.value} ${item.condition_logic}`
                                  );
                                } else if (typeof params.value === "string") {
                                  tempResult = eval(
                                    `'${params.value}' ${item.condition_logic}`
                                  );
                                }

                                return tempResult;
                              } catch (error) {
                                return true;
                              }
                            }}
                          />
                        )}

                      {item.condition_column_name &&
                        item.condition_column_name !== "" &&
                        props.allowEdit && (
                          <CustomRule
                            message={item.requireinputtext}
                            validationCallback={(params: any) => {
                              try {
                                let tempResult: boolean = true;
                                const object: any = params.data;
                                const value: any = params.value;
                                if (typeof value === "number") {
                                  tempResult = eval(
                                    `${value} ${item.condition_column_name}`
                                  );
                                } else if (typeof value === "string") {
                                  tempResult = eval(
                                    `'${value}' ${item.condition_column_name}`
                                  );
                                }

                                return tempResult;
                              } catch (error) {
                                return true;
                              }
                            }}
                          />
                        )}
                    </Column>
                  );
                  break;
                }
              }
            }
          })
        ) : (
          <></>
        )}
        {props.isMasterDetail && (
          <MasterDetail
            // enabled={props.isMasterDetail || false}
            enabled={false}
            component={props.detailsComponent}
          />
        )}

        <Summary recalculateWhileEditing={true}>
          {columns[0] && !props.isPagination && (
            <TotalItem
              column={columns[0].column_name}
              summaryType="count"
              displayFormat={`{0} Dòng`}
            />
          )}
          {columns[0] && (
            <GroupItem
              column={columns[0].column_name}
              summaryType="count"
              valueFormat="fixedPoint"
              displayFormat={` SL: {0} `}
            />
          )}
          {columns.length > 0 &&
            columns
              .filter((item) => item.is_summary)
              .map((temp) => (
                <TotalItem
                  column={temp.column_name}
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat={`{0}`}
                />
              ))}

          {columns.length > 0 &&
            columns
              .filter((item) => item.is_summary)
              .map((temp) => (
                <GroupItem
                  column={temp.column_name}
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat={` {0}`}
                  alignByColumn={true}
                />
              ))}
          {columns.length > 0 &&
            columns
              .filter((item) => item.is_average)
              .map((temp) => (
                <TotalItem
                  column={temp.column_name}
                  summaryType="avg"
                  valueFormat="fixedPoint"
                  displayFormat={`AVG: {0}`}
                />
              ))}
        </Summary>
      </DataGrid>
    </div>
  );
};

const NumberEditor = ({ e, item, props }: any) => {
  return (
    <NumberBox
      readOnly={item.is_readonly ? true : !props.allowEdit}
      // value={e.data.value}
      defaultValue={e.data.value}
      format="#,###"
      onValueChange={(value) => {
        e.data.setValue(value);
      }}
    />
  );
};

export default React.memo(DHSDataGridEdit);





// import React, { RefObject, useEffect, useState } from "react";

// import DataGrid, {
//   Column,
//   Editing,
//   Paging,
//   Selection,
//   Lookup,
//   FilterRow,
//   GroupPanel,
//   Pager,
//   Grouping,
//   SearchPanel,
//   ColumnChooser,
//   Popup,
//   RequiredRule,
//   Export,
//   HeaderFilter,
//   FilterPanel,
//   Summary,
//   TotalItem,
//   ColumnFixing,
//   Sorting,
//   NumericRule,
//   Scrolling,
//   ToolbarItem,
//   Label,
//   GroupItem,
// } from "devextreme-react/data-grid";

// import TagBox from "devextreme-react/tag-box";

// import ArrayStore from "devextreme/data/array_store";
// import DataSource from "devextreme/data/data_source";
// import { useSelector } from "react-redux";
// import { Box, Chip } from "@mui/material";
// import { IReducer, Notification } from "../../layouts";
// import {
//   HomeClient,
//   SYS_Token_AuthenticationModel,
//   SysGenRowTableModel,
// } from "../../../app/shared/service-proxies/api-shared";
// import { AppSession } from "../../../app/shared/app-session/app-session";
// import BASE_URL_API from "../../../app/shared/service-proxies/config";
// import { EventInfo } from "devextreme/events";
// import dxDataGrid, {
//   CellPreparedEvent,
//   RowPreparedEvent,
//   SelectionChangedInfo,
// } from "devextreme/ui/data_grid";
// import { httpPost } from "../../../common/httpService";
// import { useHotkeys } from "react-hotkeys-hook";
// import { SortingState } from "@devexpress/dx-react-grid";
// import HtmlEditor, {
//   Toolbar,
//   MediaResizing,
//   ImageUpload,
//   Item,
// } from "devextreme-react/html-editor";
// import NumberBox from "devextreme-react/number-box";
// import "./style_datagrid_edit.css";
// import DHSHtmlEditor from "../DHS_HTML_Editor/DHSHtmlEditor";
// import { Table } from "@devexpress/dx-react-grid-material-ui";
// import CheckBox from "devextreme-react/check-box";
// import Validator, { CustomRule } from "devextreme-react/validator";
// import TextArea from "devextreme-react/text-area";
// import ColorBox from "devextreme-react/color-box";
// import Slider from "devextreme-react/slider";
// import Switch from "devextreme-react/switch";
// import FileUploader from "devextreme-react/file-uploader";
// import DataGridSelectBox from "./DataGridSelectBox";
// // import DataGridNumberBox from "./DataGridNumberBox";

// interface IProps {
//   table_name?: string;
//   data: any[];
//   keyExpr?: string;
//   mode?: string;
//   dataGridRef: RefObject<DataGrid<any, any>>;
//   allowEdit: boolean;
//   pageSize?: number;
//   groupingAutoExpandAll?: boolean;
//   popupFullScreen?: boolean;
//   isPagination?: boolean;
//   isMultiple?: boolean;
//   height?: number;
//   defaultFilter?: boolean;
//   isParamsMaCtyFilterList?: boolean;
//   // doubleClickEdit?: boolean;

//   handlePageChange?: any;
//   handleEditingStar?: any;
//   handleInitNewRow?: any;
//   handleRowInserting?: any;
//   handleRowInserted?: any;
//   handleRowUpdating?: any;
//   handleRowUpdated?: any;
//   handleRowRemoving?: any;
//   handleRowRemoved?: any;
//   handleSaving?: any;
//   handleSaved?: any;
//   handleEditCanceling?: any;
//   handleEditCanceled?: any;
//   handleSelectionChanged?: any;
//   handlePrint?: any;
//   handleExporting?: any;
//   handleRowPrepared?: ((e: RowPreparedEvent<any, any>) => void) | undefined;
//   handleCellPrepared?: ((e: CellPreparedEvent<any, any>) => void) | undefined;
// }

// type DataSourceFilterList = {
//   cloumn_name?: string;
//   data: any[];
//   ValueMember: string;
//   DisplayMember: string;
//   TableDesign: string;
// };

// type ListDataSource = {
//   cloumn_name?: string;
//   data: any[];
//   ValueMember: string;
//   DisplayMember: string;
// }[];

// enum INPUT_TYPE {
//   // HTML_EDITOR = "html editor",
//   SELECT_BOX = "select box",
//   TAGS_BOX = "tag box",
//   NUMBER_BOX = "number box",
//   CHECK_BOX = "check box",
//   HTML_EDITOR = "html editor",
//   // SELECT_BOX = "select single",
//   // TAGS_BOX = "select multiple",
//   // NUMBER_BOX = 'input text number',
//   // CHECK_BOX = "input boolean",
//   IMAGE = "image",
//   TEXT_AREA = "text area",
//   COLOR_BOX = "color box",
//   SLIDER = "slider",
//   DATA_GRID = "data grid",
//   SWITCH = "switch",
//   CHIP_RENDER = "chip render",
// }

// const dataFixedPosition = ["left", "right"];

// let appSession: AppSession;
// const DHSDataGridEdit = (props: IProps) => {
//   // const { selectedItemRowIndexs = [] } = props;
//   const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
//   const sessionLogin: SYS_Token_AuthenticationModel = JSON.parse(
//     localStorage.getItem("SessionLogin") || "{}"
//   );
//   const homeClient = new HomeClient(appSession, BASE_URL_API);
//   const [columns, setColumns] = useState<SysGenRowTableModel[]>([]);
//   const [columnsFormList, setColumnsFormList] = useState<SysGenRowTableModel[]>(
//     []
//   );
//   // const dataGridRef = useRef<DataGrid>(null);
//   const [dataSource, setDataSource] = useState<DataSource>(
//     new DataSource({
//       store: new ArrayStore({
//         data: [],
//         key: props.keyExpr,
//       }),
//     })
//   );
//   const [listDataSource, setListDataSource] = useState<DataSourceFilterList[]>(
//     []
//   );

//   // const [allowEdit, setAllowEdit] = useState<boolean>(props.allowEdit || false)
//   const [isHiddenGroup, setIsHiddenGroup] = React.useState<boolean>(false);
//   const [isHiddenSearch, setIsHiddenSearch] = React.useState<boolean>(false);
//   const [isHiddenFilter, setIsHiddenFilter] = React.useState<boolean>(
//     props.defaultFilter || false
//   );
//   const [isFullSizePopup, setIsFullSizePopup] = useState<boolean>(
//     menu?.maximized || false
//   );
//   const [isHiddenExportData, setIsHiddenExportData] =
//     React.useState<boolean>(false);
//   const [isHiddenAdvantageFilter, setIsHiddenAdvantageFilter] =
//     React.useState<boolean>(false);
//   const [isHiddenColumnChooser, setIsHiddenColumnChooser] =
//     React.useState<boolean>(false);

//   useEffect(() => {
//     (async () => {
//       if (props.table_name) {
//         try {
//           const res = await homeClient.sysGenRowTable({
//             ...new SysGenRowTableModel(),
//             table_name: props.table_name,
//           } as SysGenRowTableModel);
//           const temp = res.map((item) => {
//             return {
//               ...item,
//               column_name: item.column_name?.toLowerCase(),
//             };
//           });
//           setColumnsFormList([...res.filter((item) => item.show_form_list)]);
//           setColumns([...res]);
//         } catch (error) {
//           setColumns([]);
//         }
//       }
//     })();
//   }, [props.table_name]);

//   // useEffect(() => {
//   //   console.log(props.allowEdit);

//   //   setAllowEdit(props.allowEdit)
//   // }, [props.allowEdit])

//   useEffect(() => {
//     if (columns.length > 0 && props.data.length >= 0) {
//       setDataSource(
//         new DataSource({
//           store: new ArrayStore({
//             data: [...props.data],
//             key: props.keyExpr,
//           }),
//         })
//       );
//     }
//   }, [columns, props.data]);

//   useEffect(() => {
//     (async () => {
//       if (columns.length > 0) {
//         const temp: string[] = [];

//         const promises = columns
//           .filter((item) => item.is_datasource)
//           .map((item, index) => {
//             temp.push(item.column_name as string);
//             return httpPost<string>("api/Common/LoadDataSourceBindToControl", {
//               ma_cty: sessionLogin.ma_cty,
//               tablelist: item.table_list,
//             });
//           });

//         const result = await Promise.all(promises);

//         const values = result.map((item: any, index) => {
//           const data = JSON.parse(item.data);

//           return {
//             cloumn_name: temp[index],
//             data: data.Table1,
//             ValueMember: data.Table[0].ValueMember,
//             DisplayMember: data.Table[0].DisplayMember,
//             TableDesign: data.Table[0].TableDesign,
//           };
//         });

//         setListDataSource([...values]);
//       }
//     })();
//   }, [columns]);

//   const handleContextMenuItems = (items: any) => {
//     console.log(items);

//     if (!items) {
//       return;
//     }

//     items.forEach((item: any) => {
//       if (item.items) {
//         // Xử lý mục menu lồng nhau
//         handleContextMenuItems(item.items);
//       }

//       switch (item.text) {
//         case "Sort Ascending":
//           item.text = "Sắp xếp tăng dần";
//           break;
//         case "Sort Descending":
//           item.text = "Sắp xếp giảm dần";
//           break;
//         case "Clear Sorting":
//           item.text = "Hủy sắp xếp";
//           break;
//         case "Group by This Column":
//           item.text = "Group cột này";
//           break;
//         case "Ungroup All":
//           item.text = "Hủy bỏ group tất cả";
//           break;
//         case "Fix":
//           item.text = "Cố định";
//           break;
//         case "To the left":
//           item.text = "Từ trái sang";
//           break;
//         case "To the right":
//           item.text = "Từ phải sang";
//           break;
//         case "Unfix":
//           item.text = "Không cố định";
//           break;
//       }
//     });
//   };

//   const handleContextMenuPreparing = (e: any) => {
//     const { row, column } = e;

//     // Xác định loại ContextMenu (dành cho hàng hoặc cột)
//     if (row) {
//       // ContextMenu cho hàng
//       const rowData = row.data;
//       // Tùy chỉnh ContextMenu cho hàng dựa trên rowData
//       e.items?.push(
//         {
//           text: isHiddenGroup ? "Ẩn nhóm ( alt + 1 )" : "Hiện nhóm ( alt + 1 )",
//           onItemClick: () => setIsHiddenGroup((pre) => !pre),
//           beginGroup: true,
//         },
//         {
//           text: isHiddenSearch
//             ? "Ẩn tìm kiếm ( alt + 2 )"
//             : "Hiện tìm kiếm ( alt + 2 )",
//           onItemClick: () => setIsHiddenSearch((pre) => !pre),
//         },
//         {
//           text: isHiddenFilter ? "Ẩn lọc ( alt + 3 )" : "Hiện lọc ( alt + 3 )",
//           onItemClick: () => setIsHiddenFilter((pre) => !pre),
//         },
//         {
//           text: isHiddenAdvantageFilter
//             ? "Ẩn lọc nâng cao ( alt + 4 )"
//             : "Hiện lọc nâng cao ( alt + 4 )",
//           onItemClick: () => setIsHiddenAdvantageFilter((pre) => !pre),
//         },
//         {
//           text: isHiddenColumnChooser
//             ? "Ẩn ẩn cột ( alt + 5 )"
//             : "Hiện ẩn cột ( alt + 5 )",
//           onItemClick: () => setIsHiddenColumnChooser((pre) => !pre),
//         },
//         {
//           text: isHiddenExportData ? "Ẩn xuất dữ liệu" : "Hiện xuất dữ liệu",
//           onItemClick: () => setIsHiddenExportData((pre) => !pre),
//         },
//         {
//           text: "Hủy lọc",
//           onItemClick: () => props.dataGridRef.current?.instance.clearFilter(),
//           beginGroup: true,
//         },
//         {
//           text: "Hủy nhóm",
//           onItemClick: () =>
//             props.dataGridRef.current?.instance.clearGrouping(),
//         },
//         {
//           text: "Hủy chọn",
//           onItemClick: () =>
//             props.dataGridRef.current?.instance.clearSelection(),
//         }
//         // Các mục menu khác cho hàng
//       );
//     } else if (column) {
//       // ContextMenu cho cột
//       const columnData = column.dataField;
//       // Tùy chỉnh ContextMenu cho cột dựa trên columnData
//       e.items.push(
//         {
//           text: isHiddenGroup ? "Ẩn group" : "Hiện group",
//           onItemClick: () => setIsHiddenGroup((pre) => !pre),
//         }
//         // Các mục menu khác cho cột
//       );
//     }

//     // Thay đổi văn bản của các mục menu trong e.items
//     handleContextMenuItems(e.items);
//   };

//   //Phim tắt
//   useHotkeys<HTMLParagraphElement>("alt+2", () =>
//     setIsHiddenSearch((pre) => !pre)
//   );
//   useHotkeys<HTMLParagraphElement>("alt+1", () =>
//     setIsHiddenGroup((pre) => !pre)
//   );

//   useHotkeys<HTMLParagraphElement>("alt+3", () =>
//     setIsHiddenFilter((pre) => !pre)
//   );
//   useHotkeys<HTMLParagraphElement>("alt+4", () =>
//     setIsHiddenAdvantageFilter((pre) => !pre)
//   );
//   useHotkeys<HTMLParagraphElement>("alt+5", () =>
//     setIsHiddenColumnChooser((pre) => !pre)
//   );

//   useHotkeys<HTMLParagraphElement>("Esc", () => {
//     props.dataGridRef.current?.instance.cancelEditData();
//   });

//   const handleDoubleClickRow = () => {
//     if (props.dataGridRef.current) {
//       props.dataGridRef.current.instance
//         .getSelectedRowKeys()
//         .then((keys) => {
//           const index = props.dataGridRef.current?.instance.getRowIndexByKey(
//             keys[0]
//           );
//           const indexSelected = index !== undefined && index >= 0 ? index : -1;
//           if (props.dataGridRef.current && indexSelected >= 0) {
//             props.dataGridRef.current.instance.editRow(indexSelected);
//             // setAllowEdit(true);
//           }
//         })
//         .catch((e) => {});
//     }
//   };

//   const handlePageChange = () => {
//     if (props.handlePageChange) {
//       props.handlePageChange();
//     }
//   };

//   return (
//     <div style={{ marginBottom: 10 }}>
//       <DataGrid
//         id="gridContainer"
//         ref={props.dataGridRef}
//         dataSource={dataSource}
//         keyExpr={props.keyExpr}
//         allowColumnReordering={true}
//         repaintChangesOnly
//         showBorders={true}
//         hoverStateEnabled={true}
//         allowColumnResizing={true}
//         onExporting={props.handleExporting}
//         rowAlternationEnabled={true}
//         columnResizingMode="widget"
//         onSelectionChanged={props.handleSelectionChanged}
//         onEditingStart={props.handleEditingStar}
//         onInitNewRow={props.handleInitNewRow}
//         onRowInserting={props.handleRowInserting}
//         onRowInserted={props.handleRowInserted}
//         onRowUpdating={props.handleRowUpdating}
//         onRowUpdated={props.handleRowUpdated}
//         onRowRemoving={props.handleRowRemoving}
//         onRowRemoved={props.handleRowRemoved}
//         // onRowDblClick={handleDoubleClickRow}
//         onSaving={props.handleSaving}
//         onSaved={props.handleSaved}
//         onEditCanceling={props.handleEditCanceling}
//         onEditCanceled={props.handleEditCanceled}
//         height={props.height ? `${props.height}vh` : "80vh"}
//         showRowLines={true}
//         onContextMenuPreparing={handleContextMenuPreparing}
//         onEditorPreparing={(e) => {}}
//         onRowPrepared={(e) => {
//           if (props.handleRowPrepared) {
//             props.handleRowPrepared(e);
//           }

//           let result: boolean = false;
//           if (e.data && e.data["bold"]) {
//             // result = e.data["bold"] === e.data["bold"] ? true : false;
//             switch (e.data["bold"]) {
//               case "1":
//                 result = true;
//                 break;
//               case 1:
//                 result = true;
//                 break;
//               case true:
//                 result = true;
//                 break;
//               default:
//                 result = false;
//                 break;
//             }
//           }

//           if (result) {
//             e.rowElement.classList.add("bold-row-datagrid");
//           }

//           // let result: boolean = false;
//           // columns.length > 0 &&
//           //   columns.map((item) => {
//           //     if (
//           //       item.condition_column_name &&
//           //       item.condition_logic &&
//           //       item.condition_value &&
//           //       e.data
//           //     ) {
//           //       try {
//           //         if (typeof e.data[item.condition_column_name] === "number") {
//           //           const value = parseInt(item.condition_value);
//           //           result = eval(
//           //             `${e.data[item.condition_column_name]} ${
//           //               item.condition_logic
//           //             } ${value}`
//           //           );
//           //         } else {
//           //           result = eval(
//           //             `'${e.data[item.condition_column_name]}' ${
//           //               item.condition_logic
//           //             } '${item.condition_value}'`
//           //           );
//           //         }
//           //       } catch (error) {}
//           //     }
//           //   });

//           if (result) {
//             e.rowElement.classList.add("backgound-color-row-datagrid");
//           }
//         }}
//         onCellPrepared={(e) => {
//           if (props.handleCellPrepared) {
//             props.handleCellPrepared(e);
//           }

//           //   if (e.rowType === "data"){
//           //     e.cellElement.className += 'backgound-color-row-datagrid'
//           // }
//         }}
//       >
//         <Selection
//           mode={props.isMultiple ? "multiple" : "single"}
//           deferred={true}
//           // deferred={props.isMultiple ? false : true}
//           // allowSelectAll={props.isMultiple ? true : false}
//         />
//         <Grouping autoExpandAll={props.groupingAutoExpandAll || false} />
//         <GroupPanel
//           visible={isHiddenGroup}
//           emptyPanelText={"Kéo thả cột muốn group vào đây"}
//         />

//         <ColumnFixing enabled={true} />

//         <SearchPanel visible={isHiddenSearch} />
//         <ColumnChooser
//           enabled={isHiddenColumnChooser}
//           emptyPanelText={"Kéo và thả cột muốn ẩn vào đây"}
//           title={"Chọn cột ẩn"}
//         />

//         <Editing
//           mode={props.mode}
//           allowUpdating={props.mode === "cell" ? true : false}
//           allowDeleting={false}
//           allowAdding={false}
//           useIcons={true}
//         >
//           <Popup
//             title={menu?.bar}
//             showTitle={true}
//             // fullScreen={isFullSizePopup}
//             fullScreen={menu?.maximized}
//             resizeEnabled={true}
//             // toolbarItems={[
//             //   {
//             //     location: "after",
//             //     widget: "dxButton",
//             //     showText: "always",
//             //     render: "",
//             //     options: {
//             //       icon: "fullscreen",
//             //     },
//             //     onClick: () => {
//             //       setIsFullSizePopup(!isFullSizePopup)
//             //     }
//             //   }
//             // ]}
//             // toolbarItems={[
//             //   {
//             //     location: "after",
//             //     widget: "dxButton",
//             //     toolbar: "bottom",
//             //     showText: "always",
//             //     cssClass: "toolbar-bottom-action",
//             //     render: "",
//             //     options: {
//             //       text: "Lưu",
//             //     },
//             //     onClick: () => {
//             //       // props.dataGridRef.current?.instance.;
//             //     }
//             //   },
//             //   {
//             //     location: "after",
//             //     widget: "dxButton",
//             //     toolbar: "bottom",
//             //     options: {
//             //       text: "Quay lại",
//             //     },
//             //     onClick: () => {
//             //       props.dataGridRef.current?.instance.cancelEditData();
//             //     }
//             //   },
//             // ]}
//           ></Popup>
//         </Editing>
//         <FilterRow visible={isHiddenFilter} />
//         <HeaderFilter visible={true} />

//         {props.isPagination && (
//           <Pager
//             visible={props.isPagination}
//             displayMode={false}
//             showPageSizeSelector={true}
//             showInfo={true}
//             allowedPageSizes={true}
//             showNavigationButtons={true}
//             // infoText={'Dòng mỗi trang'}
//           />
//         )}
//         {props.isPagination && (
//           <Paging
//             defaultPageSize={props.pageSize || 40}
//             onPageSizeChange={handlePageChange}
//             onPageIndexChange={handlePageChange}
//           />
//         )}

//         {!props.isPagination && <Scrolling mode="virtual" />}
//         <Export enabled={isHiddenExportData} allowExportSelectedData={true} />
//         <FilterPanel visible={isHiddenAdvantageFilter} />

//         {columnsFormList.length > 0 ? (
//           // eslint-disable-next-line array-callback-return
//           columnsFormList.map((item, index) => {
//             if (item.is_datasource) {
//               switch (item.input_type) {
//                 case INPUT_TYPE.SELECT_BOX:
//                   return (
//                     <Column
//                       key={index}
//                       dataField={item.column_name}
//                       caption={item.name_vn}
//                       minWidth={item.width}
//                       // allowResizing={true}
//                       dataType={item.data_type}
//                       allowEditing={item.is_readonly ? false : props.allowEdit}
//                       grouped={item.showgrouping}
//                       alignment={item.align}
//                       // calculateDisplayValue={(dataRow: any) => {
//                       //   return dataRow[listDataSource.find(
//                       //     (i) => i.cloumn_name === item.column_name
//                       //   )?.DisplayMember || '']
//                       // }}
//                       formItem={{ visible: item.show_form_edit || false }}
//                       cssClass={item.is_bold ? "bold-row-datagrid" : ""}
//                       fixed={item.is_fixed || false}
//                       fixedPosition={item.fixed_position || "left"}
//                     >
//                       {item.requireinput && (
//                         <RequiredRule message={item.requireinputtext} />
//                       )}
//                       <Lookup
//                         dataSource={
//                           listDataSource.find(
//                             (i) => i.cloumn_name === item.column_name
//                           )?.data
//                         }
//                         allowClearing={true}
//                         valueExpr={
//                           listDataSource.find(
//                             (i) => i.cloumn_name === item.column_name
//                           )?.ValueMember
//                         }
//                         displayExpr={
//                           listDataSource.find(
//                             (i) => i.cloumn_name === item.column_name
//                           )?.DisplayMember
//                         }
//                       />
//                     </Column>
//                   );
//                   break;
//                 case INPUT_TYPE.TAGS_BOX:
//                   return (
//                     <Column
//                       key={index}
//                       dataField={item.column_name}
//                       caption={item.name_vn}
//                       width={item.width}
//                       dataType={item.data_type}
//                       allowEditing={item.is_readonly ? false : props.allowEdit}
//                       grouped={item.showgrouping}
//                       format={item.format_type}
//                       alignment={item.align}
//                       // calculateDisplayValue={(dataRow: any) => {
//                       //   return dataRow[listDataSource.find(
//                       //     (i) => i.cloumn_name === item.column_name
//                       //   )?.DisplayMember || '']
//                       // }}
//                       cssClass={item.is_bold ? "bold-row-datagrid" : ""}
//                       formItem={{ visible: item.show_form_edit || false }}
//                       fixed={item.is_fixed || false}
//                       fixedPosition={item.fixed_position || "left"}
//                       editCellComponent={(event) => (
//                         <TagBox
//                           dataSource={
//                             listDataSource.find(
//                               (i) => i.cloumn_name === item.column_name
//                             )?.data
//                           }
//                           valueExpr={
//                             listDataSource.find(
//                               (i) => i.cloumn_name === item.column_name
//                             )?.ValueMember
//                           }
//                           displayExpr={
//                             listDataSource.find(
//                               (i) => i.cloumn_name === item.column_name
//                             )?.DisplayMember
//                           }
//                           disabled={item.is_readonly ? true : !props.allowEdit}
//                           showSelectionControls={true}
//                           maxDisplayedTags={10}
//                           showMultiTagOnly={false}
//                           applyValueMode="useButtons"
//                           searchEnabled={true}
//                           onValueChanged={(e) => {
//                             event.data.setValue(e.value.join(";"));
//                           }}
//                           defaultValue={
//                             event.data.value ? event.data.value.split(";") : []
//                           }
//                           onSelectionChanged={() =>
//                             event.data.component.updateDimensions()
//                           }
//                         />
//                       )}
//                       cellRender={(cell) => {
//                         // debugger
//                         return (
//                           <div>
//                             {cell.value ? (
//                               cell.value
//                                 .split(";")
//                                 .map((item: any, index: any) => (
//                                   <Chip
//                                     style={{
//                                       backgroundColor: "#2590C3",
//                                       color: "#fff",
//                                     }}
//                                     size="small"
//                                     label={item}
//                                     key={index}
//                                   />
//                                 ))
//                             ) : (
//                               <></>
//                             )}
//                           </div>
//                         );
//                       }}
//                     >
//                       {item.requireinput && (
//                         <RequiredRule message={item.requireinputtext} />
//                       )}
//                     </Column>
//                   );
//                   break;
//                 case INPUT_TYPE.DATA_GRID:
//                   return (
//                     <Column
//                       key={index}
//                       dataField={item.column_name}
//                       caption={item.name_vn}
//                       width={item.width}
//                       dataType={item.data_type}
//                       allowEditing={item.is_readonly ? false : props.allowEdit}
//                       grouped={item.showgrouping}
//                       format={item.format_type}
//                       alignment={item.align}
//                       // calculateDisplayValue={(dataRow: any) => {
//                       //   return dataRow['name_customer']
//                       // }}
//                       cssClass={item.is_bold ? "bold-row-datagrid" : ""}
//                       formItem={{ visible: item.show_form_edit || false }}
//                       fixed={item.is_fixed || false}
//                       fixedPosition={item.fixed_position || "left"}
//                       editCellComponent={(event) =>
//                         DataGridSelectBox(
//                           event,
//                           listDataSource.find(
//                             (i) => i.cloumn_name === item.column_name
//                           )?.ValueMember,
//                           listDataSource.find(
//                             (i) => i.cloumn_name === item.column_name
//                           )?.DisplayMember,
//                           listDataSource.find(
//                             (i) => i.cloumn_name === item.column_name
//                           )?.TableDesign,
//                           item.is_readonly,
//                           props.allowEdit
//                         )
//                       }
//                     >
//                       <Lookup
//                         dataSource={
//                           listDataSource.find(
//                             (i) => i.cloumn_name === item.column_name
//                           )?.data
//                         }
//                         allowClearing={true}
//                         valueExpr={
//                           listDataSource.find(
//                             (i) => i.cloumn_name === item.column_name
//                           )?.ValueMember
//                         }
//                         displayExpr={
//                           listDataSource.find(
//                             (i) => i.cloumn_name === item.column_name
//                           )?.DisplayMember
//                         }
//                       />
//                       {item.requireinput && (
//                         <RequiredRule message={item.requireinputtext} />
//                       )}
//                     </Column>
//                   );
//                   break;
//                 default:
//                   return (
//                     <Column
//                       key={index}
//                       dataField={item.column_name}
//                       caption={item.name_vn}
//                       minWidth={item.width}
//                       dataType={item.data_type}
//                       allowEditing={item.is_readonly ? false : props.allowEdit}
//                       grouped={item.showgrouping}
//                       format={item.format_type}
//                       alignment={item.align}
//                       formItem={{ visible: item.show_form_edit || false }}
//                       cssClass={"bold-row-datagrid"}
//                       fixed={item.is_fixed || false}
//                       fixedPosition={item.fixed_position || "left"}
//                     >
//                       {item.requireinput && (
//                         <RequiredRule message={item.requireinputtext} />
//                       )}
//                     </Column>
//                   );
//                   break;
//               }
//             } else {
//               switch (item.input_type) {
//                 case INPUT_TYPE.CHIP_RENDER:
//                   return (
//                     <Column
//                       key={index}
//                       dataField={item.column_name}
//                       caption={item.name_vn}
//                       width={item.width}
//                       dataType={item.data_type}
//                       allowEditing={item.is_readonly ? false : props.allowEdit}
//                       grouped={item.showgrouping}
//                       format={item.format_type}
//                       alignment={item.align}
//                       formItem={{ visible: item.show_form_edit || false }}
//                       fixed={item.is_fixed || false}
//                       fixedPosition={item.fixed_position || "left"}
//                       cellRender={(cell) => {
//                         return (
//                           <div>
//                             {cell.value !== undefined && cell.value !== null? (
//                               <Chip
//                                 style={{
//                                   backgroundColor:
//                                     item.background_color_web || "#2590C3",
//                                   color: item.font_color_web || "#fff",
//                                   maxHeight: props.isMultiple ? 15 : "auto",
//                                 }}
//                                 size="small"
//                                 label={cell.value}
//                                 key={index}
//                               />
//                             ) : (
//                               <></>
//                             )}
//                           </div>
//                         );
//                       }}
//                     >
//                       {item.requireinput && (
//                         <RequiredRule message={item.requireinputtext} />
//                       )}
//                     </Column>
//                   );
//                   break;
//                 case INPUT_TYPE.NUMBER_BOX:
//                   return (
//                     <Column
//                       key={index}
//                       dataField={item.column_name}
//                       caption={item.name_vn}
//                       width={item.width}
//                       dataType={item.data_type}
//                       allowEditing={item.is_readonly ? false : props.allowEdit}
//                       grouped={item.showgrouping}
//                       format={item.format_type}
//                       alignment={item.align}
//                       formItem={{ visible: item.show_form_edit || false }}
//                       fixed={item.is_fixed || false}
//                       fixedPosition={item.fixed_position || "left"}
//                       editCellComponent={(e) => {
//                         return (
//                           <NumberBox
//                             readOnly={
//                               item.is_readonly ? true : !props.allowEdit
//                             }
//                             // value={e.data.value}
//                             defaultValue={e.data.value}
//                             format="#,###"
//                             onValueChange={(value) => {
//                               e.data.setValue(value);
//                             }}
//                           />
//                         );
//                       }}
//                       cssClass={item.is_bold ? "bold-row-datagrid" : ""}
//                       // editCellComponent={(e) => {
//                       //   return (
//                       //     <DataGridNumberBox e={e} allowEdit={props.allowEdit} item={item} />
//                       //   )
//                       // }}
//                     >
//                       {item.requireinput && (
//                         <RequiredRule message={item.requireinputtext} />
//                       )}
//                     </Column>
//                   );
//                   break;
//                 case INPUT_TYPE.CHECK_BOX:
//                   return (
//                     <Column
//                       key={index}
//                       dataField={item.column_name}
//                       caption={item.name_vn}
//                       width={item.width}
//                       dataType={item.data_type}
//                       allowEditing={item.is_readonly ? false : props.allowEdit}
//                       grouped={item.showgrouping}
//                       format={item.format_type}
//                       alignment={item.align}
//                       formItem={{ visible: item.show_form_edit || false }}
//                       fixed={item.is_fixed || false}
//                       fixedPosition={item.fixed_position || "left"}
//                       // editCellComponent={(event) => (
//                       //   <CheckBox
//                       //     readOnly={item.is_readonly ? true : !props.allowEdit}
//                       //     defaultValue={event.data.value}
//                       //     onValueChange={(value) => {
//                       //       event.data.setValue(value);

//                       //       console.log(item.requireinput);
//                       //       console.log(event.data.value === undefined);
//                       //     }}
//                       //     // isValid={false}
//                       //     validationError={{
//                       //       message: item.requireinputtext || "",
//                       //     }}
//                       //     validationStatus={
//                       //       item.requireinput && event.data.value === undefined
//                       //         ? "invalid"
//                       //         : "valid"
//                       //     }
//                       //     // className={item.requireinput ? "require_check" : ''}
//                       //   />
//                       // )}
//                       // cellRender={(cell) => {
//                       //   return (
//                       //     <CheckBox readOnly={true} defaultValue={cell.value} />
//                       //   );
//                       // }}
//                     >
//                       {item.requireinput && (
//                         <RequiredRule
//                           message={item.requireinputtext}
//                         ></RequiredRule>
//                       )}
//                       {/* {item.requireinput && (
//                         <CustomRule
//                           type="custom"
//                           message={item.requireinputtext}
//                           validationCallback={(e: { value: undefined }) => {
//                             return e.value !== undefined;
//                             // return false;
//                           }}
//                         />
//                       )} */}
//                     </Column>
//                   );
//                   break;
//                 case INPUT_TYPE.SWITCH:
//                   return (
//                     <Column
//                       key={index}
//                       dataField={item.column_name}
//                       caption={item.name_vn}
//                       width={item.width}
//                       dataType={item.data_type}
//                       allowEditing={item.is_readonly ? false : props.allowEdit}
//                       grouped={item.showgrouping}
//                       format={item.format_type}
//                       alignment={item.align}
//                       formItem={{ visible: item.show_form_edit || false }}
//                       fixed={item.is_fixed || false}
//                       fixedPosition={item.fixed_position || "left"}
//                       editCellComponent={(e) => {
//                         return (
//                           // <NumberBox
//                           //   readOnly={
//                           //     item.is_readonly ? true : !props.allowEdit
//                           //   }
//                           //   // value={e.data.value}
//                           //   defaultValue={e.data.value}
//                           //   format="#,###"
//                           //   onValueChange={(value) => {
//                           //     e.data.setValue(value);
//                           //   }}
//                           // />
//                           <Switch
//                             // value={this.state.value}
//                             defaultValue={e.data.value || false}
//                             onValueChanged={(event) => {
//                               e.data.setValue(event.value);
//                             }}
//                             readOnly={
//                               item.is_readonly ? true : !props.allowEdit
//                             }
//                           />
//                         );
//                       }}
//                       cellRender={(cell) => {
//                         // debugger
//                         return (
//                           <div>
//                             <Switch
//                               defaultValue={cell.value || false}
//                               readOnly={true}
//                             />
//                           </div>
//                         );
//                       }}
//                     >
//                       {/* {item.requireinput && (
//                         <RequiredRule message={item.requireinputtext} />
//                       )} */}
//                     </Column>
//                   );
//                   break;
//                 case INPUT_TYPE.TEXT_AREA:
//                   return (
//                     <Column
//                       key={index}
//                       dataField={item.column_name}
//                       caption={item.name_vn}
//                       width={item.width}
//                       dataType={item.data_type}
//                       allowEditing={item.is_readonly ? false : props.allowEdit}
//                       grouped={item.showgrouping}
//                       format={item.format_type}
//                       alignment={item.align}
//                       fixed={item.is_fixed || false}
//                       fixedPosition={item.fixed_position || "left"}
//                       // cssClass={}
//                       formItem={{ visible: item.show_form_edit || false }}
//                       editCellComponent={(event) => (
//                         <TextArea
//                           readOnly={item.is_readonly ? true : !props.allowEdit}
//                           defaultValue={event.data.value}
//                           onValueChange={(value) => {
//                             event.data.setValue(value);
//                           }}
//                         />
//                       )}
//                       cssClass={item.is_bold ? "bold-row-datagrid" : ""}
//                     >
//                       {item.requireinput && (
//                         <RequiredRule message={item.requireinputtext} />
//                       )}
//                     </Column>
//                   );
//                   break;
//                 case INPUT_TYPE.COLOR_BOX:
//                   return (
//                     <Column
//                       key={index}
//                       dataField={item.column_name}
//                       caption={item.name_vn}
//                       width={item.width}
//                       dataType={item.data_type}
//                       allowEditing={item.is_readonly ? false : props.allowEdit}
//                       grouped={item.showgrouping}
//                       format={item.format_type}
//                       alignment={item.align}
//                       fixed={item.is_fixed || false}
//                       fixedPosition={item.fixed_position || "left"}
//                       // cssClass={}
//                       formItem={{ visible: item.show_form_edit || false }}
//                       editCellComponent={(event) => (
//                         <ColorBox
//                           readOnly={item.is_readonly ? true : !props.allowEdit}
//                           defaultValue={event.data.value}
//                           onValueChange={(value) => {
//                             event.data.setValue(value);
//                           }}
//                         />
//                       )}
//                       cssClass={item.is_bold ? "bold-row-datagrid" : ""}
//                     >
//                       {item.requireinput && (
//                         <RequiredRule message={item.requireinputtext} />
//                       )}
//                     </Column>
//                   );
//                   break;
//                 case INPUT_TYPE.SLIDER:
//                   return (
//                     <Column
//                       key={index}
//                       dataField={item.column_name}
//                       caption={item.name_vn}
//                       width={item.width}
//                       dataType={item.data_type}
//                       allowEditing={item.is_readonly ? false : props.allowEdit}
//                       grouped={item.showgrouping}
//                       format={item.format_type}
//                       alignment={item.align}
//                       // cssClass={}
//                       formItem={{ visible: item.show_form_edit || false }}
//                       fixed={item.is_fixed || false}
//                       fixedPosition={item.fixed_position || "left"}
//                       editCellComponent={(event) => (
//                         <Slider
//                           readOnly={item.is_readonly ? true : !props.allowEdit}
//                           defaultValue={event.data.value}
//                           onValueChange={(value) => {
//                             event.data.setValue(value);
//                           }}
//                           tooltip={{
//                             enabled: true,
//                             position: "top",
//                             showMode: "onHover",
//                           }}
//                           min={1}
//                           // max={1000}
//                         />
//                       )}
//                       cssClass={item.is_bold ? "bold-row-datagrid" : ""}
//                     >
//                       {item.requireinput && (
//                         <RequiredRule message={item.requireinputtext} />
//                       )}
//                     </Column>
//                   );
//                   break;
//                 case INPUT_TYPE.HTML_EDITOR:
//                   return (
//                     <Column
//                       key={index}
//                       dataField={item.column_name}
//                       caption={item.name_vn}
//                       width={item.width}
//                       dataType={item.data_type}
//                       allowEditing={item.is_readonly ? false : props.allowEdit}
//                       grouped={item.showgrouping}
//                       format={item.format_type}
//                       alignment={item.align}
//                       fixed={item.is_fixed || false}
//                       fixedPosition={item.fixed_position || "left"}
//                       // cssClass={}
//                       editCellComponent={(event) => (
//                         <DHSHtmlEditor
//                           readonly={item.is_readonly ? true : !props.allowEdit}
//                           defaultValue={event.data.value}
//                           onchangeHTMLValue={(e) => {
//                             event.data.setValue(e.value);
//                             // if( event.data.setValue) {
//                             //   event.data.setValue(e);
//                             // }
//                           }}
//                         />
//                       )}
//                       cssClass={item.is_bold ? "bold-row-datagrid" : ""}
//                     >
//                       {item.requireinput && (
//                         <RequiredRule message={item.requireinputtext} />
//                       )}
//                     </Column>
//                   );
//                   break;
//                 default:
//                   return (
//                     <Column
//                       key={index}
//                       dataField={item.column_name}
//                       caption={item.name_vn}
//                       minWidth={item.width}
//                       dataType={item.data_type}
//                       allowEditing={item.is_readonly ? false : props.allowEdit}
//                       grouped={item.showgrouping}
//                       format={item.format_type}
//                       alignment={item.align}
//                       formItem={{ visible: item.show_form_edit || false }}
//                       cssClass={item.is_bold ? "bold-row-datagrid" : ""}
//                       fixed={item.is_fixed || false}
//                       fixedPosition={item.fixed_position || "left"}
//                     >
//                       {item.requireinput && (
//                         <RequiredRule message={item.requireinputtext} />
//                       )}
//                     </Column>
//                   );
//                   break;
//               }
//             }
//           })
//         ) : (
//           <></>
//         )}

//         <Summary recalculateWhileEditing={true}>
//           {columns[0] && !props.isPagination && (
//             <TotalItem
//               column={columns[0].column_name}
//               summaryType="count"
//               displayFormat={`Số dòng: {0}`}
//             />
//           )}
//           {columns[0] && (
//             <GroupItem
//               column={columns[0].column_name}
//               summaryType="count"
//               valueFormat="fixedPoint"
//               displayFormat={` {0} `}
//             />
//           )}
//           {columns.length > 0 &&
//             columns
//               .filter((item) => item.is_summary)
//               .map((temp) => (
//                 <TotalItem
//                   column={temp.column_name}
//                   summaryType="sum"
//                   valueFormat="fixedPoint"
//                   displayFormat={`{0}`}
//                 />
//               ))}

//           {columns.length > 0 &&
//             columns
//               .filter((item) => item.is_summary)
//               .map((temp) => (
//                 <GroupItem
//                   column={temp.column_name}
//                   summaryType="sum"
//                   valueFormat="fixedPoint"
//                   displayFormat={` {0}`}
//                   alignByColumn={true}
//                 />
//               ))}
//         </Summary>
//       </DataGrid>
//     </div>
//   );
// };

// const NumberEditor = ({ e, item, props }: any) => {
//   return (
//     <NumberBox
//       readOnly={item.is_readonly ? true : !props.allowEdit}
//       // value={e.data.value}
//       defaultValue={e.data.value}
//       format="#,###"
//       onValueChange={(value) => {
//         e.data.setValue(value);
//       }}
//     />
//   );
// };

// export default React.memo(DHSDataGridEdit);
