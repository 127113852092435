import React from "react";
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
    ModelProcessin01,
    CaVch01Client,
    CaVch01M,
    CaVch01D0,
    LstBank,
    CaVch01D1,
    SysExchange,
    PaymentTerm,
    LstCurrency,
    CommonClient,
    ParaObjExchageRate,
    ParaObj,
    ParaObjRecNo,
    SysActionType,
    ParaObjCheck,
    InVch01Client,
    InVch01M,
    InVch01D0,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { ICustomer, IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../../actions/ApartmentBuilding.css";
import CTXDate from "../../../../components/CTX/DatePicker";
import CurrencyTextField from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import moment from "moment";
import notification from "../../../../common/notification/notification";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import DHSAutoCompleteWithDataGrid from "../../../../components/DHS/DHS_AutoCompleteCustomer/DHSAutoCompleteWithDataGrid";
import DataGrid from "devextreme-react/data-grid";
import { httpPost } from "../../../../common/httpService";
import { TransDateTime } from "../../../../components/utils/date.util";
import NumberBox from "devextreme-react/number-box";
import ReceivingVoucherDetail from "./ReceivingVoucherDetail";
import DHSDataGridFilterListByProjectSelect from "../../../../components/DHSUtilComponent/DHSDataGridFilterListByProjectSelect/DHSDataGridFilterListByProjectSelect";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";
interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: ACTIONS | string;
    payload?: any;
    filter?: IFilter;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onAddSuccess?: (data_success?: CaVch01M) => void;
    onEditSuccess?: (data_success?: CaVch01M) => void;
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});
interface IAction {
    type: ACTIONS | string;
    open: boolean;
    payload: any;
}

const defaultAction: IAction = {
    type: ACTIONS.EMPTY,
    payload: null,
    open: false,
};

interface IFilter {
    apartment_building_id?: string | null,
    block_id?: string | null,
    floor_id?: string | null,
    apartment_id?: string | null
}


var appSession: AppSession;
const status_message: string[] = ['success', 'error']


const ReceivingVoucherAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [maKH, setMaKh] = React.useState<string>();
    const inVch01Client = new InVch01Client(appSession, BASE_URL_API);
    const caVch01Client = new CaVch01Client(appSession, BASE_URL_API);
    const commonClient = new CommonClient(appSession, BASE_URL_API);

    const [data, setData] = React.useState<ModelProcessin01>({} as ModelProcessin01);
    const [data01D0, setData01D0] = React.useState<InVch01D0[]>([]);
    // const [data01D1, setData01D1] = React.useState<CaVch01D1[]>([]);
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const menu = useSelector((state: IReducer) => state.RoleReducer?.listMenu.find(x => x.code_name === "IN1"));

    const [bankName, setBankName] = React.useState<string>();
    const [bankCode, setBankCode] = React.useState<string>();

    const [isExpandReceiptAction, setIsExpandReceiptAction] = React.useState<boolean>(true);
    const [isCalledReceiptAction, setIsCalledReceiptAction] = React.useState<boolean>(true);
    const [isExpandReceiptDAction, setIsExpandReceiptDAction] = React.useState<boolean>(true);
    const [isCalledMember, setIsCalledCaVchD] = React.useState<boolean>(false);

    const [reload, setReload] = React.useState(false);
    const [fullScreen, setFullscreen] = React.useState<boolean>(false);

    const [errors, setErrors] = React.useState<any[]>();
    const [actPrint, setActPrint] = React.useState<IAction>(defaultAction);

    // 

    const [bankOptions, setBankOptions] = React.useState<any[]>([]);
    const [customerOptions, setCustomerOptions] = React.useState<ICustomer[]>([]);

    // 

    const [exchangeOptions, setExchangeOptions] = React.useState<any[]>([]);
    const [paymentTermOptions, setPaymentTermOptions] = React.useState<any[]>([]);
    const [currencyOptions, setCurrencyOptions] = React.useState<any[]>([]);

    // 

    const dataGridRef01D1 = React.useRef<DataGrid>(null);
    const dataGridRef01D0 = React.useRef<DataGrid>(null);


    React.useEffect(() => {
        if (!props.open) {
            setMaKh(undefined);

        }
    }, [props.open])

    //#region handleGetOptions

    React.useEffect(() => {
        if (isCalledReceiptAction) {
            setLoading(true)
            caVch01Client.sysExchangeGet({
                ...new SysExchange(),
                ma_ct: menu?.code_name,
            } as SysExchange)
                .then(res => {

                    setLoading(false);
                    setExchangeOptions(res)
                    setData(pre => ({
                        ...pre,
                        modelM: {
                            ...pre.modelM,
                            mA_GD: res[0].ma_gd
                        },
                    } as ModelProcessin01));

                });

            // 

            // setLoading(true)
            // caVch01Client.lstPaymentTermGet({
            //     ...new PaymentTerm(),
            //     ma_cty: SessionLogin.ma_cty,
            //     moduleid: 'CA'
            // } as PaymentTerm)
            //     .then(res => {

            //         setLoading(false);
            //         setPaymentTermOptions(res)
            //         setData(pre => ({
            //             ...pre,
            //             modelM: {
            //                 ...pre.modelM,
            //                 ma_httt: res[0].ma_httt
            //             },
            //         } as ModelProcessin01));
            //     });

            // 
            setLoading(true)
            caVch01Client.lstCurrencyGet({
                ...new LstCurrency(),
                ma_cty: SessionLogin.ma_cty,
            } as LstCurrency)
                .then(res => {

                    setLoading(false);
                    setCurrencyOptions(res);
                    setData(pre => ({
                        ...pre,
                        modelM: {
                            ...pre.modelM,
                            mA_NT: res[res.length - 1].ma_nt
                        },
                    } as ModelProcessin01));
                });
        }

        // 

        // setLoading(true)
        // const body = {
        //     ma_cty: SessionLogin.ma_cty,
        //     tablelist: "dm_ncc"
        // }
        // httpPost<string>('api/Common/LoadFilterList', body)
        //     .then(res => {

        //         const customers: ICustomer[] = JSON.parse(res.data);
        //         setCustomerOptions(customers)
        //         setLoading(false)

        //     });

    }, []);

    // React.useEffect(() => {
    //     if (data?.modelM?.ma_httt === 'CK' && bankOptions.length <= 0) {
    //         caVch01Client.lstBankGet({
    //             ...new LstBank(),
    //             ma_cty: SessionLogin.ma_cty,

    //         } as LstBank)
    //             .then(res => {
    //                 setBankOptions(res)
    //             })
    //     }
    // }, [bankOptions.length, data?.modelM?.ma_httt])

    //#endregion

    //#region hanldeFilter
    React.useEffect(() => {
        if (props.filter) {
            // setLoading(true);
            // qLCClient.qLCCResidentsGet(
            //     {
            //         ...new QLCCResidentsModel(),
            //         ...props?.filter,
            //         // id: valueGet,
            //         action_type: 'AUTOCOMPLETE_SEARCH'
            //     } as QLCCResidentsModel
            // ).then(res => {
            //     setResidentOptions(res)
            //     // setInputValue(value);

            //     setLoading(false)

            // });

            // setLoading(true)
            // const body = {
            //     ma_cty: SessionLogin.ma_cty,
            //     tablelist: "dm_kh"
            // }
            // httpPost<string>('api/Common/LoadFilterList', body)
            //     .then(res => {

            //         const customers: ICustomer[] = JSON.parse(res.data);
            //         setCustomerOptions(customers)
            //         setLoading(false)

            //     });


        }
    }, [props.filter])


    //#endregion

    //#region handleGetDetailData 

    React.useEffect(() => {


        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            setLoading(true);
            inVch01Client.inVch01MGet({
                ...new ModelProcessin01(),
                modelM: {
                    apartment_building_id:props?.filter?.apartment_building_id,
                    mA_CTY: SessionLogin.ma_cty,
                    mA_CT: menu?.code_name,
                    stT_REC: props.itemSelected.stT_REC
                }

            } as ModelProcessin01).then(res => {

                setData({
                    modelM: res.lstInVch01Ms![0],
                    arrayCt: res.arrayCt,
                    // arrayCt1: res.arrayCt1
                } as ModelProcessin01)


                setLoading(false);

            }).catch(error => {
                notification(
                    TYPE_MESSAGE.WARNING,
                    STATUS_MESSAGE[`SERVER_ERROR`]
                );

                setLoading(false)
                props.onClose();
            })

            // setId(props.itemSelected);

        } else if (props.action === ACTIONS.ADD) {
            // setData(new QLCCFloorModel());
            const ngay_ct = new Date();

            commonClient.get_VoucherNo({
                ...new ParaObj(),
                ma_cty: SessionLogin.ma_cty,
                ma_ct: menu?.code_name,
                ngay_ct
            } as ParaObj)
                .then(res => {
                    if (!res) {
                        props.onClose();
                        notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                        return;
                    }
                    setLoading(false);
                    setData(pre => ({
                        ...pre,
                        modelM: {
                            ...pre.modelM,
                            apartment_building_id: props?.filter?.apartment_building_id,
                            sO_CT: res,
                            mA_CT: menu?.code_name,
                            mA_CTY: SessionLogin.ma_cty,
                            ngaY_CT: ngay_ct,
                            ngaY_LCT: new Date(),
                            cuser: SessionLogin.userName,
                            luser: SessionLogin.userName,
                            mA_GD: pre.modelM?.mA_GD ? pre.modelM?.mA_GD : exchangeOptions[0]?.ma_gd,
                            tY_GIA: 1,
                            mA_NT: pre.modelM?.mA_NT ? pre.modelM?.mA_NT : currencyOptions[currencyOptions?.length - 1]?.ma_nt,
                            // ma_httt: pre.modelM?.ma_httt ? pre.modelM?.ma_httt : paymentTermOptions[0]?.ma_httt
                        },
                    } as ModelProcessin01));

                    commonClient.get_RecNo({
                        ...new ParaObjRecNo(),
                        ma_ct: menu?.code_name,
                        ma_cty: SessionLogin.ma_cty,
                    } as ParaObjRecNo)
                        .then(res => {
                            if (!res) {
                                props.onClose();
                                notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                                return;
                            }
                            setData(pre => ({
                                ...pre,
                                modelM: {
                                    ...pre.modelM,
                                    stT_REC: res
                                },
                            } as ModelProcessin01));
                        }).catch(error => {

                            props.onClose();
                            notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                            return;

                        });

                    if (props.itemSelected?.invoice) {
                        const SO5 = props.itemSelected?.invoice;
                        setData(pre => ({
                            ...pre,
                            modelM: {
                                ...pre.modelM,
                                idcustomer: SO5?.iDcustomer,
                                mA_KH: SO5?.iDcustomer,
                            },
                        } as ModelProcessin01));
                        setMaKh(SO5?.iDcustomer)
                    }
                }).catch(error => {

                    props.onClose();
                    notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                    return;

                });




            setId(undefined)


        };

    }, [menu?.code_name, paymentTermOptions, props.action, props.itemSelected]);

    //#endregion

    //#region handleVoucherNo
    const createVoucherNo = () => {

        commonClient.get_VoucherNo({
            ...new ParaObj(),
            ma_cty: SessionLogin.ma_cty,
            ma_ct: menu?.code_name,
            ngay_ct: data?.modelM?.ngaY_LCT
        } as ParaObj)
            .then(res => {
                setData(pre => ({
                    ...pre,
                    modelM: {
                        ...pre.modelM,
                        sO_CT: res,
                    },
                } as ModelProcessin01));
            });
    }

    //#endregion

    //#region handleError

    React.useEffect(() => {
        if (!data?.modelM?.idcustomer) {

            // Thêm lỗi khi chưa chọn hóa đơn
            const errorTemp = errors ? [...errors] : [];
            if (errorTemp.length > 0) {
                errorTemp.forEach(error => {
                    if ((error.errors as Array<any>).findIndex((x: any) => x.code === 36) === -1) {
                        errorTemp.push({
                            name: 'modelM',
                            errors: [
                                {
                                    code: 36,
                                    status: 1,
                                    message: 'Bạn chưa chọn khách hàng'
                                }
                            ]
                        });
                    }
                })
            } else {
                errorTemp.push({
                    name: 'modelM',
                    errors: [
                        {
                            code: 36,
                            status: 1,
                            message: 'Bạn chưa chọn khách hàng'
                        }
                    ]
                });
            }



            setErrors(errorTemp)


        }
        else if (data?.modelM?.idcustomer) {
            // Xóa lỗi khi đã có hóa đơn
            const errorTemp = errors ? [...errors] : [];

            errorTemp.forEach((error, idxParent) => {
                if (error.name === 'modelM') {
                    const idx = error.errors.findIndex((x: any) => x.code === 36);

                    if (idx > -1) {
                        errorTemp[idxParent]
                            .errors
                            .splice(idx, 1);
                    }
                }
            });

            setErrors(errorTemp)

        }

    }, [data?.modelM?.idcustomer])

    //#endregion

    //#region HandleSubmit

    const handleSubmit =
        // React.useCallback(
        (data01D0Saved: InVch01D0[]): void => {
            // setLoading(true);



            // if (errors && errors.length > 0) {
            //     let is_error = false;
            //     [...errors].forEach(item => {
            //         if (item.errors.length > 0) {
            //             item.errors.forEach((error: any) => {
            //                 notification(status_message[error.status], error.message)
            //             })
            //             is_error = true;
            //             return;
            //         }
            //     })

            //     if (is_error) {

            //         return;
            //     }

            // }


            const totalTTMoney = [...data01D0Saved].reduce((acc, invoice) => {
                return acc + invoice.tien!;
            }, 0);

            switch (props.action) {
                case ACTIONS.ADD:

                    data01D0Saved?.map(item => {

                        item.stT_REC = data?.modelM?.stT_REC;
                        item.mA_CTY = SessionLogin.ma_cty;
                        // item.tien_tt = Number(itemChange.tien_tt);

                    })





                    setLoading(true)
                    inVch01Client.insertCommand10({
                        ...new ModelProcessin01(),
                        ...data,
                        modelM: {
                            ...data.modelM,
                            mA_CTY: SessionLogin?.ma_cty,
                            apartment_building_id: props.filter?.apartment_building_id,
                            ngaY_CT: TransDateTime(data.modelM?.ngaY_CT!),
                            ngaY_LCT: TransDateTime(data.modelM?.ngaY_LCT!),
                            t_TIEN: totalTTMoney,
                            t_TIEN_NT: totalTTMoney

                        } as InVch01M,
                        arrayCt: data01D0Saved,
                        // arrayCt1: data01D1Saved
                    } as ModelProcessin01)
                        .then(res => {
                            if (res.status === 0) {
                                // setId(res.ref_id)
                                setLoading(false);

                                props.onAddSuccess && props.onAddSuccess({
                                    ...data.modelM,
                                    mA_CTY: SessionLogin?.ma_cty,
                                    apartment_building_id: props.filter?.apartment_building_id,
                                    ngaY_CT: TransDateTime(data.modelM?.ngaY_CT!),
                                    ngaY_LCT: TransDateTime(data.modelM?.ngaY_LCT!),
                                    t_TIEN: totalTTMoney,
                                    t_TIEN_NT: totalTTMoney

                                } as CaVch01M);
                                props.onClose();
                                notification(status_message[res.status!], STATUS_MESSAGE[`${props.action}_${res.status}`]);

                                setData(new ModelProcessin01())
                            } else if (res.status === -1) {
                                notification(status_message[1], STATUS_MESSAGE["SAME_NUMBER_VOUCHER_1"]);
                                // Tạo lại số chứng từ
                                createVoucherNo();
                                setLoading(false);

                            }

                            else {
                                notification(status_message[res.status!], STATUS_MESSAGE[`${props.action}_${res.status}`]);

                                setLoading(false);
                            }
                        }).catch((error: any) => {
                            notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                            setLoading(false);
                        })
                    break;
                case ACTIONS.EDIT:
                    // data01D1Saved?.map(item => {


                    // item.ngay_hd = moment(item.ngay_hd!).toDate();
                    // item.ngay_ct = moment(item.ngay_ct!).toDate();
                    // item.tien_tt = Number(itemChange.tien_tt);

                    // });

                    setLoading(true)
                    inVch01Client.updateCommand10({
                        ...new ModelProcessin01(),
                        ...data,
                        modelM: {
                            ...data.modelM,
                            t_TIEN: totalTTMoney,
                            t_TIEN_NT: totalTTMoney,
                            ngaY_CT: TransDateTime(data.modelM?.ngaY_CT!),
                            ngaY_LCT: TransDateTime(data.modelM?.ngaY_LCT!),

                        } as InVch01M,
                        arrayCt: data01D0Saved,
                        // arrayCt1: data01D1Saved
                    } as ModelProcessin01)
                        .then(res => {
                            notification(status_message[res.status!], STATUS_MESSAGE[`${props.action}_${res.status}`]);

                            if (res.status === 0) {
                                // setId(res.ref_id)
                                setLoading(false);
                                props.onEditSuccess && props.onEditSuccess({
                                    ...data.modelM,
                                    t_TIEN: totalTTMoney,
                                    t_TIEN_NT: totalTTMoney,
                                    ngaY_CT: TransDateTime(data.modelM?.ngaY_CT!),
                                    ngaY_LCT: TransDateTime(data.modelM?.ngaY_LCT!),

                                } as CaVch01M);
                                props.onClose();
                                setData(new ModelProcessin01())
                            } else {
                                notification('error', res.message);
                                setLoading(false);
                            }
                        }).catch((error: any) => {
                            notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                            setLoading(false);
                        })
                    break;
                default:
                    setLoading(false);
            }

            // setOperations([])
            // setIsPost(false)
            // setIsSavedD0("NOT-EXISTS-D0")
            // setIsSavedD1("NOT-EXISTS-D1")



        }
    // , []);

    //#endregion


    // React.useEffect(() => {


    // commonClient.get_VoucherNo({
    //     ...new ParaObj(),
    //     ma_cty: SessionLogin.ma_cty,
    //     ma_ct: 'CA1',
    //     ngay_ct: data?.modelM?.ngaY_CT
    // } as ParaObj)
    //     .then(res => {
    //         setData(pre => ({
    //             ...pre,
    //             modelM: {
    //                 ...pre.modelM,
    //                 sO_CT: res,
    //             },
    //         } as ModelProcessin01));
    //     })

    // }, [data?.modelM?.ngaY_CT])



    //#region handleChange

    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        const date = moment(value).toDate();
        if (date instanceof Date && !isNaN(date.valueOf())) {
            setData(pre => ({
                ...pre, modelM: {
                    ...pre.modelM,
                    [name]: new Date(value)
                }
            } as ModelProcessin01));
        }
        // setData({ ...data, [name]: new Date(value) } as SoVch05M);
    }

    const handleChangeInput = (e: any) => {
        const { name, value, apartment_id } = e.target;



        // if ((Array.isArray(value) && Array.isArray(name))) {
        //     const tempData = { ...data };
        //     name.forEach((name_, idx) => {
        //         Object.assign(tempData, {
        //             ...tempData,
        //             [name_]: value[idx]
        //         })
        //     })

        //     setData(tempData as SoVch05M)

        // }
        // else 
        setData(pre => ({
            ...pre, modelM: {
                ...pre.modelM,
                [name]: value
            }
        } as ModelProcessin01));

        // Nếu là khách hàng thì set thêm apartment id

        // setData(pre => ({ ...pre, apartment_id } as SoVch05M))

    };



    const handleChangeCurrentInput = (
        value: string | undefined, name?: string | undefined, values?: CurrencyInputOnChangeValues | undefined) => {

        setData(pre => ({
            ...pre, modelM: {
                ...pre.modelM,
                [name!]: value
            }
        } as ModelProcessin01));
    }

    // Xử lý Print
    const handlePrintAction = () => {
        setActPrint({
            ...actPrint,
            open: true,
            type: ACTIONS.EMPTY,
            payload: data?.modelM // pass the data here
        })
    }

    //#endregion


    //#region handleClickAction
    const handleClickAction = async (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                if (!data?.modelM?.idcustomer) {
                    notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["CUSTOMER_NULL"])
                    return;
                }

                const result = await commonClient.check_VoucherNo({
                    ...new ParaObjCheck(),
                    ma_ct: menu?.code_name,
                    ngay_ct: TransDateTime(data?.modelM?.ngaY_LCT!),
                    ma_cty: SessionLogin.ma_cty,
                    stt_rec: data?.modelM?.stT_REC,
                    voucherno: data?.modelM?.sO_CT
                } as ParaObjCheck);

                if (!result) {

                    // 
                    // var data01D1Saved: CaVch01D1[] = [];
                    var data01D0Saved: InVch01D0[] = [];

                    if (dataGridRef01D0.current) {
                        await dataGridRef01D0.current.instance.saveEditData();
                        await dataGridRef01D0.current!.instance.refresh();
                        data01D0Saved = dataGridRef01D0.current!.instance.getDataSource().items();



                        // setData01D1(data01D1Saved);

                        // setData(pre => ({
                        //     ...pre, modelM: {
                        //         ...pre.modelM,
                        //         t_TIEN_NT: totalTTMoney,
                        //         t_TIEN: totalTTMoney
                        //     }
                        // } as ModelProcessin01))
                    } else {
                        data01D0Saved = [...data01D0];



                    }

                    //Xữ lý lại trước khi lưu

                    // data01D1Saved.map(dt01D1 => {
                    //     dt01D1.tt = dt01D1.ty_gia! * dt01D1.tt_nt!;
                    // });

                    // const totalTTMoney = data01D1Saved.reduce((acc: any, invoice: any) => {
                    //     return acc + invoice.tt_nt!;
                    // }, 0);


                    var index01D0 = -1;
                    // index01D0 = data01D0Saved.findIndex(x => {
                    //     if (x.ma_ct_hd && data01D1Saved[0]?.ma_ct && x.ma_ct_hd === data01D1Saved[0]?.ma_ct) {
                    //         return true;
                    //     }
                    //     return false;
                    // });

                    // if (index01D0 > -1) {
                    //     data01D0Saved[index01D0].pS_CO_NT = totalTTMoney;
                    //     data01D0Saved[index01D0].pS_CO = totalTTMoney;
                    // }

                    data01D0Saved?.map(dt01D0 => {
                        dt01D0.tien = dt01D0.tieN_NT! * (data?.modelM?.tY_GIA ?? 1);
                        dt01D0.gia = dt01D0.giA_NT! * (data?.modelM?.tY_GIA ?? 1);
                    });


                    handleSubmit(data01D0Saved);

                }
                else {
                    notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SAME_NUMBER_VOUCHER_1"]);
                    // Tạo lại số chứng từ
                    createVoucherNo();
                }



                break;
            case ACTIONS.PRINT:
                handlePrintAction();
                break;

            case ACTIONS.CLOSE:
                props.onClose();
                setData(new ModelProcessin01())

                break;
        }
    }


    //#endregion


    return (
        <div>

            {/* {
                actPrint.open && <ReceiptPrintAction
                    open={actPrint.open}
                    onClose={function (): void {
                        setActPrint(defaultAction)
                    }}
                    onEditSuccess={function (is_success): void {
                        setReload(pre => !pre)
                    }}
                    action={actPrint.type}
                    itemSelected={actPrint.payload} >

                </ReceiptPrintAction>

            } */}
            <Dialog
                // id={!fullScreen ? "sysMenu84" : ""}
                fullScreen={fullScreen}
                open={props.open}
                onClose={() => {
                    props.onClose()
                    setData(new ModelProcessin01())
                }}
                // sx={{
                //     display: "flex",
                //     justifyContent: "center",
                // }}
                TransitionComponent={Transition}
                // fullWidth
                maxWidth="xl"
                fullWidth
                className="Asset-control"

            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            {
                                menu?.bar +
                                " " +
                                `${props.action === ACTIONS.VIEW
                                    ? "[Xem chi tiết]"
                                    : props.action === ACTIONS.EDIT || _id
                                        ? "[Cập nhật]"
                                        : "[Thêm mới]"
                                }`
                            }
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1.25 }}>
                            <IconButton
                                edge="end"

                                onClick={() => {
                                    setFullscreen(pre => !pre)
                                }}
                                aria-label="full screen"
                            >
                                {fullScreen ? <FullscreenExitIcon sx={{ color: '#1f2937' }} /> : <FullscreenIcon sx={{ color: '#1f2937' }} />}
                            </IconButton>
                            <IconButton
                                edge="end"
                                onClick={() => {
                                    props.onClose();
                                    setData(new ModelProcessin01())
                                }}
                                aria-label="close"
                            >
                                <CloseIcon
                                    sx={{ color: '#1f2937' }}
                                />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent>
                    <Accordion className="Asset-control" color="#1565C0" expanded={isExpandReceiptAction} onChange={() => { setIsCalledReceiptAction(true); setIsExpandReceiptAction(pre => !pre) }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ backgroundColor: "#e0f2fe" }}
                        >
                            <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Thông tin chung</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="row gutter-1">
                                <div className="col-xl-4">

                                    <div className="card">

                                        <div className="card-body pt-2">
                                            <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                                Thông tin chung
                                            </div>
                                            {/* Loại nhập */}
                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Loại nhập</InputLabel>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <DHSAutoCompleteWithDataSource
                                                        id="pay-type-select"
                                                        dataSource={exchangeOptions}
                                                        displayMember="mo_ta"
                                                        valueMember="ma_gd"
                                                        // title={}
                                                        value={data?.modelM?.mA_GD}
                                                        onValueChanged={(e, newValue: any) => {

                                                            handleChangeInput({
                                                                target: {
                                                                    name: 'mA_GD',
                                                                    value: newValue?.ma_gd ?? null
                                                                }
                                                            })
                                                        }}


                                                        readOnly={props.action === ACTIONS.VIEW}
                                                    /> 

                                                </Grid>
                                            </Grid>
                                            {/* Nhà cung cấp */}

                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel required htmlFor="resident-select" sx={{ fontSize: 14, color: '#333' }}>Nhà cung cấp</InputLabel>
                                                    {/* <DHSAutoCompleteResidentAntd
                                                            name="idcustomer"
                                                            onChange={(value, option) => {
                                                                
                                                            }}
                                                            value={data?.modelM?.idcustomer ?? null}
                                                            filter={props.payload} 
                                                            placeholder="Chọn khách hàng"
                                                           readOnly={props.action === ACTIONS.VIEW}      
                                                        /> */}
                                                </Grid>
                                                <Grid item xs={12} md={8}>

                                                    <DHSDataGridFilterListByProjectSelect
                                                        id="customer-select"
                                                        value={data?.modelM?.idcustomer ?? null}
                                                        table_list="dm_ncc"
                                                        // placeholder="Chọn khách hàng"
                                                        // filter={props.payload}
                                                        name="idcustomer"
                                                        dataGridOnSelectionChanged={(e, newValue: any) => {
                                                            handleChangeInput({
                                                                target: {
                                                                    name: "idcustomer",
                                                                    value: newValue?.idcustomer ?? null,
                                                                },
                                                            });

                                                            setMaKh(newValue?.idcustomer);


                                                            setData(pre => ({
                                                                ...pre, modelM: {
                                                                    ...pre.modelM,
                                                                    teN_KH: newValue?.ten_kh ?? null,
                                                                    mA_KH: newValue?.ma_kh ?? null
                                                                }
                                                            } as ModelProcessin01));
                                                        }}
                                                        syncDataGridSelection={function (e): void {
                                                            setData(pre => ({ ...pre, modelM: { ...pre.modelM, idcustomer: e.value } } as ModelProcessin01));
                                                            setMaKh(e.value);

                                                            // if (!maKH) {
                                                            //     setLoading(true)
                                                            //     const body = {
                                                            //         ma_cty: SessionLogin.ma_cty,
                                                            //         tablelist: "dm_ncc"
                                                            //     }
                                                            //     httpPost<string>('api/Common/LoadFilterList', body)
                                                            //         .then(res => {

                                                            //             const customers: ICustomer[] = JSON.parse(res.data);
                                                            //             setCustomerOptions(customers)
                                                            //             setLoading(false)

                                                            //         });


                                                            // }
                                                        }}
                                                        gridBoxDisplayExpr={function (item: any): string {
                                                            return item && `${item.ma_kh} - ${item.ten_kh}`;
                                                        }}

                                                        readOnly={props.action === ACTIONS.VIEW}
                                                    />
                                                    {/* <DHSAutoCompleteWithDataGrid
                                                        id="resident-select"
                                                        value={data?.modelM?.idcustomer ?? null}
                                                        table_name="DHSGridResidentSearch"
                                                        placeholder="Chọn khách hàng"
                                                        // filter={props.payload}
                                                        name="idcustomer"
                                                        valueExpr={"id"}
                                                        options={residentOptions}
                                                        dataGridOnSelectionChanged={(e, newValue: any) => {
                                                            handleChangeInput({
                                                                target: {
                                                                    name: "idcustomer",
                                                                    value: newValue?.id ?? null,
                                                                },
                                                            });

                                                            setMaKh(newValue?.apartment_id);


                                                            setData(pre => ({
                                                                ...pre, modelM: {
                                                                    ...pre.modelM,
                                                                    "teN_KH": newValue?.full_name ?? null
                                                                }
                                                            } as ModelProcessin01));
                                                        }}
                                                        syncDataGridSelection={function (e): void {
                                                            setData(pre => ({
                                                                ...pre, modelM: {
                                                                    ...pre.modelM,
                                                                    idcustomer: e.value
                                                                }
                                                            } as ModelProcessin01));

                                                            if (!maKH) {
                                                                qLCClient.qLCCResidentsGet(
                                                                    {
                                                                        ...new QLCCResidentsModel(),
                                                                        ...props?.filter, // Chỉ lấy tên không lấy apartment_name
                                                                        id: e.value,
                                                                        action_type: 'AUTOCOMPLETE_SEARCH'
                                                                    } as QLCCResidentsModel
                                                                ).then(res => {
                                                                    setMaKh(res[0].apartment_id)
                                                                    // setInputValue(value);


                                                                    console.log(res)
                                                                });
                                                            }
                                                        }}
                                                        gridBoxDisplayExpr={function (item: any): string {
                                                            return item && `${item?.full_name} - ${item?.apartment_name ?? "Chưa đăng kí"}`;
                                                        }}

                                                       readOnly={props.action === ACTIONS.VIEW}
                                                    /> */}



                                                </Grid>
                                            </Grid>



                                            {/* Địa chỉ */}

                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Địa chỉ</InputLabel>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <TextField
                                                        className="cc-input"
                                                        sx={{
                                                            width: "100%",
                                                            marginRight: "5px",
                                                        }}
                                                        title={data?.modelM?.diA_CHI}
                                                        // placeholder="Địa chỉ hiện tại"
                                                        name="diA_CHI"
                                                        onChange={handleChangeInput}
                                                        value={data?.modelM?.diA_CHI}
                                                        inputProps={{
                                                            readOnly: props.action === ACTIONS.VIEW

                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            {/* Người giao dịch */}

                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Người giao dịch</InputLabel>

                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <TextField
                                                        className="cc-input"
                                                        sx={{
                                                            width: "100%",
                                                            marginRight: "5px",
                                                        }}
                                                        name="nguoI_GD"
                                                        title={data?.modelM?.nguoI_GD}

                                                        // placeholder="Nguyễn Văn A"
                                                        onChange={handleChangeInput}
                                                        value={data?.modelM?.nguoI_GD}
                                                        inputProps={{
                                                            readOnly: props.action === ACTIONS.VIEW

                                                        }}
                                                    />

                                                </Grid>
                                            </Grid>
                                            {/* Diễn giải */}

                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Diễn giải</InputLabel>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <TextField
                                                        className="cc-input"
                                                        sx={{
                                                            width: "100%",
                                                            marginRight: "5px",
                                                        }}
                                                        name="dieN_GIAI"
                                                        title={data?.modelM?.dieN_GIAI}

                                                        // placeholder="Thu phí chung cư tháng 5/2023"
                                                        onChange={handleChangeInput}
                                                        value={data?.modelM?.dieN_GIAI}
                                                        onFocus={event => {
                                                            event.target.select();
                                                        }}
                                                        inputProps={{
                                                            readOnly: props.action === ACTIONS.VIEW

                                                        }}
                                                    />

                                                </Grid>
                                            </Grid>
                                            {/* Hình thức thanh toán */}
                                            {/* <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel title="Hình thức thanh toán" sx={{ fontSize: 14, color: '#333' }}>Hình thức thanh toán</InputLabel>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Autocomplete
                                                        id="pay-type-select"
                                                        sx={{ width: "100%" }}
                                                        // defaultValue={paymentTermOptions[0]}
                                                        options={paymentTermOptions}
                                                        autoHighlight
                                                        getOptionLabel={(option: any) => option?.ten_httt}
                                                        value={
                                                            data?.modelM?.ma_httt
                                                                ? paymentTermOptions.find(
                                                                    (item: any) => item?.ma_httt === data?.modelM?.ma_httt
                                                                )
                                                                : null
                                                        }
                                                        onChange={(e, newValue: any) => {

                                                            handleChangeInput({
                                                                target: {
                                                                    name: 'ma_httt',
                                                                    value: newValue?.ma_httt ?? null
                                                                }
                                                            });
                                                            // setPaymentMethodCode(newValue?.ma_httt);

                                                            if (newValue?.ma_httt === 'TM') {
                                                                setData(pre => ({
                                                                    ...pre, modelM: {
                                                                        ...pre.modelM,
                                                                        mA_NGH: undefined,

                                                                    }
                                                                } as ModelProcessin01));
                                                            }
                                                        }}

                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                className="cc-input"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    autoComplete: "new-password", // disable autocomplete and autofill
                                                                }}
                                                            />
                                                        )}
                                                        readOnly={props.action === ACTIONS.VIEW}
                                                    />

                                                </Grid>
                                            </Grid> */}

                                            {/* Tài khoản nợ */}

                                            {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tài khoản nợ</InputLabel>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            name="address"
                                            onChange={handleChangeInput}
                                            value={null}
                                           readOnly={props.action === ACTIONS.VIEW}
                                        />

                                    </Box> */}
                                            {/*Tiền */}
                                            {/* 
                                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tiền</InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100%", textAlign: "right" }}
                                            allowDecimals
                                            decimalsLimit={2}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            decimalScale={0}
                                            placeholder="Tổng tiền"
                                            intlConfig={{ locale: 'vi-VN', currency: 'VND' }}
                                            name="t_TT"
                                            onValueChange={handleChangeCurrentInput}
                                            value={data.modelM?.t_TIEN_NT}
                                            disabled={true}
                                        />

                                    </Box> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4">

                                    <div className="card mb-2">

                                        <div className="card-body pt-2">
                                            <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                                Thông tin chứng từ
                                            </div>
                                            {/* Ngày hạch toán */}
                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày hạch toán</InputLabel>

                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <CTXDate
                                                        className="cc-input"
                                                        style={{ width: '100%', padding: '0 5px !important' }}
                                                        name="ngaY_CT"
                                                        onChange={(e) => {
                                                            handleChangeDate(e)
                                                            commonClient.get_VoucherNo({
                                                                ...new ParaObj(),
                                                                ma_cty: SessionLogin.ma_cty,
                                                                ma_ct: menu?.code_name,
                                                                ngay_ct: e?.value
                                                            } as ParaObj)
                                                                .then(res => {
                                                                    setData(pre => ({
                                                                        ...pre,
                                                                        modelM: {
                                                                            ...pre.modelM,
                                                                            sO_CT: res,
                                                                        },
                                                                    } as ModelProcessin01));
                                                                })
                                                        }}
                                                        value={data?.modelM?.ngaY_CT}
                                                        // title={data?.modelM?.ngaY_CT}

                                                        readOnly={props.action === ACTIONS.VIEW}
                                                    />
                                                </Grid>
                                            </Grid>

                                            {/* Số chứng từ */}

                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số chứng từ</InputLabel>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <TextField
                                                        className="cc-input"
                                                        sx={{
                                                            width: "100%",
                                                            marginRight: "5px",
                                                        }}
                                                        inputProps={{
                                                            style: {
                                                                textAlign: 'left'
                                                            },
                                                            readOnly: props.action === ACTIONS.VIEW

                                                        }}
                                                        title={data?.modelM?.sO_CT}
                                                        type="text"
                                                        name="sO_CT"
                                                        onChange={handleChangeInput}
                                                        value={data?.modelM?.sO_CT}

                                                    />
                                                </Grid>
                                            </Grid>
                                            {/* Ngày chứng từ */}
                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày chứng từ</InputLabel>

                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <CTXDate
                                                        className="cc-input"
                                                        style={{ width: '100%', padding: '0 5px !important' }}
                                                        name="ngaY_LCT"
                                                        onChange={(e) => {
                                                            handleChangeDate(e);


                                                        }}
                                                        value={data.modelM?.ngaY_LCT}
                                                        readOnly={props.action === ACTIONS.VIEW}
                                                    // disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>

                                    <div className="card mb-2">

                                        <div className="card-body pt-2">
                                            <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                                Thông tin tiền tệ
                                            </div>
                                            <div className="d-flex" style={{ gap: 5 }}>
                                                {/* Loại tiền */}
                                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                    <Grid item xs={12} md={4}>
                                                        <InputLabel id="nt-select-label" sx={{ fontSize: 14, color: '#333' }}>Loại tiền</InputLabel>

                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <DHSAutoCompleteWithDataSource
                                                            id="currency-select"
                                                            dataSource={currencyOptions}
                                                            displayMember="ma_nt"
                                                            valueMember="ma_nt"
                                                            value={                                                                data?.modelM?.mA_NT }
                                                            onValueChanged={(e, newValue: any) => {
                                                                handleChangeInput({
                                                                    target: {
                                                                        name: "mA_NT",
                                                                        value: newValue?.ma_nt ?? null,
                                                                    },
                                                                });
                                                                commonClient.getExchangeRate5({
                                                                    ...new ParaObjExchageRate(),
                                                                    ma_cty: SessionLogin.ma_cty,
                                                                    ma_nt: newValue?.ma_nt,
                                                                    ngay_ct: data?.modelM?.ngaY_CT
                                                                } as ParaObjExchageRate)
                                                                    .then(res => {
                                                                        if (!res) {
                                                                            setData(pre => ({ ...pre, modelM: { ...pre.modelM, tY_GIA: 0 } } as ModelProcessin01))

                                                                        }
                                                                        else setData(pre => ({ ...pre, modelM: { ...pre.modelM, tY_GIA: res } } as ModelProcessin01))
                                                                    })

                                                            }}

                                                            readOnly={props.action === ACTIONS.VIEW}
                                                        /> 
                                                    </Grid>
                                                </Grid>
                                                {/* Tỷ giá */}
                                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 7px" }}>
                                                    <Grid item xs={12} md={4}>
                                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tỷ giá</InputLabel>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <NumberBox
                                                            // className="cc-input"
                                                            className="cc-input w-100 flex-grow-1 form-control currency-input p-0 pr-2"

                                                            format="0,###"
                                                            inputAttr={{ 'aria-label': 'Fixed Poing Format', 'style': 'text-align:right' }}
                                                            defaultValue={data?.modelM?.tY_GIA}
                                                            value={data?.modelM?.tY_GIA}

                                                            // style={{
                                                            //     textAlign: "right"
                                                            // }}
                                                            onValueChange={(value) => {
                                                                handleChangeInput({
                                                                    target: {
                                                                        name: "tY_GIA",
                                                                        value: value
                                                                    }
                                                                });


                                                            }}

                                                            readOnly={props.action === ACTIONS.VIEW}

                                                        />
                                                        {/* <CurrencyTextField
                                                            className="flex-grow-1 form-control currency-input"
                                                            style={{ width: "100%", textAlign: "right" }}
                                                            allowDecimals
                                                            decimalsLimit={2}
                                                            decimalSeparator="."
                                                            groupSeparator=","
                                                            decimalScale={0}
                                                            // placeholder="Tỷ giá"
                                                            intlConfig={{ locale: 'vi-VN', currency: 'VND' }}
                                                            name="tY_GIA"
                                                            onValueChange={handleChangeCurrentInput}
                                                            value={data?.modelM?.tY_GIA}
                                                            readOnly={props.action === ACTIONS.VIEW}
                                                        /> */}

                                                        {/* <TextField
                                                                className="cc-input"
                                                                sx={{
                                                                    width: "100%",
                                                                    marginRight: "5px",
                                                                }}
                                                                inputProps={{
                                                                    style: {
                                                                        textAlign: 'right'
                                                                    }
                                                                }}
                                                                type="number"
                                                                name="tY_GIA"
                                                                onChange={handleChangeInput}
                                                                value={data?.modelM?.tY_GIA}
                                                               readOnly={props.action === ACTIONS.VIEW}
                                                            /> */}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>


                                    </div>



                                </div>

                                <div className="col-xl-4">

                                    {/* { */}
                                    {/* data?.modelM?.ma_httt === 'CK' && <div className="card">

                                             <div className="card-body pt-2">
                                                 <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                                     Thông tin ngân hàng
                                                 </div> */}
                                    {/* Số tài khoản */}
                                    {/* <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                    <Grid item xs={12} md={4}>
                                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số tài khoản</InputLabel>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <Autocomplete
                                                            id="bank-select"
                                                            sx={{ width: "100%" }}
                                                            options={bankOptions}
                                                            autoHighlight
                                                            getOptionLabel={(option: any) => option?.banknumber}
                                                            value={
                                                                data?.modelM?.mA_NGH
                                                                    ? bankOptions?.find(
                                                                        (item: any) => item.banknumber === data.modelM?.mA_NGH
                                                                    )
                                                                    : null
                                                            }
                                                            onChange={(e, newValue: any) => {
                                                                handleChangeInput({
                                                                    target: {
                                                                        name: "mA_NGH",
                                                                        value: newValue?.banknumber ?? null,
                                                                    },
                                                                });

                                                                setBankCode(newValue?.ma_ngh);
                                                                setBankName(newValue?.ten_ngh);
                                                            }}

                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    className="cc-input"
                                                                    // placeholder="Số tài khoản"
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        autoComplete: "new-password", // disable autocomplete and autofill
                                                                    }}
                                                                />
                                                            )}
                                                            readOnly={props.action === ACTIONS.VIEW}
                                                        />

                                                    </Grid>
                                                </Grid> */}

                                    {/* Ngân hàng */}

                                    {/* <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                    <Grid item xs={12} md={4}>
                                                        <InputLabel htmlFor="address" sx={{ fontSize: 14, color: '#333' }}>Ngân hàng</InputLabel>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <div className="row">
                                                            <div className="col-xl-4 pr-0">
                                                                <TextField
                                                                    id="bank-code"
                                                                    className="cc-input"
                                                                    sx={{
                                                                        width: "100%",
                                                                        marginRight: "5px",
                                                                    }}
                                                                    name="bank-code"
                                                                    onChange={(e) => {
                                                                        setBankCode(e.target.value)
                                                                    }}
                                                                    title={data?.modelM?.mA_NGH}

                                                                    // placeholder="Mã ngân hàng"
                                                                    value={data?.modelM?.mA_NGH ? bankCode : null}
                                                                    inputProps={{
                                                                        readOnly: true
                                                                    }}
                                                                />

                                                            </div>
                                                            <div className="col-xl-8 pl-1">
                                                                <TextField
                                                                    className="cc-input"
                                                                    sx={{
                                                                        width: "100%",
                                                                        marginRight: "5px",
                                                                    }}
                                                                    name="bank-name"
                                                                    // placeholder="Tên ngân hàng"
                                                                    onChange={(e) => {
                                                                        setBankName(e.target.value)
                                                                    }}
                                                                    title={bankName}

                                                                    value={data?.modelM?.mA_NGH ? bankName : null}
                                                                    inputProps={{
                                                                        readOnly: true
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid> */}

                                    {/* </div> */}


                                    {/* } */}
                                </div>



                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="Asset-control" expanded={isExpandReceiptDAction} onChange={() => { setIsCalledCaVchD(true); setIsExpandReceiptDAction(pre => !pre) }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            sx={{ backgroundColor: "#e0f2fe" }}
                        >
                            <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Thông tin chi tiết</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="row">
                                <div className="col-12 p-0">
                                    {<ReceivingVoucherDetail
                                        action={props.action}
                                        exchange={data?.modelM?.mA_GD}
                                        arrayCt={data?.arrayCt}
                                        // arrayCt1={data?.arrayCt1}
                                        dataInvoice={props.itemSelected?.invoice && [props.itemSelected?.invoice]}
                                        // passArrayCT1={(dataCT1: CaVch01D1[]) => setData01D1(pre => ([...dataCT1]))}
                                        passArrayCT={(dataCT: InVch01D0[]) => {

                                            dataCT.forEach(ct => {

                                                setData(pre => ({
                                                    ...pre,
                                                    modelM: {
                                                        ...pre.modelM,

                                                        dieN_GIAI: pre?.modelM?.mA_GD === "3" ? (ct?.dieN_GIAI ? ct?.dieN_GIAI : null) : data?.modelM?.dieN_GIAI,

                                                    }
                                                } as ModelProcessin01))

                                            })

                                            setData01D0(pre => ([...dataCT]));
                                        }}
                                        maKH={maKH}
                                        apartment_building_id={props.filter?.apartment_building_id}
                                        idcustomer={data?.modelM?.idcustomer}
                                        dataGridRef01D1={dataGridRef01D1}
                                        dataGridRef01D0={dataGridRef01D0}
                                        // onDataGridD0Saved={(saved) => {
                                        //     const tempOparations = new Set([...operations]);
                                        //     tempOparations.add(saved);
                                        //     setOperations(Array.from(tempOparations));
                                        //     setIsSavedD0(saved)
                                        // }}

                                        // onDataGridD1Saved={(saved) => {
                                        //     const tempOparations = new Set([...operations]);
                                        //     tempOparations.add(saved);
                                        //     setOperations(Array.from(tempOparations));
                                        //     setIsSavedD1(saved)
                                        // }}

                                        totalTTMoney={function (totalTTMoney?: number | undefined): void {
                                            if (totalTTMoney) {
                                                setData(pre => ({
                                                    ...pre, modelM: {
                                                        ...pre.modelM,
                                                        t_TIEN_NT: totalTTMoney * (data?.modelM?.tY_GIA ?? 1),
                                                        t_TIEN: totalTTMoney * (data?.modelM?.tY_GIA ?? 1)
                                                    }
                                                } as ModelProcessin01))
                                            }

                                        }}
                                        stt_rec={data?.modelM?.stT_REC}
                                        onError={(errorHelper) => {

                                            errorHelper?.forEach(item => {
                                                item?.errors?.forEach((error: any) => {
                                                    notification(status_message[error.status], error.message)
                                                });
                                            })

                                            setErrors(errorHelper)
                                        }}
                                    />}
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>

                </DialogContent>
                <DialogActions>

                    <DHSDialogAction

                        action={props.action}
                        menu={menu}
                        onClickAction={handleClickAction}

                    />
                </DialogActions>
                <BlockUI blocking={isLoading} title={""}></BlockUI>
            </Dialog>
        </div>
    );
};

export default ReceivingVoucherAction;
