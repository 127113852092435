import React from "react";
import {
    Button,
    TextField,
    MenuItem,
    Dialog,
    DialogContent,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Grid,
    Paper,
    DialogActions,
} from "@mui/material";
import "../notification-action/NotificationsActions.css";
import {
    ParaObjTableList,
    QLCCCategoriesModel,
    QLCCClient,
    QLCCNewsModel,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import JoditEditor from "jodit-react";
import CategoriesActions from "../notification-category-action/CategoriesActions";
import { UploadFile } from "../notification-action/UploadFile";
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import { UpdateAvatar } from "./UploadAvatar";
import moment from "moment";
import { httpPost } from "../../../../common/httpService";
import DHSSelectBox from "../../../../components/DHS/DHS_SelectBox";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
}
interface File {
    id?: string,
    ma_cty?: string,
    stt_rec?: string,
    ma_ct?: string,
    ngay_ct?: string,
    so_ct?: string,
    name_file?: string,
    path?: string,
    file_extension?: string,
    size?: string,
    date_modified?: string,
    author_id?: string,

}
interface IActions {
    type: string,
    open: boolean,
    payload: string,
}

interface IStatus {
    id: string | undefined,
    code: string | undefined,
    name: string | undefined,
    name_us: string | undefined,
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});
var appSession: AppSession;

const NewActons = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [reload, setReload] = React.useState(false);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const qlCCClient = new QLCCClient(appSession, BASE_URL_API);
    const [config, setConfig] = React.useState<any>();
    const [data, setData] = React.useState<QLCCNewsModel>({} as QLCCNewsModel);
    const [category, setCategory] = React.useState<QLCCCategoriesModel[]>([]);
    //=================> Open Dialog Category  <=============================//
    const [actt, setActt] = React.useState<IActions>({
        type: "",
        open: false,
        payload: ""
    })
    const [path, setPath] = React.useState<string[]>([]);
    const [avatar, setAvatar] = React.useState<string[]>([]);

    const [status, setStatus] = React.useState<IStatus[]>([]);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
    const [newContent, setNewContext] = React.useState<string>();

    React.useEffect(() => {
        const body = {
            ... new ParaObjTableList(),
            ma_cty: SessionLogin.ma_cty,
            tablelist: 'dm_trangthai_news'
        } as ParaObjTableList
        httpPost<string>('/api/Common/LoadDatasourceBindControlByApartmentBuilding', {
            ma_cty: SessionLogin?.ma_cty,
            tablelist: body?.tablelist,
            apartment_building_id: apartment_building_id
        })
            .then(res => {
                console.log(JSON.parse(res.data))
                const serviceGroups: any = JSON.parse(res.data);
                if (serviceGroups) {
                    try {
                        setStatus(serviceGroups.Table1);
                    } catch (ex) {
                        alert('Tải danh sách trạng thái gặp trục trặc')
                    }
                }
            }).catch((ex) => {
                console.log(ex)
            });


        // httpPost<string>('api/Common/LoadFilterList', body)
        //     .then(result => {
        //         const lstStatus: any = JSON.parse(result.data);
        //         if (lstStatus) {
        //             setStatus(lstStatus);
        //         }
        //     });
    }, [])


    React.useEffect(() => {
        if (props.open) {
            setLoading(true)
            if (props.action !== "ADD") {
                qlCCClient
                    ?.qLCCNewsGet({
                        ...new QLCCNewsModel(),
                        id: props.itemSelected,
                        action_type: "GET_ALL",
                        apartment_building_id: apartment_building_id,
                        ma_cty: SessionLogin.ma_cty,
                    } as QLCCNewsModel)
                    .then((res) => {
                        const getNews = res[0];
                        if (getNews) {
                            setNewContext(getNews.content)
                            setData(getNews);
                            if (getNews.avatar) {
                                setAvatar(getNews.avatar.split(';'));
                            } else {
                                setAvatar([]);
                            }
                            if (getNews.file_id) {
                                setPath(getNews.file_id.split(';'));
                            } else {
                                setPath([]);
                            }
                        }
                        setLoading(false);
                    })
            }
            else {
                setData({
                    ...new QLCCNewsModel(),
                    date_modified: new Date(),
                    status_id: "1"
                } as QLCCNewsModel);
                setAvatar([]);
                setPath([]);
                setLoading(false);
            }
        }else{
            setNewContext(undefined);
        }
    }, [props.open])

    React.useEffect(() => {
        qlCCClient
            ?.qLCCCategoriesActions({
                ...new QLCCCategoriesModel(),
                status_id: '1',
                action_type: "GET-NEWS",
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCCategoriesModel)
            .then((res) => {
                setCategory(res)
            })
    }, [props.action, reload])

    const handleChangeInput = (e: any) => {
        if (props.action !== ACTIONS.VIEW) {
            const { name, value } = e.target;
            setData({
                ...data,
                [name]: value
            } as QLCCNewsModel);
        }
    }

    const handleSubmit = (e: any) => {
        if (props.action === ACTIONS.ADD) {
            qlCCClient
                ?.qLCCNewsIns({
                    ...data,
                    content: newContent,
                    author_id: SessionLogin.userName,
                    author_name: SessionLogin.fullName,
                    action_type: "INSERT",
                    file_id: path.join(';'),
                    avatar: avatar.join(';'),
                    date_modified: moment(data.date_modified).utc(true).toDate(),
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty,
                } as QLCCNewsModel)
                .then((res) => {
                    props.onClose();
                    props.onEditSuccess(true)
                }).catch((error) => {
                    props.onEditSuccess(true)
                })
        } else if (props.action === ACTIONS.EDIT) {
            qlCCClient
                ?.qLCCNewsUpd({
                    ...data,
                    content: newContent,
                    author_id: SessionLogin.userName,
                    author_name: SessionLogin.fullName,
                    action_type: "UPDATE",
                    file_id: path.join(';'),
                    avatar: avatar.join(';'),
                    date_modified: moment(data.date_modified).utc(true).toDate(),
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty,
                } as QLCCNewsModel)
                .then((res) => {
                    props.onClose();
                    props.onEditSuccess(true)
                })
                .catch((error) => {
                    props.onEditSuccess(true)
                })
        }
    }
 

    const classLabel = { fontSize: 14, fontWeight: "600", color: "#333" }

    return (
        <div>
            {
                React.useMemo(() => {
                    return (
                        <CategoriesActions
                            open={actt.open}
                            onClose={() => {
                                setActt({
                                    ...actt,
                                    open: false,
                                });
                            }}
                            action={actt.type}
                            onSubmit={handleSubmit}
                            onEditSuccess={function (is_success: any): void {
                                setReload(pre => !pre)
                            }}
                            type="INSERT-NEWS"
                        />
                    )

                }, [actt.open])
            }
            <Dialog
                id="sysMenu5"
                open={props.open}
                onClose={props.onClose}
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
                TransitionComponent={Transition}
                maxWidth="md"
                keepMounted={false}
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            {menu?.bar +
                                " " +
                                `${props.action === "VIEW"
                                    ? "[Chi tiết]"
                                    : props.action === "EDIT" || _id
                                        ? "[Cập nhật]"
                                        : "[Thêm mới]"
                                }`}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent sx={{
                    backgroundColor: '#e2e8f0'
                }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={9} >
                            <Paper elevation={3} className='p-3' >
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={classLabel}>Tiêu đề</InputLabel>
                                    <TextField
                                        className="cc-input"
                                        sx={{ width: "100%" }}
                                        name="title"
                                        onChange={handleChangeInput}
                                        value={data?.title || ""}
                                        disabled={props.action === ACTIONS.VIEW}
                                    // placeholder={"Tiêu đề thông báo"}
                                    />
                                </Box>
                                <Box style={{ width: "100%", margin: "15px 0px" }}>
                                    <InputLabel sx={classLabel}>NỘI DUNG</InputLabel>
                                    {/* <RichTextEditor
                                        value={htmlString}
                                        onChange={(newContent) => handleChangeRichText(newContent)}
                                        height={650}
                                        readOnly={props.action === ACTIONS.VIEW}
                                    /> */}
                                    {
                                        React.useMemo(() => {
                                            return <>
                                                <RichTextEditor
                                                    value={newContent}
                                                    onChange={setNewContext}
                                                    height={650}
                                                    // readOnly={props.action === ACTIONS.VIEW}
                                                />
                                            </>
                                        }, [data])
                                    }
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={3} rowSpacing={4}>
                            <Paper elevation={3} className="p-3 mb-3">
                                <Box >
                                    <InputLabel className="text-center" sx={{ fontSize: 16, color: '#333', fontWeight: '500', margin: "5px 0" }}>
                                        Tùy chọn
                                    </InputLabel>
                                    <Grid item xs={12} style={{ margin: "10px 0" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333', fontWeight: '600' }}>Trạng thái</InputLabel>
                                        <DHSSelectBox
                                            class="cc-input"
                                            dataSource={status}
                                            displayExpr="name"
                                            name="status_id"
                                            valueExpr="code"
                                            onValueChanged={handleChangeInput}
                                            searchExpr="name"
                                            defaultValue={data?.status_id}
                                            value={data?.status_id}
                                            disabled={props.action === ACTIONS.VIEW}
                                        />
                                    </Grid>
                                </Box>
                            </Paper>
                            <Paper elevation={3} className="p-3 mb-3">
                                <Box >

                                    <InputLabel
                                        sx={{
                                            fontSize: 14,
                                            color: '#333',
                                            fontWeight: '600',
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}>
                                        <span>Chuyên mục</span>
                                        {props.action !== ACTIONS.VIEW &&
                                            (
                                                <AddCircleIcon
                                                    style={{ fontSize: 16, color: '#537FE7' }}
                                                    onClick={() => {
                                                        setActt({ ...actt, open: true, type: 'ADD' })
                                                    }} />
                                            )}
                                    </InputLabel>
                                    <TextField
                                        className="cc-input"
                                        sx={{ width: "100%" }}
                                        name="category_id"
                                        onChange={handleChangeInput}
                                        value={data?.category_id ? data?.category_id : "0"}
                                        select
                                        disabled={props.action === ACTIONS.VIEW}
                                    >
                                        <MenuItem value="0">----Danh mục-----</MenuItem>
                                        {
                                            category.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Box>
                            </Paper>
                            <Paper elevation={3} className="p-3 mb-3">
                                <Box>
                                    <InputLabel
                                        sx={classLabel}
                                    >
                                        Ảnh bìa
                                    </InputLabel>
                                    <UpdateAvatar
                                        setPath={function (path_: []): void {
                                            setAvatar([...path_]);
                                        }}
                                        path={avatar}
                                        action={props.action}
                                        title="Ảnh đại diện"
                                        singer={true}
                                    />
                                </Box>
                                <Box>
                                    <InputLabel
                                        sx={classLabel}
                                    >
                                        Tập tin
                                    </InputLabel>
                                    <UploadFile
                                        setPath={function (path_: []): void {
                                            setPath([...path_]);
                                        }}
                                        path={path}
                                        action={props.action}
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleSubmit} disabled={props.action === ACTIONS.VIEW}>
                        {
                            props.action === 'ADD' ?
                                "Đăng tin" : "Cập nhật"
                        }
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={props.onClose}
                    >
                        Đóng
                    </Button>
                </DialogActions>
                <BlockUI blocking={isLoading} title={""}></BlockUI>
            </Dialog>
        </div>
    );
};

export default NewActons;
