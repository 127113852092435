import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import notification from "../../../common/notification/notification";
import BlockUI from "../../../components/Block-UI/Block-ui";
import CTXToolbar from "../../../components/CTX/Toolbar";
import DHSGridView from "../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { getRoles } from "../../../redux/actions/RoleActions";
import { AppSession } from "../../shared/app-session/app-session";
import {
    ChoThueXeClient,
    HomeClient,
    QLCCPurposeOfUseModel,
    SysModel,
    RoleRightWebModel,
    QLCCClient,
    SysActionType
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import ServicesAction from '../actions/service-action/ServicesAction';
import PurposeAction from '../actions/purpose-use/PurposeAction';
import { Notification } from "../../../components/layouts";
import { useHotkeys } from "react-hotkeys-hook";
import DHSBreadcrumb from "../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import { ACTIONS } from "../../../common/enums/DHSToolbarRoleAction";

import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DataGrid from 'devextreme-react/data-grid';
import DHSToolbarRole from '../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import { EventInfo } from 'devextreme/events';
import { getDataRowSelected, getIndexRowSelected } from "../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../common/Interfaces/StatusMessage";

interface IAction {
    open: boolean; //Mở Dialog
    type: string | undefined; //Loại action (keyword)
    //name: string | undefined; //Tên action (thêm, sửa, xóa)
    // table: string | undefined; //Tên bảng thay đổi khi thực hiện action
    payload: string | undefined;
}


const defaultAction: IAction = {
    open: false,
    type: "",
    // name: "",
    payload: ""
}

let appSession: AppSession;
const status_message = ['success', 'error']

const menuRoleName = "CC";
const PurposeList = () => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    // const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
    const { path, url } = useRouteMatch();
    // const hrClient = new HRClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    // const invClient = new LstInventoryClient(appSession, BASE_URL_API);
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();

    const [isLoading, setLoading] = React.useState<boolean>(false);

    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
    const [isShowSort, setShowSort] = React.useState<boolean>(false);
    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<QLCCPurposeOfUseModel[]>([]);
    const [itemSelected, selectItem] = React.useState<string>();
    const [action, setAction] = React.useState<IAction>({
        open: false,
        type: "",
        payload: "",
    });
    const customMenu = [
        {
            menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
            onClick: () => {
                setShowSearch(!isShowSearch);
            },
        },
        {
            menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
            onClick: () => {
                setShowGroup(!isShowGroup);
            },
        },
        {
            menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
            onClick: () => {
                setShowSort(!isShowSort);
            },
        },
        {
            menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
            onClick: () => {
                setShowFilter(!isShowFilter);
            },
        },
    ];
    useHotkeys<HTMLParagraphElement>('alt+1', () => setShowSearch(!isShowSearch));

    useHotkeys<HTMLParagraphElement>('alt+2', () => setShowGroup(!isShowGroup));

    useHotkeys<HTMLParagraphElement>('alt+3', () => setShowSort(!isShowSort));

    useHotkeys<HTMLParagraphElement>('alt+4', () => setShowFilter(!isShowFilter));
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)


    const [actCx, setActCx] = React.useState<IAction>(defaultAction);

    function onLoadDataSource() {
        setLoading(true);
        // Tải các dòng trong bảng
        qLCCClient
            .qLCCPurposeOfUseGet({
                ...new QLCCPurposeOfUseModel(),
                action_type: "GET",
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty
            } as QLCCPurposeOfUseModel)
            .then((res) => {
                setDataTable(res);
                setLoading(false);
            });
    }

    React.useEffect(() => {
        onLoadDataSource();
    }, [action.open, apartment_building_id]);


    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>();
    const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>(-1);


    const handleSubmit = (data: any) => {
        setLoading(true)
        setAction({
            ...action,
            open: false,
            type: "",
            //name: "",
            payload: ""
        });
    };

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code?.toUpperCase()) {
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<QLCCPurposeOfUseModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            if (window.confirm("Bạn có chắc chắn muốn xóa?")) {
                                setLoading(true)
                                qLCCClient
                                    .qLCCPurposeOfUseAction({
                                        ...new QLCCPurposeOfUseModel(),
                                        id: dataSelected?.id ?? null,
                                        action_type: ACTIONS.DELETE,
                                        apartment_building_id: apartment_building_id,
                                        ma_cty: SessionLogin.ma_cty
                                    } as QLCCPurposeOfUseModel)
                                    .then((res) => {
                                        setLoading(false)
                                        setIdItemSelected("")
                                        notification(status_message[Number(res.status)], res.message);
                                        onLoadDataSource()
                                    });
                            }
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: action.action_code,
                    // payload: idItemSelected
                })
                break;
            case ACTIONS.EDIT:

                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<QLCCPurposeOfUseModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: ACTIONS.EDIT,
                                payload: dataSelected?.id ?? undefined
                            })
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.VIEW:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<QLCCPurposeOfUseModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: ACTIONS.VIEW,
                                payload: dataSelected?.id ?? undefined
                            })
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;

        }

    }

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                const index = e.component.getRowIndexByKey(keys[0]);
                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
                setIdItemSelected(keys[0].id);
            });
        }
    };

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };

    return (
        <div
            className="container-fluid p-2"
            style={{ maxHeight: "94vh !important", overflowY: "auto" }}
        >
            <PurposeAction
                open={actCx.open}
                onClose={() => {
                    setActCx({
                        ...actCx,
                        open: false,
                        payload: "",
                        type: ""
                    })
                }}
                action={actCx.type}
                onSubmit={handleSubmit}
                itemSelected={actCx.payload}
                onEditSuccess={function (is_success: any): void {
                    onLoadDataSource()
                }}
            />

            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                {/* <i
                    className="bi bi-arrow-left-short"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/admin/quan-ly-danh-muc")}
                ></i>
                &nbsp;{menu?.bar} */}
                <DHSBreadcrumb
                    location={location}
                />

            </div>

            <DHSToolbarRole
                id={' '}
                menu={menu}
                customMenu={[]}
                onClickAction={handleClickAction}
            />

            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                keyExpr="id"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handleSelectionChanged={handleSelectionChanged}
                handlePageChange={handlePageChange}

            // handleExporting={handleExporting}
            />

            {/* <CTXToolbar
                id={idItemSelected}
                list={
                    roles
                        ? roles.filter(
                            (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
                        )
                        : []
                }
                onClickAction={handleClickAction}
                customMenu={customMenu}
            />
            <DHSGridView
                onSelectRow={(row) => {
                    setIdItemSelected(row);
                }}  
                defaultPageSize={20}
                //onListSelectRow={handleListOnselectRow}
                itemClicked={function (): void {
                    throw new Error("Function not implemented.");
                }}
                table_name={menu?.component ? menu?.component : ""}
                show_form_list={true}
                showSelectBox={false}
                showSortPanel={isShowSort}
                showSearchPanel={isShowSearch}
                showGroupPanel={isShowGroup}
                showFilterPanel={isShowFilter}
                data={dataTable}
            /> */}

            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </div>
    );
};

export default PurposeList;
