import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,

    Divider,
    DialogTitle,
    Autocomplete,
    FormControlLabel,
    Switch,
    Select,
    MenuItem,
    DialogContentText,
} from "@mui/material";
import CurrencyTextField from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import {
    HomeClient,
    QLCCClient,
    RoleRightWebModel,
    QLCCApartmentBuildingModel,
    QLCCApartmentTypesModel,
    LstUnitModel,
    QLCCApartmentModel,
    SysActionType,
    QLCCUtilityModel,
} from "../../../shared/service-proxies/api-shared";


import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../../actions/ApartmentBuilding.css";
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import notification from "../../../../common/notification/notification";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import { httpPost } from "../../../../common/httpService";
import axios from "axios";
import NumberBox from "devextreme-react/number-box";
import DHSUploadImage from "../../../../components/DHS/DHS-Upload-Image/DHSUploadImage";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";
import DHSAutocompleteFilterListSelect from "../../../../components/DHSUtilComponent/DHSAutocompleteFilterListSelect/DHSAutocompleteFilterListSelect";
interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (id?: string, dataEdited?: QLCCUtilityModel) => void;
    onAddSuccess: (id?: string, dataAdded?: QLCCUtilityModel) => void;
}
// interface IOpenCamera {
//     pic: any;
//     open: boolean;
// }

// const videoConstraints = {
//     width: 1280,
//     height: 720,
//     facingMode: "user",
// };

// const image_size_citizen_identification_card = {
//     width: 540,
//     height: 360,
// };

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const status_message = ["success", "error"]
var appSession: AppSession;
var htmlString: string;
const UtilityAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    // truyền id dự án
    const [data, setData] = React.useState<QLCCUtilityModel>({} as QLCCUtilityModel);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const [isDefaultPre, setIsDefaultPre] = React.useState<boolean>();

    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [totalApartmentMessage, setTotalApartmentMessage] = React.useState<JSX.Element>();
    const [actionUpdateService, setActionUpdateService] = React.useState<string>();

    const [apartmentBuildingOption, setApartmentBuildingOption] = React.useState<any>([]);
    const [utilityGroupOptions, setUtilityGroupOptions] = React.useState<{ id: string, name: string }[]>([]);
    const [fullScreen, setFullscreen] = React.useState<boolean>(false);

    const [utilityStatusOptions, setutilityStatusOptions] = React.useState<any>([
        {
            id: "FA2FEAE9-E9CB-4EE5-AD62-6E2250475C38",
            name: 'Đang áp dụng',
        },
        {
            id: "22925183-043A-4879-BA41-1CE81177CA8B",
            name: 'Không áp dụng',
        },
    ]);
    const [apartmentTypeOptions, setApartmentTypeOptions] = React.useState<any>(
        []
    );
    const [unitOptions, setUnitOptions] = React.useState<any>([]);
    // const [windowDirectionOptions, setWindowDirectionOptions] =
    //     React.useState<any>([]);
    // const [elevatorLocationOptions, setElevatorLocationOptions] =
    //     React.useState<any>([]);
    // const [emergencyExitLocationOptions, setEmergencyExitLocationOptions] =
    //     React.useState<any>([]);


    React.useEffect(() => {

        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            qLCClient
                .qLCCUtilityGet({
                    ...new QLCCUtilityModel(),
                    ma_cty: SessionLogin?.ma_cty,
                    id: props.itemSelected.utility_id,
                    apartment_building_id: props?.itemSelected?.apartment_building_id,
                    type_id: "EB926055-58D9-4552-958C-B4CAAE0A9A5F"
                } as QLCCUtilityModel)
                .then((res) => {
                    setData(res[0]);

                    setIsDefaultPre(res[0]?.is_default);

                })
                .catch(() => alert("Không lấy được dữ liệu"));
            setId(props.itemSelected);

        } else {

            setData({
                ...new QLCCUtilityModel(),
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: props?.itemSelected?.apartment_building_id,
                status: utilityStatusOptions[0].id
            } as QLCCUtilityModel);
            setId(undefined)
        };
    }, [props.action]);


    React.useEffect(() => {

        setLoading(true);
        homeClient
            ?.roleRightWebGetAll({
                ...new RoleRightWebModel(),
                rolename: menu?.moduleid,
                username: SessionLogin.userName,
                menuid: menu?.menuid,
            } as RoleRightWebModel)
            .then((res) => {
                setRoles(res);
                setLoading(false);
            });
        setLoading(true);
        qLCClient
            .qlccApartmentBuildingGet({
                ...new QLCCApartmentBuildingModel(),
                ma_cty: SessionLogin.ma_cty,
                action_type: 'GET'
            } as QLCCApartmentBuildingModel)
            .then(res => {
                setApartmentBuildingOption(res);
            })
        // setLoading(true);

        // qLCClient
        //     .qLCCApartmentTypesActions({
        //         ...new QLCCApartmentTypesModel(),
        //         action_type: 'GET'
        //     } as QLCCApartmentTypesModel)
        //     .then(res => {
        //         setApartmentTypeOptions(res)
        //         setLoading(false);
        //     });
        setLoading(true);

        qLCClient.qlccLstUnitGet({
            ...new LstUnitModel(),
            ma_cty: SessionLogin.ma_cty
        } as LstUnitModel)
            .then(res => {
                setLoading(false);
                setUnitOptions(res)
            })
        // httpPost<string>('api/Common/LoadFilterList', {
        //     tablelist: "dm_nhom_tien_ich"
        // })
        //     .then(res => {

        //         const utilities: { id: string, name: string }[] = JSON.parse(res.data);
        //         setUtilityGroupOptions(utilities)
        //         setLoading(false)

        //     });
    }, []);

    const handleSubmit = (action?: string) => {
        setLoading(true)
        if (_id || props.action === ACTIONS.EDIT) {

            qLCClient.qLCCUtilityUpd({
                ...new QLCCUtilityModel(),
                ...data,
                type_id: "EB926055-58D9-4552-958C-B4CAAE0A9A5F",
                description: htmlString,
            } as QLCCUtilityModel)
                .then((response) => {
                    setLoading(false);
                    notification(status_message[response.status!], STATUS_MESSAGE[`${props.action}_${response.status}`]);
                    if (response.status === 0) {
                        props.onEditSuccess(response.id, data);
                        props.onClose();
                    }


                });



        }
        else {
            qLCClient.qLCCUtilityIns({
                ...new QLCCUtilityModel(),
                ...data,
                type_id: "EB926055-58D9-4552-958C-B4CAAE0A9A5F",
                description: htmlString,
            } as QLCCUtilityModel)
                .then((response) => {
                    setLoading(false);
                    notification(status_message[response.status!], STATUS_MESSAGE[`${props.action}_${response.status}`])

                    if (response.status === 0) {
                        props.onAddSuccess(response.id, data);
                        props.onClose();
                    }
                });
        }
    };

    const handleChangeInput = (e: any) => {
        const { name, value, checked, type } = e.target;

        if (type === 'checkbox') {
            setData({ ...data, [name]: checked } as QLCCUtilityModel);

        }
        else setData({ ...data, [name]: value } as QLCCUtilityModel);
    };

    const handleChangeInputSelect = (e: any) => {
        const { name, value } = e.target;
        setData({ ...data, unit: value } as QLCCUtilityModel);
    }

    const handleChangeMultipleInput = (e: any) => {
        if (props.action !== ACTIONS.VIEW) {
            const { name, value } = e.target;
            if (name === 'apartment_type_id') {
                const valueSplit = value.map((value: QLCCApartmentTypesModel) => value.id).join(";")
                setData({ ...data, [name]: valueSplit } as QLCCUtilityModel);
            }
            else {
                const valueSplit = value.map((value: QLCCApartmentBuildingModel) => value.id).join(";")
                setData({ ...data, [name]: valueSplit } as QLCCUtilityModel);
            }
        }
    }

    const handleChangeRichText = (stringData: string) => {
        // setData({
        //     ...data,
        //     service_description: stringData,
        // } as QLCCUtilityModel);
        htmlString = stringData;
    };


    // const handleChangeCurrentInput = (
    //     value: string | undefined, name?: string | undefined, values?: CurrencyInputOnChangeValues | undefined) => {

    //     setData({ ...data, [name!]: value } as QLCCUtilityModel);
    // }

    // const handleClose = () => {
    //     setOpen(false)
    // }
    // const handleOpen = () => {


    //     if (isDefaultPre === true && data?.is_default === false) {

    //         qLCClient.qLCCApartmentsGet({
    //             ...new QLCCApartmentModel(),
    //             service_id: data?.id,
    //             apartment_building_id: data?.apartment_building_id,
    //             apartment_type_id: data?.apartment_type_id,
    //             action_type: 'GET_TOTAL_SERVICE_APARTMENT_BY_SERVICEID'
    //         } as QLCCApartmentModel)
    //             .then(res => {
    //                 setLoading(false);
    //                 const totalApartmentMessage = (
    //                     <div>
    //                         Có tổng <span style={{ fontWeight: 700, fontSize: "16px", color: 'red' }}>
    //                             {Number.parseInt(res[0].message!)}
    //                         </span> căn hộ đang sử dụng phí này. Bạn chăc chắn muốn xóa mặt định?
    //                     </div>
    //                 );
    //                 setActionUpdateService("UPDATE")

    //                 setTotalApartmentMessage(totalApartmentMessage)
    //             });
    //     }
    //     else {

    //         setTotalApartmentMessage(<>Bạn chắc chắn muốn lưu</>);
    //         setActionUpdateService("UPDATE-BUT-NOT-DELETE")

    //     }

    //     setOpen(true)
    // }

    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                handleSubmit();
                // handleOpen()
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
        }
    }

    //#region handleImage

    const handleRemoveImage = (e: any) => {
        const { name } = e;

        if (name) {
            setData({
                ...data,
                [name]: ''
            } as QLCCUtilityModel)
        }
    }

    const handleChangeImage = async (e: any) => {

        const { name, files } = e.target;
        const formData = new FormData();
        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };
        for (const file of files) {
            formData.append("MyFile", file);
        }
        setLoading(true)

        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    setLoading(false)
                    setData(pre => ({ ...pre, [name]: res.data[0].path } as QLCCUtilityModel))
                } else notification("warning", "Không lấy được hình ảnh");
            });

        e.target.value = '';

    }

    const handleAddNewImage = async (e: any) => {

        const { name, files } = e.target;
        const formData = new FormData();
        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };
        for (const file of files) {
            formData.append("MyFile", file);
        }
        setLoading(true)

        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    setLoading(false)
                    setData(pre => ({ ...pre, [name]: res.data[0].path } as QLCCUtilityModel))
                } else notification("warning", "Không lấy được hình ảnh");
            });

        e.target.value = '';
    }

    //#endregion

    return (
        <Dialog
            // id="sysMenu"
            open={props.open}
            onClose={props.onClose}
            // sx={{
            //     display: "flex",
            //     justifyContent: "flex-end",
            // }}
            TransitionComponent={Transition}
            maxWidth="lg"
            fullWidth
            fullScreen={fullScreen}
        >
            {/* <Dialog
                open={openCamera.open}
                onClose={handleCloseCamera}
                sx={{ height: "inherit" }}
            >
                
            </Dialog> */}

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === "UPDATE" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1.25 }}>
                        <IconButton
                            edge="end"

                            onClick={() => {
                                setFullscreen(pre => !pre)
                            }}
                            aria-label="full screen"
                        >
                            {fullScreen ? <FullscreenExitIcon sx={{ color: '#1f2937' }} /> : <FullscreenIcon sx={{ color: '#1f2937' }} />}
                        </IconButton>
                        <IconButton
                            edge="end"
                            onClick={() => {
                                props.onClose();
                            }}
                            aria-label="close"
                        >
                            <CloseIcon
                                sx={{ color: '#1f2937' }}
                            />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <div className="row">
                    <div className="col-xl-6">
                        {/* Tên tiện ích */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                required
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Tên tiện ích
                            </InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                autoFocus
                                placeholder="Tên tiện ích ..."
                                name="name"
                                onChange={handleChangeInput}
                                value={data?.name ?? null}
                                inputProps={{
                                    autoComplete: "new-password",
                                    readOnly: props.action === ACTIONS.VIEW
                                    // type: "search" // disable autocomplete and autofill
                                }}
                            // disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box>
                        {/* Nhóm tiện ích */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                required
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Nhóm tiện ích
                            </InputLabel>
                            <DHSAutocompleteFilterListSelect
                                id="main-direction-select"
                                table_list="dm_nhom_tien_ich"
                                value={data?.group_id}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "group_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });




                                }}

                                readOnly={props.action === ACTIONS.VIEW || props.action === ACTIONS.APPROVED}
                            />
                        </Box>
                        {/*Đơn vị tính */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Đơn vị tính
                            </InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="vehicle-type-select"
                                className="cc-input"
                                dataSource={unitOptions}
                                value={
                                    data?.unit_id
                                }
                                displayMember='dvt_name'
                                valueMember="dvt_id"
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "unit_id",
                                            value: newValue?.dvt_id ?? null,
                                        },
                                    });

                                    setData(pre => ({
                                        ...pre,
                                        unit: newValue?.dvt_name
                                    } as QLCCUtilityModel))
                                }}
                                readOnly={props.action === ACTIONS.VIEW}
                            />
                            {/* <Autocomplete
                                id="unit-select"
                                sx={{ width: "100%" }}

                                filterSelectedOptions
                                options={unitOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.dvt_name}
                                value={
                                    data?.unit_id
                                        ? unitOptions.find(
                                            (item: any) => {
                                                return item?.dvt_id === data?.unit_id
                                            }

                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {


                                    handleChangeInput({
                                        target: {
                                            name: "unit_id",
                                            value: newValue?.dvt_id ?? null,
                                        },
                                    });

                                    setData(pre => ({
                                        ...pre,
                                        unit: newValue?.dvt_name
                                    } as QLCCUtilityModel))
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password"// disable autocomplete and autofill
                                        }}
                                    // placeholder="Dự án ..."


                                    />
                                )}
                                readOnly={props.action === ACTIONS.VIEW}
                            /> */}
                            {/* <Select
                                style={{ width: "100%", }}
                                value={data?.unit || ""}
                                name="unit"
                                onChange={handleChangeInputSelect}
                                readOnly={props.action === ACTIONS.VIEW}
                            >
                                {
                                    unitOptions
                                    && unitOptions.map((e: LstUnitModel) => (
                                        <MenuItem key={e.dvt_id} value={e.dvt_name}>{e.dvt_name}</MenuItem>
                                    ))
                                }
                            </Select> */}
                        </Box>

                        {/* Giá tiện ích */}

                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Đơn giá</InputLabel>
                            <NumberBox
                                // className="cc-input"
                                format="0,###"
                                inputAttr={{ 'aria-label': 'Fixed Poing Format', 'style': 'text-align:right' }}
                                defaultValue={data?.prices}
                                value={data?.prices}

                                // style={{
                                //     textAlign: "right"
                                // }}
                                onValueChange={(value) => {
                                    handleChangeInput({
                                        target: {
                                            name: "prices",
                                            value: value
                                        }
                                    });


                                }}

                                readOnly={props.action === ACTIONS.VIEW}

                            />


                        </Box>



                        {/* Trạng thái tiện ích*/}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Trạng thái tiện ích
                            </InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="main-direction-select"
                                className="cc-input"
                                dataSource={utilityStatusOptions}
                                value={
                                    data?.status
                                }
                                displayMember='name'
                                valueMember="name"
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "status",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                readOnly={props.action === ACTIONS.VIEW}
                            />
                            {/* <Autocomplete
                                id="main-direction-select"
                                sx={{ width: "100%" }}
                                options={utilityStatusOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.status
                                        ? utilityStatusOptions.find(
                                            (item: any) => (item.name as string).toLowerCase() === data.status?.toLowerCase()
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "status",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                readOnly={props.action === ACTIONS.VIEW}
                            /> */}
                        </Box>





                    </div>
                    {/* chỗ này cắt div*/}
                    <div className="col-xl-6">
                        {/* Người sử dụng */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Người sử dụng
                            </InputLabel>

                            <Autocomplete
                                id="main-direction-select"
                                sx={{ width: "100%" }}
                                options={mainDirectionOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.apartment_id
                                        ? mainDirectionOptions.find(
                                            (item: any) => item.id === data.apartment_id
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "apartment_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password",
                                            // type: "search" // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box> */}


                        {/* Sự dụng cho dự án */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333" }}
                            >
                                Sử dụng cho dự án
                            </InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="apartment-select"
                                className="cc-input"
                                dataSource={apartmentBuildingOption}
                                value={
                                    data?.apartment_building_id
                                }
                                displayMember='name'
                                valueMember="id"
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeMultipleInput({
                                        target: {
                                            name: "apartment_building_id",
                                            value: newValue ?? null,
                                        },
                                    });
                                }}
                                readOnly={props.action === ACTIONS.VIEW}
                                filterSelectedOptions={true}
                                multiple={true}
                            />
                            {/* <Autocomplete
                                id="apartment-select"
                                sx={{ width: "100%" }}
                                multiple
                                filterSelectedOptions
                                options={apartmentBuildingOption}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.apartment_building_code
                                        ? apartmentBuildingOption.filter(
                                            (item: any) => {
                                                return data.apartment_building_code?.split(";").includes(item.id)
                                            }

                                        )
                                        : []
                                }
                                onChange={(e, newValue: any) => {


                                    handleChangeMultipleInput({
                                        target: {
                                            name: "apartment_building_code",
                                            value: newValue ?? null,
                                        },
                                    });
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password"// disable autocomplete and autofill
                                        }}
                                        placeholder="Dự án ..."


                                    />
                                )}
                                readOnly={props.action === ACTIONS.VIEW}
                            /> */}
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <div className="d-flex align-items-center">
                                <InputLabel sx={{ fontSize: 14, color: "#000" }}>
                                    Tải hình
                                </InputLabel>

                            </div>
                            <DHSUploadImage
                                name="image"
                                value={data?.image}
                                width={"213px"}
                                height={"213px"}
                                handleAddNewImage={handleAddNewImage}
                                handleChangeImage={handleChangeImage}
                                handleRemoveImage={handleRemoveImage}
                                disable={props?.action === ACTIONS.VIEW}
                            />
                        </Box>


                    </div>
                    {/* <div className="col-xl-6">
                       

                      
                    </div> */}

                    {/* <div className="col-xl-6"> */}
                    {/* Loại căn hộ */}
                    {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Loại căn hộ
                            </InputLabel>
                            <Autocomplete
                                id="apartment-select"
                                sx={{ width: "100%" }}
                                multiple
                                filterSelectedOptions
                                options={apartmentTypeOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.apartment_type_name}
                                value={
                                    data?.apartment_type_id
                                        ? apartmentTypeOptions.filter(
                                            (item: any) => {
                                                return data.apartment_type_id?.split(";").includes(item.id)
                                            }
                                        )
                                        : []
                                }
                                onChange={(e, newValue: any) => {


                                    handleChangeMultipleInput({
                                        target: {
                                            name: "apartment_type_id",
                                            value: newValue ?? null,
                                        },
                                    });
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password"// disable autocomplete and autofill
                                        }}
                                        placeholder="Áp dụng cho loại căn hộ ..."


                                    />
                                )}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box> */}


                    {/* </div> */}
                    {/* 
                    <div className="col-xl-12 mt-3">
                        <Accordion expanded={isExpandApartmentTypeService} onChange={() => { setIsCalledApartmentTypeService(true); setIsExpandApartmentTypeService(pre => !pre) }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>tiện ích loại căn hộ</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ApartmentTypeServiceList />
                            </AccordionDetails>
                        </Accordion>
                    </div> */}
                    {/* Mặt định */}

                    {/* <div className="col-xl-6">
                        <Box style={{ width: "100%", margin: "5px 0px" }}>


                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mặc định</InputLabel>

                            <FormControlLabel control={
                                <Switch
                                    color="primary"
                                    onChange={handleChangeInput}
                                    name="is_default"
                                    value={data?.is_default}
                                    checked={data?.is_default}
                                    disabled={props.action === ACTIONS.VIEW}
                                />}
                                label={
                                    <Typography
                                        sx={{ fontSize: 14, color: '#333' }}>
                                        Không/Có
                                    </Typography>} />


                        </Box>
                    </div> */}


                    <div className="col-12">
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Mô tả tiện ích
                            </InputLabel>
                            <RichTextEditor

                                onChange={(stringData) => handleChangeRichText(stringData)}
                                // onChange={(newValue) => handleChangeInput({ target: { name: "service_description", value: stringData } })}
                                value={data?.description ?? ""}
                                readOnly={props.action === ACTIONS.VIEW}
                            />
                        </Box>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>

                <DHSDialogAction

                    action={props.action}
                    menu={menu}
                    onClickAction={handleClickAction}
                />


            </DialogActions>

            {/* <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Xác nhận thay đổi phí vận hành
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {totalApartmentMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation sx={{ textTransform: "capitalize" }} autoFocus variant="contained" color="primary" onClick={() => handleSubmit()}>
                        Chập nhận
                    </Button>
                    {
                        actionUpdateService === "UPDATE" && <Button disableElevation sx={{ textTransform: "capitalize" }} color="error" onClick={() => {
                            handleSubmit("UPDATE-BUT-NOT-DELETE");
                        }}>Không</Button>
                    }
                    <Button disableElevation sx={{ textTransform: "capitalize" }} onClick={handleClose}>Hủy</Button>
                </DialogActions>
            </Dialog> */}
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default UtilityAction;
