import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IReducer, Notification } from "../../../components/layouts";
import { getRoles } from "../../../redux/actions/RoleActions";
import { AppSession } from "../../shared/app-session/app-session";
import {
  QLCCClient,
  HomeClient,
  RoleRightWebModel,
  SoVch05Client,
  SoVch05M,
  QLCCApartmentBuildingModel,
  QLCCBlockModel,
  QLCCDomesticWaterCycleModel,
  QLCCApartmentModel,
  SysActionType,
  ReportInfo,
  QLCCFloorModel,
  SoVch05D0,
  QLCCDomesticElectricModel,
  QLCCDomesticWaterModel,
  SysModel,
  Upload_ENTITY,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import BlockUI from "../../../components/Block-UI/Block-ui";
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import ServiceInvoicesClient from "../actions/service-action/ServiceInvoicesClientAction";
import { TransitionProps } from "@mui/material/transitions";
import ServiceInvoiceClientCreateBill from "../actions/service-action/ServiceIncoiceClientCreateBillAction";
import CloseIcon from "@mui/icons-material/Close";
import { ACTIONS } from "../../../common/enums/DHSToolbarRoleAction";
import BetweenTwoDate from "../report/cash-book-report/component/between-two-date-X";
import DHSBreadcrumb from "../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";

import dxDataGrid, { CellPreparedEvent, SelectionChangedInfo } from "devextreme/ui/data_grid";
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { EventInfo } from "devextreme/events";
import DataGrid from "devextreme-react/data-grid";
import DHSToolbarRole from "../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import {
  STATUS_MESSAGE,
  TYPE_MESSAGE,
} from "../../../common/Interfaces/StatusMessage";
import {
  getDataRowSelected,
  getIndexRowSelected,
  getKeyRowSelected,
  getListDataRowSelected,
  getListIndexRowSelected,
  getListKeyRowSelected,
} from "../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import "./style.css";
import moment from "moment";
import { setApartmentBuilding } from "../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction";
import DHSAutoCompleteWithDataSource from "../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";
import { SelectBox } from "devextreme-react";
import DHSSelectBoxDev from "../../../components/DHS/DHS_SelectBox/getSelectBox";
import { CALL_API_LOAD_APARTMENT_BUILDING_LIST } from "../apartment-building/apartment-building-list";
import DHSLoadPanel from "../../../components/DHSComponents/dialogs-and-notifications/DHSLoadPanel/DHSLoadPanel";
import DHSDialog from "../../../components/DHS/DHS_Dialog/DHSDialog";
import DHSUploadFile from "../../../components/DHSUtilComponent/DHSUploadFile/DHSUploadFile";
import { httpPost } from "../../../common/httpService";


const convert = ["success", "error", "warning", "info"];

var appSession: AppSession;
interface IAction {
  open: boolean;
  type: string | undefined;
  payload: string | undefined;
  item: any;
  domesticWaterCyclePre: any;
  t_tt: any;
  thongTinHoaDon?: any;
  action?: any;
}

interface IAttachFile {
  file?: File,
  fileUrl?: string,
  fileName?: string
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const roleMenu: string = "CC";

const ServiceInvoiceClientList: React.FC<{}> = () => {
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const { path, url } = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const [reload, setReload] = React.useState<boolean>(false);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
  const [action, setAction] = React.useState<IAction>({ open: false, type: "", payload: "", item: {}, domesticWaterCyclePre: {}, t_tt: "", thongTinHoaDon: {}, });
  const vch05Client = new SoVch05Client(appSession, BASE_URL_API);
  const [apartmentBuildingList, setApartmentBuildingList] = React.useState<QLCCApartmentBuildingModel[]>([] as QLCCApartmentBuildingModel[]);
  const [cycleList, setCycleList] = React.useState<QLCCDomesticWaterCycleModel[]>([] as QLCCDomesticWaterCycleModel[]);
  const [blockList, setBlockList] = React.useState<QLCCBlockModel[]>([] as QLCCBlockModel[]);
  const [soVch05List, setSoVch05List] = React.useState<SoVch05M[]>([] as SoVch05M[]);
  const [apartmentList, setApartmentList] = React.useState<QLCCApartmentModel[]>([] as QLCCApartmentModel[]);
  const [apartmentBuilding, setApartmentBuilding1] = React.useState<QLCCApartmentBuildingModel>({} as QLCCApartmentBuildingModel);
  const [cycle, setCycle] = React.useState<QLCCDomesticWaterCycleModel>({} as QLCCDomesticWaterCycleModel);
  const [block, setBlock] = React.useState<QLCCBlockModel>({} as QLCCBlockModel);
  const [isShowCreateBill, setIsShowCreateBill] = React.useState<boolean>(false);
  const [isShowExportBill, setIsShowExportBill] = React.useState<boolean>(false);
  const [isSearch, setIsSearch] = React.useState<boolean>(false);
  const [ngay_ct1, setNgayCT1] = React.useState<Date>(moment().startOf('month').toDate());
  const [ngay_ct2, setNgayCT2] = React.useState<Date>(moment().endOf('month').toDate());
  const [floors, setFloors] = React.useState<QLCCApartmentModel[]>([] as QLCCApartmentModel[]);
  const [floor, setFloor] = React.useState<QLCCApartmentModel>({} as QLCCApartmentModel);
  const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

  const [actionHDDT, setActionHDDT] = React.useState<IAction>({ open: false, type: "", payload: "", item: {}, domesticWaterCyclePre: {}, t_tt: "", thongTinHoaDon: undefined, });

  React.useEffect(() => {
    onloadApartmentBuildings();
  }, []);

  React.useEffect(() => {
    onloadBlocks(apartment_building_id);
    onloadCycles(apartment_building_id);
    onloadSoVch05M(apartment_building_id);
    setFloors([]);
    setFloor({} as QLCCFloorModel);
  }, [apartment_building_id]);

  React.useEffect(() => {
    onloadFloor();
    setFloor({} as QLCCFloorModel);
  }, [block]);

  const onloadApartmentBuildings = () => {
    CALL_API_LOAD_APARTMENT_BUILDING_LIST().then(res => {
      setApartmentBuildingList(res);
      // setLoading(false);
    })
  };

  const onloadBlocks = (apartmentBuildingId: string) => {
    qLCCClient
      .qlccBlockGet({
        ...new QLCCBlockModel(),
        apartment_building_id: apartmentBuildingId,
        // action_type: 'GET'
        ma_cty: SessionLogin.ma_cty,
      } as QLCCBlockModel)
      .then((res) => {
        setBlockList(res);
        if (res.length > 0) {
          // setBlock(res[0]);
        } else {
          setBlock(new QLCCBlockModel());
        }
      })
      .catch((err) => { });
  };

  const onloadCycles = (apartmentBuildingId: string) => {
    qLCCClient.qLCCDomesticWaterCycleGet({
      ...new QLCCDomesticWaterCycleModel(),
      apartment_building_id: apartmentBuildingId,
      action_type: "GET",
      ma_cty: SessionLogin.ma_cty,
    } as QLCCDomesticWaterCycleModel)
      .then((res) => {
        setCycleList(res);
        if (res.length > 0) {
          setCycle(res[0]);
        } else {
          setSoVch05List([]);
        }
      })
      .catch((err) => { });
  };

  const onloadSoVch05M = (paramsApartmentBuildingId?: string | undefined) => {
    setLoading(true);
    vch05Client
      .qLCCsoVch05MGet({
        ...new SoVch05M(),
        apartment_building_id: paramsApartmentBuildingId ? paramsApartmentBuildingId : apartment_building_id,
        ma_cty: SessionLogin.ma_cty,
        ma_ct: "SO5",
        block_id: block.id,
        floor_id: floor.id,
        ngay_ct1: moment(ngay_ct1).utc(true).startOf('day').toDate(),
        ngay_ct2: moment(ngay_ct2).utc(true).endOf('day').set('hour', 23).set('second', 0).toDate(),
      } as SoVch05M)
      .then((res: any) => {
        setSoVch05List([...res]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleClick = async (e: any, type: string = "DETAIL") => {
    const key = type === ACTIONS.ADD ? undefined : await getKeyRowSelected(dataGridRef);
    const findItem = soVch05List.find(item => item.stt_rec === key);
    // ACTION: Trường hợp đã tạo phiếu thu thì không cho chỉnh sửa
    let actions: string = type;
    if (findItem && findItem.trang_thai !== '0' && type === 'UPDATE') {
      actions = 'DETAI';
      Notification(convert[1], 'Chỉ có thể thay đổi thông tin của hóa đơn chưa được thanh toán');
    } else if (e && e.trang_thai !== '0' && type === 'UPDATE') {
      actions = 'DETAI'
      Notification(convert[1], 'Chỉ có thể thay đổi thông tin của hóa đơn chưa được thanh toán');
    }

    setAction({
      ...action,
      type: "SERVICEINVOICE",
      open: true,
      payload: e.id,
      item: e,
      t_tt: e.t_tt,
      thongTinHoaDon: findItem ? findItem : e,
      action: type,
    });
  };

  const handleSubmit = (data: any) => {
    setLoading(true);
    setAction({
      ...action,
      open: false,
      type: "",
      payload: "",
      item: {},
    });
  };

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    if (name === "apartmentBuilding") {
      // const apartmentBuildingSelect: any = apartmentBuildingList.find(
      //   (item) => item.id === value
      // );
      // if (apartmentBuildingSelect) {
      //   setApartmentBuilding1(apartmentBuildingSelect);
      // }
    } else if (name === "block") {
      const blockSelect: any = blockList.find((item) => item.id === value);
      if (blockSelect) {
        setBlock(blockSelect);
      } else {
        setBlock({} as QLCCBlockModel)
      }
    } else if (name === "floor") {
      const floorSelect: any = floors.find((item) => item.id === value);
      if (floorSelect) {
        setFloor(floorSelect);
      } else {
        setFloor({} as QLCCFloorModel)
      }
    } else if (name === "cycle") {
      const cycleSelect: any = cycleList.find((item) => item.id === value);
      if (cycleSelect) {
        setCycle(cycleSelect);
      }
    }
  };

  const addNewBill = () => {
    setIsShowCreateBill(true);
  };

  const handlePrintBill = (keys: string[]) => {
    if (keys.length > 0) {
      window.open(
        BASE_URL_API + `/Report/Reports?ma_cty=${SessionLogin.ma_cty}&ma_ct=${"SO5"}&stt_rec=${keys.join(";")}&apartment_building_id=${apartment_building_id}`);
    } else {
      Notification(convert[3], "Chưa có hóa đơn được chọn");
    }
  };

  const handlePrintBillLan2 = (keys: string[]) => {
    if (keys.length > 0) {
      window.open(
        BASE_URL_API + `/Report/Reports?ma_cty=${SessionLogin.ma_cty}&ma_ct=${"SO5"}&stt_rec=${keys.join(";")}&apartment_building_id=${apartment_building_id}&mau=LAN2`);
    } else {
      Notification(convert[3], "Chưa có hóa đơn được chọn");
    }
  };

  const handlePrintBillWithAccount = (keys: string[]) => {
    if (keys.length > 0) {
      window.open(
        BASE_URL_API + `/Report/Reports?ma_cty=${SessionLogin.ma_cty}&showuser=1&ma_ct=${"SO5"}&stt_rec=${keys.join(";")}&apartment_building_id=${apartment_building_id}`);
    } else {
      Notification(convert[3], "Chưa có hóa đơn được chọn");
    }
  };
  // del many row
  const handleDelBill = (keys: string[]) => {

    if (keys.length > 0) {

      let check = false; // True if soVch05List have in isLock equal true 

      keys.map(item => {
        const bill = soVch05List.find(p => p.stt_rec === item);

        if (bill && bill.islock === true) {
          console.log(bill.apartment_name, "Hóa đơn bị khóa")
          Notification(TYPE_MESSAGE.WARNING, "Không thể xóa hóa đơn bị khóa");
          check = true;
          return true;
        } else if (bill && bill.trang_thai !== '0') {
          Notification(convert[1], 'Chỉ có thể xóa hóa đơn chưa được thanh toán');
          check = true;
          return true;
        }
      })

      if (!check) {
        setLoading(true);
        // START DEL
        Notification(convert[3], "Vui lòng chờ");
        vch05Client
          .qLCCQLCSoVch05MDel({
            ...new SoVch05M(),
            ma_ct: "SO5",
            action_type: "PHIEUTHU",
            stt_rec: keys.join(";"),
            month: cycle.month,
            year: cycle.year,
            apartment_building_id: apartment_building_id,
            ma_cty: SessionLogin.ma_cty,
            luser: SessionLogin.userName,
          } as SoVch05M)
          .then((res: any) => {
            Notification(convert[res.status], res.message);
            // setReload(!reload);
            onloadSoVch05M();
            setLoading(false);
          })
          .catch((err) => {
            Notification("error", err.message);
            setLoading(false);
          });
      }
    }
  };

  React.useEffect(() => {
    if (block.id && cycle.month && cycle.year) {
      onloadSoVch05M();
    }
  }, [reload]);
  /* end select row in data gird */

  const handleClickAction = async (action: SysActionType, nowTime: Date) => {

    switch (action?.action_code) {
      case ACTIONS.VIEW:
        if (dataGridRef.current) {
          try {
            const key = await getKeyRowSelected(dataGridRef);
            const index = await getIndexRowSelected(dataGridRef);
            if (dataGridRef.current && key && index >= 0) {

              handleClick(selectedItemKeys);
            } else {
              Notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
              );
            }
          } catch (error) {
            Notification(
              TYPE_MESSAGE.WARNING,
              STATUS_MESSAGE[`WARNING_SELECT_ROW`]
            );
          }
        }
        break;
      case ACTIONS.ADD:
        addNewBill();
        break;
      case ACTIONS.EDIT:
        if (dataGridRef.current) {
          try {
            const key = await getKeyRowSelected(dataGridRef);
            if (dataGridRef.current && key) {
              const bill = soVch05List.find(item => item.stt_rec === key);

              if (bill && bill.islock === false) {
                await handleClick(selectedItemKeys, "UPDATE");
              } else {
                Notification(
                  TYPE_MESSAGE.WARNING,
                  "Hóa đơn đã được khóa"
                );
              }
            } else {
              Notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE[`WARNING_SELECT_ROW`]);
            }
          } catch (error) {
            Notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE[`WARNING_SELECT_ROW`]);
          }
        }
        break;
      case ACTIONS.COPY:
        break;
      case ACTIONS.DELETE:
        if (dataGridRef.current) {
          try {
            const keys = await getListKeyRowSelected(dataGridRef);
            if (dataGridRef.current && keys) {
              if (window.confirm("Xác nhận xóa?")) {
                handleDelBill(keys as string[]);
              }
            } else {
              Notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
              );
            }
          } catch (error) {
            Notification(
              TYPE_MESSAGE.WARNING,
              STATUS_MESSAGE[`WARNING_SELECT_ROW`]
            );
          }
        }
        break;
      case ACTIONS.CLOSE:
        break;
      case ACTIONS.SEARCH:
        setIsSearch(true);
        break;
      case ACTIONS.SAVE:
        break;
      case ACTIONS.PRINT:
        if (dataGridRef.current) {
          try {
            const keys = await getListKeyRowSelected(dataGridRef);
            const index = await getIndexRowSelected(dataGridRef);

            if (dataGridRef.current && keys) {
              handlePrintBill(keys as string[]);
            } else {
              Notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
              );
            }
          } catch (error) {
            Notification(
              TYPE_MESSAGE.WARNING,
              STATUS_MESSAGE[`WARNING_SELECT_ROW`]
            );
          }
        }
        break;
      case ACTIONS.PRINTBILLLAN2:
        if (dataGridRef.current) {
          try {
            const keys = await getListKeyRowSelected(dataGridRef);
            const index = await getIndexRowSelected(dataGridRef);

            if (dataGridRef.current && keys) {
              handlePrintBillLan2(keys as string[]);
            } else {
              Notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
              );
            }
          } catch (error) {
            Notification(
              TYPE_MESSAGE.WARNING,
              STATUS_MESSAGE[`WARNING_SELECT_ROW`]
            );
          }
        }
        break;
      case ACTIONS.PRINTWITHACCOUNT:
        if (dataGridRef.current) {
          try {
            const keys = await getListKeyRowSelected(dataGridRef);
            const index = await getIndexRowSelected(dataGridRef);

            if (dataGridRef.current && keys) {
              handlePrintBillWithAccount(keys as string[]);
            } else {
              Notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
              );
            }
          } catch (error) {
            Notification(
              TYPE_MESSAGE.WARNING,
              STATUS_MESSAGE[`WARNING_SELECT_ROW`]
            );
          }
        }
        break;
      case ACTIONS.HOADONDT:
        if (dataGridRef.current) {
          try {
            const keys = await getListKeyRowSelected(dataGridRef);
            const index = await getIndexRowSelected(dataGridRef);

            if (dataGridRef.current && keys) {
              // handlePrintBillLan2(keys as string[]);
              const temp = await getListDataRowSelected<SoVch05M>(dataGridRef)

              if (!temp || temp.length === 0) {
                Notification(
                  TYPE_MESSAGE.WARNING,
                  "Vui lòng chọn hóa đơn"
                );
                return;
              }
              else if (temp?.length > 1) {
                Notification(
                  TYPE_MESSAGE.WARNING,
                  "Vui lòng chỉ chọn 1 hóa đơn"
                );
                return;
              }

              setActionHDDT({
                ...actionHDDT,
                open: true,
                thongTinHoaDon: temp ? { ...temp[0] } : undefined
              })
            } else {
              Notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
              );
            }
          } catch (error) {
            Notification(
              TYPE_MESSAGE.WARNING,
              STATUS_MESSAGE[`WARNING_SELECT_ROW`]
            );
          }
        }
        break;
      case ACTIONS.CREATE:
        break;
      case ACTIONS.SENTNOTIFY:
        setIsShowExportBill(true);
        break;
      case ACTIONS.FILTER:
        break;
    }
  };

  const handleClickActionFilter = (action: string) => {
    switch (action?.toLocaleUpperCase()) {
      case ACTIONS.OK:
        if (cycle.month && cycle.year) {
          onloadSoVch05M();
          setIsSearch(false)
        }

        break;

      case ACTIONS.CLOSE:
        setIsSearch(false);

        break;
    }
  };

  const dataGridRef = React.useRef<DataGrid>(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
  const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
  // const [reportInfo, setReportInfo] = React.useState<ReportInfo>();

  const handlePageChange = () => {
    setSelectedRowIndex(-1);
    setSelectedItemKeys(undefined);
  };

  const handleSelectionChanged = async (
    e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
  ) => {
    if (dataGridRef.current) {
      // const keys: any = await getListKeyRowSelected(dataGridRef);
      const key = await getKeyRowSelected(dataGridRef);
      const index = await getIndexRowSelected(dataGridRef);
      if (dataGridRef.current && key && index >= 0) {
        const lstTemp = soVch05List;
        const findItem = await lstTemp.find(item => item.stt_rec === key);

        setSelectedItemKeys(findItem);
        setSelectedRowIndex(index >= 0 ? index : -1);
      }
    }
  };

  const onloadFloor = () => {
    if (block.id) {
      qLCCClient
        .qLCCFloorGet({
          block_id: block.id,
          apartment_building_id: apartment_building_id,
          ma_cty: SessionLogin.ma_cty,
        } as QLCCFloorModel)
        .then((res) => {
          setFloors(res);
        })
        .catch((err) => {
          console.log("Get DS Tầng thất bại");
        });
    }
  };

  const handleChangeColorCell = (e: CellPreparedEvent<any, any>) => {
    if (e.data && e.column && e.column.dataField === 'pay_status_name') {
      switch (e.data['pay_status']) {
        case 0:
          e.cellElement.className += ' customer-payment-text customer-payment-not-yet'
          break;
        case 1:
          e.cellElement.className += ' customer-payment-text customer-payment-doing'
          break;
        case 2:
          e.cellElement.className += ' customer-payment-text customer-payment-done'
          break;
        case 3:
          e.cellElement.className += ' customer-payment-text customer-payment-done'
          break;
        default:
          e.cellElement.className += ' customer-text'
          break;
      }
    }
  };

  const [isReturn, setIsReturn] = React.useState<boolean>(false);
  const [oldInvoice, setOldInvoice] = React.useState<SoVch05M>();

  React.useEffect(() => { asyncUseEffectFunction() }, [isReturn])

  async function asyncUseEffectFunction() {
    if (isReturn) {
      // Lấy thông tin hóa đơn cũ (stt_rec)
      const resApartment = await qLCCClient.qLCCApartmentsGet({
        ...new QLCCApartmentModel(),
        ...oldInvoice,
        created_date: oldInvoice?.ngay_ct,
        action_type: 'GENERATESOVCH05_3',
        ma_cty: SessionLogin.ma_cty,
        apartment_building_id: apartment_building_id,
      } as QLCCApartmentModel)

      const resSO = await vch05Client.qLCCsoVch05MGet({
        apartment_id: oldInvoice?.apartment_id,
        apartment_building_id: apartment_building_id,
        ma_cty: SessionLogin.ma_cty,
        ma_ct: "SO5",
        block_id: block.id,
        floor_id: floor.id,
      } as SoVch05M);
      const soVch05DMOld = resSO.shift() ?? new SoVch05M()
      const resSoD = await vch05Client.qLCCsoVch05D0Get({
        ... new SoVch05D0(),
        stt_rec: soVch05DMOld.stt_rec,
        apartment_building_id: apartment_building_id,
        ma_cty: SessionLogin.ma_cty,
      } as SoVch05D0)

      // Tạo hóa đơn mới =>  Cho đến khi người ta không sửa thì xóa nó đi  (stt_rec)
      await qLCCClient.qlccGenerateSOVch05InsV2({
        ...new SoVch05M(),
        ...soVch05DMOld,
        pay_status: 0,
        pay_status_name: "Chưa thanh toán",
        apartment_id: oldInvoice?.apartment_id,
        ngay_ct: oldInvoice?.ngay_ct,
        cycle_id: oldInvoice?.cycle_id,
        soVch05D0s: [...resSoD] as SoVch05D0[],
        qlccElectrics: [] as QLCCDomesticElectricModel[],
        qlccWaters: [] as QLCCDomesticWaterModel[],
        luser: SessionLogin.userName,
        ldate: moment().utc(true).toDate(),
        cdate: moment().utc(true).toDate(),
        apartment_building_id: apartment_building_id,
        ma_cty: SessionLogin.ma_cty,
        trang_thai: '0',
      } as SoVch05M);

      // Get lại để lấy master hóa đơn mới  (stt_rec) - danh sách hóa đơn của 1 căn hộ- rồi lấy hóa đơn mới nhất
      const resApartment2 = await qLCCClient.qLCCApartmentsGet({
        ...new QLCCApartmentModel(),
        ...oldInvoice,
        created_date: oldInvoice?.ngay_ct,
        action_type: 'GENERATESOVCH05_3',
        ma_cty: SessionLogin.ma_cty,
        apartment_building_id: apartment_building_id,
      } as QLCCApartmentModel)

      // invoice mới cập nhật - sẽ có hóa đơn được thêm vào
      const resSO2 = await vch05Client.qLCCsoVch05MGet({
        apartment_id: oldInvoice?.apartment_id,
        apartment_building_id: apartment_building_id,
        ma_cty: SessionLogin.ma_cty,
        ma_ct: "SO5",
        block_id: block.id,
        floor_id: floor.id,
      } as SoVch05M);

      const newInvoice = resApartment2.pop(); // new invoice just create

      if (newInvoice) {
        const tt_recM = newInvoice.stt_rec;
        const findItemM = resSO2[0] ?? new SoVch05M();
        console.log(resSO2)
        console.log(findItemM)
        const newIvoiceIsExist = {
          ...findItemM,
          islock: false,
          ngay_ct: oldInvoice?.ngay_ct,
          trang_thai: '0'
        } as SoVch05M;
        if (findItemM) {
          await handleClick(newIvoiceIsExist, ACTIONS.ADD);
        }
        setIsReturn(pre => !pre)
      }

      // qLCCClient.qLCCApartmentsGet({
      //   ...new QLCCApartmentModel(),
      //   ...oldInvoice,
      //   created_date: oldInvoice?.ngay_ct,
      //   action_type: 'GENERATESOVCH05_3',
      //   ma_cty: SessionLogin.ma_cty,
      //   apartment_building_id: apartment_building_id,
      // } as QLCCApartmentModel)
      //   .then(async (res: any) => {
      //     if (res && res[0]) {
      //       const tt_recM = res[0].stt_rec;
      //       const findItemM = soVch05List.find(item => item.stt_rec === tt_recM);
      //       const newIvoiceIsExist = {
      //         ...findItemM,
      //         islock: false,
      //         ngay_ct: oldInvoice?.ngay_ct
      //       } as SoVch05M;

      //       if (findItemM) {
      //         await handleClick(newIvoiceIsExist, ACTIONS.ADD);
      //       }
      //       setIsReturn(pre => !pre)
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //     setIsReturn(pre => !pre)
      //   });
    }
  }


  //upload hoa don dien tu
  const [fileUpload, setFileUpload] = React.useState<IAttachFile>({});
  const handleAddNewImage = (e: any) => {
    const { name, files } = e.target;
    if (files.length) {
      const imageUrl = URL.createObjectURL(files[0]); // Tạo URL tạm thời cho hình ảnh
      setFileUpload({
        file: files[0],
        fileUrl: imageUrl,
        fileName: files[0].name
      });
    }
  }

  const handleChangeImage = (e: any) => {
    const { name, files } = e.target;
    if (files.length) {
      const imageUrl = URL.createObjectURL(files[0]); // Tạo URL tạm thời cho hình ảnh
      setFileUpload({
        file: files[0],
        fileUrl: imageUrl,
        fileName: files[0].name
      });
    }
  }

  const handleRemoveImage = (e: any) => setFileUpload({
    file: undefined,
    fileName: "",
    fileUrl: ""
  })


  const handUploadHDDT = async (action: SysActionType, nowTime: Date) => {

    console.log(action);

    if (action.action_code === ACTIONS.SAVE) {
      setLoading(true)
      try {
        const formData = new FormData();
        if (!fileUpload?.file) {
          const temp = {
            ...actionHDDT.thongTinHoaDon,
            site_id: ""
          } as SoVch05M


          const result = await qLCCClient.qLCCSoVch05MUploadHDDT(temp)

          if (result.status === 0) {
            Notification(
              TYPE_MESSAGE.SUCCESS,
              "Cập nhật thành công"
            );

            // onloadSoVch05M();

            const newData: SoVch05M[] = soVch05List.map(item => {
              if (item.stt_rec === actionHDDT.thongTinHoaDon.stt_rec) {
                return {
                  ...item,
                  site_id: ""
                } as SoVch05M
              } else {
                return item as SoVch05M;
              }
            })

            setSoVch05List([...newData])

            setActionHDDT({
              ...actionHDDT,
              open: false,
              thongTinHoaDon: undefined
            })
          }
          setLoading(false)

          return;
        }

        formData.append("tbName", "HoaDonDienTu");
        formData.append("colName", "site_id");
        formData.append("MyFile", fileUpload.file);
        // 
        const options = {
          observe: "response",
          // responseType: "blob",
          headers: {
            Accept: "application/json",
            token: SessionLogin.Token
          },
        };

        // console.log(formData);


        const response = await httpPost<Upload_ENTITY[]>("/api/FileManager/PostFile_v2", formData, options).then(res => res.data);


        console.log(response);

        if (response[0].path && response[0].path !== "") {


          const temp = {
            ...actionHDDT.thongTinHoaDon,
            site_id: response[0].path
          } as SoVch05M


          const result = await qLCCClient.qLCCSoVch05MUploadHDDT(temp)

          if (result.status === 0) {
            Notification(
              TYPE_MESSAGE.SUCCESS,
              "Cập nhật thành công"
            );

            // onloadSoVch05M();

            const newData: SoVch05M[] = soVch05List.map(item => {
              if (item.stt_rec === actionHDDT.thongTinHoaDon.stt_rec) {
                return {
                  ...item,
                  site_id: BASE_URL_API + response[0].path
                } as SoVch05M
              } else {
                return item as SoVch05M;
              }
            })

            setSoVch05List([...newData])


            setActionHDDT({
              ...actionHDDT,
              open: false,
              thongTinHoaDon: undefined
            })


            setLoading(false)

          }

        }


        // if (response.ret === 0) {
        //   Notification(
        //     TYPE_MESSAGE.SUCCESS,
        //     STATUS_MESSAGE[`ATTACHFILE_WITH_APPROVE_0`]
        //   );
        //   setActCx(defaultAction);
        // }
        // else {
        //   Notification(
        //     TYPE_MESSAGE.SUCCESS,
        //     STATUS_MESSAGE[`ATTACHFILE_1`]
        //   );
        // }
        console.log(Object.fromEntries(formData))
      } catch (error) {

      }
    } else {
      setActionHDDT({
        ...actionHDDT,
        open: false,
        thongTinHoaDon: undefined
      })
    }


  }

  useEffect(() => {
    console.log(actionHDDT);

    if (actionHDDT.thongTinHoaDon && actionHDDT.thongTinHoaDon.site_id && actionHDDT.thongTinHoaDon.site_id !== '') {


      setFileUpload({
        // file: actionHDDT.thongTinHoaDon,
        // fileUrl: BASE_URL_API + actionHDDT.thongTinHoaDon.site_id,
        // fileName: BASE_URL_API + actionHDDT.thongTinHoaDon.site_id
        fileUrl: actionHDDT.thongTinHoaDon.site_id,
        fileName: actionHDDT.thongTinHoaDon.site_id
      });
    } else {
      handleRemoveImage({});
    }
  }, [actionHDDT])

  return (
    <div className="container-fluid">
      {/* Tạo hóa đơn */}
      {(isShowCreateBill
        || isShowExportBill)
        && (
          <ServiceInvoiceClientCreateBill
            open={isShowCreateBill ? isShowCreateBill : isShowExportBill}
            onClose={() => isShowCreateBill ?
              setIsShowCreateBill(false) :
              setIsShowExportBill(false)}
            onEditSuccess={() => onloadSoVch05M()}
            actionsCode={isShowCreateBill ? "CREATE" : "EXPORT"}
            setReturn={(apartment_id, ngay_ct, cycle_id, year, month) => {
              setOldInvoice({
                apartment_id: apartment_id,
                ngay_ct: ngay_ct,
                islock: false,
                cycle_id,
                year,
                month,
              } as SoVch05M)
              setIsReturn(pre => !pre);
            }}
          />
        )}

      {/* Tìm kiếm */}
      <Dialog
        open={isSearch}
        id="sysMenu4"
        className="d-flex justify-content-end"
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsSearch(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <AppBar
          sx={{
            position: "relative",
            backgroundColor: "#FFF",
            color: "#1890ff",
            boxShadow: 0,
          }}
        >
          <Toolbar>
            <Typography
              sx={{ flex: 1, fontWeight: 700 }}
              variant="h6"
              component="div"
            >
              Tìm kiếm
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setIsSearch(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            {/* Dự án */}
            {/* <Grid item xs={12}> */}
            {/* <InputLabel className="cc-label">Dự án</InputLabel>
              <DHSSelectBoxDev
                dataSource={apartmentBuildingList}
                value={apartment_building_id}
                valueExpr={'id'}
                displayExpr={'name'}
                onValueChange={(value: string) => dispatch(setApartmentBuilding({
                    id: value
                } as QLCCApartmentBuildingModel))}
              /> */}

            {/* <DHSAutoCompleteWithDataSource
                id="gender-select"
                className="cc-input"
                dataSource={apartmentBuildingList}
                value={
                  apartment_building_id
                }
                displayMember='name'
                valueMember="id"
                onValueChanged={(e, newValue: any) => {
                  // handleChangeInput({
                  //   target: {
                  //     name: "apartmentBuilding",
                  //     value: newValue?.id ? newValue.id : null,
                  //   },
                  // });
                  if (newValue && newValue.id) {
                    dispatch(setApartmentBuilding(newValue.id ?? ""))
                  }
                }}
                placeholder="Chọn dự án"
                filterSelectedOptions={true}
              /> */}
            {/* <Autocomplete
                id="gender-select"
                sx={{ width: "100%" }}
                options={apartmentBuildingList}
                autoHighlight
                getOptionLabel={(option: any) => option?.name}
                value={
                  apartmentBuilding?.id
                    ? apartmentBuildingList.find(
                      (item: any) => item.id === apartmentBuilding.id
                    )
                    : null
                }
                onChange={(e, newValue: any) => {
                  handleChangeInput({
                    target: {
                      name: "apartmentBuilding",
                      value: newValue?.id ? newValue.id : null,
                    },
                  });
                  if (newValue) {
                    dispatch(setApartmentBuilding(newValue.id ?? ""))
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="cc-input"
                    // label="Dự án"
                    placeholder="Chọn dự án"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              /> */}
            {/* </Grid> */}

            {/* Tòa nhà */}
            <Grid item xs={12}>
              <InputLabel className="cc-label">Tòa nhà</InputLabel>
              <DHSSelectBoxDev
                dataSource={blockList}
                value={block?.id}
                valueExpr={'id'}
                displayExpr={'name'}
                onValueChange={(value: string) => {
                  handleChangeInput({
                    target: {
                      name: "block",
                      value: value ? value : null,
                    },
                  });
                }}
                showClearButton={true}
              />

              {/* <DHSAutoCompleteWithDataSource
                id="gender-select"
                className="cc-input"
                dataSource={blockList}
                value={
                  block?.id
                }
                displayMember='name'
                valueMember="id"
                onValueChanged={(e, newValue: any) => {
                  handleChangeInput({
                    target: {
                      name: "block",
                      value: newValue?.id ? newValue.id : null,
                    },
                  });
                }}
                placeholder="Chọn tòa nhà"
              /> */}

              {/* <Autocomplete
                id="gender-select"
                sx={{ width: "100%" }}
                options={blockList}
                autoHighlight
                getOptionLabel={(option: any) => option?.name}
                value={
                  block?.id
                    ? blockList.find((item: any) => item.id === block.id)
                    : null
                }
                onChange={(e, newValue: any) => {
                  handleChangeInput({
                    target: {
                      name: "block",
                      value: newValue?.id ? newValue.id : null,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="cc-input"
                    // label="Tòa nhà"
                    placeholder="Chọn tòa nhà"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              /> */}
            </Grid>
            {/* Tầng */}
            <Grid item xs={12}>
              <InputLabel className="cc-label">Tầng</InputLabel>
              <DHSSelectBoxDev
                dataSource={floors}
                value={floor?.id}
                valueExpr={'id'}
                displayExpr={'name'}
                onValueChange={(value: string) => {
                  console.log(value)
                  handleChangeInput({
                    target: {
                      name: "floor",
                      value: value,
                    },
                  });
                }}
                showClearButton={true}
              />
              {/* <DHSAutoCompleteWithDataSource
                id="gender-select"
                className="cc-input"
                dataSource={floors}
                value={
                  floor?.id
                }
                displayMember='name'
                valueMember="id"
                onValueChanged={(e, newValue: any) => {
                  handleChangeInput({
                    target: {
                      name: "floor",
                      value: newValue?.id ? newValue.id : null,
                    },
                  });
                }}
                placeholder="Chọn tầng"
              /> */}

              {/* <Autocomplete
                id="gender-select"
                sx={{ width: "100%" }}
                options={floors}
                autoHighlight
                getOptionLabel={(option: any) => option?.name}
                value={
                  floor?.id
                    ? floors.find((item: any) => item.id === floor.id)
                    : null
                }
                onChange={(e, newValue: any) => {
                  handleChangeInput({
                    target: {
                      name: "floor",
                      value: newValue?.id ? newValue.id : null,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="cc-input"
                    // label="Tòa nhà"
                    placeholder="Chọn tầng"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              /> */}
            </Grid>
            {/* Thời gian */}
            <Grid item xs={12}>
              <BetweenTwoDate
                fromDate={(newDate: any) => setNgayCT1(newDate)}
                toDate={(newDate: any) => setNgayCT2(newDate)}
                reload={() => setReload(!reload)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>

          <Button
            variant="contained"
            onClick={() => handleClickActionFilter("OK")}
          >
            Thực hiện
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleClickActionFilter("CLOSE")}
          >
            Đóng
          </Button>

        </DialogActions>
      </Dialog>

      {/* Thông tin chi tiết hóa đơn */}
      {React.useMemo(() => {
        return (
          <ServiceInvoicesClient
            open={action.open}
            onClose={() => {
              setAction({
                ...action,
                open: false,
                type: "",
                payload: "",
                item: {},
              });
            }}
            item={action.item}
            onEditSuccess={function (is_success: any): void {
              onloadSoVch05M()
            }}
            action={action.action}
            itemSelected={action.payload}
            onSubmit={handleSubmit}
            domesticWaterCycle={cycle}
            component="QLCCDOMESTICWATERSERVICE"
            t_tt={action.t_tt}
            thongTinHoaDon={action.thongTinHoaDon}
            handleDelBill={handleDelBill}
          />
        );
      }, [action.open, setReload])}

      <div
        className="d-flex justify-content-md-between flex-column flex-md-row title-color"
        style={{
          minHeight: 30,
          color: "rgb(0 78 255 / 88%)",
          marginTop: 15,
          marginBottom: 15,
          fontSize: 22,
          fontWeight: 550,
        }}
      >
        <DHSBreadcrumb location={location} />
      </div>

      {/* Control */}
      <DHSToolbarRole
        id={" "}
        menu={menu}
        customMenu={[]}
        onClickAction={handleClickAction}
      />

      <DHSDataGridEdit
        data={soVch05List}
        table_name={menu?.component}
        keyExpr="stt_rec"
        mode="popup"
        dataGridRef={dataGridRef}
        allowEdit={false}
        // pageSize={menu?.page_size}
        // isPagination={menu?.is_pagination}
        isPagination={false}
        isMultiple={menu?.is_multiple}
        groupingAutoExpandAll={false}
        handlePageChange={handlePageChange}
        handleSelectionChanged={handleSelectionChanged}
        handleCellPrepared={handleChangeColorCell}
      />

      {/* <BlockUI blocking={loading} title={""}></BlockUI> */}

      <DHSDialog
        open={actionHDDT.open}
        action={""}
        isLoading={false}
        onClose={() => {
          setActionHDDT({
            ...actionHDDT,
            open: false,
            thongTinHoaDon: undefined
          })
        }}
        handleClickAction={handUploadHDDT}
      >
        <div style={{ height: '100%' }}>
          {
            actionHDDT.thongTinHoaDon && <>
              <Typography style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 17 }}>
                Số hóa đơn:  {actionHDDT.thongTinHoaDon['so_ct']}
              </Typography>
              <Typography style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 17 }}>
                Căn hộ:  {actionHDDT.thongTinHoaDon['apartment_name']}
              </Typography>
            </>
          }
          <DHSUploadFile
            value={fileUpload?.fileUrl}
            name={"fileUpload"}
            handleAddNewImage={handleAddNewImage}
            handleChangeImage={handleChangeImage}
            handleRemoveImage={handleRemoveImage}
            fileName={fileUpload?.fileName ?? ""}

            // width="50%"
            height={"100%"}
          />

        </div>
      </DHSDialog>
      <DHSLoadPanel open={loading} />
    </div>
  );
};

export default ServiceInvoiceClientList;
