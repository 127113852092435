import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import {
  ReportInfo,
  SYS_Token_AuthenticationModel,
} from "../../../app/shared/service-proxies/api-shared";
import moment from "moment";
import DataGrid from "devextreme-react/data-grid";

export const exportExcelReportNoFooter = (
  dataGridRef: React.RefObject<DataGrid<any, any>>,
  reportInfo: ReportInfo,
  fromDate: Date,
  toDate: Date
) => {
  const SessionLogin: SYS_Token_AuthenticationModel = JSON.parse(
    localStorage.getItem("SessionLogin") || "{}"
  );

  if (dataGridRef.current) {
    const e = {
      cancel: true,
      component: dataGridRef.current.instance,
      element: dataGridRef.current.instance.element,
      format: "xlsx",
      selectedRowsOnly: true,
      // selectedRowsOnly:false,
      fileName: '"DataGrid"',
    };
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(`${reportInfo?.title || "sheet1"}`);

    console.log(dataGridRef.current.instance);

    exportDataGrid({
      component: e.component,
      worksheet,
      topLeftCell: { row: 6, column: 1 },
      autoFilterEnabled: true,
      customizeCell: ({ gridCell, excelCell }) => {
        // console.log(excelCell);
        if (excelCell._row._number % 2 === 0) {
          excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "e9f3f7" },
          };
        }
      },
    })
      .then((cellRange: any) => {
        //company
        const companyName = worksheet.getRow(1);
        companyName.height = 15;
        worksheet.mergeCells(1, 1, 1, 3);
        companyName.getCell(1).font = { size: 13 };
        companyName.getCell(1).alignment = { horizontal: "left" };
        companyName.getCell(1).value =
          SessionLogin.company_info?.ten_tcty || "";

        const companyAddress = worksheet.getRow(2);
        companyAddress.height = 15;
        worksheet.mergeCells(2, 1, 2, 3);
        companyAddress.getCell(2).font = { size: 10 };
        companyAddress.getCell(2).alignment = { horizontal: "left" };
        companyAddress.getCell(2).value =
          SessionLogin.company_info?.dia_chi || "";

        // header
        const headerRow = worksheet.getRow(3);
        headerRow.height = 30;
        worksheet.mergeCells(3, 1, 3, 9);

        headerRow.getCell(2).value = reportInfo?.title || "";
        headerRow.getCell(2).font = { size: 22, bold: true };
        headerRow.getCell(2).alignment = { horizontal: "center" };

        const headerRow2 = worksheet.getRow(4);
        headerRow2.height = 20;
        worksheet.mergeCells(4, 1, 4, 9);
        headerRow2.getCell(3).value = `Từ ngày ${moment(fromDate).format(
          "DD/MM/YYYY"
        )} đến ngày ${moment(toDate).format("DD/MM/YYYY")}`;
        headerRow2.getCell(3).font = { size: 10 };
        headerRow2.getCell(3).alignment = { horizontal: "center" };

        //pannal
        const pannal = worksheet.getRow(6);
        pannal.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "2590C3" },
        };
        pannal.font = { color: { argb: "FFFFFF" }, bold: true };
      })
      .then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `${reportInfo?.title || "sheet1"}.xlsx`
          );
        });
      });
    e.cancel = true;
  } else {
  }
};
