import LoadPanel from "devextreme-react/load-panel";
import { Cancelable, EventInfo } from "devextreme/events";
import dxLoadPanel from "devextreme/ui/load_panel";
import React from "react";

interface IProps {
    open: boolean,
    container?: string | Element,
    showIndicator?: boolean,
    onHiding?: (e: Cancelable & EventInfo<dxLoadPanel>) => void
}

const DHSLoadPanel: React.FC<IProps> = (props) => {

    return (
        <LoadPanel
            container={props?.container}

            shadingColor="rgba(0,0,0,0.4)"
            position={props?.container ? {of: props.container} : "center" }
            onHiding={props?.onHiding}
            visible={props.open}
            showIndicator={props?.showIndicator ?? true}
            message="Vui lòng đợi!"
            shading={true}
            showPane={true}
            hideOnOutsideClick={false}
        />
    )
}

export default DHSLoadPanel;