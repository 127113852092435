import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BASE_URL_API from '../../../../../shared/service-proxies/config';
import { QLCCClient, QLCCTaskModel } from '../../../../../shared/service-proxies/api-shared';
import { AppSession } from '../../../../../shared/app-session/app-session';
import { QLCCTaskDetailModel } from '../../../../../shared/service-proxies/api-shared';
import './styles.css';
import TaskChildForumChat from '../task-forum/index2';
import AssignmentIcon from '@mui/icons-material/Assignment';
import moment from 'moment';
import TaskChildForumChatMaster from './task-detail-chat';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IReducer } from '../../../../../../components/layouts';
import { useSelector } from 'react-redux';

interface IProps {
    data: QLCCTaskModel;
    action: string;
    open: boolean | undefined
}

var appSession: AppSession;

const TaskChildForum = (props: IProps) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);

    const [subtasks, setSubTasks] = useState<QLCCTaskDetailModel[]>([]);
    const [idItemSelected, setIdItemSelected] = useState<string>("");
    const [subtask, setSubTask] = useState<QLCCTaskDetailModel>();
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const onload = async () => {
        const resData = await qLCClient.qLCCTaskGet({
            ... new QLCCTaskModel(),
            ...props.data,
            ma_cty: SessionLogin.ma_cty,
            cuser: SessionLogin.userName,
            luser: SessionLogin.userName,
            username_login: SessionLogin.userName,
            follower: SessionLogin.userName, // mới
            start_time: undefined,
            end_time: undefined,
            apartment_building_id: apartment_building_id
        } as QLCCTaskModel)

        if (resData) {
            const task_Father = resData[0];
            if (task_Father &&
                task_Father.qlccTaskDetails &&
                task_Father.qlccTaskDetails.length > 0
            ) {
                const lstSubTask = task_Father.qlccTaskDetails;
                setSubTasks(lstSubTask);
            }
            else {
                setSubTasks([]);
            }
        }
    }

    const handleBack = () => {
        setIdItemSelected("");

    }


    const handleSelected = (id: string) => {
        setIdItemSelected(id);

        const getItem = subtasks.find(item => item.id === id);

        if (getItem) {
            setSubTask(getItem);
        }

        // Update Is Read
        // const findIndex = subtasks.findIndex(item => item.id === id);
        // if (findIndex) {
        //     const newData = subtasks[findIndex];
        //     const lst = subtasks;
        //     lst.splice(findIndex, 1, { ...newData, is_read: true } as QLCCTaskDetailModel);
        //     setSubTasks(lst);
        // }
    }

    useEffect(() => {
        onload();
        if (idItemSelected) {
            // Update Is Read
            const findIndex = subtasks.findIndex(item => item.id === idItemSelected);
            if (findIndex) {
                const newData = subtasks[findIndex];
                const lst = subtasks;
                lst.splice(findIndex, 1, { ...newData, is_read: true } as QLCCTaskDetailModel);
                setSubTasks(lst);
            }
        }
    }, [idItemSelected])

    useEffect(() => {
        (async () => {
            await onload();
            setIdItemSelected("");
        })();
    }, [props.open])

    return (
        <div>
            {
                subtasks ?
                    <>
                        {
                            idItemSelected
                                ? <>
                                    <TaskChildForumChatMaster
                                        task_id={idItemSelected}
                                        action={props.action}
                                        open={props.open}
                                        setBack={handleBack}
                                        data={subtask}
                                    />
                                </>
                                : <>
                                    {
                                        subtasks && subtasks.map((item: QLCCTaskDetailModel) => (
                                            <Box className="task-forum-message-block">
                                                <AssignmentIcon className="task-forum-message-left" />
                                                <Box className={`${item.is_read ? ".task-forum-message-right" : "task-forum-message-right-reader"}`}>
                                                    <p className="task-forum-message-title">
                                                        {item.title ? item.title : item.name ? item.name : 'Công việc không xác định'}
                                                    </p>
                                                    <p className="task-forum-message-subtitle">
                                                        {moment(item.ldate).fromNow()}
                                                    </p>
                                                </Box>
                                                <Box className="task-forum-message-forward" onClick={() => handleSelected(item.id!)}>
                                                    {/* <Box className="task-forum-message-forward-content">
                                                        <ArrowForwardIcon />
                                                    </Box> */}
                                                </Box>
                                            </Box>
                                        ))
                                    }
                                </>
                        }


                    </>
                    : <>
                        <p className='text-center'>Không có hiển thị</p>
                    </>
            }
        </div>
    )
}

export default TaskChildForum