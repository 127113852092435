import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer, Notification } from '../../../../components/layouts';
import DHSGridView from '../../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
import { getRoles } from '../../../../redux/actions/RoleActions';
import { AppSession } from '../../../shared/app-session/app-session';
import {
    HomeClient,
    ManagementClient,
    QLCCApartmentBuildingModel,
    QLCCClient,
    QLCCVehicleInOutModel,
    ReportInfo,
    RoleRightWebModel,
    SysActionType,
} from '../../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../../shared/service-proxies/config';

import { useHistory, useLocation } from 'react-router-dom';
import BlockUI from '../../../../components/Block-UI/Block-ui';
import { useHotkeys } from 'react-hotkeys-hook';
import { ACTIONS } from '../../../../common/enums/DHSToolbarRoleAction';
import DHSToolbarRole from '../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import VehicleCardImportFileAction from '../../actions/category-vehicle-card-action/vehicle-card-import-file';
import CloseIcon from '@mui/icons-material/Close';
import DataGrid from 'devextreme-react/data-grid';
import DHSPopupReport from "../../../../components/DHS/DHS_Popup_Report/DHSPopupReport";
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { EventInfo } from "devextreme/events";

import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import DHSPopupReportDate from '../../../../components/DHS/DHS_Popup_Report/DHSPopupReportDate';
import { AppBar, Autocomplete, Box, Dialog, DialogActions, DialogContent, Divider, IconButton, InputLabel, Slide, TextField, Toolbar, Typography } from '@mui/material';
import BetweenTwoDate from '../cash-book-report/component/between-two-date-X';
import DHSDialogAction from '../../../../components/DHS/DHS_DialogAction/DialogAction';


import { TransitionProps } from '@mui/material/transitions';
import DHSBreadcrumb from '../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import { exportExcelReportNoFooter } from '../../../../components/DHS/DHS_ExportExcelReport/exportExcelReportNoFooter';
import { setApartmentBuilding } from '../../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction';
import DHSAutoCompleteWithDataSource from '../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource';
import { CALL_API_LOAD_APARTMENT_BUILDING_LIST } from '../../apartment-building/apartment-building-list';


export const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const convert = ["success", "error", "warning"];
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any,
    feedbackItem: any;

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null,
    feedbackItem: {},
}

const roleMenu: string = 'CC';
const VehicleCardInOutReport: React.FC<{}> = () => {
    const location = useLocation();

    const dispatch = useDispatch();
    const history = useHistory();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qlccClient = new QLCCClient(appSession, BASE_URL_API);
    const [dataTable, setDataTable] = React.useState<QLCCVehicleInOutModel[]>([] as QLCCVehicleInOutModel[]);
    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
    const [isShowSort, setShowSort] = React.useState<boolean>(false);
    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);


    const managerClient = new ManagementClient(appSession, BASE_URL_API);

    const dataGridRef = React.useRef<DataGrid>(null);
    const [fromDate, setFromDate] = React.useState<Date>(
        new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1, 0, 0, 0))
    );
    const [toDate, setToDate] = React.useState<Date>(
        new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 0, 23, 0, 0))
    );

    const [apartmentBuildings, setApartmentBuildings] = React.useState<QLCCApartmentBuildingModel[]>([] as QLCCApartmentBuildingModel[]);
    // const [apartmentBuilding, setApartmentBuilding1] = React.useState<QLCCApartmentBuildingModel>({} as QLCCApartmentBuildingModel);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
    const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
    const [reportInfo, setReportInfo] = React.useState<ReportInfo>();



    const customMenu = [
        {
            menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
            onClick: () => {
                setShowSearch(!isShowSearch);
            },
        },
        {
            menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
            onClick: () => {
                setShowGroup(!isShowGroup);
            },
        },
        {
            menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
            onClick: () => {
                setShowSort(!isShowSort);
            },
        },
        {
            menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
            onClick: () => {
                setShowFilter(!isShowFilter);
            },
        },
    ];


    useHotkeys<HTMLParagraphElement>('alt+1', () => setShowSearch(!isShowSearch));

    useHotkeys<HTMLParagraphElement>('alt+2', () => setShowGroup(!isShowGroup));

    useHotkeys<HTMLParagraphElement>('alt+3', () => setShowSort(!isShowSort));

    useHotkeys<HTMLParagraphElement>('alt+4', () => setShowFilter(!isShowFilter));
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const onload = () => {
        qlccClient.qLCCVehicleCardInOutGet({
            ... new QLCCVehicleInOutModel(),
            // apartment_building_id: apartmentBuilding.id,
            apartment_building_id: apartment_building_id,
            time_in_from: fromDate,
            time_in_to: toDate,
            ma_cty: SessionLogin.ma_cty,
        } as QLCCVehicleInOutModel).then(res => {
            // console.log(res);
            setDataTable(res);
            setLoading(false);

        }).catch(error => {
            console.log(error)
            setLoading(false);
        }
        )
    }


    // const handleChangeInput = (e: any) => {

    //     const { name, value } = e.target;

    //     if (name === 'apartmentBuilding') {

    //         const apartmentBuildingSelect: any = apartmentBuildings.find(item => item.id === value);

    //         if (apartmentBuildingSelect) {
    //             // setApartmentBuilding1(apartmentBuildingSelect);
    //             dispatch(setApartmentBuilding(apartmentBuildingSelect?.id ?? ''))

    //         }

    //     }

    // };

    React.useEffect(() => {
        setLoading(true);

        CALL_API_LOAD_APARTMENT_BUILDING_LIST().then(res => {
            setApartmentBuildings(res);
            // setApartmentBuilding1(res[0]);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }, [menu?.component])

    React.useEffect(() => {
        setLoading(true)
        onload();
    }, [reload, apartment_building_id]);

    // React.useEffect(() => {
    //     setLoading(true)
    //     if (apartmentBuilding) {
    //         onload();
    //     }
    // }, [apartmentBuilding]);

    const handleClickAction = (action: SysActionType, nowTime: Date) => {

        const obj: string = idItemSelected;

        switch (action?.action_code) {
            case ACTIONS.VIEW:
                break;
            case ACTIONS.ADD:
                break;
            case ACTIONS.EDIT:
                break;
            case ACTIONS.COPY:
                break;
            case ACTIONS.DELETE:
                break;
            case ACTIONS.CLOSE:
                setIsOpen(false);
                break;
            case ACTIONS.SAVE:
                break;
            case ACTIONS.OK:
                onload();
                break;
            case ACTIONS.EXPORTEXCEL:
                if (reportInfo) {
                    try {
                        exportExcelReportNoFooter(dataGridRef, reportInfo, fromDate, toDate);
                    } catch (error) {
                        Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
                    }
                } else {
                    try {
                        exportExcelReportNoFooter(dataGridRef, new ReportInfo(), fromDate, toDate);
                    } catch (error) {
                        Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
                    }
                }
                break;
            case ACTIONS.IMPORTEXCEL:
                setActCx({
                    ...actCx,
                    open: true,
                    type: "EXPORTEXCEL",
                    payload: obj,
                })
                break;
            case ACTIONS.SEARCH:
                setIsOpen(true);
                break;
        }
    }

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {

                const index = e.component.getRowIndexByKey(keys[0]);

                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
            });
        }
    };

    const handleExporting = (e: any) => {
        if (reportInfo) {
            try {
                exportExcelReportNoFooter(dataGridRef, reportInfo, fromDate, toDate);
            } catch (error) {
                Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
            }
        } else {
            try {
                exportExcelReportNoFooter(dataGridRef, new ReportInfo(), fromDate, toDate);
            } catch (error) {
                Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
            }
        }
    };

    const refreshData = async () => {
        setReload(!reload);
    };

    // React.useEffect(() => {
    //     if (isOpen) {
    //         if (apartmentBuilding && apartmentBuilding.id !== apartment_building_id) {
    //             const findApartmentBuilding = apartmentBuildings.find(item => item.id === apartment_building_id);
    //             if (findApartmentBuilding) {
    //                 setApartmentBuilding1(findApartmentBuilding);
    //             }
    //         }
    //     }
    // }, [isOpen])

    return (
        <div className='container-fluid'>
            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location}
                />
            </div>



            {/* Control */}
            <DHSToolbarRole
                id={idItemSelected as string}
                menu={menu}
                customMenu={customMenu}
                onClickAction={handleClickAction}
            />

            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                // key="so_ct"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handlePageChange={handlePageChange}
                handleSelectionChanged={handleSelectionChanged}
                handleExporting={handleExporting}
            />
            {/* đang đợi fix  */}
            <DHSPopupReportDate
                reportInfo={reportInfo}
                setReportInfo={setReportInfo}
                refreshData={() => refreshData()}
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
                setIsOpen={setIsOpen}
                isOpen={false}
            />


            {/* Lọc dữ liệu */}
            <Dialog
                id='sysMenu4'
                open={isOpen}
                TransitionComponent={Transition}
                onClose={() => setIsOpen(false)}
                aria-describedby="alert-dialog-slide-description"
                className='d-flex justify-content-end'
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            Lọc dữ liệu bảng
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setIsOpen(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent>
                    {/* Mẫu báo cáo */}
                    <Box className='w-100 mt-2'>
                        <InputLabel className='cc-label'>Dự án</InputLabel>
                        <DHSAutoCompleteWithDataSource
                            id="pay-type-select"
                            className="cc-input"
                            dataSource={apartmentBuildings}
                            value={
                                // apartmentBuilding?.id
                                apartment_building_id
                            }
                            displayMember='name'
                            valueMember="id"
                            onValueChanged={(e, newValue: any) => {
                                // handleChangeInput({
                                //     target: {
                                //         name: "apartmentBuilding",
                                //         value: newValue?.id ? newValue.id : null,
                                //     },
                                // });
                                if(newValue && newValue?.id){
                                    dispatch(setApartmentBuilding(newValue))
                                }
                            }}
                        />
                        {/* <Autocomplete
                            id="gender-select"
                            sx={{ width: "100%" }}
                            options={apartmentBuildings}
                            autoHighlight
                            getOptionLabel={(option: any) => option?.name}
                            value={
                                apartmentBuilding?.id
                                    ? apartmentBuildings.find(
                                        (item: any) => item.id === apartmentBuilding.id
                                    )
                                    : null
                            }
                            onChange={(e, newValue: any) => {
                                handleChangeInput({
                                    target: {
                                        name: "apartmentBuilding",
                                        value: newValue?.id ? newValue.id : null,
                                    },
                                });
                            }}

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className="cc-input"
                                    // label="Dự án"
                                    placeholder='Chọn dự án'
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        /> */}
                    </Box>

                    {/* Giữa 2 ngày */}
                    <Box className='w-100'>
                        <BetweenTwoDate
                            fromDate={setFromDate}
                            toDate={setToDate}
                            reload={() => setReload(!reload)}
                        />
                    </Box>

                </DialogContent>
                <DialogActions>
                    <DHSDialogAction

                        menu={menu}
                        onClickAction={handleClickAction}

                    />
                </DialogActions>
            </Dialog>

            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default VehicleCardInOutReport;


