import React, { useEffect, useRef, useState } from 'react'
import { QLCCApartmentModel, QLCCClient, QLCCEWaterModel } from '../../../shared/service-proxies/api-shared'
import DataGridEdit from '../../../../components/DHS/DHS_DataGridEdit/DataGridEdit';
import { Button } from 'antd';
import {  getListDataRowSelected} from '../../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';
import BASE_URL_API from '../../../shared/service-proxies/config';
import { AppSession } from '../../../shared/app-session/app-session';
import { Notification } from '../../../../components/layouts';
import { MESSAGE_TYPE } from '../../../../common/Interfaces/StatusMessage';
import DHSLoadPanel from '../../../../components/DHSComponents/dialogs-and-notifications/DHSLoadPanel/DHSLoadPanel';
import { AppBar, Box, Dialog, DialogActions, DialogContent, Divider, IconButton, InputLabel, Slide, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from "@mui/icons-material/Close";
import DHSTextBox from '../../../../components/DHSComponents/editors/DHSTextBox/DHSTextBox';
import DHSButton from '../../../../components/DHSComponents/actions-and-list/DHSButton/DHSButton';
import DHSSwitch from '../../../../components/DHSComponents/editors/DHSSwitch/DHSSwitch';

interface IProps {
    dataSourceApartment: QLCCApartmentModel,
    open: boolean,
}

var appSession: AppSession;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ApartmentWaterAction = (props: IProps) => {
    const { dataSourceApartment, open } = props
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const dataGridRef = useRef(null)
    const [dataSource, setDataSource] = useState<QLCCEWaterModel[]>([])
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [openDialog, setClose] = useState<boolean>(false);
    const [dataOnForm, setDataOnForm] = useState<QLCCEWaterModel>();
    const textBoxRef = useRef(null)

    useEffect(() => {
        if (open && dataSourceApartment) {
            onLoadDataSource();
        }
    }, [open, dataSourceApartment])

    async function onLoadDataSource() {
        const apartment_building_id = dataSourceApartment.apartment_building_id
        try {
            if (dataSourceApartment && dataSourceApartment?.id && dataSourceApartment?.apartment_building_id) {
                const res = await qLCCClient.qLCCWatercGet({
                    ... new QLCCEWaterModel(),
                    apartment_id: dataSourceApartment?.id,
                    apartment_building_id: apartment_building_id,
                    ma_cty: dataSourceApartment?.ma_cty,
                } as QLCCEWaterModel);

                setDataSource(res)
            }

        }
        catch (ex) {
            console.log(ex)
        }
    }

    async function UpdatedRow(e: any) {
        const { key, data } = e;
        try {
            if (dataSourceApartment && dataSourceApartment?.id && dataSourceApartment?.apartment_building_id) {
                setLoading(pre => !pre)
                const res = await qLCCClient.qLCCWaterUpd({
                    ...new QLCCEWaterModel(),
                    ...data,
                    apartment_id: dataSourceApartment?.id,
                    apartment_building_id: dataSourceApartment?.apartment_building_id,
                    ma_cty: SessionLogin?.ma_cty
                } as QLCCEWaterModel);
                Notification(MESSAGE_TYPE[res.status!], res.message)
            }
        } catch (ex) {
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    async function RemovedRow(e: any, type: 'obj' | 'arr') {
        const { key, data } = e;
        try {
            if (dataSourceApartment && dataSourceApartment?.id && dataSourceApartment?.apartment_building_id) {
                if (type === 'obj') {
                    setLoading(pre => !pre)
                    const res = await qLCCClient.qLCCWaterDel({
                        ...new QLCCEWaterModel(),
                        ...data,
                        apartment_id: dataSourceApartment?.id,
                        apartment_building_id: dataSourceApartment?.apartment_building_id,
                        ma_cty: SessionLogin?.ma_cty
                    } as QLCCEWaterModel);
                    Notification(MESSAGE_TYPE[res.status!], res.message)
                } else if (type === 'arr') {
                    setLoading(pre => !pre)
                    const lstId = e.flatMap((item: QLCCEWaterModel) => item.code);
                    const res = await qLCCClient.qLCCWaterDel({
                        ...new QLCCEWaterModel(),
                        code: lstId.join(';'),
                        apartment_id: dataSourceApartment?.id,
                        apartment_building_id: dataSourceApartment?.apartment_building_id,
                        ma_cty: SessionLogin?.ma_cty
                    } as QLCCEWaterModel);
                    Notification(MESSAGE_TYPE[res.status!], res.message)
                    if (res.status === 0) {
                        onLoadDataSource();
                    }
                }
            }
        } catch (ex) {
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    const handleClickVehicleAction = async (e: any) => {
        // console.log(e)
        try {
            switch (e?.action?.toUpperCase()) {
                case "ADD":
                    setClose(true); // open form
                    break;
                case "DELETE":
                    debugger
                    const arrObj: any = await getListDataRowSelected(dataGridRef);
                    if (arrObj && arrObj.length > 0) {
                        RemovedRow(arrObj, 'arr')
                    }
                    break;
                default:
                    console.log(e)
            }
        } catch (ex) {
            console.warn(ex)
        }
    }

    function onClose() {
        setClose(pre => !pre)
    }

    useEffect(() => {
        if (openDialog) {
            if (textBoxRef.current !== null && typeof textBoxRef.current !== 'undefined') {
                (textBoxRef.current as any)?.focus?.();
            }
            setDataOnForm({ ...new QLCCEWaterModel(), active: true } as QLCCEWaterModel);
        }
    }, [openDialog])

    async function handleChangeValue(name: string, value: string) {
        setDataOnForm({
            ...new QLCCEWaterModel(),
            ...dataOnForm,
            [name]: value,
        } as QLCCEWaterModel)
    }

    async function handleDialogInserted() {
        const data = dataOnForm;
        try {
            if (dataSourceApartment && dataSourceApartment?.id && dataSourceApartment?.apartment_building_id) {
                if (data?.code?.includes(' ') === false && data?.code.length > 0 && data?.name && data?.name.length > 0) {
                    setLoading(pre => !pre)
                    const res = await qLCCClient.qLCCWaterIns({
                        ...new QLCCEWaterModel(),
                        ...data,
                        apartment_id: dataSourceApartment?.id,
                        apartment_building_id: dataSourceApartment?.apartment_building_id,
                        ma_cty: SessionLogin?.ma_cty
                    } as QLCCEWaterModel);
                    Notification(MESSAGE_TYPE[res.status!], res.message)
                    if (res.status === 0) {
                        onLoadDataSource();
                        setClose(pre => !pre);
                    }
                } else {
                    Notification(MESSAGE_TYPE[1], "Dữ liệu nhập không chính xác")
                }
            }
        } catch (ex) {
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div>
            <div className='d-flex'>
                <div className='px-1'>
                    <Button type='text' size='large' style={{ borderRadius: 5, display: 'flex', alignItems: 'center', color: "#3787D7", fontWeight: 600 }} icon={<i className="bi bi-plus-circle mr-2"></i>} onClick={() => handleClickVehicleAction({
                        action: "ADD"
                    })}>Thêm</Button>

                </div>
                <Button type='text' size='large' style={{ borderRadius: 5, display: 'flex', alignItems: 'center', color: "#3787D7", fontWeight: 600 }} icon={<i className="bi bi-trash mr-2"></i>} onClick={() => handleClickVehicleAction({
                    action: "DELETE"
                })}>Xóa</Button>
            </div>
            <DataGridEdit
                data={dataSource}
                table_name={"ApartmentElectricTable"}
                keyExpr="id"
                mode="cell"
                dataGridRef={dataGridRef}
                allowEdit={true}
                isMultiple={true}
                groupingAutoExpandAll={false}
                height={40}
                ma_cty={dataSourceApartment?.ma_cty}
                currency="VND"
                handleRowUpdated={UpdatedRow}
            // handleRowRemoved={RemovedRow}
            // handleRowInserted={InsertedRow}
            // showControlDataGridEdit
            />
            <DHSLoadPanel open={isLoading} />

            {/* Thêm mới đồng hồ */}
            <Dialog
                open={openDialog}
                onClose={onClose}
                TransitionComponent={Transition}
                maxWidth='sm'
                fullWidth
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#2167A3",
                        color: "#ffff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            Thêm đồng hồ nước
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent >
                    <Box className='mb-2'>
                        <InputLabel>CODE<sup className='text-danger'>*</sup></InputLabel>
                        <DHSTextBox ref={textBoxRef} value={dataOnForm?.code} onValueChange={(e: any) => handleChangeValue('code', e !== undefined ? e.toUpperCase() : e,)} hint='Các ký tự phải liền nhau, và không dấu' />
                    </Box>
                    <Box className='mb-2'>
                        <InputLabel>Tên đồng hồ<sup className='text-danger'>*</sup></InputLabel>
                        <DHSTextBox value={dataOnForm?.name} onValueChange={(e: any) => handleChangeValue('name', e)} />
                    </Box>
                    <Box className='mb-2 d-flex'>
                        <InputLabel>Đang sử dụng<sup className='text-danger'>*</sup></InputLabel>
                        <DHSSwitch className='mx-3' value={dataOnForm?.active} onValueChange={(e: any) => handleChangeValue('active', e)} switchedOnText='Bật' switchedOffText='Tắt'/>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <DHSButton text="Lưu" stylingMode='contained' type='default' onClick={handleDialogInserted} icon='bi bi-box-arrow-down'/>
                    <DHSButton text="Đóng" stylingMode='text' onClick={onClose} />
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ApartmentWaterAction