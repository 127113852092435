import DropDownBox from "devextreme-react/drop-down-box";
import { OptionChangedEventInfo } from "devextreme/core/dom_component";
import dxDropDownBox, {
  dxDropDownBoxOptions,
} from "devextreme/ui/drop_down_box";
import React, { useEffect, useState } from "react";
// import {
//   HomeClient,
//   ParaSysGenRowTableModel,
//   QLCCClient,
//   QLCCResidentJobsModel,
//   SysGenRowTableModel,
// } from "../../../../../app/shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../app/shared/service-proxies/config";
import DataGrid from "devextreme-react/data-grid";
import { EventInfo } from "devextreme/events";
import dxDataGrid, { SelectionChangedInfo } from "devextreme/ui/data_grid";
import {
  getDataRowSelected,
  getKeyRowSelected,
} from "../helper-datagrid-edit-get-row";
import DHSDataGridEdit from "../DataGridEdit";
import { HomeClient, ParaSysGenRowTableModel, SysGenRowTableModel } from "../../../../app/shared/service-proxies/api-shared";
import { AppSession } from "../../../../app/shared/app-session/app-session";
const dropDownOptions = { width: 500 };

type Props = {
  event: any;
};
let appSession: AppSession;
const DataGridCustomeDataFilter = (
  event: any,
  valueExpr: any,
  displayExpr: any,
  tableDesign: any,
  is_readonly: any,
  allowEdit: any,
  onOpenedDataGridFilter?:
    | ((isOpen: boolean, datasource: any[], objectEditing?: any) => Promise<any[]>)
    | undefined
) => {
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const [isDropDownOpened, setIsDropDownOpened] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const dataGridRef = React.useRef<DataGrid>(null);
  const [selectedRowKey, setSelectedRowKey] = useState<string | number>(
    event.data.value
  );
  const [genRow, setGenRow] = useState<SysGenRowTableModel[]>([]);

  const boxOptionChanged = (e: any) => {
    if (e.name === "opened") {
      setIsDropDownOpened(e.value);
    }
    // setIsDropDownOpened(false)
  };

  useEffect(() => {
    setData([...event.data.column.lookup.dataSource]);
  }, []);

  const handleSelectItem = async (
    e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
  ) => {
    // const item = await getDataRowSelected(dataGridRef);
    const key = await getKeyRowSelected(dataGridRef);

    event.data.setValue(key);

    setSelectedRowKey(key);
    setIsDropDownOpened(false);
  };

  //   useEffect(() => {
  //     if(newDataSourceFilter)
  //     setData([...newDataSourceFilter])

  //   }, [newDataSourceFilter])

  useEffect(() => {
    (async () => {
      const res = await homeClient.sysGenRowTable({
        ...new ParaSysGenRowTableModel(),
        table_name: tableDesign,
      } as unknown as ParaSysGenRowTableModel);
      setGenRow([...res]);
    })();
  }, []);

  return (
    <DropDownBox
      onOptionChanged={boxOptionChanged}
      opened={isDropDownOpened}
      dropDownOptions={dropDownOptions}
      dataSource={data}
      value={selectedRowKey}
      displayExpr={displayExpr}
      valueExpr={valueExpr}
      readOnly={is_readonly ? true : !allowEdit}
      onOpenedChange={async (value) => {
        if (onOpenedDataGridFilter) {
          const res = await onOpenedDataGridFilter(value, [...data], event.data.data);
          setData([...res]);
        }
      }}
      contentRender={() => (
        <DHSDataGridEdit
          //   data={[...event.data.column.lookup.dataSource]}
          data={data}
          //   table_name={tableDesign}
          dataGenRowTable={genRow}
          keyExpr={valueExpr}
          mode="popup"
          dataGridRef={dataGridRef}
          allowEdit={false}
          isPagination={true}
          isMultiple={false}
          groupingAutoExpandAll={false}
          height={40}
          defaultFilter
          handleSelectionChanged={handleSelectItem} 
        />
      )}
    ></DropDownBox>
  );
};

export default DataGridCustomeDataFilter;
