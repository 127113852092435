import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,

    Divider,
    DialogTitle,
    Autocomplete,
    FormControlLabel,
    Switch,
    Select,
    MenuItem,
    DialogContentText,
    Grid,
} from "@mui/material";
import CurrencyTextField from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import {
    HomeClient,
    QLCCClient,
    RoleRightWebModel,
    QLCCApartmentBuildingModel,
    QLCCApartmentTypesModel,
    LstUnitModel,
    QLCCApartmentModel,
    SysActionType,
    LstItem,
    ItemClient,
    ItemGroupClient,
} from "../../../shared/service-proxies/api-shared";


import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../../actions/ApartmentBuilding.css";
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import notification from "../../../../common/notification/notification";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import { httpPost } from "../../../../common/httpService";
import axios from "axios";
import NumberBox from "devextreme-react/number-box";
import DHSAutocompleteFilterListSelect from "../../../../components/DHSUtilComponent/DHSAutocompleteFilterListSelect/DHSAutocompleteFilterListSelect";
interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (id?: string, dataEdited?: LstItem) => void;
    onAddSuccess: (id?: string, dataAdded?: LstItem) => void;
}
// interface IOpenCamera {
//     pic: any;
//     open: boolean;
// }

// const videoConstraints = {
//     width: 1280,
//     height: 720,
//     facingMode: "user",
// };

// const image_size_citizen_identification_card = {
//     width: 540,
//     height: 360,
// };

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const status_message = ["success", "error"]
var appSession: AppSession;
var htmlString: string;
const VehicleCardDataAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const qlccClient = new QLCCClient(appSession, BASE_URL_API);
    const itemClient = new ItemClient(appSession, BASE_URL_API);
    const itemGroupClient = new ItemGroupClient(appSession, BASE_URL_API);
    // truyền id dự án
    const [data, setData] = React.useState<LstItem>({} as LstItem);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const [isDefaultPre, setIsDefaultPre] = React.useState<boolean>();

    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [totalApartmentMessage, setTotalApartmentMessage] = React.useState<JSX.Element>();
    const [actionUpdateService, setActionUpdateService] = React.useState<string>();

    const [apartmentBuildingOption, setApartmentBuildingOption] = React.useState<any>([]);
    const [utilityGroupOptions, setUtilityGroupOptions] = React.useState<{ id: string, name: string }[]>([]);
    const [fullScreen, setFullscreen] = React.useState<boolean>(false);

    const [utilityStatusOptions, setutilityStatusOptions] = React.useState<any>([
        {
            id: "FA2FEAE9-E9CB-4EE5-AD62-6E2250475C38",
            name: 'Đang áp dụng',
        },
        {
            id: "22925183-043A-4879-BA41-1CE81177CA8B",
            name: 'Không áp dụng',
        },
    ]);
    const [apartmentTypeOptions, setApartmentTypeOptions] = React.useState<any>(
        []
    );
    const [itemGroupOptions, setItemGroupOptions] = React.useState<any>(
        []
    );
    // Phân loại 1
    const [kind1Options, setKind1Options] = React.useState<any>(
        []
    );
    // Phân loại 2
    const [kind2Options, setKind2Options] = React.useState<any>(
        []
    );
    // Phân loại 3
    const [kind3Options, setKind3Options] = React.useState<any>(
        []
    );
    // Chi nhánh
    const [siteOptions, setSiteOptions] = React.useState<any>(
        []
    );
    // Kho
    const [inventoryOptions, setInventoryOptions] = React.useState<any>(
        []
    );
    // Vị trí
    const [locationOptions, setLoacationOptions] = React.useState<any>(
        []
    );
    const [unitOptions, setUnitOptions] = React.useState<any>([]);
    // const [windowDirectionOptions, setWindowDirectionOptions] =
    //     React.useState<any>([]);
    // const [elevatorLocationOptions, setElevatorLocationOptions] =
    //     React.useState<any>([]);
    // const [emergencyExitLocationOptions, setEmergencyExitLocationOptions] =
    //     React.useState<any>([]);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)



    React.useEffect(() => {

        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            itemClient
                .lstItemGet({
                    ...new LstItem(),
                    iditem: props.itemSelected.idItem,
                    mA_CTY: SessionLogin.ma_cty
                } as LstItem)
                .then((res) => {
                    setData(res[0]);
                })
                .catch(() => alert("Không lấy được dữ liệu"));
            setId(props.itemSelected);

        } else {

            setData({
                ...new LstItem(),
                // apartment_building_code: props?.itemSelected?.apartment_building_id,
                // status: utilityStatusOptions[0].id
                apartment_building_id: apartment_building_id
            } as LstItem);
            setId(undefined)
        };
    }, [props.action]);


    React.useEffect(() => {

        // setLoading(true);
        // homeClient
        //     ?.roleRightWebGetAll({
        //         ...new RoleRightWebModel(),
        //         rolename: menu?.moduleid,
        //         username: SessionLogin.userName,
        //         menuid: menu?.menuid,
        //     } as RoleRightWebModel)
        //     .then((res) => {
        //         setRoles(res);
        //         setLoading(false);
        //     });
        // setLoading(true);
        // qlccClient
        //     .qlccApartmentBuildingGet({
        //         ...new QLCCApartmentBuildingModel(),
        //         ma_cty: SessionLogin.ma_cty,
        //         action_type: 'GET'
        //     } as QLCCApartmentBuildingModel)
        //     .then(res => {
        //         setApartmentBuildingOption(res);
        //     })
        // setLoading(true);

        // qlccClient
        //     .qLCCApartmentTypesActions({
        //         ...new QLCCApartmentTypesModel(),
        //         action_type: 'GET'
        //     } as QLCCApartmentTypesModel)
        //     .then(res => {
        //         setApartmentTypeOptions(res)
        //         setLoading(false);
        //     });
        // setLoading(true);

        // qlccClient.qlccLstUnitGet({
        //     ...new LstUnitModel(),
        //     ma_cty: SessionLogin.ma_cty
        // } as LstUnitModel)
        //     .then(res => {
        //         setLoading(false);
        //         setUnitOptions(res)
        //     })

        // httpPost<string>('api/Common/LoadDatasourceBindControlByApartmentBuilding', {
        //     tablelist: "dm_nhom_tien_ich"
        // })
        //     .then(res => {

        //         const utilities: { id: string, name: string }[] = JSON.parse(res.data);
        //         setUtilityGroupOptions(utilities)
        //         setLoading(false)

        //     });
        httpPost<string>('api/Common/LoadDatasourceBindControlByApartmentBuilding', {
            tablelist: "dm_item_group",
            ma_cty: SessionLogin.ma_cty,
            apartment_building_id: apartment_building_id
        })
            .then(res => {

                const itemGroup: any = JSON.parse(res.data);
                setItemGroupOptions(itemGroup?.Table1)
                setLoading(false)

            });

        // 


        httpPost<string>('api/Common/LoadDatasourceBindControlByApartmentBuilding', {
            tablelist: "dm_kho",
            ma_cty: SessionLogin.ma_cty,
            apartment_building_id: apartment_building_id,
        })
            .then(res => {

                const inventories: any = JSON.parse(res.data);
                setInventoryOptions(inventories?.Table1)
                setLoading(false)

            });

        httpPost<string>('api/Common/LoadDatasourceBindControlByApartmentBuilding', {
            tablelist: "dm_vitri",
            apartment_building_id: apartment_building_id,
            ma_cty: SessionLogin.ma_cty
        })
            .then(res => {

                const locations: any = JSON.parse(res.data);
                setLoacationOptions(locations?.Table1)
                setLoading(false)

            });
        httpPost<string>('api/Common/LoadDatasourceBindControlByApartmentBuilding', {
            tablelist: "lstSite",
            apartment_building_id: apartment_building_id,
            ma_cty: SessionLogin.ma_cty
        })
            .then(res => {

                const sites: any = JSON.parse(res.data);
                setSiteOptions(sites?.Table1)
                setLoading(false)

            });
        httpPost<string>('api/Common/LoadDatasourceBindControlByApartmentBuilding', {
            tablelist: "dm_plvt1",
            apartment_building_id: apartment_building_id,
            ma_cty: SessionLogin.ma_cty
        })
            .then(res => {

                const itemKind1s: any = JSON.parse(res.data);
                setKind1Options(itemKind1s?.Table1)
                setLoading(false)

            });
        httpPost<string>('api/Common/LoadDatasourceBindControlByApartmentBuilding', {
            tablelist: "dm_plvt2",
            apartment_building_id: apartment_building_id,
            ma_cty: SessionLogin.ma_cty
        })
            .then(res => {

                const itemKind2s: any = JSON.parse(res.data);
                setKind2Options(itemKind2s?.Table1)
                setLoading(false)

            });
        httpPost<string>('api/Common/LoadDatasourceBindControlByApartmentBuilding', {
            tablelist: "dm_plvt3",
            apartment_building_id: apartment_building_id,
            ma_cty: SessionLogin.ma_cty
        })
            .then(res => {

                const itemKind3s: any = JSON.parse(res.data);
                setKind1Options(itemKind3s?.Table1)
                setLoading(false)

            });
    }, []);

    const handleCheckRequired = () => {
        if (!data?.sku) {
            notification(TYPE_MESSAGE.WARNING, 'Mã vật tư ' + STATUS_MESSAGE[`REQUIRED`]);

            return false;
        }
        if (!data.teN_VT) {
            notification(TYPE_MESSAGE.WARNING, 'Tên vật tư ' + STATUS_MESSAGE[`REQUIRED`]);

            return false;
        }
        return true
    }
    const handleSubmit = (action?: string) => {

        const checked = handleCheckRequired();

        if (checked) {
            setLoading(true)
            if (_id || props.action === ACTIONS.EDIT) {

                itemClient.updateCommand13({
                    ...new LstItem(),
                    ...data,
                    mA_CTY: SessionLogin.ma_cty,
                    luser: SessionLogin.userName,

                } as LstItem)
                    .then((response) => {
                        setLoading(false);
                        notification(status_message[response.status!], STATUS_MESSAGE[`${props.action}_${response.status}`]);
                        if (response.status === 0) {
                            props.onEditSuccess(response.id, data);
                            props.onClose();
                        }


                    });



            }
            else {
                itemClient.insertCommand13({
                    ...new LstItem(),
                    ...data,
                    mA_VT: "VCARD",
                    mA_CTY: SessionLogin.ma_cty,
                    luser: SessionLogin.userName,
                    cuser: SessionLogin.userName,
                    apartment_building_id: apartment_building_id
                } as LstItem)
                    .then((response) => {
                        setLoading(false);
                        notification(status_message[response.status!], STATUS_MESSAGE[`${props.action}_${response.status}`])

                        if (response.status === 0) {
                            props.onAddSuccess(response.id, data);
                            props.onClose();
                        }
                    });
            }
        }

    };

    const handleChangeInput = (e: any) => {
        const { name, value, checked, type } = e.target;

        if (type === 'checkbox') {
            setData({ ...data, [name]: checked } as LstItem);

        }
        else setData({ ...data, [name]: value } as LstItem);
    };

    const handleChangeInputSelect = (e: any) => {
        const { name, value } = e.target;
        setData(pre => ({ ...pre, [name]: value } as LstItem));
    }

    const handleChangeMultipleInput = (e: any) => {
        if (props.action !== ACTIONS.VIEW) {
            const { name, value } = e.target;
            if (name === 'apartment_type_id') {
                const valueSplit = value.map((value: QLCCApartmentTypesModel) => value.id).join(";")
                setData({ ...data, [name]: valueSplit } as LstItem);
            }
            else {
                const valueSplit = value.map((value: QLCCApartmentBuildingModel) => value.id).join(";")
                setData({ ...data, [name]: valueSplit } as LstItem);
            }
        }
    }

    const handleChangeRichText = (stringData: string) => {
        // setData({
        //     ...data,
        //     service_description: stringData,
        // } as LstItem);
        htmlString = stringData;
    };






    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                handleSubmit();
                // handleOpen()
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
        }
    }

    //#region handleImage

    const handleRemoveImage = (e: any) => {
        const { name } = e;

        if (name) {
            setData({
                ...data,
                [name]: ''
            } as LstItem)
        }
    }

    const handleChangeImage = async (e: any) => {

        const { name, files } = e.target;
        const formData = new FormData();
        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };
        for (const file of files) {
            formData.append("MyFile", file);
        }
        setLoading(true)

        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    setLoading(false)
                    setData(pre => ({ ...pre, [name]: res.data[0].path } as LstItem))
                } else notification("warning", "Không lấy được hình ảnh");
            });

        e.target.value = '';

    }

    const handleAddNewImage = async (e: any) => {

        const { name, files } = e.target;
        const formData = new FormData();
        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };
        for (const file of files) {
            formData.append("MyFile", file);
        }
        setLoading(true)

        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    setLoading(false)
                    setData(pre => ({ ...pre, [name]: res.data[0].path } as LstItem))
                } else notification("warning", "Không lấy được hình ảnh");
            });

        e.target.value = '';
    }

    const onChangeChecked = (e: any, checked: boolean) => {
        const { name } = e.target;
        setData(pre => ({
            ...pre,
            [name]: checked,
        } as LstItem));
    };
    //#endregion

    return (
        <Dialog
            // id="sysMenu"
            open={props.open}
            onClose={props.onClose}
            // sx={{
            //     display: "flex",
            //     justifyContent: "flex-end",
            // }}
            TransitionComponent={Transition}
            maxWidth="xl"
            fullWidth
            fullScreen={fullScreen}
            className="Asset-control"
        >
            {/* <Dialog
                open={openCamera.open}
                onClose={handleCloseCamera}
                sx={{ height: "inherit" }}
            >
                
            </Dialog> */}

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === "UPDATE" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1.25 }}>
                        <IconButton
                            edge="end"

                            onClick={() => {
                                setFullscreen(pre => !pre)
                            }}
                            aria-label="full screen"
                        >
                            {fullScreen ? <FullscreenExitIcon sx={{ color: '#1f2937' }} /> : <FullscreenIcon sx={{ color: '#1f2937' }} />}
                        </IconButton>
                        <IconButton
                            edge="end"
                            onClick={() => {
                                props.onClose();
                            }}
                            aria-label="close"
                        >
                            <CloseIcon
                                sx={{ color: '#1f2937' }}
                            />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <div className="row gutter-1">
                    <div className="col -12 col-md-4">
                        <div className="card mt-2">

                            <div className="card-body pt-2">
                                <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                    Thông tin vật tư
                                </div>
                                {/* Mã hàng hóa/vật tư */}
                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel
                                            required
                                            sx={{ fontSize: 14, color: "#333", }}
                                        >
                                            Mã hàng hóa/vật tư
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            autoFocus
                                            // placeholder="Mã hàng hóa/vật tư ..."
                                            name="sku"
                                            onChange={(e) => {
                                                handleChangeInput(e);
                                                // setData(pre => ({
                                                //     ...pre,
                                                //     sku: e.target.value
                                                // } as LstItem))
                                            }}
                                            value={data?.sku ?? null}
                                            inputProps={{
                                                autoComplete: "new-password",
                                                readOnly: props.action === ACTIONS.VIEW
                                                // type: "search" // disable autocomplete and autofill
                                            }}
                                        // disabled={props.action === ACTIONS.VIEW}
                                        />
                                    </Grid>
                                </Grid>
                                {/* Tên hàng hóa/vật tư */}
                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel
                                            required
                                            sx={{ fontSize: 14, color: "#333", }}
                                        >
                                            Tên hàng hóa/vật tư
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}

                                            // placeholder="Tên hàng hóa/vật tư ..."
                                            name="teN_VT"
                                            onChange={handleChangeInput}
                                            value={data?.teN_VT ?? null}
                                            inputProps={{
                                                autoComplete: "new-password",
                                                readOnly: props.action === ACTIONS.VIEW
                                                // type: "search" // disable autocomplete and autofill
                                            }}
                                        // disabled={props.action === ACTIONS.VIEW}
                                        />
                                    </Grid>
                                </Grid>
                                {/* Tên hàng hóa/vật tư IN */}
                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel

                                            sx={{ fontSize: 14, color: "#333", }}
                                        >
                                            Tên hàng hóa/vật tư IN
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}

                                            // placeholder="Tên hàng hóa/vật tư IN ..."
                                            name="teN_VT_IN"
                                            onChange={handleChangeInput}
                                            value={data?.teN_VT_IN ?? null}
                                            inputProps={{
                                                autoComplete: "new-password",
                                                readOnly: props.action === ACTIONS.VIEW
                                                // type: "search" // disable autocomplete and autofill
                                            }}
                                        // disabled={props.action === ACTIONS.VIEW}
                                        />
                                    </Grid>
                                </Grid>

                                {/*Đơn vị tính */}
                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel
                                            sx={{ fontSize: 14, color: "#333", }}
                                        >
                                            Đơn vị tính
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <DHSAutocompleteFilterListSelect
                                            table_list="lstUnit"
                                            value={data?.dvT_ID}
                                            onValueChanged={(e, newValue: any) => {
                                                handleChangeInput({
                                                    target: {
                                                        name: "dvT_ID",
                                                        value: newValue?.dvt_id ? newValue.dvt_id : null,
                                                    },
                                                });

                                                setData(pre => ({
                                                    ...pre,
                                                    dvt: newValue?.dvt_name
                                                } as LstItem))



                                            }}

                                            readOnly={props.action === ACTIONS.VIEW || props.action === ACTIONS.APPROVED}
                                        />
                                    </Grid>
                                </Grid>

                            </div>
                        </div>


                        {/* Trạng thái tiện ích*/}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Trạng thái tiện ích
                            </InputLabel>
                            <Autocomplete
                                id="main-direction-select"
                                sx={{ width: "100%" }}
                                options={utilityStatusOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.status
                                        ? utilityStatusOptions.find(
                                            (item: any) => (item.name as string).toLowerCase() === data.status?.toLowerCase()
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "status",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                readOnly={props.action === ACTIONS.VIEW}
                            />
                        </Box> */}





                    </div>
                    <div className="col-12 col-md-4">
                        <div className="card mt-2">

                            <div className="card-body pt-2">
                                <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                    Nhóm vật tư
                                </div>
                                {/* Nhóm hàng hóa/vật tư */}
                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel

                                            sx={{ fontSize: 14, color: "#333", }}
                                        >
                                            Nhóm hàng hóa/vật tư
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Autocomplete
                                            id="main-direction-select"
                                            sx={{ width: "100%" }}
                                            options={itemGroupOptions}
                                            autoHighlight
                                            getOptionLabel={(option: any) => option?.ten_nhvt}
                                            value={
                                                data?.idgroup
                                                    ? itemGroupOptions.find(
                                                        (item: any) => item.IDGroup === data?.idgroup
                                                    )
                                                    : null
                                            }
                                            onChange={(e, newValue: any) => {
                                                handleChangeInput({
                                                    target: {
                                                        name: "idgroup",
                                                        value: newValue?.IDGroup ? newValue.IDGroup : null,
                                                    },
                                                });




                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className="cc-input"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "new-password",
                                                        // type: "search" // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                            readOnly={props.action === ACTIONS.VIEW || props.action === ACTIONS.APPROVED}
                                        />
                                    </Grid>
                                </Grid>
                                {/* Phân loại 1 */}
                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel

                                            sx={{ fontSize: 14, color: "#333", }}
                                        >
                                            Phân loại 1
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Autocomplete
                                            id="main-direction-select"
                                            sx={{ width: "100%" }}
                                            options={kind1Options}
                                            autoHighlight
                                            getOptionLabel={(option: any) => option?.ten_plvt}
                                            value={
                                                data?.idkinD1
                                                    ? kind1Options.find(
                                                        (item: any) => item.IDkind === data?.idkinD1
                                                    )
                                                    : null
                                            }
                                            onChange={(e, newValue: any) => {
                                                handleChangeInput({
                                                    target: {
                                                        name: "idkinD1",
                                                        value: newValue?.IDkind ? newValue.IDkind : null,
                                                    },
                                                });




                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className="cc-input"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "new-password",
                                                        // type: "search" // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                            readOnly={props.action === ACTIONS.VIEW || props.action === ACTIONS.APPROVED}
                                        />
                                    </Grid>
                                </Grid>
                                {/* Phân loại 2 */}
                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel

                                            sx={{ fontSize: 14, color: "#333", }}
                                        >
                                            Phân loại 2
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Autocomplete
                                            id="main-direction-select"
                                            sx={{ width: "100%" }}
                                            options={kind2Options}
                                            autoHighlight
                                            getOptionLabel={(option: any) => option?.ten_plvt}
                                            value={
                                                data?.idkinD2
                                                    ? kind2Options.find(
                                                        (item: any) => item.IDkind === data?.idkinD2
                                                    )
                                                    : null
                                            }
                                            onChange={(e, newValue: any) => {
                                                handleChangeInput({
                                                    target: {
                                                        name: "idkinD2",
                                                        value: newValue?.IDkind ? newValue.IDkind : null,
                                                    },
                                                });




                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className="cc-input"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "new-password",
                                                        // type: "search" // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                            readOnly={props.action === ACTIONS.VIEW || props.action === ACTIONS.APPROVED}
                                        />
                                    </Grid>
                                </Grid>
                                {/* Phân loại 3 */}
                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel

                                            sx={{ fontSize: 14, color: "#333", }}
                                        >
                                            Phân loại 3
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Autocomplete
                                            id="main-direction-select"
                                            sx={{ width: "100%" }}
                                            options={kind3Options}
                                            autoHighlight
                                            getOptionLabel={(option: any) => option?.ten_plvt}
                                            value={
                                                data?.idkinD3
                                                    ? kind3Options.find(
                                                        (item: any) => item.IDkind === data?.idkinD3
                                                    )
                                                    : null
                                            }
                                            onChange={(e, newValue: any) => {
                                                handleChangeInput({
                                                    target: {
                                                        name: "idkinD3",
                                                        value: newValue?.IDkind ? newValue.IDkind : null,
                                                    },
                                                });




                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className="cc-input"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "new-password",
                                                        // type: "search" // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                            readOnly={props.action === ACTIONS.VIEW || props.action === ACTIONS.APPROVED}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>

                    </div>




                    <div className="col-12 col-md-4">
                        <div className="card mt-2">

                            <div className="card-body pt-2">
                                <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                    Chi nhánh, kho, vị trí
                                </div>
                                {/* Chi nhánh */}
                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel

                                            sx={{ fontSize: 14, color: "#333", }}
                                        >
                                            Chi nhánh
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Autocomplete
                                            id="main-direction-select"
                                            sx={{ width: "100%" }}
                                            options={siteOptions}
                                            autoHighlight
                                            getOptionLabel={(option: any) => option?.site_name}
                                            value={
                                                data?.sitE_ID
                                                    ? siteOptions.find(
                                                        (item: any) => item.site_id === data?.sitE_ID
                                                    )
                                                    : null
                                            }
                                            onChange={(e, newValue: any) => {
                                                handleChangeInput({
                                                    target: {
                                                        name: "sitE_ID",
                                                        value: newValue?.site_id ? newValue.site_id : null,
                                                    },
                                                });




                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className="cc-input"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "new-password",
                                                        // type: "search" // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                            readOnly={props.action === ACTIONS.VIEW || props.action === ACTIONS.APPROVED}
                                        />
                                    </Grid>
                                </Grid>
                                {/* Kho */}
                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel

                                            sx={{ fontSize: 14, color: "#333", }}
                                        >
                                            Kho
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Autocomplete
                                            id="item-select"
                                            sx={{ width: "100%" }}
                                            options={inventoryOptions}
                                            autoHighlight
                                            getOptionLabel={(option: any) => option?.ten_kho}
                                            value={
                                                data?.mA_KHO
                                                    ? inventoryOptions.find(
                                                        (item: any) => item.ma_kho === data?.mA_KHO
                                                    )
                                                    : null
                                            }
                                            onChange={(e, newValue: any) => {
                                                handleChangeInput({
                                                    target: {
                                                        name: "mA_KHO",
                                                        value: newValue?.ma_kho ? newValue.ma_kho : null,
                                                    },
                                                });




                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className="cc-input"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "new-password",
                                                        // type: "search" // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                            readOnly={props.action === ACTIONS.VIEW || props.action === ACTIONS.APPROVED}
                                        />
                                    </Grid>
                                </Grid>
                                {/* Vị trí */}
                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel

                                            sx={{ fontSize: 14, color: "#333", }}
                                        >
                                            Vị trí
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Autocomplete
                                            id="item-select"
                                            sx={{ width: "100%" }}
                                            options={locationOptions}
                                            autoHighlight
                                            getOptionLabel={(option: any) => option?.ten_vitri}
                                            value={
                                                data?.mA_VITRI
                                                    ? locationOptions.find(
                                                        (item: any) => item.ma_vitri === data?.mA_VITRI
                                                    )
                                                    : null
                                            }
                                            onChange={(e, newValue: any) => {
                                                handleChangeInput({
                                                    target: {
                                                        name: "mA_VITRI",
                                                        value: newValue?.ma_vitri ? newValue.ma_vitri : null,
                                                    },
                                                });




                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className="cc-input"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "new-password",
                                                        // type: "search" // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                            readOnly={props.action === ACTIONS.VIEW || props.action === ACTIONS.APPROVED}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <Box className="row">
                            <Box className="col-12">

                                <div className="d-flex align-items-center">
                                    <Switch
                                        name="toN_KHO"
                                        checked={data?.toN_KHO}
                                        onChange={onChangeChecked}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />
                                    <InputLabel sx={{ fontSize: 14, color: '#333', }}>
                                        Theo dõi tồn kho

                                    </InputLabel>
                                </div>
                            </Box>

                        </Box>

                    </div>

                    <div className="col-12 col-md-4">
                        <div className="card mt-2">

                            <div className="card-body pt-2">
                                <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                    Thông tin qui đổi
                                </div>
                                {/* Đơn vị mua */}
                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel

                                            sx={{ fontSize: 14, color: "#333", }}
                                        >
                                            Đơn vị mua
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <div className="row gutter-1">
                                            <div className="col-12 col-xl-6">
                                                <Autocomplete
                                                    id="main-direction-select"
                                                    sx={{ width: "100%" }}
                                                    options={unitOptions}
                                                    autoHighlight
                                                    getOptionLabel={(option: any) => option?.dvt_name}
                                                    value={
                                                        data?.dvT_MUA_ID
                                                            ? unitOptions.find(
                                                                (item: any) => item.dvt_id === data?.dvT_MUA_ID
                                                            )
                                                            : null
                                                    }
                                                    onChange={(e, newValue: any) => {
                                                        handleChangeInput({
                                                            target: {
                                                                name: "dvT_MUA_ID",
                                                                value: newValue?.dvt_id ? newValue.dvt_id : null,
                                                            },
                                                        });

                                                        setData(pre => ({
                                                            ...pre,
                                                            dvT_MUA: newValue?.dvt_name
                                                        } as LstItem))


                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            className="cc-input"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "new-password",
                                                                // type: "search" // disable autocomplete and autofill
                                                            }}
                                                        />
                                                    )}
                                                    readOnly={props.action === ACTIONS.VIEW || props.action === ACTIONS.APPROVED}
                                                />
                                                {/* Giá mua */}





                                            </div>
                                            <div className="col-12 col-xl-6">
                                                <NumberBox
                                                    // className="cc-input"
                                                    format="0,###"
                                                    inputAttr={{ 'aria-label': 'Fixed Poing Format', 'style': 'text-align:right' }}
                                                    defaultValue={data?.hS_DVTMUA}
                                                    value={data?.hS_DVTMUA}
                                                    showSpinButtons
                                                    // style={{
                                                    //     textAlign: "right"
                                                    // }}
                                                    placeholder="Hệ số"
                                                    onValueChange={(value) => {
                                                        handleChangeInput({
                                                            target: {
                                                                name: "hS_DVTMUA",
                                                                value: value
                                                            }
                                                        });


                                                    }}

                                                    readOnly={props.action === ACTIONS.VIEW}

                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                                {/* Đơn vị bán */}
                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel

                                            sx={{ fontSize: 14, color: "#333", }}
                                        >
                                            Đơn vị bán
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <div className="row gutter-1">
                                            <div className="col-12 col-xl-6">
                                                <Autocomplete
                                                    id="main-direction-select"
                                                    sx={{ width: "100%" }}
                                                    options={unitOptions}
                                                    autoHighlight
                                                    getOptionLabel={(option: any) => option?.dvt_name}
                                                    value={
                                                        data?.dvT_BAN_ID
                                                            ? unitOptions.find(
                                                                (item: any) => item.dvt_id === data?.dvT_BAN_ID
                                                            )
                                                            : null
                                                    }
                                                    onChange={(e, newValue: any) => {
                                                        handleChangeInput({
                                                            target: {
                                                                name: "dvT_BAN_ID",
                                                                value: newValue?.dvt_id ? newValue.dvt_id : null,
                                                            },
                                                        });
                                                        setData(pre => ({
                                                            ...pre,
                                                            dvT_BAN: newValue?.dvt_name
                                                        } as LstItem))



                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            className="cc-input"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "new-password",
                                                                // type: "search" // disable autocomplete and autofill
                                                            }}
                                                        />
                                                    )}
                                                    readOnly={props.action === ACTIONS.VIEW || props.action === ACTIONS.APPROVED}
                                                />
                                            </div>
                                            <div className="col-12 col-xl-6">
                                                <NumberBox
                                                    // className="cc-input"
                                                    format="0,###"
                                                    inputAttr={{ 'aria-label': 'Fixed Poing Format', 'style': 'text-align:right' }}
                                                    defaultValue={data?.hS_DVTBAN}
                                                    value={data?.hS_DVTBAN}
                                                    showSpinButtons
                                                    // style={{
                                                    //     textAlign: "right"
                                                    // }}
                                                    placeholder="Hệ số"
                                                    onValueChange={(value) => {
                                                        handleChangeInput({
                                                            target: {
                                                                name: "hS_DVTBAN",
                                                                value: value
                                                            }
                                                        });


                                                    }}

                                                    readOnly={props.action === ACTIONS.VIEW}

                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-md-4">
                        <div className="card mt-2">

                            <div className="card-body pt-2">
                                <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                    Giới hạn tồn kho
                                </div>

                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số lượng max</InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <NumberBox
                                            // className="cc-input"
                                            format="0,###"
                                            inputAttr={{ 'aria-label': 'Fixed Poing Format', 'style': 'text-align:right' }}
                                            defaultValue={data?.sL_MAX}
                                            value={data?.sL_MAX}
                                            mode={"number"}
                                            step={1}
                                            showSpinButtons
                                            // style={{
                                            //     textAlign: "right"
                                            // }}
                                            onValueChange={(value) => {
                                                handleChangeInput({
                                                    target: {
                                                        name: "sL_MAX",
                                                        value: value
                                                    }
                                                });


                                            }}

                                            readOnly={props.action === ACTIONS.VIEW}

                                        />
                                    </Grid>

                                </Grid>
                                {/* Số lượng min */}
                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số lượng min</InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <NumberBox
                                            // className="cc-input"
                                            format="0,###"
                                            inputAttr={{ 'aria-label': 'Fixed Poing Format', 'style': 'text-align:right' }}
                                            defaultValue={data?.sL_MIN}
                                            value={data?.sL_MIN}
                                            mode={"number"}
                                            step={1}
                                            showSpinButtons
                                            // style={{
                                            //     textAlign: "right"
                                            // }}
                                            onValueChange={(value) => {
                                                handleChangeInput({
                                                    target: {
                                                        name: "sL_MIN",
                                                        value: value
                                                    }
                                                });


                                            }}

                                            readOnly={props.action === ACTIONS.VIEW}

                                        />
                                    </Grid>

                                </Grid>


                            </div>
                        </div>
                    </div>
                    {/* chỗ này cắt div*/}
                    <div className="col-12 col-md-4">
                        {/* Người sử dụng */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Người sử dụng
                            </InputLabel>

                            <Autocomplete
                                id="main-direction-select"
                                sx={{ width: "100%" }}
                                options={mainDirectionOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.apartment_id
                                        ? mainDirectionOptions.find(
                                            (item: any) => item.id === data.apartment_id
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "apartment_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password",
                                            // type: "search" // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box> */}

                        <div className="card mt-2">

                            <div className="card-body pt-2">
                                <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                    Thông tin đơn giá
                                </div>



                                {/* Giá mua */}

                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Giá mua</InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <NumberBox
                                            // className="cc-input"
                                            format="0,###"
                                            inputAttr={{ 'aria-label': 'Fixed Poing Format', 'style': 'text-align:right' }}
                                            defaultValue={data?.purchasE_PRICE}
                                            value={data?.purchasE_PRICE}
                                            showSpinButtons
                                            // style={{
                                            //     textAlign: "right"
                                            // }}
                                            onValueChange={(value) => {
                                                handleChangeInput({
                                                    target: {
                                                        name: "purchasE_PRICE",
                                                        value: value
                                                    }
                                                });


                                            }}

                                            readOnly={props.action === ACTIONS.VIEW}

                                        />
                                    </Grid>

                                </Grid>
                                {/* Giá bán */}

                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Giá bán</InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <NumberBox
                                            // className="cc-input"
                                            format="0,###"
                                            inputAttr={{ 'aria-label': 'Fixed Poing Format', 'style': 'text-align:right' }}
                                            defaultValue={data?.selL_PRICE}
                                            value={data?.selL_PRICE}
                                            showSpinButtons
                                            // style={{
                                            //     textAlign: "right"
                                            // }}
                                            onValueChange={(value) => {
                                                handleChangeInput({
                                                    target: {
                                                        name: "selL_PRICE",
                                                        value: value
                                                    }
                                                });


                                            }}

                                            readOnly={props.action === ACTIONS.VIEW}

                                        />

                                    </Grid>
                                </Grid>

                            </div>
                        </div>


                        {/* <Box className="row">
                            <Box className="col-6">
                                <InputLabel sx={{ fontSize: 14, color: '#333', }}>
                                    Không sử dụng

                                </InputLabel>
                                <Switch
                                    checked={data?.ksd}
                                    onChange={onChangeChecked}
                                    disabled={props.action === ACTIONS.VIEW}
                                />
                            </Box>

                        </Box> */}



                        {/* Sự dụng cho dự án */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333" }}
                            >
                                Sử dụng cho dự án
                            </InputLabel>
                            <Autocomplete
                                id="apartment-select"
                                sx={{ width: "100%" }}
                                multiple
                                filterSelectedOptions
                                options={apartmentBuildingOption}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.apartment_building_code
                                        ? apartmentBuildingOption.filter(
                                            (item: any) => {
                                                return data.apartment_building_code?.split(";").includes(item.id)
                                            }

                                        )
                                        : []
                                }
                                onChange={(e, newValue: any) => {


                                    handleChangeMultipleInput({
                                        target: {
                                            name: "apartment_building_code",
                                            value: newValue ?? null,
                                        },
                                    });
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password"// disable autocomplete and autofill
                                        }}
                                        placeholder="Dự án ..."


                                    />
                                )}
                                readOnly={props.action === ACTIONS.VIEW}
                            />
                        </Box> */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <div className="d-flex align-items-center">
                                <InputLabel sx={{ fontSize: 14, color: "#000" }}>
                                    Tải hình
                                </InputLabel>

                            </div>
                            {data?.anh ? (<div style={{
                                position: "relative", width: "213px",
                                maxWidth: "213px",
                                height: "213px",
                                maxHeight: "213px",
                            }}>
                                <img
                                    src={`${BASE_URL_API}${data?.anh}`}
                                    alt="Hình"
                                    className="image-shadow"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: 'cover'
                                    }}
                                />
                                <div
                                    className="position-absolute w-100 d-flex justify-content-end"
                                    style={{ top: 1, right: 1, cursor: "pointer" }}
                                >
                                    <IconButton
                                        color="error"
                                        aria-label="upload picture"
                                        component="label"
                                        onClick={() => handleRemoveImage({
                                            name: "anh"
                                        })}
                                        disabled={props.action === ACTIONS.VIEW}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </div>
                                <div
                                    className="position-absolute w-100 d-flex justify-content-end"
                                    style={{ top: 27, right: 1, cursor: "pointer" }}
                                >
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="label"
                                        disabled={props.action === ACTIONS.VIEW}
                                    >
                                        <input
                                            id={`uploadImageUpdate`}
                                            className="flex-grow-1 form-control"
                                            type="file"
                                            accept="image/*"
                                            name="anh"
                                            onChange={
                                                (e) => handleChangeImage(e)
                                            }
                                            // value={data.anh_xe}
                                            style={{ display: "none" }}
                                        />
                                        <ChangeCircleIcon />
                                    </IconButton>
                                </div>
                            </div>) : (
                                <div className="m-1">
                                    <label htmlFor={`uploadImageAdd`} style={{ width: "100%" }} className="label-file-media">
                                        <div
                                            className="c-img-card"
                                            style={{
                                                width: "213px",
                                                maxWidth: "213px",
                                                height: "213px",
                                                maxHeight: "213px",
                                                borderRadius: '5px'
                                            }}
                                        >
                                            <i className="bi bi-images" />
                                            &nbsp;Thêm ảnh mới...
                                        </div>
                                    </label>
                                    <input
                                        id="uploadImageAdd"
                                        className="flex-grow-1 form-control"
                                        type="file"
                                        accept="image/*"
                                        name="anh"
                                        onChange={handleAddNewImage}
                                        // value={data.anh_xe}
                                        style={{ display: "none" }}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />
                                </div>
                            )}
                        </Box> */}


                    </div>
                    {/* <div className="col-xl-6">
                       

                      
                    </div> */}

                    {/* <div className="col-xl-6"> */}
                    {/* Loại căn hộ */}
                    {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Loại căn hộ
                            </InputLabel>
                            <Autocomplete
                                id="apartment-select"
                                sx={{ width: "100%" }}
                                multiple
                                filterSelectedOptions
                                options={apartmentTypeOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.apartment_type_name}
                                value={
                                    data?.apartment_type_id
                                        ? apartmentTypeOptions.filter(
                                            (item: any) => {
                                                return data.apartment_type_id?.split(";").includes(item.id)
                                            }
                                        )
                                        : []
                                }
                                onChange={(e, newValue: any) => {


                                    handleChangeMultipleInput({
                                        target: {
                                            name: "apartment_type_id",
                                            value: newValue ?? null,
                                        },
                                    });
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password"// disable autocomplete and autofill
                                        }}
                                        placeholder="Áp dụng cho loại căn hộ ..."


                                    />
                                )}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box> */}


                    {/* </div> */}
                    {/* 
                    <div className="col-xl-12 mt-3">
                        <Accordion expanded={isExpandApartmentTypeService} onChange={() => { setIsCalledApartmentTypeService(true); setIsExpandApartmentTypeService(pre => !pre) }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>tiện ích loại căn hộ</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ApartmentTypeServiceList />
                            </AccordionDetails>
                        </Accordion>
                    </div> */}
                    {/* Mặt định */}

                    {/* <div className="col-xl-6">
                        <Box style={{ width: "100%", margin: "5px 0px" }}>


                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mặc định</InputLabel>

                            <FormControlLabel control={
                                <Switch
                                    color="primary"
                                    onChange={handleChangeInput}
                                    name="is_default"
                                    value={data?.is_default}
                                    checked={data?.is_default}
                                    disabled={props.action === ACTIONS.VIEW}
                                />}
                                label={
                                    <Typography
                                        sx={{ fontSize: 14, color: '#333' }}>
                                        Không/Có
                                    </Typography>} />


                        </Box>
                    </div> */}


                    <div className="col-12">
                        {/* Ghi chú */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Ghi chú
                            </InputLabel>
                            <RichTextEditor

                                onChange={(stringData) => handleChangeRichText(stringData)}
                                // onChange={(newValue) => handleChangeInput({ target: { name: "service_description", value: stringData } })}
                                value={data?.ghI_CHU ?? ""}
                                readOnly={props.action === ACTIONS.VIEW}
                            />
                        </Box>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>

                <DHSDialogAction

                    action={props.action}
                    menu={menu}
                    onClickAction={handleClickAction}
                />


            </DialogActions>

            {/* <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Xác nhận thay đổi phí vận hành
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {totalApartmentMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation sx={{ textTransform: "capitalize" }} autoFocus variant="contained" color="primary" onClick={() => handleSubmit()}>
                        Chập nhận
                    </Button>
                    {
                        actionUpdateService === "UPDATE" && <Button disableElevation sx={{ textTransform: "capitalize" }} color="error" onClick={() => {
                            handleSubmit("UPDATE-BUT-NOT-DELETE");
                        }}>Không</Button>
                    }
                    <Button disableElevation sx={{ textTransform: "capitalize" }} onClick={handleClose}>Hủy</Button>
                </DialogActions>
            </Dialog> */}
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default VehicleCardDataAction;
