import React from "react";
import { useParams } from "react-router-dom";
import notification from "../../../../../common/notification/notification";
import RichTextEditor from "../../../../../components/DHS/DHS_RichTextEditor";
import Select from "../../../../../components/DHS/DHS_Select";
import { AppSession } from "../../../../shared/app-session/app-session";
import {
  DOCClient,
  DocUserGuideProduction,
  DocUserGuideProductionFeature,
  DocUserGuideProductionModule,
  DocUserGuideProductionTag,
  RoleRightWebModel,
} from "../../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../shared/service-proxies/config";

interface IProps {
  id?: string;
  action: RoleRightWebModel | undefined;
  nowTime: Date;
  onDestroyAction?: (isDestroy: boolean, goBack?: number) => void;
}
var appSession: AppSession;
var htmlString: string = "";
const TagActionPage = (props: IProps) => {
  const docClient = new DOCClient(appSession, BASE_URL_API);
  const [data, setData] = React.useState<DocUserGuideProductionTag>(
    new DocUserGuideProductionTag()
  );
  const [{ _id }, setId] = React.useState(useParams<{ _id: string }>());
  const [back, setBack] = React.useState<number>(-1);
  const [productOptions, setProductOptions] = React.useState<
    DocUserGuideProduction[]
  >([]);
  const [moduleOptions, setModuleOptions] = React.useState<
    DocUserGuideProductionModule[]
  >([]);
  const [featureOptions, setFeatureOptions] = React.useState<
    DocUserGuideProductionFeature[]
  >([]);

  React.useEffect(() => {
    if (_id) {
      docClient
        .docUserGuideProductionTagGet({
          ...new DocUserGuideProductionTag(),
          id: _id,
        } as DocUserGuideProductionTag)
        .then((response) => {
          setData(response[0]);
        });
    }
  }, []);

  React.useEffect(() => {
    const getAllProductData = () => {
      docClient
        .docUserGuideProductionGet(new DocUserGuideProduction())
        .then((res) => {
          setProductOptions(res);
        })
        .catch((error) => console.log(error));
    };
    const getAllModuleData = () => {
      docClient
        .docUserGuideProductionModuleGet(new DocUserGuideProductionModule())
        .then((res) => {
          setModuleOptions(res);
        })
        .catch((error) => console.log(error));
    };
    const getAllFeatureData = () => {
      docClient
        .docUserGuideProductionFeatureGet(new DocUserGuideProductionFeature())
        .then((res) => setFeatureOptions(res))
        .catch((error) => console.log(error));
    };
    getAllModuleData();
    getAllFeatureData();
    getAllProductData();
  }, []);

  React.useEffect(() => {
    if (
      props.action &&
      props.action.class_name?.toLocaleUpperCase() === "SAVE"
    ) {
      try {
        if (_id) {
          docClient
            .docUserGuideProductionTagUpd({
              ...data,
              contents: htmlString,
              id: _id,
            } as DocUserGuideProductionTag)
            .then((res) => {
              setBack((item) => (item -= 1));
              if (res.ret === 0) {
                notification("success", "Cập nhật thành công");
              } else notification("error", "Cập nhật thất bại");
            });
        } else {
          docClient
            .docUserGuideProductionTagIns({
              ...data,
              contents: htmlString,
            } as DocUserGuideProductionTag)
            .then((res) => {
              if (res.ret === 0 && res.id) {
                setId({ _id: res.id });
                notification("success", "Thêm thành công");
              } else notification("error", "Thêm thất bại");
            });
        }
        if (props.onDestroyAction) props.onDestroyAction(true, back - 1);
      } catch (error) {
        console.log(error);
      }
    }
  }, [props.nowTime]);

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value } as DocUserGuideProductionTag);
  };
  const handleChangeRichText = (stringData: string) => {
    htmlString = stringData;
  };

  return (
    <>
      <div
        className="d-flex p-3 justify-content-left"
        style={{ backgroundColor: "#FFF" }}
      >
        <div className="w-100" style={{ maxWidth: 800 }}>
          <div className="form-group">
            <label>Tên</label>
            <input
              className="flex-grow-1 form-control"
              type="text"
              name="name"
              placeholder="Tên"
              onChange={handleChangeInput}
              value={data.name}
            />
          </div>
          <div className="form-group">
            <label>Mô tả</label>
            <textarea
              className="flex-grow-1 form-control"
              name="description"
              placeholder="Mô tả"
              onChange={handleChangeInput}
              value={data.description}
            />
          </div>
          <div>
            <div className="form-group">
              <div className="row">
                <div className="col-4">
                  <Select
                    options={productOptions}
                    label="Thuộc về sản phẩm"
                    onChange={handleChangeInput}
                    value={data.product_id}
                    fieldValue={"id"}
                    fieldDisplay={"name"}
                    name="product_id"
                  />
                </div>
                <div className="col-4">
                  <Select
                    options={moduleOptions?.filter(
                      (module) => module.product_id === data.product_id
                    )}
                    label="Thuộc về Module"
                    onChange={handleChangeInput}
                    value={data.module_id}
                    fieldValue={"id"}
                    fieldDisplay={"name"}
                    name="module_id"
                    disabled={data.product_id ? false : true}
                  />
                </div>
                <div className="col-4">
                  <Select
                    options={featureOptions.filter(
                      (item) => item.module_id === data.module_id
                    )}
                    label="Thuộc về tính năng"
                    onChange={handleChangeInput}
                    value={data.feature_id}
                    fieldValue={"id"}
                    fieldDisplay={"name"}
                    name="feature_id"
                    disabled={data.module_id ? false : true}
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <div>
            <RichTextEditor
              onChange={(stringData: string) =>
                handleChangeRichText(stringData)
              }
              value={data.contents}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TagActionPage;
