import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IReducer } from "../../../../../common/Interfaces/Interfaces";
import DHSToolbar from "../../../../../components/DHS/DHS-Toolbar/DHSToolbar";
import { RoleRightWebModel } from "../../../../shared/service-proxies/api-shared";
import CustomerInfomationAction from "./CustomerInfomationAction";

interface IProps {
  listToolbar?: RoleRightWebModel[];
  rowSelected?: string | undefined;
}

const CustomerInfomationPage = (props: IProps) => {
  const history = useHistory();

  const role = useSelector((state: IReducer) => state.RoleReducer?.role);
  const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
  const [action, setAction] = React.useState<RoleRightWebModel | undefined>(
    role
  );
  const [goBack, setGoBack] = React.useState<number>(-1);
  const [nowTime, setNowTime] = React.useState<Date>(new Date());

  const handleClickAction = (action: RoleRightWebModel, nowTime: Date) => {
    setAction(action);
    setNowTime(nowTime);
  };
  const handleDestroyAction = (
    isDestroy: boolean,
    goBack: number | undefined
  ) => {
    if (isDestroy) setAction(undefined);
    if (goBack) setGoBack(goBack);
  };
  const [title, setTitle] = React.useState<string>("Đang tải nội dung");

  useEffect(() => {
    switch (role?.class_name?.toLocaleUpperCase()) {
      case "ADD":
        setTitle("Thêm khách hàng");
        break;
      case "EDIT":
        setTitle("Sửa khách hàng");
        break;
      case "VIEWDETAIL":
        setTitle("Chi tiết khách hàng");
        break;
      default:
        setTitle("Đang tải nội dung");
    }
  }, [action]);

  return (
    <>
      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          marginTop: 15,
          marginBottom: 15,
          fontSize: 22,
          fontWeight: 550,
        }}
      >
        <i
          className="bi bi-arrow-left"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.go(goBack);
          }}
        ></i>
        &nbsp;&nbsp;
        {title}
      </div>{" "}
      <DHSToolbar
        list={
          roles
            ? roles.filter(
                (item) =>
                  item.on_form?.toLocaleUpperCase() === "EDIT" &&
                  item?.component === "CustomerInfomation"
              )
            : []
        }
        menuid={""}
        id={""}
        current_form={""}
        onClickAction={handleClickAction}
      />
      <CustomerInfomationAction
        id={props.rowSelected ? props.rowSelected : ""}
        action={action}
        nowTime={nowTime}
        onDestroyAction={handleDestroyAction}
      />
    </>
  );
};

export default CustomerInfomationPage;
