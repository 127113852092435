import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer, Notification } from '../../../components/layouts';
// import DHSGridView from '../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
// import { getRoles } from '../../../redux/actions/RoleActions';
import { AppSession } from '../../shared/app-session/app-session';
import {
    ARBookClient,
    CustomerClient,
    HomeClient,
    LstCustomer,
    OBCashBankClient,
    QLCCApartmentBuildingModel,
    QLCCBlockModel,
    QLCCClient,
    RoleRightWebModel,
    SysActionType,
} from '../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../shared/service-proxies/config';

import { useHistory, useLocation } from 'react-router-dom';
import BlockUI from '../../../components/Block-UI/Block-ui';
import { useHotkeys } from 'react-hotkeys-hook';
import DHSToolbarRole from '../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import { ACTIONS } from '../../../common/enums/DHSToolbarRoleAction';
import { AppBar, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, Divider, IconButton, InputLabel, MenuItem, Select, Slide, TextField, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DHSDialogAction from '../../../components/DHS/DHS_DialogAction/DialogAction';
import { TransitionProps } from '@mui/material/transitions';
import CTXDate from '../../../components/CTX/DatePicker';
// import moment from 'moment';
// import CategoryCustomerAction from '../actions/ob-cash-bank-action/Category-customer-action';
import DHSBreadcrumb from '../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';

import dxDataGrid, {
    // RowInsertedInfo,
    // RowInsertingInfo,
    // RowRemovedInfo,
    // RowRemovingInfo,
    // RowUpdatedInfo,
    // RowUpdatingInfo,
    SelectionChangedInfo,
} from "devextreme/ui/data_grid";
import { EventInfo } from "devextreme/events";
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DataGrid from 'devextreme-react/data-grid';
import { getIndexRowSelected, getKeyRowSelected } from '../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../common/Interfaces/StatusMessage';
import CategoryEmployeeAction from '../actions/ob-cash-bank-action/danh-muc-nhan-vien-action';



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const convert = ["success", "error", "warning"];
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any,
    obcash: any;

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null,
    obcash: {},
}

const roleMenu: string = 'CC';

const CategoryEmployee: React.FC<{}> = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<any>();
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qlccClient = new QLCCClient(appSession, BASE_URL_API);
    const arBookClient = new ARBookClient(appSession, BASE_URL_API);
    const customerClient = new CustomerClient(appSession, BASE_URL_API);
    const obCashBankClient = new OBCashBankClient(appSession, BASE_URL_API);
    const [dataTable, setDataTable] = React.useState<LstCustomer[]>([] as LstCustomer[]);
    const [customers, setCustomers] = React.useState<LstCustomer[]>([] as LstCustomer[]);
    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
    const [isShowSort, setShowSort] = React.useState<boolean>(false);
    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
    const [blocks, setBlocks] = React.useState<QLCCBlockModel[]>([]);
    const [block, setBlock] = React.useState<QLCCBlockModel>({} as QLCCBlockModel);
    const [trangThai, setTrangThai] = React.useState<string>("");
    const location = useLocation();
    const [apartmentBuildings, setApartmentBuildings] = React.useState<QLCCApartmentBuildingModel[]>([] as QLCCApartmentBuildingModel[]);
    const [apartmentBuilding, setApartmentBuilding] = React.useState<QLCCApartmentBuildingModel>({} as QLCCApartmentBuildingModel);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [date, setDate] = React.useState<Date>(new Date());
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)


    const onloadData = () => {
        setLoading(true);
        customerClient.lstCustomerGet({
            ... new LstCustomer(),
            mA_CTY: SessionLogin.ma_cty,
            [trangThai]: true,
            isnv: true,
            apartment_building_id: apartment_building_id,
            moduleId: 'CA'
        } as LstCustomer)
            .then(res => {
                setDataTable(res);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })
    }


    React.useEffect(() => {
        onloadData();
    }, [reload, apartment_building_id]);

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {
        const obj: any = idItemSelected;
        switch (action?.action_code) {
            case ACTIONS.VIEW:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: ACTIONS.VIEW,
                                payload: obj.idcustomer,
                                obcash: obj
                            })
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: ACTIONS.ADD
                })
                break;
            case ACTIONS.EDIT:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: ACTIONS.EDIT,
                                payload: obj.id,
                                obcash: obj
                            })
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.COPY:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: ACTIONS.COPY,
                                payload: obj.id,
                                obcash: obj
                            })
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            if (window.confirm("Xác nhận xóa")) {

                                setLoading(true);
                                const obcashBank = dataTable.find(p => p.idcustomer === obj.idcustomer);

                                customerClient.lstCustomerDel({
                                    ...new LstCustomer(),
                                    ...obcashBank,
                                    mA_CTY: SessionLogin.ma_cty,
                                    apartment_building_id: apartment_building_id,
                                } as LstCustomer | any)
                                    .then(res => {
                                        setLoading(false);
                                        Notification(convert[res.status === 0 ? 0 : 1], res.message)
                                        if (res.status === 0) {
                                            setReload(pre => !pre);
                                        }
                                    }).catch(err => setLoading(false))
                            }
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.CLOSE:
                setIsOpen(!isOpen);
                break;

            case ACTIONS.SEARCH:
                setIsOpen(!isOpen);
                break;
            case ACTIONS.SAVE:
                onloadData();
                break;
        }
    }


    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;
        switch (name) {
            case 'apartmentBuilding':
                const apartmentBuildingSelect: any = apartmentBuildings.find(item => item.id === value);
                if (apartmentBuildingSelect) {
                    setApartmentBuilding(apartmentBuildingSelect);
                }
                break;
            case 'block':
                const blockSelect: QLCCBlockModel | undefined = blocks.find(p => p.id === value);
                if (blockSelect) {
                    setBlock(blockSelect);
                }
                break;
        };
    }

    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        if (name === 'date') {
            setDate(new Date(value));
        }
    };

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
    const [allowEdit, setAllowEdit] = useState<boolean>(true);
    const [selectedItemKeys, setSelectedItemKeys] = useState<any>();

    const handlePageChange = () => {
        setIdItemSelected(undefined)
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    }

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys: any) => {
                const index = e.component.getRowIndexByKey(keys[0]);
                setIdItemSelected(keys[0])
                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
            });
        }
    };


    return (
        <div className='container-fluid'>


            <CategoryEmployeeAction
                open={actCx.open}
                onEditSuccess={() => setReload(!reload)}
                onClose={() => setActCx({ ...actCx, open: false })}
                action={actCx.type}
                itemSelected={actCx.payload}
                customer={actCx.obcash}
                apartmentBuildings={apartmentBuildings}
                payload={{
                    apartment_building_id: apartment_building_id
                }}
            />

            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location}
                />
            </div>

            {/* Control */}
            <DHSToolbarRole
                id={" "}
                menu={menu}
                onClickAction={handleClickAction}
            />

            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                key="id"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={allowEdit}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handlePageChange={handlePageChange}
                handleSelectionChanged={handleSelectionChanged}
            />

            <BlockUI blocking={loading} title={""}></BlockUI>


            {/* Lọc dữ liệu */}
            <Dialog
                id='sysMenu4'
                open={isOpen}
                TransitionComponent={Transition}
                onClose={() => setIsOpen(false)}
                aria-describedby="alert-dialog-slide-description"
                className='d-flex justify-content-end'
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            Tìm kiếm
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setIsOpen(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent>
                    {/* Mẫu báo cáo
                    <Box className='w-100 mt-2'>
                        <InputLabel className='cc-label'>Dự án</InputLabel>
                        <Autocomplete
                            id="gender-select"
                            sx={{ width: "100%" }}
                            options={apartmentBuildings}
                            autoHighlight
                            getOptionLabel={(option: any) => option?.name}
                            value={
                                apartmentBuilding?.id
                                    ? apartmentBuildings.find(
                                        (item: any) => item.id === apartmentBuilding.id
                                    )
                                    : null
                            }
                            onChange={(e, newValue: any) => {
                                handleChangeInput({
                                    target: {
                                        name: "apartmentBuilding",
                                        value: newValue?.id ? newValue.id : null,
                                    },
                                });
                            }}

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className="cc-input"
                                    // label="Dự án"
                                    placeholder='Chọn dự án'
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </Box>

                    <Box className='w-100 mt-2'>
                        <InputLabel className='cc-label'>Tòa nhà</InputLabel>
                        <Autocomplete
                            id="gender-select"
                            sx={{ width: "100%" }}
                            options={apartmentBuildings}
                            autoHighlight
                            getOptionLabel={(option: any) => option?.name}
                            value={
                                block?.id
                                    ? blocks.find(
                                        (item: any) => item.id === block.id
                                    )
                                    : null
                            }
                            onChange={(e, newValue: any) => {
                                handleChangeInput({
                                    target: {
                                        name: "block",
                                        value: newValue?.id ? newValue.id : null,
                                    },
                                });
                            }}

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className="cc-input"
                                    // label="Dự án"
                                    placeholder='Chọn tòa nhà'
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </Box> */}

                    <Box className="w-100 mt-2">
                        <InputLabel className='cc-label'>Đối tượng</InputLabel>
                        <Select
                            className="w-100"
                            value={trangThai || "_"}
                            onChange={(e) => {
                                setTrangThai(e.target.value);
                            }}
                        >
                            <MenuItem value="_"> Tất cả</MenuItem>
                            <MenuItem value="isncc"> Nhà cung cấp</MenuItem>
                            <MenuItem value="iskh"> Khách hàng</MenuItem>
                        </Select>
                    </Box>
                    {/* Giữa 2 ngày */}
                    <Box className='w-100 mt-4'>
                        <InputLabel className='cc-label'>Năm</InputLabel>
                        <CTXDate
                            name='date'
                            value={date}
                            onChange={handleChangeDate}
                            className='cc-input w-100'
                            views={["year"]}
                        />
                    </Box>

                </DialogContent>
                <DialogActions>
                    <DHSDialogAction

                        menu={menu}
                        onClickAction={handleClickAction}

                    />
                </DialogActions>
            </Dialog>

        </div >
    )
}

export default CategoryEmployee;


