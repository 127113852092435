import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer, Notification } from '../../../../components/layouts';
import { AppSession } from '../../../shared/app-session/app-session';
import { HomeClient, QLCCClient, QLCCTaskModel, SysActionType, UserInfoModel, } from '../../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../../shared/service-proxies/config';
import { useLocation } from 'react-router-dom';
import BlockUI from '../../../../components/Block-UI/Block-ui';
import DHSBreadcrumb from '../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import Scheduler, { Resource, View, Scrolling } from 'devextreme-react/scheduler';
import moment from 'moment';
import './style.css';
import TaskSearcherForReport from '../task/task-search-for-report';
import { dxSchedulerAppointment } from 'devextreme/ui/scheduler';
import DataSource, { DataSourceLike } from 'devextreme/data/data_source';
import { Box } from '@mui/material';
import { Calendar, DateBox } from 'devextreme-react';
import AppointmentComponent from './components/AppointmentComponent';
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any,
    taskData: any;

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null,
    taskData: {},
}

interface IDataSourceTask {
    startDate?: Date | undefined,
    endDate?: Date | undefined,
    text?: string | undefined,
    username?: string | undefined
    status?: string; // 1 done 0 not done,
    color?: string;
}

const colorArr = ['#74d57b', '#1db2f5', '#f5564a', '#97c95c', '#ffc720', '#eb3573']

const TaskTimeLine: React.FC<{}> = () => {
    // const homeClient = new HomeClient(appSession, BASE_URL_API);
    // const qlccClient = new QLCCClient(appSession, BASE_URL_API);

    const dispatch = useDispatch();
    const location = useLocation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);

    const [dataTable, setDataTable] = React.useState<IDataSourceTask[]>([] as IDataSourceTask[]);
    const [data, setData] = React.useState<QLCCTaskModel>({} as QLCCTaskModel);
    // const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [users, setUsers] = useState<UserInfoModel[]>([]);
    // const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const groups = ['username'];

    const currentDate = moment().toDate();
    const startDayHour = 0;
    const endDayHour = 23;

    const [openSearch, setOpenSearch] = React.useState<boolean>(false);

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code) {
            case ACTIONS.SEARCH:
                setOpenSearch(pre => !pre);
                break;
            case ACTIONS.FILTER:
                setIsOpen(pre => !pre);
                break;
        }

        setReload(false);
    }

    return (
        <div className='container-fluid'>
            <TaskSearcherForReport
                open={openSearch}
                onClose={() => setOpenSearch(pre => !pre)}
                action_type={"taskGantt"}
                handleSearch={(dataSourceTask, dataSourceUser) => {
                    setUsers(dataSourceUser);
                    if (dataSourceTask) {
                        const arr: IDataSourceTask[] = []
                        dataSourceTask.map(item => {
                            const newItem = {
                                text: item.title,
                                startDate: moment(item.start_time).toDate(),
                                endDate: moment(item.end_time).toDate(),
                                username: item.receiver,
                                status: item.status_code!.toString(),
                                color: colorArr[item.status_code!]
                            } as IDataSourceTask;
                            arr.push(newItem);
                        })
                        setDataTable([...arr]);

                        console.log({ arr, dataSourceTask, dataSourceUser })
                    }
                }}
                setData={(dataN: QLCCTaskModel) => setData({ ...data, ...dataN } as QLCCTaskModel)}
                type='FILTER'
            />

            <div className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb location={location} />
            </div>

            <DHSToolbarRole id={" "} menu={menu} customMenu={[]} onClickAction={handleClickAction} />

            <Scheduler
                className='scheduler-work-report'
                dataSource={dataTable}
                height={600}
                defaultCurrentView='week'
                defaultCurrentDate={currentDate}
                // defaultCurrentDate={new Date(2021, 3, 21)}
                startDayHour={startDayHour}
                endDayHour={endDayHour}
                cellDuration={200}
                showAllDayPanel={true}
                groups={groups}
                firstDayOfWeek={1}
                // views={['month', 'week']}
                // key={"id"}
                // appointmentComponent={AppointmentComponent}
            >
                <View
                    type='timelineWorkWeek'
                    name='Timeline'
                    groupOrientation='vertical'
                />
                <View
                    type='week'
                    groupOrientation='vertical'
                />
                <View
                    type='month'
                    groupOrientation='horizontal'
                />
                <View
                    type='day'
                    groupOrientation='horizontal'
                />
                <Resource
                    fieldExpr="username"
                    allowMultiple={false}
                    dataSource={users}
                    label="Users"
                    valueExpr={'username'}
                    displayExpr={'fullname'}
                    useColorAsDefault={true}
                />
                {/* <Scrolling mode='virtual' /> */}
            </Scheduler>
            <BlockUI blocking={loading} title={"Vui lòng chờ"}></BlockUI>
        </div>
    )
}

export default TaskTimeLine;
