import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from '../../../../components/layouts';
import { AppSession } from '../../../shared/app-session/app-session';
import { HomeClient, QLCCVehicleModel, QLCCClient, RoleRightWebModel, SysModel, QLCCApartmentBuildingModel, QLCCApartmentModel, QLCCBlockModel, QLCCFloorModel, SysGenRowTableModel } from '../../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../../shared/service-proxies/config';
import BlockUI from '../../../../components/Block-UI/Block-ui';
import { AppBar, Autocomplete, Box, Button, Dialog, DialogContent, Divider, IconButton, Slide, TextField, Toolbar, Typography } from '@mui/material';
import notification from '../../../../common/notification/notification';
import DHSBreadcrumb from '../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import DHSGridView from '../../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from '@mui/material/transitions';
import { ACTIONS } from '../../../../common/enums/DHSToolbarRoleAction';
import DHSAutoCompleteWithDataSource from '../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource';
const convert = ["success", "error", "warning"];
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any,
}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

interface IFilter {
    apartment_building_id?: string | null,
    block_id?: string | null,
    floor_id?: string | null,
    apartment_id?: string | null
}

const defaultFilter: IFilter = {
    apartment_building_id: null,
    block_id: null,
    floor_id: null,
    apartment_id: null
}

interface IProps {
    open: boolean;
    setVehicleInfo: (data: QLCCVehicleModel) => void;
    onClose: () => void;
    setVehicle: (data: QLCCVehicleModel[]) => void;
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const VehicleInfo: React.FC<IProps> = (props: IProps) => {

    const dispatch = useDispatch();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    // const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<QLCCVehicleModel[]>([]);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const [filter, setFilter] = useState<IFilter>(defaultFilter);

    // 

    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
    const [isShowSort, setShowSort] = React.useState<boolean>(false);
    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);

    const customMenu = [
        {
            menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
            onClick: () => {
                setShowSearch(!isShowSearch);
            },
        },
        {
            menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
            onClick: () => {
                setShowGroup(!isShowGroup);
            },
        },
        {
            menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
            onClick: () => {
                setShowSort(!isShowSort);
            },
        },
        {
            menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
            onClick: () => {
                setShowFilter(!isShowFilter);
            },
        },
    ];


    //

    const [apartmentBuildingOptions, setApartmentBuildingOptions] = React.useState<any[]>([]);
    const [blockOptions, setBlockOptions] = React.useState<any[]>([]);
    const [floorOptions, setFloorOptions] = React.useState<any[]>([]);
    const [apartmentOptions, setApartmentOptions] = React.useState<any[]>([]);

    useEffect(() => {
        setLoading(true);
        qLCClient.qlccApartmentBuildingGet({
            ...new QLCCApartmentBuildingModel(),
            ma_cty: SessionLogin.ma_cty,
            action_type: 'GET'
        } as QLCCApartmentBuildingModel)
            .then((res: any) => {
                setApartmentBuildingOptions(res);
                if (res) {
                    setFilter(pre => ({ ...pre, apartment_building_id: res[0].id }))
                }
                setLoading(false);
            }).catch(err => {
                setLoading(false);
            });

    }, []);

    // Block filter by apartment building

    React.useMemo(() => {

        if (filter.apartment_building_id) {
            setLoading(true);
            qLCClient.qlccBlockGet({
                ...new QLCCBlockModel(),
                apartment_building_id: filter.apartment_building_id,
            } as QLCCBlockModel)
                .then((res: any) => {

                    setBlockOptions(res)
                    // if (res) {
                    //     setFilter(pre => ({ ...pre, block_id: res[0].id }))
                    // }

                    setLoading(false);
                }).catch(err => {
                    setLoading(false);
                });;
            if (!filter.apartment_building_id) {
                setFilter(defaultFilter)
            }
        }

    }, [filter.apartment_building_id])

    // Floor filter by block

    React.useMemo(() => {

        if (filter.block_id) {
            setLoading(true);
            qLCClient.qLCCFloorGet({
                ...new QLCCFloorModel(),
                block_id: filter.block_id,
                action_type: 'GET'
            } as QLCCFloorModel)
                .then((res: any) => {

                    setFloorOptions(res)
                    // if (res) {
                    //     setFilter(pre => ({ ...pre, floor_id: res[0]?.id }))
                    // }

                    setLoading(false);
                }).catch(err => {
                    setLoading(false);
                });;

            if (!filter.block_id) {
                setFilter(pre => ({ ...pre, floor_id: null, apartment_id: null }))
            }
        }
    }, [filter.block_id])

    // Apartment filter by floor

    React.useMemo(() => {

        if (filter.floor_id) {
            setLoading(true);
            qLCClient.qLCCApartmentsGet({
                ...new QLCCApartmentModel(),
                floor_id: filter.floor_id,
                action_type: 'GET'
            } as QLCCApartmentModel)
                .then((res: any) => {

                    setApartmentOptions(res)
                    // if (res) {
                    //     setFilter(pre => ({ ...pre, floor_id: res[0]?.id }))
                    // }

                    setLoading(false);
                }).catch(err => {
                    setLoading(false);
                });;

            if (!filter.floor_id) {
                setFilter(pre => ({ ...pre, apartment_id: null }))
            }
        }
    }, [filter.floor_id])


    useEffect(() => {
        setLoading(true)
        if (filter?.apartment_building_id) {
            qLCClient.qLCCVehicleGet({
                ...new QLCCVehicleModel(),
                apartment_building_id: filter?.apartment_building_id,
                block_id: filter?.block_id,
                floor_id: filter?.floor_id,
                apartment_id: filter?.apartment_id,
            } as QLCCVehicleModel).then((res: any) => {

                setLoading(false)

                setDataTable(res)
                props.setVehicle(res);

            }).catch(err => {
                setLoading(false);
            });
        }
    }, [reload, actCx.open, filter])

    const handleClickAction = (action: RoleRightWebModel, nowTime: Date) => {
        const vehicleInfo = dataTable.find(row => row.id === idItemSelected);
        if (vehicleInfo) {
            props.setVehicleInfo(vehicleInfo);
            props.onClose()
        }
        //  else {
        //     notification("warning", "Thông tin xe không tồn tại!")
        // }
    }

    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;

        setFilter(pre => ({ ...filter, [name]: value } as IFilter));
    };

    return (
        <Dialog
            id="sysMenu"
            open={props.open}
            // onClick={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            TransitionComponent={Transition}
        // fullScreen
        >
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        Danh Sách xe
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <div className='row no-gutters my-1'>
                    {/* Dự án */}
                    <div className="col-md-12 col-xl-2 pr-1">
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            {/* <InputLabel sx={{ fontSize: 14, color: '#333' }}>Chung cư</InputLabel> */}
                            <DHSAutoCompleteWithDataSource
                                id="apartment-building-select"
                                className="cc-input"
                                dataSource={apartmentBuildingOptions}
                                displayMember="name"
                                valueMember="id"
                                value={filter?.apartment_building_id}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "apartment_building_id",
                                            value: newValue?.id ?? null,
                                        },
                                    });
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option?.id}>
                                            {option?.name}
                                        </li>
                                    );
                                }}
                            />
                            {/* <Autocomplete
                                id="apartment-building-select"
                                sx={{ width: "100%" }}
                                options={apartmentBuildingOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => `${option?.name}`}
                                value={
                                    filter?.apartment_building_id
                                        ? apartmentBuildingOptions.find(
                                            (item: any) => item.id === filter.apartment_building_id
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "apartment_building_id",
                                            value: newValue?.id ?? null,
                                        },
                                    });

                                    // 


                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option?.id}>
                                            {option?.name}
                                        </li>
                                    );
                                }}

                                renderInput={(params) => (
                                    <TextField


                                        {...params}

                                        placeholder='Chọn chung cư'
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}

                            /> */}

                        </Box>
                    </div>
                    {/* Tòa nhà */}
                    <div className="col-md-12 col-xl-2 pr-1">
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            {/* <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tòa nhà</InputLabel> */}
                            <DHSAutoCompleteWithDataSource
                                id="block-select"
                                className="cc-input"
                                dataSource={blockOptions}
                                displayMember="name"
                                valueMember="id"
                                value={filter?.block_id}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "block_id",
                                            value: newValue?.id ?? null,
                                        },
                                    });
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option?.id}>
                                            {option?.name}
                                        </li>
                                    );
                                }}
                            />
                            {/* <Autocomplete
                                    id="block-select"
                                    sx={{ width: "100%" }}
                                    options={blockOptions}
                                    autoHighlight
                                    getOptionLabel={(option: any) => `${option?.name}`}
                                    value={
                                        filter?.block_id
                                            ? blockOptions.find(
                                                (item: any) => item.id === filter.block_id
                                            )
                                            : null
                                    }
                                    onChange={(e, newValue: any) => {
                                        handleChangeInput({
                                            target: {
                                                name: "block_id",
                                                value: newValue?.id ?? null,
                                            },
                                        });
                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option?.id}>
                                                {option?.name}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField


                                            {...params}
                                            placeholder='Chọn tòa nhà (block)'
                                            className="cc-input"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                        />
                                    )}

                                /> */}
                        </Box>
                    </div>
                    {/* Tầng */}
                    <div className="col-md-12 col-xl-2 pr-1">
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            {/* <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tầng</InputLabel> */}
                            <DHSAutoCompleteWithDataSource
                                id="floor-select"
                                className="cc-input"
                                dataSource={floorOptions}
                                displayMember="name"
                                valueMember="id"
                                value={filter?.floor_id}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "floor_id",
                                            value: newValue?.id ?? null,
                                        },
                                    });
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option?.id}>
                                            {option?.name}
                                        </li>
                                    );
                                }}
                            />
                            {/* <Autocomplete
                                    id="floor-select"
                                    sx={{ width: "100%" }}
                                    options={floorOptions}
                                    autoHighlight
                                    getOptionLabel={(option: any) => `${option?.name}`}
                                    value={
                                        filter?.floor_id
                                            ? floorOptions.find(
                                                (item: any) => item.id === filter.floor_id
                                            )
                                            : null
                                    }
                                    onChange={(e, newValue: any) => {
                                        handleChangeInput({
                                            target: {
                                                name: "floor_id",
                                                value: newValue?.id ?? null,
                                            },
                                        });
                                    }}

                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option?.id}>
                                                {option?.name}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField


                                            {...params}
                                            placeholder='Chọn tầng'
                                            className="cc-input"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                        />
                                    )}

                                /> */}
                        </Box>
                    </div>
                    {/* Căn hộ */}
                    <div className="col-md-12 col-xl-2 pr-1">
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            {/* <InputLabel sx={{ fontSize: 14, color: '#333' }}>Căn hộ</InputLabel> */}
                            <DHSAutoCompleteWithDataSource
                                id="apartment-select"
                                className="cc-input"
                                dataSource={apartmentOptions}
                                displayMember="name"
                                valueMember="id"
                                value={filter?.apartment_id}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "apartment_id",
                                            value: newValue?.id ?? null,
                                        },
                                    });
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option?.id}>
                                            {option?.name}
                                        </li>
                                    );
                                }}
                            />
                            {/* <Autocomplete
                                id="apartment-select"
                                sx={{ width: "100%" }}
                                options={apartmentOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => `${option?.name}`}
                                value={
                                    filter?.apartment_id
                                        ? apartmentOptions.find(
                                            (item: any) => item.id === filter.apartment_id
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "apartment_id",
                                            value: newValue?.id ?? null,
                                        },
                                    });
                                }}

                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option?.id}>
                                            {option?.name}
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField


                                        {...params}
                                        placeholder='Chọn căn hộ'
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}

                            /> */}
                        </Box>
                    </div>
                </div>

                <DHSGridView
                    onSelectRow={(row) => {
                        const vehicleInfo = dataTable.find(p => p.id === row);
                        if (vehicleInfo) {
                            props.setVehicleInfo(vehicleInfo);
                            props.onClose();
                        }
                        // else {
                        //     notification("warning", "Thông tin xe không tồn tại!")
                        // }

                    }}
                    // onListSelectRow={handleListOnselectRow}
                    itemClicked={function (): void {
                        throw new Error("Function not implemented.");
                    }}
                    // columns={columns}
                    table_name={"QLCCVehicle"}
                    show_form_list={true}
                    showSelectBox={false}
                    data={dataTable}
                    showSortPanel={true}
                    showSearchPanel={true}
                    showGroupPanel={isShowGroup}
                    showFilterPanel={isShowFilter}
                    defaultPageSize={20}
                    grid_name={"Danh sách xe"}
                />
                <BlockUI blocking={loading} title={""}></BlockUI>
            </DialogContent>
        </Dialog>
    )
}

export default VehicleInfo;


