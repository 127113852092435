import { GridExporter } from '@devexpress/dx-react-grid-export';
import DataGrid, { Column, FilterRow, HeaderFilter, Paging, Scrolling, Selection, SearchPanel, GroupPanel, ColumnChooser } from 'devextreme-react/data-grid'
import saveAs from 'file-saver';
import { widget } from 'jquery';
import moment from 'moment';
import React from 'react'
import { SysGenRowTableModel } from '../../../../shared/service-proxies/api-shared';
import { useHotkeys } from 'react-hotkeys-hook';

interface IProps {
    dataSource: any;
    setIdItemSelected: (data: any) => void;
    header: any;
    id: string | undefined;
    isShowSearch?: boolean | undefined;
    isShowGroup?: boolean | undefined;
    isShowSort?: boolean | undefined;
    isShowFilter?: boolean | undefined;
    sizePaging?: number | undefined;
    selection?: boolean | undefined;
    table_name?: string | undefined;
    isExport?: boolean | undefined;
    setIsExport?: any;
}

const CCGridView = (props: IProps) => {
    const {
        dataSource,
        setIdItemSelected,
        header,
        id,
        isShowSearch,
        isShowGroup,
        isShowSort,
        isShowFilter,
        sizePaging,
        selection,
        isExport,
    } = props;

    const exporterRef = React.useRef<any>(null);
    const [columns, setColumns] = React.useState<any>();


    const [isHiddenGroup, setIsHiddenGroup] = React.useState<boolean>(false);
    const [isHiddenSearch, setIsHiddenSearch] = React.useState<boolean>(false);
    const [isHiddenFilter, setIsHiddenFilter] = React.useState<boolean>(false);
    const [isHiddenExportData, setIsHiddenExportData] =
        React.useState<boolean>(false);
    const [isHiddenAdvantageFilter, setIsHiddenAdvantageFilter] =
        React.useState<boolean>(false);
    const [isHiddenColumnChooser, setIsHiddenColumnChooser] =
        React.useState<boolean>(false);


    const startExport = React.useCallback(() => {
        exporterRef.current.exportGrid();
        if (props.setIsExport) {
            props.setIsExport();
        }
    }, [exporterRef]);


    React.useEffect(() => {
        if (isExport) {
            startExport();
            // setIsExport();
        }
    }, [isExport]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), props.table_name + '.xlsx');
        });
    };

    const priceColumn_customizeText = (cellInfo: any) => {
        const { data } = cellInfo;
        if (data.table_name) {
            return <div className="text-right">{new Intl.NumberFormat('vi-VN').format(Number(data.table_name))}</div>;
        }
        return <div className="text-right">0</div>;
    };

    /* header */
    const onloadHeader = (arrH: string[]) => {

        if (arrH.length === 0) {
            return [];
        }

        const header: any = [];
        const arrayHeader: any = [];

        arrH.map(item => {
            const tempArr = item.split(":");
            const tempLength = tempArr.length;
            let headerTemp: any = {};
            if (tempLength === 3) {
                headerTemp = {
                    table_name: tempArr[0],
                    name: tempArr[1].replace("H=", ""),
                    format: null,
                    width: tempArr[2].replace("W=", "")
                }
            } else if (tempLength === 4) {
                headerTemp = {
                    table_name: tempArr[0],
                    name: tempArr[1].replace("H=", ""),
                    format: tempArr[2].replace("F=", ""),
                    width: tempArr[3].replace("W=", "")
                }
            }
            header.push(headerTemp);
        })

        if (header.length > 0) {
            const columnTemps: SysGenRowTableModel[] = [] as SysGenRowTableModel[];

            header.map((item: any, index: number) => {

                let columnTemp: SysGenRowTableModel = new SysGenRowTableModel();

                if (
                    item.format === 'dd/MM/yyyy'
                    || item.format === 'dd/MM/yyyy HH.mm'
                    || item.format === 'dd/MM/yyyy HH:mm'
                    || item.format === 'dd/MM/yyyy HH:mm:ss'
                    || item.format === 'dd/MM/yyyy HH.mm.ss'
                ) {
                    arrayHeader.push(
                        <Column
                            dataField={item.table_name}
                            format={item.format}
                            alignment="left"
                            caption={item.name}
                            minWidth={item.width > 150 ? item.minWidth : 200}
                            cellRender={(cell: any) => moment(cell.data[cell.columnIndex]).format(item.format.replace("dd", "DD"))}
                        />)

                    columnTemp = {
                        ...new SysGenRowTableModel(),
                        align: "left",
                        title: item.name,
                        width: item.width,
                        format_type: 'date',
                        column_name: item.table_name,
                        number_order: index
                    } as SysGenRowTableModel;
                }
                else if (
                    item.format === 'OA'
                ) {
                    arrayHeader.push(
                        <Column
                            dataField={item.table_name}
                            dataType="number"
                            format="#,###"
                            alignment="right"
                            caption={item.name}
                            minWidth={item.width > 150 ? item.minWidth : 150}
                        // cellRender={priceColumn_customizeText}
                        />)
                    columnTemp = {
                        ...new SysGenRowTableModel(),
                        align: "left",
                        title: item.name,
                        width: item.width,
                        format_type: 'money',
                        column_name: item.table_name,
                        number_order: index
                    } as SysGenRowTableModel;
                }
                else {
                    arrayHeader.push(
                        <Column
                            dataField={item.table_name}
                            format={item.format}
                            alignment="left"
                            caption={item.name}
                            minWidth={item.width > 150 ? item.minWidth : 150}
                        />)
                    columnTemp = {
                        ...new SysGenRowTableModel(),
                        align: "left",
                        title: item.name,
                        width: item.width,
                        column_name: item.table_name,
                        number_order: index
                    } as SysGenRowTableModel;
                }
                columnTemps.push(columnTemp);
            })
            // setColumns([...columnTemps]);
        }
        return arrayHeader;
    }

    /* CHECK MANY ITEM OR CLICK A ITEM IN GRID VIEW */
    const onSelectRow = (Row: any) => {
        const { selectedRowKeys } = Row;
        setIdItemSelected(selectedRowKeys.join(';'));
    };


    React.useEffect(() => {
        if (props.header) {
            const arrH: string[] = props.header;
            if (arrH.length !== 0) {
                const header: any = [];
                const arrayHeader: any = [];

                arrH.map(item => {
                    const tempArr = item.split(":");
                    const tempLength = tempArr.length;
                    let headerTemp: any = {};
                    if (tempLength === 3) {
                        headerTemp = {
                            table_name: tempArr[0],
                            name: tempArr[1].replace("H=", ""),
                            format: null,
                            width: tempArr[2].replace("W=", "")
                        }
                    } else if (tempLength === 4) {
                        headerTemp = {
                            table_name: tempArr[0],
                            name: tempArr[1].replace("H=", ""),
                            format: tempArr[2].replace("F=", ""),
                            width: tempArr[3].replace("W=", "")
                        }
                    }
                    header.push(headerTemp);
                })

                if (header.length > 0) {
                    const columnTemps: SysGenRowTableModel[] = [] as SysGenRowTableModel[];

                    header.map((item: any, index: number) => {

                        let columnTemp: SysGenRowTableModel = {
                            ...new SysGenRowTableModel(),
                            title: item.name,
                            name_en: item.name,
                            name: item.table_name,
                            name_vn: item.name,
                            length: 0,
                            width: item.width,
                            column_name: item.table_name,
                            table_name: 'table',
                            number_order: index,
                            id: item.table_name,
                            align: "left",
                        } as SysGenRowTableModel;

                        if (
                            item.format === 'dd/MM/yyyy'
                            || item.format === 'dd/MM/yyyy HH.mm'
                            || item.format === 'dd/MM/yyyy HH:mm'
                            || item.format === 'dd/MM/yyyy HH:mm:ss'
                            || item.format === 'dd/MM/yyyy HH.mm.ss'
                        ) {
                            columnTemp = {
                                ...columnTemp,
                                align: "left",
                            } as SysGenRowTableModel;
                        }
                        else if (
                            item.format === 'OA'
                        ) {
                            columnTemp = {
                                ...columnTemp,
                                align: "right",
                            } as SysGenRowTableModel;
                        }

                        columnTemps.push(columnTemp);
                    })
                    setColumns([...columnTemps]);
                }
            }
        }
    }, [props.header])

    const handleContextMenuItems = (items: any) => {
        if (!items) {
            return;
        }

        items.forEach((item: any) => {
            if (item.items) {
                // Xử lý mục menu lồng nhau
                handleContextMenuItems(item.items);
            }

            switch (item.text) {
                case "Sort Ascending":
                    item.text = "Sắp xếp tăng dần";
                    break;
                case "Sort Descending":
                    item.text = "Sắp xếp giảm dần";
                    break;
                case "Clear Sorting":
                    item.text = "Hủy sắp xếp";
                    break;
                case "Group by This Column":
                    item.text = "Group cột này";
                    break;
                case "Ungroup All":
                    item.text = "Hủy bỏ group tất cả";
                    break;
                case "Fix":
                    item.text = "Cố định";
                    break;
                case "To the left":
                    item.text = "Từ trái sang";
                    break;
                case "To the right":
                    item.text = "Từ phải sang";
                    break;
                case "Unfix":
                    item.text = "Không cố định";
                    break;
            }
        });
    };

    const handleContextMenuPreparing = (e: any) => {
        const { row, column } = e;

        // Xác định loại ContextMenu (dành cho hàng hoặc cột)
        if (row) {
            // ContextMenu cho hàng
            const rowData = row.data;
            // Tùy chỉnh ContextMenu cho hàng dựa trên rowData
            e.items?.push(
                {
                    text: isHiddenGroup
                        ? "Ẩn group ( alt + 1 )"
                        : "Hiện group ( alt + 1 )",
                    onItemClick: () => setIsHiddenGroup((pre) => !pre),
                    beginGroup: true,
                },
                {
                    text: isHiddenSearch
                        ? "Ẩn tìm kiếm ( alt + 2 )"
                        : "Hiện tìm kiếm ( alt + 2 )",
                    onItemClick: () => setIsHiddenSearch((pre) => !pre),
                },
                {
                    text: isHiddenFilter ? "Ẩn lọc ( alt + 3 )" : "Hiện lọc ( alt + 3 )",
                    onItemClick: () => setIsHiddenFilter((pre) => !pre),
                },
                {
                    text: isHiddenAdvantageFilter
                        ? "Ẩn lọc nâng cao ( alt + 4 )"
                        : "Hiện lọc nâng cao ( alt + 4 )",
                    onItemClick: () => setIsHiddenAdvantageFilter((pre) => !pre),
                },
                // {
                //   text: isHiddenFilterSelect
                //     ? "Ẩn lọc chọn giá trị"
                //     : "Hiện lọc chọn giá trị",
                //   onItemClick: () => setIsHiddenFilterSelect((pre) => !pre),
                // },

                {
                    text: isHiddenColumnChooser
                        ? "Ẩn ẩn cột ( alt + 5 )"
                        : "Hiện ẩn cột ( alt + 5 )",
                    onItemClick: () => setIsHiddenColumnChooser((pre) => !pre),
                },
                {
                    text: isHiddenExportData ? "Ẩn xuất dữ liệu" : "Hiện xuất dữ liệu",
                    onItemClick: () => setIsHiddenExportData((pre) => !pre),
                }
                // Các mục menu khác cho hàng
            );
        } else if (column) {
            // ContextMenu cho cột
            const columnData = column.dataField;
            // Tùy chỉnh ContextMenu cho cột dựa trên columnData
            e.items.push(
                {
                    text: isHiddenGroup ? "Ẩn group" : "Hiện group",
                    onItemClick: () => setIsHiddenGroup((pre) => !pre),
                }
                // Các mục menu khác cho cột
            );
        }

        // Thay đổi văn bản của các mục menu trong e.items
        handleContextMenuItems(e.items);
    };
    //Phim tắt
    useHotkeys<HTMLParagraphElement>("alt+2", () =>
        setIsHiddenSearch((pre) => !pre)
    );
    useHotkeys<HTMLParagraphElement>("alt+1", () =>
        setIsHiddenGroup((pre) => !pre)
    );

    useHotkeys<HTMLParagraphElement>("alt+3", () =>
        setIsHiddenFilter((pre) => !pre)
    );
    useHotkeys<HTMLParagraphElement>("alt+4", () =>
        setIsHiddenAdvantageFilter((pre) => !pre)
    );
    useHotkeys<HTMLParagraphElement>("alt+5", () =>
        setIsHiddenColumnChooser((pre) => !pre)
    );


    return (
        <>
            <DataGrid
                dataSource={dataSource}
                showBorders={true}
                keyExpr={id || "ID"}
                hoverStateEnabled={true}
                // onFocusedRowChanged={(e) => console.log(e)}
                onSelectionChanged={onSelectRow}
                focusedRowEnabled={true}
                rowAlternationEnabled={true}
                showRowLines={true}
                showColumnLines={true}
                allowColumnReordering={true}
                repaintChangesOnly
                allowColumnResizing={true}
                columnResizingMode="widget"
                height={'74vh'}
                onContextMenuPreparing={handleContextMenuPreparing}
            >
                {
                    selection !== false
                    && (
                        <Selection
                            mode="multiple"
                            selectAllMode={'allPages'}
                            showCheckBoxesMode={'onClick'}
                        />
                    )
                }

                <GroupPanel
                    visible={isHiddenGroup}
                    emptyPanelText={"Kéo thả cột muốn group vào đây"}
                />

                {/* <ColumnFixing enabled={true} /> */}

                <ColumnChooser
                    enabled={isHiddenColumnChooser}
                    emptyPanelText={"Kéo và thả cột muốn ẩn vào đây"}
                    title={"Chọn cột ẩn"}
                />

                <SearchPanel visible={isHiddenSearch}
                    placeholder="Tìm kiếm..." />
                <FilterRow visible={isHiddenFilter} />
                <HeaderFilter visible={true} />

                <Paging defaultPageSize={sizePaging || 15} />

                <Scrolling mode="virtual" />

                {
                    onloadHeader(header)
                }
            </DataGrid>
            <GridExporter
                ref={exporterRef}
                rows={props.dataSource ? props.dataSource : []}
                columns={columns}
                onSave={onSave}
            />
        </>
    )
}

export default CCGridView;