import moment from "moment";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import notification from "../../../common/notification/notification";
import BlockUI from "../../../components/Block-UI/Block-ui";
import { AppSession } from "../../shared/app-session/app-session";
import {
    QLCCClient,
    QLCCDomesticWaterNormModel,
    QLCCTaskGroupModel,
    SysActionType,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import DomesticWaterNormAction from "../actions/domestic-water-action/DomesticWaterNormAction";
import dxDataGrid, {
    RowInsertingInfo,
    RowRemovingInfo,
    RowUpdatingInfo,
    SelectionChangedInfo,
} from "devextreme/ui/data_grid";
import { EventInfo } from "devextreme/events";
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";

import DataGrid from "devextreme-react/data-grid";
import { ACTIONS } from "../../../common/enums/DHSToolbarRoleAction";
import DHSToolbarRole from "../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import DHSBreadcrumb from "../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import { onAddRow, onDeleteRow, onEditRow, onViewRow } from "../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../common/Interfaces/StatusMessage";

interface IAction {
    open: boolean;
    type: string | undefined;
    payload: string | undefined;
}
let appSession: AppSession;
const TaskGroup = (Props: any) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const [dataTable, setDataTable] = React.useState<QLCCTaskGroupModel[]>([] as QLCCTaskGroupModel[]);
    const [action, setAction] = React.useState<IAction>({
        open: false,
        type: "",
        payload: ""
    });
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [reload, setReload] = React.useState(false);

    const dataGridRef = useRef<DataGrid>(null);
    const [allowEdit, setAllowEdit] = useState<boolean>(true);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    React.useEffect(() => {
        setLoading(true);
        qLCCClient
            .qLCCTaskGroupGet({
                ...new QLCCTaskGroupModel(),
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty
            } as QLCCTaskGroupModel)
            .then((res) => {
                setDataTable(res);
                setLoading(false);
            });
    }, [reload, apartment_building_id]);

    const handleSubmit = (data: any) => {
        setLoading(true)
        setAction({
            ...action,
            open: false,
            type: "",
            payload: ""
        });
    };

    const handleRowInserting = async (
        event: EventInfo<dxDataGrid<any, any>> & RowInsertingInfo<any>
    ) => {
        try {
            event.cancel = true;
            setLoading(true);
            const response = await qLCCClient
                ?.qLCCTaskGroupActions({
                    ...event.data,
                    action_type: "INSERT",
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty
                } as QLCCTaskGroupModel);
            if (response.status === 0) {
                setDataTable([{ id: response.ref_id, ...event.data }, ...dataTable]);
                dataGridRef.current?.instance.cancelEditData();
                notification(
                    TYPE_MESSAGE.SUCCESS,
                    STATUS_MESSAGE[`${ACTIONS.ADD}_${response!.status}`]
                );
            } else {
                event.cancel = true;
                notification(
                    TYPE_MESSAGE.ERRORR,
                    STATUS_MESSAGE[`${ACTIONS.ADD}_${response!.status}`]
                );
            }
        } catch (error) {
            notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
        } finally {
            setLoading(false);
        }
    };

    const handleRowUpdating = async (
        event: EventInfo<dxDataGrid<any, any>> & RowUpdatingInfo<any, any>
    ) => {
        try {
            event.cancel = true;
            setLoading(true);
            const response = await qLCCClient
                ?.qLCCTaskGroupActions({
                    ...event.oldData,
                    ...event.newData,
                    action_type: "UPDATE"
                } as QLCCTaskGroupModel)
            if (response.status === 0) {
                const dataLastUpdated = dataTable.map((item) => {
                    if (item.id === event.key) {
                        return {
                            ...new QLCCDomesticWaterNormModel(),
                            ...event.oldData,
                            ...event.newData,
                        };
                    } else {
                        return item;
                    }
                });

                setDataTable([...dataLastUpdated]);
                dataGridRef.current?.instance.cancelEditData();
                notification(
                    TYPE_MESSAGE.SUCCESS,
                    STATUS_MESSAGE[`${ACTIONS.EDIT}_${response!.status}`]
                );
            } else {
                notification(
                    TYPE_MESSAGE.ERRORR,
                    STATUS_MESSAGE[`${ACTIONS.EDIT}_${response!.status}`]
                );
            }
        } catch (error) {
            notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
        } finally {
            setLoading(false);
        }
    };

    const handleRowRemoving = async (
        event: EventInfo<dxDataGrid<any, any>> & RowRemovingInfo<any, any>
    ) => {
        try {
            setLoading(true);
            const response = await qLCCClient
                .qLCCTaskGroupActions({
                    ...new QLCCTaskGroupModel(),
                    ...event.data,
                    action_type: ACTIONS.DELETE,
                } as QLCCTaskGroupModel)

            if (response.status === 0) {
                setDataTable([...dataTable.filter((item) => item.id !== event.key)]);
                notification(
                    TYPE_MESSAGE.SUCCESS,
                    STATUS_MESSAGE[`${ACTIONS.DELETE}_${response!.status}`]
                );
            } else {
                notification(
                    TYPE_MESSAGE.ERRORR,
                    STATUS_MESSAGE[`${ACTIONS.DELETE}_${response!.status}`]
                );
            }
        } catch (error) {
            notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
        } finally {
            setLoading(false);
        }
    };

    const handleClickAction = async (action: SysActionType) => {
        switch (action?.action_code?.toLocaleUpperCase()) {
            case ACTIONS.ADD:
                await onAddRow(dataGridRef, setAllowEdit);
                break;
            case ACTIONS.DELETE:
                if (window.confirm('Are you sure you want to delete this row?')) {
                    await onDeleteRow(dataGridRef)
                }
                break;
            case ACTIONS.EDIT:
                await onEditRow(dataGridRef, setAllowEdit);
                break;
            case ACTIONS.VIEW:
                await onViewRow(dataGridRef, setAllowEdit);
                break;

            default:
                break;
        }
    };

    const handlePageChange = () => {
    }

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                console.log(keys);
            });
        }
    };

    return (
        <div
            className="container-fluid p-2"
            style={{ maxHeight: "94vh !important", overflowY: "auto" }}
        >
            {React.useMemo(() => {
                return (
                    <DomesticWaterNormAction
                        open={action.open}
                        onClose={() => {
                            setAction({
                                ...action,
                                open: false,
                                type: "",
                                payload: ""
                            });
                        }}
                        onEditSuccess={function (is_success: any): void {
                            setReload(pre => !pre)
                        }}
                        action={action.type}
                        itemSelected={action.payload}
                        onSubmit={handleSubmit}
                    />
                );
            }, [action.open, setReload])}
            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location}
                />
            </div>

            <DHSToolbarRole
                id={' '}
                menu={menu}
                onClickAction={handleClickAction}
            />
            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                keyExpr="id"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={allowEdit}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handlePageChange={handlePageChange}
                handleRowInserting={handleRowInserting}
                handleRowUpdating={handleRowUpdating}
                // handleRowUpdated={handleRowUpdated}
                handleRowRemoving={handleRowRemoving}
                handleSelectionChanged={handleSelectionChanged}
            />
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </div>
    );
};

export default TaskGroup;
