import React from "react";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import {
    Button,
    TextField,
    MenuItem,
    DialogTitle,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    Link,
    MenuList,
    Autocomplete,
    IconButton,
    InputAdornment,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    TextareaAutosize,
} from "@mui/material";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

import "./CanXe.css";
import {
    ChoThueXeClient,
    CommonClient,
    CtxDanhMucXeModel,
    FileManagerClient,
    HomeClient,
    HRClient,
    HRWorkShift,
    KiemSoatCanClient,
    KscPhieuKiemSoatModel,
    KscThongTinTauModel,
    KscVanDonModel,
    LstInventory,
    LstInventoryClient,
    ParaObj,
    RoleRightWebModel,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import notification from "../../../../common/notification/notification";
import CTXToolbar from "./../../../../components/CTX/Toolbar/index";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { useHistory, useParams } from "react-router-dom";
import DHS_Select from "../../../../components/DHS/DHS_Select";
import CTXDateTime from "../../../../components/CTX/DateTimePicker";
import { TransitionProps } from "@mui/material/transitions";
import DHSAutoComplete from "../../../../components/DHS/DHS_AutoComplete/DHSAutoComplete";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { red } from "@mui/material/colors";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import Webcam from "react-webcam";
import TextArea from "antd/lib/input/TextArea";
import CTXDate from "../../../../components/CTX/DatePicker";
import ThongTinTau from "../thong-tin-tau/ThongTinTau";
import numberWithCommas from "../../../../components/utils/numberWithCommas";

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
}
interface IOpenCamera {
    pic: any;
    open: boolean;
}
const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
};
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});
interface IAction {
    type: string;
    open: boolean;
    payload: any;
}
const defaultAction: IAction = {
    type: "",
    payload: null,
    open: false,
};
var appSession: AppSession;
const CanXe = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const webcamRef = React.useRef<any>(null);
    const fileClient = new FileManagerClient(appSession, BASE_URL_API);
    const [_id, setId] = React.useState<string>();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const kscClient = new KiemSoatCanClient(appSession, BASE_URL_API);
    const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);
    const hrClient = new HRClient(appSession, BASE_URL_API);
    const commonClient = new CommonClient(appSession, BASE_URL_API);
    const invClient = new LstInventoryClient(appSession, BASE_URL_API);
    const [openCamera, setOpenCamera] = React.useState<IOpenCamera>({
        pic: "",
        open: false,
    });
    const [data, setData] = React.useState<KscPhieuKiemSoatModel>(
        new KscPhieuKiemSoatModel()
    );
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [loaiHangOptions, setLoaiHangOptions] = React.useState<any>([]);
    const [kieuCanOptions, setKieuCanOptions] = React.useState<any>([]);
    const [khoOptions, setKhoOptions] = React.useState<any>([]);
    const [caOptions, setCaOptions] = React.useState<any>([]);
    const [bienSoOptions, setBienSoOptions] = React.useState<any>([]);
    const [khachHangOptions, setKhachHangOptions] = React.useState<any>([]);
    const [remoocOptions, setRemoocOptions] = React.useState<any>([]);
    const [taiXeOptions, setTaiXeOptions] = React.useState<any>([]);
    const [nhomTauOptions, setNhomTauOptions] = React.useState<any>([]);
    const [thongTinTauOptions, setThongTinTauOptions] = React.useState<any>([]);
    const [actTtt, setActTtt] = React.useState<IAction>(defaultAction);

    const [soVanDonOptions, setSoVanDonOptions] = React.useState<
        KscVanDonModel[]
    >([]);
    const [listHinhLucVao, setListHinhLucVao] = React.useState<string[]>([]);
    const [listHinhLucRa, setListHinhLucRa] = React.useState<string[]>([]);

    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const history = useHistory();

    React.useEffect(() => {
        if (props.action === "UPDATE" || props.action === "DETAIL") {
            kscClient
                .kscPhieuKiemSoatGet({
                    ...new KscPhieuKiemSoatModel(),
                    id: props.itemSelected,
                } as KscPhieuKiemSoatModel)
                .then((res) => {
                    setData(res[0]);
                })
                .catch((err) => alert("Không lấy được dữ liệu"));
            setId(props.itemSelected);
        } else {
            commonClient.get_VoucherNo({
                ...new ParaObj(),
                ma_cty: "DHS",
                ma_ct: "KSC",
                ngay_ct: new Date()
            } as ParaObj).then((sct) => {
                setData({
                    ...new KscPhieuKiemSoatModel(),
                    so_phieu: sct,
                } as KscPhieuKiemSoatModel);
            });
            setId(undefined);
        }
    }, [props.action]);

    React.useEffect(() => {
        setLoading(true);
        homeClient
            ?.roleRightWebGetAll({
                ...new RoleRightWebModel(),
                rolename: "KS",
                username: SessionLogin.userName,
                menuid: menu?.menuid,
            } as RoleRightWebModel)
            .then((res) => {
                setRoles(res);
                setLoading(false);
            });
        setLoading(true);
        kscClient
            .kscPhieuKiemSoatDanhMucGet({
                ...new KscPhieuKiemSoatModel(),
                action_type: "KHACH_HANG",
            } as KscPhieuKiemSoatModel)
            .then((response) => {
                let tempArr: any = [];
                response.forEach((item) => {
                    tempArr.push({
                        id: item.khach_hang_id,
                        name: item.khach_hang_name,
                    });
                });
                setKhachHangOptions(tempArr);
                setLoading(false);
            });
        setLoading(true);
        kscClient
            .kscPhieuKiemSoatDanhMucGet({
                ...new KscPhieuKiemSoatModel(),
                action_type: "REMOOC",
            } as KscPhieuKiemSoatModel)
            .then((response) => {
                let tempArr: any = [];
                response.forEach((item) => {
                    tempArr.push({
                        id: item.ma_remooc,
                        name: item.so_remooc,
                    });
                });
                setRemoocOptions(tempArr);
                setLoading(false);
            });
        setLoading(true);

        kscClient
            .kscPhieuKiemSoatDanhMucGet({
                ...new KscPhieuKiemSoatModel(),
                action_type: "BIEN_SO",
            } as KscPhieuKiemSoatModel)
            .then((response) => {
                let tempArr: any = [];
                response.forEach((item) => {
                    tempArr.push(item.bien_so);
                });
                setBienSoOptions(tempArr);
                setLoading(false);
            });
        setLoading(true);

        kscClient
            .kscPhieuKiemSoatDanhMucGet({
                ...new KscPhieuKiemSoatModel(),
                action_type: "TAI_XE",
            } as KscPhieuKiemSoatModel)
            .then((response) => {
                let tempArr: any = [];
                response.forEach((item) => {
                    tempArr.push({
                        id: item.ma_tai_xe,
                        name: item.ten_tai_xe,
                    });
                });
                setTaiXeOptions(tempArr);
                setLoading(false);
            });
        setLoading(true);

        kscClient
            .kscPhieuKiemSoatDanhMucGet({
                ...new KscPhieuKiemSoatModel(),
                action_type: "TAI_XE",
            } as KscPhieuKiemSoatModel)
            .then((response) => {
                let tempArr: any = [];
                response.forEach((item) => {
                    tempArr.push({
                        id: item.ma_tai_xe,
                        name: item.ten_tai_xe,
                    });
                });
                setTaiXeOptions(tempArr);
                setLoading(false);
            });
        setLoading(true);

        kscClient.kscVanDonGet(new KscVanDonModel()).then((response) => {
            setSoVanDonOptions(response);
            setLoading(false);
        });
        setLoading(true);
        ctxClient
            .ctxDanhMucXeActions({
                ...new CtxDanhMucXeModel(),
                tbname: "kscNhomTau",
                action: "GET",
            } as CtxDanhMucXeModel)
            .then((res) => {
                setNhomTauOptions(res);
                setLoading(false);
            });
        setLoading(true);

        invClient
            .getLstInventory({ ...new LstInventory(), mA_CTY: "DHS" } as LstInventory)
            .then((res) => {
                const tempArr: any = [];
                res.forEach((item) => {
                    tempArr.push({
                        name: item.teN_KHO,
                        id: item.mA_KHO,
                    });
                });
                setKhoOptions([...tempArr]);
                setLoading(false);
            });
        setLoading(true);

        hrClient
            .list_work_shift_get({
                ...new HRWorkShift(),
                ma_cty: "DHS",
            } as HRWorkShift)
            .then((res) => {
                const tempArr: any = [];
                res.forEach((item) => {
                    tempArr.push({
                        name: item.work_shift_name,
                        id: item.work_shift_id,
                    });
                });
                setCaOptions([...tempArr]);
                setLoading(false);
            });
        setLoading(true);
        ctxClient
            .ctxDanhMucXeActions({
                ...new CtxDanhMucXeModel(),
                tbname: "kscKieuCan",
                action: "GET",
            } as CtxDanhMucXeModel)
            .then((res) => {
                setKieuCanOptions(res);
                setLoading(false);
            });
        setLoading(true);
        ctxClient
            .ctxDanhMucXeActions({
                ...new CtxDanhMucXeModel(),
                tbname: "kscLoaiHang",
                action: "GET",
            } as CtxDanhMucXeModel)
            .then((res) => {
                console.log(res, "loại hàng")
                setLoaiHangOptions(res);
                setLoading(false);
            });
    }, []);

    React.useEffect(() => {
        setLoading(true);
        kscClient
            .kscThongTinTauActions({
                ...new KscThongTinTauModel(),
                action_type: "GET",
            } as KscThongTinTauModel)
            .then((res) => {
                setThongTinTauOptions(res);
                setLoading(false);
            });
    }, [actTtt.open]);

    const handleSubmit = () => {
        if (_id || props.action === "UPDATE") {
            setLoading(true);
            kscClient
                .kscPhieuKiemSoatUpd({
                    ...data,
                    id: _id,
                    ngay_can_1:
                        data.ngay_can_1 &&
                        new Date(
                            (new Date(data.ngay_can_1) as any) -
                            new Date().getTimezoneOffset() * 1000 * 60
                        ),
                    ngay_can_2:
                        data.ngay_can_2 &&
                        new Date(
                            (new Date(data.ngay_can_2) as any) -
                            new Date().getTimezoneOffset() * 1000 * 60
                        ),
                    hinh_luc_vao: listHinhLucVao.join(";"),
                    hinh_luc_ra: listHinhLucRa.join(";"),
                } as KscPhieuKiemSoatModel)
                .then((response) => {
                    setLoading(false);
                    alert(response.message);
                });
        } else {
            setLoading(true);
            kscClient
                .kscPhieuKiemSoatIns({
                    ...data,
                    ngay_can_1:
                        data.ngay_can_1 &&
                        new Date(
                            (new Date(data.ngay_can_1) as any) -
                            new Date().getTimezoneOffset() * 1000 * 60
                        ),
                    ngay_can_2:
                        data.ngay_can_2 &&
                        new Date(
                            (new Date(data.ngay_can_2) as any) -
                            new Date().getTimezoneOffset() * 1000 * 60
                        ),
                    hinh_luc_vao: listHinhLucVao.join(";"),
                    hinh_luc_ra: listHinhLucRa.join(";"),
                } as KscPhieuKiemSoatModel)
                .then((response) => {
                    setId(response.id);
                    setLoading(false);
                    alert(response.message);
                });
        }
    };

    const showCamera = (e: any, file: any) => {
        setOpenCamera({
            ...openCamera,
            pic: file,
            open: true,
        });
    };
    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;
        console.log(name, value, "handle change input")
        if (name === "kho_id")
            if (value === "NM")
                setData({
                    ...data,
                    kho_name: "NM",
                    [name]: value,
                } as KscPhieuKiemSoatModel);
            else
                setData({
                    ...data,
                    [name]: value,
                    kho_name: "",
                } as KscPhieuKiemSoatModel);
        else setData({ ...data, [name]: value } as KscPhieuKiemSoatModel);
    };
    const handleChangeMass = (e: any) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value.replaceAll(" ", ""),
        } as KscPhieuKiemSoatModel);
    };
    const taoMoiSoChungTu = () => {
        commonClient.get_VoucherNo({
            ...new ParaObj(),
            ma_cty: "DHS",
            ma_ct: "KSC",
             ngay_ct:  new Date()
        } as ParaObj).then((sct) => {
            setData({ ...data, so_phieu: sct } as KscPhieuKiemSoatModel);
        });
    };
    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        setData({ ...data, [name]: new Date(value) } as KscPhieuKiemSoatModel);
    };
    React.useMemo(() => {
        if (data.hinh_luc_vao) setListHinhLucVao(data.hinh_luc_vao.split(";"));
    }, [data.hinh_luc_vao]);

    React.useMemo(() => {
        if (data.hinh_luc_ra) setListHinhLucRa(data.hinh_luc_ra.split(";"));
    }, [data.hinh_luc_ra]);

    const handleAddNewImage = async (e: any) => {
        setLoading(true);
        const files = e.target.files;
        const name = e.target.name;
        const formData: FormData = new FormData();

        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
            },
        };

        for (const file of files) {
            formData.append("MyFile", file);
        }
        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    let tempArr: string[] = [];
                    res.data.forEach((data: any, pathIndex: number) => {
                        tempArr.push(data.path);
                    });
                    switch (name) {
                        case "hinh_luc_vao":
                            setListHinhLucVao([...listHinhLucVao].concat(tempArr));
                            break;
                        case "hinh_luc_ra":
                            setListHinhLucRa([...listHinhLucRa].concat(tempArr));
                            break;
                    }
                } else notification("warning", "Không lấy được hình ảnh");
                setLoading(false);
            });
    };

    const handleChangeImage = async (e: any, i: number) => {
        const files = e.target.files;
        const name = e.target.name;
        const formData = new FormData();
        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
            },
        };
        for (const file of files) {
            formData.append("MyFile", file);
        }
        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    switch (name) {
                        case "hinh_luc_vao":
                            listHinhLucVao[i] = res.data[0].path;
                            setListHinhLucVao([...listHinhLucVao]);
                            break;
                        case "hinh_luc_ra":
                            listHinhLucRa[i] = res.data[0].path;
                            setListHinhLucRa([...listHinhLucRa]);
                            break;
                        default:
                            break;
                    }
                } else notification("warning", "Không lấy được hình ảnh");
            });
    };
    const handleRemoveImage = (e: any) => {
        const { name, index } = e;
        switch (name) {
            case "hinh_luc_vao":
                listHinhLucVao.splice(index, 1);
                setListHinhLucVao([...listHinhLucVao]);
                break;
            case "hinh_luc_ra":
                listHinhLucRa.splice(index, 1);
                setListHinhLucRa([...listHinhLucRa]);
                break;
            default:
                break;
        }
    };
    const handleChangeVanDon = (e: any) => {
        const { name, value } = e.target;
        let temp = soVanDonOptions.find((item) => item.id === value);
        setData({
            ...data,
            so_bill: value, //value là id trong bảng vận đơn
            loai_hang_id: temp?.loai_hang_id,
            chu_hang: temp?.chu_hang,
            nguoi_nhan: temp?.nguoi_nhan,
            tau_id: temp?.ma_tau,
        } as KscPhieuKiemSoatModel);
    };
    const handleCloseCamera = () => {
        setOpenCamera({ ...openCamera, pic: "", open: false });
    };

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef?.current?.getScreenshot();
        fileClient
            .postFile_v4([imageSrc.replace("data:image/jpeg;base64,", "")])
            .then((res) => {
                if (res) {
                    let tempArr: string[] = [];
                    res.forEach((path: any, pathIndex: number) => {
                        tempArr.push(path);
                    });
                    switch (openCamera.pic) {
                        case "hinh_luc_vao":
                            setListHinhLucVao([...listHinhLucVao].concat(tempArr));
                            break;
                        case "hinh_luc_ra":
                            setListHinhLucRa([...listHinhLucRa].concat(tempArr));
                            break;
                    }
                } else notification("warning", "Không lấy được hình ảnh");
            });
        setOpenCamera({ ...openCamera, pic: "", open: false });
    }, [webcamRef, openCamera.pic]);

    return (
        <Dialog
            id="sysMenu"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "flex-end",
            }}
            TransitionComponent={Transition}
        >
            {React.useMemo(() => {
                return (
                    <ThongTinTau
                        open={actTtt.open}
                        title="Thông tin tàu [Thêm mới]"
                        onClose={() => {
                            setActTtt(defaultAction);
                        }}
                        itemSelected={actTtt.payload}
                        action={actTtt.type}
                    />
                );
            }, [actTtt.open])}
            <Dialog
                open={openCamera.open}
                onClose={handleCloseCamera}
                sx={{ height: "inherit" }}
            >
                <DialogTitle>Camera</DialogTitle>
                <DialogContent>
                    <Webcam
                        audio={false}
                        height={720}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={1280}
                        videoConstraints={videoConstraints}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={capture}>Chụp</Button>
                    <Button onClick={handleCloseCamera}>Đóng</Button>
                </DialogActions>
            </Dialog>
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === "DETAIL"
                                ? "[Chi tiết]"
                                : props.action === "UPDATE" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <div className="row p-2">
                    <div className="col-xl-4">
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Số phiếu</InputLabel>
                            <TextField
                                className="ksc-input"
                                sx={{
                                    width: props.action === "ADD" ? "75%" : "100%",
                                    marginRight: "5px",
                                }}
                                name="so_phieu"
                                onChange={handleChangeInput}
                                value={data.so_phieu}
                                disabled={props.action === "DETAIL"}
                            />
                            {props.action === "ADD" && (
                                <Link sx={{ fontSize: "14px" }} onClick={taoMoiSoChungTu}>
                                    Làm mới
                                </Link>
                            )}
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Khách hàng</InputLabel>
                            <Autocomplete
                                id="combo-box-demo"
                                options={khachHangOptions}
                                // sx={{ width: 300 }}
                                freeSolo
                                getOptionLabel={(option: any) => option.name}
                                value={{
                                    id: data?.khach_hang_id ? data.khach_hang_id : "",
                                    name: data?.khach_hang_name ? data.khach_hang_name : "",
                                }}
                                onChange={(event: any, newValue: any) => {
                                    setData({
                                        ...data,
                                        khach_hang_id: newValue.id,
                                        khach_hang_name: newValue.name,
                                    } as KscPhieuKiemSoatModel);
                                }}
                                inputValue={data?.khach_hang_name ? data.khach_hang_name : ""}
                                onInputChange={(event, newInputValue) => {
                                    setData({
                                        ...data,
                                        khach_hang_name: newInputValue,
                                    } as KscPhieuKiemSoatModel);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="khach_hang_id"
                                        className="ksc-input"
                                        sx={{ width: "100%" }}
                                    />
                                )}
                                disabled={props.action === "DETAIL"}
                            />
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Số vận đơn</InputLabel>
                            <Autocomplete
                                id="country-select-demo"
                                sx={{ width: "100%" }}
                                options={soVanDonOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.so_van_don}
                                value={
                                    data?.so_bill
                                        ? soVanDonOptions.find(
                                            (item: any) => item.id === data.so_bill
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeVanDon({
                                        target: {
                                            name: "so_bill",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="ksc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === "DETAIL"}
                            />
                            {/* <TextField
                className="ksc-input"
                sx={{ width: "100%" }}
                name="so_bill"
                onChange={handleChangeVanDon}
                value={data?.so_bill ? data.so_bill : ""}
                disabled={props.action === "DETAIL"}
                select
              >
                <MenuItem value="">--Chọn--</MenuItem>
                {soVanDonOptions.map((item) => (
                  <MenuItem value={item.id}>{item.so_van_don}</MenuItem>
                ))}
              </TextField> */}
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Loại hàng</InputLabel>
                            <Autocomplete
                                id="country-select-demo"
                                sx={{ width: "100%" }}
                                options={loaiHangOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.loai_hang_id
                                        ? loaiHangOptions.find(
                                            (item: any) => item.id === data.loai_hang_id
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "loai_hang_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="ksc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === "DETAIL"}
                            />
                            {/* <TextField
                className="ksc-input"
                sx={{ width: "100%" }}
                name="loai_hang_id"
                onChange={handleChangeInput}
                value={data.loai_hang_id ? data.loai_hang_id : ""}
                select
                disabled={props.action === "DETAIL"}
              >
                <MenuItem value="">--Chọn--</MenuItem>
                {loaiHangOptions.map((item: any) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </TextField> */}
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Chủ hàng</InputLabel>
                            <TextField
                                className="ksc-input"
                                sx={{ width: "100%" }}
                                name="chu_hang"
                                onChange={handleChangeInput}
                                value={data.chu_hang}
                                disabled={props.action === "DETAIL"}
                            />
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Người nhận</InputLabel>
                            <TextField
                                className="ksc-input"
                                sx={{ width: "100%" }}
                                name="nguoi_nhan"
                                onChange={handleChangeInput}
                                value={data.nguoi_nhan}
                                disabled={props.action === "DETAIL"}
                            />
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <InputLabel sx={{ fontSize: 14 }}>Tên tàu</InputLabel>
                                <IconButton
                                    style={{ height: "16px", width: "16px", color: "#1890ff" }}
                                    onClick={() =>
                                        setActTtt({
                                            ...actTtt,
                                            type: "ADD",
                                            payload: "",
                                            open: true,
                                        })
                                    }
                                    disabled={props.action === "DETAIL"}
                                >
                                    <AddCircleOutlinedIcon
                                        style={{ height: "inherit", width: "inherit" }}
                                    />
                                </IconButton>
                            </Box>
                            <Autocomplete
                                id="country-select-demo"
                                sx={{ width: "100%" }}
                                options={thongTinTauOptions}
                                autoHighlight
                                getOptionLabel={(option: any) =>
                                    `${option?.ten_tau}${option?.hang_tau_name ? ` (${option.hang_tau_name})` : ""}${option?.bien_so_tau ? ` - ${option.bien_so_tau}` : ""}`
                                }
                                value={
                                    data?.tau_id
                                        ? thongTinTauOptions.find(
                                            (item: any) => item.id === data.tau_id
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "tau_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="ksc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === "DETAIL"}
                            />
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Nhóm tàu</InputLabel>
                            <Autocomplete
                                id="country-select-demo"
                                sx={{ width: "100%" }}
                                options={nhomTauOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.nhom_tau_id
                                        ? nhomTauOptions.find(
                                            (item: any) => item.id === data.nhom_tau_id
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "nhom_tau_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="ksc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === "DETAIL"}
                            />
                            {/* <TextField
                className="ksc-input"
                sx={{ width: "100%" }}
                name="nhom_tau_id"
                onChange={handleChangeInput}
                value={data?.nhom_tau_id ? data.nhom_tau_id : ""}
                select
                disabled={props.action === "DETAIL"}
              >
                <MenuItem value="">--Chọn--</MenuItem>
                {nhomTauOptions.map((item: any) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </TextField> */}
                        </Box>
                    </div>
                    <div className="col-xl-4">
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Hầm hàng</InputLabel>
                            <TextField
                                className="ksc-input"
                                sx={{ width: "100%" }}
                                name="ham_hang"
                                onChange={handleChangeInput}
                                value={data.ham_hang}
                                disabled={props.action === "DETAIL"}
                            />
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Loại kho</InputLabel>
                            <Autocomplete
                                id="country-select-demo"
                                sx={{ width: "100%" }}
                                options={khoOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.kho_id
                                        ? khoOptions.find((item: any) => item.id === data.kho_id)
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "kho_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="ksc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === "DETAIL"}
                            />
                            {/*               
                <TextField
                  className="ksc-input"
                  sx={{ width: "100%" }}
                  name="kho_id"
                  onChange={handleChangeInput}
                  value={data.kho_id ? data.kho_id : ""}
                  select
                  disabled={props.action === "DETAIL"}
                >
                  <MenuItem value="">--Chọn--</MenuItem>
                  {khoOptions.map((item: any) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </TextField> */}
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Tên kho</InputLabel>
                            <TextField
                                className="ksc-input"
                                sx={{ width: "100%" }}
                                name="kho_name"
                                onChange={handleChangeInput}
                                value={data.kho_name}
                                disabled={props.action === "DETAIL"}
                            />
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Tên tài xế</InputLabel>
                            <Autocomplete
                                id="combo-box-demo"
                                options={taiXeOptions}
                                // sx={{ width: 300 }}
                                freeSolo
                                getOptionLabel={(option: any) => option?.name}
                                value={{
                                    id: data?.ma_tai_xe ? data.ma_tai_xe : "",
                                    name: data?.ten_tai_xe ? data.ten_tai_xe : "",
                                }}
                                onChange={(event: any, newValue: any) => {
                                    setData({
                                        ...data,
                                        ma_tai_xe: newValue.id,
                                        ten_tai_xe: newValue.name,
                                    } as KscPhieuKiemSoatModel);
                                }}
                                inputValue={data?.ten_tai_xe ? data.ten_tai_xe : ""}
                                onInputChange={(event, newInputValue) => {
                                    setData({
                                        ...data,
                                        ten_tai_xe: newInputValue,
                                    } as KscPhieuKiemSoatModel);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="ma_tai_xe"
                                        className="ksc-input"
                                        sx={{ width: "100%" }}
                                    />
                                )}
                                disabled={props.action === "DETAIL"}
                            />
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Biển số xe </InputLabel>
                            <Autocomplete
                                id="combo-box-demo"
                                options={bienSoOptions}
                                // sx={{ width: 300 }}
                                freeSolo
                                // getOptionLabel={(option: any) => option.id}
                                value={data?.bien_so ? data.bien_so : ""}
                                onInputChange={(event: any, newInputValue: any) => {
                                    setData({
                                        ...data,
                                        bien_so: newInputValue,
                                    } as KscPhieuKiemSoatModel);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="bien_so"
                                        className="ksc-input"
                                        sx={{ width: "100%" }}
                                    />
                                )}
                                disabled={props.action === "DETAIL"}
                            />
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Số Remooc</InputLabel>
                            <Autocomplete
                                id="combo-box-demo"
                                options={remoocOptions}
                                // sx={{ width: 300 }}
                                freeSolo
                                getOptionLabel={(option: any) => option.name}
                                value={{
                                    id: data?.ma_remooc ? data.ma_remooc : "",
                                    name: data?.so_remooc ? data.so_remooc : "",
                                }}
                                onChange={(event: any, newValue: any) => {
                                    setData({
                                        ...data,
                                        ma_remooc: newValue.id,
                                        so_remooc: newValue.name,
                                    } as KscPhieuKiemSoatModel);
                                }}
                                inputValue={data?.so_remooc ? data.so_remooc : ""}
                                onInputChange={(event, newInputValue) => {
                                    setData({
                                        ...data,
                                        so_remooc: newInputValue,
                                    } as KscPhieuKiemSoatModel);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="ma_remooc"
                                        className="ksc-input"
                                        sx={{ width: "100%" }}
                                    />
                                )}
                                disabled={props.action === "DETAIL"}
                            />
                        </Box>

                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Ngày chứng từ</InputLabel>
                            <CTXDate
                                className="ksc-input"
                                style={{ width: "100%" }}
                                name="ngay_chung_tu"
                                onChange={handleChangeDate}
                                value={data.ngay_chung_tu ? data.ngay_chung_tu : null}
                                disabled={props.action === "DETAIL"}
                            />
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Ca giám sát</InputLabel>
                            <Autocomplete
                                id="country-select-demo"
                                sx={{ width: "100%" }}
                                options={caOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.ma_ca_giam_sat
                                        ? caOptions.find(
                                            (item: any) => item.id === data.ma_ca_giam_sat
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "ma_ca_giam_sat",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="ksc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === "DETAIL"}
                            />
                            {/* <TextField
                className="ksc-input"
                sx={{ width: "100%" }}
                name="ma_ca_giam_sat"
                onChange={handleChangeInput}
                value={data.ma_ca_giam_sat ? data.ma_ca_giam_sat : ""}
                select
                disabled={props.action === "DETAIL"}
              >
                <MenuItem value="">--Chọn--</MenuItem>
                {caOptions.map((item: any) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </TextField> */}
                        </Box>
                    </div>
                    <div className="col-xl-4">
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Số phiếu cân</InputLabel>
                            <TextField
                                className="ksc-input"
                                sx={{ width: "100%" }}
                                name="so_phieu_can"
                                onChange={handleChangeInput}
                                value={data.so_phieu_can}
                                disabled={props.action === "DETAIL"}
                            />
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Kiểu cân</InputLabel>
                            <Autocomplete
                                id="country-select-demo"
                                sx={{ width: "100%" }}
                                options={kieuCanOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.kieu_can_id
                                        ? kieuCanOptions.find(
                                            (item: any) => item.id === data.kieu_can_id
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "kieu_can_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="ksc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === "DETAIL"}
                            />
                            {/* <TextField
                className="ksc-input"
                sx={{ width: "100%" }}
                name="kieu_can_id"
                onChange={handleChangeInput}
                value={data.kieu_can_id ? data.kieu_can_id : ""}
                select
                disabled={props.action === "DETAIL"}
              >
                <MenuItem value="">--Chọn--</MenuItem>
                {kieuCanOptions.map((item: any) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </TextField> */}
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>K.L bì</InputLabel>
                            <TextField
                                sx={{ width: "100%" }}
                                className="ksc-number ksc-input"
                                name="khoi_luong_can_lan_1"
                                onChange={handleChangeMass}
                                placeholder="0"
                                value={
                                    data.khoi_luong_can_lan_1
                                        ? numberWithCommas(data.khoi_luong_can_lan_1)
                                        : null
                                }
                                type="tel"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">kg</InputAdornment>
                                    ),
                                }}
                                disabled={props.action === "DETAIL"}
                            />
                        </Box>

                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Thời gian cân bì</InputLabel>
                            <CTXDateTime
                                className="ksc-input"
                                style={{ width: "100%" }}
                                name="ngay_can_1"
                                onChange={handleChangeDate}
                                value={data.ngay_can_1 ? data.ngay_can_1 : null}
                                disabled={props.action === "DETAIL"}
                            />
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>K.L tổng</InputLabel>
                            <TextField
                                sx={{ width: "100%" }}
                                className="ksc-number ksc-input"
                                name="khoi_luong_can_lan_2"
                                onChange={handleChangeMass}
                                placeholder="0"
                                value={
                                    data.khoi_luong_can_lan_2
                                        ? numberWithCommas(data.khoi_luong_can_lan_2)
                                        : null
                                }
                                type="tel"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">kg</InputAdornment>
                                    ),
                                }}
                                disabled={props.action === "DETAIL"}
                            />
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Thời gian cân tổng</InputLabel>
                            <CTXDateTime
                                className="ksc-input"
                                style={{ width: "100%" }}
                                name="ngay_can_2"
                                onChange={handleChangeDate}
                                value={data.ngay_can_2 ? data.ngay_can_2 : null}
                                disabled={props.action === "DETAIL"}
                            />
                        </Box>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>K.L hàng</InputLabel>
                            <TextField
                                sx={{ width: "100%" }}
                                className="ksc-number ksc-input"
                                name="khoi_luong_hang"
                                onChange={handleChangeInput}
                                placeholder="0"
                                value={
                                    data.khoi_luong_can_lan_1 &&
                                    data.khoi_luong_can_lan_2 &&
                                    data.khoi_luong_can_lan_2 - data.khoi_luong_can_lan_1
                                }
                                type="tel"
                                disabled={true}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start"></InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </div>
                    <div className="col-12">
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14 }}>Ghi chú</InputLabel>
                            <TextareaAutosize
                                className="ksc-input"
                                style={{
                                    width: "100%",
                                    maxHeight: "200px",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    borderColor: "#bdbdbd",
                                    backgroundColor: "#fff",
                                }}
                                name="ghi_chu"
                                onChange={handleChangeInput}
                                value={data.ghi_chu}
                                disabled={props.action === "DETAIL"}
                                minRows={3}
                            />
                        </Box>
                    </div>
                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                        <div className="d-flex align-items-center">
                            <InputLabel sx={{ fontSize: 14 }}>
                                Hình chụp lúc cân bì
                            </InputLabel>
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                disabled={props.action === "DETAIL"}
                            >
                                <PhotoCamera
                                    id="hinh_luc_vao"
                                    name="hinh_luc_vao"
                                    onClick={(e: any) => showCamera(e, "hinh_luc_vao")}
                                />
                            </IconButton>
                        </div>
                        <div
                            className="d-flex"
                            style={{ width: "inherit", overflow: "auto" }}
                        >
                            {listHinhLucVao.length > 0 &&
                                listHinhLucVao.map((path, index) => (
                                    <div className="m-1">
                                        {/* <label
                        htmlFor={`uploadImage${index + 1}`}
                        style={{ width: "100%" }}
                      > */}
                                        <div style={{ position: "relative" }}>
                                            <img
                                                src={`${BASE_URL_API}${path}`}
                                                alt="Hình chụp lúc cân bì"
                                                style={{
                                                    width: "290px",
                                                    maxWidth: "600px",
                                                    height: "200px",
                                                    maxHeight: "400px",
                                                }}
                                            />
                                            <div
                                                className="position-absolute w-100 d-flex justify-content-end"
                                                style={{ top: 1, right: 1, cursor: "pointer" }}
                                            >
                                                <IconButton
                                                    color="error"
                                                    aria-label="upload picture"
                                                    component="label"
                                                    onClick={() =>
                                                        handleRemoveImage({
                                                            name: "hinh_luc_vao",
                                                            index: index,
                                                        })
                                                    }
                                                    disabled={props.action === "DETAIL"}
                                                >
                                                    <CancelIcon />
                                                </IconButton>
                                            </div>
                                            <div
                                                className="position-absolute w-100 d-flex justify-content-end"
                                                style={{ top: 27, right: 1, cursor: "pointer" }}
                                            >
                                                <IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="label"
                                                    disabled={props.action === "DETAIL"}
                                                >
                                                    <input
                                                        id={`uploadImage${index + 1}`}
                                                        className="flex-grow-1 form-control"
                                                        type="file"
                                                        accept="image/*"
                                                        name="hinh_luc_vao"
                                                        onChange={(e) => handleChangeImage(e, index)}
                                                        // value={data.anh_xe}
                                                        style={{ display: "none" }}
                                                    />
                                                    <ChangeCircleIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            <div className="m-1">
                                <label htmlFor={`uploadImage`} style={{ width: "100%" }}>
                                    <div
                                        className="c-img-card"
                                        style={{
                                            width: "290px",
                                            maxWidth: "600px",
                                            height: "200px",
                                            maxHeight: "400px",
                                        }}
                                    >
                                        <i className="bi bi-images" />
                                        &nbsp;Thêm ảnh mới...
                                    </div>
                                </label>
                                <input
                                    id="uploadImage"
                                    className="flex-grow-1 form-control"
                                    type="file"
                                    accept="image/*"
                                    name="hinh_luc_vao"
                                    onChange={handleAddNewImage}
                                    multiple
                                    // value={data.anh_xe}
                                    style={{ display: "none" }}
                                    disabled={props.action === "DETAIL"}
                                />
                            </div>
                        </div>
                    </Box>
                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                        <div className="d-flex align-items-center">
                            <InputLabel sx={{ fontSize: 14 }}>
                                Hình chụp lúc cân tổng
                            </InputLabel>
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                disabled={props.action === "DETAIL"}
                            >
                                <PhotoCamera
                                    id="hinh_luc_ra"
                                    name="hinh_luc_ra"
                                    onClick={(e: any) => showCamera(e, "hinh_luc_ra")}
                                />
                            </IconButton>
                        </div>

                        <div
                            className="d-flex"
                            style={{ width: "inherit", overflow: "auto" }}
                        >
                            {listHinhLucRa.length > 0 &&
                                listHinhLucRa.map((path, index) => (
                                    <div className="m-1">
                                        {/* <label
                        htmlFor={`uploadImage${index + 1}`}
                        style={{ width: "100%" }}
                      > */}
                                        <div style={{ position: "relative" }}>
                                            <img
                                                src={`${BASE_URL_API}${path}`}
                                                alt="Hình chụp lúc cân tổng"
                                                style={{
                                                    width: "290px",
                                                    maxWidth: "600px",
                                                    height: "200px",
                                                    maxHeight: "400px",
                                                }}
                                            />
                                            <div
                                                className="position-absolute w-100 d-flex justify-content-end"
                                                style={{ top: 1, right: 1, cursor: "pointer" }}
                                            >
                                                <IconButton
                                                    color="error"
                                                    aria-label="upload picture"
                                                    component="label"
                                                    onClick={() =>
                                                        handleRemoveImage({
                                                            name: "hinh_luc_ra",
                                                            index: index,
                                                        })
                                                    }
                                                    disabled={props.action === "DETAIL"}
                                                >
                                                    <CancelIcon  sx={{backgroundColor: "#fff", borderRadius: "50%"}}/>
                                                </IconButton>
                                            </div>
                                            <div
                                                className="position-absolute w-100 d-flex justify-content-end"
                                                style={{ top: 27, right: 1, cursor: "pointer" }}
                                            >
                                                <IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="label"
                                                    disabled={props.action === "DETAIL"}
                                                >
                                                    <input
                                                        id={`uploadImage${index + 1}`}
                                                        className="flex-grow-1 form-control"
                                                        type="file"
                                                        accept="image/*"
                                                        name="hinh_luc_ra"
                                                        onChange={(e) => handleChangeImage(e, index)}
                                                        // value={data.anh_xe}
                                                        style={{ display: "none" }}
                                                    />
                                                    <ChangeCircleIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            <div className="m-1">
                                <label htmlFor={`uploadImage0`} style={{ width: "100%" }}>
                                    <div
                                        className="c-img-card"
                                        style={{
                                            width: "290px",
                                            maxWidth: "600px",
                                            height: "200px",
                                            maxHeight: "400px",
                                        }}
                                    >
                                        <i className="bi bi-images" />
                                        &nbsp;Thêm ảnh mới...
                                    </div>
                                </label>
                                <input
                                    id="uploadImage0"
                                    className="flex-grow-1 form-control"
                                    type="file"
                                    accept="image/*"
                                    name="hinh_luc_ra"
                                    onChange={handleAddNewImage}
                                    multiple
                                    // value={data.anh_xe}
                                    style={{ display: "none" }}
                                    disabled={props.action === "DETAIL"}
                                />
                            </div>
                        </div>
                    </Box>
                </div>
            </DialogContent>
            <DialogActions>
                {props.action !== "DETAIL" && (
                    <Button variant="contained" onClick={handleSubmit}>
                        Lưu
                    </Button>
                )}
                <Button onClick={props.onClose}>Hủy</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default CanXe;
