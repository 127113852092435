import React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Box,
    InputLabel,
    Autocomplete,
    TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    CommonClient,
    ParaObjReportInfo,
    SysModel,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import '../../actions/ApartmentBuilding.css';
import notification from "../../../../common/notification/notification";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";


interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    title?: string,
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (data: SysModel) => void;
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface CaVch01Print {
    reportResx?: string,
    title?: string,
}

var appSession: AppSession;

const ReceiptPrintAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    // 
    // const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    // const caVch01Client = new CaVch01Client(appSession, BASE_URL_API);


    const [data, setData] = React.useState<CaVch01Print>({} as CaVch01Print);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    // const [selectedValue, setSelectedValue] = React.useState('a');


    const commonClient = new CommonClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [reportResxOption, setReportResxOption] = React.useState<ParaObjReportInfo[]>([]);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
    // 


    React.useEffect(() => {
        setLoading(true)
        commonClient
            .reportInfoGet({
                ...new ParaObjReportInfo(),
                menuid: menu?.menuid,
                language: 'vi-VN',
                apartment_building_id: apartment_building_id,
            } as ParaObjReportInfo)
            .then(res => {

                setLoading(false)
                setReportResxOption(res);
                res.forEach(report => {
                    if (report.isdefault) {
                        setData(pre => ({ ...pre, reportResx: report?.ma_mau, title: report?.title }));
                        return;
                    }
                })
            }).catch(error => {
                setLoading(false);
                notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                props.onClose();
            })
    }, [])








    const handleSubmit = () => {
        // setLoading(true)
        // caVch01Client.print({
        //     stT_REC: props?.itemSelected?.stT_REC,
        //     mA_CT: "CA1",
        //     mA_CTY: SessionLogin?.ma_cty,
        //     action_type: data?.reportResx
        // } as CaVch01M)
        // .then(res => {

        // setLoading(false);

        // params
        const URL = BASE_URL_API + `/Report/Reports?ma_cty=${SessionLogin.ma_cty}&ma_ct=CA1&stt_rec=${props?.itemSelected?.stT_REC}&mau=${data?.reportResx}&apartment_building_id=${apartment_building_id}`

        var params: string = 'width=' + window.screen.availWidth;
        params += ', height=' + window.screen.availHeight;
        params += ', top=0, left=0'
        params += ', fullscreen=yes';
        params += ', directories=no';
        params += ', location=no';
        params += ', menubar=no';
        params += ', resizable=no';
        params += ', scrollbars=no';
        params += ', status=no';
        params += ', toolbar=no';
        window.open(URL, '_blank', params);
        // });



    };


    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value } as CaVch01Print);
    };


    return (
        <Dialog
            id="sysMenu6"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            TransitionComponent={Transition}
            maxWidth='xs'

        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {`In phiếu thu`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >
                <div className="row">
                    <div className="col-12">
                        {/* Chọn mẫu in phiếu th */}
                        <Box sx={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Chọn mẫu in phiếu thu</InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="pay-type-select"
                                className="cc-input"
                                dataSource={reportResxOption}
                                value={
                                    data?.reportResx
                                }
                                displayMember='ten_mau'
                                valueMember="ma_mau"
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "reportResx",
                                            value: newValue?.ma_mau ? newValue.ma_mau : null,
                                        },
                                    });

                                    setData(pre => ({ ...pre, title: newValue?.title ?? null }))
                                }}
                                disabled={props.action === "DETAIL"}
                            />
                            {/* <Autocomplete
                                id="report-resx-select"
                                sx={{ width: "100%" }}
                                options={reportResxOption}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.ten_mau}
                                value={
                                    data?.reportResx
                                        ? reportResxOption.find(
                                            (item: any) => item.ma_mau === data.reportResx
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "reportResx",
                                            value: newValue?.ma_mau ? newValue.ma_mau : null,
                                        },
                                    });

                                    setData(pre => ({ ...pre, title: newValue?.title ?? null }))
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === "DETAIL"}
                            /> */}

                        </Box>
                        {/* Tiêu đề */}

                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tiêu đề</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                placeholder="Tiêu đề"
                                name="title"
                                onChange={handleChangeInput}
                                value={data?.title}
                                disabled={props.action === "DETAIL"}
                            />

                        </Box>

                    </div>

                </div>
            </DialogContent>
            <DialogActions>

                <Button variant="contained" onClick={handleSubmit}>
                    In phiếu thu
                </Button>

                <Button onClick={props.onClose}>Hủy</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default ReceiptPrintAction;


