import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
    FormControlLabel,
    Switch,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    HomeClient,
    QLCCApartmentBuildingModel,
    QLCCClient,
    QLCCInvestorModel,
    QLCCResidentsModel,
    QLCCUtilityBookingStatusModel,
    QLCCUtilityModel,
    RoleRightWebModel,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import '../../actions/ApartmentBuilding.css';

import DHSAutoCompleteWithDataGrid from "../../../../components/DHS/DHS_AutoCompleteCustomer/DHSAutoCompleteWithDataGrid";
import BetweenTwoDate from "../../report/cash-book-report/component/between-two-date-X";
import { httpPost } from "../../../../common/httpService";
import moment from "moment";
import DateBox from "devextreme-react/date-box";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";
import DHSAutocompleteFilterListSelect from "../../../../components/DHSUtilComponent/DHSAutocompleteFilterListSelect/DHSAutocompleteFilterListSelect";
import { setApartmentBuilding } from "../../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction";
import { checkRequiredField } from "../../../../components/utils/object.util";


interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onApply: (filtered: IFilter) => void;
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

interface IFilter {
    apartment_building_id?: string | null,
    utility_id?: string | null,
    status_id?: string | null,
    nonDuplicateDate?: boolean | undefined,
    start_time?: Date | null,
    end_time?: Date | null,
}

const defaultFilter: IFilter = {
    apartment_building_id: null,
    utility_id: null,
    status_id: null,
    nonDuplicateDate: false,
    start_time: null,
    end_time: null,
}


var appSession: AppSession;
const ServiceBookingSearchAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const dispatch = useDispatch();
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const qLCClient = new QLCCClient(appSession, BASE_URL_API);

    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [isFirst, setIsFirst] = React.useState<boolean>(false);



    // 
    const [filter, setFilter] = React.useState<IFilter>(defaultFilter);
    const [filterString, setFilterString] = React.useState<string>("");
    const [fromDateFilter, setFromDateFilter] = React.useState<string>("");
    const [toDateFilter, setToDateFilter] = React.useState<string>("");

    // 

    // const [apartmentBuildingOptions, setApartmentBuildingOptions] = React.useState<any[]>([]);
    // const [residentOptions, setResidentOptions] = React.useState<QLCCResidentsModel[]>([]);
    // const [utilityOptions, setUtilityOptions] = React.useState<QLCCUtilityModel[]>([]);
    // const [serviceBookingStatusOptions, setServiceBookingStatusOptions] = React.useState<QLCCUtilityBookingStatusModel[]>([]);

    const [open, setOpen] = React.useState<boolean>(false);
    // 

    React.useEffect(() => {
        setOpen(props.open)
    }, [props.open]);

    React.useEffect(() => {
        if (open) {
            const current_date = new Date();
            setFilter(pre => (
                {
                    ...pre,
                    start_time: moment().startOf('month').toDate(),
                    end_time: moment().endOf('month').toDate(),
                    apartment_building_id: apartment_building_id
                } as IFilter))
        }
    }, [open, apartment_building_id])


    // React.useEffect(() => {

    //     setLoading(true);
    //     homeClient
    //         ?.roleRightWebGetAll({
    //             ...new RoleRightWebModel(),
    //             rolename: "CC",
    //             username: SessionLogin.userName,
    //             menuid: menu?.menuid,
    //         } as RoleRightWebModel)
    //         .then((res) => {
    //             setRoles(res);
    //             setLoading(false);
    //         });
    //     setLoading(true);

    // }, []);

    //#region handleGetOptions

    React.useEffect(() => {
        // setLoading(true);
        // qLCClient.qlccApartmentBuildingGet({
        //     ...new QLCCApartmentBuildingModel(),
        //     ma_cty: SessionLogin.ma_cty,
        //     action_type: 'GET'
        // } as QLCCApartmentBuildingModel)
        //     .then(response => {


        //         setApartmentBuildingOptions(response)
        //         if (response) {
        //             // setFilter(pre => ({ ...pre, apartment_building_id: response[0].id ?? null }));
        //             // handleChangeFilterInput({
        //             //     target: {
        //             //         name: "apartment_building_id",
        //             //         value: response[0].id ?? null,
        //             //     },
        //             // });
        //         }
        //         setLoading(false);
        //     });

        // 

        // const body = {
        //     ma_cty: SessionLogin.ma_cty,
        //     tablelist: "dm_kh"
        // }
        // httpPost<string>('api/Common/LoadFilterList', body)
        //     .then(res => {
        //         const customers: QLCCResidentsModel[] = JSON.parse(res.data);
        //         setResidentOptions(customers)

        //     });

        // 

        // httpPost<string>('api/Common/LoadFilterList', {
        //     tablelist: "dm_dich_vu"
        // })
        //     .then(res => {

        //         const utilities: QLCCUtilityModel[] = JSON.parse(res.data);
        //         setUtilityOptions(utilities)
        //         setLoading(false)

        //     });
        // httpPost<string>('api/Common/LoadFilterList', {
        //     tablelist: "dm_trang_thai_tien_ich"
        // })
        //     .then(res => {

        //         const utility_status: QLCCUtilityBookingStatusModel[] = JSON.parse(res.data);
        //         setServiceBookingStatusOptions(utility_status)

        //         setLoading(false)

        //     });
    }, []);

    //#endregion

    //#region hanldeWhenFilterChange
    React.useEffect(() => {
        const arrayFilter = Object.entries(filter).map(([key, value]) => ({ [key]: value }));
        var tempFilterArray: string[] = [];
        arrayFilter.forEach(filter => {
            const keys = Object.keys(filter);
            if (filter[keys[0]] !== null) {
                if (keys[0] === 'ngay_ct') {
                    tempFilterArray.push(filter[keys[0]]);

                }
                else tempFilterArray.push(`${keys[0]} LIKE '${filter[keys[0]]}'`);

            }
        })
        setFilterString(tempFilterArray.join(" AND "));

    }, [filter]);

    //#endregion

    //#region handleSubmit

    React.useEffect(() => {
        if(checkRequiredField(filter, ["status_id"]) && !isFirst){
            props.onApply(filter)
            setIsFirst(true)
        }
    }, [filter, isFirst])
    const handleSubmit = () => {


        props.onApply(filter)

    };

    //#endregion

    //#region handleFilterString
    const handleChangeFilterInput = (e: any) => {
        const { name, value } = e.target;

        setFilter(pre => ({ ...pre, [name]: value } as IFilter));

        // 

        // updateFilterString(name, value);

    };

    const handleChangeDateFilter = (e: any) => {
        const { value, name } = e.target;

        if (value instanceof Date && !isNaN(value.valueOf())) {
            setFilter(pre => ({
                ...pre, [name]: value
            } as IFilter));
        }
        // setData({ ...data, [name]: new Date(value) } as SoVch05M);
    }

    const updateFilterString = (name: string, value: any) => {
        var tempFilterString = filterString;
        setFilterString(tempFilterString.trim());
    }

    //#endregion

    //#region handleWhenDateChange

    React.useEffect(() => {
        if (fromDateFilter && toDateFilter) {
            updateFilterDateBetween("ngay_ct")
        }

    }, [fromDateFilter, toDateFilter])


    const updateFilterDateBetween = (name: string) => {

        setFilter(pre => ({ ...pre, [name]: `${name} BETWEEN '${fromDateFilter}' AND '${toDateFilter}'` }))
    }

    //#endregion


    return (
        <Dialog
            id="sysMenu6"
            open={open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "end",
                overflow: "visible !important",

            }}
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    height: "100vh !important",
                    minHeight: "100vh !important"
                }
            }}
            maxWidth='xs'
            fullScreen
            keepMounted
        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {"Tìm dịch vụ đã đăng ký [nâng cao]"
                            // menu?.bar +
                            //     " " +
                            //     `${action.action === "DETAIL"
                            //         ? "[Chi tiết]"
                            //         : action.action === "UPDATE" || _id
                            //             ? "[Cập nhật]"
                            //             : "[Thêm mới]"
                            //     }`
                        }
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent sx={{ overflow: 'visible' }}>

                <div className="row">
                    <div className="col-md-12" >
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Chung cư</InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="apartment-building-select"
                                className="cc-input"
                                dataSource={apartmentBuildingOptions}
                                value={
                                    filter?.apartment_building_id
                                }
                                displayMember='name'
                                valueMember="id"
                                onValueChanged={(e, newValue: any) => {
                                   dispatch(setApartmentBuilding(newValue) as QLCCApartmentBuildingModel)
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option?.id}>
                                            {option?.name}
                                        </li>
                                    );
                                }}
                            />

                        </Box> */}

                        {/*  */}

                        <Box style={{ width: "100%", margin: "5px 0px" }}>

                            <InputLabel
                                required
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Chọn dịch vụ
                            </InputLabel>
                            <DHSAutocompleteFilterListSelect
                                id="main-direction-select"
                                table_list="dm_dich_vu"
                                value={
                                    filter?.utility_id
                                }

                                onValueChanged={(e, newValue: any) => {
                                    handleChangeFilterInput({
                                        target: {
                                            name: "utility_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                            />
                           


                        </Box>

                        <Box style={{ width: "100%", margin: "5px 0px" }}>

                            <InputLabel
                                required
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Chọn trạng thái
                            </InputLabel>

                            <DHSAutocompleteFilterListSelect
                                id="status-select"
                                table_list="dm_trang_thai_tien_ich"
                                value={filter?.status_id}
                                defaultValue={(data) => {
                                    setFilter(pre => ({
                                        ...pre,
                                        status_id: data[0].id
                                    }))
                                }}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeFilterInput({
                                        target: {
                                            name: "status_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });




                                }}

                            />



                        </Box>

                        {/* Không trùng ngày */}
                        <Box className="row">
                            <Box className="col-12">


                                {/* <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ban công</InputLabel> */}

                                <FormControlLabel control={
                                    <Switch
                                        name="nonDuplicateDate"
                                        value={filter.nonDuplicateDate}
                                        checked={filter.nonDuplicateDate}
                                        onChange={(e: any, checked: boolean) => handleChangeFilterInput({
                                            target: {
                                                name: e.target.name,
                                                value: checked
                                            }
                                        })}
                                        disabled={props.action === "DETAIL"}
                                        color="primary" />} label={<Typography sx={{ fontSize: 14, color: '#333' }}>Không trùng ngày</Typography>} />


                            </Box>


                            {/* <Box className="col-6">


                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tình trạng</InputLabel>

                                                <FormControlLabel control={
                                                    <Switch
                                                        name="condition"
                                                        value={data.condition}
                                                        checked={data.condition}

                                                        onChange={(e: any, checked: boolean) => handleChangeInput({
                                                            target: {
                                                                name: e.target.name,
                                                                value: checked
                                                            }
                                                        })}
                                                        disabled={props.action === "DETAIL"}
                                                        color="primary" />} label={<Typography sx={{ fontSize: 14, color: '#333' }}>Không cho thuê/Đang cho thuê</Typography>} />

                                            </Box> */}

                        </Box>
                    </div>
                </div>

                <Box style={{ width: "100%", margin: "5px 0px" }}>
                    {/* <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mã code</InputLabel> */}
                    <div className="card">

                        <div className="card-body">
                            <div className="" style={{ fontSize: "14px", fontWeight: 500 }}>
                                Thời gian sử dụng
                            </div>
                            <Box style={{ width: "100%", margin: "5px 0px" }}>

                                <InputLabel
                                    required
                                    sx={{ fontSize: 14, color: "#333", }}
                                >
                                    Sử dụng từ
                                </InputLabel>
                                <DateBox applyValueMode="useButtons"
                                    defaultValue={filter?.start_time ?? undefined}
                                    value={filter?.start_time ?? undefined}
                                    type="datetime"
                                    displayFormat={"dd/MM/yyyy HH:mm:ss"}
                                    // min={this.min}
                                    // max={this.now}
                                    inputAttr={{ 'aria-label': 'Date Time', }}
                                    onValueChanged={(e) => handleChangeDateFilter({
                                        target: {
                                            name: "start_time",
                                            value: e.value
                                        }
                                    })} />
                            </Box>
                            <Box style={{ width: "100%", margin: "5px 0px" }}>

                                <InputLabel
                                    required
                                    sx={{ fontSize: 14, color: "#333", }}
                                >
                                    Sử dụng đến
                                </InputLabel>
                                <DateBox applyValueMode="useButtons"
                                    defaultValue={filter?.end_time ?? undefined}
                                    value={filter?.end_time ?? undefined}
                                    type="datetime"
                                    displayFormat={"dd/MM/yyyy HH:mm:ss"}
                                    // min={this.min}
                                    // max={this.now}
                                    inputAttr={{ 'aria-label': 'Date Time', }}
                                    onValueChanged={(e) => handleChangeDateFilter({
                                        target: {
                                            name: "end_time",
                                            value: e.value
                                        }
                                    })} />
                            </Box>
                        </div>
                    </div>

                </Box>
                {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mã code</InputLabel>
                    <TextField
                        className="cc-input"
                        sx={{
                            width: "100%",
                            marginRight: "5px",
                        }}
                        autoFocus
                        name="code"
                        onChange={handleChangeInput}
                        value={data.code}
                        disabled={action.action === "DETAIL"}
                    />

                </Box> */}





            </DialogContent>
            <DialogActions>
                {props.action !== "DETAIL" && (
                    <Button variant="contained" onClick={handleSubmit}>
                        Áp dụng
                    </Button>
                )}
                <Button onClick={props.onClose}>Bỏ lọc</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default ServiceBookingSearchAction;


