import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    CommonClient,
    HomeClient,
    QLCCClient,
    QLCCRegulationModel,
    RoleRightWebModel,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import '../ApartmentBuilding.css';
import notification from "../../../../common/notification/notification";
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import CTXDateTime from "../../../../components/CTX/DateTimePicker";
import Upload from "../../../../components/DHS/DHS_Upload/Upload";
import moment from "moment";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";


interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const status_message = ['success', 'error']

var appSession: AppSession;
var htmlString: string;

const RegulationAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);

    const [data, setData] = React.useState<QLCCRegulationModel>(new QLCCRegulationModel());
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [action, setAction] = React.useState<IProps>(props);



    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    // 


    const [RegulationImageList, setRegulationImageList] = React.useState<string[]>([] as string[]);

    const [warrantyOptions, setWarrantyOptions] = React.useState<any[]>([
        {
            id: '552EFEC6-280D-4241-9C13-00097B',
            name: 'Bảo hành 1'
        },
        {
            id: '820B17F9-5F07-4D74-AF4A-842D94',
            name: 'Bảo hành 2'
        },
        {
            id: 'B201486D-CA50-46EC-9B0B-C8969F',
            name: 'Bảo hành 3'
        }
    ]);


    React.useEffect(() => {
        if (action.action === "EDIT" || action.action === "DETAIL") {
            qLCClient
                .qLCCRegulationGet({
                    ...new QLCCRegulationModel(),
                    id: action.itemSelected,
                    action_type: 'GET'
                } as QLCCRegulationModel)
                .then((res) => {
                    setData(res[0]);

                })
                .catch(() => alert("Không lấy được dữ liệu"));
            setId(action.itemSelected);
        } else {
            setData(new QLCCRegulationModel());
            setId(undefined)
        };
        // }
    }, [action.action]);


    React.useEffect(() => {

        setLoading(true);
        homeClient
            ?.roleRightWebGetAll({
                ...new RoleRightWebModel(),
                rolename: "CC",
                username: SessionLogin.userName,
                menuid: menu?.menuid,
            } as RoleRightWebModel)
            .then((res) => {
                setRoles(res);
                setLoading(false);
            });
        setLoading(true);

    }, []);

    React.useMemo(() => {
        if (data.attach_files) setRegulationImageList(data.attach_files.split(";"));
    }, [data.attach_files]);

    const handleSubmit = () => {


        setLoading(true)

        switch (action.action) {

            case 'EDIT': {
                qLCClient.qLCCRegulationAction({
                    ...new QLCCRegulationModel(),
                    ...data,
                    notes: htmlString,
                    action_type: 'UPDATE'
                } as QLCCRegulationModel)
                    .then((response) => {
                        setLoading(false);
                        notification(status_message[response.status!], response.message);
                        response.status === 0 && action.onEditSuccess(true);

                    });
                break;
            }
            case 'ADD': {
                qLCClient.qLCCRegulationAction({
                    ...new QLCCRegulationModel(),
                    ...data,
                    notes: htmlString,
                    action_type: 'INSERT'
                } as QLCCRegulationModel)
                    .then((response) => {
                        setLoading(false);
                        notification(status_message[response.status!], response.message)

                        if (response.status === 0) {
                            action.onEditSuccess(true);
                            setId(response.ref_id)
                            setAction({ ...action, itemSelected: response.ref_id, action: 'EDIT' })
                        }
                    });

                break;

            }
        }
        // if (_id || action.action === "EDIT") {


        // }
        // else {

        // }
    };

    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value } as QLCCRegulationModel);
    };

    const handleChangeDate = (e: any) => {
        const { name, value } = e;

        setData({ ...data, [name]: new Date(value) } as QLCCRegulationModel);
    }

    const handleChangeRichText = (stringData: string) => {
        htmlString = stringData;


    }


    return (
        <Dialog
            id="sysMenu"
            open={action.open}
            onClose={action.onClose}
            sx={{
                display: "flex",
                justifyContent: "flex-end",
            }}
            TransitionComponent={Transition}
            maxWidth='xs'

        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${action.action === "DETAIL"
                                ? "[Chi tiết]"
                                : action.action === "UPDATE" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={action.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >

                <Box style={{ width: "100%", margin: "5px 0px" }}>
                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tên qui định</InputLabel>
                    <TextField
                        className="cc-input"
                        sx={{
                            width: "100%",
                            marginRight: "5px",
                        }}
                        autoFocus
                        name="name"
                        onChange={handleChangeInput}
                        value={data.name}
                        disabled={action.action === "DETAIL"}
                    />

                </Box>



                {/* Bảo hành */}
                <Box style={{ width: "100%", margin: "5px 0px" }}>
                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Bảo hành</InputLabel>
                    <DHSAutoCompleteWithDataSource
                        id="country-select-demo"
                        className="cc-input"
                        dataSource={warrantyOptions}
                        displayMember="name"
                        valueMember="id"
                        value={data?.warranty_of}
                        onValueChanged={(e, newValue: any) => {
                            handleChangeInput({
                                target: {
                                    name: "warranty_of",
                                    value: newValue?.id ? newValue.id : null,
                                },
                            });
                        }}
                        disabled={action.action === "DETAIL"}
                    />
                    {/* <Autocomplete
                        id="country-select-demo"
                        sx={{ width: "100%" }}
                        options={warrantyOptions}
                        autoHighlight
                        getOptionLabel={(option: any) => option?.name}
                        value={
                            data?.warranty_of
                                ? warrantyOptions.find(
                                    (item: any) => item.id === data.warranty_of
                                )
                                : null
                        }
                        onChange={(e, newValue: any) => {
                            handleChangeInput({
                                target: {
                                    name: "warranty_of",
                                    value: newValue?.id ? newValue.id : null,
                                },
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className="cc-input"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                            />
                        )}
                        disabled={action.action === "DETAIL"}
                    /> */}
                </Box>
                {/*  ghi chú */}
                <Box style={{ width: "100%", margin: "5px 0px" }}>
                    <InputLabel sx={{ fontSize: 14, color: '#333' }}> Mô tả</InputLabel>
                    <RichTextEditor
                        onChange={(stringData) => handleChangeRichText(stringData)}
                        value={data.notes}

                    />
                </Box>

                {/* Ngày tạo */}
                <Box sx={{ width: '100%', margin: '5px 0' }}>
                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày tạo</InputLabel>
                    <CTXDateTime
                        className="cc-input"
                        style={{ width: '100%' }}
                        name="create_date"
                        onChange={handleChangeDate}
                        value={data.create_date ?? null}
                        disabled={action.action === "DETAIL"}
                    />
                </Box>

                {/* Đính kèm file */}
                <Box style={{ width: "100%", margin: "5px 0px" }}>
                    <div className="d-flex align-items-center">
                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>
                            Đính kèm file
                        </InputLabel>
                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                            disabled={action.action === "DETAIL"}
                        >

                        </IconButton>
                    </div>
                    <div style={{ display: 'flex', overflowX: 'auto', gap: '3px' }}>
                        <Upload multiple callbackURL={function (url: string): void {

                        }} />

                        <div className="m-1">
                            {/* <label htmlFor={`uploadFrontImageAdd`} style={{ width: "100%" }}>
                                <div
                                    className="c-img-card"
                                    style={{
                                        width: "324px",
                                        maxWidth: "360px",
                                        height: "204px",
                                        maxHeight: "240px",
                                        borderRadius: '12px'
                                    }}
                                >
                                    <i className="bi bi-images" />
                                    &nbsp;Thêm ảnh mới...
                                </div>
                            </label> */}
                            {/* <input
                                id="uploadFrontImageAdd"
                                className="flex-grow-1 form-control"
                                type="file"
                                accept="image/*"
                                name="front_side_image"
                                onChange={handleAddNewFile}
                                // value={data.anh_xe}
                                style={{ display: "none" }}
                                disabled={action.action === "DETAIL"}
                            /> */}
                        </div>
                    </div>


                </Box>


            </DialogContent>
            <DialogActions>
                {action.action !== "DETAIL" && (
                    <Button variant="contained" onClick={handleSubmit}>
                        Lưu
                    </Button>
                )}
                <Button onClick={action.onClose}>Hủy</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default RegulationAction;


