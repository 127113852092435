/* eslint-disable import/first */
import axios from "axios";
import BASE_URL_API from "../app/shared/service-proxies/config";
import { Method } from "../common/Interfaces/Interfaces";
const config = require("./default.json");
declare global {
    interface Window {
        BASE_URL: string;
    }
}
export default function callApi(endpoint: string, method: Method, body?: any, headers?: any) {
    return axios({
        method: method,
        url: `${BASE_URL_API}/${endpoint}`,
        data: body,
        headers: {
            Authorization: 'DHSJWT-ADD55F36-CE51-4902-B2F6-62DEA2F46B04'
        }
    });
}
export function callApiToken(uri: string, method: Method, body?: any, headers: object = {}) {
    let _header = {
        ...headers,
        Authorization: 'DHSJWT-ADD55F36-CE51-4902-B2F6-62DEA2F46B04',
    }

    if (method.toLocaleUpperCase() === 'POST') {
        return axios({
            method: method,
            url: `${BASE_URL_API}/${uri}`,
            data: body,
            headers: _header
        });
    } else if (method.toLocaleUpperCase() === 'GET') {
        return axios({
            method: method,
            url: `${BASE_URL_API}/${uri}`,
            params: body,
            headers: _header
        });
    }
    else {
        return axios({
            method: method,
            url: `${BASE_URL_API}/${uri}`,
            data: body,
            headers: _header
        });
    }
}



