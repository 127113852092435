import React from "react";
import Paper from "@mui/material/Paper";
import {
  CustomSummary,
  DataTypeProvider,
  FilteringState,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  IntegratedSummary,
  PagingState,
  SearchState,
  SelectionState,
  SortingState,
  SummaryState,
} from "@devexpress/dx-react-grid";

import {
  Grid,
  PagingPanel,
  Table,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  TableGroupRow,
  Toolbar,
  GroupingPanel,
  TableColumnResizing,
  DragDropProvider,
  SearchPanel,
  ExportPanel,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import { GridExporter } from '@devexpress/dx-react-grid-export';

import {
  HomeClient,
  SysGenRowTableModel,
} from "../../../../../app/shared/service-proxies/api-shared";
import { AppSession } from "../../../../../app/shared/app-session/app-session";
import BASE_URL_API from "../../../../../app/shared/service-proxies/config";
import "./grid-view.css";
import { alpha, styled } from "@mui/material/styles";
import saveAs from 'file-saver';
import { Grouping } from "@devexpress/dx-react-grid";

interface IProps {
  itemClicked?: () => void;
  onSelectRow: (v: any, index?: number) => void;
  onSelectRowItem?: (v: any, index?: number) => void;
  onListSelectRow?: (v: any[]) => void;
  paperHeight?: string,
  headerClassName?: string,
  filterClassName?: string,
  table_name?: string;
  grid_name?: string;
  data: any[];
  columns?: any[];
  show_form_list: boolean;
  showSearchPanel?: boolean;
  showFilterPanel?: boolean;
  showGroupPanel?: boolean;
  showSortPanel?: boolean;
  showSelectBox?: boolean;
  excel?: boolean;
  defaultPageSize?: number;
  defaultGrouping?: Grouping[],
  expandedGroups?: string[]
}
// const rows: IRow[] = generateRows({ length: 60 }) as IRow[];
interface IDefautWidths {
  columnName: string;
  width: number;
}
const pageSizes = [5, 10, 15, 20, 50, 100, 500, 1000];

let appSession: AppSession;
const DHSGridView = (props: IProps) => {
  // const index = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const exporterRef = React.useRef<any>(null);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const [selection, setSelection] = React.useState<any[]>([]);
  const [columns, setColumn] = React.useState<any[]>([]);
  const [currencyColumns, setcurrencyColumns] = React.useState<any[]>([]);
  const [imageColumns, setImageColumns] = React.useState<any[]>([]);
  const [numberColumns, setNumberColumns] = React.useState<any[]>([]);
  const [dateColumns, setDateColumns] = React.useState<any[]>([]);
  const [dateTimeColumns, steDateTimeColumns] = React.useState<any[]>([]);
  const [defaultColumnGrouping, setDefaultColumnGrouping] = React.useState<any[]>([]);
  const [defaultHiddenColumnNames, setDefaultHiddenColumnNames] =
    React.useState<string[]>(["id"]);
  const [defaultColumnWidths, setDefaultColumnWidths] = React.useState<any[]>(
    []
  );

  const [tableColumnExtensions, setTableColumnExtensions] = React.useState<Table.ColumnExtension[]>([]);

  React.useEffect(() => {
    let list_hide: string[] = [];
    let list_resing: IDefautWidths[] = [];
    let pr = new SysGenRowTableModel();
    pr.table_name = props.table_name;
    pr.show_form_list = props.show_form_list;
    homeClient?.sysGenRowTable(pr).then((res) => {
      let currency: any[] = [];
      let totalSummary: any[] = [];
      let images: any[] = [];
      let extensions: any[] = [];
      let dates: any[] = [];
      let dateTimes: any[] = [];
      let numbers: any[] = [];
      res.forEach((row) => {
        if (row.default_hiden) list_hide.push(row.name ? row.name : "");
        list_resing.push({
          columnName: row.name ? row.name : "",
          width: row.width ? row.width : 150,
        });
        if (row.data_type === "money") {
          currency.push(row.column_name);
          totalSummary.push({ columnName: row.column_name, type: "sum" });
          extensions.push({ columnName: row.column_name, align: "right" });
        } else if (row.data_type === "datetime") {
          dateTimes.push(row.data_type === 'datetime')
        } else if (row.data_type === "date")
          dates.push(row.column_name);
        else if (row.data_type === 'number')
          numbers.push(row.column_name);
        else if (row.data_type === "image") {
          images.push(row.column_name);
        }
      });
      settotalSummaryItems([]);
      // settotalSummaryItems(totalSummary);
      setTableColumnExtensions(extensions);
      setcurrencyColumns(currency);
      setImageColumns(images);
      setNumberColumns(numbers);
      setDateColumns(dates);
      steDateTimeColumns(dateTimes);
      setColumn(res);
      setDefaultHiddenColumnNames(list_hide);
      setDefaultColumnWidths(list_resing);
    });
  }, [props.showFilterPanel]);

  const handleRowChange = (event: any[]) => {
    var id = "";
    try {
      if (props.data[event[event.length - 1]]["id"]) {
        id = props.data[event[event.length - 1]]["id"];

        props.onSelectRow(id, event[event.length - 1]);
        props.onSelectRowItem && props.onSelectRowItem(props.data[event[event.length - 1]], event[event.length - 1]);
      } else {
        props.onSelectRow(
          props.data[event[event.length - 1]],
          event[event.length - 1]
        );
        props.onSelectRowItem && props.onSelectRowItem(
          props.data[event[event.length - 1]],
          event[event.length - 1]
        );
      }
    } catch {
      props.onSelectRow("");
    }
    var ids: any[] = [];
    event.forEach((e) => {
      ids?.push(props.data[e]?.id);
    });
    setSelection(event.length > 0 ? [event[event.length - 1]] : []);
    // props.onListSelectRow(ids);
  };
  const PREFIX = "Demo";
  const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
  };
  const StyledTable = styled(Table.Table)(({ theme }) => ({
    [`&.${classes.tableStriped}`]: {
      "& tbody tr:nth-of-type(odd)": {
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
      },
    },
  }));
  const TableComponent = (pr: any) => (
    <StyledTable {...pr} className={classes.tableStriped} />
  );

  const CellComponent = (props: any) => {

    const { column } = props;
    // console.log(props, "props")
    // if (column.name === 'ngay_ct') {
    //   return <HighlightedCell {...props} />;
    // }
    return <Table.Cell {...props}  style={{ border: '0.5px solid rgba(226, 232, 240, 0.7)' }} />;
  };
  const startExport = React.useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const getTotalSummaryValues = () => {
    try {
      if (props.data.length > 0) {
        return totalSummaryItems.map((summary) => {
          const { columnName, type } = summary;
          return IntegratedSummary.defaultCalculator(
            type,
            props.data,
            (row: any) => row[columnName]
          );
        });
      }
    } catch (e) {
      console.log("error totalSummaryItems");
      console.log(e);
    }
  };
  const Row = (props: any) => (
    <Table.Row
      {...props}
      
      onContextMenu={(e: any) => {
        e.preventDefault();
        alert("Context menu");
      }}
    />
  );
  const CurrencyFormatter = (value: any) => (
    <div style={{ textAlign: 'right', paddingRight: "0.5rem" }}>{value.value ? value.value.toLocaleString("en-US") : 0}</div>
  );
  const CurrencyTypeProvider = (props: any) => (
    <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />
  );

  const NumberType = (value: any) => (
    <div style={{ textAlign: 'right' }}>{value?.value}</div>
  );

  const NumberTypeProvider = (props: any) => (
    <DataTypeProvider formatterComponent={NumberType} {...props} />
  );

  const DateType = (value: any) => (
    <div style={{ textAlign: 'center' }}>{value?.value}</div>
  );

  const DateTypeProvider = (props: any) => (
    <DataTypeProvider formatterComponent={DateType} {...props} />
  );

  const DateTimeType = (value: any) => (
    <div style={{ textAlign: 'center' }}>{value?.value}</div>
  );

  const DateTimeTypeProvider = (props: any) => (
    <DataTypeProvider formatterComponent={DateTimeType} {...props} />
  );

  const ImagesFormatter = (value: any) => (
    <img src={value.value} alt={"img"} style={{ height: 64 }} />
  );
  const ImagesTypeProvider = (props: any) => (
    <DataTypeProvider
      formatterComponent={ImagesFormatter}
      //   editorComponent={BooleanEditor}
      {...props}
    />
  );

  
  const formatlessSummaryTypes = ["sum"];

  const [totalSummaryItems, settotalSummaryItems] = React.useState<any[]>([
    { columnName: "region", type: "count" },
    { columnName: "amount", type: "max" },
    { columnName: "amount", type: "sum" },
  ]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), props.table_name + '.xlsx');
    });
  };

  return (
    <div>
      <div className="div-list-btn-export">
        {
          props.excel && (
            <button className="btn grid-export" onClick={startExport}><i className="bi bi-file-earmark-excel"></i> Excel</button>
          )
        }
      </div>

      <Paper sx={{ height: props.paperHeight ?? 'auto' }}>
        <Grid
          rows={props.data ? props.data : []}
          columns={props.columns ? props.columns : columns}

          // rootComponent={(props) => <Grid.Root {...props}  />}
        >


          <DragDropProvider />
          <CurrencyTypeProvider for={currencyColumns} />
          <ImagesTypeProvider for={imageColumns} />
          <NumberTypeProvider for={numberColumns} />
          <DateTypeProvider for={dateColumns} />
          <DateTimeTypeProvider for={dateTimeColumns} />
          <FilteringState />
          <PagingState
            defaultCurrentPage={0}
            defaultPageSize={props.defaultPageSize ? props.defaultPageSize : 10}
          />
          <SelectionState
            selection={selection}
            onSelectionChange={handleRowChange}
          />
          {/* <SummaryState totalItems={totalSummaryItems} /> */}
          <SortingState />
          <GroupingState 
          expandedGroups={props.expandedGroups}
          defaultExpandedGroups={["A-Z"]}
          grouping={props.data.length > 0 ? props.defaultGrouping : undefined} />
          <SearchState />

          <IntegratedGrouping />
          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
          <IntegratedSelection />

          {/* <IntegratedSummary /> */}

          {/* <CustomSummary totalValues={getTotalSummaryValues()} /> */}
          <Table  rowComponent={Row} tableComponent={TableComponent} cellComponent={CellComponent} messages={{ noData: "Không có dữ liệu"}} />
          <TableColumnResizing
            columnWidths={defaultColumnWidths}
            onColumnWidthsChange={setDefaultColumnWidths}
            minColumnWidth={55}
          />
          <VirtualTable cellComponent={CellComponent} columnExtensions={defaultColumnWidths} messages={{noData: "Không có dữ liệu"}}/>
          <TableHeaderRow
            showSortingControls={props.showSortPanel}
            showGroupingControls={props.showGroupPanel}
            rowComponent={(headerProps) => <TableHeaderRow.Row {...headerProps} className={props.headerClassName ?? ""} />}
            cellComponent={(params) => <TableHeaderRow.Cell {...params} style={{ border: '0.5px solid rgba(226, 232, 240, 0.7)' }}></TableHeaderRow.Cell>}
          />
          {/* <TableGroupRow /> */}

          {/* <TableSummaryRow /> */}
          {props.showFilterPanel && (
            <TableFilterRow showFilterSelector={true} rowComponent={(rowFilterProps) => <TableFilterRow.Row {...rowFilterProps} className={props.filterClassName ?? ""}  />} />
          )}

          

          <TableSelection
            selectByRowClick
            highlightRow
            showSelectionColumn={props.showSelectBox}
          />
          <TableColumnVisibility
            defaultHiddenColumnNames={defaultHiddenColumnNames}
          />



          {/* <ChooseColumn /> */}
          {/* <TableFixedColumns leftColumns={leftColumns} rightColumns={rightColumns} /> */}

          {(props.showGroupPanel || props.showSearchPanel) && <Toolbar />}

          <TableGroupRow  
            
          />
          {props.showSearchPanel  && <SearchPanel />}

          {props.showGroupPanel && (
            <GroupingPanel
              showSortingControls={props.showSortPanel}
              showGroupingControls={props.showGroupPanel}

            />
          )}
          <PagingPanel pageSizes={pageSizes} messages={{ rowsPerPage: "Dòng mỗi trang" }} />
          {/* <ExportPanel startExport={startExport} /> */}
        </Grid>
        <GridExporter
          ref={exporterRef}
          rows={props.data ? props.data : []}
          columns={columns}
          onSave={onSave}
        />
      </Paper>
    </div>
  );
};

export default DHSGridView;
