import { MenuItem, Select } from "@mui/material";

import React, { PureComponent, useCallback } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  Sector,
} from "recharts";
import { AppSession } from "../../../../app/shared/app-session/app-session";
import {
  CrmChartMini,
  CrmChartMiniDetail,
  CRMClient,
  CustomerInfomationHistoryDetail,
} from "../../../../app/shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../app/shared/service-proxies/config";

import "./Dashboard.css";

let appSession: AppSession;

const Dashboard = () => {
  const crmClient = new CRMClient(appSession, BASE_URL_API);
  const COLORS = [
    "#5c61ff",
    "#b75bf5",
    "#e36b79",
    "#f7ad6d",
    "#82f76d",
    "#389cc9",
    "#fa69ca",
  ];
  const [cityIndex, setCityIndex] = React.useState<number>();
  const [majorIndex, setMajorIndex] = React.useState<number>();
  const [demandIndex, setDemandIndex] = React.useState<number>();
  const [customerResourceIndex, setCustomerResourceIndex] =
    React.useState<number>();
  const [option, setOption] = React.useState<string>("Monthly");
  const [cityData, setCityData] = React.useState<CrmChartMini[]>([]);
  const [majorData, setMajorData] = React.useState<CrmChartMini[]>([]);
  const [demandData, setDemandData] = React.useState<CrmChartMini[]>([]);
  const [customerResourceData, setCustomerResourceData] = React.useState<
    CrmChartMini[]
  >([]);
  const [customterInfomationPieHistory, setCustomterInfomationPieHistory] =
    React.useState<any[]>([]);
  const [customterInfomationLineHistory, setCustomterInfomationLineHistory] =
    React.useState<any>([]);

  const [opacity, setOpacity] = React.useState({
    "1. Thêm mới": 1,
    "2. Đang theo dõi": 1,
    "3. Đã demo": 1,
    "4. Đã gửi báo giá": 1,
    "5. Đã gửi hợp đồng mẫu": 1,
    "6. Đã ký hợp đồng": 1,
    "7. Treo": 1,
    "8. Đóng": 1,
  });
  const RADIAN = Math.PI / 180;

  React.useEffect(() => {
    crmClient
      .crmCityGroupByGet({
        ...new CrmChartMiniDetail(),
        type: option,
      } as CrmChartMiniDetail)
      .then((res) => setCityData(res))
      .catch((err) => console.log(err));
    crmClient
      .crmMajorGroupByGet({
        ...new CrmChartMiniDetail(),
        type: option,
      } as CrmChartMiniDetail)
      .then((res) => setMajorData(res))
      .catch((err) => console.log(err));
    crmClient
      .crmDemandGroupByGet({
        ...new CrmChartMiniDetail(),
        type: option,
      } as CrmChartMiniDetail)
      .then((res) => setDemandData(res))
      .catch((err) => console.log(err));
    crmClient
      .crmCustomerResourceGroupByGet({
        ...new CrmChartMiniDetail(),
        type: option,
      } as CrmChartMiniDetail)
      .then((res) => setCustomerResourceData(res))
      .catch((err) => console.log(err));
    crmClient
      .crmCustomerStatusGroupByGet({
        ...new CustomerInfomationHistoryDetail(),
        type: option,
      } as CustomerInfomationHistoryDetail)
      .then((res) => {
        setCustomterInfomationPieHistory(res);
      })
      .catch((err) => console.log(err));
    crmClient
      .crmCustomerInfomationHistoryGroupByDateGet({
        ...new CustomerInfomationHistoryDetail(),
        type: option,
      } as CustomerInfomationHistoryDetail)
      .then((res) => {
        // let tempArr = customterInfomationLineHistory.slice();
        // for (var i = 0; i < res.length; i++) {
        //   var obj: any={};
        //   for (const [key, value] of Object.entries(res[i])) {
        //     obj['"' + key + '"'] = value;
        //     console.log(`${key}: ${value}`);
        //   }
        //   tempArr.push(obj);
        // }
        setCustomterInfomationLineHistory(res);
      })
      .catch((err) => console.log(err));
  }, [option]);

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: {
    cx: any;
    cy: any;
    midAngle: any;
    innerRadius: any;
    outerRadius: any;
    percent: any;
    index: any;
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g style={{ display: "block" }}>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <text x={cx} y={cy} dy={24} textAnchor="middle" fill={fill}>
          {(percent * 100).toFixed(0)} %
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text> */}
      </g>
    );
  };

  const handleMouseEnter = React.useCallback(
    (o) => {
      const { dataKey } = o;

      setOpacity({ ...opacity, [dataKey]: 0.5 });
    },
    [opacity, setOpacity]
  );
  const handleMouseLeave = React.useCallback(
    (o) => {
      const { dataKey } = o;
      setOpacity({ ...opacity, [dataKey]: 1 });
    },
    [opacity, setOpacity]
  );
  const onPieEnter = (type: string, _: any, index: number) => {
    switch (type) {
      case "City":
        setCityIndex(index);
        break;
      case "Major":
        setMajorIndex(index);
        break;
      case "Demand":
        setDemandIndex(index);
        break;
      case "CustomerResource":
        setCustomerResourceIndex(index);
        break;
    }
  };
  const onPieLeave = (type: string, _: any, index: number) => {
    switch (type) {
      case "City":
        setCityIndex(undefined);
        break;
      case "Major":
        setMajorIndex(undefined);
        break;
      case "Demand":
        setDemandIndex(undefined);
        break;
      case "CustomerResource":
        setCustomerResourceIndex(undefined);
        break;
    }
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <div className="w-100 p-3">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Dashboard</h2>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={option}
            onChange={(event) => {
              setOption(event.target.value);
            }}
            sx={{ width: "15%" }}
          >
            <MenuItem value={"Weekly"}>Weekly</MenuItem>
            <MenuItem value={"Monthly"}>Monthly</MenuItem>
            <MenuItem value={"Yearly"}>Yearly</MenuItem>
          </Select>
        </div>

        <div className="row">
          <div className="col-lg-3 p-2">
            <div className="dhs-card p-3">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3>Thành phố</h3>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <PieChart width={200} height={200}>
                  <Pie
                    data={cityData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    // label={renderCustomizedLabel}
                    outerRadius={90}
                    innerRadius={65}
                    fill="#8884d8"
                    dataKey="value"
                    activeIndex={cityIndex}
                    activeShape={renderActiveShape}
                    onMouseEnter={(_: any, index: number) =>
                      onPieEnter("City", _, index)
                    }
                    onMouseLeave={(_: any, index: number) =>
                      onPieLeave("City", _, index)
                    }
                  >
                    {cityData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </div>
            </div>
          </div>

          <div className="col-lg-3 p-2">
            <div className="dhs-card p-3">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3>Ngành</h3>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <PieChart width={200} height={200}>
                  <Pie
                    data={majorData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    // label={renderCustomizedLabel}
                    outerRadius={90}
                    innerRadius={65}
                    fill="#8884d8"
                    dataKey="value"
                    activeIndex={majorIndex}
                    activeShape={renderActiveShape}
                    onMouseEnter={(_: any, index: number) =>
                      onPieEnter("Major", _, index)
                    }
                    onMouseLeave={(_: any, index: number) =>
                      onPieLeave("Major", _, index)
                    }
                  >
                    {majorData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </div>
            </div>
          </div>

          <div className="col-lg-3 p-2">
            <div className="dhs-card p-3">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3>Nhu cầu</h3>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <PieChart width={200} height={200}>
                  <Pie
                    data={demandData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    // label={renderCustomizedLabel}
                    outerRadius={90}
                    innerRadius={65}
                    fill="#8884d8"
                    dataKey="value"
                    activeIndex={demandIndex}
                    activeShape={renderActiveShape}
                    onMouseEnter={(_: any, index: number) =>
                      onPieEnter("Demand", _, index)
                    }
                    onMouseLeave={(_: any, index: number) =>
                      onPieLeave("Demand", _, index)
                    }
                  >
                    {demandData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </div>
            </div>
          </div>

          <div className="col-lg-3 p-2">
            <div className="dhs-card p-3">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3>Kênh tiếp nhận</h3>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <PieChart width={200} height={200}>
                  <Pie
                    data={customerResourceData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    // label={renderCustomizedLabel}
                    outerRadius={90}
                    innerRadius={65}
                    fill="#8884d8"
                    dataKey="value"
                    activeIndex={customerResourceIndex}
                    activeShape={renderActiveShape}
                    onMouseEnter={(_: any, index: number) =>
                      onPieEnter("CustomerResource", _, index)
                    }
                    onMouseLeave={(_: any, index: number) =>
                      onPieLeave("CustomerResource", _, index)
                    }
                  >
                    {customerResourceData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-lg-6 p-2">
            <div className="dhs-card p-3">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3>Khách hàng</h3>
                </div>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={10}
                  onChange={() => {}}
                  sx={{ width: "35%" }}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </div>
              <LineChart
                width={500}
                height={300}
                data={data2}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />
                <Line
                  type="monotone"
                  dataKey="pv"
                  strokeOpacity={opacity.pv}
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey="Thêm mới"
                  strokeOpacity={opacity["Thêm mới"]}
                  stroke="#82ca9d"
                />
                <Line
                  type="monotone"
                  dataKey="iv"
                  strokeOpacity={opacity.iv}
                  stroke="#82ca9d"
                />
              </LineChart>
            </div>
          </div> */}
          <div className="col-lg-6 p-2">
            <div className="dhs-card p-3">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3>Tiến độ</h3>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <LineChart
                  width={500}
                  height={300}
                  data={customterInfomationLineHistory}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip isAnimationActive={false} />
                  <Legend
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  />
                  <Line
                    type="monotone"
                    dataKey="1. Thêm mới"
                    strokeOpacity={opacity["1. Thêm mới"]}
                    stroke={COLORS[0]}
                    isAnimationActive={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="2. Đang theo dõi"
                    strokeOpacity={opacity["2. Đang theo dõi"]}
                    stroke={COLORS[1]}
                    isAnimationActive={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="3. Đã demo"
                    strokeOpacity={opacity["3. Đã demo"]}
                    stroke={COLORS[2]}
                    isAnimationActive={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="4. Đã gửi báo giá"
                    strokeOpacity={opacity["4. Đã gửi báo giá"]}
                    stroke={COLORS[3]}
                    isAnimationActive={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="5. Đã gửi hợp đồng mẫu"
                    strokeOpacity={opacity["5. Đã gửi hợp đồng mẫu"]}
                    stroke={COLORS[4]}
                    isAnimationActive={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="6. Đã ký hợp đồng"
                    strokeOpacity={opacity["6. Đã ký hợp đồng"]}
                    stroke={COLORS[5]}
                    isAnimationActive={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="7. Treo"
                    strokeOpacity={opacity["7. Treo"]}
                    stroke={COLORS[6]}
                    isAnimationActive={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="8. Đóng"
                    strokeOpacity={opacity["8. Đóng"]}
                    stroke={COLORS[7]}
                    isAnimationActive={false}
                  />
                </LineChart>
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-2">
            <div className="dhs-card p-3">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3>Tình trạng</h3>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <PieChart width={500} height={300}>
                  <Pie
                    // isAnimationActive={false}
                    data={customterInfomationPieHistory}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {customterInfomationPieHistory?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ResponsiveContainer>
  );
};

export default Dashboard;
