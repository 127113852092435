import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    HomeClient,
    HomePageClient,
    RoleRightWebModel,
    SiRoleInfoClient,
    RoleInfo,
    ParaObjRoleGet,
    SysActionType,

} from '../../../app/shared/service-proxies/api-shared';
import BASE_URL_API from '../../../app/shared/service-proxies/config';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import notification from "../../../common/notification/notification";

// import UserInfoGroupAction from '/../../../../../app/quan-ly-chung-cu/actions/RoleInfo-Action/UserInfoGroupAction';
import { AppSession } from '../../shared/app-session/app-session';
import { IReducer } from '../../../components/layouts';
import { getRoles } from '../../../redux/actions/RoleActions';
import RoleInfoAction from './action/RoleInfoAction';
import BlockUI from '../../../components/Block-UI/Block-ui';
import { components } from '../../../common/ComponentsConfig/components';
import { httpPost } from '../../../common/httpService';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../common/Interfaces/StatusMessage';
import DHSBreadcrumb from '../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import DHSToolbarRole from '../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import { ACTIONS } from '../../../common/enums/DHSToolbarRoleAction';
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DataGrid from 'devextreme-react/data-grid';
import { EventInfo } from 'devextreme/events';
import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import { getDataRowSelected, getIndexRowSelected } from '../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

var appSession: AppSession;



const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

interface RoleInfoId extends RoleInfo {
    id?: string
}

const roleMenu: string = 'CC';
const status_message = ['success', 'error']


const RoleInfoComponent: React.FC<{}> = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { path } = useRouteMatch();
    const location = useLocation();
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    // const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<RoleInfoId[]>([]);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const siRoleInfoClient = new SiRoleInfoClient(appSession, BASE_URL_API);

    const [actCx, setActCx] = useState<IAction>(defaultAction);
   

    // 

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
    // #region filter_bar


    // #endregion

    // #region get_menu
    useEffect(() => {
        setLoading(true)
        homeClient.roleRightWebGetAll({
            ...new RoleRightWebModel(),
            menuid: menu?.menuid,
            rolename: roleMenu,
            username: 'DHSOFT',
        } as RoleRightWebModel)
            .then((res) => {
                dispatch(getRoles(res));
                setRoles(res);
                setLoading(false);
            })

    }, [menu?.component])

    // #endregion

    //#region catch_history
    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            // Tác vụ cần thực hiện khi bạn quay lại component trước đó
            setSelectedItemKeys(undefined);
        });

        return () => {
            unlisten(); // Hủy lắng nghe khi component bị hủy (unmounted)
        };
    }, [history]);

    //#endregion


    // #region hanlde_action
    React.useEffect(() => {
        setLoading(true);

        const body = {
            "ma_cty": SessionLogin.ma_cty,
        }

        httpPost<RoleInfoId[]>("api/SiRoleInfo/GetListRoleInfo", body)
            .then(response => {
                const data = response.data;
                data.map(item => {
                    item.id = item.rolename;
                });
                setDataTable(response.data)

                setLoading(false)
            }).catch(error => {

            })

        setSelectedItemKeys(undefined);

    }, [reload]);


    const handleClickAction = async (action: SysActionType) => {

        switch (action.action_code?.toUpperCase()) {
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: action?.action_code?.toUpperCase() ?? "",
                    // payload: selectedItemKeys?.rolename ?? null
                })
                break;
            case ACTIONS.EDIT:
            case ACTIONS.VIEW:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<RoleInfo>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: action?.action_code?.toUpperCase() ?? "",
                                payload: dataSelected?.rolename ?? null
                            })
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<RoleInfo>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            if (window.confirm("Bạn có chắc chắn muốn xóa?")) {
                                setLoading(true)
                                siRoleInfoClient.deleteCommand22({
                                    ...new ParaObjRoleGet(),
                                    ma_cty: SessionLogin.ma_cty,
                                    rolename: dataSelected?.rolename ?? null,
                                } as ParaObjRoleGet)
                                    .then((res: any) => {
                                        setLoading(false)
                                        notification(status_message[res ? 0 : 1], STATUS_MESSAGE[`DELETE_${res ? 0 : 1}`]);
                                        setReload(!reload)
                                    });
                            }
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.PERMISSION:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<RoleInfo>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            history.push(`/admin/role/permission/${selectedItemKeys?.rolename}?fullname=${selectedItemKeys?.fullname}`);
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
        }

    }

    // #endregion

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                console.log(keys);

                const index = e.component.getRowIndexByKey(keys[0]);

                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
            });
        }
    };

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };
    return (

        <Switch>
            <Route path={path} exact>
                <div className='container-fluid'>

                    {
                        actCx.open && (
                            <RoleInfoAction
                                open={true}
                                onClose={function (): void {
                                    setActCx(defaultAction);
                                }}
                                action={actCx.type}
                                itemSelected={actCx.payload}
                                onEditSuccess={function (is_success: boolean): void {
                                    setReload(pre => !pre)
                                }} >
                            </RoleInfoAction>
                        )
                    }

                    <div
                        className="d-flex title-color"
                        style={{
                            height: 30,
                            color: "rgb(0 78 255 / 88%)",
                            marginTop: 15,
                            marginBottom: 15,
                            fontSize: 22,
                            fontWeight: 550,
                        }}
                    >
                        <DHSBreadcrumb
                            location={location}
                        />
                    </div>

                    <DHSToolbarRole
                        id={' '}
                        menu={menu}
                        customMenu={[]}
                        onClickAction={handleClickAction}
                    />

                    <DHSDataGridEdit
                        data={dataTable}
                        table_name={menu?.component}
                        key="rolename"
                        mode="popup"
                        dataGridRef={dataGridRef}
                        allowEdit={false}
                        pageSize={menu?.page_size}
                        isPagination={menu?.is_pagination}
                        isMultiple={menu?.is_multiple}
                        groupingAutoExpandAll={false}
                        handlePageChange={handlePageChange}
                        handleSelectionChanged={handleSelectionChanged}
                    // handleExporting={handleExporting}
                    />                   

                    <BlockUI blocking={loading} title={""}></BlockUI>
                </div>
            </Route>
            {roles &&
                roles.map((role, index) => {
                    return (
                        role.link &&
                        role.link.length > 1 &&
                        role.component && (
                            <Route
                                key={index}
                                path={role.is_parameter ? role.link + ":_id" : role.link}
                                component={components[role.component.toLocaleUpperCase()]}
                                exact
                            ></Route>
                        )
                    );
                })}
        </Switch>
    )
}

export default RoleInfoComponent;


