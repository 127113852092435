import React, { useEffect, useState } from 'react'
import { FileManagerClient, QLCCDomesticWaterCycleModel, QLCCDomesticWaterModel } from '../../../../../../shared/service-proxies/api-shared'
import { UseContextUpdateDomesticWaterMoreThanOneMeterDataSourceGet, UseContextUpdateDomesticWaterMoreThanOneMeterDataSourceSet } from '../context/update-domestic-water-more-than-meter-context'
import DHSUploadImage from '../../../../../../../components/DHS/DHS-Upload-Image/DHSUploadImage'
import DHSUploadMultipleImage from '../../../../../../../components/DHSUtilComponent/DHSUploadMultipleImage/DHSUploadMultipleImage'
import axios from 'axios'
import BASE_URL_API from '../../../../../../shared/service-proxies/config'
import { Notification } from '../../../../../../../components/layouts'
import { findIndex } from 'lodash'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DHSLoadPanel from '../../../../../../../components/DHSComponents/dialogs-and-notifications/DHSLoadPanel/DHSLoadPanel'
import { AppSession } from '../../../../../../shared/app-session/app-session'

interface IProps {
    domesWaterCycleDefault: QLCCDomesticWaterCycleModel
}
var appSession: AppSession;

const UpdateDomesticWaterMoreThanMeterUpdateFile = (props: IProps) => {
    const { domesWaterCycleDefault } = props;
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const [files, setFiles] = useState<string[]>([])
    const fileManagerClient = new FileManagerClient(appSession, BASE_URL_API)

    const dataSource = UseContextUpdateDomesticWaterMoreThanOneMeterDataSourceGet();
    const setDataSource = UseContextUpdateDomesticWaterMoreThanOneMeterDataSourceSet();

    const toBase64 = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });

    async function handleAddNewImage(e: any) {
        if (dataSource && !domesWaterCycleDefault.is_block) {
            const { name, files } = e.target;
            console.log({ name, files })
            const formData: FormData = new FormData();

            const options = {
                observe: "response",
                // responseType: "blob",
                headers: {
                    Accept: "application/json",
                    token: SessionLogin.Token
                },
            };

            formData.append("tbName", 'QLCCDomesticWater');
            formData.append("colName", 'attach_files');
            const listFile: string[] = []

            for (const file of files) {
                const data: any = await toBase64(file);
                listFile.push(data)
            }

            setLoading(true)
            fileManagerClient.postFile_v5_for_web(
                'QLCCDomesticWater',
                'attach_files',
                listFile,
            ).then(res => {
                if (res) {
                    const resString = res.join(';')
                    const copyDataSource = [...dataSource];
                    if (copyDataSource.length > 0) {
                        const findIdx = copyDataSource.findIndex(item => item.id === name);
                        if (findIdx >= 0) {
                            const newItem = {
                                ...copyDataSource[findIdx],
                                attach_files: copyDataSource[findIdx].attach_files?.concat(";", resString) || resString
                            } as QLCCDomesticWaterModel;

                            copyDataSource.splice(findIdx, 1, newItem);
                            if (setDataSource) {
                                setDataSource(copyDataSource);
                            }
                        }
                    }
                } else Notification("warning", "Không lấy được hình ảnh");
                setLoading(false);
            });
        }
    }

    async function handleRemoveImage(e: any) {
        if (dataSource && !domesWaterCycleDefault.is_block) {
            const { name, index } = e;
            if (name) {
                const copyDataSource = [...dataSource];
                if (copyDataSource.length > 0) {
                    const findIdx = copyDataSource.findIndex(item => item.id === name);
                    if (findIdx >= 0) {
                        const attach_files: string[] = [];
                        const getItem = copyDataSource[findIdx];
                        if (copyDataSource && getItem?.attach_files !== undefined) {
                            attach_files.push(...getItem.attach_files?.split(';'))
                            attach_files.splice(index, 1);
                        }
                        const newItem = {
                            ...copyDataSource[findIdx],
                            attach_files: attach_files.join(';'),
                        } as QLCCDomesticWaterModel;

                        copyDataSource.splice(findIdx, 1, newItem);
                        if (setDataSource) {
                            setDataSource(copyDataSource);
                        }
                    }
                }
            }
        }
    }

    return (
        <>
            <DHSLoadPanel open={isLoading} />
            {
                dataSource && dataSource.map((item, index) => {
                    const attach_files = item?.attach_files;
                    const parseFile: any = [];
                    if (attach_files) {
                        attach_files.split(';').map(item => {
                            if (item.length > 0) {
                                parseFile.push({
                                    fileUrl: BASE_URL_API + item,
                                    fileName: item.split('/').reverse()[0],
                                    filePath: item,
                                })
                            }
                        })
                    }
                    return <>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                // aria-controls="panel2a-content"
                                // id="panel2a-header"
                                sx={{ backgroundColor: "#2167A3" }}
                            >
                                <Typography sx={{ fontWeight: 600, color: "#fff" }}>{item.meter_name ? item.meter_name : `Đồng hồ nước số ${index + 1}`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <DHSUploadMultipleImage
                                    dataFile={parseFile}
                                    name={`${item?.id}`}
                                    handleAddNewImage={handleAddNewImage}
                                    handleRemoveImage={handleRemoveImage}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </>
                })
            }
        </>
    )
}

export default UpdateDomesticWaterMoreThanMeterUpdateFile