import 'devextreme/dist/css/dx.light.css';

import React, { useEffect } from "react";
import Admin from "./components/layouts/Admin/Admin";
import { Switch, Route, useHistory } from "react-router-dom";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Asset/twitter-bootstrap/bootstrap.min.css";
import "react-tiny-fab/dist/styles.css";
import { useDispatch } from "react-redux";
import BlockUI from "./components/Block-UI/Block-ui";
import MachineStatus from "./components/layouts/Manufacturing/MachineStatus";

import ResidentRegister from './app/website/Register/ResidentRegister';
import { messaging } from './firebase';
import { pushNotification, setIsReadNotification } from './redux/actions/Notification';


// import Dashboard_right from './components/layouts/Dashbroad/Navbar/dashboardRight/Dashboard_right';
declare global {
    interface Window {
        BASE_URL: string;
    }
}



function App() {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (window.location.pathname === "/") {
            history.push("/admin");
        }


    }, []);

    // 


    const [isLoading, setLoading] = React.useState(false);

    //================================ Begin DocumentView

    const handleCallback = (data: any) => {
        setLoading(data);
    };

    useEffect(() => {
        fnonMessage();
    try{
        const channel = new BroadcastChannel("notifications");
        channel.addEventListener("message", (event) => {
          console.log("Receive background: ", event.data);
          dispatch(setIsReadNotification({
                    ...event.data.notification
                }))
        });
    }catch{}

    }, [])
    const fnonMessage= ()=>{
        try{
                messaging.onMessage(payload => {
                console.log("Receive foreground: ", payload);
                dispatch(pushNotification({
                    ...payload.notification
                }))
            })
        }catch(err:any){
            console.error('fnonMessage',err)
        }
        
    }
    //================================ End DocumentView

    return (
        <div className="App" style={{ fontFamily: 'Helvetica' }}>
            <Switch>
                <Route path="/admin/" >
                    <Admin />
                </Route>

                {/* <Route path="/admin/Dashboard_right">
          <Dashboard_right />
        </Route> */}

                <Route path={"/apartment-building/resident/register"}>
                    <ResidentRegister />
                </Route>
                {/* <Route path="/documents">
          <Products />
        </Route>
        <Route path="/doc/PRO-:_productId/FEA-:_featureId">
          <DocumentView callback={handleCallback} />
        </Route>
        <Route path="/doc/PRO-:_productId/FEA-:_featureId"></Route> */}
                <Route
                    key="notfound"
                    path="/MachineStatus"
                    exact
                >
                    <MachineStatus history={history} />
                </Route>
            </Switch>
            <BlockUI blocking={isLoading} title={""}></BlockUI>

        </div>
    );
}

export default App;
