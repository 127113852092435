import React, { useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment';
// Component UI
import { Avatar, Box, Divider, IconButton, ImageListItem, InputBase, Paper, Stack, Tooltip, Typography } from '@mui/material';
// ICON
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

import BASE_URL_API from '../../../../../shared/service-proxies/config';
import { AppSession } from '../../../../../shared/app-session/app-session';
import { QLCCTaskContactModel } from '../../../../../shared/service-proxies/api-shared';
import { QLCCClient } from '../../../../../shared/service-proxies/api-shared';

import { ACTIONS } from '../../../../../../common/enums/DHSToolbarRoleAction';
import { IReducer, Notification } from '../../../../../../components/layouts';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../../../../common/Interfaces/StatusMessage';
import TextBox from 'devextreme-react/text-box';
import { checkTypeFile } from './index2';
import { useSelector } from 'react-redux';

interface IProps {
    task_id: string | undefined,
    action: string;
    open: boolean | undefined
}

var appSession: AppSession;


const TaskForum = (props: IProps) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);

    const [data, setData] = useState<QLCCTaskContactModel[]>([]);
    const [myChat, setMyChat] = useState<QLCCTaskContactModel>();
    const [path, setPath] = useState<string[]>([]);

    const [reload, setReload] = useState<boolean>(false);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    // Nội dung chat
    const onload = async () => {
        if (props.task_id) {
            const resContactGet = await qLCCClient.qLCCTaskContactGet({
                ...new QLCCTaskContactModel(),
                task_id: props.task_id,
                is_read_name: SessionLogin.userName,
                apartment_building_id: apartment_building_id
            } as QLCCTaskContactModel);

            if (resContactGet) {
                setData(resContactGet);
            }
        }
    }

    useEffect(() => {
        onload();
    }, [props.open, reload])

    const onUploadFile = (e: any) => {

        const { name, files } = e.target;
        const formData: FormData = new FormData();

        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };

        for (const file of files) {
            formData.append("MyFile", file);
        }

        // Name Folder save in Server
        formData.append("tbName", 'QLCCTaskCheckList');
        formData.append("colName", 'attach_files');

        axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    if (setPath) {
                        // if (props.singer) {
                        // setPath([res.data[0]?.path]);
                        // } else {
                        setPath([...path, res.data[0]?.path]);
                        // }
                    }
                    // reset lại file input
                    e.target.value = '';
                } else Notification(TYPE_MESSAGE['WARNING'], STATUS_MESSAGE['NETWORK_CORRECTION']);
            });

    }

    const onChangeInput = async (e: any) => {
        const { name, value } = e.target;
        setMyChat({
            ...myChat,
            [name]: value
        } as QLCCTaskContactModel)
    }

    const validate = () => {
        if (path.length > 0 &&
            path) {
            return true;
        }
        else if (myChat?.content &&
            myChat.content.length > 0 &&
            myChat.content !== ''
        ) {
            return true;
        }
        return false;
    }

    const onSentMessage = async () => {
        if (validate()) {
            const newModel = {
                ... new QLCCTaskContactModel(),
                ...myChat,
                task_id: props.task_id,
                cuser: SessionLogin.userName,
                luser: SessionLogin.userName,
                date_add: moment().toDate(),
                fullname: SessionLogin.fullName,
                attach_files: path ? path.join(';') : ""
            } as QLCCTaskContactModel;


            qLCCClient.qLCCTaskContactIns({
                ...newModel,
                action_type: 'SEND',
                apartment_building_id: apartment_building_id
            } as QLCCTaskContactModel)
                .then(res => {
                    // setReload(pre => !pre);
                    if (res.ref_id) {
                        setMyChat({ ...new QLCCTaskContactModel() } as QLCCTaskContactModel);
                        setPath([]);
                    }
                    setData([...data, newModel]);
                })
                .catch(err => {
                    Notification(TYPE_MESSAGE['INFO'], STATUS_MESSAGE['NETWORK_CORRECTION'])
                })
        } else {
            Notification(TYPE_MESSAGE['INFO'], 'Chưa soạn nội dung')
        }
    }

    return (
        <>
            <Typography variant="h6" className="text-start mb-3">Bình luận</Typography>
            <Box
                className="mb-1 p-0"
            >
                <Box
                    // component="form"
                    // elevation={3}
                    // sx={{ p: '0', display: 'flex', alignItems: 'center', mb: '2', backgroundColor: 'rgba(255, 255, 255, 1)'}}
                    className="around-input-base"
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Soạn nội dung ..."
                        // inputProps={{ 'aria-label': 'search google maps' }}
                        name="content"
                        value={myChat?.content ?? ""}
                        onChange={(e) => {
                            onChangeInput({
                                target: {
                                    name: 'content',
                                    value: e.target.value ?? null
                                }
                            })
                        }}

                        onKeyPress={(event: any) => {
                            if (event.key === 'Enter')
                                onSentMessage()
                        }}
                    />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <Tooltip title="Tải tập tin">
                        <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" >
                            <label className="m-0 p-0" htmlFor="file_attach">
                                <AttachFileIcon />
                            </label>
                        </IconButton>
                    </Tooltip>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <Tooltip title="Gửi đi">
                        <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={onSentMessage}>
                            <SendIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Typography variant="subtitle2" className="pt-2" >
                    <input id="file_attach" type="file" accept="*" multiple={true} className="d-none" onChange={onUploadFile} />
                    {/* Danh sách tập tin */}
                    <p className="block-file-attach">
                        {
                            path && path.length > 0 && (<p className=''> Danh sách tệp tin: </p>)
                        }
                        {
                            lstFile(path, props.action, setPath)
                        }
                    </p>
                </Typography>
            </Box>
            <Box
                // elevation={3}
                style={{
                    maxHeight: '600px',
                    overflowY: 'auto',
                    paddingTop: '20px',
                }}
            >
                {
                    data && data.length > 0
                        ?
                        data.map(dataItem => (
                            <span key={dataItem.id}>
                                {
                                    MessageItem(dataItem, props.action, setReload)
                                }
                            </span>
                        ))
                        :
                        <p className="text-center">Trống</p>
                }
            </Box >
        </>
    )
}

export default TaskForum;

const MessageItem = (mess: QLCCTaskContactModel, action: string, setReload: any) => {
    const { cuser, date_add, attach_files, content, fullname, department_name } = mess;
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    let isEditTask = false;
    // let dataEditTask = mess;

    const checkSenderOrDev = (userName: string, title: "avatar") => {
        const myUsername = SessionLogin.userName;
        if (myUsername && userName.toUpperCase() === myUsername.toUpperCase()) {
            switch (title) {
                case 'avatar':
                    return 'avatar-icon-messager-my';
            }
        } else {
            switch (title) {
                case 'avatar':
                    return 'avatar-icon-messager';
            }
        }
    }

    const checkMyChat = (userName: string) => {
        const myUsername = SessionLogin.userName;
        if (myUsername && userName.toUpperCase() === myUsername.toUpperCase()) {
            return true;
        } else {
            return false
        }
    }

    const issetTypFile = (lstPath: string, type: "IMAGE" | "OTHER") => {
        const issetOtherFile = (lstPath: string) => {
            const path: string[] = lstPath ? lstPath.split(";") : [];
            const lstOtherFile = path.filter(item => !checkTypeFile(item));
            return lstOtherFile.length > 0
        }

        const issetImageFile = (lstPath: string) => {
            const path: string[] = lstPath ? lstPath.split(";") : [];
            const lstOtherFile = path.filter(item => checkTypeFile(item));
            return lstOtherFile.length > 0
        }

        switch (type) {
            case "IMAGE":
                return issetImageFile(lstPath);
            case "OTHER":
                return issetOtherFile(lstPath);
            default:
                return false;
        }
    }

    return (
        <>
            <Typography
                variant="body1"
                className="comment-notify-daily mt-3"
            >
                <Typography variant="subtitle2" className="d-flex justify-content-between align-items-center">
                    <Typography variant="subtitle2" className="d-flex align-items-center">
                        <Avatar className={`${checkSenderOrDev(cuser!, 'avatar')}`}>{`${fullname ? fullname[0] : cuser ? cuser[0] : "U"}`}</Avatar>
                        <Typography variant="subtitle2" typography={'span'} className="comment-notify-daily-username">
                            {`${fullname ? fullname : cuser}`}
                        </Typography>
                        <span>
                            {`${department_name ? ' - ' + department_name : ""}`}
                        </span>
                    </Typography>
                    <Typography variant="caption" className="text-center" >{moment(date_add).fromNow()}</Typography>
                </Typography>

                <Typography variant="body2" className="mt-2 px-5">
                    {`${content ? content : ''}`}
                </Typography>

                <Stack spacing={{ xs: 1, sm: 2 }} direction="row" flexWrap="wrap">
                    {
                        lstFileInMessage(attach_files, "IMAGE")
                    }
                </Stack>
                <Typography variant="body2" className={`mt-1 px-2`}>
                    {
                        issetTypFile(attach_files || "", "OTHER")
                        && (
                            <Typography variant="body2" className='px-5'>
                                {
                                    lstFileInMessage(attach_files, 'OTHER_FILE')
                                }
                            </Typography>
                        )
                    }
                </Typography>
                <Typography variant="caption" className="px-5" >
                    <Tooltip title="Thời gian cập nhật lần cuối" arrow>
                        <Typography variant="subtitle1" typography={'span'} className="d-inline" ><small>Cập nhật {moment(date_add).format('DD-MM-YYYY HH:mm')}</small></Typography>
                    </Tooltip>
                </Typography>
            </Typography>
        </>
    )
}

const lstFile = (path: string[], action: string, setPath: any) => {

    const handleRemoveFile = (filePath: string) => {

        if (action !== ACTIONS.VIEW) {
            if (window.confirm("Xác nhận xóa tập tin?")) {
                const pathTemp: string[] = path.filter((file: any) => file !== filePath);
                setPath([...pathTemp]);
            }
        }

    }

    return <>
        {
            path.length > 0
            && path.map((pathItem: string, index: Number) => (
                <span className='d-inline'>
                    <p className="file_item_attach" onClick={() => window.open(BASE_URL_API + pathItem)}>
                        {pathItem.split("/").reverse()[0]}
                    </p>
                    <p className="file_item_attach">
                        <CloseIcon
                            className='cursor-close-around'
                            onClick={() => handleRemoveFile(pathItem)}
                        />
                    </p>
                </span>
            ))
        }
    </>
}

// const lstFileInMessage = (pathS: string | undefined) => {
//     const path: string[] = pathS ? pathS.split(";") : [];
//     const nameFile = (pathUrl: string) => {
//         const file = pathUrl.split("/").reverse()[0];
//         return file;
//     }
//     return <>
//         {
//             path.length > 0
//             && path.map((pathItem: string, index: Number) => (
//                 <Tooltip title={nameFile(pathItem)} arrow placement="right">
//                     <span className='d-inline'>
//                         <p className="file_item_attach_mess" onClick={() => window.open(BASE_URL_API + pathItem)}>
//                             {pathItem.split("/").reverse()[0]}
//                         </p>
//                     </span>
//                 </Tooltip>
//             ))
//         }
//     </>
// }

const lstFileInMessage = (pathS: string | undefined, typeFile: "IMAGE" | "OTHER_FILE") => {

    const path: string[] = pathS ? pathS.split(";") : [];

    const getNameFile = (filePath: string) => {
        return filePath.split("/").reverse()[0];
    }

    const issetFile = (lstFile: string[]) => {
        return lstFile.length > 0;
    }

    if (typeFile === 'IMAGE') {
        const lstImageFile = path.filter(itemFile => checkTypeFile(itemFile));

        return <>
            {
                issetFile(lstImageFile)
                && lstImageFile.map((item) => (
                    <img
                        key={item} onClick={() => window.open(BASE_URL_API + item)}
                        className='m-3 object-fit-cover'
                        src={`${BASE_URL_API + item}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${BASE_URL_API + item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt={getNameFile(item)}
                        loading="lazy"
                        style={{
                            width: '300px'
                        }}
                    />
                ))
            }
        </>
    } else { // file other 
        const lstOtherFile = path.filter(itemFile => !checkTypeFile(itemFile));


        return <>
            {
                issetFile(lstOtherFile)
                && lstOtherFile.map((pathItem: string, index: Number) => (
                    <Tooltip title={pathItem.split("/").reverse()[0]} arrow >
                        <div className='d-flex justify-content-start align-items-center'>
                            <p className="file_item_attach_mess_chat_1" onClick={() => window.open(BASE_URL_API + pathItem)}>
                                {pathItem.split("/").reverse()[0]}
                            </p>

                        </div>
                    </Tooltip>
                ))
            }
        </>
    }
}