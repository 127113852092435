import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Select from "../../../../../components/DHS/DHS_Select";
import BASE_URL_API from "../../../../shared/service-proxies/config";
import { AppSession } from "../../../../shared/app-session/app-session";
import {
  ContactPersonPosition,
  CrmCity,
  CRMClient,
  CrmCustomerInfomationDetail,
  CrmDemand,
  CrmMajor,
  CustomerInfomation,
  CustomerResources,
  CustomerStatus,
  HomeClient,
  ICrmCustomerInfomationDetail,
  ICustomerInfomation,
  RoleRightWebModel,
  UserInfo,
} from "../../../../shared/service-proxies/api-shared";

import "./CustomerInfomation.css";
import CurrencyInput from "react-currency-input-field";
import DHSDate from "../../../../../components/DHS/DHS-Date/DHSDateComponent";
import Upload from "../../../../../components/DHS/DHS_Upload/Upload";
import notification from "../../../../../common/notification/notification";

interface IProps {
  id?: string;
  action: RoleRightWebModel | undefined;
  nowTime: Date;
  onDestroyAction?: (isDestroy: boolean, goBack?: number) => void;
}

var appSession: AppSession;
const CustomerInfomationAction = (props: IProps) => {
  const crmClient = new CRMClient(appSession, BASE_URL_API);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const [{ _id }, setId] = React.useState(useParams<{ _id: string }>());
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [data, setData] = React.useState<ICustomerInfomation>({
    ...new CustomerInfomation(),
    nguoi_phu_trach: SessionLogin.userName.toUpperCase(),
    ngay_phat_sinh: new Date(),
  });
  const history = useHistory();
  const [back, setBack] = React.useState<number>(-1);
  const [isViewDetail, activeViewDetail] = React.useState<boolean>(false);
  const [customerDetail, setCusomterDetail] =
    React.useState<ICrmCustomerInfomationDetail>({
      ...new CrmCustomerInfomationDetail(),
      time_add: new Date(),
    });
  const [customerDetailView, setCusomterDetailView] = React.useState<
    ICrmCustomerInfomationDetail[]
  >([]);
  const [demandOptions, setDemandOptions] = React.useState<any[]>([]);
  const [cityOptions, setCityOptions] = React.useState<any[]>([]);
  const [gia_tri_bao_gia, setgia_tri_bao_gia] = React.useState<any>(0);
  const [gia_tri_hop_dong, setgia_tri_hop_dong] = React.useState<any>(0);
  const [statusOptions, setStatusOptions] = React.useState<any[]>([]);
  const [contactPersonPositionOptions, setContactPersonPositionOptions] =
    React.useState<any[]>([]);
  const [receptChannelOptions, setReceptChannelOptions] = React.useState<any[]>(
    []
  );
  const [majorOptions, setMajorOptions] = React.useState<any[]>([]);
  const [chargePersonOptions, setChargePersonOptions] = React.useState<any[]>(
    []
  );
  const [trigger, setTrigger] = React.useState<boolean>(false);

  //Set ID and Config
  React.useEffect(() => {
    if (_id) {
      setCusomterDetail({
        ...customerDetail,
        id_master: _id,
        account_id: SessionLogin.userName,
      });
    } else
      setCusomterDetail({
        ...customerDetail,
        account_id: SessionLogin.userName,
      });
  }, [_id]);

  //Toolbar Event
  React.useEffect(() => {
    if (
      props.action &&
      props.action.class_name?.toLocaleUpperCase() === "VIEWDETAIL"
    ) {
      activeViewDetail(true);
    } else if (props.action && !props.action.class_name) {
      history.goBack();
    }

    if (_id) {
      crmClient
        .crmCustomerInfomationGet({
          ...new CustomerInfomation(),
          id: _id,
          username: SessionLogin.userName,
        } as CustomerInfomation)
        .then((response) => {
          setData(response[0]);
          setgia_tri_bao_gia(
            response[0].gia_tri_bao_gia ? response[0].gia_tri_bao_gia : 0
          );
          setgia_tri_hop_dong(
            response[0].gia_tri_hop_dong ? response[0].gia_tri_hop_dong : 0
          );
        });
    }
  }, []);

  React.useEffect(() => {
    if (
      props.action &&
      props.action.class_name?.toLocaleUpperCase() === "SAVE"
    ) {
      try {
        if (_id) {
          crmClient
            .crmCustomerInfomationUpd({
              ...data,
              id: _id,
              nguoi_nhap: SessionLogin.userName,
            } as CustomerInfomation)
            .then((res) => {
              setBack((item) => (item -= 1));
              notification(
                res[0].ret === 0
                  ? "success"
                  : res[0].ret === 1
                    ? "error"
                    : "warning",
                res[0].message
              );
            });
        } else {
          crmClient
            .crmCustomerInfomationIns({
              ...data,
              nguoi_nhap: SessionLogin.userName,
            } as CustomerInfomation)
            .then((res) => {
              setBack((item) => (item -= 1));
              if (res[0].id) {
                setId({ _id: res[0].id });
              }
              notification(
                res[0].ret === 0
                  ? "success"
                  : res[0].ret === 1
                    ? "error"
                    : "warning",
                res[0].message
              );
            });
        }
        if (props.onDestroyAction) props.onDestroyAction(true, back - 1);
      } catch (error) {
        console.log(error);
      }
    }
  }, [props.nowTime]);

  //Get Select Option
  React.useEffect(() => {
    crmClient.crmCustomerStatusGet(new CustomerStatus()).then((response) => {
      setStatusOptions(response);
    });
    crmClient
      .crmContactPersonPositionGet(new ContactPersonPosition())
      .then((response) => {
        setContactPersonPositionOptions(response);
      });
    crmClient
      .customerResourcesGet({
        ...new CustomerResources(),
        ma_cty: SessionLogin.ma_cty,
      } as CustomerResources)
      .then((response) => {
        setReceptChannelOptions(response);
      });
    crmClient
      .crmMajorGet(new CrmMajor())
      .then((response) => setMajorOptions(response));
    homeClient
      .userInfoGet({
        ...new UserInfo(),
        username: SessionLogin.userName,
      } as UserInfo)
      .then((response) => {
        setChargePersonOptions(response);
      });
    crmClient
      .crmDemandGet(new CrmDemand())
      .then((response) => setDemandOptions(response));

    crmClient
      .crmCityGet(new CrmCity())
      .then((response) => setCityOptions(response));
  }, []);

  //Get Customer Info
  React.useEffect(() => {
    try {
      if (_id) {
        let tempInfo = new CrmCustomerInfomationDetail();
        tempInfo.id_master = _id;
        crmClient.customerInfomationDetailGet(tempInfo).then((response) => {
          setCusomterDetailView(response);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [trigger]);

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleChangeCurencyInput = (e: any) => {
    try {
      const { name, value } = e.target;
      let v = value;
      let fraction = 0;
      if (v.toString().endsWith(".")) v += "00";
      if (v.indexOf(".") > 0) {
        var enwith = Number(v.toString().split(".")[1]);
        if (enwith > 9) fraction = 2;
        else fraction = 1;
      }
      setData({
        ...data,
        [name]: parseFloat(v.replaceAll(/[,]/gi, "")).toFixed(fraction),
      });
    } catch { }
  };
  const handleChangeCalendar = (e: any) => {
    setData({ ...data, ngay_phat_sinh: e });
  };
  const handleChangeQuoteCalendar = (e: any) => {
    setData({ ...data, ngay_gui_bao_gia: e });
  };
  const handleChangeContractCalendar = (e: any) => {
    setData({ ...data, ngay_ky_hop_dong: e });
  };
  const handleChangeExportCalendar = (e: any) => {
    setData({ ...data, xhd_ngay_xuat: e })
  }
  const handleChangeInfoInput = (e: any) => {
    const { name, value } = e.target;
    setCusomterDetail({ ...customerDetail, [name]: value });
  };
  const handleChangeInfoCalendar = (e: any) => {
    setCusomterDetail({ ...customerDetail, time_add: e });
  };
  const getUrl = (url: string) => {
    setCusomterDetail({ ...customerDetail, attach_files: url });
  };

  const handleSubmit = () => {
    if (
      customerDetail.account_id &&
      customerDetail.notes &&
      customerDetail.time_add
    ) {
      crmClient
        .customerInfomationDetailIns(
          customerDetail as CrmCustomerInfomationDetail
        )
        .then((response) => {
          if (response[0].ret === 0) {
            setTrigger(!trigger);
            alert("Thêm thành công");
          } else alert("Thêm thất bại");
        });
    } else {
      alert("Vui lòng nhập đủ thông tin");
    }
  };

  return (
    <>
      <div className="action-page">
        <div className="containter-fluid d-flex row">
          <div className="col-lg-9">
            <div className="row">
              <div className="panel panel-primary col-lg-12">
                <div className="panel-body">
                  <h3 className="text-on-pannel text-primary">
                    <strong className="text-uppercase">
                      {" "}
                      <a href="javascript:$('.div-customer').css('display')!='none'?$('.div-customer').hide('slow'):$('.div-customer').show('slow');">
                        Khách hàng
                      </a>{" "}
                    </strong>
                  </h3>
                  <div
                    className="row div-customer"
                    style={{ paddingTop: "10px" }}
                  >
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">Mã khách hàng</label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="ma_kh"
                          onChange={handleChangeInput}
                          value={data?.ma_kh ? data.ma_kh : ""}
                          disabled={true}
                          title={data.ma_kh ? data.ma_kh : ""}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">Tên công ty*</label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="ten_cty"
                          onChange={handleChangeInput}
                          value={data.ten_cty}
                          title={data.ten_cty}
                          disabled={isViewDetail}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <Select
                          label="Tỉnh, thành phố"
                          options={cityOptions}
                          value={data.thanh_pho}
                          disabled={isViewDetail}
                          onChange={handleChangeInput}
                          fieldValue={"id"}
                          fieldDisplay={"city_name"}
                          name="thanh_pho"
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">Địa chỉ</label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="dia_chi_cty"
                          onChange={handleChangeInput}
                          value={data.dia_chi_cty}
                          disabled={isViewDetail}
                          title={data.dia_chi_cty}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">
                          Điện thoại công ty
                        </label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="dien_thoai_cty"
                          onChange={handleChangeInput}
                          value={data.dien_thoai_cty}
                          disabled={isViewDetail}
                          title={data.dien_thoai_cty}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">Email công ty</label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="email_cty"
                          onChange={handleChangeInput}
                          value={data.email_cty}
                          disabled={isViewDetail}
                          title={data.email_cty}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">Fax</label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="fax"
                          onChange={handleChangeInput}
                          value={data.fax}
                          disabled={isViewDetail}
                          title={data.fax}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">ext.</label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="ext"
                          onChange={handleChangeInput}
                          value={data.ext}
                          disabled={isViewDetail}
                          title={data.ext}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">Website</label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="website"
                          onChange={handleChangeInput}
                          value={data.website}
                          disabled={isViewDetail}
                          title={data.website}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <Select
                          label="Ngành"
                          value={data.nganh}
                          options={majorOptions}
                          onChange={handleChangeInput}
                          disabled={isViewDetail}
                          fieldValue={"id"}
                          fieldDisplay={"name"}
                          name="nganh"
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">Người sở hữu</label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="nguoi_so_huu"
                          onChange={handleChangeInput}
                          value={data.nguoi_so_huu}
                          disabled={isViewDetail}
                          title={data.nguoi_so_huu}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12"></div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">Người liên hệ*</label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="nguoi_lien_he"
                          onChange={handleChangeInput}
                          value={data.nguoi_lien_he}
                          disabled={isViewDetail}
                          title={data.nguoi_lien_he}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <Select
                          label="Chức vụ"
                          value={data.chuc_vu}
                          options={contactPersonPositionOptions}
                          onChange={handleChangeInput}
                          disabled={isViewDetail}
                          fieldValue={"id"}
                          fieldDisplay={"name"}
                          name="chuc_vu"
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">Di động cá nhân</label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="dien_thoai_ca_nhan"
                          onChange={handleChangeInput}
                          value={data.dien_thoai_ca_nhan}
                          disabled={isViewDetail}
                          title={data.dien_thoai_ca_nhan}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">Email cá nhân</label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="email_ca_nhan"
                          onChange={handleChangeInput}
                          value={data.email_ca_nhan}
                          disabled={isViewDetail}
                          title={data.email_ca_nhan}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel panel-primary col-lg-12">
                <div className="panel-body">
                  <h3 className="text-on-pannel text-primary">
                    <strong className="text-uppercase">
                      {" "}
                      <a href="javascript:$('.div-sale-update').css('display')!='none'?$('.div-sale-update').hide('slow'):$('.div-sale-update').show('slow');">
                        Sale cập nhật
                      </a>{" "}
                    </strong>
                  </h3>
                  <div
                    className="row div-sale-update"
                    style={{ paddingTop: "10px" }}
                  >
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <Select
                          label="Người phụ trách*"
                          value={data.nguoi_phu_trach}
                          options={chargePersonOptions}
                          onChange={handleChangeInput}
                          disabled={isViewDetail}
                          fieldValue={"username"}
                          fieldDisplay={"fullname"}
                          name="nguoi_phu_trach"
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <Select
                          label="Kênh tiếp nhận"
                          options={receptChannelOptions}
                          value={data.kenh_tiep_nhan}
                          disabled={isViewDetail}
                          onChange={handleChangeInput}
                          fieldValue={"customer_Resource_ID"}
                          fieldDisplay={"customer_Resource_name"}
                          name="kenh_tiep_nhan"
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <Select
                          label="Tình trạng"
                          options={statusOptions}
                          value={data.tinh_trang}
                          multiple={true}
                          onChange={handleChangeInput}
                          disabled={isViewDetail}
                          fieldValue={"id"}
                          fieldDisplay={"name"}
                          name="tinh_trang"
                        />{" "}
                      </div>
                    </div>
                    <div className="col-lg-12"></div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <Select
                          label="Nhu cầu Sản phẩm"
                          value={data.loai_nhu_cau}
                          options={demandOptions}
                          onChange={handleChangeInput}
                          disabled={isViewDetail}
                          fieldValue={"id"}
                          fieldDisplay={"demand_name"}
                          name="loai_nhu_cau"
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">Mô tả nhu cầu</label>
                        <textarea
                          className="flex-grow-1 form-control"
                          name="nhu_cau"
                          onChange={handleChangeInput}
                          value={data.nhu_cau}
                          disabled={isViewDetail}
                          title={data.nhu_cau}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">Ghi chú</label>
                        <textarea
                          className="flex-grow-1 form-control"
                          name="ghi_chu"
                          onChange={handleChangeInput}
                          value={data.ghi_chu}
                          disabled={isViewDetail}
                          title={data.ghi_chu}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12"></div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">
                          Chiến dịch Marketing
                        </label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="chien_dich_mkt"
                          onChange={handleChangeInput}
                          value={data.chien_dich_mkt}
                          disabled={isViewDetail}
                          title={data.chien_dich_mkt}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <DHSDate
                          label="Ngày phát sinh"
                          className="flex-grow-1 form-control"
                          type="text"
                          name="ngay_phat_sinh"
                          onChange={handleChangeCalendar}
                          value={data.ngay_phat_sinh?.toString()}
                          disabled={isViewDetail}
                          id={data.id ? data.id : ''} />
                      </div>
                    </div>

                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">Telesale</label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="telesale"
                          onChange={handleChangeInput}
                          value={data.telesale}
                          disabled={isViewDetail}
                          title={data.telesale}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel panel-primary col-lg-12">
                <div className="panel-body">
                  <h3 className="text-on-pannel text-primary">
                    <strong className="text-uppercase">
                      <a href="javascript:$('.div-contract').css('display')!='none'?$('.div-contract').hide('slow'):$('.div-contract').show('slow');">
                        Hợp đồng
                      </a>
                    </strong>
                  </h3>
                  <div
                    className="row div-contract"
                    style={{ paddingTop: "10px" }}
                  >
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">Số báo giá</label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="so_bao_gia"
                          onChange={handleChangeInput}
                          value={data.so_bao_gia}
                          disabled={isViewDetail}
                          title={data.so_bao_gia}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">
                          Giá trị báo giá (VND)
                        </label>
                        <CurrencyInput
                          style={{ textAlign: "right" }}
                          className="flex-grow-1 form-control"
                          // intlConfig={{ locale: 'en-US', currency: 'GBP' }}
                          name="gia_tri_bao_gia"
                          data-number-stepfactor="100"
                          value={
                            data.gia_tri_bao_gia
                              ? data.gia_tri_bao_gia
                              : undefined
                          }
                          // placeholder="VND"
                          onChange={handleChangeCurencyInput}
                          // onBlur={handleOnBlur}
                          allowDecimals
                          decimalsLimit={2}
                          disabled={isViewDetail}
                          decimalSeparator="."
                          groupSeparator=","
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <DHSDate
                          label="Ngày gửi báo giá"
                          className="flex-grow-1 form-control"
                          type="text"
                          name="ngay_gui_bao_gia"
                          onChange={handleChangeQuoteCalendar}
                          value={data.ngay_gui_bao_gia?.toString()}
                          disabled={isViewDetail}
                          id={data.id ? data.id : ''}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12"></div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">Số hợp đồng</label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="so_hop_dong"
                          onChange={handleChangeInput}
                          value={data.so_hop_dong}
                          disabled={isViewDetail}
                          title={data.so_hop_dong}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">
                          Giá trị hợp đồng (VND)
                        </label>
                        <CurrencyInput
                          style={{ textAlign: "right" }}
                          className="flex-grow-1 form-control"
                          // prefix={"VND "}
                          name="gia_tri_hop_dong"
                          data-number-stepfactor="100"
                          value={
                            data.gia_tri_hop_dong
                              ? data.gia_tri_hop_dong
                              : undefined
                          }
                          // placeholder="VND"
                          onChange={handleChangeCurencyInput}
                          // onBlur={handleOnBlur}
                          allowDecimals
                          disableAbbreviations
                          disabled={isViewDetail}
                          decimalSeparator="."
                          groupSeparator=","
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <DHSDate
                          label="Ngày ký hợp đồng"
                          className="flex-grow-1 form-control"
                          type="text"
                          name="ngay_ky_hop_dong"
                          onChange={handleChangeContractCalendar}
                          value={data.ngay_ky_hop_dong?.toString()}
                          disabled={isViewDetail}
                          id={data.id ? data.id : ''}
                        />
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div className="panel panel-primary col-lg-12">
                <div className="panel-body">
                  <h3 className="text-on-pannel text-primary">
                    <strong className="text-uppercase">
                      <a href="javascript:$('.div-bill').css('display')!='none'?$('.div-bill').hide('slow'):$('.div-bill').show('slow');">
                        Thông tin xuất hóa đơn
                      </a>
                    </strong>
                  </h3>
                  <div
                    className="row div-bill"
                    style={{ paddingTop: "10px" }}
                  >

                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">
                          Tên công ty
                        </label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="xhd_ten_cty"
                          onChange={handleChangeInput}
                          value={data.xhd_ten_cty}
                          disabled={isViewDetail}
                          title={data.xhd_ten_cty}
                        />
                      </div>
                    </div>

                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">Mã số thuế</label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="xhd_mst"
                          onChange={handleChangeInput}
                          value={data.xhd_mst}
                          disabled={isViewDetail}
                          title={data.xhd_mst}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-6"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <label className="label-input">Địa chỉ</label>
                        <input
                          className="flex-grow-1 form-control"
                          type="text"
                          name="xhd_dia_chi"
                          onChange={handleChangeInput}
                          value={data.xhd_dia_chi}
                          disabled={isViewDetail}
                          title={data.xhd_dia_chi}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12"></div>
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <DHSDate
                          label="Ngày xuất"
                          className="flex-grow-1 form-control"
                          type="text"
                          name="xhd_ngay_xuat"
                          onChange={handleChangeExportCalendar}
                          value={data.xhd_ngay_xuat?.toString()}
                          disabled={isViewDetail}
                          id={data.id ? data.id : ''}
                        />
                      </div>
                    </div>{" "}
                    <div
                      className="col-lg-3"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <div className="form-group">
                        <Select
                          label="Trạng thái"
                          value={data.xhd_trang_thai}
                          options={[
                            {
                              key: "Y",
                              value: "Yes",
                            },
                            {
                              key: "N",
                              value: "No",
                            },
                          ]}
                          onChange={handleChangeInput}
                          disabled={isViewDetail}
                          fieldValue={"value"}
                          fieldDisplay={"value"}
                          name="xhd_trang_thai"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3"
            style={{ paddingLeft: "5px", paddingRight: "5px" }}
          >
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="col container activity-container"
                  style={{ overflow: "hidden" }}
                >
                  <div className="form-group" style={{ fontSize: 25 }}>
                    <h3 style={{ textAlign: "center" }}>Hoạt động</h3>
                  </div>
                  <div className="form-group">
                    <DHSDate
                      label="Ngày tương tác"
                      className="flex-grow-1 form-control"
                      type="text"
                      name="time_add"
                      onChange={handleChangeInfoCalendar}
                      value={customerDetail.time_add}
                      disabled={!_id ? true : false}
                      id={data.id ? data.id : ''}
                    />
                  </div>
                  <div className="form-group">
                    <label className="label-input">Ghi chú</label>
                    <textarea
                      className="flex-grow-1 form-control"
                      name="notes"
                      onChange={handleChangeInfoInput}
                      value={customerDetail.notes}
                      disabled={!_id ? true : false}
                      title={customerDetail.notes}
                    />
                  </div>
                  <div className="form-group">
                    <Upload
                      fileExtension="/*"
                      callbackURL={getUrl}
                      haveIcon={true}
                    />
                  </div>
                  <div className="form-group d-flex justify-content-center">
                    <button
                      className="btn btn-primary"
                      onClick={handleSubmit}
                      disabled={!_id ? true : false}
                    >
                      Cập nhật
                    </button>
                  </div>

                  <ul className="container">
                    {(customerDetailView as CrmCustomerInfomationDetail[]).map(
                      (detail) => {
                        return (
                          <li className="border-bottom p-1">
                            <p>
                              <b>{`${detail.account_id} `}</b>
                              {`${detail.notes} vào `}
                              <i>{`${detail.time_add_f}`}</i>
                            </p>
                            {detail.attach_files && (
                              <a
                                href={detail.attach_files}
                                className="d-flex"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="bi bi-paperclip"></i>
                                {
                                  detail.attach_files.split("/")[
                                  detail.attach_files.split("/").length - 1
                                  ]
                                }
                              </a>
                            )}
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerInfomationAction;
