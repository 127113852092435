import React, { useEffect } from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Grid
} from "@mui/material";
import {
    HomeClient,
    QLCCClient,
    QLCCDomesticWaterCycleModel,
    QLCCDomesticWaterModel,
    RoleRightWebModel,
    SoVch05M,
    SoVch05Client,
    QLCCDomesticWaterParam,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import CloseIcon from "@mui/icons-material/Close";
import CTXDatePicker from "../../../../components/CTX/DatePicker";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import { useParams } from "react-router-dom";
import { Notification } from "../../../../components/layouts";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import DateTime2 from "../../../../components/DHS/DHS-DateTime";
import DHSDate from "../../../../components/DHS/DHS-Date/DHSDateComponent";
import DHSDateTime from "../../../../components/DHS/DHS-DateTime/DHSDateTimeComponent";
import DHSDateTimeComponentV2 from "../../../../components/DHS/DHS-DateTime/DHSDateTimeComponentV2";
import moment from "moment";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import DHSUploadImage from "../../../../components/DHS/DHS-Upload-Image/DHSUploadImage";
import DHSNumberBox from "../../../../components/DHSComponents/editors/DHSNumberBox/DHSNumberBox";
import DHSLoadPanel from "../../../../components/DHSComponents/dialogs-and-notifications/DHSLoadPanel/DHSLoadPanel";
import { MESSAGE_TYPE } from "../../../../common/Interfaces/StatusMessage";
import DomesticWaterUpdateMoreThanOneMeter from "./update-domestic-water-meter/update-more-than-one-meter";
import UpdateDomesticWaterOneMeter from "./update-domestic-water-meter/update-one-meter";
import { useWindowSize } from "../../../../common/useWindowWide";


interface IProps {
    open: boolean;
    itemSelected?: any;
    item?: any;
    action?: any;
    domesticWaterCycle: any;
    domesticWaterCyclePresent?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
    domesticWaterCyclePrevious?: any;
    component?: any;
}

interface IDataSourceCustomer {
    dataSource: QLCCDomesticWaterModel[];
    data: QLCCDomesticWaterModel | undefined,
    active: boolean,
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

var appSession: AppSession;
const defaultDataSourceCustom = {
    dataSource: [],
    data: undefined,
    active: false
} as IDataSourceCustomer;


const DomesticWater = (props: IProps) => {
    const convert = ['success', 'error', 'warning']
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const stt_rec = props.item.stt_rec;
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    //  Cycle current
    const [qlccDomesWaterCycleDefault, setDomesticWaterCycleDefault] = React.useState<QLCCDomesticWaterCycleModel>({} as QLCCDomesticWaterCycleModel);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const [dataSource, setDataSource] = React.useState<IDataSourceCustomer>();
    const [dataItem, setDataItem] = React.useState<IDataSourceCustomer>();


    async function onLoadDataSource() {
        setLoading(pre => !pre)
        try {
            /* chu kỳ */
            const res1 = qLCCClient.qLCCDomesticWaterCycleGet({
                ...new QLCCDomesticWaterCycleModel(),
                action_type: 'ENTER-WATER',
                id: props.domesticWaterCycle,
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCDomesticWaterCycleModel);

            /* danh sách phiếu nước sinh hoạt theo chu kì */
            const res2 = qLCCClient.qLCCDomesticWaterGet({
                ... new QLCCDomesticWaterModel(),
                id: props.itemSelected,
                domestic_water_cycle_id: props.domesticWaterCycle,
                action_type: 'BY-DOMESTICWATER',
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCDomesticWaterModel)

            const promiseAll = await Promise.all([res1, res2]); // CALL API TIET KIEM THOI GIAN

            const getItemCycle = promiseAll[0].pop();
            if (getItemCycle) {
                setDomesticWaterCycleDefault(getItemCycle);
            }

            if (promiseAll[1].length > 1) {
                try {
                    const newData = promiseAll[1];
                    setDataSource({
                        dataSource: newData,
                        data: undefined,
                        active: true
                    } as IDataSourceCustomer)
                } catch (ex) {
                    console.warn(ex);
                }
            } else if (promiseAll[1].length === 1) {
                try {
                    const getDomesticWaterCyclePre = promiseAll[1].pop();
                    if (getDomesticWaterCyclePre) {
                        setDataItem({
                            dataSource: [],
                            data: getDomesticWaterCyclePre,
                            active: true,
                        } as IDataSourceCustomer)
                    }
                } catch (ex) {
                    console.warn(ex);
                }
            } else {
                // Không tìm thấy được phiếu nước của một căn hộ, sẽ tạo mới và sau đó thì chẳng có sau đó nữa
                const newDataItem = {
                    ... new QLCCDomesticWaterModel(),
                    previous: 0,
                    latest: 0,
                    loss: 0,
                    consume: 0,
                    consume_total: -1,
                    apartment_building_id: apartment_building_id,
                } as QLCCDomesticWaterModel;
                setDataItem({
                    dataSource: [],
                    data: newDataItem,
                    active: true,
                } as IDataSourceCustomer)
            }
            setLoading(pre => !pre);
        } catch (ex) {
            console.warn(ex);
            alert('Lỗi! GET DOMESTIC WATER CODE 155')
            setLoading(pre => !pre);
        }
    }

    async function onLoad() {
        if (props.open) {
            console.log(props.action)
            if (props.action === "UPDATE" || props.action === "DETAIL") {
                await onLoadDataSource();
            } else {
                alert('Lỗi ACTIONS truyền vào')
            };
        } else {
            setDataSource(defaultDataSourceCustom);
            setDataItem(defaultDataSourceCustom);
        }
    }

    React.useEffect(() => {
        onLoad();
    }, [props.open]);

    function OnSubmitUpdateDomesticWaterOneMeter(dataParams: QLCCDomesticWaterModel) {

        function payStatus(dataParams: QLCCDomesticWaterModel) {
            return dataParams && dataParams.pay_status;
        }

        if (!payStatus(dataParams)) {
            if (typeof (dataParams.previous) === "string" && dataParams.previous === "") {
                Notification("warning", "Chỉ số cũ chưa được nhập");
                return;
            } else if (typeof (dataParams.latest) === "string" && dataParams.latest === "") {
                Notification("warning", "Chỉ số mới chưa được nhập");
                return;
            }
            else if (typeof (dataParams.loss) === "string" && dataParams.loss === "") {
                Notification("warning", "Chỉ số hao hụt chưa được nhập");
                return;
            } else if (Number(dataParams.latest) < 0) {
                Notification("error", "Chỉ số mới đang âm");
                return;
            }
        } else if (Number(dataParams.previous) < 0) {
            Notification("error", "Chỉ số cũ đang âm");
            return;
        } else if (Number(dataParams.latest) < Number(dataParams.previous)) {
            Notification("warning", "Chỉ số cũ phải bé hơn chỉ số mới");
            return;
        }
        setLoading(true);
        qLCCClient
            .qLCCDomesticWaterUpd({
                ...dataParams,
                enter_status: true,
                previous: Number(dataParams.previous),
                latest: Number(dataParams.latest),
                loss: Number(dataParams.loss),
                user_enter: SessionLogin.userName,
                enter_date: moment(dataParams.enter_date).startOf('day').utc(true).toDate(),
                action_type: props.action,
                domestic_water_cycle_id: props.domesticWaterCycle,
                attach_files: dataParams.attach_files || "",
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCDomesticWaterModel)
            .then((res: any) => {
                Notification(convert[res.status], res.message);
                if (res.status === 0) {
                    props.onEditSuccess(true);
                    props.onClose();
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Notification(convert[err.status], err.message);
            });
    }

    async function OnSubmitUpdateDomesticWaterMorThanMeter(dataParams: QLCCDomesticWaterModel[]) {
        if (dataParams) {
            try {
                setLoading(true);
                // Tùy chỉnh data source
                const newDataSource: QLCCDomesticWaterModel[] = [];
                dataParams.map(item => {
                    newDataSource.push({
                        ...item,
                        user_enter: SessionLogin.userName,
                        enter_date: moment(item?.enter_date).startOf('day').utc(true).toDate(),
                        action_type: props.action,
                        domestic_water_cycle_id: props.domesticWaterCycle,
                        ma_cty: SessionLogin.ma_cty,
                        apartment_building_id: apartment_building_id,
                        attach_files: item?.attach_files || "",
                        enter_status: true,
                    } as QLCCDomesticWaterModel)
                })

                const res = await qLCCClient
                    .qLCCDomesticWaterUpd2({
                        apartment_building_id: apartment_building_id,
                        ma_cty: SessionLogin.ma_cty,
                        qLCCDomesticWaters: newDataSource,
                    } as QLCCDomesticWaterParam);
                if (res) {
                    Notification(MESSAGE_TYPE[res.status === undefined ? 1 : res.status], res?.message);
                    if (res.status === 0) {
                        props.onEditSuccess(true);
                        props.onClose();
                    }
                }
            } catch (ex) {
                console.warn('Lỗi API');
            } finally {
                setLoading(false);
            }
        }
    }

    function onCloseWindow() {
        props.onClose();
    }

    const sizeWindow = useWindowSize();

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                fullScreen={sizeWindow.width < 600}
                fullWidth
                TransitionComponent={Transition}
                maxWidth="md"
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            {"Số tiêu thụ nước" +
                                " " +
                                `${props.action === "DETAIL"
                                    ? "[Chi tiết]"
                                    : props.action === "EDIT" || apartment_building_id
                                        ? "[Cập nhật]"
                                        : "[Thêm mới]"
                                }`}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                {dataSource && dataSource.active &&
                    <DomesticWaterUpdateMoreThanOneMeter
                        active={dataSource.active}
                        apartment_building_id={apartment_building_id}
                        dataSource={dataSource ? dataSource.dataSource : []}
                        domesWaterCycleDefault={qlccDomesWaterCycleDefault}
                        onCloseWindow={onCloseWindow}
                        // qLCCClient={qLCCClient}
                        setLoading={() => setLoading(pre => !pre)}
                        onSubmit={(dataSourceParams) => {
                            // console.log(dataSourceParams)
                            OnSubmitUpdateDomesticWaterMorThanMeter(dataSourceParams)
                        }}
                    />
                }
                {
                    dataItem && dataItem.active && <UpdateDomesticWaterOneMeter
                        SessionLogin={SessionLogin}
                        action={props.action}
                        active={dataItem.active}
                        apartment_building_id={apartment_building_id}
                        domesWaterCycleDefault={qlccDomesWaterCycleDefault}
                        onCloseWindow={onCloseWindow}
                        onSubmit={(dataSourceParams) => {
                            // console.log(dataSourceParams)
                            OnSubmitUpdateDomesticWaterOneMeter(dataSourceParams)
                        }}
                        setLoading={() => setLoading(pre => !pre)}
                        domesticWaterCyclePrevious={props.domesticWaterCyclePrevious}
                        dataSource={dataItem.data!}
                        qLCCClient={qLCCClient}
                    />
                }
            </Dialog >
            <DHSLoadPanel open={isLoading} />
        </>
    );
};

export default DomesticWater;
