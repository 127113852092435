import React, { useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment';
// Component UI
import { Avatar, Box, Divider, Grid, IconButton, ImageList, ImageListItem, InputBase, Paper, Tooltip, Typography } from '@mui/material';
// ICON
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

import BASE_URL_API from '../../../../../shared/service-proxies/config';
import { AppSession } from '../../../../../shared/app-session/app-session';
import { QLCCTaskContactModel } from '../../../../../shared/service-proxies/api-shared';
import { QLCCClient } from '../../../../../shared/service-proxies/api-shared';

import { ACTIONS } from '../../../../../../common/enums/DHSToolbarRoleAction';
import { IReducer, Notification } from '../../../../../../components/layouts';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../../../../common/Interfaces/StatusMessage';
import { useSelector } from 'react-redux';

interface IProps {
    task_id: string | undefined,
    action: string;
    open: boolean | undefined
}

var appSession: AppSession;


const TaskChildForumChat = (props: IProps) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);

    const [data, setData] = useState<QLCCTaskContactModel[]>([]);
    const [myChat, setMyChat] = useState<QLCCTaskContactModel>();
    const [path, setPath] = useState<string[]>([]);

    const [reload, setReload] = useState<boolean>(false);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)



    // Nội dung chat
    const onload = async () => {
        if (props.task_id) {
            const resContactGet = await qLCCClient.qLCCTaskContactGet({
                ...new QLCCTaskContactModel(),
                task_id: props.task_id,
                is_read_name: SessionLogin.userName,
                apartment_building_id: apartment_building_id
            } as QLCCTaskContactModel);

            if (resContactGet) {
                setData(resContactGet);
            }
        }
    }

    useEffect(() => {
        onload();
    }, [props.open, reload])

    const onUploadFile = (e: any) => {

        const { name, files } = e.target;
        const formData: FormData = new FormData();

        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };

        for (const file of files) {
            formData.append("MyFile", file);
        }

        // Name Folder save in Server
        formData.append("tbName", 'QLCCTaskCheckList');
        formData.append("colName", 'attach_files');

        axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    if (setPath) {
                        // if (props.singer) {
                        // setPath([res.data[0]?.path]);
                        // } else {
                        setPath([...path, res.data[0]?.path]);
                        // }
                    }
                    // reset lại file input
                    e.target.value = '';
                } else Notification(TYPE_MESSAGE['WARNING'], STATUS_MESSAGE['NETWORK_CORRECTION']);
            });

    }

    const onChangeInput = async (e: any) => {
        const { name, value } = e.target;
        setMyChat({
            ...myChat,
            [name]: value
        } as QLCCTaskContactModel)
    }

    const validate = () => {
        if (path.length > 0 &&
            path) {
            return true;
        }
        else if (myChat?.content &&
            myChat.content.length > 0 &&
            myChat.content !== ''
        ) {
            return true;
        }
        return false;
    }

    const onSentMessage = async () => {
        if (validate()) {
            const newModel = {
                ... new QLCCTaskContactModel(),
                ...myChat,
                task_id: props.task_id,
                cuser: SessionLogin.userName,
                luser: SessionLogin.userName,
                date_add: moment().toDate(),
                fullname: SessionLogin.fullName,
                attach_files: path ? path.join(';') : ""
            } as QLCCTaskContactModel;

            qLCCClient.qLCCTaskContactIns({
                ...newModel,
                action_type: 'SEND',
                apartment_building_id: apartment_building_id
            } as QLCCTaskContactModel)
                .then(res => {
                    // setReload(pre => !pre);
                    if (res.ref_id) {
                        setMyChat({ ...new QLCCTaskContactModel() } as QLCCTaskContactModel);
                        setPath([]);
                    }
                    setData([...data, newModel]);
                })
                .catch(err => {
                    Notification(TYPE_MESSAGE['INFO'], STATUS_MESSAGE['NETWORK_CORRECTION'])
                })
        } else {
            Notification(TYPE_MESSAGE['INFO'], 'Chưa soạn nội dung')
        }
    }




    return (
        <>
            <Box
                // elevation={3}
                className="task-forum-around-chat"
            >
                {
                    data && data.length > 0
                        ?
                        data.map(dataItem => (
                            <span key={dataItem.id}>
                                {
                                    MessageItem(dataItem)
                                }
                            </span>
                        ))
                        :
                        <p className="text-center task-child-forum-content">Bắt đầu tương tác &#9786;</p>
                }
            </Box >
            <Box
                className="mb-1 p-0 pt-2"
            >
                <Box
                    // component="form"
                    // elevation={3}
                    className="around-input-base-child"
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Soạn nội dung ..."
                        // inputProps={{ 'aria-label': 'search google maps' }}
                        name="content"
                        value={myChat?.content ?? ""}
                        onChange={(e) => {
                            onChangeInput({
                                target: {
                                    name: 'content',
                                    value: e.target.value ?? null
                                }
                            })
                        }}

                        onKeyPress={(event: any) => {
                            if (event.key === 'Enter')
                                onSentMessage()
                        }}
                    />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <Tooltip title="Tải tập tin">
                        <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" >
                            <label className="m-0 p-0" htmlFor="file_attach_subtask">
                                <AttachFileIcon />
                            </label>
                        </IconButton>
                    </Tooltip>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <Tooltip title="Gửi đi">
                        <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={onSentMessage}>
                            <SendIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Typography variant="subtitle2" className="pt-2" >
                    <input id="file_attach_subtask" type="file" accept="*" multiple={true} className="d-none" onChange={onUploadFile} />
                    {/* Danh sách tập tin */}
                    <p className="block-file-attach">
                        {
                            path && path.length > 0 && (<p className=''> Danh sách tệp tin: </p>)
                        }
                        {
                            lstFile(path, props.action, setPath)
                        }
                    </p>
                </Typography>
            </Box>
        </>
    )
}

export default TaskChildForumChat;

const MessageItem = (mess: QLCCTaskContactModel) => {
    const { cuser, date_add, attach_files, content, fullname, department_name } = mess;
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");


    const checkSenderOrDev = (userName: string, title: "block" | "username" | "time" | "avatar" | 'content' | "block-text") => {
        const myUsername = SessionLogin.userName;
        if (myUsername && userName.toUpperCase() === myUsername.toUpperCase()) {
            switch (title) {
                case "block":
                    return 'justify-content-end';
                case "block-text":
                    return 'task-my-text'
                case "username":
                    return 'd-none';
                case "time":
                    return 'text-center';
                case 'avatar':
                    return 'd-none';
                case 'content':
                    return 'text-right'
            }
        } else {
            switch (title) {
                case "block":
                    return 'justify-content-start';
                case "username":
                    return 'd-flex align-items-center justify-content-start';
                case "time":
                    return 'text-center';
                case 'content':
                    return 'px-2'
                case "block-text":
                    return 'task-your-text'
            }
        }
    }

    const issetTypFile = (lstPath: string, type: "IMAGE" | "OTHER") => {
        const issetOtherFile = (lstPath: string) => {
            const path: string[] = lstPath ? lstPath.split(";") : [];
            const lstOtherFile = path.filter(item => !checkTypeFile(item));
            return lstOtherFile.length > 0
        }

        const issetImageFile = (lstPath: string) => {
            const path: string[] = lstPath ? lstPath.split(";") : [];
            const lstOtherFile = path.filter(item => checkTypeFile(item));
            return lstOtherFile.length > 0
        }

        switch (type) {
            case "IMAGE":
                return issetImageFile(lstPath);
            case "OTHER":
                return issetOtherFile(lstPath);
            default:
                return false;
        }
    }

    return (
        <>
            <Typography variant="body2" className={`${checkSenderOrDev(cuser!, "time")}`} >
                <Typography variant="caption" ><small>{moment(date_add).format('HH:mm DD-MM-YYYY')}</small></Typography>
            </Typography>
            <Grid container spacing={2} className={`d-flex ${checkSenderOrDev(cuser!, "block")}`}>
                <Grid item xs={10}>
                    <Typography
                        variant="body1"
                    >
                        <Box className={`d-flex ${checkSenderOrDev(cuser!, "block")}`} >
                            <Avatar className={`avatar-icon-messager ${checkSenderOrDev(cuser!, "avatar")}`}>{`${fullname ? fullname[0] : cuser ? cuser[0] : "U"}`}</Avatar>
                            <Box>
                                <Typography variant="subtitle2" className={`${checkSenderOrDev(cuser!, "username")}`}>
                                    <Typography variant="subtitle2" className="d-flex align-items-center" >
                                        <Typography variant="subtitle2" typography={'span'} className="comment-notify-daily-username">
                                            {`${fullname ? fullname : cuser}`}
                                        </Typography>
                                        <span>
                                            {`${department_name ? ' - ' + department_name : ""}`}
                                        </span>
                                    </Typography>
                                </Typography>

                                {/* Danh sách ảnh */}
                                {
                                    lstFileInMessage(attach_files, "IMAGE")
                                }

                                {
                                    content &&
                                    <Typography variant="body2" className={`mt-1 task-forum-child-content px-2 ${checkSenderOrDev(cuser!, "content")} ${checkSenderOrDev(cuser!, "block-text")}`}>
                                        {`${content ? content : ''}`}
                                    </Typography>
                                }
                                <Typography variant="body2" className={`mt-1 px-2 ${checkSenderOrDev(cuser!, "block-text")}`}>
                                    {
                                        issetTypFile(attach_files || "", "OTHER")
                                        && <>
                                            {
                                                lstFileInMessage(attach_files, "OTHER_FILE")
                                            }
                                        </>
                                    }
                                </Typography>

                                {/* <hr /> */}
                            </Box>
                        </Box>
                    </Typography >

                </Grid>
            </Grid >

        </>
    )
}

const lstFile = (path: string[], action: string, setPath: any) => {

    const handleRemoveFile = (filePath: string) => {

        if (action !== ACTIONS.VIEW) {
            if (window.confirm("Xác nhận xóa tập tin?")) {
                const pathTemp: string[] = path.filter((file: any) => file !== filePath);
                setPath([...pathTemp]);
            }
        }

    }

    return <>
        {
            path.length > 0
            && path.map((pathItem: string, index: Number) => (
                <span className='d-inline'>
                    <p className="file_item_attach" onClick={() => window.open(BASE_URL_API + pathItem)}>
                        {pathItem.split("/").reverse()[0]}
                    </p>
                    <p className="file_item_attach">
                        <CloseIcon
                            className='cursor-close-around'
                            onClick={() => handleRemoveFile(pathItem)}
                        />
                    </p>
                </span>
            ))
        }
    </>
}

const lstFileInMessage = (pathS: string | undefined, typeFile: "IMAGE" | "OTHER_FILE") => {

    const path: string[] = pathS ? pathS.split(";") : [];

    const getNameFile = (filePath: string) => {
        return filePath.split("/").reverse()[0];
    }

    const issetFile = (lstFile: string[]) => {
        return lstFile.length > 0;
    }

    if (typeFile === 'IMAGE') {
        const lstImageFile = path.filter(itemFile => checkTypeFile(itemFile));

        return <>
            {
                issetFile(lstImageFile)
                && lstImageFile.map((item) => (
                    <ImageListItem key={item} onClick={() => window.open(BASE_URL_API + item)}>
                        <img
                            className='mb-1 w-100'
                            src={`${BASE_URL_API + item}?w=164&h=164&fit=crop&auto=format`}
                            srcSet={`${BASE_URL_API + item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            alt={getNameFile(item)}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))
            }
        </>
    } else { // file other 
        const lstOtherFile = path.filter(itemFile => !checkTypeFile(itemFile));


        return <>
            {
                issetFile(lstOtherFile)
                && lstOtherFile.map((pathItem: string, index: Number) => (
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className="file_item_attach_mess_chat" >
                            {pathItem.split("/").reverse()[0]}
                        </p>
                        <span className='cursor-pointer'>
                            <ArrowCircleDownIcon onClick={() => window.open(BASE_URL_API + pathItem)} />
                        </span>
                    </div>
                ))
            }
        </>
    }
}

// Type Image File
export const checkTypeFile = (filePath: string) => {

    const getTypeFile = (filePath: string) => {
        const fileName = filePath.split("/").reverse()[0];
        if (fileName && fileName.length > 0) {
            const typeFile = filePath.split(".").reverse()[0];
            return typeFile;
        } else {
            return "";
        }
    }

    const isFileImage = (typeFile: string) => {
        const lstTypeFileImage = ["JPEG", "JPG", "PNG", "GIF"];
        return lstTypeFileImage.includes(typeFile.toLocaleUpperCase());
    }

    const typeFile = getTypeFile(filePath);
    return isFileImage(typeFile);
}

