import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { IReducer } from "../../../../../common/Interfaces/Interfaces";
import DHSToolbar from "../../../../../components/DHS/DHS-Toolbar/DHSToolbar";
import DHSInputText from "../../../../../components/DHS/DHS_InputText/InputText";
import InputText from "../../../../../components/DHS/DHS_InputText/InputText";
import { Notification } from "../../../../../components/layouts";
import DHSGridEdit from "../../../../../components/layouts/Admin/Grid/Grid-edit/Grid-edit";
import GridEdit from "../../../../../components/layouts/Admin/Grid/Grid-edit/Grid-edit";
import { AppSession } from "../../../../shared/app-session/app-session";
import {
  CRMClient,
  CrmCustomerInfomationDetail,
  CrmPayup,
  CustomerInfomation,
  IRoleRightWebModel,
  RoleRightWebModel,
} from "../../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../shared/service-proxies/config";

interface IProps {
  rowSelected?: string | undefined;
  action?: RoleRightWebModel | undefined;
}
let appSession: AppSession;
let dataTemp: any[] | undefined;
const PayupPage = (props: IProps) => {
  const location = useLocation();
  const history = useHistory();
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const role = useSelector((state: IReducer) => state.RoleReducer?.role);
  const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
  const crmClient = new CRMClient(appSession, BASE_URL_API);
  const [action, setAction] = React.useState<IRoleRightWebModel | undefined>(
    role
  );
  const [{ _id }, setId] = React.useState(useParams<{ _id: string }>());
  const [nowTime, setNowTime] = React.useState<Date>(new Date());
  const [data, setData] = React.useState<any[]>([]);
  const [cusomterData, setCusomterData] = React.useState<CustomerInfomation>(
    new CustomerInfomation()
  );
  const [dataChange, setDataChange] = React.useState<any[]>([]);
  const [isChange, setIsChange] = React.useState<boolean>(false);
  const [isLoadData, setIsLoadData] = React.useState<boolean>(false);
  const [selectionid, setSelectionid] = React.useState<any[]>([]);
  const handleData = (data: any[]) => {
    dataTemp = data;
  };
  const onListSelectRow = (data: any[]) => {
    setSelectionid(data);
  };
  const handleClickAction = (action: IRoleRightWebModel, nowTime: Date) => {
    // setAction(action);
    // setNowTime(nowTime);
    switch (action.class_name) {
      case "Save":
        let pr: CustomerInfomation = new CustomerInfomation();
        pr.ma_kh = _id;
        pr.listPayups = dataTemp;
        try {
          pr.listPayups = [];
          var Subs = dataTemp as CrmPayup[];
          if (Subs && Subs.length > 0) {
            for (var i = 0; i < Subs.length; i++) {
              let newrow: any = new CrmPayup();
              for (const [key, value] of Object.entries(Subs[i])) {
                newrow[key] = value;
              }
              newrow["ma_kh"] = _id;
              if (newrow["pay_date"])
                newrow["pay_date_f"] =
                  newrow["pay_date"].getFullYear() +
                  "-" +
                  (newrow["pay_date"].getMonth() + 1) +
                  "-" +
                  newrow["pay_date"].getDate();
              newrow = CrmPayup.fromJS(newrow);
              pr.listPayups.push(newrow);
            }
          }
        } catch {}

        crmClient?.crmPayupUpd(pr).then((res) => {
          if (res.status === 0) {
            Notification("success", "Cập nhật thành công");
            window.location.reload();
          } else Notification("error", res.message);
        });
        break;
      case "Add":
        let newrow: CrmPayup = new CrmPayup();
        newrow.id = "-" + Math.random();
        let t: any[] = [...data, newrow];
        setData(t);
        break;
      case "Delete":
        let datafilter = data.filter(
          (dt) =>
            selectionid.filter((idselected) => idselected == dt.id).length == 0
        );
        let dataTempfilter = dataTemp?.filter(
          (dt) =>
            selectionid.filter((idselected) => idselected == dt.id).length == 0
        );
        dataTemp = dataTempfilter;
        setData(datafilter);
        break;
      default:
        break;
    }
  };
  const handleDestroyAction = (isDestroy: boolean) => {
    if (isDestroy) setAction(undefined);
  };

  const [title, setTitle] = React.useState<string>("Đang tải nội dung");
  useEffect(() => {
    switch (action?.class_name?.toLocaleUpperCase()) {
      case "ADD":
        setTitle("Thêm khách hàng");
        break;
      case "EDIT":
        setTitle("Sửa khách hàng");
        break;
      case "VIEWDETAIL":
        setTitle("Chi tiết khách hàng");
        break;
      case "PAYUP":
        setTitle(`Khách hàng thanh toán`);
        break;
    }
  }, [action]);
  React.useEffect(() => {
    LoadData();
    crmClient
      .crmCustomerInfomationGet({
        ...new CustomerInfomation(),
        id: _id,
        username: SessionLogin.userName,
      } as CustomerInfomation)
      .then((response) => {
        setCusomterData(response[0]);
      });
  }, []);
  const LoadData = () => {
    let pr: CrmPayup = new CrmPayup();
    pr.ma_kh = _id;
    crmClient?.crmPayupGet(pr).then((res) => {
      setData(res ? res : []);
      dataTemp = res;
      setIsLoadData(true);
    });
  };
  return (
    <>
      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          marginTop: 15,
          marginBottom: 15,
          fontSize: 22,
          fontWeight: 550,
        }}
      >
        <i
          className="bi bi-arrow-left"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.goBack();
          }}
        ></i>
        &nbsp;&nbsp;
        {title}
      </div>

      <DHSToolbar
        list={
          roles
            ? roles.filter(
                (item) =>
                  item.on_form?.toLocaleUpperCase() === "EDIT" &&
                  item?.component === "Payup"
              )
            : []
        }
        menuid={""}
        id={""}
        current_form={""}
        onClickAction={handleClickAction}
      />
      <div className="row" style={{ backgroundColor: "white" }}>
        <div className="d-flex p-4">
          <label className="label-imput">Mã khách hàng:</label>
          <a
            href={`/admin/category-customer/portal-sale-edit${cusomterData.id}`}
          >
            &nbsp;&nbsp;{cusomterData.ma_kh}
          </a>
          <br />
        </div>
        <div className="d-flex p-4">
          <label className="label-imput">Tên khách hàng:</label>&nbsp;&nbsp;
          {cusomterData.ten_cty}
          <br />
        </div>
        <div className="d-flex p-4">
          <label className="label-imput">Địa chỉ:</label>&nbsp;&nbsp;
          {cusomterData.dia_chi_cty}
          <br />
        </div>
      </div>
      {isLoadData ? (
        <DHSGridEdit
          itemClicked={function (): void {
            throw new Error("Function not implemented.");
          }}
          onSelectRow={function (v: any): void {
            throw new Error("Function not implemented.");
          }}
          table_name={"crmPayup"}
          onChangeData={handleData}
          onListSelectRow={onListSelectRow}
          show_form_list={false}
          data={data}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default PayupPage;
