import React from "react";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../../common/Interfaces/Interfaces";
import DHSToolbar from "../../../../../components/DHS/DHS-Toolbar/DHSToolbar";
import {
  IRoleRightWebModel,
  RoleRightWebModel,
  CRMClient,
} from "../../../../shared/service-proxies/api-shared";
import MajorAction from "./MajorAction";
import BASE_URL_API from "../../../../shared/service-proxies/config";
import { AppSession } from "../../../../shared/app-session/app-session";
import { useHistory } from "react-router-dom";

interface IProps {
  listToolbar?: RoleRightWebModel[];
  rowSelected?: string | undefined;
}

let appSession: AppSession;
const MajorPage = (props: IProps) => {
  const history = useHistory();

  const role = useSelector((state: IReducer) => state.RoleReducer?.role);
  const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
  const [action, setAction] = React.useState<RoleRightWebModel | undefined>(
    role
  );
  const [nowTime, setNowTime] = React.useState<Date>(new Date());
  const [goBack, setGoBack] = React.useState<number>(-1);
  const handleClickAction = (action: RoleRightWebModel, nowTime: Date) => {
    setAction(action);
    setNowTime(nowTime);
  };
  const handleDestroyAction = (isDestroy: boolean, goBack?: number) => {
    if (isDestroy) setAction(undefined);
    if (goBack) setGoBack(goBack);
  };
  const [title, setTitle] = React.useState<string>("Đang tải nội dung");
  
  React.useEffect(() => {
    switch (action?.class_name?.toLocaleUpperCase()) {
      case "ADD":
        setTitle("Thêm ngành");
        break;
      case "EDIT":
        setTitle("Sửa ngành");
        break;
    }
  }, [action]);
  return (
    <>
      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          marginTop: 15, 
          marginBottom: 15,
          fontSize: 22,
          fontWeight: 550,
        }}
      >
        {" "}
        <i
          className="bi bi-arrow-left"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.go(goBack);
          }}
        ></i>
        &nbsp;&nbsp;
        {title}
      </div>{" "}
      <DHSToolbar
        list={
          roles
            ? roles.filter(
                (item) => item.on_form?.toLocaleUpperCase() === "EDIT" &&
                item?.component === "Major"
              )
            : []
        }
        menuid={""}
        id={""}
        current_form={""}
        onClickAction={handleClickAction}
      />
      <MajorAction
        id={props.rowSelected}
        action={action as RoleRightWebModel}
        nowTime={nowTime}
        onDestroyAction={handleDestroyAction}
      />
    </>
  );
};

export default MajorPage;
