import React, { Component } from "react";
import {
  Chart,
  CommonSeriesSettings,
  Legend,
  SeriesTemplate,
  Animation,
  ArgumentAxis,
  Title,
  ZoomAndPan,
  ValueAxis,
  ScrollBar,
  Aggregation,
  TickInterval,
  Label,
  Tooltip,
} from "devextreme-react/chart";
import RangeSelector, {
  Size,
  Margin,
  Scale,
  // Chart as RsChart,
  // SeriesTemplate as RsSeriesTemplate,
  // CommonSeriesSettings as RsCommonSeriesSettings,
  // CommonAxisSettings as RsCommonAxisSettings,
  // ValueAxis as RsValueAxis,
  // TickInterval as RsTickInterval,
  // Label as RsLabel,
} from "devextreme-react/range-selector";
import moment from "moment";
import { connect } from "react-redux";
import { MachineRptClient, PDMachineStatusClient } from "../../../../app/shared/service-proxies/api-shared";
import { AppSession } from "../../../../app/shared/app-session/app-session";
import BASE_URL_API from "../../../../app/shared/service-proxies/config";
let appSession: AppSession;
const TimeLineChart = (props: any) => {
  const [model, setModel] = React.useState<any>();
  const machineRptClient = new MachineRptClient(appSession, BASE_URL_API);
  const pDMachineStatusClient = new PDMachineStatusClient(appSession, BASE_URL_API);

  const [dataSource, setdataSource] = React.useState<any>();

  React.useEffect(() => {
    setModel({
      visualRange: {
        startValue: props.time_from,
        endValue: props.time_to,
      },
    })
    setdataSource(props.dataSource);

  }, []);

  const mapStateToProps = (state: any) => {
    return {
      ma_cty: state.LoginReducer.ma_cty,
      listMachineActionState: state.ManufacturingReducer.listMachineActionState,
    };
  };

  const mapDispatchToProps = (dispatch: any, props: any) => {
    return {
      // GetMachinesActionState: (query) => {
      //   dispatch(actions.GetMachinesActionState(query));
      // },
    };
  };
  const updateVisualRange = (e: any) => {
    setModel({ ...model, visualRange: e.value });
  };
  const handleChange = (e: any) => {
    if (e.name === "valueAxis") {
      setModel({ ...model, visualRange: e.value });
    }
  };
  const customizeSeries = (valueFromNameField: any) => {
    var color = "";
    if (props.listMachineActionState.length > 0) {
      props.listMachineActionState.map((item: any, index: any) => {
        if (item.DESCRIPTION === valueFromNameField) {
          color = item.BACKCOLOR;
        }
      });
    }
    return color !== "" ? { color: color } : {};
  };

  return (
    <>
      {
        model ? (
          <div>
            <Chart
              id="chart"
              dataSource={dataSource}
              barGroupPadding={0.2}
              rotated={true}
              onOptionChanged={handleChange}
            >
              <Title
                text="Machine Timeline"
                subtitle={props.machineName}
                font={{ color: "#1890ff" }}
              />
              <CommonSeriesSettings
                type="rangeBar"
                argumentField="machine_code"
                rangeValue1Field="time_from"
                rangeValue2Field="time_to"
                barOverlapGroup="machine_code"
                ignoreEmptyPoints={true}
              >
                <Aggregation enabled={true} />
              </CommonSeriesSettings>
              <ArgumentAxis>
                <Label visible={false}></Label>
              </ArgumentAxis>
              <Legend verticalAlignment="bottom" horizontalAlignment="center">
                <Title text="Trạng thái" />
              </Legend>
              <ValueAxis valueType="datetime" visualRange={model.visualRange}>
                <TickInterval milliseconds={5} />
              </ValueAxis>
              <SeriesTemplate
                nameField="state_description"
                customizeSeries={customizeSeries}
              />
              <ZoomAndPan
                valueAxis="both"
                argumentAxis="none"
                allowTouchGestures={true}
              />
              <ScrollBar visible={false} />
              <Animation enabled={false} />
              <Tooltip
                enabled={true}
                location="edge"
                customizeTooltip={props.customizeTooltip}
              />
            </Chart>
            <RangeSelector
              id="range-selector"
              dataSource={dataSource}
              onValueChanged={updateVisualRange}
              value={model.visualRange}
            >
              <Size height={120} />
              <Margin left={10} />
              <Scale
                startValue={props.time_from}
                endValue={props.time_to}
                valueType="datetime"
              />
            </RangeSelector>
          </div>
        ) : ''
      }
    </>

  );
}

export default TimeLineChart;
