import { notification } from "antd";
const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
    style: {
      zIndex: "9999 !important",
    },
  });
}


export default openNotificationWithIcon;