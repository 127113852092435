import React from "react";
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
    FormControlLabel,
    Switch,
    Tabs,
    Tab,
    InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    HomePageClient,
    UserInfo,
    UserInfoGroupModel,
    SysActionType,
    SiUserInfoClient,
    ParaUserRole,
    UserRole,
    UserApartmentBuilding,
    ProcessUserinfoModel,
    LstDepartment,
    LoginModel,
    HomeClient,
    CryptModel,
} from "../../../shared/service-proxies/api-shared";


import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import '../../actions/ApartmentBuilding.css';
import notification from "../../../../common/notification/notification";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import CTXDateTime from "../../../../components/CTX/DateTimePicker";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ERROR_CODE } from "../../../../common/errorCode";
import { useHotkeys } from "react-hotkeys-hook";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import { httpPost } from "../../../../common/httpService";
import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import axios from "axios";
import { validationExtensions, validationSize } from "../../../../common/images/FileAccept";
import { printf } from "../../../../components/utils/string.util";
import DHSUploadImage from "../../../../components/DHS/DHS-Upload-Image/DHSUploadImage";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface IProps {
    open: boolean;
    itemSelected?: UserInfo;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: "1rem 0" }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const UserRolecolumns: ColumnsType<IUserRole> = [
    {
        key: 'roleName',
        title: 'Tên vài trò',
        dataIndex: 'roleName',
        align: "left",

    },
    {
        key: 'fullName',
        title: 'Tên đầy đủ',
        dataIndex: 'fullName',
        align: "left",

    }

];

const UserApartmentBuildingcolumns: ColumnsType<IUserApartmentBuilding> = [
    {
        key: 'apartment_building_name',
        title: 'Tên dự án',
        dataIndex: 'apartment_building_name',
        align: "left",

    },
    // {
    //     key: 'fullName',
    //     title: 'Tên đầy đủ',
    //     dataIndex: 'fullName',
    //     align: "left",

    // }

];



const status_message = ['success', 'error']

var appSession: AppSession;

interface IUserRole extends UserRole {
    key: string
}
interface IUserApartmentBuilding extends UserApartmentBuilding {
    key: string
}

const total_tabs = 3;
const UserInfoAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    // 
    // const [roles, setRoles] = React.useState<RoleRightWebModel[]>();


    const [data, setData] = React.useState<UserInfo>({} as UserInfo);
    const [rePassword, setRePassword] = React.useState<string>("");
    const [userRoleData, setUserRoleData] = React.useState<IUserRole[]>([] as IUserRole[]);
    const [userApartmentBuildingData, setUserApartmentBuildingData] = React.useState<IUserApartmentBuilding[]>([] as IUserApartmentBuilding[]);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [reload, setReload] = React.useState<boolean>(false);

    const homePageClient = new HomePageClient(appSession, BASE_URL_API);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const siUserInfoClient = new SiUserInfoClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);


    // 

    const [groupOptions, setGroupOptions] = React.useState<any[]>([]);
    const [departmentOptions, setDepartmentOptions] = React.useState<LstDepartment[]>([]);


    // 

    const [value, setValue] = React.useState(0);


    // const [isShowPassword, setIsShowPassword] = React.useState<boolean>(false);
    // const [roleInfoSelected, setRoleInfoSelected] = React.useState<string>();
    const [userRoleSelectedRowKeys, setUserRoleSelectedRowKeys] = React.useState<React.Key[]>([]);
    const [userApartmentBuildingSelectedRowKeys, setUserApartmentBuildingSelectedRowKeys] = React.useState<React.Key[]>([]);

    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [fullScreen, setFullscreen] = React.useState<boolean>(false);
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const [isShowPassword, setIsShowPassword] = React.useState<boolean>(false);

    // rowSelection object indicates the need for row selection
    const userRoleRowSelection = {
        selectedRowKeys: userRoleSelectedRowKeys,
        onChange: (userRoleSelectedRowKeys: React.Key[], selectedRows: IUserRole[]) => {

            setUserRoleSelectedRowKeys(userRoleSelectedRowKeys)

            // const tempSelected = userRoleSelectedRowKeys.join(";")

            // setServiceSelected(tempSelected);
        },
        getCheckboxProps: (record: IUserRole) => ({
            disabled: data?.isadmin === true, // Column configuration not to be checked
            name: record.roleName,
        }),
    };

    // rowSelection object indicates the need for row selection
    const userApartmentBuildingRowSelection = {
        selectedRowKeys: userApartmentBuildingSelectedRowKeys,
        onChange: (userApartmentBuildingSelectedRowKeys: React.Key[], selectedRows: UserApartmentBuilding[]) => {

            setUserApartmentBuildingSelectedRowKeys(userApartmentBuildingSelectedRowKeys)
            // const tempSelected = userRoleSelectedRowKeys.join(";")

            // setServiceSelected(tempSelected);
        },
        getCheckboxProps: (record: UserApartmentBuilding) => ({
            // disabled: record.is_default === true, // Column configuration not to be checked
            name: record.apartment_building_name,
        }),
    };


    useHotkeys('ctrl+alt+t', () => {
        if (value >= total_tabs - 1) {
            setValue(0)
        }
        else setValue(pre => pre + 1)
    })

    React.useEffect(() => {
        if(props.open){
            setIsShowPassword(false)
            setShowPassword(false)
        }
    }, [props.open])

    React.useEffect(() => {
        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            // siUserInfoClient.userInfoGet2({
            //     ...new LoginModel(),
            //     userName: props.itemSelected
            // } as LoginModel)
            //     .then((res) => {
            //         setData(res[0]);
            //         setRePassword(res[0]?.password ?? "")

            //     })
            //     .catch(() => alert("Không lấy được dữ liệu"));


            // setId(props.itemSelected);
            if (props.itemSelected) {
                setData({
                    ...new UserInfo(),
                    ...props.itemSelected
                } as UserInfo);
                setRePassword(props.itemSelected?.password ?? "")
            }
        } else {
            setData({
                ...new UserInfo(),
            } as UserInfo);
            setUserRoleSelectedRowKeys([])
            setUserApartmentBuildingSelectedRowKeys([])
            setRePassword("")
            setId(undefined)
        };
        // }
    }, [props.action, props.itemSelected]);


    React.useEffect(() => {

        if (SessionLogin.userName) {
            setData(pre => ({ ...pre, cuser: SessionLogin.userName, luser: SessionLogin.userName } as UserInfo))
        }

    }, [SessionLogin.userName]);

    React.useEffect(() => {
        setLoading(true)

        siUserInfoClient.getListUserRole({
            ...new ParaUserRole(),
            userName: props.itemSelected?.username,

        } as ParaUserRole)
            .then(res => {
                setLoading(false)
                const tempData: IUserRole[] = [];
                const tempKeys = [...userRoleSelectedRowKeys];
                res.forEach(userRole => {
                    if (userRole.chon === true) {
                        tempKeys.push(userRole.roleName as React.Key);
                    }
                    tempData.push({
                        ...userRole,
                        key: userRole.roleName,

                    } as IUserRole);
                })
                setUserRoleData(tempData);
                if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) setUserRoleSelectedRowKeys(tempKeys);
            });

        // 

        setLoading(true)

        siUserInfoClient.getListUserApartmentBuilding({
            ...new ParaUserRole(),
            userName: props.itemSelected?.username,

        } as ParaUserRole)
            .then(res => {
                setLoading(false)
                const tempData: IUserApartmentBuilding[] = [];
                const tempKeys = userApartmentBuildingSelectedRowKeys && [...userApartmentBuildingSelectedRowKeys];
                res.forEach(userRole => {
                    if (userRole.grant_access === true) {
                        tempKeys?.push(userRole.apartment_building_id!);
                    }
                    tempData.push({
                        ...userRole,
                        key: userRole.apartment_building_id,

                    } as IUserApartmentBuilding);
                })
                setUserApartmentBuildingData(tempData);
                if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) setUserApartmentBuildingSelectedRowKeys(tempKeys);
            });
    }, [props.action, props.itemSelected?.username,])

    React.useEffect(() => {



        setLoading(true)
        const body = {
            ma_cty: SessionLogin.ma_cty,
            tablelist: "dm_bo_phan"
        }
        httpPost<string>('api/Common/LoadFilterList', body)
            .then(res => {

                const departments: LstDepartment[] = JSON.parse(res.data);
                setDepartmentOptions(departments)
                setLoading(false)

            });

        setLoading(true);
        homePageClient.userInfoGroupGet({
            ...new UserInfoGroupModel(),
            action_type: 'GET'
        } as UserInfoGroupModel)
            .then(res => {
                setGroupOptions(res)

                setLoading(false);
            });

    }, [])




    //#region Validation
    // React.useEffect(() => {
    //     if (data?.password
    //         && data?.password.length !== rePassword.length
    //         && data?.password?.toLocaleLowerCase() !== rePassword.toLocaleLowerCase()) {

    //         setErrors(pre => ({
    //             ...pre,
    //             "INCORRECT_PASSWORD_CONFIRMATION": "Mật khẩu nhập lại không chính xác"
    //         }));
    //     }
    //     else if (data?.password
    //         && data?.password.length === rePassword.length
    //         && data?.password?.toLocaleLowerCase() === rePassword.toLocaleLowerCase()) {

    //         const { INCORRECT_PASSWORD_CONFIRMATION, ...tempErrors } = errors;
    //         setErrors(tempErrors);
    //     }
    // }, [data?.password, rePassword]);

    React.useEffect(() => {
        if (!data?.group_id) {
            setErrors(pre => ({
                ...pre,
                "GROUP_REQUIRED": "Nhóm là bắt buộc."
            }));
        }
        else if (data?.group_id) {
            const { GROUP_REQUIRED, ...tempErrors } = errors;
            setErrors(tempErrors);
        }
    }, [data?.group_id])

    //#endregion

    const handleSubmit = () => {


        if (Object.keys(errors).length > 0) {
            Object.values(errors).forEach(error => {
                notification(status_message[1], error);

            });
            return;
        }



        setLoading(true)

        switch (props.action) {


            case ACTIONS.EDIT: {

                const dataUserRoleSelected = [...userRoleData].filter(userRole => {
                    if (userRoleSelectedRowKeys && userRoleSelectedRowKeys.includes(userRole.roleName as React.Key)) {
                        userRole.chon = true;
                        userRole.userName = data?.username;
                        return userRole;
                    }
                    return null;
                })



                const dataUserApartmentSelected = [...userApartmentBuildingData].filter(userApartment => {
                    if (userApartmentBuildingSelectedRowKeys && userApartmentBuildingSelectedRowKeys.includes(userApartment.apartment_building_id as React.Key)) {
                        userApartment.userName = data?.username;
                        userApartment.grant_access = true;
                        return userApartment;
                    }
                    return null;
                })
                setLoading(true)
                siUserInfoClient.userInfoUpd({
                    ...new ProcessUserinfoModel(),
                    userinfo: { ...data, luser: SessionLogin.userName, action_type: "UPDATE" } as UserInfo,
                    lstuserrole: [
                        ...dataUserRoleSelected
                    ] as UserInfo[],
                    ma_cty: SessionLogin.ma_cty,
                    // action_type: "UPDATE"
                } as ProcessUserinfoModel)
                    .then((response) => {
                        setLoading(false);
                        notification(status_message[response ? 0 : 1], STATUS_MESSAGE[`${props.action}_${response ? 0 : 1}`]);
                        if (response) {
                            props.onEditSuccess(true);
                            props.onClose();

                        }

                    }).catch(error => {
                        notification(status_message[1], "Có lỗi xãy ra");
                        setLoading(false)

                    });

                setLoading(true)
                siUserInfoClient.userApartmentBuildingIns([
                    ...dataUserApartmentSelected
                ] as UserApartmentBuilding[])
                    .then(res => {
                        setLoading(false)

                        // Sẽ xữ lý sau
                    }).catch(error => {
                        notification(status_message[1], "Có lỗi xãy ra");
                        setLoading(false)

                    });


                break;
            }

            case ACTIONS.ADD: {

                const dataUserRoleSelected = [...userRoleData].filter(userRole => {
                    if (userRoleSelectedRowKeys && userRoleSelectedRowKeys.includes(userRole.roleName as React.Key)) {
                        userRole.userName = data?.username;
                        userRole.chon = true;
                        return userRole;
                    }
                    return null;
                })



                const dataUserApartmentSelected = [...userApartmentBuildingData].filter(userApartment => {
                    if (userApartmentBuildingSelectedRowKeys && userApartmentBuildingSelectedRowKeys.includes(userApartment.apartment_building_id as React.Key)) {
                        userApartment.userName = data?.username;
                        userApartment.grant_access = true;
                        return userApartment;
                    }
                    return null;
                })

                setLoading(true)
                siUserInfoClient.userInfoIns({
                    ...new ProcessUserinfoModel(),
                    ma_cty: SessionLogin.ma_cty,
                    userinfo: {
                        ...data,
                        cuser: SessionLogin.userName,
                        issuperadmin: false,
                        disabled: false,
                        apartment_building_id: apartment_building_id
                    } as UserInfo,
                    lstuserrole: [
                        ...dataUserRoleSelected
                    ] as UserRole[]

                } as ProcessUserinfoModel)
                    .then((response) => {
                        setLoading(false);
                        notification(status_message[response ? 0 : 1], STATUS_MESSAGE[`${props.action}_${response ? 0 : 1}`]);

                        if (response) {
                            props.onEditSuccess(true);
                            props.onClose();
                            // setId(response.ref_id)
                            // setAction({ ...action, itemSelected: { ...props.itemSelected, username: response.ref_id }, action: 'EDITACCOUNT' })
                        }
                    }).catch(error => {
                        notification(status_message[1], "Có lỗi xãy ra");
                        setLoading(false)

                    });

                setLoading(true)
                siUserInfoClient.userApartmentBuildingIns([
                    ...dataUserApartmentSelected
                ] as UserApartmentBuilding[])
                    .then(res => {
                        setLoading(false)

                        // Sẽ xữ lý sau
                    }).catch(error => {
                        notification(status_message[1], "Có lỗi xãy ra");
                        setLoading(false)

                    });
                break;
            }


            default:
                break;
        }

    };



    const handleChangeInput = (e: any) => {
        const { name, value, checked, type } = e.target;

        if (type === 'checkbox') {
            setData({ ...data, [name]: checked } as UserInfo);

        }
        else setData({ ...data, [name]: value } as UserInfo);
    };

    //#region handleImage

    const handleRemoveImage = (e: any) => {
        const { name } = e;

        if (name) {
            setData({
                ...data,
                [name]: ''
            } as UserInfo)
        }
    }

    const handleChangeImage = async (e: any) => {
        var isExit = false;
        const { name, files, value } = e.target;
        if (!validationExtensions(value)) {
            e.target.value = '';
            notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE[`ALLOW_JPG_JPGE_PNG`])
            return;
        }
        const formData = new FormData();
        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };
        for (const file of files) {
            if (validationSize(file.size) === 0) {
                formData.append("MyFile", file);

            } else {
                isExit = true;
                notification(TYPE_MESSAGE.WARNING, printf(STATUS_MESSAGE[`ALLOW_SIZE`], "1MB", "4MB"))
                break;

            }
        }

        if (isExit) {
            e.target.value = '';
            return;
        }


        setLoading(true)

        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    setLoading(false)
                    setData(pre => ({ ...pre, [name]: res.data[0].path } as UserInfo))
                } else notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE[`CANNOT_GET_IMAGE`]);
            });

        e.target.value = '';

    }

    const handleAddNewImage = async (e: any) => {

        const { name, files, value } = e.target;

        if (!validationExtensions(value)) {
            e.target.value = '';
            notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE[`ALLOW_JPG_JPGE_PNG`])
            return;
        }
        const formData = new FormData();
        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };
        for (const file of files) {
            formData.append("MyFile", file);
        }
        setLoading(true)

        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    setLoading(false)
                    setData(pre => ({ ...pre, [name]: res.data[0].path } as UserInfo))
                } else notification("warning", "Không lấy được hình ảnh");
            });

        e.target.value = '';
    }

    //#endregion

    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:
                handleSubmit();
                break;

            case ACTIONS.CLOSE:
               
                props.onClose();
               
                break;
        }
    }

    // 
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    //
    const handleClickShowPassword = () => {
        if(!isShowPassword){
            homeClient.decrypt(new CryptModel({
                password: "Dhs@123456",
                text: data?.password
            })).then(res => {
                if (res.result) {
                    setData(pre => ({
                        ...pre,
                        password: res.result
                    } as UserInfo));
                    setShowPassword(pre => !pre)
                    setIsShowPassword(true)
                }
            })
        }
        else{
            setShowPassword(pre => !pre)

        }
        
    }



    return (
        <Dialog
            // id="sysMenu"
            open={props.open}
            onClose={() => {
              
                props.onClose()
                
            }}
            PaperProps={{
                sx: !fullScreen ? {
                    minHeight: "90vh",
                    height: "90vh"
                } : undefined
            }}
            sx={{
                // display: "flex",
                // justifyContent: "flex-end",
            }}
            TransitionComponent={Transition}
            maxWidth='lg'
            fullWidth
            fullScreen={fullScreen}


            className="Common-control"
        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                        className="app-bar-dialog"
                    >
                        {`${menu?.bar} ${data?.username ? data?.username : ""} ${props.action === ACTIONS.VIEW
                            ? "[Chi tiết]"
                            : props.action === ACTIONS.EDIT || _id
                                ? "[Cập nhật]"
                                : "[Thêm mới]"
                            }`}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1.25 }}>
                        <IconButton
                            edge="end"

                            onClick={() => {
                                setFullscreen(pre => !pre)
                            }}
                            aria-label="full screen"
                        >
                            {fullScreen ? <FullscreenExitIcon sx={{ color: '#1f2937' }} /> : <FullscreenIcon sx={{ color: '#1f2937' }} />}
                        </IconButton>
                        <IconButton
                            edge="end"
                            onClick={() => {
                                props.onClose();
                            }}
                            aria-label="close"
                        >
                            <CloseIcon
                                sx={{ color: '#1f2937' }}
                            />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Thông tin cơ bản" sx={{ textTransform: 'none', fontSize: 14 }}  {...a11yProps(0)} />
                        <Tab label="Phân quyền theo vai trò" sx={{ textTransform: 'none', fontSize: 14 }}  {...a11yProps(1)} />
                        <Tab label="Phân quyền theo dự án" sx={{ textTransform: 'none', fontSize: 14 }}  {...a11yProps(1)} />
                        {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>

                    <div className="row gutter-1">
                        <div className="col-4">
                            <div className="card">

                                <div className="card-body">
                                    <div>
                                        Thông tin chung
                                    </div>

                                    {
                                        //     props.action === 'EDITACCOUNT'
                                        //     && roles
                                        //     && roles.filter(item => item.on_form === 'changePassword').map((role) => (
                                        //         <Button
                                        //             className={role.class_name}
                                        //             onClick={() => setIsShowPassword(!isShowPassword)}
                                        //             style={{ width: "100%" }}

                                        //         >
                                        //             {`${isShowPassword ? "Tắt" : ""} ${role.name_vn}`}
                                        //         </Button>
                                        //     ))
                                    }

                                    {/* Mã nhân viên */}
                                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mã nhân viên</InputLabel>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            placeholder="Mã nhân viên..."
                                            name="employeecode"
                                            onChange={handleChangeInput}
                                            value={data.employeecode}
                                            disabled={props.action === ACTIONS.VIEW}
                                        />

                                    </Box>

                                    {/* Họ tên đầy đủ */}
                                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Họ tên đầy đủ</InputLabel>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            placeholder="Họ tên đầy đủ..."
                                            name="fullname"
                                            autoComplete="new-password"
                                            onChange={handleChangeInput}
                                            value={data.fullname}
                                            disabled={props.action === ACTIONS.VIEW}
                                        />

                                    </Box>

                                    {/* Số điện thoại */}
                                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số điện thoại</InputLabel>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            placeholder="Số điện thoại..."
                                            type={'tel'}
                                            name="phone"
                                            autoComplete="new-password"
                                            onChange={handleChangeInput}
                                            value={data.phone}
                                            disabled={props.action === ACTIONS.VIEW}
                                        />

                                    </Box>


                                    {/* Email */}
                                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Email</InputLabel>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            placeholder="Email..."
                                            type={'email'}
                                            name="email"
                                            autoComplete="new-password"
                                            onChange={handleChangeInput}
                                            value={data.email}
                                            disabled={props.action === ACTIONS.VIEW}
                                        />

                                    </Box>
                                </div>
                            </div>

                            <div className="card mt-2">

                                <div className="card-body">
                                    <div>
                                        Nhóm user
                                    </div>

                                    {/* Nhóm */}

                                    <div className="row no-gutters">
                                        <div className="col-md-6 col-12 pr-1">
                                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                                <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Nhóm</InputLabel>
                                                <Autocomplete
                                                    id="vehicle-type-select"
                                                    sx={{ width: "100%" }}
                                                    options={groupOptions}
                                                    autoHighlight
                                                    getOptionLabel={(option: any) => option?.group_name}
                                                    value={
                                                        data?.group_id && groupOptions.length > 0
                                                            ? groupOptions.find(
                                                                (item: any) => item.id === data?.group_id
                                                            )
                                                            : null
                                                    }
                                                    onChange={(e, newValue: any) => {
                                                        handleChangeInput({
                                                            target: {
                                                                name: "group_id",
                                                                value: newValue?.id ? newValue.id : null,
                                                            },
                                                        });
                                                    }}

                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            className="cc-input"
                                                            placeholder="Nhóm ..."
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "new-password", // disable autocomplete and autofill
                                                            }}
                                                        />
                                                    )}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                />

                                            </Box>
                                        </div>
                                        <div className="col-md-6 col-12 pl-1">
                                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Bộ phận</InputLabel>
                                                <Autocomplete
                                                    id="department-select"
                                                    sx={{ width: "100%" }}
                                                    options={departmentOptions}
                                                    autoHighlight
                                                    defaultValue={departmentOptions[0]}
                                                    getOptionLabel={(option: LstDepartment) => option?.ten_bp ?? ""}
                                                    value={
                                                        data?.department_id && departmentOptions.length > 0
                                                            ? departmentOptions.find(
                                                                (item: any) => item.ma_bp === data.department_id
                                                            )
                                                            : null
                                                    }
                                                    onChange={(e, newValue: LstDepartment | null) => {
                                                        handleChangeInput({
                                                            target: {
                                                                name: "department_id",
                                                                value: newValue?.ma_bp ? newValue?.ma_bp : null,
                                                            },
                                                        });
                                                    }}

                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            className="cc-input"
                                                            placeholder="Bộ phận ..."
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "new-password", // disable autocomplete and autofill
                                                            }}
                                                        />
                                                    )}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                />

                                            </Box>
                                        </div>
                                    </div>

                                    {/* Admin */}
                                    <Box className="row">
                                        <Box className="col-6">


                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Admin</InputLabel>

                                            <FormControlLabel control={
                                                <Switch
                                                    color="primary"
                                                    onChange={handleChangeInput}
                                                    name="isadmin"
                                                    value={data.isadmin}
                                                    checked={data.isadmin}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                />}
                                                label={
                                                    <Typography
                                                        sx={{ fontSize: 14, color: '#333' }}>
                                                        Không/Có
                                                    </Typography>} />


                                        </Box>


                                        <Box className="col-6">


                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Vô hiệu hóa</InputLabel>

                                            <FormControlLabel control={
                                                <Switch
                                                    color="primary"
                                                    onChange={handleChangeInput}
                                                    name="disabled"
                                                    value={data.disabled}
                                                    checked={data.disabled}
                                                    disabled={props.action === ACTIONS.VIEW} />}
                                                label={
                                                    <Typography
                                                        sx={{ fontSize: 14, color: '#333' }}
                                                    >
                                                        Không/Có
                                                    </Typography>} />

                                        </Box>



                                        {/* Supperadmin */}

                                        {/* <Box className="col-6">

                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Khoá</InputLabel>

                                            <FormControlLabel control={
                                                <Switch
                                                    color="primary"
                                                    onChange={handleChangeInput}
                                                    name="issuperadmin"
                                                    value={data.issuperadmin}
                                                    checked={data.issuperadmin}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                />}
                                                label={
                                                    <Typography
                                                        sx={{ fontSize: 14, color: '#333' }}>
                                                        Không/Có
                                                    </Typography>} />


                                        </Box> */}
                                        {/* Khoá */}

                                        {/* <Box className="col-6">

                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Khoá</InputLabel>

                                            <FormControlLabel control={
                                                <Switch
                                                    color="primary"
                                                    onChange={handleChangeInput}
                                                    name="isblock"
                                                    value={data.isblock}
                                                    checked={data.isblock}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                />}
                                                label={
                                                    <Typography
                                                        sx={{ fontSize: 14, color: '#333' }}>
                                                        Không/Có
                                                    </Typography>} />


                                        </Box> */}




                                    </Box>
                                </div>

                            </div>


                        </div>

                        <div className="col-4">

                            {/*  */}

                            <div className="card">

                                <div className="card-body">
                                    <div>
                                        Tài khoản và Mật khẩu
                                    </div>
                                    {/* Tài khoản (username) */}
                                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tài khoản (username)</InputLabel>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            placeholder="Tài khoản (username)..."
                                            name="username"
                                            onChange={(e: any) => {
                                                handleChangeInput({
                                                    target: {
                                                        name: e.target.name,
                                                        value: e.target.value.toLocaleUpperCase()
                                                    }
                                                })
                                            }}
                                            value={data.username}
                                            disabled={props.action === (ACTIONS.VIEW || "EDITACCOUNT")}
                                        />

                                    </Box>
                                    {/* Mật khẩu (password) */}
                                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mật khẩu (password)</InputLabel>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            placeholder="Mật khẩu (password)"
                                            name="password"
                                            onChange={(e: any) => {
                                                handleChangeInput({
                                                    target: {
                                                        name: e.target.name,
                                                        value: e.target.value.toLocaleUpperCase()
                                                    }
                                                })
                                            }}
                                            type={showPassword ? "text" : "password"}

                                            value={data.password}
                                            disabled={props.action === (ACTIONS.VIEW || "EDITACCOUNT")}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />

                                    </Box>

                                    {/* Nhập lại Mật khẩu (password) */}
                                    {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Nhập lại mật khẩu (re-password)</InputLabel>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            placeholder="Nhập lại mật khẩu (password)"
                                            name="re-password"
                                            onChange={(e: any) => {
                                                setRePassword(e.target.value)


                                            }}
                                            type="password"
                                            value={rePassword}
                                            helperText={errors[ERROR_CODE["INCORRECT_PASSWORD_CONFIRMATION"]]}
                                            disabled={props.action === (ACTIONS.VIEW || "EDITACCOUNT")}
                                        />

                                    </Box> */}

                                </div>
                            </div>



                            <div className="card mt-2">
                                <div className="card-body">
                                    <div>
                                        Server mail
                                    </div>
                                    {/* Server mail */}
                                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Server mail</InputLabel>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            placeholder="Server mail..."
                                            type={'text'}
                                            name="servermail"
                                            autoComplete="new-password"
                                            onChange={handleChangeInput}
                                            value={data.servermail}
                                            disabled={props.action === ACTIONS.VIEW}
                                        />

                                    </Box>

                                    {/* pass mail */}
                                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Password mail</InputLabel>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            placeholder="Password mail..."
                                            type={'password'}
                                            name="passmail"
                                            autoComplete="new-password"
                                            onChange={handleChangeInput}
                                            value={data.passmail}
                                            disabled={props.action === ACTIONS.VIEW}
                                        />

                                    </Box>
                                    <div className="row">
                                        <div className="col-6">
                                            {/* Mail port */}
                                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mail port</InputLabel>
                                                <TextField
                                                    className="cc-input"
                                                    sx={{
                                                        width: "100%",
                                                        marginRight: "5px",
                                                    }}
                                                    placeholder="Mail port..."
                                                    type={'number'}
                                                    name="mailPort"
                                                    autoComplete="new-password"
                                                    onChange={handleChangeInput}
                                                    value={data.mailPort}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                />

                                            </Box>
                                        </div>
                                        <div className="col-6">


                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Enable SSL</InputLabel>

                                            <FormControlLabel control={
                                                <Switch
                                                    color="primary"
                                                    onChange={handleChangeInput}
                                                    name="enableSSL"
                                                    value={data.enableSSL}
                                                    checked={data.enableSSL}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                />}
                                                label={
                                                    <Typography
                                                        sx={{ fontSize: 14, color: '#333' }}>
                                                        Không/Có
                                                    </Typography>} />


                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>



                        <div className="col-4">


                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        Hình ảnh
                                    </div>
                                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <div className="d-flex align-items-center">
                                            <InputLabel sx={{ fontSize: 14, color: "#000" }}>
                                                Chữ ký
                                            </InputLabel>

                                        </div>
                                        <DHSUploadImage
                                            name="linksignoff"
                                            value={data?.linksignoff}
                                            handleAddNewImage={handleAddNewImage}
                                            handleChangeImage={handleChangeImage}
                                            handleRemoveImage={handleRemoveImage}
                                            disable={props?.action === ACTIONS.VIEW}
                                        />
                                    </Box>

                                </div>
                            </div>

                            <div className="card mt-2">
                                <div className="card-body">
                                    <div>
                                        Lịch sử chỉnh sửa
                                    </div>
                                    {/* Người tạo */}
                                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Người tạo</InputLabel>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            placeholder="Người tạo..."
                                            type={'text'}
                                            name="#"
                                            autoComplete="new-password"
                                            // onChange={handleChangeInput}
                                            value={data.cuser}
                                            disabled={true}
                                        />

                                    </Box>

                                    {/* Người chỉnh sửa */}
                                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Người chỉnh sửa</InputLabel>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            placeholder="Người chỉnh sửa..."
                                            type={'text'}
                                            name="#"
                                            autoComplete="new-password"
                                            // onChange={handleChangeInput}
                                            value={data.luser}
                                            disabled={true}
                                        />

                                    </Box>

                                    {/* Ngày tạo */}
                                    <Box sx={{ width: '100%', margin: '5px 0' }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày tạo</InputLabel>
                                        <CTXDateTime
                                            className="cc-input"
                                            style={{ width: '100%' }}
                                            name="created_date"
                                            // onChange={handleChangeDate}
                                            value={data.cdate ?? null}
                                            disabled
                                        />
                                    </Box>

                                    {/* Ngày cập nhật */}
                                    <Box sx={{ width: '100%', margin: '5px 0' }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày cập nhật</InputLabel>
                                        <CTXDateTime
                                            className="cc-input"
                                            style={{ width: '100%' }}
                                            name="updated_date"
                                            // onChange={handleChangeDate}
                                            value={data.ldate ?? null}
                                            disabled
                                        />
                                    </Box>
                                </div>
                            </div>



                        </div>

                        <div className="col-4">





                        </div>
                    </div>

                </TabPanel>

                <TabPanel value={value} index={1}>
                    <div className="row gutter-1">
                        <div className="col-8">
                            {/* <div className="card mt-2">

                                <div className="card-body">
                                    <div>
                                        Vai trò
                                    </div> */}

                            <Table
                                bordered
                                rowSelection={{
                                    type: 'checkbox',
                                    ...userRoleRowSelection,
                                }}
                                columns={UserRolecolumns}
                                dataSource={userRoleData}
                                pagination={false}
                                scroll={{ y: 400 }}
                                locale={{
                                    emptyText() {
                                        return <span style={{ color: "red" }}>Quyền trống.</span>
                                    },
                                }}
                            />
                            {/* </div> */}
                            {/* </div> */}



                        </div>
                    </div>
                </TabPanel>

                <TabPanel value={value} index={2}>
                    <div className="row gutter-1">
                        <div className="col-8">
                            {/* <div className="card mt-2">

                                <div className="card-body">
                                    <div>
                                        Vai trò
                                    </div> */}

                            <Table
                                bordered
                                rowSelection={{
                                    type: 'checkbox',
                                    ...userApartmentBuildingRowSelection,
                                }}
                                columns={UserApartmentBuildingcolumns}
                                dataSource={userApartmentBuildingData}
                                pagination={false}
                                scroll={{ y: 400 }}
                                locale={{
                                    emptyText() {
                                        return <span style={{ color: "red" }}>Dự án trống.</span>
                                    },
                                }}
                            />
                            {/* </div> */}
                            {/* </div> */}



                        </div>
                    </div>
                </TabPanel>



            </DialogContent>
            <DialogActions>
                <DHSDialogAction

                    action={props.action}
                    menu={menu}
                    onClickAction={handleClickAction}
                />
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog >
    );
};

export default UserInfoAction;
