import React, { ReactElement, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout } from "antd";
import { Switch, Route, useHistory, useRouteMatch } from "react-router-dom";
import _ from "lodash";
import { AppSession } from "../../../shared/app-session/app-session";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { Company, RptGLPTKQHDKDModel, GLClient, HomeClient, HRClient, LoginClient, LstHRDepartments, RoleRightWebModel, RptGLBCPT04DetailModel } from "../../../shared/service-proxies/api-shared";
import DHSToolbar from "../../../../components/DHS/DHS-Toolbar/DHSToolbar";
import DHSGridView from "../../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { components } from "../../../../common/ComponentsConfig/components";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { getRoles } from "../../../../redux/actions/RoleActions";
import Select from "../../../../components/DHS/DHS_Select";
import './profit-loss.css'
export interface MatchParams {
    id: string;
}

let appSession: AppSession;
let _dataGrid: any = [];
const ProfitLoss = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
    const { path, url } = useRouteMatch();
    const hrClient = new HRClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const loginClient = new LoginClient(appSession, BASE_URL_API);
    const glClient = new GLClient(appSession, BASE_URL_API);
    const role = useSelector((state: IReducer) => state.RoleReducer?.role);
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setloading] = React.useState<boolean>(false);
    const [itemSelected, selectItem] = React.useState<string>();
    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
    const [isShowSort, setShowSort] = React.useState<boolean>(false);
    const [isShowSelect, setShowSelect] = React.useState<boolean>(false);
    const [isNavFilter, setNavFilter] = React.useState<boolean>(true);
    const [back, setBack] = React.useState<number>(-1)
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [unitOptions, setUnitOptions] = React.useState<any[]>([]);
    const [dataGrid, setdataGrid] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any>({
        ma_cty: SessionLogin.ma_cty,
        ma_bp: '',
        thang1: 1,
        thang2: 2,
        quythang: 2,
        nam: (new Date()).getFullYear()
    });
    const [listCompany, setListCompany] = React.useState<Company[]>([]);
    const customMenu = [
        {
            menuName: isShowSelect ? "Ẩn Select Box" : "Hiện Select Box",
            onClick: () => {
                setShowSelect(!isShowSelect);
            },
        },
        {
            menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
            onClick: () => {
                setShowFilter(!isShowFilter);
            },
        },
        {
            menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
            onClick: () => {
                setShowSearch(!isShowSearch);
            },
        },
        {
            menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
            onClick: () => {
                setShowGroup(!isShowGroup);
            },
        },
        {
            menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
            onClick: () => {
                setShowSort(!isShowSort);
            },
        },
    ];
    const list_month = [
        {
            value: 1,
            name: 'Tháng 1'
        },
        {
            value: 2,
            name: 'Tháng 2'
        },
        {
            value: 3,
            name: 'Tháng 3'
        },
        {
            value: 4,
            name: 'Tháng 4'
        },
        {
            value: 5,
            name: 'Tháng 5'
        },
        {
            value: 6,
            name: 'Tháng 6'
        },
        {
            value: 7,
            name: 'Tháng 7'
        },
        {
            value: 8,
            name: 'Tháng 8'
        },
        {
            value: 9,
            name: 'Tháng 9'
        },
        {
            value: 10,
            name: 'Tháng 10'
        },
        {
            value: 11,
            name: 'Tháng 11'
        },
        {
            value: 12,
            name: 'Tháng 12'
        }
    ]
    const handleClickAction = (action: any) => {
        setBack((item) => (item -= 1));
        if (action.class_name.toUpperCase() === "SEARCH") {
            refreshGrid();
        }
    };
    const onClickNav = (e: any, v: boolean) => {
        setNavFilter(v);
        setTimeout(() => {
            setdataGrid(_dataGrid.slice());
        }, 100);
        $('.nav-link').removeClass('active');
        $('.nav-link.' + e).addClass('active');
    }
    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;
        // if (name == 'thang1') {
        //     const now = new Date(data.nam, value, 1);
        //     const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        //     const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        //     setData({ ...data, ['thang1']: firstDay });
        //     setData({ ...data, ['thang2']: lastDay });
        // } else {

        // }
        setData({ ...data, [name]: value });
    };
    React.useEffect(() => {
        homeClient
            ?.roleRightWebGetAll({
                ...new RoleRightWebModel(),
                rolename: "GL",
                menuid: menu?.menuid,
            } as RoleRightWebModel)
            .then((res) => {
                dispatch(getRoles(res));
                setRoles(res);
            });
        loginClient.listCompany().then((res) => {
            setListCompany(res);
        });
        hrClient
            .lstHRDepartmentsGet({
                ...new LstHRDepartments(),
                ma_cty: SessionLogin.ma_cty,
            } as LstHRDepartments)
            .then((response) => setUnitOptions(response));
        refreshGrid();
    }, []);
    const refreshGrid = () => {
        var pt = new RptGLBCPT04DetailModel();
        pt.ma_cty = data.ma_cty
        pt.thang1 = data.thang1
        pt.thang2 = data.thang2
        pt.quythang = data.quythang.toString()
        pt.mau = '01'
        pt.nam = parseInt(data.nam.toString())
        glClient
            .rptGLBCPT04Detail(pt)
            .then((response) => {
                _dataGrid = response;
                setdataGrid(_dataGrid);
                setloading(true)
            });
    }
    return (
        <div className="container-fluid p-1">
            <Switch>
                <Route path={path} exact>
                    <div
                        className="d-flex title-color"
                        style={{
                            height: 30,
                            color: "rgb(0 78 255 / 88%)",
                            marginTop: 15,
                            marginBottom: 15,
                            fontSize: 22,
                            fontWeight: 550,
                        }}
                    >
                        <i
                            className="bi bi-arrow-left"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                history.go(back);
                            }}
                        ></i>
                        &nbsp;&nbsp;
                        {menu?.bar}
                    </div>
                    <DHSToolbar
                        id={itemSelected}
                        list={
                            roles
                                ? roles.filter(
                                    (item) =>
                                        item.on_form?.toLocaleUpperCase() === "PROFITLOSS"
                                )
                                : []
                        }
                        onClickAction={(item) => {
                            handleClickAction(item)
                        }}
                        customMenu={customMenu}
                    />
                    <>
                        <ul className="nav nav-tabs" style={{ display: 'block' }}>
                            <li className="nav-item">
                                <a className="nav-link 1 active" style={{ border: '1px solid #bfbfbf' }} onClick={() => onClickNav(1, true)} href="#">Điều kiện lọc</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link 2" style={{ border: '1px solid #bfbfbf' }} onClick={() => onClickNav(2, false)} href="#">Kết quả</a>
                            </li>
                        </ul>
                    </>
                    {
                        isNavFilter == false ? (
                            <DHSGridView
                                onSelectRow={(row) => {
                                    selectItem(row);
                                }}
                                // onListSelectRow={handleListOnselectRow}
                                itemClicked={function (): void {
                                    throw new Error("Function not implemented.");
                                }}
                                table_name={
                                    menu?.component ? menu.component : ""
                                }
                                show_form_list={true}
                                showFilterPanel={isShowFilter}
                                showSortPanel={isShowSort}
                                showSearchPanel={isShowSearch}
                                showGroupPanel={isShowGroup}
                                showSelectBox={isShowSelect}
                                data={dataGrid}
                                defaultPageSize={20000}
                            />
                        ) : (
                            <div className="row" style={{ backgroundColor: 'white', padding: '50px' }}>
                                <div className="col-12"></div>
                                <div
                                    className="mb-3 col-xl-6"
                                    style={{ paddingLeft: "5px", paddingRight: "5px" }}
                                >
                                    <div className="form-group">
                                        <Select
                                            label="Công ty"
                                            value={data.ma_cty}
                                            options={listCompany}
                                            onChange={handleChangeInput}
                                            disabled={false}
                                            fieldValue={"ma_cty"}
                                            fieldDisplay={"ten_cty"}
                                            name="ma_cty"
                                        />
                                    </div>
                                </div>
                                <div className="col-12"></div>
                                <div className="form-group mb-3 col-xl-6" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                    <label className="label-input">Tiêu đề</label>
                                    <input
                                        className="flex-grow-1 form-control"
                                        value={''}
                                        disabled={false}
                                    />
                                </div>
                                <div className="col-12"></div>
                                <div
                                    className="mb-3 col-xl-6"
                                    style={{ paddingLeft: "5px", paddingRight: "5px" }}
                                >
                                    <div className="form-group">
                                        <Select
                                            label="Bộ phận"
                                            value={data.ma_bp}
                                            options={unitOptions}
                                            onChange={handleChangeInput}
                                            disabled={false}
                                            fieldValue={"department_code"}
                                            fieldDisplay={"department_name"}
                                            name="ma_bp"
                                        />
                                    </div>
                                </div>
                                <div className="col-12"></div>
                                <div
                                    className="mb-3 col-xl-3"
                                    style={{ paddingLeft: "5px", paddingRight: "5px" }}
                                >
                                    <div className="form-group">
                                        <Select
                                            label="Tháng 1"
                                            value={data.thang1}
                                            options={list_month}
                                            onChange={handleChangeInput}
                                            disabled={false}
                                            fieldValue={"value"}
                                            fieldDisplay={"name"}
                                            name="thang1"
                                        />
                                    </div>
                                </div>
                                <div
                                    className="mb-3 col-xl-3"
                                    style={{ paddingLeft: "5px", paddingRight: "5px" }}
                                >
                                    <div className="form-group">
                                        <Select
                                            label="Tháng 2"
                                            value={data.thang2}
                                            options={list_month}
                                            onChange={handleChangeInput}
                                            disabled={false}
                                            fieldValue={"value"}
                                            fieldDisplay={"name"}
                                            name="thang2"
                                        />
                                    </div>
                                </div>
                                <div className="col-12"></div>
                                <div className="form-group mb-3 col-xl-3" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                    <label className="label-input">Quý </label>
                                    <input
                                        className="flex-grow-1 form-control"
                                        value={data.quythang}
                                        disabled={false}
                                        onChange={handleChangeInput}
                                        name="quythang"
                                        type="number"
                                    />
                                </div>
                                <div className="form-group mb-3 col-xl-3" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                    <label className="label-input">Năm</label>
                                    <input
                                        className="flex-grow-1 form-control"
                                        value={data.nam}
                                        disabled={false}
                                        onChange={handleChangeInput}
                                        name="nam"
                                        type="number"
                                    />
                                </div>
                            </div>

                        )
                    }

                </Route>
                {roles &&
                    roles.map((role, index) => {
                        return (
                            role.link &&
                            role.link.length > 1 &&
                            role.component && (
                                <Route
                                    key={index}
                                    path={role.is_parameter ? role.link + ":_id" : role.link}
                                    component={components[role.component.toLocaleUpperCase()]}
                                    exact
                                ></Route>
                            )
                        );
                    })}
            </Switch>
        </div>
    );
}
export default ProfitLoss;