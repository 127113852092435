import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DHSToolbar from '../../../../components/DHS/DHS-Toolbar/DHSToolbar';
import { IReducer } from '../../../../components/layouts';
import DHSGridView from '../../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
import { getRoles } from '../../../../redux/actions/RoleActions';
import { AppSession } from '../../../shared/app-session/app-session';
import { ChoThueXeClient, HomeClient, QLCCResidentsModel, QLCCClient, RoleRightWebModel, SysModel, SysMenu, SysActionType } from '../../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../../shared/service-proxies/config';
import { useHistory } from 'react-router-dom';
import BlockUI from '../../../../components/Block-UI/Block-ui';
import ResidentAction from '../../actions/resident-action/ResidentAction';
import moment from 'moment';
import notification from '../../../../common/notification/notification';
import DataGrid from 'devextreme-react/data-grid';
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DHSToolbarRole from '../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import { ACTIONS } from '../../../../common/enums/DHSToolbarRoleAction';
import { getDataRowSelected, getIndexRowSelected } from '../../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../../common/Interfaces/StatusMessage';

const convert = ["success", "error", "warning"];
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}
interface IProps {
    apartment_id?: string,
    apartment_building_id?: string,
    floor_id?: string,
    title?: string,
    onEditSuccess: () => void,
}



const roleMenu: string = 'CC';
const status_message = ['success', 'error']
const ApartmentMemberList: React.FC<IProps> = (props) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const dispatch = useDispatch();
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const history = useHistory();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    // const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<any[]>([]);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);

    const [actCx, setActCx] = useState<IAction>(defaultAction);

    // 

    const dataGridRef = React.useRef<DataGrid>(null);





    // 

    // useEffect(() => {
    //     
    // })
    useEffect(() => {
        setLoading(true)
        homeClient.roleRightWebGetAll({
            ...new RoleRightWebModel(),
            menuid: '50.20.01',
            rolename: roleMenu,
            username: 'DHSOFT',
        } as RoleRightWebModel)
            .then((res) => {

                dispatch(getRoles(res));
                setRoles(res);
                setLoading(false);

            })



        // setRoles(roleInit);


    }, [])

    useEffect(() => {
        if (props.apartment_id) {
            setLoading(true)
            qLCClient.qLCCResidentsGet({
                ...new QLCCResidentsModel(),
                apartment_id: props.apartment_id,
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: props.apartment_building_id,
                action_type: "APARTMENT_MEMBER"
            } as QLCCResidentsModel).then((res) => {
                setLoading(false)
                const tempData: any[] = []
                res.forEach(resident => {
                    tempData.push({
                        ...resident,
                        date_of_birth: resident?.date_of_birth ? moment(resident.date_of_birth).format("DD/MM/yyyy") : null,
                        move_in_date: resident?.move_in_date ? moment(resident.move_in_date).format("DD/MM/yyyy") : null,
                        move_out_date: resident.move_out_date ? moment(resident.move_out_date).format("DD/MM/yyyy") : null
                    })
                })
                setDataTable(tempData)
            })
            // props.onEditSuccess();
        }
    }, [props.apartment_building_id, props.apartment_id, reload])

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {

        switch (action?.action_code?.toUpperCase()) {
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<QLCCResidentsModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            if (window.confirm("Bạn có chắc chắn muốn xóa?")) {
                                setLoading(true)
                                qLCClient.qlccResidentsDel({
                                    ...new QLCCResidentsModel(),
                                    apartment_id: props.apartment_id,
                                    ma_cty: SessionLogin?.ma_cty,
                                    apartment_building_id: apartment_building_id,
                                    id: dataSelected?.id,
                                } as QLCCResidentsModel)
                                    .then((res) => {
                                        setLoading(false)

                                        notification(status_message[res.status!], res.message);
                                        setReload(!reload)
                                    });
                            }
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: action?.action_code?.toUpperCase() ?? "",
                    // payload: idItemSelected
                })
                break;
            case ACTIONS.EDIT:
            case ACTIONS.VIEW:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<QLCCResidentsModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: action?.action_code?.toUpperCase() ?? "",
                                payload: {
                                    id: dataSelected?.id ?? null,
                                    ma_cty: dataSelected?.ma_cty
                                }
                            });
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
        }
    }
    return (


        <div className='container-fluid p-0'>

            {
                actCx.open && <ResidentAction
                    open={actCx.open}
                    onClose={function (): void {
                        setActCx(defaultAction);
                        setIdItemSelected("")
                    }}
                    apartment_id={props.apartment_id}
                    floor_id={props.floor_id}
                    title={props.title ?? "Cư dân"}
                    action={actCx.type}
                    action_type='MEMBER_APARTMENT'
                    itemSelected={actCx.payload}
                    onEditSuccess={function (data: SysModel): void {
                        setReload(pre => !pre)

                    }} >
                </ResidentAction>
            }


            {/* <DHSToolbar
                id={idItemSelected}
                list={roles
                    ? roles.filter(
                        (item) => item.on_form?.toLocaleUpperCase() === "VIEWMEMBER"
                    )
                    : []}
                customMenu={[]}
                onClickAction={handleClickAction}
            /> */}

            <DHSToolbarRole
                id={' '}
                menu={{
                    ...menu,
                    menuid: '50.20.01',
                } as SysMenu}
                customMenu={[]}
                onClickAction={handleClickAction}
            />

            <DHSDataGridEdit
                data={dataTable}
                table_name={"QLCCApartmentMember"}
                keyExpr="id"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
            handleSelectionChanged={async (e: any) => console.log(props.apartment_id, "apID", await getDataRowSelected(dataGridRef), "event") }
            // handlePageChange={handlePageChange}

            // handleExporting={handleExporting}
            />
            {/* <DHSGridView
                onSelectRow={(row) => {
                    
                    setIdItemSelected(row);
                }}
                // onListSelectRow={handleListOnselectRow}
                itemClicked={function (): void {
                    throw new Error("Function not implemented.");
                }}
                table_name={"QLCCApartmentMember"}
                show_form_list={true}
                showSelectBox={false}
                data={dataTable}
                showSortPanel={isShowSort}
                showSearchPanel={isShowSearch}
                showGroupPanel={isShowGroup}
                showFilterPanel={isShowFilter}
                defaultPageSize={2000}
                grid_name={"Danh sách thành viên cư dân"}
                excel={false}
            /> */}
            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default ApartmentMemberList;


