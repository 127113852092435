import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    CircularProgress,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IReducer } from '../../../../common/Interfaces/Interfaces';
import { getRoles } from '../../../../redux/actions/RoleActions';
import { AppSession } from '../../../shared/app-session/app-session';
import {
    HomeClient,
    QLCCBlockModel,
    QLCCClient,
    QLCCDomesticWaterCycleModel,
    QLCCDomesticWaterModel,
    QLCCDomesticWaterReportModel,
    QLCCFloorModel,
    RoleRightWebModel,
    SoVch05M
} from '../../../shared/service-proxies/api-shared';
import BASE_URL_API from "../../../shared/service-proxies/config";
import HomeIcon from '@mui/icons-material/Home';
import './domestic-water-floor.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DomesticWater from '../../actions/domestic-water-action/DomesticWaterAction';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import WaterDamageRoundedIcon from '@mui/icons-material/WaterDamageRounded';
import { Checkbox, Tooltip } from 'antd';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { Notification } from '../../../../components/layouts';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import DHSBreadcrumb from '../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import { FormatMoney } from '../../../../components/controller/Format';
import DHSLoadPanel from '../../../../components/DHSComponents/dialogs-and-notifications/DHSLoadPanel/DHSLoadPanel';
import moment from 'moment';

interface IProps {
    isSelected: string;
}

interface IAction {
    open: boolean;
    type: string | undefined;
    payload: string | undefined;
    item: any;
    domesticWaterCyclePre: any;
}

interface IReportDomesticWaterByBlock {
    block_id: string | undefined,
    enter: number,
    total: number,
    percent_enter: number,
}


let appSession: AppSession;

const menuRoleName = "CC";

const defaultValueDomesticWaterReportByBlock = {
    block_id: undefined,
    enter: 0,
    total: 0,
    percent_enter: 0
} as IReportDomesticWaterByBlock

const DomesticWaterFloor = (Props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const location = useLocation();
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const [qlccFloorList, setQLCCFloorList] = React.useState<QLCCFloorModel[]>([] as QLCCFloorModel[]);
    const [qlccBlockList, setQLCCBlockList] = React.useState<QLCCBlockModel[]>([] as QLCCBlockModel[]);
    const [qlccDomesticWaterList, setDomesticWaterList] = React.useState<QLCCDomesticWaterModel[]>([] as QLCCDomesticWaterModel[]);
    const [qlccDomesWaterCycle, setDomesticWaterCycle] = React.useState<QLCCDomesticWaterCycleModel>({} as QLCCDomesticWaterCycleModel);
    const [domesWaterCycleList, setDomesticWaterCycleList] = React.useState<QLCCDomesticWaterCycleModel[]>([] as QLCCDomesticWaterCycleModel[]);
    const [domesticWaterDefault, setDomesticWaterDefault] = React.useState<string>("");
    const [indexSelectCycle, setIndexSelectCycle] = React.useState<number>(0);
    const [blockID_, setBlockID_] = React.useState<string>("");
    const [reload, setReload] = React.useState(false);
    /* load hiệu ứng */
    const [onloadBlock, setOnloadBlock] = React.useState<boolean>(false);
    const [refresh, setRefresh] = React.useState(false);

    const [action, setAction] = React.useState<IAction>({
        open: false,
        type: "",
        payload: "",
        item: {},
        domesticWaterCyclePre: {},
    });

    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
    const [domesticWaterReport, setDomesticWaterReport] = React.useState<QLCCDomesticWaterReportModel>();
    const [domesticsWaterReportByBlock, setDomesticWaterReportByBlock] = React.useState<IReportDomesticWaterByBlock>(defaultValueDomesticWaterReportByBlock)

    async function OnloadReportDomesticWater(param?: string | undefined) {
        const res = await qLCCClient.qLCCDomesticWaterReportGet({
            ... new QLCCDomesticWaterReportModel(),
            ma_cty: SessionLogin.ma_cty,
            apartment_building_id: apartment_building_id,
            domestic_water_cycle_id: param
        } as QLCCDomesticWaterReportModel);
        const item = res.pop();
        setDomesticWaterReport(item)
    }


    /* load - danh sách tòa nhà, danh sách các chu kì */
    React.useEffect(() => {
        // setLoading(true);
        qLCCClient
            .qlccBlockGet({
                ... new QLCCBlockModel(),
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCBlockModel)
            .then(res => {
                setQLCCBlockList(res);
                setBlockID_(res[0].id || "");
                onloadFloor(res[0].id || "");
            });
        qLCCClient
            .qLCCDomesticWaterCycleGet({
                ... new QLCCDomesticWaterCycleModel(),
                // apartment_building_id: _id,
                apartment_building_id: apartment_building_id,
                action_type: 'ENTER-WATER',
                ma_cty: SessionLogin.ma_cty,

            } as QLCCDomesticWaterCycleModel)
            .then(res => {
                const date = new Date();
                /* lấy của tháng trước đó làm mặc định */ // Nếu có 1 item thì lấy nó luôn 
                // const temp = res.length === 1 ? res[0] : res.find(item => item.month === date.getMonth() + 1  && item.year === date.getFullYear());
                const dateNow = moment().month() === 0 ? 11 : moment().month() -1 ;
                const yearNow = moment().year();
                const getItem = res.find(item => moment(item.to_date).month() === dateNow  && moment(item.to_date).year() === yearNow)
                const temp = getItem ? getItem : res[0];

                if (temp) {
                    setDomesticWaterCycle(temp);
                    OnloadReportDomesticWater(temp?.id)
                    setDomesticWaterDefault(temp.id || "");
                    setIndexSelectCycle(0);
                } else {
                    setDomesticWaterDefault(res[0]?.id || "");
                    setDomesticWaterCycle(res[0]);
                    setIndexSelectCycle(0);
                }
                setDomesticWaterCycleList(res);
                // setLoading(false);
            })
            .catch((err) => {
                // setLoading(false);
            })
    }, [apartment_building_id])

    /* load - danh sách phiếu nước sinh hoạt */
    const onloadDomesticWater = (blockID: string) => {
        setLoading(true);
        qLCCClient
            .qLCCDomesticWaterGet({
                ...new QLCCDomesticWaterModel(),
                domestic_water_cycle_id: qlccDomesWaterCycle.id,
                block_id: blockID,
                // apartment_building_id: _id,
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCDomesticWaterModel)
            .then((res) => {
                setDomesticWaterList(res);

                // Thống kê số lượng của từng dự án
                const total = res.length;
                if (total > 0) {
                    const enter = res.filter(item => item.enter_date).length;
                    const percent = enter > 0 ? enter / total * 100 : 0;

                    setDomesticWaterReportByBlock({
                        block_id: blockID,
                        enter: enter,
                        percent_enter: percent,
                        total: total
                    } as IReportDomesticWaterByBlock)
                } else {
                    setDomesticWaterReportByBlock(defaultValueDomesticWaterReportByBlock)
                }

                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    }

    /* load - danh sách tầng */
    const onloadFloor = (blockID: string) => {
        // setLoading(true);
        qLCCClient
            .qLCCFloorGet({
                ...new QLCCFloorModel(),
                block_id: blockID,
                // apartment_building_id: _id,
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCFloorModel)
            .then((res) => {
                let arrTemp = res;
                arrTemp.sort((a: any, b: any) => a.number_floor - b.number_floor);
                setQLCCFloorList(arrTemp);
                // setLoading(false);
                setTimeout(() => {
                    setOnloadBlock(false);
                }, 500)
            }).catch((err) => {
                // setLoading(false);
            })
    }

    React.useEffect(() => {
        onload();
    }, [qlccFloorList, reload])

    const onload = () => {
        if (qlccFloorList.length > 0) {
            if (blockID_ && blockID_ != "") {
                onloadDomesticWater(blockID_);
            }
            else {
                var blockID = qlccFloorList[0].block_id;
                if (blockID) onloadDomesticWater(blockID);
            }
        }
    }

    const hanldeClickBlock = (e: any) => {
        setOnloadBlock(true);
        const blockID = e.target.id;
        setBlockID_(blockID);
        onloadFloor(blockID);
        if (onloadBlock) setOnloadBlock(false);
    }

    const hanldeClick = (e: any) => {
        setAction({
            ...action,
            type: indexSelectCycle <= domesWaterCycleList.findIndex(item => item.id === domesticWaterDefault) ? "UPDATE" : "DETAIL",
            open: true,
            payload: e.id,
            item: e,
            domesticWaterCyclePre: domesWaterCycleList[indexSelectCycle + 1]
        });
    }

    const handleChangeIntput = (e: any) => {
        const { name, value } = e.target;
        if (name.toUpperCase() == "DOMESTICWATERCYCLE") {
            // const newValue = domesWaterCycleList.filter(item => item.id == value);
            const newValue = domesWaterCycleList.find(item => item.id == value);
            if (newValue) {
                setIndexSelectCycle(domesWaterCycleList.findIndex(item => item.id == value));
                setDomesticWaterCycle(newValue);
                OnloadReportDomesticWater(value);
                if (blockID_ && blockID_ != "") {
                    onloadFloor(blockID_);
                }
            }
        }
    }

    const styleSuccess: any = (qlccDomesticWaterList: any, id_: string) => {
        const complete: number = qlccDomesticWaterList.filter((element: any) => element.floor_id == id_ && element.enter_status == true).length;
        const taskComplete: number = qlccDomesticWaterList.filter((element: any) => element.floor_id == id_).length;
        if (taskComplete == 0) {
            return {
                backgroundColor: '#9BA4B4'
            }
        } else if (complete == 0) {
            return {
                backgroundColor: '#dc3545'
            }
        }
        else if (complete == taskComplete) {
            return {
                backgroundColor: '#198754'

            }
        } else if (taskComplete > complete) {
            return {
                backgroundColor: '#F7C04A'
            }
        }
        return {
            backgroundColor: '#ffc107'
        }
    }

    const checkCycle = () => {
        if (
            domesticWaterDefault
            && qlccDomesWaterCycle
            && qlccDomesWaterCycle.month
            && qlccDomesWaterCycle.year)
            if (
                qlccDomesWaterCycle.month == (new Date()).getMonth() + 1
                && qlccDomesWaterCycle.year == (new Date()).getFullYear()
            )
                return true;
            else {
                return domesticWaterDefault == qlccDomesWaterCycle.id
            }
    }

    const selectBlock = (floor_id: string) => {
        return `${blockID_ == floor_id ? "selected" : "unselected"} btn-hover block-btn`;
    }

    const handleSubmit = (data: any) => {
        // setLoading(true)
        setAction({
            ...action,
            open: false,
            type: "",
            payload: "",
            item: {}
        });
    };

    const handlerefresh = () => {
        setRefresh(true);
        onload();
        OnloadReportDomesticWater(qlccDomesWaterCycle.id);
        setTimeout(() => {
            Notification("success", "Làm mới lại danh sách");
            setRefresh(false);
        }, 1000);
    }

    const calTotalsInfor = () => {
        // tổng số căn hộ
        const apartmentTotal = qlccDomesticWaterList.length;
        // tổng số thanh toán
        const paymentTotal = qlccDomesticWaterList.filter(item => item.pay_status == true).length;
        // tổng số chưa thanh toán
        const notpayTotal = Math.abs(apartmentTotal - paymentTotal);
        // tổng số đã cập nhật
        const updateTotal = qlccDomesticWaterList.filter(item => item.enter_status == true).length;
        const notpayTotal2 = Math.abs(updateTotal - paymentTotal);
        // phần trăm cập nhật
        const persentEnter = updateTotal / apartmentTotal * 100;
        // phần trăm thanh toán
        const persentPay = paymentTotal / updateTotal * 100;
        // tỉ lệ chưa thanh toán
        const persentNotPay = Math.abs(updateTotal - paymentTotal) / updateTotal * 100;

        return {
            apartmentTotal: apartmentTotal,
            paymentTotal: paymentTotal,
            notpayTotal: notpayTotal2,
            updateTotal: updateTotal,
            persentEnter: isNaN(persentEnter) ? 0 : persentEnter.toFixed(2),
            persentPay: notpayTotal2 == 0 ? 0 : persentPay.toFixed(2),
            persentNotPay: notpayTotal2 == 0 ? 0 : persentNotPay.toFixed(2),
        }
    }

    const calPersentEnterInfoFloor = (data: QLCCDomesticWaterModel[], floor_id: string) => {
        let haveEnter = qlccDomesticWaterList.filter(element => element.floor_id == floor_id && element.enter_status == true).length;
        let notEnter = qlccDomesticWaterList.filter(element => element.floor_id == floor_id).length;
        let persentEnter = 0;
        if (haveEnter != 0 && notEnter != 0) persentEnter = haveEnter * 100 / notEnter;
        return {
            haveEnter: haveEnter,
            notEnter: notEnter,
            persentEnter: persentEnter.toFixed(2)
        }
    }

    return (
        <div>
            {/* <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <i
                    className="bi bi-arrow-left-short"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.goBack()}
                ></i>
                &nbsp; Cập nhật chỉ số nước căn hộ

            </div> */}
            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 20,
                    marginLeft: 15,
                    marginBottom: 10,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb location={location} />
                <Button
                    variant="outlined"
                    style={{ marginLeft: 20, paddingTop: 0, paddingBottom: 0, minWidth: 100 }}
                    onClick={handlerefresh}
                >
                    {refresh ? <CircularProgress size={18} /> : "Làm mới"}
                </Button>
            </div>

            {React.useMemo(() => {
                return (
                    <DomesticWater
                        open={action.open}
                        onClose={() => {
                            setAction({
                                ...action,
                                open: false,
                                type: "",
                                payload: "",
                                item: {}
                            });
                        }}
                        item={action.item}
                        domesticWaterCyclePresent={domesticWaterDefault}
                        onEditSuccess={function (is_success: any): void {
                            setReload(pre => !pre)
                        }}
                        action={action.type}
                        itemSelected={action.payload}
                        onSubmit={handleSubmit}
                        domesticWaterCycle={qlccDomesWaterCycle.id}
                        domesticWaterCyclePrevious={action.domesticWaterCyclePre}
                    />
                );
            }, [action.open, setReload])}
            <Grid container spacing={1}
            >
                <Grid item xs={12} sm={4} md={4} lg={3}>
                    <Box
                        className="d-flex justify-content-center"
                    >
                        <Box
                            sx={{
                                width: '80%'
                                , marginTop: '1.5rem'
                            }}
                        >

                            <InputLabel htmlFor="input-with-domestic-water-cycle">
                                Bước 1. Chọn kỳ nước
                            </InputLabel>
                            <Select
                                className="text-center"
                                sx={{
                                    width: '98%',
                                }}
                                size='small'
                                id="input-with-domestic-water-cycle"
                                name="DOMESTICWATERCYCLE"
                                onChange={handleChangeIntput}
                                value={qlccDomesWaterCycle.id || ""}
                            >
                                {
                                    domesWaterCycleList &&
                                    domesWaterCycleList.map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item.id}
                                        >
                                            <span style={{ fontSize: 14 }}>
                                                {item.title}
                                            </span>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Box>
                    </Box>
                    <Box
                        className="d-flex justify-content-center"
                    >
                        <Box
                            sx={{
                                marginTop: '1.5rem'
                                , width: '80%'
                            }}
                        >
                            <InputLabel htmlFor="input-with-domestic-water-block">
                                Bước 2. Chọn tòa nhà ({qlccBlockList && (`${qlccBlockList.length}`)})
                            </InputLabel>
                            <Box
                                id="input-with-domestic-water-block"
                                className='hidden-scrollbar width-scrollbar-hoa-don'
                            // className='hidden-scrollbar width-scrollbar'
                            >

                                {
                                    qlccBlockList &&
                                    qlccBlockList.map((floor) => (
                                        <Button
                                            className={selectBlock(floor.id || "")}
                                            key={floor.id}
                                            id={floor.id}
                                            onClick={hanldeClickBlock}
                                        >
                                            {(onloadBlock && blockID_ == floor.id) ? <CircularProgress size={18} color='inherit' /> : floor.name}
                                        </Button>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={9}>
                    <InputLabel
                        htmlFor="input-with-domestic-water-block"
                        className='label-input-domestic-water d-flex justify-content-between align-items-center'
                    >
                        <Grid container
                            sx={{
                                marginRight: '3%',
                            }}
                        >
                            <Grid
                                item xs={12} sm={5} md={8}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                Bước 3. Số tầng {blockID_ && qlccFloorList && (`(${qlccFloorList.length})`)}
                                <Tooltip title={(
                                    <div className="sub-title-about" style={{ marginTop: 4 }}>
                                        <div className="item-block"><span className="sub-circle" style={{ backgroundColor: '#198754', marginLeft: 7, marginRight: 7 }}></span><span className='sub-circle-title'>Đã hoàn thành</span></div>
                                        <div className="item-block"><span className="sub-circle" style={{ backgroundColor: '#F7C04A', marginLeft: 7, marginRight: 7 }}></span><span className='sub-circle-title'>Chưa hoàn thành</span></div>
                                        <div className="item-block"><span className="sub-circle" style={{ backgroundColor: '#dc3545', marginLeft: 7, marginRight: 7 }}></span><span className='sub-circle-title'>Chưa cập nhật</span></div>
                                        <div className="item-block"><span className="sub-circle" style={{ backgroundColor: '#9BA4B4', marginLeft: 7, marginRight: 7 }}></span><span className='sub-circle-title'>Tầng trống</span></div>
                                        <p></p>
                                        <div className="item-block" style={{ marginLeft: 7 }}>
                                            <WaterDamageRoundedIcon
                                                sx={{
                                                    fontSize: "20px",
                                                }}
                                            />
                                            <span style={{ marginLeft: 7, marginRight: 4 }}>
                                                Cập nhật chỉ số nước
                                            </span>
                                        </div>
                                        <div className="item-block" style={{ marginLeft: 7 }}>
                                            <PriceCheckIcon
                                                sx={{
                                                    fontSize: "20px",
                                                }}
                                            />
                                            <span style={{ marginLeft: 7, marginRight: 4 }}>
                                                Tình trạng thanh toán
                                            </span>
                                        </div>
                                        <div className="item-block" style={{ marginLeft: 7 }}>
                                            <AccessTimeIcon
                                                sx={{
                                                    fontSize: "20px",
                                                }}
                                            />
                                            <span style={{ marginLeft: 7, marginRight: 4 }}>
                                                Quá hạn thanh toán
                                            </span>
                                        </div>
                                    </div>
                                )}
                                    color={'#408E91'}
                                    placement="bottom"
                                    overlayStyle={{ width: '500px' }}
                                >
                                    <ContactSupportIcon style={{ color: '#4D648D' }} />
                                </Tooltip>
                            </Grid>
                            <Grid xs={12} sm={7} md={4}
                                sx={{
                                    marginBottom: '10px',
                                }}
                            >
                                {/* <p className='margin-0 text-center text-uppercase text-danger'>
                                    <b>{qlccBlockList && qlccBlockList.find(item => item.id == blockID_)?.name}</b>
                                </p> */}
                                <p className='margin-0'>
                                    <span className='width-50percent display-inline-block'>
                                        <b>Tỉ lệ đã cập nhật của dự án:</b>
                                    </span>
                                    <span className='width-40percent'>
                                        {/* {calTotalsInfor().persentEnter}% ({calTotalsInfor().updateTotal}/{calTotalsInfor().apartmentTotal}) */}
                                        {domesticWaterReport?.col}% ({domesticWaterReport?.enter}/{domesticWaterReport?.apartment})
                                    </span>
                                </p>
                                {/* <p className='margin-0'>
                                    <span className='width-50percent display-inline-block'>
                                        <b>Tỉ lệ đã cập nhật của tòa nhà:</b>
                                    </span>
                                    <span className='width-40percent'>
                                         {domesticsWaterReportByBlock?.percent_enter.toFixed(1)}% ({domesticsWaterReportByBlock?.enter}/{domesticsWaterReportByBlock?.total})
                                    </span>
                                </p> */}
                            </Grid>
                        </Grid>

                    </InputLabel>
                    <Box>
                        {
                            qlccFloorList && <div>

                                <div
                                    className='minWidth_150-maxWidth_300-inline_block-fontWeight_500'
                                >
                                </div>
                                <span
                                    className='icon-border-left-1-padding-5 icon-inline-block-100'
                                >
                                    <WaterDamageRoundedIcon
                                        sx={{
                                            fontSize: "24px",
                                        }}
                                        color='primary'
                                    />
                                    {qlccDomesticWaterList &&
                                        (
                                            `(${domesticsWaterReportByBlock?.enter}/${domesticsWaterReportByBlock?.total})`
                                        )
                                    }

                                </span>
                                <span className='icon-inline-block-50 text-percent'>
                                    {qlccDomesticWaterList &&
                                        (
                                            `${domesticsWaterReportByBlock?.percent_enter.toFixed(1)}%`
                                        )
                                    }
                                </span>
                            </div>
                        }
                    </Box>
                    <Box
                        className='hidden-scrollbar width-scrollbar-floor'
                    >

                        {
                            qlccFloorList &&
                            qlccFloorList.map(item => (
                                <Accordion
                                    key={item.id}
                                    sx={{
                                        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;',
                                    }}
                                    style={styleSuccess(qlccDomesticWaterList, item.id)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography
                                            sx={{
                                                color: '#e4eefc',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}

                                        >
                                            <div
                                                className='minWidth_150-maxWidth_300-inline_block-fontWeight_500'
                                            >
                                                {item.name}
                                            </div>
                                            <span
                                                className='icon-border-left-1-padding-5 icon-inline-block-100'
                                            >
                                                <WaterDamageRoundedIcon
                                                    sx={{
                                                        fontSize: "24px",
                                                    }}
                                                />
                                                {qlccDomesticWaterList &&
                                                    (
                                                        `(${calPersentEnterInfoFloor(qlccDomesticWaterList, item.id || "").haveEnter}/${calPersentEnterInfoFloor(qlccDomesticWaterList, item.id || "").notEnter})`
                                                    )
                                                }

                                            </span>
                                            <span className='icon-inline-block-50 text-percent'>
                                                {qlccDomesticWaterList &&
                                                    (
                                                        `${calPersentEnterInfoFloor(qlccDomesticWaterList, item.id || "").persentEnter}%`
                                                    )
                                                }
                                            </span>

                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            backgroundColor: "#F0F5F9"
                                        }}
                                    >
                                        <Typography>
                                            <Box>
                                                {
                                                    qlccDomesticWaterList &&
                                                    qlccDomesticWaterList.filter(element => element.floor_id == item.id).map(e => (
                                                        <div
                                                            key={e.id}
                                                            className='house-around'
                                                        >
                                                            <div className='house-around-second' >
                                                                <div
                                                                    onClick={(event) => hanldeClick(e)}
                                                                >
                                                                    <p
                                                                        id={e.apartment_id}
                                                                        className={e.enter_status && e.enter_status === true ? "house-icon house-color-pay-status-true" : "house-icon house-color-pay-status-false"}
                                                                    >
                                                                        <HomeIcon style={{ fontSize: '40px' }} />
                                                                    </p>
                                                                    <p
                                                                        className='house-title'
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                        }}
                                                                    >
                                                                        <span
                                                                            className='line-clamp-1'

                                                                        >
                                                                            {e.apartment_name}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    !qlccDomesticWaterList ||
                                                    (qlccDomesticWaterList &&
                                                        qlccDomesticWaterList.filter(element => element.floor_id == item.id).length == 0) && (
                                                        <div
                                                            style={{
                                                                textAlign: 'center'
                                                            }}
                                                        >
                                                            {
                                                                blockID_ &&
                                                                    blockID_ == "" ? (
                                                                    <h4 style={{ color: '#D8D8D8' }}>Vui lòng chọn tòa nhà!</h4>
                                                                ) : (
                                                                    <h5 style={{ color: '#D8D8D8' }}>Không có căn hộ</h5>
                                                                )
                                                            }

                                                        </div>
                                                    )
                                                }
                                            </Box>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))
                        }
                        {
                            (qlccFloorList &&
                                qlccFloorList.length == 0
                            ) && (
                                <div
                                    style={{
                                        textAlign: 'center'
                                    }}
                                >
                                    {
                                        blockID_ &&
                                        blockID_ != "" && (
                                            <h5 style={{ color: '#D8D8D8' }}>Tầng trống</h5>
                                        )
                                    }
                                </div>
                            )
                        }
                        {
                            !blockID_ &&
                            (
                                <div
                                    style={{
                                        textAlign: 'center'
                                    }}
                                >
                                    <h5>Chọn tòa nhà</h5>
                                </div>
                            )
                        }
                    </Box>
                </Grid>
            </Grid>
            <DHSLoadPanel open={isLoading} />
        </div >
    )
}

export default DomesticWaterFloor