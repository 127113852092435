import React, { Component } from "react";
import PieChart, {
  Series,
  Label,
  Connector,
  Legend,
  Title,
  CommonSeriesSettings,
  SeriesTemplate,
} from "devextreme-react/pie-chart";
import _ from "lodash";
import { connect } from "react-redux";
import { MachineRptClient, ModelHandlePD, PDMachineStatusClient } from "../../../../app/shared/service-proxies/api-shared";
import { AppSession } from "../../../../app/shared/app-session/app-session";
import BASE_URL_API from "../../../../app/shared/service-proxies/config";
let appSession: AppSession;
const Pie = (props: any) => {
  const machineRptClient = new MachineRptClient(appSession, BASE_URL_API);
  const pDMachineStatusClient = new PDMachineStatusClient(appSession, BASE_URL_API);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [listMachineActionState, setListMachineActionState] = React.useState<any>();
  React.useEffect(() => {
    machineRptClient.getLstActionState({ ...new ModelHandlePD(), mA_CTY: SessionLogin.ma_cty } as ModelHandlePD).then(res => {
      setListMachineActionState(res)
    })
  }, []);
  const formatLabel = (arg: any) => {
    const value = (parseInt(arg.value) / props.totalDuration) * 100;
    return `${_.round(value, 2)} %`;
  };
  const customizePoint = (valueFromNameField: any) => {
    var color = "";
    if (props.listMachineActionState.length > 0) {
      props.listMachineActionState.map((item: any, index: any) => {
        if (item.DESCRIPTION === valueFromNameField.argument) {
          color = item.BACKCOLOR;
        }
      });
    }
    return color !== "" ? { color: color } : {};
  };
  // const { legendVisible, title, dataSource } = this.props;
  return (
    <div>
      <PieChart
        id="pie"
        type="doughnut"
        dataSource={props.dataSource}
        customizePoint={customizePoint}
        resolveLabelOverlapping="shift"
      >
        <Title text={props.title} font={{ size: 18, color: "#1890ff" }} />
        <CommonSeriesSettings
          argumentField="state_description"
          valueField="duration"
        >
          <Label
            visible={true}
            customizeText={formatLabel}
            format="fixedPoint"
          >
            <Connector visible={true} width={0.5} />
          </Label>
        </CommonSeriesSettings>
        <SeriesTemplate nameField="machine_code" />
        {props.legendVisible ? (
          <></>
        ) : (
          <Legend
            horizontalAlignment="center"
            verticalAlignment="bottom"
            border={{ visible: true }}
          />
        )}
      </PieChart>
    </div>
  );
}




export default Pie;
