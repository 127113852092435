import {PUSH_URL_LEVEL_1, PUSH_URL_LEVEL_2} from "../constants/ActionTypes";

export const pushPathLevel1 = (payload: string) => (dispatch: any, getState: any) => {
    dispatch({
      type: PUSH_URL_LEVEL_1,
      payload
    })
  }

export const pushPathLevel2 = (payload: string) => (dispatch: any, getState: any) => {
    dispatch({
      type: PUSH_URL_LEVEL_2,
      payload
    })
  }