import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { components } from "../../../../common/ComponentsConfig/components";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import notification from "../../../../common/notification/notification";
import DHSToolbar from "../../../../components/DHS/DHS-Toolbar/DHSToolbar";
import DHSGridView from "../../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { getRole, getRoles } from "../../../../redux/actions/RoleActions";
import { AppSession } from "../../../shared/app-session/app-session";
import {
  HomeClient,
  HRClient,
  LeaveForm,
  OvertimeForm,
  RoleRightWebModel,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";

let appSession: AppSession;
const roles: any[] = [
  {
    menuid: "02.00.00",
    code: "02.00.01",
    name_vn: "Thêm",
    class_name: "",
    on_form: "View",
    icon: "bi bi-pencil-fill",
    link: "/admin/list-leave-form/new-form",
    component: "WorkerLeaveForm",
    enable_default: true,
    is_parameter: false,
    disabled: false,
  },
  {
    menuid: "02.00.00",
    code: "02.00.02",
    name_vn: "Sửa",
    class_name: "",
    on_form: "View",
    icon: "bi bi-pencil-square",
    link: "/admin/list-leave-form/edit-form",
    component: "WorkerLeaveForm",
    enable_default: false,
    is_parameter: true,
    disabled: false,
  },
  {
    menuid: "03.00.00",
    code: "03.00.01",
    name_vn: "Thêm",
    class_name: "",
    on_form: "View",
    icon: "bi bi-pencil-fill",
    link: "/admin/list-overtime-form/new-form",
    component: "WorkerOvertimeForm",
    enable_default: true,
    is_parameter: false,
    disabled: false,
  },
  {
    menuid: "03.00.00",
    code: "03.00.02",
    name_vn: "Sửa",
    class_name: "",
    on_form: "View",
    icon: "bi bi-pencil-square",
    link: "/admin/list-overtime-form/edit-form",
    component: "WorkerOvertimeForm",
    enable_default: false,
    is_parameter: true,
    disabled: false,
  },
];
const WorkerGrid = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [dataTable, setDataTable] = React.useState<any[]>([]);
  // const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
  const { path, url } = useRouteMatch();
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const hrClient = new HRClient(appSession, BASE_URL_API);

  const [itemSelected, selectItem] = React.useState<string>();
  const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
  const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
  const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
  const [isShowSort, setShowSort] = React.useState<boolean>(false);
  const [isShowSelect, setShowSelect] = React.useState<boolean>(false);
  const [back, setBack] = React.useState<number>(-1);
  const customMenu = [
    {
      menuName: isShowSelect ? "Ẩn Select Box" : "Hiện Select Box",
      onClick: () => {
        setShowSelect(!isShowSelect);
      },
    },
    {
      menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
      onClick: () => {
        setShowFilter(!isShowFilter);
      },
    },
    {
      menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
      onClick: () => {
        setShowSearch(!isShowSearch);
      },
    },
    {
      menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
      onClick: () => {
        setShowGroup(!isShowGroup);
      },
    },
    {
      menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
      onClick: () => {
        setShowSort(!isShowSort);
      },
    },
  ];

  React.useEffect(() => {
    switch (menu?.component?.toUpperCase()) {
      case "WORKEROVERTIMEGRID":
        hrClient
          ?.hROvertimeGet({
            ...new OvertimeForm(),
            ma_cty: SessionLogin.ma_cty,
            sender_code: SessionLogin.employeeCode,
            action_type: 1,
          } as OvertimeForm)
          .then((respond) => {
            setDataTable(respond);
          });
        break;
      case "WORKERLEAVEGRID":
        hrClient
          ?.hROnLeaveGet({
            ...new LeaveForm(),
            ma_cty: SessionLogin.ma_cty,
            sender_code: SessionLogin.employeeCode,
            action_type: 1,
          } as LeaveForm)
          .then((respond) => {
            setDataTable(respond);
          });
        break;
    }
  }, [menu?.component]);

  const handleClickAction = () => {};

  return (
    <div className="container-fluid p-1">
      <Switch>
        <Route path={path} exact>
          <div
            className="d-flex title-color"
            style={{
              height: 30,
              color: "rgb(0 78 255 / 88%)",
              marginTop: 15,
              marginBottom: 15,
              fontSize: 22,
              fontWeight: 550,
            }}
          >
            <i
              className="bi bi-arrow-left"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.go(back);
              }}
            ></i>
            &nbsp;&nbsp;
            {menu?.bar}
          </div>{" "}
          <DHSToolbar
            id={itemSelected}
            list={roles.filter((item) => item.menuid === menu?.menuid)}
            onClickAction={handleClickAction}
            customMenu={customMenu}
          />
          <DHSGridView
            onSelectRow={(row) => {
              selectItem(row);
            }}
            // onListSelectRow={handleListOnselectRow}
            itemClicked={function (): void {
              throw new Error("Function not implemented.");
            }}
            table_name={
              menu?.component?.toLocaleUpperCase() === "WORKEROVERTIMEGRID"
                ? "TKOVERTIMEFORM"
                : "TKLEAVEFORM"
            }
            data={dataTable}
            show_form_list={true}
            showFilterPanel={isShowFilter}
            showSortPanel={isShowSort}
            showSearchPanel={isShowSearch}
            showGroupPanel={isShowGroup}
            showSelectBox={isShowSelect}
          />
        </Route>
        {roles &&
          roles.map((role, index) => {
            return (
              role.link &&
              role.link.length > 1 &&
              role.component && (
                <Route
                  key={index}
                  path={role.is_parameter ? role.link + ":_id" : role.link}
                  component={components[role.component.toLocaleUpperCase()]}
                  exact
                ></Route>
              )
            );
          })}
      </Switch>
    </div>
  );
};

export default WorkerGrid;
