import React from "react";
import { Button, Dialog, DialogContent, DialogActions, Slide, IconButton, AppBar, Toolbar, Typography, Divider, Grid, Box, Paper, InputLabel, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { HomeClient, QLCCClient, QLCCTaskDetailModel, DepartmentClient, LstDepartment, UserInfo, QLCCTaskGroupModel, QLCCTaskTypeModel, QLCCTaskStatusModel, HomePageClient, QLCCTaskGroupCycleModel, UserInfoFilterModel, UserInfoModel, } from "../../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../shared/service-proxies/config";
import { AppSession } from "../../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import { ACTIONS } from "../../../../../common/enums/DHSToolbarRoleAction";

import BlockUI from "../../../../../components/Block-UI/Block-ui";
import DHSSelectBox from "../../../../../components/DHS/DHS_SelectBox";
import DHSTextBox from "../../../../../components/DHS/DHS_TextBox";
import DHSDateTimeComponentV2 from "../../../../../components/DHS/DHS-DateTime/DHSDateTimeComponentV2";
import CheckBox from "devextreme-react/check-box";
import RichTextEditor from "../../../../../components/DHS/DHS_RichTextEditor";
import moment from "moment";
import { HintCode } from "../../../../../common/enums/DHSInputDetail";

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    data?: any;
    onClose: () => void;
    onEditSuccess: () => void;
    onSubmit: (e: any, actions: string) => void;
    start_time?: Date;
    ma_bp?: string | undefined;
    receiver?: string | undefined;
    groups: QLCCTaskGroupModel[];
    types: QLCCTaskTypeModel[];
    statusList: QLCCTaskStatusModel[];
    typeCode: string | undefined; // 1 : CÔNG VIỆC | 2: KẾT HOẠCH CÔNG VIỆC |3.CHECKLIST
    groupTask?: string | undefined;
    cycle?: QLCCTaskGroupCycleModel[];
    apartmentBuildingId: string | undefined;
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export function NEWID() {
    const length = 30;
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return `NEW${result}${new Date().getTime()}`;
}

var appSession: AppSession;
var htmlString: string;
const TYPE_CODE_OF_REPORT: string = "3";
const TYPE_CODE_TASK_SCRIPT: string = "2";
const TYPE_CODE_TASK: string = "1";
const TYPE_CODE_SCRIPT = "2" // CÔNG VIỆC THEO KẾ HOẠCH

const TaskChild = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    const departmentClient = new DepartmentClient(appSession, BASE_URL_API);
    const qLCCHome = new HomeClient(appSession, BASE_URL_API);
    const [data, setData] = React.useState<QLCCTaskDetailModel>({} as QLCCTaskDetailModel);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [departments, setDepartments] = React.useState<LstDepartment[]>([]);
    const [users, setUsers] = React.useState<UserInfo[]>([]);
    const qlccHomePage = new HomePageClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [Cycle, setCycle] = React.useState<QLCCTaskGroupCycleModel[]>([])
    const [isChangeDate, setChangeDate] = React.useState<boolean>(false);
    const [isChangeDate2, setChangeDate2] = React.useState<boolean>(false); // For action VIEW
    const [newContent, setNewContext] = React.useState<string>();



    const disable = (actions: string[]) => {
        const action = props.action;
        return !actions.includes(action)
    }


    React.useEffect(() => {
        qLCCHome.userInfoGet({
            ... new UserInfo(),
            ma_cty: SessionLogin.ma_cty,
            action_type: 'TASK-CHILD',
            apartment_building_id: props.apartmentBuildingId
        } as UserInfo)
            .then(res => {
                setUsers(res);
            })
            .catch(error => {
                console.log(error);
            })
    }, [])

    React.useEffect(() => {
        (async () => {
            if (props.open) {
                try {
                    setLoading(true);
                    const response = await departmentClient.get({
                        ... new LstDepartment(),
                        ma_cty: SessionLogin.ma_cty,
                        ma_ct: 'Ma_BP',
                    } as LstDepartment);
                    setDepartments([...response]);
                } catch (error) {
                    setDepartments([]);
                } finally {
                    setLoading(false);
                }

                if (props.cycle) {
                    setCycle([...props.cycle])
                } else {
                    const ResCycle = await qLCClient.qLCCTaskGroupCycleGet({
                        ... new QLCCTaskGroupCycleModel(),
                        action_type: "GET"
                    } as QLCCTaskGroupCycleModel)

                    setCycle([...ResCycle])
                }


                if (props.action === ACTIONS.ADD || props.action === ACTIONS.INSERT) {
                    const ResMyInfo = await qlccHomePage.userInfoWebFilterAdmin({
                        ... new UserInfoFilterModel(),
                        ma_cty: SessionLogin.ma_cty,
                        apartment_building_id: props.apartmentBuildingId ? props.apartmentBuildingId : '',
                        // user_login: props.receiver,
                        username: props.receiver,
                        // action_type: "GET-USER"
                    } as UserInfoFilterModel)

                    const myInfo = ResMyInfo[0];

                    setUsers([...ResMyInfo])

                    // LẤY TRẠNG THÁI ĐẦU TIÊN MẶT ĐỊNH
                    const status = await props.statusList.find(item => item.stt! < 2);
                    debugger

                    setData({
                        ...new QLCCTaskDetailModel(),
                        cdate: new Date(),
                        ldate: new Date(),
                        cuser: SessionLogin.userName,
                        luser: SessionLogin.userName,
                        percent: 0,
                        is_done: false,
                        sender: SessionLogin.userName,
                        start_time: props.action === ACTIONS.ADD ? props.start_time : new Date(props.start_time?.getTime()! + 60 * 1000),
                        end_time: props.action === ACTIONS.ADD ? moment(props.start_time?.getTime()! + 1000 * 3600).toDate() : new Date(props.start_time?.getTime()! - 60 * 1000),
                        status_id: status && status.id ? status.id : "",
                        group_id: props.groupTask ? props.groupTask : "",
                        department_id: myInfo && myInfo.department_id ? myInfo.department_id : '',
                        receiver: myInfo && myInfo.department_id ? myInfo.username : '',
                        receiver_name: myInfo && myInfo.fullname ? myInfo.fullname : '',
                        apartment_building_id: props.apartmentBuildingId
                    } as QLCCTaskDetailModel);
                    setChangeDate(pre => !pre);
                     
                } else if (props.action === ACTIONS.COPY) {
                    setData({
                        ...new QLCCTaskDetailModel(),
                        ...props.data,
                        id: NEWID(),
                        task_id: "",
                        ldate: new Date(),
                        luser: SessionLogin.userName,
                        start_time: props.start_time,
                        end_time: new Date(props.start_time?.getTime()! + 60 * 1000)
                    } as QLCCTaskDetailModel);
                    setNewContext(props.data.description);

                    // const ResContent = props.data.description;
                    // if (ResContent) {
                        // htmlString = ResContent;
                        // setNewContext(ResContent)
                    // }
                }
                else {
                    setData({
                        ...new QLCCTaskDetailModel(),
                        ...props.data,
                        ldate: new Date(),
                        luser: SessionLogin.userName,
                    } as QLCCTaskDetailModel);
                    const ResContent = await props.data.description;
                    if (ResContent) {
                        // htmlString = ResContent;
                        setNewContext(ResContent)
                    }

                    setChangeDate2(pre => !pre);
                    setChangeDate2(pre => !pre);

                }
            }else{
                setNewContext('');
            }
        })();
    }, [props.action, props.itemSelected, props.open]);

    React.useEffect(() => {
        if (isChangeDate) {
            setData({
                ...data,
                start_time: props.action === ACTIONS.ADD ? props.start_time : new Date(props.start_time?.getTime()! - 60 * 1000),
                end_time: props.action === ACTIONS.ADD ? moment(props.start_time?.getTime()! + 1000 * 3600).toDate() : new Date(props.start_time?.getTime()!),
            } as QLCCTaskDetailModel);
        }
    }, [isChangeDate])

    React.useEffect(() => {
        if (isChangeDate2) {
            setData({
                ...data,
                start_time: moment(props.data.start_time).toDate(),
                end_time: moment(props.data.end_time).toDate(),
                date_done: data.is_done ? moment(data.date_done).toDate() : undefined,
            } as QLCCTaskDetailModel);
        }
    }, [isChangeDate2])

    React.useEffect(() => {
        if (data.department_id && data.department_id !== '') {
            qLCCHome.userInfoGet({
                ... new UserInfo(),
                department_id: data.department_id,
                ma_cty: SessionLogin.ma_cty,
                apartment_building_id: props.apartmentBuildingId

            } as UserInfo)
                .then(res => {
                    setUsers(res);
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }, [data.department_id])

    React.useEffect(() => {
        if (data.percent) {
            if (data.percent === 100) {
                const status = props.statusList.find(item => item.stt! > 2);
                if (status) {
                    setData({
                        ...data,
                        status_id: status.id,
                    } as QLCCTaskDetailModel);
                }
            } else {
                const status = props.statusList.find(item => item.stt! < 2);
                if (status) {
                    setData({
                        ...data,
                        status_id: status.id,
                    } as QLCCTaskDetailModel);
                }
            }
        }
    }, [data.percent])

    const handleChangeInput = async (e: any) => {
        const { name, value } = e.target;
        if (name === 'receiver') {
            const findUser = users.find(item => item.username === value);
            setData({
                ...data,
                [name]: value,
                receiver_name: findUser?.fullname
            } as QLCCTaskDetailModel);
        } else {
            setData({ ...data, [name]: value } as QLCCTaskDetailModel);
        }
    }

    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        setData({ ...data, [name]: new Date(value) } as QLCCTaskDetailModel);
    }

    const handleSubmit = async () => {

        setChangeDate(pre => !pre)

        const newid = NEWID();

        if (props.typeCode === TYPE_CODE_SCRIPT) {
            if (data.cycle_id ? true : window.confirm('Cảnh báo chưa có thông tin chu kỳ công việc. Tiếp tục?')) {
                const newData = {
                    ...data,
                    id: props.action !== ACTIONS.VIEW && props.action !== ACTIONS.EDIT ? newid : data.id,
                    // description: htmlString,
                    description: newContent,
                } as QLCCTaskDetailModel;
                props.onSubmit(newData, props.action);
                props.onClose();
            }
        } else {
            const statusData = props.statusList.find(item => item.id === data.status_id);

            if (statusData) {
                const newData = {
                    ...data,
                    id: props.action !== ACTIONS.VIEW
                        && props.action !== ACTIONS.EDIT
                        && props.action !== ACTIONS.UPDATETASK ? newid : data.id,
                    description: newContent,
                    is_done: statusData.stt && statusData.stt > 2 ? true : false
                } as QLCCTaskDetailModel;
                props.onSubmit(newData, props.action);
                props.onClose();

                console.log(newData)
            }
        }
    }

    const handleSave = async () => {
        props.onSubmit({ ...data, description: newContent }, props.action);
        setData({
            ...new QLCCTaskDetailModel(),
            id: NEWID(),
            cdate: new Date(),
            ldate: new Date(),
            cuser: SessionLogin.userName,
            luser: SessionLogin.userName,
            percent: 0,
            is_done: false,
            sender: SessionLogin.userName,
            start_time: data.start_time,
            end_time: data.end_time,
        } as QLCCTaskDetailModel);
    }

    // const handleChangeRichText = (newContent: string) => {
    //     htmlString = newContent;
    // }


    const titleGetAction = () => {
        const menuComponentFeedback: string = 'QLCCFeedbacks'

        switch (props.action) {
            case ACTIONS.ADD:
                if (menu?.component === menuComponentFeedback)
                    return 'Giao việc';
                else
                    return 'Thêm mới'
            case ACTIONS.EDIT:
                return 'Chỉnh sửa';
            case ACTIONS.UPDATETASK:
                return 'Cập nhật tiến độ';
            case ACTIONS.SENTTASK_2:
                return 'Giao việc';
            case ACTIONS.SENTTASK:
                return 'Chuyển tiếp việc';
            case ACTIONS.VIEW:
                return 'Chi tiết';
            default:
                return 'Thêm mới';
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            className="d-flex justify-content-center"
            TransitionComponent={Transition}
            maxWidth="xl"
            fullWidth
        >
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${titleGetAction()}`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={props.typeCode === TYPE_CODE_TASK ? 8 : 12}>
                        <Paper
                            elevation={3}
                            variant="outlined"
                            sx={{
                                padding: "13px 10px"
                            }}>
                            <InputLabel className="text-center text-uppercase" sx={{ fontSize: 14, color: '#333', fontWeight: "bold" }}>Thông tin</InputLabel>
                            {/* <Box className="box-around">
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tiêu đề</InputLabel>
                                <DHSTextBox
                                    name='title'
                                    value={data?.title}
                                    onChange={handleChangeInput}
                                />
                            </Box> */}
                            <Box className="box-around">
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tên công việc</InputLabel>
                                <DHSTextBox
                                    name='name'
                                    value={data?.name}
                                    onChange={handleChangeInput}
                                    disabled={disable([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.INSERT, ACTIONS.COPY])}
                                    hint={HintCode.TASK_TITLE}
                                />
                            </Box>
                            <Box className="box-around">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Nhóm công việc</InputLabel>
                                        <DHSSelectBox
                                            dataSource={props.groups}
                                            name="group_id"
                                            onValueChanged={handleChangeInput}
                                            displayExpr="name"
                                            searchExpr="name"
                                            valueExpr="id"
                                            value={data?.group_id}
                                            disabled={disable([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.INSERT, ACTIONS.COPY])}
                                            hint={HintCode.TASK_GROUP}
                                        />
                                    </Grid>
                                    {
                                        props.typeCode === TYPE_CODE_SCRIPT
                                        && <>
                                            <Grid item xs={12} md={6}>
                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Chu kỳ công việc</InputLabel>
                                                <DHSSelectBox
                                                    dataSource={Cycle}
                                                    name="cycle_id"
                                                    onValueChanged={handleChangeInput}
                                                    displayExpr="name"
                                                    searchExpr="name"
                                                    valueExpr="id"
                                                    value={data?.cycle_id}
                                                    disabled={disable([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.INSERT, ACTIONS.COPY])}
                                                    hint={HintCode.TASK_CYCLE}
                                                />
                                            </Grid>
                                        </>
                                    }

                                </Grid>
                            </Box>
                            <Box className="box-around">
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mô tả</InputLabel>

                                {/* <RichTextEditor
                                    value={htmlString}
                                    onChange={(newContent) => handleChangeRichText(newContent)}
                                    readOnly={disable([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.INSERT, ACTIONS.COPY])}
                                /> */}
                                  {
                                    React.useMemo(() => {
                                        return <>
                                            <RichTextEditor
                                                value={newContent}
                                                onChange={setNewContext}
                                                // height={650}
                                                readOnly={props.action === ACTIONS.VIEW}
                                            />
                                        </>
                                    }, [data, props.open])
                                }
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {
                            props.typeCode === TYPE_CODE_TASK
                            && <>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Paper
                                            elevation={3}
                                            variant="outlined"
                                            sx={{
                                                padding: "13px 10px"
                                            }}>
                                            <InputLabel className="text-center text-uppercase" sx={{ fontSize: 14, color: '#333', fontWeight: "bold" }}>Phân công</InputLabel>
                                            <Box className="box-around">
                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Bộ phận</InputLabel>
                                                <DHSSelectBox
                                                    // defaultValue={props.ma_bp}
                                                    dataSource={departments}
                                                    name="department_id"
                                                    onValueChanged={handleChangeInput}
                                                    displayExpr="ten_bp"
                                                    searchExpr="ten_bp"
                                                    valueExpr="ma_bp"
                                                    value={data?.department_id ?? props.ma_bp}
                                                    disabled={disable([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.INSERT, ACTIONS.COPY])}
                                                    hint={HintCode.DEPARTMENT}
                                                />
                                            </Box>

                                            <Box className="box-around">
                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Phân công cho</InputLabel>
                                                <DHSSelectBox
                                                    // defaultValue={props.receiver}
                                                    dataSource={users}
                                                    name="receiver"
                                                    onValueChanged={handleChangeInput}
                                                    displayExpr="fullname"
                                                    searchExpr="fullname"
                                                    valueExpr="username"
                                                    value={data?.receiver ?? props.receiver}
                                                    disabled={disable([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.INSERT, ACTIONS.COPY])}
                                                    hint={HintCode.TASK_RECEIVER}
                                                />
                                            </Box>

                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper
                                            elevation={3}
                                            variant="outlined"
                                            sx={{
                                                padding: "13px 10px"
                                            }}>
                                            <InputLabel className="text-center text-uppercase" sx={{ fontSize: 14, color: '#333', fontWeight: "bold" }}>Tiến độ công việc</InputLabel>

                                            {
                                                props.typeCode !== TYPE_CODE_SCRIPT
                                                && <>
                                                    <Box className="box-around">
                                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Trạng thái</InputLabel>
                                                        <DHSSelectBox
                                                            dataSource={props.statusList}
                                                            name="status_id"
                                                            onValueChanged={handleChangeInput}
                                                            displayExpr="name"
                                                            searchExpr="name"
                                                            valueExpr="id"
                                                            value={data?.status_id}
                                                            disabled={disable([])}
                                                            hint={HintCode.TASK_STATUS}
                                                        />
                                                    </Box>
                                                </>
                                            }

                                            <Box className="box-around">
                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Bắt đầu</InputLabel>
                                                <DHSDateTimeComponentV2
                                                    name="start_time"
                                                    value={data.start_time}
                                                    onChange={(e: any) => {
                                                        handleChangeDate(e)
                                                    }}
                                                    disabled={disable([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.INSERT, ACTIONS.COPY, ACTIONS.UPDATETASK])}
                                                    maxDate={data?.end_time}
                                                    hint={HintCode.TASK_START_TIME}
                                                />
                                            </Box>
                                            <Box className="box-around">
                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Kết thúc</InputLabel>
                                                <DHSDateTimeComponentV2
                                                    name="end_time"
                                                    value={data.end_time}
                                                    onChange={(e: any) => {
                                                        handleChangeDate(e)
                                                    }}
                                                    disabled={disable([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.INSERT, ACTIONS.COPY, ACTIONS.UPDATETASK])}
                                                    minDate={data?.start_time}
                                                    hint={HintCode.TASK_END_TIME}
                                                />
                                            </Box>
                                            <Box className="d-flex box-around pt-2">
                                                <CheckBox
                                                    value={data.is_done}
                                                    id="is_done"
                                                    name="is_done"
                                                    onValueChange={e => handleChangeInput({
                                                        target: {
                                                            name: "is_done",
                                                            value: e ? e : false
                                                        }
                                                    })}
                                                    disabled={disable([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.INSERT, ACTIONS.COPY, ACTIONS.UPDATETASK])}
                                                />
                                                <InputLabel htmlFor="is_done" className="cc-label ml-2">Đã hoàn thành</InputLabel>
                                            </Box>
                                            {
                                                props.action !== ACTIONS.ADD && (
                                                    <>
                                                        <Box className="box-around">
                                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày hoàn thành</InputLabel>
                                                            <DHSDateTimeComponentV2
                                                                name="date_done"
                                                                type="datetime"
                                                                value={data?.date_done}
                                                                onChange={(e: any) => {
                                                                    handleChangeDate(e)
                                                                }}
                                                                disabled={disable([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.INSERT, ACTIONS.COPY, ACTIONS.UPDATETASK])}
                                                                hint={HintCode.TASK_DATE_DONE}
                                                            />
                                                        </Box>
                                                    </>
                                                )
                                            }
                                        </Paper>
                                    </Grid>
                                </Grid>

                            </>
                        }
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                {props.action !== ACTIONS.VIEW && (
                    <>
                        <Tooltip title="Lưu thông tin công việc" arrow >
                            <Button variant="contained" onClick={handleSubmit}>
                                Lưu
                            </Button>
                        </Tooltip>
                    </>
                )}
                <Button onClick={props.onClose}>Hủy</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog >
    );
};

export default TaskChild;
