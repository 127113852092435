import React from 'react'
import DHSGantt from '../../../../../../components/DHS/DHS_Gantt'
import { QLCCTaskModel } from '../../../../../shared/service-proxies/api-shared'

interface IProps {
    dataSource: QLCCTaskModel[]
    selectedRowKey: (e: string |
        number) => void;
}

const WorkReportGANTT = (props: IProps) => {

    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    return (
        <>
            <DHSGantt
                tasksDataSource={props.dataSource}
                taskKey='id'
                taskTitle='name'
                taskParent='task_id'
                taskStartTime='start_time'
                taskEndTime='end_time'
                taskTimeCalculator='total_time'
                taskProgress='percent'
                taskReceiver="receiver_name"
                taskStatus='status_name'
                dependenciesDataSource={props.dataSource}
                dependenciesKey='id'
                dependenciesPredecessor='id'
                dependenciesType='id'
                // dependenciesSuccessorId='task_id'
                Editing={false}
                rootValue={-1} // valueOfParent
                selectedRowKey={props.selectedRowKey}
                open={isOpen}
                onClose={() => setIsOpen(pre => !pre)}
            />
        </>
    )
}

export default WorkReportGANTT