import React from "react";
import { Tooltip } from "devextreme-react/tooltip";

type Props = {};

const LabelToolTip = (id: string, data: any, tooltipText?: string) => {
  return (
    <React.Fragment>
      <div id="template-content">
        <i id={id} className="dx-icon dx-icon-info"></i>
        {data.text}
      </div>

      <Tooltip target={`#${id}`} showEvent="mouseenter" hideEvent="mouseleave">
        <div id="tooltip-content">
          <h5 style={{ color: "#337ab7" }}>{tooltipText}</h5>
        </div>
      </Tooltip>
    </React.Fragment>
  );
};

export default LabelToolTip;
