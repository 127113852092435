import { Box, InputLabel } from "@mui/material";
import React from "react";
import { ValueChangedInfo } from "devextreme/ui/editor/editor";
import { EventInfo, NativeEventInfo } from "devextreme/events";
import { SelectBoxInstance } from "devextreme/ui/select_box";
import { httpPost } from "../../../common/httpService";
import BASE_URL_API from "../../../app/shared/service-proxies/config";
import { Template } from 'devextreme-react/core/template';
import LoadIndicator from 'devextreme-react/load-indicator';
import SelectBox from "devextreme-react/select-box";
import DropDownBox from "devextreme-react/drop-down-box";
import DataGrid from "devextreme-react/data-grid";
import { CommonClient, HomeClient, ParaObjTableList, SysGenRowTableModel } from "../../../app/shared/service-proxies/api-shared";
import { AppSession } from "../../../app/shared/app-session/app-session";
import dxDataGrid, { SelectionChangedInfo } from "devextreme/ui/data_grid";
import dxDropDownBox from "devextreme/ui/drop_down_box";
import DHSDataGridEdit from "../../DHS/DHS_DataGridEdit/DataGridEdit";
import { useSelector } from "react-redux";
import { IReducer } from "../../../common/Interfaces/Interfaces";


interface IProps {
    id?: string,
    table_list: string,
    inputLabel?: string,
    floatingLable?: boolean,
    requiredMessage?: string,
    placeholder?: string,
    name?: string,
    hint?: string,
    value: any,
    required?: boolean,
    showClearButton?: boolean,
    tabIndex?: any,
    disabled?: boolean,
    readOnly?: boolean,
    gridBoxDisplayExpr?: (item: any) => void
    defaultValue?: (data: any[]) => void,
    dataGridOnSelectionChanged?: (e: any, newValue: any) => void,
    syncDataGridSelection?: (e: NativeEventInfo<dxDropDownBox, Event> & ValueChangedInfo) => void,
}

var appSession: AppSession;
const DHSDataGridFilterListByProjectSelect: React.FC<IProps> = (props) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
    const commonClient = new CommonClient(appSession, BASE_URL_API);
    const [dataSource, setDataSource] = React.useState<any>();
    const ref = React.useRef<SelectBox | null>(null);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    // 

    const [isGridBoxOpened, setIsGridBoxOpened] = React.useState<boolean>(false);

    // 
    const dropDownRef = React.useRef<DropDownBox>(null);
    const dataGridRef = React.useRef<DataGrid>(null);
    // const [isDataGridReady, setIsDataGridReady] = React.useState<boolean>(false);
    //   


    React.useEffect(() => {
        if(dataSource?.Table1.length > 0){
            props?.defaultValue && props.defaultValue(dataSource?.Table1)

        }
    }, [dataSource?.Table1])

    React.useEffect(() => {
        (async () => {
            setIsLoading(true)
            const response = await httpPost<string>("/api/Common/LoadDatasourceBindControlByApartmentBuilding",{
                ...new ParaObjTableList(),
                ma_cty: SessionLogin.ma_cty,
                tablelist: props.table_list,
                apartment_building_id: apartment_building_id
            } as ParaObjTableList).then(res => res.data);
            setIsLoading(false)
            const parseRes = JSON.parse(response);

            setDataSource(parseRes);
        })()
    }, [])

    React.useEffect(() => {
        console.log(props.value, "value")
    }, [props.value])

    const onGridBoxOpened = (e: any) => {
        if (e.name === 'opened') {
            setIsGridBoxOpened(e.value)
        }
    }

    const dataGridOnSelectionChanged = (e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>) => {
        // this.setState({
        //   gridBoxValue: e.selectedRowKeys,
        //   isGridBoxOpened: false,
        // });
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                console.log(keys);

                props.dataGridOnSelectionChanged && props.dataGridOnSelectionChanged({}, keys[0])
                setIsGridBoxOpened(false);
            });
        }

    }

    const dataGridRender = () => {
        return (

            <DHSDataGridEdit
                dataGridRef={dataGridRef}
                // width={props.width}
                table_name={dataSource?.Table[0].TableDesign}
                data={dataSource?.Table1}
                // columns={columns}
                // hoverStateEnabled={true}
                // selectedRowKeys={selectedRowKeys}
                height={40}
                defaultFilter
                allowEdit={false}
                isPagination={false}
                isMultiple={false}
                groupingAutoExpandAll={false}
                handleSelectionChanged={dataGridOnSelectionChanged}


            />
        );
    }
    return (
        <>

            {
                <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', width: "100%" }}>
                    {(!props?.floatingLable &&
                       props?.inputLabel) &&  <InputLabel required={props?.required} sx={{ fontSize: 14, color: '#333' }}>{props?.inputLabel}</InputLabel>
                    }
                    <DropDownBox
                        ref={dropDownRef}
                        value={props.value}
                        opened={isGridBoxOpened}
                        label={props?.floatingLable ? props?.inputLabel : ""}
                        labelMode={props?.floatingLable ? "floating" : undefined }
                        valueExpr={dataSource?.Table[0].ValueMember}
                        deferRendering={false}
                        displayExpr={props?.gridBoxDisplayExpr ?? dataSource?.Table[0].DisplayMember}
                        placeholder={props.placeholder || ""}
                        showClearButton={true}
                        dataSource={dataSource?.Table1}
                        onValueChanged={props.syncDataGridSelection}
                        onOptionChanged={onGridBoxOpened}
                        contentRender={dataGridRender}
                        onKeyUp={(e) => {
                            if (e.event?.code === "Space" || e.event?.key === ' ') {
                                if (!isGridBoxOpened) {
                                    setIsGridBoxOpened(true)

                                }
                            }
                        }}
                        dropDownOptions={{
                            width: "auto",
                            height: "auto"

                        }}
                        tabIndex={props.tabIndex}
                        disabled={props.disabled}
                        readOnly={props.readOnly}
                    />
                </Box>


            }
        </>
    )
}

export default DHSDataGridFilterListByProjectSelect;