import { Box, InputLabel } from "@mui/material";
import React from "react";
import { ValueChangedInfo } from "devextreme/ui/editor/editor";
import { NativeEventInfo } from "devextreme/events";
import { SelectBoxInstance } from "devextreme/ui/select_box";
import SelectBox from "devextreme-react/select-box";
import { ISchedulerView, schedulerViews } from "../../master-data/schedulerView";
import DHSSelectBox from "../../../editors/DHSSelectBox/DHSSelectBox";

interface IProps {

    floatingLable?: boolean,
    requiredMessage?: string,
    name?: string,
    placeholder?: string,
    defaultValue?: (data: ISchedulerView[]) => void,
    value: any,
    hiddenLabel?: boolean,
    required?: boolean,
    showClearButton?: boolean,
    onValueChanged?: (e: NativeEventInfo<SelectBoxInstance, KeyboardEvent | Event | MouseEvent | PointerEvent> & ValueChangedInfo, schedulerView: ISchedulerView) => void
}

const SchedulerViewSelect: React.FC<IProps> = (props) => {



    const ref = React.useRef<SelectBox | null>(null);
 
    const [dataSource] = React.useState<ISchedulerView[]>(schedulerViews);


    React.useEffect(() => {
        if(dataSource.length > 0){
            props?.defaultValue && props.defaultValue(dataSource)

        }
    }, [dataSource])


    return (
        <>
            {(!props?.floatingLable && !props?.hiddenLabel) &&
                <InputLabel required={props?.required} sx={{ fontSize: 14, color: '#333' }}>{"Loại xem"}</InputLabel>
            }
            <DHSSelectBox
                ref={ref}
                dataSource={dataSource}
                label={props?.floatingLable ? "Loại xem" : ""}
                labelMode={props?.floatingLable ? "floating" : undefined}
                name={props?.name}
                onValueChanged={(e) => props?.onValueChanged && props?.onValueChanged(e, e.component.option("selectedItem") as ISchedulerView)}
                displayExpr={"name"}
                valueExpr={"code"}
                deferRendering={false}
                value={props?.value ?? undefined}
                placeholder={props?.placeholder}
                showClearButton={props?.showClearButton}
                searchEnabled={false}
                required={props?.required}
                requiredMessage={props?.requiredMessage}
                inputAttr={{
                    title: ref.current?.instance.option("selectedItem")?.name ?? ""
                }}  

            />
        </>
    )
}

export default SchedulerViewSelect;