import React from "react";
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
    Grid,
    Stack,
} from "@mui/material";
import CurrencyTextField from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import CloseIcon from "@mui/icons-material/Close";

import {
    QLCCClient,
    QLCCApartmentBuildingModel,
    SysActionType,
    CommonClient,
    CaVch01Client,
    LstCurrency,
    ParaObjExchageRate,
    ParaObj,
    QLCCOBCashBank,
    QLCCResidentsModel,
    ApBookClient,
    LstAccount,
    ObCustomer,
    OBCustomerClient,
    ParaObjTableList,
} from "../../../shared/service-proxies/api-shared";


import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../ApartmentBuilding.css";
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import CTXDate from "../../../../components/CTX/DatePicker";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import DHSAutoCompleteTableResident from "../../../../components/DHS/DHS_AutoCompleteResident/DHSAutoCompleteTableResident";
import notification from "../../../../common/notification/notification";
import DHSAutoCompleteWithDataGrid from "../../../../components/DHS/DHS_AutoCompleteCustomer/DHSAutoCompleteWithDataGrid";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { callApiToken } from "../../../../routes/configApi";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";
import { httpPost } from "../../../../common/httpService";




interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: () => void;
    obcash?: any;
    apartmentBuildings?: QLCCApartmentBuildingModel[] | undefined;
    payload?: any;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const status_message = ['success', 'error']
var appSession: AppSession;

const PayableCustomerAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    const oBClient = new OBCustomerClient(appSession, BASE_URL_API);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [payableCustomer, setObCashBank] = React.useState<ObCustomer>({} as ObCustomer);
    const caVch01Client = new CaVch01Client(appSession, BASE_URL_API);
    const [currencyOptions, setCurrencyOptions] = React.useState<any[]>([]);
    const commonClient = new CommonClient(appSession, BASE_URL_API);
    const apBookClient = new ApBookClient(appSession, BASE_URL_API);
    const [lstAccounts, setLstAccounts] = React.useState<LstAccount[]>([] as LstAccount[]);
    const [residentOptions, setResidentOptions] = React.useState<any>([]);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const Token = {
        Token: SessionLogin.Token
    }

    const onLoadFilter = () => {
        const body =
        {
            ma_cty: SessionLogin.ma_cty,
            tablelist: "DM_NCC",
            apartment_building_id: apartment_building_id
        }

        httpPost<string>('/api/Common/LoadDatasourceBindControlByApartmentBuilding', {
            ma_cty: body.ma_cty,
            tablelist: body.tablelist,
            apartment_building_id: apartment_building_id
        })
            .then(res => {
                console.log(JSON.parse(res.data))
                const serviceGroups: any = JSON.parse(res.data)
                setResidentOptions(serviceGroups?.Table1)
            }).catch((ex) => {
                console.log(ex)
            });

        // callApiToken("api/Common/LoadFilterList", "POST", body, Token)
        //     .then((res) => {
        //         setResidentOptions(res.data)
        //         setLoading(false);
        //     })
        //     .catch((error) => console.log(error));
    }

    React.useEffect(() => {
        setLoading(true);

        // qLCClient.qLCCResidentsGet({
        //     ...new QLCCResidentsModel(),
        //     apartment_building_id: props.payload?.id!,
        //     action_type: 'AUTOCOMPLETE_SEARCH'
        // } as QLCCResidentsModel).then(res => {
        //     setResidentOptions(res)
        //     setLoading(false);
        // });

        caVch01Client.lstCurrencyGet({
            ...new LstCurrency(),
            ma_cty: SessionLogin.ma_cty,
        } as LstCurrency)
            .then(res => {
                setLoading(false);
                setCurrencyOptions(res);
            });

        qLCClient.qLCClstAccountGet(
            SessionLogin.ma_cty,
            ""
        )
            .then((res: any) => {

                setLstAccounts(res);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            })

        onLoadFilter();
    }, [])

    React.useEffect(() => {
        if (props.open === true) {
            if (props.action === ACTIONS.ADD) {
                setLoading(true);
                const newApBook = {
                    ... new ObCustomer(),
                    ma_nt: "VND",
                    ty_gia: 1,
                    tk: '331',
                    nam: Number(new Date())
                } as ObCustomer;

                setObCashBank({
                    ...newApBook,
                } as ObCustomer);
                setLoading(false);

            } else {


                setObCashBank({
                    ...props.obcash,
                    nam: Number(new Date(props.obcash?.nam!, new Date().getMonth(), 2))
                } as ObCustomer);
                setLoading(false);

                console.log(props.obcash)

            }
        }



    }, [props.open, props.itemSelected]);

    React.useEffect(() => {

        if (payableCustomer && payableCustomer.ma_nt && props.open === true) {

            commonClient
                .getExchangeRate5({

                    ... new ParaObjExchageRate(),
                    ma_cty: SessionLogin.ma_cty,
                    ma_nt: payableCustomer.ma_nt,
                    ngay_ct: new Date()

                } as ParaObjExchageRate)
                .then(res => {
                    if (res) {

                        const tyGia = Number(res);
                        const du_co = payableCustomer.du_co_nt && !isNaN(payableCustomer.du_co_nt) ? Number(payableCustomer.du_co_nt) * tyGia : 0;
                        const du_no = payableCustomer.du_no_nt && !isNaN(payableCustomer.du_no_nt) ? Number(payableCustomer.du_no_nt) * tyGia : 0;
                        setObCashBank({

                            ...payableCustomer,
                            du_co,
                            du_no,
                            ty_gia: tyGia,

                        } as ObCustomer)

                    }
                }).catch(err => {
                    console.log(err)
                })
        }
    }, [payableCustomer.ma_nt])


    const handleChange = (e: any) => {

        const { name, value } = e.target;

        if (props.action !== ACTIONS.VIEW) {

            setObCashBank({
                ...payableCustomer,
                [name]: value,
            } as ObCustomer);

        }
    }

    const handleChangeDate = (e: any) => {
        const { name, value } = e;

        if (props.action !== ACTIONS.VIEW) {

            setObCashBank({
                ...payableCustomer,
                [name]: value,
            } as ObCustomer);

        }
    }

    const handleUpdate = () => {

        oBClient.obCustomerUpd({
            ... new ObCustomer(),
            ...payableCustomer,
            nam: Number(new Date(payableCustomer?.nam!).getFullYear()),
            ma_cty: SessionLogin.ma_cty,
            luser: SessionLogin.userName,
            ldate: new Date(),
            du_co_nt: Number(payableCustomer.du_co_nt),
            du_no_nt: Number(payableCustomer.du_no_nt),
        } as ObCustomer).then(res => {

            status(res.status === 0 ? res.status : 1, res.message);

        })

    }


    const status = (status: number = 1, message: string = "") => {
        notification(status_message[status], message);
        if (status === 0) {
            props.onEditSuccess();
            props.onClose()
        }

    }

    const handleSubmit = () => {

        oBClient.obCustomerIns({
            ... new ObCustomer(),
            ...payableCustomer,
            ma_cty: SessionLogin.ma_cty,
            nam: Number(new Date(payableCustomer?.nam!).getFullYear()),
            luser: SessionLogin.userName,
            cuser: SessionLogin.userName,
            du_co_nt: Number(payableCustomer.du_co_nt),
            du_no_nt: Number(payableCustomer.du_no_nt),
            cdate: new Date(),
            ldate: new Date(),
        } as ObCustomer).then(res => {
            status(res.status === 0 ? res.status : 1, res.message)
        }).catch(err => {
            console.log(err)
        })
    }

    const handleClickAction = (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code) {
            case ACTIONS.SAVE:

                if (props.action === ACTIONS.ADD) {
                    handleSubmit();

                } else if (props.action === ACTIONS.EDIT) {
                    handleUpdate();
                }

                break;

            case ACTIONS.CLOSE:

                props.onClose();

                break;
        }
    }


    const handleChangeCurrentInput = (

        value: string | undefined, name?: string | undefined, values?: CurrencyInputOnChangeValues | undefined) => {
        if (props.action.toUpperCase() !== ACTIONS.VIEW) {

            const convert = Number(value) !== 0 ? Number(value) * Number(payableCustomer.ty_gia) / 1.00 : 0;
            const name2: string | undefined = name?.split("_nt")[0].toString();

            setObCashBank({
                ...payableCustomer,
                [name!]: value,
                [name2!]: isNaN(convert) ? 0 : 1.00 * convert,
            } as ObCustomer);

        }

    }

    return (
        <Dialog
            id="sysMenu5"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            TransitionComponent={Transition}
            maxWidth="xs"
        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === "VIEW"
                                ? "[Chi tiết]"
                                : props.action === "EDIT" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={6}>

                                <Typography className="text-start mb-3"><strong>Thông tin chung</strong></Typography>
                                <Box className="mb-3 d-md-flex align-items-center justify-content-between">
                                    <InputLabel className="cc-label w-100">
                                        Năm
                                    </InputLabel>
                                    <CTXDate
                                        className=" w-100 cc-input"
                                        value={payableCustomer.nam}
                                        name={"nam"}
                                        views={["year"]}
                                        onChange={handleChangeDate}
                                    />
                                </Box>
                                <Box className="mb-2 d-md-flex justify-content-between w-100">
                                    <InputLabel
                                        className="w-100 cc-input"
                                    >
                                        Tài khoản
                                    </InputLabel>
                                    <DHSAutoCompleteWithDataSource
                                        id="apartment-select"
                                        className="cc-input"
                                        dataSource={lstAccounts}
                                        displayMember="ten_tk"
                                        valueMember="tk"
                                        value={payableCustomer?.tk}
                                        onValueChanged={(e, newValue: any) => {
                                            handleChange({
                                                target: {
                                                    name: "tk",
                                                    value: newValue?.tk ? newValue.tk : null,
                                                },
                                            });
                                        }}
                                        disabled={true}
                                    />
                                    {/* <Autocomplete
                                        id="apartment-select"
                                        sx={{ width: "100%" }}
                                        options={lstAccounts}
                                        autoHighlight
                                        getOptionLabel={(option: any) => `${option?.ten_tk}`}
                                        value={
                                            lstAccounts
                                            && lstAccounts.find(
                                                (item: any) => item.tk === payableCustomer.tk
                                            )
                                        }
                                        onChange={(e, newValue: any) => {
                                            handleChange({
                                                target: {
                                                    name: "tk",
                                                    value: newValue?.tk ? newValue.tk : null,
                                                },
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className="cc-input"
                                                placeholder="Tài khoản"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password", // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                        disabled={true}
                                    /> */}
                                </Box>
                                <Box className="mb-2 d-md-flex justify-content-between w-100">
                                    <InputLabel
                                        className="w-100 cc-input"
                                    >
                                        Nhà cung cấp
                                    </InputLabel>
                                    <Box className="w-100">
                                        <DHSAutoCompleteWithDataGrid
                                            id="resident-select"
                                            value={payableCustomer.iDcustomer ?? null}
                                            table_name="DM_NCC"
                                            // filter={props.payload}
                                            name="iDcustomer"
                                            valueExpr={"idcustomer"}
                                            options={residentOptions}
                                            dataGridOnSelectionChanged={(e, newValue: any) => {
                                                handleChange({
                                                    target: {
                                                        name: "iDcustomer",
                                                        value: newValue?.idcustomer ?? null,
                                                    },
                                                });
                                            }}
                                            syncDataGridSelection={function (e): void {
                                                setObCashBank(pre => ({
                                                    ...pre,
                                                    iDcustomer: e.value

                                                } as ObCustomer));
                                            }}
                                            gridBoxDisplayExpr={function (item: any): string {
                                                return item && `${item?.ten_kh}`;
                                            }}

                                            disabled={props.action === ACTIONS.VIEW}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>  </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} >
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={6}>
                                <Box className="mb-3 d-md-flex align-items-start justify-content-between">
                                    <InputLabel className="cc-label w-100">
                                        Loại tiền
                                    </InputLabel>
                                    <Box className="w-100">
                                        <Stack spacing={2} direction="row">
                                            <DHSAutoCompleteWithDataSource
                                                id="apartment-select"
                                                className="cc-input"
                                                dataSource={currencyOptions}
                                                displayMember="ma_nt"
                                                valueMember="ma_nt"
                                                value={payableCustomer?.ma_nt}
                                                onValueChanged={(e, newValue: any) => {
                                                    handleChange({
                                                        target: {
                                                            name: "ma_nt",
                                                            value: newValue?.ma_nt ? newValue.ma_nt : null,
                                                        },
                                                    });
                                                }}
                                            />
                                            {/* <Autocomplete
                                                id="apartment-select"
                                                sx={{ width: "100%" }}
                                                options={currencyOptions}
                                                autoHighlight
                                                getOptionLabel={(option: any) => `${option.ma_nt}`}
                                                value={
                                                    payableCustomer.ma_nt
                                                        ? currencyOptions.find(
                                                            (item: any) => item.ma_nt === payableCustomer.ma_nt
                                                        ) : null
                                                }
                                                onChange={(e, newValue: any) => {
                                                    handleChange({
                                                        target: {
                                                            name: "ma_nt",
                                                            value: newValue?.ma_nt ? newValue.ma_nt : null,
                                                        },
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className="cc-input"
                                                        placeholder="-- Mã ngoại tệ --"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password", // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                            /> */}

                                            {/* Tỷ giá */}

                                            <CurrencyTextField
                                                className="flex-grow-1 form-control currency-input"
                                                style={{ width: "100%", textAlign: "right" }}
                                                allowDecimals
                                                decimalsLimit={2}
                                                decimalSeparator="."
                                                groupSeparator=","
                                                decimalScale={2}
                                                placeholder="Tỷ giá"
                                                intlConfig={currencyOptions.find(p => p.ma_nt === payableCustomer.ma_nt?.toLocaleUpperCase())}
                                                name="ty_gia"
                                                onValueChange={handleChangeCurrentInput}
                                                value={payableCustomer.ty_gia}
                                            />
                                        </Stack>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} ></Grid>
                            <Grid item xs={12} md={6} >
                                <Typography className="text-start mb-3"><strong>Tiền ngoại tệ</strong></Typography>

                                <Stack spacing={2}>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Dư nợ NT
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            decimalsLimit={2}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            decimalScale={2}
                                            //placeholder="Dư nợ ngoại tệ"
                                            intlConfig={currencyOptions.find(p => p.ma_nt === payableCustomer.ma_nt?.toLocaleUpperCase())}
                                            name="du_no_nt"
                                            onValueChange={handleChangeCurrentInput}
                                            value={payableCustomer.du_no_nt}
                                        />
                                    </Box>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Dư có NT
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            decimalsLimit={2}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            decimalScale={2}
                                            //placeholder="Dư có ngoại tệ"
                                            intlConfig={currencyOptions.find(p => p.ma_nt === payableCustomer.ma_nt?.toLocaleUpperCase())}
                                            name="du_co_nt"
                                            onValueChange={handleChangeCurrentInput}
                                            value={payableCustomer.du_co_nt}
                                        />
                                    </Box>

                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography className="text-start mb-3"><strong>Tiền quy đổi</strong></Typography>

                                <Stack spacing={2}>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Dư nợ
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            decimalsLimit={2}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            decimalScale={3}
                                            //placeholder="Dư nợ"
                                            intlConfig={{ locale: 'vi-VN', currency: 'VND' }}

                                            name="du_no"
                                            // onValueChange={handleChangeCurrentConvertInput}
                                            value={payableCustomer.du_no}
                                            disabled={true}
                                        />
                                    </Box>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Dư có
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            decimalsLimit={2}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            decimalScale={3}
                                            //placeholder="Dư có"
                                            intlConfig={{ locale: 'vi-VN', currency: 'VND' }}

                                            name="du_co"
                                            // onValueChange={handleChangeCurrentConvertInput}
                                            value={payableCustomer.du_co}
                                            disabled={true}
                                        />
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>


            {
                props.action
                && props.action !== ACTIONS.VIEW
                && (<>
                    <DialogActions>

                        <DHSDialogAction

                            menu={menu}
                            onClickAction={handleClickAction}
                            action={props.action}
                        />

                    </DialogActions>
                </>)
            }


            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog >
    );
};

export default PayableCustomerAction;
