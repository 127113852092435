import React from "react";
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Grid,
    Divider,
    FormControlLabel,
    Switch,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    HomeClient,
    HomePageClient,
    ModalHandle,
    RoleInfo,
    RoleRightWebModel,
    SiRoleInfoClient,
    SysActionType,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";

import "../../actions/ApartmentBuilding.css";
import { httpPost } from "../../../../common/httpService";
import notification from "../../../../common/notification/notification";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});



const status_message = ['success', 'error']
var appSession: AppSession;
const RoleInfoAction: React.FC<IProps> = (props: IProps) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    // const token = SessionLogin.Token;

    const [_id, setId] = React.useState<string>();
    // const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    //const qLCClient = new QLCClient(appSession, BASE_URL_API);


    const [data, setData] = React.useState<RoleInfo>({} as RoleInfo);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const siRoleInfoClient = new SiRoleInfoClient(appSession, BASE_URL_API);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);


    React.useEffect(() => {
        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            // Tải các dòng trong bảng
            const body = {
                "rolename": props.itemSelected,
                "ma_cty": SessionLogin.ma_cty
            }
            httpPost<RoleInfo[]>("api/SiRoleInfo/GetListRoleInfo", body)
                .then(response => {

                    setData(response.data[0])
                    setLoading(false)
                }).catch(error => {

                })
            setId(props.itemSelected);

        } else {

            setData({
                // ...new RoleInfo(),
                grand: false,
                ksd: false
            } as RoleInfo);
            setId(undefined)
        };
    }, [props.action]);




    const handleSubmit = (e?: any) => {

        if (props.action === ACTIONS.ADD) {
            setLoading(true)
            siRoleInfoClient.insertCommand22({
                ...new ModalHandle(),
                roleInfo: data,
                ma_cty: SessionLogin.ma_cty
            } as ModalHandle)
                .then(res => {

                    notification(status_message[res ? 0 : 1], STATUS_MESSAGE[`${props.action}_${res ? 0 : 1}`]);
                    if (res) {
                        props.onEditSuccess(true);
                        props.onClose();
                    }

                    setLoading(false)
                }).catch(error => {
                    setLoading(false)
                    notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])


                })
        } else if (props.action === ACTIONS.EDIT) {
            siRoleInfoClient.updateCommand22({
                ...new ModalHandle(),
                roleInfo: data,
                ma_cty: SessionLogin.ma_cty
            } as ModalHandle)
                .then(res => {

                    notification(status_message[res ? 0 : 1], STATUS_MESSAGE[`${props.action}_${res ? 0 : 1}`]);
                    if (res) {
                        props.onEditSuccess(true);
                        props.onClose();
                    }

                    setLoading(false)
                }).catch(error => {
                    notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])

                    setLoading(false)

                })
        }

    }

    const handleChangeInput = (e: any) => {
        // if (props.action !== ACTIONS.VIEW) {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        } as RoleInfo);
        // }
    };

    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                // handleSubmit();             
                handleSubmit()
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
        }
    }


    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            
            TransitionComponent={Transition}
            maxWidth="md"
        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === ACTIONS.EDIT || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {/* Tên vai trò */}
                    <Grid item xs={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tên vai trò</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%" }}
                                name="rolename"
                                placeholder="Nhập tên vai trò ..."
                                onChange={handleChangeInput}
                                value={data.rolename}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box>
                    </Grid>
                    {/* Tên đầy đủ */}
                    <Grid item xs={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tên đầy đủ</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%" }}
                                name="fullname"
                                placeholder="Nhập tên đầy đủ ..."
                                onChange={handleChangeInput}
                                value={data.fullname}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box>
                    </Grid>

                    <Grid container item xs={12}>
                        {/* <Grid item xs={12} md={6}>
                            <Box className="">

                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Grand</InputLabel>

                                <FormControlLabel control={
                                    <Switch
                                        color="primary"
                                        onChange={(e, checked) => handleChangeInput({
                                            target: {
                                                name: e.target.name,
                                                value: checked
                                            }
                                        })}
                                        name="grand"
                                        value={data.grand}
                                        checked={data.grand}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />}
                                    label={
                                        <Typography
                                            sx={{ fontSize: 14, color: '#333' }}>
                                            Không/Có
                                        </Typography>} />


                            </Box>





                        </Grid> */}
                        <Grid item xs={12} md={6}>
                            <Box className="">

                                {/* <InputLabel sx={{ fontSize: 14, color: '#333' }}>Không sử dụng</InputLabel> */}

                                <FormControlLabel control={
                                    <Switch
                                        color="primary"
                                        onChange={(e, checked) => handleChangeInput({
                                            target: {
                                                name: e.target.name,
                                                value: checked
                                            }
                                        })}
                                        name="ksd"
                                        value={data.ksd}
                                        checked={data.ksd}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />}
                                    label={
                                        <Typography
                                            sx={{ fontSize: 14, color: '#333' }}>
                                            Không sử dụng
                                        </Typography>} />


                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>

                <DHSDialogAction

                    action={props.action}
                    menu={menu}
                    onClickAction={handleClickAction}
                />
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default RoleInfoAction;
