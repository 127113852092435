import React, { useEffect, useState } from "react";

import HtmlEditor, {
  Toolbar,
  MediaResizing,
  ImageUpload,
  Item,
} from "devextreme-react/html-editor";
import CheckBox from "devextreme-react/check-box";
import { NativeEventInfo } from "devextreme/events";
import dxHtmlEditor from "devextreme/ui/html_editor";
import { ValueChangedInfo } from "devextreme/ui/editor/editor";
import JoditEditor from "jodit-react";

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];
const headerValues = [false, 1, 2, 3, 4, 5];

type Props = {
  onchangeHTMLValue?: (
    e: NativeEventInfo<dxHtmlEditor, Event> & ValueChangedInfo
  ) => void;
  // onchangeHTMLValue?: (
  //   value: string
  // ) => void;
  defaultValue?: string;
  readonly?: boolean;
  height?: number;
};

const tab = ["file", "url"];

const DHSHtmlEditor = (props: Props) => {
  const handleValueChange = (
    e: NativeEventInfo<dxHtmlEditor, Event> & ValueChangedInfo
  ) => {
    if (props.onchangeHTMLValue) {
      props.onchangeHTMLValue(e);
    }
  };

  // const handleValueChange = (
  //   newValue: string
  // ) => {
  //   if (props.onchangeHTMLValue) {
  //     props.onchangeHTMLValue(newValue);
  //   }
  // };

  return (
    <div className="widget-container">
      <HtmlEditor
          height={`${props.height || 40}vh`}
          defaultValue={props.defaultValue}
          valueType='html'
          onValueChanged={handleValueChange}
          readOnly={props.readonly || false}
        >
          <MediaResizing enabled={true} />
          <ImageUpload tabs={tab} fileUploadMode="base64" />
          <Toolbar multiline={true}>
            <Item name="undo" />
            <Item name="redo" />
            <Item name="separator" />
            <Item
              name="size"
              acceptedValues={sizeValues}
            />
            <Item
              name="font"
              acceptedValues={fontValues}
            />
            <Item name="separator" />
            <Item name="bold" />
            <Item name="italic" />
            <Item name="strike" />
            <Item name="underline" />
            <Item name="separator" />
            <Item name="alignLeft" />
            <Item name="alignCenter" />
            <Item name="alignRight" />
            <Item name="alignJustify" />
            <Item name="separator" />
            <Item name="orderedList" />
            <Item name="bulletList" />
            <Item name="separator" />
            <Item
              name="header"
              acceptedValues={headerValues}
            />
            <Item name="separator" />
            <Item name="color" />
            <Item name="background" />
            <Item name="separator" />
            <Item name="link" />
            <Item name="image" />
            <Item name="separator" />
            <Item name="clear" />
            <Item name="codeBlock" />
            <Item name="blockquote" />
            <Item name="separator" />
            <Item name="insertTable" />
            <Item name="deleteTable" />
            <Item name="insertRowAbove" />
            <Item name="insertRowBelow" />
            <Item name="deleteRow" />
            <Item name="insertColumnLeft" />
            <Item name="insertColumnRight" />
            <Item name="deleteColumn" />
          </Toolbar>
        </HtmlEditor>
      {/* <JoditEditor
        value={props.defaultValue || ''}
        
        onChange={(newContent) => {
          handleValueChange(newContent)
        }
        }
        config={config as any}
      /> */}
    </div>
  );
};

const config = {
  placeholder: 'Nội dung...',
  height: 400,
  minHeight: 500,
  uploader: {
      insertImageAsBase64URI: true,
      imagesExtensions: ['jpg', 'png', 'jpeg', 'gif', 'svg', 'webp']
  },
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  defaultActionOnPasteFromWord: 'insert_clear_html',
  commandToHotkeys: {
      removeFormat: ['ctrl+shift+m', 'cmd+shift+m'],
      insertOrderedList: ['ctrl+shift+7', 'cmd+shift+7'],
      insertUnorderedList: ['ctrl+shift+8, cmd+shift+8'],
      selectall: ['ctrl+a', 'cmd+a'],
      bold: ['ctrl+b'],
      indent: ['ctrl+]'],
      outdent: ['ctrl+['],
      italic: ['ctrl+i'],
      openSearchDialog: ['ctrl+f'],
      openReplaceDialog: ['ctrl+r'],
  },
  redo: 'ctrl+z',
  undo: 'ctrl+y,ctrl+shift+z',
  toolbarSticky: true,
  toolbarDisableStickyForMobile: false,
  toolbarStickyOffset: 100,
}

export default DHSHtmlEditor;
