import React from "react";
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
    Grid,
    Stack,
    CircularProgress,
} from "@mui/material";
import CurrencyTextField from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import CloseIcon from "@mui/icons-material/Close";

import {
    QLCCClient,
    RoleRightWebModel,
    QLCCApartmentBuildingModel,
    OBCashBankClient,
    SysActionType,
    LstAccount,
    CommonClient,
    CaVch01Client,
    LstCurrency,
    ArBook,
    ParaObjExchageRate,
    ARBookClient,
    ParaObj,
    QLCCOBCashBank,
    QLCCResidentsModel,
} from "../../../shared/service-proxies/api-shared";


import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../ApartmentBuilding.css";
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import CTXDate from "../../../../components/CTX/DatePicker";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import DHSAutoCompleteTableResident from "../../../../components/DHS/DHS_AutoCompleteResident/DHSAutoCompleteTableResident";
import notification from "../../../../common/notification/notification";
import DHSAutoCompleteWithDataGrid from "../../../../components/DHS/DHS_AutoCompleteCustomer/DHSAutoCompleteWithDataGrid";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";
interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: () => void;
    obcash?: any;
    apartmentBuildings?: QLCCApartmentBuildingModel[] | undefined;
    payload?: any;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const status_message = ['success', 'error']
var appSession: AppSession;

const ReceivableAction: React.FC<IProps> = (props: IProps) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [obCashBank, setObCashBank] = React.useState<ArBook>({} as ArBook);
    const caVch01Client = new CaVch01Client(appSession, BASE_URL_API);
    const [currencyOptions, setCurrencyOptions] = React.useState<any[]>([]);
    const commonClient = new CommonClient(appSession, BASE_URL_API);
    const arBookClient = new ARBookClient(appSession, BASE_URL_API);
    const [residentOptions, setResidentOptions] = React.useState<QLCCResidentsModel[]>([]);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    async function onLoadResident() {
        qLCClient.qLCCResidentsGet({
            ...new QLCCResidentsModel(),
            // apartment_building_id: props.payload?.id!,
            apartment_building_id: apartment_building_id,
            action_type: 'AUTOCOMPLETE_SEARCH'
        } as QLCCResidentsModel).then(res => {
            setResidentOptions(res)
        });
    }

    React.useEffect(() => {
        setLoading(true);
        caVch01Client.lstCurrencyGet({
            ...new LstCurrency(),
            ma_cty: SessionLogin.ma_cty,
        } as LstCurrency)
            .then(res => {
                setLoading(false);
                setCurrencyOptions(res);
            });

        commonClient.get_VoucherNo({
            ... new ParaObj(),
            ma_cty: SessionLogin.ma_cty,
            ma_ct: "SO5",
            ngay_ct: new Date(),
        } as ParaObj)
            .then(res => {
                setObCashBank({
                    ...obCashBank,
                    so_ct: res
                } as ArBook);
            })
    }, [])

    // React.useEffect(() => {
    //     onLoadResident();
    // }, [apartment_building_id])

    React.useEffect(() => {
        if (props.open === true) {
            onLoadResident();
            if (props.action === ACTIONS.ADD) {
                setLoading(true);
                const newArBook = {
                    ... new ArBook(),
                    ngay_ct: new Date(),
                    ngay_hd: new Date(),
                    ma_nt: 'VND',
                } as ArBook;
                commonClient.get_VoucherNo({
                    ... new ParaObj(),
                    ma_cty: SessionLogin.ma_cty,
                    ma_ct: "SO5",
                    ngay_ct: new Date(),
                } as ParaObj)
                    .then(res => {
                        setObCashBank({
                            ...newArBook,
                            so_ct: res
                        } as ArBook);
                        setLoading(false);
                    }).catch(err => {
                        setLoading(false);
                    })
            } else {
                setObCashBank({
                    ...props.obcash,
                } as ArBook);
                setLoading(false);
            }
        }

    }, [props.open]);

    React.useEffect(() => {

        if (obCashBank && obCashBank.ma_nt && props.open === true) {

            commonClient
                .getExchangeRate5({

                    ... new ParaObjExchageRate(),
                    ma_cty: SessionLogin.ma_cty,
                    ma_nt: obCashBank.ma_nt,
                    ngay_ct: obCashBank.ngay_ck || new Date()

                } as ParaObjExchageRate)
                .then(res => {
                    if (res) {

                        const tyGia = Number(res);

                        setObCashBank({
                            ...obCashBank,
                            ty_gia: tyGia,
                        } as ArBook)


                    }
                }).catch(err => {

                })
        }
    }, [obCashBank.ma_nt])


    const handleChange = (e: any) => {

        const { name, value } = e.target;

        if (props.action !== ACTIONS.VIEW) {

            if (name === 'ngay_ct') {

                if (props.obcash && obCashBank.ngay_ct) {
                    commonClient.get_VoucherNo({
                        ... new ParaObj(),
                        ma_cty: SessionLogin.ma_cty,
                        ma_ct: "SO5",
                        ngay_ct: obCashBank.ngay_ct
                    } as ParaObj)
                        .then(res => {
                            setObCashBank({
                                ...obCashBank,
                                [name]: value,
                                so_ct: res
                            } as ArBook);
                        })
                }

            }
            else {
                setObCashBank({
                    ...obCashBank,
                    [name]: value,
                } as ArBook);
            }

        }
    }

    const handleChangeDate = (e: any) => {
        const { name, value } = e;

        if (props.action !== ACTIONS.VIEW) {

            setObCashBank({
                ...obCashBank,
                [name]: value,
            } as ArBook);

        }
    }

    const handleUpdate = () => {

        // debugger
        const ngayChungTuUTC = obCashBank.ngay_ct ? new Date(obCashBank.ngay_ct) : new Date();
        const tyGia: number = isNaN(obCashBank.ty_gia ?? 0) ? 1 : Number(obCashBank.ty_gia);

        arBookClient.arBookUpd({
            ... new ArBook(),
            ...obCashBank,
            ma_cty: SessionLogin.ma_cty,
            ma_ct: "SO5",
            ngay_ct: ngayChungTuUTC ? new Date(Date.UTC(ngayChungTuUTC?.getFullYear(), ngayChungTuUTC?.getMonth(), ngayChungTuUTC?.getDate(), 0, 0, 0)) : new Date(),
            ngay_hd: ngayChungTuUTC ? new Date(Date.UTC(ngayChungTuUTC?.getFullYear(), ngayChungTuUTC?.getMonth(), ngayChungTuUTC?.getDate(), 0, 0, 0)) : new Date(),
            da_tt: Number(obCashBank.da_tt_nt) * tyGia,
            du_tt: Number(obCashBank.du_tt_nt) * tyGia,
            t_thue: Number(obCashBank.t_thue_nt),
            t_tien2: Number(obCashBank.t_tien_nt2) * tyGia,
            t_tt: Number(obCashBank.t_tt_nt) * tyGia,
            tien_tt: Number(obCashBank.tien_tt_nt) * tyGia,
            ty_gia: Number(obCashBank.ty_gia),
            da_tt_nt: Number(obCashBank.da_tt_nt),
            du_tt_nt: Number(obCashBank.du_tt_nt),
            t_thue_nt: Number(obCashBank.t_thue_nt),
            t_tien_nt2: Number(obCashBank.t_tien_nt2),
            t_tt_nt: Number(obCashBank.t_tt_nt),
            tien_tt_nt: Number(obCashBank.tien_tt_nt)
        } as ArBook).then(res => {

            status(res.status === 0 ? res.status : 1, res.message);

        })

    }


    const status = (status: number = 1, message: string = "") => {
        notification(status_message[status], message);
        if (status === 0) {
            props.onEditSuccess();
            props.onClose()
        }

    }

    const handleSubmit = () => {

        const ngayChungTuUTC = obCashBank.ngay_ct ? new Date(obCashBank.ngay_ct) : new Date();
        const tyGia: number = isNaN(obCashBank.ty_gia ?? 0) ? 1 : Number(obCashBank.ty_gia);

        arBookClient.arBookIns({
            ... new ArBook(),
            ...obCashBank,
            ma_cty: SessionLogin.ma_cty,
            ma_ct: "SO5",
            ngay_ct: ngayChungTuUTC ? new Date(Date.UTC(ngayChungTuUTC?.getFullYear(), ngayChungTuUTC?.getMonth(), ngayChungTuUTC?.getDate(), 0, 0, 0)) : new Date(),
            ngay_hd: ngayChungTuUTC ? new Date(Date.UTC(ngayChungTuUTC?.getFullYear(), ngayChungTuUTC?.getMonth(), ngayChungTuUTC?.getDate(), 0, 0, 0)) : new Date(),
            da_tt: Number(obCashBank.da_tt_nt) * tyGia,
            du_tt: Number(obCashBank.du_tt_nt) * tyGia,
            t_thue: Number(obCashBank.t_thue_nt),
            t_tien2: Number(obCashBank.t_tien_nt2) * tyGia,
            t_tt: Number(obCashBank.t_tt_nt) * tyGia,
            tien_tt: Number(obCashBank.tien_tt_nt) * tyGia,
            ty_gia: Number(obCashBank.ty_gia),
            da_tt_nt: Number(obCashBank.da_tt_nt),
            du_tt_nt: Number(obCashBank.du_tt_nt),
            t_thue_nt: Number(obCashBank.t_thue_nt),
            t_tien_nt2: Number(obCashBank.t_tien_nt2),
            t_tt_nt: Number(obCashBank.t_tt_nt),
            tien_tt_nt: Number(obCashBank.tien_tt_nt),
            apartment_building_id: apartment_building_id
        } as ArBook).then(res => {
            status(res.status === 0 ? res.status : 1, res.message)
        }).catch(err => {

        })
    }

    const handleClickAction = (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code) {
            case ACTIONS.SAVE:

                if (props.action === ACTIONS.ADD) {
                    handleSubmit();

                } else if (props.action === ACTIONS.EDIT) {
                    handleUpdate();
                }

                break;

            case ACTIONS.CLOSE:

                props.onClose();

                break;
        }
    }


    const handleChangeCurrentInput = (

        value: string | undefined, name?: string | undefined, values?: CurrencyInputOnChangeValues | undefined) => {
        if (props.action.toUpperCase() !== ACTIONS.VIEW) {
            setObCashBank({
                ...obCashBank,
                [name!]: value,
            } as ArBook);

        }

    }

    return (
        <Dialog
            id="sysMenu5"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            TransitionComponent={Transition}
            maxWidth="xs"
        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === "VIEW"
                                ? "[Chi tiết]"
                                : props.action === "EDIT" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box className="mb-2 d-md-flex justify-content-between w-100">
                                    <InputLabel
                                        className="w-100 cc-input"
                                    >
                                        Khách hàng
                                    </InputLabel>
                                    <Box className="w-100">
                                        <DHSAutoCompleteWithDataGrid
                                            id="resident-select"
                                            value={obCashBank.iDcustomer ?? null}
                                            table_name="DHSGridResidentSearch"
                                            // placeholder="Chọn khách hàng"
                                            // filter={props.payload}
                                            name="iDcustomer"
                                            valueExpr={"id"}
                                            options={residentOptions}
                                            dataGridOnSelectionChanged={(e, newValue: any) => {
                                                handleChange({
                                                    target: {
                                                        name: "iDcustomer",
                                                        value: newValue?.id ?? null,
                                                    },
                                                });
                                            }}
                                            syncDataGridSelection={function (e): void {
                                                setObCashBank(pre => ({
                                                    ...pre,
                                                    iDcustomer: e.value

                                                } as ArBook));
                                            }}
                                            gridBoxDisplayExpr={function (item: any): string {
                                                return item && `${item?.full_name} - ${item?.apartment_name ?? "Chưa đăng kí"}`;
                                            }}

                                            disabled={props.action === ACTIONS.VIEW}
                                        />
                                    </Box>
                                </Box>

                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box className="mb-3 d-md-flex align-items-center justify-content-between">
                                    <InputLabel className="cc-label w-100">
                                        Ngày chứng từ
                                    </InputLabel>
                                    <CTXDate
                                        className=" w-100 cc-input"
                                        value={obCashBank.ngay_ct}
                                        name={"ngay_ct"}
                                        onChange={handleChangeDate}
                                    />
                                </Box>
                                {/* Số chứng từ */}
                                <Box className="mb-3 d-md-flex align-items-center justify-content-between">
                                    <InputLabel className="cc-label w-100">
                                        Số chứng từ
                                    </InputLabel>
                                    <TextField
                                        className="cc-input w-100"
                                        value={obCashBank.so_ct}
                                        name="so_ct"
                                        onChange={handleChange}
                                        placeholder="Nhập số chứng từ"
                                    />
                                </Box>
                                {/* Ngày hóa đơn */}
                                {/* <Box className="mb-3 d-md-flex align-items-center justify-content-between">
                                    <InputLabel className="cc-label w-100">
                                        Ngày hóa đơn
                                    </InputLabel>
                                    <CTXDate
                                        className=" w-100 cc-input"
                                        value={obCashBank.cdate}
                                        name={"date"}
                                        onChange={handleChangeDate}
                                    />
                                </Box> */}
                                {/* Số hóa đơn */}
                                {/* <Box className="mb-3 d-md-flex align-items-center justify-content-between">
                                    <InputLabel className="cc-label w-100">
                                        Số hóa đơn
                                    </InputLabel>
                                    <TextField
                                        className="cc-input w-100"
                                        value={""}
                                        name=""
                                        onChange={handleChangeInput}
                                        placeholder="Nhập số hóa đơn"
                                    />
                                </Box> */}
                                {/* Tỷ giá */}
                                <Box className="mb-3 d-md-flex align-items-start justify-content-between">
                                    <InputLabel className="cc-label w-100">
                                        Loại tiền
                                    </InputLabel>
                                    <Box className="w-100">
                                        <Stack spacing={2}>
                                            <DHSAutoCompleteWithDataSource
                                                id="apartment-select"
                                                className="cc-input"
                                                dataSource={currencyOptions}
                                                displayMember="ma_nt"
                                                valueMember="ma_nt"
                                                value={obCashBank?.ma_nt}
                                                onValueChanged={(e, newValue: any) => {
                                                    handleChange({
                                                        target: {
                                                            name: "ma_nt",
                                                            value: newValue?.ma_nt ? newValue.ma_nt : null,
                                                        },
                                                    });
                                                }}
                                            />
                                            {/* <Autocomplete
                                                id="apartment-select"
                                                sx={{ width: "100%" }}
                                                options={currencyOptions}
                                                autoHighlight
                                                getOptionLabel={(option: any) => `${option.ma_nt}`}
                                                value={
                                                    obCashBank.ma_nt
                                                        ? currencyOptions.find(
                                                            (item: any) => item.ma_nt === obCashBank.ma_nt
                                                        ) : null
                                                }
                                                onChange={(e, newValue: any) => {
                                                    handleChange({
                                                        target: {
                                                            name: "ma_nt",
                                                            value: newValue?.ma_nt ? newValue.ma_nt : null,
                                                        },
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className="cc-input"
                                                        //placeholder="-- Mã ngoại tệ --"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password", // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                            /> */}

                                            {/* Tỷ giá */}

                                            <CurrencyTextField
                                                className="flex-grow-1 form-control currency-input"
                                                style={{ width: "100%", textAlign: "right" }}
                                                allowDecimals
                                                // decimalsLimit={2}
                                                decimalsLimit={currencyOptions.find(p => p.currency === obCashBank.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                                decimalSeparator="."
                                                groupSeparator=","
                                                decimalScale={0}
                                                placeholder="Tỷ giá"
                                                intlConfig={currencyOptions.find(p => p.ma_nt === obCashBank.ma_nt?.toLocaleUpperCase())}
                                                name="ty_gia"
                                                onValueChange={handleChangeCurrentInput}
                                                value={obCashBank.ty_gia}
                                            />
                                        </Stack>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} >
                                <Stack spacing={2}>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Tiền hàng
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            // decimalsLimit={2}
                                            decimalsLimit={currencyOptions.find(p => p.currency === obCashBank.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            decimalScale={0}
                                            //placeholder="Tiền hàng"
                                            intlConfig={currencyOptions.find(p => p.ma_nt === obCashBank.ma_nt?.toLocaleUpperCase())}
                                            name="t_tien2"
                                            onValueChange={handleChangeCurrentInput}
                                            value={obCashBank.t_tien_nt2}
                                        />
                                    </Box>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Tiền thuế
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            // decimalsLimit={2}
                                            decimalsLimit={currencyOptions.find(p => p.currency === obCashBank.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            decimalScale={0}
                                            //placeholder="Tiền thuế"
                                            intlConfig={currencyOptions.find(p => p.ma_nt === obCashBank.ma_nt?.toLocaleUpperCase())}
                                            name="t_thue"
                                            onValueChange={handleChangeCurrentInput}
                                            value={obCashBank.t_thue_nt}
                                        />
                                    </Box>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Tổng thanh toán
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            // decimalsLimit={2}
                                            decimalsLimit={currencyOptions.find(p => p.currency === obCashBank.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            decimalScale={0}
                                            // placeholder="Tổng tiền thanh toán"
                                            intlConfig={currencyOptions.find(p => p.ma_nt === obCashBank.ma_nt?.toLocaleUpperCase())}
                                            name="t_tt_nt"
                                            onValueChange={handleChangeCurrentInput}
                                            value={obCashBank.t_tt_nt}
                                        />
                                    </Box>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Đã thanh toán
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            // decimalsLimit={2}
                                            decimalsLimit={currencyOptions.find(p => p.currency === obCashBank.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            decimalScale={0}
                                            // placeholder="Số tiền đã thanh toán"
                                            intlConfig={currencyOptions.find(p => p.currency === obCashBank.ma_nt?.toLocaleUpperCase())}
                                            name="da_tt_nt"
                                            onValueChange={handleChangeCurrentInput}
                                            value={obCashBank.da_tt_nt}
                                        />
                                    </Box>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Còn phải thanh toán
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            // decimalsLimit={2}
                                            decimalsLimit={currencyOptions.find(p => p.currency === obCashBank.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            decimalScale={0}
                                            //placeholder="Số tiền còn phải thanh toán"
                                            intlConfig={currencyOptions.find(p => p.currency === obCashBank.ma_nt?.toLocaleUpperCase())}
                                            name="du_tt_nt"
                                            onValueChange={handleChangeCurrentInput}
                                            value={obCashBank.du_tt_nt}
                                        />
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>


            {
                props.action
                && props.action !== ACTIONS.VIEW
                && (<>
                    <DialogActions>

                        <DHSDialogAction

                            menu={menu}
                            onClickAction={handleClickAction}
                            action={props.action}
                        />

                    </DialogActions>
                </>)
            }


            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog >
    );
};

export default ReceivableAction;
