

import React from 'react'

import Gantt, {
    Tasks,
    Dependencies,
    Resources,
    ResourceAssignments,
    Column,
    Editing,
    HeaderFilter,
    FilterRow,
    Validation,
} from 'devextreme-react/gantt';

import { ContextMenuPreparingEvent, GanttScaleType } from 'devextreme/ui/gantt';

// import 'devextreme/dist/css/dx.common.css';
import 'devexpress-gantt/dist/dx-gantt.css';
import './styles.css'
import { AppBar, Button, Dialog, DialogContent, Grid, IconButton, Popover, Slide, Toolbar, Typography } from '@mui/material';
import DHSSelectBox from '../DHS_SelectBox';
import Switch from 'devextreme-react/switch';

import SettingsIcon from '@mui/icons-material/Settings';
import moment from 'moment';

import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from '@mui/material/transitions';


interface IProps {
    tasksDataSource: any[];
    dependenciesDataSource?: any[];
    resourcesDataSource?: any[];
    resourceAssignments?: any[];
    taskKey: string;
    taskParent: string;
    taskStartTime: string;
    taskEndTime: string;
    taskProgress: string;
    taskTitle: string;
    taskReceiver: string; // NGƯỜI THỰC HIỆN
    taskStatus: string; // ĐANG LÀM | TẠM NGƯNG | HOÀN THÀNH
    dependenciesKey?: string;
    dependenciesPredecessor?: string;
    dependenciesType?: string;
    dependenciesSuccessorId?: string;
    Editing?: boolean;
    resourcesKey?: string;
    resourcesText?: string;
    resourcesColor?: string;
    selectedRowKey: (key: string | number) => void;
    rootValue?: string | number;
    height?: number;
    scaleType?: GanttScaleType;
    taskTimeCalculator?: string;
    open?: boolean;
    onClose?: () => void;
}

interface IToolbar {
    scaleType: GanttScaleType;
    filterRow: boolean;
    editing: boolean;
}

interface IColumn {
    dataField: string,
    caption: string,
    width: number,
    dataType: string,
    format: string,
}


const scaleType = [{
    code: "auto",
    name: "Tự động"
}, {
    code: "minutes",
    name: "Phút"
}, {
    code: "hours",
    name: "Giờ"
}, {
    code: "sixHours",
    name: "6 giờ"
},
{
    code: "days",
    name: "Ngày"
}
    , {
    code: "weeks",
    name: "Tuần"
}, {
    code: "months",
    name: "Tháng"
}, {
    code: "quarters",
    name: "Quý"
}, {
    code: "years",
    name: "Năm"
}]

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const DHSGantt = (props: IProps) => {

    const [toolbar, setToolbar] = React.useState<IToolbar>({} as IToolbar);

    React.useEffect(() => {
        setToolbar({
            scaleType: props.scaleType ? props.scaleType : "months",
            filterRow: false,
            editing: props.Editing !== undefined ? props.Editing : true
        })
    }, [])

    const handleContextMenuItems = (items: any) => {
        console.log(items);

        if (!items) {
            return;
        }

        items.forEach((item: any) => {
            if (item.items) {
                // Xử lý mục menu lồng nhau
                handleContextMenuItems(item.items);
            }

            switch (item.text) {
                case "Sort Ascending":
                    item.text = "Sắp xếp tăng dần";
                    break;
                case "Sort Descending":
                    item.text = "Sắp xếp giảm dần";
                    break;
                case "Clear Sorting":
                    item.text = "Hủy sắp xếp";
                    break;
                case "Group by This Column":
                    item.text = "Group cột này";
                    break;
                case "Ungroup All":
                    item.text = "Hủy bỏ group tất cả";
                    break;
                case "Fix":
                    item.text = "Cố định";
                    break;
                case "To the left":
                    item.text = "Từ trái sang";
                    break;
                case "To the right":
                    item.text = "Từ phải sang";
                    break;
                case "Unfix":
                    item.text = "Không cố định";
                    break;
            }
        });
    };

    const handleContextMenuPreparing = (e: ContextMenuPreparingEvent) => {
        // Thay đổi văn bản của các mục menu trong e.items
        handleContextMenuItems(e.items)
    };

    const handleChangeToolbar = (e: any) => {
        const { name, value } = e.target;
        setToolbar({
            ...toolbar,
            [name]: value
        })
    }

    const selectedRowKey = async (e: any) => {
        const { selectedRowKey } = e;
        if (selectedRowKey) {
            props.selectedRowKey(selectedRowKey)
        }
    }

    const [open, setOpen] = React.useState<boolean>(false);

    const handleOpen = () => {
        setOpen(pre => !pre);
    }

    return <>

        <Dialog
            open={props.open ? props.open : false}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => { if (props.onClose) props.onClose(); }}
            aria-describedby="alert-dialog-slide-description"
            className='d-flex justify-content-end'
            maxWidth='md'
            fullWidth
            fullScreen
        >
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        Tùy chỉnh
                    </Typography>
                    <IconButton
                        edge="end"
                        color="error"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon sx={{ color: '#1f2937' }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <DHSSelectBox
                    dataSource={scaleType}
                    displayExpr='name'
                    searchExpr='name'
                    value={toolbar.scaleType}
                    name="scaleType"
                    valueExpr='code'
                    onValueChanged={handleChangeToolbar}
                />
                <div className="dx-field">
                    <div className="dx-field-label">Lọc</div>
                    <div className="dx-field-value">
                        <Switch
                            switchedOnText='Bật'
                            switchedOffText='Tắt'
                            defaultValue={false}
                            value={toolbar.filterRow}
                            onValueChange={(e) => handleChangeToolbar({
                                target: {
                                    name: "filterRow",
                                    value: e
                                }
                            })} />
                    </div>
                </div>
            </DialogContent>
        </Dialog>

        <Grid container spacing={2} className='mb-1 d-md-flex justify-content-md-end'>
            <Grid item xs={12} md={2}>
                <DHSSelectBox
                    dataSource={scaleType}
                    displayExpr='name'
                    searchExpr='name'
                    value={toolbar.scaleType}
                    name="scaleType"
                    valueExpr='code'
                    onValueChanged={handleChangeToolbar}
                />
            </Grid>
        </Grid>
        < Gantt
            taskListWidth={900}
            scaleType={toolbar.scaleType}
            height={props.height ? `${props.height}vh` : '70vh'}
            // rootValue={props.rootValue ? props.rootValue : -1}
            taskTitlePosition={'inside'}
            taskContentRender={(e) => TaskTemplate(e, props.taskStartTime, props.taskProgress, props.taskTitle, props.taskStatus)}
            taskTooltipContentRender={(e) => TaskTimeTooltipContentRender(e, props.taskTitle,
                props.taskStartTime, props.taskEndTime,
                props.taskProgress, props.taskReceiver,
                props.taskStatus)
            }
            // accessKey={undefined}
            // activeStateEnabled={true}
            // allowSelection={true}
            // disabled={false}
            startDateRange={moment().startOf('year').toDate()}
            endDateRange={moment().endOf('year').toDate()}
            // firstDayOfWeek={0}
            // focusStateEnabled={false}
            // showDependencies={true}
            // showResources={true}
            // showRowLines={true}
            // visible={true}
            // onContextMenuPreparing={handleContextMenuPreparing}
            onSelectionChanged={(e) => {
                selectedRowKey(e);
            }}
        >
            <Column dataField={props.taskTitle} caption="Tiêu đề công việc" width={200} />
            <Column dataField={props.taskReceiver} caption="Nhân viên" width={200} />
            <Column dataField={props.taskStartTime} caption="Bắt đầu" dataType="datetime" format={'dd/MM/yyyy HH:mm'} width={150} />
            <Column dataField={props.taskEndTime} caption="Kết thúc" dataType="datetime" format={'dd/MM/yyyy HH:mm'} width={150} />
            <Column dataField={props.taskTimeCalculator} caption="Giờ" dataType="number" />
            <Column dataField={props.taskStatus} caption="Trạng thái" />
            <Column dataField={props.taskProgress} caption="Tiến độ (%)" dataType="number" />

            <Tasks
                keyExpr={props.taskKey}
                parentIdExpr={props.taskParent}
                startExpr={props.taskStartTime}
                endExpr={props.taskEndTime}
                titleExpr={props.taskTitle}
                progressExpr={props.taskProgress}
                dataSource={props.tasksDataSource}
            />

            <Dependencies
                keyExpr={props.dependenciesKey ? props.dependenciesKey : ""}
                predecessorIdExpr={props.dependenciesPredecessor ? props.dependenciesPredecessor : ""}
                typeExpr={props.dependenciesType ? props.dependenciesType : ""}
                successorIdExpr={props.dependenciesSuccessorId ? props.dependenciesSuccessorId : ""}
                dataSource={props.dependenciesDataSource}
            />
            <Resources
                keyExpr={props.resourcesKey ? props.resourcesKey : ""}
                textExpr={props.resourcesText ? props.resourcesText : ""}
                colorExpr={props.resourcesColor ? props.resourcesColor : ""}
                dataSource={props.resourcesDataSource}
            />
            {/* <ResourceAssignments dataSource={props.resourceAssignments} /> */}


            {/* <Validation autoUpdateParentTasks /> */}
            <Editing enabled={toolbar.editing} />
            <HeaderFilter visible={true} />
            <FilterRow visible={toolbar.filterRow} />
        </ Gantt>

    </>
}

export default DHSGantt;

const TaskTemplate = (item: any, end_time: string, progress: string, title: string, status: string) => {
    const titleN: any = Object.values(item.taskData)[Object.keys(item.taskData).indexOf(title)];
    const progressN: any = Object.values(item.taskData)[Object.keys(item.taskData).indexOf(progress)];
    const end_timeN: any = Object.values(item.taskData)[Object.keys(item.taskData).indexOf(end_time)];
    const statusN: any = Object.values(item.taskData)[Object.keys(item.taskData).indexOf(status)];

    return <>
        <div
            // className={`custom-task ${getTaskColor(end_timeN, progressN)} d-flex flex-column task-content`}
            className={`custom-task ${getTaskColorByStatus(statusN)} d-flex flex-column task-content`}
            style={{
                width: `${item.taskSize.width}px`,
            }}>
            <div className=''>
                <div className='p-1'>{titleN}</div>
                {/* <div className='custom-task-row'>{item.taskResources[0].text}</div> */}
            </div>
            <div className='task-progress task-progress-first' >
                <div
                    className='h-100 task-progress task-progress-second'
                    style={{
                        width: `${progressN}%`,
                    }}
                >
                </div>
            </div>
        </div>
    </>
}


const getTaskColor = (end_time: Date, progress: number) => {
    const NOWDATE = new Date();
    const PROGRESS_DONE: number = 100;

    if (progress < PROGRESS_DONE && end_time <= NOWDATE)
        return `task-warning`
    else if (progress < PROGRESS_DONE && end_time > NOWDATE) {
        return `task-dannger`
    } else {
        return `task-success`;
    }
}


const getTaskColorByStatus = (status: string) => {

    switch (status) {
        case '':
            return 'text-white customer-payment-not-yet-bg'
        case 'Chưa làm':
            return 'text-white is-new-bg'
        case 'Đang làm':
            return 'text-white is-progressing-bg'
        case 'Hoàn thành':
            return 'text-white is-done-bg'
        case 'Đã nhận việc':
            return 'text-white is-received-bg'
        case 'Chưa nhận việc':
            return 'text-white is-new-bg'
        default:
            return '';
    }
}

const TaskTimeTooltipContentRender = (item: any, ptitle: string, pstart: string, pend: string, pprogress: string, preceiver: string, Pstatus_name: string) => {

    const title: any = Object.values(item)[Object.keys(item).indexOf(ptitle)];
    const progress: any = Object.values(item)[Object.keys(item).indexOf(pprogress)];
    const start: any = Object.values(item)[Object.keys(item).indexOf(pstart)];
    const end: any = Object.values(item)[Object.keys(item).indexOf(pend)];
    const receiver: any = Object.values(item)[Object.keys(item).indexOf(preceiver)];
    const status_name: any = Object.values(item)[Object.keys(item).indexOf(Pstatus_name)];

    // console.log(title,progress,start,end,receiver,status_name)
    return (
        <>
            <div className="taskTooltipContentRender">
                <div className="text-justify text-limited"><strong>Công việc: </strong> {title}</div>
                <div className="text-justify text-limited"><strong>Nhân viên: </strong> {receiver}</div>
                <div className="text-justify"><strong>Bắt đầu:</strong> {moment(start).format("DD/MM/YYYY HH:mm")}</div>
                <div className="text-justify"><strong>Kết thúc:</strong> {moment(end).format("DD/MM/YYYY HH:mm")}</div>
                <div className="text-justify"><strong>Trạng thái:</strong> {status_name}</div>
                <div className="text-justify"><strong>Tiến độ:</strong> {`${progress}%`}</div>
            </div >
        </>
    );
}

// showTaskDetailsDialog