import { AnyAction } from "redux"
import { PUSH_NOTIFICATION, SET_IS_READ_NOTIFICATION, SET_TOTAL_READ_NOTIFICATION, SET_TOTAL_VIEW_NOTIFICATION } from "../constants/ActionTypes"

export interface INotificationState {
    title?: string,
    body?: string,
    data?: any
}

interface NotificationState {
    notification: INotificationState[],
    isRead: boolean,
    numUnRead: number,
    numUnView: number,
    numNotiFromFCM: number

}

const initialState: NotificationState = {
    notification: [],
    isRead: false,
    numUnRead: 0,
    numUnView: 0,
    numNotiFromFCM: 0
}

var NotificationReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case PUSH_NOTIFICATION:
            return {
                ...state,
                isRead: false,
                numUnRead: state.numUnRead + 1,
                numNotiFromFCM: state.numNotiFromFCM + 1,
                notification: [
                    action.payload,
                    ...state.notification,

                ]
            } as NotificationState
        case SET_IS_READ_NOTIFICATION:
            return {
                ...state,
                isRead: action.payload,
                numUnRead: action.payload ? 0 : state.numUnRead
            } as NotificationState
        case SET_TOTAL_READ_NOTIFICATION:
            return {
                ...state,
                numUnRead: action.payload
            } as NotificationState
        case SET_TOTAL_VIEW_NOTIFICATION:
            return {
                ...state,
                numUnView: action.payload
            } as NotificationState
        default:
            return state;
    }

}

export default NotificationReducer;