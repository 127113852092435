import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IReducer, Notification } from "../../../../components/layouts";
import { AppSession } from "../../../shared/app-session/app-session";
import {
  BaseformClient,
  ParasysActionType,
  ReportInfo,
  RptCATMNH01,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";

import { useHistory, useLocation } from "react-router-dom";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import { callApiToken } from "../../../../routes/configApi";
import "../../actions/ApartmentBuilding.css";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { SysActionType } from "../../../shared/service-proxies/api-shared";
import { useHotkeys } from "react-hotkeys-hook";
import SearchOperation from "../../actions/report-action/searchOperationAction";
import DHSBreadcrumb from "../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { exportExcelReport } from "../../../../components/DHS/DHS_ExportExcelReport/exportExcelReport";
import { EventInfo } from "devextreme/events";
import dxDataGrid, { SelectionChangedInfo } from "devextreme/ui/data_grid";
import DataGrid from "devextreme-react/data-grid";
var appSession: AppSession;

interface IAction {
  open: boolean;
  type: string;
  payload: any;
}

interface IWaterReport {
  ma_cty: string | undefined;
  ngay_Ct1: Date;
  ngay_ct2: Date;
  apartment_building_id: string | undefined;
  spname: string | undefined;
}

const InvoiceBalanceReport: React.FC<{}> = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = React.useState<boolean>(false);
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const [reload, setReload] = React.useState<boolean>(false);
  const [dataTable, setDataTable] = React.useState<RptCATMNH01[]>(
    [] as RptCATMNH01[]
  );
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

  const Token = {
    Token: SessionLogin.Token,
  };
  const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
  const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
  const [isShowSort, setShowSort] = React.useState<boolean>(false);
  const [isShowFilter, setShowFilter] = React.useState<boolean>(false);

  const [data, setData] = React.useState<IWaterReport>({
    ma_cty: "",
    ngay_Ct1: new Date(),
    ngay_ct2: new Date(),
    apartment_building_id: "",
    spname: "",
  });

  const [reportInfoList, setReportInfoList] = React.useState<any>([]);
  const [reportInfo, setReportInfo] = React.useState<ReportInfo>(
    {} as ReportInfo
  );
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isExport, setIsExport] = React.useState<boolean>(false);

  const [actionTypes, setActionTypes] = React.useState<ParasysActionType[]>();
  const baseFormClient = new BaseformClient(appSession, BASE_URL_API);
  const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

  useHotkeys<HTMLParagraphElement>("ctrl+f1", () => setIsOpen(!isOpen));

  useHotkeys<HTMLParagraphElement>("alt+1", () => setShowSearch(!isShowSearch));

  useHotkeys<HTMLParagraphElement>("alt+2", () => setShowGroup(!isShowGroup));

  useHotkeys<HTMLParagraphElement>("alt+3", () => setShowSort(!isShowSort));

  useHotkeys<HTMLParagraphElement>("alt+4", () => setShowFilter(!isShowFilter));


  const onloadData = () => {
    callApiToken("api/QLCCReport/WaterReport", "POST",
      {
        ...data,
        ma_cty: SessionLogin.ma_cty,
        ngay_Ct1: new Date(Date.UTC(data.ngay_Ct1.getFullYear(), data.ngay_Ct1.getMonth(), data.ngay_Ct1.getDate(), 0, 0)),
        ngay_ct2: new Date(Date.UTC(data.ngay_ct2.getFullYear(), data.ngay_ct2.getMonth(), data.ngay_ct2.getDate(), 23, 59)),
        spname: reportInfo.spname || "",
        apartment_building_id: apartment_building_id
      },
      Token)
      .then((response: any) => {
        setDataTable(response.data.Table);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    // debugger
    baseFormClient.getActionType({
      ...new ParasysActionType(),
      action_type: menu?.action_type,
      language: "vi-VN",
    } as ParasysActionType)
      .then((res) => {
        setActionTypes(res);
      });

    callApiToken("api/Common/ReportInfoGet", "POST",
      {
        menuid: menu?.menuid,
        language: "vi-VN",
        apartment_building_id: apartment_building_id,
        ma_cty: SessionLogin.ma_cty,
      },
      Token
    ).then((response) => {
      setReportInfoList(response.data);
      if (response.data.length > 0) {
        setReportInfo({
          ...reportInfo,
          ...response.data[0],
          header: response.data[0]?.formateD_COL_LIST.split(","),
        } as ReportInfo);
      }
    }).catch((error) => {
      console.log(error);
    });
  }, [menu?.component]);

  React.useEffect(() => {
    // debugger
    onloadData();
  }, [reload, reportInfo, apartment_building_id]);


  const handleClickAction = (action: SysActionType, nowTime: Date) => {
    if (action.action_code === ACTIONS.SEARCH) {
      setIsOpen(true);
    } else if (action.action_code === ACTIONS.EXPORTEXCEL) {
      if (dataGridRef.current && reportInfo) {
        if (reportInfo) {
          exportExcelReport(
            dataGridRef,
            reportInfo,
            data.ngay_Ct1,
            data.ngay_ct2
          );
        } else {
          Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
        }
      }
    }
  };

  const handleSubmit = () => {
    setReload(!reload);
    setIsOpen(false);
  };
  const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
  const dataGridRef = React.useRef<DataGrid>(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

  const handleSelectionChanged = (
    e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
  ) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
        console.log(keys);

        const index = e.component.getRowIndexByKey(keys[0]);

        setSelectedRowIndex(index >= 0 ? index : -1);
        setSelectedItemKeys(keys[0]);
      });
    }
  };

  const handlePageChange = () => {
    setSelectedRowIndex(-1);
    setSelectedItemKeys(undefined);
  };
  const handleExporting = (e: any) => {
    if (reportInfo) {
      try {
        exportExcelReport(
          dataGridRef,
          reportInfo,
          data.ngay_Ct1,
          data.ngay_ct2
        );
      } catch (error) {
        Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
      }
    } else {
      Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
    }
  };

  return (
    <div className="container-fluid">
      {/* Lọc dữ liệu */}

      <SearchOperation
        isOpen={isOpen}
        setIsOpen={(status: any) => setIsOpen(status)}
        reportInfo={reportInfo}
        setReportInfo={(data: ReportInfo) => {
          setReportInfo(data as ReportInfo);
        }}
        setReload={() => setReload(!reload)}
        data={data}
        setData={(data: any) =>
          setData({
            ...data,
          })
        }
      />

      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          marginTop: 15,
          marginBottom: 15,
          fontSize: 22,
          fontWeight: 550,
        }}
      >
        <DHSBreadcrumb location={location} />
      </div>

      {/* Control */}
      <DHSToolbarRole id={" "} menu={menu} onClickAction={handleClickAction} />
      <DHSDataGridEdit
        data={dataTable}
        table_name={menu?.menuid}
        // key="so_ct"
        mode="popup"
        dataGridRef={dataGridRef}
        allowEdit={false}
        pageSize={menu?.page_size}
        isPagination={menu?.is_pagination}
        isMultiple={menu?.is_multiple}
        groupingAutoExpandAll={false}
        handlePageChange={handlePageChange}
        handleSelectionChanged={handleSelectionChanged}
        handleExporting={handleExporting}
      />

      <BlockUI blocking={loading} title={""}></BlockUI>
    </div>
  );
};

export default InvoiceBalanceReport;
