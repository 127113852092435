import React from "react";
import { useParams } from "react-router-dom";
import notification from "../../../../../common/notification/notification";
import Select from "../../../../../components/DHS/DHS_Select";
import { AppSession } from "../../../../shared/app-session/app-session";
import {
  DOCClient,
  DocUserGuideProduction,
  DocUserGuideProductionModule,
  IDocUserGuideProduction,
  IDocUserGuideProductionModule,
  RoleRightWebModel,
} from "../../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../shared/service-proxies/config";

interface IProps {
  id?: string;
  action: RoleRightWebModel | undefined;
  nowTime: Date;
  onDestroyAction?: (isDestroy: boolean, goBack?: number) => void;
}
var appSession: AppSession;
const ModuleActionPage = (props: IProps) => {
  const docClient = new DOCClient(appSession, BASE_URL_API);
  const [data, setData] = React.useState<IDocUserGuideProductionModule>(
    new DocUserGuideProductionModule()
  );
  const [back, setBack] = React.useState<number>(-1);
  const [{ _id }, setId] = React.useState(useParams<{ _id: string }>());
  const [productOptions, setProductOptions] = React.useState<
    IDocUserGuideProduction[]
  >([]);
  React.useEffect(() => {
    if (_id) {
      docClient
        .docUserGuideProductionModuleGet({
          ...new DocUserGuideProductionModule(),
          id: _id,
        } as DocUserGuideProductionModule)
        .then((response) => {
          setData(response[0] as DocUserGuideProductionModule);
        });
    }
  }, []);
  React.useEffect(() => {
    const getAllProductData = () => {
      docClient
        ?.docUserGuideProductionGet(new DocUserGuideProduction())
        .then((res) => {
          setProductOptions(res);
        })
        .catch((error) => console.log(error));
    };
    getAllProductData();
  }, []);
  React.useEffect(() => {
    if (
      props.action &&
      props.action.class_name?.toLocaleUpperCase() === "SAVE"
    ) {
      try {
        if (_id) {
          docClient
            .docUserGuideProductionModuleUpd({
              ...data,
              id: _id,
            } as DocUserGuideProductionModule)
            .then((res) => {
              setBack((item) => (item -= 1));
              if (res.ret === 0) {
                notification("success","Cập nhật thành công");
              } else notification("error","Cập nhật thất bại");
            });
        } else {
          docClient
            .docUserGuideProductionModuleIns(
              data as DocUserGuideProductionModule
            )
            .then((res) => {
              if (res.ret === 0 && res.id) {
                setId({ _id: res.id });
                notification("success", "Thêm thành công");
              } else notification("success", "Thêm thất bại");
            });
        }
        if (props.onDestroyAction) props.onDestroyAction(true, back - 1);
      } catch (error) {
        console.log(error);
      }
    }
  }, [props.nowTime]);

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  return (
    <>
      <div className="d-flex p-3 justify-content-left" style={{backgroundColor: "#FFF"}}>
        <div className="w-100" style={{ maxWidth: 800 }}>
          <div className="form-group">
            <label>Tên</label>
            <input
              className="flex-grow-1 form-control"
              type="text"
              name="name"
              placeholder="Tên"
              onChange={handleChangeInput}
              value={data.name}
            />
          </div>
          <div className="form-group">
            <label>Mô tả</label>
            <textarea
              className="flex-grow-1 form-control"
              name="description"
              placeholder="Mô tả"
              onChange={handleChangeInput}
              value={data.description}
            />
          </div>
          <div className="form-group">
            <label>Level</label>
            <input
              className="flex-grow-1 form-control"
              type="number"
              name="levels"
              placeholder="Cấp độ"
              onChange={handleChangeInput}
              value={data.levels}
            />
          </div>
          <Select
            options={productOptions}
            label="Thuộc về sản phẩm"
            onChange={handleChangeInput}
            value={data.product_id}
            fieldValue={"id"}
            fieldDisplay={"name"}
            name="product_id"
          />
        </div>
      </div>
    </>
  );
};

export default ModuleActionPage;
