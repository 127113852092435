import React, { Fragment } from "react";
import {
    Button,
    TextField,
    MenuItem,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Grid,
    Select,
} from "@mui/material";
import {
    QLCCCategoriesModel,
    QLCCClient,
    RoleRightWebModel,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

import { TransitionProps } from "@mui/material/transitions";

import BlockUI from "../../../../components/Block-UI/Block-ui";
import { Notification } from "../../../../components/layouts";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
    type?: 'INSERT-NOTIFY' | 'INSERT-NEWS' | undefined;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});
var appSession: AppSession;
const CategoriesActions = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [data, setData] = React.useState<QLCCCategoriesModel>({} as QLCCCategoriesModel);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const dataSourceType = [{
        name: 'Tin tức',
        value: 'NEWS',
    }, {
        name: 'Thông báo',
        value: 'NOTIFICATIONS'
    }]

    React.useEffect(() => {
        if (props.action === "EDIT" || props.action === "VIEW") {
            qLCCClient
                .qLCCCategoriesActions({
                    ...new QLCCCategoriesModel(),
                    id: props.itemSelected,
                    action_type: "GET",
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty,
                } as QLCCCategoriesModel)
                .then((res) => {
                    setData(res[0])
                })
            setId(props.itemSelected);
        } else {
            setData(new QLCCCategoriesModel());
            setId(undefined);
        }
    }, [props.action]);

    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        } as QLCCCategoriesModel);
    }
    const handleSubmit = () => {
        if (props.action === ACTIONS.EDIT) {
            qLCCClient
                ?.qLCCCategoriesActions({
                    ...data,
                    author_id: SessionLogin.userName,
                    date_modified: new Date(),
                    status_id: data.status_id ? data.status_id : "1",
                    id: props.itemSelected,
                    action_type: "UPDATE",
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty,
                } as QLCCCategoriesModel)
                .then((res) => {
                    // Notification(convert[res.status], error[0].message);
                    props.onEditSuccess(true);
                    props.onClose()
                });
        } else {
            qLCCClient
                ?.qLCCCategoriesActions({
                    ...data,
                    author_id: SessionLogin.userName,
                    date_modified: new Date(),
                    status_id: data.status_id ? data.status_id : "1",
                    action_type: "INSERT",
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty,
                } as QLCCCategoriesModel)
                .then((res) => {
                    Notification('success', res[0].message)
                    props.onEditSuccess(true)
                    props.onClose()
                })
        }
    }

    const titleGetAction = () => {
        switch (props.action) {
            case ACTIONS.ADD:
                return 'Thêm mới'
            case ACTIONS.EDIT:
                return 'Chỉnh sửa';
            case ACTIONS.UPDATETASK:
                return 'Cập nhật tiến độ';
            case ACTIONS.SENTTASK_2:
                return 'Giao việc';
            case ACTIONS.SENTTASK:
                return 'Chuyển tiếp việc';
            case ACTIONS.VIEW:
                return 'Chi tiết';
            default:
                return 'Thêm mới';
        }
    }

    return (
        <Dialog
            id=""
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            TransitionComponent={Transition}
            maxWidth="md"
        >
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {`Danh mục [${titleGetAction()}]`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tên Danh mục</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%" }}
                                name="name"
                                onChange={(e: any) => { handleChangeInput({ target: { name: "name", value: e.target.value } }) }}
                                value={data.name}
                                disabled={props.action === ACTIONS.VIEW}
                                placeholder="Tên danh mục ..."
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Trạng thái</InputLabel>
                            <Select
                                sx={{ width: "100%" }}
                                className="cc-input"
                                id="demo-simple-select"
                                value={data.status_id ? data.status_id : "1"}
                                name="status_id"
                                disabled={props.action === ACTIONS.VIEW}
                                onChange={(e, newValue: any) => { handleChangeInput({ target: { name: "status_id", value: newValue.props.value } }) }}
                            >
                                <MenuItem value={"1"}>Công bố</MenuItem>
                                <MenuItem value={"0"}>Không công bố</MenuItem>
                            </Select>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Danh mục cho</InputLabel>
                            <DHSAutoCompleteWithDataSource
                                dataSource={dataSourceType}
                                displayMember="name"
                                valueMember="value"
                                value={data?.type}
                                onValueChanged={(e, newValue: any) => { handleChangeInput({ target: { name: "type", value: newValue.value} }) }}
                            />

                            {/* <Select
                                sx={{ width: "100%" }}
                                className="cc-input"
                                id="demo-simple-select"
                                value={data?.type}
                                name="type"
                                disabled={props.action === ACTIONS.VIEW}
                                onChange={(e, newValue: any) => { handleChangeInput({ target: { name: "type", value: newValue.props.value } }) }}
                            >
                                <MenuItem value={"NEWS"}>Tin tức</MenuItem>
                                <MenuItem value={"NOTIFICATIONS"}>Thông báo</MenuItem>
                            </Select> */}
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSubmit} disabled={props.action === ACTIONS.VIEW}>Lưu</Button>
                <Button variant="outlined" onClick={props.onClose}>Đóng</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default CategoriesActions;
