import { IconButton } from "@mui/material";
import React from "react";
import BASE_URL_API from "../../../app/shared/service-proxies/config";

import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
interface IProps {
    value?: string,
    name: string,
    handleRemoveImage?: (data: any) => void,
    handleChangeImage?: (data: any) => void,
    handleAddNewImage?: (data: any) => void,
    width?: string | number,
    height?: string | number,
    disable?: boolean
}



const cancelButtonStyle: React.CSSProperties = {
    position: "absolute",
    top: 1,
    right: 1,
    cursor: "pointer",
};

const uploadButtonStyle: React.CSSProperties = {
    position: "absolute",
    top: 27,
    right: 1,
    cursor: "pointer",
};

const fileInputStyle: React.CSSProperties = {
    display: "none",
};

const dropzoneMessage: { [key: string]: string } = {
    "": "Kéo thả, click hoặc dán vào để thêm mới...",
    "paste": "Click hoặc dán hình vào để thêm mới...",
    "drag": "Thả hình vào để thêm mới...",
    "upload": "Chọn hình để thêm mới..."
}
const dropzoneIcon: { [key: string]: JSX.Element } = {
    "": <i className="bi bi-images" style={{ fontSize: 42, display: "block", textAlign: "center" }} />,
    "paste": <i className="bi bi-clipboard-plus-fill" style={{ fontSize: 42, display: "block", textAlign: "center" }}></i>,
    "drag": <i className="bi bi-cloud-upload-fill" style={{ fontSize: 42, display: "block", textAlign: "center" }}></i>,
    "upload": <i className="bi bi-upload" style={{ fontSize: 42, display: "block", textAlign: "center" }}></i>
}

const DHSUploadImage: React.FC<IProps> = (props) => {
    const dropZoneRef = React.useRef<HTMLDivElement>(null);
    const [dropzoneType, setDropzoneType] = React.useState<"" | "paste" | "drag" | "upload">("");

    const imageStyle: React.CSSProperties = {
        position: "relative",
        minWidth: props?.width ?? "213px",
        width: props?.width ?? "213px",
        maxWidth: props?.width ?? "213px",
        height: props?.height ?? "154px",
        minHeight: props?.height ?? "154px",
        maxHeight: props?.height ?? "154px",
    };
    return (
        <>
            {props?.value ? (
                <div style={imageStyle}>
                    <div
                        className="image-shadow"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: 'cover',
                            backgroundImage: `url("${BASE_URL_API}${props?.value ?? ""}")`,
                            backgroundSize: "80%",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "10px"
                        }}
                    />
                    <div className="position-absolute w-100 d-flex justify-content-end" style={cancelButtonStyle}>
                        <IconButton
                            color="error"
                            aria-label="upload picture"
                            component="label"
                            onClick={() => {
                                props?.handleRemoveImage && props?.handleRemoveImage({
                                    name: props?.name
                                });
                                setDropzoneType("")
                            }}
                            size="small"
                            disabled={props?.disable}
                        >
                            <CancelIcon sx={{ backgroundColor: "#fff", borderRadius: "50%" }} />
                        </IconButton>
                    </div>
                    <div className="position-absolute w-100 d-flex justify-content-end" style={uploadButtonStyle}>
                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                            size="small"
                            disabled={props.disable}
                        >
                            <input
                                id={`uploadImageUpdate`}
                                className="flex-grow-1 form-control"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                name={props?.name}
                                onChange={(e) => props?.handleChangeImage && props?.handleChangeImage(e)}
                                style={fileInputStyle}
                            />
                            <ChangeCircleIcon sx={{ backgroundColor: "#fff", borderRadius: "50%" }} />
                        </IconButton>
                    </div>
                </div>
            ) : (
                <div className="m-1">
                    <label
                        htmlFor={`uploadImageAdd`}
                        style={imageStyle}
                        className="label-file-media"
                        onClick={() => {
                            const el = dropZoneRef.current;
                            if (el) {
                                el.style.backgroundColor = "#fff"
                                el.style.border = "3px groove #333"
                                el.style.outline = "none"
                                setDropzoneType("upload")
                            }
                        }}
                    >
                        <div
                            ref={dropZoneRef}
                            className="c-img-card"
                            contentEditable
                            style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: '5px',
                                caretColor: "transparent"
                            }}
                            onBeforeInput={(e) => e.preventDefault()}
                            onDragEnter={(e) => {
                                e.preventDefault()
                                const el = dropZoneRef.current;
                                if (el) {
                                    el.style.border = "3px dashed #333"
                                    el.style.backgroundColor = "#fff"
                                    setDropzoneType("drag")
                                }
                            }}
                            onDragOver={(e) => {
                                e.preventDefault()
                                const el = dropZoneRef.current;
                                if (el) {
                                    el.style.border = "3px dashed #333"
                                    el.style.backgroundColor = "#fff"
                                    setDropzoneType("drag")
                                }
                            }}
                            onDragLeave={(e) => {
                                e.preventDefault();
                                const el = dropZoneRef.current;
                                if (el && dropzoneType === "drag") {
                                    el.style.border = "none"
                                    el.style.backgroundColor = "#a4a5a6"
                                    setDropzoneType("")
                                }
                            }}
                            onDrop={(e) => {
                                e.preventDefault()
                                const files = e.dataTransfer.files;
                                console.log(files)
                                props?.handleAddNewImage && props?.handleAddNewImage({
                                    target: {
                                        name: props?.name,
                                        files,
                                        value: files[0].name
                                    }
                                })
                            }}
                            onPaste={(e) => {
                                e.preventDefault()
                                const clipboardData = e.clipboardData || (window as any).clipboardData;
                                if (clipboardData) {
                                    const items = clipboardData.items;
                                    for (let i = 0; i < items.length; i++) {
                                        const item = items[i];
                                        if (item.kind === 'file') {
                                            const file = item.getAsFile();
                                            props?.handleAddNewImage && props?.handleAddNewImage({
                                                target: {
                                                    name: props?.name,
                                                    files: [file],
                                                    value: file?.name
                                                }
                                            })
                                        }
                                    }
                                }
                            }}
                            onMouseEnter={(e) => {
                                const el = dropZoneRef.current;
                                if (el) {
                                    el.focus()
                                    el.style.backgroundColor = "#fff"
                                    el.style.border = "3px dotted #333"
                                    el.style.outline = "none"
                                    setDropzoneType("paste")
                                }
                            }}
                            onMouseLeave={(e) => {
                                const el = dropZoneRef.current;
                                if (el && dropzoneType === "upload") {
                                    el.style.backgroundColor = "#a4a5a6"
                                    el.style.border = "none"
                                    setDropzoneType("")
                                }
                            }}
                            onBlur={(e) => {
                                const el = dropZoneRef.current;
                                if (el && dropzoneType === "paste") {
                                    el.style.backgroundColor = "#a4a5a6"
                                    el.style.border = "none"
                                    setDropzoneType("")
                                }
                            }}
                        >
                            <div className="d-flex flex-column justify-content-center">
                                {dropzoneIcon[dropzoneType]}
                                <span style={{ fontSize: 13, textAlign: "center", display: "block", padding: "0.25rem 1.25rem" }}>&nbsp;{
                                    dropzoneMessage[dropzoneType]
                                }</span>
                            </div>
                        </div>
                    </label>
                    <input
                        id="uploadImageAdd"
                        className="flex-grow-1 form-control"
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        name={props?.name}
                        onChange={(e) => props?.handleAddNewImage && props?.handleAddNewImage(e)}
                        style={fileInputStyle}
                        disabled={props?.disable}
                    />
                </div>
            )}
        </>
    );

}

export default DHSUploadImage;