import { AppBar, Box, Breakpoint, Dialog, DialogActions, DialogContent, Divider, IconButton, PaperProps, Slide, SxProps, Theme, Toolbar, Typography } from "@mui/material";
import React from "react";
import DHSDialogAction from "../DHS_DialogAction/DialogAction";
import BlockUI from "../../Block-UI/Block-ui";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import { ACTIONS, ACTIONS_NAME } from "../../../common/enums/DHSToolbarRoleAction";
import { SysActionType } from "../../../app/shared/service-proxies/api-shared";
import DHSDialogAction2 from "../DHS_DialogAction2/DialogAction2";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});
interface IProps {
    id?: string,
    open: boolean,
    action: string | ACTIONS,
    action_type?: 1 | 2 | 3,
    maxWidth?: false | Breakpoint,
    isLoading?: boolean,
    defaultFullScreen?: boolean,
    disableEditFullscreen?: boolean,
    disableFullscreen?: boolean,
    keepMounted?: boolean,
    toolbarTitle?: string,
    minHeightPaper?: number,
    minWidthPaper?: number,
    PaperProps?: Partial<PaperProps>,
    sx?: SxProps<Theme>,
    onClose: () => void,
    handleClickAction: (action: SysActionType, nowTime: Date) => void
}

const DHSDialog: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [fullScreen, setFullscreen] = React.useState<boolean>(props?.defaultFullScreen ?? false);

    const getActionType = () => {
        switch (props?.action_type) {

            case 2:
                return <DHSDialogAction2

                    action={props.action}
                    menu={menu}
                    onClickAction={props.handleClickAction}
                    isDialogAction
                />
            default:
                return <DHSDialogAction

                    action={props.action}
                    menu={menu}
                    onClickAction={props.handleClickAction}
                    isDialogAction
                />
        }
    }
    return (
        <Dialog
            id={props?.id}
            open={props.open}
            onClose={(e, reason) => {
                if(reason !== "backdropClick"){
                    props.onClose();

                }
            }}
            
            PaperProps={{
                sx: !fullScreen ? {
                    ...props?.PaperProps?.sx,
                    minHeight: `${props?.minHeightPaper ?? 90}vh`,
                    height: `${props?.minHeightPaper ?? 90}vh`
                } as SxProps<Theme> : undefined
            }}
            sx={props?.sx}
            TransitionComponent={Transition}
            maxWidth={props?.maxWidth ?? "lg"}
            fullWidth
            fullScreen={fullScreen}
            // className="Common-control"
            keepMounted={props?.keepMounted}
        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                        className="app-bar-dialog"
                    >
                        {props?.toolbarTitle ??<>{`${menu?.bar} / `} <span style={{color: "#9ca3af"}}> {`${ACTIONS_NAME[props?.action!]}`}  </span> </>} 
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1.25 }}>
                        <IconButton
                            edge="end"

                            onClick={() => {
                                if(!props?.disableEditFullscreen){
                                    setFullscreen(pre => !pre)
                                }
                            }}
                            aria-label="full screen"
                        >
                            {fullScreen ? (!props?.disableEditFullscreen ? <FullscreenExitIcon sx={{ color: '#1f2937' }} /> :<></> ) : (!props?.disableFullscreen ? <FullscreenIcon sx={{ color: '#1f2937' }} /> : <></>)}
                        </IconButton>
                        <IconButton
                            edge="end"
                            onClick={() => {
                                props.onClose();
                            }}
                            aria-label="close"
                        >
                            <CloseIcon
                                sx={{ color: '#1f2937' }}
                            />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                {props?.children}
            </DialogContent>
            <Divider />
            <DialogActions sx={{ py: "1rem"}}>
                {
                    getActionType()
                }
            </DialogActions>
           { props.isLoading &&  <BlockUI blocking={props.isLoading} title={""}></BlockUI> }

        </Dialog >
    )
}

export default DHSDialog;