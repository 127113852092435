export const printf = (format: string, ...args: any[]) => {
    return format.replace(/%(\w)/g, (_, specifier) => {
      const arg = args.shift();
      switch (specifier) {
        case 'd':
          return String(parseInt(arg));
        case 'f':
          return String(parseFloat(arg).toFixed(2));
        default:
          return arg;
      }
    });
  }

  export const removeParamsOnLink = (link: string | undefined): string => {
    return link ? link.replace(/\/:[^/]+/g, "") : ""
  }
  
  
  export const removeDiacriticsAndReplaceSpaces = (input: string): string => {
    const diacriticsMap: { [key: string]: string } = {
      á: 'a', à: 'a', ả: 'a', ã: 'a', ạ: 'a',
      ắ: 'a', ằ: 'a', ẳ: 'a', ẵ: 'a', ặ: 'a',
      é: 'e', è: 'e', ẻ: 'e', ẽ: 'e', ẹ: 'e',
      í: 'i', ì: 'i', ỉ: 'i', ĩ: 'i', ị: 'i',
      ó: 'o', ò: 'o', ỏ: 'o', õ: 'o', ọ: 'o',
      ố: 'o', ồ: 'o', ổ: 'o', ỗ: 'o', ộ: 'o',
      ú: 'u', ù: 'u', ủ: 'u', ũ: 'u', ụ: 'u',
      ứ: 'u', ừ: 'u', ử: 'u', ữ: 'u', ự: 'u',
      ý: 'y', ỳ: 'y', ỷ: 'y', ỹ: 'y', ỵ: 'y',
      đ: 'd',
    };
    const normalizedText = input
      .toLowerCase() // Chuyển chuỗi về chữ thường
      .split('')
      .map(char => diacriticsMap[char] || char) // Ánh xạ ký tự với bảng chuyển đổi
      .join('');
  
    // Thay thế dấu cách bằng dấu "_"
    return normalizedText.replace(/\s+/g, '_');
  }
  
  
  export const capitalizeFirstLetter = (inputString: string): string => {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }
  export const lowerFirstLetter = (inputString: string): string => {
    return inputString.charAt(0).toLowerCase() + inputString.slice(1);
  }