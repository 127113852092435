import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteRenderOptionState, TextField } from "@mui/material";
import _ from "lodash";
import React from "react";


interface IProps {
    dataSource: any[],
    value?: any,
    defaultValue?: (data: any[]) => void,
    displayMember?: string,
    getOptionLabel?: (option: any) => string,
    valueMember: string,
    readOnly?: boolean,
    disabled?: boolean,
    onValueChanged?: (event: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined) => void
    id?: string
    filterSelectedOptions?: any;
    multiple?: boolean;
    clearIcon?: boolean;
    className?: string;
    renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: any, state: AutocompleteRenderOptionState) => React.ReactNode,
    placeholder?: string,
}

const DHSAutoCompleteWithDataSource: React.FC<IProps> = (props) => {

    const [isDefaulted, setIsDefaulted] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (props.dataSource.length > 0 && !isDefaulted) {
            props?.defaultValue && props.defaultValue(props.dataSource)
            setIsDefaulted(true)

        }
    }, [props.dataSource, isDefaulted])


    return <Autocomplete
        className={props.className}
        id={props.id ? props.id : ''}
        sx={{ width: "100%" }}
        options={props.dataSource}
        autoHighlight
        clearIcon={props?.clearIcon}
        getOptionLabel={(option: any) => props.getOptionLabel ? props.getOptionLabel(option) : props.displayMember ? option[props.displayMember] : ''}
        multiple={props?.multiple ?? false}
        value={
            props?.multiple
                ? props.dataSource.filter(item => (props.value as string)?.split(";").includes(item[props.valueMember]))
                : props?.value ? props.dataSource.find(
                    (item: any) => {
                        return (item[props.valueMember] as string)?.toLowerCase() === props.value?.toLowerCase()
                    }) : null
        }
        onChange={props?.onValueChanged}
        renderOption={props.renderOption}
        renderInput={(params) => (
            <TextField
                {...params}
                className="cc-input"
                placeholder={props?.placeholder}
                inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                }}
            />
        )}
        filterSelectedOptions={props?.filterSelectedOptions}
        disabled={props?.disabled}
        readOnly={props?.readOnly}
    />
}

export default DHSAutoCompleteWithDataSource;