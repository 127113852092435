import _ from "lodash";

export const checkRequiredField = (object: any, fields: string[]): boolean => {
  for (const field of fields) {
    if (object && object.hasOwnProperty(field)) {
      if (!object[field]) {
        if (typeof object[field] === "number" && object[field] === 0) continue;
        return false;
      }
      continue;
    }
    else {
      return false;
    }

  }
  return true;
}

export const isNullOrUndefinedOrEmpty = (value: any): boolean => {
  // Kiểm tra xem giá trị có là null hoặc undefined
  if (_.isNull(value) || _.isUndefined(value)) {
    return true;
  }

  // Kiểm tra xem giá trị có là một chuỗi rỗng (empty string)
  if (_.isString(value) && _.isEmpty(value)) {
    return true;
  }

  // Kiểm tra xem giá trị có là một mảng rỗng (empty array)
  if (_.isArray(value) && _.isEmpty(value)) {
    return true;
  }

  // Kiểm tra xem giá trị có là một đối tượng Date và không có giá trị
  if (_.isDate(value) && isNaN(value.getTime())) {
    return true;
  }

  // Kiểm tra xem giá trị có là một đối tượng rỗng (empty object)
  if (_.isObject(value) && _.isEmpty(value) && !(_.isDate(value) || _.isBoolean(value) || _.isFunction(value))) {
    return true;
  }

  

  return false;
}