import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CalendarPickerView } from "@mui/lab";
import { parseISO } from 'date-fns';
import moment from "moment";
interface IProps {
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  style?: any;
  value?: any;
  className?: string;
  type?: string;
  name?: string;
  id?: string;
  onChange?: (event: any) => void;
  views?: CalendarPickerView[];
  title?: string;
  minDate?: Date;
  maxDate?: Date;
}
export default function CTXDate(props: IProps) {
  const format = "DD/MM/YYYY";
  const formatMonth = "MM/YYYY";
  const formatYear = "YYYY";
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      className={props.className}
    >
      <DatePicker
        views={props.views}
        label={props.label}
        value={props.value}
        
        onChange={(newValue) =>
          props.onChange &&
          props.onChange({ name: props.name, value: newValue })
        }
        inputFormat={
          props.views
            ? props.views[props.views.length - 1]?.toUpperCase() === "YEAR"
              ? formatYear
              : props.views[props.views.length - 1]?.toUpperCase() === "MONTH"
                ? formatMonth
                : format
            : format
        }
        readOnly = {props.readOnly}
        renderInput={(params) => (
          <TextField
            {...params}
            id={props.id}
            className={props.className}
            name={props.name}
            style={props.style}
          title={props.title}
          // inputProps={{
          //   readOnly: props.readOnly
          // }}
          />
        )}
        minDate={props.minDate ?? new Date(1900, 1, 1, 0, 0, 0)}
        maxDate={props.maxDate ?? new Date(3000, 12, 30, 0, 0, 0)}
        disabled={props.disabled}
      />
    </LocalizationProvider>
  );
}
