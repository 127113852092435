import React, { useEffect, useState } from "react";
import {
  // ADReportClient,
  // DictionaryParamsTab,
  HomeClient,
  // ParamsGetReportInfo,
  // ReportClient,
  ReportInfo,
  SYS_Token_AuthenticationModel,
  SysActionType,
  SysGenRowTableModel,
  // ViewTypeDataGridReport,
} from "../../../shared/service-proxies/api-shared";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { AppSession } from "../../../shared/app-session/app-session";
import { httpPost } from "../../../../common/httpService";
import DHSForm from "../../../../components/DHSComponents/form-and-multi-purpose/DHSForm/DHSForm";
import { GroupItem, Item, Label, SimpleItem } from "devextreme-react/form";
import BASE_URL_API from "../../../shared/service-proxies/config";
import notification from "../../../../common/notification/notification";
import {
  STATUS_MESSAGE,
  TYPE_MESSAGE,
} from "../../../../common/Interfaces/StatusMessage";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../components/layouts";
import DHSTagBox from "../../../../components/DHSComponents/editors/DHSTagBox/DHSTagBox";
// import DHSDataGridFilterListSelectFormItem from "../../../../components/DHSUtilComponents/DHSDataGridFilterListSelectFormItem/DHSDataGridFilterListSelectFormItem";
import LabelToolTip from "../form/LabelToolTip";
import DHSDialog from "../../../../components/DHS/DHS_Dialog/DHSDialog";

interface IProps {
  open: boolean;
  action: string | ACTIONS;
  index?: number;
  data: SysGenRowTableModel;
  onClose: () => void;
  onEditSuccess?: (dataUpdated: SysGenRowTableModel) => void;
  onAddSuccess?: (dataUpdated: SysGenRowTableModel) => void;
}
const lstMasterData: { field_name: string; table_list: string }[] = [
  { field_name: "data_type", table_list: "lstDataType" },
  { field_name: "format_type", table_list: "lstFormatType" },
  { field_name: "input_type", table_list: "lstInputType" },
  { field_name: "align", table_list: "lstAlign" },
];

const ViewTypeReportList = [
  {
    code: "TC",
    name: "Tiền chuẩn",
  },
  {
    code: "NT",
    name: "Ngoại tệ",
  },
];

//chi ho tro nhap likeu
export const RoundCurrencyTypes = [
  {
    code: "round_of_price",
    name: "R_O Price",
  },
  {
    code: "round_of_amount",
    name: "R_O Amount",
  },
];

const DecimalTypes = [
  {
    code: "quantity_decimal",
    name: "Quantity",
  },
  {
    code: "price_decimal",
    name: "Price",
  },
  {
    code: "amount_decimal",
    name: "Amount",
  },
];

const detailActions = [ACTIONS.VIEW, ACTIONS.EDIT, ACTIONS.COPY];
let appSession: AppSession;
const DataGridSetingAction: React.FC<IProps> = (props) => {
  const listMenu = useSelector(
    (state: IReducer) => state.RoleReducer?.listMenu
  );
  // const adReportClient = new ADReportClient(appSession, BASE_URL_API);
  // const reportClient = new ReportClient(appSession, BASE_URL_API);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = React.useState<SysGenRowTableModel>(
    new SysGenRowTableModel()
  );
  const [readOnlyIfActionIs, setReadOnlyIfActionIs] = useState<boolean>(false);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const [masterData, setMasterData] = React.useState<{
    [key: string]: {
      option: any;
      valueMember: any;
      displayMember: any;
      tableDesign: any;
    };
  }>({});
  const sessionLogin: SYS_Token_AuthenticationModel = JSON.parse(
    localStorage.getItem("SessionLogin") || "{}"
  );
  const [isReport, setIsReport] = useState<boolean>(false);
  const [reportInfoList, setReportInfoList] = useState<ReportInfo[]>([]);
  // const [viewTypeReportList, setViewTypeReportList] = useState<
  //   ViewTypeDataGridReport[]
  // >([]);

  // useEffect(() => {
  //   if (props.data && props.data.menuid) {
  //     const menuSelected = listMenu?.find(
  //       (item) => item.menuid === props.data.menuid
  //     );

  //     console.log(menuSelected);

  //     if (menuSelected?.report) {
  //       (async () => {
  //         setLoading(true);
  //         try {
  //           const res = await adReportClient.getListReportInfo({
  //             ...new ParamsGetReportInfo(),
  //             menuid: props.data.menuid,
  //           } as ParamsGetReportInfo);

  //           const res2 = await reportClient.getViewTypeDataGridReport();

  //           setReportInfoList([...res]);
  //           setViewTypeReportList([...res2]);
  //           setIsReport(true);
  //         } catch {
  //         } finally {
  //           setLoading(false);
  //         }
  //       })();
  //     } else {
  //       setIsReport(false);
  //     }
  //   }
  // }, [props?.data]);

  useEffect(() => {
    if (detailActions.includes(props?.action! as ACTIONS)) {
      setData(props?.data);
    } else if (props?.action === ACTIONS.ADD) {
      setData(props?.data);
    }

    setReadOnlyIfActionIs(props.action === ACTIONS.VIEW);
  }, [props?.action]);

  useEffect(() => {
    (async () => {
      const tempmasterData = { ...masterData };
      setLoading(true);
      await Promise.all(
        lstMasterData.map((lmd) => {
          return httpPost<string>("api/Common/LoadDataSourceBindToControl", {
            ma_cty: sessionLogin.ma_cty,
            tablelist: lmd.table_list,
          }).then((res) => {
            const data: any = JSON.parse(res.data);
            const option_name = `${lmd.field_name}_options`;

            // Update the specific property in the shallow-copied object
            tempmasterData[option_name] = tempmasterData[option_name] || {};
            tempmasterData[option_name].option = data.Table1;
            tempmasterData[option_name].valueMember = data.Table[0].ValueMember;
            tempmasterData[option_name].displayMember =
              data.Table[0].DisplayMember;
            tempmasterData[option_name].tableDesign = data.Table[0].TableDesign;
          });
        })
      );

      setMasterData(tempmasterData);
      setLoading(false);
    })();
  }, []);

  const handleSubmit = async () => {
    switch (props?.action) {
      case ACTIONS.EDIT:
        setLoading(true);
        console.log(data);

        const responseUpd = await homeClient.sysGenRowTableUpd({
          ...new SysGenRowTableModel(),
          ...data,
        } as SysGenRowTableModel);

        if (responseUpd[0].status === 0) {
          const newData = new SysGenRowTableModel({
            ...data,
          });
          props.onEditSuccess && props.onEditSuccess(newData);
          props?.onClose();

          notification(TYPE_MESSAGE.SUCCESS, STATUS_MESSAGE["EDIT_0"]);
        } else {
          notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE["EDIT_1"]);
        }
        setLoading(false);
        break;
      case ACTIONS.ADD:
        console.log(data);

        setLoading(true);
        const responseAdd = await homeClient.sysGenRowTableIns({
          ...new SysGenRowTableModel(),
          ...data,
        } as SysGenRowTableModel);

        if (responseAdd[0].status === 0) {
          const newData = new SysGenRowTableModel({
            ...data,
            id: responseAdd[0].id,
          });

          props.onAddSuccess && props.onAddSuccess(newData);

          //

          notification(TYPE_MESSAGE.SUCCESS, STATUS_MESSAGE["ADD_0"]);
        } else {
          notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE["ADD_1"]);
        }
        setLoading(false);
        props?.onClose();
        break;
      case ACTIONS.COPY:
        setLoading(true);
        console.log(data);

        const responseCoppy = await homeClient.sysGenRowTableIns({
          ...new SysGenRowTableModel(),
          ...data,
        } as SysGenRowTableModel);

        if (responseCoppy[0].status === 0) {
          const newData = new SysGenRowTableModel({
            ...data,
            id: responseCoppy[0].id,
          });

          props.onAddSuccess && props.onAddSuccess(newData);

          notification(TYPE_MESSAGE.SUCCESS, STATUS_MESSAGE["ADD_0"]);
        } else {
          notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE["ADD_1"]);
        }
        setLoading(false);

        props?.onClose();
        break;
      default:
        break;
    }
  };

  const handleClickAction = (action: SysActionType) => {
    switch (action.action_code) {
      case ACTIONS.SAVE:
        handleSubmit();
        break;

      case ACTIONS.CLOSE:
        setData(new SysGenRowTableModel());
        setReportInfoList([]);
        // setViewTypeReportList([]);
        props.onClose();
        break;
    }
  };

  return (
    <DHSDialog
      open={props.open}
      action={props.action}
      isLoading={isLoading}
      onClose={props.onClose}
      handleClickAction={handleClickAction}
    >
      <DHSForm
        formData={data}
        labelLocation="top"
        // onFormDataChange={(value: any) => {
        //   console.log(value);

        // }}
        // onFieldDataChanged={(e) => {
        //   console.log(e.component.option('formdata'));

        // }}
        // onContentReady={(e) => {
        //   e.component.getEditor("field_name")?.focus();
        // }}
      >
        <GroupItem colCount={2} caption="Thông tin chung">
          <Item
            dataField="menuid"
            editorType={"dxTextBox"}
            editorOptions={{ readOnly: props.action !== ACTIONS.COPY }}
          >
            <Label text={"Menu ID"} />
          </Item>
          <Item
            dataField="table_name"
            editorType={"dxTextBox"}
            editorOptions={{ readOnly: readOnlyIfActionIs }}
          >
            <Label text={"Tên bảng"} />
          </Item>
        </GroupItem>
        <GroupItem colCount={4} caption="Thông tin chi tiết">
          <Item
            isRequired={true}
            dataField="column_name"
            editorType={"dxTextBox"}
            editorOptions={{ readOnly: readOnlyIfActionIs, require }}
          >
            <Label text={"Tên cột"} />
          </Item>
          <Item
            isRequired
            dataField="data_type"
            editorType={"dxSelectBox"}
            editorOptions={{
              dataSource: masterData["data_type_options"]?.option,
              displayExpr: masterData["data_type_options"]?.displayMember,
              valueExpr: masterData["data_type_options"]?.valueMember,
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Kiểu dữ liệu"} />
          </Item>
          <Item
            dataField="input_type"
            editorType={"dxSelectBox"}
            editorOptions={{
              dataSource: masterData["input_type_options"]?.option,
              displayExpr: masterData["input_type_options"]?.displayMember,
              valueExpr: masterData["input_type_options"]?.valueMember,
              readOnly: readOnlyIfActionIs,
              showClearButton: true,
            }}
          >
            <Label text={"Kiểu nhập"} />
          </Item>

          {/* <SimpleItem
            dataField={"input_type"}
            editorType={"dxDropDownBox"}
            visible={true}
            component={(dataItem: any) => {
              const dataa = dataItem.data;
              return (
                <DHSDataGridFilterListSelectFormItem
                  // requiredMessage={`Vui lòng không để trống "${dictionay.name_vn}"`}
                  keyExpr={"input_type_name"}
                  dataSource={masterData["input_type_options"]?.option}
                  tableDesign={"TD_lstInputType"}
                  displayExpr={masterData["input_type_options"]?.displayMember}
                  valueExpr={masterData["input_type_options"]?.valueMember}
                  placeholder="Select..."
                  par_name={dataa?.dataField ?? ""}
                  event={dataa}
                  // value={dataa.component.option(`formData[${dictionay.column_name}]`)}
                  dataGridOnSelectionChanged={(e, newValue) => {
                    // setData((pre: any) => ({ ...pre, [dataa?.dataField!]: newValue[props.masterData[`${dataa?.dataField}_options`]?.valueMember] } as any))
                    dataa.component.updateData(dataa?.dataField!, newValue);
                  }}
                  syncDataGridSelection={function (e): void {
                    // setData((pre: any) => ({ ...pre, [dataa?.dataField!]: e?.value } as any))
                    dataa.component.updateData(dataa?.dataField!, e.value);
                  }}
                  showClearButton={true}
                />
              );
            }}
          >
            <Label text={"Kiểu nhập"} />
          </SimpleItem> */}
          <Item
            dataField="name_vn"
            editorType={"dxTextBox"}
            editorOptions={{ readOnly: readOnlyIfActionIs }}
          >
            <Label text={"Tên Tiếng Việt"} />
          </Item>
          <Item
            dataField="name_en"
            editorType={"dxTextBox"}
            editorOptions={{ readOnly: readOnlyIfActionIs }}
          >
            <Label text={"Tên Tiếng Anh"} />
          </Item>

          <Item
            dataField="note"
            editorType={"dxTextBox"}
            editorOptions={{ readOnly: readOnlyIfActionIs }}
          >
            <Label text={"Mô tả"} />
          </Item>
          <Item
            dataField="show_form_list"
            editorType={"dxCheckBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Ẩn / Hiện"} />
          </Item>
          <Item
            dataField="show_form_edit"
            editorType={"dxCheckBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Ẩn / Hiện form sửa"} />
          </Item>
        </GroupItem>
        <GroupItem colCount={4} caption="Kích thước và vị trí">
          <Item
            dataField="width"
            editorType={"dxNumberBox"}
            editorOptions={{
              format: "fixedPoint",
              showSpinButtons: true,
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Chiều ngang Web"} />
          </Item>
          <Item
            dataField="width_mobile"
            editorType={"dxNumberBox"}
            editorOptions={{
              format: "fixedPoint",
              showSpinButtons: true,
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Chiều ngang Mobile"} />
          </Item>
          <Item
            dataField="number_order"
            editorType={"dxNumberBox"}
            editorOptions={{
              format: "fixedPoint",
              showSpinButtons: true,
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Thứ tự"} />
          </Item>
          <Item
            dataField="is_fixed"
            editorType={"dxCheckBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Cố định"} />
          </Item>
          <Item
            dataField="fixed_position"
            editorType={"dxTextBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Vị trí cố định"} />
          </Item>
        </GroupItem>
        <GroupItem colCount={4} caption="Validate">
          <Item
            dataField="requireinput"
            editorType={"dxCheckBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Bắt buộc nhập"} />
          </Item>

          <Item
            dataField="is_readonly"
            editorType={"dxCheckBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Không được sửa"} />
          </Item>
          <Item
            dataField="requireinputtext"
            editorType={"dxTextBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Thông báo lỗi"} />
          </Item>
          <Item
            dataField="condition_logic"
            editorType={"dxTextBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label
              render={(e) => {
                return LabelToolTip(
                  "condition_logic",
                  e,
                  `config theo syntax của TS, sử dụng khi chỉ là so sánh với 1 giá trị cụ thể. VD: > 0`
                );
              }}
              text={"Ràng buộc theo giá trị"}
            />
          </Item>
          <Item
            dataField="condition_column_name"
            editorType={"dxTextBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            {/* <Label text={"Ràng buộc theo biểu thức"} /> */}
            <Label
              render={(e) => {
                return LabelToolTip(
                  "condition_column_name",
                  e,
                  `config theo syntax của TS, biến object là dữ liệu của 1 row, biến value là dữ liệu của cell đang nhập liệu. VD: > object['so_luong'] && value > 0`
                );
              }}
              text={"Ràng buộc theo biểu thức"}
            />
          </Item>
        </GroupItem>
        <GroupItem colCount={12} caption="Formule">
          <Item
            colSpan={3}
            dataField="formule_column_name1"
            editorType={"dxTextBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"F Column 1"} />
          </Item>
          <Item
            colSpan={9}
            dataField="formule_logic1"
            editorType={"dxTextBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label
              render={(e) => {
                return LabelToolTip(
                  "formule_logic1",
                  e,
                  `config theo syntax của TS, biến object là dữ liệu của 1 row. VD: object['so_luong'] * object['don_gia']`
                );
              }}
              text={"Formule logic 1"}
            />
          </Item>
          {/* <Item
            colSpan={2}
            dataField="round_type_currency1"
            editorType={"dxSelectBox"}
            editorOptions={{
              dataSource: RoundCurrencyTypes,
              displayExpr: "name",
              valueExpr: "code",
              readOnly: readOnlyIfActionIs,
              showClearButton: true,
              onValueChanged: (e: any) => {
                
              }
            }}
            
          >
            <Label text={"round_type_currency1"} />
          </Item>

          <Item
            colSpan={2}
            dataField="decimal_type1"
            editorType={"dxSelectBox"}
            editorOptions={{
              dataSource: DecimalTypes,
              displayExpr: "name",
              valueExpr: "code",
              readOnly: readOnlyIfActionIs,
              showClearButton: true,
            }}
          >
            <Label text={"decimal_type1"} />
          </Item> */}

          <Item
            colSpan={3}
            dataField="formule_column_name2"
            editorType={"dxTextBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Formule column name 2"} />
          </Item>
          <Item
            colSpan={9}
            dataField="formule_logic2"
            editorType={"dxTextBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label
              render={(e) => {
                return LabelToolTip(
                  "formule_logic2",
                  e,
                  `config theo syntax của TS, biến object là dữ liệu của 1 row. VD: object['so_luong'] * object['don_gia']`
                );
              }}
              text={"Formule logic 2"}
            />
          </Item>
          <Item
            colSpan={3}
            dataField="formule_column_name3"
            editorType={"dxTextBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Formule column name 3"} />
          </Item>
          <Item
            colSpan={9}
            dataField="formule_logic3"
            editorType={"dxTextBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label
              render={(e) => {
                return LabelToolTip(
                  "formule_logic3",
                  e,
                  `config theo syntax của TS, biến object là dữ liệu của 1 row. VD: object['so_luong'] * object['don_gia']`
                );
              }}
              text={"Formule logic 3"}
            />
          </Item>

          {/* 4 */}
          <Item
            colSpan={3}
            dataField="formule_column_name4"
            editorType={"dxTextBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Formule column name 4"} />
          </Item>
          <Item
            colSpan={9}
            dataField="formule_logic4"
            editorType={"dxTextBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label
              render={(e) => {
                return LabelToolTip(
                  "formule_logic4",
                  e,
                  `config theo syntax của TS, biến object là dữ liệu của 1 row. VD: object['so_luong'] * object['don_gia']`
                );
              }}
              text={"Formule logic 4"}
            />
          </Item>

          {/* 5 */}
          <Item
            colSpan={3}
            dataField="formule_column_name5"
            editorType={"dxTextBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Formule column name 5"} />
          </Item>
          <Item
            colSpan={9}
            dataField="formule_logic5"
            editorType={"dxTextBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label
              render={(e) => {
                return LabelToolTip(
                  "formule_logic5",
                  e,
                  `config theo syntax của TS, biến object là dữ liệu của 1 row. VD: object['so_luong'] * object['don_gia']`
                );
              }}
              text={"Formule logic 5"}
            />
          </Item>
          {/* 6 */}
          <Item
            colSpan={3}
            dataField="formule_column_name6"
            editorType={"dxTextBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Formule column name 6"} />
          </Item>
          <Item
            colSpan={9}
            dataField="formule_logic6"
            editorType={"dxTextBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label
              render={(e) => {
                return LabelToolTip(
                  "formule_logic6",
                  e,
                  `config theo syntax của TS, biến object là dữ liệu của 1 row. VD: object['so_luong'] * object['don_gia']`
                );
              }}
              text={"Formule logic 6"}
            />
          </Item>
        </GroupItem>

        <GroupItem colCount={4} caption="Fortmat">
          <Item
            dataField="font_color_web"
            editorType={"dxColorBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
              showClearButton: true,
              editAlphaChannel: true
            }}
          >
            <Label text={"Màu Web"} />
          </Item>
          <Item
            dataField="background_color_web"
            editorType={"dxColorBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
              showClearButton: true,
              editAlphaChannel: true
            }}
          >
            <Label text={"Backgound Web"} />
          </Item>
          <Item
            dataField="font_color_mobile"
            editorType={"dxColorBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
              showClearButton: true,
              editAlphaChannel: true
            }}
          >
            <Label text={"Màu Mobile"} />
          </Item>
          <Item
            dataField="background_color_mobile"
            editorType={"dxColorBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
              showClearButton: true,
              editAlphaChannel: true
            }}
          >
            <Label text={"Backgound Mobile"} />
          </Item>
          <Item
            dataField="is_bold"
            editorType={"dxCheckBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"In đậm"} />
          </Item>

          <Item
            dataField="align"
            editorType={"dxSelectBox"}
            editorOptions={{
              dataSource: masterData["align_options"]?.option,
              displayExpr: masterData["align_options"]?.displayMember,
              valueExpr: masterData["align_options"]?.valueMember,
              readOnly: readOnlyIfActionIs,
              showClearButton: true,
            }}
          >
            <Label text={"Vị trí"} />
          </Item>
          <Item
            dataField="format_type"
            editorType={"dxSelectBox"}
            editorOptions={{
              dataSource: masterData["format_type_options"]?.option,
              displayExpr: masterData["format_type_options"]?.displayMember,
              valueExpr: masterData["format_type_options"]?.valueMember,
              readOnly: readOnlyIfActionIs,
              showClearButton: true,
            }}
          >
            <Label text={"Định dạng"} />
          </Item>
          <Item
            dataField="precision"
            editorType={"dxNumberBox"}
            editorOptions={{
              format: "fixedPoint",
              showSpinButtons: true,
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Số chữ số thập phân"} />
          </Item>
        </GroupItem>
        <GroupItem colCount={4} caption="Tiện ích">
          <Item
            dataField="showgrouping"
            editorType={"dxCheckBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Hiện theo nhóm"} />
          </Item>
          <Item
            dataField="is_summary"
            editorType={"dxCheckBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Hiển thị tổng"} />
          </Item>
          <Item
            dataField="is_average"
            editorType={"dxCheckBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Trung bình cộng"} />
          </Item>
        </GroupItem>
        <GroupItem colCount={4} caption="Danh mục">
          <Item
            dataField="is_datasource"
            editorType={"dxCheckBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"Is datasource"} />
          </Item>
          <Item
            dataField="table_list"
            editorType={"dxTextBox"}
            editorOptions={{ readOnly: readOnlyIfActionIs }}
          >
            <Label text={"Table list"} />
          </Item>
        </GroupItem>

        <GroupItem colCount={4} caption="Multi Header">
          <Item
            dataField="is_parent_column"
            editorType={"dxCheckBox"}
            editorOptions={{
              readOnly: readOnlyIfActionIs,
            }}
          >
            <Label text={"is Parent Column"} />
          </Item>
          <Item
            dataField="parent_column_name"
            editorType={"dxTextBox"}
            editorOptions={{ readOnly: readOnlyIfActionIs }}
          >
            <Label text={"Tên cột cha"} />
          </Item>
        </GroupItem>

        {/* {isReport && (
          <GroupItem colCount={4} caption="Báo cáo">
            <Item
              dataField="template_code_report"
              editorType={"dxSelectBox"}
              editorOptions={{
                dataSource: reportInfoList,
                displayExpr: "ten_mau",
                valueExpr: "ma_mau",
                readOnly: readOnlyIfActionIs,
                onValueChanged: (e: any) => {
                  setData({
                    ...data,
                    table_name: `${data.menuid || ""}-${e.value || ""}`,
                  } as SysGenRowTableModel);
                },
              }}
            >
              <Label text={"Mẫu báo cáo"} />
            </Item>
            <Item
              dataField="view_type_report"
              editorType={"dxSelectBox"}
              editorOptions={{
                dataSource: ViewTypeReportList,
                displayExpr: "name",
                valueExpr: "code",
                readOnly: readOnlyIfActionIs,
                showClearButton: true,
              }}
            >
              <Label text={"Loại xem báo cáo"} />
            </Item>
          </GroupItem>
        )} */}
      </DHSForm>
    </DHSDialog>
  );
};

export default DataGridSetingAction;
