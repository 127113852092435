import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from '../../../components/layouts';
import { AppSession } from '../../shared/app-session/app-session';
import {
    CaVch01Client,
    CaVch01D0,
    CaVch01D1,
    CaVch02Client,
    CaVch02D0,
    CaVch02D1,
    HomeClient,
    LstCharge,
    RoleRightWebModel,
    SysGenRowTableModel
} from '../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../shared/service-proxies/config';

import { useHistory } from 'react-router-dom';
import BlockUI from '../../../components/Block-UI/Block-ui';
import notification from '../../../common/notification/notification';
import { SoVch05Client } from '../../shared/service-proxies/api-shared';
import moment from 'moment';
import { Autocomplete, Box, Button, Paper, Tab, Tabs, TextField } from '@mui/material';
import DHSGridEdit from '../../../components/layouts/Admin/Grid/Grid-edit/Grid-edit';
import DHSInputMoney from '../../../components/DHS/DHS_InputMoney/Input-money';
import { generateUUID } from '../../../components/utils/generationUuid';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { ChangeSet, DataTypeProvider, TableColumnWidthInfo, } from '@devexpress/dx-react-grid';
import DHSInputText from '../../../components/DHS/DHS_InputText/InputText';
import { ACTIONS } from '../../../common/enums/DHSToolbarRoleAction';
import DataGrid, { Column, ColumnChooser, ColumnFixing, Editing, FilterBuilderPopup, FilterPanel, FilterRow, Format, GroupPanel, Grouping, HeaderFilter, Lookup, Pager, Paging, SearchPanel, Selection, Summary, Texts, TotalItem } from 'devextreme-react/data-grid';
import themes from 'devextreme/ui/themes';
import { DataType } from 'devextreme/ui/data_grid';
import { ToVietNamCurrency } from '../../../components/utils/number.util';
import { httpPost } from '../../../common/httpService';
import NumberBox from 'devextreme-react/number-box';

var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any

}


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface IProps {
    arrayCt?: CaVch02D0[],
    arrayCt1?: CaVch02D1[],
    action?: string,
    apartment_id?: string,
    totalTTMoney: (totalTTMoney?: number) => void,
    passArrayCT?: (data: CaVch02D0[]) => void,
    passArrayCT1?: (data: CaVch02D1[]) => void,
    onChangeTT?: (data: CaVch02D1[]) => void,
    onError?: (errors?: any[]) => void,
    dataGridRef02D0: React.RefObject<DataGrid<any, any>>,
    stt_rec?: string,
    dataInvoice?: any[]
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: "1rem 0" }}>
                    {children}
                </Box>
            )}
        </div>
    );
}


// const pageSizes = [5, 10, 15, 0];

let dataTemp: any[] | undefined;

const PaymentCashD: React.FC<IProps> = (props) => {

    // const dispatch = useDispatch();
    // const history = useHistory();
    // const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const homeClient = new HomeClient(appSession, BASE_URL_API);

    const [dataTable02D1, setDataTable02D1] = React.useState<CaVch01D1[]>([]); // any là CaVch01D1

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [value, setValue] = React.useState(0);


    // 

    const [selectionid, setSelectionid] = React.useState<any[]>([]);

    const [errors, setErrors] = React.useState<any[]>();
    // 

    // 

    const [dataTable02D0, setDataTable02D0] = React.useState<any[]>([

    ]);
    //    

    const [chargeOptions, setChargeOptions] = React.useState<any[]>([]);


    //    
    const [allMode] = useState<string>('allPages');
    const [checkBoxesMode] = useState<string>(themes.current().startsWith('material') ? 'always' : 'onClick');
    const [editRowKey, setEditRowKey] = React.useState<string | null>(null);
    // const [selectedRowKeys, setSelectedRowKeys] = React.useState<string[]>([]);
    // const [selectedRowIndexs, setSelectedRowIndexs] = React.useState<number[]>([]);

    // 

    const [isHiddenGroup, setIsHiddenGroup] = React.useState<boolean>(false);
    const [isHiddenSearch, setIsHiddenSearch] = React.useState<boolean>(false);
    const [isHiddenFilter, setIsHiddenFilter] = React.useState<boolean>(false);
    const [isHiddenAdvantageFilter, setIsHiddenAdvantageFilter] = React.useState<boolean>(false);
    const [isHiddenColumnChooser, setIsHiddenColumnChooser] = React.useState<boolean>(false);
    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    //#region hanldeAddAndEdit01D1
    const onAddButton02D0 = React.useMemo(() => () => {
        if (props.dataGridRef02D0.current) {
            props.dataGridRef02D0.current.instance.addRow();

        }
    }, []);

    const onDeleteButton02D0 = React.useMemo(() => () => {
        if (props.dataGridRef02D0.current) {
            props.dataGridRef02D0.current.instance.getSelectedRowKeys()
                .then(keys => {
                    keys.map(key => {
                        const rowIndex = props.dataGridRef02D0.current?.instance.getRowIndexByKey(key);
                        if (rowIndex !== null && rowIndex !== undefined && rowIndex > -1) {
                            props.dataGridRef02D0.current!.instance.deleteRow(rowIndex);
                            props.dataGridRef02D0.current!.instance.refresh();
                        }

                    })
                });

        }
    }, []);

    //#endregion

    //#region hanleEditAndDetailAction

    React.useEffect(() => {
        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            const tempData01D1 = [...dataTable02D1];

            props.arrayCt1?.forEach((caVch02) => {
                const index = tempData01D1.findIndex(x => x.stt_rec_hd === caVch02.stt_rec_hd);
                if (index === -1) {
                    tempData01D1.push({
                        ...caVch02,
                        // ngay_ct: moment(caVch02.ngay_ct).format("DD/MM/yyyy"),
                        ngay_hd: moment(caVch02.ngay_hd).format("DD/MM/yyyy"),
                    } as any)
                }

            });
            // setDataTable05M(tempData05M);
            // setCa02D1(props.arrayCt1 ?? []);
            setDataTable02D1(tempData01D1 ?? [])

        }
    }, [props.action, props.arrayCt1]);

    React.useEffect(() => {
        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            if (props.arrayCt) {
                const tempData02D0: any = [...props.arrayCt];
                tempData02D0.map((dt02D0: any) => {
                    dt02D0.id = generateUUID();
                })
                setDataTable02D0(tempData02D0 ?? []);
            }


        }
    }, [props.action, props.arrayCt]);

    //#endregion

    // #region handle01D0Grid


    React.useEffect(() => {

        setLoading(true)
        const body = {
            ma_cty: SessionLogin.ma_cty,
            tablelist: "dm_phi_chi"
        }
        httpPost<string>('api/Common/LoadDatasourceBindControlByApartmentBuilding', body)
            .then(res => {

                const lstCharge = JSON.parse(res.data);
                setChargeOptions(lstCharge?.Table1 ?? []);
                setLoading(false);

            }).catch(() => setLoading(false));

    }, []);

    const [columns, setColumns] = React.useState<any[]>([]);




    React.useEffect(() => {
        props.onError && props.onError(errors);
    }, [errors]);



    // React.useEffect(() => {
    // setLoading(true);
    // homeClient
    //     .roleRightWebGetAll({
    //         ...new RoleRightWebModel(),
    //         menuid: "60.10.10",
    //         rolename: roleMenu,
    //         username: SessionLogin.userName,
    //     } as RoleRightWebModel)
    //     .then((res) => {
    //         dispatch(getRoles(res));
    //         setRoles(res);
    //         setLoading(false);
    //     })
    //     .catch((err) => {
    //         setLoading(false)
    //     })



    // }, [menu?.component])

    React.useEffect(() => {
        let pr = new SysGenRowTableModel();
        pr.table_name = '69.20.01_CaVch02D0' //props.table_name;
        pr.show_form_list = true // props.show_form_list;
        homeClient?.sysGenRowTable(pr).then((res) => {
            setColumns(res);
        });
    }, []);






    //#region handleWhen01D0Change
    // Khi 01D0 có thay đổi giá trị
    React.useEffect(() => {
        const tempData01D0 = [...dataTable02D0]
        tempData01D0.map(item => {
            item.key = undefined;
            return item;
        })
        props.passArrayCT && props.passArrayCT(tempData01D0);
        const totalTTMoney = [...dataTable02D0].reduce((acc, invoice) => {
            return acc + invoice.pS_NO_NT!;
        }, 0);
        props.totalTTMoney(totalTTMoney)
    }, [dataTable02D0])

    //#endregion


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    //#endregion


    // #region handle01D1Grid

    const handleContextMenuItems = (items: any) => {
        if (!items) {
            return;
        }

        items.forEach((item: any) => {
            if (item.items) {
                // Xử lý mục menu lồng nhau
                handleContextMenuItems(item.items);
            }

            switch (item.text) {
                case "Sort Ascending":
                    item.text = 'Sắp xếp tăng dần';
                    break;
                case "Sort Descending":
                    item.text = 'Sắp xếp giảm dần';
                    break;
                case "Clear Sorting":
                    item.text = 'Hủy sắp xếp';
                    break;
                case "Group by This Column":
                    item.text = 'Group cột này';
                    break;
                case "Ungroup All":
                    item.text = 'Hủy bỏ group tất cả';
                    break;
                case "Fix":
                    item.text = 'Cố định';
                    break;
                case "To the left":
                    item.text = 'Từ trái sang';
                    break;
                case "To the right":
                    item.text = 'Từ phải sang';
                    break;
                case "Unfix":
                    item.text = 'Không cố định';
                    break;
            }
        });
    };


    const handleContextMenuPreparing = (e: any) => {
        const { row, column } = e;

        // Xác định loại ContextMenu (dành cho hàng hoặc cột)
        if (row) {
            // ContextMenu cho hàng
            // Tùy chỉnh ContextMenu cho hàng dựa trên rowData
            e.items?.push(
                {
                    text: isHiddenGroup ? "Ẩn group" : "Hiện group",
                    onItemClick: () => setIsHiddenGroup(pre => !pre),
                    beginGroup: true,
                },
                {
                    text: isHiddenSearch ? "Ẩn tìm kiếm" : "Hiện tìm kiếm",
                    onItemClick: () => setIsHiddenSearch(pre => !pre),
                },
                {
                    text: isHiddenFilter ? "Ẩn lọc" : "Hiện lọc",
                    onItemClick: () => setIsHiddenFilter(pre => !pre),
                },
                {
                    text: isHiddenAdvantageFilter ? "Ẩn lọc nâng cao" : "Hiện lọc nâng cao",
                    onItemClick: () => setIsHiddenAdvantageFilter(pre => !pre),
                },
                {
                    text: isHiddenColumnChooser ? "Ẩn ẩn cột" : "Hiện ẩn cột",
                    onItemClick: () => setIsHiddenColumnChooser(pre => !pre),

                },
                // Các mục menu khác cho hàng
            );
        } else if (column) {
            // ContextMenu cho cột
            // Tùy chỉnh ContextMenu cho cột dựa trên columnData
            e.items.push(
                {
                    text: isHiddenGroup ? "Ẩn group" : "Hiện group",
                    onItemClick: () => setIsHiddenGroup(pre => !pre),
                },
                // Các mục menu khác cho cột
            );
        }

        // Thay đổi văn bản của các mục menu trong e.items
        handleContextMenuItems(e.items);


    };

    // Chuyển từ SoVch05M thành CaVch01D1
    React.useEffect(() => {

        props.passArrayCT1 && props.passArrayCT1(dataTable02D1);


        if (dataTable02D1.length > 0) {
            const totalTTMoney = [...dataTable02D1].reduce((acc: any, invoice: any) => {
                return acc + invoice.tien_tt!;
            }, 0);
            // tìm ra phiếu thu mà thu bằng hóa đơn (phân biệt bằng ma_ct_hd)
            // vì số ct ở hóa đơn sẽ map qua 01D0
            // còn tạo phiếu thu khác sẽ có so_ct khác
            const temp02D0 = [...dataTable02D0];
            const index01D0 = temp02D0.findIndex(x => x.ma_ct_hd === dataTable02D1[0].ma_hd)
            if (temp02D0.length > 0 && index01D0 > -1) {
                temp02D0[index01D0].pS_NO_NT = totalTTMoney;
                temp02D0[index01D0].pS_NO = totalTTMoney;
                temp02D0[index01D0].pS_CO_NT = 0;
                temp02D0[index01D0].pS_CO = 0;
            }

            setDataTable02D0(temp02D0)
        }


    }, [dataTable02D1]);

    // Xóa dòng 05M
    const HandleDelete01D1 = () => {

        const tempData01D1 = [...dataTable02D1];

        selectionid.forEach(idselect => {
            const index = tempData01D1.findIndex(x => x.stt_rec_hd === idselect);
            if (index > -1) {
                tempData01D1.splice(index, 1);
            }
        })



        // Xóa 01D0 khi 05M không còn data
        const tempData01D0 = [...dataTable02D0];

        if (tempData01D1.length === 0) {
            const index = tempData01D0.findIndex(x => x.stT_REC === selectionid[0]);
            tempData01D0.splice(index, 1)
            setDataTable02D0(tempData01D0);


        }

        setDataTable02D1(tempData01D1);




    }

    //  #endregion

    const savedDT02D0 = () => {
        const tempData02D0 = [...dataTable02D0]
        tempData02D0.map(item => {
            item.key = undefined;
            return item;
        })
        props.passArrayCT && props.passArrayCT(tempData02D0);
        const totalTTMoney = [...dataTable02D0].reduce((acc, invoice) => {
            return acc + invoice.pS_NO_NT!;
        }, 0);
        props.totalTTMoney(totalTTMoney)
    }





    return (
        <div className='container-fluid'>
            <div>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Chi tiết" sx={{ textTransform: 'none', fontSize: 14 }}  {...a11yProps(0)} />
                        {/* <Tab label="Thanh toán hóa đơn" sx={{ textTransform: 'none', fontSize: 14 }}  {...a11yProps(1)} /> */}
                        {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <div style={{ overflowX: 'scroll', }} className='row no-gutters'>
                        {
                            props.action !== ACTIONS.VIEW && <Paper className='col-12 d-flex py-1' sx={{ gap: 0, backgroundColor: '#F6F7F9' }}>

                                <Button
                                    variant='text'
                                    sx={{
                                        padding: '0 0.75rem', color: '#1976D2', fontWeight: 600, fontSize: 14,
                                        textTransform: 'capitalize',
                                        backgroundColor: 'transparent',
                                    }}
                                    startIcon={<i className={"bi bi-plus-lg"} style={{ color: '#1976D2', fontSize: 18, }} />}
                                    // onClick={HandleAddNew01DO}
                                    onClick={onAddButton02D0}
                                >Thêm</Button>
                                <Button
                                    variant='text'
                                    sx={{
                                        padding: '0 0.75rem', color: '#1976D2', fontWeight: 600, fontSize: 14,
                                        textTransform: 'capitalize',
                                        backgroundColor: 'transparent',
                                    }}
                                    startIcon={<i className={"bi bi-trash3"} style={{ color: '#1976D2', fontSize: 18, }} />}
                                    onClick={onDeleteButton02D0}>Xóa</Button>


                            </Paper>
                        }
                        <div className="col-12">
                            <Paper>
                                <DataGrid
                                    ref={props.dataGridRef02D0}
                                    dataSource={dataTable02D0}
                                    allowColumnReordering={true}
                                    allowColumnResizing={true}
                                    showBorders={true}
                                    noDataText='Không có dữ liệu'
                                    repaintChangesOnly={true}
                                    // columnHidingEnabled={true}
                                    columnMinWidth={50}
                                    columnAutoWidth={true}
                                    onEditingStart={() => {
                                        console.log('EditingStart')
                                    }}
                                    onInitNewRow={() => console.log('InitNewRow')}
                                    onRowInserting={() => {

                                    }}
                                    onRowInserted={() => {
                                        // const tempData = [...dataTable01D0]
                                        // tempData.push(e.data)
                                        // setDataTable01D0(tempData)
                                    }}
                                    onRowUpdating={() => console.log('RowUpdating')}
                                    onRowUpdated={() => console.log('RowUpdated')}
                                    onRowRemoving={() => console.log('RowRemoving')}
                                    onRowRemoved={() => console.log('RowRemoved')}
                                    onSaving={() => {
                                        console.log('Saving')
                                    }}
                                    onSaved={(e) => {
                                        console.log(e, "saved")
                                        savedDT02D0();
                                    }}
                                    onEditCanceling={() => console.log('EditCanceling')}
                                    onEditCanceled={() => console.log('EditCanceled')}
                                    onSelectionChanged={() => {
                                        // const tempKeys: string[] = []
                                        // const tempIndexs: number[] = []
                                        // params.selectedRowKeys.forEach(key => {
                                        //     tempKeys.push(key.__KEY__)
                                        //     tempIndexs.push(params.component.getRowIndexByKey(key))
                                        // })
                                        // setSelectedRowKeys(tempKeys)
                                        // setSelectedRowIndexs(tempIndexs);
                                    }}
                                    onContextMenuPreparing={handleContextMenuPreparing}

                                >

                                    <HeaderFilter visible={true} allowSearch={true}  >

                                    </HeaderFilter>
                                    <FilterPanel texts={{ clearFilter: "Xóa lọc", createFilter: "Tạo bộ lọc" }} visible={isHiddenAdvantageFilter} />
                                    <FilterBuilderPopup title={"Tạo bộ lọc cho cột"} position={filterBuilderPopupPosition} />

                                    <ColumnChooser enabled={isHiddenColumnChooser} emptyPanelText={"Kéo và thả cột muốn ẩn vào đây"} title={"Chọn cột ẩn"} />
                                    <ColumnFixing enabled={true} />
                                    {
                                        columns.map((row, index) => {

                                            if (row.data_type === "number") {
                                                return <Column
                                                    key={index}
                                                    caption={row.name_vn}
                                                    dataField={row.column_name ? row.column_name : ""}
                                                    dataType={row.data_type as DataType}
                                                    // format={(value: any) => formatDataType(row.format_type, value)}
                                                    width={row.width ? row.width : 150}
                                                    editCellComponent={(props) => {

                                                        return <NumberBox
                                                            format="#,###"
                                                            style={{ textAlign: "right" }}
                                                            onValueChange={(value) => props.data.setValue(value)}
                                                            value={props.data.value} />


                                                    }}
                                                >
                                                    {row.column_name === 'mA_PHI' && (
                                                        <Lookup

                                                            dataSource={chargeOptions}
                                                            displayExpr="ten_phi"
                                                            valueExpr="ma_phi"
                                                        />
                                                    )}

                                                    {
                                                        row?.format_type && <Format
                                                            type={row.format_type}
                                                            precision={0}
                                                        />
                                                    }
                                                </Column>
                                            }

                                            else return <Column
                                                key={index}
                                                caption={row.name_vn}
                                                dataField={row.column_name ? row.column_name : ""}
                                                dataType={row.data_type as DataType}
                                                // format={(value: any) => formatDataType(row.format_type, value)}
                                                width={row.width ? row.width : 150}
                                            >
                                                {row.column_name === 'mA_PHI' && (
                                                    <Lookup

                                                        dataSource={chargeOptions}
                                                        displayExpr="ten_phi"
                                                        valueExpr="ma_phi"
                                                    />
                                                )}

                                                {
                                                    row?.format_type && <Format
                                                        type={row.format_type}
                                                        precision={0}
                                                    />
                                                }
                                            </Column>
                                        })
                                    }
                                    <Selection
                                        mode="multiple"
                                        selectAllMode={allMode}
                                        showCheckBoxesMode={checkBoxesMode}

                                        deferred={true}

                                    />
                                    <FilterRow visible={isHiddenFilter} />
                                    <SearchPanel visible={isHiddenSearch} placeholder={"Tìm ..."} />
                                    <Grouping contextMenuEnabled={true} allowCollapsing={true} />
                                    <GroupPanel visible={isHiddenGroup} emptyPanelText={"Kéo vào thả cột muốn group vào đây"} />
                                    {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}


                                    <Editing
                                        mode="cell"
                                        allowUpdating={props.action !== ACTIONS.VIEW}
                                        allowDeleting={false}
                                        allowAdding={false}
                                        useIcons={true}
                                        newRowPosition={"last"}
                                        selectTextOnEditStart={true}
                                        // changes={changes}
                                        // onChangesChange={setChanges}
                                        editRowKey={editRowKey}
                                        onEditRowKeyChange={setEditRowKey}
                                    // texts={{
                                    //     confirmDeleteMessage: "Bạn chắn chắn muốn xóa hàng này?",
                                    // }}
                                    >
                                        {/* <Texts confirmDeleteMessage="Bạn chắn chắn muốn xóa hàng này?" addRow={"Thêm dòng mới"}></Texts> */}
                                    </Editing>

                                    {/* <Paging defaultPageSize={5} /> */}

                                    <Summary>
                                        <TotalItem

                                            column="pS_NO_NT"
                                            summaryType="sum"
                                            valueFormat={"fixedPoint"}
                                            displayFormat={"Tổng: {0}"} />

                                    </Summary>

                                </DataGrid>
                            </Paper>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div style={{ overflowX: 'scroll', backgroundColor: "#fff" }} className='row no-gutters'>
                        {
                            props.action !== ACTIONS.VIEW && <Paper className='col-12 py-1 d-flex' style={{ gap: 0, backgroundColor: '#F6F7F9' }}>
                                {/* <Button
                                type='primary'
                                size='middle'
                                style={{ borderRadius: 5, display: 'flex', alignItems: 'center' }}
                                icon={<SaveFilled />}
                                onClick={HandleSave01D1}>Lưu</Button> */}
                                <Button
                                    variant='text'
                                    style={{
                                        padding: '0 0.75rem', color: '#1976D2', fontWeight: 600, fontSize: 14,
                                        textTransform: 'capitalize',
                                        backgroundColor: 'transparent',
                                    }}
                                    startIcon={<i className={"bi bi-trash3"} style={{ color: '#1976D2', fontSize: 18, }} />}
                                    onClick={HandleDelete01D1}>Xóa</Button>

                            </Paper>
                        }
                        <div className="col-12">

                            {/* <DHSGridEdit

                                onSelectRow={(row) => {
                                    setIdItemSelected(row);
                                }}
                                // onListSelectRow={handleListOnselectRow}
                                itemClicked={function (): void {
                                    throw new Error("Function not implemented.");
                                }}
                                table_name={'69.20.01_CaVch02D1'}
                                show_form_list={false}
                                data={dataTable02D1}
                                onListSelectRow={onListSelectRow}
                                onChangeData={(v: CaVch01D1[]) => {
                                    props.onChangeTT!(v);
                                    dataTemp = v;


                                }}
                                onError={(errors) => {
                                    setErrors(errors)
                                }}
                                action={props.action}
                                blurCurrencyInput={(e, column, row) => HandleSave01D1(e)}
                            /> */}
                        </div>
                    </div>
                </TabPanel>

            </div>


            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default PaymentCashD;


