import React from "react";
import { Checkbox } from "antd";
import '../ApartmentBuilding.css';
import './NotificationsActions.css';
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import TreeView from "devextreme-react/tree-view";
import { useDispatch, useSelector } from "react-redux";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { AppSession } from "../../../shared/app-session/app-session";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { HomeClient, QLCCApartmentBuildingModel, QLCCApartmentModel, QLCCBlockModel, QLCCClient, QLCCFloorModel, QLCCNotificationsModel, RoleRightWebModel, } from "../../../shared/service-proxies/api-shared";
import { Button, Dialog, DialogContent, DialogActions, InputLabel, Box, Slide, IconButton, AppBar, Toolbar, Typography, Divider, Grid, Select, MenuItem, Badge, BadgeProps, } from "@mui/material";
import { CALL_API_LOAD_APARTMENT_BUILDING_LIST } from "../../apartment-building/apartment-building-list";


interface IProps {
    open: boolean;
    onClose: () => void;
    onSubmit?: (data: any) => void;
    action: string;
    data: QLCCNotificationsModel;
    setData: (newValue: any) => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

var appSession: AppSession;
const NotificationPopupHouse: React.FC<IProps> = (props: IProps) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [FloorOptions, setFloorOptions] = React.useState<QLCCFloorModel[]>([] as QLCCFloorModel[]); // thay đổi vì model không có items đáp ứng treeview
    const [Floors, setFloors] = React.useState<any[]>([] as any[]);
    const [blockOptions, setBlockOptions] = React.useState<QLCCBlockModel[]>([] as QLCCBlockModel[]);
    const [apartmentBuildingOptions, setAPartmentBuildingOptions] = React.useState<QLCCApartmentBuildingModel[]>([] as QLCCApartmentBuildingModel[]);
    const [apartmentOptions, setApartmentOptions] = React.useState<QLCCApartmentModel[]>([] as QLCCApartmentModel[]);
    const [notification, setNotification] = React.useState<QLCCNotificationsModel>({} as QLCCNotificationsModel);
    const [reload, setReload] = React.useState(false);
    const [blockId, setBlockId] = React.useState<string>("");
    const [changeBlock, setChangeBlock] = React.useState<boolean>(false);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const onLoadAB = async () => {
        const data = await CALL_API_LOAD_APARTMENT_BUILDING_LIST();
        setAPartmentBuildingOptions([...data])
    }

    const onLoadBlock = async (apartment_building_id: string) => {
        const data = await qLCCClient.qlccBlockGet({
            ... new QLCCBlockModel(),
            ma_cty: SessionLogin.ma_cty,
            apartment_building_id: apartment_building_id
        } as QLCCBlockModel);
        setBlockOptions([...data])
        
        if (data &&
            data.length > 0 &&
            data[0] &&
            data[0].id
        ) {
            if (props.action === ACTIONS.ADD) {
                setNotification({
                    ...notification,
                    block_id: data[0].id
                } as QLCCNotificationsModel);
            }
            setBlockId(data[0].id)
        }
    }

    const onLoadFloors = async (block_id: string) => {
        const data = await qLCCClient.qLCCFloorGet({
            ... new QLCCFloorModel(),
            ma_cty: SessionLogin.ma_cty,
            block_id: block_id,
            apartment_building_id: apartment_building_id
        } as QLCCFloorModel);
        setFloorOptions([...data])
    }

    const onLoadApartment = async (block_id: string) => {
        const data = await qLCCClient.qLCCApartmentsGet({
            ...new QLCCApartmentModel(),
            block_id: block_id,
            apartment_building_id: apartment_building_id,
            ma_cty: SessionLogin.ma_cty,

        } as QLCCApartmentModel);
        setApartmentOptions([...data]);
    }

    React.useEffect(() => {
        (async () => {
            await onLoadAB();
            if (apartmentBuildingOptions &&
                apartmentBuildingOptions.length > 0 &&
                apartmentBuildingOptions[0] &&
                apartmentBuildingOptions[0].id
            ) {
                const apartmentBuildingId: string = apartmentBuildingOptions[0].id;
                setNotification({
                    ...notification,
                    apartment_building_id: apartmentBuildingId
                } as QLCCNotificationsModel);
            }
        })();
    }, []);

    React.useEffect(() => {
        if (props.open) {
            if (props.action === ACTIONS.ADD) {
                setNotification({
                    ...notification,
                    apartment_building_id: apartment_building_id
                } as QLCCNotificationsModel);
            } else {
                setNotification({
                    ...notification,
                    ...props.data,
                    apartment_building_id: props.data.apartment_building_id,
                    apartment_id: props.data.apartment_id,
                } as QLCCNotificationsModel);
            }
        }
    }, [props.open])

    React.useEffect(() => {
        (async () => {
            if (notification.apartment_building_id) {
                await onLoadBlock(notification.apartment_building_id);
             }
        })();
    }, [notification.apartment_building_id])

    const onloadApartment = (async () => {
        if (notification.block_id &&
            notification.block_id.length > 0) {
            const lstB: string = notification.block_id;
            const data = await (
                qLCCClient.qLCCApartmentsGet({
                    ...new QLCCApartmentModel(),
                    block_id: lstB,
                    ma_cty: SessionLogin.ma_cty,
                    apartment_building_id: apartment_building_id,
                } as QLCCApartmentModel)
            )
            // setApartmentOptions([...data]);
            //lộc nhưng căn hộ có trạng thái ẩn đi 
            const dataFilter = data.filter(item => item.status_id !== '0')
            const lstApartment = await dataFilter.flatMap(item => item.id);
            setNotification({
                ...notification,
                apartment_id: lstApartment.join(';')
            } as QLCCApartmentModel);
        } else {
            setNotification({
                ...notification,
                apartment_id: ""
            } as QLCCApartmentModel);
        }
        setReload(pre => !pre);

    })

    React.useEffect(() => {
        // if (props.action === ACTIONS.ADD) {
        (async () => {
            await onloadApartment();
        })();
        // }
    }, [changeBlock]);

    React.useEffect(() => {
        (async () => {
            if (blockId && blockId.length > 0) {
                await onLoadApartment(blockId);
                await onLoadFloors(blockId);
            }
        })();
    }, [blockId]);


    React.useEffect(() => {
        const lst: any[] = [];
        if (FloorOptions.length > 0) {
            const lstNotifyA = notification.apartment_id?.split(';') || [];
            FloorOptions.map(item => {
                const lstA = apartmentOptions.filter(p => p.floor_id === item?.id!).flatMap(p => {
                    return {
                        id: p.id,
                        name: p.name,
                        owner_resident_name: p.owner_resident_name,
                        selected: lstNotifyA.includes(p?.id!)
                    }
                });
                lst.push({
                    ...item,
                    totalNumber: lstA.length,
                    items: [...lstA],
                });
            });
            console.log(lstNotifyA, 'lstNotifyA')
            console.log(lst, 'floor')
            setFloors([...lst]);
        }
    }, [FloorOptions, notification.block_id, reload]);

    const handleChangeInput = async (e: any) => {
        const { name, value } = e.target;
        await setNotification({
            ...notification,
            [name]: value
        } as QLCCNotificationsModel);
    }

    const selectedBlock = (block_id: string) => {
        return `${blockId == block_id ? "selected" : "unselected"} btn-hover block-btn`;
    }

    const checkBox = async (e: any) => {
        const { name, value } = e.target;
        const lstBlock = notification.block_id ? notification.block_id.split(';') : [];
        const findItem = lstBlock.find(item => item === value);
        if (findItem) {
            const lstB = lstBlock.filter(item => item !== findItem);
            setNotification({
                ...notification,
                block_id: lstB.join(';'),
            } as QLCCNotificationsModel);

        } else {
            setNotification({
                ...notification,
                block_id: lstBlock.join(';') ? lstBlock.join(';') + ';' + value : value,
            } as QLCCNotificationsModel);
        }

        setChangeBlock(pre => !pre);
    }

    const onSelectionChanged = async (e: any) => {
        // lọc bỏ những căn hộ có trong 'apartmentOptions' sau đó thêm mới những căn hộ đã chọn vào
        const data: string[] = await e.component.getSelectedNodeKeys(); // dữ liệu không đảm bảo do trong danh sách keys tồn tại key của 'floor'

        const lstA = apartmentOptions.flatMap(item => item.id);
        const lstNotifyA = notification.apartment_id?.split(';') || [];

        lstA.map(item => {
            const findIndex = lstNotifyA.findIndex(p => p === item);
            if (findIndex >= 0) {
                lstNotifyA.splice(findIndex, 1);
            }
        })

        setNotification({
            ...notification,
            apartment_id: [...data, ...lstNotifyA].join(';')
        } as QLCCNotificationsModel)
    }

    const checkBoxA = (e: any) => {
        (async () => {
            const { name, value } = e.target;
            const lstAOfData: string[] = notification?.apartment_id?.split(';') || [];
            const findIndex = lstAOfData.indexOf(value);
            if (findIndex >= 0) {
                lstAOfData.splice(findIndex, 1);
            } else {
                lstAOfData.push(value);
            }
            setNotification({
                ...notification,
                apartment_id: lstAOfData.join(";")
            } as QLCCNotificationsModel)
        })();
    }

    const handleSubmit = async () => {
        console.log(notification)
        props.setData({
            ...notification,
            block_id: blockId,
        } as QLCCNotificationsModel);
        props.onClose();
    }

    return (
        <>
            <Dialog
                id="sysMenu5"
                open={props.open}
                onClose={props.onClose}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
                TransitionComponent={Transition}
                maxWidth='xs'
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            GỬI THÔNG BÁO
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent >
                    <div>
                        <Grid container spacing={1}
                        >
                            <Grid item xs={12} sm={4.5} md={3} lg={3}>
                                <Box
                                    className="d-flex justify-content-center"
                                >
                                    <Box
                                        sx={{
                                            width: '80%'
                                            , marginTop: '1.5rem'
                                        }}
                                    >

                                        {/* <InputLabel htmlFor="input-with-domestic-water-cycle">
                                            Bước 1. Chọn dự án
                                        </InputLabel> */}
                                        {/* <Select
                                            className="text-center"
                                            sx={{
                                                width: '98%',
                                            }}
                                            size='small'
                                            id="input-with-domestic-water-cycle"
                                            name="apartment_building_id"
                                            onChange={handleChangeInput}
                                            value={notification?.apartment_building_id}
                                            defaultValue={notification?.apartment_building_id}
                                        >
                                            {
                                                apartmentBuildingOptions &&
                                                apartmentBuildingOptions.map((item, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={item.id}
                                                    >
                                                        <span style={{ fontSize: 14 }}>
                                                            {item.name}
                                                        </span>
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select> */}
                                    </Box>
                                </Box>
                                <Box
                                    className="d-flex justify-content-center"
                                >
                                    <Box
                                        sx={{
                                            width: '80%'
                                        }}
                                    >
                                        <InputLabel htmlFor="input-with-domestic-water-block">
                                            Bước 2. Chọn tòa nhà ({blockOptions && (`${blockOptions.length}`)})
                                        </InputLabel>
                                        <Box
                                            id="input-with-domestic-water-block"
                                            className='hidden-scrollbar width-scrollbar-hoa-don'
                                        >
                                            {
                                                blockOptions &&
                                                blockOptions.map(block => (
                                                    <div className="d-flex align-items-center" key={block.id}>
                                                        <Button
                                                            className={selectedBlock(block.id || "")}
                                                            id={block.id}
                                                            onClick={() => {
                                                                // handleChangeInput({
                                                                //     target: {
                                                                //         name: 'block_id',
                                                                //         value: block.id || "",
                                                                //     }
                                                                // })
                                                                setBlockId(block.id || "")
                                                            }}
                                                        >
                                                            {block.name}
                                                        </Button>
                                                        <Button>
                                                            <Checkbox id={block.id} value={block.id} onClick={checkBox} checked={notification.block_id?.split(';').find(item => item === block.id) ? true : false} />
                                                        </Button>
                                                    </div>
                                                ))
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={5.5} md={7} lg={7}>

                                <InputLabel
                                    htmlFor="input-with-domestic-water-block"
                                    className='label-input-domestic-water d-flex justify-content-between align-items-center'
                                >
                                    <Grid container
                                        sx={{
                                            marginRight: '3%',
                                        }}
                                    >
                                        <Grid
                                            item xs={12} sm={5} md={8}
                                            className="d-flex align-items-center"
                                        >
                                            Bước 3. Số tầng {FloorOptions && (`(${FloorOptions.length})`)}
                                        </Grid>
                                        {
                                            blockId &&
                                            (<Grid xs={12} sm={7} md={4}
                                                sx={{
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                <p className='margin-0 text-center text-uppercase text-danger'>
                                                    <b>{blockOptions && blockOptions.find(item => item.id == blockId)?.name}</b>
                                                </p>
                                                <p className='margin-0'>
                                                    <span className='width-50percent display-inline-block'>
                                                        <b>Tổng số căn hộ</b>
                                                    </span>
                                                    <span className='width-40percent'>
                                                        {apartmentOptions && apartmentOptions?.length}
                                                    </span>
                                                </p>
                                                {/* <p className='margin-0'>
                                                    <span className='width-50percent display-inline-block'>
                                                        Đã chọn
                                                    </span>
                                                    <span className='width-40percent'>

                                                    </span>
                                                </p> */}
                                            </Grid>)
                                        }
                                    </Grid>
                                </InputLabel>
                                <Box className='w-100 treeviewNotify'>
                                    <TreeView
                                        id="treeview"
                                        items={Floors}
                                        selectNodesRecursive={true}
                                        selectByClick={true}
                                        showCheckBoxesMode={"normal"}
                                        selectionMode={"multiple"}
                                        // onContentReady={this.treeViewContentReady}
                                        itemRender={(item: any) => {
                                            return `${item.name} ${item.owner_resident_name ? `- ${item.owner_resident_name}` : `(${item.totalNumber} căn hộ)`}`;
                                        }}
                                        onSelectionChanged={onSelectionChanged}
                                    // onItemClick={() => console.log(Floors)}
                                    // onItemClick={onItemClick}
                                    // onItemSelectionChanged={onSelectionChanged}
                                    />
                                </Box>
                                {/* <Box
                                    className='hidden-scrollbar width-scrollbar-floor'
                                >
                                    {
                                        FloorOptions &&
                                        FloorOptions.map(floor => (
                                            <Accordion
                                                key={floor.id}
                                                sx={{
                                                    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;',
                                                }}
                                                style={{ backgroundColor: '#198754' }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Grid container spacing={2} sx={{ color: 'white' }}>
                                                        <Grid item xs={4} sm={2} md={2}>
                                                            <span className="fontWeight_500" style={{ fontSize: 14 }}>
                                                                {floor.name}
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={2}>
                                                            <OtherHousesIcon
                                                                sx={{
                                                                    fontSize: "24px",
                                                                    marginRight: '15px',
                                                                    float: 'left',
                                                                }}
                                                            />
                                                            {
                                                                apartmentOptions.filter(element => element.floor_id == floor.id).length
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    sx={{
                                                        backgroundColor: "#F0F5F9"
                                                    }}
                                                >
                                                    <Typography>
                                                        <Box>
                                                            {
                                                                apartmentOptions &&
                                                                apartmentOptions.filter(element => element.floor_id == floor.id).map(e => (
                                                                    <div
                                                                        key={e.id}
                                                                        className='house-around-service'
                                                                    >
                                                                        <div
                                                                            className='house-around-second'
                                                                        >
                                                                            <div>
                                                                                <Tooltip
                                                                                    placement="bottomRight"
                                                                                    mouseLeaveDelay={0.4}
                                                                                >
                                                                                    <p
                                                                                        id={e.id}
                                                                                        className=
                                                                                        {
                                                                                            e.status_id === "0"
                                                                                                ? "house-icon house-color-unactive"
                                                                                                : "house-icon house-color-pay-status-true"
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            e.status_id !== "0"
                                                                                                ? (
                                                                                                    <StyledBadge badgeContent={e.qLCCSoVch05MLogs?.length} color="error">
                                                                                                        <HomeIcon style={{ fontSize: '36px' }} />
                                                                                                    </StyledBadge>
                                                                                                )
                                                                                                :
                                                                                                <HomeIcon style={{ fontSize: '36px', color: '#C6C6C6' }} />
                                                                                        }
                                                                                    </p>

                                                                                    <p
                                                                                        className='house-title'
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'center',
                                                                                        }}
                                                                                    >
                                                                                        <Checkbox
                                                                                            onClick={checkBoxA}
                                                                                            value={e.id}
                                                                                            checked={notification?.apartment_id?.split(';').includes(e?.id!)}
                                                                                            disabled={e.status_id === "0"}
                                                                                        >
                                                                                            <span
                                                                                                className='line-clamp-1'
                                                                                            >
                                                                                                {e.name}
                                                                                            </span>
                                                                                        </Checkbox>
                                                                                    </p>
                                                                                    {
                                                                                        e.owner_resident_id
                                                                                        && (
                                                                                            <>
                                                                                                <p
                                                                                                    className='house-title-sup line-clamp-1'
                                                                                                >
                                                                                                    {e.owner_resident_name}
                                                                                                </p>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                            {
                                                                !apartmentOptions ||
                                                                (apartmentOptions &&
                                                                    apartmentOptions.filter(element => element.floor_id == floor.id).length == 0) && (
                                                                    <div
                                                                        style={{
                                                                            textAlign: 'center'
                                                                        }}
                                                                    >
                                                                        {
                                                                            QLCCFloorModel &&
                                                                                blockId == "" ? (
                                                                                <h4 style={{ color: '#D8D8D8' }}>Vui lòng chọn tòa nhà!</h4>
                                                                            ) : (
                                                                                <h5 style={{ color: '#D8D8D8' }}>Không có căn hộ</h5>
                                                                            )
                                                                        }

                                                                    </div>
                                                                )
                                                            }
                                                        </Box>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))
                                    }
                                </Box> */}
                            </Grid>
                            {/* <Grid item xs={12} sm={3.5} md={2} lg={2}>
                                <h4>Danh sách</h4>
                                <TreeView
                                    id="treeview"
                                    items={Floors}
                                    selectNodesRecursive={true}
                                    selectByClick={true}
                                    showCheckBoxesMode={"normal"}
                                    selectionMode={"multiple"}
                                    // onContentReady={this.treeViewContentReady}
                                    itemRender={(item: any) => {
                                        return `${item.name} ${item.owner_resident_name ? `- ${item.owner_resident_name}` : `(${item.totalNumber} căn hộ)`}`;
                                    }}
                                    onSelectionChanged={onSelectionChanged}
                                // onItemClick={onItemClick}
                                // onItemSelectionChanged={onSelectionChanged}
                                />

                            </Grid> */}
                        </Grid>
                    </div >
                </DialogContent>
                <DialogActions>
                    <Button sx={{ marginRight: '1rem' }} variant="contained" color={"warning"}
                        onClick={handleSubmit}
                        disabled={props.action === ACTIONS.VIEW}
                    >
                        XÁC NHẬN
                    </Button>
                    <Button onClick={props.onClose}>Đóng</Button>
                </DialogActions>
                <BlockUI blocking={isLoading} title={""}></BlockUI>
            </Dialog>
        </>
    );
};

export default NotificationPopupHouse;


