import TextBox from 'devextreme-react/text-box'
import React from 'react'

interface IProps {
    value: any;
    name: string;
    showClearButton?: boolean;
    onChange: (value: any) => void;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    hint?: string  | undefined;
}

const DHSTextBox = (props: IProps) => {

    const handleChangeInput = (e: any) => {
        const { value } = e;
        props.onChange(
            {
                target: {
                    name: props.name,
                    value: value ? value : null
                }
            }
        )
    }
    return (
        <TextBox
            className={props.className ? props.className : ""}
            value={props.value ? props.value : ""}
            inputAttr={{ 'aria-label': 'Name' }}
            showClearButton={props.showClearButton ? true : false}
            onValueChanged={handleChangeInput}
            placeholder={props.placeholder ?? ""}
            disabled={props.disabled ? props.disabled : false}
            hint={props.hint ? props.hint : ""}
        />
    )
}

export default DHSTextBox