import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer, Notification } from '../../../components/layouts';
import { getRoles } from '../../../redux/actions/RoleActions';
import { AppSession } from '../../shared/app-session/app-session';
import { ChoThueXeClient, CtxDanhMucXeModel, HomeClient, RoleRightWebModel, SysActionType, } from '../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../shared/service-proxies/config';
import { useHistory, useLocation } from 'react-router-dom';
import BlockUI from '../../../components/Block-UI/Block-ui';
import { TransitionProps } from '@mui/material/transitions';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, Slide, TextField } from '@mui/material';
import { useHotkeys } from 'react-hotkeys-hook';
import dxDataGrid, { SelectionChangedInfo, } from "devextreme/ui/data_grid";
import { EventInfo } from "devextreme/events";
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DataGrid from 'devextreme-react/data-grid';
import DHSBreadcrumb from '../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import { ACTIONS } from '../../../common/enums/DHSToolbarRoleAction';
import DHSToolbarRole from '../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import { getIndexRowSelected, getKeyRowSelected } from '../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../common/Interfaces/StatusMessage';

const convert = ["success", "error", "warning"];
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any,
    feedbackItem: any;

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null,
    feedbackItem: {},
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const roleMenu: string = 'CC';
const TypeFeedBack: React.FC<{}> = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);
    const [dataTable, setDataTable] = React.useState<CtxDanhMucXeModel[]>([] as CtxDanhMucXeModel[]);
    const [data, setData] = React.useState<CtxDanhMucXeModel>({} as CtxDanhMucXeModel);
    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
    const [isShowSort, setShowSort] = React.useState<boolean>(false);
    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);

    const customMenu = [
        {
            menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
            onClick: () => {
                setShowSearch(!isShowSearch);
            },
        },
        {
            menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
            onClick: () => {
                setShowGroup(!isShowGroup);
            },
        },
        {
            menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
            onClick: () => {
                setShowSort(!isShowSort);
            },
        },
        {
            menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
            onClick: () => {
                setShowFilter(!isShowFilter);
            },
        },
    ];

    useHotkeys<HTMLParagraphElement>('alt+1', () => setShowSearch(!isShowSearch));

    useHotkeys<HTMLParagraphElement>('alt+2', () => setShowGroup(!isShowGroup));

    useHotkeys<HTMLParagraphElement>('alt+3', () => setShowSort(!isShowSort));

    useHotkeys<HTMLParagraphElement>('alt+4', () => setShowFilter(!isShowFilter));

    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    React.useEffect(() => {
        setLoading(true)
        ctxClient
            .ctxDanhMucXeActions({
                ... new CtxDanhMucXeModel(),
                tbname: "QLCCFeedbackType",
                action: "GET",
            } as CtxDanhMucXeModel)
            .then(res => {
                setDataTable(res);
                setLoading(false);
            })
    }, [reload]);

    React.useEffect(() => {
        if (actCx
            && actCx.open
            && (
                actCx.type === ACTIONS.EDIT
                || actCx.type === ACTIONS.VIEW
            )
        ) {
            const temp = actCx.feedbackItem;
            if (temp) {
                setData({ ...temp } as CtxDanhMucXeModel);
            } else {
                Notification(convert[2], "Không tim thấy đối tượng");
                setActCx(defaultAction);
            }
        }
    }, [actCx.open])


    const handleClickAction = async (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code) {
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: action.action_code,
                    payload: idItemSelected,
                    feedbackItem: dataTable.find(item => item.id === idItemSelected)
                })
                break;
            case ACTIONS.VIEW:
            case ACTIONS.EDIT:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: action.action_code,
                                payload: idItemSelected,
                                feedbackItem: dataTable.find(item => item.id === idItemSelected)
                            })
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }

                break;
            case ACTIONS.COPY:
                break;
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            if (window.confirm("Xác nhận xóa")) {
                                setLoading(true)
                                ctxClient
                                    .ctxDanhMucXeActions({
                                        ... new CtxDanhMucXeModel(),
                                        id: idItemSelected,
                                        tbname: "QLCCFeedbackType",
                                        action: "DELETE"
                                    } as CtxDanhMucXeModel)
                                    .then(res => {
                                        if (res) {
                                            Notification(convert[Number(res[0].ret) || 0], res[0].message)
                                        }
                                        setDataTable(res);
                                        setLoading(false);
                                        setReload(!reload);
                                        setIdItemSelected("");
                                    })
                            }
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }

                break;
            case ACTIONS.CLOSE:
                break;
            case ACTIONS.SEARCH:
                break;
            case ACTIONS.SAVE:
                break;
        }
    }


    const handleOnChange = (e: any) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        } as CtxDanhMucXeModel)
    }

    const handleSubmit = () => {
        setLoading(true)
        ctxClient
            .ctxDanhMucXeActions({
                ... new CtxDanhMucXeModel(),
                id: actCx.payload,
                name: data.name,
                tbname: "QLCCFeedbackType",
                action: actCx.type
            } as CtxDanhMucXeModel)
            .then(res => {
                if (res) {
                    Notification(convert[Number(res[0].ret) || 0], res[0].message)
                }
                setDataTable(res);
                setLoading(false);
                setReload(!reload);
            })
    }

    const handlePageChange = () => {
        setIdItemSelected("")
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    }

    const location = useLocation();

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
    const [allowEdit, setAllowEdit] = useState<boolean>(true);
    const [selectedItemKeys, setSelectedItemKeys] = useState<any>();


    useHotkeys<HTMLParagraphElement>('alt+1', () => setShowSearch(!isShowSearch));

    useHotkeys<HTMLParagraphElement>('alt+2', () => setShowGroup(!isShowGroup));

    useHotkeys<HTMLParagraphElement>('alt+3', () => setShowSort(!isShowSort));

    useHotkeys<HTMLParagraphElement>('alt+4', () => setShowFilter(!isShowFilter));


    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys: any) => {
                console.log(keys);

                const index = e.component.getRowIndexByKey(keys[0]);

                setIdItemSelected(keys[0].id)
                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
            });
        }
    };

    return (
        <div className='container-fluid'>
            <Dialog
                id='sysMenu6'
                open={actCx.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setActCx(defaultAction)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Loại phản ánh"}</DialogTitle>
                <DialogContent>
                    <InputLabel shrink>Tên</InputLabel>
                    <TextField
                        className='w-100'
                        value={data.name}
                        name='name'
                        onChange={handleOnChange}
                        disabled={actCx.type === ACTIONS.VIEW}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setActCx(defaultAction)}>Đóng</Button>
                    <Button onClick={handleSubmit} variant='contained' color='info'>Lưu</Button>
                </DialogActions>
            </Dialog>
            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location}
                />
            </div>
            {/* Control */}
            <DHSToolbarRole
                id={' '}
                menu={menu}
                onClickAction={handleClickAction}
            />

            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                key="id"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={allowEdit}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handlePageChange={handlePageChange}
                handleSelectionChanged={handleSelectionChanged}
            />
            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default TypeFeedBack;


