import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import notification from "../../../common/notification/notification";
import BlockUI from "../../../components/Block-UI/Block-ui";
import { AppSession } from "../../shared/app-session/app-session";
import {
    HomeClient,
    QLCCClient,
    QLCCApartmentBuildingModel,
    QLCCDomesticWaterCycleModel,
    SysActionType,
    QLCCUtilityModel,
    QLCCUtilityBookingModel,
    LoginClient,
    Company
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import ServicesAction from '../actions/service-action/ServicesAction'
import { AutoComplete, Form } from "antd";
import { useHotkeys } from "react-hotkeys-hook";
import DHSBreadcrumb from "../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import DataGrid from "devextreme-react/data-grid";
import { EventInfo } from "devextreme/events";
import dxDataGrid, { CellPreparedEvent, SelectionChangedInfo } from "devextreme/ui/data_grid";
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { ACTIONS } from "../../../common/enums/DHSToolbarRoleAction";
import DHSToolbarRole from "../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import { getDataRowSelected, getIndexRowSelected } from "../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../common/Interfaces/StatusMessage";
import CompanyInfoAction from "./action/CompanyInfoAction";


// import "./style.css";
interface IAction {
    open: boolean; //Mở Dialog
    type: string | undefined; //Loại action (keyword)
    // name: string | undefined; //Tên action (thêm, sửa, xóa)
    // table: string | undefined; //Tên bảng thay đổi khi thực hiện action
    payload: any | undefined;
}

interface IFilter {
    apartmentId?: string;
    domesticwaterCycleId?: string;
    payStatus?: string;
    open: boolean;
}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

let appSession: AppSession;
const convert = ["success", "error", "warning"];
const menuRoleName = "CC";
const CompanyInfo: React.FC<{}> = (props) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const dispatch = useDispatch();
    const location = useLocation();

    // const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
    // const hrClient = new HRClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const loginClient = new LoginClient(appSession, BASE_URL_API);
    // const invClient = new LstInventoryClient(appSession, BASE_URL_API);

    const [isLoading, setLoading] = React.useState<boolean>(false);

    const [dataTable, setDataTable] = React.useState<Company[]>([]);
    const [form] = Form.useForm();


    const [reload, setReload] = React.useState(false);

    // 

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>();
    const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>(-1);

    //  

    const [actCx, setActCx] = React.useState<IAction>(defaultAction);
    const [filter, setFilter] = React.useState<IFilter>({
        open: false,
    } as IFilter);

    const [apartmentBuildingList, setApartmentBuildingList] = React.useState<any[]>([] as any[]);
    const [apartmentBuildingSelected, setApartmentBuildingSelected] = React.useState<any>();

    React.useEffect(() => {

        /* tải danh sách dự án */

       
    }, [])



    React.useEffect(() => {
        setLoading(true);
        // Tải các dòng trong bảng

        loginClient
            .listCompany()
            .then((res) => {
                setLoading(false);
                setDataTable(res);

            });



    }, [reload]);



    const handleClickAction = async (action: SysActionType) => {

        // if (!apartmentBuildingSelected?.key) {
        //     notification(convert[1], "Vui lòng chọn dự án!")
        // }
        // else {
        switch (action?.action_code?.toLocaleUpperCase()) {
            // case ACTIONS.FILTER:
            //     setFilter({ ...filter, open: true })

            //     break;
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<QLCCUtilityBookingModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            // if (window.confirm("Bạn có chắc muốn xóa")) {
                            //     setLoading(true)
                            //     qLCCClient
                            //         .qLCCUtilityDel({
                            //             ...new QLCCUtilityModel(),
                            //             id: dataSelected?.id,
                            //             // tbname: menu?.component,
                            //             // action_type: "DELETE"
                            //         } as QLCCUtilityModel)
                            //         .then((res) => {
                            //             setLoading(false)
                            //             notification(convert[Number(res.status)], res.message);
                            //             if (res.status === 0) {
                            //                 const tempData = [...dataTable];
                            //                 const index = tempData.findIndex(x => x.id === res.id);
                            //                 if (index >= 0) {
                            //                     tempData.splice(index, 1);
                            //                     setDataTable(tempData);
                            //                 }
                            //             }
                            //             // setReload(!reload)
                            //         });
                            // }
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`DELETE_UNAVAILABLE`]
                            );
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: action?.action_code?.toUpperCase() ?? "",
                    payload: {
                        // service_id: selectedItemKeys?.id,
                        // apartment_building_id: apartmentBuildingSelected?.key
                    }
                });
                break;
            case ACTIONS.VIEW:
            case ACTIONS.EDIT:
            case ACTIONS.APPROVED:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<QLCCUtilityBookingModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: action?.action_code?.toUpperCase() ?? "",
                                payload: {
                                    utility_booking_id: dataSelected?.id,
                                    // apartment_building_id: apartmentBuildingSelected?.key
                                }
                            });
                        }
                        else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
        }
        // }


    };

    const onFinish = React.useCallback(async (values: QLCCUtilityBookingModel) => {
        // 

        // const body = Object.assign({}, data,);


    }, []);

    const onFinishFailed = (errorInfo: any) => {

        // api["error"]({
        //     message: "Đăng ký không thành công",
        //     description: "Vui lòng xem lại các ô nhập!"

        // });

    }

    const onApartmentBuildingSelect = (data: string, option: any) => {

        // setApartmentBuildingSelected({
        //     id: option.key,
        //     name: option.value
        // });



    };

    const onApartmentBuildingChange = (data: any, option: any) => {

        setApartmentBuildingSelected({
            key: option.key,
            value: option.value
        });
    };


    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                console.log(keys);

                const index = e.component.getRowIndexByKey(keys[0]);

                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
            });
        }
    };

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };

  
    return (
        <div
            className="container-fluid p-2"
            style={{ maxHeight: "94vh !important", overflowY: "auto" }}
        >
            {
                actCx.open && <CompanyInfoAction
                    open={actCx.open}
                    onClose={function (): void {
                        setActCx(defaultAction)
                    }}
                    onEditSuccess={function (dataEdited): void {
                        // if (idEdited) {
                        //     const tempData = [...dataTable];
                        //     const index = tempData.findIndex(x => x.id === idEdited);
                        //     if (index >= 0 && dataEdited) {
                        //         tempData[index] = dataEdited;
                        //         setDataTable(tempData);
                        //     }
                        // }
                        setReload(pre => !pre)
                    }}
                    
                    action={actCx.type}
                    itemSelected={actCx.payload} >

                </CompanyInfoAction>

            }





            <div className='row no-gutters my-1 align-items-center '>
                {/* Go back */}

                <div
                    className="d-flex col-md-12 col-xl-9 title-color"
                    style={{
                        height: 30,
                        color: "rgb(0 78 255 / 88%)",
                        marginTop: 15,
                        marginBottom: 15,
                        fontSize: 22,
                        fontWeight: 550,
                    }}
                >
                    <DHSBreadcrumb
                        location={location} />

                </div>
                {/* <div className="col-md-12 col-xl-3 pr-1 " style={{ marginLeft: 'auto' }}>

                    <Form
                        name="basic"
                        form={form}
                        layout="horizontal"
                        labelAlign="left"
                        // initialValues={{ requiredMarkValue: requiredMark }}
                        // onValuesChange={onRequiredTypeChange}

                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    // requiredMark={requiredMark}
                    // labelCol={{ span: 8 }}
                    >
                        <Form.Item
                            label={<span style={{ fontWeight: 600, fontSize: 14 }}>Chọn dự án</span>}
                            name="apartment_building_id"
                            hasFeedback
                            // required 
                            // tooltip="Thông tin mã khách hàng là bắt buộc"
                            rules={[
                                { required: true, message: 'Dự án là bắt buộc!' },
                            ]}

                        >

                            {
                                apartmentBuildingSelected && <AutoComplete

                                    size="middle"
                                    value={apartmentBuildingSelected ? apartmentBuildingList.find(item => item.key === apartmentBuildingSelected?.key) : null}
                                    defaultValue={apartmentBuildingSelected}
                                    options={apartmentBuildingList}
                                    onSelect={onApartmentBuildingSelect}
                                    // onSearch={ApartmentSearchInput}
                                    onChange={onApartmentBuildingChange}
                                    placeholder="Chọn dự án"
                                />
                            }
                        </Form.Item>
                    </Form>



                </div> */}
            </div>
            <DHSToolbarRole
                id={' '}
                menu={menu}
                customMenu={[]}
                onClickAction={handleClickAction}
            />

            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                keyExpr="ma_cty"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handleSelectionChanged={handleSelectionChanged}
                handlePageChange={handlePageChange}

            // handleExporting={handleExporting}
            />
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </div>
    );
};

export default CompanyInfo;
