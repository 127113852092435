import {
  LstCurrency,
  SysSetupSI,
} from "../app/shared/service-proxies/api-shared";

const sysSetupSI: SysSetupSI = new SysSetupSI();

export default class DHSMathUtils {
  public static DigitRoundPriceValue = (value: number, ma_cty: string, ma_nt: string) => {
    const numberRound: number | undefined = this.DigitRoundPrice(ma_cty, ma_nt);
    if (numberRound || numberRound === 0) {
        return parseFloat(value.toFixed(numberRound));
    } else {
      return value;
    }
  };

  public static DigitRoundAmountValue = (value: number, ma_cty: string, ma_nt: string) => {
    const numberRound: number | undefined = this.DigitRoundAmount(
      ma_cty,
      ma_nt
    );
    console.log(numberRound);
    
    if (numberRound || numberRound === 0)  {
        return parseFloat(value.toFixed(numberRound));
    } else {
      return value;
    }
  };

  public static DigitRoundPrice = (ma_cty: string, ma_nt: string) => {
    const lstCurrency: LstCurrency[] = JSON.parse(
      localStorage.getItem("lstCurrency") || "[]"
    );
    const currency = lstCurrency.find(
      (item) => item.ma_cty === ma_cty && item.ma_nt === ma_nt
    );
    return currency?.roundoffprice;
  };

  public static DigitRoundAmount = (ma_cty: string, ma_nt: string) => {
    const lstCurrency: LstCurrency[] = JSON.parse(
      localStorage.getItem("lstCurrency") || "[]"
    );
    const currency = lstCurrency.find(
      (item) => item.ma_cty === ma_cty && item.ma_nt === ma_nt
    );
    return currency?.roundoffamount;
  };

  public static RoundAmount = (value: number, ma_cty: string, isNT: boolean) => {
    if (isNT) {
      const fcAmount_decimal: number | undefined = sysSetupSI?.fcAmount_decimal;
      if (fcAmount_decimal) {
        return value.toFixed(fcAmount_decimal);
      } else {
        return value;
      }
    } else {
      const amount_decimal: number | undefined = sysSetupSI?.amount_decimal;
      if (amount_decimal) {
        return value.toFixed(amount_decimal);
      } else {
        return value;
      }
    }
  };

  public static RoundPrice = (value: number, ma_cty: string, isNT: boolean) => {
    if (isNT) {
      const fcPrice_decimal: number | undefined = sysSetupSI?.fcPrice_decimal;
      if (fcPrice_decimal) {
        return value.toFixed(fcPrice_decimal);
      } else {
        return value;
      }
    } else {
      const price_decimal: number | undefined = sysSetupSI?.price_decimal;
      if (price_decimal) {
        return value.toFixed(price_decimal);
      } else {
        return value;
      }
    }
  };
}
