import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from '../../../components/layouts';
import { getRoles } from '../../../redux/actions/RoleActions';
import { AppSession } from '../../shared/app-session/app-session';
import {
    CaVch01D0,
    CaVch01D1,
    HomeClient,
    LstCharge,
    RoleRightWebModel,
    SysGenRowTableModel
} from '../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../shared/service-proxies/config';

import BlockUI from '../../../components/Block-UI/Block-ui';
import notification from '../../../common/notification/notification';
import { Box, Button, Paper, Tab, Tabs } from '@mui/material';
import { PlusOutlined } from '@ant-design/icons';
import ReceiptInvoiceAction from './actions/ReceiptInvoiceAction';
import { generateUUID } from '../../../components/utils/generationUuid';
import { ACTIONS } from '../../../common/enums/DHSToolbarRoleAction';
import DataGrid, { Column, ColumnChooser, ColumnFixing, Editing, FilterBuilderPopup, FilterPanel, FilterRow, Format, GroupPanel, Grouping, HeaderFilter, Lookup, RequiredRule, SearchPanel, Selection, Summary, TotalItem } from 'devextreme-react/data-grid';
import dxDataGrid, { DataType, RowRemovedInfo, RowUpdatedInfo } from 'devextreme/ui/data_grid';
import themes from 'devextreme/ui/themes';

import Receipt01D1 from './receipt-D1';
import { EventInfo } from 'devextreme/events';
import { httpPost } from '../../../common/httpService';
import NumberBox from 'devextreme-react/number-box';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../common/Interfaces/StatusMessage';

var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}



interface IProps {
    arrayCt?: CaVch01D0[],
    arrayCt1?: CaVch01D1[],
    action?: string,
    maKH?: string,
    exchange?: any,
    idcustomer?: string,
    apartment_building_id?: string | null,
    totalTTMoney: (totalTTMoney?: number) => void,
    passArrayCT?: (data: CaVch01D0[],) => void,
    passArrayCT1?: (data: CaVch01D1[]) => void,
    onChangeTT?: (data: CaVch01D1[]) => void,
    dataGridRef01D1: React.RefObject<DataGrid<any, any>>,
    dataGridRef01D0: React.RefObject<DataGrid<any, any>>,
    onError?: (errors?: any[]) => void,
    stt_rec?: string,
    dataInvoice?: any[]
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: "1rem 0" }}>
                    {children}
                </Box>
            )}
        </div>
    );
}


const invoice_action: {[key: string | number]: string} = {
    1: "SELECTINVOICE",
    2: "SELECTINVOICE",
    3: "SELECTINVOICE",
    4: "SELECTINVOICE",
    5: "SELECTUTILITY",
    6: "SELECTCARNUMBER",

}
const invoice_tab_title: {[key: string | number]: string} = {
    1: "Thanh toán hóa đơn",
    2: "Thanh toán hóa đơn",
    3: "Thanh toán hóa đơn",
    4: "Thanh toán hóa đơn",
    5: "Thanh toán hóa đơn dịch vụ",
    6: "Thanh toán hóa đơn thẻ xe",

}

const invoice_tab_visible: {[key: string | number]: boolean} = {
    1: true,
    2: true,
    3: false,
    4: true,
    5: false,
    6: false,

}


const roleMenu: string = 'CC';
const ReceiptInvoiceList: React.FC<IProps> = (props) => {

    const dispatch = useDispatch();
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [reload, setReload] = React.useState<boolean>(false);
    const homeClient = new HomeClient(appSession, BASE_URL_API);

    const [dataTable01D1, setDataTable01D1] = React.useState<CaVch01D1[]>([]); // any là CaVch01D1
    const [ca01D1, setCa01D1] = React.useState<CaVch01D1[]>([]);
    const [actRi, setActRi] = useState<IAction>(defaultAction);
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [value, setValue] = React.useState(0);


    // 

    const [errors, setErrors] = React.useState<any[]>();
    // 


    const [dataTable01D0, setDataTable01D0] = React.useState<any[]>([

    ]);

    // 

    const [chargeOptions, setChargeOptions] = React.useState<any[]>([]);


    // 

    const [allMode] = useState<string>('allPages');
    const [checkBoxesMode] = useState<string>(themes.current().startsWith('material') ? 'always' : 'onClick');
    // const [changes, setChanges] = React.useState<any>([]);
    const [editRowKey, setEditRowKey] = React.useState<string | null>(null);
    const [selected01D0RowKeys, setSelected01D0RowKeys] = React.useState<string[]>([]);
    const [selected01D1RowKeys, setSelected01D1RowKeys] = React.useState<string[]>([]);
    // const dataGridRef = React.useRef<DataGrid>(null);
    // const [isAddNewRow, setIsAddNewRow] = React.useState<boolean>(false);


    // 

    const [isHiddenGroup, setIsHiddenGroup] = React.useState<boolean>(false);
    const [isHiddenSearch, setIsHiddenSearch] = React.useState<boolean>(false);
    const [isHiddenFilter, setIsHiddenFilter] = React.useState<boolean>(false);
    const [isHiddenAdvantageFilter, setIsHiddenAdvantageFilter] = React.useState<boolean>(false);
    const [isHiddenColumnChooser, setIsHiddenColumnChooser] = React.useState<boolean>(false);

    const [firstEditLoad, setFirstEditLoad] = React.useState<boolean>(false);
    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    // 

    const { dataGridRef01D1 } = props;

    // React.useEffect(() => {
    //     if(!props.maKH){
    //         setDataTable01D0([])
    //         setDataTable01D1([])
    //     }
    // }, [props.maKH])

    //#region HandleAddAnđeleteDataGrid01D0
    // 
    const onAddButton01D0 = (e: React.MouseEvent<HTMLButtonElement>) => {

        if (props.dataGridRef01D0.current) {
            props.dataGridRef01D0.current.instance.addRow();

        }

    };

    const onDeleteButton01D0 = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (props.dataGridRef01D0.current) {
            const keys = await props.dataGridRef01D0.current.instance.getSelectedRowKeys();
            if (keys.length > 0) {
                keys.map(key => {
                    const rowIndex = props.dataGridRef01D0.current?.instance.getRowIndexByKey(key);
                    console.log(rowIndex, "rowIndex")
                    if (rowIndex !== null && rowIndex !== undefined && rowIndex > -1) {
                        props.dataGridRef01D0.current!.instance.deleteRow(rowIndex);
                        props.dataGridRef01D0.current!.instance.refresh();
                    }
                })
                props.dataGridRef01D0.current!.instance.clearSelection();

            }
            else {
                notification(
                    TYPE_MESSAGE.WARNING,
                    STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                );
            }



        }
    };

    //#endregion

    //#region hanleEditAndDetailAction

    React.useEffect(() => {
        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            setFirstEditLoad(true);
            const tempData01D1 = [...dataTable01D1];

            props.arrayCt1?.forEach((caVch01) => {
                const index = tempData01D1.findIndex(x => x.stt_rec_hd === caVch01.stt_rec_hd);
                if (index === -1) {
                    tempData01D1.push({
                        ...caVch01,
                        ngay_ct: caVch01.ngay_ct,
                        ngay_hd: caVch01.ngay_hd,
                    } as any)
                }

            });
            // setDataTable05M(tempData05M);
            setCa01D1(props.arrayCt1 ?? []);
            setDataTable01D1(tempData01D1 ?? [])

        }
    }, [props.action, props.arrayCt1]);

    React.useEffect(() => {
        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            if (props.arrayCt) {
                const tempData01D0: any = [...props.arrayCt];
                tempData01D0.map((dt01D0: any) => {
                    dt01D0.id = generateUUID();
                })
                setDataTable01D0(tempData01D0 ?? []);
            }


        }
    }, [props.action, props.arrayCt]);

    //#endregion

    // #region handle01D0Grid  

    const handleContextMenuItems = (items: any) => {
        if (!items) {
            return;
        }

        items.forEach((item: any) => {
            if (item.items) {
                // Xử lý mục menu lồng nhau
                handleContextMenuItems(item.items);
            }

            switch (item.text) {
                case "Sort Ascending":
                    item.text = 'Sắp xếp tăng dần';
                    break;
                case "Sort Descending":
                    item.text = 'Sắp xếp giảm dần';
                    break;
                case "Clear Sorting":
                    item.text = 'Hủy sắp xếp';
                    break;
                case "Group by This Column":
                    item.text = 'Group cột này';
                    break;
                case "Ungroup All":
                    item.text = 'Hủy bỏ group tất cả';
                    break;
                case "Fix":
                    item.text = 'Cố định';
                    break;
                case "To the left":
                    item.text = 'Từ trái sang';
                    break;
                case "To the right":
                    item.text = 'Từ phải sang';
                    break;
                case "Unfix":
                    item.text = 'Không cố định';
                    break;
            }
        });
    };


    const handleContextMenuPreparing = (e: any) => {
        const { row, column } = e;

        // Xác định loại ContextMenu (dành cho hàng hoặc cột)
        if (row) {
            // ContextMenu cho hàng
            const rowData = row.data;
            // Tùy chỉnh ContextMenu cho hàng dựa trên rowData
            e.items?.push(
                {
                    text: isHiddenGroup ? "Ẩn group" : "Hiện group",
                    onItemClick: () => setIsHiddenGroup(pre => !pre),
                    beginGroup: true,
                },
                {
                    text: isHiddenSearch ? "Ẩn tìm kiếm" : "Hiện tìm kiếm",
                    onItemClick: () => setIsHiddenSearch(pre => !pre),
                },
                {
                    text: isHiddenFilter ? "Ẩn lọc" : "Hiện lọc",
                    onItemClick: () => setIsHiddenFilter(pre => !pre),
                },
                {
                    text: isHiddenAdvantageFilter ? "Ẩn lọc nâng cao" : "Hiện lọc nâng cao",
                    onItemClick: () => setIsHiddenAdvantageFilter(pre => !pre),
                },
                {
                    text: isHiddenColumnChooser ? "Ẩn ẩn cột" : "Hiện ẩn cột",
                    onItemClick: () => setIsHiddenColumnChooser(pre => !pre),

                },
                // Các mục menu khác cho hàng
            );
        } else if (column) {
            // ContextMenu cho cột
            const columnData = column.dataField;
            // Tùy chỉnh ContextMenu cho cột dựa trên columnData
            e.items.push(
                {
                    text: isHiddenGroup ? "Ẩn group" : "Hiện group",
                    onItemClick: () => setIsHiddenGroup(pre => !pre),
                },
                // Các mục menu khác cho cột
            );
        }

        // Thay đổi văn bản của các mục menu trong e.items
        handleContextMenuItems(e.items);


    };

    React.useEffect(() => {
        // setLoading(true);
        // caVch01Client.lstChargesGet({
        //     ...new LstCharge(),
        //     ma_cty: SessionLogin.ma_cty,

        // } as LstCharge)
        //     .then(response => {


        //         setChargeOptions(response)
        //         setLoading(false);
        //     }).catch(error => setLoading(false));
        setLoading(true)
        const body = {
            ma_cty: SessionLogin.ma_cty,
            tablelist: "dm_phi_thu",
        }
        httpPost<string>('api/Common/LoadDatasourceBindControlByApartmentBuilding', body)
            .then(res => {

                const lstCharge = JSON.parse(res.data);
                setChargeOptions(lstCharge?.Table1 ?? []);
                setLoading(false);

            }).catch(error => setLoading(false));

    }, []);

    const [columns, setColumns] = React.useState<SysGenRowTableModel[]>([]);






    React.useEffect(() => {
        props.onError && props.onError(errors);
    }, [errors]);



    React.useEffect(() => {
        setLoading(true);
        homeClient
            .roleRightWebGetAll({
                ...new RoleRightWebModel(),
                menuid: "60.10.10",
                rolename: roleMenu,
                username: SessionLogin.userName,
            } as RoleRightWebModel)
            .then((res) => {
                dispatch(getRoles(res));
                setRoles(res);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false)
            })



    }, [menu?.component])

    React.useEffect(() => {
        let pr = new SysGenRowTableModel();
        pr.table_name = '69.10.01_CaVch01D0' //props.table_name;
        pr.show_form_list = true // props.show_form_list;
        homeClient?.sysGenRowTable(pr).then((res) => {

            setColumns(res);
        });
    }, []);

    //#region handlePayFromSoVch05

    // Xử lý khi thanh toán từ hóa đơn
    React.useEffect(() => {
        const { dataInvoice } = props
        const temp01D1 = [...dataTable01D1];
        if (dataInvoice) {
            dataInvoice.forEach(dt05M => {
                const index = temp01D1.findIndex(x => x.stt_rec_hd === dt05M.stt_rec);
                if (index === -1) {
                    temp01D1.push({
                        ma_cty: dt05M.ma_cty,
                        stt_rec_hd: dt05M.stt_rec,
                        ma_ct: dt05M.ma_ct,
                        so_hd: dt05M.so_ct,
                        so_ct: dt05M.so_ct,
                        idCustomer: dt05M.iDcustomer,
                        ngay_hd: dt05M.ngay_ct,
                        ngay_ct: dt05M.ngay_ct,
                        ma_nt: dt05M.ma_nt,
                        ty_gia: dt05M.ty_gia,
                        tien_tt_nt: dt05M.t_tt_nt,
                        tien_tt: dt05M.t_tt,
                        ma_hd: dt05M.ma_ct,
                        dien_giai: dt05M.dien_giai,
                        tt_nt: dt05M.t_tt_nt,
                        tt: dt05M.t_tt,
                        loai_tt: dt05M.loai_tt
                    } as any);
                }
            });

            // thêm 01D0 khi thêm 05M
            const totalTTMoney = dataInvoice.reduce((acc, invoice) => {
                return acc + invoice.t_tt!;
            }, 0);
            props.totalTTMoney(totalTTMoney)
            // const genId: string = generateUUID();
            // 01D0 và 05M khi thêm hóa đơn thì phân biệt bởi so_ct
            // nếu so_ct là S05 đây sẽ là phiếu thu cho hóa đơn
            const temp01D0: any[] = [...dataTable01D0];
            const data01D1Temp = [...dataTable01D1]
            var index01D0 = -1;
            if (dataInvoice.length > 0) {
                // if (props.action === 'ADD') {
                //     index01D0 = temp01D0.findIndex(x => x.sO_CT === dataInvoice[0]?.ma_ct);

                // }
                // else if (props.action === ACTIONS.EDIT) {
                index01D0 = temp01D0.findIndex(x => x.ma_ct_hd === dataInvoice[0]?.ma_ct);

                // }
                const data01D0 = {
                    ...new CaVch01D0(),
                    stT_REC: dataInvoice[0]?.stt_rec,
                    stT_REC0: generateUUID(),
                    key: dataInvoice[0]?.stt_rec,
                    sO_CT: dataInvoice[0]?.so_ct,
                    dieN_GIAI: `Thu phí ${dataInvoice[0]?.dien_giai}`,
                    ma_ct_hd: dataInvoice[0]?.ma_ct,
                    teN_KH: dataInvoice[0]?.ten_kh_vat,
                    pS_CO_NT: totalTTMoney,
                    pS_CO: totalTTMoney,
                    pS_NO_NT: 0,
                    pS_NO: 0
                } as any


                if (index01D0 === -1) {
                    temp01D0.push(data01D0)

                }
                else {
                    temp01D0[index01D0] = data01D0;
                }

            }
            else {
                index01D0 = temp01D0.findIndex(x => x.ma_ct_hd === data01D1Temp[0]?.ma_ct);
                temp01D0.splice(index01D0, 1)

            }


            setDataTable01D0(temp01D0);
            setDataTable01D1(temp01D1)
        }
    }, [])

    //#endregion



    //#region handleWhen01D0Change
    // Khi 01D0 có thay đổi giá trị
    React.useEffect(() => {


        // 
        const tempData01D0 = [...dataTable01D0]
        // tempData01D0.map((item, index) => {
        //    item.key = undefined;
        // return item;
        // })
        props.passArrayCT && props.passArrayCT(tempData01D0);
        const totalTTMoney = [...dataTable01D0].reduce((acc, invoice) => {
            return acc + invoice.pS_CO_NT!;
        }, 0);
        props.totalTTMoney(totalTTMoney)

    }, [dataTable01D0,])


    const savedDT01D0 = () => {
        const tempData01D0 = [...dataTable01D0]
        tempData01D0.length > 0 && tempData01D0.map((item, index) => {
            if (!item) {
                tempData01D0.splice(index, 1)

            }
            // else item.key = undefined;
            return item;
        })
        // props.passArrayCT && props.passArrayCT(tempData01D0);
        // const totalTTMoney = [...dataTable01D0].reduce((acc, invoice) => {
        //     return acc && acc + invoice.pS_CO!;
        // }, 0);
        // props.totalTTMoney(totalTTMoney)
        setDataTable01D0(tempData01D0)



    }


    //#endregion

    //#region handleWhenCustomerHaveNotSeleted

    // Khi người dùng chưa chọn khách hàng
    const handleClickRecieptInvoiceAction = (e: any) => {
        if (!props.maKH) {
            notification(TYPE_MESSAGE.WARNING, "Vui lòng chọn khách hàng");
        }
        else {
            setActRi(pre => ({
                ...pre,
                open: true,
                type: e.action,
                itemSelected: null,
                payload: {
                    maKH: props?.maKH,
                    idcustomer: props?.idcustomer,
                    data: dataTable01D1,
                    apartment_building_id: props?.apartment_building_id
                }
            }))
        }
    }

    //#endregion

    // 


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (dataGridRef01D1.current) {
            dataGridRef01D1.current.instance.saveEditData();
            dataGridRef01D1.current.instance.refresh();

        }
        if (props.dataGridRef01D0.current) {
            props.dataGridRef01D0.current.instance.saveEditData().then(() => {


            });
            props.dataGridRef01D0.current.instance.refresh();

        }
        setValue(newValue);
    };


    //#endregion


    // #region handle01D1Grid

    // Chuyển từ SoVch05M thành CaVch01D1
    React.useEffect(() => {

        props.passArrayCT1 && props.passArrayCT1(dataTable01D1);


        if (!firstEditLoad) {
            if (dataTable01D1.length > 0) {
                const totalTTMoney = [...dataTable01D1].reduce((acc: any, invoice: any) => {
                    return acc + invoice.tt_nt!;
                }, 0);
                // tìm ra phiếu thu mà thu bằng hóa đơn (phân biệt bằng ma_ct_hd)
                // vì số ct ở hóa đơn sẽ map qua 01D0
                // còn tạo phiếu thu khác sẽ có so_ct khác
                const temp01D0 = [...dataTable01D0];
                const index01D0 = temp01D0.findIndex(x => x?.ma_ct_hd === dataTable01D1[0]?.ma_ct)
                if (temp01D0.length > 0 && index01D0 > -1) {
                    temp01D0[index01D0].pS_CO_NT = totalTTMoney;
                    temp01D0[index01D0].pS_CO = totalTTMoney;
                    temp01D0[index01D0].pS_NO = 0;
                    temp01D0[index01D0].pS_NO_NT = 0;
                }

                setDataTable01D0(temp01D0)
            }
            setFirstEditLoad(false);
        }


    }, [reload, dataTable01D1]);

    // Xóa dòng 05M
    const HandleDelete01D1 = (e: any) => {

        const tempData01D1 = [...dataTable01D1];

        // selectionid.forEach(idselect => {
        // const index = tempData01D1.findIndex(x => x.stt_rec_hd === e.data.stt_rec_hd);
        // if (index > -1) {
        //     tempData01D1.splice(index, 1);
        // }
        // })



        // // Xóa 01D0 khi 05M không còn data
        const tempData01D0 = [...dataTable01D0];

        if (tempData01D1.length === 0) {
            const index = tempData01D0.findIndex(x => x.ma_ct_hd === e.data.ma_ct);
            tempData01D0.splice(index, 1)
            setDataTable01D0(tempData01D0);


        }

        setDataTable01D1(tempData01D1);

        console.log(e, "removed")




    }

    const removeRow01D1 = async (e: any) => {
        if (dataGridRef01D1.current) {
            const keys = await dataGridRef01D1.current.instance.getSelectedRowKeys();
            if (keys.length > 0) {
                keys.map(key => {
                    const rowIndex = dataGridRef01D1.current?.instance.getRowIndexByKey(key);
                    if (rowIndex !== null && rowIndex !== undefined && rowIndex > -1) {
                        dataGridRef01D1.current!.instance.deleteRow(rowIndex);
                       
                        dataGridRef01D1.current!.instance.refresh();
                    }

                });
                dataGridRef01D1.current!.instance.clearSelection();
            } else {
                notification(
                    TYPE_MESSAGE.WARNING,
                    STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                );
            }

        }
    }

    // Khi input thay đổi lưu lại 05M
    const HandleSave01D1 = (e: any) => {
        // CaVch05M



        const updatedData01D1 = [...dataTable01D1].reduce((acc: CaVch01D1[], item) => {
            const updatedItem = e.data.stt_rec_hd === item.stt_rec_hd ? {
                ...e.data,
                tt: e.data?.tt_nt * e.data?.ty_gia
            } : null;
            if (updatedItem) {
                acc.push(updatedItem);
            } else {
                acc.push(item);
            }
            return acc;
        }, [])

        setDataTable01D1(updatedData01D1)

        const totalTTMoney = updatedData01D1.reduce((acc: any, invoice: any) => {
            return acc + invoice.tt_nt!;
        }, 0);
        // tìm ra phiếu thu mà thu bằng hóa đơn (phân biệt bằng so_ct)
        // vì số ct ở hóa đơn sẽ map qua 01D0
        // còn tạo phiếu thu khác sẽ có so_ct khác
        const temp01D0 = [...dataTable01D0];
        var index01D0 = -1;

        // if(props.action === 'ADD'){
        //     index01D0 = temp01D0.findIndex(x => x.sO_CT === dataTable05M[0].ma_ct)
        // }
        // else if(props.action === ACTIONS.EDIT){
        index01D0 = temp01D0.findIndex(x => x.ma_ct_hd === dataTable01D1[0].ma_ct)

        // }

        if (index01D0 > -1) {
            temp01D0[index01D0].pS_CO_NT = totalTTMoney;
            temp01D0[index01D0].pS_CO = totalTTMoney;
            temp01D0[index01D0].pS_NO = 0;
            temp01D0[index01D0].pS_NO_NT = 0;
        }


        setDataTable01D0(temp01D0);


    }
    //  #endregion







    return (
        <div className='container-fluid'>
            {
                actRi.open && (
                    <ReceiptInvoiceAction
                        open={true}
                        onClose={function (): void {
                            setActRi(defaultAction);
                        }}
                        action={actRi.type}
                        payload={actRi.payload}
                        stt_rec={props.stt_rec}
                        exchange ={ props.exchange}
                        onEditSuccess={function (response): void {
                            if (response) {
                                const totalTTMoney = response.reduce((acc, invoice) => {
                                    return acc + invoice.tt_nt!;
                                }, 0);
                                props.totalTTMoney(totalTTMoney)
                                // const genId: string = generateUUID();
                                // 01D0 và 05M khi thêm hóa đơn thì phân biệt bởi so_ct
                                // nếu so_ct là S05 đây sẽ là phiếu thu cho hóa đơn
                                const temp01D0: any[] = [...dataTable01D0];
                                const data01D1Temp = [...dataTable01D1]
                                var index01D0 = -1;
                                if (response.length > 0) {
                                    // if (props.action === 'ADD') {
                                    //     index01D0 = temp01D0.findIndex(x => x.sO_CT === response[0]?.ma_ct);

                                    // }
                                    // else if (props.action === ACTIONS.EDIT) {
                                    index01D0 = temp01D0.findIndex(x => x.ma_ct_hd === response[0]?.ma_ct);

                                    // }
                                    const data01D0 = {
                                        ...new CaVch01D0(),
                                        mA_CTY: response[0]?.ma_cty,
                                        stT_REC: response[0]?.stt_rec,
                                        stT_REC0: generateUUID(),
                                        key: response[0]?.stt_rec,
                                        sO_CT: response[0]?.ma_ct,
                                        ma_ct_hd: response[0]?.ma_ct,
                                        teN_KH: response[0]?.ten_kh_vat,
                                        dieN_GIAI: `Thu tiền ${response[0]?.dien_giai}`,
                                        pS_CO: totalTTMoney,
                                        pS_CO_NT: totalTTMoney,
                                        pS_NO: 0,
                                        pS_NO_NT: 0
                                    } as any


                                    if (index01D0 === -1) {
                                        temp01D0.push(data01D0)

                                    }
                                    else {
                                        temp01D0[index01D0] = data01D0;
                                    }

                                }
                                else {
                                    index01D0 = temp01D0.findIndex(x => x.ma_ct_hd === data01D1Temp[0]?.ma_ct);
                                    temp01D0.splice(index01D0, 1)

                                }


                                setDataTable01D0(temp01D0);

                                // any là CaVch01D0

                                const CustTransTemp = response;
                                if (CustTransTemp.length > 0) {
                                    const temp01D1 = [...dataTable01D1];
                                    CustTransTemp.forEach(CustItem => {
                                        const index = temp01D1.findIndex(x => x.stt_rec_hd === CustItem.stt_rec);
                                        if (index === -1) {
                                            temp01D1.push({
                                                ma_cty: CustItem.ma_cty,
                                                ma_ct: CustItem.ma_ct,
                                                so_ct: CustItem.so_ct,
                                                so_hd: CustItem.so_ct,
                                                idCustomer: CustItem.idcustomer,
                                                ngay_hd: CustItem.ngay_ct,
                                                ngay_ct: CustItem.ngay_ct,
                                                ma_nt: CustItem.ma_nt,
                                                ty_gia: CustItem.ty_gia,
                                                tien_tt_nt: CustItem.tien_tt_nt,
                                                tien_tt: CustItem.tien_tt,
                                                // ma_hd: CustItem.ma_ct,
                                                stt_rec_hd: CustItem.stt_rec,
                                                tt_nt: CustItem.tt_nt,
                                                tt: CustItem.tt,
                                                tk_pt: CustItem.tk_pk,
                                                dien_giai: CustItem.dien_giai,
                                                loai_tt: "1"
                                            } as any);
                                        }

                                    });
                                    setDataTable01D1(temp01D1)

                                }

                                else setDataTable01D1([])
                                setValue(1);

                            }

                        }}
                    >
                    </ReceiptInvoiceAction>
                )
            }








            {/* {
                !props?.dataInvoice && <div className='row mt-3'>
                    <div className="col-12 mb-2 d-flex">
                        <div className='px-1'>
                            

                        </div>

                    </div>
                </div>
            } */}

            <div>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Chi tiết" sx={{ textTransform: 'none', fontSize: 14 }}  {...a11yProps(0)} />
                        <Tab label={invoice_tab_title[props.exchange]} hidden={invoice_tab_visible[props.exchange]} sx={{ textTransform: 'none', fontSize: 14 }}  {...a11yProps(1)} />
                        {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <div style={{ overflowX: 'scroll', }} className='row no-gutters'>
                        {
                            props.action !== ACTIONS.VIEW && <Paper className='col-12 d-flex py-1' sx={{ gap: 0, backgroundColor: '#F6F7F9' }}>

                                <Button
                                    variant='text'
                                    sx={{
                                        padding: '0 0.75rem', color: '#1976D2', fontWeight: 600, fontSize: 14,
                                        textTransform: 'capitalize',
                                        backgroundColor: 'transparent',
                                    }}
                                    startIcon={<i className={"bi bi-plus-lg"} style={{ color: '#1976D2', fontSize: 18, }} />}
                                    // onClick={HandleAddNew01DO}
                                    onClick={onAddButton01D0}


                                >Thêm</Button>
                                <Button
                                    variant='text'
                                    sx={{
                                        padding: '0 0.75rem', color: '#1976D2', fontWeight: 600, fontSize: 14,
                                        textTransform: 'capitalize',
                                        backgroundColor: 'transparent',
                                    }}
                                    startIcon={<i className={"bi bi-trash3"} style={{ color: '#1976D2', fontSize: 18, }} />}
                                    // onClick={HandleDelete01DO}
                                    onClick={onDeleteButton01D0}
                                >Xóa</Button>


                            </Paper>
                        }
                        <div className="col-12">
                            <Paper>
                                <DataGrid
                                    ref={props.dataGridRef01D0}
                                    keyExpr={"stT_REC0"}
                                    dataSource={dataTable01D0}
                                    // allowColumnReordering={true}
                                    allowColumnResizing={true}
                                    showBorders={true}
                                    noDataText='Không có dữ liệu'
                                    repaintChangesOnly={true}
                                    // columnHidingEnabled={true}
                                    // onFocusedRowChanged={(e) => console.log("focus row")}
                                    columnMinWidth={50}
                                    columnAutoWidth={true}
                                    onEditingStart={(e) => {
                                        // operation = "EDIT-START-D0";
                                        // props.onDataGridD0Saved && props.onDataGridD0Saved(operation);

                                        // setIsSaved();
                                        console.log('EditingStart')
                                    }}
                                    onInitNewRow={() => console.log('InitNewRow')}
                                    onRowInserting={(e) => {
                                        e.data["stT_REC0"] = generateUUID();
                                    }}
                                    onRowInserted={(e) => {
                                        
                                        // const tempData = [...dataTable01D0]
                                        // tempData.push(e.data)
                                        // setDataTable01D0(tempData)
                                    }}

                                    onRowUpdating={() => console.log('RowUpdating')}
                                    onRowUpdated={() => console.log('RowUpdated')}
                                    onRowRemoving={() => console.log('RowRemoving')}
                                    onRowRemoved={() => console.log('RowRemoved')}
                                    onSaving={() => {
                                        // setIsSaved("SAVING");
                                        // operation = "SAVING-D0"
                                        // props.onDataGridD0Saved && props.onDataGridD0Saved(operation);
                                        console.log('Saving')
                                        // savedDT01D0();
                                    }}
                                    onSaved={(e) => {
                                        console.log(e, "saved")
                                        // setIsSaved("SAVED")
                                        // operation = "SAVED-D0"
                                        // props.onDataGridD0Saved && props.onDataGridD0Saved(operation);


                                        savedDT01D0();
                                    }}
                                    // selected01D0RowKeys={selected01D0RowKeys}
                                    // onSelectedRowKeysChange={setSelected01D0RowKeys}
                                    onEditCanceling={() => console.log('EditCanceling')}
                                    onEditCanceled={() => console.log('EditCanceled')}
                                    onSelectionChanged={(params) => {
                                        const tempIndexs: number[] = []
                                        if (props.dataGridRef01D0.current) {
                                            props.dataGridRef01D0.current.instance.getSelectedRowKeys()
                                                .then(keys => {
                                                    setSelected01D0RowKeys(keys)
                                                    keys.forEach(key => {

                                                        tempIndexs.push(props.dataGridRef01D0.current!.instance.getRowIndexByKey(key))
                                                    })
                                                });


                                        }

                                    }}
                                    onContextMenuPreparing={handleContextMenuPreparing}
                                >
                                    <HeaderFilter visible={true} allowSearch={true}  >

                                    </HeaderFilter>
                                    <FilterPanel visible={isHiddenAdvantageFilter} />
                                    <FilterBuilderPopup title={"Tạo bộ lọc cho cột"} position={filterBuilderPopupPosition} />

                                    <ColumnChooser enabled={isHiddenColumnChooser} emptyPanelText={"Kéo và thả cột muốn ẩn vào đây"} title={"Chọn cột ẩn"} />
                                    <ColumnFixing enabled={true} />
                                    {
                                        columns.map((row, index) => {

                                            if (row.data_type === "number") {
                                                return <Column
                                                    key={index}
                                                    caption={row.name_vn}
                                                    dataField={row.column_name ? row.column_name : ""}
                                                    dataType={row.data_type as DataType}
                                                    // format={(value: any) => formatDataType(row.format_type, value)}
                                                    width={row.width ? row.width : 150}
                                                    editCellComponent={(props) => {

                                                        return <NumberBox
                                                            format="#,###"
                                                            style={{ textAlign: "right" }}
                                                            onValueChange={(value) => props.data.setValue(value)}
                                                            value={props.data.value} />


                                                    }}

                                                >
                                                    {row.column_name === 'mA_PHI' && (
                                                        <Lookup
                                                            dataSource={chargeOptions}
                                                            displayExpr="ten_phi"
                                                            valueExpr="ma_phi"
                                                        />
                                                    )}

                                                    {
                                                        row?.format_type && <Format
                                                            type={row.format_type}
                                                            precision={0}
                                                        />
                                                    }

                                                    {
                                                        row.requireinput &&  <RequiredRule message={row.requireinputtext} />
                                                    }
                                                </Column>
                                            }

                                            else return <Column
                                                key={index}
                                                caption={row.name_vn}
                                                dataField={row.column_name ? row.column_name : ""}
                                                dataType={row.data_type as DataType}
                                                // format={(value: any) => formatDataType(row.format_type, value)}
                                                width={row.width ? row.width : 150}


                                            >
                                                {row.column_name === 'mA_PHI' && (
                                                    <Lookup
                                                        dataSource={chargeOptions}
                                                        displayExpr="ten_phi"
                                                        valueExpr="ma_phi"
                                                    />
                                                )}

                                                {
                                                    row?.format_type && <Format
                                                        type={row.format_type}
                                                        precision={0}
                                                    />
                                                }

{
                                                        row.requireinput &&  <RequiredRule message={row.requireinputtext} />
                                                    }
                                            </Column>
                                        })
                                    }
                                    <Selection
                                        mode="multiple"
                                        selectAllMode={allMode}
                                        showCheckBoxesMode={checkBoxesMode}
                                        deferred={true}


                                    />
                                    <FilterRow visible={isHiddenFilter} />
                                    <SearchPanel visible={isHiddenSearch} placeholder={"Tìm ..."} />
                                    <Grouping contextMenuEnabled={true} allowCollapsing={true} />
                                    <GroupPanel visible={isHiddenGroup} emptyPanelText={"Kéo vào thả cột muốn group vào đây"} />
                                    {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}


                                    <Editing
                                        mode="cell"
                                        allowUpdating={props.action !== ACTIONS.VIEW}
                                        allowDeleting={false}
                                        allowAdding={false}
                                        startEditAction={"click"}
                                        // useIcons={true}
                                        // changes={changes}
                                        // onChangesChange={setChanges}
                                        editRowKey={editRowKey}
                                        onEditRowKeyChange={setEditRowKey}
                                        newRowPosition={"last"}
                                        confirmDelete={true}
                                        selectTextOnEditStart={true}

                                    // texts={{
                                    //     confirmDeleteMessage: "Bạn chắn chắn muốn xóa hàng này?",
                                    // }}
                                    >
                                        {/* <Texts confirmDeleteMessage="Bạn chắn chắn muốn xóa hàng này?" addRow={"Thêm dòng mới"}></Texts> */}
                                    </Editing>

                                    {/* <Paging defaultPageSize={1} /> */}

                                    <Summary>
                                        <TotalItem
                                            column="pS_CO_NT"
                                            summaryType="sum"
                                            valueFormat={"fixedPoint"}
                                            displayFormat={"Tổng: {0}"}

                                        />

                                    </Summary>

                                </DataGrid>


                            </Paper>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div style={{ overflowX: 'scroll', backgroundColor: "#fff" }} className='row no-gutters'>
                        {
                            props.action !== ACTIONS.VIEW && <Paper className='col-12 py-1 d-flex' style={{ gap: 0, backgroundColor: '#F6F7F9' }}>
                                {/* <Button
                                type='primary'
                                size='middle'
                                style={{ borderRadius: 5, display: 'flex', alignItems: 'center' }}
                                icon={<SaveFilled />}
                                onClick={HandleSave01D1}>Lưu</Button> */}
                                <Button variant='text' style={{
                                    padding: '0 0.75rem', color: '#1976D2', fontWeight: 600, fontSize: 14,
                                    textTransform: 'none',
                                    backgroundColor: 'transparent',
                                }} startIcon={<PlusOutlined style={{ color: '#1976D2', fontSize: 18, }} />} onClick={() => handleClickRecieptInvoiceAction({
                                    action: invoice_action[props.exchange]
                                })}>{"Chọn hóa đơn"}</Button>
                                <Button
                                    variant='text'
                                    style={{
                                        padding: '0 0.75rem', color: '#1976D2', fontWeight: 600, fontSize: 14,
                                        textTransform: 'none',
                                        backgroundColor: 'transparent',
                                    }}
                                    startIcon={<i className={"bi bi-trash3"} style={{ color: '#1976D2', fontSize: 18, }} />}
                                    onClick={removeRow01D1}
                                >Xóa</Button>

                            </Paper>
                        }
                        <div className="col-12">


                            <Receipt01D1
                                dataGridRef={dataGridRef01D1}
                                data={dataTable01D1}
                                action={props.action as ACTIONS}
                                exchange={props.exchange}
                                onRowUpdated={function (e: EventInfo<dxDataGrid<any, any>> & RowUpdatedInfo<any, any>): void {
                                    HandleSave01D1(e);
                                    // props.onDataGridD1Saved && props.onDataGridD1Saved("UPDATED-D1");
                                }}
                                selectedRowKeys={(selectedRowKeys) => {
                                    setSelected01D1RowKeys(selectedRowKeys);
                                }}
                                selectedRowIndexs={(selectedRowIndex) => {
                                }}
                                onRowUpdating={(e) => console.log(e, "updatinggggg")}
                                // onDataGridD1Saved={props.onDataGridD1Saved}
                                onRowRemoved={function (e: EventInfo<dxDataGrid<any, any>> & RowRemovedInfo<any, any>): void {
                                    HandleDelete01D1(e);
                                }} />
                            {/* <DHSGridEdit

                                onSelectRow={(row) => {
                                    setIdItemSelected(row);
                                }}
                                // onListSelectRow={handleListOnselectRow}
                                itemClicked={function (): void {
                                    throw new Error("Function not implemented.");
                                }}
                                table_name={'QLCCCaVch01D1'}
                                show_form_list={false}
                                data={dataTable01D1}
                                onListSelectRow={onListSelectRow}
                                onChangeData={(v: CaVch01D1[]) => {
                                    props.onChangeTT!(v);
                                    dataTemp = v;


                                }}
                                onError={(errors) => {
                                    setErrors(errors)
                                }}
                                action={props.action}
                                blurCurrencyInput={(e, column, row) => HandleSave01D1(e, column, row)}
                            /> */}
                        </div>
                    </div>
                </TabPanel>

            </div>


            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default ReceiptInvoiceList;


