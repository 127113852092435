import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    HomeClient,
    QLCCClient,
    HomePageClient,
    RoleRightWebModel,
    UserInfoGroupModel,
    UserInfo,
    UserInfoModel,
    SysActionType,

} from '../../../app/shared/service-proxies/api-shared';
import BASE_URL_API from '../../../app/shared/service-proxies/config';
import { useHistory, useLocation } from 'react-router-dom';
import DHSToolbar from '../../../components/DHS/DHS-Toolbar/DHSToolbar';
import { AppSession } from '../../shared/app-session/app-session';
import { IReducer } from '../../../common/Interfaces/Interfaces';
import { getRoles } from '../../../redux/actions/RoleActions';
import notification from '../../../common/notification/notification';
import BlockUI from '../../../components/Block-UI/Block-ui';
import DHSToolbarRole from '../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import { ACTIONS } from '../../../common/enums/DHSToolbarRoleAction';
import ResidentAccountAction from './action/ResidentAccountAction';
import DHSBreadcrumb from '../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import DHSGridView from '../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { DataGrid } from 'devextreme-react';
import { getDataRowSelected } from '../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../common/Interfaces/StatusMessage';
import { Notification } from '../../../components/layouts';



const convert = ["success", "error", "warning"];
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

const roleMenu: string = 'CC';
const ResidentAccount: React.FC<{}> = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<any>();
    // const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<UserInfo[]>([]);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const homePageClient = new HomePageClient(appSession, BASE_URL_API);
    // Table
    const dataGridRef = React.useRef<DataGrid>(null);
    const [actCx, setActCx] = useState<IAction>(defaultAction);
    // Tòa nhà
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    async function onloadDataSource() {
        try {
            setLoading(true);
            const response = await homePageClient.userInfoWebGet({
                ...new UserInfoModel(),
                action_type: "GET_BY_RESIDENT_GROUP",
                apartment_building_id: apartment_building_id,
                user_login: SessionLogin.userName,
                isadmin: SessionLogin.isadmin,
                issuperadmin: SessionLogin.issupperadmin,
            } as UserInfoModel);
            setDataTable(response)
        } catch (ex) { }
        finally {
            const loadingTime = setTimeout(() => {
                setLoading(false);
                clearTimeout(loadingTime)
            }, 2000)
        }
    }

    useEffect(() => {
    })
    useEffect(() => {
        onloadDataSource();
    }, [apartment_building_id])

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {
        const dataItem: any = await getDataRowSelected(dataGridRef)
        switch (action?.action_code?.toUpperCase()) {
            case ACTIONS.DELETE:
                if (dataItem) {
                    if (window.confirm("Bạn có chắc chắn muốn xóa?")) {
                        setLoading(true)
                        homePageClient.userInfoWebAction({
                            ... new UserInfoModel(),
                            action_type: "DELETE",
                            username: dataItem?.username
                        } as UserInfoModel)
                            .then(res => {
                                notification(convert[res.status || 0], res.message);
                                setLoading(false)
                                setReload(!reload);
                            })
                            .catch(err => {
                                setLoading(false)
                                setReload(!reload);
                            })
                    }
                } else {
                    Notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE['WARNING_SELECT_ROW'])
                }
                break;
            case ACTIONS.ADD:
            case ACTIONS.EDIT:
            case ACTIONS.VIEW:
                if (dataItem) {
                    setActCx({
                        ...actCx,
                        open: true,
                        type: action?.action_code?.toUpperCase() === ACTIONS.VIEW ? "DETAIL" : action?.action_code?.toUpperCase(),
                        payload: dataItem
                    });
                }
                else {
                    Notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE['WARNING_SELECT_ROW'])
                }
                break;
        }
    }

    return (
        <div className='container-fluid'>
            {
                actCx.open && <ResidentAccountAction
                    open={actCx.open}
                    onClose={function (): void {
                        setActCx(defaultAction);
                    }}
                    action={actCx.type}
                    itemSelected={actCx.payload}
                    onEditSuccess={function (is_success: boolean): void {
                        onloadDataSource();
                    }} >

                </ResidentAccountAction>
            }
            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb location={location} />
            </div>
            <DHSToolbarRole id={' '} menu={menu} onClickAction={handleClickAction} />
            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                keyExpr="username"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
            />
            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default ResidentAccount;


