import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,

    Divider,
    DialogTitle,
    Autocomplete,
    FormControlLabel,
    Switch,
    Select,
    MenuItem,
    DialogContentText,
} from "@mui/material";
import CurrencyTextField from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import CloseIcon from "@mui/icons-material/Close";

import {
    HomeClient,
    QLCCServiceModel,
    QLCCClient,
    RoleRightWebModel,
    QLCCApartmentBuildingModel,
    QLCCApartmentTypesModel,
    LstUnitModel,
    QLCCApartmentModel,
    SysActionType,
} from "../../../shared/service-proxies/api-shared";


import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../ApartmentBuilding.css";
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import notification from "../../../../common/notification/notification";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { checkRequiredField } from "../../../../components/utils/object.util";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import DHSAutoCompleteWithDataGrid from "../../../../components/DHS/DHS_AutoCompleteCustomer/DHSAutoCompleteWithDataGrid";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";
import { httpPost } from "../../../../common/httpService";
import DHSAutocompleteFilterListSelect from "../../../../components/DHSUtilComponent/DHSAutocompleteFilterListSelect/DHSAutocompleteFilterListSelect";
interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (id?: string, dataEdited?: QLCCServiceModel) => void;
    onAddSuccess: (id?: string, dataAdded?: QLCCServiceModel) => void;
}
// interface IOpenCamera {
//     pic: any;
//     open: boolean;
// }

// const videoConstraints = {
//     width: 1280,
//     height: 720,
//     facingMode: "user",
// };

// const image_size_citizen_identification_card = {
//     width: 540,
//     height: 360,
// };

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});



const status_message = ['success', 'error']
var appSession: AppSession;
var htmlString: string;
const ServicesAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    // truyền id dự án
    const [data, setData] = React.useState<QLCCServiceModel>({} as QLCCServiceModel);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const [isDefaultPre, setIsDefaultPre] = React.useState<boolean>();

    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [totalApartmentMessage, setTotalApartmentMessage] = React.useState<JSX.Element>();
    const [actionUpdateService, setActionUpdateService] = React.useState<string>();

    const [apartmentBuildingOption, setApartmentBuildingOption] = React.useState<any>([]);

    const [serviceStatusOptions, setServiceStatusOptions] = React.useState<any>([
        {
            id: '1',
            name: 'Đang áp dụng',
        },
        {
            id: '2',
            name: 'Không áp dụng',
        },
    ]);
    const [apartmentTypeOptions, setApartmentTypeOptions] = React.useState<any>(
        []
    );
    const [unitOptions, setUnitOptions] = React.useState<any>([]);
    // const [windowDirectionOptions, setWindowDirectionOptions] =
    //     React.useState<any>([]);
    // const [elevatorLocationOptions, setElevatorLocationOptions] =
    //     React.useState<any>([]);
    // const [emergencyExitLocationOptions, setEmergencyExitLocationOptions] =
    //     React.useState<any>([]);


    React.useEffect(() => {

        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            qLCClient
                .qlccServicesGet({
                    ...new QLCCServiceModel(),
                    id: props.itemSelected.service_id,
                    ma_cty: props.itemSelected?.ma_cty,
                    apartment_building_id: props?.itemSelected?.apartment_building_id,
                    action_type: "GET"
                } as QLCCServiceModel)
                .then((res) => {
                    setData(res[0]);

                    setIsDefaultPre(res[0]?.is_default);

                })
                .catch(() => alert("Không lấy được dữ liệu"));
            setId(props.itemSelected);

        } else {

            setData({
                ...new QLCCServiceModel(),
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: props?.itemSelected?.apartment_building_id
            } as QLCCServiceModel);
            setId(undefined)
        };
    }, [props.action]);


    React.useEffect(() => {

        setLoading(true);
        qLCClient
            .qlccApartmentBuildingGet({
                ...new QLCCApartmentBuildingModel(),
                ma_cty: SessionLogin.ma_cty,
                action_type: 'GET'
            } as QLCCApartmentBuildingModel)
            .then(res => {
                setApartmentBuildingOption(res);
            })
        setLoading(true);

        qLCClient
            .qLCCApartmentTypesActions({
                ...new QLCCApartmentTypesModel(),
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: props?.itemSelected?.apartment_building_id,
                action_type: 'GET'
            } as QLCCApartmentTypesModel)
            .then(res => {
                setApartmentTypeOptions(res)
                setLoading(false);
            });
        setLoading(true);

        qLCClient.qlccLstUnitGet({
            ...new LstUnitModel(),
            ma_cty: SessionLogin.ma_cty,
        } as LstUnitModel)
            .then(res => {
                setLoading(false);
                setUnitOptions(res)
            })
    }, []);

    const handleSubmit = (action?: string) => {

        if (_id || props.action === ACTIONS.EDIT) {
            setLoading(true)

            //Lỗi trên IOS
            qLCClient.qlccServicesAction({
                ...new QLCCServiceModel(),
                ...data,
                service_description: htmlString,
                action_type: action ?? actionUpdateService
            } as QLCCServiceModel)
                .then((response) => {
                    setLoading(false);
                    notification(status_message[response.status!], response.message);
                    if (response.status === 0) {
                        props.onEditSuccess(response.id, data);
                        props.onClose();
                    }

                });

        }
        else {
            setLoading(true)

            qLCClient.qlccServicesAction({
                ...new QLCCServiceModel(),
                ...data,
                ma_cty: SessionLogin?.ma_cty,
                service_description: htmlString,
                action_type: 'INSERT'
            } as QLCCServiceModel)
                .then((response) => {
                    setLoading(false);
                    notification(status_message[response.status!], response.message)

                    if (response.status === 0) {
                        props.onAddSuccess(response.id, data);
                        props.onClose();
                    }
                });
        }

    };

    const handleChangeInput = (e: any) => {
        const { name, value, checked, type } = e.target;

        if (type === 'checkbox') {
            setData({ ...data, [name]: checked } as QLCCServiceModel);

        }
        else setData({ ...data, [name]: value } as QLCCServiceModel);
    };

    const handleChangeInputSelect = (e: any) => {
        const { name, value } = e.target;
        setData({ ...data, unit: value } as QLCCServiceModel);
    }

    const handleChangeMultipleInput = (e: any) => {
        if (props.action !== ACTIONS.VIEW) {
            const { name, value } = e.target;
            if (name === 'apartment_type_id') {
                const valueSplit = value.map((value: QLCCApartmentTypesModel) => value.id).join(";")
                setData({ ...data, [name]: valueSplit } as QLCCServiceModel);
            }
            else {
                const valueSplit = value.map((value: QLCCApartmentBuildingModel) => value.id).join(";")
                setData({ ...data, [name]: valueSplit } as QLCCServiceModel);
            }
        }
    }

    const handleChangeRichText = (stringData: string) => {
        // setData({
        //     ...data,
        //     service_description: stringData,
        // } as QLCCServiceModel);
        htmlString = stringData;
    };


    const handleChangeCurrentInput = (
        value: string | undefined, name?: string | undefined, values?: CurrencyInputOnChangeValues | undefined) => {

        setData({ ...data, [name!]: value } as QLCCServiceModel);
    }

    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = () => {


        if (isDefaultPre === true && data?.is_default === false) {

            qLCClient.qLCCApartmentsGet({
                ...new QLCCApartmentModel(),
                ma_cty: SessionLogin?.ma_cty,
                service_id: data?.id,
                apartment_building_id: data?.apartment_building_code,
                apartment_type_id: data?.apartment_type_id,
                action_type: 'GET_TOTAL_SERVICE_APARTMENT_BY_SERVICEID'
            } as QLCCApartmentModel)
                .then(res => {
                    setLoading(false);
                    const totalApartmentMessage = (
                        <div>
                            Có tổng <span style={{ fontWeight: 700, fontSize: "16px", color: 'red' }}>
                                {Number.parseInt(res[0].message!)}
                            </span> căn hộ đang sử dụng phí này. Bạn chăc chắn muốn xóa mặt định?
                        </div>
                    );
                    setActionUpdateService("UPDATE")

                    setTotalApartmentMessage(totalApartmentMessage)
                });
        }
        else {

            setTotalApartmentMessage(<>Bạn chắc chắn muốn lưu?</>);
            setActionUpdateService("UPDATE-BUT-NOT-DELETE")

        }

        setOpen(true)
    }

    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                // handleSubmit();  
                if (checkRequiredField(data, ["service_name", "service_prices"])) {
                    handleOpen()
                } else {
                    notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["FIELD_REQUIRED"])
                }
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
        }
    }

    async function onloadServiceGetCode() {
        httpPost<string>('/api/Common/LoadDatasourceBindControlByApartmentBuilding', {
            ma_cty: SessionLogin?.ma_cty,
            tablelist: 'lstServiceCodeGet',
            apartment_building_id: props?.itemSelected?.apartment_building_id
        })
            .then(res => {
                console.log(JSON.parse(res.data))
                const serviceGroups: any = JSON.parse(res.data)
                // setDataSource(serviceGroups)

            }).catch((ex) => {
                console.log(ex)
            });
    }

    return (
        <Dialog
            id="sysMenu"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "flex-end",
            }}
            TransitionComponent={Transition}
            maxWidth="xs"
        >
            {/* <Dialog
                open={openCamera.open}
                onClose={handleCloseCamera}
                sx={{ height: "inherit" }}
            >
                
            </Dialog> */}

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === ACTIONS.EDIT || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <div className="row">
                    <div className="col-xl-6">
                        {/* Tên dịch vụ */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                required
                                sx={{ fontSize: 14, color: "#333", fontWeight: "500" }}
                            >
                                Tên dịch vụ
                            </InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                autoFocus
                                placeholder="Tên dịch vụ ..."
                                name="service_name"
                                onChange={handleChangeInput}
                                value={data?.service_name ?? null}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box>



                        {/* Giá dịch vụ */}

                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Chi phí</InputLabel>
                            <CurrencyTextField
                                className="flex-grow-1 form-control currency-input"
                                style={{ width: "100%", textAlign: "right" }}
                                allowDecimals
                                decimalsLimit={2}
                                decimalSeparator="."
                                groupSeparator=","
                                decimalScale={0}
                                // placeholder="Chi phí"
                                intlConfig={{ locale: 'vi-VN', currency: 'VND' }}
                                name="service_prices"
                                onValueChange={handleChangeCurrentInput}
                                value={data?.service_prices ?? undefined}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box>


                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Code*</InputLabel>
                            <DHSAutocompleteFilterListSelect
                                table_list="lstServiceCodeGet"
                                value={data?.service_code}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "service_code",
                                            value: newValue?.service_code ? newValue.service_code : null,
                                        },
                                    });

                                }}

                                readOnly={props.action === ACTIONS.VIEW}
                            />
                        </Box>


                    </div>
                    {/* chỗ này cắt div*/}
                    <div className="col-xl-6">
                        {/* Người sử dụng */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", fontWeight: "500" }}
                            >
                                Người sử dụng
                            </InputLabel>

                            <Autocomplete
                                id="main-direction-select"
                                sx={{ width: "100%" }}
                                options={mainDirectionOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.apartment_id
                                        ? mainDirectionOptions.find(
                                            (item: any) => item.id === data.apartment_id
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "apartment_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password",
                                            // type: "search" // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box> */}

                        {/*Đơn vị tính */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", fontWeight: "500" }}
                            >
                                Đơn vị tính
                            </InputLabel>
                            <Select
                                style={{ width: "100%", }}
                                value={data?.unit || ""}
                                name="unit"
                                onChange={handleChangeInputSelect}
                            >
                                {
                                    unitOptions
                                    && unitOptions.map((e: LstUnitModel) => (
                                        <MenuItem key={e.dvt_id} value={e.dvt_id}>{e.dvt_name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </Box>
                        {/* Sự dụng cho dự án */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333" }}
                            >
                                Sử dụng cho dự án
                            </InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="apartment-select"
                                className="cc-input"
                                dataSource={apartmentBuildingOption}
                                displayMember="name"
                                valueMember="id"
                                value={data?.apartment_building_id}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeMultipleInput({
                                        target: {
                                            name: "apartment_building_id",
                                            value: newValue ?? null,
                                        },
                                    });
                                }}
                                disabled={props.action === ACTIONS.VIEW}
                                filterSelectedOptions={true}
                                multiple={true}
                            />
                            {/* <Autocomplete
                                id="apartment-select"
                                sx={{ width: "100%" }}
                            <DHSAutoCompleteWithDataSource
                               
                                multiple
                                filterSelectedOptions
                                dataSource={apartmentBuildingOption}
                                displayMember="name"
                                valueMember="id"
                                value={data?.apartment_building_code}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeMultipleInput({
                                        target: {
                                            name: "apartment_building_code",
                                            value: newValue ?? null,
                                        },
                                    });
                                }}

                                disabled={props.action === ACTIONS.VIEW}
                            /> */}
                        </Box>


                    </div>
                    <div className="col-xl-6">
                        {/* Trạng thái dịch vụ*/}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", fontWeight: "500" }}
                            >
                                Trạng thái dịch vụ
                            </InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="service-status-select"
                                className="cc-input"
                                dataSource={serviceStatusOptions}
                                displayMember="name"
                                valueMember="id"
                                value={data?.service_status}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "service_status",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                            {/* <Autocomplete
                                id="service-status-select"
                                sx={{ width: "100%" }}
                                options={serviceStatusOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.service_status
                                        ? serviceStatusOptions.find(
                                            (item: any) => item.id === data.service_status
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "service_status",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === ACTIONS.VIEW}
                            /> */}
                        </Box>
                    </div>

                    <div className="col-xl-6">
                        {/* Loại căn hộ */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", fontWeight: "500" }}
                            >
                                Loại căn hộ
                            </InputLabel>
                            <Autocomplete
                                id="apartment-select"
                                sx={{ width: "100%" }}
                                multiple
                                filterSelectedOptions
                                options={apartmentTypeOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.apartment_type_name}
                                value={
                                    data?.apartment_type_id
                                        ? apartmentTypeOptions.filter(
                                            (item: any) => {
                                                return data.apartment_type_id?.split(";").includes(item.id)
                                            }
                                        )
                                        : []
                                }
                                onChange={(e, newValue: any) => {


                                    handleChangeMultipleInput({
                                        target: {
                                            name: "apartment_type_id",
                                            value: newValue ?? null,
                                        },
                                    });
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password"// disable autocomplete and autofill
                                        }}
                                        placeholder="Áp dụng cho loại căn hộ ..."


                                    />
                                )}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box>


                    </div>
                    {/* 
                    <div className="col-xl-12 mt-3">
                        <Accordion expanded={isExpandApartmentTypeService} onChange={() => { setIsCalledApartmentTypeService(true); setIsExpandApartmentTypeService(pre => !pre) }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Dịch vụ loại căn hộ</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ApartmentTypeServiceList />
                            </AccordionDetails>
                        </Accordion>
                    </div> */}

                    <div className="col-xl-6">
                        {/* Mặt định */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>


                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mặc định</InputLabel>

                            <FormControlLabel control={
                                <Switch
                                    color="primary"
                                    onChange={handleChangeInput}
                                    name="is_default"
                                    value={data?.is_default}
                                    checked={data?.is_default}
                                    disabled={props.action === ACTIONS.VIEW}
                                />}
                                label={
                                    <Typography
                                        sx={{ fontSize: 14, color: '#333' }}>
                                        Không/Có
                                    </Typography>} />


                        </Box>
                    </div>


                    <div className="col-12">
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", fontWeight: "500" }}
                            >
                                Mô tả dịch vụ
                            </InputLabel>
                            <RichTextEditor
                                onChange={(stringData) => handleChangeRichText(stringData)}
                                // onChange={(newValue) => handleChangeInput({ target: { name: "service_description", value: stringData } })}
                                value={data?.service_description ?? ""}
                            />
                        </Box>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>

                <DHSDialogAction

                    action={props.action}
                    menu={menu}
                    onClickAction={handleClickAction}
                />


            </DialogActions>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
                maxWidth={"sm"}
                fullWidth
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>

                        <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogTitle id="draggable-dialog-title">
                    Xác nhận {props?.action === ACTIONS.EDIT ? "thay đổi" : "thêm"} [{data?.service_name ?? "Phí vận hành"}]
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {totalApartmentMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation sx={{ textTransform: "capitalize" }} autoFocus variant="contained" color="primary" onClick={() => handleSubmit()}>
                        Chập nhận
                    </Button>
                    {
                        actionUpdateService === "UPDATE" && <Button disableElevation variant="contained" sx={{ textTransform: "capitalize" }} color="error" onClick={() => {
                            handleSubmit("UPDATE-BUT-NOT-DELETE");
                        }}>Không</Button>
                    }
                    <Button disableElevation sx={{ textTransform: "capitalize" }} onClick={handleClose}>Hủy</Button>
                </DialogActions>
            </Dialog>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default ServicesAction;
