import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { ReportInfo, SYS_Token_AuthenticationModel } from "../../../app/shared/service-proxies/api-shared";
import moment from "moment";
import DataGrid from "devextreme-react/data-grid";



export const exportExcelReport = (dataGridRef: React.RefObject<DataGrid<any, any>>, reportInfo: ReportInfo,
    fromDate: Date,
    toDate: Date,
   ) => {
    const SessionLogin: SYS_Token_AuthenticationModel = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    if(dataGridRef.current) {
        const e = {
            cancel: true,
            component: dataGridRef.current.instance,
            element: dataGridRef.current.instance.element,
            format: "xlsx",
            selectedRowsOnly:true,
            // selectedRowsOnly:false,
            fileName: '"DataGrid"',
          }
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet(`${reportInfo?.title || 'sheet1'}`);

          console.log(dataGridRef.current.instance);
          
      
          exportDataGrid({
            component: e.component,
            worksheet,
            topLeftCell: { row: 6, column: 1 },
            autoFilterEnabled: true,
            customizeCell: ({ gridCell, excelCell }) => {
                // console.log(excelCell);
                if(excelCell._row._number % 2 === 0) {
                    excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'e9f3f7' } };
                }
              },

            
          }).then((cellRange: any) => {
            //company
            const companyName = worksheet.getRow(1);
            companyName.height = 15;
            worksheet.mergeCells(1, 1, 1, 3);
            companyName.getCell(1).font = { size: 13 };
            companyName.getCell(1).alignment = { horizontal: 'left' };
            companyName.getCell(1).value = SessionLogin.company_info?.ten_tcty || '';
      
            const companyAddress = worksheet.getRow(2);
            companyAddress.height = 15;
            worksheet.mergeCells(2, 1, 2, 3);
            companyAddress.getCell(2).font = { size: 10 };
            companyAddress.getCell(2).alignment = { horizontal: 'left' };
            companyAddress.getCell(2).value =  SessionLogin.company_info?.dia_chi || '';
      
            // header
            const headerRow = worksheet.getRow(3);
            headerRow.height = 30;
            worksheet.mergeCells(3, 1, 3, 9);
      
            headerRow.getCell(2).value = reportInfo?.title || '';
            headerRow.getCell(2).font = {  size: 22, bold: true };
            headerRow.getCell(2).alignment = { horizontal: 'center' };
      
            const headerRow2 = worksheet.getRow(4);
            headerRow2.height = 20;
            worksheet.mergeCells(4, 1, 4, 9);
            headerRow2.getCell(3).value = `Từ ngày ${moment(fromDate).format('DD/MM/YYYY')} đến ngày ${moment(toDate).format('DD/MM/YYYY')}`;
            headerRow2.getCell(3).font = { size: 10 };
            headerRow2.getCell(3).alignment = { horizontal: 'center' };
      
            // footer
            const footerRowIndex = cellRange.to.row + 2;
            const footerRow = worksheet.getRow(footerRowIndex);
            const footerRow2 = worksheet.getRow(footerRowIndex + 1);
            const footerRow3 = worksheet.getRow(footerRowIndex + 8);

            //1
            worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 3);
            footerRow.getCell(1).value = 'Người lập';
            footerRow.getCell(1).font = {bold: true, size: 13 };
            footerRow.getCell(1).alignment = { horizontal: 'center' };
            worksheet.mergeCells(footerRowIndex + 1, 1, footerRowIndex + 1, 3);
            footerRow2.getCell(1).value = '(Chữ ký/Họ tên)';
            footerRow2.getCell(1).font = {italic: true, size: 10 };
            footerRow2.getCell(1).alignment = { horizontal: 'center' };
            worksheet.mergeCells(footerRowIndex + 8, 1, footerRowIndex + 8, 3);
            footerRow3.getCell(1).value = SessionLogin.fullName  || '';
            footerRow3.getCell(1).font = {bold: true, size: 13 };
            footerRow3.getCell(1).alignment = { horizontal: 'center' };

            //2
            worksheet.mergeCells(footerRowIndex, 4, footerRowIndex, 6);
            footerRow.getCell(4).value = 'Kế toán trưởng';
            footerRow.getCell(4).font = {bold: true, size: 13 };
            footerRow.getCell(4).alignment = { horizontal: 'center' };
            worksheet.mergeCells(footerRowIndex + 1, 7, footerRowIndex + 1, 9);
            footerRow2.getCell(4).value = '(Chữ ký/Họ tên)';
            footerRow2.getCell(4).font = {italic: true, size: 10 };
            footerRow2.getCell(4).alignment = { horizontal: 'center' };
            worksheet.mergeCells(footerRowIndex + 8, 7, footerRowIndex + 8, 9);
            footerRow3.getCell(4).value = SessionLogin.company_info?.ktt || '';
            footerRow3.getCell(4).font = {bold: true, size: 13 };
            footerRow3.getCell(4).alignment = { horizontal: 'center' };

            //3
            worksheet.mergeCells(footerRowIndex, 7, footerRowIndex, 9);
            footerRow.getCell(7).value = 'Giám đốc';
            footerRow.getCell(7).font = {bold: true, size: 13};
            footerRow.getCell(7).alignment = { horizontal: 'center' };
            worksheet.mergeCells(footerRowIndex + 1, 4, footerRowIndex + 1, 6);
            footerRow2.getCell(7).value = '(Chữ ký/Họ tên)';
            footerRow2.getCell(7).font = {italic: true, size: 10 };
            footerRow2.getCell(7).alignment = { horizontal: 'center' };
            worksheet.mergeCells(footerRowIndex + 8, 4, footerRowIndex + 8, 6);
            footerRow3.getCell(7).value = SessionLogin.company_info?.giam_doc || '';
            footerRow3.getCell(7).font = {bold: true, size: 13 };
            footerRow3.getCell(7).alignment = { horizontal: 'center' };
      
      
            //pannal
            const pannal = worksheet.getRow(6);
            pannal.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '2590C3' },  };
            pannal.font = { color: { argb: 'FFFFFF' }, bold: true };
          }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${reportInfo?.title || 'sheet1'}.xlsx`);
            });
          });
          e.cancel = true;
    } else {

    }
    
  };