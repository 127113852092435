import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import CTXToolbar from "../../../../components/CTX/Toolbar";
import { Notification } from "../../../../components/layouts";
import DHSGridView from "../../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { getRoles } from "../../../../redux/actions/RoleActions";
import { AppSession } from "../../../shared/app-session/app-session";
import {
    HomeClient,
    QLCCCategoriesModel,
    QLCCClient,
    RoleRightWebModel,
    SysActionType,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import CategoriesActions from "../../actions/notification-category-action/CategoriesActions";
import { useHotkeys } from "react-hotkeys-hook";
import DHSBreadcrumb from "../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import dxDataGrid, {
    RowInsertedInfo,
    RowInsertingInfo,
    RowRemovedInfo,
    RowRemovingInfo,
    RowUpdatedInfo,
    RowUpdatingInfo,
    SelectionChangedInfo,
} from "devextreme/ui/data_grid";
import { EventInfo } from "devextreme/events";
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DataGrid from "devextreme-react/data-grid";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { getIndexRowSelected, getKeyRowSelected } from "../../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";




interface IAction {
    open: boolean;
    type: string | undefined;
    name: string | undefined;
    payload: string | undefined;
}
let appSession: AppSession;
const convert = ["success", "error", "warning"];
const menuRoleName = "CC";
const Categories = () => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const history = useHistory();
    const dispatch = useDispatch();
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [itemSelected, selectItem] = React.useState<string>();

    const [action, setAction] = React.useState<IAction>({
        open: false,
        type: "",
        name: "",
        payload: "",
    });
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
    const [isShowSort, setShowSort] = React.useState<boolean>(false);
    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<QLCCCategoriesModel[]>([] as QLCCCategoriesModel[]);
    const [reload, setReload] = React.useState(false);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)


    const customMenu = [
        {
            menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
            onClick: () => {
                setShowSearch(!isShowSearch);
            },
        },
        {
            menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
            onClick: () => {
                setShowGroup(!isShowGroup);
            },
        },
        {
            menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
            onClick: () => {
                setShowSort(!isShowSort);
            },
        },
        {
            menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
            onClick: () => {
                setShowFilter(!isShowFilter);
            },
        },
    ];

    useHotkeys<HTMLParagraphElement>('alt+1', () => setShowSearch(!isShowSearch));

    useHotkeys<HTMLParagraphElement>('alt+2', () => setShowGroup(!isShowGroup));

    useHotkeys<HTMLParagraphElement>('alt+3', () => setShowSort(!isShowSort));

    useHotkeys<HTMLParagraphElement>('alt+4', () => setShowFilter(!isShowFilter));

    React.useEffect(() => {
        // setLoading(true);


        // control add remove and edit
        // homeClient
        //     ?.roleRightWebGetAll({
        //         ...new RoleRightWebModel(),
        //         username: SessionLogin.userName,
        //         rolename: menuRoleName,
        //         menuid: menu?.menuid,
        //     } as RoleRightWebModel)
        //     .then((res) => {
        //         dispatch(getRoles(res));
        //         setRoles(res);
        //         setLoading(false);
        //     });
    }, [menu?.component]);

    async function OnLoadDataSource() {
        qLCCClient
            .qLCCCategoriesActions({
                ...new QLCCCategoriesModel(),
                action_type: "GET",
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCCategoriesModel)
            .then((res) => {

                let tempArr: any[] = [];
                res.forEach((item) => {
                    tempArr.push({
                        ...item,
                        // date_modified: moment(item.date_modified).format("DD/MM/YYYY"),
                        status_id: item.status_id == "1" ? "Công bố" : "Không công bố"
                    })
                })
                setDataTable(tempArr);
            })
            .finally(() => {
                setLoading(false);

            })
    }

    React.useEffect(() => {
        setLoading(true);
        OnLoadDataSource();
    }, [reload, apartment_building_id]);



    const handleSubmit = (data: any) => {
        setLoading(true)
        setAction({
            ...action,
            open: false,
            type: "",
            name: "",
            payload: ""
        });
    };


    const handleClickAction = async (action: SysActionType, nowTime: Date) => {
        console.log(selectedItemKeys)
        switch (action?.action_code?.toLocaleUpperCase()) {
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            if (window.confirm("Bạn có chắc muốn xóa")) {
                                setLoading(true)
                                qLCCClient
                                    .qLCCCategoriesActions({
                                        ...new QLCCCategoriesModel(),
                                        id: selectedItemKeys?.id,
                                        action_type: "DELETE",
                                    } as QLCCCategoriesModel)
                                    .then((response) => {
                                        setLoading(false);
                                        Notification(convert[0], response[0].message);
                                        setReload(!reload);
                                        selectItem("");
                                    })
                                    .catch((error) => {
                                        setLoading(false);
                                        Notification(convert[1], error[0].message);
                                        setReload(!reload);
                                    });
                            }
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.ADD:
                setAction({
                    ...action,
                    type: ACTIONS.ADD,
                    name: "",
                    open: true,
                } as IAction);
                break;
            case ACTIONS.EDIT:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setAction({
                                ...action,
                                type: ACTIONS.EDIT,
                                name: "",
                                open: true,
                                payload: selectedItemKeys?.id ?? null
                            });
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.VIEW:

                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setAction({
                                ...action,
                                type: ACTIONS.VIEW,
                                name: "",
                                open: true,
                                payload: selectedItemKeys?.id ?? null
                            });
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }

                break;
            case ACTIONS.PRINT:
                break;

            default:
                break;
        }
    };

    const location = useLocation();
    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>(-1);
    const [allowEdit, setAllowEdit] = React.useState<boolean>(true);
    const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>();

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    }

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                console.log(keys);

                const index = e.component.getRowIndexByKey(keys[0]);


                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
            });
        }
    };

    return (
        <div
            className="container-fluid p-2"
            style={{ maxHeight: "94vh !important", overflowY: "auto" }}
        >
            {React.useMemo(() => {
                return (
                    <CategoriesActions
                        open={action.open}
                        onClose={() => {
                            setAction({
                                open: false,
                                type: "",
                                name: "",
                                payload: "",
                            });
                        }}
                        action={action.type}
                        onSubmit={handleSubmit}
                        itemSelected={action.payload}
                        onEditSuccess={function (is_success: any): void {
                            setReload(pre => !pre)
                        }}
                    />
                );
            }, [action.open])}

            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location}
                />
            </div>
            <DHSToolbarRole
                id={" "}
                menu={menu}
                onClickAction={handleClickAction}
            />
            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                key="id"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={allowEdit}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handlePageChange={handlePageChange}
                handleSelectionChanged={handleSelectionChanged}
            />
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </div>
    );
};

export default Categories;
