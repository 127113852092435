import { AnyAction } from "redux";
import { LstBank, LstCurrency, LstUnitModel, PaymentTerm, QLCCApartmentBuildingModel, QLCCUtilityModel, SysExchange } from "../../../app/shared/service-proxies/api-shared";
import { PUSH_APARTMENT_BUILDING_OPTIONS, PUSH_BLANK_OPTIONS, PUSH_CURRENCY_OPTIONS, PUSH_CUSTOMER_OPTIONS, PUSH_EXCHANGE_OPTIONS, PUSH_PAYMENT_TERM_OPTIONS, PUSH_UNIT_OPTIONS, PUSH_UTILITY_OPTIONS } from "../../constants/ActionTypes";
import { ICustomer } from "../../../common/Interfaces/Interfaces";

export interface IOption {
    paymentTermOptions: PaymentTerm[],
    bankOptions: LstBank[],
    customerOptions: ICustomer[],
    exchangeOptions: SysExchange[],
    currencyOptions: LstCurrency[],
    unitOptions: LstUnitModel[],
    apartmentBuildingOptions: QLCCApartmentBuildingModel[],
    utilityOptions: QLCCUtilityModel[]

}

const initialState: IOption = {
    paymentTermOptions: [],
    bankOptions: [],
    customerOptions: [],
    exchangeOptions: [],
    currencyOptions: [],
    unitOptions: [],
    apartmentBuildingOptions: [],
    utilityOptions: []
}


var OptionsReducer = (state = initialState, action: AnyAction) => {
    console.log(action.type, "type")
    switch (action.type) {
        case PUSH_PAYMENT_TERM_OPTIONS:

            return {
                ...state,
                paymentTermOptions: action.payload
            } as IOption
        case PUSH_BLANK_OPTIONS:
            return {
                ...state,
                bankOptions: action.payload
            } as IOption
        case PUSH_CUSTOMER_OPTIONS:

            return {
                ...state,
                customerOptions: action.payload
            } as IOption
        case PUSH_EXCHANGE_OPTIONS:

            return {
                ...state,
                exchangeOptions: action.payload
            } as IOption
        case PUSH_CURRENCY_OPTIONS:

            return {
                ...state,
                currencyOptions: action.payload
            } as IOption
        case PUSH_UNIT_OPTIONS:

            return {
                ...state,
                unitOptions: action.payload
            } as IOption
        case PUSH_UTILITY_OPTIONS:

            return {
                ...state,
                utilityOptions: action.payload
            } as IOption
        case PUSH_APARTMENT_BUILDING_OPTIONS:

            return {
                ...state,
                apartmentBuildingOptions: action.payload
            } as IOption
        default: return state;
    }
}

export default OptionsReducer;
