import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import notification from "../../../common/notification/notification";
import BlockUI from "../../../components/Block-UI/Block-ui";
import CTXToolbar from "../../../components/CTX/Toolbar";
import DHSGridView from "../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { getRoles } from "../../../redux/actions/RoleActions";
import { AppSession } from "../../shared/app-session/app-session";
import {
    HomeClient,
    QLCCApartmentBuildingModel,
    QLCCClient,
    QLCCInvestorModel,
    RoleRightWebModel,
    SysActionType,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import ApartmentBuildingActions from "../actions/apartment-building-action/ApartmentBuildingActions";
import { useHotkeys } from "react-hotkeys-hook";
import DHSBreadcrumb from "../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import { EventInfo } from "devextreme/events";
import dxDataGrid, { SelectionChangedInfo } from "devextreme/ui/data_grid";
import DataGrid from "devextreme-react/data-grid";
import DHSToolbarRole from "../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { ACTIONS } from "../../../common/enums/DHSToolbarRoleAction";
import { Notification } from "../../../components/layouts";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../common/Interfaces/StatusMessage";
import { getIndexRowSelected, getKeyRowSelected } from "../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import UserHelper from "../../../helpers/user-helper/user-helper";
import { httpPost } from "../../../common/httpService";

interface IParams {
    action_type?: string | undefined
}

export interface IInfoDashboardApartmentBuilding {
    aparment: number,
    apartmentTypeUse: number,
    apartmentTypeNotUse: number,
    apartmentCondition: number, // Số lượng căn hộ thuê
}

export interface IIDApartment {
    id: string | undefined,
    floor_id: string | undefined
}

export interface IDataFilter_02023 {
    apartment_building_id?: string | undefined,
    block_id?: string | undefined,
}


interface IAction {
    open: boolean;
    type: string | undefined;
    payload: string | undefined;
}

let appSession: AppSession;
const convert = ["success", "error", "warning"];
const menuRoleName = "CC";
const ApartmentBuilding = () => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [itemSelected, selectItem] = React.useState<string>();
    const [actAb, setActAb] = React.useState<IAction>({
        open: false,
        type: "",
        payload: ""
    });
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
    const [isShowSort, setShowSort] = React.useState<boolean>(false);
    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<QLCCApartmentBuildingModel[]>([]);
    const [investorList, setInvestorList] = React.useState<QLCCInvestorModel[]>([]);

    const [reload, setReload] = React.useState(false);

    // 

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>();
    const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>(-1);
    //  

    const customMenu = [
        {
            menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
            onClick: () => {
                setShowSearch(!isShowSearch);
            },
        },
        {
            menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
            onClick: () => {
                setShowGroup(!isShowGroup);
            },
        },
        {
            menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
            onClick: () => {
                setShowSort(!isShowSort);
            },
        },
        {
            menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
            onClick: () => {
                setShowFilter(!isShowFilter);
            },
        },
    ];

    useHotkeys<HTMLParagraphElement>('alt+1', () => setShowSearch(!isShowSearch));

    useHotkeys<HTMLParagraphElement>('alt+2', () => setShowGroup(!isShowGroup));

    useHotkeys<HTMLParagraphElement>('alt+3', () => setShowSort(!isShowSort));

    useHotkeys<HTMLParagraphElement>('alt+4', () => setShowFilter(!isShowFilter));

    const loadApartmentBuildings = (investor?: QLCCInvestorModel[]) => {
        CALL_API_LOAD_APARTMENT_BUILDING_LIST().then((res) => {
            setDataTable(res);
            setLoading(false);
        });
    }

    React.useEffect(() => {
        loadApartmentBuildings();
    }, [reload]);

    const handleClickAction = async (action: SysActionType) => {
        switch (action?.action_code?.toUpperCase()) {
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            if (window.confirm("Bạn có chắc muốn xóa")) {
                                setLoading(true);
                                qLCCClient
                                    .qlccApartmentBuildingDel({
                                        ...new QLCCApartmentBuildingModel(),
                                        id: itemSelected,
                                    } as QLCCApartmentBuildingModel)
                                    .then((response: any) => {
                                        setLoading(false);
                                        selectItem("")
                                        notification(convert[response.status], response.message);
                                        setReload(!reload);
                                    })
                                    .catch((error) => {
                                        setLoading(false);
                                        notification(convert[1], error.message)
                                        setReload(!reload);
                                    })
                            }
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }

                break;
            case ACTIONS.ADD:
                setActAb({
                    ...action,
                    type: action.action_code?.toUpperCase(),
                    open: true,
                    payload: selectedItemKeys ?? null
                });
                break;
            case ACTIONS.EDIT:
            case ACTIONS.VIEW:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setActAb({
                                ...action,
                                type: action.action_code?.toUpperCase(),
                                open: true,
                                payload: selectedItemKeys ?? null
                            });
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
        };
    }

    const handleSubmit = (data: any) => {
        setLoading(true)
        setActAb({
            ...actAb,
            open: false,
            type: "",
            payload: ""
        });
    };

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                console.log(keys);

                const index = e.component.getRowIndexByKey(keys[0]);

                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
                selectItem(keys[0]);
            });
        }
    };

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };
    return (
        <div
            className="container-fluid p-2"
            style={{ maxHeight: "94vh !important", overflowY: "auto" }}
        >
            {/* {React.useMemo(() => {
                return (
                    <ApartmentBuildingActions
                        open={actAb.open}
                        onClose={() => {
                            setActAb({
                                ...actAb,
                                open: false,
                                type: "",
                                payload: ""
                            });
                        }}
                        onEditSuccess={function (is_success: any): void {
                            setReload(pre => !pre)
                        }}
                        action={actAb.type}
                        itemSelected={actAb.payload}
                        onSubmit={handleSubmit}
                    />
                );
            }, [actAb.open, setReload])} */}
            <ApartmentBuildingActions
                open={actAb.open}
                onClose={() => {
                    setActAb({
                        ...actAb,
                        open: false,
                        type: "",
                        payload: ""
                    });
                }}
                onEditSuccess={function (is_success: any): void {
                    setReload(pre => !pre)
                }}
                action={actAb.type}
                itemSelected={actAb.payload}
                onSubmit={handleSubmit}
            />
            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location}
                />
            </div>
            <DHSToolbarRole
                id={" "}
                menu={menu}
                customMenu={[]}
                onClickAction={handleClickAction}
            />

            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                keyExpr="id"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handleSelectionChanged={handleSelectionChanged}
                handlePageChange={handlePageChange}

            // handleExporting={handleExporting}
            />
            {/* <DHSGridView
                onSelectRow={(row) => {
                    selectItem(row);
                }}
                defaultPageSize={100}
                // onListSelectRow={handleListOnselectRow}
                itemClicked={function (): void {
                    throw new Error("Function not implemented.");
                }}
                table_name={menu?.component ? menu?.component : ""}
                show_form_list={true}
                showSelectBox={false}
                showSortPanel={isShowSort}
                showSearchPanel={isShowSearch}
                showGroupPanel={isShowGroup}
                showFilterPanel={isShowFilter}
                data={dataTable}
            /> */}

            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </div>
    );
};

export default ApartmentBuilding;

export async function CALL_API_LOAD_APARTMENT_BUILDING_LIST(params?: IParams) {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const userInfo = await UserHelper.getUserInfo()
    // console.log(userInfo)

    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const res = await qLCCClient.qlccApartmentBuildingGet({
        ...new QLCCApartmentBuildingModel(),
        ma_cty: SessionLogin.ma_cty,
        action_type: params && params.action_type ? params.action_type : "GET",
        isadmin: userInfo?.isadmin,
        issuperadmin: userInfo?.issupperadmin,
        user_login: userInfo?.userName
    } as QLCCApartmentBuildingModel);

    // console.log({
    //     title: 'CALL_API_LOAD_APARTMENT_BUILDING_LIST',
    //     ma_cty: SessionLogin.ma_cty,
    //     action_type: params && params.action_type ? params.action_type : "GET",
    //     isadmin: userInfo?.isadmin,
    //     issuperadmin: userInfo?.issupperadmin,
    //     user_login: userInfo?.userName,
    //     dataSource: res
    // })
    return res;
}

export async function CALL_API_LOAD_INFO_DASHBOARD_APARTMENT_BUILDING(apartment_building_id: string) {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
 
    const res = await httpPost<string>('/api/Common/LoadDatasourceBindControlByApartmentBuilding', {
        ma_cty: SessionLogin?.ma_cty,
        tablelist: 'dashboardApartmentBuildingCount',
        apartment_building_id: apartment_building_id
    })

    const partRes: any = JSON.parse(res.data)

    try {
     
        const obj = {
            aparment: partRes?.Table1[0]?.aparment,
            apartmentTypeUse: partRes?.Table1[0]?.apartmentTypeUse,
            apartmentTypeNotUse: partRes?.Table1[0]?.apartmentTypeNotUse,
            apartmentCondition: partRes?.Table1[0]?.apartmentCondition,
        } as IInfoDashboardApartmentBuilding
        console.log(obj)
        return obj;
    } catch (ex) {
        console.warn(ex)
        return {
            aparment: 0,
            apartmentTypeUse: 0,
            apartmentTypeNotUse: 0,
            apartmentCondition: 0,
        } as IInfoDashboardApartmentBuilding
    }
}


export async function CALL_API_LOAD_ID_APARTMENT(apartment_building_id: string) {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const res1 = await httpPost<string>('/api/Common/LoadDatasourceBindControlByApartmentBuilding', {
        ma_cty: SessionLogin?.ma_cty,
        tablelist: 'lstIDApartment',
        apartment_building_id: apartment_building_id
    })

    const partRes1: any = JSON.parse(res1.data)
    try {
        return partRes1.Table1 as IIDApartment[]
    } catch (ex) {
        console.log(ex)
        return [] as IIDApartment[];
    }
}