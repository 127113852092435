import { Grid } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SysMenu } from "../../../app/shared/service-proxies/api-shared";
import PortalTimeKeepingGrid from "../../../app/website/PortalTimeKeeping/PortalTimeKeepingGrid";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import { getMenu, getMenuShowGrid } from "../../../redux/actions/RoleActions";
import "./index.css";
import { pushPathLevel2 } from "../../../redux/actions/PathAction";
const Role = () => {
  const dispatch = useDispatch();
  const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
  const listMenu = useSelector(
    (state: IReducer) => state.RoleReducer?.listMenu
  );
  const [menus, setMenu] = React.useState<SysMenu[]>([]);

  React.useEffect(() => {
    if (listMenu) {
      setMenu(
        listMenu?.filter(
          (menu) =>
            menu.menuid !== menuid &&
            menu.menuid?.slice(0, 2) === menuid?.slice(0, 2) &&
            menu.menuid?.endsWith(".00")
        )
      );
    }
  }, [listMenu]);
  
  return menuid !== "30.00.00" ? (
    <tr className="container-fluid table row h-100" style={{ margin: 0 }}>
      {listMenu
        ?.filter(
          (menu) =>
            menu.menuid !== menuid &&
            menu.menuid?.slice(0, 2) === menuid?.slice(0, 2) &&
            menu.menuid?.endsWith(".00")
        )
        .map((item, index) => {
          return (
            <td className="col-xl-4" style={{ border: "none", padding: 10 }}>
              <div
                className="h-100"
                style={{
                  backgroundColor: "#FFF",
                  padding: 10,
                  overflowY: "auto",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#F0F8FF",
                    color: "#1890FF",
                    fontSize: 16,
                    padding: 3,
                    marginBottom: 5,
                    fontWeight: "bold",
                  }}
                >
                  {item.bar}
                </div>
                {listMenu
                  .filter(
                    (menuChild) =>
                      menuChild.menuid !== item.menuid &&
                      menuChild.menuid?.slice(0, 5) === item.menuid?.slice(0, 5)
                  )
                  .map((itemChild, index) => {
                    return (
                      <Link
                        to={`${
                          itemChild.link_web
                            ? itemChild.link_web
                            : `/admin/notfound`
                        }`}
                        key={itemChild.menuid}
                        id={itemChild.menuid}
                        style={{ textDecoration: "none",  }}
                      >
                        <div
                          className={
                            index % 2 === 0
                              ? "first-background-color"
                              : "second-background-color"
                          }
                          style={{
                            color: "white",
                            minHeight: 45,
                            height: 50,
                            padding: "7px",
                            margin: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'end',
                            position: 'relative',
                            borderRadius: 5
                          }}
                          onClick={() => {
                            dispatch(getMenuShowGrid(itemChild));
                            dispatch(pushPathLevel2({
                              menuid_level_2: itemChild?.menuid,
                              url_level_2: itemChild?.link_web,
                              bar_level_2: itemChild?.bar
                            } as any))
                          }}
                        >
                          <div className="position-absolute" style={{top: 3, right: 5}}>
                            <img src={`/icon/${itemChild.menuid}.png?v=${new Date().getTime()}`} alt="" style={{width: "28px"}} />
                          </div>
                          <div style={{fontSize : "16px"}}>{itemChild.bar}</div>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            </td>
          );
        })}
    </tr>
  ) : (
    <tr className="container-fluid table row h-100" style={{ margin: 0 }}>
      <td className="col-xl-8" style={{ border: "none", padding: 10 }}>
        <div
          className="h-100"
          style={{
            backgroundColor: "#FFF",
            padding: 10,
            overflowY: "auto",
          }}
        >
          <div
            style={{
              backgroundColor: "#F0F8FF",
              color: "#1890FF",
              fontSize: 16,
              padding: 3,
              marginBottom: 5,
              fontWeight: "bold",
            }}
          >
            {menus[0]?.bar}
          </div>
          <div className="row m-0">
            {listMenu &&
              listMenu
                .filter(
                  (menuChild) =>
                    menuChild.menuid !== menus[0]?.menuid &&
                    menuChild.menuid?.slice(0, 5) ===
                      menus[0]?.menuid?.slice(0, 5)
                )
                .map((itemChild, index) => {
                  return (
                    <Link
                      className="col-6 p-0"
                      to={`${
                        itemChild.link_web
                          ? itemChild.link_web
                          : `/admin/notfound`
                      }`}
                      key={itemChild.menuid}
                      id={itemChild.menuid}
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className={
                          index % 4 === 0 || (index + 1) % 4 === 0
                            ? "first-background-color"
                            : "second-background-color"
                        }
                        style={{
                          color: "white",
                          minHeight: 45,
                          height: 50,
                          padding: 3,
                          margin: 1,
                        }}
                        onClick={() => {
                          dispatch(getMenuShowGrid(itemChild));
                        }}
                      >
                        {itemChild.bar}
                      </div>
                    </Link>
                  );
                })}
          </div>
        </div>
      </td>
      <td className="col-xl-4" style={{ border: "none", padding: 10 }}>
        <div
          className="h-100"
          style={{
            backgroundColor: "#FFF",
            padding: 10,
            overflowY: "auto",
          }}
        >
          <div
            style={{
              backgroundColor: "#F0F8FF",
              color: "#1890FF",
              fontSize: 16,
              padding: 3,
              marginBottom: 5,
              fontWeight: "bold",
            }}
          >
            {menus[2]?.bar}
          </div>
          {listMenu &&
            listMenu
              .filter(
                (menuChild) =>
                  menuChild.menuid !== menus[2]?.menuid &&
                  menuChild.menuid?.slice(0, 5) ===
                    menus[2]?.menuid?.slice(0, 5)
              )
              .map((itemChild, index) => {
                return (
                  <Link
                    to={`${
                      itemChild.link_web
                        ? itemChild.link_web
                        : `/admin/notfound`
                    }`}
                    key={itemChild.menuid}
                    id={itemChild.menuid}
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className={
                        index % 2 === 0
                          ? "first-background-color"
                          : "second-background-color"
                      }
                      style={{
                        color: "white",
                        minHeight: 45,
                        height: 50,
                        padding: 3,
                        margin: 1,
                      }}
                      onClick={() => {
                        dispatch(getMenuShowGrid(itemChild));
                      }}
                    >
                      {itemChild.bar}
                    </div>
                  </Link>
                );
              })}
        </div>
      </td>
      <td className="col-xl-8" style={{ border: "none", padding: 10 }}>
        <div
          className="h-100"
          style={{
            backgroundColor: "#FFF",
            padding: 10,
            overflowY: "auto",
          }}
        >
          <div
            style={{
              backgroundColor: "#F0F8FF",
              color: "#1890FF",
              fontSize: 16,
              padding: 3,
              marginBottom: 5,
              fontWeight: "bold",
            }}
          >
            {menus[1]?.bar}
          </div>
          <div className="row m-0">
            {listMenu &&
              listMenu
                .filter(
                  (menuChild) =>
                    menuChild.menuid !== menus[1]?.menuid &&
                    menuChild.menuid?.slice(0, 5) ===
                      menus[1]?.menuid?.slice(0, 5)
                )
                .map((itemChild, index) => {
                  return (
                    <Link
                      className="col-6 p-0"
                      to={`${
                        itemChild.link_web
                          ? itemChild.link_web
                          : `/admin/notfound`
                      }`}
                      key={itemChild.menuid}
                      id={itemChild.menuid}
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className={
                          index % 4 === 0 || (index + 1) % 4 === 0
                            ? "first-background-color"
                            : "second-background-color"
                        }
                        style={{
                          color: "white",
                          minHeight: 45,
                          height: 50,
                          padding: 3,
                          margin: 1,
                        }}
                        onClick={() => {
                          dispatch(getMenuShowGrid(itemChild));
                        }}
                      >
                        {itemChild.bar}
                      </div>
                    </Link>
                  );
                })}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default Role;
