const periodData = [
    {
        name: "Tháng 1",
        value: "01",
    },
    {
        name: "Tháng 2",
        value: "02",
    },
    {
        name: "Tháng 3",
        value: "03",
    },
    {
        name: "Tháng 4",
        value: "04",
    },
    {
        name: "Tháng 5",
        value: "05",
    },
    {
        name: "Tháng 6",
        value: "06",
    },
    {
        name: "Tháng 7",
        value: "07",
    },
    {
        name: "Tháng 8",
        value: "08",
    },
    {
        name: "Tháng 9",
        value: "09",
    },
    {
        name: "Tháng 10",
        value: "10",
    },
    {
        name: "Tháng 11",
        value: "11",
    },
    {
        name: "Tháng 12",
        value: "12",
    },
    {
        name: "Quý 1",
        value: "q1",
    },
    {
        name: "Quý 2",
        value: "q2",
    },
    {
        name: "Quý 3",
        value: "q3",
    },
    {
        name: "Quý 4",
        value: "q4",
    },
    {
        name: "6 tháng đầu năm",
        value: "d6t",
    },
    {
        name: "6 tháng cuối năm",
        value: "c6t",
    },
    {
        name: "Cả năm",
        value: "cn",
    },
    {
        name: "Theo tuần",
        value: "week",
    },
    {
        name: "Từ..Đến..",
        value: "formTo",
    },
];

const shiftData = [
    {
        name: "Tất cả",
        value: 0,
    },
    {
        name: "Ca 1",
        value: 1,
    },
    {
        name: "Ca 2",
        value: 2,
    },
    {
        name: "Ca 3",
        value: 3,
    },
];
const granttScaleType = [
    {
        type: "auto",
        name: "Tự động",
    },
    {
        type: "minutes",
        name: "Phút",
    },
    {
        type: "hours",
        name: "Giờ",
    },
    {
        type: "days",
        name: "Ngày",
    },
    {
        type: "weeks",
        name: "Tuần",
    },
    {
        type: "months",
        name: "Tháng",
    },
    {
        type: "quarters",
        name: "Quý",
    },
    {
        type: "years",
        name: "Năm",
    },
];

const lstSize = [
    {
        SIZE_ID: "35",
        SIZE_NAME: "35",
    },
    {
        SIZE_ID: "36",
        SIZE_NAME: "36",
    },
    {
        SIZE_ID: "37",
        SIZE_NAME: "37",
    },
    {
        SIZE_ID: "38",
        SIZE_NAME: "38",
    },
    {
        SIZE_ID: "39",
        SIZE_NAME: "39",
    },
    {
        SIZE_ID: "40",
        SIZE_NAME: "40",
    },
    {
        SIZE_ID: "41",
        SIZE_NAME: "41",
    },
];

const lstColor = [
    {
        COLOR_ID: "01",
        COLOR_NAME: "ĐỎ",
        COLOR_NAME_EN: "#ff6878",
    },
    {
        COLOR_ID: "02",
        COLOR_NAME: "XANH DƯƠNG",
        COLOR_NAME_EN: "#5D8AFC",
    },
    {
        COLOR_ID: "03",
        COLOR_NAME: "TÍM",
        COLOR_NAME_EN: "#807EF5",
    },
    {
        COLOR_ID: "05",
        COLOR_NAME: "ĐEN",
        COLOR_NAME_EN: "black",
    },
    {
        COLOR_ID: "06",
        COLOR_NAME: "VÀNG",
        COLOR_NAME_EN: "#fab755",
    },
    {
        COLOR_ID: "04",
        COLOR_NAME: "TRẮNG",
        COLOR_NAME_EN: "white",
    },
];
const wWidth = window.screen.width;
const widthDrawer =
    wWidth < 600
        ? (90 * wWidth) / 100
        : wWidth < 900
            ? (60 * wWidth) / 100
            : (50 * wWidth) / 100;
export {
    periodData,
    shiftData,
    granttScaleType,
    lstSize,
    lstColor,
    widthDrawer,
};
