import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from '../../../components/layouts';
import { getRoles } from '../../../redux/actions/RoleActions';
import { AppSession } from '../../shared/app-session/app-session';
import { HomeClient, QLCCVehicleModel, QLCCClient, RoleRightWebModel, SysModel, QLCCApartmentBuildingModel, QLCCApartmentModel, QLCCBlockModel, QLCCFloorModel, SysActionType, ReportInfo } from '../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../shared/service-proxies/config';
import { useHistory, useLocation } from 'react-router-dom';
import BlockUI from '../../../components/Block-UI/Block-ui';
import VehicleAction from '../actions/vehicle-action/VehicleAction';
import { Autocomplete, Box, TextField } from '@mui/material';
import notification from '../../../common/notification/notification';
import DHSToolbarRole from '../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import { ACTIONS } from '../../../common/enums/DHSToolbarRoleAction';

import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { EventInfo } from "devextreme/events";
import DataGrid from 'devextreme-react/data-grid';
import DHSBreadcrumb from '../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import { getDataRowSelected, getIndexRowSelected, getKeyRowSelected } from '../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../common/Interfaces/StatusMessage';
import { setApartmentBuilding } from '../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction';
import DHSAutoCompleteWithDataSource from '../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource';


const convert = ["success", "error", "warning"];
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

interface IFilter {
    apartment_building_id?: string | null,
    block_id?: string | null,
    floor_id?: string | null,
    apartment_id?: string | null
}

const defaultFilter: IFilter = {
    apartment_building_id: null,
    block_id: null,
    floor_id: null,
    apartment_id: null
}

const roleMenu: string = 'CC';
const VehicleList: React.FC<{}> = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    // const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<QLCCVehicleModel[]>([]);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const [filter, setFilter] = useState<IFilter>(defaultFilter);

    //

    const [apartmentBuildingOptions, setApartmentBuildingOptions] = React.useState<any[]>([]);
    const [blockOptions, setBlockOptions] = React.useState<any[]>([]);
    const [floorOptions, setFloorOptions] = React.useState<any[]>([]);
    const [apartmentOptions, setApartmentOptions] = React.useState<any[]>([]);

    // 


    // useEffect(() => {
    //     setLoading(true);
    //     qLCClient.qlccApartmentBuildingGet({
    //         ...new QLCCApartmentBuildingModel(),
    //         ma_cty: SessionLogin.ma_cty,
    //         action_type: 'GET'
    //     } as QLCCApartmentBuildingModel)
    //         .then(response => {


    //             setApartmentBuildingOptions(response)
    //             // if (response) {
    //             //     setFilter(pre => ({ ...pre, apartment_building_id: response[0].id }))
    //             // }
    //             setLoading(false);
    //         });

    // }, []);

    React.useEffect(() => {
        setFilter(pre => ({ ...pre, apartment_building_id: apartment_building_id }))

    }, [apartment_building_id])

    // Block filter by apartment building

    React.useMemo(() => {

        if (filter?.apartment_building_id) {
            setLoading(true);
            qLCClient.qlccBlockGet({
                ...new QLCCBlockModel(),
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: filter?.apartment_building_id,
                action_type: 'GET'
            } as QLCCBlockModel)
                .then(response => {

                    setBlockOptions(response)
                    // if (response) {
                    //     setFilter(pre => ({ ...pre, block_id: response[0].id }))
                    // }

                    setLoading(false);
                });
        }
        if (!filter?.apartment_building_id) {
            setFilter(defaultFilter)
        }

    }, [filter?.apartment_building_id])

    // Floor filter by block

    React.useMemo(() => {

        if (filter?.apartment_building_id) {
            setLoading(true);
            qLCClient.qLCCFloorGet({
                ...new QLCCFloorModel(),
                block_id: filter.block_id,
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: filter?.apartment_building_id,
                action_type: 'GET'
            } as QLCCFloorModel)
                .then(response => {

                    setFloorOptions(response)
                    // if (response) {
                    //     setFilter(pre => ({ ...pre, floor_id: response[0]?.id }))
                    // }

                    setLoading(false);
                });

        }
        if (!filter?.block_id) {
            setFilter(pre => ({ ...pre, floor_id: null, apartment_id: null }))
        }
    }, [filter?.block_id, filter?.apartment_building_id])

    // Apartment filter by floor

    React.useMemo(() => {

        if (filter?.apartment_building_id) {
            setLoading(true);
            qLCClient.qLCCApartmentsGet({
                ...new QLCCApartmentModel(),
                floor_id: filter?.floor_id,
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: filter?.apartment_building_id,
                action_type: 'GET'
            } as QLCCApartmentModel)
                .then(response => {

                    setApartmentOptions(response ?? [])
                    // if (response) {
                    //     setFilter(pre => ({ ...pre, floor_id: response[0]?.id }))
                    // }

                    setLoading(false);
                });

        }
        if (!filter?.floor_id) {
            setFilter(pre => ({ ...pre, apartment_id: null }))
        }
    }, [filter?.floor_id, filter?.apartment_building_id])

    useEffect(() => {
        setLoading(true)
        if (filter?.apartment_building_id) {
            qLCClient.qLCCVehicleGet({
                ...new QLCCVehicleModel(),
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: filter?.apartment_building_id,
                block_id: filter?.block_id,
                floor_id: filter?.floor_id,
                apartment_id: filter?.apartment_id,
            } as QLCCVehicleModel).then((res) => {

                setLoading(false)
                setDataTable(res)
            })
        }
    }, [reload, filter])


    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;

        setFilter(pre => ({ ...filter, [name]: value } as IFilter));
    };

    // const [allowEdit, setAllowEdit] = useState<boolean>(true);

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code) {
            case ACTIONS.VIEW:
                // await onViewRow(dataGridRef, setAllowEdit)
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<QLCCVehicleModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: action.action_code,
                                payload: {
                                    vehicle_id: idItemSelected,
                                    apartment_id: filter?.apartment_id
                                }
                            })
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: action.action_code ?? "",
                    payload: {
                        vehicle_id: idItemSelected,
                        apartment_id: filter?.apartment_id
                    }
                })
                break;
            case ACTIONS.EDIT:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<QLCCVehicleModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && dataSelected && index >= 0) {
                            setActCx(pre => ({
                                ...pre,
                                open: true,
                                type: action.action_code ?? "",
                                payload: {
                                    ma_cty: dataSelected?.ma_cty,
                                    apartment_building_id: dataSelected?.apartment_building_id,
                                    vehicle_id: idItemSelected,
                                    apartment_id: dataSelected?.apartment_id
                                }
                            }))
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.COPY:
                notification("info", "Đang phát triển")
                break;
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<QLCCVehicleModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && dataSelected && index >= 0) {
                            if (window.confirm("Bạn có chắc chắn muốn xóa?")) {
                                setLoading(true)
                                qLCClient.qLCCVehicleDel({
                                    ...new QLCCVehicleModel(),
                                    id: idItemSelected,
                                    ma_cty: dataSelected?.ma_cty,
                                    apartment_building_id: dataSelected?.apartment_building_id,
                                    action_type: action.action_code,

                                } as QLCCVehicleModel)
                                    .then((res) => {
                                        setLoading(false)
                                        setIdItemSelected("")
                                        notification(convert[res.status!], res.message!);
                                        setReload(!reload)
                                    });
                            }
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }

                break;
            case ACTIONS.CLOSE:
                break;
            case ACTIONS.SEARCH:
                break;
            case ACTIONS.SAVE:
                break;
        }
    }

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
    const [selectedItemKeys, setSelectedItemKeys] = useState<any>();

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };

    const handleSelectionChanged = async (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        // if (dataGridRef.current) {
        //     dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {

        //         const index = e.component.getRowIndexByKey(keys[0]);
        //         setSelectedRowIndex(index >= 0 ? index : -1);
        //         setSelectedItemKeys(keys[0]);
        //         setIdItemSelected(keys[0].id);
        //     });
        // }

        // const temp =await getListDataRowSelected(dataGridRef)
        const data: any = await getDataRowSelected(dataGridRef);
        console.log(data);

        setSelectedItemKeys(data);
        setIdItemSelected(data.id);
    };

    return (


        <div className='container-fluid'>

            {
                actCx.open && <VehicleAction
                    open={actCx.open}
                    onClose={function (): void {
                        setActCx(defaultAction);
                    }}
                    action={actCx.type}
                    itemSelected={actCx.payload}
                    payload={actCx.payload}
                    onEditSuccess={function (data_response: SysModel): void {
                        setReload(pre => !pre)
                    }} >

                </VehicleAction>
            }

            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location} />
            </div>

            <div className='row no-gutters my-1'>
                {/* Dự án */}
                {/* <div className="col-md-12 col-xl-2 pr-1">
                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Chung cư</InputLabel>
                        <DHSAutoCompleteWithDataSource
                            id="apartment-building-select"
                            className="cc-input"
                            dataSource={apartmentBuildingOptions}
                            value={
                                filter?.apartment_building_id
                            }
                            displayMember='name'
                            valueMember="id"
                            onValueChanged={(e, newValue: any) => {
                                dispatch(setApartmentBuilding(newValue?.id ?? ""))
                            }}

                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option?.id}>
                                        {option?.name}
                                    </li>
                                );
                            }}
                            placeholder='Chọn chung cư'
                            />
                    </Box>
                </div> */}
                {/* Tòa nhà */}
                <div className="col-md-12 col-xl-2 pr-1">
                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                        {/* <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tòa nhà</InputLabel> */}
                        <DHSAutoCompleteWithDataSource
                            id="block-select"
                            className="cc-input"
                            dataSource={blockOptions}
                            value={
                                filter?.block_id
                            }
                            displayMember='name'
                            valueMember="id"
                            onValueChanged={(e, newValue: any) => {
                                handleChangeInput({
                                    target: {
                                        name: "block_id",
                                        value: newValue?.id ?? null,
                                    },
                                });
                            }}

                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option?.id}>
                                        {option?.name}
                                    </li>
                                );
                            }}
                            placeholder='Chọn tòa nhà (block)'
                        />

                    </Box>
                </div>
                {/* Tầng */}
                <div className="col-md-12 col-xl-2 pr-1">
                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                        {/* <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tầng</InputLabel> */}
                        <DHSAutoCompleteWithDataSource
                            id="floor-select"
                            className="cc-input"
                            dataSource={floorOptions}
                            value={
                                filter?.floor_id
                            }
                            displayMember='name'
                            valueMember="id"
                            onValueChanged={(e, newValue: any) => {
                                handleChangeInput({
                                    target: {
                                        name: "floor_id",
                                        value: newValue?.id ?? null,
                                    },
                                });
                            }}

                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option?.id}>
                                        {option?.name}
                                    </li>
                                );
                            }}
                            placeholder='Chọn tầng'
                        />

                    </Box>
                </div>
                {/* Căn hộ */}
                <div className="col-md-12 col-xl-2 pr-1">
                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                        {/* <InputLabel sx={{ fontSize: 14, color: '#333' }}>Căn hộ</InputLabel> */}
                        <DHSAutoCompleteWithDataSource
                            id="apartment-select"
                            className="cc-input"
                            dataSource={apartmentOptions}
                            value={
                                filter?.apartment_id
                            }
                            displayMember='name'
                            valueMember="id"
                            onValueChanged={(e, newValue: any) => {
                                handleChangeInput({
                                    target: {
                                        name: "apartment_id",
                                        value: newValue?.id ?? null,
                                    },
                                });
                            }}

                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option?.id}>
                                        {option?.name}
                                    </li>
                                );
                            }}
                            placeholder='Chọn căn hộ'
                        />

                    </Box>
                </div>
            </div>

            {/* Control */}
            <DHSToolbarRole
                id={' '}
                menu={menu}
                customMenu={[]}
                onClickAction={handleClickAction}
            />


            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                keyExpr="id"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handlePageChange={handlePageChange}
                handleSelectionChanged={handleSelectionChanged}
            />

            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default VehicleList;

