import axios from "axios";
import { AnyARecord } from "dns";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import callApi from "../../../routes/configApi";
import "./style.css";

export interface ICheckInOutProps {}

export default function CheckInOut(props: ICheckInOutProps) {
  const videoRef = useRef<any>(null);
  const photoRef = useRef<any>(null);
  const messageRef = useRef<string>("Giữ khuôn mặt ở giữa");
  const [message, setMessage] = useState<string>("Giữ khuôn mặt ở giữa");
  const [isStart, setIsStart] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const sessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

  function getMedia(constraints: any) {
    if (navigator.mediaDevices) {
      navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        let video = videoRef?.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error(err);
      });
    }
    else alert("Không truy cập được thiết bị ghi hình")
    
  }
  useEffect(() => {
    getVideo();
  }, [videoRef]);

  const getVideo = () => {
    getMedia({
      // video: { width: 1920, height: 1080 },
      video: true,
      audio: false,
    });
  };

  const handleStart = async () => {
    setIsStart(true);
    let check = true;

    while (check) {
      let result: any = await uploadImg();
      if (result.status === 2) {
        check = true;
      } else {
        check = false;
        setIsSuccess(true);
        stopVideo();
      }

      messageRef.current = result.message;
      setMessage(messageRef.current);
    }
  };
  useEffect(() => {
    return () => {
      if (history.action) {
        stopVideo();
      }
    };
  }, [history.action]);

  const uploadImg = async () => {
    const width = 314;
    const height = width / (16 / 9);

    let video = videoRef?.current;
    let photo = photoRef?.current;

    let ctx = photo.getContext("2d");
    ctx.drawImage(video, 0, 0, width, height);

    const data_uri = photoRef.current.toDataURL();

    const data = {
      company_code: sessionLogin.ma_cty,
      employee_code: sessionLogin.userName,
      type: "checkin",
      base64: data_uri,
    };

    //api dùng để test

    const serverUrl = "http://192.168.1.221:8084";

    const instance = axios.create({
      baseURL: serverUrl,
      headers: {
        "Content-type": "application/json; charset=utf-8; multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    });

    let res = await instance.post(
      `/FaceApi/CheckInOut_FaceID_Setting`,
      JSON.stringify(data)
    );

    // let res = await callApi(
    //     "FaceApi/CheckInOut_FaceID_Setting",
    //     "POST",
    //     JSON.stringify(data),
    //     {
    //         "Content-type":
    //             "application/json; charset=utf-8; multipart/form-data",
    //         "Access-Control-Allow-Origin": "*",
    //     }
    // );

    return res.data;
  };

  const stopVideo = () => {
    videoRef.current.srcObject.getTracks().forEach((track: any) => {
      track.stop();
    });
  };

  return (
    <div className="App">
      <div className="camera">
        <div style={{ position: "relative", width: "100%" }}>
          <video
            className={"video-checkin " + (isSuccess ? "hidden" : "")}
            ref={videoRef}
          ></video>
          <span
            className={
              "message-checkin " + (isSuccess ? "message-checkin-success" : "")
            }
          >
            {" "}
            {message}{" "}
          </span>
        </div>
        <canvas ref={photoRef}></canvas>
        <button
          className={"btn-start-camera " + (isStart ? "hidden" : "")}
          onClick={handleStart}
        >
          START!
        </button>
      </div>
    </div>
  );
}
