import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import TextBox from 'devextreme-react/text-box';
import { Divider } from 'antd';
import { AppBar, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    onClosed: () => void;
    isConfirm: (isConfirm: boolean, data: any) => void;
}

export default function ConfirmWithNote(props: IProps) {
    const [notes, setNotes] = React.useState<string>('');

    const onChangeValue = (e: string) => {
        setNotes(e);
    }

    const onSubmit = () => {
        props.isConfirm(true, notes);
    }

    const onCancel = () => {
        props.isConfirm(false, notes);
    }

    return (
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.onClosed}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="sm"
                fullWidth
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            {`Xác nhận`}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={props.onClosed}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent sx={{ paddingTop: 0 }}>
                    <Typography>
                        <strong>Ghi chú</strong>
                    </Typography>
                    <TextBox
                        placeholder="Nhập nội dung ..."
                        inputAttr={{ 'aria-label': 'Full Name' }}
                        defaultValue={notes}
                        onValueChange={onChangeValue}
                    />
                </DialogContent>
                <DialogActions>
                    <Tooltip title='Xác nhận khóa'>
                        <Button variant="contained" color="info" onClick={onSubmit}>Xác nhận</Button>
                    </Tooltip>
                    <Tooltip title='Hủy hành động'>
                        <Button onClick={onCancel}>Hủy</Button>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </div>
    );
}