import { Box } from "@mui/material";
import Popup, { ToolbarItem } from "devextreme-react/popup";
import React from "react";
import { IAction } from "../../../common/Interfaces/Interfaces";
import DHSDisplayFile from "../DHSDisplayFile/DHSDisplayFile";

const defaultAction: IAction = {
    open: false,
    type: "",
    index: 0,
    payload: null
}


interface IProps{
    open: boolean,
    data: any,
    onClose: () => void
}
const DHSZoomFile: React.FC<IProps> = (props) => {
    const [fullScreen, setFullScreen] = React.useState<boolean>(false);

    return (
        <>
            {
            (React.useMemo(() => (
                <Popup
                    visible={props.open}
                    onHiding={(e) => props.onClose()}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showCloseButton={true}
                    showTitle={true}
                    fullScreen={fullScreen}
                    title="Chi tiết file"
                    container=".dx-viewport"
                    width={!fullScreen ? "80%" : undefined}
                    height={!fullScreen ? "80%" : undefined}
                >

                    <ToolbarItem
                        widget="dxButton"
                        toolbar="top"
                        location="after"
                        cssClass={"p-0"}
                        options={{
                            icon: fullScreen ? "copy" : "fullscreen",
                            text: "",
                            style: { WebkitTextStroke: "1px" },
                            stylingMode: "text",
                            onClick: (e: any) => setFullScreen(pre => !pre)
                        }}
                    >

                    </ToolbarItem>
                    {/* <ScrollView width='100%' height='100%'> */}
                    <Box width='100%' height='100%'>
                        {props.data && <DHSDisplayFile fileUrl={props.data?.fileUrl} fileName={props.data?.fileName}/>}
                    </Box>
                    {/* </ScrollView> */}

                </Popup>
            ), [props.open, props.data, fullScreen]))
        }
        </>
    )
}

export default DHSZoomFile;