import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { components } from "../../../../common/ComponentsConfig/components";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import notification from "../../../../common/notification/notification";
import CTXFormDialog from "../../../../components/CTX/Dialog";
import CTXToolbar from "../../../../components/CTX/Toolbar";
import DHSToolbar from "../../../../components/DHS/DHS-Toolbar/DHSToolbar";
import { Notification } from "../../../../components/layouts";
import DHSGridView from "../../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { getRole, getRoles } from "../../../../redux/actions/RoleActions";
import { AppSession } from "../../../shared/app-session/app-session";
import {
  ChoThueXeClient,
  CtxDanhMucXeModel,
  CtxLoaiXeModel,
  HomeClient,
  RoleRightWebModel,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import "./style.css";
interface IAction {
  open: boolean; //Mở Dialog
  type: string | undefined; //Loại action (keyword)
  name: string | undefined; //Tên action (thêm, sửa, xóa)
  table: string | undefined; //Tên bảng thay đổi khi thực hiện action
}
let appSession: AppSession;
const convert = ["success", "error", "warning"];
const menuRoleName = "KS";
const DanhSach = () => {
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

  const history = useHistory();
  const dispatch = useDispatch();
  // const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
  const { path, url } = useRouteMatch();
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);
  const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
  const [itemSelected, selectItem] = React.useState<string>();
  const [action, setAction] = React.useState<IAction>({
    open: false,
    type: "",
    name: "",
    table: "",
  });

  const [dataTable, setDataTable] = React.useState<any[]>([]);
  const [reload, setReload] = React.useState(false);

  React.useEffect(() => {
    homeClient
      ?.roleRightWebGetAll({
        ...new RoleRightWebModel(),
        username: SessionLogin.userName,
        rolename: menuRoleName,
        menuid: menu?.menuid,
      } as RoleRightWebModel)
      .then((res) => {
        dispatch(getRoles(res));
        setRoles(res);
      });
  }, []);

  React.useEffect(() => {
    if (menu?.component?.toUpperCase() === "CTXLOAIXE") {
      ctxClient
        .ctxLoaiXeGet(new CtxLoaiXeModel())
        .then((res) => setDataTable(res));
    } else
      ctxClient
        ?.ctxDanhMucXeActions({
          ...new CtxDanhMucXeModel(),
          tbname: menu?.component,
          action: "GET",
        } as CtxDanhMucXeModel)
        .then((res) => {
          setDataTable(res);
        });
  }, [reload]);

  const handleClickAction = (action: RoleRightWebModel) => {
    if (action?.class_name?.toUpperCase() === "DELETE") {
      if (window.confirm("Bạn có chắc muốn xóa")) {
        ctxClient
          .ctxDanhMucXeActions({
            ...new CtxDanhMucXeModel(),
            id: itemSelected,
            tbname: menu?.component,
            action: "DELETE",
          } as CtxDanhMucXeModel)
          .then((response) => {
            Notification(convert[Number(response[0].ret)], response[0].message);
          });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
        setReload(!reload);
        // window.location.reload();
      }
    } else if (action?.class_name?.toUpperCase() !== "BACK") {
      setAction({
        ...action,
        type: action.class_name?.toUpperCase(),
        name: action.name_vn?.toUpperCase(),
        open: true,
        table: action.component,
      });
      // setOpen(true);
    } else history.goBack();
  };

  const handleSubmit = (data: any) => {
    switch (action?.table?.toUpperCase()) {
      case "CTXCHOTHUEXE":
        if (!itemSelected) {
          ctxClient.ctxLoaiXeIns(data).then((response) => {
            Notification(convert[Number(response[0].ret)], response[0].message);
          });
        } else {
          ctxClient.ctxLoaiXeUpd(data).then((response) => {
            Notification(convert[Number(response[0].ret)], response[0].message);
          });
        }
        break;
      default:
        ctxClient
          .ctxDanhMucXeActions({
            ...new CtxDanhMucXeModel(),
            id: itemSelected,
            name: data,
            tbname: action?.table,
            action: action?.type,
          } as CtxDanhMucXeModel)
          .then((response) => {
            Notification(convert[Number(response[0].ret)], response[0].message);
          });
        break;
    }
    setAction({
      ...action,
      open: false,
      type: "",
      name: "",
      table: "",
    });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div
      className="container-fluid p-2"
      style={{ maxHeight: "94vh !important", overflowY: "auto" }}
    >
      <Switch>
        <Route path={path} exact>
          {React.useMemo(() => {
            return (
              <CTXFormDialog
                title={`${action.name} ${menu?.short_name?.toUpperCase()}`}
                row={itemSelected}
                table={action.table}
                open={action.open}
                onClose={() => {
                  setAction({
                    ...action,
                    open: false,
                    type: "",
                    // name: "",
                    table: "",
                  });
                }}
                onSubmit={handleSubmit}
              />
            );
          }, [action.open])}

          <div
            className="d-flex title-color"
            style={{
              height: 30,
              color: "rgb(0 78 255 / 88%)",
              marginTop: 15,
              marginBottom: 15,
              fontSize: 22,
              fontWeight: 550,
            }}
          >
            <i
              className="bi bi-arrow-left-short"
              style={{ cursor: "pointer" }}
              onClick={() => history.goBack()}
            ></i>
            &nbsp;{menu?.bar}
          </div>
          <CTXToolbar
            id={itemSelected}
            list={
              roles
                ? roles.filter(
                    (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
                  )
                : []
            }
            onClickAction={handleClickAction}
          />
          <DHSGridView
            onSelectRow={(row) => {
              selectItem(row);
            }}
            defaultPageSize={20}
            // onListSelectRow={handleListOnselectRow}
            itemClicked={function (): void {
              throw new Error("Function not implemented.");
            }}
            table_name={menu?.component ? menu.component : ""}
            show_form_list={true}
            showSelectBox={false}
            data={dataTable}
          />
        </Route>
        {roles &&
          roles.map((role, index) => {
            return (
              role.link &&
              role.link.length > 1 &&
              role.component && (
                <Route
                  key={index}
                  path={role.is_parameter ? role.link + ":_id" : role.link}
                  component={components[role.component.toLocaleUpperCase()]}
                  exact
                ></Route>
              )
            );
          })}
      </Switch>
    </div>
  );
};

export default DanhSach;
