import callApi from "../../../../routes/configApi";
import * as types from "../../../constants/ActionTypes";
export const GetRptMachineUptimeTotalAll = (query: any) => (dispatch: any, getState: any) => {
  callApi("api/MachineRpt/GetRptMachineUptimeTotalAll", "POST", query)
    .then((res) =>
      dispatch({
        type: types.GET_RPT_MACHINE_UPTIME_TOTALALL,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const GetRptMachineUptime = (query: any) => (dispatch: any, getState: any) => {
  callApi("api/MachineRpt/GetRptMachineUptime", "POST", query)
    .then((res) =>
      dispatch({
        type: types.GET_RPT_MACHINE_UPTIME,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const GetRptMachineTimeline = (query: any) => (dispatch: any, getState: any) => {
  callApi("api/MachineRpt/GetRptMachineTimeline", "POST", query)
    .then((res) =>
      dispatch({
        type: types.GET_RPT_MACHINE_TIMELINE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const GetRptMachineWorkShift = (query: any) => (dispatch: any, getState: any) => {
  callApi("api/MachineRpt/GetRptMachineWorkShift", "POST", query)
    .then((res) =>
      dispatch({
        type: types.GET_RPT_MACHINE_WORK_SHIFT,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const GetLstMachinesGroup = (ma_cty: any) => (dispatch: any, getState: any) => {
  callApi("api/MachineRpt/GetLstMachinesGroup", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_GROUP_MACHINE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const containRptMachine = (data: any) => {
  return {
    type: types.CONTAIN_RPT_MACHINE,
    payload: data,
  };
};
