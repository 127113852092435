import * as types from "../constants/ActionTypes";
import { AnyAction } from "redux";
var initialState = {
  lstHomePage: [],
  lstDetailPage: [],
  lstProductPage: [],
  lstImages: [],
  requestSuccess: false,
  languageId: "vi-VN",
  homeIns: null,
  homeUpdate: null,
  homeDelete: null,
  lstRelatedLink: [],
};

var HomePageReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.GET_LIST_HOME_PAGE: {
      return {
        ...state,
        lstHomePage: action.payload,
      };
    }
    case types.GET_LIST_DETAIL_PAGE: {
      return {
        ...state,
        lstDetailPage: action.payload,
      };
    }
    case types.INSERT_CUSTOMER_REQUEST: {
      return {
        ...state,
        requestSuccess: action.payload,
      };
    }
    case types.INITIAL_STATE: {
      return {
        ...state,
        requestSuccess: false,
      };
    }
    case types.GET_LIST_PRODUCT_PAGE: {
      return {
        ...state,
        lstProductPage: action.payload,
      };
    }
    case types.LANGUAGE_ID: {
      return {
        ...state,
        languageId: action.payload,
      };
    }
    case types.GET_ALL_IMAGE: {
      return {
        ...state,
        lstImages: action.payload,
      };
    }
    case types.DHS_HOME_INS: {
      return {
        ...state,
        homeIns: action.payload,
      };
    }
    case types.DHS_HOME_UPD: {
      return {
        ...state,
        homeUpdate: action.payload,
      };
    }
    case types.DHS_HOME_DEL: {
      return { ...state, homeDelete: action.payload };
    }
    case types.CLEAR_HOME: {
      return {
        ...state,
        homeIns: null,
        homeUpdate: null,
        homeDelete: null,
      };
    }
    case types.GET_LIST_RELATED_LINK: {
      return {
        ...state,
        lstRelatedLink: action.payload,
      };
    }
    default:
      return state;
  }
};

export default HomePageReducer;
