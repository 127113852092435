import { Box, Stack } from "@mui/material";
import React from "react";
import { getFileExtension, isImageFile, isPDFFile } from "../../utils/file.util";
import DHSButton from "../../DHSComponents/actions-and-list/DHSButton/DHSButton";

interface IProps {
    fileUrl: string,
    fileName: string
}

const DHSDisplayFile: React.FC<IProps> = (props) => {
    const [scale, setScale] = React.useState<number>(1);
    const [rotate, setRotate] = React.useState<number>(0);
    // 
    const [dragging, setDragging] = React.useState(false);
    const [position, setPosition] = React.useState({ x: 0, y: 0 });
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const imageRef = React.useRef<HTMLImageElement | null>(null);
    // 

    React.useEffect(() => {
        console.log(scale, "scale")
    }, [scale])

    const handleMouseDown = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation();
        
        if (imageRef.current && containerRef.current) {
            setDragging(true);
            const offsetX = e.clientX - imageRef.current.getBoundingClientRect().left;
            const offsetY = e.clientY - imageRef.current.getBoundingClientRect().top;
            setPosition({ x: offsetX, y: offsetY });
            console.log(imageRef.current.getBoundingClientRect().left, imageRef.current.getBoundingClientRect().top)

        }
    };

    const handleMouseUp = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation();
        setDragging(false);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation();
        if (dragging && imageRef.current && containerRef.current) {
            // 
            const maxX = imageRef.current.width - containerRef.current.offsetWidth;
            const maxY = imageRef.current.height - containerRef.current.offsetHeight;
            // 

            // 
            const newX = (e.clientX - containerRef.current.getBoundingClientRect().left) - position.x;
            const newY = (e.clientY - containerRef.current.getBoundingClientRect().top ) - position.y;
            // if (newX >= 0 && newX <= maxX && newY >= 0 && newY <= maxY) {
            imageRef.current.style.top = `${newY}px`;
            imageRef.current.style.left = `${newX}px`;

            // }
        }
    };
    //   
    const DisplayFile = (fileUrl: string, fileName: string): JSX.Element => {
        // Kiểm tra định dạng của URL để xác định loại tệp
        if (isImageFile(fileName)) {
            // Hiển thị hình ảnh
            return (
                <Stack spacing={1} height={"inherit"}>
                    <Stack direction={"row"} spacing={1}>
                        <DHSButton icon="bi bi-zoom-in"
                            onClick={(e) => {
                                if (scale + 0.5 < 3) {
                                    setScale(pre => pre + 0.5)
                                }

                            }} />
                        <DHSButton icon="bi bi-zoom-out"
                            onClick={(e) => {
                                if (scale - 0.5 > 0) {
                                    setScale(pre => pre - 0.5)
                                }

                            }} />
                        {/* <DHSButton icon="bi bi-zoom-out"
                            onClick={(e) => {
                                if (rotate + 30 <= 360) {
                                    setRotate(pre => {
                                        if(pre + 30 === 360) return 0;
                                        return pre + 30
                                    })

                                }

                            }} /> */}
                    </Stack>
                    <Box ref={containerRef} 
                    className="image-shadow"
                    sx={{
                        height: "inherit",
                        display: "flex",
                        justifyContent: "center",
                        overflow: "hidden",
                        cursor: "pointer",
                        position: "relative",
                        transition: "transform 0.3s ease",

                    }}
                        onMouseLeave={(e) => setDragging(false)}
                        onMouseUp={(e) => setDragging(false)}
                        onWheel={(e) => {

                            if (e.deltaY < 0) {
                                if (scale + 0.5 < 3) {
                                    setScale(pre => pre + 0.5)
                                }
                            }
                            else if (e.deltaY > 0) {
                                if (scale - 0.5 > 0) {
                                    setScale(pre => pre - 0.5)
                                }
                            }
                        }}
                    >
                        <img
                            ref={imageRef}
                            src={fileUrl}
                            style={{
                                position: "absolute",
                                cursor: dragging ? "grab" : "pointer",
                                transform: `scale(${scale}) rotate(${rotate}deg)`,
                                objectFit: "contain",
                                transformOrigin:  "top left",
                                width: "fit-content",
                                height: "100%"
                            }}
                            alt="Hình ảnh"
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            onMouseMove={handleMouseMove}
                        />
                    </Box>
                </Stack>
            );
        } else if (isPDFFile(fileName)) {
            // Hiển thị tệp PDF
            return (
                <Box sx={{ height: "inherit", display: "flex", justifyContent: "center" }}>
                    <iframe src={fileUrl} title="PDF Viewer" style={{ overflow: "hidden", height: "100%", width: "100%" }} height="100%" width="100%"></iframe>
                </Box>
            );
        } else {
            // Nếu không phải hình ảnh hoặc PDF, bạn có thể xử lý nó theo cách khác
            return <Stack spacing={1}>
                <Box sx={{ textAlign: "center", fontSize: "1rem", fontWeight: 500 }}>Loại tệp không được hỗ trợ ".{getFileExtension(fileName)}"</Box>
                <a href={fileUrl} className="text-center" download target="_blank" rel="noreferrer">
                    <DHSButton icon="download" type="default" text="Tải về máy" />
                </a>
            </Stack>;
        }
    }
    return (
        <>
            {DisplayFile(props?.fileUrl, props?.fileName)}
        </>
    )
}

export default DHSDisplayFile;