import moment from "moment";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import notification from "../../../common/notification/notification";
import BlockUI from "../../../components/Block-UI/Block-ui";
import CTXToolbar from "../../../components/CTX/Toolbar";
import DHSGridView from "../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { getRoles } from "../../../redux/actions/RoleActions";
import { AppSession } from "../../shared/app-session/app-session";
import {
    HomeClient,
    QLCCClient,
    QLCCDomesticWaterModel,
    QLCCDomesticWaterNormModel,
    QLCCTaskTypeModel,
    RoleRightWebModel,
    SysActionType,
    SysGenRowTableModel,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import DomesticWaterNormAction from "../actions/domestic-water-action/DomesticWaterNormAction";
import { useHotkeys } from "react-hotkeys-hook";
import dxDataGrid, {
    RowInsertedInfo,
    RowInsertingInfo,
    RowRemovedInfo,
    RowRemovingInfo,
    RowUpdatedInfo,
    RowUpdatingInfo,
    SelectionChangedInfo,
} from "devextreme/ui/data_grid";
import { EventInfo } from "devextreme/events";
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";

import { Notification } from "../../../components/layouts";
import DataGrid from "devextreme-react/data-grid";
import { ACTIONS } from "../../../common/enums/DHSToolbarRoleAction";
import DHSToolbarRole from "../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import DHSBreadcrumb from "../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import { onAddRow, onDeleteRow, onEditRow, onViewRow } from "../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../common/Interfaces/StatusMessage";

interface IAction {
    open: boolean;
    type: string | undefined;
    payload: string | undefined;
}
let appSession: AppSession;
const convert = ["success", "error", "warning"];
const menuRoleName = "CC";
const TaskType = (Props: any) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [itemSelected, selectItem] = React.useState<string>();
    const [dataTable, setDataTable] = React.useState<QLCCTaskTypeModel[]>([] as QLCCTaskTypeModel[]);
    const [action, setAction] = React.useState<IAction>({
        open: false,
        type: "",
        payload: ""
    });
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
    const [isShowSort, setShowSort] = React.useState<boolean>(false);
    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
    const [reload, setReload] = React.useState(false);
    const customMenu = [
        {
            menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
            onClick: () => {
                setShowSearch(!isShowSearch);
            },
        },
        {
            menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
            onClick: () => {
                setShowGroup(!isShowGroup);
            },
        },
        {
            menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
            onClick: () => {
                setShowSort(!isShowSort);
            },
        },
        {
            menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
            onClick: () => {
                setShowFilter(!isShowFilter);
            },
        },
    ];
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)


    const dataGridRef = useRef<DataGrid>(null);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
    const [allowEdit, setAllowEdit] = useState<boolean>(true);
    const [selectedItemKeys, setSelectedItemKeys] = useState<any>();

    React.useEffect(() => {
        (async () => {
            setLoading(true);
            const result = await qLCCClient.qLCCTaskTypeGet({
                ...new QLCCTaskTypeModel(),
                ma_cty: SessionLogin.ma_cty,
                apartment_building_id: apartment_building_id
            } as QLCCTaskTypeModel);
            setDataTable([...result]);
            setLoading(false);
        })();
    }, [reload]);

    const handleRowInserting = async (
        event: EventInfo<dxDataGrid<any, any>> & RowInsertingInfo<any>
    ) => {
        try {
            event.cancel = true;
            notification(
                TYPE_MESSAGE.ERRORR,
                "Chưa có phương thức thực hiện"
            );
            return;
            setLoading(true);
            const response = await qLCCClient
                ?.qLCCDomesticWaterNormAction({
                    ...event.data,
                    action_type: "INSERT"
                } as QLCCDomesticWaterNormModel);

            if (response.status === 0) {
                setDataTable([{ id: response.id, ...event.data }, ...dataTable]);
                dataGridRef.current?.instance.cancelEditData();
                notification(
                    TYPE_MESSAGE.SUCCESS,
                    STATUS_MESSAGE[`${ACTIONS.ADD}_${response!.status}`]
                );
            } else {
                event.cancel = true;
                notification(
                    TYPE_MESSAGE.ERRORR,
                    STATUS_MESSAGE[`${ACTIONS.ADD}_${response!.status}`]
                );
            }
        } catch (error) {
            notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
        } finally {
            setLoading(false);
        }
    };

    const handleRowUpdating = async (
        event: EventInfo<dxDataGrid<any, any>> & RowUpdatingInfo<any, any>
    ) => {
        try {
            event.cancel = true;
            notification(
                TYPE_MESSAGE.ERRORR,
                "Chưa có phương thức thực hiện"
            );
            return;

            setLoading(true);
            const response = await qLCCClient
                ?.qLCCDomesticWaterNormAction({
                    ...event.oldData,
                    ...event.newData,
                    action_type: "UPDATE"
                } as QLCCDomesticWaterNormModel)
            if (response.status === 0) {
                const dataLastUpdated = dataTable.map((item) => {
                    if (item.id === event.key) {
                        return {
                            ...new QLCCDomesticWaterNormModel(),
                            ...event.oldData,
                            ...event.newData,
                        };
                    } else {
                        return item;
                    }
                });

                setDataTable([...dataLastUpdated]);
                dataGridRef.current?.instance.cancelEditData();
                notification(
                    TYPE_MESSAGE.SUCCESS,
                    STATUS_MESSAGE[`${ACTIONS.EDIT}_${response!.status}`]
                );
            } else {
                notification(
                    TYPE_MESSAGE.ERRORR,
                    STATUS_MESSAGE[`${ACTIONS.EDIT}_${response!.status}`]
                );
            }
        } catch (error) {
            notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
        } finally {
            setLoading(false);
        }
    };

    const handleRowRemoving = async (
        event: EventInfo<dxDataGrid<any, any>> & RowRemovingInfo<any, any>
    ) => {
        try {
            notification(
                TYPE_MESSAGE.ERRORR,
                "Chưa có phương thức thực hiện"
            );
            return;

            setLoading(true);
            const response = await qLCCClient
                .qLCCDomesticWaterNormAction({
                    ...new QLCCDomesticWaterModel(),
                    ...event.data,
                    action_type: ACTIONS.DELETE,
                } as QLCCDomesticWaterModel)

            if (response.status === 0) {
                setDataTable([...dataTable.filter((item) => item.id !== event.key)]);
                notification(
                    TYPE_MESSAGE.SUCCESS,
                    STATUS_MESSAGE[`${ACTIONS.DELETE}_${response!.status}`]
                );
            } else {
                notification(
                    TYPE_MESSAGE.ERRORR,
                    STATUS_MESSAGE[`${ACTIONS.DELETE}_${response!.status}`]
                );
            }
        } catch (error) {
            notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
        } finally {
            setLoading(false);
        }
    };

    const handleClickAction = async (action: SysActionType) => {
        switch (action?.action_code?.toLocaleUpperCase()) {
            case ACTIONS.ADD:
                await onAddRow(dataGridRef, setAllowEdit);
                break;
            case ACTIONS.DELETE:
                await onDeleteRow(dataGridRef)
                break;
            case ACTIONS.EDIT:
                await onEditRow(dataGridRef, setAllowEdit);
                break;
            case ACTIONS.VIEW:
                await onViewRow(dataGridRef, setAllowEdit);
                break;

            default:
                break;
        }
    };

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    }

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                console.log(keys);

                const index = e.component.getRowIndexByKey(keys[0]);


                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
            });
        }
    };

    return (
        <div className="container-fluid p-2" style={{ maxHeight: "94vh !important", overflowY: "auto" }} >
            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb location={location} />
            </div>

            <DHSToolbarRole
                id={' '}
                menu={menu}
                onClickAction={handleClickAction}
            />
            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                keyExpr="id"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={allowEdit}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handlePageChange={handlePageChange}
                handleRowInserting={handleRowInserting}
                handleRowUpdating={handleRowUpdating}
                // handleRowUpdated={handleRowUpdated}
                handleRowRemoving={handleRowRemoving}
                handleSelectionChanged={handleSelectionChanged}
            />
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </div>
    );
};

export default TaskType;
