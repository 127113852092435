interface IStatusMessage {
    [key: string]: string;
}
export const STATUS_MESSAGE: IStatusMessage = {
    //#region action
    ADD_0: "Thêm mới thành công",
    ADD_1: "Thêm mới không thành công",
    EDIT_0: "Cập nhật thành công",
    EDIT_1: "Cập nhật không thành công",
    DELETE_0: "Xóa thành công",
    DELETE_1: "Xóa không thành công",
    SAVE_0: "Lưu thành công",
    SAVE_1: "Lưu không thành công",
    APPROVED_0: "Duyệt thành công",
    APPROVED_1: "Duyệt không thành công. Vui lòng kiểm tra lại thông tin và thử lại.",
    CANCELAPPROVAL_1: "Hủy duyệt không thành công. Vui lòng kiểm tra lại thông tin và thử lại.",
    CANCELAPPROVAL_0: "Hủy duyệt thành công",
    REJECT_1: "Từ chối không thành công. Vui lòng kiểm tra lại thông tin và thử lại.",
    REJECT_0: "Từ chối thành công",
    //#endregion

    //#region image
    ALLOW_JPG_JPGE_PNG: "Hình ảnh cho phép là jpg, jpge hoặc png",
    ALLOW_SIZE: "Kích thước cho phép giới hạn từ %s đến %s.",
    CANNOT_GET_IMAGE: "Không lấy được hình ảnh",
    //#endregion

    //#region date
    DATE_CANCLE_NOT_EMPTY_WHEN_STATUS_IS_NO_USE: `Ngày hủy không được trống nếu trạng thái là "Không còn sử dụng"`,
    //#endregion

    //#region validation
    DELETE_UNAVAILABLE: "Chức năng xóa không khả dụng ở chức năng này.",
    CUSTOMER_NULL: "Vui lòng chọn khách hàng.",
    REQUIRED: "Không được phép trống", 
    FIELD_REQUIRED: "Vui lòng nhập các trường bắt buộc.",
    USER_NOT_EXISTS: "Người sử dụng không tồn tại hoặc chưa được duyệt. Vui lòng kiểm tra và thử lại sau.", 
    UTILITY_APPROVED: "Tiện ích đã được duyệt.", 
    SERVICE_APPROVED: "Tiện ích đã được duyệt.", 
    UTILITY_REJECT_INVALID: 'Không thể từ chối khi dữ liệu chọn có trạng thái ngoài "Đang đợi duyệt".', 
    UTILITY_CANCEL_APPROVAL_INVALID: 'Không thể hủy duyệt khi dữ liệu chọn có trạng thái ngoài "Đã đặt thành công".',
    VEHICLE_NO_NOT_EXISTS: "Biển số xe không có trên hệ thống. Vui lòng kiểm tra lại",
    VEHICLE_NO_IS_DUPLICATE: "Biển số xe hiện tại nhiều hơn 1. Vui lòng kiểm tra lại",
    //#endregion
    
    //#region lock
    NOT_ALLOW_EDIT_WHEN_LOCK: "Chức năng sửa không còn khả dụng vì đã bị khóa.",
    NOT_ALLOW_DELETE_WHEN_LOCK: "Chức năng xóa không còn khả dụng vì đã bị khóa.",
    //#endregion

    //#region voucher
    SAME_NUMBER_VOUCHER_1: "Trùng số chứng từ. Vui lòng lưu lại.",
    //#endregion
    //#region CA
    EXCHANGE_BY_INVOICE_3: `Loại thu "Thu theo hóa đơn" bắt buộc phải chọn "hóa đơn".`,
    EXCHANGE_BY_INVOICE_6: `Loại thu "Thu theo cọc thẻ xe" bắt buộc phải chọn "Thẻ xe".`,
    EXCHANGE_BY_INVOICE_5: `Loại thu "Thu theo dịch vụ/tiện ích" bắt buộc phải chọn "dịch vụ/tiện ích".`,
    //#endregion

    SERVER_ERROR: 'Hết phiên làm việc hoặc lỗi kết nối Server. Vui lòng đăng nhập lại hoặc kiểm tra kết nối mạng',
    TOKEN_EXPIRED: 'Hết phiên làm việc',
    WARNING_SELECT_ROW: 'Vui lòng chọn dữ liệu',
    UNAVAILABLE_FEATURE: 'Chức năng không khả dụng',
    TRY_ANOTHER: 'Thực hiện không thành công \n Hãy thử một cách khác!',
    NO_PERMISSIONS: 'Bạn không có quyền này!',
    PASSWORD: 'Nhập mật khẩu',
    USERNAME: 'Nhập tài khoản',
    NETWORK_CORRECTION: 'Vui lòng kiểm tra kết nối mạng!',
    SENT_TASK_SUCCESS: 'Công việc đã hoàn thành không thể chuyển',
    SENT_TASK_ERROR: 'Không thể chuyển việc lúc này',
    SENT_TASK_DONE: 'Công việc đã xử lý không thể giao tiếp tục'
}

interface ITypeMessage {
    [key: string]: string;
}

export enum TYPE_MESSAGE {
    SUCCESS = 'success',
    ERRORR = 'error',
    WARNING = 'warning',
    INFO = 'info',
}

export const MESSAGE_TYPE = [
    'success',
    'error',
    'warning',
    'info'
]


// export const StatusMessage: IStatusMessage[] = [
//     {
//         action: 'ADD',
//         message: {
//             0: "Thêm mới thành công.",
//             1: "Thêm mới không thành công"
//         }
//     },
//     {
//         action: 'EDIT',
//         message: {
//             0: "Cập nhật mới thành công.",
//             1: "Cập nhật mới không thành công"
//         }
//     },
//     {
//         action: 'DELETE',
//         message: {
//             0: "Xóa thành công.",
//             1: "Xóa không thành công"
//         }
//     },
// ]

