import React from "react";
import NumberBox, { INumberBoxOptions } from "devextreme-react/number-box";


interface IProps extends INumberBoxOptions{

}

const DHSNumberBox : React.FC<IProps> = (props) => {
    return <NumberBox
                {...props}
                
              />
}

export default DHSNumberBox;