import moment from "moment";
// import { debug } from "console";
// import { Space, Spin } from "antd";
// import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { formatNumber } from "devextreme/localization";
// import routes from "../../../../components/routes/routes";
// import CurrencyTextField from "react-currency-input-field";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TransitionProps } from "@mui/material/transitions";
import { Notification } from "../../../../components/layouts";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import ReceiptAction from "../../receipt/actions/ReceiptAction";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { AppSession } from "../../../shared/app-session/app-session";
import { FormatMoney } from "../../../../components/controller/Format";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
// import { ToVietNamCurrency } from "../../../../components/utils/number.util";
import DataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DHSTextBox from "../../../../components/DHSComponents/editors/DHSTextBox/DHSTextBox";
import DHSNumberBox from "../../../../components/DHSComponents/editors/DHSNumberBox/DHSNumberBox";
import { getListDataSource } from "../../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
// import DataGridCustomeDataFilter from "../../../../components/DHS/DHS_DataGridEdit/DataGridCustomeDataFilter";
import DHSLoadPanel from "../../../../components/DHSComponents/dialogs-and-notifications/DHSLoadPanel/DHSLoadPanel";
import { QLCCClient, QLCCDomesticWaterModel, SoVch05M, SoVch05Client, SoVch05D0, QLCCDomesticElectricModel, CommonClient, ParaObjTableList, LstItemGroup, } from "../../../shared/service-proxies/api-shared";
import { Button, Dialog, DialogContent, DialogActions, Box, Slide, IconButton, AppBar, Toolbar, Typography, Divider, Grid, InputLabel, } from "@mui/material";
import { useWindowSize } from "../../../../common/useWindowWide";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { httpPost } from "../../../../common/httpService";
import _ from "lodash";
import { generateUUID } from "../../../../components/utils/generationUuid";

interface IProps {
    open: boolean;
    itemSelected?: any;
    item?: any;
    action?: any;
    domesticWaterCycle?: any;
    domesticWaterCyclePresent?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
    domesticWaterCyclePrevious?: any;
    component?: any;
    t_tt?: any;
    thongTinHoaDon?: any;
    handleDelBill?: (p: string[]) => void;
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


interface IAction {
    type: string;
    open: boolean;
    payload: any;
    itemSelected: any;
}

const defaultAction: IAction = {
    type: "",
    payload: null,
    open: false,
    itemSelected: null
};

//KI HIEU DINH MUC NUOC 
const normWater = ["DM1", "DM2", "DM3"];
// GIA TRI GIOI HAN MOI CU DAN TRONG DINH MUC
const COST_DM = [4, 2, 0];

const convert = ['success', 'error', 'warning', 'info']
var appSession: AppSession;
const ServiceInvoicesClient = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const stt_rec = props.item.stt_rec;
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const vch05Client = new SoVch05Client(appSession, BASE_URL_API);
    const [data, setData] = React.useState<QLCCDomesticWaterModel>({} as QLCCDomesticWaterModel);
    const [thongTinDV, setThongTinDV] = React.useState<SoVch05D0[]>([]);
    /* event change input -> notify confirm save ?*/
    const [isChangeInput, setIsChangeInput] = React.useState<boolean>(false);
    /* auto scroll */
    const dataGridRef = React.useRef<any>(null);
    const dataGridElectricRef = React.useRef<any>(null);
    const dataGridWaterRef = React.useRef<any>(null);
    const [thongTinHD, setTTHD] = React.useState<SoVch05M>({} as SoVch05M);
    const [actRc, setActRc] = React.useState<IAction>(defaultAction);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const [description, setDescription] = React.useState<string>("");
    const [totalMoney, setTotalMoney] = React.useState<number>(0); // Tổng tiền thanh toán
    const [dataSourceElectric, setDataSourceElectric] = React.useState<QLCCDomesticElectricModel[]>([]) // chỉ số điện
    const [dataSourceWater, setDataSourceWater] = useState<QLCCDomesticWaterModel[]>([])
    const [dataSourceItemGroup, setDataSourceItemGroup] = useState<LstItemGroup[]>([])

    async function AsyncsUeEffectStart() {
        setTotalMoney(0)
        if (props.open) {
            onloadDataSource();
            OnLoadLstItemGroup();
            if (props.action === "UPDATE" || props.action === "DETAIL" || props.action === 'EDIT') {
                setLoading(true);
                vch05Client
                    .qLCCsoVch05D0Get({
                        ... new SoVch05D0(),
                        stt_rec: stt_rec,
                        apartment_building_id: apartment_building_id,
                        ma_cty: SessionLogin.ma_cty,
                    } as SoVch05D0 | any)
                    .then(res => {
                        setThongTinDV(res);
                        setIsChangeInput(false);
                        setLoading(false);
                        onloadDataSourceWater(res)
                        if (res) {
                            total_old_debt(res, props.thongTinHoaDon)
                            const findWater = res.find(item => item.nhom_vt === 'WATER');
                            if (findWater && findWater.description) {
                                setDescription(findWater.description)
                            } else {
                                setDescription('')
                            }
                        }
                    })
            } else if (props.action === ACTIONS.ADD) {
                setLoading(true);
                try {
                    const res = await vch05Client.qLCCsoVch05D0Get({
                        ... new SoVch05D0(),
                        stt_rec: stt_rec,
                        ma_cty: SessionLogin.ma_cty,
                    } as SoVch05D0);
                    setThongTinDV(res);
                    setIsChangeInput(false);
                    setLoading(false);
                    if (res) {
                        const findWater = res.find(item => item.nhom_vt === 'WATER');
                        if (findWater && findWater.description) {
                            setDescription(findWater.description)
                        }
                    }
                } catch { }
            } else {
                setData({
                    ...new QLCCDomesticWaterModel(),
                } as QLCCDomesticWaterModel);
                setIsChangeInput(false);
            };
            if (props.action === ACTIONS.ADD) {
                setTTHD({
                    ...new SoVch05M(),
                    ...props.thongTinHoaDon,
                    trang_thai: '0',
                } as SoVch05M)
            } else {
                setTTHD(props.thongTinHoaDon)
            }
        }
    }

    React.useEffect(() => {
        AsyncsUeEffectStart();
    }, [props.open]);

    /* thanh toán */
    const handlPay = () => {
        if (!payStatus(data)) {
            setActRc({
                ...actRc,
                type: "ADD",
                payload: {
                    invoice_id: props.itemSelected,
                    invoice: thongTinHD
                },
                open: true,
            })
        }
    }

    const payStatus = (data: QLCCDomesticWaterModel) => {
        return data && data.pay_status;
    }

    /* 
         TTDV: object model SoVch05D0
         name: Tên trường trong model SoVch05D0
         e: event onChange
    */
    // NOTES: Đã check 14-11-2023 - Cập nhật khi thay đổi giá trị sẽ không đẩy lên đầu nữa
    const handleRowChange = (TTDV: SoVch05D0 | undefined, name: string, e: any) => {
        try {
            if (TTDV) {
                // const ArrayThongTinDV: SoVch05D0[] = thongTinDV.filter(DV => DV.stt_rec0 !== TTDV.stt_rec0);
                const ArrayThongTinDV: SoVch05D0[] = [...thongTinDV];

                switch (name.toLocaleUpperCase()) {
                    case 'SO_LUONG':
                        const { value } = e.target;
                        if (value !== '' && TTDV.gia2 !== undefined) {
                            if (TTDV.gia2 !== 0) {
                                const tt = value !== 0 ? TTDV.gia2 * value : 0;
                                /* value !== 0 =>  tt =  TTDV.gia2 * value <=> tt = 0*/
                                changeValue(ArrayThongTinDV, TTDV, name, Number(value), tt);
                            } else {
                                /* TTDV.gia2 => tt = 0 */
                                changeValue(ArrayThongTinDV, TTDV, name, Number(value), 0);
                            }
                        } else {
                            /* value = '' =>  tt = 0 */
                            changeValue(ArrayThongTinDV, TTDV, name, 0, 0);
                        }
                        break;
                    case 'GIA2':
                        const values2 = e;
                        if (values2 !== '' && TTDV.so_luong !== undefined) {
                            if (TTDV.so_luong !== 0) {
                                const tt: number = values2 !== 0 ? TTDV.so_luong * values2 : 0;
                                /* value !== 0 =>  tt =  TTDV.gia2 * value <=> tt = 0*/
                                changeValue(ArrayThongTinDV, TTDV, name, Number(values2), tt);
                            } else {
                                /* TTDV.so_luong => tt = 0 */
                                changeValue(ArrayThongTinDV, TTDV, name, Number(values2), 0);
                            }
                        } else {
                            /* value = '' =>  tt = 0 */
                            changeValue(ArrayThongTinDV, TTDV, name, 0, 0);
                        }
                        break;
                    case 'MA_PHI':
                        // const { value } = e.target;

                        if (value !== "") {
                            const ItemTTDV = {
                                ...TTDV,
                                ma_phi: value,
                            } as SoVch05D0;
                            setThongTinDV([ItemTTDV, ...ArrayThongTinDV]);
                            setIsChangeInput(true);
                        }
                        break;
                    case 'TT':
                        if (e !== "") {
                            const ItemTTDV = {
                                ...TTDV,
                                tien2: Number.parseFloat(e),
                                tien_nt2: Number.parseFloat(e),
                                gia_nt2: Number.parseFloat(e),
                                gia2: Number.parseFloat(e),
                                tt: Number.parseFloat(e),
                                tt_nt: Number.parseFloat(e),
                            } as SoVch05D0;
                            setThongTinDV([ItemTTDV, ...ArrayThongTinDV]);
                            setIsChangeInput(true);
                        }
                        break;
                }
            }
        } catch (ex) {
            console.log(ex)
        }
    }

    // NOTES: Đã check 14-11-2023
    const handleRowChangeDomesticWater = (name: string, e: any) => {
        const value = e;
        switch (name.toLocaleUpperCase()) {
            case 'WATER_PREVIOUS':
                setTTHD({
                    ...thongTinHD,
                    water_previous: value,
                    water_consume_total: Number(thongTinHD.water_latest) - Number(value),
                } as SoVch05M);
                setIsChangeInput(true);
                break;
            case 'WATER_LATEST':
                setTTHD({
                    ...thongTinHD,
                    water_latest: value,
                    water_consume_total: Number(value) - Number(thongTinHD.water_previous),
                } as SoVch05M);

                setIsChangeInput(true);
                break;
        }
    }

    // NOTES: Đã check 14-11-2023 - Cập nhật khi thay đổi giá trị sẽ không đẩy lên đầu nữa
    const changeValue = (ArrayThongTinDV: SoVch05D0[], TTDV: SoVch05D0, name: string, value: number, tt: number) => {
        // const tt = TTDV.gia2! * value;
        const findIndex = ArrayThongTinDV.findIndex(DV => DV.stt_rec0 === TTDV.stt_rec0)

        let ItemTTDV = {
            ...TTDV,
            [name]: Number(value),
            [`${name}_qd`]: Number(value),              //  bổ sung 03/10/2023 // so_luong_qd
            tien2: TTDV.gia2! * value,                  //  bổ sung 03/10/2023
            tien_nt2: TTDV.gia2! * value,               //  bổ sung 03/10/2023
            gia_nt2: TTDV.gia2!,                        // Bổ sung 05.10.2023
            tt: tt,
            tt_nt: tt
        } as SoVch05D0;
        if (name === 'gia2') {
            ItemTTDV = {
                ...ItemTTDV,
                gia_nt2: Number(value),
                tien2: ItemTTDV.so_luong! * value,
                tien_nt2: ItemTTDV.so_luong! * value,
            } as SoVch05D0;
        }
        // console.log(name)
        // console.log(TTDV);
        // console.log(ItemTTDV);

        // setThongTinDV([ItemTTDV, ...ArrayThongTinDV]);
        ArrayThongTinDV.splice(findIndex, 1, ItemTTDV);
        total_old_debt(ArrayThongTinDV);
        setThongTinDV(ArrayThongTinDV);
        setIsChangeInput(true);
    }

    // NOTES: Đã check 14-11-2023
    const handleSave = async () => {
        setLoading(true);
        const getDataSourceTable: any = await getListDataSource(dataGridRef);
        const getDataSourceTableElectric: any = await getListDataSource(dataGridElectricRef);
        const newDataSourceDetail: SoVch05D0[] = [];
        getDataSourceTable.map((x: SoVch05D0) => {
            const obj = {
                ma_cty: x?.ma_cty,
                apartment_building_id: x?.apartment_building_id,
                floor_id: x?.floor_id,
                apartment_id: x?.apartment_id,
                stt_rec: x?.stt_rec,
                stt_rec0: x?.stt_rec0,
                idItem: x?.idItem,
                ten_vt: x?.ten_vt,
                nhom_vt: x?.nhom_vt,
                ten_nhom_vt: x?.ten_nhom_vt,
                dvt: x?.dvt,
                so_luong: x?.so_luong,
                so_luong_qd: x?.so_luong,
                gia_nt2: x?.gia_nt2,
                gia2: x?.gia2,
                tien_nt2: (x?.so_luong ?? 0) * (x?.gia2 ?? 0),
                tien2: (x?.so_luong ?? 0) * (x?.gia2 ?? 0),
                tl_ck: x?.tl_ck,
                tien_ck_nt: x?.tien_ck_nt,
                tien_ck: x?.tien_ck,
                ck_ds: x?.ck_ds,
                ck_ds_nt: x?.ck_ds_nt,
                ts_gtgt: x?.ts_gtgt,
                thue_gtgt_nt: x?.thue_gtgt_nt,
                thue_gtgt: x?.thue_gtgt,
                tt_nt: (x?.so_luong ?? 0) * (x?.gia2 ?? 0),
                tt: (x?.so_luong ?? 0) * (x?.gia2 ?? 0),
                khuyen_mai: x?.khuyen_mai,
                tra_ck: x?.tra_ck,
                ma_nvkd: x?.ma_nvkd,
                ma_hd: x?.ma_hd,
                ma_bp: x?.ma_bp,
                ma_phi: x?.ma_phi,
                ma_spct: x?.ma_spct,
                tk_pt: x?.tk_pt,
                tk_thue: x?.tk_thue,
                tk_dt: x?.tk_dt,
                tk_ck: x?.tk_ck,
                ma_thue: x?.ma_thue,
                site_id: x?.site_id,
                hblNo: x?.hblNo,
                dien_giai: x?.dien_giai,
                cont20: x?.cont20,
                cont40: x?.cont40,
                part: x?.part,
                hoahong: x?.hoahong,
                stt_rec_hbl: x?.stt_rec_hbl,
                stt_rec0_hbl: x?.stt_rec0_hbl,
                sua_tien: x?.sua_tien,
                sua_thue: x?.sua_thue,
                sua_ck: x?.sua_ck,
                ma_nt_bill: x?.ma_nt_bill,
                ty_gia_bill: x?.ty_gia_bill,
                gia_nt2_bill: x?.gia_nt2_bill,
                tien_nt2_bill: x?.tien_nt2_bill,
                tien_thue_nt_bill: x?.tien_thue_nt_bill,
                ts_gtgt_bill: x?.ts_gtgt_bill,
                t_tien_nt_bill: x?.t_tien_nt_bill,
                old_debt: x?.old_debt,
                stt_einvoice: x?.stt_einvoice,
                ma_ct_bill: x?.ma_ct_bill,
                action_type: x?.action_type,
                path_file: x?.path_file,
                description: x?.description,
                electric_previous: x?.electric_previous,
                electric_latest: x?.electric_latest,
                electric_consume_total: x?.electric_consume_total,
                electric_norm_resident: x?.electric_norm_resident,
            } as SoVch05D0
            newDataSourceDetail.push(obj);
        })
        //DANGER: CHƯA SỬ DỤNG - CẬP NHẬT CHỈ SỐ NƯỚC
        const getDataSourceWater: any = await getListDataSource(dataGridWaterRef); // cập nhật chỉ số nước đối với mảng
        const previous = Number(thongTinHD?.water_previous);
        const latest = Number(thongTinHD?.water_latest);
        if (previous > latest) {
            Notification('error', 'Chỉ số nước đang được nhập sai');
            return
        }
        const t_tt = newDataSourceDetail.reduce((acc, obj) => acc + (obj.tt ? obj.tt : 0), 0);
        // try { newDataSourceDetail.map((item, index) => { newDataSourceDetail.splice(index, 1, { ...item, description: description } as SoVch05D0); }) } catch (e) { }
        try {
            const res = await vch05Client
                .qLCCsoVch05MUpd({
                    ...thongTinHD,
                    action_type: "UPDATE",
                    soVch05D0s: [...newDataSourceDetail],
                    qlccElectrics: getDataSourceTableElectric,
                    qlccWaters: getDataSourceWater,
                    t_tt: t_tt,
                    t_tt_nt: t_tt,
                    luser: SessionLogin.userName,
                    water_previous: previous,
                    water_latest: latest,
                    total_old_debt: t_tt + (thongTinHD.old_debt ? thongTinHD.old_debt : 0),
                    du_tt: t_tt,
                    du_tt_nt: t_tt,
                    t_tien2: t_tt,
                    t_tien_nt2: t_tt,
                    // du_tt: t_tt + thongTinHD.old_debt! - thongTinHD.da_tt!,
                    // du_tt_nt: t_tt + thongTinHD.old_debt! - thongTinHD.da_tt!
                    ngay_ct: moment(thongTinHD.ngay_ct).utc(true).toDate(),
                    ldate: moment(thongTinHD.ldate).utc(true).toDate(),
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty,
                } as SoVch05M);

            if (res && res.status !== undefined) {
                props.onClose();
                props.onEditSuccess(true);
                Notification(convert[res.status], res.message);
                setLoading(false);
            }
        }
        catch (ex) {
            setLoading(false);
            Notification(convert[1], "Kết xuất dữ liệu không chính xác, vui lòng reset màn hình hóa đơn");
        }
    }

    // NOTES: Đã check 14-11-2023
    const handleAdd = async () => {
        setLoading(true);
        const getDataSourceTable: any = await getListDataSource(dataGridRef);
        const getDataSourceTableElectric: any = await getListDataSource(dataGridElectricRef);
        const newDataSourceDetail: SoVch05D0[] = getDataSourceTable;
        const getDataSourceWater: any = await getListDataSource(dataGridWaterRef); // cập nhật chỉ số nước đối với mảng

        const previous = Number(thongTinHD?.water_previous);
        const latest = Number(thongTinHD?.water_latest);
        const t_tt = newDataSourceDetail.reduce((acc, obj) => acc + (obj.tt ? obj.tt : 0), 0);

        try { newDataSourceDetail.map((item, index) => { newDataSourceDetail.splice(index, 1, { ...item, description: description } as SoVch05D0); }) } catch (e) { }

        try {
            const res = await qLCCClient
                .qlccGenerateSOVch05InsV2({
                    ...thongTinHD,
                    // action_type: "UPDATE",
                    soVch05D0s: [...newDataSourceDetail],
                    qlccElectrics: getDataSourceTableElectric,
                    qlccWaters: getDataSourceWater,
                    t_tt: t_tt,
                    t_tt_nt: t_tt,
                    luser: SessionLogin.userName,
                    water_previous: previous,
                    water_latest: latest,
                    total_old_debt: t_tt + (thongTinHD.old_debt ? thongTinHD.old_debt : 0),
                    du_tt: t_tt,
                    du_tt_nt: t_tt,
                    t_tien2: t_tt,
                    t_tien_nt2: t_tt,
                    // du_tt: t_tt + thongTinHD.old_debt! - thongTinHD.da_tt!,
                    // du_tt_nt: t_tt + thongTinHD.old_debt! - thongTinHD.da_tt!
                    ngay_ct: moment(thongTinHD.ngay_ct).utc(true).toDate(),
                    ldate: moment(thongTinHD.ldate).utc(true).toDate(),
                    cdate: moment(thongTinHD.cdate).utc(true).toDate(),
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty,
                } as SoVch05M)

            if (res && res.status !== undefined) {
                props.onClose();
                props.onEditSuccess(true);
                Notification(convert[res.status], res.message)
                setLoading(false);
            }

        }
        catch (ex) {
            setLoading(false);

        }
    }

    // NOTES: Đã check 14-11-2023
    /* TONG TIEN THANH TOAN */
    const total_old_debt = async (params?: SoVch05D0[], paramsHD?: SoVch05M) => {
        const getDataSourceTable: any = await getListDataSource(dataGridRef);
        const newDataSourceDetail: SoVch05D0[] = [];

        if (params !== undefined) {
            newDataSourceDetail.push(...params)
        } else {
            newDataSourceDetail.push(...getDataSourceTable);
        }

        const price = newDataSourceDetail.reduce((acc, obj) => acc + (obj.tt ? obj.tt : 0), 0);

        if (paramsHD && paramsHD.old_debt) {
            const newPrice = price + Number(paramsHD.old_debt);
            setTotalMoney(newPrice)
        }
        else if (thongTinHD?.old_debt) {
            const newPrice = price + Number(thongTinHD.old_debt);
            setTotalMoney(newPrice)
        }
        else {
            setTotalMoney(price)
        }
    }

    // NOTES: Đã check 14-11-2023
    const handleSentBill = () => {
        setLoading(true);
        qLCCClient
            .qlccGenerateSOVch05Ins({
                ... new SoVch05M(),
                ma_ct: 'SO5',
                apartment_id: thongTinHD.apartment_id,
                cycle_id: props.domesticWaterCycle.id,
                month: props.domesticWaterCycle.month,
                year: props.domesticWaterCycle.year,
                luser: SessionLogin.userName,
                is_send_notifi: true,
                action_type: 'GENERATESOVCH05_2',
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
            } as SoVch05M)
            .then((res) => {
                if (res) {
                    Notification(convert[0], res.message)
                }
                setLoading(false);
            }).catch((err) => {
                if (err.status) {
                    Notification('Error', err.message)
                }
                setLoading(false);
            })
    }

    // NOTES: Đã check 14-11-2023
    /* In hóa đơn */
    const overviewBill = () => {
        window.open(BASE_URL_API + `/Report/Reports?ma_cty=${SessionLogin.ma_cty}&ma_ct=${'SO5'}&stt_rec=${stt_rec}&apartment_building_id=${apartment_building_id}`);
    }

    // NOTES: Đã check 14-11-2023 
    /* CAP NHAT DINH MUC KHI THAY DOI CHI SO NUOC */
    React.useEffect(() => {
        // CHIA 2 OPTIONS: 1. DA DANG KI NORM WATER; 2. KHONG DANG KY NORM WATER
        if (
            thongTinHD.water_norm_resident
            && thongTinHD.water_norm_resident > 0
        ) {
            const water_consume_total: number = thongTinHD.water_consume_total ? thongTinHD.water_consume_total : 0;
            const water_norm_resident: number = thongTinHD.water_norm_resident;

            const arrDM = [0, 0, 0];

            // NEU TONG TIEU THU < 0 => KHONG THAY DOI SO DINH MUC
            if (water_consume_total < 0)
                return;

            // TINH DINH MUC 1 - 2 - 3
            if (water_consume_total < water_norm_resident * 4) {
                arrDM[0] = water_consume_total;
            } else {
                arrDM[0] = water_norm_resident * COST_DM[0];
                if (water_consume_total > (arrDM[0] + water_norm_resident * COST_DM[1])) {
                    arrDM[1] = water_norm_resident * COST_DM[1];
                    arrDM[2] = water_consume_total - arrDM[1] - arrDM[0];
                } else {
                    arrDM[1] = water_consume_total - arrDM[0];
                }
            }

            // LAY CAC ITEM TRONG SOVCHO5D (CAC DINH MUC MUC NUOC TRONG HOA DON)
            const arrayThongTinDV_DM: any = [];
            normWater.map(item => {
                const temp = thongTinDV.find(e => e.ma_ct_bill === item);
                if (temp) {
                    arrayThongTinDV_DM.push(temp);
                }
            }
            )

            // CAP NHAT GIA TRI MOI CHO CAC ITEM DINH MUC NUOC
            if (arrayThongTinDV_DM.length > 0) {
                arrayThongTinDV_DM.map((item: any, index: number) => {
                    item.so_luong = arrDM[index];
                    if (item.gia2) {
                        item.tt = arrDM[index] !== 0 ? item.gia2 * arrDM[index] : 0;
                        item.tt_nt = arrDM[index] !== 0 ? item.gia2 * arrDM[index] : 0;
                        item.gia_nt2 = item.gia2;
                    }
                })
            }

            // LAY CAC ITEM TRONG SOVCH05D KHONG PHAI LA DINH MUC NUOC
            const ArrayThongTinDV: any[] = [];

            thongTinDV.map(DV => {
                let status = false;
                arrayThongTinDV_DM.map((item: any) => {
                    if (DV.stt_rec0 === item.stt_rec0)
                        status = true;
                })
                if (!status)
                    ArrayThongTinDV.push(DV);
            })


            // GAN DU LIEU SOVCH05D
            const newDataSourceDetail = [
                ...ArrayThongTinDV,
                ...arrayThongTinDV_DM
            ]
            setThongTinDV(newDataSourceDetail)
            total_old_debt(newDataSourceDetail)
        } else if (
            thongTinHD.water_norm_resident === 0
        ) {
            handleRowChange(thongTinDV.find(item => item.ma_ct_bill === normWater[2]),
                'so_luong', {
                target: {
                    value: thongTinHD.water_consume_total
                }
            })
        }
    }, [thongTinHD.water_consume_total]);


    // NOTES: Đã check 14-11-2023
    const ngayCT = (): Date => {
        const date = props.thongTinHoaDon && props.thongTinHoaDon.ngay_ct ? props.thongTinHoaDon.ngay_ct : new Date();
        return date;
    }

    // NOTES: Đã check 14-11-2023
    const handleChangeDescription = (e: any) => {
        const { value } = e.target;
        setDescription(value);
        setIsChangeInput(true);
    }


    // Load danh sách phiếu điện
    async function onloadDataSource() {
        try {
            const getElectric = await qLCCClient.qLCCDomesticElectricGet({
                ...new QLCCDomesticElectricModel(),
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
                apartment_id: props.thongTinHoaDon.apartment_id,
                stt_rec: stt_rec,
            } as QLCCDomesticElectricModel);
            setDataSourceElectric(getElectric)
        } catch (ex) {
            console.log(ex)
        }
    }


    // Load danh sách phiếu nước
    async function onloadDataSourceWater(dataSourceParams?: SoVch05D0[]) {
        const thongTinDVCopy: SoVch05D0[] = []

        if (dataSourceParams) {
            thongTinDVCopy.push(...dataSourceParams);

        } else {
            thongTinDVCopy.push(...thongTinDV);
        }
        try {
            const findItemWater = thongTinDVCopy.find(item => item.ma_ct_bill === normWater[0]);
            const getWater = await qLCCClient.qLCCDomesticWaterGet({
                ...new QLCCDomesticElectricModel(),
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
                apartment_id: props.thongTinHoaDon.apartment_id,
                id: findItemWater?.idItem,
                action_type: "GENERATESOVCH05",
            } as QLCCDomesticElectricModel);
            setDataSourceWater(getWater);
        } catch (ex) {
            console.log(ex)
        }
    }

    // Tính lại hóa đơn nước nếu sử dụng từ 2 đồng hồ trở lên 
    function CalculatorDomesticWater(dataSourceWaterParams: QLCCDomesticWaterModel[]) {
        // CHIA 2 OPTIONS: 1. DA DANG KI NORM WATER; 2. KHONG DANG KY NORM WATER
        const totalOfDomesticWater: number = dataSourceWaterParams.reduce((a: any, b: QLCCDomesticWaterModel) => a + b.consume_total, 0)
        // console.log('Tổng tiêu thụ nước', totalOfDomesticWater)
        if (
            thongTinHD.water_norm_resident
            && thongTinHD.water_norm_resident > 0
        ) {
            const water_consume_total: number = totalOfDomesticWater;
            const water_norm_resident: number = thongTinHD.water_norm_resident;

            const arrDM = [0, 0, 0];

            // NEU TONG TIEU THU < 0 => KHONG THAY DOI SO DINH MUC
            if (water_consume_total < 0)
                return;

            // TINH DINH MUC 1 - 2 - 3
            if (water_consume_total < water_norm_resident * 4) {
                arrDM[0] = water_consume_total;
            } else {
                arrDM[0] = water_norm_resident * COST_DM[0];
                if (water_consume_total > (arrDM[0] + water_norm_resident * COST_DM[1])) {
                    arrDM[1] = water_norm_resident * COST_DM[1];
                    arrDM[2] = water_consume_total - arrDM[1] - arrDM[0];
                } else {
                    arrDM[1] = water_consume_total - arrDM[0];
                }
            }

            // LAY CAC ITEM TRONG SOVCHO5D (CAC DINH MUC MUC NUOC TRONG HOA DON)
            const arrayThongTinDV_DM: any = [];
            normWater.map(item => {
                const temp = thongTinDV.find(e => e.ma_ct_bill === item);
                if (temp) {
                    arrayThongTinDV_DM.push(temp);
                }
            }
            )

            // CAP NHAT GIA TRI MOI CHO CAC ITEM DINH MUC NUOC
            if (arrayThongTinDV_DM.length > 0) {
                arrayThongTinDV_DM.map((item: any, index: number) => {
                    item.so_luong = arrDM[index];
                    if (item.gia2) {
                        item.tt = arrDM[index] !== 0 ? item.gia2 * arrDM[index] : 0;
                        item.tt_nt = arrDM[index] !== 0 ? item.gia2 * arrDM[index] : 0;
                    }
                })
            }

            // LAY CAC ITEM TRONG SOVCH05D KHONG PHAI LA DINH MUC NUOC
            const ArrayThongTinDV: any[] = [];

            thongTinDV.map(DV => {
                let status = false;
                arrayThongTinDV_DM.map((item: any) => {
                    if (DV.stt_rec0 === item.stt_rec0)
                        status = true;
                })
                if (!status)
                    ArrayThongTinDV.push(DV);
            })


            // GAN DU LIEU SOVCH05D
            const newDataSourceDetail = [
                ...ArrayThongTinDV,
                ...arrayThongTinDV_DM
            ]
            setThongTinDV(newDataSourceDetail)
            total_old_debt(newDataSourceDetail)
        } else if (
            thongTinHD.water_norm_resident === 0
        ) {
            handleRowChange(thongTinDV.find(item => item.ma_ct_bill === normWater[2]),
                'so_luong', {
                target: {
                    value: totalOfDomesticWater
                }
            })
        }
    }

    const sizeWindow = useWindowSize();
    const [isFull, setIsFull] = useState(false);


    async function OnLoadLstItemGroup() {
        const response = await httpPost<string>("api/Common/LoadDatasourceBindControlByApartmentBuilding", {
            ma_cty: SessionLogin.ma_cty,
            tablelist: 'dm_nhomvt2',
            apartment_building_id: apartment_building_id
        }).then(res => res.data);
        const parseRes = JSON.parse(response);
        setDataSourceItemGroup(parseRes);
    }

    function handleClose() {
        if (props.action === ACTIONS.ADD && props.handleDelBill && thongTinHD.stt_rec) {
            props.handleDelBill([thongTinHD.stt_rec])
            props.onEditSuccess(true);
        }
        props.onClose();
    }


    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            fullScreen={sizeWindow.width < 600 || isFull}
            TransitionComponent={Transition}
            maxWidth="lg"
        >
            {
                actRc.open && <ReceiptAction
                    open={actRc.open}
                    onClose={function (): void {
                        setActRc(defaultAction);
                        props.onEditSuccess(true);
                        props.onClose();
                    }}

                    onEditSuccess={function (is_success): void {
                        //  setReload(pre => !pre)
                    }}
                    action={ACTIONS.ADD}
                    itemSelected={actRc.payload}
                    filter={{
                        apartment_building_id: apartment_building_id,
                        apartment_id: thongTinHD.apartment_id
                    }}
                >
                </ReceiptAction>
            }
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#2167A3",
                    color: "#fff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {`Thông tin chi tiết hóa đơn`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setIsFull(pre => !pre)}
                        aria-label="close"
                    >
                        {
                            sizeWindow.width < 600 || isFull ? <FullscreenExitIcon />
                                : <FullscreenIcon />
                        }
                    </IconButton>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent style={{
            }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}
                        sx={{
                            fontSize: 15, color: '#333'
                        }}
                    >
                        {
                            props.thongTinHoaDon && props.thongTinHoaDon.islock
                            && (
                                <p className="text-uppercase text-start text-danger" style={{ fontWeight: '600', fontSize: 14 }}>
                                    <sup>*</sup> [ Hóa đơn đã bị khóa]
                                </p>
                            )
                        }
                        <p className="text-uppercase text-center  text-header-invoice m-0" >{`THÔNG BÁO PHÍ QUẢN LÝ VẬN HÀNH THÁNG ${ngayCT().getMonth() + 1}`}</p>
                        <p className="text-uppercase text-center  text-header-invoice" >{`(phí nước tháng ${ngayCT().getMonth() === 0 ? 12 : ngayCT().getMonth()}, phí xe tháng ${moment(ngayCT()).month() + 1}/${ngayCT().getFullYear()})`}</p>


                        <p>
                            Số hóa đơn: <b>{props.action === ACTIONS.ADD ? <span className="text-danger">*Hóa đơn được tạo mới</span> : thongTinHD?.so_ct}</b>
                        </p>
                        <p>
                            <span>
                                <span style={{ marginRight: '10px' }}><>Kính gửi: Anh/Chị</> <b>{thongTinHD.ten_kh_vat}</b></span>
                                <span>Căn hộ: <b>{thongTinHD.apartment_name}</b></span>
                            </span>
                        </p>
                        <p>

                        </p>
                        <p style={{ marginBottom: '0px' }}>
                            {
                                'Ban Quản lý chung cư xin thông báo phí chung cư tháng này của Quý cư dân như sau:'
                            }
                        </p>
                    </Grid>

                    {/* Nhập điện & nước */}
                    <Grid item xs={12}>
                        {/* <Box className='row'>
                            <Box className=' col-12 col-md-12 mb-2  form-input-invoice'>
                                <InputLabel className="label-invoice" >Kỳ nước</InputLabel>
                                <DHSTextBox
                                    value={description}
                                    onValueChange={e => {
                                        handleChangeDescription({
                                            target: {
                                                value: e,
                                                name: `description`
                                            }
                                        })
                                    }}
                                    disabled={(props.action === "DETAIL" || thongTinHD.islock || props.action === ACTIONS.VIEW || thongTinHD?.trang_thai !== '0')}
                                />
                            </Box>
                        </Box> */}
                        <Box className='row'>
                            <Box className={`col-12 ${dataSourceElectric.length > 0 ? 'col-md-6' : ''} mb-2`} >
                                {
                                    dataSourceWater.length <= 1 ?
                                        <>
                                            <Box className='p-2'
                                                sx={{
                                                    border: '0.5px solid #b3b3b3',
                                                    borderRadius: '7px'
                                                }}
                                            >
                                                <p className="mb-1 text-bold">Chỉ số tiêu thụ nước</p>
                                                <Box className='mb-2 form-input-invoice'>
                                                    <InputLabel className="label-invoice">Số cũ</InputLabel>
                                                    <DHSNumberBox
                                                        value={thongTinHD?.water_previous}
                                                        onValueChange={e => {
                                                            handleRowChangeDomesticWater('water_previous', e)
                                                        }}
                                                        disabled={(props.action === "DETAIL" || thongTinHD.islock || props.action === ACTIONS.VIEW || thongTinHD?.trang_thai !== '0')}
                                                        format={'#,##0.##'}
                                                    />
                                                </Box>
                                                <Box className='mb-2 form-input-invoice'>
                                                    <InputLabel className="label-invoice">Số mới</InputLabel>
                                                    <DHSNumberBox
                                                        value={thongTinHD?.water_latest}
                                                        onValueChange={e => {
                                                            handleRowChangeDomesticWater('water_latest', e)
                                                        }}
                                                        disabled={(props.action === "DETAIL" || thongTinHD.islock || props.action === ACTIONS.VIEW || thongTinHD?.trang_thai !== '0')}
                                                        min={thongTinHD?.water_previous}
                                                        validationError={'Chỉ số mới phải lớn hơn chỉ số cũ'}

                                                        format={'#,##0.##'}
                                                    />
                                                </Box>
                                                <Box className='mb-2 form-input-invoice d-flex flex-column justify-content-end'>
                                                    <p className="m-0 text-bold"><span className="pr-2">Chỉ số nước tiêu thụ:</span> {formatNumber(thongTinHD?.water_consume_total ?? 0, '#,##0.##')}</p>
                                                    <p className="m-0 text-bold"><span className="pr-2">Định mức nhân khẩu: </span>{thongTinHD.water_norm_resident}</p>
                                                </Box>

                                            </Box>

                                        </>
                                        : <>
                                            <Box className='p-2'
                                                sx={{
                                                    border: '0.5px solid #b3b3b3',
                                                    borderRadius: '7px'
                                                }}
                                            >
                                                <p className="mb-1 text-bold">Chỉ số tiêu thụ nước</p>
                                                <DataGridEdit
                                                    data={dataSourceWater}
                                                    table_name={"DomesticWaterUpdate2"}
                                                    keyExpr="id"
                                                    mode="cell"
                                                    dataGridRef={dataGridWaterRef}
                                                    allowEdit={(props.action !== "DETAIL" && !thongTinHD.islock! && props.action !== ACTIONS.VIEW && thongTinHD?.trang_thai === '0')}
                                                    isMultiple={false}
                                                    groupingAutoExpandAll={false}
                                                    height={30}
                                                    ma_cty={SessionLogin?.ma_cty}
                                                    currency="VND"
                                                    handleRowUpdated={async (e) => {
                                                        try {
                                                            const res: any = await getListDataSource(dataGridWaterRef);
                                                            CalculatorDomesticWater(res);
                                                            setIsChangeInput(true);
                                                        } catch (ex) {
                                                            console.warn(ex)
                                                            alert('Lỗi Update Domestic Water')
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        </>
                                }
                            </Box>
                            {
                                dataSourceElectric.length > 0
                                && <>
                                    <Box className="col-12 col-md-6 mb-2">
                                        <Box className='p-2'
                                            sx={{
                                                border: '0.5px solid #b3b3b3',
                                                borderRadius: '7px'
                                            }}
                                        >
                                            <p className="mb-1 text-bold">Tiêu thụ điện</p>
                                            <DataGridEdit
                                                data={dataSourceElectric}
                                                table_name={"soVch05D0DomesticElectric"}
                                                keyExpr="code"
                                                mode="cell"
                                                dataGridRef={dataGridElectricRef}
                                                allowEdit={props.action !== "DETAIL" && !thongTinHD.islock! && props.action !== ACTIONS.VIEW && thongTinHD?.trang_thai === '0'} // Không phải xem,  không khóa, chưa thanh toán => true
                                                isPagination={false}
                                                isMultiple={false}
                                                groupingAutoExpandAll={false}
                                                height={30}
                                                ma_cty={thongTinHD.ma_cty}
                                                currency="VND"
                                                handleRowUpdating={e => {
                                                    // total_old_debt(); // cập nhật lại tổng tiền thanh toán
                                                }}
                                                handleRowUpdated={async e => {
                                                    setIsChangeInput(true);
                                                    const getData: any = await getListDataSource(dataGridElectricRef);
                                                    if (getData) {
                                                        const consume_total = getData.reduce((acc: any, obj: QLCCDomesticElectricModel) => acc + (obj.consume_total ? obj.consume_total : 0), 0);
                                                        const findItem = thongTinDV.find(item => item.ma_ct_bill === 'PD');
                                                        handleRowChange(findItem, 'so_luong', { target: { name: 'consume_total', value: consume_total } })
                                                    }
                                                    // total_old_debt(); // cập nhật lại tổng tiền thanh toán
                                                }}
                                            // showControlDataGridEdit
                                            />
                                        </Box>
                                    </Box>
                                </>
                            }
                        </Box>


                        <p className="mt-3 mb-1 text-bold">Danh sách các loại phí</p>
                        <DataGridEdit
                            data={thongTinDV}
                            table_name={"soVch05D0"}
                            keyExpr="stt_rec0"
                            mode="form"
                            // mode="cell"
                            dataGridRef={dataGridRef}
                            allowEdit={props.action !== "DETAIL" && !thongTinHD.islock! && props.action !== ACTIONS.VIEW && thongTinHD?.trang_thai === '0'} // Không phải xem,  không khóa, chưa thanh toán => true
                            isPagination={false}
                            isMultiple={false}
                            groupingAutoExpandAll={true}
                            height={60}
                            ma_cty={thongTinHD.ma_cty}
                            currency="VND"
                            handleRowUpdating={e => {
                                total_old_debt(); // cập nhật lại tổng tiền thanh toán
                            }}

                            handleInitNewRow={(e) => {
                                e.data = {
                                    ...new SoVch05D0(),
                                    stt_rec: thongTinHD?.stt_rec,
                                    stt_rec0: generateUUID(),
                                    ma_cty: SessionLogin.ma_cty,
                                    apartment_building_id: apartment_building_id,
                                    floor_id: undefined,
                                    apartment_id: thongTinHD?.apartment_id,
                                    idItem: undefined,
                                    ten_vt: undefined,
                                    nhom_vt: undefined,
                                    ten_nhom_vt: undefined,
                                    dvt: undefined,
                                    so_luong: 0,
                                    so_luong_qd: 0,
                                    gia_nt2: 0,
                                    gia2: 0,
                                    tien_nt2: 0,
                                    tien2: 0,
                                    tl_ck: 0,
                                    tien_ck_nt: 0,
                                    tien_ck: 0,
                                    ck_ds: 0,
                                    ck_ds_nt: 0,
                                    ts_gtgt: 0,
                                    thue_gtgt_nt: 0,
                                    thue_gtgt: 0,
                                    tt_nt: 0,
                                    tt: 0,
                                    khuyen_mai: undefined,
                                    tra_ck: undefined,
                                    ma_nvkd: undefined,
                                    ma_hd: undefined,
                                    ma_bp: undefined,
                                    ma_phi: undefined,
                                    ma_spct: undefined,
                                    tk_pt: undefined,
                                    tk_thue: undefined,
                                    tk_dt: undefined,
                                    tk_ck: undefined,
                                    ma_thue: undefined,
                                    site_id: undefined,
                                    hblNo: undefined,
                                    dien_giai: undefined,
                                    cont20: undefined,
                                    cont40: undefined,
                                    part: undefined,
                                    hoahong: undefined,
                                    stt_rec_hbl: undefined,
                                    stt_rec0_hbl: undefined,
                                    sua_tien: undefined,
                                    sua_thue: undefined,
                                    sua_ck: undefined,
                                    ma_nt_bill: undefined,
                                    ty_gia_bill: undefined,
                                    gia_nt2_bill: undefined,
                                    tien_nt2_bill: undefined,
                                    tien_thue_nt_bill: undefined,
                                    ts_gtgt_bill: undefined,
                                    t_tien_nt_bill: undefined,
                                    old_debt: undefined,
                                    stt_einvoice: undefined,
                                    ma_ct_bill: undefined,
                                    action_type: undefined,
                                    path_file: undefined,
                                    description: undefined,
                                    electric_previous: undefined,
                                    electric_latest: undefined,
                                    electric_consume_total: undefined,
                                    electric_norm_resident: undefined
                                } as SoVch05D0
                            }}

                            handleRowRemoving={e => {
                                const dataRemove: SoVch05D0 | undefined = e?.data;
                                if (dataRemove && dataRemove.nhom_vt === 'WATER' && dataRemove?.idItem) {
                                    e.cancel = true;
                                    Notification('error', 'Không thể xóa thông tin bắt buộc ');
                                }
                            }}

                            handleRowRemoved={async (e) => {
                                // Kiểm tra có phải hóa đơn chứng từ thuộc nước hay không?
                                const res: any = await getListDataSource(dataGridRef);
                                const copyThongTinDV = [...thongTinDV];
                                try {
                                    setThongTinDV(res);
                                    total_old_debt(res); // cập nhật lại tổng tiền thanh toán
                                    setIsChangeInput(true);
                                } catch (ex) {
                                    setThongTinDV(copyThongTinDV);
                                    console.warn('Lỗi hóa đơn', ex)
                                }
                            }}

                            handleRowUpdated={e => {
                                // update lần 2 để xử lí
                                const newData: SoVch05D0 = e.data;
                                console.log(newData)
                                const findIndexData = thongTinDV.findIndex(item => item.stt_rec0 === e.key);
                                if (findIndexData >= 0) {
                                    setIsChangeInput(true);
                                    const getData = thongTinDV[findIndexData];
                                    const findItem = thongTinDV.find(x => x.stt_rec0 === newData.stt_rec0) // thông tin của hóa đơn chi tiết gốc

                                    if (getData.nhom_vt !== newData.nhom_vt) {
                                        const findItemGroup = dataSourceItemGroup.find(item => item.ma_nhvt === newData.nhom_vt);
                                        const copyThongTinDV = [...thongTinDV];
                                        copyThongTinDV.splice(findIndexData, 1, {
                                            ...newData,
                                            gia_nt2: newData.gia2,
                                            ten_nhom_vt: findItemGroup?.ten_nhvt
                                        } as SoVch05D0);
                                        // Copy data mới để set description
                                        const newArr = [...copyThongTinDV];
                                        copyThongTinDV.map((x, index) => {
                                            if (x.nhom_vt === newData.nhom_vt) {
                                                newArr.splice(index, 1, {
                                                    ...x,
                                                    description: newData?.description !== findItem?.description ? newData?.description : findItem?.description
                                                } as SoVch05D0);
                                            }
                                        })
                                        setThongTinDV(newArr)
                                        total_old_debt(newArr); // cập nhật lại tổng tiền thanh toán
                                    } else {
                                        const copyThongTinDV = [...thongTinDV];
                                        copyThongTinDV.splice(findIndexData, 1, {
                                            ...newData,
                                            gia_nt2: newData.gia2,
                                        } as SoVch05D0);
                                        // Copy data mới để set description
                                        const newArr = [...copyThongTinDV];
                                        copyThongTinDV.map((x, index) => {
                                            if (x.nhom_vt === newData.nhom_vt) {
                                                newArr.splice(index, 1, {
                                                    ...x,
                                                    description: newData?.description !== findItem?.description ? newData?.description : findItem?.description
                                                } as SoVch05D0);
                                            }
                                        })
                                        setThongTinDV(newArr)
                                        total_old_debt(newArr); // cập nhật lại tổng tiền thanh toán
                                    }
                                }
                            }}

                            handleRowInserted={async (e) => {
                                // console.log(e)
                                // setIsChangeInput(true);
                                // total_old_debt();
                                const res: any = await getListDataSource(dataGridRef);
                                const copyThongTinDV = [...thongTinDV];
                                try {
                                    setThongTinDV(res);
                                    total_old_debt(res); // cập nhật lại tổng tiền thanh toán
                                    setIsChangeInput(true);
                                } catch (ex) {
                                    setThongTinDV(copyThongTinDV);

                                    console.warn('Lỗi hóa đơn', ex)
                                }
                            }}
                            showControlDataGridEdit
                        />

                        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                            <Box>
                                <p className="d-flex justify-content-between">
                                    <span className="text-bold pr-3">Phí tháng này: </span>
                                    <span >{FormatMoney(_.sumBy(thongTinDV, 'tt_nt'))} &#8363;</span>
                                </p>
                                <p className="d-flex justify-content-between">
                                    <span className="text-bold pr-3">Nợ kỳ trước: </span>
                                    <span >{FormatMoney(thongTinHD.old_debt)} &#8363;</span>
                                </p>
                                <p className="d-flex justify-content-between">
                                    <span className="text-bold pr-3">Tổng tiền thanh toán: </span>
                                    <span >{FormatMoney(totalMoney)} &#8363;</span>
                                </p>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {
                    isChangeInput && props.action !== ACTIONS.ADD
                    && (
                        <Button
                            variant="contained"
                            onClick={handleSave}
                            color="success"
                        >
                            Lưu
                        </Button>
                    )
                }
                {
                    props.action === ACTIONS.ADD
                    && (
                        <Button
                            variant="contained"
                            // onClick={handleAdd}
                            onClick={handleSave} // 25/01/2024 Đổi vì quy trình mới sẽ tạo hóa đơn trước khi cho người khác cập nhật chỉnh sửa
                            color="success"
                        >
                            Lưu
                        </Button>
                    )
                }
                {
                    thongTinHD.pay_status! < 2
                    && !thongTinHD.islock
                    && (
                        <Button
                            variant="contained"
                            onClick={handlPay}
                        >
                            Thanh toán
                        </Button>
                    )
                }
                {
                    !thongTinHD.islock
                    || thongTinHD.pay_status === 2
                    && (
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleSentBill}
                        >
                            Gửi thông báo
                        </Button>
                    )
                }
                <Button
                    color={"warning"}
                    variant="contained"
                    onClick={overviewBill}
                >
                    In hóa đơn
                </Button>
                <Button onClick={handleClose}>Đóng</Button>
            </DialogActions>
            {/* <BlockUI blocking={isLoading} title={""}></BlockUI> */}
            <DHSLoadPanel open={isLoading} />
        </Dialog >
    );
};

export default ServiceInvoicesClient;
