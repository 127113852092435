import { Header } from "antd/lib/layout/layout";
import React from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Avatar from "@mui/material/Avatar";
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import Logout from "@mui/icons-material/Logout";
import { useHistory } from "react-router-dom";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import "./Navbar.css"
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { setIsReadNotification } from "../../../../redux/actions/Notification";
import moment from "moment";
import 'moment/locale/vi';
import NotificationAdmin from "../Notification/NotificationAdmin";
import DHSApartmentBuildingSelect from "../../../DHSDictionaries/DHSApartmentBuildingSelect/DHSApartmentBuildingSelect";
import { setApartmentBuilding } from "../../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction";
import _ from "lodash";
import { StyleRules, createStyles, makeStyles } from "@mui/styles";
import { QLCCApartmentBuildingModel } from "../../../../app/shared/service-proxies/api-shared";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      color: "white",
      borderBottom: "1px solid #fff",
      "&:hover": {
        // CSS styles khi hover
        outlineColor: "#fff"
      },
    },
    popupIndicator: {
      color: "#fff"
    }
  } as StyleRules<{}, string>),
);


interface IProps {
  collapsed: boolean;
  onChangeCollapsed: (collapsed: boolean) => void;
  username?: string;
  fullName?: string;
  ten_cty?: string;
  nam_tc?: string;
  avatar?: any;
  email?: any;
  phone?: any;
  onLogout?: () => void;
  open?: boolean;
  setOpen?: any;
}
const Navbar = (props: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
  const openMenu = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };


  React.useEffect(() => {
    console.log(apartment_building_id, "44")
  }, [apartment_building_id])

  return (
    <Toolbar
      style={{
        backgroundColor: "#2167a3",
        display: "flex",
        alignItems: "center",
        paddingLeft: 7
      }}
      height={50}
    >
      <Item
        location="before"
        widget="dxButton"
        options={{
          widget: "dxButton",
          icon: "menu",
          location: "before",
          onClick: () => props.setOpen(!props.open),
        }}
      />
      <Item
        location="before"
        locateInMenu="never"
        render={() => <Typography className="hidden-item-mobile-400 animation-holder" fontWeight='600'  color={'#fff'}>{props.ten_cty}</Typography>}
      />
      <Item
        location="after"
        locateInMenu="never"
        cssClass={""}
        render={() => <DHSApartmentBuildingSelect
          classes={useStyles}
          // variant="standard"
          variant="standard"
          value={apartment_building_id}
          defaultValue={(data) => {
            if (_.isEmpty(apartment_building_id)) {
              dispatch(setApartmentBuilding(data[0]))
            }
          }}
          // onValueChange={(e, value, reason) => {
          //   if (reason === "selectOption") {
          //     dispatch(setApartmentBuilding(value))
          //   }
          // }}
          onValueChange={e => {
            dispatch(setApartmentBuilding(e))
          }}
        />}
      />
      <Item
        location="after"
        locateInMenu="never"
        cssClass={"toolbar-notification"}
        render={() => <NotificationAdmin />}
      />
      <Item
        location="after"
        locateInMenu="never"
        render={() => (
          <div>
            <Box
              sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
            >
              <Tooltip title={props.fullName ?? ""}>
                <div
                  onClick={handleClick}
                  style={{ display: 'flex', alignItems: 'center' }}
                >



                  <Typography
                    aria-controls={openMenu ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    color={'#fff'} className='hidden-item-mobile-400 fullName-account'
                    sx={{ ml: 2 }}
                  >
                    {/* {"Tài khoản "} */}
                    {
                      props.username ? props.username : "Người dùng không xác định"
                    }

                    {/* {
                      props.phone ? ` | ${props.phone}` : ` | ${props?.email || "Cập nhật số điện thoại"}`
                    } */}
                  </Typography>
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ mr: 2 }}
                    aria-controls={openMenu ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                  >
                    <Avatar sx={{ width: 32, height: 32 }}>{props.avatar ? props.avatar : props?.username?.[0]}</Avatar>
                  </IconButton>
                </div>
              </Tooltip>{" "}
            </Box>
            <div>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenu}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem>
                  <Avatar /><b>{props.fullName}</b>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => history.push("/admin/change-password")}>
                  <ListItemIcon>
                    <SyncLockIcon fontSize="small" />
                  </ListItemIcon>
                  Đổi mật khẩu
                </MenuItem>
                <MenuItem onClick={props.onLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Đăng xuất
                </MenuItem>
              </Menu>
            </div>
          </div>
        )}
      />
    </Toolbar>
  );
};

export default Navbar;







// import { Header } from "antd/lib/layout/layout";
// import React from "react";
// import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
// import Avatar from "@mui/material/Avatar";
// import {
//   Box,
//   Divider,
//   IconButton,
//   ListItemIcon,
//   Menu,
//   MenuItem,
//   Tooltip,
// } from "@mui/material";
// import SyncLockIcon from "@mui/icons-material/SyncLock";
// import Logout from "@mui/icons-material/Logout";
// import { useHistory } from "react-router-dom";
// import { Typography } from "antd";
// interface IProps {
//   collapsed: boolean;
//   onChangeCollapsed: (collapsed: boolean) => void;
//   username?: string;
//   ten_cty?: string;
//   nam_tc?: string;
//   avatar?: any;
//   email?: any;
//   phone?: any;
//   onLogout?: () => void;
// }
// const Navbar = (props: IProps) => {
//   const history = useHistory();
//   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
//   const open = Boolean(anchorEl);
//   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };
//   return (
//     <Header
//       className="site-layout-background"
//       style={{
//         display: "flex",
//         alignItems: "center",
//         padding: "0px 5px",
//         height: "6vh",
//         borderBottom: "1px solid white",
//         color: "white",
//       }}
//     >
//       {React.createElement(
//         props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
//         {
//           className: "trigger",
//           onClick: () => props.onChangeCollapsed(props.collapsed),
//         }
//       )}
//       <span style={{ paddingLeft: "15px" }}>
//         {props.ten_cty}
//       </span>
//       <div
//         style={{
//           display: "flex",
//           position: "absolute",
//           right: 10,
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         <Box
//           sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
//         >
//           <Tooltip title="Account settings">
//             <div
//               onClick={handleClick}
//             >
//               <span className='hidden-item-mobile-400'>
//                 <b>
//                   {
//                     props.username ? props.username : "Người dùng không xác định"
//                   }
//                 </b>
//                 {
//                   props.phone ? ` | ${props.phone}` : ` | ${props?.email || "Cập nhật số điện thoại"}`
//                 }
//               </span>
//               <IconButton
//                 onClick={handleClick}
//                 size="small"
//                 sx={{ ml: 2 }}
//                 aria-controls={open ? "account-menu" : undefined}
//                 aria-haspopup="true"
//                 aria-expanded={open ? "true" : undefined}
//               >
//                 <Avatar sx={{ width: 32, height: 32 }}>{props.avatar ? props.avatar : props?.username?.[0]}</Avatar>
//               </IconButton>
//             </div>
//           </Tooltip>{" "}
//         </Box>
//         <div>
//           <Menu
//             anchorEl={anchorEl}
//             id="account-menu"
//             open={open}
//             onClose={handleClose}
//             onClick={handleClose}
//             PaperProps={{
//               elevation: 0,
//               sx: {
//                 overflow: "visible",
//                 filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
//                 mt: 1.5,
//                 "& .MuiAvatar-root": {
//                   width: 32,
//                   height: 32,
//                   ml: -0.5,
//                   mr: 1,
//                 },
//                 "&:before": {
//                   content: '""',
//                   display: "block",
//                   position: "absolute",
//                   top: 0,
//                   right: 14,
//                   width: 10,
//                   height: 10,
//                   bgcolor: "background.paper",
//                   transform: "translateY(-50%) rotate(45deg)",
//                   zIndex: 0,
//                 },
//               },
//             }}
//             transformOrigin={{ horizontal: "right", vertical: "top" }}
//             anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
//           >
//             <MenuItem>
//               <Avatar /> <b>{props.username}</b>
//             </MenuItem>
//             <Divider />
//             <MenuItem onClick={() => history.push("/admin/change-password")}>
//               <ListItemIcon>
//                 <SyncLockIcon fontSize="small" />
//               </ListItemIcon>
//               Đổi mật khẩu
//             </MenuItem>
//             <MenuItem onClick={props.onLogout}>
//               <ListItemIcon>
//                 <Logout fontSize="small" />
//               </ListItemIcon>
//               Đăng xuất
//             </MenuItem>
//           </Menu>
//         </div>

//       </div>
//     </Header>
//   );
// };

// export default Navbar;
