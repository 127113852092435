import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer, Notification } from '../../../../components/layouts';
import DHSGridView from '../../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
import { getRoles } from '../../../../redux/actions/RoleActions';
import { AppSession } from '../../../shared/app-session/app-session';
import {
    ApBookClient,
    HomeClient,
    OBCashBankClient,
    QLCCApartmentBuildingModel,
    QLCCClient,
    QLCCVehicleCardModel,
    RoleRightWebModel,
    SysActionType,
} from '../../../shared/service-proxies/api-shared';

import BASE_URL_API from '../../../shared/service-proxies/config';

import { useHistory, useLocation } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import { AppBar, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, Divider, IconButton, InputLabel, MenuItem, Select, Slide, TextField, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import moment from 'moment';
import { ACTIONS } from '../../../../common/enums/DHSToolbarRoleAction';
import DHSToolbarRole from '../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import BlockUI from '../../../../components/Block-UI/Block-ui';
import CTXDate from '../../../../components/CTX/DatePicker';
import DHSDialogAction from '../../../../components/DHS/DHS_DialogAction/DialogAction';
import AllVehicelCardAction from '../../actions/category-vehicle-card-action/all-vehicle-card-action';
import DHSBreadcrumb from '../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import DHSAutoCompleteWithDataSource from '../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const convert = ["success", "error", "warning"];
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any,
    obcash: any;

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null,
    obcash: {},
}

const roleMenu: string = 'CC';
const VehicleVipCard: React.FC<{}> = () => {

    const dispatch = useDispatch();
    const location = useLocation();

    const history = useHistory();

    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();

    const [loading, setLoading] = React.useState<boolean>(false);

    const [idItemSelected, setIdItemSelected] = React.useState<any>();

    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [reload, setReload] = React.useState<boolean>(false);

    const homeClient = new HomeClient(appSession, BASE_URL_API);

    const qlccClient = new QLCCClient(appSession, BASE_URL_API);

    const apBookClient = new ApBookClient(appSession, BASE_URL_API);

    const obCashBankClient = new OBCashBankClient(appSession, BASE_URL_API);

    const [dataTable, setDataTable] = React.useState<QLCCVehicleCardModel[]>([] as QLCCVehicleCardModel[]);

    const [vehicleCard, setVehicleCard] = React.useState<QLCCVehicleCardModel[]>([] as QLCCVehicleCardModel[]);

    const [actCx, setActCx] = useState<IAction>(defaultAction);

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);

    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);

    const [isShowSort, setShowSort] = React.useState<boolean>(false);

    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);

    const customMenu = [
        {
            menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
            onClick: () => {
                setShowSearch(!isShowSearch);
            },
        },
        {
            menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
            onClick: () => {
                setShowGroup(!isShowGroup);
            },
        },
        {
            menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
            onClick: () => {
                setShowSort(!isShowSort);
            },
        },
        {
            menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
            onClick: () => {
                setShowFilter(!isShowFilter);
            },
        },
    ];

    const [apartmentBuildings, setApartmentBuildings] = React.useState<QLCCApartmentBuildingModel[]>([] as QLCCApartmentBuildingModel[]);

    const [apartmentBuilding, setApartmentBuilding] = React.useState<QLCCApartmentBuildingModel>({} as QLCCApartmentBuildingModel);

    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const [date, setDate] = React.useState<Date>(new Date());

    const [optionFindListVehicle, setOptionFindListVehicle] = React.useState<string>("");

    const actionsTodo = [
        {
            id: "is_cancel",
            title: "1. Thẻ hủy",
            titleDate: "Ngày hủy",
            nameDate: "date_cancel",
            isADD: false
        },
        {
            id: "is_return",
            title: "2. Thẻ trả lại",
            titleDate: "Ngày trả",
            nameDate: "date_return",
            isADD: false

        },
        {
            id: "is_vip",
            title: "3. Thẻ VIP",
            titleDate: "Ngày cấp",
            nameDate: "date_add",
            isADD: null
        },
        {
            id: "is_renew",
            title: "4. Thẻ cấp lại",
            titleDate: "Ngày cấp lại",
            nameDate: "date_add",
            isADD: false
        },
        {
            id: "is_sell",
            title: "5. Thẻ bán",
            titleDate: "Ngày bán",
            nameDate: "date_add",
            isADD: true
        },
        {
            id: "is_sell_available",
            title: "6. Thẻ bán ( thẻ có sẵn)",
            titleDate: "Ngày bán",
            nameDate: "date_add",
            isADD: true
        },
        {
            id: "is_change",
            title: "7. Thẻ đổi",
            titleDate: "Ngày đổi",
            nameDate: "date_add",
            isADD: false
        },
        {
            id: "isAll",
            title: " -- Chọn tất cả --",
            titleDate: "",
            nameDate: "",
            isADD: false
        },
    ]


    const onloadData = () => {
        qlccClient.qLCCVehicleCardGet({
            ... new QLCCVehicleCardModel(),
            apartment_building_id: apartmentBuilding.id,
            //  điều kiện lọc
            is_vip: true
        } as QLCCVehicleCardModel)
            .then(res => {
                const arr: any = [];

                res.map(p => {
                    arr.push({
                        ...p,
                        cdate: moment(p.cdate).format("DD/MM/YYYY"),
                        ldate: moment(p.ldate).format("DD/MM/YYYY"),
                        date_add: moment(p.date_add).format('DD/MM/YYYY'),
                        date_cancel: moment(p.date_cancel).format('DD/MM/YYYY'),
                        date_return: moment(p.date_return).format('DD/MM/YYYY'),
                        is_cancel: p.is_cancel ? "Có" : "",
                        is_return: p.is_return ? "Có" : "",
                        is_vip: p.is_vip ? "Có" : "",
                        is_renew: p.is_renew ? "Có" : "",
                        is_sell: p.is_sell ? "Có" : "",
                        is_change: p.is_change ? "Có" : "",
                        is_broken: p.is_broken ? "Có" : "",
                        is_cancel_return_card: p.is_cancel_return_card ? "Có" : "Không",
                        is_sell_available: p.is_sell_available ? "Có" : "",
                    } as any);
                })
                setVehicleCard(res);
                setDataTable([...arr]);
                setLoading(false);
            }).catch(err => {
                console.log(err)
            })

    }

    useHotkeys<HTMLParagraphElement>('alt+1', () => setShowSearch(!isShowSearch));

    useHotkeys<HTMLParagraphElement>('alt+2', () => setShowGroup(!isShowGroup));

    useHotkeys<HTMLParagraphElement>('alt+3', () => setShowSort(!isShowSort));

    useHotkeys<HTMLParagraphElement>('alt+4', () => setShowFilter(!isShowFilter));

    React.useEffect(() => {
        setLoading(true);

        homeClient
            .roleRightWebGetAll({
                ...new RoleRightWebModel(),
                menuid: menu?.menuid,
                rolename: roleMenu,
                username: SessionLogin.userName,
            } as RoleRightWebModel)
            .then((res) => {
                dispatch(getRoles(res));
                setRoles(res);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            })


        qlccClient.qlccApartmentBuildingGet({
            ... new QLCCApartmentBuildingModel(),
            ma_cty: SessionLogin.ma_cty
        } as QLCCApartmentBuildingModel).then(res => {

            setApartmentBuildings(res);

            setApartmentBuilding(res[0]);

            setLoading(false);

        }).catch((err) => {
            console.log(err);
        })



    }, [])

    React.useEffect(() => {


        if (apartmentBuilding && apartmentBuilding.id) {

            setLoading(true);
            onloadData();
        }

    }, [reload, apartmentBuilding, date]);

    const handleClickAction = (action: SysActionType, nowTime: Date) => {

        const obj: string = idItemSelected;

        switch (action?.action_code) {
            case ACTIONS.VIEW:
                setActCx({
                    ...actCx,
                    open: true,
                    type: "VIEW",
                    payload: obj,
                    obcash: vehicleCard.find(data => data.id === obj)
                })
                break;
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: "ADD"
                })
                break;
            case ACTIONS.EDIT:
                setActCx({
                    ...actCx,
                    open: true,
                    type: "EDIT",
                    payload: obj,
                    obcash: vehicleCard.find(data => data.id === obj)
                })
                break;
            case ACTIONS.COPY:
                setActCx({
                    ...actCx,
                    open: true,
                    type: "COPY",
                    payload: obj,
                    obcash: vehicleCard.find(data => data.id === obj)
                })
                break;
            case ACTIONS.DELETE:
                if (window.confirm("Xác nhận xóa")) {
                    setLoading(true);
                    const obcashBank = dataTable.find(p => p.id === obj);
                    qlccClient.qLCCVehicleCardDel({
                        ...new QLCCVehicleCardModel(),
                        id: obj,
                    } as QLCCVehicleCardModel)
                        .then(res => {
                            Notification(convert[res.status === 0 ? 0 : 1], res.message)
                            setLoading(false);
                            if (res.status === 0) {
                                setReload(pre => !pre)
                            }
                        })
                }
                break;
            case ACTIONS.CLOSE:
                setIsOpen(!isOpen);
                break;
            case ACTIONS.SEARCH:
                setIsOpen(!isOpen);
                break;
            case ACTIONS.SAVE:
                onloadData();
                break;
        }
    }


    const handleChangeInput = (e: any) => {

        const { name, value } = e.target;

        if (name === 'apartmentBuilding') {

            const apartmentBuildingSelect: any = apartmentBuildings.find(item => item.id === value);

            if (apartmentBuildingSelect) {
                setApartmentBuilding(apartmentBuildingSelect);
            }

        }

    };

    const handleChangeDate = (e: any) => {

        const { name, value } = e;

        if (name === 'date') {

            setDate(new Date(value));

        }

    };


    return (
        <div className='container-fluid'>


            <AllVehicelCardAction

                open={actCx.open}
                onEditSuccess={() => setReload(!reload)}
                onClose={() => setActCx({ ...defaultAction })}
                action={actCx.type}
                itemSelected={actCx.payload}
                vehicleCard={actCx.obcash}
                apartmentBuildings={apartmentBuildings}
                payload={apartmentBuilding}
                vipAction={true}
            />

            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                 <DHSBreadcrumb
                    location={location}
                />
            </div>

            {/* Control */}
            <DHSToolbarRole
                id={idItemSelected as string}
                menu={menu}
                customMenu={customMenu}
                onClickAction={handleClickAction}
            />

            <DHSGridView
                onSelectRow={(row) => {
                    setIdItemSelected(row);
                }}
                // onListSelectRow={handleListOnselectRow}
                itemClicked={function (): void {
                    throw new Error("Function not implemented.");
                }}
                table_name={menu?.component ?? ''}
                show_form_list={true}
                showSelectBox={false}
                data={dataTable}
                showSortPanel={isShowSort}
                showSearchPanel={isShowSearch}
                showGroupPanel={isShowGroup}
                showFilterPanel={isShowFilter}
                defaultPageSize={20}
                grid_name={"Số dư công nợ phải trả"}
                excel={false}
            />
            <BlockUI blocking={loading} title={""}></BlockUI>


            {/* Lọc dữ liệu */}
            <Dialog
                id='sysMenu4'
                open={isOpen}
                TransitionComponent={Transition}
                onClose={() => setIsOpen(false)}
                aria-describedby="alert-dialog-slide-description"
                className='d-flex justify-content-end'
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            Lọc dữ liệu bảng
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setIsOpen(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent>
                    {/* Mẫu báo cáo */}
                    <Box className='w-100 mt-2'>
                        <InputLabel className='cc-label'>Dự án</InputLabel>
                        <DHSAutoCompleteWithDataSource
                            id="status-select"
                            className="cc-input"
                            dataSource={apartmentBuildings}
                            displayMember="name"
                            valueMember="id"
                            value={apartmentBuilding?.id}
                            onValueChanged={(e, newValue: any) => {
                                handleChangeInput({
                                    target: {
                                        name: "apartmentBuilding",
                                        value: newValue?.id ? newValue.id : null,
                                    },
                                });
                            }}
                        />
                        {/* <Autocomplete
                            id="gender-select"
                            sx={{ width: "100%" }}
                            options={apartmentBuildings}
                            autoHighlight
                            getOptionLabel={(option: any) => option?.name}
                            value={
                                apartmentBuilding?.id
                                    ? apartmentBuildings.find(
                                        (item: any) => item.id === apartmentBuilding.id
                                    )
                                    : null
                            }
                            onChange={(e, newValue: any) => {
                                handleChangeInput({
                                    target: {
                                        name: "apartmentBuilding",
                                        value: newValue?.id ? newValue.id : null,
                                    },
                                });
                            }}

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className="cc-input"
                                    // label="Dự án"
                                    placeholder='Chọn dự án'
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        /> */}
                    </Box>

                    {/* Giữa 2 ngày */}
                    <Box className='w-100 mt-2'>
                        <InputLabel className='cc-label'>Năm</InputLabel>
                        <CTXDate
                            name='date'
                            value={date}
                            onChange={handleChangeDate}
                            className='cc-input w-100'
                            views={["year"]}
                        />
                    </Box>

                </DialogContent>
                <DialogActions>
                    <DHSDialogAction

                        menu={menu}
                        onClickAction={handleClickAction}

                    />
                </DialogActions>
            </Dialog>

        </div >
    )
}

export default VehicleVipCard;


