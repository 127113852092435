import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  TextField,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  InputLabel,
  Box,
  Slide,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  Autocomplete,
} from "@mui/material";
import axios from "axios";

import "./VanDon.css";
import {
  ChoThueXeClient,
  CommonClient,
  CtxDanhMucXeModel,
  HomeClient,
  HRClient,
  KiemSoatCanClient,
  KscThongTinTauModel,
  KscVanDonModel,
  LstInventoryClient,
  RoleRightWebModel,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import notification from "../../../../common/notification/notification";
import CTXToolbar from "./../../../../components/CTX/Toolbar/index";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { useHistory, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

import { TransitionProps } from "@mui/material/transitions";

import BlockUI from "../../../../components/Block-UI/Block-ui";

interface IProps {
  open: boolean;
  itemSelected?: any;
  action?: any;
  onClose: () => void;
  onSubmit?: (data: any, action: any) => void;
}
interface IOpenCamera {
  pic: any;
  open: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});
var appSession: AppSession;
const VanDon = (props: IProps) => {
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

  const [_id, setId] = React.useState<string>();
  const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
  const kscClient = new KiemSoatCanClient(appSession, BASE_URL_API);
  const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);
  const [data, setData] = React.useState<KscVanDonModel>(new KscVanDonModel());
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [loaiHangOptions, setLoaiHangOptions] = React.useState<any>([]);
  const [thongTinTauOptions, setThongTinTauOptions] = React.useState<any>([]);

  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const history = useHistory();

  React.useEffect(() => {
    if (props.action === "UPDATE" || props.action === "DETAIL") {
      kscClient
        .kscVanDonGet({
          ...new KscVanDonModel(),
          id: props.itemSelected,
        } as KscVanDonModel)
        .then((res) => {
          setData(res[0]);
        })
        .catch((err) => alert("Không lấy được dữ liệu"));
      setId(props.itemSelected);
    } else {
      setData(new KscVanDonModel());
      setId(undefined)
    };
  }, [props.action]);

  React.useEffect(() => {
    setLoading(true);
    homeClient
      ?.roleRightWebGetAll({
        ...new RoleRightWebModel(),
        rolename: "KS",
        username: SessionLogin.userName,
        menuid: menu?.menuid,
      } as RoleRightWebModel)
      .then((res) => {
        setRoles(res);
        setLoading(false);
      });

    setLoading(true);
    ctxClient
      .ctxDanhMucXeActions({
        ...new CtxDanhMucXeModel(),
        tbname: "kscLoaiHang",
        action: "GET",
      } as CtxDanhMucXeModel)
      .then((res) => {
        setLoaiHangOptions(res);
        setLoading(false);
      });
    setLoading(true);
    kscClient
      .kscThongTinTauActions({
        ...new KscThongTinTauModel(),
        action_type: "GET",
      } as KscThongTinTauModel)
      .then((res) => {
        setThongTinTauOptions(res);
        setLoading(false);
      });
  }, []);

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value } as KscVanDonModel);
  };
  const handleSubmit = () => {
    if (_id || props.action === "UPDATE") {
      setLoading(true);
      kscClient
        .kscVanDonAction({
          ...data,
          id: _id,
          action_type: "UPDATE",
        } as KscVanDonModel)
        .then((response) => {
          setLoading(false);
          alert(response.message);
        });
    } else {
      setLoading(true);

      kscClient
        .kscVanDonAction({
          ...data,
          action_type: "INSERT",
        } as KscVanDonModel)
        .then((response) => {
          setId(response.id);
          setLoading(false);
          alert(response.message);
        });
    }
  };

  return (
    <Dialog
      id="sysMenu3"
      open={props.open}
      onClose={props.onClose}
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          backgroundColor: "#FFF",
          color: "#1890ff",
          boxShadow: 0,
        }}
      >
        <Toolbar>
          <Typography
            sx={{ flex: 1, fontWeight: 700 }}
            variant="h6"
            component="div"
          >
            {menu?.bar +
              " " +
              `${
                props.action === "DETAIL"
                  ? "[Chi tiết]"
                  : props.action === "UPDATE" || _id
                  ? "[Cập nhật]"
                  : "[Thêm mới]"
              }`}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Divider />
      <DialogContent>
        <Box style={{ width: "100%", margin: "5px 0px" }}>
          <InputLabel sx={{ fontSize: 14 }}>Số vận đơn</InputLabel>
          <TextField
            className="ksc-input"
            sx={{ width: "100%" }}
            name="so_van_don"
            onChange={handleChangeInput}
            value={data.so_van_don}
            disabled={props.action === "DETAIL"}
          />
        </Box>
        <Box style={{ width: "100%", margin: "5px 0px" }}>
          <InputLabel sx={{ fontSize: 14 }}>Tên tàu</InputLabel>
          <Autocomplete
            id="country-select-demo"
            sx={{ width: "100%" }}
            options={thongTinTauOptions}
            autoHighlight
            getOptionLabel={(option: any) =>
              `${option?.ten_tau}${option?.hang_tau_name ? ` (${option.hang_tau_name})` : ""}${option?.bien_so_tau ? ` - ${option.bien_so_tau}` : ""}`
            }
            value={
              data?.ma_tau
                ? thongTinTauOptions.find(
                    (item: any) => item.id === data.ma_tau
                  )
                : null
            }
            onChange={(e, newValue: any) => {
              handleChangeInput({
                target: {
                  name: "ma_tau",
                  value: newValue?.id ? newValue.id : null,
                },
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className="ksc-input"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
            disabled={props.action === "DETAIL"}
          />
        </Box>
        <Box style={{ width: "100%", margin: "5px 0px" }}>
          <InputLabel sx={{ fontSize: 14 }}>Loại hàng</InputLabel>
          <TextField
            className="ksc-input"
            sx={{ width: "100%" }}
            name="loai_hang_id"
            onChange={handleChangeInput}
            value={data.loai_hang_id ? data.loai_hang_id : ""}
            select
            disabled={props.action === "DETAIL"}
          >
            <MenuItem value="">--Chọn--</MenuItem>
            {loaiHangOptions.map((item: any) => (
              <MenuItem value={item.id}>{item.name}</MenuItem>
            ))}
          </TextField>
        </Box>
        <Box style={{ width: "100%", margin: "5px 0px" }}>
          <InputLabel sx={{ fontSize: 14 }}>Chủ hàng</InputLabel>
          <TextField
            className="ksc-input"
            sx={{ width: "100%" }}
            name="chu_hang"
            onChange={handleChangeInput}
            value={data.chu_hang}
            disabled={props.action === "DETAIL"}
          />
        </Box>
        <Box style={{ width: "100%", margin: "5px 0px" }}>
          <InputLabel sx={{ fontSize: 14 }}>Người nhận</InputLabel>
          <TextField
            className="ksc-input"
            sx={{ width: "100%" }}
            name="nguoi_nhan"
            onChange={handleChangeInput}
            value={data.nguoi_nhan}
            disabled={props.action === "DETAIL"}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSubmit}>
          Lưu
        </Button>
        <Button onClick={props.onClose}>Đóng</Button>
      </DialogActions>
      <BlockUI blocking={isLoading} title={""}></BlockUI>
    </Dialog>
  );
};

export default VanDon;
