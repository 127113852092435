import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Switch,
    styled,
    Grid,
    MenuItem,
    Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
    QLCCFloorModel,
    QLCCClient,
    QLCCApartmentBuildingModel,
    QLCCBlockModel,
    SysActionType,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../ApartmentBuilding.css";
import notification from "../../../../common/notification/notification";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BlockAction from "../block-action/BlockAction";
import FloorsListAction from "./FloorsListAction";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import { Notification } from "../../../../components/layouts";
import { TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import { CALL_API_LOAD_APARTMENT_BUILDING_LIST, IDataFilter_02023 } from "../../apartment-building/apartment-building-list";
import { NumberBox, SelectBox, TextArea, TextBox } from "devextreme-react";

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
    dataFilter?: IDataFilter_02023,
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});
interface IAction {
    type: string;
    open: boolean;
    payload: string;
}

var appSession: AppSession;
const ServicesForm: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const convert = ["success", "error", "warning"];
    const [_id, setId] = React.useState<string>();
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);

    const [data, setData] = React.useState<QLCCFloorModel>({} as QLCCFloorModel);
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [apartmentBuildingList, setApartmentBuildingList] = React.useState<QLCCApartmentBuildingModel[]>([]);
    const [blockList, setBlockList] = React.useState<QLCCBlockModel[]>([]);
    const [apartmentBuildingSelect, selectApartmentBuilding] = React.useState<string>("");
    const [floorTotal, setFloorTotal] = React.useState<Number>();
    const [blockId, selectBlock] = React.useState<string>();
    const [actt, setActt] = React.useState<IAction>({
        type: "",
        open: false,
        payload: ""
    })

    const [isShowModal, setIsShowModal] = React.useState<IAction>({
        type: "",
        open: false,
        payload: ""
    });

    const [reload, setReload] = React.useState(false);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)


    async function onLoadDataSourceApartmentBuilding() {
        // const res = await qLCClient
        //     .qlccApartmentBuildingGet({
        //         ... new QLCCApartmentBuildingModel(),
        //         ma_cty: SessionLogin.ma_cty,
        //     } as QLCCApartmentBuildingModel)

        const res = await CALL_API_LOAD_APARTMENT_BUILDING_LIST();
        setApartmentBuildingList(res);
    }

    async function onLoadDataSourceBlock(paramApartment_building_id?: string | undefined) {
        const res = await qLCClient.qlccBlockGet({
            ... new QLCCBlockModel(),
            apartment_building_id: paramApartment_building_id ? paramApartment_building_id : apartment_building_id,
            ma_cty: SessionLogin.ma_cty,
            action_type: "GET"
        } as QLCCBlockModel)
        setBlockList(res);
    }

    React.useEffect(() => {
        if (props.open) {
            onLoadDataSourceApartmentBuilding();
            onLoadDataSourceBlock();
            if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
                setLoading(true);
                qLCClient
                    .qLCCFloorGet({
                        ...new QLCCFloorModel(),
                        id: props.itemSelected,
                        action_type: "GET",
                        apartment_building_id: apartment_building_id,
                        ma_cty: SessionLogin.ma_cty
                    } as QLCCFloorModel)
                    .then((res) => {
                        setData(res[0]);
                        if (res[0].block_id) {
                            selectBlock(res[0].block_id);
                            countFloor(res[0].block_id)
                        }
                        qLCClient
                            .qlccBlockGet({
                                ... new QLCCBlockModel(),
                                id: res[0].block_id,
                                action_type: "GET",
                                apartment_building_id: apartment_building_id,
                                ma_cty: SessionLogin.ma_cty,
                            } as QLCCBlockModel)
                            .then((res) => {
                                if (res[0].apartment_building_id)
                                    selectApartmentBuilding(res[0].apartment_building_id);
                                setLoading(false);
                            })
                            .catch((err) => alert("Không lấy được dữ liệu block"));
                        setLoading(false);
                    })
                    .catch((err) => setLoading(false));
                setId(props.itemSelected);

            } else {
                setData({ ...new QLCCFloorModel(), block_id: props.dataFilter?.block_id } as QLCCFloorModel);
                setId(undefined)
                selectApartmentBuilding(apartment_building_id);
                if (props.dataFilter && props.dataFilter.block_id) {
                    selectBlock(props.dataFilter.block_id);
                    countFloor(props.dataFilter.block_id);
                }
            };
        }
    }, [props.action, props.itemSelected, props.open]);

    React.useEffect(() => {
        if (apartmentBuildingSelect != "") {
            qLCClient
                .qlccBlockGet({
                    ... new QLCCBlockModel(),
                    apartment_building_id: apartmentBuildingSelect,
                    ma_cty: SessionLogin.ma_cty,
                    action_type: "GET"
                } as QLCCBlockModel)
                .then((res) => {
                    setBlockList(res);
                })
        }
    }, [apartmentBuildingSelect]);

    const handleSubmit = (status: string = "") => {
        setLoading(true);
        switch (props.action.toLocaleUpperCase()) {
            case ACTIONS.ADD:
                qLCClient
                    .qLCCFloorAction({
                        ...data,
                        ma_cty: SessionLogin.ma_cty,
                        action_type: "INSERT",
                        apartment_building_id: apartmentBuildingSelect ? apartmentBuildingSelect : apartment_building_id
                    } as QLCCFloorModel)
                    .then((res) => {
                        setLoading(false);
                        notification(convert[res?.status!], res.message);
                        if (res.status === 0) {
                            props.onClose();
                            props.onEditSuccess(true);

                        }
                        setData({
                            ...new QLCCFloorModel(),
                            block_id: blockId,
                        } as QLCCFloorModel);
                        if (blockId != null) {
                            countFloor(blockId);
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        notification(convert[error?.status], error.message);
                    })
                break;
            case ACTIONS.EDIT:
                qLCClient
                    .qLCCFloorAction({
                        ...data,
                        ma_cty: SessionLogin.ma_cty,
                        id: _id,
                        action_type: "UPDATE",
                        apartment_building_id: apartmentBuildingSelect ? apartmentBuildingSelect : apartment_building_id
                    } as QLCCFloorModel)
                    .then((res) => {
                        setLoading(false);
                        notification(convert[res?.status!], res.message);
                        if (res.status === 0) {
                            props.onClose();
                            props.onEditSuccess(true);

                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        notification(convert[error?.status], error.message);
                    })
                break;
            default:
                setLoading(false);
        }
    };

    const countFloor = (value: string) => {
        qLCClient
            .qLCCFloorGet({
                ...new QLCCFloorModel(),
                block_id: value,
                apartment_building_id: apartmentBuildingSelect ? apartmentBuildingSelect : apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCFloorModel)
            .then(res => {
                setFloorTotal(res.length);
            })
    }

    const handleChangeInput = (e: any) => {
        if (props.action !== ACTIONS.VIEW) {
            const { name, value } = e.target;
            if (name === 'apartment_building_id') {
                selectApartmentBuilding(value);
                setData({
                    ...data,
                    block_id: ""
                } as QLCCFloorModel)
            }
            else {
                if (name === 'block_id') {
                    selectBlock(value);
                    countFloor(value);
                }
                setData({ ...data, [name]: value } as QLCCFloorModel);
            }
        }
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.ADD_NEW:
            case ACTIONS.SAVE_NEW:
                if (props.action.toLocaleUpperCase() === ACTIONS.ADD) {
                    qLCClient
                        .qLCCFloorAction({
                            apartment_building_id: apartmentBuildingSelect ? apartmentBuildingSelect : apartment_building_id,
                            ...data,
                            ma_cty: SessionLogin.ma_cty,
                            id: _id,
                            action_type: props.action.toLocaleUpperCase() === ACTIONS.ADD ? "INSERT" : "UPDATE"
                        } as QLCCFloorModel)
                        .then((res) => {
                            setLoading(false);
                            notification(convert[res?.status!], res.message);
                            if (res.status === 0) {
                                props.onEditSuccess(true);
                                setData({
                                    ...new QLCCFloorModel(),
                                    id: undefined,
                                    ma_cty: SessionLogin.ma_cty,
                                    name: undefined,
                                    block_id: blockId,
                                    resident_total: undefined,
                                    description: undefined,
                                    number_floor: undefined,
                                    area: undefined,
                                    apartment_total: undefined,
                                    block_name: blockList.find(item => item.id === blockId)?.name,
                                    apartment_name: undefined,
                                    apartment_building_name: apartmentBuildingList.find(item => item.id === apartmentBuildingSelect)?.name,
                                    action_type: undefined,
                                    status: undefined,
                                    message: undefined,
                                } as QLCCFloorModel);
                                if (blockId != null) {
                                    countFloor(blockId);
                                }
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            notification(convert[error?.status], error.message);
                        })
                }else{
                    handleSubmit();
                }
                break;
            case ACTIONS.SAVE:
            case ACTIONS.ADD:
                // handleSubmit();             
                handleSubmit()
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
        }
    }


    return (
        <div>
            {
                React.useMemo(() => {
                    return (
                        <BlockAction
                            open={actt.open}
                            onClose={() => {
                                setActt({
                                    ...actt,
                                    open: false,
                                });
                            }}
                            action={actt.type}
                            onSubmit={handleSubmit}
                            onEditSuccess={function (is_success: any): void {
                                setReload(pre => !pre)
                            }}
                            itemSelected={blockId}
                        />
                    )
                }, [actt.open])
            }
            {
                React.useMemo(() => {
                    return (
                        <FloorsListAction
                            open={isShowModal.open}
                            onClose={() => {
                                setIsShowModal({
                                    ...isShowModal,
                                    open: false,
                                });
                            }}
                            action={isShowModal.type}
                            onSubmit={handleSubmit}
                            onEditSuccess={function (is_success: any): void {
                                setReload(pre => !pre)
                            }}
                            itemSelected={blockId}
                        />
                    )
                }, [isShowModal.open])
            }
            <Dialog
                id="sysMenu2"
                open={props.open}
                onClose={props.onClose}
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
                TransitionComponent={Transition}
                maxWidth="xs"
                keepMounted={false}
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            {
                                menu?.bar +
                                " " +
                                `${props.action === ACTIONS.VIEW
                                    ? "[Xem chi tiết]"
                                    : props.action === "UPDATE" || _id
                                        ? "[Cập nhật]"
                                        : "[Thêm mới]"
                                }`
                            }
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} sm={6} md={6} >
                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Chung cư</InputLabel>
                                <SelectBox
                                    dataSource={apartmentBuildingList}
                                    value={apartmentBuildingSelect}
                                    onValueChange={selectApartmentBuilding}
                                    displayExpr={'name'}
                                    valueExpr={'id'}
                                    disabled={props.action === ACTIONS.VIEW}
                                    searchEnabled
                                    searchMode="contains"
                                    searchTimeout={500}
                                    placeholder=""
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} >
                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333', display: 'flex', justifyContent: 'space-between' }}>
                                    Tòa nhà
                                    {data.block_id &&
                                        (
                                            <RemoveRedEyeIcon
                                                style={{ fontSize: 16, color: '#537FE7' }}
                                                onClick={() => {
                                                    setActt({ ...actt, open: true, type: ACTIONS.VIEW })
                                                }} />
                                        )}
                                </InputLabel>
                                <SelectBox
                                    dataSource={blockList}
                                    value={blockId}
                                    onValueChange={e => {
                                        handleChangeInput({
                                            target: {
                                                name: "block_id",
                                                value: e
                                            },
                                        });
                                    }}
                                    displayExpr={'name'}
                                    valueExpr={'id'}
                                    disabled={props.action === ACTIONS.VIEW}
                                    searchEnabled
                                    searchMode="contains"
                                    searchTimeout={500}
                                    placeholder=""
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tên tầng</InputLabel>
                                <TextBox
                                    value={data?.name}
                                    onValueChange={e => {
                                        handleChangeInput({
                                            target: {
                                                name: "name",
                                                value: e
                                            },
                                        });
                                    }}
                                    disabled={props.action === ACTIONS.VIEW}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} >
                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>
                                    Tầng số
                                    {
                                        floorTotal
                                            ? (
                                                `(đang có ${floorTotal} tầng)`
                                            ) : ""
                                    }
                                    {
                                        floorTotal
                                            ? <RemoveRedEyeIcon
                                                style={{ fontSize: 16, color: '#537FE7' }}
                                                onClick={() => {
                                                    setIsShowModal({ ...actt, open: true, type: ACTIONS.VIEW, payload: blockId || "" })
                                                }} />
                                            : ""
                                    }
                                </InputLabel>
                                <NumberBox
                                    format="#,###"
                                    value={data?.number_floor}
                                    onValueChange={e => {
                                        handleChangeInput({
                                            target: {
                                                name: "number_floor",
                                                value: e
                                            },
                                        });
                                    }}
                                    disabled={props.action === ACTIONS.VIEW}
                                    min={0}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} >
                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Diện tích (m&#178;)</InputLabel>
                                <NumberBox
                                    format="#,###"
                                    value={data?.area}
                                    onValueChange={e => {
                                        handleChangeInput({
                                            target: {
                                                name: "area",
                                                value: e
                                            },
                                        });
                                    }}
                                    disabled={props.action === ACTIONS.VIEW}
                                    min={0}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tổng số căn hộ</InputLabel>
                                <NumberBox
                                    format="#,###"
                                    value={data?.apartment_total}
                                    onValueChange={e => {
                                        handleChangeInput({
                                            target: {
                                                name: "apartment_total",
                                                value: e
                                            },
                                        });
                                    }}
                                    disabled={props.action === ACTIONS.VIEW}
                                    min={0}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mô tả</InputLabel>
                                <TextArea
                                    value={data?.description}
                                    onValueChange={e => {
                                        handleChangeInput({
                                            target: {
                                                name: "description",
                                                value: e
                                            },
                                        });
                                    }}
                                    disabled={props.action === ACTIONS.VIEW}
                                    height={100}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <DHSDialogAction
                        action={props.action}
                        menu={menu}
                        onClickAction={handleClickAction}
                        isDialogAction={true}
                    />
                </DialogActions>
                <BlockUI blocking={isLoading} title={""}></BlockUI>
            </Dialog>
        </div>
    );
};

export default ServicesForm;
