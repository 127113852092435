import React, { ReactElement, useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import {
    Scheduler,
    Appointments,
    MonthView,
    DateNavigator,
    TodayButton,
} from '@devexpress/dx-react-scheduler-material-ui';
import { appointments, renderData } from './timesheetData';
import { Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { ViewState } from '@devexpress/dx-react-scheduler';
import { HRClient, HRWorkShiftCalendar } from '../../../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../../../shared/service-proxies/config';
import { AppSession } from '../../../../shared/app-session/app-session';
import './timesheet.css'
// import 'jquery.ui.datetimepicker'j
interface IProps {
    label: string;
    disabled: boolean;
    value?: any;
    className: string;
    type: string;
    name: string;
    onChange: (v: any) => void;
}
interface IData {
    title: string;
    startDate: any;
    endDate: any;
}
let appSession: AppSession;
const SchedulerTimesheet = (props: IProps) => {
    const format = "DD/MM/YYYY";
    const clientHR = new HRClient(appSession, BASE_URL_API);
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [data, setData] = useState<IData[]>([]);
    const defaultCurrentDate = (new Date()).getFullYear() + '-' + ((new Date()).getMonth() + 1) + '-01';
    React.useEffect(() => {
        if (clientHR) {
            let param = new HRWorkShiftCalendar();
            param.ma_cty = SessionLogin.ma_cty;
            param.month = (new Date()).getMonth() + 1;
            param.year = (new Date()).getFullYear();
            param.employee_Id = SessionLogin.employeeCode;//SessionLogin.userName
            clientHR?.hRWorkShiftOfEmployeeInMonthGet(param).then((res) => {
                let datas: IData[] = [];
                res.map(dt => {
                    if (!dt.date) dt.date = new Date();
                    datas.push({
                        title: dt.employee_fullname ? dt.employee_fullname + ' [' + dt.check_in + ' - ' + dt.check_out + ']' : '',
                        startDate: new Date(dt.date.getFullYear(), dt.date.getMonth(), dt.date.getDate(), Number(dt.check_in?.split(':')[0]), Number(dt.check_in?.split(':')[1])),
                        endDate: new Date(dt.date.getFullYear(), dt.date.getMonth(), dt.date.getDate(), Number(dt.check_out?.split(':')[0]), Number(dt.check_out?.split(':')[1]))
                    })
                })
                let t = renderData(datas)
                setData(t);
            });
        }
    }, []);
    const handleChangeDate = (e: any) => {
        props.onChange(e);
    };

    return (
        <Paper>
            {
                data.length > 0 ? (
                    <Scheduler
                        data={data}
                    >
                        <ViewState
                            defaultCurrentDate={defaultCurrentDate}
                        />
                        <MonthView />
                        <MonthView />
                        <Toolbar />
                        <DateNavigator />
                        <TodayButton />
                        <Appointments />
                    </Scheduler>
                ) : <></>
            }

        </Paper>
    );
};
export default SchedulerTimesheet;
