import React, { ReactElement, useState, useEffect, useRef } from "react";
import { Spin, Row, Col } from "antd";
import logo from "../../../../Asset/image/LOGIN/New-logo-Minh-Dung/Logo-MinhDung.png";
import { useDispatch, useSelector } from "react-redux";
import { Login } from "../../../../redux/actions/actions";
import "./index.css";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import _ from "lodash";
import BASE_URL_API from "../../../../app/shared/service-proxies/config";
import {
  Company,
  FiscalYear,
  HomeClient,
  LoginClient,
} from "../../../../app/shared/service-proxies/api-shared";
import { AppSession } from "../../../../app/shared/app-session/app-session";
import { Button, InputAdornment, MenuItem, TextField } from "@mui/material";
// import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from "@mui/icons-material/Lock";
import { getMessagingToken } from "../../../../firebase";
import { Notification as notification } from "../..";
import { TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import { STATUS_MESSAGE } from "../../../../common/Interfaces/StatusMessage";

interface Props { }
interface IData {
  Company: string;
  UserName: string;
  PassWord: string;
  ten_cty: string;
  nam_tc: number;
}
let appSession: AppSession;
export default function LoginPage({ }: Props): ReactElement {
  const loginClient = new LoginClient(appSession, BASE_URL_API);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [listCompany, setListCompany] = React.useState<Company[]>();
  const [listFiscalYear, setListFiscalYear] = React.useState<FiscalYear[]>();
  const [isgrantedNoti, setIsGrantedNoti] = React.useState<boolean>(false);

  const isLogin = useSelector(
    (state: IReducer) => state.AdminPageReducer?.isLogin
  );
  const [data, setData] = React.useState<IData>({
    Company: "",
    UserName: "",
    PassWord: "",
    ten_cty: "",
    nam_tc: new Date().getFullYear(),
  });

  const refUser = useRef<HTMLInputElement>(null);
  const refPass = useRef<HTMLInputElement>(null)


  useEffect(() => {
    loginClient.listCompany().then((res) => {
      setListCompany(res);
      if (res && res[0]?.ma_cty) {
        setData({ ...data, Company: res[0].ma_cty });
      }
    });
    loginClient
      .getAllFiscalYear2({ ...new FiscalYear(), ma_cty: "001" } as FiscalYear)
      .then((res) => {
        setListFiscalYear(res);
      });
  }, [isLogin]);

  useEffect(() => {
    try {
      if (Notification.permission === "granted") {
        setIsGrantedNoti(true);
      }
      else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            setIsGrantedNoti(true)
          }
        })
      }
    } catch { }
  }, [])

  const validation = () => {
    if (data.UserName === undefined || data.UserName === '' || data.UserName.length === 0) {
      notification(
        TYPE_MESSAGE.ERRORR,
        STATUS_MESSAGE[`USERNAME`]
      );
      // if (refUser.current) {
      //   refUser.current.focus();
      // }
      return false;
    }
    if (data.PassWord === undefined || data.PassWord === '' || data.PassWord.length === 0) {
      // if (refPass.current) {
      //   refPass.current.focus();
      // }
      notification(
        TYPE_MESSAGE.ERRORR,
        STATUS_MESSAGE[`PASSWORD`]
      );
      return false;
    }
    return true;
  }

  const handleSubmit = async () => {
    if (validation()) {
      let firebaseTokenWeb = isgrantedNoti ? await getMessagingToken() : "";
      setLoading(true);
      setTimeout(() => {
        dispatch(
          Login({
            ma_cty: data.Company ? data.Company : '001',
            userName: data.UserName,
            passWord: data.PassWord,
            ten_cty: data.ten_cty,
            nam_tc: data.nam_tc,
            token_notifi_web: firebaseTokenWeb ?? ""
          })
        );
        setLoading(false);
      }, 750);
    }
  };


  return (
    <div className="login">
      <Row>
        <Col span={24}>
          <Spin spinning={isLoading}>
            <div className="box-img" style={{ width: "100%" }}>
              <img
                src={logo}
                className="logoDHS"
                alt="Logo Minh Dũng"
              />
            </div>
            <div
              className="p-4"
            >
              <Row
                gutter={[16, 16]}
              >
                <Col span={24} style={{ margin: "5px 0px 5px 0px" }}>
                  <TextField
                    inputRef={refUser}
                    className="w-100 input-light pl-2"
                    placeholder="Nhập tài khoản"
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start" >
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={data.UserName}
                    onChange={(e: any) => setData({ ...data, UserName: (e.target.value as string).toUpperCase() })}
                    onKeyDown={e => {
                      if (e.key.toLocaleUpperCase() === "ENTER") {
                        handleSubmit();
                      };
                    }}
                    variant="standard"
                  />
                </Col>
                <Col span={24} style={{ margin: "5px 0px 5px 0px" }}>
                  <TextField
                    inputRef={refPass}
                    className="w-100  input-light pl-2"
                    name="PassWord"
                    placeholder="Nhập mật khẩu"
                    type="password"
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={data.PassWord}
                    onChange={(e: any) => setData({ ...data, PassWord: e.target.value })}
                    onKeyDown={e => {
                      if (e.key.toLocaleUpperCase() === "ENTER") {
                        handleSubmit();
                      };
                    }}
                    variant="standard"
                  />
                </Col>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 15,
                  }}
                >
                  <Button
                    style={{
                      width: "100%",
                      fontWeight: "800",
                      // backgroundColor: "rgb(42 88 152)",
                      backgroundColor: "#00377c"
                    }}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Đăng nhập
                  </Button>
                </Col>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "#ffffff",
                  }}
                >
                  <a href="." className="text-white font-weight-normal">Quên mật khẩu</a>
                  <a href="/apartment-building/resident/register" className="text-white font-weight-normal">Đăng ký</a>
                </Col>
              </Row>
            </div>
          </Spin>
        </Col>
      </Row>
    </div>
  );
}
