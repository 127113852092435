import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from '../../../components/layouts';
import { getRoles } from '../../../redux/actions/RoleActions';
import { AppSession } from '../../shared/app-session/app-session';
import { ChoThueXeClient, HomeClient, QLCCResidentsModel, QLCCClient, RoleRightWebModel, SysModel, QLCCApartmentBuildingModel, QLCCBlockModel, QLCCFloorModel, QLCCApartmentModel, SysActionType } from '../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../shared/service-proxies/config';
import { useHistory, useLocation } from 'react-router-dom';
import BlockUI from '../../../components/Block-UI/Block-ui';
import ResidentAction from '../actions/resident-action/ResidentAction';
import notification from '../../../common/notification/notification';
import { Autocomplete, Box, TextField } from '@mui/material';
import DHSBreadcrumb from '../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import DHSToolbarRole from '../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DataGrid from 'devextreme-react/data-grid';
import { EventInfo } from 'devextreme/events';
import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import { ACTIONS } from '../../../common/enums/DHSToolbarRoleAction';
import { getDataRowSelected, getIndexRowSelected } from '../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../common/Interfaces/StatusMessage';
import { setApartmentBuilding } from '../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction';
import DHSAutoCompleteWithDataSource from '../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource';

var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

interface IFilter {
    apartment_building_id?: string | null,
    block_id?: string | null,
    floor_id?: string | null,
    apartment_id?: string | null
}

const defaultFilter: IFilter = {
    apartment_building_id: null,
    block_id: null,
    floor_id: null,
    apartment_id: null
}

const roleMenu: string = 'CC';
const status_message = ['success', 'error']
const ResidentList: React.FC<{}> = () => {

    const dispatch = useDispatch();
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const location = useLocation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    // const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<QLCCResidentsModel[]>([] as QLCCResidentsModel[]);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const [filter, setFilter] = useState<IFilter>(defaultFilter);

    // 

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

    //

    const [apartmentBuildingOptions, setApartmentBuildingOptions] = React.useState<any[]>([]);
    const [blockOptions, setBlockOptions] = React.useState<any[]>([]);
    const [floorOptions, setFloorOptions] = React.useState<any[]>([]);
    const [apartmentOptions, setApartmentOptions] = React.useState<any[]>([]);

    // 


    // useEffect(() => {
    //     setLoading(true);
    //     qLCClient.qlccApartmentBuildingGet({
    //         ...new QLCCApartmentBuildingModel(),
    //         ma_cty: SessionLogin.ma_cty,
    //         action_type: 'GET'
    //     } as QLCCApartmentBuildingModel)
    //         .then(response => {


    //             setApartmentBuildingOptions(response)
    //             // if (response) {
    //             //     setFilter(pre => ({ ...pre, apartment_building_id: response[0].id }))
    //             // }
    //             setLoading(false);
    //         });

    // }, []);


    React.useEffect(() => {
        console.log(filter)
    }, [filter])


    React.useEffect(() => {
        setFilter(pre => ({ ...pre, apartment_building_id: apartment_building_id }))

    }, [apartment_building_id])

    // Block filter by apartment building

    React.useMemo(() => {

        setLoading(true);
        if (filter?.apartment_building_id) {
            qLCClient.qlccBlockGet({
                ...new QLCCBlockModel(),
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: filter.apartment_building_id,
                action_type: 'GET'
            } as QLCCBlockModel)
                .then(response => {

                    setBlockOptions(response)
                    // if (response) {
                    //     setFilter(pre => ({ ...pre, block_id: response[0].id }))
                    // }

                    setLoading(false);
                });
        }
        if (!filter.apartment_building_id) {
            setFilter(defaultFilter)
        }

    }, [filter.apartment_building_id])

    // Floor filter by block

    React.useMemo(() => {

        if (filter?.apartment_building_id) {
            setLoading(true);
            qLCClient.qLCCFloorGet({
                ...new QLCCFloorModel(),
                block_id: filter.block_id,
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: filter?.apartment_building_id,
                action_type: 'GET'
            } as QLCCFloorModel)
                .then(response => {

                    setFloorOptions(response)
                    setLoading(false);
                });
        }
        if (!filter.block_id) {
            setFilter(pre => ({ ...pre, floor_id: null, apartment_id: null }))
        }

    }, [filter.block_id, filter?.apartment_building_id])

    // Apartment filter by floor

    React.useMemo(() => {

        if (filter?.apartment_building_id) {
            setLoading(true);
            qLCClient.qLCCApartmentsGet({
                ...new QLCCApartmentModel(),
                floor_id: filter.floor_id,
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: filter?.apartment_building_id,
                action_type: 'GET'
            } as QLCCApartmentModel)
                .then(response => {

                    setApartmentOptions(response)
                    // if (response) {
                    //     setFilter(pre => ({ ...pre, floor_id: response[0]?.id }))
                    // }

                    setLoading(false);
                });
        }
        if (!filter.floor_id) {
            setFilter(pre => ({ ...pre, apartment_id: null }))
        }

    }, [filter.floor_id, filter?.apartment_building_id])


    useEffect(() => {
        if (filter?.apartment_building_id) {
            setLoading(true)
            qLCClient.qLCCResidentsGet({
                ...new QLCCResidentsModel(),
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: filter?.apartment_building_id,
                block_id: filter?.block_id,
                floor_id: filter?.floor_id,
                apartment_id: filter?.apartment_id
            } as QLCCResidentsModel).then((res) => {

                setLoading(false)

                const tempData: any[] = []
                res.forEach(resident => {
                    tempData.push({
                        ...resident,
                        is_water: resident.is_water ? "Đã đăng ký" : "Chưa đăng ký",
                        // date_of_birth: moment(resident.date_of_birth).format("DD/MM/yyyy"),
                        // move_in_date: moment(resident.move_in_date).format("DD/MM/yyyy"),
                        // move_out_date: moment(resident.move_out_date).format("DD/MM/yyyy"),
                        // type: typeOptions.find(item => item.id === resident.type)?.name
                    })
                })
                setDataTable(tempData)
            })
        }
    }, [reload, filter])

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {

        switch (action?.action_code?.toUpperCase()) {
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<QLCCResidentsModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            if (window.confirm("Bạn có chắc chắn muốn xóa?")) {
                                setLoading(true)
                                qLCClient.qlccResidentsDel({
                                    ...new QLCCResidentsModel(),
                                    id: dataSelected?.id,
                                    ma_cty: dataSelected?.ma_cty,
                                    apartment_building_id: filter?.apartment_building_id
                                } as QLCCResidentsModel)
                                    .then((res) => {
                                        setLoading(false)
                                        setIdItemSelected("")
                                        notification(status_message[res.status!], res.message);
                                        // setReload(!reload)
                                        if (res.status === 0) {
                                            const tempData = [...dataTable];
                                            const index = tempData.findIndex(x => x.id === dataSelected?.id);
                                            tempData.splice(index, 1);
                                            setDataTable(tempData)
                                        }
                                    });
                            }
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: action?.action_code?.toUpperCase() ?? "",
                    // payload: selectedItemKeys?.id ?? null,
                });
                break;
            case ACTIONS.EDIT:
            case ACTIONS.VIEW:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<QLCCResidentsModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: action?.action_code?.toUpperCase() ?? "",
                                payload: {
                                    id: dataSelected?.id ?? null,
                                    ma_cty: dataSelected?.ma_cty
                                },
                            });
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
        }
    }

    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;

        setFilter(pre => ({ ...filter, [name]: value } as IFilter));
    };

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                console.log(keys);

                const index = e.component.getRowIndexByKey(keys[0]);

                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
            });
        }
    };

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };


    return (


        <div className='container-fluid'>

            {
                actCx.open && <ResidentAction
                    open={actCx.open}
                    onClose={function (): void {
                        setActCx(defaultAction);
                    }}
                    action={actCx.type}
                    itemSelected={actCx.payload}
                    onEditSuccess={function (data: SysModel): void {
                        setReload(pre => !pre)
                    }}

                    apartment_id={filter?.apartment_id}
                >

                </ResidentAction>
            }

            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location}
                />
            </div>

            <div className='row no-gutters my-1'>
                {/* Dự án */}
                {/* <div className="col-md-12 col-xl-2 pr-1">
                    <Box style={{ width: "100%", margin: "5px 0px" }}>

                        <InputLabel htmlFor='apartment-building-select' variant='filled' sx={{ fontSize: 14, color: '#333' }}>Chung cư</InputLabel>
                        <DHSAutoCompleteWithDataSource
                            id="apartment-building-select"
                            className="cc-input"
                            dataSource={apartmentBuildingOptions}
                            value={
                                filter?.apartment_building_id
                            }
                            displayMember='name'
                            valueMember="id"
                            onValueChanged={(e, newValue: any) => {
                                dispatch(setApartmentBuilding(newValue?.id ?? ""))
                            }}

                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option?.id}>
                                        {option?.name}
                                    </li>
                                );
                            }}
                            placeholder='Chọn chung cư'
                        />


                    </Box>
                </div> */}

                {/* Tòa nhà */}
                <div className="col-md-12 col-xl-2 pr-1">
                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                        {/* <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tòa nhà</InputLabel> */}
                        {
                            <DHSAutoCompleteWithDataSource
                                id="block-select"
                                dataSource={blockOptions}
                                displayMember='name'
                                valueMember='id'
                                value={filter?.block_id}
                                placeholder='Chọn tòa nhà (block)'
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "block_id",
                                            value: newValue?.id ?? null,
                                        },
                                    });
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option?.id}>
                                            {option?.name}
                                        </li>
                                    );
                                }}


                            />}
                    </Box>
                </div>
                {/* Tầng */}
                <div className="col-md-12 col-xl-2 pr-1">
                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                        {/* <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tầng</InputLabel> */}
                        {
                            <DHSAutoCompleteWithDataSource
                                id="floor-select"
                                dataSource={floorOptions}
                                displayMember='name'
                                valueMember='id'
                                placeholder='Chọn tầng'
                                value={filter?.floor_id}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "floor_id",
                                            value: newValue?.id ?? null,
                                        },
                                    });
                                }}

                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option?.id}>
                                            {option?.name}
                                        </li>
                                    );
                                }}


                            />}
                    </Box>
                </div>
                {/* Căn hộ */}
                <div className="col-md-12 col-xl-2 pr-1">
                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                        {/* <InputLabel sx={{ fontSize: 14, color: '#333' }}>Căn hộ</InputLabel> */}
                        {
                            <DHSAutoCompleteWithDataSource
                                id="apartment-select"
                                dataSource={apartmentOptions}
                                displayMember='name'
                                valueMember='id'
                                placeholder='Chọn căn hộ'
                                value={filter?.apartment_id}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "apartment_id",
                                            value: newValue?.id ?? null,
                                        },
                                    });
                                }}

                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option?.id}>
                                            {option?.name}
                                        </li>
                                    );
                                }}


                            />}
                    </Box>
                </div>
            </div>

            <DHSToolbarRole
                id={' '}
                menu={menu}
                customMenu={[]}
                onClickAction={handleClickAction}
            />


            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                keyExpr="id"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handleSelectionChanged={handleSelectionChanged}
                handlePageChange={handlePageChange}

            // handleExporting={handleExporting}
            />
            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default ResidentList;


