import DropDownBox from "devextreme-react/drop-down-box";
import { OptionChangedEventInfo } from "devextreme/core/dom_component";
import { dxDropDownBoxOptions } from "devextreme/ui/drop_down_box";
import DHSDataGridEdit from "../DataGridEdit";
import React, { useEffect, useState } from "react";
import {
  QLCCClient,
  QLCCResidentJobsModel,
} from "../../../../app/shared/service-proxies/api-shared";
import { AppSession } from "../../../../app/shared/app-session/app-session";
import BASE_URL_API from "../../../../app/shared/service-proxies/config";
import DataGrid from "devextreme-react/data-grid";
import { EventInfo } from "devextreme/events";
import dxDataGrid, { SelectionChangedInfo } from "devextreme/ui/data_grid";
import { getDataRowSelected, getKeyRowSelected } from "../helper-datagrid-edit-get-row";
const dropDownOptions = { width: 500 };

type Props = {
  event: any;
};
let appSession: AppSession;
const DataGridSelectBox = (event: any,valueExpr: any, displayExpr: any, tableDesign: any, is_readonly: any, allowEdit: any) => {
  const [isDropDownOpened, setIsDropDownOpened] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const dataGridRef = React.useRef<DataGrid>(null);
  const [selectedRowKey, setSelectedRowKey] = useState<string | number>(event.data.value);

  const boxOptionChanged = (e: any) => {
    if (e.name === "opened") {
      setIsDropDownOpened(e.value);
    }
    // setIsDropDownOpened(false)
  };

  useEffect(() => {
    setData([...event.data.column.lookup.dataSource]);
  }, []);

  const handleSelectItem = async (e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>) => {
    // const item = await getDataRowSelected(dataGridRef);
    const key = await getKeyRowSelected(dataGridRef);

    event.data.setValue(key);
    
    setSelectedRowKey(key)
    setIsDropDownOpened(false);
    
  }

  return (
    <DropDownBox
      onOptionChanged={boxOptionChanged}
      opened={isDropDownOpened}
      dropDownOptions={dropDownOptions}
      dataSource={data}
      value={selectedRowKey}
      displayExpr={displayExpr}
      valueExpr={valueExpr}
      readOnly={
        is_readonly ? true : !allowEdit
      }
      contentRender={() => (
        <DHSDataGridEdit
          data={[...event.data.column.lookup.dataSource]}
          table_name={tableDesign}
          keyExpr={valueExpr}
          mode="popup"
          dataGridRef={dataGridRef}
          allowEdit={false}
          isPagination={true}
          isMultiple={false}
          groupingAutoExpandAll={false}
          height={40}
          defaultFilter
          handleSelectionChanged={handleSelectItem}
        />
      )}
    ></DropDownBox>
  );
};

export default DataGridSelectBox;
