import { formatDate } from "devextreme/localization";
import { ViewType } from "devextreme/ui/scheduler";

export const formatStartEndDateFromView = (currentView: ViewType, startDate: Date, endDate: Date) => {
    switch (currentView as string) {
        case "day":
        case "timelineDay":
        case "week":
        case "timelineWeek":
        case "workWeek":
        case "timelineWorkweek":
            {
                return `${formatDate(startDate, "h:mm a")} - ${formatDate(endDate, "h:mm a")}`
            }
        case "month":
        case "timelineMonth":
            {
                return `${formatDate(startDate, "MMMM d")} ${formatDate(startDate, "h:mm a")} - ${formatDate(endDate, "h:mm a")}`
            }
        default: return "Định dạng còn thiếu xót";
    }
}