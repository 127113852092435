import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { Box, IconButton, InputLabel } from "@mui/material";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import BASE_URL_API from "../../../shared/service-proxies/config";
import axios from "axios";
import notification from "../../../../common/notification/notification";
import BlockUI from "../../../../components/Block-UI/Block-ui";

interface IProps {
    action: string,
    image?: string,
    video?: string,
    dataMediaFile: (data_file: any) => void
}

const ApartmentMediaFileAction: React.FC<IProps> = (props) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const [apartmentImageList, setApartmentImageList] = React.useState<string[]>([] as string[]);
    const [apartmentVideo, setApartmentVideo] = React.useState<string>();
    // 
    const [isLoading, setLoading] = React.useState<boolean>(false);
    // 


    React.useEffect(() => {
        // if (apartmentImageList.length > 0 || apartmentVideo) {
            props.dataMediaFile({
                data_media: {
                    apartmentImageList,
                    apartmentVideo,
                },
            });
        // }
    }, [apartmentImageList, apartmentVideo]);

    React.useMemo(() => {
        if (props.image)
            setApartmentImageList(props.image!.split(";"))
    }, [props.image])

    React.useMemo(() => {
        if (props.video)
            setApartmentVideo(props.video)

    }, [props.video])


    const handleRemoveFile = (e: any) => {
        const { name, idx } = e;
        
        if (name && name === 'apartment_image') {
            // setData({
            //     ...data,
            //     [name]: ''
            // })

            const tempApartmentImageList = [...apartmentImageList];
            tempApartmentImageList.splice(idx, 1);
            setApartmentImageList(tempApartmentImageList)

        }
        else if (name && name === 'apartment_video') {
            setApartmentVideo("")
        }
    }

    const handleChangeFile = async (e: any, idx?: number) => {
        
        const { name, files } = e.target;
        const formData = new FormData();
        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };
        for (const file of files) {
            formData.append("MyFile", file);
        }
        setLoading(true)

        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    switch (name) {
                        case "apartment_image":
                            const tempApartmentImageList = [...apartmentImageList]
                            tempApartmentImageList[idx!] = res.data[0].path;
                            setApartmentImageList(tempApartmentImageList);
                            setLoading(false)

                            break;
                        case "apartment_video":
                            setApartmentVideo(res.data[0].path)
                            setLoading(false)

                            break;
                        default:
                            setLoading(false)
                            notification("error", "Lỗi không upload được hình ảnh");
                            break;
                    }
                } else notification("warning", "Không lấy được hình ảnh");
            });

        e.target.value = '';

    }

    const handleAddNewFile = async (e: any) => {
        
        const { name, files } = e.target;
        const formData: FormData = new FormData();

        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };

        for (const file of files) {
            formData.append("MyFile", file);
        }
        setLoading(true)
        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    let tempArr: string[] = [];
                    res.data.forEach((data: any, pathIndex: number) => {
                        tempArr.push(data.path);
                    });
                    switch (name) {
                        case "apartment_image":
                            setApartmentImageList([...apartmentImageList].concat(tempArr));
                            break;
                        case "apartment_video":
                            setApartmentVideo(res.data[0].path);
                            break;
                    }
                } else notification("warning", "Không lấy được hình ảnh");
                setLoading(false);
            });

        // reset lại file input

        e.target.value = '';

    }
    return (
        <>
            <div className="row">
                <div className="col-12">
                    {/* Hình căn hộ */}
                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                        <div className="d-flex align-items-center">
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>
                                Hình căn hộ
                            </InputLabel>
                            {/* <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                disabled={props.action === "DETAIL"}
                            >
                                <PhotoCamera
                                    id="apartment_image"
                                    name="apartment_image"
                                    onClick={(e: any) => showCamera(e, "apartment_image")}
                                />
                            </IconButton> */}
                        </div>
                        <div style={{ display: 'flex', overflowX: 'auto', gap: '3px' }}>
                            {apartmentImageList.length > 0 && apartmentImageList.map((path, idx) => (<div key={idx} style={{
                                position: 'relative',
                                width: "324px",
                                maxWidth: "360px",
                                height: "204px",
                                maxHeight: "240px",
                                flexShrink: 0
                            }}>
                                <img
                                    src={`${BASE_URL_API}${path}` }
                                    alt="Hình căn hộ"
                                    className="image-shadow"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: '0.25rem'
                                    }}
                                />
                                <div
                                    className="position-absolute w-100 d-flex justify-content-end"
                                    style={{ top: 1, right: 1, cursor: "pointer" }}
                                >
                                    <IconButton
                                        color="error"
                                        aria-label="upload picture"
                                        component="label"
                                        onClick={() => handleRemoveFile({
                                            name: "apartment_image",
                                            idx: idx
                                        })
                                        }
                                        disabled={props.action === "DETAIL"}
                                    >
                                        <CancelIcon  sx={{backgroundColor: "#fff", borderRadius: "50%"}}/>
                                    </IconButton>
                                </div>
                                <div
                                    className="position-absolute w-100 d-flex justify-content-end"
                                    style={{ top: 27, right: 1, cursor: "pointer" }}
                                >
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="label"
                                        disabled={props.action === "DETAIL"}
                                    >
                                        <input
                                            id={`uploadFrontImageUpdate`}
                                            className="flex-grow-1 form-control"
                                            type="file"
                                            accept="image/*"
                                            name="apartment_image"
                                            onChange={
                                                (e) => handleChangeFile(e, idx)
                                            }
                                            // value={data.anh_xe}
                                            style={{ display: "none" }}
                                        />
                                        <ChangeCircleIcon  sx={{backgroundColor: "#fff", borderRadius: "50%"}}/>
                                    </IconButton>
                                </div>
                            </div>))}

                            <div className="m-1">
                                <label htmlFor={`uploadApartmentImageAdd`} style={{ width: "100%" }}>
                                    <div
                                        className="c-img-card"
                                        style={{
                                            width: "324px",
                                            maxWidth: "360px",
                                            height: "204px",
                                            maxHeight: "240px",
                                            borderRadius: '12px'
                                        }}
                                    >
                                        <i className="bi bi-images" />
                                        &nbsp;Thêm ảnh mới...
                                    </div>
                                </label>
                                <input
                                    id="uploadApartmentImageAdd"
                                    className="flex-grow-1 form-control"
                                    type="file"
                                    accept="image/*"
                                    name="apartment_image"
                                    multiple
                                    onChange={handleAddNewFile}
                                    // value={data.anh_xe}
                                    style={{ display: "none" }}
                                    disabled={props.action === "DETAIL"}
                                />
                            </div>
                        </div>


                    </Box>

                    {/* Video căn hộ */}
                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                        <div className="d-flex align-items-center">
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>
                                Video căn hộ
                            </InputLabel>
                            {/* <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                disabled={props.action === "DETAIL"}
                            >
                                <PhotoCamera
                                    id="apartment-video"
                                    name="apartment_video"
                                    onClick={(e: any) => showCamera(e, "apartment_video")}
                                />
                            </IconButton> */}
                        </div>
                        {apartmentVideo ? (<div style={{
                            position: "relative", width: "324px",
                            maxWidth: "360px",
                            height: "204px",
                            maxHeight: "240px",
                        }}>
                            <video
                                src={`${BASE_URL_API}${apartmentVideo}`}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: '5px',
                                    objectFit: 'fill'
                                }}
                                className="image-shadow"
                                controls
                                muted
                            />
                            <div
                                className="position-absolute w-100 d-flex justify-content-end"
                                style={{ top: 1, right: 1, cursor: "pointer" }}
                            >
                                <IconButton
                                    color="error"
                                    aria-label="upload picture"
                                    component="label"
                                    onClick={() => handleRemoveFile({
                                        name: "apartment_video"
                                    })
                                    }
                                    disabled={props.action === "DETAIL"}
                                >
                                    <CancelIcon />
                                </IconButton>
                            </div>
                            <div
                                className="position-absolute w-100 d-flex justify-content-end"
                                style={{ top: 27, right: 1, cursor: "pointer" }}
                            >
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    disabled={props.action === "DETAIL"}
                                >
                                    <input
                                        id={`uploadVideoUpdate`}
                                        className="flex-grow-1 form-control"
                                        type="file"
                                        accept="video/*"
                                        name="apartment_video"
                                        onChange={
                                            (e) => handleChangeFile(e)
                                        }
                                        // value={data.anh_xe}
                                        style={{ display: "none" }}
                                    />
                                    <ChangeCircleIcon />
                                </IconButton>
                            </div>
                        </div>) : (
                            <div className="m-1">
                                <label htmlFor={`uploadVideoAdd`} style={{ width: "100%"}} className="label-file-media">
                                    <div
                                        className="c-img-card"
                                        style={{
                                            width: "324px",
                                            maxWidth: "360px",
                                            height: "204px",
                                            maxHeight: "240px",
                                            borderRadius: '12px'
                                        }}
                                    >
                                        <i className="bi bi-images" />
                                        &nbsp;Thêm video mới...
                                    </div>
                                </label>
                                <input
                                    id="uploadVideoAdd"
                                    className="flex-grow-1 form-control"
                                    type="file"
                                    accept="video/*"
                                    name="apartment_video"
                                    onChange={handleAddNewFile}
                                    // value={data.anh_xe}
                                    style={{ display: "none" }}
                                    disabled={props.action === "DETAIL"}
                                />
                            </div>
                        )}
                    </Box>
                </div>
            </div>
            <BlockUI blocking={isLoading} title={""}></BlockUI>

        </>
    )
}


export default ApartmentMediaFileAction;