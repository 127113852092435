import React from "react";
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,

    Divider,
    Autocomplete,
    Switch,
    Select,
    MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import {
    HomeClient,
    QLCCClient,
    RoleRightWebModel,
    QLCCApartmentBuildingModel,
    QLCCApartmentTypesModel,
    LstUnitModel,
    SysActionType,
    LstItem,
    ItemClient,
    ItemGroupClient,
    ObItem,
    CommonClient,
    ParaObjExchageRate,
    OBItemClient,
} from "../../../shared/service-proxies/api-shared";


import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../../actions/ApartmentBuilding.css";
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import notification from "../../../../common/notification/notification";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import { httpPost } from "../../../../common/httpService";
import axios from "axios";
import NumberBox from "devextreme-react/number-box";
import DHSAutoCompleteWithDataGrid from "../../../../components/DHS/DHS_AutoCompleteCustomer/DHSAutoCompleteWithDataGrid";
import DHSDateTimeComponentV2 from "../../../../components/DHS/DHS-DateTime/DHSDateTimeComponentV2";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";
interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (id?: string, dataEdited?: LstItem) => void;
    onAddSuccess: (id?: string, dataAdded?: LstItem) => void;
}
// interface IOpenCamera {
//     pic: any;
//     open: boolean;
// }

// const videoConstraints = {
//     width: 1280,
//     height: 720,
//     facingMode: "user",
// };

// const image_size_citizen_identification_card = {
//     width: 540,
//     height: 360,
// };

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const status_message = ["success", "error"]
var appSession: AppSession;
var htmlString: string;
const ItemBalanceAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const qlccClient = new QLCCClient(appSession, BASE_URL_API);
    const obItemClient = new OBItemClient(appSession, BASE_URL_API);
    // truyền id dự án
    const [data, setData] = React.useState<ObItem>({} as ObItem);
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const [isDefaultPre, setIsDefaultPre] = React.useState<boolean>();

    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const commonClient = new CommonClient(appSession, BASE_URL_API);
    const itemClient = new ItemClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [exchangeRate, setExchangeRate] = React.useState<number>(0);
    const [fullScreen, setFullscreen] = React.useState<boolean>(false);


    const [apartmentTypeOptions, setApartmentTypeOptions] = React.useState<any>(
        []
    );
    const [itemOptions, setItemOptions] = React.useState<any>(
        []
    );
    const [inventoryOptions, setInventoryOptions] = React.useState<any>(
        []
    );
    const [currencyOptions, setCurrencyOptions] = React.useState<any>(
        []
    );
    // Vị trí
    const [locationOptions, setLoacationOptions] = React.useState<any>(
        []
    );
    const [unitOptions, setUnitOptions] = React.useState<any>([]);
    // const [windowDirectionOptions, setWindowDirectionOptions] =
    //     React.useState<any>([]);
    // const [elevatorLocationOptions, setElevatorLocationOptions] =
    //     React.useState<any>([]);
    // const [emergencyExitLocationOptions, setEmergencyExitLocationOptions] =
    //     React.useState<any>([]);

    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    React.useEffect(() => {

        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            obItemClient
                .obItemGet({
                    ...new ObItem(),
                    idItem: props.itemSelected.idItem,
                    ma_kho: props.itemSelected.ma_kho,
                    ma_cty: SessionLogin.ma_cty,
                    apartment_building_id: apartment_building_id,
                } as ObItem | any)
                .then((res) => {
                    setData(res[0]);
                })
                .catch(() => alert("Không lấy được dữ liệu"));
            setId(props.itemSelected);

        } else {

            setData({
                ...new ObItem(),
                // apartment_building_code: props?.itemSelected?.apartment_building_id,
                // status: utilityStatusOptions[0].id
                apartment_building_id: apartment_building_id,
            } as ObItem | any);
            setId(undefined)
        };
    }, [props.action, props.itemSelected]);

    React.useEffect(() => {
        if (data?.ma_nt) {
            commonClient.getExchangeRate5({
                ...new ParaObjExchageRate(),
                ma_cty: SessionLogin.ma_cty,
                ma_nt: data?.ma_nt,
                ngay_ct: new Date()
            } as ParaObjExchageRate)
                .then(res => {
                    if (!res) {
                        setExchangeRate(0)

                    }
                    else {
                        setExchangeRate(res)
                        if (data?.tien_nt) {
                            setData(pre => ({ ...pre, tien: data?.so_luong ? res * data.tien_nt! * data?.so_luong! : res * data.tien_nt! } as ObItem))
                        }
                    }
                })
        }
    }, [data?.ma_nt])


    React.useEffect(() => {

        setLoading(true);
        homeClient
            ?.roleRightWebGetAll({
                ...new RoleRightWebModel(),
                rolename: menu?.moduleid,
                username: SessionLogin.userName,
                menuid: menu?.menuid,
            } as RoleRightWebModel)
            .then((res) => {
                setRoles(res);
                setLoading(false);
            });
        // setLoading(true);
        // qlccClient
        //     .qlccApartmentBuildingGet({
        //         ...new QLCCApartmentBuildingModel(),
        //         ma_cty: SessionLogin.ma_cty,
        //         action_type: 'GET'
        //     } as QLCCApartmentBuildingModel)
        //     .then(res => {
        //         setApartmentBuildingOption(res);
        //     })
        // setLoading(true);

        // qlccClient
        //     .qLCCApartmentTypesActions({
        //         ...new QLCCApartmentTypesModel(),
        //         action_type: 'GET'
        //     } as QLCCApartmentTypesModel)
        //     .then(res => {
        //         setApartmentTypeOptions(res)
        //         setLoading(false);
        //     });
        setLoading(true);

        qlccClient.qlccLstUnitGet({
            ...new LstUnitModel(),
            ma_cty: SessionLogin.ma_cty
        } as LstUnitModel)
            .then(res => {
                setLoading(false);
                setUnitOptions(res)
            })

        // httpPost<string>('api/Common/LoadFilterList', {
        //     tablelist: "dm_nhom_tien_ich"
        // })
        //     .then(res => {

        //         const utilities: { id: string, name: string }[] = JSON.parse(res.data);
        //         setUtilityGroupOptions(utilities)
        //         setLoading(false)

        //     });
        setLoading(true);
        itemClient.lstItemGet({
            ...new LstItem(),
            mA_CTY: SessionLogin.ma_cty
        } as LstItem)
            .then(response => {
                setItemOptions(response);
                setLoading(false)

            })
        httpPost<string>('api/Common/LoadFilterList', {
            tablelist: "dm_khok",
            ma_cty: SessionLogin.ma_cty,
            apartment_building_id: apartment_building_id,
        })
            .then(res => {

                const inventories: { ma_kho: string, ten_kho: string }[] = JSON.parse(res.data);
                setInventoryOptions(inventories)
                setLoading(false)

            });
        httpPost<string>('api/Common/LoadFilterList', {
            tablelist: "dm_nt",
            ma_cty: SessionLogin.ma_cty,
            apartment_building_id: apartment_building_id,
        })
            .then(res => {

                const currencies: { ma_nt: string, ten_nt: string }[] = JSON.parse(res.data);
                setCurrencyOptions(currencies)
                setLoading(false)

            });

        httpPost<string>('api/Common/LoadFilterList', {
            tablelist: "dm_vitri",
            ma_cty: SessionLogin.ma_cty,
            apartment_building_id: apartment_building_id,
        })
            .then(res => {

                const locations: { ma_vitri: string, ten_vitri: string }[] = JSON.parse(res.data);
                setLoacationOptions(locations)
                setLoading(false)

            });
    }, [apartment_building_id]);

    const handleCheckRequired = (): boolean => {
        if (!data?.idItem) {
            return false;
        }

        return true;
    }

    const handleSubmit = (action?: string) => {
        if (!handleCheckRequired()) {
            notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE['FIELD_REQUIRED'])
            return;
        }
        setLoading(true)
        if (_id || props.action === ACTIONS.EDIT) {

            obItemClient.obItemUpd({
                ...new ObItem(),
                ...data,
                ma_cty: SessionLogin.ma_cty,
                luser: SessionLogin.userName,

            } as ObItem)
                .then((response) => {
                    setLoading(false);
                    notification(status_message[response.status!], STATUS_MESSAGE[`${props.action}_${response.status}`]);
                    if (response.status === 0) {
                        props.onEditSuccess(response.id, data);
                        props.onClose();
                    }


                });



        }
        else {
            obItemClient.obItemIns({
                ...new ObItem(),
                ...data,
                ma_cty: SessionLogin.ma_cty,
                luser: SessionLogin.userName,
                cuser: SessionLogin.userName,
            } as ObItem)
                .then((response) => {
                    setLoading(false);
                    notification(status_message[response.status!], STATUS_MESSAGE[`${props.action}_${response.status}`])

                    if (response.status === 0) {
                        props.onAddSuccess(response.id, data);
                        props.onClose();
                    }
                });
        }
    };

    const handleChangeInput = (e: any) => {
        const { name, value, checked, type } = e.target;

        if (type === 'checkbox') {
            setData(pre => ({ ...pre, [name]: checked } as LstItem));

        }
        else setData(pre => ({ ...pre, [name]: value } as LstItem));
    };










    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                handleSubmit();
                // handleOpen()
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
        }
    }



    const onChangeChecked = (_: any, checked: boolean) => {

        setData(pre => ({
            ...pre,
            ksd: checked,
        } as LstItem));
    };

    return (
        <Dialog
            // id="sysMenu"
            open={props.open}
            onClose={props.onClose}
            // sx={{
            //     display: "flex",
            //     justifyContent: "flex-end",
            // }}
            PaperProps={{
                sx: !fullScreen ? {
                    minHeight: "90vh",
                    height: "90vh"
                } : undefined
            }}
            TransitionComponent={Transition}
            maxWidth="lg"
            fullWidth
            fullScreen={fullScreen}
            className="Asset-control"
        >
            {/* <Dialog
                open={openCamera.open}
                onClose={handleCloseCamera}
                sx={{ height: "inherit" }}
            >
                
            </Dialog> */}

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === "UPDATE" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1.25 }}>
                        <IconButton
                            edge="end"

                            onClick={() => {
                                setFullscreen(pre => !pre)
                            }}
                            aria-label="full screen"
                        >
                            {fullScreen ? <FullscreenExitIcon sx={{ color: '#1f2937' }} /> : <FullscreenIcon sx={{ color: '#1f2937' }} />}
                        </IconButton>
                        <IconButton
                            edge="end"
                            onClick={() => {
                                props.onClose();
                            }}
                            aria-label="close"
                        >
                            <CloseIcon
                                sx={{ color: '#1f2937' }}
                            />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <div className="row gutter-1">
                    <div className="col-xl-6">
                        {/* Mã thẻ xe */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                required
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Mã thẻ xe
                            </InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                autoFocus
                                placeholder="Mã thẻ xe ..."
                                name="sku"
                                onChange={handleChangeInput}
                                value={data?.sku ?? null}
                                inputProps={{
                                    autoComplete: "new-password",
                                    readOnly: props.action === ACTIONS.VIEW
                                    // type: "search" // disable autocomplete and autofill
                                }}
                            // disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box> */}
                        {/* Tên vật tư */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                required
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Tên vật tư
                            </InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                              
                                placeholder="Tên vật tư ..."
                                name="teN_VT"
                                onChange={handleChangeInput}
                                value={data?.teN_VT ?? null}
                                inputProps={{
                                    autoComplete: "new-password",
                                    readOnly: props.action === ACTIONS.VIEW
                                    // type: "search" // disable autocomplete and autofill
                                }}
                            // disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box> */}
                        {/* Tên VT IN */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                required
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Tên VT IN
                            </InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                               
                                placeholder="Tên VT IN ..."
                                name="teN_VT_IN"
                                onChange={handleChangeInput}
                                value={data?.teN_VT_IN ?? null}
                                inputProps={{
                                    autoComplete: "new-password",
                                    readOnly: props.action === ACTIONS.VIEW
                                    // type: "search" // disable autocomplete and autofill
                                }}
                            // disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box> */}
                        {/* Tên loại xe */}
                        <div className="card">

                            <div className="card-body pt-2">
                                <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                    Thông tin vật tư
                                </div>
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel
                                        required
                                        sx={{ fontSize: 14, color: "#333", }}
                                    >
                                        Chọn vật tư
                                    </InputLabel>
                                    <DHSAutoCompleteWithDataGrid
                                        id="itme-select"
                                        value={data?.idItem ?? null}
                                        // tabIndex={-1}
                                        table_name="VehicleCardData"
                                        // placeholder="Chọn khách hàng"
                                        // filter={props.payload}
                                        name="iditem"
                                        valueExpr={"iditem"}
                                        options={itemOptions}
                                        dataGridOnSelectionChanged={(e, newValue: any) => {

                                            handleChangeInput({
                                                target: {
                                                    name: "idItem",
                                                    value: newValue?.iditem ? newValue.iditem : null,
                                                },
                                            });


                                        }}
                                        syncDataGridSelection={function (e): void {

                                            handleChangeInput({
                                                target: {
                                                    name: "idItem",
                                                    value: e.value ?? null,
                                                },
                                            });

                                        }}
                                        gridBoxDisplayExpr={function (item: any): string {
                                            return item && `${item.sku} - ${item.teN_VT}`;
                                        }}

                                        readOnly={props.action === ACTIONS.VIEW}

                                    />
                                    {/* <Autocomplete
                                        id="item-select"
                                        sx={{ width: "100%" }}
                                        options={itemOptions}
                                        autoHighlight
                                        getOptionLabel={(option: any) => option?.ten_vt}
                                        value={
                                            data?.idItem
                                                ? itemOptions.find(
                                                    (item: any) => item.iditem === data?.idItem
                                                )
                                                : null
                                        }
                                        onChange={(e, newValue: any) => {
                                            handleChangeInput({
                                                target: {
                                                    name: "idItem",
                                                    value: newValue?.iditem ? newValue.iditem : null,
                                                },
                                            });




                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className="cc-input"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password",
                                                    // type: "search" // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                        readOnly={props.action === ACTIONS.VIEW}
                                    /> */}
                                </Box>




                                {/* Năm */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Năm</InputLabel>
                                    <DHSDateTimeComponentV2
                                        value={data?.nam ? new Date(data?.nam, 1, 1, 0, 0, 0) : null}
                                        name={"nam"}
                                        type="date"
                                        zoomLevel="year"
                                        maxZoomLevel="decade"
                                        pickerType="calendar"
                                        format="yyyy"
                                        onChange={(e) => {
                                            const { name, value } = e;
                                            setData(pre => ({
                                                ...pre,
                                                [name]: (value as Date).getFullYear()
                                            } as ObItem))
                                        }}
                                        applyValueMode="instantly"
                                        disabled={props.action === ACTIONS.VIEW}
                                    />

                                </Box>

                            </div>
                        </div>

                        <div className="card mt-2">

                            <div className="card-body pt-2">
                                <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                    Kho, vị trí
                                </div>
                                {/* Kho */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel

                                        sx={{ fontSize: 14, color: "#333", }}
                                    >
                                        Kho
                                    </InputLabel>
                                    <DHSAutoCompleteWithDataSource
                                        id="item-select"
                                        className="cc-input"
                                        dataSource={inventoryOptions}
                                        value={data?.ma_kho}
                                        displayMember='ten_kho'
                                        valueMember="ma_kho"
                                        onValueChanged={(e, newValue: any) => {
                                            handleChangeInput({
                                                target: {
                                                    name: "ma_kho",
                                                    value: newValue?.ma_kho ? newValue.ma_kho : null,
                                                },
                                            });
                                        }}
                                        readOnly={props.action === ACTIONS.VIEW}
                                    />
                                    {/* <Autocomplete
                                        id="item-select"
                                        sx={{ width: "100%" }}
                                        options={inventoryOptions}
                                        autoHighlight
                                        getOptionLabel={(option: any) => option?.ten_kho}
                                        value={
                                            data?.ma_kho
                                                ? inventoryOptions.find(
                                                    (item: any) => item.ma_kho === data?.ma_kho
                                                )
                                                : null
                                        }
                                        onChange={(e, newValue: any) => {
                                            handleChangeInput({
                                                target: {
                                                    name: "ma_kho",
                                                    value: newValue?.ma_kho ? newValue.ma_kho : null,
                                                },
                                            });




                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className="cc-input"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password",
                                                    // type: "search" // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                        readOnly={props.action === ACTIONS.VIEW}
                                    /> */}
                                </Box>
                                {/* Vị trí */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel

                                        sx={{ fontSize: 14, color: "#333", }}
                                    >
                                        Vị trí
                                    </InputLabel>
                                    <DHSAutoCompleteWithDataSource
                                        id="item-select"
                                        className="cc-input"
                                        dataSource={locationOptions}
                                        value={data?.ma_vitri}
                                        displayMember='ten_vitri'
                                        valueMember="ma_vitri"
                                        onValueChanged={(e, newValue: any) => {
                                            handleChangeInput({
                                                target: {
                                                    name: "ma_vitri",
                                                    value: newValue?.ma_vitri ? newValue.ma_vitri : null,
                                                },
                                            });
                                        }}
                                        readOnly={props.action === ACTIONS.VIEW}
                                    />
                                    {/* <Autocomplete
                                        id="item-select"
                                        sx={{ width: "100%" }}
                                        options={locationOptions}
                                        autoHighlight
                                        getOptionLabel={(option: any) => option?.ten_vitri}
                                        value={
                                            data?.ma_vitri
                                                ? locationOptions.find(
                                                    (item: any) => item.ma_vitri === data?.ma_vitri
                                                )
                                                : null
                                        }
                                        onChange={(e, newValue: any) => {
                                            handleChangeInput({
                                                target: {
                                                    name: "ma_vitri",
                                                    value: newValue?.ma_vitri ? newValue.ma_vitri : null,
                                                },
                                            });




                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className="cc-input"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password",
                                                    // type: "search" // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                        readOnly={props.action === ACTIONS.VIEW}
                                    /> */}
                                </Box>
                            </div>
                        </div>





                        {/* Trạng thái tiện ích*/}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Trạng thái tiện ích
                            </InputLabel>
                            <Autocomplete
                                id="main-direction-select"
                                sx={{ width: "100%" }}
                                options={utilityStatusOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.status
                                        ? utilityStatusOptions.find(
                                            (item: any) => (item.name as string).toLowerCase() === data.status?.toLowerCase()
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "status",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                readOnly={props.action === ACTIONS.VIEW}
                            />
                        </Box> */}





                    </div>
                    {/* chỗ này cắt div*/}
                    <div className="col-xl-6">
                        {/* Người sử dụng */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Người sử dụng
                            </InputLabel>

                            <Autocomplete
                                id="main-direction-select"
                                sx={{ width: "100%" }}
                                options={mainDirectionOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.apartment_id
                                        ? mainDirectionOptions.find(
                                            (item: any) => item.id === data.apartment_id
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "apartment_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password",
                                            // type: "search" // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box> */}
                        <div className="card ">

                            <div className="card-body pt-2">
                                <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                    Thông tin ngoại tệ
                                </div>
                                {/* Ngoại tệ */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel
                                        required
                                        sx={{ fontSize: 14, color: "#333", }}
                                    >
                                        Ngoại tệ
                                    </InputLabel>
                                    <DHSAutoCompleteWithDataSource
                                        id="item-select"
                                        className="cc-input"
                                        dataSource={currencyOptions}
                                        value={data?.ma_nt}
                                        displayMember='ma_nt'
                                        valueMember="ma_nt"
                                        onValueChanged={(e, newValue: any) => {
                                            handleChangeInput({
                                                target: {
                                                    name: "ma_nt",
                                                    value: newValue?.ma_nt ? newValue.ma_nt : null,
                                                },
                                            });
                                        }}
                                        readOnly={props.action === ACTIONS.VIEW}
                                    />
                                    {/* <Autocomplete
                                        id="item-select"
                                        sx={{ width: "100%" }}
                                        options={currencyOptions}
                                        autoHighlight
                                        getOptionLabel={(option: any) => `${option?.ma_nt}`}
                                        value={
                                            data?.ma_nt
                                                ? currencyOptions.find(
                                                    (item: any) => item.ma_nt === data?.ma_nt
                                                )
                                                : null
                                        }
                                        onChange={(e, newValue: any) => {
                                            handleChangeInput({
                                                target: {
                                                    name: "ma_nt",
                                                    value: newValue?.ma_nt ? newValue.ma_nt : null,
                                                },
                                            });




                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className="cc-input"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password",
                                                    // type: "search" // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                        readOnly={props.action === ACTIONS.VIEW}
                                    /> */}
                                </Box>

                            </div>
                        </div>
                        <div className="card mt-2">

                            <div className="card-body pt-2">
                                <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                    Thông tin tiền tệ
                                </div>

                                {/* Số lượng */}

                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số lượng</InputLabel>
                                    <NumberBox
                                        // className="cc-input"
                                        // format="0,###"
                                        inputAttr={{ 'aria-label': 'Fixed Poing Format', 'style': 'text-align:right' }}
                                        defaultValue={data?.so_luong ?? undefined}
                                        value={data?.so_luong ?? undefined}

                                        // style={{
                                        //     textAlign: "right"
                                        // }}
                                        showSpinButtons
                                        onValueChange={(value) => {
                                            handleChangeInput({
                                                target: {
                                                    name: "so_luong",
                                                    value: value
                                                }
                                            });
                                            if (data?.tien_nt && value > 0 && exchangeRate) {
                                                setData(pre => ({ ...pre, tien: value * data.tien_nt! * exchangeRate } as ObItem))
                                            }

                                        }}

                                        readOnly={props.action === ACTIONS.VIEW}

                                    />


                                </Box>

                                {/* Đơn giá */}

                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Đơn giá</InputLabel>
                                    <NumberBox
                                        // className="cc-input"
                                        format="0,###"
                                        inputAttr={{ 'aria-label': 'Fixed Poing Format', 'style': 'text-align:right' }}
                                        defaultValue={data?.tien_nt ?? undefined}
                                        value={data?.tien_nt ?? undefined}

                                        style={{
                                            textAlign: "right"
                                        }}
                                        onValueChange={(value) => {
                                            handleChangeInput({
                                                target: {
                                                    name: "tien_nt",
                                                    value: value
                                                }
                                            });

                                            if (data?.so_luong && exchangeRate) {
                                                setData(pre => ({ ...pre, tien: value * data.so_luong! * exchangeRate } as ObItem))
                                            }


                                        }}

                                        readOnly={props.action === ACTIONS.VIEW}
                                    // readOnly={true}

                                    />


                                </Box>

                                {/* Thành tiền*/}

                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Thành tiền</InputLabel>
                                    <NumberBox
                                        // className="cc-input"
                                        format="0,###"
                                        inputAttr={{ 'aria-label': 'Fixed Poing Format', 'style': 'text-align:right' }}
                                        defaultValue={data?.tien ?? undefined}
                                        value={data?.tien ?? undefined}

                                        // style={{
                                        //     textAlign: "right"
                                        // }}
                                        // onValueChange={(value) => {
                                        //     handleChangeInput({
                                        //         target: {
                                        //             name: "tien",
                                        //             value: value
                                        //         }
                                        //     });
                                        //     if(data?.so_luong && exchangeRate){
                                        //         setData(pre => ({...pre, tien_nt: value * data.so_luong! * exchangeRate} as ObItem))
                                        //     }

                                        // }}

                                        // readOnly={props.action === ACTIONS.VIEW}
                                        readOnly

                                    />


                                </Box>


                            </div>
                        </div>

                        {/* <Box className="row">
                            <Box className="col-6">
                                <InputLabel sx={{ fontSize: 14, color: '#333', }}>
                                   Không sử dụng

                                </InputLabel>
                                <Switch
                                    checked={data?.ksd}
                                    onChange={onChangeChecked}
                                    disabled={props.action === ACTIONS.VIEW}
                                />
                            </Box>

                        </Box> */}



                        {/* Sự dụng cho dự án */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333" }}
                            >
                                Sử dụng cho dự án
                            </InputLabel>
                            <Autocomplete
                                id="apartment-select"
                                sx={{ width: "100%" }}
                                multiple
                                filterSelectedOptions
                                options={apartmentBuildingOption}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.apartment_building_code
                                        ? apartmentBuildingOption.filter(
                                            (item: any) => {
                                                return data.apartment_building_code?.split(";").includes(item.id)
                                            }

                                        )
                                        : []
                                }
                                onChange={(e, newValue: any) => {


                                    handleChangeMultipleInput({
                                        target: {
                                            name: "apartment_building_code",
                                            value: newValue ?? null,
                                        },
                                    });
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password"// disable autocomplete and autofill
                                        }}
                                        placeholder="Dự án ..."


                                    />
                                )}
                                readOnly={props.action === ACTIONS.VIEW}
                            />
                        </Box> */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <div className="d-flex align-items-center">
                                <InputLabel sx={{ fontSize: 14, color: "#000" }}>
                                    Tải hình
                                </InputLabel>

                            </div>
                            {data?.anh ? (<div style={{
                                position: "relative", width: "213px",
                                maxWidth: "213px",
                                height: "213px",
                                maxHeight: "213px",
                            }}>
                                <img
                                    src={`${BASE_URL_API}${data?.anh}`}
                                    alt="Hình"
                                    className="image-shadow"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: 'cover'
                                    }}
                                />
                                <div
                                    className="position-absolute w-100 d-flex justify-content-end"
                                    style={{ top: 1, right: 1, cursor: "pointer" }}
                                >
                                    <IconButton
                                        color="error"
                                        aria-label="upload picture"
                                        component="label"
                                        onClick={() => handleRemoveImage({
                                            name: "anh"
                                        })}
                                        disabled={props.action === ACTIONS.VIEW}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </div>
                                <div
                                    className="position-absolute w-100 d-flex justify-content-end"
                                    style={{ top: 27, right: 1, cursor: "pointer" }}
                                >
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="label"
                                        disabled={props.action === ACTIONS.VIEW}
                                    >
                                        <input
                                            id={`uploadImageUpdate`}
                                            className="flex-grow-1 form-control"
                                            type="file"
                                            accept="image/*"
                                            name="anh"
                                            onChange={
                                                (e) => handleChangeImage(e)
                                            }
                                            // value={data.anh_xe}
                                            style={{ display: "none" }}
                                        />
                                        <ChangeCircleIcon />
                                    </IconButton>
                                </div>
                            </div>) : (
                                <div className="m-1">
                                    <label htmlFor={`uploadImageAdd`} style={{ width: "100%" }} className="label-file-media">
                                        <div
                                            className="c-img-card"
                                            style={{
                                                width: "213px",
                                                maxWidth: "213px",
                                                height: "213px",
                                                maxHeight: "213px",
                                                borderRadius: '5px'
                                            }}
                                        >
                                            <i className="bi bi-images" />
                                            &nbsp;Thêm ảnh mới...
                                        </div>
                                    </label>
                                    <input
                                        id="uploadImageAdd"
                                        className="flex-grow-1 form-control"
                                        type="file"
                                        accept="image/*"
                                        name="anh"
                                        onChange={handleAddNewImage}
                                        // value={data.anh_xe}
                                        style={{ display: "none" }}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />
                                </div>
                            )}
                        </Box> */}


                    </div>
                    {/* <div className="col-xl-6">
                       

                      
                    </div> */}

                    {/* <div className="col-xl-6"> */}
                    {/* Loại căn hộ */}
                    {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Loại căn hộ
                            </InputLabel>
                            <Autocomplete
                                id="apartment-select"
                                sx={{ width: "100%" }}
                                multiple
                                filterSelectedOptions
                                options={apartmentTypeOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.apartment_type_name}
                                value={
                                    data?.apartment_type_id
                                        ? apartmentTypeOptions.filter(
                                            (item: any) => {
                                                return data.apartment_type_id?.split(";").includes(item.id)
                                            }
                                        )
                                        : []
                                }
                                onChange={(e, newValue: any) => {


                                    handleChangeMultipleInput({
                                        target: {
                                            name: "apartment_type_id",
                                            value: newValue ?? null,
                                        },
                                    });
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password"// disable autocomplete and autofill
                                        }}
                                        placeholder="Áp dụng cho loại căn hộ ..."


                                    />
                                )}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box> */}


                    {/* </div> */}
                    {/* 
                    <div className="col-xl-12 mt-3">
                        <Accordion expanded={isExpandApartmentTypeService} onChange={() => { setIsCalledApartmentTypeService(true); setIsExpandApartmentTypeService(pre => !pre) }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>tiện ích loại căn hộ</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ApartmentTypeServiceList />
                            </AccordionDetails>
                        </Accordion>
                    </div> */}
                    {/* Mặt định */}

                    {/* <div className="col-xl-6">
                        <Box style={{ width: "100%", margin: "5px 0px" }}>


                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mặc định</InputLabel>

                            <FormControlLabel control={
                                <Switch
                                    color="primary"
                                    onChange={handleChangeInput}
                                    name="is_default"
                                    value={data?.is_default}
                                    checked={data?.is_default}
                                    disabled={props.action === ACTIONS.VIEW}
                                />}
                                label={
                                    <Typography
                                        sx={{ fontSize: 14, color: '#333' }}>
                                        Không/Có
                                    </Typography>} />


                        </Box>
                    </div> */}

                    {/* Ghi chú */}

                    {/* <div className="col-12">
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Ghi chú
                            </InputLabel>
                            <RichTextEditor

                                onChange={(stringData) => handleChangeRichText(stringData)}
                                // onChange={(newValue) => handleChangeInput({ target: { name: "service_description", value: stringData } })}
                                value={data?.ghI_CHU ?? ""}
                                readOnly={props.action === ACTIONS.VIEW}
                            />
                        </Box>
                    </div> */}
                </div>
            </DialogContent>
            <DialogActions>

                <DHSDialogAction

                    action={props.action}
                    menu={menu}
                    onClickAction={handleClickAction}
                />


            </DialogActions>

            {/* <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Xác nhận thay đổi phí vận hành
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {totalApartmentMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation sx={{ textTransform: "capitalize" }} autoFocus variant="contained" color="primary" onClick={() => handleSubmit()}>
                        Chập nhận
                    </Button>
                    {
                        actionUpdateService === "UPDATE" && <Button disableElevation sx={{ textTransform: "capitalize" }} color="error" onClick={() => {
                            handleSubmit("UPDATE-BUT-NOT-DELETE");
                        }}>Không</Button>
                    }
                    <Button disableElevation sx={{ textTransform: "capitalize" }} onClick={handleClose}>Hủy</Button>
                </DialogActions>
            </Dialog> */}
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default ItemBalanceAction;
