import { Box, Card, Paper, Stack } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DHSBreadcrumb from '../../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb'
import BlockUI from '../../../../../components/Block-UI/Block-ui'
import { useLocation } from 'react-router-dom'
import WorkReportStackUser from '../component/stack-user'
import WorkReportCalendar from '../component/calendar'
import moment from 'moment'
import DHSToolbarRole from '../../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole'
import { useSelector } from 'react-redux'
import { IReducer } from '../../../../../components/layouts'
import { HomePageClient, LstDepartment, ParaObjTableList, QLCCClient, QLCCTaskModel, QLCCTaskStatusModel, SysActionType, UserInfoFilterModel } from '../../../../shared/service-proxies/api-shared'
import { AppSession } from '../../../../shared/app-session/app-session'
import BASE_URL_API from '../../../../shared/service-proxies/config'
import DHSSelectBox from '../../../../../components/DHSComponents/editors/DHSSelectBox/DHSSelectBox'
import { httpPost } from '../../../../../common/httpService'
import { OptionChangedEventInfo } from 'devextreme/core/dom_component'
import dxScheduler from 'devextreme/ui/scheduler'
import { ItemClickEvent } from 'devextreme/ui/list'
import DHSButton from '../../../../../components/DHSComponents/actions-and-list/DHSButton/DHSButton'
import { Chart, DataGrid } from 'devextreme-react'
import { ArgumentAxis, CommonSeriesSettings, Legend, Margin, Series } from 'devextreme-react/chart'
import WorkFilterData, { IDataFilter, TYPE_VIEW } from '../component/filter'
import DHSDataGridEdit from "../../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { getDataRowSelected } from '../../../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row'
import TaskCheckListAction from '../../../actions/work/task-check-list-action'
import { ACTIONS } from '../../../../../common/enums/DHSToolbarRoleAction'
import WorkReportGANTT from '../component/gantt'
import TaskListAction from '../../../actions/work/task-list-action'


var appSession: AppSession;

const WorkReportFeedBack = () => {
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(false);
    const schedulerRef = useRef<any>(null);
    const listRef = useRef<any>(null);
    const dataFilterRef = useRef<any>(null);
    const dataSource1_TASK_Ref = useRef<any>(null);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const qlccHomePage = new HomePageClient(appSession, BASE_URL_API);
    const qlccClient = new QLCCClient(appSession, BASE_URL_API);
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
    const [dataFilter, setDataFilter] = useState<QLCCTaskModel>();
    const [views, setViews] = useState<TYPE_VIEW>();
    const [dataSource1_DEPARTMENT, setDataSource] = useState<LstDepartment[]>()
    const [dataSource1_USER, setDataSource1] = useState<UserInfoFilterModel[]>()
    const [dataSource1_TASK, setDataSource2] = useState<QLCCTaskModel[]>([])
    const [currendViewCalenadar, setCurrentViewCalendar] = useState<any>();
    const [dataSource1_STATUS, setDataSource3] = React.useState<QLCCTaskStatusModel[]>();
    const [zoom, setZoom] = useState(false);
    const [flag, setFlag] = useState(false);
    const dataGridRef = useRef<DataGrid>(null);


    async function onloadListUser(department_id?: string) {
        const dataUsers = await qlccHomePage.userInfoWebFilterAdmin({
            ... new UserInfoFilterModel(),
            ma_cty: SessionLogin.ma_cty,
            apartment_building_id: apartment_building_id,
            department_id: department_id,
        } as UserInfoFilterModel);
        setDataSource1(dataUsers);
    }

    async function onloadListTasks(dataFilter: QLCCTaskModel) {
        const Res = await qlccClient.qLCCTaskGet({
            ...new QLCCTaskModel(),
            ...dataFilter,
            start_time: moment(dataFilter.start_time).utc(true).toDate(),
            end_time: moment(dataFilter.end_time).utc(true).toDate(),
            ngay_ct: dataFilter?.ngay_ct,
            action_type: 'FILTER_USER_FEEDBACK',
            ma_cty: SessionLogin.ma_cty,
            type_code: '1',
            apartment_building_id: apartment_building_id,
            is_admin_apartment: SessionLogin.isadmin,
            is_manager_apartment: SessionLogin.issupperadmin,
            receiver: dataFilter.username_login ?? SessionLogin.userName,
            username_login: SessionLogin.userName,
        } as QLCCTaskModel);
        setDataSource2(Res);
    }

    async function onloadDepartment() {
        const body = {
            ... new ParaObjTableList(),
            tablelist: 'dm_bp'
        } as ParaObjTableList
        httpPost<string>('/api/Common/LoadDatasourceBindControlByApartmentBuilding', {
            ma_cty: SessionLogin?.ma_cty,
            tablelist: body?.tablelist,
            apartment_building_id: apartment_building_id
        }).then(res => {
            const serviceGroups: any = JSON.parse(res.data)
            if (serviceGroups) {
                const dataDepartment = serviceGroups?.Table1;
                setDataSource([...dataDepartment])
            }
        }).catch((ex) => { });
    }

    //dataSource1_TASK
    async function onloadDataSource2(date: Date) {
        const check: boolean = currendViewCalenadar === 'day'
            || currendViewCalenadar === 'week'
            || currendViewCalenadar === 'month';
        const obj = {
            ...dataFilter,
            ngay_ct: date,
            start_time: moment(date).startOf(check ? currendViewCalenadar.toLowerCase() : 'month').toDate(),
            end_time: moment(date).endOf(check ? currendViewCalenadar.toLowerCase() : 'month').toDate(),
        } as QLCCTaskModel;
        setDataFilter(obj);
        await onloadListTasks(obj);
    };

    async function onloadOnMount() {
        const ResStatus = await qlccClient.qLCCTaskStatusGet({
            ...new QLCCTaskStatusModel(),
            ma_cty: SessionLogin.ma_cty,
            apartment_building_id: apartment_building_id,
        } as QLCCTaskStatusModel);

        const objFilter = {
            ... new QLCCTaskModel(),
            start_time: moment().startOf('month').toDate(),
            ngay_ct: moment().startOf('month').utc(true).toDate(),
            end_time: moment().endOf('month').toDate(),
        } as QLCCTaskModel

        setDataFilter(objFilter);
        onloadDepartment();
        setDataSource3(ResStatus);
        onloadListUser(); // Phải cho sau data filter

    }

    useEffect(() => {
        if (apartment_building_id) {
            onloadOnMount();
        }
    }, [apartment_building_id]);

    useEffect(() => {
        setDataSource2([]);
    }, [apartment_building_id]);

    useEffect(() => {
        onloadDataSource2(moment(dataFilter?.ngay_ct).utc(true).toDate());
    }, [currendViewCalenadar]);

    useEffect(() => {
        dataFilterRef.current = dataFilter;
    }, [dataFilter])

    //common functions
    function onOptionChanged(e: OptionChangedEventInfo<dxScheduler>) {
        if (e.name === 'currentDate') {
            onloadDataSource2(moment(e.value).utc(true).toDate());
        } else if (e.name === 'currentView') {
            setCurrentViewCalendar(e.value);
        }
    }

    async function onChangeDepartment(e: any) {
        const obj = { ...dataFilter, department_id: e } as QLCCTaskModel
        setDataFilter(obj);
        onloadListUser(e);
    }


    const onChangeUser = async (e: ItemClickEvent<any, any>) => {
        const dataItem = e.itemData as UserInfoFilterModel
        const objDataFilter = { ...dataFilter, username_login: dataItem.username } as QLCCTaskModel
        setDataFilter(objDataFilter)
        onloadListTasks(objDataFilter);
    }

    async function onZoomDisplay() {
        setZoom(pre => !pre)
    }

    async function onInit(pObj: IDataFilter) {
        setViews(pObj.views)
    }

    async function onFilter(pObj: IDataFilter) {
        setViews(pObj.views)
    }

    // common functions end

    // view data detail
    const [data, setData] = useState<QLCCTaskModel>();
    const [open, setClose] = useState(false);

    function onAppointmentlClick(e: any) {
        setData(e.appointmentData as QLCCTaskModel);
        setClose(true);
    }

    function onClose() {
        setClose(false);
        setData(undefined);
    }

    async function handleSelectionChanged(e: any) {
        const rowSelected = await getDataRowSelected(dataGridRef);
        if (rowSelected) {
            setData(rowSelected as any);
            setClose(true);
        }
    }

    function selectedRowKey(e: string | number) {
        const find = dataSource1_TASK.find(x => x.id === e);
        if (find) {
            setData(find);
            setClose(true);
        }
    }
    // view data detail

    return (
        <Box className='task-calendar-container'>
            <Box
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    marginLeft: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb location={location} />
            </Box>
            <Paper elevation={3} className='my-3 px-2 py-3 mx-3'>
                <WorkFilterData zoom={zoom} onZoomDisplay={onZoomDisplay} onValueChange={onFilter} onInit={onInit} isFeedBack={true} />
            </Paper>
            <Box className="d-flex justify-content-between bg-light" height={'80vh'} >
                <Box width={'20%'} height={'100%'} className="mx-3" sx={{ display: zoom ? 'none' : '', minWidth: '300px' }}>
                    <Box className="my-2">
                        <DHSSelectBox
                            dataSource={dataSource1_DEPARTMENT ? dataSource1_DEPARTMENT : []}
                            valueExpr={'ma_bp'}
                            displayExpr={'ten_bp'}
                            value={dataFilter?.department_id}
                            onValueChange={onChangeDepartment}
                            showClearButton
                            placeholder='Bộ phận'
                            searchEnabled
                            searchExpr={'ten_bp'}
                            searchMode='contains'
                        />
                    </Box>
                    <Stack direction='row' height={'68vh'}>
                        <WorkReportStackUser
                            dataSource={dataSource1_USER ? dataSource1_USER : []}
                            height={'100%'}
                            searchExpr='username'
                            key={'WorkReportStackUser'}
                            ref={listRef}
                            displayExpr='fullname'
                            keyExpr='username'
                            onItemClick={onChangeUser}
                        />
                    </Stack>
                </Box>
                {
                    views === TYPE_VIEW.CALENDAR
                        ? <>
                            <Box width={zoom ? '100%' : '80%'} height={'100%'} className="mx-3" minWidth={'500px'}>
                                <Box width={'100%'} className=" mb-4">
                                    <Stack direction={{ xs: 'column', lg: 'row' }} width={'100%'} justifyContent={'space-around'} spacing={{ xs: 1, md: 2, lg: 3 }}>
                                        {
                                            CardRenderItem(dataSource1_STATUS ?? [], dataSource1_TASK ?? [])
                                        }
                                    </Stack>
                                </Box>
                                <Box className="scheduler-work-report">
                                    <WorkReportCalendar
                                        height={'60vh'}
                                        ref={schedulerRef}
                                        dataSource={dataSource1_TASK}
                                        endDateExpr='start_time'
                                        startDateExpr='end_time'
                                        endDayHour={24}
                                        startDayHour={0}
                                        textExpr='title'
                                        title=''
                                        onOptionChanged={onOptionChanged}
                                        statusResource={{
                                            dataSource: dataSource1_STATUS ? dataSource1_STATUS : [],
                                            enable: true,
                                            colorExpr: 'color_code',
                                            displayExpr: 'name',
                                            valueExpr: 'stt',
                                            label: "Trạng thái",
                                            fieldExpr: 'status_code',
                                            maCt: ''
                                        }}
                                        onAppointmentClick={onAppointmentlClick}
                                    />
                                </Box>
                            </Box>
                        </>
                        : views === TYPE_VIEW.TABLE ?
                            <Box width={zoom ? '100%' : '80%'} height={'100%'} className="mx-3" minWidth={'500px'}>
                                <Box width={'100%'} className=" mb-4">
                                    <Stack direction={{ xs: 'column', lg: 'row' }} width={'100%'} justifyContent={'space-around'} spacing={{ xs: 1, md: 2, lg: 3 }}>
                                        {
                                            CardRenderItem(dataSource1_STATUS ?? [], dataSource1_TASK ?? [])
                                        }
                                    </Stack>
                                </Box>
                                <DHSDataGridEdit
                                    data={dataSource1_TASK}
                                    table_name={'QLCCTaskCheckList'}
                                    // key="id"
                                    keyExpr='id'
                                    // mode="popup"
                                    dataGridRef={dataGridRef}
                                    allowEdit={false}
                                    pageSize={menu?.page_size}
                                    isPagination={menu?.is_pagination}
                                    isMultiple={false}
                                    groupingAutoExpandAll={false}
                                    height={63}
                                    handleSelectionChanged={handleSelectionChanged}
                                />
                            </Box>
                            : <Box>
                                <WorkReportGANTT
                                    dataSource={dataSource1_TASK}
                                    selectedRowKey={selectedRowKey}
                                />
                            </Box>
                }
                <TaskListAction
                    open={open}
                    onClose={onClose}
                    onEditSuccess={function (is_success): void {
                    }}
                    action={ACTIONS.VIEW}
                    itemSelected={data?.id}
                    data={data}
                    apartmentBuildingId={apartment_building_id}
                />
            </Box>
        </Box>
    )
}


function CardRenderItem(data1: QLCCTaskStatusModel[], data2: QLCCTaskModel[]) {
    return <>
        {
            data1.map(item => <Card sx={{
                width: '100%',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                minHeight: '50px',
                backgroundColor: item?.color_code ?? stringToColor(item?.id ?? 'BUG QUÁ'),
                color: 'white',
                fontSize: 'large',
                fontWeight: 'bold',
            }}>{`${data2.filter(e => e?.status_id === item?.id).length} ${item?.name}`}</Card>)
        }
    </>
}


export function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export default WorkReportFeedBack