import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IReducer } from '../../../components/layouts';
import { AppSession } from '../../shared/app-session/app-session';
import { QLCCCitizenIdentificationCardsModel, QLCCClient, SysActionType } from '../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../shared/service-proxies/config';
import { useLocation } from 'react-router-dom';
import BlockUI from '../../../components/Block-UI/Block-ui';
import CitizenIdentificationCardAction from '../actions/citizen-identification-card-action/CitizenIdentificationCardAction';
import notification from '../../../common/notification/notification';
import DHSBreadcrumb from '../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import DHSToolbarRole from '../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DataGrid from 'devextreme-react/data-grid';
import { EventInfo } from 'devextreme/events';
import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import { ACTIONS } from '../../../common/enums/DHSToolbarRoleAction';
import { getDataRowSelected, getIndexRowSelected } from '../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../common/Interfaces/StatusMessage';


var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

const status_message = ['success', 'error']
const CitizenIdentificationCardList: React.FC<{}> = () => {

    const location = useLocation();
    // const [roles, setRoles] = React.useState<RoleRightWebModel[]>();/
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [loading, setLoading] = React.useState<boolean>(false);
    // const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const [dataTable, setDataTable] = React.useState<QLCCCitizenIdentificationCardsModel[]>([]);
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);

    const [actCx, setActCx] = useState<IAction>(defaultAction);

    // 

    // 

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

    // 

    useEffect(() => {
        setLoading(true)
        qLCClient.qLCCCitizenIdentificationCardsActions({
            ...new QLCCCitizenIdentificationCardsModel(),
            apartment_building_id: apartment_building_id,
            action_type: 'GET',
        } as QLCCCitizenIdentificationCardsModel).then((res) => {

            setLoading(false)

            const tempData: any[] = []

            res.forEach(cccd => {
                tempData.push({
                    ...cccd,
                    // date_issue: moment(cccd.date_issue).format("DD/MM/yyy")
                });
            })
            setDataTable(tempData)
        })
    }, [apartment_building_id])

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {

        switch (action?.action_code?.toUpperCase()) {
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<QLCCCitizenIdentificationCardsModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            if (window.confirm("Bạn có chắc chắn muốn xóa?")) {
                                setLoading(true)
                                qLCClient.qLCCCitizenIdentificationCardsActions({
                                    ...new QLCCCitizenIdentificationCardsModel(),
                                    id: dataSelected?.id ?? null,
                                    apartment_building_id: apartment_building_id,
                                    ma_cty: SessionLogin?.ma_cty,
                                    action_type: action.action_code.toUpperCase(),
                                } as QLCCCitizenIdentificationCardsModel)
                                    .then((res) => {
                                        setLoading(false)


                                        notification(status_message[res[0].status!], res[0].message);
                                        // setReload(!reload)
                                        if (res[0].status === 0) {
                                            const tempData = [...dataTable];
                                            const index = tempData.findIndex(x => x.id === dataSelected?.id);
                                            if (index >= 0) {
                                                tempData.splice(index, 1);
                                                setDataTable(tempData);
                                            }
                                        }
                                    });
                            }
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: action?.action_code?.toUpperCase() ?? "",
                    // payload: selectedItemKeys?.id ?? null
                })
                break;
            case ACTIONS.EDIT:
            case ACTIONS.VIEW:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<QLCCCitizenIdentificationCardsModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: action?.action_code?.toUpperCase() ?? "",
                                payload: dataSelected?.id ?? null
                            })
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
        }
    }

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                console.log(keys);

                const index = e.component.getRowIndexByKey(keys[0]);

                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
            });
        }
    };

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };
    return (


        <div className='container-fluid'>

            {
                actCx.open && <CitizenIdentificationCardAction
                    open={actCx.open}
                    onClose={function (): void {
                        setActCx(defaultAction);
                    }}
                    action={actCx.type}
                    itemSelected={actCx.payload}
                    onEditSuccess={function (id?: string | undefined, data?: QLCCCitizenIdentificationCardsModel | undefined): void {
                        if (id && data) {
                            const tempData = [...dataTable];
                            const index = tempData.findIndex(x => x.id === id);
                            if (index >= 0) {
                                tempData[index] = data;
                                setDataTable(tempData)
                            }


                        }
                    }}
                    onAddedSuccess={function (id?: string | undefined, data?: QLCCCitizenIdentificationCardsModel | undefined): void {
                        if (id && data) {
                            const tempData = [...dataTable];

                            tempData.unshift(data);
                            setDataTable(tempData)



                        }
                    }} >
                </CitizenIdentificationCardAction>
            }

            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location}
                />

            </div>

            <DHSToolbarRole
                id={' '}
                menu={menu}
                customMenu={[]}
                onClickAction={handleClickAction}
            />

            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                keyExpr="id"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handleSelectionChanged={handleSelectionChanged}
                handlePageChange={handlePageChange}

            // handleExporting={handleExporting}
            />

            <BlockUI blocking={loading} title={""}></BlockUI>


        </div>
    )
}

export default CitizenIdentificationCardList;


