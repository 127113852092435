import React, { ReactElement, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Login from "./Login/Login";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import { clearLogin, setIdxDB } from "../../../redux/actions/actions";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import _ from "lodash";

import "./index.css";
import { components } from "../../../common/ComponentsConfig/components";
import {
  HomeClient,
  UserInfo,
  SysMenu,
  SaleSupport,
  QLCCApartmentBuildingModel,
} from "../../../app/shared/service-proxies/api-shared";
import {
  clearRoles,
  getAllMenu,
  getMenu,
} from "../../../redux/actions/RoleActions";
import BASE_URL_API from "../../../app/shared/service-proxies/config";
import { AppSession } from "../../../app/shared/app-session/app-session";

import Navbar from "./Navbar/Navbar";
import { Content } from "antd/lib/layout/layout";
import UpdatePasswordPage from "./User/Login/Update-password";
import { useWindowSize } from "../../../common/useWindowWide";
import { IndexedDBService } from "../../../common/indexedDBApi";
import {
  DB_NAME,
  DB_VERSION,
  OBJECTSTORENAME,
} from "../../../common/indexedDBApi/constant";
import Drawer from "devextreme-react/drawer";
import List from "devextreme-react/list.js";
import { pushPathLevel1 } from "../../../redux/actions/PathAction";
import { Link } from "react-router-dom";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { onDeleteToken } from "../../../firebase";
import Notfound from "../../../common/notfound/Notfound";
import Dashboard from "./Dashboard/Dashboard";
import Role from "../Role/Role";
import PortalSaleGrid from "../../../app/website/PortalSale/PortalSaleGrid";
import PortalSaleGridOnlyView from "../../../app/website/PortalSale/PortalSaleGridOnlyView";
import CustomerInfomation from "../../../app/website/PortalSale/Childs/CustomerInfomation";
import CustomerStatus from "../../../app/website/PortalSale/Childs/CustomerStatus";
import ContactPersonPosition from "../../../app/website/PortalSale/Childs/ContactPersonPosition";
import SaleSupportGrid from "../../../app/website/PortalSale/SaleSupport/SaleSupportGrid";
import PayupPage from "../../../app/website/PortalSale/Childs/Payup/Payup";
import PortalDocGrid from "../../../app/website/PortalDoc/PortalDocGrid";
import Major from "../../../app/website/PortalSale/Childs/Major";
import ProductPage from "../../../app/website/PortalDoc/Childs/Products";
import ModulePage from "../../../app/website/PortalDoc/Childs/Modules";
import FeaturePage from "../../../app/website/PortalDoc/Childs/Features";
import TagPage from "../../../app/website/PortalDoc/Childs/Tags";
import PortalTimeKeepingGrid from "../../../app/website/PortalTimeKeeping/PortalTimeKeepingGrid";
import ApproveGrid from "../../../app/website/PortalTimeKeeping/Childs/ApproveGrid";
import ApproveForm from "../../../app/website/PortalTimeKeeping/Childs/ApproveForm";
import ApproveFormV2 from "../../../app/website/PortalTimeKeeping/Childs/ApproveFormV2";
import LeaveForm from "../../../app/website/PortalTimeKeeping/Childs/LeaveForm";
import InVch01 from "../../../app/quan-ly-chung-cu/receiving-voucher/receiving-voucher";
import ItemGroup from "../../../app/quan-ly-chung-cu/item/group-item/group-item";
import Site from "../../../app/quan-ly-chung-cu/site/site";
import ItemKind from "../../../app/quan-ly-chung-cu/item/item-kind/item-kind";
import TaskCheckList from "../../../app/quan-ly-chung-cu/work/task/task-check-list";
import Inventory from "../../../app/quan-ly-chung-cu/inventory/inventory";
import Unit from "../../../app/quan-ly-chung-cu/unit/unit";
import UtilityGroupCategory from "../../../app/quan-ly-chung-cu/utility-group-category/service-group-category";
import ItemBalance from "../../../app/quan-ly-chung-cu/item-balance/item-balance";
import VehicleCardData from "../../../app/quan-ly-chung-cu/vehicle-card-data/vehicle-card-data";
import CompanyInfo from "../../../app/quan-ly-chung-cu/company-info/CompanyInfo";
import TaskType from "../../../app/quan-ly-chung-cu/work/task-type";
import TaskGANTT from "../../../app/quan-ly-chung-cu/work/task-gantt/task-gantt";
import UtilityServiceGroup from "../../../app/quan-ly-chung-cu/utility-service-group/utility-service-group";
import TaskScript from "../../../app/quan-ly-chung-cu/work/task/task-script";
import TaskGroup from "../../../app/quan-ly-chung-cu/work/task-group";
import TaskList from "../../../app/quan-ly-chung-cu/work/task/task-list";
import ServiceBooking from "../../../app/quan-ly-chung-cu/service-booking/service-booking";
import ServiceList from "../../../app/quan-ly-chung-cu/service2/service-list";
import UtilityBooking from "../../../app/quan-ly-chung-cu/utility-booking/utility-booking";
import Department from "../../../app/quan-ly-chung-cu/ob-cash-bank/department";
import CategoryEmployee from "../../../app/quan-ly-chung-cu/ob-cash-bank/danh-muc-nhan-vien";
import VehicleCardReport from "../../../app/quan-ly-chung-cu/report/vehicle-card-report/vehicle-card-report";
import VehicleCardInOutReport from "../../../app/quan-ly-chung-cu/report/vehicle-card-in-out-report/vehicle-card-in-out-report";
import QLCCBanDongPhi from "../../../app/quan-ly-chung-cu/report/qlcc-bandongphi/qlcc-bandongphi";
import VehicleChangeCard from "../../../app/quan-ly-chung-cu/category-vehicle-card/list-category-vehicle-card/vehicle-change-card";
import VehicleRenewCard from "../../../app/quan-ly-chung-cu/category-vehicle-card/list-category-vehicle-card/vehicle-renew-card";
import VehicleReturnCard from "../../../app/quan-ly-chung-cu/category-vehicle-card/list-category-vehicle-card/vehicle-return-card";
import VehicleCardList from "../../../app/quan-ly-chung-cu/category-vehicle-card/report-category-vehicle-card/category-list-vehicle-card";
import VehicleVipCard from "../../../app/quan-ly-chung-cu/category-vehicle-card/list-category-vehicle-card/vehicle-vip-card";
import VehicleBrokenCard from "../../../app/quan-ly-chung-cu/category-vehicle-card/list-category-vehicle-card/vehicle-broken-card";
import VehicleSellCard from "../../../app/quan-ly-chung-cu/category-vehicle-card/list-category-vehicle-card/vehicle-sell-card";
import VehicleCancelCard from "../../../app/quan-ly-chung-cu/category-vehicle-card/list-category-vehicle-card/vehicle-cancel-card";
import AllVehicleCard from "../../../app/quan-ly-chung-cu/category-vehicle-card/list-category-vehicle-card/all-vehicle-card";
import UserInfoPermission from "../../../app/quan-ly-chung-cu/user-info/action/UserInfoPermission";
import OvertimeForm from "../../../app/website/PortalTimeKeeping/Childs/OvertimeForm";
import SchedulerTimesheet from "../../../app/erp/hr/employee/timesheet/timesheet";
import CheckInOut from "../CheckInOut/CheckInOut";
import SchedulerEmployeeTimesheet from "../../../app/erp/hr/employee/employee-timesheet/employee-timesheet";
import WorkerGrid from "../../../app/website/PortalTimeKeeping/Worker/WorkerGrid";
import WorkerLeaveForm from "../../../app/website/PortalTimeKeeping/Worker/Leave/WorkerLeaveForm";
import WorkerOvertimeForm from "../../../app/website/PortalTimeKeeping/Worker/Overtime/WorkerOvertimeForm";
import MachineStatus from "../Manufacturing/MachineStatus";
import CostCenterReport from "../../../app/erp/general-ledger/management-reports/cost-center-report";
import ProfitLoss from "../../../app/erp/general-ledger/profit-loss/profit-loss";
import RequestApproveGrid from "../../../app/erp/request-approve/RequestApproveGrid";
import DanhSach from "../../../app/cho-thue-xe/cai-dat/danh-sach";
import DanhSachNghiepVu from "../../../app/cho-thue-xe/nghiep-vu/danh-sach/danh-sach";
import ThongTinXe from "../../../app/cho-thue-xe/nghiep-vu/thong-tin-xe/thong-tin-xe";
import UserRightComponent from "../../../common/UserRight/UserRight";
import { setApartmentBuilding } from "../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction";
// var socket = io(`https://${window.location.host}`);

export interface MatchParams {
  id: string;
}

let appSession: AppSession;

export default function Admin(): ReactElement {
  const client = new HomeClient(appSession, BASE_URL_API);
  const indexedDBService = new IndexedDBService(
    DB_NAME,
    DB_VERSION,
    OBJECTSTORENAME
  );
  const listMenu = useSelector(
    (state: IReducer) => state.RoleReducer?.listMenu
  );

  const isLogin = useSelector(
    (state: IReducer) => state.AdminPageReducer?.isLogin
  );
  const isAdmin = useSelector(
    (state: IReducer) => state.AdminPageReducer?.isAdmin
  );
  const userInfo = useSelector(
    (state: IReducer) => state.AdminPageReducer?.userInfo
  );
  // const defaultMenu = useSelector(
  //   (state: IReducer) => state.RoleReducer?.menuid
  // );

  const [listSysMenu, setListSysMenu] = React.useState<SysMenu[]>([]);
  const history = useHistory();

  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

  const [logout, setLogout] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [dataSourceMenu, setDataSourceMenu] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(true);
  const size = useWindowSize();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  React.useEffect(() => {
    if (isLogin) {
      // dispatch(getMenu('30.00.00'));
      // console.log("user info: ", userInfo);



      indexedDBService
        .openDB()
        .then((db) => {
          // const data = { id: 1, name: 'John Doe', age: 25 };
          indexedDBService
            .getData(db, userInfo.userName)
            .then((data) => {
              if (!data) {
                indexedDBService
                  .addData(db, userInfo)
                  .then(() => {
                    console.log("Data added to object store");
                    dispatch(setIdxDB(true))
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });

      localStorage.setItem(
        "SessionLogin",
        JSON.stringify({
          userName: userInfo.userName,
          email: userInfo.email,
          phone: userInfo.phone,
          fullName: userInfo.fullName,
          employeeCode: userInfo.employeeCode,
          ma_cty: userInfo.ma_cty,
          ten_cty: userInfo.ten_cty,
          nam_tc: userInfo.nam_tc,
          Token: userInfo.token_authorization,
          Token_expiry_date: userInfo.token_authorization_expiry_date,
          isadmin: userInfo.isadmin,
          grand: userInfo.isgrand,
          issupperadmin: userInfo.issupperadmin,
          company_info: userInfo.company_info,
        })
      );
      setTimeout(() => {
        client
          ?.danhMucWeb({
            ...new UserInfo(),
            username: userInfo.userName,
          } as UserInfo)
          .then((res) => {
            setListSysMenu(res);
            dispatch(getAllMenu(res));
          })
          .catch((err) => {
            if (err.status === 401) {
              localStorage.removeItem("SessionLogin");
              onLogout();
            }
          });
      }, 500);
    }
  }, [isLogin]);

  const onDeleteTokenFCM = async () => {
    try {
      await onDeleteToken();
    } catch (error) {
      console.error(error)
    }
    history.push("/admin");
  }

  const onLogout = async () => {
    // 


    // 
    dispatch(clearLogin());
    dispatch(clearRoles());
    dispatch(setApartmentBuilding(undefined));
    // dispatch(setApartmentBuilding({
    //   id: ''
    // } as QLCCApartmentBuildingModel));
    localStorage.removeItem("SessionLogin");
    await onDeleteTokenFCM();
    setLogout(!logout);

    indexedDBService
      .openDB()
      .then((db) => {
        // const data = { id: 1, name: 'John Doe', age: 25 };
        indexedDBService
          .getData(db, userInfo.userName)
          .then((data) => {
            if (data.userName === userInfo.userName) {
              indexedDBService
                .deleteData(db, data.userName)
                .then(() => {
                  console.log("Data delete to object store");
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });



    // notification("success", "Đăng xuất thành công !");
  };

  const handleChangeCollapsed = (collaped: boolean) => {
    setCollapsed(!collaped);
  };

  // const toolbarItems = [{
  //     widget: 'dxButton',
  //     location: 'before',
  //     options: {
  //       icon: 'menu',
  //       onClick: () => this.setState({ opened: !this.state.opened }),
  //     },
  //   }];

  useEffect(() => {
    if (size.width <= 550) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [size.width]);

  useEffect(() => {
    const temp = listSysMenu
      .filter((item) => item.menuid?.endsWith(".00.00"))
      .map((menu, index) => {
        return {
          id: index,
          text: menu.bar,
          icon: "product",
          link_web: menu.link_web,
          bar: menu.bar,
          menuid: menu.menuid,
          picture1: menu.picture1,
        };
      });

    setDataSourceMenu([...temp]);
  }, [listSysMenu]);

  return (
    <div className="AdminPage">
      {isLogin || !_.isEmpty(SessionLogin) ? (
        <>
          <Navbar
            collapsed={collapsed}
            onChangeCollapsed={handleChangeCollapsed}
            username={userInfo?.userName}
            fullName={userInfo.fullName}
            ten_cty={userInfo.ten_cty}
            nam_tc={userInfo.nam_tc}
            email={userInfo.email}
            phone={userInfo.phone}
            onLogout={onLogout}
            open={open}
            setOpen={setOpen}
          />
          {/* <Layout style={{ height: "92vh" }}> */}
          <Drawer
            opened={open}
            // openedStateMode={size.width > 550 ? "shrink" : "overlap"}
            openedStateMode={"shrink"}
            position={"left"}
            revealMode={"slide"}
            focusStateEnabled={true}
            activeStateEnabled={true}
            component={() => NavigationList(dataSourceMenu, setOpen)}

            style={{ backgroundColor: "#337ab7" }}
            height={"100%"}
          >
            <Content
              style={{
                minHeight: "94vh",
                maxHeight: "94vh",
                overflow: "auto",
              }}
            >
              <Switch>
                <Route
                  key="notfound"
                  path="/admin/notfound"
                  component={components.NOTFOUND}
                  exact
                ></Route>
                <Route path="/admin/change-password">
                  <UpdatePasswordPage />
                </Route>
                {listSysMenu.map((menu) => {
                  return (
                    menu.link_web &&
                    menu.component && (
                      <Route
                        key={menu.menuid}
                        path={`${menu.link_web}`}
                        component={
                          components[menu.component.toLocaleUpperCase()]
                        }
                      />
                    )
                  );
                })}
                <Redirect exact from="/admin" to="/admin/dashboard_right" />
              </Switch>
            </Content>
          </Drawer>
          {/* </Layout> */}
        </>
      ) : (
        <Login />
      )}
    </div>
  );
}

const NavigationList = (dataSourceMenu: any, setOpen: any) => {
  const dispatch = useDispatch();
  const [menuIDSelected, setMenuIDSelected] = useState<string>();
  const menuid_level_1 = useSelector((state: IReducer) => state.UrlReducer?.menuid_level_1);
  const size = useWindowSize();


  useEffect(() => {

    setMenuIDSelected(menuid_level_1)

  }, [menuid_level_1])



  return (
    <div className="list" style={{ width: "200px", height: "100%" }}>
      <List
        dataSource={dataSourceMenu}
        hoverStateEnabled={true}
        activeStateEnabled={true}
        focusStateEnabled={false}
        className="panel-list dx-theme-accent-as-background-color"
        onItemClick={(e) => {
          dispatch(getMenu(e.itemData.menuid));
          dispatch(
            pushPathLevel1({
              url_level_1: e.itemData.link_web!,
              bar_level_1: e.itemData.bar,
              menuid_level_1: e.itemData.menuid,
            } as any)
          );
          size.width <= 550 &&
            setOpen(false);
        }}
        keyExpr={'menuid'}
        itemRender={(item) => {
          return (
            <Link
              id={item.menuid}
              to={item.link_web ? `${item.link_web}` : `/admin/notfound`}
              style={{
                textDecoration: "none",
                fontSize: "16px",
                display: "block",
                height: '100%',
                // backgroundColor: (menuIDSelected && menuIDSelected  === item.menuid) ? '#06283d' : ''
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <span role="img" className="anticon">
                  <img
                    alt=""
                    style={{ width: "22px", minWidth: "22px" }}
                    src={
                      BASE_URL_API +
                      "/" +
                      item.picture1 +
                      "?v=" +
                      new Date().getTime()
                    }
                  ></img>
                </span>

                <Typography
                  variant="body1"
                  color={(menuIDSelected && menuIDSelected === item.menuid) ? '#fcba03' : '#fff'}
                  sx={{ ml: 1, fontWeight: "600" }}
                >
                  {item.text}
                </Typography>
              </Box>
            </Link>
          );
        }}
      />
    </div>
  );
};
const components2: any = {
  DASHBOARD: Dashboard,
  //Portal Sale
  CRMROLE: Role,
  CRMCUSTOMERINFOMATION: PortalSaleGrid,
  CRMCUSTOMERSEARCH: PortalSaleGridOnlyView,
  CRMCUSTOMERSTATUS: PortalSaleGrid,
  CRMCONTACTPERSONPOSITION: PortalSaleGrid,
  CRMMAJOR: PortalSaleGrid,
  CUSTOMERINFOMATION: CustomerInfomation,
  CUSTOMERSTATUS: CustomerStatus,
  CONTACTPERSONPOSITION: ContactPersonPosition,
  PAYUP: PayupPage,
  //Customer Care
  CSKHROLE: Role,
  CSKHSALESUPPORT: SaleSupportGrid,
  SALESUPPORT: SaleSupport,
  //Portal Doc
  DOCUSERGUIDEPRODUCTION: PortalDocGrid,
  DOCUSERGUIDEPRODUCTIONMODULE: PortalDocGrid,
  DOCUSERGUIDEPRODUCTIONFEATURE: PortalDocGrid,
  DOCUSERGUIDEPRODUCTIONTAG: PortalDocGrid,
  MAJOR: Major,
  PRODUCT: ProductPage,
  MODULE: ModulePage,
  FEATURE: FeaturePage,
  TAG: TagPage,
  // Portal TimeKeeping
  TKROLE: Role,
  TKLEAVEFORM: PortalTimeKeepingGrid,
  TKOVERTIMEFORM: PortalTimeKeepingGrid,
  APPROVEGRID: ApproveGrid,
  HRAPPROVEGRID: ApproveGrid,
  APPROVEGRIDV2: ApproveGrid,
  HRAPPROVEGRIDV2: ApproveGrid,
  APPROVEFORM: ApproveForm,
  HRAPPROVEFORM: ApproveForm,
  APPROVEFORMV2: ApproveFormV2,
  HRAPPROVEFORMV2: ApproveFormV2,
  LEAVEFORM: LeaveForm,
  OVERTIMEFORM: OvertimeForm,
  SCHEDULERTIMESHEET: SchedulerTimesheet,
  SCHEDULEREMPLOYEETIMESHEET: SchedulerEmployeeTimesheet,
  CHECKIN: CheckInOut,
  TIMESHEET: SchedulerEmployeeTimesheet,
  WORKERLEAVEGRID: WorkerGrid,
  WORKEROVERTIMEGRID: WorkerGrid,
  WORKERLEAVEFORM: WorkerLeaveForm,
  WORKEROVERTIMEFORM: WorkerOvertimeForm,
  //Notfound
  NOTFOUND: Notfound,
  MACHINESTATUS: MachineStatus,
  //ERP
  MANUFACTURING: Role,
  GENERAL_LEDGER: Role,
  COSTCENTERREPORT: CostCenterReport,
  PROFITLOSS: ProfitLoss,
  ERPSUPPLYREQUEST: RequestApproveGrid,
  ERPSURFACETREATMENTREQUEST: RequestApproveGrid,
  //ChoThueXe
  CTXDANHMUC: Role,

  CTXMAUSON: DanhSach,
  CTXNOISANXUATXE: DanhSach,
  CTXLOAICHOTHUE: DanhSach,
  CTXLOAIXE: DanhSach,
  CTXKIEUXE: DanhSach,
  CTXHANGXE: DanhSach,
  CTXTRANGTHAI: DanhSach,
  CTXTRANGTHAIXE: DanhSach,
  CTXLOAIBAODUONG: DanhSach,

  CTXDANHSACHTHUE: DanhSachNghiepVu,
  CTXDANHSACHXE: DanhSachNghiepVu,
  CTXDANHSACHGIAODICH: DanhSachNghiepVu,
  // CTXTHONGTINTHUE: ThongTinThue,
  CTXTHONGTINXE: ThongTinXe,
  SETTING: Role,
  USERRIGHTCOMPONENT: UserRightComponent,
  // USERINFO: UserRightListComponent,
  CTXBAOCAO: Role,
  // CTXBAOCAODOANHSO: BaoCao,
  // CTXBAOCAOSOLANTHUE: BaoCaoDoanhSo,
  // //KiemSoatCan
  // KSCROLE: Role,
  // KSCDANHSACHPHIEU: DanhSachKSC,
  // KSCDANHSACHPHIEUTEST: DanhSachTestKSC,
  // KSCDANHSACHVANDON: DanhSachKSC,
  // KSCDANHSACHKHACHHANG: DanhSachKSC,
  // KSCDANHSACHTAU: DanhSachKSC,
  // KSCCANXE: CanXe,
  // KSCLOAIHANG: DanhMucKSC,
  // KSCKIEUCAN: DanhMucKSC,
  // KSCNHOMTAU: DanhMucKSC,
  // KSCHANGTAU: DanhMucKSC,
  // KSCDANHSACHKHO: DanhMucKSC,
  // KSCDANHSACHCA: DanhMucKSC,
  // KSCBAOCAOXUATFILE: BaoCaoKSC,
  // Quan li chung cu
  QLCCSYSTEM: Role,
  QLCCAPARTMENTBUILDINGMANAGERMENT: Role,
  QLCCCOST: Role,
  QLCCCATEGORY: Role,
  QLCCCSERVICE: Role,
  QLCCFEEDBACKMANAGERMENT: Role,
  QLCCNOTIFICATIONMANAGERMENT: Role,
  QLCCBANKMANAGERMENT: Role,
  QLCCOBCASHBANKROLE: Role, // Khánh 22 05 2023 15h52
  QLCCVEHICLECARD: Role, // Khánh 26 05 2023
  QLCCFEEDBACKMANAGEMENT: Role, // Khánh 27 06 2023
  QLCCTASKMANAGERMENT: Role, // Khánh 27 06 2023


  // QLCCBLOCK: QLCCBlock,
  // QLCCAPARTMENTBUILDING: QLCCApartmentBuilding,
  // QLCCRESIDENT: QLCCResident,
  // QLCCCITIZENIDENTIFICATIONCARD: QLCCCitizenIdentificationCard,
  // QLCCFEEDBACK: QLCCFeedback,
  // QLCCRESIDENTJOB: QLCCResidentJob,
  // QLCCAPARTMENT: QLCCApartment,
  // QLCCOPERATINGCOST: OperatingCost,
  // QLCCSERVICEINVOICE: QLCCServiceInvoice,
  // QLCCSERVICEINVOICEDETAIL: QLCCServiceInvoiceDetail,
  // QLCCCATEGORIE: QLCCCategory,
  // QLCCNEWS: QLCCNews,
  // QLCCNOTIFICATION: QLCCNotification,
  // QLCCFLOOR: QLCCFloor,
  // QLCCINVESTOR: QLCCInvestor,
  // QLCCREGULATION: QLCCRegulation,
  // QLCCAPARTMENTBUILDINGDOMESTICWATER: QLCCApartmentBuildingDomesticWater,
  // QLCCAPARTMENTDOMESTICWATER: QLCCApartmentDomesticWater,
  // QLCCDOMESTICWATERNORM: QLCCDomesticWaterNorm,
  // QLCCVEHICLE: QLCCVehicle,
  // QLCCVEHICLETYPE: QLCCVehicleType,
  // RESIDENTACCOUNT: ResidentAccount,
  // EMPLOYEEACCOUNT: EmployeeAccount,
  // //Dăng 22-03-2023
  // QLCCPURPOSEOFUSE: Purpose,
  // USERINFOGROUP: UserInfoGroup,
  // USERINFOTYPE: UserInfoType,
  // USERINFOAPPROVED: UserInfoApproved,
  // USERINFOBLOCK: UserInfoBlock,
  // QLCCAPARTMENTV2: QLCCApartmentListVrII,
  // // add DASHBOARDQLCC
  // // DASHBOARDQLCC: Dashboard_right,
  // DATAGRIDSETTING: DataGridSetting,
  // "69.10.01_CAVCH01M": QLCCReceiptList,
  // "69.20.01_CAVCH02M": QLCCPaymentCashM,
  // QLCCDASHBOARD: QLCCDashboard,
  // QLCCRECIEPTINVOICE: ReceiptInvoiceList,
  // QLCCUTILITY: UtilityList,
  // QLCCSERVICEINVOICECLIENT: ServiceInvoiceClient,
  // QLCCFEEDBACKS: FeedBackList,
  // QLCCTYPEFEEDBACK: TypeFeedBack,
  // QLCCCASHREPORT: CashBookReport,
  // QLCCDOMESTICWATERCYCLE: DomesticWaterCycle,
  // QLCCLOCKEDINVOICE: LockedList,
  // ROLEMENU: RoleMenuList,
  // ROLEMENUPERMISSION: RoleMenuPermission,
  // ROLEINFO: RoleInfo,
  // QLCCCASHRECORD: CashBookRecord,
  // QLCCCASHEXPENSESBOOK: CashExpensesBook,
  // QLCCHISTORYDOMESTICWATER: HistoryDomesticWater,
  // QLCCCASHEXPENDITUREREPORT: CashByExpenditureReport,
  // QLCCINVOICEBALANCEREPORT: InvoiceBalanceReport,
  // QLCCOPERATIONFEEREPORT: OperationFeeReport,
  // QLCCOBCASHBANK: OBCashBank,
  // QLCCOBCASHBANK2: Receivable,
  // QLCCPAYABLE: Payable, // 24 05 2023 khanh
  // QLCCRECEIVABLECUSTOMER: ReceivableCustomer, // 25 05 2023 khanh
  // QLCCPAYABLECUSTOMER: PayableCustomer,
  // QLCCCATEGORYCUSTOMER: CategoryCustomer,
  // 25-05-2023
  USERINFO: UserInfo,
  USERINFOPERMISSION: UserInfoPermission,
  QLCCVEHICLECARDALL: AllVehicleCard,
  // 30 05 2023
  QLCCVEHICLECANCEL: VehicleCancelCard,
  QLCCVEHICLESELL: VehicleSellCard,
  QLCCVEHICLEBROKEN: VehicleBrokenCard,
  QLCCVEHICLEVIP: VehicleVipCard,
  QLCCVEHICLECARDLIST: VehicleCardList,
  QLCCVEHICLERETURN: VehicleReturnCard,
  QLCCVEHICLERENEW: VehicleRenewCard,
  QLCCVEHICLECHANGE: VehicleChangeCard,
  //01 06 2023
  QLCCVEHICLECARDREPORT: VehicleCardReport,
  QLCCVEHICLECARDINOUTREPORT: VehicleCardInOutReport,
  //4/6/2023
  QLCCBANDONGPHI: QLCCBanDongPhi,
  // 15 06 2023
  //  NOTIFYDAILY: NotifyDaily // quản lý thông báo hàng ngày
  QLCCCATEGORYEMPLOYEE: CategoryEmployee,
  QLCCDEPARTMENT: Department,
  // 17/06/2023
  QLCCUTILITYBOOKING: UtilityBooking,
  //24/06/2023
  QLCCSERVICE2: ServiceList,
  QLCCSERVICEBOOKING: ServiceBooking,
  // 22 06 2023
  QLCCTASKLIST: TaskList,
  //29 06 2023
  QLCCTASKGROUP: TaskGroup,
  QLCCTASKTYPE: TaskType,
  QLCCTASKSCRIPT: TaskScript,
  //01/07/2023
  QLCCUTILITYSERVICEGROUP: UtilityServiceGroup,
  QLCCTASKGANTT: TaskGANTT,
  //03/07/2022
  COMPANYINFO: CompanyInfo,
  //06/06/2023
  VEHICLECARDDATA: VehicleCardData,
  //07/07/2023
  QLCCUTILITYSERVICEGROUPCATEGORY: UtilityGroupCategory,
  ITEMBALANCE: ItemBalance,
  QLCCTASKCHECKLIST: TaskCheckList,
  // 12/07/2023
  ITEMGROUP: ItemGroup,
  ITEMKIND: ItemKind,
  INVENTORY: Inventory,
  UNIT: Unit,
  LOCATION: Location,
  SITE: Site,
  // 18/07/2023
  INVCH01: InVch01
}



// import React, { ReactElement, useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import Login from "./Login/Login";
// import { IReducer } from "../../../common/Interfaces/Interfaces";
// import notification from "../../../common/notification/notification";
// import { clearLogin } from "../../../redux/actions/actions";
// import { Layout } from "antd";
// import { Switch, Route, useHistory, Redirect } from "react-router-dom";
// import _ from "lodash";

// import "./index.css";
// import { components } from "../../../common/ComponentsConfig/components";
// import {
//     HomeClient,
//     UserInfo,
//     SysMenu,
// } from "../../../app/shared/service-proxies/api-shared";
// import {
//     clearRoles,
//     getAllMenu,
//     getMenu,
//     getMenuByWorker,
// } from "../../../redux/actions/RoleActions";
// import BASE_URL_API from "../../../app/shared/service-proxies/config";
// import { AppSession } from "../../../app/shared/app-session/app-session";

// import Leftbar from "../Leftbar/Leftbar";
// import Navbar from "./Navbar/Navbar";
// import { Content } from "antd/lib/layout/layout";
// import UpdatePasswordPage from "./User/Login/Update-password";
// import { useWindowSize } from "../../../common/useWindowWide";
// import BlockUI from "../../Block-UI/Block-ui";
// import { IndexedDBService } from "../../../common/indexedDBApi";
// import { DB_NAME, DB_VERSION, OBJECTSTORENAME } from "../../../common/indexedDBApi/constant";
// // var socket = io(`https://${window.location.host}`);

// export interface MatchParams {
//     id: string;
// }

// let appSession: AppSession;

// export default function Admin(): ReactElement {
//     const client = new HomeClient(appSession, BASE_URL_API);
//     const indexedDBService = new IndexedDBService(DB_NAME, DB_VERSION, OBJECTSTORENAME);
//     const listMenu = useSelector(
//         (state: IReducer) => state.RoleReducer?.listMenu
//     );

//     const isLogin = useSelector(
//         (state: IReducer) => state.AdminPageReducer?.isLogin
//     );
//     const isAdmin = useSelector(
//         (state: IReducer) => state.AdminPageReducer?.isAdmin
//     );
//     const userInfo = useSelector(
//         (state: IReducer) => state.AdminPageReducer?.userInfo
//     );
//     const defaultMenu = useSelector(
//         (state: IReducer) => state.RoleReducer?.menuid
//     );

//     const [listSysMenu, setListSysMenu] = React.useState<SysMenu[]>([]);
//     const history = useHistory();

//     const SessionLogin = JSON.parse(
//         localStorage.getItem("SessionLogin") || "{}"
//     );

//     const [logout, setLogout] = useState(false);
//     const [collapsed, setCollapsed] = useState(false);
//     const size = useWindowSize();
//     const dispatch = useDispatch();

//     React.useEffect(() => {
//         if (isLogin) {
//             // dispatch(getMenu('30.00.00'));
//             // console.log("user info: ", userInfo);

//             indexedDBService.openDB().then((db) => {
//                 // const data = { id: 1, name: 'John Doe', age: 25 };
//                 indexedDBService.getData(db, userInfo.userName).then((data) => {
//                     if(!data){
//                         indexedDBService.addData(db, userInfo).then(() => {
//                             console.log('Data added to object store');

//                           }).catch((error) => {
//                             console.log(error);
//                           });
//                     }
//                   }).catch((error) => {
//                     console.log(error);
//                   });

//               }).catch((error) => {
//                 console.log(error);
//               });

//             localStorage.setItem(
//                 "SessionLogin",
//                 JSON.stringify({
//                     userName: userInfo.userName,
//                     email: userInfo.email,
//                     phone: userInfo.phone,
//                     fullName: userInfo.fullName,
//                     employeeCode: userInfo.employeeCode,
//                     ma_cty: userInfo.ma_cty,
//                     ten_cty: userInfo.ten_cty,
//                     nam_tc: userInfo.nam_tc,
//                     Token: userInfo.token_authorization,
//                     Token_expiry_date: userInfo.token_authorization_expiry_date,
//                     isadmin: userInfo.isadmin,
//                     grand: userInfo.isgrand,
//                     issupperadmin: userInfo.issupperadmin,
//                     company_info: userInfo.company_info
//                 })
//             );
//             setTimeout(() => {
//                 client
//                     ?.danhMucWeb({
//                         ...new UserInfo(),
//                         username: userInfo.userName,
//                     } as UserInfo)
//                     .then((res) => {
//                         setListSysMenu(res);
//                         dispatch(getAllMenu(res));
//                     })
//                     .catch((err) => {
//                         if (err.status === 401) {
//                             localStorage.removeItem("SessionLogin");
//                             onLogout();
//                         }
//                     });
//             }, 500);
//         }
//     }, [isLogin]);

//     const onLogout = () => {
//         dispatch(clearLogin());
//         dispatch(clearRoles());
//         localStorage.removeItem("SessionLogin");
//         history.push("/admin");
//         setLogout(!logout);

//         indexedDBService.openDB().then((db) => {
//             // const data = { id: 1, name: 'John Doe', age: 25 };
//             indexedDBService.getData(db, userInfo.userName).then((data) => {
//                 if(data.userName === userInfo.userName){
//                     indexedDBService.deleteData(db, data.userName).then(() => {
//                         console.log('Data delete to object store');

//                       }).catch((error) => {
//                         console.log(error);
//                       });
//                 }
//               }).catch((error) => {
//                 console.log(error);
//               });

//           }).catch((error) => {
//             console.log(error);
//           });

//         // notification("success", "Đăng xuất thành công !");
//     };

//     const handleChangeCollapsed = (collaped: boolean) => {
//         setCollapsed(!collaped);
//     };
//     return (
//         <div className="AdminPage">
//             {isLogin || !_.isEmpty(SessionLogin) ? (
//                 <Layout style={{ height: "94vh" }}>
//                     <Navbar
//                         collapsed={collapsed}
//                         onChangeCollapsed={handleChangeCollapsed}
//                         username={userInfo.userName}
//                         ten_cty={userInfo.ten_cty}
//                         nam_tc={userInfo.nam_tc}
//                         email={userInfo.email}
//                         phone={userInfo.phone}
//                         onLogout={onLogout}
//                     />
//                     <Layout>
//                         {size.width > 550 ? (
//                             <Leftbar
//                                 menus={listSysMenu.filter((item) =>
//                                     item.menuid?.endsWith(".00.00")
//                                 )}
//                                 onLogout={onLogout}
//                                 collapsed={collapsed}
//                                 onClickMenu={(menu) => {
//                                     dispatch(getMenu(menu.menuid));
//                                 }}
//                                 userInfo={userInfo}
//                                 style={{ minHeight: "94vh", height: "inherit" }}
//                                 defaultSelectedKeys={
//                                     defaultMenu ? [defaultMenu] : ["30.00.00"]
//                                 }
//                             />
//                         ) : (
//                             <Leftbar
//                                 menus={listSysMenu.filter((item) =>
//                                     item.menuid?.endsWith(".00.00")
//                                 )}
//                                 onLogout={onLogout}
//                                 collapsed={!collapsed}
//                                 collapsedWidth={0}
//                                 onClickMenu={(menu) => {
//                                     setCollapsed(false);
//                                     dispatch(getMenu(menu.menuid));
//                                 }}
//                                 userInfo={userInfo}
//                                 style={{
//                                     position: "fixed",
//                                     minHeight: "94vh",
//                                     height: "inherit",
//                                     zIndex: 999,
//                                 }}
//                                 defaultSelectedKeys={
//                                     defaultMenu ? [defaultMenu] : ["30.00.00"]
//                                 }
//                             />
//                         )}

//                         <Content
//                             style={{
//                                 minHeight: "94vh",
//                                 maxHeight: "94vh",
//                                 overflow: "auto",
//                             }}
//                         >
//                             <Switch>
//                                 <Route
//                                     key="notfound"
//                                     path="/admin/notfound"
//                                     component={components["NOTFOUND"]}
//                                     exact
//                                 ></Route>
//                                 <Route path="/admin/change-password">
//                                     <UpdatePasswordPage />
//                                 </Route>
//                                 {listSysMenu.map((menu) => {
//                                     return (
//                                         menu.link_web &&
//                                         menu.component && (
//                                             <Route
//                                                 key={menu.menuid}
//                                                 path={`${menu.link_web}`}
//                                                 component={
//                                                     components[
//                                                         menu.component.toLocaleUpperCase()
//                                                     ]
//                                                 }
//                                             />
//                                         )
//                                     );
//                                 })}
//                                 <Redirect
//                                     exact
//                                     from="/admin"
//                                     to="/admin/dashboard_right"
//                                 />
//                             </Switch>
//                         </Content>
//                     </Layout>
//                 </Layout>
//             ) : (
//                 <Login />
//             )}
//         </div>
//     );
// }
