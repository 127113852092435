import React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    HomeClient,
    QLCCApartmentModel,
    QLCCClient,
    QLCCServiceModel,
    RoleRightWebModel,
    SysModel,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import '../ApartmentBuilding.css';
import notification from "../../../../common/notification/notification";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ToVietNamCurrency } from "../../../../components/utils/number.util";
import { STATUS_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";


interface IProps {
    open: boolean;
    payload?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (response: SysModel) => void;
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const status_message = ['success', 'error']


const columns: ColumnsType<QLCCServiceModel> = [
    {
        key: 'service_name',
        title: 'Tên dịch vụ',
        dataIndex: 'service_name',
    },
    {
        key: 'service_prices',
        title: 'Đơn giá',
        dataIndex: 'service_prices',
        align: "right",
        render(value, record, index) {
            return <div style={{ textAlign: 'end' }}>{ToVietNamCurrency(value)}</div>
        },
    },
    {
        key: 'is_default',
        title: 'Trạng thái',
        dataIndex: 'is_default',
        render(value, record, index) {
            return value ? <Chip label={"Bắt buộc"} sx={{padding: 0}} color="primary" /> : <Chip label="Không bắt buộc" color="default" />
        },
    },

];






var appSession: AppSession;
const ApartmentServiceAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();

    // 
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);


    const [data, setData] = React.useState<any[]>([]);
    const [isLoading, setLoading] = React.useState<boolean>(false);



    // const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    // 

    const [serviceSelected, setServiceSelected] = React.useState<string>();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>();

    React.useEffect(() => {
        if(props?.payload?.service_id){
            setSelectedRowKeys(props.payload.service_id?.split(";"));
            setServiceSelected(props.payload.service_id);
        }
       

    }, [props?.payload?.service_id])

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: QLCCServiceModel[]) => {
            
            setSelectedRowKeys(selectedRowKeys)
            const tempSelected = selectedRowKeys.join(";")
            
            setServiceSelected(tempSelected);
        },
        getCheckboxProps: (record: QLCCServiceModel) => ({
            disabled: record.is_default === true, // Column configuration not to be checked
            name: record.service_name,
        }),
    };

    React.useEffect(() => {

        if (props.action === ACTIONS.EDIT) {
            qLCClient
                .qlccServicesGet({
                    ...new QLCCServiceModel(),
                    ma_cty: props.payload?.ma_cty,
                    apartment_building_code: props.payload?.apartment_building_id,
                    apartment_building_id: props.payload?.apartment_building_id,
                    apartment_type_id: props.payload?.apartment_type_id,
                    action_type: 'APARTMENT-TYPE-SERVICE'
                } as QLCCServiceModel)
                .then((res) => {

                    const tempData: any[] = [];
                    res.forEach(data => {
                        tempData.push({
                            ...data,
                            key: data.id
                        })
                    })
                    setData(tempData);
                    
                })
                .catch(() => alert("Không lấy được dữ liệu"));
            // setId(props.itemSelected);
        } else {
            setData([]);
            setId(undefined)
        };
        // }
    }, [props.action]);


    // React.useEffect(() => {
    //     
    //     setLoading(true);
    //     homeClient
    //         ?.roleRightWebGetAll({
    //             ...new RoleRightWebModel(),
    //             rolename: "CC",
    //             username: SessionLogin.userName,
    //             menuid: menu?.menuid,
    //         } as RoleRightWebModel)
    //         .then((res) => {
    //             setRoles(res);
    //             setLoading(false);
    //         });
    //     setLoading(true);

    // }, []);


    const handleSubmit = () => {
        
        
        setLoading(true)


        qLCClient.qLCCApartmentsUpd({
            ...new QLCCApartmentModel(),
            ma_cty: props?.payload?.ma_cty,
            apartment_building_id: props.payload?.apartment_building_id,
            service_id: serviceSelected,
            id: props.payload.apartment_id,
            action_type: 'SERVICE'
        } as QLCCApartmentModel)
            .then((response) => {
                setLoading(false);
                notification(status_message[response.status!], STATUS_MESSAGE[`${props.action}_${response.status}`]);

                
                if (response?.status === 0) {
                    props.onEditSuccess(response);
                    props.onClose()
                }
            });



    };

    // const handleChangeInput = (e: any) => {
    //     const { name, value } = e.target;
    //     setData({ ...data, [name]: value } as QLCCServiceModel);
    // };




    return (
        <Dialog
            id="sysMenu"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "right",
            }}
            TransitionComponent={Transition}
            maxWidth='xs'

        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {"Dịch vụ căn hộ" +
                            " " +
                            `${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === ACTIONS.EDIT|| _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >

                <div className="row">
                    <div className="col-12 col-xl-7">
                        <Table
                            bordered
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                            }}
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                            locale={{
                                emptyText() {
                                    return <span style={{color: "red"}}>Loại căn hộ chưa có trong căn hộ hoặc chưa có phí trong loại căn hộ.</span>
                                },
                            }}
                        />
                    </div>
                </div>


            </DialogContent>
            <DialogActions>
                {props.action !== ACTIONS.VIEW && (
                    <Button variant="contained" onClick={handleSubmit}>
                        Lưu
                    </Button>
                )}
                <Button onClick={props.onClose}>Hủy</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default ApartmentServiceAction;


