import React from "react";
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    CRMClient,
    Company,
    HomeClient,
    LoginClient,
    QLCCResidentsModel,
    RoleRightWebModel,
    SysActionType,
    SysModel,
} from "../../../shared/service-proxies/api-shared";

import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import '../../actions/ApartmentBuilding.css';
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { Switch } from "antd";
import axios from "axios";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import notification from "../../../../common/notification/notification";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import DHSUploadImage from "../../../../components/DHS/DHS-Upload-Image/DHSUploadImage";


interface IProps {
    open: boolean;
    itemSelected?: any;
    title?: any;
    action?: any;
    apartment_id?: any,
    floor_id?: any,
    action_type?: string;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (data: boolean) => void;

}
interface IOpenCamera {
    pic: any;
    open: boolean;
}

const videoConstraints = {
    width: 240,
    height: 320,
    facingMode: "user",
};


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});
interface IAction {
    type: string;
    open: boolean;
    payload: any;
}
const defaultAction: IAction = {
    type: "",
    payload: null,
    open: false,
};






var appSession: AppSession;
const status_message: string[] = ['success', 'error']
const CompanyInfoAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string | undefined>(props.itemSelected);
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const crmClient = new CRMClient(appSession, BASE_URL_API);

    const loginClient = new LoginClient(appSession, BASE_URL_API);

    const [data, setData] = React.useState<Company>();
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [reload, setReload] = React.useState<boolean>(false);

    const homeClient = new HomeClient(appSession, BASE_URL_API);

    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [action, setAction] = React.useState<IProps>(props);
    const [fullScreen, setFullscreen] = React.useState<boolean>(false);


    // 


    const [actCic, setActCic] = React.useState<IAction>(defaultAction);

    const webcamRef = React.useRef<any>(null);

    const [openCamera, setOpenCamera] = React.useState<IOpenCamera>({
        pic: "",
        open: false,
    });

    const handleCloseCamera = () => {
        setOpenCamera({ ...openCamera, pic: "", open: false });
    };

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef?.current?.getScreenshot();
        /*fileClient
            .postFile_v4([imageSrc.replace("data:image/jpeg;base64,", "")])
            .then((res) => {
                if (res) {
                    let tempArr: string[] = [];
                    res.forEach((path: any, pathIndex: number) => {
                        tempArr.push(path);
                    });
                    switch (openCamera.pic) {
                        case "hinh_luc_vao":
                            setListHinhLucVao([...listHinhLucVao].concat(tempArr));
                            break;
                        case "hinh_luc_ra":
                            setListHinhLucRa([...listHinhLucRa].concat(tempArr));
                            break;
                    }
                } else notification("warning", "Không lấy được hình ảnh");
            });*/

        if (imageSrc) {

            setData({
                ...data,
                [openCamera.pic]: imageSrc
            } as QLCCResidentsModel)
        }
        // setOpenCamera({ ...openCamera, pic: "", open: false });
    }, [webcamRef, openCamera.pic]);




    React.useEffect(() => {

        setLoading(true);
        homeClient
            ?.roleRightWebGetAll({
                ...new RoleRightWebModel(),
                rolename: "CC",
                username: SessionLogin.userName,
                menuid: menu?.menuid,
            } as RoleRightWebModel)
            .then((res) => {
                setRoles(res);
                setLoading(false);
            });
        setLoading(true);

    }, []);

    // React.useMemo(() => {
    //     setData({
    //         ...data,
    //         logo1: `https://media-cdn-v2.laodong.vn/storage/newsportal/2021/1/26/874344/Can-Cuoc-Cong-Dan-Ga.jpg`
    //     })
    // }, [data.logo1])

    React.useEffect(() => {
        setLoading(true);

        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            loginClient.listCompany()
                .then(res => {
                    setData(res[0]);
                    setReload(false)
                }).catch(() => alert("Không lấy được dữ liệu"));
        }
        else {
            setData({
                ...new QLCCResidentsModel(),
                apartment_id: props.apartment_id,
                is_water: true,
            } as QLCCResidentsModel);
            setId('')
            setLoading(false);
        }
    }, [props.open]);

    // Load lần đầu tiên
    React.useEffect(() => {


    }, []);

    const showCamera = (e: any, file: any) => {
        setOpenCamera({
            ...openCamera,
            pic: file,
            open: true,
        });
    };

    const onChangeChecked = (checked: boolean) => {

        setData(pre => ({
            ...pre,
            'active': checked,
        } as Company));
    };

    const handleSubmit = () => {

        switch (props?.action.toUpperCase()) {


            case ACTIONS.EDIT:
                crmClient.companyInfoUpd({
                    ...new Company(),
                    ...data
                } as Company)
                    .then(response => {
                        setLoading(false);
                        notification(status_message[response ? 0 : 1], STATUS_MESSAGE[`${props.action}_${response ? 0 : 1}`]);
                        if (response) {
                            props.onEditSuccess(true);
                            props.onClose();

                        }
                    })
                    .catch((error: any) => {
                        notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                        setLoading(false);
                    })
                break
        }
    };

    const handleChangeInput = (e: any) => {
        const { name, value, type, checked } = e.target;


        if (type === 'checkbox') {
            setData({ ...data, [name]: checked } as QLCCResidentsModel);

        }
        else setData({ ...data, [name]: value } as QLCCResidentsModel);

    };

    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        setData({ ...data, [name]: new Date(value) } as QLCCResidentsModel);
    }

    const handleRemoveImage = (e: any) => {
        const { name } = e;

        if (name) {
            setData({
                ...data,
                [name]: ''
            } as QLCCResidentsModel)
        }
    }

    const handleChangeImage = async (e: any) => {

        const { name, files } = e.target;
        const formData = new FormData();
        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };
        for (const file of files) {
            formData.append("MyFile", file);
        }
        setLoading(true)

        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    setLoading(false)
                    setData(pre => ({ ...pre, [name]: res.data[0].path } as QLCCResidentsModel))
                } else notification("warning", "Không lấy được hình ảnh");
            });

        e.target.value = '';

    }

    const handleAddNewImage = async (e: any) => {

        const { name, files } = e.target;
        const formData = new FormData();
        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };
        for (const file of files) {
            formData.append("MyFile", file);
        }
        setLoading(true)

        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    setLoading(false)
                    setData(pre => ({ ...pre, [name]: res.data[0].path } as QLCCResidentsModel))
                } else notification("warning", "Không lấy được hình ảnh");
            });

        e.target.value = '';
    }

    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                // handleSubmit();             
                handleSubmit()
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
        }
    }


    return (
        <Dialog
            // id="sysMenu"
            open={props.open}
            onClose={props.onClose}
            PaperProps={{
                sx: !fullScreen ? {
                    minHeight: "90vh",
                    height: "90vh"
                } : undefined
            }}
            TransitionComponent={Transition}
            maxWidth="lg"
            fullWidth
            fullScreen={fullScreen}
            className="Asset-control"

        >


            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {(props.title ?? menu?.bar) +
                            " " +
                            `${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === "UPDATE" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1.25 }}>
                        <IconButton
                            edge="end"

                            onClick={() => {
                                setFullscreen(pre => !pre)
                            }}
                            aria-label="full screen"
                        >
                            {fullScreen ? <FullscreenExitIcon sx={{ color: '#1f2937' }} /> : <FullscreenIcon sx={{ color: '#1f2937' }} />}
                        </IconButton>
                        <IconButton
                            edge="end"
                            onClick={() => {
                                props.onClose();
                            }}
                            aria-label="close"
                        >
                            <CloseIcon
                                sx={{ color: '#1f2937' }}
                            />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >

                <div className="row">

                    <div className="col-xl-6">
                        <div className="card mt-2">

                            <div className="card-body pt-2">
                                <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                    Thông tin công ty
                                </div>

                                {/* Mã cty */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mã cty</InputLabel>
                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        autoFocus
                                        name="ma_cty"
                                        onChange={handleChangeInput}
                                        value={data?.ma_cty}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />

                                </Box>

                                {/* Tên tcty */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tên tcty</InputLabel>
                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        name="ten_tcty"
                                        autoComplete="new-password"
                                        onChange={handleChangeInput}
                                        value={data?.ten_tcty}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />

                                </Box>
                                {/* Tên cty */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tên cty</InputLabel>
                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        name="dd"
                                        autoComplete="new-password"
                                        onChange={handleChangeInput}
                                        value={data?.ten_cty}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />

                                </Box>
                                {/* Địa chỉ */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Địa chỉ</InputLabel>
                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        name="dia_chi"
                                        autoComplete="new-password"
                                        onChange={handleChangeInput}
                                        value={data?.dia_chi}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />

                                </Box>
                                {/* Mã thuế */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mã thuế</InputLabel>
                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        name="ma_thue"
                                        autoComplete="new-password"
                                        onChange={handleChangeInput}
                                        value={data?.ma_thue}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />

                                </Box>
                                {/* Tel */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tel</InputLabel>
                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        name="tel"
                                        autoComplete="new-password"
                                        onChange={handleChangeInput}
                                        value={data?.tel}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />

                                </Box>
                                {/* Fax */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Fax</InputLabel>
                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        name="fax"
                                        autoComplete="new-password"
                                        onChange={handleChangeInput}
                                        value={data?.fax}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />

                                </Box>
                                {/* Email */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Email</InputLabel>
                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        name="email"
                                        type="email"
                                        autoComplete="new-password"
                                        onChange={handleChangeInput}
                                        value={data?.email}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />

                                </Box>


                            </div>
                        </div>

                    </div>

                    {/* column 2 */}
                    <div className="col-xl-6">
                        <div className="card mt-2">

                            <div className="card-body pt-2">
                                <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                    Thông tin liên hệ
                                </div>
                                {/* Giám đốc */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Giám đốc</InputLabel>
                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        name="giam_doc"
                                        autoComplete="new-password"
                                        onChange={handleChangeInput}
                                        value={data?.giam_doc}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />

                                </Box>
                                {/* Kế toán trưởng */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Kế toán trưởng</InputLabel>
                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        name="ktt"
                                        autoComplete="new-password"
                                        onChange={handleChangeInput}
                                        value={data?.ktt}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />

                                </Box>

                                {/* Thủ quỷ */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Thủ quỷ</InputLabel>
                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        name="thu_quy"
                                        autoComplete="new-password"
                                        onChange={handleChangeInput}
                                        value={data?.thu_quy}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />

                                </Box>

                            </div>
                        </div>
                        {/*  */}
                        <div className="card mt-2">

                            <div className="card-body pt-2">
                                <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                    Thông tin ngân hàng
                                </div>
                                {/* Tên NH */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tên NH</InputLabel>
                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        name="ten_nh"
                                        autoComplete="new-password"
                                        onChange={handleChangeInput}
                                        value={data?.ten_nh}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />

                                </Box>
                                {/* Số tk NH */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số tài khoản NH</InputLabel>
                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        name="so_tk_nh"
                                        autoComplete="new-password"
                                        onChange={handleChangeInput}
                                        value={data?.so_tk_nh}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />

                                </Box>
                                {/* Tỉnh/Thành phố NH */}
                                <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tỉnh/Thành phố NH</InputLabel>
                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        name="tinh_tp_nh"
                                        autoComplete="new-password"
                                        onChange={handleChangeInput}
                                        value={data?.tinh_tp_nh}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />

                                </Box>
                                {/* Tên DB */}
                                {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
<InputLabel sx={{ fontSize: 14, color: '#333' }}>Tên DB</InputLabel>
<TextField
    className="cc-input"
    sx={{
        width: "100%",
        marginRight: "5px",
    }}
    name="dbname"
    autoComplete="new-password"
    onChange={handleChangeInput}
    value={data?.dbname}
    disabled={props.action === ACTIONS.VIEW}
/>

</Box> */}
                            </div>
                        </div>


                        <div className="card mt-2">

                            <div className="card-body pt-2">
                                <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                    Trạng thái
                                </div>

                                <Box className="row">
                                    <Box className="col-6">
                                        <InputLabel sx={{ fontSize: 14, color: '#333', }}>
                                            Hoạt động

                                        </InputLabel>
                                        <Switch
                                            checked={data?.active}
                                            onChange={onChangeChecked}
                                            disabled={props.action === ACTIONS.VIEW}
                                        />
                                    </Box>

                                </Box>

                            </div>
                        </div>
                    </div>


                    {/* <div className="col-xl-4 mt-2">
                      
                    </div> */}
                    <div className="col-12 mt-2">
                        <div className="card">

                            <div className="card-body">
                                <div>
                                    Logo
                                </div>
                                {/* Logo1 */}
                                <Box style={{ margin: "5px 0px" }} className="row no-gutters">
                                    <Box className="col-xl-4">
                                        <div className="d-flex align-items-center">
                                            <InputLabel sx={{ fontSize: 14 }}>
                                                Logo1
                                            </InputLabel>

                                        </div>
                                        {/* Logo1 */}
                                        <DHSUploadImage
                                            name="logo1"
                                            value={data?.logo1}
                                            width={"318px"}
                                            height={"188px"}
                                            handleAddNewImage={handleAddNewImage}
                                            handleChangeImage={handleChangeImage}
                                            handleRemoveImage={handleRemoveImage}
                                            disable={props?.action === ACTIONS.VIEW}
                                        />
                                    </Box>
                                    <Box className="col-xl-4">
                                        {/* Logo2 */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <div className="d-flex align-items-center">
                                                <InputLabel sx={{ fontSize: 14 }}>
                                                    Logo2
                                                </InputLabel>

                                            </div>
                                            <DHSUploadImage
                                                name="logo2"
                                                value={data?.logo2}
                                                width={"318px"}
                                                height={"188px"}
                                                handleAddNewImage={handleAddNewImage}
                                                handleChangeImage={handleChangeImage}
                                                handleRemoveImage={handleRemoveImage}
                                                disable={props?.action === ACTIONS.VIEW}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className="col-xl-4">
                                        {/* Logo3 */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <div className="d-flex align-items-center">
                                                <InputLabel sx={{ fontSize: 14 }}>
                                                    Logo3
                                                </InputLabel>

                                            </div>
                                            <DHSUploadImage
                                                name="logo3"
                                                value={data?.logo3}
                                                width={"318px"}
                                                height={"188px"}
                                                handleAddNewImage={handleAddNewImage}
                                                handleChangeImage={handleChangeImage}
                                                handleRemoveImage={handleRemoveImage}
                                                disable={props?.action === ACTIONS.VIEW}
                                            />
                                        </Box>
                                    </Box>
                                </Box>

                            </div>
                        </div>

                    </div>
                    <div className="col-12 mt-2">
                        <div className="card">

                            <div className="card-body">
                                <div>
                                    Con dấu
                                </div>
                                {/*  Con dấu 1 */}
                                <Box style={{ margin: "5px 0px" }} className="row no-gutters">
                                    <Box className="col-xl-4">
                                        <div className="d-flex align-items-center">
                                            <InputLabel sx={{ fontSize: 14 }}>

                                                Con dấu 1
                                            </InputLabel>

                                        </div>
                                        <DHSUploadImage
                                            name="condau1"
                                            value={data?.condau1}
                                            width={"318px"}
                                            height={"188px"}
                                            handleAddNewImage={handleAddNewImage}
                                            handleChangeImage={handleChangeImage}
                                            handleRemoveImage={handleRemoveImage}
                                            disable={props?.action === ACTIONS.VIEW}
                                        />
                                    </Box>
                                    <Box className="col-xl-4">
                                        {/* Con dấu 2 */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <div className="d-flex align-items-center">
                                                <InputLabel sx={{ fontSize: 14 }}>
                                                    Con dấu 2
                                                </InputLabel>

                                            </div>
                                            <DHSUploadImage
                                                name="condau2"
                                                value={data?.condau2}
                                                width={"318px"}
                                                height={"188px"}
                                                handleAddNewImage={handleAddNewImage}
                                                handleChangeImage={handleChangeImage}
                                                handleRemoveImage={handleRemoveImage}
                                                disable={props?.action === ACTIONS.VIEW}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className="col-xl-4">
                                        {/* Con dấu 3 */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <div className="d-flex align-items-center">
                                                <InputLabel sx={{ fontSize: 14 }}>
                                                    Con dấu 3
                                                </InputLabel>

                                            </div>
                                            <DHSUploadImage
                                                name="condau3"
                                                value={data?.condau3}
                                                width={"318px"}
                                                height={"188px"}
                                                handleAddNewImage={handleAddNewImage}
                                                handleChangeImage={handleChangeImage}
                                                handleRemoveImage={handleRemoveImage}
                                                disable={props?.action === ACTIONS.VIEW}
                                            />
                                        </Box>
                                    </Box>
                                </Box>

                            </div>
                        </div>

                    </div>
                </div>





            </DialogContent>
            <DialogActions>

                <DHSDialogAction

                    action={props.action}
                    menu={menu}
                    onClickAction={handleClickAction}
                />

            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default CompanyInfoAction;


