/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DHSToolbar from '../../../components/DHS/DHS-Toolbar/DHSToolbar';
import { IReducer, Notification } from '../../../components/layouts';
import DHSGridView from '../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
import { getRoles } from '../../../redux/actions/RoleActions';
import { AppSession } from '../../shared/app-session/app-session';
import {
    CommonClient,
    GLClient,
    HomeClient,
    ParaObjTableList,
    QLCCClient,
    QLCCFeedbackModel,
    RoleRightWebModel,
    SysActionType,
} from '../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../shared/service-proxies/config';

import { useHistory, useLocation } from 'react-router-dom';
import BlockUI from '../../../components/Block-UI/Block-ui';
import notification from '../../../common/notification/notification';
import moment from 'moment';
import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, Slide, Switch, Toolbar, Typography } from '@mui/material';
import CTXDate from '../../../components/CTX/DatePicker';
import { ParaObjGetVch } from '../../shared/service-proxies/api-shared';
import DHS_Select from '../../../components/DHS/DHS_Select';
import { callApiToken } from '../../../routes/configApi';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from "@mui/icons-material/Close";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Column, FilterRow, HeaderFilter, Paging, Scrolling, SearchPanel, Selection, Summary, TotalItem } from 'devextreme-react/data-grid';
import CCGridView from '../report/cash-book-report/component/CCGridView';
import DHSBreadcrumb from '../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';

import {
    RowInsertedInfo,
    RowInsertingInfo,
    RowRemovedInfo,
    RowRemovingInfo,
    RowUpdatedInfo,
    RowUpdatingInfo,
} from "devextreme/ui/data_grid";

import { ACTIONS } from '../../../common/enums/DHSToolbarRoleAction';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../common/Interfaces/StatusMessage';


import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { EventInfo } from "devextreme/events";
import { exportExcelReportNoFooter } from '../../../components/DHS/DHS_ExportExcelReport/exportExcelReportNoFooter';
import DataGrid from 'devextreme-react/data-grid';
import DHSToolbarRole from "../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import { getIndexRowSelected, getKeyRowSelected } from "../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import { getListKeyRowSelected } from '../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';


const convert = ["success", "error", "warning", "info"];

var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any,
    feedbackItem: any;

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null,
    feedbackItem: {},
}


const roleMenu: string = 'CC';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const LockedList = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const gLClient = new GLClient(appSession, BASE_URL_API);
    const common = new CommonClient(appSession, BASE_URL_API);
    const [dataTable, setDataTable] = React.useState<any[]>([] as any);
    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const [isShowFilterTable, setIsShowFilterTable] = React.useState<boolean>(true);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const Token = {
        Token: SessionLogin.Token
    }

    const [paraObjGetVch, setParaObjGetVch] = React.useState<ParaObjGetVch>({
        ... new ParaObjGetVch(),
        ma_cty: SessionLogin.ma_cty,
        ngay_ct1: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1),
        ngay_ct2: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 0),
        islock: false,
        ma_ct: "",
        so_ct1: "",
        so_ct2: "",
        phan_he: ""
    } as ParaObjGetVch);

    const [chungTuList, setChungTuList] = React.useState<any>([] as any);
    const [phanHeList, setPhanHeList] = React.useState<any>([] as any);
    const [header, setHeader] = React.useState<string>("");

    const [tableName, setTableName] = React.useState<string>("");
    const [keyDataTB, setKeyDataTB] = React.useState<string>("");



    const maCT = {
        "CA1": "api/GL/GetDataVoucherCA1_Lock", // PHIEU CHI
        "CA2": "api/GL/GetDataVoucherCA2_Lock", // PHIEU CHI
        "SO5": "api/GL/GetDataVoucherSO5_Lock", // HOA DON DICH VU
    }

    const keyDataTable = {
        "CA1": "stT_REC",
        "CA2": "stT_REC",
        "SO5": "stt_rec",
    }

    React.useEffect(() => {
        onLoadPhanHeList();
    }, [menu?.component])

    React.useEffect(() => {
        try{
            handleSubmit();
        }catch(ex){
            console.log(`Lỗi load lần đầu`)
        }
    }, [apartment_building_id])

    /* GET - Phân hệ */
    const onLoadPhanHeList = () => {
        const body =
        {
            ma_cty: SessionLogin.ma_cty,
            tablelist: "sysmodule",
            apartment_building_id: apartment_building_id
        }
        callApiToken("api/Common/LoadFilterList", "POST", body, Token)
            .then((res) => {
                setPhanHeList(res.data);
                setLoading(false);
            })
            .catch((error) => console.log(error));
    }

    /* GET - Chứng từ */
    const onloadGetVoucherByModule = (_phanHe: string = "") => {
        const body =
        {
            ma_cty: SessionLogin.ma_cty,
            phan_he: _phanHe,
            apartment_building_id: apartment_building_id
        }
        callApiToken("api/Common/GetVoucherByModule", "POST", body, Token)
            .then((res) => {
                if (res.status === 200) {
                    setChungTuList(res.data);
                }
            })
            .catch((error) => console.log(error));
    }

    React.useEffect(() => {
        if (paraObjGetVch.phan_he) {
            onloadGetVoucherByModule(paraObjGetVch.phan_he);
        }
    }, [paraObjGetVch.phan_he])

    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;
        setParaObjGetVch({
            ...paraObjGetVch,
            [name]: value,
        } as ParaObjGetVch)
    }

    const handleChangeDate = (event: any) => {
        const { name, value } = event;
        setParaObjGetVch({
            ...paraObjGetVch,
            [name]: new Date(value)
        } as ParaObjGetVch)
    }

    const handleChangeSwitcher = (event: any) => {
        const { name, checked } = event.target;
        setParaObjGetVch({
            ...paraObjGetVch,
            [name]: checked
        } as ParaObjGetVch)
    }

    const onloadData = () => {

        if (paraObjGetVch.ma_ct) {
            const index = Object.keys(maCT).indexOf(paraObjGetVch.ma_ct?.toLocaleUpperCase()!);

            // TODO: SET UTC DATE
            const body = {
                ...paraObjGetVch,
                // ngay_ct1: new Date(Date.UTC(paraObjGetVch.ngay_ct1?.getFullYear()!,
                //     paraObjGetVch.ngay_ct1?.getMonth()!,
                //     paraObjGetVch.ngay_ct1?.getDate()!,
                //     0, 0, 0)),
                // ngay_ct2: new Date(Date.UTC(paraObjGetVch.ngay_ct2?.getFullYear()!,
                //     paraObjGetVch.ngay_ct2?.getMonth()!,
                //     paraObjGetVch.ngay_ct2?.getDate()!,
                //     23, 59, 0)),
                ngay_ct1: moment(paraObjGetVch.ngay_ct1).startOf('day').utc(true).toDate(),
                ngay_ct2: moment(paraObjGetVch.ngay_ct2).endOf('day').utc(true).toDate(),
                apartment_building_id: apartment_building_id
            } as ParaObjGetVch | any;

            callApiToken(Object.values(maCT)[index], "POST", body, Token)
                .then((res) => {
                    setDataTable([...res.data]);
                    if (res.status === 200) setIsShowFilterTable(false);
                })
                .catch((error) => {
                    Notification(convert[convert.length - 1], "Không có chứng từ được tìm thấy!")
                });

        }
        // switch (paraObjGetVch.ma_ct?.toLocaleUpperCase()) {
        //     case "CA1":
        //         // PHIEU THU
        //         callApiToken("api/GL/GetDataVoucherCA1_Lock", "POST", paraObjGetVch, Token)
        //             .then((res) => {
        //                 setDataTable([...res.data]);
        //                 if (res.status === 200) setIsShowFilterTable(false);
        //             })
        //             .catch((error) => console.log(error));
        //         break;
        //     case "CA2":
        //         // PHIEU CHI
        //         callApiToken("api/GL/GetDataVoucherCA2_Lock", "POST", paraObjGetVch, Token)
        //             .then((res) => {
        //                 setDataTable([...res.data]);
        //                 if (res.status === 200) setIsShowFilterTable(false);
        //             })
        //             .catch((error) => console.log(error));
        //         break;
        //     case "SO5":
        //         // HOA DON DICH VU
        //         callApiToken("api/GL/GetDataVoucherSO5_Lock", "POST", paraObjGetVch, Token)
        //             .then((res) => {
        //                 setDataTable([...res.data]);
        //                 if (res.status === 200) setIsShowFilterTable(false);
        //             })
        //             .catch((error) => console.log(error));
        //         break;
        //     default:
        //         Notification(convert[convert.length - 1], "Không có chứng từ được tìm thấy!")
        // }

    }

    const handleSubmit = () => {

        if (
            paraObjGetVch.ngay_ct1 && paraObjGetVch.ngay_ct2 &&
            (paraObjGetVch.ngay_ct1 > paraObjGetVch.ngay_ct2)) {
            Notification(convert[convert.length - 1], "Ngày bắt đầu không được bé hơn ngày kết thúc!")
            return;
        }


        onloadData();

        if (paraObjGetVch.ma_ct) {
            setTableName(chungTuList
                && chungTuList.find((item: any) => item.ma_ct === paraObjGetVch.ma_ct)
                ? chungTuList.find((item: any) => item.ma_ct === paraObjGetVch.ma_ct).table_name
                : "")

            const key = Object.values(keyDataTable)[Object.keys(keyDataTable).indexOf(paraObjGetVch.ma_ct!)]
            if (key) {
                console.log(key)
                setKeyDataTB(key);
            }
        }
        // // HEADER FOR TABLE
        // callApiToken("api/Baseform/FormatGridSyncData", "POST", {
        //     ma_cty: paraObjGetVch.ma_cty,
        //     ma_ct: paraObjGetVch.ma_ct,
        //     isHeader: true
        // }, Token)
        //     .then((res) => {
        //         setHeader(res.data);
        //         if (res.data) {
        //         }
        //     })
        //     .catch((error) => console.log(error));



    }

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {

        const id = await getKeyRowSelected(dataGridRef);
        const lstKey = await getListKeyRowSelected(dataGridRef);

        let isLock: boolean | undefined = undefined;
        switch (action?.action_code?.toLocaleUpperCase()) {
            case ACTIONS.LOCK:

                try {
                    const key = await getKeyRowSelected(dataGridRef);
                    const index = await getIndexRowSelected(dataGridRef);
                    if (dataGridRef.current && key && index >= 0) {
                        isLock = false;
                    } else {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                } catch (error) {
                    Notification(
                        TYPE_MESSAGE.WARNING,
                        STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                    );
                }

                break;
            case ACTIONS.UNLOCK:
                try {
                    const key = await getKeyRowSelected(dataGridRef);
                    const index = await getIndexRowSelected(dataGridRef);
                    if (dataGridRef.current && key && index >= 0) {
                        isLock = true;
                    } else {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                } catch (error) {
                    Notification(
                        TYPE_MESSAGE.WARNING,
                        STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                    );
                }
                break;
            case ACTIONS.SEARCH:
                setIsShowFilterTable(true)
                break;
            default:
                break;
        }

        if (isLock !== undefined) {
            const body: any = {
                ma_cty: SessionLogin.ma_cty,
                ma_ct: paraObjGetVch.ma_ct?.toLocaleUpperCase(),
                stt_rec: lstKey.join(';'),
                islock: isLock,
                ret: 0
            }

            callApiToken("api/GL/LockVoucher", "POST", body, Token)
                .then(response => {
                    if (response.status === 200) {
                        const status = response.data ? 0 : 1;
                        const message = response.data ? "Thực hiện thành công" : "";
                        Notification(convert[status], message)
                        onloadData();
                    }
                }).catch(error => {
                    console.log(error)
                })
        }

    };

    const location = useLocation();


    const dataGridRef = React.useRef<DataGrid>(null);

    return (
        <div className='container-fluid'>
            <Dialog
                id="sysMenu3"
                open={isShowFilterTable}
                TransitionComponent={Transition}
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
                onClose={() => setIsShowFilterTable(false)}
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            Lọc dữ liệu
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setIsShowFilterTable(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent>
                    <Grid container spacing={2} className='d-flex justify-content-center'>
                        <Grid item xs={12} className='m-2'>

                            {/* Phân hệ */}
                            <Box className='d-flex align-items-center justify-content-between mb-2 w-100'>
                                <InputLabel id="phan_he-label" className="cc-label">Phân hệ</InputLabel>
                                <Box
                                    className='w-75 '
                                >
                                    <DHS_Select
                                        id='phan_he-label'
                                        className='cc-input'
                                        name='phan_he'
                                        value={paraObjGetVch?.phan_he}
                                        onChange={handleChangeInput}
                                        options={phanHeList}
                                        fieldValue="moduleid"
                                        fieldDisplay="modulename"
                                    />
                                </Box>
                            </Box>

                            {/* Chứng từ */}
                            <Box className='d-flex align-items-center justify-content-between mb-2'>
                                <InputLabel id="ma_ct-label" className="cc-label">Chứng từ</InputLabel>
                                <Box
                                    className='w-75'
                                >
                                    <DHS_Select
                                        id='ma_ct-label'
                                        className='cc-input'
                                        name='ma_ct'
                                        value={paraObjGetVch?.ma_ct}
                                        onChange={handleChangeInput}
                                        options={chungTuList}
                                        fieldValue="ma_ct"
                                        fieldDisplay="ten_ct"
                                    />
                                </Box>
                            </Box>

                            {/* Từ ngày */}
                            <Box className='d-flex align-items-center justify-content-between mb-2'>
                                <InputLabel id="ngay_ct1-label" className="cc-label">Từ ngày</InputLabel>
                                <CTXDate
                                    id="ngay_ct1-label"
                                    className='w-75 cc-input'
                                    name='ngay_ct1'
                                    value={paraObjGetVch.ngay_ct1 || new Date()}
                                    maxDate={(paraObjGetVch.ngay_ct2 || new Date())}
                                    onChange={handleChangeDate}
                                />
                            </Box>

                            {/* Đến ngày */}
                            <Box className='d-flex align-items-center justify-content-between mb-2'>
                                <InputLabel id="ngay_ct1-label" className="cc-label">Đến ngày</InputLabel>
                                <CTXDate
                                    id="ngay_ct2-label"
                                    className='w-75 cc-input'
                                    name="ngay_ct2"
                                    value={paraObjGetVch.ngay_ct2 || new Date()}
                                    onChange={handleChangeDate}
                                    minDate={(paraObjGetVch.ngay_ct1 || new Date())}

                                />
                            </Box>

                            {/* Trạng thái */}
                            <Box className='d-flex align-items-center justify-content-between mb-2'>
                                <InputLabel id="islock-label" className="cc-label">Trạng thái</InputLabel>
                                <FormControlLabel
                                    aria-required="true"
                                    control={
                                        <Switch
                                            name='islock'
                                            checked={paraObjGetVch.islock}
                                            onChange={handleChangeSwitcher} />
                                    }
                                    label={paraObjGetVch.islock ? "Đã khóa" : "Chưa khóa"}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsShowFilterTable(false)}>Đóng</Button>
                    <Button
                        variant='contained'
                        color='info'
                        onClick={handleSubmit}
                    >
                        Tìm kiếm
                    </Button>
                </DialogActions>
            </Dialog>
            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >

                <DHSBreadcrumb
                    location={location}
                />
            </div>
            <DHSToolbarRole
                id={" "}
                menu={menu}
                onClickAction={handleClickAction}
            />

            {
                React.useMemo(() => {
                    return <>
                        <DHSDataGridEdit
                            data={dataTable}
                            table_name={tableName}
                            // key="so_ct"
                            keyExpr={keyDataTB}
                            dataGridRef={dataGridRef}
                            allowEdit={false}
                            pageSize={menu?.page_size}
                            isPagination={menu?.is_pagination}
                            isMultiple={true}
                            groupingAutoExpandAll={false}
                        />
                    </>
                }, [tableName, dataTable])
            }

            {/* <div className='container-fluid p-0 mt-3'>
                <CCGridView
                    dataSource={dataTable}
                    id='stt_rec'
                    header={header}
                    setIdItemSelected={(lstId: string) => setIdItemSelected(lstId)}
                />
            </div> */}
            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default LockedList;

