import List, { IListOptions } from "devextreme-react/list";
import React from "react";

interface IProps extends React.PropsWithChildren<IListOptions>{

}

const DHSList = React.forwardRef<List, IProps>((props, ref) => {
    return <List
                {...props}
                ref={ref}
              >
                {props?.children}
              </List>
})

export default DHSList;