import React, { useEffect } from "react";

import {
  BaseformClient,
  ParasysActionType,
  SysActionType,
  SysMenu,
  UserInfo,
} from "../../../app/shared/service-proxies/api-shared";
import { Button, Paper } from "@mui/material";
import BASE_URL_API from "../../../app/shared/service-proxies/config";
import { AppSession } from "../../../app/shared/app-session/app-session";
import { ACTIONS } from "../../../common/enums/DHSToolbarRoleAction";
import { IndexedDBService } from "../../../common/indexedDBApi";
import { DB_NAME, DB_VERSION, OBJECTSTORENAME } from "../../../common/indexedDBApi/constant";


interface IProps {
  menu?: SysMenu;
  action?: ACTIONS | string;
  onClickAction: (action: SysActionType, nowTime: Date) => void;
  isDialogAction?: boolean,
  condition_actions? : {[key: string]: boolean}
}
const default_hidden_click_action = [ACTIONS.ADD, ACTIONS.EDIT]
const default_hidden_action = [ACTIONS.PRINT]
const default_hidden_when_view = [ACTIONS.SAVE]


var appSession: AppSession;
const DHSDialogAction2: React.FC<IProps> = (props: IProps) => {
  const baseFormClient = new BaseformClient(appSession, BASE_URL_API);
  const indexedDBService = new IndexedDBService(DB_NAME, DB_VERSION, OBJECTSTORENAME);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [actionTypes, setActionTypes] = React.useState<SysActionType[]>();
  const [userInfo, setUserInfo] = React.useState<UserInfo>();



  useEffect(() => {
    baseFormClient.getActionType({
      ...new ParasysActionType(),
      action_type: props?.menu?.action_type2,
      language: 'vi-VN'
    } as ParasysActionType)
      .then(res => {
        setActionTypes(res);
      })

    indexedDBService.openDB().then((db) => {
      // const data = { id: 1, name: 'John Doe', age: 25 };

      // indexedDBService.addData(db, userInfo).then(() => {
      //   console.log('Data added to object store');

      indexedDBService.getData(db, SessionLogin.userName).then((data) => {
        setUserInfo(data);
      }).catch((error) => {
        console.log(error);
      });
      // }).catch((error) => {
      //   console.log(error);
      // });
    }).catch((error) => {
      console.log(error);
    });
  }, [])

  //#region handle_click_action
  const handleClick = (lst: SysActionType) => {

    // if ((userInfo?.isadmin || userInfo?.issuperadmin)) {

    props.onClickAction(lst, new Date());
    // }
    // else {
    //   baseFormClient.getUserPermissionAction({
    //     ...new ParaPermissionAction(),
    //     menuid: props?.menu?.menuid,
    //     username: SessionLogin.userName,
    //     action_code: lst.action_code,

    //   } as ParaPermissionAction)
    //     .then(res => {


    //       if (res?.grant_access === true) {
    //         props.onClickAction(lst, new Date());

    //       }
    //       else {
    //         notification(status_message[1], ERROR_PERMISSION_MESSAGE[lst.action_code!])
    //       }
    //     })
    //     .catch(error => notification(status_message[1], ERROR_PERMISSION_MESSAGE[lst.action_code!]));
    // }
    // props.onClickAction(lst, new Date());
    // }
  };

  //#endregion


  return (
    <div>
      <Paper elevation={0} className="role-dialog-action d-flex position-relative" sx={{ backgroundColor: 'inherit' }}>
        {actionTypes && actionTypes

          .map((itemFiltered, index) => {
            return <div

              key={itemFiltered.action_code ? itemFiltered.action_code + index : index}
            >
              {

                !(default_hidden_click_action.includes(props.action as ACTIONS ?? ACTIONS.EMPTY)
                  && default_hidden_action.includes(itemFiltered.action_code as ACTIONS ?? ACTIONS.EMPTY))
                  // Nếu như action là view thì ẩn nút trong default_hidden_when_view
                  ? (props.action === ACTIONS.VIEW && default_hidden_when_view.includes(itemFiltered.action_code as ACTIONS))
                    // action với điều kiện
                    ? <></> : (props.condition_actions && (props.condition_actions[itemFiltered.action_code!] || !Object.keys(props.condition_actions).includes(itemFiltered.action_code!)) ) || !props.condition_actions ? <Button
                      variant="contained"
                      disableElevation
                      key={itemFiltered.action_code ? itemFiltered.action_code : ""}
                      id={itemFiltered.action_code}
                      startIcon={<i className={itemFiltered.iconweb} style={{ fontSize: 16, color: props.isDialogAction ? "#fff" : itemFiltered.fontcolorweb, }}></i>}
                      className={
                        // itemFiltered.class_name +
                        "py-1"
                        // " " +
                        // (itemFiltered.disabled === true ? "disabled" : "")
                      }
                      sx={{
                        padding: '0 0.75rem',
                        margin: '0 0.225rem',
                        pointerEvents: 'auto',
                        fontSize: 14,
                        textTransform: 'capitalize',

                        backgroundColor: itemFiltered.backgroupcolorweb,
                        '&:hover': {
                          backgroundColor: itemFiltered.backgroupcolorweb,
                      },
                        //     (
                        //       itemFiltered.class_name?.toLocaleLowerCase() === "ADD".toLocaleLowerCase() ? "#337ab7" :
                        //         itemFiltered.class_name?.toLocaleLowerCase() === "EDIT".toLocaleLowerCase() ? "#5bc0de" :
                        //           itemFiltered.class_name?.toLocaleLowerCase() === "DETAIL".toLocaleLowerCase() ? "#94a3b8" :
                        //             itemFiltered.class_name?.toLocaleLowerCase() === "DELETE".toLocaleLowerCase() ? "#d9534f" : ""
                        //     )

                      }}
                      onClick={() => handleClick(itemFiltered as SysActionType)}
                    >
                      <span style={{ color: props.isDialogAction ? "#fff" : itemFiltered.fontcolorweb, }}>{itemFiltered.action_name}</span>
                    </Button>
                  : <></> : <></>}
            </div>
          })}
      </Paper>
    </div>
  );
};

DHSDialogAction2.propTypes = {};

// const mapStateToProps = (state:IReducer) => ({
//     list: state.ToolbarReducer?.list
// })

// export default connect(mapStateToProps, {} ) (DHSDialogAction2)
export default DHSDialogAction2;
