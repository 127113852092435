import React from 'react'
import numberWithCommas from '../../utils/numberWithCommas'

interface IProps {
    onChange?: (value: number, currency?: string) => void,
    handleKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
    handleBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void,
    pattern?: string,
    value: number,
    currency: string,
    label?: string,
    id?: string,
    name: string,
    className?: string,
    disabled?: boolean,
    readOnly?: boolean,
    error?: boolean,
    errorMessage?: string,
    currencyPosition?: 'left' | 'right',
    numbersAfterDecimal?: number,
    step?: number,
    objDataChangeGrid?: any,
}

const DHS_InputMoney: React.FC<IProps> = (props) => {
    const { currency, onChange, error, numbersAfterDecimal = 2, errorMessage, className, disabled, id, name, readOnly, label, currencyPosition = 'right' } = props

    const inputRef = React.useRef<HTMLInputElement | null>(null)
    const [value, setValue] = React.useState<any>(props.value)
    // const [caretPos, setCaretPos] = React.useState(0)
    // const [active, setActive] = React.useState(false)
    const [deletedCharacter, setDeletedCharacter] = React.useState(-1)

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        console.log(e.key)
        if (e.key === 'Delete' || e.key === 'Backspace') {
            const seletionStart = (inputRef.current?.selectionStart ?? 1) - 1
            if ((inputRef.current?.value[seletionStart] ?? '') === ',') {
                setDeletedCharacter(seletionStart)
            } else {
                setDeletedCharacter(-1)
            }
        }else{
            const keyCode = e.key;
            const allowKeys = ['ArrowRight', 'ArrowLeft',]
            const regex = /^[0-9.\b]+$/; // Chỉ cho phép nhập số và dấu chấm và backspace
            if(allowKeys.includes(keyCode)){
                return;
            }
            if ( !regex.test(keyCode)) {
              e.preventDefault();
            }
        }
        
    }



    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let caretP = e.target.selectionStart ?? 0
        let valueInput = e.currentTarget.value

        if (deletedCharacter > 0) {
            let temp = valueInput.split('')
            temp.splice(deletedCharacter - 1, 1)
            valueInput = temp.join('')
            setDeletedCharacter(-1)
            caretP--
        }
        if (!valueInput) {
            valueInput = '0'
        } else {
            let originLength = valueInput.length
            if (valueInput.startsWith('0')) {
                valueInput = valueInput.slice(1)
            }
            if (valueInput.startsWith('.')) {
                valueInput = "0" + valueInput
            }
            if (valueInput.indexOf(".") >= 0) {
                let decimal_pos = valueInput.indexOf(".")
                let left_side = valueInput.substring(0, decimal_pos)
                let right_side = valueInput.substring(decimal_pos + 1)
                // left_side = numberWithCommas(left_side.replaceAll(",", "").replace(/\D/g, ''))
                // right_side = numberWithCommas(right_side.replaceAll(",", "").replace(/\D/g, ''))
                right_side = right_side.substring(0, numbersAfterDecimal)
                valueInput = left_side + "." + right_side
            } else {
                // valueInput = numberWithCommas(valueInput.replaceAll(",", "").replace(/\D/g, ''))
            }
            const newLength = valueInput.length
            const newCarretPos = newLength - originLength + caretP
            // setCaretPos(newCarretPos > 0 ? newCarretPos : 0)
            // setActive(true)
        }
        if (onChange) {
            setValue(Number.parseFloat(valueInput.replaceAll(',', '')));
            onChange(Number.parseFloat(valueInput.replaceAll(',', '')), currency)
        }


    }

    React.useEffect(() => {
        if (inputRef && inputRef.current) {
            // inputRef.current.setSelectionRange(caretPos, caretPos)
            // setActive(false)
            // inputRef.current.focus();
        }
        try {
            let newvalue: any = props.objDataChangeGrid.find((event: any) => event.stt_rec === props.id)[name];
            setValue(newvalue);
        } catch { }
    }, [props.value])


    return (
        <div className={className}>
            {(label !== undefined) && <label htmlFor={id} className={className}>{label}</label>}
            <div className="input-group m-0">
                {currencyPosition === 'left' && <div className="input-group-prepend">
                    <span className="input-group-text">{currency}</span>
                </div>}
                <input
                    ref={inputRef}
                    type="text"
                    value={numberWithCommas(value)}
                    className={`flex-grow-1 form-control ${error ? 'is-invalid' : ''}`}
                    onChange={handleInputChange}
                    onKeyDownCapture={props.handleKeyPress}
                    readOnly={readOnly}
                    disabled={disabled}
                    name={name}
                    id={id}
                    pattern={props?.pattern}
                    onKeyDown={handleKeyDown}
                    onBlur={props.handleBlur}
                    style={{ textAlign: 'right', borderRadius: '5px', backgroundColor: 'transparent !important'}}
                    onMouseDown={(e) => e.stopPropagation()}
                />
                {/* {currencyPosition === 'right' && <div className="input-group-append">
                    <span className="input-group-text">{currency}</span>
                </div>} */}
                {error && <div className="invalid-feedback">
                    {errorMessage}
                </div>}
            </div>
        </div>
    )
}

export default DHS_InputMoney