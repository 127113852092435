import React from "react";
import { useParams } from "react-router-dom";
import notification from "../../../../../common/notification/notification";
import { AppSession } from "../../../../shared/app-session/app-session";
import {
  CRMClient,
  ContactPersonPosition,
  IContactPersonPosition,
  RoleRightWebModel,
} from "../../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../shared/service-proxies/config";

interface IProps {
  id: string;
  action: RoleRightWebModel | undefined;
  nowTime: Date;
  onDestroyAction?: (isDestroy: boolean, goBack?: number) => void;
}
var appSession: AppSession;
const PositionActionPage = (props: IProps) => {
  const crmClient = new CRMClient(appSession, BASE_URL_API);
  const [data, setData] = React.useState<IContactPersonPosition>(
    new ContactPersonPosition() as IContactPersonPosition
  );
  const [{ _id }, setId] = React.useState(useParams<{ _id: string }>());
  const [back, setBack] = React.useState<number>(-1);
  React.useEffect(() => {
    if (_id) {
      crmClient
        .crmContactPersonPositionGet({
          ...new ContactPersonPosition(),
          id: _id,
        } as ContactPersonPosition)
        .then((response) => {
          setData(response[0] as IContactPersonPosition);
        });
    }
  }, []);
  React.useEffect(() => {
    if (
      props.action &&
      props.action.class_name?.toLocaleUpperCase() === "SAVE"
    ) {
      try {
        if (_id) {
          crmClient
            .crmContactPersonPositionUpd({
              ...data,
              id: _id,
            } as ContactPersonPosition)
            .then((res) => {
              setBack((item) => (item -= 1));
              notification(
                res[0].ret === 0
                  ? "success"
                  : res[0].ret === 1
                  ? "error"
                  : "warning",
                res[0].message
              );
            })
        } else {
          crmClient
            .crmContactPersonPositionIns(data as ContactPersonPosition)
            .then((res) => {
              setBack((item) => (item -= 1));
              if(res[0].id) {
                setId({ _id: res[0].id });
              }
              notification(
                res[0].ret === 0
                  ? "success"
                  : res[0].ret === 1
                  ? "error"
                  : "warning",
                res[0].message
              );
            })
        }
        if (props.onDestroyAction) props.onDestroyAction(true, back -1);
      } catch (error) {
        console.log(error);
      }
    }
  }, [props.nowTime]);

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  return (
    <>
      <div className="d-flex">
        <div className="w-100" style={{ maxWidth: 800 }}>
          <div className="form-group">
            <label>Tên*</label>
            <input
              className="flex-grow-1 form-control"
              type="text"
              name="name"
              placeholder="Tên trạng thái"
              onChange={handleChangeInput}
              value={data.name}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PositionActionPage;
