import React from 'react'
import { UserInfoFilterModel, UserInfoModel } from '../../../../../shared/service-proxies/api-shared'
import { Avatar } from 'antd';
import BASE_URL_API from '../../../../../shared/service-proxies/config';

import GroupRemoveIcon from '@mui/icons-material/GroupRemove';

import './styles.css';
import { ACTIONS } from '../../../../../../common/enums/DHSToolbarRoleAction';
import { IReducer, Notification } from '../../../../../../components/layouts';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../../../../common/Interfaces/StatusMessage';
import { Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';

interface IProps {
    follower: UserInfoFilterModel[] | [];
    data?: string | undefined;
    removeFolloer?: (username: string) => void;
    action: string;
    enabled: string[];
    onSelectedUser?: (userInfo: UserInfoFilterModel) => void;
    onRemoveUser?: (userInfo: UserInfoFilterModel) => void;
}

const TaskFollower: React.FC<IProps> = (props) => {

    const { follower } = props;

    const onload = () => {
        const data = props.data;
        const lstUser: UserInfoFilterModel[] = [];

        if (data) {

            const lstUsername = data.split(';');
            if (lstUsername && lstUsername.length > 0) {
                lstUsername.map(username => {
                    const findUser = follower.find(item => item.username === username);
                    if (findUser) {
                        lstUser.push(findUser);
                    }
                })
            }
        }
        return lstUser;
    }

    const handleClickRM = (user: UserInfoFilterModel) => {
        if (props.enabled.includes(props.action)) {

            try {
                if (props.onRemoveUser) {
                    props.onRemoveUser(user);
                }
                if (props.removeFolloer) {
                    const data = props.data;
                    if (data && user.username) {
                        const lstUsername = data.split(';');
                        const findIndex = lstUsername.findIndex(item => item === user.username);
                        if (findIndex !== -1) {
                            lstUsername.splice(findIndex, 1);
                            props.removeFolloer(lstUsername.join(';'));
                        }
                    }
                }
            } catch (e) {
                console.log(e);
                Notification(
                    TYPE_MESSAGE.ERRORR,
                    STATUS_MESSAGE[`SERVER_ERROR`]
                );
            }
        } else {
            Notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`UNAVAILABLE_FEATURE`]
            );
        }
    }

    const RenderItemFL = (user: UserInfoFilterModel) => {
        const avatar1 = <Avatar className="task-follow-avatar" alt={user.username!} src={BASE_URL_API + (user.avatar ? user.avatar : "")} />;
        // const avatar1 = <Avatar className="task-follow-avatar" alt={user.username!} src="https://th.bing.com/th/id/OIP._AJzDMg7KIvtScY_AF8y1gAAAA?pid=ImgDet&rs=1" />;
        const avatar2 = <Avatar className="task-follow-avatar-color">{user.username ? user.username[0] : 'U'}</Avatar>;

        return (<div className='m-2 mb-3'>
            <p className="m-0 d-flex justify-content-between align-items-center">
                <Tooltip title={user.fullname ? user.fullname : user.username ? user.username : "Tài khoản không xác định"}>
                    <span className="d-flex align-items-center cursor-pointer" onClick={() => {
                        if (props.onSelectedUser) {
                            props.onSelectedUser(user)
                        }
                    }}>
                        <span>
                            {user.avatar ? avatar1 : avatar2}
                        </span>
                        <span className="d-inline mx-2">
                            {user.fullname ? user.fullname : user.username ? user.username : "Người dùng"}
                        </span>
                    </span>
                </Tooltip>
                <Tooltip title="Loại bỏ người theo dỗi">
                    <GroupRemoveIcon className="task-follow-icon-remove-group" onClick={() => handleClickRM(user)} />
                </Tooltip>
            </p>
        </div>);
    }

    return (
        <>
            {
                follower && follower.length > 0 ? (
                    <div className="task-follower-list">
                        {
                            onload().length > 0 &&
                            onload().map(user => (
                                RenderItemFL(user)
                            ))
                        }
                    </div>
                ) :
                    (<>
                        <p className="text-center">Không có người theo dõi</p>
                    </>)
            }
        </>
    )
}

export default TaskFollower;

