import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRoles } from '../../../redux/actions/RoleActions';
import { AppSession } from '../../../app/shared/app-session/app-session';
import {
    HomeClient,
    LoginModel,
    ProcessUserinfoDelModel,
    RoleRightWebModel,
    SiUserInfoClient,
    SysActionType,
    UserInfo as IUserInfo,
    UserInfoModel,
    ComfirmCodeModel,

} from '../../../app/shared/service-proxies/api-shared';
import BASE_URL_API from '../../../app/shared/service-proxies/config';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { IReducer } from '../../../components/layouts';
import BlockUI from '../../../components/Block-UI/Block-ui';
import notification from '../../../common/notification/notification';
import DHSToolbarRole from '../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import UserInfoAction from './action/UserInfoAction';
import { ACTIONS } from '../../../common/enums/DHSToolbarRoleAction';
import { components } from '../../../common/ComponentsConfig/components';
import DHSBreadcrumb from '../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../common/Interfaces/StatusMessage';
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DataGrid from 'devextreme-react/data-grid';
import { EventInfo } from 'devextreme/events';
import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import { getDataRowSelected, getIndexRowSelected, getListKeyRowSelected } from '../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';
import UserInfoChangePassword from './action/UserInfoChangePassword';
import DHSConfirmWithMessage from '../../../components/DHS/DHS_Confirm/DHSConfirmWithMessage';

const convert = ["success", "error", "warning"];
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

interface IConfirmMessage<T = any> {
    open: boolean,
    title?: string,
    description?: string,
    message?: string,
    payload?: T
}

const defaultConfirmMessage: IConfirmMessage = {
    open: false,
    title: "",
    description: "",
    message: "",
    payload: null
}

const roleMenu: string = 'CC';
const UserInfo: React.FC<{}> = () => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const dispatch = useDispatch();
    const history = useHistory();
    const { path } = useRouteMatch();
    const location = useLocation();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    // const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<UserInfoModel[]>([]);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const siUserInfoClient = new SiUserInfoClient(appSession, BASE_URL_API);

    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const [actCp, setActCp] = useState<IAction>(defaultAction);

    // 

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)


    //

    const [confirmResetPassword, setConfirmResetPassword] = React.useState<IConfirmMessage<IUserInfo>>(defaultConfirmMessage);

    // 
    useEffect(() => {
        setLoading(true)
        homeClient.roleRightWebGetAll({
            ...new RoleRightWebModel(),
            menuid: menu?.menuid,
            rolename: roleMenu,
            username: SessionLogin.userName,
        } as RoleRightWebModel)
            .then((res) => {
                dispatch(getRoles(res));
                setRoles(res);
                setLoading(false);
            })

    }, [menu?.component])

    //#region catch_history
    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            // Tác vụ cần thực hiện khi bạn quay lại component trước đó
            setSelectedItemKeys(undefined);
        });

        return () => {
            unlisten(); // Hủy lắng nghe khi component bị hủy (unmounted)
        };
    }, [history]);

    //#endregion

    useEffect(() => {
        setLoading(true)
        siUserInfoClient.userInfoGet2({
            ...new LoginModel(),
            userName: SessionLogin.userName,
            apartment_building_id: apartment_building_id
        } as LoginModel | any).then((res) => {
            setLoading(false)
            setDataTable(res)
        })
    }, [reload, apartment_building_id])

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code?.toUpperCase()) {
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<IUserInfo>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            if (window.confirm("Bạn có chắc chắn muốn xóa?")) {
                                setLoading(true)
                                siUserInfoClient.userInfoDel({
                                    ...new ProcessUserinfoDelModel(),
                                    ma_cty: SessionLogin.ma_cty,
                                    username: dataSelected?.username ?? null
                                } as ProcessUserinfoDelModel)
                                    .then(res => {
                                        notification(convert[res ? 0 : 1], STATUS_MESSAGE[`${ACTIONS.DELETE}_${res ? 0 : 1}`]);
                                        setLoading(false)
                                        setReload(!reload);
                                    })
                                    .catch(err => {
                                        setLoading(false)
                                        notification(convert[1], "Có lỗi xãy ra");

                                        setReload(!reload);

                                    })
                            }
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;

            case ACTIONS.VIEW:
            case ACTIONS.EDIT:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<IUserInfo>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: action?.action_code?.toUpperCase() ?? "",
                                payload: dataSelected ?? null
                            })
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.ADD:

                setActCx({
                    ...actCx,
                    open: true,
                    type: action?.action_code?.toUpperCase() ?? "",
                    // payload: selectedItemKeys?.username ?? null
                })
                break;

            case ACTIONS.PERMISSION:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<IUserInfo>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            history.push(`/admin/user/permission/${selectedItemKeys?.username}?fullname=${selectedItemKeys?.fullname}`);
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.CHANGE_PASSWORD:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<IUserInfo>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            setActCp(pre => ({
                                ...pre,
                                open: true,
                                payload: dataSelected

                            } as IAction))
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }

                break;

            case ACTIONS.GEN_PASSWORD:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<IUserInfo>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            setConfirmResetPassword(pre => ({
                                ...pre,
                                open: true,
                                title: `Tạo lại mật khẩu.`,
                                message: "Bạn chắc chắn muốn reset lại password cho người dùng " + dataSelected?.username,
                                payload: dataSelected

                            } as IConfirmMessage<IUserInfo>))
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.PRINT:
                if (dataGridRef.current) {
                    try {
                        const keys = await getListKeyRowSelected(dataGridRef);
                        if (dataGridRef.current && keys) {
                            console.log(keys)
                            const handlePrintBillWithAccount = (keys: string[]) => {
                                if (keys.length > 0) {
                                    window.open(
                                        BASE_URL_API + `/Report/Reports?ma_cty=${SessionLogin.ma_cty}&showuser=1&ma_ct=${"USERINFO"}&stt_rec=${keys.join(";")}&apartment_building_id=${apartment_building_id}`);
                                } else {
                                    notification(convert[3], "Chưa có hóa đơn được chọn");
                                }
                            };

                            handlePrintBillWithAccount(keys as string[]);
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            default: break;
        }

    }

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {

                const index = e.component.getRowIndexByKey(keys[0]);

                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
            });
        }
    };

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };

    //

    const handleChangeResetPasswordConfirm = (result: boolean, userSelected?: IUserInfo) => {
        if (result) {
            setLoading(true)
            siUserInfoClient.userInfoResetPassword(new ComfirmCodeModel({
                apartment_building_id: userSelected?.apartment_building_id,
                userName: userSelected?.username
            })).then(res => {
                if (res[0]?.status === 0) {
                    notification(TYPE_MESSAGE.SUCCESS, res[0]?.message)
                    setConfirmResetPassword(pre => ({
                        ...pre,
                        message: `Mật khẩu người dùng "${userSelected?.username}" đã được đổi lại thành: ` + res[0]?.de_code
                    }))
                    setReload(pre => !pre);
                } else {
                    notification(TYPE_MESSAGE.WARNING, res[0]?.message)
                }
                setLoading(false)

            }).catch(error => {
                console.error(error);
                setLoading(false)

            })
        } else {
            setConfirmResetPassword(defaultConfirmMessage)
        }
    }

    return (

        <Switch>
            <Route path={path} exact>
                <div className='container-fluid'>

                    {
                        React.useMemo(() => {
                            return <UserInfoAction
                                open={actCx.open}
                                onClose={function (): void {
                                    setActCx(defaultAction);
                                }}
                                action={actCx.type}
                                itemSelected={actCx.payload}
                                onEditSuccess={function (is_success: boolean): void {
                                    setReload(pre => !pre)
                                }} >

                            </UserInfoAction>
                        }, [actCx.open, actCx.payload, actCx.type])
                    }

                    {
                        React.useMemo(() => {
                            return <UserInfoChangePassword
                                open={actCp.open}
                                onClose={function (): void {
                                    setActCp(defaultAction);
                                }}
                                action={actCp.type}
                                itemSelected={actCp.payload}
                                onEditSuccess={function (is_success: boolean): void {
                                    setReload(pre => !pre)
                                }} >

                            </UserInfoChangePassword>
                        }, [actCp.open, actCp.payload, actCp.type])
                    }

                    <div
                        className="d-flex title-color"
                        style={{
                            height: 30,
                            color: "rgb(0 78 255 / 88%)",
                            marginTop: 15,
                            marginBottom: 15,
                            fontSize: 22,
                            fontWeight: 550,
                        }}
                    >
                        <DHSBreadcrumb
                            location={location} />
                        {/* <div role="presentation" onClick={() => { }}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link to={"/admin"} color="inherit" >
                                    Admin
                                </Link>
                                <Link to={"/admin/he-thong"} color="inherit" >
                                    he-thong
                                </Link>
                                <Typography color="text.primary">user</Typography>
                            </Breadcrumbs>
                       
                    </div> */}

                    </div>

                    <DHSToolbarRole
                        id={' '}
                        menu={menu}
                        customMenu={[]}
                        onClickAction={handleClickAction}
                    />

                    <DHSDataGridEdit
                        data={dataTable}
                        table_name={menu?.component}
                        // key="username"
                        keyExpr={"username"}
                        // mode="popup"
                        dataGridRef={dataGridRef}
                        allowEdit={false}
                        pageSize={menu?.page_size}
                        isPagination={menu?.is_pagination}
                        isMultiple={menu?.is_multiple}
                        groupingAutoExpandAll={false}
                        handleSelectionChanged={handleSelectionChanged}
                        handlePageChange={handlePageChange}

                    // handleExporting={handleExporting}
                    />

                    {/* <DHSGridView
                        onSelectRow={(row) => {
                            setIdItemSelected(row.username);
                        }}
                        // onListSelectRow={handleListOnselectRow}
                        itemClicked={function (): void {
                            throw new Error("Function not implemented.");
                        }}
                        table_name={menu?.component}
                        show_form_list={true}
                        showSelectBox={false}
                        data={dataTable}
                        showSortPanel={isShowSort}
                        showSearchPanel={isShowSearch}
                        showGroupPanel={isShowGroup}
                        showFilterPanel={isShowFilter}
                        defaultPageSize={2000}
                        grid_name={"Danh sách tài khoản nhân viên"}
                        excel={false}
                    /> */}
                    <BlockUI blocking={loading} title={""}></BlockUI>

                    {
                        // Reset password
                        React.useMemo(() => (
                            <DHSConfirmWithMessage
                                open={confirmResetPassword.open}
                                onClosed={(e, reason) => {
                                    // setConfirmResetPassword(defaultConfirmMessage)
                                }}
                                onSubmit={(result) => handleChangeResetPasswordConfirm(result, confirmResetPassword?.payload)}
                                zIndex={1600}
                                title={confirmResetPassword?.title ?? ""}
                                message={confirmResetPassword?.message ?? ""}
                                // renderContent={renderEmployeeContentConfirm}
                                showButton='submit_cancel'

                            />
                        ), [confirmResetPassword?.message, confirmResetPassword.open, confirmResetPassword?.payload, confirmResetPassword?.title])
                    }
                </div>
            </Route >
            {roles &&
                roles.map((role, index) => {

                    return (
                        role.link &&
                        role.link.length > 1 &&
                        role.component && (
                            <Route
                                key={index}
                                path={role.is_parameter ? role.link + ":_id" : role.link}
                                component={components[role.component.toLocaleUpperCase()]}

                            ></Route>
                        )
                    );
                })}


        </Switch >
    )
}

export default UserInfo;


