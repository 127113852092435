export const allowedBaseFileExtensions = /(\.jpg|\.jpeg|\.png)$/i;


export const validationExtensions = (filePath: string, allowedExtensions?: RegExp) => {
    if (allowedExtensions) {
        if (!allowedExtensions.exec(filePath)) {
            return false;
        }
        return true;
    }
    else {
        if (!allowedBaseFileExtensions.exec(filePath)) {
            return false;
        }
        return true;

    }
}
export const validationSize = (fsize: number, minSize: number = 0, maxSize: number = 4096,) => {

    const file = Math.round((fsize / 1024));
    // The size of the file.
    if (file > maxSize) {
        return 1;
    } else if (file <= minSize) {
        return -1;
    } else {
        return 0;
    }

}