import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Grid,
} from "@mui/material";
import {
    QLCCCategoriesModel,
    QLCCClient,
    QLCCDomesticWaterNormModel,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import CloseIcon from "@mui/icons-material/Close";

import { TransitionProps } from "@mui/material/transitions";

import BlockUI from "../../../../components/Block-UI/Block-ui";
import { Notification } from "../../../../components/layouts";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import CurrencyTextField from "react-currency-input-field";
interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});
var appSession: AppSession;
const DomesticWaterNormAction = (props: IProps) => {
    const convert = ["success", "error", "warning"];
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [data, setData] = React.useState<QLCCDomesticWaterNormModel>({} as QLCCDomesticWaterNormModel);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const [normPercent, setNormPercent] = React.useState<Number>();
    const [normPercent1, setNormPercent1] = React.useState<Number>();
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    React.useEffect(() => {
        setLoading(true);
        if (props.action === "EDIT" || props.action === "DETAIL") {
            qLCCClient
                .qLCCDomesticWaterNormGet({
                    ...new QLCCDomesticWaterNormModel(),
                    id: props.itemSelected,
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty
                } as QLCCDomesticWaterNormModel)
                .then((res) => {
                    setData(res[0])
                    setNormPercent(res[0].norm_percent);
                    setNormPercent1(res[0].norm_percent1);
                    setLoading(false);
                })
            setId(props.itemSelected);
        } else {
            setData(new QLCCCategoriesModel());
            setNormPercent(0);
            setNormPercent1(0);
            setId(undefined);
            setLoading(false);
        }
    }, [props.action]);


    const setDataObject = (name: string, value: string | number | undefined) => {
        setData({
            ...data,
            [name]: value,
        } as QLCCDomesticWaterNormModel);
    }

    const handleChangeInput = (e: any) => {
        if (props.action.toUpperCase() !== "DETAIL") {
            const { name, value } = e.target;
            // if (name == 'norm_percent1' || name == 'norm_percent') {
            //     if (value >= 0 && value <= 100) {
            //         if (name == 'norm_percent') {
            //             setNormPercent(value);
            //             setNormPercent1(100 - value);
            //         } else {
            //             setNormPercent1(value);
            //             setNormPercent(100 - value);
            //         }
            //     }
            // }
            // else {
            setDataObject(name, value);
            // }
        }
    }

    const handleChangeCurrentInput = (
        value: string | undefined, name?: string | undefined, values?: CurrencyInputOnChangeValues | undefined) => {
        if (props.action.toUpperCase() !== "DETAIL") {
            setData({ ...data, [name!]: value } as QLCCDomesticWaterNormModel);
        }
    }

    const handleSubmit = () => {
        switch (props.action.toUpperCase()) {
            case "ADD":

                qLCCClient
                    ?.qLCCDomesticWaterNormAction({
                        ...data,
                        // norm_value: Number(data.norm_value),
                        // norm_percent: normPercent || 0,
                        // norm_value1: Number(data.norm_value1),
                        // norm_percent1: normPercent1 || 0,
                        action_type: "INSERT",
                        apartment_building_id: apartment_building_id,
                        ma_cty: SessionLogin.ma_cty,
                    } as QLCCDomesticWaterNormModel)
                    .then((res) => {
                        Notification(convert[res.status || 0], res.message);
                        setLoading(false);
                        if (res.status == 0) {
                            props.onClose();
                            props.onEditSuccess(true)
                        }
                    })
                    .catch((err) => {
                        Notification(convert[err.status || 0], err.message);
                    });
                break;
            case "EDIT":
                qLCCClient
                    ?.qLCCDomesticWaterNormAction({
                        ...data,
                        id: props.itemSelected,
                        norm_value: Number(data.norm_value),
                        norm_percent: normPercent || 0,
                        norm_value1: Number(data.norm_value1),
                        norm_percent1: normPercent1 || 0,
                        action_type: "UPDATE",
                        apartment_building_id: apartment_building_id,
                        ma_cty: SessionLogin.ma_cty,
                    } as QLCCDomesticWaterNormModel)
                    .then((res) => {
                        setLoading(false);
                        Notification(convert[res.status || 0], res.message);
                        if (res.status == 0) {
                            props.onClose();
                            props.onEditSuccess(true);
                        }
                    })
                    .catch((err) => {
                        Notification(convert[err.status || 0], err.message);
                    });
                break;
            default:
                break;
        }
    }

    return (
        <Dialog
            id="sysMenu6"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            TransitionComponent={Transition}
            maxWidth="md"
        >
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === "DETAIL"
                                ? "[Chi tiết]"
                                : props.action === "EDIT" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tên định mức</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%" }}
                                name="name"
                                onChange={handleChangeInput}
                                value={data.name}
                                placeholder="Tên định mức ..."
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Giá trị</InputLabel>
                            <CurrencyTextField
                                className="flex-grow-1 form-control currency-input"
                                style={{ width: "100%", textAlign: "right" }}
                                allowDecimals
                                decimalsLimit={2}
                                decimalSeparator="."
                                groupSeparator=","
                                decimalScale={0}
                                placeholder="Chi phí"
                                intlConfig={{ locale: 'vi-VN', currency: 'VND' }}
                                name="norm_value"
                                onValueChange={handleChangeCurrentInput}
                                value={data.norm_value}
                            />

                        </Box>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>
                                Phần trăm (%)
                            </InputLabel>
                            <TextField
                                type="number"
                                className="cc-input"
                                inputProps={{style: {textAlign: "right"}}}
                                sx={{ width: "100%" }}
                                name="norm_percent"
                                onChange={handleChangeInput}
                                // value={data.norm_percent}
                                value={normPercent}
                                placeholder="Phần trăm định mức ..."
                            />
                        </Box>
                    </Grid> */}
                    {/* <Grid item xs={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Giá trị</InputLabel>
                            <CurrencyTextField
                                className="flex-grow-1 form-control currency-input"
                                style={{ width: "100%", textAlign: "right" }}
                                allowDecimals
                                decimalsLimit={2}
                                decimalSeparator="."
                                groupSeparator=","
                                decimalScale={0}
                                placeholder="Chi phí"
                                intlConfig={{ locale: 'vi-VN', currency: 'VND' }}
                                name="norm_value1"
                                onValueChange={handleChangeCurrentInput}
                                value={data.norm_value1}
                            />

                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>
                                Phần trăm (%)
                            </InputLabel>
                            <TextField
                                type="number"
                                className="cc-input"
                                inputProps={{style: {textAlign: "right"}}}
                                sx={{ width: "100%" }}
                                name="norm_percent1"
                                onChange={handleChangeInput}
                                // value={data.norm_percent1}
                                value={normPercent1}
                                placeholder="Phần trăm định mức ..."
                            />
                        </Box>
                    </Grid> */}
                </Grid>
            </DialogContent>
            <DialogActions>
                {
                    props.action !== "DETAIL" &&
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Lưu
                    </Button>
                }
                <Button onClick={props.onClose}>Đóng</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default DomesticWaterNormAction;
