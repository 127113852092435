import React from "react";
import { TextField, Dialog, DialogContent, DialogActions, InputLabel, Box, Slide, IconButton, AppBar, Toolbar, Typography, Divider, Grid, } from "@mui/material";
import notification from "../../../../common/notification/notification";
import { QLCCApartmentBuildingModel, QLCCBlockModel, QLCCPurposeOfUseModel, QLCCClient, SysActionType, } from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import JoditEditor from "jodit-react";
import PurposeAction from "../purpose-use/PurposeAction";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import { NumberBox, SelectBox, TextBox } from "devextreme-react";

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
}

interface File {
    id?: string,
    ma_cty?: string,
    stt_rec?: string,
    ma_ct?: string,
    ngay_ct?: string,
    so_ct?: string,
    name_file?: string,
    path?: string,
    file_extension?: string,
    size?: string,
    date_modified?: string,
    author_id?: string,
}

interface IAction {
    type: string;
    open: boolean;
    payload: any;
}

const defaultAction: IAction = {
    type: "",
    payload: null,
    open: false,
};
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const status_message = ['success', 'error']
var appSession: AppSession;
const BlockAction = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [config, setConfig] = React.useState<any>();
    const [data, setData] = React.useState<QLCCBlockModel>({} as QLCCBlockModel);
    const [apartmentBuildingImageList, setApartmentBuildingImageList] = React.useState<File[]>([] as File[]);
    const [apartmentBuildingVideoList, setApartmentBuildingVideoList] = React.useState<File[]>([] as File[]);
    const [reload, setReload] = React.useState(false);
    const [purposeOptions, setpurposeOptions] = React.useState<any>([]);
    const [apartmentBuildingOptions, setapartmentBuildingOptions] = React.useState<QLCCApartmentBuildingModel[]>([]);
    const [actVt, setActVt] = React.useState<IAction>(defaultAction);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    React.useEffect(() => {
        if (props.open) {
            if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
                qLCCClient
                    .qlccBlockGet({
                        ...new QLCCBlockModel(),
                        id: props.itemSelected,
                        apartment_building_id: apartment_building_id,
                        ma_cty: SessionLogin.ma_cty
                        // action_type: "GET"
                    } as QLCCBlockModel)
                    .then((res) => {
                        setData(res[0]);
                    })
                    .catch((err) => alert("Không lấy được dữ liệu"));
                setId(props.itemSelected);
            } else {
                const obj = {
                    ...new QLCCBlockModel(),
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty,
                } as QLCCBlockModel;
                setData(obj);
                setId(undefined);
                console.log('first', obj)
            };
        }
    }, [props.action, props.itemSelected, props.open]);

    async function onLoadDataSource() {
        setLoading(true);
        const res1 = qLCCClient.qlccApartmentBuildingGet({
            ... new QLCCApartmentBuildingModel(),
            ma_cty: SessionLogin.ma_cty,
            action_type: "GET"
        } as QLCCApartmentBuildingModel);

        //Mục đích sử dụng
        const res2 = qLCCClient.qLCCPurposeOfUseGet({
            ... new QLCCPurposeOfUseModel(),
            action_type: "GET",
            ma_cty: SessionLogin.ma_cty,
            apartment_building_id: apartment_building_id,
        } as QLCCPurposeOfUseModel)

        const res = await Promise.all([res1, res2]);

        setapartmentBuildingOptions(res[0]);
        setpurposeOptions(res[1]);

        setLoading(false);
    }

    React.useEffect(() => {
        onLoadDataSource();
    }, [reload]);

    React.useEffect(() => {
        setConfig({
            placeholder: 'Nội dung...',
            height: 500,
            minHeight: 500,
            uploader: {
                insertImageAsBase64URI: true,
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif', 'svg', 'webp']
            },
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPasteFromWord: 'insert_clear_html',
            commandToHotkeys: {
                removeFormat: ['ctrl+shift+m', 'cmd+shift+m'],
                insertOrderedList: ['ctrl+shift+7', 'cmd+shift+7'],
                insertUnorderedList: ['ctrl+shift+8, cmd+shift+8'],
                selectall: ['ctrl+a', 'cmd+a'],
                bold: ['ctrl+b'],
                indent: ['ctrl+]'],
                outdent: ['ctrl+['],
                italic: ['ctrl+i'],
                openSearchDialog: ['ctrl+f'],
                openReplaceDialog: ['ctrl+r'],
            },
            redo: 'ctrl+z',
            undo: 'ctrl+y,ctrl+shift+z',
            toolbarDisableStickyForMobile: false,
            toolbarStickyOffset: 100,
        })
    }, [])

    const handleChangeInput = (e: any) => {
        if (props.action !== ACTIONS.VIEW) {
            const { name, value } = e.target;
            setData({
                ...data,
                [name]: value
            } as QLCCBlockModel);
        }
    }

    const handleAddNewFile = (e: any) => {
        const { name } = e.target;
        if (name === 'apartment_building_video') {
            const file: File = {
                path: URL.createObjectURL(e.target.files[0])
            }
            const tempApartmentBuildingVideoList = apartmentBuildingVideoList.concat(file)
            setApartmentBuildingVideoList(tempApartmentBuildingVideoList);
        }
        else if (e.target.files.length > 0) {
            //=================== front side image
            const file: File = {
                path: URL.createObjectURL(e.target.files[0])
            }
            const tempApartmentBUildingImageList = apartmentBuildingImageList.concat(file)
            setApartmentBuildingImageList(tempApartmentBUildingImageList);
        }
        // reset lại file input
        e.target.value = '';
    }

    const handleChangeFile = (e: any, idx?: number) => {
        const { name } = e.target;
        if (e.target.files.length > 0) {
            const tempApartmentBuildingImageList = [...apartmentBuildingImageList]
            tempApartmentBuildingImageList[idx!].path = URL.createObjectURL(e.target.files[0]);
            setApartmentBuildingImageList(tempApartmentBuildingImageList);
            // reset lại file input
            e.target.value = '';
        }
    }


    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        setData({ ...data, [name]: new Date(value) } as QLCCBlockModel);
    }
    const handleSubmit = (e?: any) => {
        if (props.action === "ADD") {
            setLoading(true);
            qLCCClient
                .qlccBlockAction({
                    ...data,
                    start_date: apartmentBuildingOptions.find((e: any) => e.id == data.apartment_building_id)?.start_date,
                    ma_cty: SessionLogin.ma_cty,
                    action_type: "INSERT"
                } as QLCCBlockModel)
                .then(response => {
                    setLoading(false);
                    notification(status_message[response.status!], response.message)
                    props.onEditSuccess(true);
                })
                .catch(err => {
                    alert(err.message);
                })
        } else if (props.action === ACTIONS.EDIT) {
            setLoading(true);
            qLCCClient
                .qlccBlockAction({
                    ...data,
                    id: _id,
                    ma_cty: SessionLogin.ma_cty,
                    action_type: "UPDATE"
                } as QLCCBlockModel)
                .then(response => {
                    setLoading(false);
                    notification(status_message[response.status!], response.message)
                    props.onEditSuccess(true);
                })
                .catch(err => {
                    alert(err.message);
                })
        }
    }

    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                // handleSubmit();             
                handleSubmit()
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
        }
    }

    return (
        <div>
            <Dialog
                id="sysMenu"
                open={props.open}
                onClose={props.onClose}
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
                TransitionComponent={Transition}
                maxWidth="md"
            >
                {
                    actVt.open && <PurposeAction
                        open={actVt.open}
                        onClose={function (): void {
                            setActVt(defaultAction);
                        }}
                        action={actVt.type}
                        itemSelected={actVt.payload}
                        onEditSuccess={function (is_success: boolean): void {
                            setReload(pre => !pre)
                        }} >

                    </PurposeAction>
                }
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            {'Thông tin tòa nhà' +
                                " " +
                                `${props.action === ACTIONS.VIEW
                                    ? "[Chi tiết]"
                                    : props.action === ACTIONS.EDIT || _id
                                        ? "[Cập nhật]"
                                        : "[Thêm mới]"
                                }`}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} sm={4} md={4}>
                            {/*Tên dự án*/}
                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333', fontWeight: '500' }}>Tên chung cư</InputLabel>
                                <SelectBox
                                    dataSource={apartmentBuildingOptions}
                                    value={data?.apartment_building_id}
                                    onValueChange={e => {
                                        handleChangeInput({
                                            target: {
                                                name: "apartment_building_id",
                                                value: e
                                            },
                                        });
                                    }}
                                    displayExpr={'name'}
                                    valueExpr={'id'}
                                    disabled={props.action === ACTIONS.VIEW}
                                    searchEnabled
                                    searchMode="contains"
                                    searchTimeout={500}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} >
                            {/*Tên tòa nhà*/}
                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333', fontWeight: '500' }}>Tên tòa nhà</InputLabel>
                                <TextBox
                                    value={data?.name}
                                    onValueChange={e => {
                                        handleChangeInput({
                                            target: {
                                                name: "name",
                                                value: e
                                            },
                                        });
                                    }}
                                    disabled={props.action === ACTIONS.VIEW}
                                />
                            </Box>
                        </Grid>


                        {/* Diện tích */}
                        <Grid item xs={12} sm={4} md={4} >
                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333', fontWeight: '500' }}>Diện tích (m&#178;)</InputLabel>
                                <NumberBox
                                    format="#,###"
                                    value={data?.area}
                                    onValueChange={e => {
                                        handleChangeInput({
                                            target: {
                                                name: "area",
                                                value: e
                                            },
                                        });
                                    }}
                                    disabled={props.action === ACTIONS.VIEW}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} >
                            {/* Mục đích sử dụng */}
                            <Box style={{ width: "100%" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}> Mục đích sử dụng
                                    <IconButton
                                        style={{ height: "20px", width: "20px", color: "#1890ff", padding: "0 1rem" }}
                                        onClick={() =>
                                            setActVt({
                                                ...actVt,
                                                type: "ADD",
                                                payload: "",
                                                open: true,
                                            })
                                        }
                                        disabled={props.action === ACTIONS.VIEW}
                                    >
                                        <AddCircleOutlinedIcon
                                            style={{ height: "inherit", width: "inherit" }}
                                        />
                                    </IconButton>
                                </InputLabel>
                                <SelectBox
                                    dataSource={purposeOptions}
                                    value={data?.purpose_of_use_id}
                                    onValueChange={e => {
                                        handleChangeInput({
                                            target: {
                                                name: "purpose_of_use_id",
                                                value: e
                                            },
                                        });
                                    }}
                                    displayExpr={'name'}
                                    valueExpr={'id'}
                                    disabled={props.action === ACTIONS.VIEW}
                                    searchEnabled
                                    searchMode="contains"
                                    searchTimeout={500}
                                />
                            </Box>

                        </Grid>

                        {/* Số lượng tầng */}
                        <Grid item xs={12} sm={4} md={4} >
                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333', fontWeight: '500' }}>Tổng số tầng</InputLabel>
                                <NumberBox
                                    format="#,###"
                                    value={data?.floor_total}
                                    onValueChange={e => {
                                        handleChangeInput({
                                            target: {
                                                name: "floor_total",
                                                value: e
                                            },
                                        });
                                    }}
                                    disabled={props.action === ACTIONS.VIEW}
                                />
                            </Box>
                        </Grid>

                        {/* Số căn hộ */}
                        <Grid item xs={12} sm={4} md={4} >
                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333', fontWeight: '500' }}>Tổng số căn hộ</InputLabel>
                                <NumberBox
                                    format="#,###"
                                    value={data?.apartment_total}
                                    onValueChange={e => {
                                        handleChangeInput({
                                            target: {
                                                name: "apartment_total",
                                                value: e
                                            },
                                        });
                                    }}
                                    disabled={props.action === ACTIONS.VIEW}
                                />
                            </Box>
                        </Grid>

                        {/* Link google map */}
                        <Grid item xs={12} sm={4} md={4} >
                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333', fontWeight: '500' }}>Link google map</InputLabel>
                                <TextBox
                                    value={data?.loc_google_map_link}
                                    onValueChange={e => {
                                        handleChangeInput({
                                            target: {
                                                name: "loc_google_map_link",
                                                value: e
                                            },
                                        });
                                    }}
                                    disabled={props.action === ACTIONS.VIEW}
                                />
                            </Box>
                        </Grid>


                        <Grid item xs={12} sm={4} md={4} >
                            {/* Hê thống than máy*/}
                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333', fontWeight: '500' }}>Số lượng thang máy</InputLabel>
                                <TextField
                                    className="cc-input"
                                    type="number"
                                    sx={{ width: "100%" }}
                                    name="elevator_system_id"
                                    placeholder="Số lượng thang máy"
                                    onChange={handleChangeInput}
                                    value={data.elevator_system_id}
                                    inputProps={{
                                        style: {
                                            textAlign: "right",
                                        }
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} >
                            {/* Hê thống thang bộ*/}
                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333', fontWeight: '500' }}>Số lượng thang bộ</InputLabel>
                                <TextField
                                    className="cc-input"
                                    type="number"
                                    sx={{ width: "100%" }}
                                    name="staircase_system_id"
                                    placeholder="Số lượng thang bộ"
                                    onChange={handleChangeInput}
                                    value={data.staircase_system_id}
                                    inputProps={{
                                        style: {
                                            textAlign: "right",
                                        }
                                    }}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} >
                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333', fontWeight: '500' }}>Mô tả</InputLabel>
                                <JoditEditor
                                    value={data.description ? data.description : ''}
                                    onChange={(newContent) => handleChangeInput({ target: { name: 'description', value: newContent } })}
                                    config={config}
                                // disabled={props.action === ACTIONS.VIEW}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <DHSDialogAction action={props.action} menu={menu} onClickAction={handleClickAction} isDialogAction={true} />
                </DialogActions>
                <BlockUI blocking={isLoading} title={""}></BlockUI>
            </Dialog>
        </div>
    );
};

export default BlockAction;
