import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Grid,
    Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    HomeClient,
    QLCCPurposeOfUseModel,
    QLCCClient,
    RoleRightWebModel,
} from "../../../shared/service-proxies/api-shared";


import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";

import "../ApartmentBuilding.css";
import notification from "../../../../common/notification/notification";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});



const status_message = ['success', 'error']
var appSession: AppSession;
const PurposeAction: React.FC<IProps> = (props: IProps) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);


    const [data, setData] = React.useState<QLCCPurposeOfUseModel>({} as QLCCPurposeOfUseModel);
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)


    React.useEffect(() => {
        console.log(props)
        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW || props.action === 'DETAIL') {
            qLCClient
                .qLCCPurposeOfUseGet({
                    ...new QLCCPurposeOfUseModel(),
                    id: props.itemSelected,
                    action_type: "GET",
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty
                } as QLCCPurposeOfUseModel)
                .then((res) => {
                    setData(res[0]);
                })
                .catch(() => alert("Không lấy được dữ liệu"));
            setId(props.itemSelected);
        } else {
            setData(new QLCCPurposeOfUseModel());
            setId(undefined)
        };
    }, [props.action]);

    // React.useEffect(() => {
    //     setLoading(true);
    //     homeClient
    //         ?.roleRightWebGetAll({
    //             ...new RoleRightWebModel(),
    //             rolename: "CC",
    //             username: SessionLogin.userName,
    //             menuid: menu?.menuid,
    //         } as RoleRightWebModel)
    //         .then((res) => {
    //             setRoles(res);
    //             setLoading(false);
    //         });
    //     setLoading(true);
    // }, []);


    const handleSubmit = () => {
        if (props.action === ACTIONS.ADD) {
            qLCClient
                .qLCCPurposeOfUseAction({
                    ...data,
                    action_type: "INSERT",
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty
                } as QLCCPurposeOfUseModel)
                .then(response => {
                    setLoading(false);
                    notification(status_message[response.status!], response.message);
                    if (response.status === 0) {
                        props.onEditSuccess(true);
                        props.onClose();
                    }
                })
                .catch(err => {
                    alert(err.message);
                })
        } else if (props.action === ACTIONS.EDIT) {
            qLCClient
                .qLCCPurposeOfUseAction({
                    ...data,
                    id: _id,
                    action_type: "UPDATE",
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty
                } as QLCCPurposeOfUseModel)
                .then(response => {
                    setLoading(false);
                    notification(status_message[response.status!], response.message);
                    if (response.status === 0) {
                        props.onEditSuccess(true);
                        props.onClose();
                    }
                })
                .catch(err => {
                    alert(err.message);
                })
        }
    }

    const handleChangeInput = (e: any) => {
        if (props.action !== ACTIONS.VIEW) {
            const { name, value } = e.target;
            setData({
                ...data,
                [name]: value
            } as QLCCPurposeOfUseModel);
        }
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            TransitionComponent={Transition}
            maxWidth="md"
        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === ACTIONS.EDIT
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {/* Người sử dụng */}
                    <Grid item xs={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Người sử dụng</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%" }}
                                name="purpose_for"
                                placeholder="Người sử dụng"
                                onChange={handleChangeInput}
                                value={data.purpose_for}
                                disabled={props.action === ACTIONS.VIEW}

                            />
                        </Box>
                    </Grid>
                    {/* Mục đích sử dụng */}
                    <Grid item xs={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mục đích sử dụng</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%" }}
                                name="name"
                                placeholder="Mục đích sử dụng"
                                onChange={handleChangeInput}
                                value={data.name}
                                onKeyDown={(e) => {
                                    if (e.key.toLocaleUpperCase() === "ENTER") {
                                        handleSubmit();
                                    }
                                }}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {props.action !== ACTIONS.VIEW && (
                    <Button variant="contained" onClick={handleSubmit}>
                        Lưu
                    </Button>
                )}
                <Button onClick={props.onClose}>Hủy</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default PurposeAction;
