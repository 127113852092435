import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteClasses, Box, InputLabel, Stack, TextField, Theme, useTheme } from "@mui/material";
import { ClassNameMap, StyleRules, createStyles, makeStyles } from "@mui/styles";
import React from "react"
import { QLCCApartmentBuildingModel, QLCCClient } from "../../../app/shared/service-proxies/api-shared";
import BASE_URL_API from "../../../app/shared/service-proxies/config";
import { AppSession } from "../../../app/shared/app-session/app-session";
import UserHelper from "../../../helpers/user-helper/user-helper";
import { useSelector } from "react-redux";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import DHSSelectBox from "../../DHSComponents/editors/DHSSelectBox/DHSSelectBox";


interface IProps {
    id?: string,
    variant?: "standard" | "filled" | "outlined",
    clearIcon?: boolean,
    value?: string | null,
    classes?: (props?: any) => ClassNameMap<string>,
    defaultValue?: (data: QLCCApartmentBuildingModel[]) => void,
    // onValueChange?: (event: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined) => void
    onValueChange?: (e: QLCCApartmentBuildingModel) => void
}

var appSession: AppSession;
const DHSApartmentBuildingSelect: React.FC<IProps> = (props) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const isSetIdxDB = useSelector((state: IReducer) => state.AdminPageReducer?.isSetIdxDB);
    const qlccClient = new QLCCClient(appSession, BASE_URL_API)
    const [dataSource, setDataSource] = React.useState<QLCCApartmentBuildingModel[]>([] as QLCCApartmentBuildingModel[]);
    const theme = useTheme()
    const classes = props?.classes ? props?.classes(theme) : undefined;

    React.useEffect(() => {
        if (isSetIdxDB) {
            UserHelper.getUserInfo().then(user => {
                if (user?.userName) {
                    qlccClient.qlccApartmentBuildingGet({
                        ...new QLCCApartmentBuildingModel(),
                        ma_cty: SessionLogin.ma_cty,
                        user_login: user?.userName,
                        isadmin: user?.isadmin,
                        issuperadmin: user?.issupperadmin
                    } as QLCCApartmentBuildingModel).then(res => {

                        setDataSource(res);

                    }).catch((err) => {
                        console.log(err);
                    })
                }
            })
        }

    }, [isSetIdxDB])

    React.useEffect(() => {
        if (dataSource.length > 0) {
            props?.defaultValue && props.defaultValue(dataSource)

        }
    }, [dataSource])
    return <Stack
        sx={{
            // width: "15rem",
            // width: '100%',
            // maxWidth: '15rem',
            paddingBottom: "0.5rem"
        }}
        direction="row">
        {/* <InputLabel  className='cc-label text-white'>Dự án</InputLabel> */}
        <DHSSelectBox
            dataSource={dataSource}
            showClearButton={props.clearIcon ?? false}
            // showDropDownButton={true}
            searchEnabled={dataSource.length > 5 ? true : false}
            searchMode="contains"
            displayExpr="name"
            stylingMode="outlined"
            value={props.value}
            valueExpr="id"
            onSelectionChanged={e => {
                const data = e.selectedItem;
                if(props.onValueChange){
                    props.onValueChange(data);
                }
            }}
            buttons={[
                {
                    location: 'before',
                    name:"Chung cư",
                    options: {
                        icon: 'bi bi-building',
                        stylingMode: "text"
                    }
                }
            ]}
        />
        {/* <Autocomplete
            id="gender-select"
            sx={{ width: "100%" }}
            classes={classes}
            options={dataSource}
            autoHighlight
            clearIcon={props?.clearIcon ?? false}
            disablePortal
            getOptionLabel={(option: any) => option?.name}
            value={(props?.value && dataSource.length)
                ? dataSource.find(
                    (item: any) => item.id === props.value)
                : null
            }
            onChange={props?.onValueChange}

            renderInput={(params) => (
                <TextField
                    {...params}
                    className="cc-input"
                    // label="Dự án"
                    variant={props.variant}
                    placeholder='Chọn dự án'
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                />
            )}
        /> */}
    </Stack>
}

export default DHSApartmentBuildingSelect;