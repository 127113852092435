import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Grid,
    DialogTitle,
    TableCell,
    tableCellClasses,
    TableContainer,
    Paper,
    TableHead,
    Table,
    TableRow,
    TableBody,
    CircularProgress,
    Select,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Badge,
    BadgeProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    CommonClient,
    HomeClient,
    QLCCApartmentBuildingModel,
    QLCCApartmentModel,
    QLCCBlockModel,
    QLCCClient,
    QLCCDomesticWaterCycleModel,
    QLCCDomesticWaterModel,
    QLCCFloorModel,
    RoleRightWebModel,
    SoVch05Client,
} from "../../../shared/service-proxies/api-shared";



import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import CTXDateTime from "../../../../components/CTX/DateTimePicker";
import '../ApartmentBuilding.css';
import { SoVch05M } from "../../../shared/service-proxies/api-shared";
import { useHistory } from "react-router-dom";
import { Checkbox, Tooltip, notification } from "antd";
import styled from "styled-components";
import { getRoles } from "../../../../redux/actions/RoleActions";
import { Notification } from "../../../../components/layouts";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import HomeIcon from '@mui/icons-material/Home';
import ServiceInvoiceDetailAction from "./ServiceInvoiceDetailAction";
import CTXDate from "../../../../components/CTX/DatePicker";
import { setApartmentBuilding } from "../../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction";
import { CALL_API_LOAD_APARTMENT_BUILDING_LIST, CALL_API_LOAD_ID_APARTMENT, CALL_API_LOAD_INFO_DASHBOARD_APARTMENT_BUILDING } from "../../apartment-building/apartment-building-list";
import moment from "moment";
import DHSLoadPanel from "../../../../components/DHSComponents/dialogs-and-notifications/DHSLoadPanel/DHSLoadPanel";
import { useWindowSize } from "../../../../common/useWindowWide";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';


interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    qLCCSoVch05MLogs?: any;
    cycleName?: any;
    onEditSuccess: () => void;
    actionsCode: 'EXPORT' | 'CREATE' | string;
    setReturn: (apartment_id: string, ngay_ct: Date, cycle_id: string, year: number, month: number) => void;
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


interface IAction {
    open: boolean;
    type: string | undefined;
    payload: string | undefined;
    item: any;
    domesticWaterCyclePre: any;
    qLCCSoVch05MLogs: any;
    cycleName: any;
}

const menuRoleName = "CC";
const convert = ["success", "error", "warning", "info"];

var appSession: AppSession;
const ServiceInvoiceClientCreateBill: React.FC<IProps> = (props: IProps) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const history = useHistory();
    const dispatch = useDispatch();
    // const [_id, setId] = React.useState<string>("")
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const [qlccFloorList, setQLCCFloorList] = React.useState<QLCCFloorModel[]>([] as QLCCFloorModel[]);
    const [qlccBlockList, setQLCCBlockList] = React.useState<QLCCBlockModel[]>([] as QLCCBlockModel[]);
    const [apartmentBuildingOptions, setAPartmentBuildingOptions] = React.useState<QLCCApartmentBuildingModel[]>([] as QLCCApartmentBuildingModel[]);
    const [qlccDomesWaterCycle, setDomesticWaterCycle] = React.useState<QLCCDomesticWaterCycleModel>({} as QLCCDomesticWaterCycleModel);
    const [domesWaterCycleList, setDomesticWaterCycleList] = React.useState<QLCCDomesticWaterCycleModel[]>([] as QLCCDomesticWaterCycleModel[]);
    const [apartmentList, setApartmentList] = React.useState<QLCCApartmentModel[]>([] as QLCCApartmentModel[]);
    const [apartmentListOfApartmentBuilding, setApartmentListOfApartmentBuilding] = React.useState<QLCCApartmentModel[]>([] as QLCCApartmentModel[]);
    const [domesticWaterDefault, setDomesticWaterDefault] = React.useState<string>("");
    const [indexSelectCycle, setIndexSelectCycle] = React.useState<number>(0);
    const [blockID_, setBlockID_] = React.useState<string>("");
    const [reload, setReload] = React.useState(false);
    /* checkbox */
    const [checkedList, setCheckedList] = React.useState<string[]>([]);
    const [checkedListFloor, setCheckedListFloor] = React.useState<string[]>([]);
    /* open dialog phát hành phiếu */
    const [isDialogExport, setIsDialogExport] = React.useState<boolean>(false);
    const [notificationAnt, contextNotification] = notification.useNotification();
    /* load hiệu ứng */
    const [onloadBlock, setOnloadBlock] = React.useState<boolean>(false);
    const [refresh, setRefresh] = React.useState(false);
    const [action, setAction] = React.useState<IAction>({
        open: false,
        type: "",
        payload: "",
        item: {},
        domesticWaterCyclePre: {},
        qLCCSoVch05MLogs: [],
        cycleName: '',
    });
    const [ngay_ct, setNgay_ct] = React.useState<Date>(new Date());
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
    const [infoDashboardApartmentBuilding, setInfoDashboardApartmentBuilding] = React.useState<number>(0);

    // Tổng số lượng căn hộ
    async function onLoadInfoDashBoard(params?: string) {
        const res = await CALL_API_LOAD_INFO_DASHBOARD_APARTMENT_BUILDING(params ? params : apartment_building_id);
        setInfoDashboardApartmentBuilding(res.aparment);
    }

    const styleTable = {
        "& .MuiDialog-container": {
            "& .MuiPaper-root": {
                // width: "100%",
                // maxWidth: "50vw",  // Set your width here
                minHeight: '60vh',
            },
        },
    }

    // styled

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#1D79DB',
            color: 'white',
            minWidth: '150px',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: 3,
            top: 6,
            padding: '0 4px',
        },
    }));

    /* load - danh sách tòa nhà, danh sách các chu kì */
    React.useEffect(() => {
        if (props.open) {
            setLoading(true);
            CALL_API_LOAD_APARTMENT_BUILDING_LIST().then(res => {
                setAPartmentBuildingOptions(res);
            })
            onloadDataWhenChangeApartmentBuilding(apartment_building_id)
            setLoading(false);
            onLoadInfoDashBoard();
        }
    }, [props.open])

    // React.useEffect(() => {
    //     if (qlccDomesWaterCycle.month && qlccDomesWaterCycle.year && apartment_building_id !== "" && apartmentBuildingOptions.length > 0) {
    //         const apartmentBuilding = apartmentBuildingOptions.find(item => item.id === apartment_building_id);
    //         const date = apartmentBuilding?.posted_date_invoice ? apartmentBuilding?.posted_date_invoice.getDate() : new Date().getDate();
    //         const month = qlccDomesWaterCycle.month - 1;
    //         const year = qlccDomesWaterCycle.year;
    //         // setNgay_ct(new Date(Date.UTC(year, month, date, 0, 0, 0)));
    //         setNgay_ct(moment().year(year).month(month).date(date).startOf('day').toDate());
    //     }
    // }, [qlccDomesWaterCycle,apartmentBuildingOptions])


    const onloadBlock_ = (apartmentBuildingID: string) => {
        qLCCClient
            .qlccBlockGet({
                ... new QLCCBlockModel(),
                apartment_building_id: apartmentBuildingID,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCBlockModel)
            .then(res => {
                setQLCCBlockList(res);
                setBlockID_(res[0].id || "");
                onloadFloor(res[0].id || "");
            });
    }

    const onloadCycle = (apartmentBuildingID: string) => {
        qLCCClient
            .qLCCDomesticWaterCycleGet({
                ... new QLCCDomesticWaterCycleModel(),
                apartment_building_id: apartmentBuildingID,
                // action_type: 'ENTER-WATER',
                ma_cty: SessionLogin.ma_cty,
            } as QLCCDomesticWaterCycleModel)
            .then(res => {
                const date = new Date();
                /* lấy của tháng trước đó làm mặc định */
                // const temp = res.find(item => item.month == (date.getMonth()) && item.year == date.getFullYear());

                const temp = res.find(item => moment(item.to_date).month() === moment().month())
                // const temp = res[0];
                if (temp) {
                    setDomesticWaterCycle(temp);
                    setDomesticWaterDefault(temp.id || "");
                    setIndexSelectCycle(0);
                } else {
                    setDomesticWaterDefault(res[0]?.id || "");
                    setDomesticWaterCycle(res[0]);
                    setIndexSelectCycle(0);
                }
                setDomesticWaterCycleList(res);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            })
    }

    const onloadCheckList = (data: QLCCDomesticWaterModel[]) => {
        const tempCheckedList = data.filter(item => item.enter_status == true && item.pay_status == false);
        const lst: string[] = tempCheckedList.map(item => item.apartment_id || "");
        const lstFloor: string[] = tempCheckedList.map(item => item.floor_id || "");
        if (
            lst.length > 0
            && lstFloor.length > 0
            && lst
            && lstFloor
        ) {

            /* lộc những id trùng nhau */
            let convertSet = new Set(lstFloor);
            let convertSetList = new Set(lst);

            setCheckedList(Array.from(convertSetList));
            setCheckedListFloor(Array.from(convertSet));
        }
    }

    /* load - danh sách căn hộ*/
    const onloadApartments = (blockID: string) => {
        qLCCClient
            .qLCCApartmentsGet({
                ... new QLCCApartmentModel(),
                block_id: blockID,
                action_type: 'PHAT-HANH-HOA-DON',
                month: qlccDomesWaterCycle.month,
                year: qlccDomesWaterCycle.year,
                ma_cty: SessionLogin.ma_cty,
                apartment_building_id: apartment_building_id,
            } as QLCCApartmentModel)
            .then(response => {
                setApartmentList(response);
            })
            .catch((err) => {
                setLoading(false);
            });
    }

    const onloadApartmentsOfApartmentBuilding = (params: string) => {
        qLCCClient
            .qLCCApartmentsGet({
                ... new QLCCApartmentModel(),
                action_type: 'PHAT-HANH-HOA-DON',
                month: qlccDomesWaterCycle.month,
                year: qlccDomesWaterCycle.year,
                ma_cty: SessionLogin.ma_cty,
                apartment_building_id: params,
            } as QLCCApartmentModel)
            .then(response => {
                setApartmentListOfApartmentBuilding(response);
            })
            .catch((err) => {
                setLoading(false);
            });
    }

    /* load - danh sách tầng */
    const onloadFloor = (blockID: string, prapartmentBuilding?: string) => {
        qLCCClient
            .qLCCFloorGet({
                ...new QLCCFloorModel(),
                block_id: blockID,
                ma_cty: SessionLogin.ma_cty,
                apartment_building_id: prapartmentBuilding ? prapartmentBuilding : apartment_building_id,
            } as QLCCFloorModel)
            .then((res) => {
                let arrTemp = res;
                arrTemp.sort((a: any, b: any) => a.number_floor - b.number_floor);
                setQLCCFloorList(arrTemp);
                // setLoading(false);
                setTimeout(() => {
                    setOnloadBlock(false);
                }, 500)
            }).catch((err) => {
                // setLoading(false);
            })
    }

    React.useEffect(() => {
        if (apartmentList && apartmentList.length > 0) {
            onloadCheckList(apartmentList);
        } else {
            setCheckedList([]);
        }
    }, [apartmentList])

    React.useEffect(() => {
        onload();
    }, [qlccFloorList, reload])

    const onload = () => {
        if (qlccFloorList.length > 0) {
            if (blockID_ && blockID_ !== "") {
                onloadApartments(blockID_);
            }
            else {
                var blockID = qlccFloorList[0].block_id;
                if (blockID) onloadApartments(blockID);
            }
            onloadApartmentsOfApartmentBuilding(apartment_building_id)
        } else {
            setApartmentList([]);
        }
    }

    const hanldeClickBlock = (e: any) => {
        setOnloadBlock(true);
        const blockID = e.target.id;
        setBlockID_(blockID);
        onloadFloor(blockID);
        if (onloadBlock) setOnloadBlock(false);
    }

    const hanldeClick = (e: any) => {
        if (e.qLCCSoVch05MLogs?.length > 0) {
            setAction({
                ...action,
                type: "EDITEXPORT",
                open: true,
                payload: e.id,
                item: e,
                domesticWaterCyclePre: domesWaterCycleList[indexSelectCycle + 1],
                qLCCSoVch05MLogs: e.qLCCSoVch05MLogs
            });
        }
    }


    const handleChangeChecked = (e: any) => {
        const { value, checked } = e.target;

        if (checked) {
            setCheckedList([...checkedList, value]); //*1 해줘야 number로 들어가서 type 호환이 됨.
        } else {
            setCheckedList(checkedList.filter((item) => item != value));
        }
    }

    const selectItem = (id: string = '') => {
        const collection: any[] = [];
        const itemsChecks: boolean = checkedListFloor.includes(id);

        if (!itemsChecks) {
            for (const item of apartmentList.filter(apartment => apartment.floor_id == id && apartment.status_id !== '0')) {
                collection.push(item.id);
            }
            setCheckedList([...checkedList, ...collection]);
            setCheckedListFloor([...checkedListFloor, id])
        }
        else {
            let temp: any[] = checkedList;
            for (const item of apartmentList.filter(apartment => apartment.floor_id == id)) {
                if (checkedList.includes(item.id || "")) {
                    temp = ((temp.filter(apartmentTemp => apartmentTemp != item.id)));
                }
            }
            setCheckedList([...temp]);
            setCheckedListFloor(checkedListFloor.filter(listFloorId => listFloorId != id));
        }
    };

    const handleExportList = () => {
        switch (action.payload?.toLocaleUpperCase()) {
            case "CREATE":
                // debugger
                // if (
                //     moment(ngay_ct).toDate() > moment().year(qlccDomesWaterCycle.year!).month(qlccDomesWaterCycle.month! - 1).endOf('month').toDate()
                //     || moment(ngay_ct).toDate() < moment().year(qlccDomesWaterCycle.year!).month(qlccDomesWaterCycle.month! - 1).startOf('month').toDate()
                // ) {
                //     Notification('info', `Ngày chứng từ phải trong tháng  ${qlccDomesWaterCycle.title}`);
                //     break;
                // }
                setLoading(true);
                Notification(convert[3], "Hóa đơn đang được tạo, vui lòng chờ!");
                qLCCClient.qlccGenerateSOVch05Ins({
                    ... new SoVch05M(),
                    ma_ct: 'SO5',
                    ma_cty: SessionLogin.ma_cty,
                    apartment_id: checkedList.join(';'),
                    cycle_id: qlccDomesWaterCycle.id,
                    month: qlccDomesWaterCycle.month,
                    year: qlccDomesWaterCycle.year,
                    is_send_notifi: false,
                    action_type: 'GENERATESOVCH05_2',
                    ngay_ct: new Date(Date.UTC(ngay_ct.getFullYear(), ngay_ct.getMonth(), ngay_ct.getDate(), 0, 0, 0)),
                    apartment_building_id: apartment_building_id,
                } as SoVch05M)
                    .then((res) => {
                        if (res) {
                            if (res.status === 2 && checkedList.length === 1) {
                                if (window.confirm("Hóa đơn đã được tạo trước đó, xác nhận tạo thêm hóa đơn mới?")) {
                                    // console.log(checkedList, '  ', qlccDomesWaterCycle.id)
                                    props.setReturn(checkedList[0], ngay_ct, qlccDomesWaterCycle.id ?? '', qlccDomesWaterCycle.year ?? 0, qlccDomesWaterCycle.month ?? 0);
                                    props.onClose();
                                }
                            } else {
                                openNotification(res.message, 4.5, res.status);
                            }
                        }
                        onload();
                        setLoading(false);
                        props.onEditSuccess();
                        setIsDialogExport(false);
                        setCheckedList([]);
                    }).catch((err) => {
                        if (err.status) {
                            Notification('Error', err.message)
                        }
                        setLoading(false);
                    })

                break;
            case "EXPORT":
                Notification(convert[3], "Thông báo hóa đơn đang được gửi, vui lòng chờ!")
                qLCCClient.qlccGenerateSOVch05Ins({
                    ... new SoVch05M(),
                    ma_ct: 'SO5',
                    ma_cty: SessionLogin.ma_cty,
                    apartment_id: checkedList.join(';'),
                    cycle_id: qlccDomesWaterCycle.id,
                    month: qlccDomesWaterCycle.month,
                    year: qlccDomesWaterCycle.year,
                    is_send_notifi: true,
                    action_type: 'GENERATESOVCH05_2',
                    ngay_ct: new Date(Date.UTC(ngay_ct.getFullYear(), ngay_ct.getMonth(), ngay_ct.getDate(), 0, 0, 0)),
                    apartment_building_id: apartment_building_id,
                } as SoVch05M)
                    .then((res) => {
                        if (res) {
                            // Notification(convert[0], res.message)
                            openNotification(res.message, 4.5, res.status);
                        }
                        onload();
                        setLoading(false);
                        setIsDialogExport(false);
                        setCheckedList([]);
                    }).catch((err) => {
                        if (err.status) {
                            Notification('Error', err.message)
                        }
                        setLoading(false);
                    })
        }
    }




    const handleChangeIntput = (e: any) => {
        const { name, value } = e.target;
        if (name.toUpperCase() === "DOMESTICWATERCYCLE") {
            // const newValue = domesWaterCycleList.filter(item => item.id == value);
            const newValue = domesWaterCycleList.find(item => item.id == value);
            if (newValue) {
                setIndexSelectCycle(domesWaterCycleList.findIndex(item => item.id == value));
                setDomesticWaterCycle(newValue);
                if (blockID_ && blockID_ != "") {
                    onloadFloor(blockID_);
                }
            }
        } else if (name.toUpperCase() === "APARTMENTBUILDING") {
            const lengthCheck = checkedList.length;
            if (lengthCheck > 0) {
                dispatch(setApartmentBuilding({
                    id: value
                } as QLCCApartmentBuildingModel))
                setCheckedListFloor([]);
                setCheckedList([]);
                // onloadBlock_(value);
                // onloadCycle(value);
                onloadDataWhenChangeApartmentBuilding(value);
            } else {
                dispatch(setApartmentBuilding({
                    id: value
                } as QLCCApartmentBuildingModel))
                // onloadBlock_(value);
                // onloadCycle(value);
                onloadDataWhenChangeApartmentBuilding(value);
            }
            onLoadInfoDashBoard(value);
            onloadApartmentsOfApartmentBuilding(value)
        }
    }

    const onloadDataWhenChangeApartmentBuilding = async (value: string) => {
        setLoading(true)
        const res1 = qLCCClient.qlccBlockGet({
            ... new QLCCBlockModel(),
            apartment_building_id: value,
            ma_cty: SessionLogin.ma_cty,
        } as QLCCBlockModel);
        const res2 = qLCCClient.qLCCDomesticWaterCycleGet({
            ... new QLCCDomesticWaterCycleModel(),
            apartment_building_id: value,
            // action_type: 'ENTER-WATER',
            ma_cty: SessionLogin.ma_cty,
        } as QLCCDomesticWaterCycleModel)

        const resAll = await Promise.all([res1, res2]);

        const data1 = resAll[0];
        const data2 = resAll[1];

        // data1
        setQLCCBlockList(data1);
        setBlockID_(data1[0].id || "");
        onloadFloor(data1[0].id || "", value);

        // data2
        const date = new Date();
        /* lấy của tháng trước đó làm mặc định */
        const temp = data2.find(item => item.month == (date.getMonth()) && item.year == date.getFullYear()); //  1/12/2023 sửa theo yêu cầu anh Hiệp - tránh nhầm lẫn cho người tạo
        // const temp = data2.find(item => moment(item.to_date).month() === moment().month()); // 20/11/2023 sửa theo yêu cầu của Chị Dung
        // debugger
        // const temp = data2[0];
        if (temp) {
            setDomesticWaterCycle(temp);
            setDomesticWaterDefault(temp.id || "");
            setIndexSelectCycle(0);
        } else {
            setDomesticWaterDefault(data2[0]?.id || "");
            setDomesticWaterCycle(data2[0]);
            setIndexSelectCycle(0);
        }
        setDomesticWaterCycleList(data2);
        setLoading(false)

    }

    const clearCheckedList = () => {
        onloadBlock_(apartment_building_id);
        onloadCycle(apartment_building_id);

    }

    const styleStatus: any = (qlccDomesticWaterList: any, id_: string) => {
        const complete: number = qlccDomesticWaterList.filter((element: any) => element.floor_id == id_ && element.enter_status == true).length;
        const taskComplete: number = qlccDomesticWaterList.filter((element: any) => element.floor_id == id_).length;
        if (taskComplete == 0) {
            return {
                backgroundColor: '#9BA4B4'
            }
        } else if (complete == 0) {
            return {
                backgroundColor: '#dc3545'
            }
        }
        else if (complete == taskComplete) {
            return {
                backgroundColor: '#198754'

            }
        } else if (taskComplete > complete) {
            return {
                backgroundColor: '#F7C04A'
            }
        }
        return {
            backgroundColor: '#ffc107'
        }
    }

    const checkCycle = () => {
        if (
            domesticWaterDefault
            && qlccDomesWaterCycle
            && qlccDomesWaterCycle.month
            && qlccDomesWaterCycle.year)
            if (
                qlccDomesWaterCycle.month == (new Date()).getMonth() + 1
                && qlccDomesWaterCycle.year == (new Date()).getFullYear()
            )
                return true;
            else {
                return domesticWaterDefault == qlccDomesWaterCycle.id
            }
    }

    const selectBlock = (floor_id: string) => {
        return `${blockID_ == floor_id ? "selected" : "unselected"} btn-hover block-btn`;
    }

    const handleSubmit = (data: any) => {
        setLoading(true)
        setAction({
            ...action,
            open: false,
            type: "",
            payload: "",
            item: {}
        });
    };

    const handlerefresh = async () => {
        setRefresh(true);
        onload();
        setTimeout(() => {
            openNotification('Đã làm mới lại danh sách')
            setRefresh(false);
        }, 500);
    }

    const openNotification = (text: string = "Thông báo", duration: number = 4.5, status: Number = 0) => {
        if (status === 0) {
            notificationAnt.success({
                message: `Thông báo`,
                description:
                    `${text}`,
                placement: 'top',
                duration
            });
        } else if (status === 1) {
            notificationAnt.error({
                message: `Thông báo`,
                description:
                    `${text}`,
                placement: 'top',
                duration
            });
        }
    };

    const handleSelectAll = async () => {
        if (checkedList.length > 0) {
            setCheckedList([]);
            setCheckedListFloor([]);
        } else {
            // const collection: any[] = [];
            // const collectionFloor: any[] = [];

            // qlccFloorList.forEach((item) => collectionFloor.push(item.id))

            // for (const item of apartmentList.filter(item => item.status_id !== '0')) {
            //     collection.push(item.id);
            // }

            const res = await CALL_API_LOAD_ID_APARTMENT(apartment_building_id)

            const lstApartment: string[] = [];
            const lstFloor: string[] = []

            if (res.length > 0) {
                res.map(item => {
                    if (item.floor_id && !lstFloor.includes(item.floor_id)) {
                        lstFloor.push(item.floor_id);
                    }

                    if (item.id) {
                        lstApartment.push(item.id);
                    }
                })
            }

            // setCheckedList([...collection]);
            // setCheckedListFloor([...collectionFloor]);
            setCheckedList(lstApartment);
            setCheckedListFloor(lstFloor);
        }
    }


    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        setNgay_ct(new Date(value));
    };


    const sizeWindow = useWindowSize();
    const [isFull, setIsFull] = React.useState(false);

    return (
        <>
            {/* Xem danh sách hóa đơn của căn hộ từ trước đến giờ */}
            {(
                React.useMemo(() => {
                    return (
                        <ServiceInvoiceDetailAction
                            itemSelected={action.payload}
                            onClose={function (): void {
                                setAction({
                                    ...action,
                                    open: false,
                                    payload: "",
                                })
                            }}
                            open={action.open}
                            qLCCSoVch05MLogs={action.qLCCSoVch05MLogs.sort((a: any, b: any) => b.date_add - a.date_add)}
                            cycleName={domesWaterCycleList[indexSelectCycle]?.month}
                            dataMaster={action.item}
                        />
                    );
                }, [action.open, action.payload])
            )}
            <Dialog
                // id="sysMenu5"
                open={props.open}
                onClose={props.onClose}
                // sx={{
                //     display: "flex",
                //     justifyContent: "center",
                // }}
                fullWidth
                TransitionComponent={Transition}
                maxWidth='lg'
                fullScreen={sizeWindow.width < 600 || isFull}
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            {
                                props.actionsCode && props.actionsCode === "CREATE" ? "Tạo hóa đơn" : "Gửi thông báo hóa đơn"
                            }
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setIsFull(pre => !pre)}
                            aria-label="close"
                        >
                            {
                                sizeWindow.width < 600 || isFull ? <FullscreenExitIcon />
                                    : <FullscreenIcon />
                            }
                        </IconButton>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent >
                    <div>
                        {contextNotification}
                        <Dialog
                            open={isDialogExport}
                            onClose={() => setIsDialogExport(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            sx={styleTable}
                            fullScreen={sizeWindow.width < 600}
                            maxWidth='xl'
                            fullWidth
                        >
                            <DialogTitle id="alert-dialog-title" className='d-flex justify-content-between align-items-center'>
                                <Typography className="text-uppercase fw-bold"><b>Danh sách hóa đơn phát hành</b></Typography>
                                <IconButton color="primary" onClick={() => setIsDialogExport(false)} >
                                    <CloseIcon fontSize='medium' />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
                                    <Table
                                        stickyHeader
                                        sx={{ width: '100%' }}
                                        aria-label="Danh sách căn hộ chuẩn bị phát hành hóa đơn"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="center" sx={{ minWidth: '25px' }}>Chọn</StyledTableCell>
                                                <StyledTableCell align="center" sx={{ minWidth: '50px' }}>STT</StyledTableCell>
                                                <StyledTableCell align="center" sx={{ minWidth: '150px', width: 'auto' }}>Tên căn hộ</StyledTableCell>
                                                <StyledTableCell align="center" sx={{ minWidth: '200px', width: 'auto' }}>Chủ hộ</StyledTableCell>
                                                {/* <StyledTableCell align="center" sx={{ minWidth: '100px', width: 'auto' }}>Số lần phát hành</StyledTableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                checkedList.length > 0
                                                && checkedList.map((id, index) => (
                                                    <TableRow
                                                        key={id}
                                                    >
                                                        <TableCell align="center">
                                                            <Checkbox
                                                                value={id}
                                                                onClick={handleChangeChecked}
                                                                checked={checkedList.includes(id || "")}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center">{index + 1}</TableCell>
                                                        <TableCell align="left">{apartmentListOfApartmentBuilding.find(aparment => aparment.id === id)?.name}</TableCell>
                                                        <TableCell align="left">{apartmentListOfApartmentBuilding.find(aparment => aparment.id === id)?.owner_resident_name}</TableCell>
                                                        {/* <TableCell align="right">{apartmentList.find(aparment => aparment.id === id)?.qLCCSoVch05MLogs?.length}</TableCell> */}
                                                    </TableRow>
                                                ))
                                            }
                                            {
                                                checkedList.length == 0
                                                &&
                                                <TableRow>
                                                    <TableCell align="center" colSpan={5}>Danh sách trống</TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Typography align='right'>Tổng số: {checkedList.length} căn hộ được chọn</Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setIsDialogExport(false)} variant="outlined">Đóng</Button>
                                {
                                    checkedList.length > 0
                                    && (
                                        <Button
                                            autoFocus
                                            variant='contained'
                                            onClick={handleExportList}
                                        >
                                            {
                                                action.payload?.toLocaleUpperCase() === "CREATE"
                                                    ? (
                                                        `Tạo hóa đơn`
                                                    )
                                                    : (
                                                        `Gửi thông báo`
                                                    )
                                            }
                                        </Button>
                                    )
                                }
                            </DialogActions>
                        </Dialog>
                        <Grid container spacing={2}
                            className="d-flex title-color"
                            style={{
                                color: "rgb(0 78 255 / 88%)",
                                fontSize: 22,
                                fontWeight: 550,
                            }}
                        >
                            <Grid item xs={12} sm={12} md={4} lg={5} sx={{ marginLeft: 5 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <Button
                                            className='w-100'
                                            variant={"contained"}
                                            color={'primary'}
                                            onClick={handleSelectAll}
                                            sx={{ marginRight: '1rem' }}
                                        >
                                            {
                                                checkedList.length > 0
                                                    ? (
                                                        ` Bỏ chọn`
                                                    )
                                                    : (
                                                        `Chọn tất cả`
                                                    )
                                            }
                                            {
                                                checkedList
                                                && checkedList.length > 0
                                                && (
                                                    <span style={{ marginLeft: 7 }}>
                                                        {

                                                            `(Đã chọn ${checkedList.length})`
                                                        }
                                                    </span>
                                                )
                                            }
                                        </Button>

                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button
                                            className='w-100'
                                            sx={{ marginRight: '1rem' }}
                                            variant="contained"
                                            color={"warning"}
                                            onClick={() => {
                                                setIsDialogExport(true);
                                                setAction({
                                                    ...action,
                                                    payload: props.actionsCode ? props.actionsCode : "CREATE"
                                                })
                                            }}
                                        >
                                            {
                                                props.actionsCode && props.actionsCode === "CREATE" ? "Tạo hóa đơn" : "Gửi thông báo"
                                            }
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CTXDate
                                            className='w-100'
                                            label="Ngày phát hành"
                                            name="ngay_ct"
                                            value={ngay_ct}
                                            onChange={handleChangeDate}
                                        // minDate={moment().year(qlccDomesWaterCycle?.year!).month(qlccDomesWaterCycle?.month! - 1).startOf('month').utc(true).toDate()}
                                        // minDate={moment(qlccDomesWaterCycle.to_date).startOf('month').toDate()}
                                        // maxDate={moment(qlccDomesWaterCycle.to_date).endOf('month').toDate()}
                                        // maxDate={new Date(Date.UTC(qlccDomesWaterCycle.year!, qlccDomesWaterCycle.month! + 1, -1, 23, 59, 59))}
                                        // maxDate={moment().year(qlccDomesWaterCycle?.year!).month(qlccDomesWaterCycle?.month! - 1).endOf('month').utc(true).toDate()}
                                        // maxDate={moment().year(qlccDomesWaterCycle?.year!).month(qlccDomesWaterCycle?.month! - 1).endOf('month').utc(true).toDate()}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid container spacing={1}
                        >
                            <Grid item xs={12} sm={4.5} md={3} lg={3}>
                                <Box
                                    className="d-flex justify-content-center"
                                >
                                    <Box
                                        sx={{
                                            width: '80%'
                                            , marginTop: '1.5rem'
                                        }}
                                    >

                                        <InputLabel htmlFor="input-with-domestic-water-cycle">
                                            Bước 1. Chọn dự án
                                        </InputLabel>
                                        <Select
                                            className="text-center"
                                            sx={{
                                                width: '98%',
                                            }}
                                            size='small'
                                            id="input-with-domestic-water-cycle"
                                            name="APARTMENTBUILDING"
                                            onChange={handleChangeIntput}
                                            value={apartment_building_id || ""}
                                        >
                                            {
                                                apartmentBuildingOptions &&
                                                apartmentBuildingOptions.map((item, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={item.id}
                                                    >
                                                        <span style={{ fontSize: 14 }}>
                                                            {item.name}
                                                        </span>
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                        <InputLabel htmlFor="input-with-domestic-water-cycle">
                                            Bước 2. Chọn kỳ nước
                                        </InputLabel>
                                        <Select
                                            className="text-center"
                                            sx={{
                                                width: '98%',
                                            }}
                                            size='small'
                                            id="input-with-domestic-water-cycle"
                                            name="DOMESTICWATERCYCLE"
                                            onChange={handleChangeIntput}
                                            value={qlccDomesWaterCycle?.id || ""}
                                        >
                                            {
                                                domesWaterCycleList &&
                                                domesWaterCycleList.map((item, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={item.id}
                                                    >
                                                        <span style={{ fontSize: 14 }}>
                                                            {item.title}
                                                        </span>
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </Box>
                                </Box>
                                <Box
                                    className="d-flex justify-content-center"
                                >
                                    <Box
                                        sx={{
                                            width: '80%'
                                        }}
                                    >
                                        <InputLabel htmlFor="input-with-domestic-water-block">
                                            Bước 3. Chọn tòa nhà ({qlccBlockList && (`${qlccBlockList.length}`)})
                                        </InputLabel>
                                        <Box
                                            id="input-with-domestic-water-block"
                                            className='hidden-scrollbar width-scrollbar-hoa-don'
                                        >

                                            {
                                                qlccBlockList &&
                                                qlccBlockList.map((floor) => (
                                                    <Button
                                                        className={selectBlock(floor.id || "")}
                                                        key={floor.id}
                                                        id={floor.id}
                                                        onClick={hanldeClickBlock}
                                                    >
                                                        {(onloadBlock && blockID_ == floor.id) ? <CircularProgress size={18} color='inherit' /> : floor.name}
                                                    </Button>
                                                ))
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={7.5} md={9} lg={9}>

                                <InputLabel
                                    htmlFor="input-with-domestic-water-block"
                                    className='label-input-domestic-water d-flex justify-content-between align-items-center'
                                >
                                    <Grid container
                                        sx={{
                                            marginRight: '3%',
                                        }}
                                    >
                                        <Grid
                                            item xs={12} sm={5} md={8}
                                            className="d-flex align-items-center"
                                        >
                                            Bước 4. Số tầng {blockID_ && qlccFloorList && (`(${qlccFloorList.length})`)}
                                        </Grid>
                                        <Grid xs={12} sm={7} md={4}
                                            sx={{
                                                marginBottom: '10px',
                                            }}
                                        >
                                            <p className='margin-0 text-center text-uppercase text-danger'>
                                                {/* <b>{qlccBlockList && qlccBlockList.find(item => item.id == blockID_)?.name}</b> */}
                                                <b>{apartmentBuildingOptions && apartmentBuildingOptions.find(item => item.id === apartment_building_id)?.name}</b>
                                            </p>
                                            <p className='margin-0'>
                                                <span className='width-50percent display-inline-block'>
                                                    <b>Tổng số căn hộ</b>
                                                </span>
                                                <span className='width-40percent'>
                                                    {/* {apartmentList && apartmentList?.length} */}
                                                    {infoDashboardApartmentBuilding}
                                                </span>
                                            </p>
                                            <p className='margin-0'>
                                                <span className='width-50percent display-inline-block'>
                                                    {/* <b>Số lượng phiếu đã phát hành</b> */}
                                                </span>
                                                <span className='width-40percent'>
                                                    {/* {"chưa tính"} */}
                                                </span>
                                            </p>
                                        </Grid>
                                    </Grid>

                                </InputLabel>
                                <Box
                                    className='hidden-scrollbar width-scrollbar-floor'
                                >
                                    {
                                        qlccFloorList &&
                                        qlccFloorList.map(floor => (
                                            <Accordion
                                                key={floor.id}
                                                sx={{
                                                    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;',
                                                }}
                                                style={{ backgroundColor: '#198754' }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Grid container spacing={2} sx={{ color: 'white' }}>
                                                        <Grid item xs={4} sm={2} md={2}>
                                                            <span className="fontWeight_500" style={{ fontSize: 14 }}>
                                                                {floor.name}
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={2}>
                                                            <OtherHousesIcon
                                                                sx={{
                                                                    fontSize: "24px",
                                                                    marginRight: '15px',
                                                                    float: 'left',
                                                                }}
                                                            />
                                                            {
                                                                apartmentList.filter(element => element.floor_id == floor.id).length
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    sx={{
                                                        backgroundColor: "#F0F5F9"
                                                    }}
                                                >
                                                    <Typography>
                                                        <Box>
                                                            {
                                                                /* chọn/ bỏ chọn tất cả */
                                                                apartmentList
                                                                && apartmentList.filter(element => element.floor_id == floor.id).length > 0
                                                                && (
                                                                    <div className='house-around-service'>
                                                                        <div className='house-around-second'>
                                                                            <div
                                                                                id={floor.id}
                                                                                onClick={() => selectItem(floor.id)}>
                                                                                <p
                                                                                    className="house-icon select-all-icon"
                                                                                >
                                                                                    <OfflinePinIcon style={{ fontSize: '40px' }} />
                                                                                </p>
                                                                                <p
                                                                                    className="house-title select-all-title"
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                    }}
                                                                                >
                                                                                    <span className='line-clamp-1'>
                                                                                        {
                                                                                            checkedListFloor
                                                                                                && floor.id
                                                                                                && checkedListFloor.includes(floor.id)
                                                                                                ? (
                                                                                                    "Bỏ chọn tất cả"
                                                                                                ) : (
                                                                                                    "Chọn tất cả"
                                                                                                )
                                                                                        }
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                apartmentList &&
                                                                apartmentList.filter(element => element.floor_id == floor.id).map(e => (
                                                                    <div
                                                                        key={e.id}
                                                                        className='house-around-service'
                                                                    >
                                                                        <div
                                                                            className='house-around-second'
                                                                        >
                                                                            <div>
                                                                                <Tooltip
                                                                                    placement="bottomRight"
                                                                                    mouseLeaveDelay={0.4}
                                                                                >
                                                                                    <p
                                                                                        id={e.id}
                                                                                        className=
                                                                                        {
                                                                                            e.status_id === "0"
                                                                                                ? "house-icon house-color-unactive"
                                                                                                : "house-icon house-color-pay-status-true"
                                                                                        }
                                                                                        onClick={() => hanldeClick(e)}
                                                                                    >
                                                                                        {
                                                                                            e.status_id !== "0"
                                                                                                ? (
                                                                                                    <StyledBadge badgeContent={e.qLCCSoVch05MLogs?.length} color="error">
                                                                                                        <HomeIcon style={{ fontSize: '36px' }} />
                                                                                                    </StyledBadge>
                                                                                                )
                                                                                                :
                                                                                                <HomeIcon style={{ fontSize: '36px', color: '#C6C6C6' }} />
                                                                                        }
                                                                                    </p>

                                                                                    <p
                                                                                        className='house-title'
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'center',
                                                                                        }}
                                                                                    >
                                                                                        <Checkbox
                                                                                            onClick={handleChangeChecked}
                                                                                            value={e.id}
                                                                                            checked={checkedList.includes(e.id || "")}
                                                                                            disabled={e.status_id === "0"}
                                                                                        >
                                                                                            <span
                                                                                                className='line-clamp-1'
                                                                                            >

                                                                                                {e.name}

                                                                                            </span>
                                                                                        </Checkbox>
                                                                                    </p>
                                                                                    {
                                                                                        e.owner_resident_id
                                                                                        && (
                                                                                            <>
                                                                                                <p
                                                                                                    className='house-title-sup line-clamp-1'
                                                                                                >
                                                                                                    {e.owner_resident_name}
                                                                                                </p>
                                                                                                {/* <p
                                                                                        className='house-title-sup house-title-cursor line-clamp-1'
                                                                                        onClick={() => hanldeClick(e)}
                                                                                    >
                                                                                        <a href="#"
                                                                                            style={{
                                                                                                color: (e.qLCCSoVch05MLogs && e.qLCCSoVch05MLogs?.length > 0) ? 'blue' : 'red'
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                e.qLCCSoVch05MLogs
                                                                                                    && e.qLCCSoVch05MLogs?.length > 0 ?
                                                                                                    `Lần phát hành: ${e.qLCCSoVch05MLogs?.length}`
                                                                                                    :
                                                                                                    `Chưa phát hành`
                                                                                            }
                                                                                        </a>
                                                                                    </p> */}
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                            {
                                                                !apartmentList ||
                                                                (apartmentList &&
                                                                    apartmentList.filter(element => element.floor_id == floor.id).length == 0) && (
                                                                    <div
                                                                        style={{
                                                                            textAlign: 'center'
                                                                        }}
                                                                    >
                                                                        {
                                                                            blockID_ &&
                                                                                blockID_ == "" ? (
                                                                                <h4 style={{ color: '#D8D8D8' }}>Vui lòng chọn tòa nhà!</h4>
                                                                            ) : (
                                                                                <h5 style={{ color: '#D8D8D8' }}>Không có căn hộ</h5>
                                                                            )
                                                                        }

                                                                    </div>
                                                                )
                                                            }
                                                        </Box>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))
                                    }
                                    {
                                        (qlccFloorList &&
                                            qlccFloorList.length == 0
                                        ) && (
                                            <div
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {
                                                    blockID_ &&
                                                    blockID_ != "" && (
                                                        <h5 style={{ color: '#D8D8D8' }}>Tầng trống</h5>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        !blockID_ &&
                                        (
                                            <div
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <h5>Chọn tòa nhà</h5>
                                            </div>
                                        )
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </div >
                </DialogContent>
                {/* <BlockUI blocking={isLoading} title={""}></BlockUI> */}
                <DHSLoadPanel open={isLoading} />
            </Dialog>
        </>
    );
};

export default ServiceInvoiceClientCreateBill;


