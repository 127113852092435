import React, { useState } from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Grid,
    Autocomplete,
} from "@mui/material";
import {
    QLCCApartmentBuildingModel,
    QLCCCategoriesModel,
    QLCCClient,
    QLCCDomesticWaterCycleModel,
    QLCCDomesticWaterNormModel,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import CloseIcon from "@mui/icons-material/Close";

import { TransitionProps } from "@mui/material/transitions";

import BlockUI from "../../../../components/Block-UI/Block-ui";
import { Notification } from "../../../../components/layouts";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import CurrencyTextField from "react-currency-input-field";
import CTXDate from "../../../../components/CTX/DatePicker";
import moment from "moment";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { MESSAGE_TYPE } from "../../../../common/Interfaces/StatusMessage";
import { STATUS_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import DHSSelectBox from "../../../../components/DHS/DHS_SelectBox";
import _ from "lodash";
import DHSDateTimeComponentV2 from "../../../../components/DHS/DHS-DateTime/DHSDateTimeComponentV2";
import { HintCode } from "../../../../common/enums/DHSInputDetail";
interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
    data: QLCCDomesticWaterCycleModel | undefined;
    apartmentBuilding: QLCCApartmentBuildingModel | undefined;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});
var appSession: AppSession;

const DomesticWaterCycleAction = (props: IProps) => {
    const convert = ["success", "error", "warning"];
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [_id, setId] = React.useState<string>();
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<QLCCDomesticWaterCycleModel>({ ... new QLCCDomesticWaterCycleModel() } as QLCCDomesticWaterCycleModel);
    const [apartmentBuildings, setApartmentBuildings] = React.useState<QLCCApartmentBuildingModel[]>([] as QLCCApartmentBuildingModel[]);
    const [month, setMonth] = useState<number>();
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)


    // Trả về từ ngày, đến ngày dựa trên thời gian setup sẵn của dự án
    const getNewDateByApartmentBuilding = () => {
        const getApartmentBuilding = props.apartmentBuilding
        if (getApartmentBuilding) {
            const date = moment(getApartmentBuilding.posted_date_invoice).date();
            const getMonthOfFromDate = moment().month(); // manh động lắm
            const getMonthOfToDate = moment().month();// manh động lắm
            return {
                from_date: moment().date(date).month(getMonthOfFromDate).startOf('day').toDate(),
                to_date: moment().date(date).month(getMonthOfFromDate >= getMonthOfToDate ? getMonthOfFromDate + 1 : getMonthOfToDate).endOf('day').toDate(),
            }
        }
        return {
            from_date: moment().startOf('day').toDate(),
            to_date: moment().endOf('day').toDate(),
        }
    }

    React.useEffect(() => {
        if (props.open) {
            if (props.action === ACTIONS.ADD) {
                const newObjDate = getNewDateByApartmentBuilding();
                const newData = { ...new QLCCDomesticWaterCycleModel(), from_date: newObjDate.from_date, to_date: newObjDate.to_date, apartment_building_id: props.apartmentBuilding?.id, } as QLCCDomesticWaterCycleModel;
                setData(newData);
                setMonth(moment(newObjDate.to_date).month());
            } else {
                setData({
                    ...props.data,
                    create_date: props.data?.create_date ? new Date(props.data?.create_date) : new Date()
                } as QLCCDomesticWaterCycleModel);
                if (props.data) {
                    setMonth(moment(props.data.to_date).month());
                }
            }
        }
    }, [props.open]);


    // Trả về từ ngày, đến ngày dựa trên thời gian setup sẵn của dự án
    const getNewDate = (param: QLCCDomesticWaterCycleModel) => {
        const getApartmentBuilding = _.find(apartmentBuildings, item => item.id === param.apartment_building_id);
        if (getApartmentBuilding) {
            const date = moment(getApartmentBuilding.posted_date_invoice).date();
            const getMonthOfFromDate = moment(param.from_date).month();
            const getMonthOfToDate = moment(param.to_date).month();
            return {
                from_date: moment().date(date).month(getMonthOfFromDate).startOf('day').toDate(),
                to_date: moment().date(date).month(getMonthOfFromDate >= getMonthOfToDate ? getMonthOfFromDate + 1 : getMonthOfToDate).endOf('day').toDate(),
            }
        } else if (props.apartmentBuilding) {
            const date = moment(props.apartmentBuilding.posted_date_invoice).date();
            const getMonthOfFromDate = moment(param.from_date).month();
            const getMonthOfToDate = moment(param.to_date).month();
            return {
                from_date: moment().date(date).month(getMonthOfFromDate).startOf('day').toDate(),
                to_date: moment().date(date).month(getMonthOfFromDate >= getMonthOfToDate ? getMonthOfFromDate + 1 : getMonthOfToDate).endOf('day').toDate(),
            }
        }
        return {
            from_date: moment().startOf('day').toDate(),
            to_date: moment().endOf('day').toDate(),
        }
    }

    const handleChangeInput = (e: any) => {
        if (props.action.toUpperCase() !== ACTIONS.VIEW) {
            const { name, value } = e.target;

            if (name === 'apartment_building_id') { // chọn dự án
                const newObjDate = getNewDate({ ...data, apartment_building_id: value } as QLCCDomesticWaterCycleModel);
                const newData = { ...data, from_date: newObjDate.from_date, to_date: newObjDate.to_date, apartment_building_id: value, } as QLCCDomesticWaterCycleModel;

                setData(newData);
            }
            else {
                const newData = { ...data, [name]: value, } as QLCCDomesticWaterCycleModel;
                setData(newData);
            }
        }
    }
    const handleSubmit = () => {
        switch (props.action.toUpperCase()) {
            case ACTIONS.ADD:
                if (moment(data.from_date).toDate() > moment(data.to_date).toDate()) {
                    Notification(convert[1], "Ngày bắt đầu không được bé hơn ngày kết thúc");
                }
                else {
                    qLCCClient
                        ?.qlccDomesticWaterCycleAction({
                            ...data,
                            from_date: moment(data.from_date).startOf("day").utc(true).toDate(),
                            to_date: moment(data.to_date).endOf("day").utc(true).set('millisecond', 0).toDate(),
                            month: moment(data.to_date).month() === 11 ? 1 : moment(data.to_date).month() + 1,
                            year: moment(data.to_date).year(),
                            action_type: "INSERT",
                            ma_cty: SessionLogin.ma_cty,
                            apartment_building_id: apartment_building_id,
                         } as any)
                        .then((res) => {
                            Notification(convert[res.status || 0], res.message);
                            setLoading(false);
                            if (res.status == 0) {
                                props.onClose();
                                props.onEditSuccess(true)
                            }
                        })
                        .catch((err) => {
                            Notification(convert[err.status || 0], err.message);
                        });
                }
                break;
            case ACTIONS.EDIT:
                if (moment(data.from_date).toDate() > moment(data.to_date).toDate()) {
                    Notification(convert[1], "Ngày bắt đầu không được bé hơn ngày kết thúc");
                }
                else {
                    qLCCClient
                        ?.qlccDomesticWaterCycleAction({
                            ...data,
                            from_date: moment(data.from_date).startOf("day").utc(true).toDate(),
                            to_date: moment(data.to_date).endOf("day").utc(true).set('millisecond', 0).toDate(),
                            month: moment(data.to_date).month() === 11 ? 1 : moment(data.to_date).month() + 1,
                            year: moment(data.to_date).year(),
                            action_type: "UPDATE",
                            ma_cty: SessionLogin.ma_cty,
                            apartment_building_id: apartment_building_id,
                        } as any)
                        .then((res) => {
                            Notification(convert[res.status || 0], res.message);
                            setLoading(false);
                            if (res.status == 0) {
                                props.onClose();
                                props.onEditSuccess(true)
                            }
                        })
                        .catch((err) => {
                            Notification(convert[err.status || 0], err.message);
                        });
                }
                break;
            default:
                break;
        }
    }

    const handleLock = async () => {
        try {
            const ResData = await qLCCClient.qlccDomesticWaterCycleAction({
                ... new QLCCDomesticWaterCycleModel(),
                ...data,
                action_type: "BLOCK"
            } as QLCCDomesticWaterCycleModel);

            if (ResData) {
                Notification(MESSAGE_TYPE[ResData.status!], ResData.message!);
                if (ResData.status == 0) {
                    props.onClose();
                    props.onEditSuccess(true)
                }
            }
        } catch (ex) {
            alert(ex);
            Notification(MESSAGE_TYPE[1], STATUS_MESSAGE['SERVER_ERROR']);
        }
    }

    const handleUnlock = async () => {
        try {
            const ResData = await qLCCClient.qlccDomesticWaterCycleAction({
                ... new QLCCDomesticWaterCycleModel(),
                ...data,
                action_type: "UNBLOCK"
            } as QLCCDomesticWaterCycleModel);

            if (ResData) {
                Notification(MESSAGE_TYPE[ResData.status!], ResData.message!);
                if (ResData.status == 0) {
                    props.onClose();
                    props.onEditSuccess(true)
                }
            }
        } catch (ex) {
            alert(ex);
            Notification(MESSAGE_TYPE[1], STATUS_MESSAGE['SERVER_ERROR']);
        }
    }

    return (
        <Dialog
            id="sysMenu6"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            TransitionComponent={Transition}
            maxWidth="md"
        >
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === "DETAIL"
                                ? "[Chi tiết]"
                                : props.action === "EDIT" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="error"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {/* <Grid item xs={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Dự án</InputLabel>
                            <DHSSelectBox
                                dataSource={[props.apartmentBuilding]}
                                displayExpr="name"
                                valueExpr="id"
                                name="apartment_building_id"
                                value={data?.apartment_building_id}
                                onValueChanged={handleChangeInput}
                                searchExpr="name"
                                disabled={true}
                                hint={HintCode.APARTMENT_BUILDING}
                            />
                        </Box>
                    </Grid> */}
                    <Grid item xs={12} md={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Từ ngày </InputLabel>
                            <DHSDateTimeComponentV2
                                value={data.from_date}
                                name="from_date"
                                format="dd-MM-yyyy"
                                onChange={(e) => {
                                    handleChangeInput({
                                        target: {
                                            name: e.name,
                                            value: e.value
                                        }
                                    })
                                }}
                                disabled={props.action === ACTIONS.VIEW}
                                applyValueMode={"instantly"}
                                type="date"
                                maxDate={moment(data.to_date).toDate()}
                                hint={HintCode.FROM_DATE}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Đến ngày</InputLabel>
                            <DHSDateTimeComponentV2
                                value={data.to_date}
                                name="to_date"
                                format="dd-MM-yyyy"
                                onChange={(e) => {
                                    handleChangeInput({
                                        target: {
                                            name: e.name,
                                            value: e.value
                                        }
                                    })
                                }}
                                disabled={props.action === ACTIONS.VIEW}
                                applyValueMode={"instantly"}
                                type="date"
                                minDate={moment(data.from_date).toDate()}
                                hint={HintCode.TO_DATE}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent >
            <DialogActions>
                {
                    props.action !== ACTIONS.ADD && !data.is_block && (
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleLock}
                            >
                                Khóa
                            </Button>
                        </>
                    )
                }
                {
                    props.action !== ACTIONS.ADD && data.is_block && (
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleUnlock}
                            >
                                Mở khóa
                            </Button>
                        </>
                    )
                }
                {
                    props.action !== ACTIONS.VIEW &&
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Lưu
                    </Button>
                }
                <Button onClick={props.onClose}>Đóng</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog >
    );
};

export default DomesticWaterCycleAction;
