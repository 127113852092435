import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { AppSession } from "../../../../shared/app-session/app-session";
import {
  HRClient,
  HREmployee,
  LeaveForm,
  LstHRDepartments,
  LstHRLeaveType,
  LstHRPositions,
  RoleRightWebModel,
} from "../../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../shared/service-proxies/config";
import Select from "../../../../../components/DHS/DHS_Select";
import notification from "../../../../../common/notification/notification";
import DHSDate from "../../../../../components/DHS/DHS-Date/DHSDateComponent";

interface IProps {
  id?: string;
  action: RoleRightWebModel | undefined;
  nowTime: Date;
  onDestroyAction?: (isDestroy: boolean, goBack?: number) => void;
}
var appSession: AppSession;

const ApproveFormAction = (props: IProps) => {
  const hrClient = new HRClient(appSession, BASE_URL_API);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [goBack, setGoBack] = React.useState<number>(-1);
  const [data, setData] = React.useState<LeaveForm>({
    ...new LeaveForm(),
    sender_name: SessionLogin.userName,
    fromdate: new Date(),
    todate: new Date(),
  } as LeaveForm);
  const [{ _id }, setId] = React.useState(useParams<{ _id: string }>());
  const history = useHistory();
  const [isViewDetail, activeViewDetail] = React.useState<boolean>(false);
  const [typeOptions, setTypeOptions] = React.useState<any[]>([]);
  // const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

  React.useEffect(() => {
    if (
      props.action &&
      props.action.class_name?.toLocaleUpperCase() === "VIEWDETAIL"
    ) {
      activeViewDetail(true);
    } else if (props.action && !props.action.class_name) {
      history.push("/admin/on-leave");
    }
    if (_id) {
      hrClient
        .hROnLeaveGet({
          ...new LeaveForm(),
          id: _id,
          ma_cty: SessionLogin.ma_cty,
          receiver_code: SessionLogin.employeeCode,
        } as LeaveForm)
        .then((response) => {
          setData(response[0]);
        });
    }
  }, []);
  //Set Options
  React.useEffect(() => {
    hrClient
      .lstHRLeaveTypeGet({
        ...new LstHRLeaveType(),
        ma_cty: SessionLogin.ma_cty,
      } as LstHRLeaveType)
      .then((response) => {
        setTypeOptions(response);
      });
  }, []);

  React.useEffect(() => {
    if (_id && props.action) {
      switch (props.action.class_name?.toLocaleUpperCase()) {
        case "APPROVE":
          hrClient
            .hROnLeaveUpd({
              ...data,
              id: _id,
              approve_status_code: 1,
              sender_code: SessionLogin.employeeCode,
            } as LeaveForm)
            .then((res) => {
              setGoBack((item) => (item -= 1));
              if (res[0].ret === 0) {
                notification("success", res[0].message);
              } else if (res[0].ret === 1) {
                notification("error", res[0].message);
              } else {
                notification("warning", res[0].message);
              }
            })
            .catch((err) => console.log(err));
          break;
        case "HRAPPROVE":
          hrClient
            .hROnLeaveUpd({
              ...data,
              id: _id,
              approve_status_code: 2,
              sender_code: SessionLogin.employeeCode,
            } as LeaveForm)
            .then((res) => {
              setGoBack((item) => (item -= 1));
              if (res[0].ret === 0) {
                notification("success", res[0].message);
              } else if (res[0].ret === 1) {
                notification("error", res[0].message);
              } else {
                notification("warning", res[0].message);
              }
            })
            .catch((err) => console.log(err));
          break;
        case "REJECT":
          hrClient
            .hROnLeaveUpd({
              ...data,
              id: _id,
              approve_status_code: 3,
              sender_code: SessionLogin.employeeCode,
            } as LeaveForm)
            .then((res) => {
              setGoBack((item) => (item -= 1));
              if (res[0].ret === 0) {
                notification("success", res[0].message);
              } else if (res[0].ret === 1) {
                notification("error", res[0].message);
              } else {
                notification("warning", res[0].message);
              }
            })
            .catch((err) => console.log(err));
          break;
      }
    }
    if (props.onDestroyAction) props.onDestroyAction(true, goBack - 1);
  }, [props.nowTime]);

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value } as LeaveForm);
  };

  return (
    <>
      <div className="d-flex">
        <div className="w-100 p-4">
          <div style={{ backgroundColor: "white" }} className="p-3 d-flex">
            <div className="w-100 h-100">
              <div className="row">
                <div className="form-group col-xl-12">
                  <label>Người gửi</label>
                  <input
                    className="flex-grow-1 form-control"
                    placeholder="Người gửi"
                    value={data.sender_name}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-3">
                  <Select
                    label="Loại nghỉ phép"
                    value={data.type}
                    options={typeOptions}
                    onChange={handleChangeInput}
                    disabled={true}
                    fieldValue={"leave_type_id"}
                    fieldDisplay={"leave_type_name"}
                    name="type"
                  />
                </div>
                <div className="form-group col-md-3">
                  <DHSDate
                    label="Ngày bắt đầu"
                    className="flex-grow-1 form-control"
                    name="fromdate"
                    value={data.fromdate?.toString()}
                    disabled={true}
                    id={data.id ? data.id : ""}
                  />
                </div>
                <div className="form-group col-md-3">
                  <DHSDate
                    label="Ngày kết thúc"
                    className="flex-grow-1 form-control"
                    name="todate"
                    value={data.todate?.toString()}
                    disabled={true}
                    id={data.id ? data.id : ""}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Tổng ngày nghỉ</label>
                  <input
                    className="flex-grow-1 form-control"
                    type="number"
                    name="sumarytime_onleave"
                    value={data.sumarytime_onleave}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label>Lí do</label>
                  <textarea
                    className="flex-grow-1 form-control"
                    name="reason"
                    value={data.reason}
                    disabled={true}
                  />
                </div>
                <div className="col-12"></div>
                <div className="form-group col-md-6">
                  <label>Ý kiến từ người quản lý</label>
                  <textarea
                    className="flex-grow-1 form-control"
                    name="opinion_from_manager"
                    onChange={handleChangeInput}
                    value={data.opinion_from_manager}
                    disabled={false}
                  />
                </div>
                <div className="col-12"></div>
                <div className="form-group col-md-6">
                  <label>Ý kiến từ phòng nhân sự</label>
                  <textarea
                    className="flex-grow-1 form-control"
                    name="opinion_from_hr"
                    onChange={handleChangeInput}
                    value={data.opinion_from_hr}
                    disabled={false}
                  />
                </div>
              </div>

              {/* <div className="form-group">
                <label>Chữ ký từ trưởng bộ phận</label>
                <input
                  className="flex-grow-1 form-control"
                  type="text"
                  name="receihead_departmentver"
                  placeholder="Trưởng bộ phận"
                  onChange={handleChangeInput}
                  value={data.head_department}
                />
              </div>
              <div className="form-group">
                <label>Chữ ký từ phòng nhân sự</label>
                <input
                  className="flex-grow-1 form-control"
                  type="text"
                  name="hr"
                  placeholder="Phòng nhân sự"
                  onChange={handleChangeInput}
                  value={data.hr}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApproveFormAction;
