import React, { useEffect, useState } from 'react';
import { AppSession } from '../../../shared/app-session/app-session';
import { QLCCClient, QLCCServiceModel, QLCCVehicleModel, SysModel } from '../../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../../shared/service-proxies/config';
import BlockUI from '../../../../components/Block-UI/Block-ui';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import VehicleAction from '../../actions/vehicle-action/VehicleAction';

import { Button, Card, List, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import "./apartment.css"
import notification from '../../../../common/notification/notification';
import { ToVietNamCurrency } from '../../../../components/utils/number.util';


var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any,
    itemSelected: any

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null,
    itemSelected: null
}

// styled


// 



interface IProps {
    ma_cty?: string,
    apartment_id?: string,
    apartment_building_id?: string
}

// 



const columns: ColumnsType<QLCCVehicleModel> = [
    {
        key: 'owner_name',
        title: 'Chủ cavet xe',
        dataIndex: 'owner_name',
        
    },
    {
        key: 'vehicle_name',
        title: 'Tên xe',
        dataIndex: 'vehicle_name',
    },
    {
        key: 'vehicle_no',
        title: 'Biển số xe',
        dataIndex: 'vehicle_no',
    },
    {
        key: 'vehicle_type_name',
        title: 'Loại xe',
        dataIndex: 'vehicle_type_name',
    },
    {
        key: 'amount',
        title: 'Đơn giá',
        dataIndex: 'amount',
        align: 'right',
        render(value, record, index) {
            return <div style={{textAlign: 'end'}}>{ToVietNamCurrency(value)}</div>
        },
    },
    // {
    //     key: 'quantity',
    //     title: 'Số lượng',
    //     dataIndex: 'quantity',
    // },

];
const status_message = ['success', 'error']

const ApartmentVehicleList: React.FC<IProps> = (props) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    // const roles = useSelector((state: IReducer) => state.RoleReducer?.ApartmentServicelistRole);

    const [reload, setReload] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<QLCCVehicleModel[]>([]);

    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);

    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const [quantityTotal, setQuantityTotal] = useState<number>(0);
    const [amountTotal, setAmountTotal] = useState<number>(0);

    // 

    const [actVh, setActVh] = React.useState<IAction>(defaultAction);
    const [vehicleSelected, setVehicleSelected] = React.useState<string>();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>();

    useEffect(() => {


        setLoading(true)
        qLCCClient.qLCCVehicleGet({
            ...new QLCCVehicleModel(),
            ma_cty: props?.ma_cty,
            apartment_id: props.apartment_id,
            apartment_building_id: props?.apartment_building_id,
            action_type: 'APARTMENT_VEHICLE'
        } as QLCCVehicleModel)
            .then(res => {
                let quantityTotalTemp = 0;
                let amountTotalTemp = 0;
                res.forEach((vehicle) => {
                    quantityTotalTemp += vehicle.quantity!;
                    amountTotalTemp += vehicle.amount!;
                });
                setLoading(false);
                // setDataTable(res);
                const tempData: any[] = [];
                res.forEach(data => {
                    tempData.push({
                        ...data,
                        key: data.id
                    })
                });

                // const totalRow = {
                //     key: Date.now().toString(),
                //     vihicle_name: null,
                //     vehicle_no: "",
                //     vehicle_type_name: "",
                //     amount: amountTotalTemp,
                //     quantity: quantityTotalTemp
                // };
                // tempData.push(totalRow);
                setDataTable(tempData);
                setAmountTotal(amountTotalTemp);
                setQuantityTotal(quantityTotalTemp);
                
            })





    }, [props.apartment_id, reload]);

    const handleClickVehicleAction = (e: any) => {
        setActVh(pre => ({
            ...pre,
            itemSelected: vehicleSelected
        }))
        if (e.action.toUpperCase() === "DELETE") {
            if(!vehicleSelected){
                notification(status_message[1], "Vui lòng chọn hàng cần xoá!")
                
            }
            else{
                if (window.confirm("Bạn có chắc chắn muốn xóa?")) {
               

                    qLCCClient.qLCCVehicleDel({
                        ...new QLCCVehicleModel(),
                        id: vehicleSelected,
                        ma_cty: props?.ma_cty,
                        apartment_building_id: props?.apartment_building_id,
                        action_type: 'DELETE'
                    } as QLCCVehicleModel)
                        .then((response) => {
                            setLoading(false);
                            notification(status_message[response.status!], response.message)
                            if (response.status === 0) {
                                setReload(pre => !pre)
                                setVehicleSelected(undefined);
                                // action.action.onEditSuccess(true);
                                // setId(response.ref_id)
                                // setAction({ ...action, itemSelected: response.ref_id, action: 'EDIT' })
                            }
                        });
                   
            }
            }
        }
        else{
            setActVh(pre => ({
                ...pre,
                open: true,
                type: e.action,
                itemSelected: vehicleSelected,
                payload: {
                    ma_cty: props?.ma_cty,
                    apartment_id: props.apartment_id,
                    apartment_building_id: props?.apartment_building_id,
                    vehicle_id: vehicleSelected
                }
            }))
        }
       

    }

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: QLCCVehicleModel[]) => {
            
            setSelectedRowKeys(selectedRowKeys)
            // const tempSelected = selectedRowKeys.join(";")
            // 
            setVehicleSelected(selectedRowKeys[0] as string);
        },
        getCheckboxProps: (record: QLCCVehicleModel) => ({
            // disabled: !record.vehicle_name, // Column configuration not to be checked
            name: record.vehicle_name,
        }),
    };

    function rowClassName(record: any, index: number) {
        if (index === dataTable.length - 1) {
            return 'total-row';
        }

        return "";
    }


    return (


        <div className='container-fluid p-0'>

            {
                actVh.open && <VehicleAction
                    open={actVh.open}
                    onClose={function (): void {
                        setActVh(defaultAction);
                    }}
                    action={actVh.type}
                    title='Xe cư dân'
                    action_type={"OWNER_APARTMENT"}
                    payload={actVh.payload}
                    itemSelected={actVh.itemSelected}
                    onEditSuccess={function (data_response: SysModel): void {
                        setReload(pre => !pre)
                    }} >

                </VehicleAction>
            }

            <div className='row'>
                <div className="col-md-12 mb-2 d-flex">
                    <div className='px-1'>
                        <Button type='text' size='large' style={{ borderRadius: 5, display: 'flex', alignItems: 'center', color: "#3787D7", fontWeight: 600 }} icon={<PlusOutlined />} onClick={() => handleClickVehicleAction({
                            action: "ADD"
                        })}>Thêm xe</Button>

                    </div>
                    <div className='px-1'>
                        <Button type='text' size='large' style={{ borderRadius: 5, display: 'flex', alignItems: 'center', color: "#3787D7", fontWeight: 600 }} disabled={!vehicleSelected} icon={<i className="bi bi-pencil-square anticon "></i>} onClick={() => handleClickVehicleAction({
                            action: "EDIT"
                        })}>Chỉnh sửa xe</Button>
                    </div>
                    <div className='px-1'>
                        <Button type='text' danger size='large' style={{ borderRadius: 5, display: 'flex', alignItems: 'center', color: "#3787D7", fontWeight: 600 }} disabled={!vehicleSelected} icon={<i className="bi bi-trash3 anticon"></i>} onClick={() => handleClickVehicleAction({
                            action: "DELETE",
                        })}>Xoá xe</Button>
                    </div>
                </div>

                <div className="col-md-8 col-xs-12">
                    <div>
                        <Table
                            rowSelection={{
                                type: 'radio',
                                ...rowSelection,
                            }}
                            columns={columns}
                            dataSource={dataTable}
                            // rowClassName={rowClassName}
                            pagination={{ pageSize: 5 }}
                        />
                    </div>
                </div>
                <div className="col-md-4  col-xs-12">
                    <Card title="Tổng" className='total-vehicle-card'>
                    
                        <List>
                            <List.Item className='d-flex'>
                                <Typography.Text >Tổng đơn giá: </Typography.Text> <div style={{textAlign: 'end', fontWeight: '700'}}>{ToVietNamCurrency(amountTotal)}</div>
                            </List.Item>
                            <List.Item className='d-flex'>
                                <Typography.Text >Tổng số lượng: </Typography.Text> <div style={{textAlign: 'end', fontWeight: '700'}}> {quantityTotal}</div>
                            </List.Item>
                        </List>
                    </Card>
                </div>
            </div>




            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default ApartmentVehicleList;


