import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DHSToolbar from '../../../../components/DHS/DHS-Toolbar/DHSToolbar';
import { IReducer } from '../../../../components/layouts';
import DHSGridView from '../../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
import { getRoles } from '../../../../redux/actions/RoleActions';
import { AppSession } from '../../../shared/app-session/app-session';
import {
    BaseformClient,
    CaVch01Client,
    CaVch01M,
    HomeClient,
    ParasysActionType,
    QLCCApartmentBuildingModel,
    RoleRightWebModel,
    RptCATMNH01,
    RptCATMNH01Model,
    SoVch01Client,
    SoVch05M,
} from '../../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../../shared/service-proxies/config';

import { useHistory, useLocation } from 'react-router-dom';
import BlockUI from '../../../../components/Block-UI/Block-ui';
import notification from '../../../../common/notification/notification';
import { SoVch05Client } from '../../../shared/service-proxies/api-shared';
import moment from 'moment';
import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputLabel, MenuItem, Modal, Select, Slide, TextField, Toolbar, Typography } from '@mui/material';
import CTXDate from '../../../../components/CTX/DatePicker';
import { callApiToken } from '../../../../routes/configApi';
import '../../actions/ApartmentBuilding.css';
import { TransitionProps } from '@mui/material/transitions';
import SearchIcon from '@mui/icons-material/Search';
import CCGridView from '../cash-book-report/component/CCGridView';
import BetweenTwoDate from '../cash-book-report/component/between-two-date-X';
import { isBuffer } from 'lodash';
import DHSToolbarRole from '../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import { ACTIONS } from '../../../../common/enums/DHSToolbarRoleAction';
import { SysActionType } from '../../../shared/service-proxies/api-shared';
import { useHotkeys } from 'react-hotkeys-hook';
import SearchOperation from '../../actions/report-action/searchOperationAction';
import DHSBreadcrumb from '../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';


const convert = ["success", "error", "warning"];
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

interface IPayment {
    paymentAmount: string | undefined,
    paymentType: string | undefined
}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

interface IReportInfo {
    spname: string | undefined,
    title: string | undefined,
    header: string[] | undefined,
}



const roleMenu: string = 'CC';
const CashByExpenditureReport: React.FC<{}> = () => {
    const location = useLocation();

    const dispatch = useDispatch();
    const history = useHistory();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const vch05Client = new SoVch05Client(appSession, BASE_URL_API);
    const vch01Client = new SoVch01Client(appSession, BASE_URL_API);
    const caVch01Client = new CaVch01Client(appSession, BASE_URL_API);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const [dataTable, setDataTable] = React.useState<RptCATMNH01[]>([] as RptCATMNH01[]);
    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const [isShowModal, setIsShowModal] = React.useState<boolean>(false);
    const [payment, setPayment] = React.useState<IPayment>({} as IPayment);
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const Token = {
        Token: SessionLogin.Token
    }
    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
    const [isShowSort, setShowSort] = React.useState<boolean>(false);
    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);

    const [isShowDate, setIsShowDate] = React.useState<boolean>(true);
    const [fromDate, setFromDate] = React.useState<Date>(
        new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1, 0, 0, 0))
    );
    const [toDate, setToDate] = React.useState<Date>(
        new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 0, 23, 0, 0))
    );
    const customMenu = [
        {
            menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
            onClick: () => {
                setShowSearch(!isShowSearch);
            },
        },
        {
            menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
            onClick: () => {
                setShowGroup(!isShowGroup);
            },
        },
        {
            menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
            onClick: () => {
                setShowSort(!isShowSort);
            },
        },
        {
            menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
            onClick: () => {
                setShowFilter(!isShowFilter);
            },
        },
    ];

    const [reportInfoList, setReportInfoList] = React.useState<any>([]);
    const [reportInfo, setReportInfo] = React.useState<IReportInfo>({} as IReportInfo)
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [isExport, setIsExport] = React.useState<boolean>(false);


    const [actionTypes, setActionTypes] = React.useState<ParasysActionType[]>();
    const baseFormClient = new BaseformClient(appSession, BASE_URL_API);


    useHotkeys<HTMLParagraphElement>('ctrl+f1', () => setIsOpen(!isOpen));

    useHotkeys<HTMLParagraphElement>('alt+1', () => setShowSearch(!isShowSearch));

    useHotkeys<HTMLParagraphElement>('alt+2', () => setShowGroup(!isShowGroup));

    useHotkeys<HTMLParagraphElement>('alt+3', () => setShowSort(!isShowSort));

    useHotkeys<HTMLParagraphElement>('alt+4', () => setShowFilter(!isShowFilter));

    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)


    const onloadData = () => {
        if (apartment_building_id) {
            callApiToken("api/QLCCReport/WaterReport", "POST", {
                ma_cty: SessionLogin.ma_cty,
                ngay_Ct1: fromDate,
                ngay_ct2: toDate,
                apartment_building_id: apartment_building_id,
                spname: reportInfo.spname || ""
            }, Token)
                .then((response: any) => {
                    setDataTable(response.data.Table);
                }).catch(error => {
                    console.log(error)
                })
        }
    }

    React.useEffect(() => {
        setLoading(true);
        homeClient
            .roleRightWebGetAll({
                ...new RoleRightWebModel(),
                menuid: menu?.menuid,
                rolename: roleMenu,
                username: SessionLogin.userName,
            } as RoleRightWebModel)
            .then((res) => {
                dispatch(getRoles(res));
                setRoles(res);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false)
            })


        baseFormClient.getActionType({
            ...new ParasysActionType(),
            action_type: menu?.action_type,
            language: 'vi-VN'
        } as ParasysActionType)
            .then(res => {
                setActionTypes(res);
            })

        callApiToken("api/Common/ReportInfoGet", "POST", {
            menuid: menu?.menuid,
            apartment_building_id: apartment_building_id,
            language: "vi-VN"
        }, Token)
            .then(response => {
                setReportInfoList(response.data);
                if (response.data.length > 0) {
                    setReportInfo({
                        ...reportInfo,
                        ...response.data[0],
                        header: response.data[0]?.formateD_COL_LIST.split(',')
                    } as IReportInfo);
                }
            }).catch(error => {
                console.log(error)
            })

    }, [menu?.component])

    React.useEffect(() => {
        onloadData();
    }, [reload]);

    React.useEffect(() => {
        onloadData();
    }, [reportInfo, apartment_building_id]);

    const handleClickAction = (action: SysActionType, nowTime: Date) => {
        if (action.action_code === ACTIONS.SEARCH) {
            setIsOpen(true);
        } else if (action.action_code === ACTIONS.EXPORTEXCEL) {
            setIsExport(!isExport);
        }
    }

    const handleSubmit = () => {
        setReload(!reload);
        setIsOpen(false);
    }


    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === 'reportInfo') {
            const item = reportInfoList.find((e: any) => e.spname === value);
            if (item) {
                setReportInfo({
                    ...reportInfo,
                    spname: value,
                    header: item.formateD_COL_LIST.split(','),
                    title: item.title,
                } as IReportInfo);
            }
        }
    }

    return (
        <div className='container-fluid'>

            {/* Lọc dữ liệu */}

            {/* <SearchOperation
                isOpen={isOpen}
                setIsOpen={(status: any) => setIsOpen(status)}
                reportInfo={reportInfo}
                handleChange={handleChange}
                reportInfoList={reportInfoList}
                setReload={() => setReload(!reload)}
                setFromDate={(data: any) => setFromDate(data)}
                setToDate={(data: any) => setToDate(data)}
                setApartmentBuilding={(data: any) => setApartmentBuilding(data)}
                apartmentBuilding={apartmentBuilding.id}
            /> */}

            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location}
                />
            </div>

            {/* Control */}
            <DHSToolbarRole
                id={idItemSelected as string}
                menu={menu}
                customMenu={customMenu}
                onClickAction={handleClickAction}
            />


            <CCGridView
                dataSource={dataTable}
                selection={false} // ẩn chon nhiều đi
                id='name'
                header={reportInfo.header || []}
                setIdItemSelected={(lstId: string) => setIdItemSelected(lstId)}
                isShowFilter={isShowFilter}
                isShowGroup={isShowGroup}
                isShowSearch={isShowSearch}
                isShowSort={isShowSort}
                table_name='Báo cáo công nợ theo hóa đơn'
                isExport={isExport}
                setIsExport={() => setIsExport(!isExport)} // tự động tắt xuất excel

            />

            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default CashByExpenditureReport;


