import React, { useEffect } from "react";
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
    Grid,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    Button,
    useMediaQuery,
    debounce,
} from "@mui/material";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import CloseIcon from "@mui/icons-material/Close";

import {
    QLCCClient,
    QLCCApartmentBuildingModel,
    SysActionType,
    QLCCVehicleCardModel,
    QLCCVehicleModel,
    QLCCVehicleCardStatusModel,
    ItemClient,
    LstItem,
} from "../../../shared/service-proxies/api-shared";


import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../ApartmentBuilding.css";
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import CTXDate from "../../../../components/CTX/DatePicker";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import notification from "../../../../common/notification/notification";
import VehicleInfo from "./vehicle-info";
import CurrencyTextField from "react-currency-input-field";
import { httpPost } from "../../../../common/httpService";
import DHSAutoCompleteWithDataGrid from "../../../../components/DHS/DHS_AutoCompleteCustomer/DHSAutoCompleteWithDataGrid";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import NumberBox from "devextreme-react/number-box";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { TransDateTime } from "../../../../components/utils/date.util";
import DHSAutocompleteFilterListSelect from "../../../../components/DHSUtilComponent/DHSAutocompleteFilterListSelect/DHSAutocompleteFilterListSelect";



interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: () => void;
    vehicleCard?: any;
    apartmentBuildings?: QLCCApartmentBuildingModel[] | undefined;
    payload?: any;
    vipAction?: boolean | undefined;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


// const actionsTodo = [
//     {
//         id: "is_cancel",
//         title: "1. Thẻ hủy",
//         titleAction: "1. Hủy thẻ",
//         titleDate: "Ngày hủy",
//         nameDate: "date_cancel",
//         isADD: false
//     },
//     {
//         id: "is_return",
//         title: "2. Thẻ trả lại",
//         titleAction: "2. Trả thẻ lại",
//         titleDate: "Ngày trả",
//         nameDate: "date_return",
//         isADD: false

//     },
//     // {
//     //     id: "is_vip",
//     //     title: "3. Thẻ VIP",
//     //     titleDate: "Ngày cấp",
//     //     nameDate: "date_add",
//     //     isADD: null
//     // },
//     {
//         id: "is_renew",
//         title: "4. Thẻ cấp lại",
//         titleAction: "4. Cấp lại thẻ",
//         titleDate: "Ngày cấp lại",
//         nameDate: "date_add",
//         isADD: false
//     },
//     {
//         id: "is_sell",
//         title: "5. Thẻ bán",
//         titleAction: "5. Bán thẻ",
//         titleDate: "Ngày bán",
//         nameDate: "ldate",
//         isADD: true
//     },
//     {
//         id: "is_sell_available",
//         title: "6. Thẻ bán ( thẻ có sẵn)",
//         titleAction: "6. Bán thẻ ( thẻ có sẵn)",
//         titleDate: "Ngày bán",
//         nameDate: "ldate",
//         isADD: true
//     },
//     {
//         id: "is_change",
//         title: "7. Thẻ đổi",
//         titleAction: "7. Đổi lại thẻ",
//         titleDate: "Ngày đổi",
//         nameDate: "date_add",
//         isADD: false
//     },
// ]

const dateName: { [key: number]: string } = {
    1: "date_return",
    2: "date_cancel",
    3: "date_add",
    4: "date_add",
    5: "date_add",
    6: "date_add",
    7: "date_add"
}

const vehicleCardStatusCodeLost = [3, 4, 8]

const status_message = ['success', 'error']
var appSession: AppSession;

const AllVehicelCardAction: React.FC<IProps> = (props: IProps) => {

    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const isSmallScreen = useMediaQuery('(max-width: 600px)');

    const [_id, setId] = React.useState<string>();

    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    const itemClient = new ItemClient(appSession, BASE_URL_API);

    const [isLoading, setLoading] = React.useState<boolean>(false);

    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [vehicleCard, setVehicleCard] = React.useState<QLCCVehicleCardModel>({} as QLCCVehicleCardModel);

    const qlccClient = new QLCCClient(appSession, BASE_URL_API);

    const [actionTodo, setActionToDo] = React.useState<number>();

    const [vehicleInfo, setVehicleInfo] = React.useState<QLCCVehicleModel>({} as QLCCVehicleModel);

    const [openVehicleInfo, isOpenVehicleInfo] = React.useState<boolean>(false);

    const [vehicleList, setVehicleList] = React.useState<QLCCVehicleModel[]>([]);

    const [itemOptions, setItemOptions] = React.useState<any>(
        []
    );
    const [vehicleOptions, setVehicleOptions] = React.useState<QLCCVehicleModel[]>(
        []
    );
    const [apartmentBuildingOptions, setApartmentBuildingOptions] = React.useState<QLCCApartmentBuildingModel[]>([]);
    const [itemGroupOptions, setItemGroupOptions] = React.useState<{ IDGroup: string, ten_nhvt: string }[]>([]);

    const [apartmentBuilding, setApartmentBuilding] = React.useState<QLCCApartmentBuildingModel>({} as QLCCApartmentBuildingModel);
    const [vehicleCardStatusOptions, setVehicleCardStatusOptions] = React.useState<QLCCVehicleCardStatusModel[]>([]);

    // 
    const [fullScreen, setFullscreen] = React.useState<boolean>(false);
    const [oldVehicleCardNumber, setOldVehicleCardNumber] = React.useState<string>("");

    // 

    const [duplicateVehicleId, setDuplicateVehicleId] = React.useState<Set<string>>(new Set<string>());
    const [sellPrice, setSellPrice] = React.useState<number>(0);
    // 

    React.useEffect(() => {
       if(props?.payload?.apartment_building_id){
        setLoading(true)
        qlccClient.qLCCVehicleGet({
            ...new QLCCVehicleModel(),
            apartment_building_id: props.payload?.apartment_building_id,
            ma_cty: SessionLogin?.ma_cty,
            action_type: "GET"
        } as QLCCVehicleModel)
            .then(response => {
                setLoading(false)
                setVehicleOptions(response)
            })
       }
    }, [props.payload?.apartment_building_id])

    React.useEffect(() => {

       if(props.payload?.apartment_building_id) {
           itemClient.lstItemGet({
               ...new LstItem(),
               mA_CTY: SessionLogin.ma_cty,
               apartment_building_id: props.payload?.apartment_building_id
           } as LstItem)
               .then(response => {
                   setItemOptions(response);
                   setLoading(false)
   
               })

       }
       

    }, [props.payload?.apartment_building_id]);

    // React.useEffect(() => {
    //    if(vehicleCard.vehicle_no && ( props.action === ACTIONS.VIEW || props.action === ACTIONS.EDIT)){
    //      // Kiểm tra biển số xe
    //      handleCheckVehicleNo();
    //    }
    // }, [props.action, vehicleCard.vehicle_no])

    React.useEffect(() => {
        if (props.action === ACTIONS.ADD) {
            // switch (actionTodo) {
            //     case 1:

            //         setVehicleCard(pre => ({
            //             ...pre,
            //             date_cancel: new Date(),
            //             date_add: undefined,
            //             ldate: undefined,
            //             date_return: undefined
            //         } as QLCCVehicleCardModel))
            //         break;
            //     case 2:

            //         setVehicleCard(pre => ({
            //             ...pre,
            //             date_cancel: undefined,
            //             date_add: undefined,
            //             ldate: undefined,
            //             date_return: new Date()
            //         } as QLCCVehicleCardModel))
            //         break;
            //     case 3:
            //     case 4:

            //         setVehicleCard(pre => ({
            //             ...pre,
            //             date_cancel: undefined,
            //             date_add: new Date(),
            //             ldate: undefined,
            //             date_return: undefined
            //         } as QLCCVehicleCardModel))
            //             break;
            //         case 5:
            //         case 6:

            //             setVehicleCard(pre => ({
            //                 ...pre,
            //                 date_cancel: undefined,
            //                 date_add: undefined,
            //                 date_return: undefined,
            //                 ldate: new Date()
            //             } as QLCCVehicleCardModel))
            //             break;
            //     }
        }

        if (actionTodo !== 4) {
            setVehicleCard(pre => ({
                ...pre,
                card_number_old: undefined
            } as QLCCVehicleCardModel))
        }
    }, [actionTodo])


    React.useEffect(() => {
        if (props.open === true) {

            setLoading(true);
            if (props.apartmentBuildings) {
                setApartmentBuildingOptions([...props.apartmentBuildings]);
                setLoading(false);
            }
            else {
                qLCClient.qlccApartmentBuildingGet({
                    ...new QLCCApartmentBuildingModel(),
                    ma_cty: SessionLogin.ma_cty,
                    action_type: 'GET'
                } as QLCCApartmentBuildingModel)
                    .then((res: any) => {
                        setApartmentBuildingOptions(res);
                        if (res) {
                            setApartmentBuilding(res[0]);
                        }
                        setLoading(false);
                    });
            }

            setVehicleInfo({
                ...new QLCCVehicleModel(),
            } as QLCCVehicleModel)

            if (props.action === ACTIONS.ADD) {

                setLoading(true);

                setVehicleCard({
                    ...new QLCCVehicleCardModel(),
                    is_vip: props.vipAction ? true : false,
                    quantity: 1
                } as QLCCVehicleCardModel);

                setActionToDo(undefined);

                setLoading(false);

            } else {
                const itemVehicleInfo = vehicleList.find(p => p.id === props.vehicleCard.vehicle_id);

                if (itemVehicleInfo) {
                    setVehicleCard({
                        ...props.vehicleCard,
                        vehicle_info: itemVehicleInfo,
                    } as QLCCVehicleCardModel);
                    handleCheckVehicleNo(itemVehicleInfo?.vehicle_no)
                    setActionToDo(Number.parseInt(props.vehicleCard?.status_code))

                    // setVehicleInfo({
                    //     ...itemVehicleInfo
                    // } as QLCCVehicleModel)

                } else {
                    setVehicleCard({
                        ...props.vehicleCard,
                    } as QLCCVehicleCardModel);
                    handleCheckVehicleNo(props?.vehicleCard?.vehicle_no)
                    setActionToDo(Number.parseInt(props.vehicleCard?.status_code))

                }

                // if (props.vehicleCard.is_return) {
                //     setActionToDo(1);
                // } else if (props.vehicleCard.is_cancel) {
                //     setActionToDo(2);
                // } else if (props.vehicleCard.is_renew) {
                //     setActionToDo(3);
                // } else if (props.vehicleCard.is_sell) {
                //     setActionToDo(6);
                // } else if (props.vehicleCard.is_broken) {
                //     setActionToDo(4); // chưa biết
                // } else if (props.vehicleCard.is_sell_available) {
                //     setActionToDo(5);
                // } else if (props.vehicleCard.is_change) {
                //     setActionToDo(7);
                // }

                setLoading(false);

            }
        }

    }, [props.open, props.itemSelected, props.vehicleCard]);


    // React.useEffect(() => {
    //     if (props.payload && props.action === ACTIONS.ADD) {
    //         qLCClient.qLCCVehicleCardNewNumberGet(props.payload?.apartment_building_id)
    //             .then(res => {
    //                 setVehicleCard({
    //                     ...vehicleCard,
    //                     card_number: res
    //                 } as QLCCVehicleCardModel)
    //             }).catch(err => {
    //                 console.log(err)
    //             });

    //     }
    // }, [props.payload, props.open])

    const handleChange = (e: any) => {

        if (props.action !== ACTIONS.VIEW) {

            const { name, value } = e.target;

            setVehicleCard(pre => ({
                ...pre,
                [name]: value,
            } as QLCCVehicleCardModel));
        }
    }

    const handleChangeDate = (e: any) => {
        const { name, value } = e;

        if (props.action !== ACTIONS.VIEW) {

            setVehicleCard(pre => ({
                ...pre,
                [name]: new Date(value),
            } as QLCCVehicleCardModel));

        }
    }

    const handleSelectActionTodo = (e: any) => {
        const { name, value } = e.target;
        setActionToDo(value);
    }

    const handleCheckRequired = (): boolean => {
        if (!vehicleCard?.status_id) {
            return false;
        }
        if (!vehicleCard?.card_number) {
            return false;
        }
        return true;
    }

    const handleUpdate = () => {

        const newVehicleCard = {
            ...vehicleCard,
            // ma_cty: SessionLogin.ma_cty,
            customer_id: vehicleCard?.vehicle_info?.resident_id ?? "KL",
            // is_cancel: actionTodo === 2 ? true : false,
            // is_return: actionTodo === 1 ? true : false,
            // is_renew: actionTodo === 3 ? true : false,
            // is_sell: actionTodo === 6 ? true : false,
            // is_broken: actionTodo === 4 ? true : false, // chưa biết
            // is_change: actionTodo === 7 ? true : false,
            // is_sell_available: actionTodo === 5 ? true : false,
            id: props.vehicleCard.id,
            card_number: vehicleCard?.card_number,
            vehicle_info: vehicleCard?.vehicle_info ? vehicleCard?.vehicle_info : undefined,
            vehicle_no: vehicleCard?.vehicle_info?.vehicle_no ? vehicleCard?.vehicle_info?.vehicle_no : null,
            vehicle_id: vehicleCard?.vehicle_info?.id,
            vehicle_name: vehicleCard?.vehicle_info?.vehicle_name,
            luser: SessionLogin.userName,
            date_modified: vehicleCard?.date_modified ? TransDateTime(vehicleCard?.date_modified) : undefined,
            ldate: TransDateTime(new Date()),
            // apartment_building_id: props.payload?.apartment_building_id,
            // is_cancel_return_card: actionTodo === 1 ? vehicleCard.is_cancel_return_card : false,
        } as QLCCVehicleCardModel;
        // debugger
        qlccClient.qLCCVehicleCardUpd({
            ...new QLCCVehicleCardModel(),
            ...newVehicleCard
        } as QLCCVehicleCardModel)
            .then((res: any) => {
                notification(status_message[res.status], res.message)
                if (res.status === 0) {
                    props.onEditSuccess();
                    props.onClose();
                }
            }).catch(err => {
                console.log(err)
            })

    }


    const status = (status: number = 1, message: string = "") => {
        notification(status_message[status], message);
        if (status === 0) {
            props.onEditSuccess();
            props.onClose()
        }
    }

    const handleSubmit = () => {
        const newVehicleCard = {
            ...vehicleCard,
            ma_cty: SessionLogin.ma_cty,
            customer_id: vehicleCard?.vehicle_info?.resident_id ?? "KL",
            // is_cancel: actionTodo === 2 ? true : false,
            // is_return: actionTodo === 1 ? true : false,
            // is_renew: actionTodo === 3 ? true : false,
            // is_sell: actionTodo === 6 ? true : false,
            // is_broken: actionTodo === 4 ? true : false,
            // is_change: actionTodo === 7 ? true : false,
            // is_sell_available: actionTodo === 5 ? true : false,
            vehicle_info: vehicleInfo ? vehicleInfo : undefined,
            vehicle_no: vehicleCard?.vehicle_info?.vehicle_no ? vehicleCard?.vehicle_info?.vehicle_no : null,
            vehicle_id: vehicleCard?.vehicle_info?.id,
            vehicle_name: vehicleCard?.vehicle_info?.vehicle_name,
            cdate: TransDateTime(new Date()),
            ldate: TransDateTime(new Date()),
            luser: SessionLogin.userName,
            cuser: SessionLogin.userName,
            // date_add: vehicleCard?.date_add ? TransDateTime(vehicleCard?.date_add) : undefined,
            // date_return: vehicleCard?.date_return ? TransDateTime(vehicleCard?.date_return) : undefined,
            // date_cancel: vehicleCard?.date_cancel ? TransDateTime(vehicleCard?.date_cancel) : undefined,
            date_modified: vehicleCard?.date_modified ? TransDateTime(vehicleCard?.date_modified) : undefined,
            apartment_building_id: props.payload?.apartment_building_id,
            // is_cancel_return_card: actionTodo === 1 ? vehicleCard.is_cancel_return_card : false,
        } as QLCCVehicleCardModel;

        qLCClient.qLCCVehicleCardIns({
            ...new QLCCVehicleCardModel(),
            ...newVehicleCard
        } as QLCCVehicleCardModel)
            .then((res: any) => {
                notification(status_message[res.status], res.message)
                if (res.status === 0) {
                    props.onEditSuccess();
                    props.onClose();
                }
            }).catch(err => {
                console.log(err)
            })
    }

    const handleClickAction = (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code) {
            case ACTIONS.SAVE:

                if (props.action === ACTIONS.ADD) {
                    if (!handleCheckRequired()) {
                        notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE['FIELD_REQUIRED'])
                        return;
                    }
                    handleSubmit();

                } else if (props.action === ACTIONS.EDIT) {
                    if (!handleCheckRequired()) {
                        notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE['FIELD_REQUIRED'])
                        return;
                    }
                    handleUpdate();
                }

                break;

            case ACTIONS.CLOSE:

                props.onClose();
                setVehicleCard(pre => ({ ...pre, vehicle_no: undefined } as QLCCVehicleCardModel))

                break;
        }
    }





    const handleChangeSwitch = (e: any) => {
        const { name, checked } = e.target;


        if (props.action !== ACTIONS.VIEW) {

            setVehicleCard(pre => ({
                ...vehicleCard,
                [name]: checked,
            } as QLCCVehicleCardModel));

        }
    }

    const handleChangeInput = (e: any) => {

        const { name, value } = e.target;

        const item = apartmentBuildingOptions.find(p => p.id === value);

        if (item) {
            setApartmentBuilding(item);
            if (item.id !== vehicleInfo?.apartment_building_id) {
                setVehicleInfo({} as QLCCVehicleModel);
                setVehicleCard({
                    ...vehicleCard,
                    vehicle_info: {} as QLCCVehicleModel,
                    [name]: value
                } as QLCCVehicleCardModel)
            }
        }
    };

    const handleCheckVehicleNo = async (vehicle_no?: string) => {
        try {
            if (vehicle_no && vehicle_no?.length > 0) {
                setLoading(true);
                const response = await qlccClient.qLCCVehicleGet({
                    ...new QLCCVehicleModel(),
                    ma_cty: SessionLogin?.ma_cty,
                    vehicle_no: vehicle_no ? vehicle_no : vehicleCard?.vehicle_no,
                    apartment_building_id: props.payload?.apartment_building_id,
                    action_type: "OWNER"
                } as QLCCVehicleModel);
                if (response.length === 1) {
                    if (response[0]?.id) {
                        const tempDuplicateVehicleId = new Set(duplicateVehicleId);

                        // nếu chưa có trong set
                        if (!tempDuplicateVehicleId.has(response[0]?.id)) {
                            // tìm xem có id chưa
                            if (vehicleOptions.find(x => x.id === response[0]?.id)) {

                                // Thêm vào những id bị trùng
                                tempDuplicateVehicleId.add(response[0]?.id)
                                setDuplicateVehicleId(tempDuplicateVehicleId);
                            }
                        }
                        if (!tempDuplicateVehicleId.has(response[0]?.id)) {
                            const tempVehicleOptions = [...vehicleOptions].concat(response)
                            setVehicleOptions(tempVehicleOptions)
                        }


                    }

                    setLoading(false)
                    if (response[0].resident_id) {

                        setVehicleCard(pre => ({
                            ...pre,
                            vehicle_info: response[0],
                            owner_name: response[0].owner_name
                        } as QLCCVehicleCardModel))
                        setVehicleInfo(response[0]);
                        return;
                    } else if (response[0].owner_name) {
                        setVehicleCard(pre => ({
                            ...pre,
                            vehicle_info: response[0],
                            owner_name: response[0].owner_name
                        } as QLCCVehicleCardModel))
                        setVehicleInfo(response[0]);
                        return;
                    }
                    else {
                        clearVehicleInfo()
                        return;
                    }
                }
                else if (response.length <= 0) {
                    notification(
                        TYPE_MESSAGE.WARNING,
                        STATUS_MESSAGE[`VEHICLE_NO_NOT_EXISTS`]
                    );
                    clearVehicleInfo()
                }
                else {
                    notification(
                        TYPE_MESSAGE.WARNING,
                        STATUS_MESSAGE[`VEHICLE_NO_IS_DUPLICATE`]
                    );
                }
                clearVehicleInfo();
                setLoading(false)

            }
        }
        catch (error: any) {
            notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`SERVER_ERROR`]
            );

            setLoading(false)
        }

    }

    const clearVehicleInfo = () => {
        setVehicleCard(pre => ({
            ...pre,
            vehicle_info: undefined,
            owner_name: ""
        } as QLCCVehicleCardModel))
        setVehicleInfo({
            ...new QLCCVehicleModel(),
        } as QLCCVehicleModel)
    }

    // 300 milliseconds là khoảng thời gian debounce, bạn có thể điều chỉnh theo nhu cầu của mình



    const handleDebouncedFilter = async (e: any) => {
        // handleChange(e);

        if (actionTodo && vehicleCardStatusCodeLost.includes(actionTodo)) {
            debouncedHandleChange(e.target.value)
        }
        const { name, value } = e.target;
        setVehicleCard(pre => ({
            ...pre,
            [name]: value
        } as QLCCVehicleCardModel))


    }

    const sendRequest = debounce(async (value: any) => {
        if ((value as string).length > 0) {
            const response = await qlccClient.qLCCVehicleCardGet({
                ...new QLCCVehicleCardModel(),
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: props.payload?.apartment_building_id,
                vehicle_no: value
            } as QLCCVehicleCardModel);


            if (response.length > 0) {
                setVehicleCard(pre => ({
                    ...pre,
                    card_number_old: response[response.length - 1].card_number
                } as QLCCVehicleCardModel))
            } else {
                setVehicleCard(pre => ({
                    ...pre,
                    card_number_old: ""
                } as QLCCVehicleCardModel))
            }

            return response;
        }
        else {
            setVehicleCard(pre => ({ ...pre, card_number_old: "" } as QLCCVehicleCardModel))
            return null;
        }



    }, 500);

    const debouncedHandleChange = React.useCallback((value: string) => sendRequest(value), []);





    return (

        <>

            <Dialog
                // id="sysMenu"
                open={props.open}
                onClose={() => {
                    props.onClose();
                    setVehicleCard(pre => ({ ...pre, vehicle_no: undefined } as QLCCVehicleCardModel))
                }}
                // sx={{
                //     display: "flex",
                //     justifyContent: "center",
                // }}
                PaperProps={{
                    sx: !fullScreen ? {
                        minHeight: "90vh",
                        height: "90vh"
                    } : undefined
                }}
                TransitionComponent={Transition}
                maxWidth="lg"
                fullWidth
                fullScreen={fullScreen}
                className="Asset-control"
            >

                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            {menu?.bar +
                                " " +
                                `${props.action === ACTIONS.VIEW
                                    ? "[Chi tiết]"
                                    : props.action === ACTIONS.EDIT || _id
                                        ? "[Cập nhật]"
                                        : "[Thêm mới]"
                                }`}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1.25 }}>
                            <IconButton
                                edge="end"

                                onClick={() => {
                                    setFullscreen(pre => !pre)
                                }}
                                aria-label="full screen"
                            >
                                {fullScreen ? <FullscreenExitIcon sx={{ color: '#1f2937' }} /> : <FullscreenIcon sx={{ color: '#1f2937' }} />}
                            </IconButton>
                            <IconButton
                                edge="end"
                                onClick={() => {
                                    props.onClose();
                                    setVehicleCard(pre => ({ ...pre, vehicle_no: undefined } as QLCCVehicleCardModel))

                                }}
                                aria-label="close"
                            >
                                <CloseIcon
                                    sx={{ color: '#1f2937' }}
                                />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent>

                    <div className="row pb-2 gutter-1">
                        <div className="col-12 col-md-6">
                            <div className="card">

                                <div className="card-body py-2">
                                    <div className="pb-2" style={{ fontSize: "16px", fontWeight: 700 }}>
                                        Thông tin thẻ xe
                                    </div>
                                    <Grid container spacing={1}>
                                        {/* <Grid item xs={12} md={12} >
                                            <Box className="w-100 d-md-flex justify-content-between">
                                                <InputLabel className="cc-label w-25">Dự án</InputLabel>
                                                <Autocomplete
                                                    id="apartment-building-select"
                                                    sx={{ width: "100%" }}
                                                    options={apartmentBuildingOptions}
                                                    autoHighlight
                                                    getOptionLabel={(option: any) => `${option?.name}`}
                                                    value={
                                                        vehicleCard.apartment_building_id
                                                            ? apartmentBuildingOptions.find(
                                                                (item: any) => item.id === vehicleCard.apartment_building_id
                                                            )
                                                            : null
                                                    }
                                                    onChange={(e, newValue: any) => {
                                                        handleChangeInput({
                                                            target: {
                                                                name: "apartment_building_id",
                                                                value: newValue?.id ?? null,
                                                            },
                                                        });
                                                    }}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={option?.id}>
                                                                {option?.name}
                                                            </li>
                                                        );
                                                    }}

                                                    renderInput={(params) => (
                                                        <TextField


                                                            {...params}

                                                            placeholder='Chọn dự án'
                                                            className="cc-input"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "new-password", // disable autocomplete and autofill
                                                            }}
                                                        />
                                                    )}

                                                />
                                            </Box>
                                        </Grid> */}
                                        <Grid item xs={12} md={12} >

                                        <Box className="w-100 d-md-flex justify-content-between">
                                                <InputLabel required className="cc-label w-25">Loại nghiệp vụ</InputLabel>
                                                <Box className='w-100'>
                                                <DHSAutocompleteFilterListSelect
                                                    table_list="dm_vehicle_card_status"
                                                    value={vehicleCard?.status_code ? Number.parseInt(vehicleCard?.status_code) : undefined}
                                                    onValueChanged={(e, newValue: any) => {
                                                        handleSelectActionTodo({
                                                            target: {
                                                                name: "",
                                                                value: newValue?.code ?? null,
                                                            },
                                                        });

                                                        setVehicleCard(pre => ({
                                                            ...pre,
                                                            status_id: newValue?.id,
                                                            status_code: newValue?.code?.toString(),
                                                            status_name: newValue?.name1,
                                                            card_number_old: vehicleCardStatusCodeLost.includes(Number.parseInt(pre.status_code ?? "0")) ? pre.card_number_old : undefined,
                                                            date_modified: new Date()
                                                        } as QLCCVehicleCardModel))
                                                    }}




                                                />
                                                    {/* <Select
                                                        className="cc-input w-100"
                                                        value={actionTodo}
                                                        onChange={(e) => {
                                                            handleSelect(e);
                                                            
                                                        }}
                                                        
                                                    >
                                                        {

                                                            vehicleCardStatusOptions.length > 0 && (
                                                                vehicleCardStatusOptions.map(todo =>
                                                                (
                                                                    <MenuItem key={todo.id} value={todo.code}>{todo.name1}</MenuItem>
                                                                ))
                                                            )
                                                        }
                                                    </Select> */}
                                                    {/* {
                                                        vehicleCardStatusOptions.length > 0 && (actionTodo === vehicleCardStatusOptions[0].code)
                                                        &&
                                                         <>
                                                            <Box>
                                                                <FormControlLabel
                                                                    control={<Switch
                                                                        name="is_cancel_return_card"
                                                                        onChange={handleChangeSwitch}
                                                                        checked={vehicleCard.is_cancel_return_card || false}
                                                                    />}
                                                                    label="Trả thẻ xe" />
                                                            </Box>
                                                            <Box>
                                                                <FormControlLabel
                                                                    control={<Switch
                                                                        name="is_cancel_card_and_vehicle"
                                                                        onChange={handleChangeSwitch}
                                                                        checked={vehicleCard.is_cancel_card_and_vehicle || false}
                                                                    />}
                                                                    label="Xe không sử dụng" />
                                                            </Box>
                                                        </>
                                                    } */}
                                                    <Box>
                                                        <FormControlLabel
                                                            control={<Switch
                                                                name="is_vip"
                                                                onChange={(e, checked) => {
                                                                    handleChangeSwitch(e);

                                                                    if (checked) {
                                                                        setVehicleCard(pre => ({
                                                                            ...pre,
                                                                            amount: 0
                                                                        } as QLCCVehicleCardModel))
                                                                    }
                                                                    else {
                                                                        setVehicleCard(pre => ({
                                                                            ...pre,
                                                                            amount: sellPrice
                                                                        } as QLCCVehicleCardModel))
                                                                    }
                                                                }}
                                                                checked={vehicleCard.is_vip || false}
                                                            />}
                                                            label="Thẻ VIP" />
                                                    </Box>
                                                </Box>
                                            </Box>

                                        </Grid>

                                        {/* Mã thẻ */}
                                        <Grid item xs={12} md={12} >
                                            <Box className="w-100 d-md-flex justify-content-between">
                                                <InputLabel

                                                    className="cc-label w-25"
                                                    sx={{ fontSize: 14, color: "#333", }}
                                                >
                                                    Mã thẻ
                                                </InputLabel>
                                                <DHSAutoCompleteWithDataGrid
                                                    id="itme-select"
                                                    value={vehicleCard?.idItem ?? null}
                                                    // tabIndex={-1}
                                                    table_name="LstItemOptions"
                                                    // placeholder="Chọn khách hàng"
                                                    // filter={props.payload}
                                                    name="iditem"
                                                    valueExpr={"iditem"}
                                                    options={itemOptions}
                                                    dataGridOnSelectionChanged={(e, newValue: any) => {

                                                        handleChange({
                                                            target: {
                                                                name: "idItem",
                                                                value: newValue?.iditem ? newValue.iditem : null,
                                                            },
                                                        });

                                                        // Nếu đã là thẻ VIP thì amount = 0
                                                        setVehicleCard(pre => ({
                                                            ...pre,
                                                            card_type_id: newValue?.idgroup ?? null,
                                                            amount: vehicleCard?.is_vip ? 0 : (newValue?.selL_PRICE ?? null)
                                                        } as QLCCVehicleCardModel))

                                                        setSellPrice(newValue?.selL_PRICE ?? 0);

                                                    }}
                                                    syncDataGridSelection={function (e): void {

                                                        handleChange({
                                                            target: {
                                                                name: "idItem",
                                                                value: e.value ?? null,
                                                            },
                                                        });

                                                        setVehicleCard(pre => ({
                                                            ...pre,
                                                            card_type_id: e.value ?? null,
                                                            amount: e.value ?? null,
                                                        } as QLCCVehicleCardModel))

                                                        setSellPrice(e.value ?? 0);


                                                    }}
                                                    gridBoxDisplayExpr={function (item: any): string {
                                                        return item && `${item.sku} - ${item.teN_VT}`;
                                                    }}

                                                    readOnly={props.action === ACTIONS.VIEW || props.action === ACTIONS.APPROVED}

                                                />
                                                {/* <Autocomplete
                                                    id="item-select"

                                                    sx={{ width: "100%" }}
                                                    options={itemOptions}
                                                    autoHighlight
                                                    getOptionLabel={(option: any) => option?.ten_vt}
                                                    value={
                                                        vehicleCard?.idItem
                                                            ? itemOptions.find(
                                                                (item: any) => item.iditem === vehicleCard?.idItem
                                                            )
                                                            : null
                                                    }
                                                    onChange={(e, newValue: any) => {
                                                        handleChange({
                                                            target: {
                                                                name: "idItem",
                                                                value: newValue?.iditem ? newValue.iditem : null,
                                                            },
                                                        });




                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            className="cc-input w-100 flex-grow-1 form-control currency-input p-0"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "new-password",
                                                                // type: "search" // disable autocomplete and autofill
                                                            }}
                                                        />
                                                    )}
                                                    readOnly={props.action === ACTIONS.VIEW || props.action === ACTIONS.APPROVED}
                                                /> */}
                                            </Box>
                                        </Grid>

                                        {/* Chọn loại thẻ */}
                                        <Grid item xs={12} md={12} >
                                            <Box className="w-100 d-md-flex justify-content-between">
                                                <InputLabel
                                                    className="cc-label w-25"
                                                    sx={{ fontSize: 14, color: "#333", }}
                                                >
                                                    Loại thẻ
                                                </InputLabel>

                                                <DHSAutocompleteFilterListSelect
                                                    table_list="dm_item_group"
                                                    value={vehicleCard.card_type_id}
                                                    onValueChanged={(e, newValue: any) => {
                                                        handleChange({
                                                            target: {
                                                                name: "card_type_id",
                                                                value: newValue?.IDGroup ? newValue.IDGroup : null,
                                                            },
                                                        });

                                                    }}

                                                    readOnly={props.action === ACTIONS.VIEW}
                                                />
                                            </Box>
                                        </Grid>

                                        {/* Só thẻ cũ */}
                                        <Grid item xs={12} md={12} >
                                            <Box className="w-100 d-md-flex justify-content-between">
                                                <InputLabel required className="cc-label w-25">Số thẻ {vehicleCard?.status_code && vehicleCardStatusCodeLost.includes(Number.parseInt(vehicleCard?.status_code ?? 0)) ? "mới" : ""}</InputLabel>
                                                <TextField
                                                    className="cc-input w-100"
                                                    // placeholder="Mã số thẻ xe"
                                                    value={vehicleCard.card_number}
                                                    name="card_number"
                                                    onChange={handleChange}
                                                // disabled={true}
                                                />
                                            </Box>
                                        </Grid>
                                        {
                                            actionTodo && vehicleCardStatusCodeLost.includes(actionTodo) && <Grid item xs={12} md={12} >
                                                <Box className="w-100 d-md-flex justify-content-between">
                                                    <InputLabel className="cc-label w-25">Số thẻ cũ</InputLabel>
                                                    <TextField
                                                        className="cc-input w-100"
                                                        // placeholder="Mã số thẻ xe"
                                                        value={vehicleCard?.card_number_old}
                                                        name="card_number_old"
                                                        onChange={handleChange}
                                                    // disabled={true}
                                                    />
                                                </Box>
                                            </Grid>
                                        }
                                        {
                                            actionTodo
                                            && (
                                                <Grid item xs={12} md={12} >
                                                    <Box className="d-md-flex justify-content-between">
                                                        <InputLabel className="cc-label w-25">Ngày {vehicleCard?.status_name?.replace(/\d+\.\s|\([^)]+\)/g, '').toLowerCase()}</InputLabel>
                                                        <CTXDate
                                                            className="cc-input w-100"
                                                            value={vehicleCard?.date_modified ?? null}
                                                            // value={actionTodo === '1' ? vehicleCard.date_cancel : actionTodo === '2' ? vehicleCard.date_return : vehicleCard.date_add}
                                                            name={"date_modified"}
                                                            onChange={handleChangeDate}
                                                        // disabled={actionTodo === '5' || actionTodo === '6'}
                                                        />
                                                    </Box>
                                                </Grid>
                                            )
                                        }
                                        {/* Phí thẻ */}
                                        <Grid item xs={12} md={12} >
                                            <Box className="w-100 d-md-flex justify-content-between">
                                                <InputLabel className="cc-label w-25">Phí thẻ</InputLabel>
                                                <NumberBox
                                                    // className="cc-input"
                                                    className="cc-input w-100 flex-grow-1 form-control currency-input p-0 pr-2"

                                                    format="0,###"
                                                    inputAttr={{ 'aria-label': 'Fixed Poing Format', 'style': 'text-align:right' }}
                                                    defaultValue={vehicleCard.amount}
                                                    value={vehicleCard.amount}

                                                    // style={{
                                                    //     textAlign: "right"
                                                    // }}
                                                    onValueChange={(value) => {
                                                        handleChange({
                                                            target: {
                                                                name: "amount",
                                                                value: value
                                                            }
                                                        });


                                                    }}

                                                    readOnly={props.action === ACTIONS.VIEW}

                                                />
                                                {/* <CurrencyTextField
                                    style={{ textAlign: "right" }}
                                    allowDecimals
                                    decimalsLimit={2}
                                    decimalSeparator="."
                                    groupSeparator=","
                                    decimalScale={0}
                                    //placeholder="Dư nợ đầu năm ngoại tệ"
                                    intlConfig={{
                                        locale: 'vi-VN', currency: 'VND'
                                    }}
                                    name=""
                                    onValueChange={handleChangeCurrentInput}
                                    value={vehicleCard.amount}
                                /> */}
                                            </Box>
                                        </Grid>

                                        {/* Só lượng */}
                                        <Grid item xs={12} md={12} >
                                            <Box className="w-100 d-md-flex justify-content-between">
                                                <InputLabel className="cc-label w-25">Số lượng</InputLabel>
                                                <NumberBox
                                                    // className="cc-input"
                                                    className="cc-input w-100 flex-grow-1 form-control currency-input p-0 pr-2"

                                                    format="0,###"
                                                    inputAttr={{ 'aria-label': 'Fixed Poing Format', 'style': 'text-align:right' }}
                                                    defaultValue={vehicleCard.quantity}
                                                    value={vehicleCard.quantity}
                                                    showSpinButtons
                                                    // style={{
                                                    //     textAlign: "right"
                                                    // }}
                                                    onValueChange={(value) => {
                                                        handleChange({
                                                            target: {
                                                                name: "quantity",
                                                                value: value
                                                            }
                                                        });


                                                    }}

                                                    readOnly={props.action === ACTIONS.VIEW}

                                                />

                                            </Box>
                                        </Grid>



                                    </Grid>


                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card">

                                <div className="card-body py-2">
                                    <div className="pb-2" style={{ fontSize: "16px", fontWeight: 700 }}>
                                        Thông tin xe
                                    </div>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={12} >
                                            <Box className="w-100 d-md-flex justify-content-between">
                                                <InputLabel className="cc-label w-25">Biển số xe</InputLabel>
                                                <TextField
                                                    className="cc-input w-100"
                                                    // placeholder="Mã số thẻ xe"
                                                    value={vehicleCard?.vehicle_no ?? undefined}
                                                    name="vehicle_no"
                                                    onChange={(e) => {
                                                        // handleChange(e);

                                                        handleDebouncedFilter(e) // Sẽ chỉnh lại trong tương lai
                                                        setVehicleInfo(pre => ({
                                                            ...pre,
                                                            vehicle_no: e.target.value
                                                        } as QLCCVehicleModel))
                                                    }}
                                                // disabled={true}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12} >
                                            <Box className="w-100 d-md-flex justify-content-between">
                                                <InputLabel className={`${isSmallScreen ? "" : " w-25"} cc-label`}>Khách hàng</InputLabel>
                                                <Box className={`${isSmallScreen ? "" : "w-75 pr-1"} d-flex d-md-block`}>
                                                    <DHSAutoCompleteWithDataGrid
                                                        id="customer-select"
                                                        value={vehicleInfo?.id ?? null}
                                                        tabIndex={-1}
                                                        table_name="QLCCVehicleOptions"
                                                        // placeholder="Chọn khách hàng"
                                                        // filter={props.payload}

                                                        name="id"
                                                        valueExpr={"id"}
                                                        options={vehicleOptions}
                                                        dataGridOnSelectionChanged={(e, newValue: any) => {

                                                            setVehicleInfo(newValue)
                                                            setVehicleCard(pre => ({
                                                                ...pre,
                                                                customer_name: newValue?.owner_name,
                                                                owner_name: newValue?.owner_name,
                                                                vehicle_no: newValue?.vehicle_no,
                                                                vehicle_info: newValue
                                                            } as QLCCVehicleCardModel));


                                                        }}
                                                        syncDataGridSelection={function (e): void {
                                                            if (!e?.value) {

                                                                setVehicleInfo(e.value)
                                                                setVehicleCard(pre => ({
                                                                    ...pre,
                                                                    customer_name: "",
                                                                    owner_name: "",
                                                                    vehicle_no: "",
                                                                    vehicle_info: e.value
                                                                } as QLCCVehicleCardModel));

                                                            }

                                                        }}
                                                        gridBoxDisplayExpr={function (item: any): string {
                                                            return item && `${item.apartment_name} - ${item.owner_name}`;
                                                        }}

                                                        disabled={props.action === ACTIONS.VIEW}
                                                    />
                                                </Box>
                                                <Button variant="contained" className={isSmallScreen ? "mt-1" : "w-25"} onClick={() => handleCheckVehicleNo(vehicleCard?.vehicle_no)}>Kiểm tra</Button>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12} >
                                            <Box className="w-100 d-md-flex justify-content-between">
                                                <InputLabel className="cc-label w-25">Tên chủ cavet</InputLabel>
                                                <TextField
                                                    className="cc-input w-100"
                                                    // placeholder="Mã số thẻ xe"
                                                    value={vehicleCard?.owner_name ?? undefined}
                                                    name="owner_name"
                                                    onChange={handleChange}
                                                // disabled={true}
                                                />
                                            </Box>
                                        </Grid>
                                        {/* <Grid item xs={12} md={12} >
                            <Box className="d-md-flex justify-content-between">
                                <InputLabel className="cc-label w-25">{actionTodo === '1' ? `Ngày hủy` : `Ngày đăng ký`}</InputLabel>
                                <CTXDate
                                    className="cc-input w-100"
                                    value={actionTodo === '1' ? vehicleCard.date_cancel : vehicleCard.ldate}
                                    name={actionTodo === '1' ? `date_cancel` : `ldate`}
                                    onChange={handleChangeDate}
                                />
                            </Box>
                        </Grid> */}
                                        <Grid item xs={12} md={12} >
                                            <InputLabel className="cc-label w-25">Ghi chú</InputLabel>
                                            <Box className="w-100 d-md-flex justify-content-between">

                                                <TextField
                                                    className="cc-input w-100"
                                                    placeholder="Nội dung"
                                                    rows={4}
                                                    multiline
                                                    name="notes"
                                                    value={vehicleCard.notes}
                                                    onChange={handleChange}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Grid container spacing={2}>

                        {/* <Grid item xs={12} md={12} >
                            <Box className="w-100 d-md-flex justify-content-between">
                                <InputLabel className="cc-label w-25">Khách hàng</InputLabel>
                                <TextField
                                    className="cc-input w-100"
                                    placeholder="Khách hàng"
                                    value={vehicleInfo?.owner_name && vehicleInfo?.apartment_name ? `${vehicleInfo?.owner_name} - ${vehicleInfo?.apartment_name}` : null}
                                    onClick={() => isOpenVehicleInfo(true)}
                                />
                            </Box>
                        </Grid> */}

                    </Grid>
                </DialogContent>


                {
                    props.action !== ACTIONS.VIEW
                    && (<>
                        <DialogActions>

                            <DHSDialogAction

                                menu={menu}
                                onClickAction={handleClickAction}
                                action={props.action}
                            />

                        </DialogActions>
                    </>)
                }


                <BlockUI blocking={isLoading} title={""}></BlockUI>
            </Dialog >

            {/* <VehicleInfo
                open={openVehicleInfo}
                setVehicleInfo={(data: QLCCVehicleModel) => setVehicleInfo(data)}
                onClose={() => isOpenVehicleInfo(!openVehicleInfo)}
                setVehicle={(data: QLCCVehicleModel[]) => setVehicleList(data)}
            /> */}

        </>
    );
};

export default AllVehicelCardAction;
