import React from "react";
import JoditEditor from "jodit-react";
import BASE_URL_API from "../../../app/shared/service-proxies/config";
import axios from "axios";

interface IProps {
  onChange?: (htmlString: string) => void;
  value?: string;
  readOnly?: boolean;
  height?: number;
}
const RichTextEditor: React.FC<IProps> = ({ value, onChange, readOnly, height }) => {
  const editor = React.useRef(null);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

  const config: any = {
    
    height: height ? height : 300,
    readonly: readOnly ? readOnly : false,
    toolbar: true,
    uploader: {
      fileUpload: true, // Insert uploaded images as base64 URIs
      url: `${BASE_URL_API}/api/FileManager/PostFile_v3`,
      method: "POST",
      withCredentials: true,
      headers: {
        Accept: "application/json",
        token: SessionLogin.Token
      },
      isSuccess: function (resp: any) {
        return resp.length > 0;
      },
      getMessage: function (resp: any) {

        return resp[0].message;
      },
      processFileName: (key: any, file: File, name: any) => {
        return ["MyFile", file, name];
      },
      process: function (resp: any) {
        return {
          fileName: resp[0].fileName || "",
          path: resp[0].path,
          name: resp[0].name,
          error: resp[0]?.error ?? null,
          msg: resp[0].message
        };
      },
      defaultHandlerSuccess: function (data: any, resp: any) {
        // console.log(data, resp)
        // const field = 'path';
        //     if (data[field] && data[field].length) {
        //       for (let i = 0; i < data[field].length; i += 1) {
        // console.log((this as any).s, "12312");
        (this as any).s.insertImage(BASE_URL_API + data.path);
        //   }
        // }
      },
      error: function (e: any) {
        (this as any).message.message(e.getMessage(), 'error', 4000);
      }
    },
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    defaultActionOnPasteFromWord: 'insert_clear_html',
    commandToHotkeys: {
      removeFormat: ['ctrl+shift+m', 'cmd+shift+m'],
      insertOrderedList: ['ctrl+shift+7', 'cmd+shift+7'],
      insertUnorderedList: ['ctrl+shift+8, cmd+shift+8'],
      selectall: ['ctrl+a', 'cmd+a'],
      bold: ['ctrl+b'],
      indent: ['ctrl+]'],
      outdent: ['ctrl+['],
      italic: ['ctrl+i'],
      openSearchDialog: ['ctrl+f'],
      openReplaceDialog: ['ctrl+r'],
      justifyCenter: ['ctrl+alt+e'],
      justifyLeft: ['ctrl+alt+l'],
      justifyRight: ['ctrl+alt+r']
    },
    redo: 'ctrl+z',
    undo: 'ctrl+y,ctrl+shift+z',
    toolbarSticky: true,
    toolbarDisableStickyForMobile: false,
    toolbarStickyOffset: 100,
  } as any

  return React.useMemo(() => {

    return (
      <JoditEditor
        ref={editor}
        value={value ? value : ''}
        config={config}
        onChange={(newContent) => onChange && onChange(newContent)}
      
      />
    );
  }, [value])

};

export default RichTextEditor;
