import React, { ReactElement, useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import {
  Scheduler,
  Appointments,
  AppointmentTooltip,
  MonthView,
  DateNavigator,
  TodayButton,
  Resources,
  EditRecurrenceMenu,
  AppointmentForm,
} from "@devexpress/dx-react-scheduler-material-ui";
import { renderData } from "./employee-timesheetData";

import {
  DragDropProvider,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import { EditingState, ViewState } from "@devexpress/dx-react-scheduler";
import {
  HRApproveStatus,
  HRClient,
  HRTimeAttendanceOfMonth,
  HRWorkShiftCalendar,
  HRWorkShiftOfEmployeeModel,
  LeaveForm,
  LstHRLeaveType,
  OvertimeForm,
} from "../../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../shared/service-proxies/config";
import { AppSession } from "../../../../shared/app-session/app-session";
import "./employee-timesheet.css";
import { pink, purple, teal, amber, deepOrange } from "@mui/material/colors";
import { Card } from "@mui/material";
import IconTask from "../../../../../Asset/image/icons8-task-64.png";
import IconComingSoon from "../../../../../Asset/image/icons8-coming-soon-64.png";
import IconAttendance from "../../../../../Asset/image/icons8-immigration-64.png";
// import 'jquery.ui.datetimepicker'j
interface IProps {
  label: string;
  disabled: boolean;
  value?: any;
  className: string;
  type: string;
  name: string;
  onChange: (v: any) => void;
}
interface IData {
  id: number;
  roomId: any;
  members: any;
  rRule: string;
  title: string;
  startDate: any;
  endDate: any;
}
let appSession: AppSession;
const SchedulerEmployeeTimesheet = (props: IProps) => {
  const clientHR = new HRClient(appSession, BASE_URL_API);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [data, setData] = useState<IData[]>([]);
  const [workDay, setWorkDay] = useState<number>(0);
  const [leaveDay, setLeaveDay] = useState<number>(0);
  const [resources, setResources] = useState<any[]>([]);
  const pad = "00";
  let datas: IData[] = [];
  const defaultCurrentDate =
    new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-01";
  // const resources: any = [
  //   {
  //     fieldName: "roomId",
  //     title: "Room",
  //     instances: [
  //       {
  //         text: "Nghỉ phép",
  //         id: 1,
  //         color: amber,
  //       },
  //       {
  //         text: "Nghỉ không lương",
  //         id: 2,
  //         color: pink,
  //       },
  //       {
  //         text: "Nghỉ BHXH",
  //         id: 3,
  //         color: purple,
  //       },
  //       {
  //         text: "Nghỉ thai sản",
  //         id: 4,
  //         color: deepOrange,
  //       },
  //       {
  //         text: "Nghỉ dưỡng sức",
  //         id: 5,
  //         color: teal,
  //       },
  //       {
  //         text: "Nghỉ ốm có lương",
  //         id: 6,
  //         color: purple,
  //       },
  //       {
  //         text: "Nghỉ ốm không lương",
  //         id: 7,
  //         color: deepOrange,
  //       },
  //       {
  //         text: "Nghỉ tai nạn lao động",
  //         id: 8,
  //         color: teal,
  //       },
  //     ],
  //   },
  //   {
  //     fieldName: "members",
  //     title: "Members",
  //     instances: [
  //       // {
  //       //     text: 'Andrew Glover',
  //       //     id: 1,
  //       //     color: '#7E57C2',
  //       // }, {
  //       //     text: 'Arnie Schwartz',
  //       //     id: 2,
  //       //     color: '#FF7043',
  //       // }, {
  //       //     text: 'John Heart',
  //       //     id: 3,
  //       //     color: '#E91E63',
  //       // }, {
  //       //     text: 'Taylor Riley',
  //       //     id: 4,
  //       //     color: '#E91E63',
  //       // }, {
  //       //     text: 'Brad Farkus',
  //       //     id: 5,
  //       //     color: '#AB47BC',
  //       // }, {
  //       //     text: 'Arthur Miller',
  //       //     id: 6,
  //       //     color: '#FFA726',
  //       // },
  //     ],
  //     allowMultiple: true,
  //   },
  // ];
  const Padder = (len: any, pad: any) => {
    if (len === undefined) {
      len = 1;
    } else if (pad === undefined) {
      pad = '0';
    }

    var pads = '';
    while (pads.length < len) {
      pads += pad;
    }

    pad = function (what: any) {
      var s = what.toString();
      return pads.substring(0, pads.length - s.length) + s;
    };
  }
  React.useEffect(() => {
    var today = new Date();
    if (clientHR) {
      clientHR.hRApproveStatusGet({ ...new HRApproveStatus(), ma_cty: SessionLogin.ma_cty } as HRApproveStatus).then(res => {
        let arrT: any[] = [];
        var id = 1;
        res.forEach(element => {
          arrT.push({
            id: element.id, text: element.name, color: element.color
          })
          id++;
        });
        setResources(
          [{
            fieldName: "roomId",
            title: "Room",
            instances: arrT
          }]
        )
      })
      let hRTimeAttendanceOfMonth = new HRTimeAttendanceOfMonth();
      hRTimeAttendanceOfMonth.ma_cty = SessionLogin.ma_cty;
      hRTimeAttendanceOfMonth.month = today.getMonth() + 1;
      hRTimeAttendanceOfMonth.year = today.getFullYear();
      hRTimeAttendanceOfMonth.employee_id = SessionLogin.employeeCode; //SessionLogin.userName
      hRTimeAttendanceOfMonth.type_action = 'ONEMONTH';
      clientHR
        ?.listTimeAttendanceOfMonth(hRTimeAttendanceOfMonth)
        .then((res2) => {

          let roomid = 0;
          roomid++;
          let id = 0;
          if (roomid > 5) roomid = 1;
          clientHR.hROnLeaveGet({ ...new LeaveForm(), sender_code: SessionLogin.employeeCode, ma_cty: SessionLogin.ma_cty, receiver_code: SessionLogin.employeeCode } as LeaveForm).then((respond) => {
            res2.map(data => {
              id++;
              if (!data.date) data.date = new Date();
              datas.push({
                id: id,
                title: data?.title ? (data?.title) : '',
                roomId: '',
                members: [],
                startDate: data.date && data.date ? (new Date(data.date?.getFullYear(), data.date?.getMonth(), data.date?.getDate(), 8, 1)) : new Date(),
                endDate: data.date && data.date ? (new Date(data.date?.getFullYear(), data.date?.getMonth(), data.date?.getDate(), 8, 2)) : new Date(),
                rRule: "FREQ=WEEKLY;BYDAY=MO,TH;COUNT=10",
              });
            })

            if (respond) {
              respond.map(data => {
                datas.push({
                  id: datas.length * 100,
                  title: (data?.type_name ? (data?.type_name) : '') + ' (' + data.approve_status_name + ')',
                  roomId: data?.approve_status ? data?.approve_status : '',
                  members: [],
                  startDate: data.fromdate && data.fromdate ? (new Date(data.fromdate?.getFullYear(), data.fromdate?.getMonth(), data.fromdate?.getDate(), 23, 58)) : new Date(),
                  endDate: data.todate && data.todate ? (new Date(data.todate?.getFullYear(), data.todate?.getMonth(), data.todate?.getDate(), 23, 59)) : new Date(),
                  rRule: "FREQ=WEEKLY;BYDAY=MO,TH;COUNT=10",
                });
              })
            }
            let t = renderData(datas);
            setData(t)
            clientHR
              .hROvertimeGet({
                ...new OvertimeForm(),
                ma_cty: SessionLogin.ma_cty,
                sender_code: SessionLogin.employeeCode,
                fromtime: new Date(today.getFullYear(), today.getMonth(), 15)
              } as OvertimeForm)
              .then((respond) => {
                if (respond) {
                  respond.map(data => {
                    datas.push({
                      id: datas.length * 100,
                      title: 'Tăng ca (' + data.approve_status_name + ')',
                      roomId: data.approve_status,
                      members: [],
                      startDate: data.fromtime && data.fromtime ? (new Date(data.fromtime?.getFullYear(), data.fromtime?.getMonth(), data.fromtime?.getDate(), 23, 58)) : new Date(),
                      endDate: data.totime && data.totime ? (new Date(data.totime?.getFullYear(), data.totime?.getMonth(), data.totime?.getDate(), 23, 59)) : new Date(),
                      rRule: "FREQ=WEEKLY;BYDAY=MO,TH;COUNT=10",
                    });
                  })
                }
                let t = renderData(datas);
                setData(t)
              });
          });

        });

      // clientHR
      //   .listTimeAttendanceOfMonth({
      //     ...new HRTimeAttendanceOfMonth(),
      //     ma_cty: SessionLogin.ma_cty,
      //     month: today.getMonth() + 1,
      //     year: today.getFullYear(),
      //     employee_id: SessionLogin.employeeCode,
      //   } as HRTimeAttendanceOfMonth)
      //   .then((response) => {
      //     response.forEach((item) => {
      //       setWorkDay((workDay) => {
      //         if (item.ngay_cong) {
      //           return workDay + item?.ngay_cong;
      //         }
      //         return workDay;
      //       });
      //     });
      //   });
      // clientHR
      //   .hROnLeaveGet({
      //     ...new LeaveForm(),
      //     sender_name: SessionLogin.userName,
      //   } as LeaveForm)
      //   .then((respond) => {
      //     respond.map(data => {
      //       datas.push({
      //         id: datas.length * 100,
      //         title: data?.text ? (data?.text) : '',
      //         roomId: data.type,
      //         members: [],
      //         startDate: data.fromdate && data.fromdate ? (new Date(data.fromdate?.getFullYear(), data.fromdate?.getMonth(), data.fromdate?.getDate(), 8, 1)) : new Date(),
      //         endDate: data.todate && data.todate ? (new Date(data.todate?.getFullYear(), data.todate?.getMonth(), data.todate?.getDate(), 8, 2)) : new Date(),
      //         rRule: "FREQ=WEEKLY;BYDAY=MO,TH;COUNT=10",
      //       });
      //     })
      //     let t = renderData(datas);
      //     setData(t);
      //   }
      //   );
    }
  }, []);
  const commitChanges = (changed: any) => {
    let data_edit = data.map((appointment) =>
      changed[appointment.id]
        ? { ...appointment, ...changed[appointment.id] }
        : appointment
    );
    setData(data_edit);
  };
  return (
    <Paper>
      <div className="row card-container">
        <div className="col-md-3 card-body-timesheet">
          <Card className="card-item">
            <div>Tổng công hưởng lương</div>
            <div className="d-flex align-items-center">
              <div className="col-9 value">{workDay}</div>
              <div className="col-3">
                <img src={IconTask} alt="WorkDay" />
              </div>
            </div>
          </Card>
        </div>
        <div className="col-md-3 card-body-timesheet">
          <Card className="card-item">
            <div>Đang phát triển</div>
            <div className="d-flex align-items-center">
              <div className="col-9 value"></div>
              <div className="col-3">
                <img src={IconComingSoon} alt="Coming Soon" />
              </div>
            </div>
          </Card>
        </div>
        <div className="col-md-3 card-body-timesheet">
          <Card className="card-item">
            <div>Đang phát triển</div>
            <div className="d-flex align-items-center">
              <div className="col-9 value"></div>
              <div className="col-3">
                <img src={IconComingSoon} alt="Coming Soon" />
              </div>
            </div>
          </Card>
        </div>
        <div className="col-md-3 card-body-timesheet">
          <Card className="card-item">
            <div>Số ngày nghỉ phép</div>
            <div className="d-flex align-items-center">
              <div className="col-9 value">{leaveDay}</div>
              <div className="col-3">
                <img src={IconAttendance} alt="On leave" />
              </div>
            </div>
          </Card>
        </div>
      </div>
      {data.length > 0 ? (
        <Scheduler data={data}>
          <EditingState onCommitChanges={commitChanges} />
          <EditRecurrenceMenu />
          <ViewState defaultCurrentDate={defaultCurrentDate} />
          <MonthView />
          <Toolbar />
          <Appointments />
          <AppointmentTooltip showOpenButton />
          <AppointmentForm />
          <DateNavigator />
          <TodayButton />
          <Resources data={resources} mainResourceName="roomId" />
          <DragDropProvider />
        </Scheduler>
      ) : (
        <></>
      )}
    </Paper>
  );
};
export default SchedulerEmployeeTimesheet;
