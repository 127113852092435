import React, { useCallback, useMemo } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Box,
    Paper,
    InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    HomeClient,
    QLCCClient,
    QLCCTaskModel,
    QLCCTaskDetailModel,
    SysActionType,
    LstDepartment,
    SysModel,
    ParaObjTableList,
    QLCCTaskStatusModel,
    QLCCTaskGroupModel,
    QLCCTaskTypeModel,
    QLCCTaskGroupCycleModel,
    UserInfoModel,
    HomePageClient,
    UserInfoFilterModel,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import { Notification } from "../../../../components/layouts";
import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { EventInfo } from "devextreme/events";
import DataGrid from 'devextreme-react/data-grid';
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { MESSAGE_TYPE, STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import { getIndexRowSelected, getKeyRowSelected } from "../../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import notification from "../../../../common/notification/notification";
import DHSSelectBox from "../../../../components/DHS/DHS_SelectBox";
import DHSTextBox from "../../../../components/DHS/DHS_TextBox";
import TaskChild, { NEWID } from "./task-child/task-child";
import { TransDateTime } from "../../../../components/utils/date.util";

import './index.css'
import { httpPost } from "../../../../common/httpService";

import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import DHSToolbarRoleActionType2 from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRoleChild";
import DHSTagBox from "../../../../components/DHS/DHS_TagBox";

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    data?: any;
    onClose: () => void;
    onEditSuccess: (is_success: boolean) => void;
    apartmentBuildingId?: string | undefined;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface IActions {
    open: boolean;
    id: string;
    actions: string;
    data: QLCCTaskDetailModel,
    start_time: Date;
}

const defaultAction = {
    open: false,
    id: "",
    actions: "",
    data: {} as QLCCTaskDetailModel,
    start_time: new Date(),
};


// hàm get date min or max in lst task detail
const GET_DATE = (data: QLCCTaskDetailModel[], EQUAL: number) => {
    /**
     * EQUAL = 0 EQUAL 
     * EQUAL < LESS THAN
     * EQUAL > 0 MORE THAN
     */

    if (data.length === 1) {
        const item = data[0];
        if (EQUAL > 0) {
            return new Date(item.end_time + '');
        } else if (EQUAL < 0) {
            return new Date(item.start_time + '');
        }
    }
    else if (data.length > 1) {
        if (EQUAL > 0) {
            const d: any = data.flatMap(item => item.end_time)
            const maxDate = d.reduce((a: Date, b: Date) => a > b ? a : b)
            return new Date(maxDate.toString());
        } else if (EQUAL < 0) {
            const d: any = data.flatMap(item => item.start_time)
            const minDate = d.reduce((a: Date, b: Date) => a < b ? a : b)
            return new Date(minDate.toString());
        }
    }
    return new Date();
}

var appSession: AppSession;
var htmlString: string;
const TYPE_CODE_TASK_SCRIPT: string = "2";
const TaskScriptAction = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    const qLCCHome = new HomeClient(appSession, BASE_URL_API);
    const qlccHomePage = new HomePageClient(appSession, BASE_URL_API);

    const [data, setData] = React.useState<QLCCTaskModel>({} as QLCCTaskModel);
    const [dataDetails, setDataDetails] = React.useState<QLCCTaskDetailModel[]>([] as QLCCTaskDetailModel[]);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [departments, setDepartments] = React.useState<LstDepartment[]>([]);
    const [actionPopup, setActionPopup] = React.useState<IActions>({ ...defaultAction });
    const [isFull, setIsFull] = React.useState<boolean>(true);
    const [statusList, setStatusList] = React.useState<QLCCTaskStatusModel[]>([]);
    const [Groups, setGroups] = React.useState<QLCCTaskGroupModel[]>([]);
    const [Types, setTypes] = React.useState<QLCCTaskTypeModel[]>([]);
    const [Cycle, setCycle] = React.useState<QLCCTaskGroupCycleModel[]>([])

    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [follower, setFollower] = React.useState<UserInfoModel[]>([]);
    const disable = props.action === ACTIONS.VIEW ? true : false;
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const [newContent, setNewContext] = React.useState<string>();


    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const body = {
                    ... new ParaObjTableList(),
                    ma_cty: SessionLogin.ma_cty,
                    tablelist: 'dm_bp'
                } as ParaObjTableList

                httpPost<string>('/api/Common/LoadDatasourceBindControlByApartmentBuilding', {
                    ma_cty: SessionLogin?.ma_cty,
                    tablelist: body?.tablelist,
                    apartment_building_id: apartment_building_id
                })
                    .then(res => {
                        // console.log(JSON.parse(res.data))
                        const serviceGroups: any = JSON.parse(res.data)
                        if (serviceGroups) {
                            setDepartments([...serviceGroups?.Table1]);
                        }
                    })
                    .catch((ex) => {
                        console.log(ex)
                    })

                // httpPost<string>('api/Common/LoadFilterList', body)
                //     .then(result => {
                //         const deapartmentData: LstDepartment[] = JSON.parse(result.data);
                //         setDepartments([...deapartmentData]);
                //     });
            } catch (error) {
                setDepartments([]);
            } finally {
                setLoading(false);
            }
        })();


        (async () => {
            const ResType = await qLCClient.qLCCTaskTypeGet({
                ...new QLCCTaskTypeModel(),
                ma_cty: SessionLogin.ma_cty,
                apartment_building_id: apartment_building_id
            } as QLCCTaskTypeModel);

            const ResGroup = await qLCClient.qLCCTaskGroupGet({
                ...new QLCCTaskGroupModel(),
                ma_cty: SessionLogin.ma_cty,
                apartment_building_id: apartment_building_id
            } as QLCCTaskGroupModel);

            const ResStatus = await qLCClient.qLCCTaskStatusGet({
                ...new QLCCTaskStatusModel(),
                ma_cty: SessionLogin.ma_cty,
                apartment_building_id: apartment_building_id
            } as QLCCTaskStatusModel);

            const ResCycle = await qLCClient.qLCCTaskGroupCycleGet({
                ... new QLCCTaskGroupCycleModel(),
                ma_cty: SessionLogin.ma_cty,
                apartment_building_id: apartment_building_id,
                action_type: "GET"
            } as QLCCTaskGroupCycleModel)

            // Thông tin người theo dỗi của một dự án
            const ResFollowInApartmentBuilding = await qlccHomePage.userInfoWebFilterAdmin({
                ... new UserInfoFilterModel(),
                ma_cty: SessionLogin.ma_cty,
                // username: SessionLogin.userName,
                apartment_building_id: apartment_building_id,
                // action_type: "GET_USER_MANAGER"
            } as UserInfoFilterModel)

            setTypes([...ResType]);
            setGroups([...ResGroup]);
            setStatusList([...ResStatus]);
            setCycle([...ResCycle])
            if (ResFollowInApartmentBuilding) {
                setFollower([...ResFollowInApartmentBuilding]);
            }
        })();

    }, [])


    React.useEffect(() => {
        if (props.open) {
            if (props.action === ACTIONS.ADD) {
                const obj = {
                    ... new QLCCTaskModel(),
                    ma_cty: SessionLogin.ma_cty,
                    cuser: SessionLogin.userName,
                    cdate: new Date(),
                    ldate: new Date(),
                    start_time: new Date(),
                    end_time: new Date(new Date().getTime() + 60 * 1000),
                    luser: SessionLogin.userName,
                    sender: SessionLogin.userName,
                    percent: 0,
                    is_done: false,
                    type_code: TYPE_CODE_TASK_SCRIPT
                } as QLCCTaskModel
                setId(undefined);
                setDataDetails([] as QLCCTaskDetailModel[]);
                setData({ ...obj } as QLCCTaskModel);
                // htmlString = '';
            } else {
                setId(props.itemSelected);
                qLCClient
                    .qLCCTaskGet({
                        ... new QLCCTaskModel(),
                        ...props.data,
                        ma_cty: SessionLogin.ma_cty,
                        cuser: SessionLogin.userName,
                        luser: SessionLogin.userName,
                        username_login: SessionLogin.userName,
                        start_time: undefined,
                        end_time: undefined,
                        apartment_building_id: apartment_building_id
                    } as QLCCTaskModel)
                    .then((res) => {
                        if (res[0]) {
                            setData({
                                ...res[0],
                                ldate: new Date(),
                                luser: SessionLogin.userName,
                                type_code: TYPE_CODE_TASK_SCRIPT,
                            } as QLCCTaskModel)

                            if (res[0].qlccTaskDetails) {
                                setDataDetails(res[0].qlccTaskDetails);

                            } else {
                                setDataDetails([] as QLCCTaskDetailModel[])
                            }
                        }
                    })
            }
        } else {
            setNewContext('');
            setData(new QLCCTaskModel());
            setDataDetails([] as QLCCTaskDetailModel[])
        }
    }, [props.itemSelected, props.action]);


    React.useEffect(() => {
        debugger
        if (data && data.description && props.open) {
            setNewContext(data.description);
        }
    }, [data.description, props.open])

    React.useEffect(() => {
        if (actionPopup.open) {
            if (dataDetails.length > 0) {
                setData({
                    ...data,
                    start_time: GET_DATE(dataDetails, -1),
                    end_time: GET_DATE(dataDetails, 1)
                } as QLCCTaskModel)
            }
        }
    }, [actionPopup.open]);

    React.useEffect(() => {
        if (dataDetails.length > 0) {
            const dataDetailsLength = dataDetails.length;
            const dataDetailsDone = dataDetails.filter(item => item.is_done === true).length || 0;
            const percent = dataDetailsLength !== 0 ? dataDetailsDone / dataDetailsLength * 100 : 0;
            setData({
                ...data,
                percent: Number(percent.toFixed(2)),
                is_done: dataDetailsDone === dataDetailsLength
            } as QLCCTaskModel)
        }
    }, [dataDetails]);

    const handleChangeInput = useCallback(async (e: any) => {
        const { name, value } = e.target;
        if (!data.type_code) debugger
        const DONE_PERCENT = 100;
        const DOING_PERCENT = 0;
        if (name === "percent") {
            switch (e) {
                case DOING_PERCENT:
                    setData({
                        ...data,
                        is_done: false,
                    } as QLCCTaskDetailModel);
                    break;
                case DONE_PERCENT:
                    setData({
                        ...data,
                        is_done: true,
                    } as QLCCTaskDetailModel);
                    break;
            }
        } else if (name === 'is_done') {
            if (value) {
                setData({
                    ...data,
                    percent: 100
                } as QLCCTaskDetailModel);
            } else {
                setData({
                    ...data,
                    percent: 0
                } as QLCCTaskDetailModel);
            }
        } else {
            setData({ ...data, [name]: value } as QLCCTaskModel);
        }
    }, [data]);

    const handleChangeDate = (e: any) => {
        console.log(e)
        const { name, value } = e;
        setData({ ...data, [name]: new Date(value) } as QLCCTaskModel);
    }

    const checkAction = () => {
        if (props.action === ACTIONS.VIEW) {
            Notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE[`UNAVAILABLE_FEATURE`]);
            return false;
        }
        return true;
    }

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>(-1);
    const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>();

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {

                const index = e.component.getRowIndexByKey(keys[0]);

                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
            });
        }
    };

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {

        const ItemChoose = dataDetails.find(item => item.id === selectedItemKeys);

        switch (action?.action_code) {
            case ACTIONS.INSERT:
                if (checkAction()) {
                    if (dataGridRef.current) {
                        try {
                            const key = await getKeyRowSelected(dataGridRef);
                            const index = await getIndexRowSelected(dataGridRef);
                            if (dataGridRef.current && key && index >= 0) {
                                const detailSelect = dataDetails[selectedRowIndex];
                                setActionPopup({
                                    ...actionPopup,
                                    open: true,
                                    actions: action?.action_code,
                                    start_time: GET_DATE([detailSelect], -1) || new Date()
                                })
                            } else {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                );
                            }
                        } catch (error) {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    }
                }
                break;
            case ACTIONS.ADD:
                if (checkAction()) {
                    setActionPopup({
                        ...actionPopup,
                        open: true,
                        actions: action?.action_code,
                        start_time: GET_DATE(dataDetails, 1) || new Date()
                    })
                }
                break;
            case ACTIONS.DELETE:
                if (checkAction()) {

                    if (dataGridRef.current) {
                        try {
                            const key = await getKeyRowSelected(dataGridRef);
                            const index = await getIndexRowSelected(dataGridRef);
                            if (dataGridRef.current && key && index >= 0) {
                                const newDatas = dataDetails;
                                newDatas.splice(index, 1)
                                setDataDetails([...newDatas]);
                                console.log(newDatas)
                            } else {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                );
                            }
                        } catch (error) {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    }
                }
                break;
            case ACTIONS.COPY:
                if (checkAction()) {
                    if (dataGridRef.current) {
                        try {
                            const key = await getKeyRowSelected(dataGridRef);
                            const index = await getIndexRowSelected(dataGridRef);
                            if (dataGridRef.current && key && index >= 0) {
                                setActionPopup({
                                    ...actionPopup,
                                    open: true,
                                    actions: action?.action_code,
                                    data: ItemChoose || {} as QLCCTaskDetailModel,
                                    start_time: GET_DATE(dataDetails, 1) || new Date()
                                })
                            } else {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                );
                            }
                        } catch (error) {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    }
                }
                break;
            case ACTIONS.VIEW:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setActionPopup({
                                ...actionPopup,
                                open: true,
                                actions: action?.action_code,
                                data: ItemChoose || {} as QLCCTaskDetailModel
                            })
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.EDIT:
                if (checkAction()) {
                    if (dataGridRef.current) {
                        try {
                            const key = await getKeyRowSelected(dataGridRef);
                            const index = await getIndexRowSelected(dataGridRef);
                            if (dataGridRef.current && key && index >= 0) {
                                setActionPopup({
                                    ...actionPopup,
                                    open: true,
                                    actions: action?.action_code,
                                    data: ItemChoose || {} as QLCCTaskDetailModel
                                })
                            } else {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                );
                            }
                        } catch (error) {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    }
                }
                break;
            case ACTIONS.SAVE:
            case ACTIONS.CLOSE:
            case ACTIONS.SEARCH:
        }
    }

    const _validate = () => {
        if (!data.cycle_id) {
            return window.confirm('Cảnh báo chưa có thông tin chu kỳ công việc. Tiếp tục?');
        }

        return true;
    }

    const handleSubmit = async () => {
        if (_validate()) {

            var cycleItem = { ...new QLCCTaskGroupCycleModel() };

            if (data.cycle_id) {
                const tmpCylce = Cycle.find(item => item.id === data.cycle_id);
                if (tmpCylce) cycleItem = tmpCylce;
            }

            const TranslateTime: QLCCTaskDetailModel[] = [];
            await dataDetails.map(item => {
                TranslateTime.push({
                    ...item,
                    start_time: TransDateTime(item.start_time || new Date()),
                    end_time: TransDateTime(item.end_time || new Date()),
                } as QLCCTaskDetailModel);
            })

            debugger
            switch (props.action) {
                case ACTIONS.ADD:
                    const result: SysModel = await qLCClient.qLCCTaskIns({
                        ...new QLCCTaskModel(),
                        ...data,
                        description: newContent,
                        qlccTaskDetails: [...TranslateTime],
                        start_time: TransDateTime(data.start_time || new Date()),
                        end_time: TransDateTime(data.end_time || new Date()),
                        ldate: TransDateTime(new Date()),
                        cdate: TransDateTime(data.cdate || new Date),
                        cycle_code: cycleItem && cycleItem.code ? cycleItem.code : undefined,
                        cycle_name: cycleItem && cycleItem.name ? cycleItem.name : undefined,
                        name: data.title ? data.title : undefined,
                        ma_cty: SessionLogin.ma_cty,
                        apartment_building_id: apartment_building_id
                    } as QLCCTaskModel);

                    if (result) {
                        notification(MESSAGE_TYPE[result.status ?? 0], result.message);
                        if (result.status === 0) {
                            props.onEditSuccess(true);
                            props.onClose();
                        }
                    }
                    break;
                case ACTIONS.EDIT:
                    const resultUpd = await qLCClient.qLCCTaskUpd({
                        ...new QLCCTaskModel(),
                        ...props.data,
                        ...data,
                        description: newContent,
                        qlccTaskDetails: [...TranslateTime],
                        ldate: TransDateTime(new Date()),
                        cdate: TransDateTime(data.cdate || new Date),
                        cycle_code: cycleItem && cycleItem.code ? cycleItem.code : undefined,
                        cycle_name: cycleItem && cycleItem.name ? cycleItem.name : undefined,
                        name: data.title ? data.title : undefined,
                        username_login: SessionLogin.userName,
                        ma_cty: SessionLogin.ma_cty,
                        apartment_building_id: apartment_building_id,
                        ngay_ct: TransDateTime(data.ngay_ct),
                    } as QLCCTaskModel)

                    if (resultUpd) {
                        notification(MESSAGE_TYPE[resultUpd.status ?? 0], resultUpd.message);
                        if (resultUpd.status === 0) {
                            props.onEditSuccess(true);
                            props.onClose();
                        }
                    }
                    break;
            }
        }
    }

    const handleTadChild = (e: any, actions: string) => {

        const newItem = {
            ...e,
            id: NEWID(),
        } as QLCCTaskDetailModel

        switch (actions.toLocaleUpperCase()) {
            case ACTIONS.ADD:
            case ACTIONS.COPY:
                setDataDetails([...dataDetails, newItem])
                break;
            case ACTIONS.EDIT:
                const dataEdit = dataDetails;
                const newData = {
                    ...dataEdit[selectedRowIndex],
                    ...newItem
                } as QLCCTaskDetailModel;
                const NUMBER_DELETE = 1;
                dataEdit.splice(selectedRowIndex, NUMBER_DELETE, newData)
                setDataDetails([...dataEdit]);
                break;
            case ACTIONS.INSERT:
                const data = newItem;
                const INDEX = selectedRowIndex;
                const dataInsert = dataDetails;
                const NUMBER_DELETE_1 = 0;

                dataInsert.splice(INDEX, NUMBER_DELETE_1, data);
                setDataDetails([...dataInsert]);
                break;
        }
    }

    const handleChangeRichText = (newContent: string) => {
        htmlString = newContent;
    }


    // TODO: Người theo dõi
    const handleChangeFollowers = async (e: any) => {
        const { value } = e.target;
        setData({
            ...data,
            follower: value.join(';')
        } as QLCCTaskModel)
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            className="d-flex justify-content-center"
            TransitionComponent={Transition}
            maxWidth="xl"
            fullWidth
            fullScreen={isFull}
            keepMounted
        >
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === ACTIONS.EDIT || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        className="mr-2"
                        edge="end"
                        color="info"
                        onClick={() => setIsFull(pre => !pre)}
                        aria-label="close"
                    >
                        {isFull ? <CloseFullscreenIcon sx={{ color: '#1f2937' }} /> : <FullscreenIcon sx={{ color: '#1f2937' }} />}

                    </IconButton>
                    <IconButton
                        edge="end"
                        color="error"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon sx={{ color: '#1f2937' }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent className='task-DialogContent'>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper
                            variant="outlined"
                            sx={{
                                padding: "13px 10px"
                            }}>
                            <InputLabel className="text-center text-uppercase" sx={{ fontSize: 14, color: '#333', fontWeight: "bold" }}>Thông tin</InputLabel>
                            <Box className="box-around">
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tiêu đề</InputLabel>
                                <DHSTextBox
                                    value={data?.title}
                                    name='title'
                                    onChange={handleChangeInput}
                                    disabled={disable}
                                />
                            </Box>
                            {/* <Box className="box-around">
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tên công việc</InputLabel>
                                <DHSTextBox
                                    value={data?.name}
                                    name='name'
                                    onChange={handleChangeInput}
                                />
                            </Box> */}
                            <Box className="box-around">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Nhóm công việc</InputLabel>
                                        <DHSSelectBox
                                            dataSource={Groups}
                                            name="group_id"
                                            onValueChanged={handleChangeInput}
                                            displayExpr="name"
                                            searchExpr="name"
                                            valueExpr="id"
                                            value={data?.group_id}
                                            disabled={disable}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Chu kỳ công việc</InputLabel>
                                        <DHSSelectBox
                                            dataSource={Cycle}
                                            name="cycle_id"
                                            onValueChanged={handleChangeInput}
                                            displayExpr="name"
                                            searchExpr="name"
                                            valueExpr="id"
                                            value={data?.cycle_id}
                                            disabled={disable}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Theo dõi</InputLabel>
                                        <DHSTagBox
                                            dataSource={follower}
                                            name="follower"
                                            onValueChange={handleChangeFollowers}
                                            displayExpr="fullname"
                                            searchExpr="fullname"
                                            valueExpr="username"
                                            // defaultValue={data.follower ? data.follower.split(';') : []}
                                            value={data.follower ? data.follower.split(';') : []}
                                            disabled={disable}
                                            multiline={true}
                                            maxLength={500}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Bộ phận</InputLabel>
                                        <DHSSelectBox
                                            dataSource={departments}
                                            name="department_id"
                                            onValueChanged={handleChangeInput}
                                            displayExpr="ten_bp"
                                            searchExpr="ten_bp"
                                            valueExpr="ma_bp"
                                            value={data?.department_id}
                                            disabled={disable}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className="box-around">
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mô tả</InputLabel>

                                {/* {props.action === ACTIONS.VIEW ? <>
                                    <div dangerouslySetInnerHTML={{ __html: htmlString }} className='task-description' />
                                </> : <>
                                    <RichTextEditor
                                        value={htmlString}
                                        height={350}
                                        onChange={(newContent) => handleChangeRichText(newContent)}
                                    />
                                </>} */}

                                {/* <RichTextEditor
                                    value={htmlString}
                                    onChange={(newContent) => handleChangeRichText(newContent)}
                                // readOnly={disable}
                                /> */}
                                {
                                    useMemo(() => {
                                        return <>
                                            <RichTextEditor
                                                value={newContent}
                                                onChange={setNewContext}
                                                // height={650}
                                                readOnly={props.action === ACTIONS.VIEW}
                                            />
                                        </>
                                    }, [data])
                                }

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                sx={{ backgroundColor: "#e0f2fe" }}

                            >
                                <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Danh sách công việc</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <DHSToolbarRoleActionType2
                                    id={" "}
                                    menu={menu}
                                    customMenu={[]}
                                    onClickAction={handleClickAction}
                                />
                                <DHSDataGridEdit
                                    data={dataDetails}
                                    table_name={"QLCCTaskScriptDetails"}
                                    // key="so_ct"
                                    keyExpr="id"
                                    // mode="cell"
                                    dataGridRef={dataGridRef}
                                    allowEdit={false}
                                    pageSize={menu?.page_size}
                                    isPagination={menu?.is_pagination}
                                    isMultiple={false}
                                    groupingAutoExpandAll={false}
                                    handlePageChange={handlePageChange}
                                    handleSelectionChanged={handleSelectionChanged}
                                    height={40}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>

                <TaskChild
                    open={actionPopup.open}
                    onClose={() => setActionPopup({
                        ...actionPopup,
                        open: false
                    })}
                    data={actionPopup.data}
                    action={actionPopup.actions}
                    onEditSuccess={() => console.log('hi')}
                    onSubmit={handleTadChild}
                    start_time={actionPopup.start_time}
                    ma_bp={data.department_id}
                    receiver={data.receiver}
                    groups={Groups}
                    types={Types}
                    statusList={statusList}
                    // typeCode={data?.type_code}
                    typeCode={TYPE_CODE_TASK_SCRIPT}
                    groupTask={data.group_id}
                    cycle={Cycle}
                    apartmentBuildingId={apartment_building_id}
                />
            </DialogContent>
            <DialogActions>
                {props.action !== ACTIONS.VIEW && (
                    <Button variant="contained" onClick={handleSubmit}>
                        Lưu
                    </Button>
                )}
                <Button onClick={props.onClose}>Hủy</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog >
    );
};

export default TaskScriptAction;

