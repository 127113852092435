import React from "react";
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import { setIsReadNotification, setTotalReadNotification, setTotalViewNotification } from "../../../../redux/actions/Notification";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { QLCCClient, QLCCNotificationsModel } from "../../../../app/shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../app/shared/service-proxies/config";
import { AppSession } from "../../../../app/shared/app-session/app-session";
import { Link } from "react-router-dom";

var appSession: AppSession;

const NotificationAdmin: React.FC<{}> = () => {

  // 
  const dispatch = useDispatch();
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const isLogin = useSelector((state: IReducer) => state.AdminPageReducer?.isLogin);
  const notification = useSelector((state: IReducer) => state.NotificationReducer?.notification);
  const [notis, setNotis] = React.useState<QLCCNotificationsModel[]>([]);
  const numUnView = useSelector((state: IReducer) => state.NotificationReducer?.numUnView);
  const numNotiFromFCM = useSelector((state: IReducer) => state.NotificationReducer?.numNotiFromFCM);
  const [anchorElNoti, setAnchorElNoti] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorElNoti);
  const [top, setTop] = React.useState<number>(4);

  // 
  const qLCCClient = new QLCCClient(appSession, BASE_URL_API);

  React.useEffect(() => {
    qLCCClient.qLCCNotificationGet({
      ...new QLCCNotificationsModel(),
      recipient_id: SessionLogin.userName,
      is_login: isLogin,
      top: top
    } as QLCCNotificationsModel)
      .then(notification => {
        setNotis(notification);
        let totalIsView = 0;
        notification.forEach(x => {
          if (!x.is_view) {
            totalIsView++;
          }
        });
        dispatch(setTotalViewNotification(totalIsView))
      })
  }, [isLogin, top, notification, numNotiFromFCM])


  const handleViewNotiClick = async (event: React.MouseEvent<HTMLButtonElement>) => {

    setAnchorElNoti(event.currentTarget);
    const unviewNotis = [...notis].filter(noti => !noti.is_view);
    if (unviewNotis.length > 0) {
      const ids = unviewNotis.map(x => x.id).join(";");
      const result = await qLCCClient.qLCCNotificationUpd({
        ...notification,
        is_view: true,
        id: ids,
        action_type: "VIEWED"
      } as QLCCNotificationsModel)
      if(result.status === 0){
        dispatch(setTotalViewNotification(0))
        
      }
    }

    dispatch(setIsReadNotification(true))
  };
  const handleReadNotiClick = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, notification: QLCCNotificationsModel, idx: number) => {
    // setAnchorElNoti(event.currentTarget);
    // dispatch(setIsReadNotification(true))
    if (!notification.is_read) {
     const result = await qLCCClient.qLCCNotificationUpd({
        ...notification,
        is_read: true,
        action_type: "READED"
      } as QLCCNotificationsModel)

      if(result.status === 0){
        const tempNotis = [...notis];
        tempNotis[idx].is_read = true;

        setNotis(tempNotis)
        
      }
    }
  };
  const handleNotiClose = () => {
    setAnchorElNoti(null);
  };
  return <Box >
    <Badge color="error" badgeContent={numUnView}>
      <IconButton
        id="basic-button-noti"
        aria-controls={open ? 'basic-menu-noti' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleViewNotiClick}
        sx={{
          padding: 0

        }}

      >
        <NotificationsIcon sx={{ color: "#fff" }} />
      </IconButton>
    </Badge>
    <Menu
      id="basic-menu-noti"
      anchorEl={anchorElNoti}
      open={open}
      onClose={handleNotiClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button-noti',
      }}

      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          minWidth: 360,
          maxHeight: 400,
          minHeight: 400,
          overflowY: "scroll",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      <MenuItem divider disableRipple disableTouchRipple
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "#fff !important",
          zIndex: 100,
          '&:hover': {
            backgroundColor: 'inherit', // Đặt màu nền hover thành màu nền mặc định (hoặc giá trị mong muốn)
          },
          '&.clicked': {
            backgroundColor: 'inherit', // Đặt màu nền khi click thành màu nền mặc định (hoặc giá trị mong muốn)
          },
        }}>Thông báo</MenuItem>
      {
        notis && notis.length > 0 ? notis?.map((noti, idx) => {
          return <Link key={idx} to={`${noti.link}${noti.id_reference ? '?id=' + noti.id_reference : ''}`}  style={{ textDecoration: 'none' }} onClick={(e) => handleReadNotiClick(e, noti, idx)}>
            <MenuItem divider onClick={handleNotiClose} sx={{ backgroundColor: noti.is_read ? "" : "#eff6ff" }} >
              <List sx={{ width: '100%', minWidth: 360, paddingTop: 0, paddingBottom: 0, }}>

                <ListItem alignItems="flex-start" sx={{ padding: 0 }}>
                  {/* <ListItemAvatar>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar> */}
                  <ListItemText
                    primary={<span style={{ fontWeight: 600, color: "#333" }}>{noti.title}</span>}
                    secondary={
                      <Box className="d-flex flex-column">

                        <Typography
                          // sx={{ display: '' }}
                          style={{ wordBreak: 'break-word', color: "#333" }}
                          noWrap={false}
                          whiteSpace={"break-spaces"}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {noti.content}

                        </Typography>

                        <Typography
                          sx={{ display: 'inline', color: "#333" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                          textAlign={"right"}
                        >
                          {moment().diff(noti.ngay_ct, "weeks") >= 1 ? moment(noti.ngay_ct).format("DD/MM/yyyy HH:mm") : moment(noti.ngay_ct).fromNow()}
                        </Typography>
                      </Box>
                    }
                  />

                </ListItem>
              </List>
            </MenuItem>
          </Link>
        }) : <Typography variant="body1" textAlign={"center"}> Không có thông báo</Typography>
      }
      {
        notis && notis.length > 0 && <MenuItem disableRipple disableTouchRipple
          sx={{
            // position: "sticky",
            // top: 0,
            backgroundColor: "#fff !important",
            zIndex: 100,
            '&:hover': {
              backgroundColor: 'inherit', // Đặt màu nền hover thành màu nền mặc định (hoặc giá trị mong muốn)
            },
            '&.clicked': {
              backgroundColor: 'inherit', // Đặt màu nền khi click thành màu nền mặc định (hoặc giá trị mong muốn)
            },
          }}>
          <Button variant="text" fullWidth onClick={() => setTop(pre => pre + 4)}>Xem thêm</Button>
        </MenuItem>
      }
    </Menu>
  </Box>
}

export default NotificationAdmin;