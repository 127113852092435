import { UserInfo } from "../../app/shared/service-proxies/api-shared";
import { IndexedDBService } from "../../common/indexedDBApi";
import { DB_NAME, DB_VERSION, OBJECTSTORENAME } from "../../common/indexedDBApi/constant";

interface UserInfoIdxDB extends UserInfo{
    issupperadmin?: boolean | undefined;
    userName?: string | undefined
}

class UserHelper {
    private static indexedDBService = new IndexedDBService(DB_NAME, DB_VERSION, OBJECTSTORENAME);
    // private static sessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    public static userInfo: UserInfoIdxDB;

    public static getUserInfo = async (): Promise<UserInfoIdxDB | undefined> => {
        try {
            const db = await UserHelper.indexedDBService.openDB();
            const sessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
            // const data = { id: 1, name: 'John Doe', age: 25 };

            // indexedDBService.addData(db, userInfo).then(() => {
            //   console.log('Data added to object store');

            const data = await UserHelper.indexedDBService.getData(db, sessionLogin.userName);
            UserHelper.userInfo = data

            return data as UserInfoIdxDB
        } catch (error) {
            console.error(error)
            return undefined;

        }

        // }).catch((error) => {
        //   console.log(error);
        // });

    }
}

export default UserHelper;