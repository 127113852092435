import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteRenderOptionState, Box, InputLabel, TextField } from "@mui/material";
import React from "react";
import { httpPost } from "../../../common/httpService";
import { useSelector } from "react-redux";
import { IReducer } from "../../layouts";


interface IProps {
    id?: string,
    table_list: string,
    value: any,
    readOnly?: boolean,
    disabled?: boolean,
    disableClearable?: boolean,
    name?: string,
    placeholder?: string,
    renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: any, state: AutocompleteRenderOptionState) => React.ReactNode,
    filterSelectedOptions?: boolean,
    defaultValue?: (data: any[]) => void,
    defaultValueWhenDataSourceChanged?: (data: any[]) => void,
    onValueChanged?: (event: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined) => void,
    onDataSourceMounted?: (data: any[]) => void;
    onDataSourceChanged?: (data: any[]) => void;
    onReturnFirstDataAfterMount?: (data: any) => void;
}

const DHSAutocompleteFilterListSelect: React.FC<IProps> = (props) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const [dataSource, setDataSource] = React.useState<any>();
    const [isDefaulted, setIsDefaulted] = React.useState<boolean>(false)

    React.useEffect(() => {
        httpPost<string>('/api/Common/LoadDatasourceBindControlByApartmentBuilding', {
            ma_cty: SessionLogin?.ma_cty,
            tablelist: props?.table_list,
            apartment_building_id: apartment_building_id
        })
            .then(res => {
                console.log(JSON.parse(res.data))
                const serviceGroups: any = JSON.parse(res.data)
                setDataSource(serviceGroups)
            }).catch((ex) => {
                console.log(ex)
            });
    }, [apartment_building_id, props?.table_list])

    React.useEffect(() => {
        if (dataSource && dataSource.length > 0 && props.onReturnFirstDataAfterMount !== undefined) {
            const copyDataSource = [...dataSource];

            const firstItem = copyDataSource.shift();
            if (firstItem !== undefined) {
                props.onReturnFirstDataAfterMount(firstItem)
            }
        }
    }, [dataSource])

    React.useEffect(() => {
        if (dataSource?.Table1.length > 0) {
            if (!isDefaulted) {
                props?.defaultValue && props.defaultValue(dataSource?.Table1);
                props?.onDataSourceMounted && props.onDataSourceMounted(dataSource?.Table1);
                setIsDefaulted(true)
            }

            else {
                props?.defaultValueWhenDataSourceChanged && props.defaultValueWhenDataSourceChanged(dataSource?.Table1)
                props?.onDataSourceChanged && props.onDataSourceChanged(dataSource?.Table1)
            }

        }
    }, [dataSource?.Table1, isDefaulted])

    return <Autocomplete
        id={props?.id}
        sx={{ width: "100%" }}
        options={dataSource?.Table1}
        autoHighlight
        disableClearable={props?.disableClearable}
        filterSelectedOptions={props?.filterSelectedOptions}
        getOptionLabel={(option: any) => option && option[dataSource?.Table[0]?.DisplayMember]}
        value={
            (props?.value && dataSource?.Table1?.length)
                ? dataSource.Table1.find(
                    (item: any) => item[dataSource?.Table[0]?.ValueMember] === props?.value
                )
                : null
        }
        onChange={props?.onValueChanged}
        renderOption={props?.renderOption}
        renderInput={(params) => (
            <TextField
                {...params}
                className="cc-input"
                placeholder={props?.placeholder}
                inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                    // type: "search" // disable autocomplete and autofill
                }}
            />
        )}
        disabled={props?.disabled}
        readOnly={props?.readOnly}
    />
}

export default DHSAutocompleteFilterListSelect;