import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IReducer, Notification } from '../../../components/layouts';
import { AppSession } from '../../shared/app-session/app-session';
import { QLCCFloorModel, QLCCClient, SysActionType, QLCCBlockModel } from '../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../shared/service-proxies/config';
import { useLocation } from 'react-router-dom';
import BlockUI from '../../../components/Block-UI/Block-ui';
import FloorsAction from '../actions/floor-action/FloorsAction';
import notification from '../../../common/notification/notification';
import DHSBreadcrumb from '../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DataGrid from 'devextreme-react/data-grid';
import DHSToolbarRole from '../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import { EventInfo } from 'devextreme/events';
import { ACTIONS } from '../../../common/enums/DHSToolbarRoleAction';
import { getIndexRowSelected, getKeyRowSelected } from '../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../common/Interfaces/StatusMessage';
import { Box, SelectBox } from 'devextreme-react';
import { IDataFilter_02023 } from './apartment-building-list';
import DHSBlockSelectBox from '../../../components/DHSDictionaries/DHSBlockSelectBox';

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

const convert = ["success", "error", "warning"];
var appSession: AppSession;
const FloorsList: React.FC<{}> = () => {

    const location = useLocation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const [dataTable, setDataTable] = React.useState<QLCCFloorModel[]>([]);
    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [dataFilter, setDataFilter] = useState<IDataFilter_02023>()


    React.useEffect(() => {
        onLoadDataSourceFloor();
    },[apartment_building_id])


    async function onLoadDataSourceFloor(block_id?: string | undefined) {
        const res = await qLCCClient
            .qLCCFloorGet({
                ...new QLCCFloorModel(),
                block_id: block_id ? block_id : dataFilter?.block_id,
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCFloorModel);
        setDataTable(res);
    }

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code?.toUpperCase()) {
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            if (window.confirm("Bạn có chắc muốn xóa")) {
                                setLoading(true)
                                qLCCClient
                                    .qLCCFloorAction({
                                        ...new QLCCFloorModel(),
                                        id: idItemSelected,
                                        action_type: "DELETE"
                                    } as QLCCFloorModel)
                                    .then((res: any) => {
                                        setLoading(false)
                                        notification(convert[res?.status], res.message);
                                        setReload(!reload)
                                        setIdItemSelected("")
                                        onLoadDataSourceFloor();
                                    });
                            }
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: action?.action_code?.toUpperCase() ?? "",
                    payload: selectedItemKeys ?? null
                })
                break;
            case ACTIONS.EDIT:
            case ACTIONS.VIEW:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: action?.action_code?.toUpperCase() ?? "",
                                payload: selectedItemKeys ?? null
                            })
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }

                break;

        }

    }

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                console.log(keys);

                const index = e.component.getRowIndexByKey(keys[0]);

                // setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
                setIdItemSelected(keys[0]);
            });
        }
    };

    const handlePageChange = () => {
        setSelectedItemKeys(undefined);
    };

    const handleChangeInput = (e: any) => {
        const { name, value } = e;
        setDataFilter({
            ...dataFilter,
            [name]: value
        } as IDataFilter_02023);

        switch (name) {
            case "block_id":
                onLoadDataSourceFloor(value);
                break;
            default:
                console.log(name)
        }
    }

    return (
        <div className='container-fluid'>

            {
                (React.useMemo(() => {
                    return <FloorsAction
                        dataFilter={dataFilter}
                        open={actCx.open}
                        onClose={function (): void {
                            setActCx(defaultAction)
                        }}
                        onEditSuccess={function (is_success): void {
                            onLoadDataSourceFloor();
                        }}
                        action={actCx.type}
                        itemSelected={actCx.payload} >
                    </FloorsAction>
                }, [actCx.open, setReload]))
            }

            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location}
                />
            </div>
            {/* <DHSBlockSelectBox
                value={dataFilter?.block_id}
                returnDataAfterMount={e => {
                    setDataFilter({
                        block_id: e.id
                    });
                    onLoadDataSourceFloor(e.id)
                }}
                onValueChange={e => {
                    handleChangeInput({
                        name: "block_id",
                        value: e
                    });
                }}
            /> */}
            <DHSToolbarRole
                id={" "}
                menu={menu}
                customMenu={[]}
                onClickAction={handleClickAction}
            />

            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                keyExpr="id"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handleSelectionChanged={handleSelectionChanged}
                handlePageChange={handlePageChange}

            // handleExporting={handleExporting}
            />
            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default FloorsList;


