import { AnyAction } from "redux"
import {PUSH_URL_LEVEL_1,  PUSH_URL_LEVEL_2} from "../constants/ActionTypes";
interface IUrl{
    menuid_level_1?: string,
    level_1?: string,
    bar_level_1?: string,
    menuid_level_2?: string,
    level_2?: string,
    bar_level_2?: string
}
const initialState: IUrl = {
    menuid_level_1: "",
    level_1: "",
    bar_level_1: "",
    menuid_level_2: "",
    level_2: "",
    bar_level_2: ""

}

var UrlReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case PUSH_URL_LEVEL_1: 
        return {
            ...state,
            menuid_level_1: action.payload.menuid_level_1,
            level_1: action.payload.url_level_1,
            bar_level_1: action.payload.bar_level_1,
        } as IUrl
        case PUSH_URL_LEVEL_2: 
        return {
            ...state,
            menuid_level_2: action.payload.menuid_level_2,
            level_2: action.payload.url_level_2,
            bar_level_2: action.payload.bar_level_2,
        } as IUrl
        default:
             return state;

    }
}

export default UrlReducer;