import React from 'react';
import { Link as RouterLink, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { Breadcrumbs, Link, LinkProps, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { IReducer } from '../../../common/Interfaces/Interfaces';

interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => {
    return <Link {...props} component={RouterLink as any} />;
}

interface IProps {
    location: any,
    textExpand?: string,
    is_paramater?: boolean
}

const breadcrumbNameMap: { [key: string]: string } = {
    //50.00.00
    "/apartment-building-list": "Dự án",
    "/block-list": "Tòa nhà",
    "/floor-list": "Tầng",
    "/apartment": "Danh sách căn hộ",
    "/resident": "Danh sách cư dân",
    "/citizen-identification-card": "Quản lý CCCD",
    "/feedbacks-list": "Phản ánh",

    //54.00.00
    "/notification-list": "Thông báo",
    "/news-list": "Tin tức",
    //#region menu_85
    '/user': 'Người sử dụng',
    '/user/permission': 'Phân quyền',
    '/role/permission': 'Phân quyền',
    '/role': 'Vai trò',
    '/resident-account': "Danh sách tài khoản cư dân",
    '/datagrid-setting': "Thông tin lưới",
    //#endregion
    //#region menu_69
    "/receipt": "Phiếu thu",
    "/payment-voucher": "Phiếu chi",
    //#endregion
    //#region menu_64
    "/vehicle": "Danh sách xe",
    "/ab-domistic-water": "Cập nhật chỉ số nước",
    "/locked-list": "Khóa dữ liệu",
    "/service-invoice-client-list": "Hóa đơn",
    "/service-invoice-detail-list": "Phát hành hóa đơn",
    "/service-invoice-list": "Quản lý hóa đơn",
    //#endregion
    //#region 70.00.00
    "/cash-book-report": "Sổ quỹ tiền mặt",
    "/cash-book-record": "Sổ nhật ký thu tiền",
    "/cash-expenses-book": "Sổ nhật ký chi tiền",
    "/history-domestic-water": "Danh sách các hộ sử dụng nước",
    "/Operation-Fee-Report": "Bảng đóng phí",
    "/InvoiceBalanceReport": "Tổng hợp thu - chi theo phí",
    "/CashByExpenditureReport": "Báo cáo công nợ theo hóa đơn",
    "/vehicle-card-report": "Báo cáo danh sách thẻ xe",
    "/vehicle-card-in-out-report" :  "Báo cáo danh sách xe ra vào",

    //#endregion
    //#region 71.00.00
    "/so-du-tien-mat-ngan-hang": "Số dư tiền mặt ngân hàng",
    "/so-du-cong-no-phai-thu": "Số dư hóa đơn của khách hàng",
    "/so-du-cong-no-phai-tra": "Số dư hóa đơn nhà cung cấp",
    "/so-du-doi-tuong-phai-thu": "Số dư công nợ khách hàng",
    "/so-du-doi-tuong-phai-tra": "Số dư công nợ nhà cung cấp",
    //#endregion
    //#region menu_74
    "/investor": "Chủ đầu tư",
    "/vehicle-type": "Phí các loại xe",
    "/service": "Phí vận hành",
    "/resident-job": "Nghề nghiệp cư dân",
    "/regulation": "Qui định",
    "/type-feedback": "Danh sách loại phản ánh",
    "/domestic-water-cycle": "Tạo kỳ hóa đơn/nước",
    "/danh-muc-khach-hang": "Danh mục khách hàng",
    "/category-list": "Danh mục tin tức và thông báo",
    //#endregion
    //#region  75.00.00
    "/danh-sach-the-huy": "Danh sách thẻ hủy",
    "/danh-sach-the-ban": "Danh sách thẻ bán",
    "/danh-sach-the-hong": "Danh sách thẻ hỏng",
    "/danh-sach-the-vip": "Danh sách thẻ VIP",
    "/danh-sach-the-tra": "Danh sách thẻ trả",
    "/danh-sach-the-doi": "Danh sách thẻ đổi",
    "/danh-sach-the-lam-moi": "Danh sách thẻ làm mới",
    "/danh-sach-tat-ca-the-xe": "Danh sách tất cả thẻ",
    "/danh-sach-xe-ra-vao" : "Danh sách ra - vào",
    "/bangdongphi" : "Bảng đóng phí"
    //#endregion
};

const DHSBreadcrumb: React.FC<IProps> = (props) => {

    const url_level_1 = useSelector((state: IReducer) => state.UrlReducer?.level_1);
    const bar_level_1 = useSelector((state: IReducer) => state.UrlReducer?.bar_level_1);
    const url_level_2 = useSelector((state: IReducer) => state.UrlReducer?.level_2);
    const bar_level_2 = useSelector((state: IReducer) => state.UrlReducer?.bar_level_2);

    const pathnames = props.location.pathname.split('/').filter((x: any) => x);
    if (props.is_paramater) {
        pathnames.splice(pathnames.length - 1, 1);
    }
    pathnames.splice(0, 1);

    return (
        <Breadcrumbs aria-label="breadcrumb" separator="/">
            <LinkRouter sx={{ fontWeight: 600 }} underline="hover" color="inherit" to={url_level_1 ?? ""}>
                {bar_level_1}
            </LinkRouter>
            <LinkRouter sx={{ color:  !props.is_paramater ? "#1976D2" : "", fontWeight: 600 }} underline="hover" color="inherit" to={url_level_2 ?? ""}>
                {bar_level_2}
            </LinkRouter>
            {pathnames.map((value: string, index: number) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                return last &&  props.is_paramater && (
                    <Typography sx={{ color: "#1976D2", fontWeight: 600 }} key={to}>
                        {`${breadcrumbNameMap[to]} `} {props.textExpand && props.textExpand}
                    </Typography>
                );
                //  : (
                //     <LinkRouter underline="hover" sx={{ fontWeight: 600 }} color="inherit" to={`/admin${to}`} key={`/admin${to}`}>
                //         {breadcrumbNameMap[to]}
                //     </LinkRouter>
                // );
            })}
        </Breadcrumbs>
    );
}

export default DHSBreadcrumb;