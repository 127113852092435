import React from "react";
import DateBox, { IDateBoxOptions } from "devextreme-react/date-box";

type dxDateBoxProps = React.ComponentProps<typeof DateBox>;
interface IProps extends dxDateBoxProps{

}

const DHSDateBox = React.forwardRef<DateBox, IProps>((props,ref) => {
    return <DateBox
                {...props}
                displayFormat={props?.displayFormat ?? "dd/MM/yyyy"}
                ref={ref}
              />
})

export default DHSDateBox;