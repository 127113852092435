import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
    FormControlLabel,
    Switch,
    AccordionSummary,
    Accordion,
    AccordionDetails,
    Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    CommonClient,
    HomeClient,
    QLCCApartmentModel,
    QLCCApartmentTypesModel,
    QLCCClient,
    RoleRightWebModel,
    SysActionType,
    SysModel,
} from "../../../shared/service-proxies/api-shared";


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import '../ApartmentBuilding.css';
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import notification from "../../../../common/notification/notification";
import ResidentAction from "../resident-action/ResidentAction";
import ApartmentMemberList from "../../apartment-building/apartment/apartment-member-list";
import ApartmentServiceList from "../../apartment-building/apartment/apartment-service-list";
import ApartmentServiceAction from "./ApartmentServiceAction";
import ApartmentVehicleList from "../../apartment-building/apartment/apartment-vehicle-list";
import ApartmentMediaFileAction from "./ApartmentMediaFileAction";
import CTXDate from "../../../../components/CTX/DatePicker";
import { ParaObjectStatus } from "../../../shared/service-proxies/api-shared";
import { SysStatus } from "../../../shared/service-proxies/api-shared";
import { STATUS_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";
import ApartmentElectricAction from "./ApartmentElectricAction";
import ApartmentWaterAction from "./ApartmentWaterAction";
import DHSCheckBox from "../../../../components/DHSComponents/editors/DHSCheckBox/DHSCheckBox";
import DHSNumberBox from "../../../../components/DHSComponents/editors/DHSNumberBox/DHSNumberBox";
import { TransDateTime } from "../../../../utils/date.util";

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
    floorSelect?: any;
    bLockSelect?: any;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});
interface IAction {
    type: string;
    open: boolean;
    payload: any;
}
const defaultAction: IAction = {
    type: "",
    payload: null,
    open: false,
};






var appSession: AppSession;
var htmlString: string;
const status_message: string[] = ['success', 'error']

const ApartmentAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();


    const [data, setData] = React.useState<QLCCApartmentModel>({} as QLCCApartmentModel);
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const common = new CommonClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    // 

    const [action, setAction] = React.useState<IProps>(props);
    const [actRes, setActRes] = React.useState<IAction>(defaultAction);
    const [actSv, setActSv] = React.useState<IAction>(defaultAction);
    const [actVh, setActVh] = React.useState<IAction>(defaultAction);

    // 

    const [isCalledApartmentInfo, setIsCalledApartmentInfo] = React.useState<boolean>(true);
    const [isCalledMember, setIsCalledMember] = React.useState<boolean>(false);
    const [isCalledService, setIsCalledService] = React.useState<boolean>(false);
    const [isCalledVehicle, setIsCalledVehicle] = React.useState<boolean>(false);
    const [isElectric, setIsElectric] = React.useState<boolean>(false);
    const [isWater, setIsWater] = React.useState<boolean>(false);

    // 
    const [isExpandApartmentInfo, setIsExpandApartmentInfo] = React.useState<boolean>(true);


    // 

    // const [floorOptions, setFloorOptions] = React.useState<any>([]);
    // const [landlordOptions, setlandlordOptions] = React.useState<QLCCResidentsModel[]>([]);
    // const [apartmentBuildingOptions, setApartmentBuildingOptions] = React.useState<any>([]);
    // const [blockOptions, setBlockOptions] = React.useState<any>([]);
    const [apartmentTypeOptions, setApartmentTypeOptions] = React.useState<QLCCApartmentTypesModel[]>([]);
    const [statusOptions, setStatusOptions] = React.useState<SysStatus[]>([] as SysStatus[]);
    const [mainDirectionOptions, setMainDirectionOptions] = React.useState<any>([

        {
            id: '0051260E-FCB2-4110-979A-732A7DDDAD5E',
            name: 'Hướng Bắc'
        },
        {
            id: 'DEDA097D-A520-44A5-BFEF-9FBE4F5E594B',
            name: 'Hướng Nam'
        },
        {
            id: 'BFC94704-C49F-4F1E-BBF5-4D942E26BB99',
            name: 'Hướng Đông'
        },
        {
            id: 'CFA28162-756B-4229-8924-C03A137F40F7',
            name: 'Hướng Tây'
        },
        {
            id: '7A55F893-E705-47A9-B977-E71E49B3F1BD',
            name: 'Hướng Đông Bắc'
        },
        {
            id: 'E8ADD77B-8EDA-4743-B157-B31C88CADE9B',
            name: 'Hướng Tây Nam'
        }
    ]);

    // const [windowDirectionOptions, setWindowDirectionOptions] = React.useState<any>([
    //     {
    //         id: '0051260E-FCB2-4110-979A-732A7DDDAD5E',
    //         name: 'Hướng Bắc'
    //     },
    //     {
    //         id: 'DEDA097D-A520-44A5-BFEF-9FBE4F5E594B',
    //         name: 'Hướng Nam'
    //     },
    //     {
    //         id: 'BFC94704-C49F-4F1E-BBF5-4D942E26BB99',
    //         name: 'Hướng Đông'
    //     },
    //     {
    //         id: 'CFA28162-756B-4229-8924-C03A137F40F7',
    //         name: 'Hướng Tây'
    //     },
    //     {
    //         id: '7A55F893-E705-47A9-B977-E71E49B3F1BD',
    //         name: 'Hướng Đông Bắc'
    //     },
    //     {
    //         id: 'E8ADD77B-8EDA-4743-B157-B31C88CADE9B',
    //         name: 'Hướng Tây Nam'
    //     }
    // ]);


    const [elevatorLocationOptions, setElevatorLocationOptions] = React.useState<any>([
        {
            id: '0051260E-FCB2-4110-979A-732A7DDDAD5E',
            name: 'Trong toà nhà'
        },
        {
            id: 'DEDA097D-A520-44A5-BFEF-9FBE4F5E594B',
            name: 'Ngoài trời'
        },
    ]);


    const [emergencyExitLocationOptions, setEmergencyExitLocationOptions] = React.useState<any>([
        {
            id: '0051260E-FCB2-4110-979A-732A7DDDAD5E',
            name: 'Cửa chính căn hộ'
        },
        {
            id: 'DEDA097D-A520-44A5-BFEF-9FBE4F5E594B',
            name: 'Thang máy'
        },
        {
            id: 'CFA28162-756B-4229-8924-C03A137F40F7',
            name: 'Cửa sổ'
        },
        {
            id: 'BFC94704-C49F-4F1E-BBF5-4D942E26BB99',
            name: 'Hệ thống thoát hiểm bên ngoài'
        },
    ]);

    // const [serviceApartment, setServieApartment] = React.useState<string[]>();



    // 

    // const webcamRef = React.useRef<any>(null);

    // const [openCamera, setOpenCamera] = React.useState<IOpenCamera>({
    //     pic: "",
    //     open: false,
    // });

    // const handleCloseCamera = () => {
    //     setOpenCamera({ ...openCamera, pic: "", open: false });
    // };

    // const capture = React.useCallback(() => {
    //     const imageSrc = webcamRef?.current?.getScreenshot({ width: 324, height: 204 });
    //     // fileClient
    //     //     .postFile_v4([imageSrc.replace("data:image/jpeg;base64,", "")])
    //     //     .then((res) => {
    //     //         if (res) {
    //     //             let tempArr: string[] = [];
    //     //             res.forEach((path: any, pathIndex: number) => {
    //     //                 tempArr.push(path);
    //     //             });
    //     //             switch (openCamera.pic) {
    //     //                 case "hinh_luc_vao":
    //     //                     setListHinhLucVao([...listHinhLucVao].concat(tempArr));
    //     //                     break;
    //     //                 case "hinh_luc_ra":
    //     //                     setListHinhLucRa([...listHinhLucRa].concat(tempArr));
    //     //                     break;
    //     //             }
    //     //         } else notification("warning", "Không lấy được hình ảnh");
    //     //     });

    //     if (imageSrc) {
    //         setData({
    //             ...data,
    //             [openCamera.pic]: imageSrc
    //         } as QLCCApartmentModel)
    //     }


    //     // setOpenCamera({ ...openCamera, pic: "", open: false });
    // }, [webcamRef, openCamera.pic]);

    React.useEffect(() => {
        common.getStatus({
            ... new ParaObjectStatus(),
            mA_CT: "CH1",
            language: "vi-VN"
        } as ParaObjectStatus)
            .then((res) => {
                setStatusOptions(res);
                // 
            })
    }, [])

    React.useEffect(() => {
        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            if (props?.bLockSelect?.apartment_building_id) {
                qLCCClient
                    .qLCCApartmentsGet({
                        ...new QLCCApartmentModel(),
                        id: props.itemSelected?.id,
                        ma_cty: props.itemSelected?.ma_cty,
                        apartment_building_id: props.itemSelected?.apartment_building_id
                    } as QLCCApartmentModel)
                    .then((res) => {
                        setData(res[0]);
                    })
                    .catch(() => alert("Không lấy được dữ liệu"));
                setId(props.itemSelected?.id);
            }
        } else {
            setData({
                ...new QLCCApartmentModel(),
                apartment_building_id: props.bLockSelect?.apartment_building_id,
                ma_cty: SessionLogin?.ma_cty,
                is_use_water: true,
                is_use_electric: false,
            } as QLCCApartmentModel);
            setId('');
        }
    }, [props.action, props.bLockSelect?.apartment_building_id, props.itemSelected]);


    React.useEffect(() => {

        if (isCalledApartmentInfo && props.bLockSelect?.apartment_building_id) {
            setLoading(true);
            qLCCClient.qLCCApartmentTypesActions({
                ...new QLCCApartmentTypesModel(),
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: props.bLockSelect?.apartment_building_id,
                action_type: 'GET'
            } as QLCCApartmentTypesModel)
                .then(res => {
                    setApartmentTypeOptions(res)
                    setLoading(false);
                });



            // setLoading(true);
            // qLCCClient.qLCCFloorGet({
            //     ...new QLCCFloorModel(),
            //     action_type: 'GET'
            // } as QLCCFloorModel)
            //     .then(res => {
            //         setFloorOptions(res)
            //         setLoading(false);
            //     });
        }


    }, [isCalledApartmentInfo, props.bLockSelect?.apartment_building_id]);

    // useEffect(() => {
    //     if (isCalledApartmentInfo) {
    //         setLoading(true);
    //         qLCCClient.qLCCResidentsGet({
    //             ...new QLCCResidentsModel(),
    //             action_type: 'GET'
    //         } as QLCCResidentsModel)
    //             .then(res => {
    //                 setlandlordOptions(res)
    //                 setLoading(false);
    //             });

    //         setLoading(true);
    //         qLCCClient.qlccApartmentBuildingGet({
    //             ...new QLCCApartmentBuildingModel(),
    //             action_type: 'GET'
    //         } as QLCCApartmentBuildingModel)
    //             .then(res => {
    //                 setApartmentBuildingOptions(res)
    //                 setLoading(false);
    //             });
    //     }
    // }, [reload, isCalledApartmentInfo])


    // React.useMemo(() => {
    //     if (isCalledApartmentInfo) {
    //         setLoading(true)
    //         qLCCClient.qlccBlockGet({
    //             ...new QLCCBlockModel(),
    //             apartment_building_id: data.apartment_building_id
    //         } as QLCCBlockModel)
    //             .then(res => {
    //                 setLoading(false);
    //                 setBlockOptions(res);
    //             });
    //     }
    // }, [data.apartment_building_id, isCalledApartmentInfo]);

    // React.useMemo(() => {

    //     if (isCalledApartmentInfo) {
    //         setLoading(true)
    //         qLCCClient.qLCCFloorGet({
    //             ...new QLCCFloorModel(),
    //             block_id: data.block_id
    //         } as QLCCFloorModel)
    //             .then(res => {
    //                 setLoading(false);
    //                 setFloorOptions(res);
    //             });
    //     }
    // }, [data.block_id, isCalledApartmentInfo])

    // React.useEffect(() => {
    //     setLoading(true);

    // }, [actTtt.open]);
    // const showCamera = (e: any, file: any) => {
    //     setOpenCamera({
    //         ...openCamera,
    //         pic: file,
    //         open: true,
    //     });
    // };

    const handleSubmit = () => {
        switch (props.action) {
            case ACTIONS.EDIT:
                setLoading(true);

                if (_id) {
                    qLCCClient.qLCCApartmentsUpd({
                        ...new QLCCApartmentModel(),
                        ...data,
                        floor_id: props.floorSelect?.id,
                        floor_name: props.floorSelect?.name,
                        block_id: props.bLockSelect?.id,
                        block_name: props.bLockSelect?.name,
                        description: htmlString,
                        id: props.itemSelected?.id,
                        date_apply_resident_water_norm: TransDateTime(data.date_apply_resident_water_norm)
                    } as QLCCApartmentModel).then(res => {
                        setLoading(false);
                        notification(status_message[res.status!], STATUS_MESSAGE[`${props.action}_${res.status}`]);

                        if (res.status === 0) {
                            props.onEditSuccess(true);
                            props.onClose();
                        }
                    })
                } else {
                    notification(status_message[1], "Vui lòng chọn hàng cần sửa hoặc xem chi tiết")
                }


                break;
            case ACTIONS.ADD:
                setLoading(true);
                qLCCClient.qLCCApartmentsIns({
                    ...new QLCCApartmentModel(),
                    ...data,
                    ma_cty: SessionLogin?.ma_cty,
                    floor_id: props.floorSelect?.id,
                    floor_name: props.floorSelect?.name,
                    block_id: props.bLockSelect?.id,
                    block_name: props.bLockSelect?.name,
                    apartment_building_id: props.bLockSelect?.apartment_building_id,
                    apartment_building_name: props.bLockSelect?.apartment_building_name,
                    description: htmlString,
                } as QLCCApartmentModel).then(res => {
                    setLoading(false);
                    notification(status_message[res.status!], STATUS_MESSAGE[`${props.action}_${res.status}`]);
                    if (res.status === 0) {
                        if (res.status === 0) {
                            props.onEditSuccess(true);
                            props.onClose();
                        }
                        setId(res.ref_id)
                        setAction({ ...action, itemSelected: res.ref_id, action: 'EDIT' });
                    }
                })
                break;
            default:
                break;
        }
    };

    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;



        if ((Array.isArray(value) && Array.isArray(name))) {
            const tempData = { ...data };
            name.forEach((name_, idx) => {
                Object.assign(tempData, {
                    ...tempData,
                    [name_]: value[idx]
                })
            })

            setData(tempData as QLCCApartmentModel)

        }
        else setData({ ...data, [name]: value } as QLCCApartmentModel);
    };

    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        setData({ ...data, [name]: new Date(value) } as QLCCApartmentModel);
    }



    const handleChangeRichText = (stringData: string) => {
        htmlString = stringData;
    }

    const handleClickServiceAction = (e: any) => {

        setActSv(pre => ({
            ...pre,
            open: true,
            type: ACTIONS.EDIT,
            payload: {
                ma_cty: props.itemSelected?.ma_cty,
                service_id: data.service_id,
                apartment_id: data.id,
                apartment_building_id: props.itemSelected?.apartment_building_id,
                apartment_type_id: data?.apartment_type_id,
            }
        }))

    }

    const apartmentMember = React.useMemo(() => {
        if (isCalledMember) {
            return (
                <ApartmentMemberList
                    apartment_id={props.itemSelected?.id}
                    apartment_building_id={props.itemSelected?.apartment_building_id}
                    floor_id={data.floor_id}
                    title="Thành viên"
                    onEditSuccess={() => props.onEditSuccess(true)}
                />
            )
        }
    }, [data.floor_id, isCalledMember, props.itemSelected?.id, props.itemSelected?.apartment_building_id])

    const apartmentService = React.useMemo(() => {
        if (isCalledService) {
            return (
                <ApartmentServiceList service_id={data.service_id!} ma_cty={props.itemSelected?.ma_cty} apartment_building_id={props.itemSelected?.apartment_building_id} apartment_id={data.id!} />
            )
        }
    }, [isCalledService, data.service_id, data.id, props.itemSelected?.ma_cty, props.itemSelected?.apartment_building_id])


    const apartmentVehicle = React.useMemo(() => {
        if (isCalledVehicle) {
            return (
                <ApartmentVehicleList apartment_id={data.id!} ma_cty={props?.itemSelected?.ma_cty} apartment_building_id={props.bLockSelect?.apartment_building_id} />
            )
        }
    }, [data.id, isCalledVehicle, props.bLockSelect?.apartment_building_id, props?.itemSelected?.ma_cty])

    const onMediaFileChange = ({ data_media }: { data_media: any }) => {

        const { apartmentImageList, apartmentVideo } = data_media;
        // if ((apartmentImageList as Array<string>).length > 0) {
        setData(pre => ({ ...pre, image: (apartmentImageList as Array<string>).join(";") } as QLCCApartmentModel));
        // }


        // if (apartmentVideo) {
        setData(pre => ({ ...pre, video: (apartmentVideo as string) } as QLCCApartmentModel));

        // }

    }

    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                // handleSubmit();             
                handleSubmit()
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
        }
    }

    return (
        <Dialog
            id="sysMenu90"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "flex-end",
            }}
            TransitionComponent={Transition}
            maxWidth='xs'

        >

            {/* Cư dân action */}
            {actRes.open && <ResidentAction
                open={actRes.open}
                title="Chủ hộ"
                onClose={() => {
                    setActRes(defaultAction);
                }}
                itemSelected={actRes.payload}
                action={actRes.type}
                action_type={"OWNER_APARTMENT"}
                onEditSuccess={function (data: SysModel): void {

                    // setReload(pre => !pre);
                    setData(pre => ({ ...pre, owner_resident_id: data.ref_id } as QLCCApartmentModel));
                }} />
            }

            {/* Dịch vụ action */}

            {
                actSv.open && (
                    <ApartmentServiceAction
                        open={true}
                        onClose={function (): void {
                            setActSv(defaultAction);
                        }}
                        action={actSv.type}
                        payload={actSv.payload}
                        onEditSuccess={function (response): void {

                            const tempData: QLCCApartmentModel = Object.assign({}, data, { service_id: response.ref_id });

                            setData(tempData);



                            // Cập nhật lại service id
                            // Nếu service_id không có cái nào



                        }} >
                    </ApartmentServiceAction>
                )
            }

            {/* Xe action */}
            {
                // actVh.open && (
                //     <ApartmentServiceAction
                //         open={true}
                //         onClose={function (): void {
                //             setActVh(defaultAction);
                //         }}
                //         action={actVh.type}
                //         payload={actVh.payload}
                //         onEditSuccess={function (response): void {

                //             const tempData: QLCCApartmentModel = Object.assign({}, data, { service_id: response.ref_id });

                //             setData(tempData);



                //             // Cập nhật lại service id
                //             // Nếu service_id không có cái nào



                //         }} >
                //     </ApartmentServiceAction>
                // )
            }
            {/* <Dialog
                open={openCamera.open}
                onClose={handleCloseCamera}
                sx={{ height: "inherit" }}
            >
                <DialogTitle>Camera</DialogTitle>
                <DialogContent>
                    <div className="webcam-container">
                        <Webcam
                            audio={false}
                            height={360}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            width={540}
                            mirrored
                            videoConstraints={videoConstraints}
                        />
                        <div className="citizen-card-placeholder"></div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={capture}>Chụp</Button>
                    <Button onClick={handleCloseCamera}>Đóng</Button>
                </DialogActions>
            </Dialog> */}

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +

                            `${props.action === ACTIONS.VIEW
                                ? data?.name! +
                                " " + "[Chi tiết]"
                                : props.action === "EDIT" || _id
                                    ? data?.name! +
                                    " " + "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >

                <Accordion expanded={isExpandApartmentInfo} onChange={() => { setIsCalledApartmentInfo(true); setIsExpandApartmentInfo(pre => !pre) }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ backgroundColor: "#e0f2fe" }}
                    >
                        <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Thông tin chung</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography >
                            {
                                isCalledApartmentInfo && <div className="row">
                                    <div className="col-xl-4">

                                        {/* Chủ hộ */}
                                        {/* <Box
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Chủ hộ</InputLabel>
                                                {
                                                  landlordOptions.length > 0 &&  <Autocomplete
                                                        id="apartment-select"
                                                        sx={{ width: "100%" }}
                                                        options={landlordOptions}
                                                        autoHighlight
                                                        getOptionLabel={(option: any) => `${option?.full_name}`}
                                                        value={
                                                            data?.owner_resident_id
                                                                ? landlordOptions.find(
                                                                    (item: any) => item.id === data.owner_resident_id
                                                                )
                                                                : null
                                                        }
                                                        onChange={(e, newValue: any) => {
                                                            handleChangeInput({
                                                                target: {
                                                                    name: ["owner_resident_id", "owner_resident_name"],
                                                                    value: [newValue?.id ?? null, `${newValue?.last_name} ${newValue?.first_name}`] ?? null,
                                                                },
                                                            });
                                                        }}


                                                        renderInput={(params) => (
                                                            <TextField
                                                                autoFocus

                                                                {...params}
                                                                className="cc-input"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    autoComplete: "new-password", // disable autocomplete and autofill
                                                                }}
                                                            />
                                                        )}
                                                        disabled={props.action === ACTIONS.VIEW}
                                                    />

                                                }
                                            </Box>
                                            <IconButton
                                                style={{ height: "24px", width: "24px", color: "#1890ff", padding: "0 1rem" }}
                                                onClick={() =>
                                                    setActRes({
                                                        ...actRes,
                                                        type: "ADD",
                                                        payload: "",
                                                        open: true,
                                                    })
                                                }
                                                disabled={props.action === ACTIONS.VIEW}
                                            >
                                                <AddCircleOutlinedIcon
                                                    style={{ height: "inherit", width: "inherit" }}
                                                />
                                            </IconButton>
                                        </Box> */}

                                        {/* Chung cư */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Chung cư</InputLabel>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                value={props.bLockSelect?.apartment_building_name}
                                            />

                                        </Box>

                                        {/* Toà nhà */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Toà nhà</InputLabel>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                value={props.bLockSelect?.name}
                                            />

                                        </Box>
                                        {/* Tầng */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tầng</InputLabel>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                value={props.floorSelect?.name}
                                            />

                                        </Box>

                                        {/* Tên căn hộ */}

                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Tên căn hộ</InputLabel>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                name="name"
                                                onChange={handleChangeInput}
                                                value={data.name}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />

                                        </Box>

                                        {/* Loại căn hộ */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Loại căn hộ</InputLabel>
                                            {
                                                apartmentTypeOptions.length > 0 && <DHSAutoCompleteWithDataSource
                                                    id="apartment-type-select"

                                                    dataSource={apartmentTypeOptions}
                                                    displayMember="apartment_type_name"
                                                    value={data?.apartment_type_id}
                                                    valueMember="id"
                                                    onValueChanged={(e, newValue: any) => {
                                                        handleChangeInput({
                                                            target: {
                                                                name: "apartment_type_id",
                                                                value: newValue?.id ? newValue.id : null,
                                                            },
                                                        });
                                                    }}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                />
                                            }

                                        </Box>

                                        {/* Diện tích */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Diện tích (m2)</InputLabel>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                name="area"
                                                onChange={handleChangeInput}
                                                value={data.area}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />

                                        </Box>






                                    </div>

                                    <div className="col-xl-4">

                                        {/* Số lượng phòng ngủ */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số lượng phòng ngủ</InputLabel>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                type="number"
                                                name="bedroom_number"
                                                onChange={handleChangeInput}
                                                value={data.bedroom_number}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />

                                        </Box>

                                        {/* Số lượng nhà vệ sinh */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số lượng nhà vệ sinh</InputLabel>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                type="number"
                                                name="bathroom_number"
                                                onChange={handleChangeInput}
                                                value={data.bathroom_number}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />

                                        </Box>

                                        {/* Hướng nhà */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Hướng nhà</InputLabel>
                                            <DHSAutoCompleteWithDataSource
                                                id="main-direction-select"

                                                dataSource={mainDirectionOptions}
                                                displayMember="name"
                                                valueMember="name"
                                                value={data?.main_direction}
                                                onValueChanged={(e, newValue: any) => {
                                                    handleChangeInput({
                                                        target: {
                                                            name: "main_direction",
                                                            value: newValue?.name ? newValue.name : null,
                                                        },
                                                    });
                                                }}

                                                disabled={props.action === ACTIONS.VIEW}
                                            />

                                        </Box>

                                        {/* Hướng cửa sổ */}
                                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Hướng cửa sổ</InputLabel>
                                            <Autocomplete
                                                id="window-direction-select"
                                                sx={{ width: "100%" }}
                                                options={windowDirectionOptions}
                                                autoHighlight
                                                getOptionLabel={(option: any) => option?.name}
                                                value={
                                                    data?.window_direction
                                                        ? windowDirectionOptions.find(
                                                            (item: any) => item.name === data.window_direction
                                                        )
                                                        : null
                                                }
                                                onChange={(e, newValue: any) => {
                                                    handleChangeInput({
                                                        target: {
                                                            name: "window_direction",
                                                            value: newValue?.name ? newValue.name : null,
                                                        },
                                                    });
                                                }}

                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className="cc-input"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password", // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />

                                        </Box> */}

                                        {/* Vị trí thang máy */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Vị trí thang máy</InputLabel>
                                            <DHSAutoCompleteWithDataSource
                                                id="window-direction-select"

                                                dataSource={elevatorLocationOptions}
                                                displayMember="name"
                                                valueMember="name"
                                                value={data?.elevator_location}
                                                onValueChanged={(e, newValue: any) => {
                                                    handleChangeInput({
                                                        target: {
                                                            name: "elevator_location",
                                                            value: newValue?.name ? newValue.name : null,
                                                        },
                                                    });
                                                }}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />

                                        </Box>

                                        {/* Vị trí lối thoát hiểm */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Vị trí lối thoát hiểm</InputLabel>
                                            <DHSAutoCompleteWithDataSource
                                                id="emergency-exit-location-select"

                                                dataSource={emergencyExitLocationOptions}
                                                displayMember="name"
                                                value={data?.emergency_exit_location}
                                                valueMember="name"
                                                onValueChanged={(e, newValue: any) => {
                                                    handleChangeInput({
                                                        target: {
                                                            name: "emergency_exit_location",
                                                            value: newValue?.name ? newValue.name : null,
                                                        },
                                                    });
                                                }}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />

                                        </Box>

                                        {/* balcony condition */}
                                        <Box className="row">
                                            <Box className="col-6">


                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ban công</InputLabel>

                                                <FormControlLabel control={
                                                    <Switch
                                                        name="balcony"
                                                        value={data.balcony}
                                                        checked={data.balcony}
                                                        onChange={(e: any, checked: boolean) => handleChangeInput({
                                                            target: {
                                                                name: e.target.name,
                                                                value: checked
                                                            }
                                                        })}
                                                        disabled={props.action === ACTIONS.VIEW}
                                                        color="primary" />} label={<Typography sx={{ fontSize: 14, color: '#333' }}>Không/Có</Typography>} />


                                            </Box>


                                            <Box className="col-6">


                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tình trạng</InputLabel>

                                                <FormControlLabel control={
                                                    <Switch
                                                        name="condition"
                                                        value={data.condition}
                                                        checked={data.condition}

                                                        onChange={(e: any, checked: boolean) => handleChangeInput({
                                                            target: {
                                                                name: e.target.name,
                                                                value: checked
                                                            }
                                                        })}
                                                        disabled={props.action === ACTIONS.VIEW}
                                                        color="primary" />} label={<Typography sx={{ fontSize: 14, color: '#333' }}>Không cho thuê/Đang cho thuê</Typography>} />

                                            </Box>

                                        </Box>
                                    </div>

                                    <div className="col-xl-4">

                                        {/* Giá thuê */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Giá thuê</InputLabel>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                name="rental_price"
                                                type="number"
                                                onChange={handleChangeInput}
                                                value={data.rental_price}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />

                                        </Box>

                                        {/* Giá bán */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Giá bán</InputLabel>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                name="sale_price"
                                                type="number"
                                                onChange={handleChangeInput}
                                                value={data.sale_price}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />

                                        </Box>

                                        {/* Số lược xem */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số lượt xem</InputLabel>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                name="view_count"
                                                type="number"
                                                onChange={handleChangeInput}
                                                value={data.view_count}
                                                inputProps={{

                                                    style: {
                                                        cursor: 'not-allowed'
                                                    }
                                                }
                                                }
                                                disabled
                                            />

                                        </Box>

                                        {/* Ngày tạo
                                <Box sx={{ width: '100%', margin: '5px 0' }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày tạo</InputLabel>
                                    <CTXDateTime
                                        className="cc-input"
                                        style={{ width: '100%' }}
                                        name="created_date"
                                        onChange={handleChangeDate}
                                        value={data.created_date ?? null}
                                        disabled
                                    />
                                </Box> */}

                                        {/* Ngày cập nhật */}
                                        {/* <Box sx={{ width: '100%', margin: '5px 0' }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày cập nhật</InputLabel>
                                    <CTXDateTime
                                        className="cc-input"
                                        style={{ width: '100%' }}
                                        name="updated_date"
                                        onChange={handleChangeDate}
                                        value={data.updated_date ?? null}
                                        disabled
                                    />
                                </Box> */}

                                        <Box sx={{ width: '100%', margin: '5px 0' }}>


                                            <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Trạng thái</InputLabel>

                                            <DHSAutoCompleteWithDataSource
                                                id="emergency-exit-location-select"
                                                dataSource={statusOptions}
                                                displayMember="mo_ta"
                                                valueMember="trang_thai"
                                                value={data?.status_id}
                                                onValueChanged={(e, newValue: any) => {
                                                    handleChangeInput({
                                                        target: {
                                                            name: "status_id",
                                                            value: newValue?.trang_thai ? newValue.trang_thai : null,
                                                        },
                                                    });
                                                }}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />


                                        </Box>
                                        {/* Diện tích */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Tổng thành viên đã đăng ký định mức nước</InputLabel>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                type={"number"}
                                                name="number_resident_water_norm"
                                                onChange={handleChangeInput}
                                                value={data.number_resident_water_norm}
                                                disabled={props.action === ACTIONS.VIEW}
                                                inputProps={{
                                                    style: {
                                                        textAlign: "right",
                                                    }
                                                }}
                                            />

                                        </Box>

                                        {/* Ngày áp dụng đăng ký định mức nước */}
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày áp dụng định mức nước</InputLabel>
                                            <CTXDate
                                                className="cc-input"
                                                style={{ width: '100%', padding: '0 5px !important' }}
                                                name="date_apply_resident_water_norm"
                                                onChange={handleChangeDate}
                                                value={data?.date_apply_resident_water_norm ?? null}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />
                                        </Box>
                                        {/* Tùy chọn tạo nhanh đồng hồ nước hoặc đồng hồ điện */}
                                        <Box className="my-3" style={{ width: "100%", margin: "5px 0px" }}>
                                            {/* <InputLabel className="" sx={{ fontSize: 14, color: '#333', fontWeight: 'bold' }}>Tùy chọn khởi tạo</InputLabel> */}
                                            <Box style={{ width: "100%", margin: "5px 0px" }} className="">
                                                <DHSCheckBox
                                                    value={data?.is_use_water}
                                                    onValueChange={e => {
                                                        // const res = window.confirm('Xác nhận thay đổi');
                                                        // if (res) {
                                                        handleChangeInput({
                                                            target: {
                                                                name: 'is_use_water',
                                                                value: e
                                                            }
                                                        })
                                                        // }
                                                    }}
                                                    text="Sử dụng nước"
                                                // disabled={props.action !==  ACTIONS.ADD}
                                                />
                                            </Box>
                                            <Box style={{ width: "100%", margin: "5px 0px" }} className="">
                                                <DHSCheckBox
                                                    value={data?.is_use_electric}
                                                    onValueChange={e => {
                                                        // const res = window.confirm('Xác nhận thay đổi');
                                                        // if (res) {
                                                        handleChangeInput({
                                                            target: {
                                                                name: 'is_use_electric',
                                                                value: e
                                                            }
                                                        })
                                                        // }
                                                    }}
                                                    text="Sử dụng điện"
                                                // disabled={props.action !==  ACTIONS.ADD}
                                                />
                                            </Box>
                                        </Box>
                                    </div>

                                    <div className="col-12">
                                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}> Mô tả</InputLabel>
                                            <RichTextEditor
                                                onChange={(stringData) => handleChangeRichText(stringData)}
                                                value={data.description}

                                            />
                                        </Box>
                                    </div>
                                </div>
                            }

                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        sx={{ backgroundColor: "#e0f2fe" }}
                    >
                        <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Hình ảnh căn hộ</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ApartmentMediaFileAction action={props.action} dataMediaFile={onMediaFileChange} image={data.image} video={data.video} />
                    </AccordionDetails>
                </Accordion>

                {
                    props.action !== "ADD" && <>
                        {/* Thành viên căn hộ */}
                        <Accordion onChange={() => { setIsCalledMember(true) }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                sx={{ backgroundColor: "#e0f2fe" }}
                            >
                                <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Thành viên căn hộ</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {props.itemSelected?.id && apartmentMember}
                            </AccordionDetails>
                        </Accordion>

                        {/* Dịch vụ căn hộ */}
                        <Accordion onChange={() => setIsCalledService(true)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                sx={{ backgroundColor: "#e0f2fe" }}
                            >
                                <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Chi phí dịch vụ</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {action?.action === 'EDIT' && <div className='d-row no-gutters'>
                                    <div className="col-4">
                                        <Button variant='text' startIcon={<i className="bi bi-pencil-square"></i>} onClick={handleClickServiceAction}>Cập nhật</Button>
                                    </div>
                                </div>}
                                {data.service_id && apartmentService}
                            </AccordionDetails>
                        </Accordion>

                        {/* Xe căn hộ */}
                        <Accordion onChange={() => setIsCalledVehicle(true)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                sx={{ backgroundColor: "#e0f2fe" }}
                            >
                                <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Xe căn hộ</Typography>
                            </AccordionSummary>
                            <AccordionDetails>



                                {data?.id && apartmentVehicle}
                            </AccordionDetails>
                        </Accordion>

                        <Accordion onChange={() => setIsElectric(true)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                sx={{ backgroundColor: "#e0f2fe" }}
                            >
                                <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Đồng hồ điện</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {isElectric && <ApartmentElectricAction dataSourceApartment={data} open={props.open} />}
                            </AccordionDetails>
                        </Accordion>

                        <Accordion onChange={() => setIsWater(true)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                sx={{ backgroundColor: "#e0f2fe" }}
                            >
                                <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Đồng hồ nước</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {isWater && <ApartmentWaterAction dataSourceApartment={data} open={props.open} />}
                            </AccordionDetails>
                        </Accordion>
                    </>

                }
            </DialogContent>
            <DialogActions>
                <DHSDialogAction

                    action={props.action}
                    menu={menu}
                    onClickAction={handleClickAction}
                />

            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default ApartmentAction;


