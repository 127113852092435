export interface ISchedulerView{
    code: string,
    name: string
}

export const schedulerViews: ISchedulerView[] = [
    {
        code: "bv",
        name: "Basic view"
    },
    {
        code: "timeline",
        name: "Timeline view"
    },
    {
        code: "agenda",
        name: "Agenda view"
    },
]