import React from "react";
import {
  Button,
  Box,
  Tabs,
  Tab,
  Typography,
  TextField,
  Checkbox,
  TextareaAutosize,
  MenuItem,
  Select,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import "./style.css";
import {
  ChoThueXeClient,
  CtxDanhMucXeModel,
  CtxLoaiXeModel,
  CtxThongTinXeBaoDuongModel,
  CtxThongTinXeHinhAnhModel,
  CtxThongTinXeModel,
  HomeClient,
  RoleRightWebModel,
} from "../../../shared/service-proxies/api-shared";
import ChangeIcon from "../../../../Asset/image/change.png";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import notification from "../../../../common/notification/notification";
import CTXToolbar from "../../../../components/CTX/Toolbar";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { useHistory, useParams } from "react-router-dom";
import BaoDuongDialog from "./BaoDuongDialog";
import HinhXeDialog from "./HinhXeDialog";
import DHSGridView from "../../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import DHS_Select from "../../../../components/DHS/DHS_Select";
import CurrencyTextField from "react-currency-input-field";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
interface IOpen {
  isOpen: boolean;
  action: string;
}
interface IItem {
  position: number | undefined;
  id: string;
}
var appSession: AppSession;

const ThongTinXe = () => {
  const [{ _id }, setId] = React.useState(useParams<{ _id: string }>());
  const [cccd_h] = React.useState<number>(186);

  const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const [data, setData] = React.useState<CtxThongTinXeModel>({
    ...new CtxThongTinXeModel(),
    isUse: 1,
  } as CtxThongTinXeModel);
  const [value, setValue] = React.useState(0);
  const [value2, setValue2] = React.useState(0);
  const [loaiXeOptions, setLoaiXeOptions] = React.useState<any>([]);
  const [hangXeOptions, setHangXeOptions] = React.useState<any>([]);
  const [kieuXeOptions, setKieuXeOptions] = React.useState<any>([]);
  const [mauXeOptions, setMauXeOptions] = React.useState<any>([]);
  const [noiSanXuatXeOptions, setNoiSanXuatXeXeOptions] = React.useState<any>(
    []
  );
  const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [open, setOpen] = React.useState<IOpen>({ isOpen: false, action: "" });
  const [open2, setOpen2] = React.useState<IOpen>({
    isOpen: false,
    action: "",
  });
  const history = useHistory();
  const [trangThaiXeOptions, setTrangThaiXeOptions] = React.useState<any>([]);
  const [xeSelected, selectXe] = React.useState<IItem>();
  const [hinhSelected, selectHinh] = React.useState<IItem>();

  React.useEffect(() => {
    homeClient
      ?.roleRightWebGetAll({
        ...new RoleRightWebModel(),
        rolename: "KS",
        menuid: menu?.menuid,
        username: SessionLogin.userName,
      } as RoleRightWebModel)
      .then((res) => {
        setRoles(res);
      });
    ctxClient
      .ctxDanhMucXeActions({
        ...new CtxDanhMucXeModel(),
        tbname: "ctxHangXe",
        action: "GET",
      } as CtxDanhMucXeModel)
      .then((res) => setHangXeOptions(res));
    ctxClient
      .ctxDanhMucXeActions({
        ...new CtxDanhMucXeModel(),
        tbname: "ctxKieuXe",
        action: "GET",
      } as CtxDanhMucXeModel)
      .then((res) => setKieuXeOptions(res));
    ctxClient
      .ctxDanhMucXeActions({
        ...new CtxDanhMucXeModel(),
        tbname: "ctxMauSon",
        action: "GET",
      } as CtxDanhMucXeModel)
      .then((res) => setMauXeOptions(res));
    ctxClient
      .ctxDanhMucXeActions({
        ...new CtxDanhMucXeModel(),
        tbname: "ctxNoiSanXuatXe",
        action: "GET",
      } as CtxDanhMucXeModel)
      .then((res) => setNoiSanXuatXeXeOptions(res));
    ctxClient
      .ctxDanhMucXeActions({
        ...new CtxDanhMucXeModel(),
        tbname: "ctxTrangThaiXe",
        action: "GET",
      } as CtxDanhMucXeModel)
      .then((res) => setTrangThaiXeOptions(res));
  }, []);
  React.useEffect(() => {
    if (_id) {
      ctxClient
        .ctxThongTinXeGet({
          ...new CtxThongTinXeModel(),
          id: _id,
          action_type: "BYXEID",
        } as CtxThongTinXeModel)
        .then((res) => {
          setData(res[0]);
        });
    }
  }, []);
  React.useEffect(() => {
    if (data.hang_xe_id) {
      ctxClient.ctxLoaiXeGet(new CtxLoaiXeModel()).then((res) => {
        setLoaiXeOptions(
          res.filter((item) => item.hang_xe_id === data.hang_xe_id)
        );
      });
    } else setLoaiXeOptions([]);
  }, [data.hang_xe_id]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleChange2 = (event: React.SyntheticEvent, newValue: number) => {
    setValue2(newValue);
  };
  const handleChangeInput = (e: any) => {
    if (e.target.name === "isUse") {
      setData({
        ...data,
        isUse: Number(e.target.checked),
      } as CtxThongTinXeModel);
    } else {
      const { name, value } = e.target;
      setData({ ...data, [name]: value } as CtxThongTinXeModel);
    }
  };
  const handleChangePrice = (e: any) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: Number(value.split(",").join("")),
    } as CtxThongTinXeModel);
  };
  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const handleUploadFile = (e: any) => {
    const files = e.target.files;
    getBase64(files[0], (result: any) => {
      setData({
        ...data,
        anh_xe: result,
      } as CtxThongTinXeModel);
    });
    // const formData = new FormData();
    // formData.append("MyFile", files[0]);
    // const options = {
    //   observe: "response",
    //   // responseType: "blob",
    //   headers: {
    //     Accept: "application/json",
    //   },
    // };
    // axios
    //   .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
    //   .then((res) => {
    //     setData({
    //       ...data,
    //       anh_xe: `${BASE_URL_API}${res.data[0].path}`,
    //     } as CtxThongTinXeModel);
    //   });
  };
  const handleClickAction = (action: any) => {
    switch (action.class_name?.toUpperCase()) {
      case "SAVE":
        if (_id) {
          ctxClient
            .ctxThongTinXeUpd({
              ...data,
            } as CtxThongTinXeModel)
            .then((response) => {
              if (response[0].ret === "0")
                notification("success", response[0].message);
              else if (response[0].ret === "1")
                notification(
                  "error",
                  response[0].message ? response[0].message : "Có lỗi xảy ra"
                );
              else
                notification(
                  "warning",
                  response[0].message ? response[0].message : "Có lỗi xảy ra"
                );
            });
        } else {
          ctxClient
            .ctxThongTinXeIns({
              ...data,
            } as CtxThongTinXeModel)
            .then((response) => {
              if (response[0].ret === "0") {
                if (response[0].id) {
                  setId({ _id: response[0].id });
                }
                notification("success", response[0].message);
              } else if (response[0].ret === "1")
                notification(
                  "error",
                  response[0].message ? response[0].message : "Có lỗi xảy ra"
                );
              else
                notification(
                  "warning",
                  response[0].message ? response[0].message : "Có lỗi xảy ra"
                );
            });
        }
        break;
      default:
        history.goBack();
        break;
    }
  };
  const deleteThongTinXeBaoDuong = (value: any) => {
    let ftempArr = [];
    let ltempArr = [];
    let position = xeSelected?.position ? xeSelected.position : 0;

    if (data.ctxThongTinXeBaoDuongs && xeSelected) {
      ftempArr = data.ctxThongTinXeBaoDuongs.slice(0, position);
      ltempArr = data.ctxThongTinXeBaoDuongs.slice(
        position + 1,
        data.ctxThongTinXeBaoDuongs.length
      );
      setData({
        ...data,
        ctxThongTinXeBaoDuongs: [...ftempArr, ...ltempArr],
      } as CtxThongTinXeModel);
    }
    
  };
  const deleteThongTinHinhAnh = (value: any) => {
    let ftempArr = [];
    let ltempArr = [];
    let position = hinhSelected?.position ? hinhSelected.position : 0;
    if (data.danh_sach_hinh_xe && hinhSelected) {
      ftempArr = data.danh_sach_hinh_xe.slice(0, position);
      ltempArr = data.danh_sach_hinh_xe.slice(
        position + 1,
        data.danh_sach_hinh_xe.length
      );
      setData({
        ...data,
        danh_sach_hinh_xe: [...ftempArr, ...ltempArr],
      } as CtxThongTinXeModel);
    }
  };

  const handleSubmit = (value: any, act: any) => {
    let tempArr: any = [];
    if (data.ctxThongTinXeBaoDuongs && data.ctxThongTinXeBaoDuongs.length > 0) {
      tempArr = data.ctxThongTinXeBaoDuongs;
    }
    if (act === "ADD") {
      tempArr.push(value);
      setData({
        ...data,
        ctxThongTinXeBaoDuongs: [...tempArr],
      } as CtxThongTinXeModel);
    } else if (act === "UPDATE") {
      setData({
        ...data,
        ctxThongTinXeBaoDuongs: [...tempArr].map((item, ix) => {
          if (ix === xeSelected?.position) {
            return value;
          } else return item;
        }),
      } as CtxThongTinXeModel);
    }
    setOpen({ ...open, isOpen: false, action: "" });
  };
  const handleSubmit2 = (value: any, act: any) => {
    let tempArr: any = [];
    if (data.danh_sach_hinh_xe && data.danh_sach_hinh_xe?.length > 0) {
      tempArr = data.danh_sach_hinh_xe;
    }
    if (act === "ADD") {
      tempArr.push(value);
      setData({
        ...data,
        danh_sach_hinh_xe: [...tempArr],
      } as CtxThongTinXeModel);
    } else if (act === "UPDATE") {
      setData({
        ...data,
        danh_sach_hinh_xe: [...tempArr].map((item, ih) => {
          if (ih === hinhSelected?.position) {
            return value;
          } else return item;
        }),
      } as CtxThongTinXeModel);
    }

    setOpen2({ ...open2, isOpen: false, action: "" });
  };

  return (
    <div>
      {React.useMemo(() => {
        return (
          <BaoDuongDialog
            itemSelected={xeSelected?.id}
            open={open.isOpen}
            action={open.action}
            onClose={() => {
              setOpen({ ...open, isOpen: false, action: "" });
            }}
            onSubmit={handleSubmit}
          />
        );
      }, [open])}

      {React.useMemo(() => {
        return (
          <HinhXeDialog
            itemSelected={hinhSelected?.id}
            open={open2.isOpen}
            action={open2.action}
            onClose={() => {
              setOpen2({ ...open2, isOpen: false, action: "" });
            }}
            onSubmit={handleSubmit2}
          />
        );
      }, [open2])}
      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          marginTop: 15,
          marginBottom: 15,
          fontSize: 22,
          fontWeight: 550,
        }}
      >
        <i
          className="bi bi-arrow-left-short"
          style={{ cursor: "pointer" }}
          onClick={() => history.goBack()}
        ></i>
        &nbsp;{menu?.bar + " / " + `${_id ? "Cập nhật" : "Thêm mới"}`}
      </div>
      <div className="p-1">
        <CTXToolbar
          list={
            roles
              ? roles.filter(
                  (item) => item.on_form?.toLocaleUpperCase() === "EDIT"
                )
              : []
          }
          onClickAction={handleClickAction}
        />
        <Box className="p-1" sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Xe" {...a11yProps(0)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="row justify-content-between">
                <div className="col-xl-4">
                  <div className="row p-1 c-input-card">
                    <div className="col-md-2 d-flex align-items-center c-font">
                      Hãng, loại xe*
                    </div>
                    <div className="col-md-1" style={{ height: 5 }} />
                    <div className="col-md-9 p-0 d-flex justify-content-between">
                      <DHS_Select
                        name="hang_xe_id"
                        onChange={handleChangeInput}
                        value={data.hang_xe_id}
                        options={hangXeOptions}
                        fieldDisplay="name"
                        fieldValue="id"
                      />
                      <div style={{ padding: "0px 1px" }} />
                      <DHS_Select
                        name="loai_xe_id"
                        onChange={handleChangeInput}
                        value={data.loai_xe_id}
                        options={loaiXeOptions}
                        fieldDisplay="name"
                        fieldValue="id"
                      />
                    </div>
                  </div>
                  <div className="row p-1 c-input-card">
                    <div className="col-md-2 d-flex align-items-center c-font">
                      Kiểu xe
                    </div>
                    <div className="col-md-1" style={{ height: 5 }} />

                    <div className="col-md-9 p-0">
                      <div className="form-group">
                        <DHS_Select
                          name="kieu_xe_id"
                          onChange={handleChangeInput}
                          value={data.kieu_xe_id}
                          options={kieuXeOptions}
                          fieldDisplay="name"
                          fieldValue="id"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row p-1 c-input-card">
                    <div className="col-md-2 d-flex align-items-center c-font">
                      Đời xe*
                    </div>
                    <div className="col-md-1" style={{ height: 5 }} />

                    <div className="col-md-9 p-0">
                      <div className="form-group">
                        <TextField
                          sx={{ width: "100%" }}
                          name="doi_xe"
                          onChange={handleChangeInput}
                          value={data.doi_xe}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row p-1 c-input-card">
                    <div className="col-md-2 d-flex align-items-center c-font">
                      Biển số*
                    </div>{" "}
                    <div className="col-md-1" style={{ height: 5 }} />
                    <div className="col-md-9 p-0">
                      <div className="form-group">
                        <TextField
                          sx={{ width: "100%" }}
                          name="bien_so"
                          onChange={handleChangeInput}
                          value={data.bien_so}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row p-1 c-input-card">
                    <div className="col-md-2 d-flex align-items-center c-font">
                      Màu sơn
                    </div>
                    <div className="col-md-1" style={{ height: 5 }} />

                    <div className="col-md-9 p-0">
                      <div className="form-group">
                        <DHS_Select
                          name="mau_son_id"
                          onChange={handleChangeInput}
                          value={data.mau_son_id}
                          options={mauXeOptions}
                          fieldDisplay="name"
                          fieldValue="id"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row p-1 c-input-card">
                    <div className="col-md-2 d-flex align-items-center c-font">
                      Sản xuất
                    </div>
                    <div className="col-md-1" style={{ height: 5 }} />

                    <div className="col-md-9 p-0">
                      <div className="form-group">
                        <DHS_Select
                          name="noi_san_xuat_id"
                          onChange={handleChangeInput}
                          value={data.noi_san_xuat_id}
                          options={noiSanXuatXeOptions}
                          fieldDisplay="name"
                          fieldValue="id"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row p-1 c-input-card">
                    <div className="col-md-2 d-flex align-items-center c-font">
                      Dung tích, số khung, số máy
                    </div>
                    <div className="col-md-1" style={{ height: 5 }} />

                    <div className="col-md-9 p-0 d-flex justify-content-between">
                      <div className="form-group">
                        <TextField
                          sx={{ width: "100%" }}
                          name="dung_tich"
                          onChange={handleChangeInput}
                          value={data.dung_tich}
                        />
                      </div>

                      <div
                        className="form-group"
                        style={{ marginRight: 1, marginLeft: 1 }}
                      >
                        <TextField
                          sx={{ width: "100%" }}
                          name="so_khung"
                          onChange={handleChangeInput}
                          value={data.so_khung}
                        />
                      </div>

                      <div className="form-group">
                        <TextField
                          sx={{ width: "100%" }}
                          name="so_may"
                          onChange={handleChangeInput}
                          value={data.so_may}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5">
                  <div className="row p-1 c-input-card">
                    <div className="col-md-2 d-flex align-items-center c-font">
                      Tên chủ xe
                    </div>
                    <div className="col-md-1" style={{ height: 5 }} />

                    <div className="col-md-9 p-0">
                      <div className="form-group">
                        <TextField
                          sx={{ width: "100%" }}
                          name="ten_chu_xe"
                          onChange={handleChangeInput}
                          value={data.ten_chu_xe}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row p-1 c-input-card">
                    <div className="col-md-2 d-flex align-items-center c-font">
                      Giá thuê giờ
                    </div>
                    <div className="col-md-1" style={{ height: 5 }} />

                    <div className="col-md-9 p-0">
                      <div className="form-group">
                        <CurrencyTextField
                          className="flex-grow-1 form-control"
                          allowDecimals
                          decimalsLimit={2}
                          decimalSeparator="."
                          groupSeparator=","
                          style={{ width: "100%", textAlign: "right" }}
                          name="gia_thue_gio"
                          // type="number"
                          onChange={handleChangePrice}
                          value={data.gia_thue_gio ? data.gia_thue_gio : 0}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row p-1 c-input-card">
                    <div className="col-md-2 d-flex align-items-center c-font">
                      Giá thuê ngày
                    </div>
                    <div className="col-md-1" style={{ height: 5 }} />

                    <div className="col-md-9 p-0">
                      <div className="form-group">
                        <CurrencyTextField
                          className="flex-grow-1 form-control"
                          allowDecimals
                          decimalsLimit={2}
                          decimalSeparator="."
                          groupSeparator=","
                          style={{ width: "100%", textAlign: "right" }}
                          // type="number"
                          name="gia_thue_ngay"
                          onChange={handleChangePrice}
                          value={data.gia_thue_ngay ? data.gia_thue_ngay : 0}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row p-1 c-input-card">
                    <div className="col-md-2 d-flex align-items-center c-font">
                      Giá thuê tháng
                    </div>
                    <div className="col-md-1" style={{ height: 5 }} />

                    <div className="col-md-9 p-0">
                      <div className="form-group">
                        <CurrencyTextField
                          className="flex-grow-1 form-control"
                          allowDecimals
                          decimalsLimit={2}
                          decimalSeparator="."
                          groupSeparator=","
                          style={{ width: "100%", textAlign: "right" }}
                          // type="number"
                          name="gia_thue_thang"
                          onChange={handleChangePrice}
                          value={data.gia_thue_thang ? data.gia_thue_thang : 0}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row p-1 c-input-card">
                    <div className="col-md-2 d-flex align-items-center c-font">
                      Giá thuê năm
                    </div>
                    <div className="col-md-1" style={{ height: 5 }} />

                    <div className="col-md-9 p-0">
                      <div className="form-group">
                        <CurrencyTextField
                          className="flex-grow-1 form-control"
                          allowDecimals
                          decimalsLimit={2}
                          decimalSeparator="."
                          groupSeparator=","
                          style={{ width: "100%", textAlign: "right" }}
                          // type="number"
                          name="gia_thue_nam"
                          onChange={handleChangePrice}
                          value={data.gia_thue_nam ? data.gia_thue_nam : 0}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row p-1 c-input-card">
                    <div className="col-md-2 d-flex align-items-center c-font">
                      Còn dùng
                    </div>
                    <div className="col-md-1" style={{ height: 5 }} />

                    <div className="col-md-9 p-0">
                      <Checkbox
                        sx={{ padding: 0 }}
                        name="isUse"
                        checked={Boolean(data.isUse)}
                        onChange={handleChangeInput}
                      />
                    </div>
                  </div>
                  <div className="row p-1 c-input-card">
                    <div className="col-md-2 d-flex align-items-center c-font">
                      Trạng thái
                    </div>
                    <div className="col-md-1" style={{ height: 5 }} />

                    <div className="col-md-9 p-0">
                      <DHS_Select
                        name="trang_thai_id"
                        onChange={handleChangeInput}
                        value={data.trang_thai_id}
                        options={trangThaiXeOptions}
                        fieldDisplay="name"
                        fieldValue="id"
                      />
                    </div>
                  </div>
                  <div className="row p-1 c-input-card">
                    <div className="col-md-2 d-flex align-items-center c-font">
                      Diễn giải
                    </div>
                    <div className="col-md-1" style={{ height: 5 }} />

                    <div className="col-md-9 p-0">
                      <div className="form-group">
                        <TextareaAutosize
                          name="dien_giai"
                          aria-label="minimum height"
                          minRows={3}
                          style={{ width: "100%", borderRadius: "3px" }}
                          value={data.dien_giai}
                          onChange={handleChangeInput}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="row p-1 c-input-card">
                    <div className="col-md-3 d-flex c-font">Ảnh đại diện</div>
                    <div className="col-md-9 p-0">
                      <label htmlFor="uploadImage" style={{ width: "100%" }}>
                        {data.anh_xe ? (
                          <>
                            <img
                              src={data.anh_xe}
                              alt={data.loai_xe_name}
                              style={{
                                width: "auto",
                                maxWidth: cccd_h * 1.58,
                                height: cccd_h,
                                maxHeight: cccd_h - 20,
                              }}
                            />
                            <div
                              className="position-absolute w-100 d-flex justify-content-end"
                              style={{ top: 5, right: 5, cursor: "pointer" }}
                            >
                              <img
                                src={ChangeIcon}
                                alt="change cccd"
                                style={{ height: 32, width: 32 }}
                              />
                            </div>
                          </>
                        ) : (
                          <div
                            className="c-img-card"
                            style={{
                              width: "100%",
                              maxWidth: cccd_h * 1.58,
                              maxHeight: cccd_h,
                            }}
                          >
                            <i className="bi bi-images" />
                            &nbsp;Thêm ảnh mới...
                          </div>
                        )}
                      </label>
                      <input
                        id="uploadImage"
                        className="flex-grow-1 form-control"
                        type="file"
                        name="anh_xe"
                        onChange={handleUploadFile}
                        // value={data.anh_xe}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              Chi tiết
            </TabPanel>
          </Box>
        </Box>
        <Box className="p-2" sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value2}
                onChange={handleChange2}
                aria-label="basic tabs example"
              >
                <Tab label="Hình xe" {...a11yProps(0)} />
                <Tab label="Bảo dưỡng" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value2} index={0}>
              <Button
                className="c-button"
                size="medium"
                variant="outlined"
                startIcon={<AddCircleIcon />}
                onClick={() => {
                  setOpen2({ ...open2, isOpen: true, action: "ADD" });
                }}
              >
                Thêm
              </Button>
              <Button
                className="c-button"
                size="medium"
                variant="outlined"
                startIcon={<EditIcon />}
                onClick={() => {
                  setOpen2({ ...open2, isOpen: true, action: "UPDATE" });
                }}
              >
                Sửa
              </Button>
              <Button
                className="c-button"
                size="medium"
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={deleteThongTinHinhAnh}
              >
                Xóa
              </Button>
              <div style={{ height: "5px" }}></div>
              <DHSGridView
                table_name="CtxHinhXe"
                show_form_list={true}
                data={data.danh_sach_hinh_xe ? data.danh_sach_hinh_xe : []}
                onSelectRow={(v, i) => {
                  selectHinh({ ...hinhSelected, id: v, position: i });
                  // setIndex2(i);
                }}
              />
            </TabPanel>

            <TabPanel value={value2} index={1}>
              <Button
                className="c-button"
                size="medium"
                variant="outlined"
                startIcon={<AddCircleIcon />}
                onClick={() => {
                  setOpen({ ...open, isOpen: true, action: "ADD" });
                }}
              >
                Thêm
              </Button>
              <Button
                className="c-button"
                size="medium"
                variant="outlined"
                startIcon={<EditIcon />}
                onClick={() => {
                  setOpen({ ...open, isOpen: true, action: "UPDATE" });
                }}
              >
                Sửa
              </Button>
              <Button
                className="c-button"
                size="medium"
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={deleteThongTinXeBaoDuong}
              >
                Xóa
              </Button>
              <div style={{ height: "5px" }}></div>
              <DHSGridView
                table_name="CtxThongTinXeBaoDuong"
                show_form_list={true}
                data={
                  data.ctxThongTinXeBaoDuongs ? data.ctxThongTinXeBaoDuongs : []
                }
                onSelectRow={(v, i) => {
                  selectXe({ ...xeSelected, id: v, position: i });
                }}
              />
            </TabPanel>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default ThongTinXe;
