import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DHSToolbar from '../../../components/DHS/DHS-Toolbar/DHSToolbar';
import { IReducer } from '../../../components/layouts';
import DHSGridView from '../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
import { getRoles } from '../../../redux/actions/RoleActions';
import { AppSession } from '../../shared/app-session/app-session';
import { HomeClient, QLCCClient, RoleRightWebModel, QLCCRegulationModel, SysActionType } from '../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../shared/service-proxies/config';
import { useHistory, useLocation } from 'react-router-dom';
import BlockUI from '../../../components/Block-UI/Block-ui';
import notification from "../../../common/notification/notification";
import RegulationAction from '../actions/regulation-action/RegulationAction';
import moment from 'moment';
import DHSBreadcrumb from '../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import DHSToolbarRole from '../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
import { EventInfo } from 'devextreme/events';
import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import DataGrid from 'devextreme-react/data-grid';
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { ACTIONS } from '../../../common/enums/DHSToolbarRoleAction';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../common/Interfaces/StatusMessage';
import { getDataRowSelected, getIndexRowSelected } from '../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';



var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

const roleMenu: string = 'CC';
const status_message = ['success', 'error']


const RegulationList: React.FC<{}> = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    // const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<QLCCRegulationModel[]>([]);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);

    const [actCx, setActCx] = useState<IAction>(defaultAction);

    // 

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

    //  

    useEffect(() => {
        setLoading(true)

        homeClient.roleRightWebGetAll({
            ...new RoleRightWebModel(),
            menuid: menu?.menuid,
            rolename: roleMenu,
            username: 'DHSOFT',
        } as RoleRightWebModel)
            .then((res) => {

                dispatch(getRoles(res));
                setRoles(res);
                setLoading(false);
            })

    }, [menu?.component])

    useEffect(() => {
        setLoading(true)
        qLCCClient.qLCCRegulationGet({
            ...new QLCCRegulationModel()
        } as QLCCRegulationModel).then((res) => {

            setLoading(false)
            const tempData: any[] = []

            res.forEach(regulation => {
                tempData.push({
                    ...regulation,
                    create_date: moment(regulation.create_date).format("DD/MM/yyyy")
                })
            })
            setDataTable(tempData)
        })
    }, [reload])

    const handleClickAction = async (action: SysActionType) => {

        switch (action.action_code?.toUpperCase()) {
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: action?.action_code?.toUpperCase() ?? "",
                    // payload: selectedItemKeys?.id ?? null
                });
            break;
            case ACTIONS.EDIT:
            case ACTIONS.VIEW:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<QLCCRegulationModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                setActCx({
                    ...actCx,
                    open: true,
                    type: action?.action_code?.toUpperCase() ?? "",
                    payload: dataSelected?.id ?? null
                });
            } else {
                notification(
                  TYPE_MESSAGE.WARNING,
                  STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                );
              }
            } catch (error) {
              notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
              );
            }
          }
                break;
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<QLCCRegulationModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                if (window.confirm("Bạn có chắc chắn muốn xóa?")) {
                    setLoading(true)
                    qLCCClient.qLCCRegulationAction({
                        ...new QLCCRegulationModel(),
                        id: dataSelected?.id ?? null,
                        action_type: action.action_code.toUpperCase(),
                    } as QLCCRegulationModel)
                        .then((res) => {
                            setLoading(false)
                            setIdItemSelected("")
                            notification(status_message[Number(res.status)], res.message);
                            setReload(!reload)
                        });
                }
            } else {
                notification(
                  TYPE_MESSAGE.WARNING,
                  STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                );
              }
            } catch (error) {
              notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
              );
            }
          }
                break;
        }
       
    }

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                console.log(keys);

                const index = e.component.getRowIndexByKey(keys[0]);

                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
            });
        }
    };

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };
    return (


        <div className='container-fluid'>

            {
                actCx.open && (
                    <RegulationAction
                        open={true}
                        onClose={function (): void {
                            setActCx(defaultAction);
                        }}
                        action={actCx.type}
                        itemSelected={actCx.payload}
                        onEditSuccess={function (is_success: boolean): void {
                            setReload(pre => !pre)
                        }} >
                    </RegulationAction>
                )
            }

            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location} />
            </div>

            <DHSToolbarRole
                id={selectedItemKeys?.id ?? null}
                menu={menu}
                customMenu={[]}
                onClickAction={handleClickAction}
            />

            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                keyExpr="id"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handleSelectionChanged={handleSelectionChanged}
                handlePageChange={handlePageChange}

            // handleExporting={handleExporting}
            />

            {/* <DHSGridView
                onSelectRow={(row) => {
                    
                    setIdItemSelected(row);
                }}
                // onListSelectRow={handleListOnselectRow}
                itemClicked={function (): void {
                    throw new Error("Function not implemented.");
                }}
                table_name={menu?.component ?? ''}
                show_form_list={true}
                showSelectBox={false}
                data={dataTable}
                // showSortPanel={isShowSort}
                // showSearchPanel={isShowSearch}
                // showGroupPanel={isShowGroup}
                // showFilterPanel={isShowFilter}
                defaultPageSize={2000}
                grid_name={"Danh sách qui định"}
                excel={false}
            /> */}
            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default RegulationList;


