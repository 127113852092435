import * as types from "../constants/ActionTypes";
import callApi from "../../routes/configApi";
import { dhsDetailPage, ICusRequest, IProductPage } from "../index";
import { IDetailPage, IHomePage } from "../../components/layouts";
import { type } from "os";
import notification from "../../common/notification/notification";

export const GetListHomePage =
  (dhsHomePage: dhsDetailPage) => (dispatch: any, getState: any) => {
    // callApi("api/HomePage/GetListHomePage", "POST", dhsHomePage)
    //   .then((res: { data: any }) =>
    //     dispatch({
    //       type: types.GET_LIST_HOME_PAGE,
    //       payload: res.data,
    //     })
    //   )
    //   .catch((error) => console.log(error));
  };
export const GetListProductPage =
  (dhsProductPage: dhsDetailPage) => (dispatch: any, getState: any) => {
    callApi("api/HomePage/GetListProductPage", "POST", dhsProductPage)
      .then((res: { data: any }) =>
        dispatch({
          type: types.GET_LIST_PRODUCT_PAGE,
          payload: res.data,
        })
      )
      .catch((error) => console.log(error));
  };

export const GetListDetailPage =
  (dhsDetailpage: dhsDetailPage) => (dispatch: any, getState: any) => {
    callApi("api/HomePage/GetListDetailPage", "POST", dhsDetailpage)
      .then((res: { data: any }) =>
        dispatch({
          type: types.GET_LIST_DETAIL_PAGE,
          payload: res.data,
        })
      )
      .catch((error) => console.log(error));
  };
export const GetListRelatedLink =
  (dhsDetailpage: dhsDetailPage) => (dispatch: any, getState: any) => {
    callApi("api/HomePage/GetListDetailPage", "POST", dhsDetailpage)
      .then((res: { data: any }) => {
        dispatch({
          type: types.GET_LIST_RELATED_LINK,
          payload: res.data,
        });
      })
      .catch((error) => console.log(error));
  };
export const InsertCusRequest =
  (cusRequest: ICusRequest) => (dispatch: any, getState: any) => {
    callApi("api/HomePage/InsertCusRequest", "POST", cusRequest)
      .then((res: { data: any }) =>
        dispatch({
          type: types.INSERT_CUSTOMER_REQUEST,
          payload: res.data,
        })
      )
      .catch((error) => console.log(error));
  };
export const SetLanguage =
  (language: string) => (dispatch: any, getState: any) => {
    dispatch({
      type: types.LANGUAGE_ID,
      payload: language,
    });
  };

export const initialState = () => (dispatch: any, getState: any) => {
  dispatch({
    type: types.INITIAL_STATE,
  });
};

// export const sessionLogin =
//   (user: { MA_CTY: string; USERNAME: string; EMPLOYEE_CODE: string }) =>
//   (dispatch: any) => {
//     dispatch({
//       type: types.SESSION_LOGIN,
//       payload: user,
//     });
//   };

export const clearLogin = () => (dispatch: any, getState: any) => {
  dispatch({
    type: types.CLEAR_LOGIN,
  });
};
export const setIdxDB = (payload: boolean) => (dispatch: any, getState: any) => {
  dispatch({
    type: types.SET_IDX_DB,
    payload: payload
  });
};
export const Login =
  (user:
    {
      ma_cty: string;
      userName: string;
      passWord: string,
      ten_cty: string,
      nam_tc: number,
      token_notifi_web: string
    }) =>
    (dispatch: any, getState: any) => {
      if (!user.nam_tc) user.nam_tc = new Date().getFullYear();
      callApi("api/Login/Login", "POST", user)
        .then((res: any) => {
          dispatch({
            type: types.LOGIN,
            payload: res.data[0],
          });

          if (res.data.length > 0) {
            notification("success", "Đăng nhập thành công");
          } else
            notification("error", "Thông tin đăng nhập chưa đúng");
        })
        .catch((error) => notification("error", "Thông tin đăng nhập chưa đúng"));
    };

//DHSDetailPage
export const InsertDetailPage =
  (detailPage: IDetailPage) => (dispatch: any, getState: any) => {
    callApi("api/HomePage/InsertDetailPage", "POST", detailPage)
      .then((res: { data: any }) =>
        dispatch({
          type: types.DHS_DETAIL_INS,
          payload: res.data,
        })
      )
      .catch((error) => console.log(error));
  };

export const UpdateDetailPage =
  (detailPage: IDetailPage) => (dispatch: any, getState: any) => {
    callApi("api/HomePage/UpdateDetailPage", "POST", detailPage)
      .then((res: { data: any }) =>
        dispatch({
          type: types.DHS_DETAIL_UPD,
          payload: res.data,
        })
      )
      .catch((error) => console.log(error));
  };

export const DeleteDetailpage =
  (detailPage: { MA_CTY: string; DETAILID: string; PARENTID: string }) =>
    (dispatch: any, getState: any) => {
      callApi("api/HomePage/DeleteDetailPage", "POST", detailPage)
        .then((res: { data: any }) =>
          dispatch({
            type: types.DHS_DETAIL_DEL,
            payload: res.data,
          })
        )
        .catch((error) => console.log(error));
    };
//DHSHomePage
export const InsertHomePage =
  (HomePage: IHomePage) => (dispatch: any, getState: any) => {
    callApi("api/HomePage/InsertHomePage", "POST", HomePage)
      .then((res: { data: any }) =>
        dispatch({
          type: types.DHS_HOME_INS,
          payload: res.data,
        })
      )
      .catch((error) => console.log(error));
  };

export const UpdateHomePage =
  (HomePage: IHomePage) => (dispatch: any, getState: any) => {
    callApi("api/HomePage/UpdateHomePage", "POST", HomePage)
      .then((res: { data: any }) =>
        dispatch({
          type: types.DHS_HOME_UPD,
          payload: res.data,
        })
      )
      .catch((error) => console.log(error));
  };

export const DeleteHomepage =
  (HomePage: { MA_CTY: string; MENUID: string }) =>
    (dispatch: any, getState: any) => {
      callApi("api/HomePage/DeleteHomePage", "POST", HomePage)
        .then((res: { data: any }) =>
          dispatch({
            type: types.DHS_HOME_DEL,
            payload: res.data,
          })
        )
        .catch((error) => console.log(error));
    };
export const DeleteImage =
  (img: { IMGNAME: string | undefined }) => (dispatch: any, getState: any) => {
    callApi("api/HomePage/DeleteImages", "POST", img)
      .then((res: { data: any }) =>
        dispatch({
          type: types.DELETE_IMAGE,
          payload: res.data,
        })
      )
      .catch((error) => console.log(error));
  };

export const UploadImage =
  (IMAGEFILE: any) => (dispatch: any, getState: any) => {
    callApi("api/HomePage/UploadImages", "POST", IMAGEFILE)
      .then((res: { data: any }) =>
        dispatch({
          type: types.UPLOAD_IMAGE,
          payload: res.data,
        })
      )
      .catch((error) => console.log(error));
  };

export const GetAllImage = () => (dispatch: any, getState: any) => {
  callApi("api/HomePage/GetAllImages", "GET")
    .then((res: { data: any }) =>
      dispatch({
        type: types.GET_ALL_IMAGE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const CusRequestGet = () => (dispatch: any, getState: any) => {
  callApi("/api/Homepage/getLstCusRequest", "GET")
    .then((res: { data: any }) =>
      dispatch({
        type: types.DHS_CUS_REQUEST_GET,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const clearDetail = () => (dispatch: any, getState: any) => {
  dispatch({
    type: types.CLEAR_DETAIL,
  });
};
export const clearHome = () => (dispatch: any, getState: any) => {
  dispatch({
    type: types.CLEAR_HOME,
  });
};
