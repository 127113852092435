import React from 'react'
import { QLCCTaskModel } from '../../../shared/service-proxies/api-shared'
import moment from 'moment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
interface IProps {
    task: QLCCTaskModel
    handleView: (id: string) => void;
}

const TaskMiniSize = (props: IProps) => {
    const { task } = props;

    const handlClick = () => {
        if (task.id) {
            props.handleView(task.id);
        }
    }


    return (
        <div className="card-task-detail d-flex justify-content-between">
            <div className="card-task-detail-right">
                <div className="header-task-detail">
                    {/* <div className="image-task-detail">
                    
                </div> */}
                    <AccountCircleIcon className="image-task-detail" />
                    <div>
                        <p className="name-task-detail">
                            {`${task && task.receiver_name ? task.receiver_name : task && task.receiver ? task.receiver : task.sender!}`
                                + ` - ${task && task.department_name ? task.department_name : "Không thuộc quản lý"}`
                            }
                        </p>
                    </div>
                </div>

                <div className="message-task-detail">
                    {
                        // task.description ? <div dangerouslySetInnerHTML={{__html: task.description}} /> : ""
                    }
                    {/* {
                        task.is_done ? (
                            <p> Đã hoàn thành công việc</p>
                        ) : <p> Đã nhận công việc</p>
                    } */}
                    <p><span className="text-capitalize">{`${task.receiver_name ? task.receiver_name : ""}`}</span><span className='text-lowercase'>{` ${task.status_name}`}</span></p>
                    <p className='d-flex justify-content-between'>
                        <span><small>{`Giao việc: ${moment(task.ngay_ct).fromNow()} - Trạng thái công việc: ${task.status_name ? task.status_name : "Chưa nhận được công việc"}`}</small></span>
                    </p>
                </div>
            </div>

            <button className='view-task-detail d-flex justify-content-center align-items-center'
                onClick={handlClick}
            >
                <div className="view-task-detail-child">
                    <span>Xem chi tiết</span>
                </div>
            </button>

            {/* <div className='view-task-detail d-flex justify-content-center align-items-center'
                onClick={handlClick}
            >
                <div className="view-task-detail-child">
                    <span>Xem chi tiết</span>
                </div>
            </div> */}
        </div>
    )
}

export default TaskMiniSize