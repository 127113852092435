import React from "react";
import { useParams } from "react-router-dom";
import RichTextEditor from "../../../../../components/DHS/DHS_RichTextEditor";
import { AppSession } from "../../../../shared/app-session/app-session";
import {
  DOCClient,
  DocUserGuideProduction,
  DocUserGuideProductionFeature,
  DocUserGuideProductionModule,
  RoleRightWebModel,
} from "../../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../shared/service-proxies/config";
import Select from "../../../../../components/DHS/DHS_Select";
import notification from "../../../../../common/notification/notification";

interface IProps {
  id?: string;
  action: RoleRightWebModel | undefined;
  nowTime: Date;
  onDestroyAction?: (isDestroy: boolean, goBack?: number) => void;
}
var appSession: AppSession;
var htmlString: string = "";
const FeatureActionPage = (props: IProps) => {
  const docClient = new DOCClient(appSession, BASE_URL_API);
  const [data, setData] = React.useState<DocUserGuideProductionFeature>(
    new DocUserGuideProductionFeature()
  );
  const [back, setBack] = React.useState<number>(-1);
  const [{ _id }, setId] = React.useState(useParams<{ _id: string }>());
  const [_productId, setProductId] = React.useState<string>("");
  const [productOptions, setProductOptions] = React.useState<
    DocUserGuideProduction[]
  >([]);
  const [moduleOptions, setModuleOptions] = React.useState<
    DocUserGuideProductionModule[]
  >([]);
  const [featureOptions, setFeatureOptions] = React.useState<
    DocUserGuideProductionFeature[]
  >([]);

  React.useEffect(() => {
    if (_id) {
      docClient
        .docUserGuideProductionFeatureGet({
          ...new DocUserGuideProductionFeature(),
          id: _id,
        } as DocUserGuideProductionFeature)
        .then((response) => {
          setData(response[0] as DocUserGuideProductionFeature);
          setProductId(response[0].product_id ? response[0].product_id : "");
        });
    }
  }, []);

  React.useEffect(() => {
    const getAllProductData = () => {
      docClient
        .docUserGuideProductionGet(new DocUserGuideProduction())
        .then((res) => {
          setProductOptions(res);
        })
        .catch((error) => console.log(error));
    };
    const getAllModuleData = () => {
      docClient
        .docUserGuideProductionModuleGet(new DocUserGuideProductionModule())
        .then((res) => {
          setModuleOptions(res);
        })
        .catch((error) => console.log(error));
    };
    const getAllFeatureData = () => {
      docClient
        .docUserGuideProductionFeatureGet(new DocUserGuideProductionFeature())
        .then((res) => setFeatureOptions(res))
        .catch((error) => console.log(error));
    };
    getAllModuleData();
    getAllFeatureData();
    getAllProductData();
  }, []);

  React.useEffect(() => {
    if (
      props.action &&
      props.action.class_name?.toLocaleUpperCase() === "SAVE"
    ) {
      try {
        if (_id) {
          docClient
            .docUserGuideProductionFeatureUpd({
              ...data,
              contents: htmlString,
              id: _id,
            } as DocUserGuideProductionFeature)
            .then((res) => {
              setBack((item) => (item -= 1));
              if (res.ret === 0) {
                notification("success", "Cập nhật thành công");
              } else notification("error", "Cập nhật thất bại");
            });
        } else {
          docClient
            .docUserGuideProductionFeatureIns({
              ...data,
              contents: htmlString,
            } as DocUserGuideProductionFeature)
            .then((res) => {
              if (res.ret === 0 && res.id) {
                setId({ _id: res.id });
                notification("success", "Thêm thành công");
              } else notification("error", "Thêm thất bại");
            });
        }
        if (props.onDestroyAction) props.onDestroyAction(true, back - 1);
      } catch (error) {
        console.log(error);
      }
    }
  }, [props.nowTime]);

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value } as DocUserGuideProductionFeature);
  };
  const handleChangeProductSelect = (e: any) => {
    setProductId(e.target.value);
  };
  const handleChangeRichText = (stringData: string) => {
    htmlString = stringData;
  };

  return (
    <>
      <div
        className="d-flex p-3 justify-content-left"
        style={{ backgroundColor: "#FFF" }}
      >
        <div className="w-100">
          <div className="p-3 d-flex justify-content-left container">
            <div className="w-100 h-100">
              <div className="form-group">
                <label>Tên</label>
                <input
                  className="flex-grow-1 form-control"
                  type="text"
                  name="name"
                  placeholder="Tên"
                  onChange={handleChangeInput}
                  value={data.name}
                />
              </div>
              <div className="form-group">
                <label>Mô tả</label>
                <textarea
                  className="flex-grow-1 form-control"
                  name="description"
                  placeholder="Mô tả"
                  onChange={handleChangeInput}
                  value={data.description}
                />
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-4">
                    <Select
                      options={productOptions}
                      label="Thuộc về sản phẩm"
                      onChange={handleChangeProductSelect}
                      value={_productId}
                      fieldValue={"id"}
                      fieldDisplay={"name"}
                      name=""
                    />
                  </div>
                  <div className="col-4">
                    <Select
                      options={moduleOptions?.filter(
                        (module) => module.product_id === _productId
                      )}
                      label="Thuộc về Module"
                      onChange={handleChangeInput}
                      value={data.module_id}
                      fieldValue={"id"}
                      fieldDisplay={"name"}
                      name="module_id"
                      disabled={_productId ? false : true}
                    />
                  </div>
                  <div className="col-4">
                    <Select
                      options={featureOptions.filter(
                        (item) => item.module_id === data.module_id
                      )}
                      label="Thuộc về tính năng"
                      onChange={handleChangeInput}
                      value={data.parent_id}
                      fieldValue={"id"}
                      fieldDisplay={"name"}
                      name="parent_id"
                      disabled={data.module_id ? false : true}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Level</label>
                <input
                  className="flex-grow-1 form-control"
                  type="text"
                  name="levels"
                  placeholder="Cấp độ"
                  onChange={handleChangeInput}
                  value={data.levels}
                />
              </div>
              <br />
              <RichTextEditor
                onChange={(stringData) => handleChangeRichText(stringData)}
                value={data.contents}
              />
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeatureActionPage;
