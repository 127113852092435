import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IReducer } from "../../../../../common/Interfaces/Interfaces";
import DHSToolbar from "../../../../../components/DHS/DHS-Toolbar/DHSToolbar";
import { RoleRightWebModel } from "../../../../shared/service-proxies/api-shared";
import ApproveFormAction from "./ApproveFormAction";

interface IProps {
  listToolbar?: RoleRightWebModel[];
  rowSelected?: string | undefined;
  action: RoleRightWebModel;
}

const ApproveFormPage = (props: IProps) => {
  const role = useSelector((state: IReducer) => state.RoleReducer?.role);
  const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
  const [action, setAction] = React.useState<RoleRightWebModel | undefined>();
  const [nowTime, setNowTime] = React.useState<Date>(new Date());
  const history = useHistory();
  const [back, setBack] = React.useState<number>(-1)
  const [form, setForm] = React.useState<string>("");

  React.useEffect(() => {
    if (role?.component?.toLocaleUpperCase() === "HRAPPROVEGRID") {
      setForm("HRAPPROVEEDIT");
    } else if (role?.component?.toLocaleUpperCase() === "APPROVEGRID")
      setForm("APPROVEEDIT");
  }, [role]);
  
  const handleClickAction = (action: RoleRightWebModel, nowTime: Date) => {
    setAction(action);
    setNowTime(nowTime);
  };
  const handleDestroyAction = (isDestroy: boolean, goBack?: number) => {
    if (isDestroy) setAction(undefined);
    if(goBack) setBack(goBack);
  };

  return (
    <>
      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          fontSize: 22,
          fontWeight: 550,
          marginTop: 15,
          marginBottom: 15,
        }}
      >
        <i
          className="bi bi-arrow-left"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.go(back);
          }}
        ></i>
        &nbsp;&nbsp;
        {"Chi tiết phiếu nghỉ phép"}
      </div>{" "}
      <DHSToolbar
        list={
          roles
            ? roles.filter(
                (item) => item.on_form?.toLocaleUpperCase() === form
              )
            : []
        }
        menuid={""}
        id={""}
        current_form={""}
        onClickAction={handleClickAction}
      />
      <ApproveFormAction
        id={props.rowSelected}
        action={action as RoleRightWebModel}
        nowTime={nowTime}
        onDestroyAction={handleDestroyAction}
      />
    </>
  );
};

export default ApproveFormPage;
