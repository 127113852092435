import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    DialogTitle,
    Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    CtxDanhMucXeModel,
    HomeClient,
    KhoanCangClient,
    QLCCApartmentModel,
    QLCCCitizenIdentificationCardsModel,
    QLCCClient,
    QLCCResidentJobsModel,
    QLCCResidentsModel,
    RoleRightWebModel,
    SysActionType,
    SysModel,
} from "../../../shared/service-proxies/api-shared";

import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import '../ApartmentBuilding.css';
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Webcam from "react-webcam";
import CTXDate from "../../../../components/CTX/DatePicker";
import CitizenIdentificationCardAction from "../citizen-identification-card-action/CitizenIdentificationCardAction";
import notification from "../../../../common/notification/notification";
import DHSAutoCompleteApartment from "../../../../components/DHS/DHS_AutoCompleteApartment/DHSAutoCompleteApartment";
import { Switch } from "antd";
import axios from "axios";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import { TransDateTime } from "../../../../components/utils/date.util";
import DHSUploadImage from "../../../../components/DHS/DHS-Upload-Image/DHSUploadImage";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";


interface IProps {
    open: boolean;
    itemSelected?: any;
    title?: any;
    action?: any;
    apartment_id?: any,
    floor_id?: any,
    action_type?: string;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (data: SysModel) => void;

}
interface IOpenCamera {
    pic: any;
    open: boolean;
}

const videoConstraints = {
    width: 240,
    height: 320,
    facingMode: "user",
};


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});
interface IAction {
    type: string;
    open: boolean;
    payload: any;
}
const defaultAction: IAction = {
    type: "",
    payload: null,
    open: false,
};


const action_type_props: any = {
    "OWNER_APARTMENT": true,
    "MEMBER_APARTMENT": true
}



var appSession: AppSession;
const status_message: string[] = ['success', 'error']
const ResidentAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)


    const [_id, setId] = React.useState<string | undefined>(props.itemSelected?.id);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);

    const khoangCangClient = new KhoanCangClient(appSession, BASE_URL_API);

    const [data, setData] = React.useState<QLCCResidentsModel>();
    const [residentType, setResidentType] = React.useState<string | undefined>("");
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [reload, setReload] = React.useState<boolean>(false);

    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [action, setAction] = React.useState<IProps>(props);


    // 



    const [genderOptions, setGenderOptions] = React.useState<any>([
        {
            id: 'C475363E-DF24-4B63-A309-B17D904247DE',
            name: 'Nam'
        },
        {
            id: '4A1EE721-9D76-484F-BA37-BDBC351B74C7',
            name: 'Nữ'
        },
        {
            id: 'C11C7324-6837-45A2-9227-5DA75107461E',
            name: 'Không muốn nói'
        },
    ]);

    const [residentStatusOptions, setResidentStatusOptions] = React.useState<any>([]);
    const [jobOptions, setJobOptions] = React.useState<QLCCResidentJobsModel[]>([]);
    const [apartmentOptions, setApartmentOptions] = React.useState<QLCCApartmentModel[]>([]);
    const [citizenIdentificationCardOptions, setCitizenIdentificationCardOptions] = React.useState<any>([]);
    const [relationshipOptions, setRelationshipOptions] = React.useState<any[]>([
        {
            id: "1",
            name: "Chồng"
        },
        {
            id: "2",
            name: "Vợ"
        },
        {
            id: "3",
            name: "Ông nội"
        },
        {
            id: "4",
            name: "Bà nội"
        },
        {
            id: "5",
            name: "Ông ngoại"
        },
        {
            id: "6",
            name: "Cô"
        },
        {
            id: "7",
            name: "Dượng"
        },
        {
            id: "8",
            name: "Chú"
        },
        {
            id: "9",
            name: "Thím"
        },
        {
            id: "10",
            name: "Dì"
        },
        {
            id: "11",
            name: "Cậu"
        },
        {
            id: "12",
            name: "Mợ"
        },
    ]);

    const [typeOptions, setTypeOptions] = React.useState<any[]>([
        {
            id: 'Chủ hộ',
            name: 'Chủ hộ'
        },
        {
            id: 'Thành viên',
            name: 'Thành viên'
        },
    ]);

    const [statusOptions, setStautusOptions] = React.useState<any[]>([
        // {
        //     id: 'F6C1E73D-6A56-4674-8F25-F78A2AA38787',
        //     name: 'Đã kí hợp đồng'
        // },
        // {
        //     id: 'C6116B94-3E60-4700-B7FE-C59DC161F54F',
        //     name: 'Đang ở'
        // },
        // {
        //     id: '60F90A7A-06D5-42C2-B44B-BE7F32EF7E75',
        //     name: 'Không còn ở'
        // },
    ]);

    const [actCic, setActCic] = React.useState<IAction>(defaultAction);

    const webcamRef = React.useRef<any>(null);

    const [openCamera, setOpenCamera] = React.useState<IOpenCamera>({
        pic: "",
        open: false,
    });

    const handleCloseCamera = () => {
        setOpenCamera({ ...openCamera, pic: "", open: false });
    };

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef?.current?.getScreenshot();
        /*fileClient
            .postFile_v4([imageSrc.replace("data:image/jpeg;base64,", "")])
            .then((res) => {
                if (res) {
                    let tempArr: string[] = [];
                    res.forEach((path: any, pathIndex: number) => {
                        tempArr.push(path);
                    });
                    switch (openCamera.pic) {
                        case "hinh_luc_vao":
                            setListHinhLucVao([...listHinhLucVao].concat(tempArr));
                            break;
                        case "hinh_luc_ra":
                            setListHinhLucRa([...listHinhLucRa].concat(tempArr));
                            break;
                    }
                } else notification("warning", "Không lấy được hình ảnh");
            });*/

        if (imageSrc) {

            setData({
                ...data,
                [openCamera.pic]: imageSrc
            } as QLCCResidentsModel)
        }
        // setOpenCamera({ ...openCamera, pic: "", open: false });
    }, [webcamRef, openCamera.pic]);

    // React.useEffect(() => {

    //     setLoading(true);
    //     homeClient
    //         ?.roleRightWebGetAll({
    //             ...new RoleRightWebModel(),
    //             rolename: "CC",
    //             username: SessionLogin.userName,
    //             menuid: menu?.menuid,
    //         } as RoleRightWebModel)
    //         .then((res) => {
    //             setRoles(res);
    //             setLoading(false);
    //         });
    //     setLoading(true);

    // }, []);

    // React.useMemo(() => {
    //     setData({
    //         ...data,
    //         image_path: `https://media-cdn-v2.laodong.vn/storage/newsportal/2021/1/26/874344/Can-Cuoc-Cong-Dan-Ga.jpg`
    //     })
    // }, [data.image_path])

    React.useEffect(() => {
        setLoading(true);
        if (props?.apartment_id) {
            setData(pre => ({ ...pre, apartment_id: props.apartment_id, } as QLCCResidentsModel));
        }
        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            qLCCClient.qLCCResidentsGet({
                ...new QLCCResidentsModel(),
                id: props.itemSelected?.id,
                ma_cty: props.itemSelected?.ma_cty,
                apartment_building_id: apartment_building_id,
            } as QLCCResidentsModel)
                .then(res => {
                    setData(res[0]);
                    setResidentType(res[0].type)

                    setReload(false)
                }).catch(() => alert("Không lấy được dữ liệu"));

        }
        else {
            setData({
                ...new QLCCResidentsModel(),
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: apartment_building_id,
                apartment_id: props.apartment_id,
                is_water: true,
            } as QLCCResidentsModel);
            setId('')
            setLoading(false);
        }
    }, [props.open, props.apartment_id]);

    // Load lần đầu tiên
    React.useEffect(() => {

        setLoading(true);
        qLCCClient.qLCCResidentJobsActions({
            ...new QLCCResidentJobsModel(),
            ma_cty: SessionLogin?.ma_cty,
            apartment_building_id: apartment_building_id,
            action_type: 'GET'
        } as QLCCResidentJobsModel)
            .then(res => {
                setJobOptions(res)
                setLoading(false);
            });


        setLoading(true);
        khoangCangClient.kcDanhMucActions({
            ...new CtxDanhMucXeModel(),
            tbname: "QLCCResidentStatus",
            action: "GET"
        } as CtxDanhMucXeModel)
            .then(res => {
                setResidentStatusOptions(res)
                setLoading(false);
            });
    }, []);


    // Khi thêm mới CCCD nêu thành công thì reload lại
    React.useMemo(() => {

        setLoading(true);
        qLCCClient.qLCCCitizenIdentificationCardsActions({
            ...new QLCCCitizenIdentificationCardsModel(),
            ma_cty: SessionLogin?.ma_cty,
            apartment_building_id: apartment_building_id,
            action_type: 'GET'
        } as QLCCCitizenIdentificationCardsModel)
            .then(res => {
                setCitizenIdentificationCardOptions(res)
                setLoading(false);
            })


    }, [reload]);

    const showCamera = (e: any, file: any) => {
        setOpenCamera({
            ...openCamera,
            pic: file,
            open: true,
        });
    };

    const onChangeChecked = (checked: boolean) => {

        setData(pre => ({
            ...pre,
            'is_water': checked,
        } as QLCCResidentsModel));
    };

    const handleSubmit = () => {

        switch (props?.action.toUpperCase()) {
            case ACTIONS.ADD:
                if (props.apartment_id) {
                    setLoading(true);
                    qLCCClient.qLCCResidentsIns({
                        ...new QLCCResidentsModel(),
                        ...data,
                        date_of_birth: data?.date_of_birth ? TransDateTime(data?.date_of_birth) : undefined,
                        move_in_date: data?.move_in_date ? TransDateTime(data?.move_in_date) : undefined,
                        move_out_date: data?.move_out_date ? TransDateTime(data?.move_out_date) : undefined,
                        apartment_id: props.apartment_id,
                        apartment_building_id: apartment_building_id,
                        action_type: (data?.type !== null && data?.type === "Chủ hộ") ? "OWNER_APARTMENT" : "MEMBER_APARTMENT"
                    } as QLCCResidentsModel).then(res => {
                        setLoading(false);
                        notification(status_message[res.status!], res.message);
                        if (res.status === 0) {
                            setId(res.ref_id)
                            if (props.action_type === "MEMBER_APARTMENT") setAction(pre => ({ ...pre, open: false }));
                            // else setAction({ ...action, itemSelected: res.ref_id, action: ACTIONS.EDIT })
                            setData({ ...new QLCCResidentsModel(), apartment_id: data?.apartment_id } as QLCCResidentsModel);
                            props.onEditSuccess(res);
                            props.onClose();
                        }
                    }).catch(error => setLoading(false));
                } else {
                    setLoading(true);
                    qLCCClient.qLCCResidentsIns({
                        ...new QLCCResidentsModel(),
                        ...data,
                        apartment_building_id: apartment_building_id,
                        date_of_birth: data?.date_of_birth ? TransDateTime(data?.date_of_birth) : undefined,
                        move_in_date: data?.move_in_date ? TransDateTime(data?.move_in_date) : undefined,
                        move_out_date: data?.move_out_date ? TransDateTime(data?.move_out_date) : undefined,
                        action_type: (data?.type !== null && data?.type === "Chủ hộ") ? "OWNER_APARTMENT" : "MEMBER_APARTMENT"
                    } as QLCCResidentsModel).then(res => {
                        setLoading(false);
                        notification(status_message[res.status!], res.message);
                        if (res.status === 0) {
                            setId(res.ref_id)
                            if (props.action_type === "MEMBER_APARTMENT") setAction(pre => ({ ...pre, open: false }));
                            // else setAction({ ...action, itemSelected: res.ref_id, action: ACTIONS.EDIT })
                            setData({ ...new QLCCResidentsModel(), apartment_id: data?.apartment_id } as QLCCResidentsModel);
                            props.onEditSuccess(res);
                            props.onClose();
                        }
                    }).catch(error => setLoading(false));
                }
                break;
            case ACTIONS.EDIT:
                if (props.itemSelected?.id && props.apartment_id) {
                    setLoading(true);
                    qLCCClient.qLCCResidentsUpd({
                        ...new QLCCResidentsModel(),
                        ...data,
                        date_of_birth: data?.date_of_birth ? TransDateTime(data?.date_of_birth) : undefined,
                        move_in_date: data?.move_in_date ? TransDateTime(data?.move_in_date) : undefined,
                        move_out_date: data?.move_out_date ? TransDateTime(data?.move_out_date) : undefined,
                        apartment_id: props.apartment_id,
                        action_type: residentType !== data?.type ? (data?.type !== null && data?.type === "Chủ hộ") ? "OWNER_APARTMENT" : "MEMBER_APARTMENT" : "",
                        id: props.itemSelected?.id
                    } as QLCCResidentsModel)
                        .then(res => {
                            setLoading(false);
                            notification(status_message[res.status!], res.message);
                            if (res.status === 0) {
                                setData(new QLCCResidentsModel());
                                props.onEditSuccess(res);
                                props.onClose();
                            }
                        }).catch(error => {
                            notification(status_message[error.status!], error.message);
                            setLoading(false)
                        });
                }
                else {
                    setLoading(true);
                    qLCCClient.qLCCResidentsUpd({
                        ...new QLCCResidentsModel(),
                        ...data,
                        date_of_birth: data?.date_of_birth ? TransDateTime(data?.date_of_birth) : undefined,
                        move_in_date: data?.move_in_date ? TransDateTime(data?.move_in_date) : undefined,
                        move_out_date: data?.move_out_date ? TransDateTime(data?.move_out_date) : undefined,
                        id: props.itemSelected?.id
                    } as QLCCResidentsModel)
                        .then(res => {
                            setLoading(false);
                            notification(status_message[res.status!], res.message);
                            if (res.status === 0) {
                                setData(new QLCCResidentsModel());
                                props.onEditSuccess(res);
                                props.onClose();
                            }
                        }).catch(error => {
                            notification(status_message[error.status!], error.message);
                            setLoading(false)
                        });
                }
                break;
        }
    };

    const handleChangeInput = (e: any) => {
        const { name, value, type, checked } = e.target;


        if (type === 'checkbox') {
            setData({ ...data, [name]: checked } as QLCCResidentsModel);

        }
        else setData({ ...data, [name]: value } as QLCCResidentsModel);

    };

    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        setData({ ...data, [name]: new Date(value) } as QLCCResidentsModel);
    }

    const handleRemoveImage = (e: any) => {
        const { name } = e;

        if (name) {
            setData({
                ...data,
                [name]: ''
            } as QLCCResidentsModel)
        }
    }

    const handleChangeImage = async (e: any) => {

        const { name, files } = e.target;
        const formData = new FormData();
        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };
        for (const file of files) {
            formData.append("MyFile", file);
        }
        setLoading(true)

        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    setLoading(false)
                    setData(pre => ({ ...pre, [name]: res.data[0].path } as QLCCResidentsModel))
                } else notification("warning", "Không lấy được hình ảnh");
            });

        e.target.value = '';

    }

    const handleAddNewImage = async (e: any) => {

        const { name, files } = e.target;
        const formData = new FormData();
        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };
        for (const file of files) {
            formData.append("MyFile", file);
        }
        setLoading(true)

        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    setLoading(false)
                    setData(pre => ({ ...pre, [name]: res.data[0].path } as QLCCResidentsModel))
                } else notification("warning", "Không lấy được hình ảnh");
            });

        e.target.value = '';
    }

    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                // handleSubmit();             
                handleSubmit()
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
        }
    }


    return (
        <Dialog
            id="sysMenu"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "flex-end",
            }}
            TransitionComponent={Transition}
            maxWidth='xs'

        >
            {
                actCic.open && <CitizenIdentificationCardAction
                    open={actCic.open}
                    onClose={function (): void {
                        setActCic(defaultAction);
                    }}
                    action={actCic.type}
                    itemSelected={actCic.payload}
                    action_type={props.action_type}
                    title="Căn cước công dân"
                    onAddedSuccess={function (id?: string, data?: QLCCCitizenIdentificationCardsModel): void {
                        setReload(pre => !pre)
                        setData(pre => ({ ...pre, citizen_identification_card_id: data?.id } as QLCCResidentsModel))
                    }} >
                </CitizenIdentificationCardAction>
            }

            <Dialog
                open={openCamera.open}
                onClose={handleCloseCamera}
                sx={{ height: "inherit" }}
            >
                <DialogTitle>Camera</DialogTitle>
                <DialogContent>
                    <div className="webcam-container">
                        <Webcam
                            audio={false}
                            height={320}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            width={240}
                            mirrored
                            videoConstraints={videoConstraints}
                        />

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={capture}>Chụp</Button>
                    <Button onClick={handleCloseCamera}>Đóng</Button>
                </DialogActions>
            </Dialog>

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {(props.title ?? menu?.bar) +
                            " " +
                            `${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === "UPDATE" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >

                <div className="row">

                    <div className="col-xl-4 mt-2">

                        {/* Căn hộ */}
                        {
                            (!action_type_props[props.action_type!]) && <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Căn hộ</InputLabel>
                                {
                                    <DHSAutoCompleteApartment
                                        id="apartment-select"
                                        value={
                                            data?.apartment_id ?? null
                                        }
                                        name="apartment_id"
                                        onChange={(e, newValue: any) => {

                                            if (newValue) {
                                                handleChangeInput({
                                                    target: {
                                                        name: "apartment_id",
                                                        value: newValue?.id,
                                                    },
                                                });
                                            }
                                        }}

                                        disabled={props.action === ACTIONS.VIEW}
                                    />
                                }

                            </Box>
                        }


                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Họ và tên đệm</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                autoFocus
                                name="last_name"
                                onChange={handleChangeInput}
                                value={data.last_name}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box> */}

                        {/* Tên */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Họ và tên</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                name="full_name"
                                autoComplete="new-password"
                                onChange={handleChangeInput}
                                value={data?.full_name}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box>
                        {/* Ngày sinh */}
                        <Box sx={{ width: '100%', margin: '5px 0' }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày sinh</InputLabel>
                            <CTXDate
                                className="cc-input"
                                style={{ width: '100%', padding: '0 5px !important' }}
                                name="date_of_birth"
                                onChange={handleChangeDate}
                                value={data?.date_of_birth ?? null}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box>

                        {/* Giới tính */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Giới tính</InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="gender-select"

                                dataSource={genderOptions}
                                displayMember="name"
                                valueMember="name"
                                value={data?.gender}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "gender",
                                            value: newValue?.name ? newValue.name : null,
                                        },
                                    });
                                }}
                                disabled={props.action === ACTIONS.VIEW}
                            /> 

                        </Box>

                        {/* Nơi sinh */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Nơi sinh</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                name="birth_place"
                                onChange={handleChangeInput}
                                value={data?.birth_place}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box>

                        {/* Quên quán */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Quên quán</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                name="native_place"
                                onChange={handleChangeInput}
                                value={data.native_place}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box> */}

                        {/* Chỗ ở hiện tại */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Chỗ ở hiện tại</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                name="current_residence"
                                onChange={handleChangeInput}
                                value={data.current_residence}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box> */}

                        {/* Khu vực */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Khu vực</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                placeholder="Thành phố Hồ Chí Minh"
                                name="area"
                                onChange={handleChangeInput}
                                value={data.area}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box> */}


                        {/* Nghề nghiệp */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Nghề nghiệp</InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="job-select"
                                dataSource={jobOptions}
                                displayMember="job_name"
                                valueMember="id"
                                value={data?.job_id}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "job_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}


                                disabled={props.action === ACTIONS.VIEW}
                            /> 

                        </Box>





                    </div>

                    {/* column 2 */}
                    <div className="col-xl-4 mt-2">




                        {/* CCCD/CMT */}
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>CCCD/CMT</InputLabel>
                                <DHSAutoCompleteWithDataSource
                                    id="citizen-identification-card-select"
                                    dataSource={citizenIdentificationCardOptions}
                                    displayMember="citizen_identification_number"
                                    valueMember="id"
                                    value={data?.citizen_identification_card_id}
                                    onValueChanged={(e, newValue: any) => {
                                        handleChangeInput({
                                            target: {
                                                name: "citizen_identification_card_id",
                                                value: newValue?.id ? newValue.id : null,
                                            },
                                        });
                                    }}


                                    disabled={props.action === ACTIONS.VIEW}
                                /> 

                            </Box>
                            <Box sx={{ paddingTop: '14pt' }}>
                                <IconButton
                                    style={{ height: "24px", width: "24px", color: "#1890ff", padding: "0 1rem" }}
                                    onClick={() =>
                                        setActCic(pre => ({
                                            ...pre,
                                            type: "ADD",
                                            payload: "",
                                            open: true,
                                        }))
                                    }
                                    disabled={props.action === ACTIONS.VIEW}
                                >
                                    <AddCircleOutlinedIcon
                                        style={{ height: "inherit", width: "inherit" }}
                                    />
                                </IconButton>
                            </Box>
                        </Box>

                        {/* Quốc tịch */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Quốc tịch</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                name="nationality"
                                onChange={handleChangeInput}
                                value={data?.nationality}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box>

                        {/* Quốc tịch khác
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Quốc tịch khác</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                name="other_nationality"
                                onChange={handleChangeInput}
                                value={data?.other_nationality}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box> */}

                        {/* Dân tộc*/}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Dân tộc</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                name="nation"
                                onChange={handleChangeInput}
                                value={data?.nation}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box>

                        {/* Tôn giáo */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tôn giáo</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                name="religion"
                                onChange={handleChangeInput}
                                value={data?.religion}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box>

                        {/* Số điện thoại */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số điện thoại</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                name="phone"
                                type="phone"
                                autoComplete="new-password"
                                onChange={(e) => {
                                    if (e.target.value.length > 10) {
                                        // setErrorText("Số CMT/CCCD lớn hơn 12.");
                                    } else {
                                        handleChangeInput(e);

                                    }
                                }}
                                value={data?.phone}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box>

                        {/* Số điện thoại khác
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số điện thoại khác</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                name="other_phone"
                                type="phone"
                                autoComplete="off"
                                onChange={handleChangeInput}
                                value={data?.other_phone}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box> */}

                        {/* Email */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Email</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                name="email"
                                type="email"
                                autoComplete="new-password"
                                onChange={handleChangeInput}
                                value={data?.email}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box>


                        {/* Email khác
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Email khác</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                name="other_email"
                                type="email"
                                autoComplete="off"

                                onChange={handleChangeInput}
                                value={data?.other_email}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box> */}

                        {/* Số hộ chiếu
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số hộ chiếu</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                name="passport_number"
                                onChange={handleChangeInput}
                                value={data?.passport_number}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box> */}
                    </div>


                    <div className="col-xl-4 mt-2">
                        {/* Mã số thuế
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mã số thuế</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                name="tax_code"
                                onChange={handleChangeInput}
                                value={data?.tax_code}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box> */}

                        {/* Loại */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Loại</InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="type-select"

                                dataSource={typeOptions}
                                displayMember="name"
                                valueMember="name"
                                value={data?.type}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "type",
                                            value: newValue?.name ? newValue.name : null,
                                        },
                                    });

                                }}

                                disabled={props.action === ACTIONS.VIEW}
                            /> 

                        </Box>

                        {/* Trạng thái */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Trạng thái</InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="status-select"
                                
                                dataSource={residentStatusOptions}
                                displayMember="name"
                                valueMember="id"
                                value={  data?.status_id}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "status_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}

                                disabled={props.action === ACTIONS.VIEW}
                            /> 

                        </Box>


                        {/* Ngày chuyển đến */}
                        <Box sx={{ width: '100%', margin: '5px 0' }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày chuyển đến</InputLabel>
                            <CTXDate
                                className="cc-input"
                                style={{ width: '100%', padding: '0 5px !important' }}
                                name="move_in_date"
                                onChange={handleChangeDate}
                                value={data?.move_in_date ?? null}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box>

                        {/* Ngày đi */}
                        <Box sx={{ width: '100%', margin: '5px 0' }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày chuyển đi</InputLabel>
                            <CTXDate
                                className="cc-input"
                                style={{ width: '100%', padding: '0 5px !important' }}
                                name="move_out_date"
                                onChange={handleChangeDate}
                                value={data?.move_out_date ?? null}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box>

                        <Box sx={{ width: '100%', margin: '5px 0' }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                Đăng kí định mức nước
                                <Switch
                                    checked={data?.is_water}
                                    onChange={onChangeChecked}
                                    disabled={props.action === ACTIONS.VIEW}
                                />
                            </InputLabel>

                        </Box>

                        {/* <FormControlLabel sx={{ padding: '4.5px' }} control={<Switch color="primary" />} label={<Typography sx={{ fontSize: 14, color: '#333' }}>Vợ</Typography>} /> */}

                        {/* Quen hệ với chủ hộ */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Quan hệ với chủ hộ</InputLabel>
                            <Autocomplete
                                id="status-select"
                                sx={{ width: "100%" }}
                                options={relationshipOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.relationship
                                        ? relationshipOptions.find(
                                            (item: any) => item.name === data.relationship
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "relationship",
                                            value: newValue?.name ? newValue.name : null,
                                        },
                                    });
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box> */}



                        {/* Vợ chồng */}
                        {/* <Box className="row">
                            <Box className="col-6">


                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Chồng</InputLabel>

                                <FormControlLabel control={
                                    <Switch
                                        color="primary"
                                        onChange={handleChangeInput}
                                        name="husband"
                                        value={data.husband}
                                        checked={data.husband}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />}
                                    label={
                                        <Typography
                                            sx={{ fontSize: 14, color: '#333' }}>
                                            Có/Không
                                        </Typography>} />


                            </Box>


                            <Box className="col-6">


                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Vợ</InputLabel>

                                <FormControlLabel control={
                                    <Switch
                                        color="primary"
                                        onChange={handleChangeInput}
                                        name="wife" value={data.wife}
                                        checked={data.wife}
                                        disabled={props.action === ACTIONS.VIEW} />}
                                    label={
                                        <Typography
                                            sx={{ fontSize: 14, color: '#333' }}
                                        >
                                            Có/Không
                                        </Typography>} />

                            </Box>

                        </Box> */}


                        {/* Ông bà */}
                        {/* <Box >

                            <div className="row position-relative justify-content-between no-gutters">
                                <div className="col-5">
                                    <div>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ông bà</InputLabel>

                                        <FormControlLabel control={
                                            <Switch
                                                color="primary"
                                                onChange={handleChangeInput}
                                                name="grandparent"
                                                value={data.grandparent}
                                                checked={data.grandparent}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />}
                                            label={
                                                <Typography
                                                    sx={{ fontSize: 14, color: '#333' }}>Có/Không
                                                </Typography>
                                            } />

                                    </div>
                                </div>
                                <div className="col-6">

                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số lượng ông bà</InputLabel>

                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        type="number"
                                        name="grandparent_number"
                                        onChange={handleChangeInput}
                                        value={data.grandparent_number}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />
                                </div>

                            </div>

                        </Box> */}

                        {/* Con */}
                        {/* <Box>

                            <div className="row position-relative  justify-content-between no-gutters">
                                <div className="col-5">
                                    <div>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Con</InputLabel>

                                        <FormControlLabel control={
                                            <Switch
                                                color="primary"
                                                onChange={handleChangeInput}
                                                name="childrend"
                                                value={data.childrend}
                                                checked={data.childrend}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />}
                                            label={
                                                <Typography
                                                    sx={{ fontSize: 14, color: '#333' }}>
                                                    Có/Không
                                                </Typography>} />

                                    </div>
                                </div>
                                <div className="col-6 ">

                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số lượng con</InputLabel>

                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        type="number"
                                        name="childrend_number"
                                        onChange={handleChangeInput}
                                        value={data.childrend_number}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />
                                </div>

                            </div>

                        </Box> */}

                        {/* Bạn */}
                        {/* <Box >

                            <div className="row position-relative justify-content-between no-gutters">
                                <div className="col-5">
                                    <div>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Bạn</InputLabel>

                                        <FormControlLabel control={
                                            <Switch
                                                color="primary"
                                                disabled={props.action === ACTIONS.VIEW} />}
                                            label={
                                                <Typography
                                                    sx={{ fontSize: 14, color: '#333' }}>
                                                    Có/Không
                                                </Typography>} />

                                    </div>
                                </div>
                                <div className="col-6 ">

                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số lượng bạn</InputLabel>

                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        name="friend_number"
                                        type="number"
                                        onChange={handleChangeInput}
                                        value={data.friend_number}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />
                                </div>

                            </div>

                        </Box> */}




                        {/* <FormGroup >
                            <div className="row">
                                <div className="col-6">
                                    <FormControlLabel sx={{ padding: '4.5px' }} control={<Switch color="primary" defaultChecked />} label={<Typography sx={{ fontSize: 14, color: '#333' }}>Ông bà</Typography>} />
                                </div>
                                <div className="col-6">
                                    <FormControlLabel sx={{ padding: '4.5px' }} control={<Switch color="primary" />} label={<Typography sx={{ fontSize: 14, color: '#333' }}>Con cái</Typography>} />

                                </div>
                            </div>
                        </FormGroup>


                        <FormGroup >
                            <div className="row">
                                <div className="col-6">
                                    <FormControlLabel sx={{ padding: '4.5px' }} control={<Switch color="primary" defaultChecked />} label={<Typography sx={{ fontSize: 14, color: '#333' }}>Bạn bè</Typography>} />

                                </div>
                            </div>
                        </FormGroup> */}




                    </div>
                </div>


                <div>
                    {/* Hình cư dân */}
                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                        <div className="d-flex align-items-center">
                            <InputLabel sx={{ fontSize: 14 }}>
                                Hình cư dân
                            </InputLabel>
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                disabled={props.action === ACTIONS.VIEW}
                            >
                                <PhotoCamera
                                    id="image_path"
                                    name="image_path"
                                    onClick={(e: any) => showCamera(e, "image_path")}
                                />
                            </IconButton>
                        </div>
                        <DHSUploadImage
                            name="image_path"
                            value={data?.image_path}
                            width={"228px"}
                            height={"304px"}
                            handleAddNewImage={handleAddNewImage}
                            handleChangeImage={handleChangeImage}
                            handleRemoveImage={handleRemoveImage}
                            disable={props?.action === ACTIONS.VIEW}
                        />
                    </Box>
                </div>



            </DialogContent>
            <DialogActions>

                <DHSDialogAction

                    action={props.action}
                    menu={menu}
                    onClickAction={handleClickAction}
                />

            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default ResidentAction;


