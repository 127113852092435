import * as React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AppSession } from "../../shared/app-session/app-session";
import {
  ChoThueXeClient,
  CtxPhieuThueXeModel,
  CtxThongTinXeModel,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";

interface IData {
  name: string;
  [key: string]: any;
}

let appSession: AppSession;
const colors = [
  "#8834d8",
  "#7884d8",
  "#88f4d8",
  "#5284d8",
  "#8802d8",
  "#881df8",
];
const BaoCaoDoanhSo = () => {
  const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);
  const [listCar, setListCar] = React.useState<CtxThongTinXeModel[]>([]);
  const [listForm, setListForm] = React.useState<CtxPhieuThueXeModel[]>([]);

  const [data, setData] = React.useState<IData[]>();

  React.useEffect(() => {
    ctxClient.ctxPhieuThueXeGet(new CtxPhieuThueXeModel()).then((response) => {
      setListForm(response);
    });
    ctxClient
      .ctxBaoCaoDoanhSoTheoXe(new CtxPhieuThueXeModel())
      .then((response) => console.log(response));
    ctxClient.ctxThongTinXeGet(new CtxThongTinXeModel()).then((response) => {
      setListCar(response);
      let tempArr: IData[] = [];
      getDaysInMonth(new Date().getMonth(), new Date().getFullYear()).forEach(
        (day) => {
          let item: IData = { name: day.getDate().toString() };

          response.forEach((car) => {
            item = {
              ...item,
              [`${car.hang_xe_name} ${car.loai_xe_name}`]: Math.floor(
                Math.random() * 10
              ),
            };
          });
          tempArr.push(item);
        }
      );
      setData([...tempArr]);
    });
  }, []);

  const getDaysInMonth = (month: number, year: number) => {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };
  return (
    <LineChart
      width={1280}
      height={620}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      {listCar.map((car, index) => (
        <Line
          key={car.id}
          type="monotone"
          dataKey={`${car.hang_xe_name} ${car.loai_xe_name}`}
          stroke={colors[index]}
        />
      ))}
      {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
    </LineChart>
  );
};
export default BaoCaoDoanhSo;
