import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DHSToolbar from '../../../components/DHS/DHS-Toolbar/DHSToolbar';
import { IReducer, Notification } from '../../../components/layouts';
import DHSGridView from '../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
import { getMenuShowGrid, getRoles } from '../../../redux/actions/RoleActions';
import { AppSession } from '../../shared/app-session/app-session';
import {
    HomeClient,
    QLCCApartmentBuildingModel,
    QLCCBlockModel,
    QLCCClient,
    QLCCFeedbackModel,
    QLCCFloorModel,
    QLCCTaskModel,
    ReportInfo,
    RoleRightWebModel,
    SysActionType,
} from '../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../shared/service-proxies/config';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import BlockUI from '../../../components/Block-UI/Block-ui';
import notification from '../../../common/notification/notification';
import moment from 'moment';
import FeedBackAction from '../actions/feedback-action/feedback-action';
import { useHotkeys } from 'react-hotkeys-hook';
import DHSBreadcrumb from '../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';

import { ACTIONS } from "../../../common/enums/DHSToolbarRoleAction";

import dxDataGrid, { CellPreparedEvent, RowPreparedEvent, SelectionChangedInfo } from 'devextreme/ui/data_grid';
import DHSPopupReportDate from '../../../components/DHS/DHS_Popup_Report/DHSPopupReportDate';
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { EventInfo } from "devextreme/events";
import { exportExcelReportNoFooter } from '../../../components/DHS/DHS_ExportExcelReport/exportExcelReportNoFooter';
import DataGrid from 'devextreme-react/data-grid';
import DHSToolbarRole from "../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import { getIndexRowSelected, getKeyRowSelected } from '../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../common/Interfaces/StatusMessage';
import DHSDialogAction from '../../../components/DHS/DHS_DialogAction/DialogAction';
import { AppBar, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, Divider, IconButton, InputLabel, TextField, Toolbar, Typography } from '@mui/material';
import { Transition } from '../category-vehicle-card/report-category-vehicle-card/category-list-vehicle-card';
import CloseIcon from '@mui/icons-material/Close';
import BetweenTwoDate from '../report/cash-book-report/component/between-two-date-X';
import "./type.css";
import TaskListAction from '../actions/work/task-list-sent-task-action';
import _, { stubTrue } from 'lodash';
import { setApartmentBuilding } from '../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction';
import DHSAutoCompleteWithDataSource from '../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource';
import { CALL_API_LOAD_APARTMENT_BUILDING_LIST } from '../apartment-building/apartment-building-list';
import { pushPathLevel2 } from '../../../redux/actions/PathAction';


const convert = ["success", "error", "warning"];
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any,
    feedbackItem: any;

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null,
    feedbackItem: {},
}

const TASK_STATUS_SENT_TASK = 5;
const roleMenu: string = 'CC';
const FeedBackList: React.FC<{}> = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { search } = useLocation();

    const history = useHistory();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const menus = useSelector((state: IReducer) => state.RoleReducer?.listMenu);
    const [reload, setReload] = React.useState<boolean>(false);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qlccClient = new QLCCClient(appSession, BASE_URL_API);
    const [dataTable, setDataTable] = React.useState<QLCCFeedbackModel[]>([] as QLCCFeedbackModel[]);
    const [data, setData] = React.useState<QLCCFeedbackModel>({} as QLCCFeedbackModel);
    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const [apartmentBuildings, setApartmentBuildings] = React.useState<QLCCApartmentBuildingModel[]>([]);
    const [blocks, setBLocks] = React.useState<QLCCBlockModel[]>([]);
    const [floors, setFloors] = React.useState<QLCCFloorModel[]>([]);
    const [fromDate, setFromDate] = React.useState<Date>(new Date());
    const [toDate, setToDate] = React.useState<Date>(new Date());

    const [openTask, setOpenTask] = React.useState<boolean>(false);
    const [idTask, setTaskId] = React.useState<string>("");
    const [task, setTask] = React.useState<QLCCTaskModel[]>([]);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>(); // obj feedback

    async function onLoadDataSource(pa?: string | undefined) {
        const res = await qlccClient.qlccFeedbackGet({
            ...new QLCCFeedbackModel(),
            username_login: SessionLogin.userName,
            action_type: 'GET',
            apartment_building_id: pa ? pa : apartment_building_id,
            ma_cty: SessionLogin.ma_cty,
            is_admin_apartment: SessionLogin.isadmin,
            is_manager_apartment: undefined,
        } as QLCCFeedbackModel)
        setDataTable(res);
    }

    React.useEffect(() => {
        setLoading(true)
        const searchParams = new URLSearchParams(search);
        const id = searchParams.get('id');
        const getMenuWhenLinkTo = menus?.find(x => x.link_web?.toLowerCase() === window.location.pathname.toLowerCase());
        if (id && getMenuWhenLinkTo) {
            dispatch(getMenuShowGrid(getMenuWhenLinkTo));
            qlccClient
                .qlccFeedbackGet({
                    ...new QLCCFeedbackModel(),
                    username_login: SessionLogin.userName,
                    id: id,
                    action_type: 'GET',
                    ma_cty: SessionLogin.ma_cty,
                    is_admin_apartment: SessionLogin.isadmin,
                    is_manager_apartment: undefined,
                } as QLCCFeedbackModel)
                .then((res) => {
                    if (res) {
                        setDataTable(res);
                        // open action
                        setActCx({
                            ...actCx,
                            open: true,
                            type: ACTIONS.VIEW as string,
                            payload: idItemSelected,
                            feedbackItem: res[0]
                        })
                    }
                    setLoading(false);
                })
                .catch((err) => {
                })
        }
        else {
            onLoadDataSource()
        }
    }, [search, reload, apartment_building_id]);

    React.useEffect(() => {
        if (data?.block_id) {
            qlccClient.qLCCFloorGet({
                ...new QLCCFloorModel(),
                ma_cty: SessionLogin.ma_cty,
                block_id: data.block_id
            } as QLCCFloorModel).then(res => setFloors(res))
                .catch(err => console.log(err))
        }
    }, [data?.block_id]);

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code) {
            case ACTIONS.VIEW:
            case ACTIONS.ADD:
            case ACTIONS.EDIT:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: action?.action_code,
                                payload: idItemSelected,
                                feedbackItem: selectedItemKeys
                            })
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.SENTTASK_2:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            const item = dataTable.find(item => item.id === idItemSelected);
                            setActCx({
                                ...actCx,
                                open: false,
                                type: action?.action_code,
                                payload: idItemSelected, // id of feedback
                                feedbackItem: selectedItemKeys // object Feedback
                            })
                            setOpenTask(true);
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }

                break;
            case ACTIONS.COPY:
                break;
            case ACTIONS.DELETE:
                Notification(
                    TYPE_MESSAGE.INFO,
                    "Không được phép xóa"
                );
                break;
            case ACTIONS.CLOSE:
                break;
            case ACTIONS.SEARCH:
                // if (data?.apartment_building_id !== apartment_building_id) {
                //     setData({
                //         ...data,
                //         apartment_building_id: apartment_building_id
                //     } as QLCCFeedbackModel)
                // }
                setIsOpen(pre => !pre);
                qlccClient.qlccBlockGet({
                    ... new QLCCBlockModel(),
                    ma_cty: SessionLogin.ma_cty,
                    apartment_building_id: apartment_building_id
                } as QLCCBlockModel)
                    .then(res => {
                        try {
                            setBLocks(res);
                            // setData({
                            //     ...data,
                            //     block_id: res[0].id
                            // } as QLCCFeedbackModel)
                        } catch (err) {
                            console.log(err)
                        }
                    })
                    .catch(err => console.log(err))
                break;
            case ACTIONS.SAVE:
                break;
            case "RELOAD":
                break;
        }
    }

    const handlePageChange = () => {
        // setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                const index = e.component.getRowIndexByKey(keys[0]);
                // setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
                setIdItemSelected(keys[0].id || "");
            });
        }
    };

    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value } as QLCCFeedbackModel)
    }

    const handleRowPrepared = (e: RowPreparedEvent<any, any>) => {
        const dataRow: QLCCFeedbackModel = e.data;
        if (dataRow) {
            if (!dataRow.is_read) {
                e.rowElement.className += " fw-bold"
            }
        }
    }

    const handleChangeColorCell = (e: CellPreparedEvent<any, any>) => {
        if (e.data && e.column && e.column.dataField === 'status_name') {
            switch (e.data['status_code']) {
                case 0:
                    e.cellElement.className += 'customer-payment-text customer-payment-not-yet'
                    break;
                case 1:
                    e.cellElement.className += 'customer-payment-text is-new'
                    break;
                case 2:
                    e.cellElement.className += 'customer-payment-text is-progressing'
                    break;
                case 3:
                    e.cellElement.className += 'customer-payment-text is-done'
                    break;
                default:
                    break;
            }
        }
        if (e.data && e.column && e.column.dataField === 'counter_message') {
            switch (e.data['counter_message']) {
                case null:
                    break;
                default:
                    e.cellElement.className += ' customer-text'
                    break;
            }
        }
    };

    const isRead = (id: string) => {
        const feedBackIndex = dataTable.findIndex(item => item.id === id);
        // debugger
        if (feedBackIndex) {
            const newFB = dataTable[feedBackIndex];
            dataTable.splice(
                feedBackIndex,
                1,
                {
                    ...newFB,
                    is_read: true
                } as QLCCFeedbackModel
            );
        }
    }

    const handleFilter = () => {
        // if (data?.apartment_building_id) {
        //     dispatch(setApartmentBuilding(data?.apartment_building_id))
        // }
        setLoading(true);
        qlccClient
            .qlccFeedbackGet({
                ...new QLCCFeedbackModel(),
                username_login: SessionLogin.userName,
                action_type: 'GET',
                ...data,
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCFeedbackModel)
            .then((res) => {
                if (res) {
                    setDataTable(res);
                    setIsOpen(false)
                }
                setLoading(false);
            })
            .catch((err) => {

            })
    }

    return (
        <div className='container-fluid'>
            {
                (React.useMemo(() => {
                    return <FeedBackAction
                        open={actCx.open}
                        onClose={function (): void {
                            const searchParams = new URLSearchParams(search);
                            searchParams.delete("id");
                            history.replace({
                                search: searchParams.toString(),
                            })
                            try {
                                isRead(actCx.feedbackItem.id)
                            } catch (ex) {
                                console.log(ex)
                            }
                            setActCx(defaultAction);
                            setReload(pre => !pre)
                        }}
                        onEditSuccess={function (is_success): void {
                            setReload(pre => !pre)
                        }}
                        action={actCx.type}
                        itemSelected={actCx.payload}
                        feedBackItem={actCx.feedbackItem}
                    />
                }, [actCx.open, setReload]))
            }
            <div className="d-flex title-color" style={{ height: 30, color: "rgb(0 78 255 / 88%)", marginTop: 15, marginBottom: 15, fontSize: 22, fontWeight: 550, }}>
                <DHSBreadcrumb location={location} />
            </div>
            <DHSToolbarRole id={' '} menu={menu} onClickAction={handleClickAction} />
            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                // key="so_ct"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handlePageChange={handlePageChange}
                handleSelectionChanged={handleSelectionChanged}
                handleRowPrepared={handleRowPrepared}
                handleCellPrepared={handleChangeColorCell}

            />
            {/* Lọc dữ liệu */}
            <Dialog
                id='sysMenu4'
                open={isOpen}
                TransitionComponent={Transition}
                onClose={() => setIsOpen(false)}
                aria-describedby="alert-dialog-slide-description"
                className='d-flex justify-content-end'
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            Lọc dữ liệu bảng
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setIsOpen(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent>
                    <Box className='w-100 mt-2'>
                        <InputLabel className='cc-label'>Tòa nhà</InputLabel>
                        <DHSAutoCompleteWithDataSource
                            id="gender-select"
                            className="cc-input"
                            dataSource={blocks}
                            value={data?.block_id}
                            displayMember='name'
                            valueMember="id"
                            onValueChanged={(e, newValue: any) => {
                                handleChangeInput({
                                    target: {
                                        name: "block_id",
                                        value: newValue?.id ? newValue.id : null,
                                    },
                                });
                            }}
                        />
                    </Box>
                    <Box className='w-100 mt-2'>
                        <InputLabel className='cc-label'>Tầng</InputLabel>
                        <DHSAutoCompleteWithDataSource
                            id="gender-select"
                            className="cc-input"
                            dataSource={floors}
                            value={data?.floor_id}
                            displayMember='name'
                            valueMember="id"
                            onValueChanged={(e, newValue: any) => {
                                handleChangeInput({
                                    target: {
                                        name: "floor_id",
                                        value: newValue?.id ? newValue.id : null,
                                    },
                                });
                            }}
                        />
                    </Box>
                    <Box className='w-100 mt-2'>
                        <BetweenTwoDate
                            fromDate={setFromDate}
                            toDate={setToDate}
                            reload={() => setReload(!reload)}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                    >
                        Tìm kiếm
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Giao việc */}
            <TaskListAction
                open={openTask}
                onClose={function (): void {
                    setOpenTask(pre => !pre)
                    setTaskId("");
                }}
                onEditSuccess={(is_success): void => {
                    setReload(pre => !pre)
                }}
                // action={selectedItemKeys !== undefined
                //     ? selectedItemKeys.status_code === 1 ? ACTIONS.ADD
                //         : selectedItemKeys.status_code === 2 ? ACTIONS.EDIT
                //             : ACTIONS.VIEW
                //     : ACTIONS.ADD}
                action={selectedItemKeys !== undefined
                    && selectedItemKeys.status_code === 3 ? ACTIONS.VIEW
                    : ACTIONS.ADD}
                titleFeedBack={createTitle(idItemSelected, dataTable)}
                contentFeedBack={CreateContent(idItemSelected, dataTable)}
                feedback_id={idItemSelected}
                apartmentBuildingId={dataTable.find(item => item.id === idItemSelected)?.apartment_building_id!}
                data={idTask ? task.find(item => item.id === idTask)! : undefined}
                status={TASK_STATUS_SENT_TASK}
                feedbackData={_.find(dataTable, item => item.id === idItemSelected)}
            // feedbackData={selectedItemKeys}
            />
        </div>
    )
}

const createTitle = (idItemSelected: string, dataTable: QLCCFeedbackModel[]) => {
    const data = _.find(dataTable, (item) => item.id === idItemSelected);
    return `Phản ánh ${data && data.title ? data.title : ''}`;
}

const CreateContent = (idItemSelected: string, dataTable: QLCCFeedbackModel[]) => {
    const data = _.find(dataTable, (item) => item.id === idItemSelected);
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    if (data) {
        return `<div style="font-size: 14px;">` +
            `<p style="text-align: left"><strong>Tiêu đề: ${data.title}</strong></p>` +
            `<p style="text-align: left">` +
            `<b>Nội dung: </b>${data.content}</p>` +
            `<p><b>Ghi chú: </b></p>` +
            `<p style="text-align: right">` +
            `Người giao <b>${SessionLogin.fullName}</b>` +
            `</p>` +
            `</div>`
    }
    return ''
}

export default FeedBackList;


