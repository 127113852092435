import SelectBox from 'devextreme-react/select-box';
import { NativeEventInfo } from 'devextreme/events';
import { SelectBoxInstance } from 'devextreme/ui/select_box';
import React from 'react'


interface IProps {
    dataSource: any[];
    displayExpr: string;
    searchExpr: string;
    valueExpr: string;
    onValueChanged: (value: any) => void;
    name: string;
    value: string | number | undefined;
    class?: string | undefined;
    inputAttr?: ["Minumum Search length", "Search Timeout", "Search Expression", "Search Mode", "Product", "Simple Product"]
    placeholder?: string | undefined;
    showDataBeforeSearch?: boolean;
    minSearchLength?: number;
    searchTimeout?: number;
    searchEnabled?: boolean;
    noDataText?: string
    defaultValue?: string | undefined;
    disabled?: boolean;
    label?: string | undefined;
    clearBtn?: boolean | undefined;
    hint?: string;
    sx?: any;
}

const DHSSelectBox = (props: IProps) => {

    const handleChangeInput = (e: any) => {
        const { value } = e;
        props.onValueChanged({
            target: {
                name: props.name,
                value: value
            },
        })
    }

    return (
        <SelectBox
            defaultValue={props.defaultValue}
            className={props.class ? props.class : ""}
            dataSource={props.dataSource}
            displayExpr={props.displayExpr}
            searchExpr={props.searchExpr}
            inputAttr={{ 'aria-label': props.inputAttr || "Simple Product" }}
            searchMode={'contains'}
            searchEnabled={props.searchEnabled ? props.searchEnabled : true}
            searchTimeout={props.searchTimeout ? props.searchTimeout : 250}
            minSearchLength={props.minSearchLength ? props.minSearchLength : 0}
            valueExpr={props.valueExpr}
            showDataBeforeSearch={props.showDataBeforeSearch ? props.showDataBeforeSearch : true}
            placeholder={props.placeholder ? props.placeholder : ""}
            onValueChanged={handleChangeInput}
            value={props.value ? props.value : null}
            noDataText={`<div style='color: #a0a0a0; text-align: center;'>${props.noDataText ? props.noDataText : "Trống"}</div>`}
            disabled={props.disabled ? props.disabled : false}
            label={props.label ? props.label : undefined}
            key={props.valueExpr}
            showClearButton={props.clearBtn ? props.clearBtn : false}
            hint={props.hint ? props.hint : undefined}
        />
    )
}

export default DHSSelectBox