import { AnyAction } from "redux"
import { SET_APARTMENT_BUILDING } from "../../constants/ActionTypes"
import { QLCCApartmentBuildingModel } from "../../../app/shared/service-proxies/api-shared"
export interface IApartmentBuildingReducer {
    apartment_building_id: string,
    apartment_building?: QLCCApartmentBuildingModel
}
const initialState: IApartmentBuildingReducer = {
    apartment_building_id: "",
    apartment_building: undefined

}

var apartmentBuildingReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_APARTMENT_BUILDING:
            return {
                ...state,
                apartment_building_id: action.payload?.id,
                apartment_building: action.payload,
            } as IApartmentBuildingReducer

        default:
            return state;

    }
}

export default apartmentBuildingReducer;