import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { components } from "../../../common/ComponentsConfig/components";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Box, InputLabel, MenuItem, Popover, TextField } from "@mui/material";
import DHSToolbar from "../../../components/DHS/DHS-Toolbar/DHSToolbar";
import { Notification } from "../../../components/layouts";
import DHSGridView from "../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { getRole, getRoles } from "../../../redux/actions/RoleActions";
import { AppSession } from "../../shared/app-session/app-session";
import {
  ChoThueXeClient,
  KiemSoatCanClient,
  HomeClient,
  RoleRightWebModel,
  KscPhieuKiemSoatModel,
  CtxDanhMucXeModel,
  KscVanDonModel,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { QrReader } from "react-qr-reader";
import BlockUI from "../../../components/Block-UI/Block-ui";
import notification from "../../../common/notification/notification";
import CTXDate from "../../../components/CTX/DatePicker";
import { CalendarPickerView } from "@mui/lab";
import { now } from "lodash";

interface IOpenCamera {
  pic: any;
  open: boolean;
}

let appSession: AppSession;
const convert = ["success", "error", "warning"];
const menuRoleName = "KS";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};
enum Action {
  BYYEAR = "BYYEAR",
  BYMONTH = "BYMONTH",
  BYDAY = "BYDAY",
  BYSEASON = "BYSEASON",
  BYSTARYEAR = "BYSTARYEAR",
  BYENDYEAR = "BYENDYEAR",
}

interface IAction {
  type: Action;
  payload: any;
}
interface IPeriod {
  fromDate: Date;
  toDate: Date;
}
interface ISeason {
  year: Date;
  seasonNumber: number;
}
const filterType = [
  {
    name: "Theo Năm",
    value: 1,
    views: ["year"],
    action: "BYYEAR",
  },
  {
    name: "Theo Tháng",
    value: 2,
    views: ["year", "month"],
    action: "BYMONTH",
  },
  {
    name: "Theo Ngày",
    value: 3,
    views: ["year", "month", "day"],
    action: "BYDAY",
  },
  {
    name: "Theo Quý",
    value: 4,
    action: "BYSEASON",
  },
  {
    name: "6 tháng đầu năm",
    value: 5,
    action: "BYSTARYEAR",
  },
  {
    name: "6 tháng cuối năm",
    value: 6,
    action: "BYENDYEAR",
  },
];
const listSeason = [
  {
    name: "Quý 1",
    value: 10,
  },
  {
    name: "Quý 2",
    value: 20,
  },
  {
    name: "Quý 3",
    value: 30,
  },
  {
    name: "Quý 4",
    value: 40,
  },
];
const listMonth = [
  {
    name: "Tháng 1",
    value: 1,
  },
  {
    name: "Tháng 2",
    value: 2,
  },
  {
    name: "Tháng 3",
    value: 3,
  },
  {
    name: "Tháng 4",
    value: 4,
  },
  {
    name: "Tháng 5",
    value: 5,
  },
  {
    name: "Tháng 6",
    value: 6,
  },
  {
    name: "Tháng 7",
    value: 7,
  },
  {
    name: "Tháng 8",
    value: 8,
  },
  {
    name: "Tháng 9",
    value: 9,
  },
  {
    name: "Tháng 1",
    value: 10,
  },
  {
    name: "Tháng 11",
    value: 11,
  },
  {
    name: "Tháng 12",
    value: 12,
  },
];

function filterReducer(period: IPeriod, action: IAction) {
  const { type, payload } = action;
  switch (type) {
    case Action.BYYEAR:
      return {
        ...period,
        fromDate: new Date(payload.getFullYear(), 0, 1),
        toDate: new Date(payload.getFullYear(), 11, 31),
      };
    case Action.BYMONTH:
      return {
        ...period,
        fromDate: new Date(payload.getFullYear(), payload.getMonth(), 1),
        toDate: new Date(payload.getFullYear(), payload.getMonth() + 1, 0),
      };
    case Action.BYDAY:
      return {
        ...period,
        fromDate: payload.fromDate,
        toDate: payload.toDate,
      };
    case Action.BYSEASON:
      var from: Date = new Date();
      var to: Date = new Date();
      var y = payload.year.getFullYear();
      if (payload.seasonNumber === 10) {
        from = new Date(y, 0, 1);
        to = new Date(y, 3, 0);
      } else if (payload.seasonNumber === 20) {
        from = new Date(y, 3, 1);
        to = new Date(y, 6, 0);
      } else if (payload.seasonNumber === 30) {
        from = new Date(y, 6, 1);
        to = new Date(y, 9, 0);
      } else if (payload.seasonNumber === 40) {
        from = new Date(y, 9, 1);
        to = new Date(y, 12, 0);
      }
      return {
        ...period,
        fromDate: from,
        toDate: to,
      };
    case Action.BYSTARYEAR:
      return {
        ...period,
        fromDate: new Date(payload.getFullYear(), 0, 1),
        toDate: new Date(payload.getFullYear(), 5, 30),
      };
    case Action.BYENDYEAR:
      return {
        ...period,
        fromDate: new Date(payload.getFullYear(), 6, 1),
        toDate: new Date(payload.getFullYear(), 11, 31),
      };
    default:
      return period;
  }
}

const BaoCaoKSC = () => {
  const history = useHistory();
  // const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
  const webcamRef = React.useRef<any>(null);
  const [filter, dispatch] = React.useReducer(filterReducer, {
    fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    toDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  });
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const kscClient = new KiemSoatCanClient(appSession, BASE_URL_API);
  const [roles, setRoles] = React.useState<RoleRightWebModel[]>();

  const [itemSelected, selectItem] = React.useState<string>();
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
  const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
  const [isShowSort, setShowSort] = React.useState<boolean>(false);
  const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [dataTable, setDataTable] = React.useState<any[]>([]);

  const [isLoading, setLoading] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);
  const [type, setType] = React.useState<number>(2);
  const [year, setYear] = React.useState<Date>(new Date());
  const [halfYear, setHalfYear] = React.useState<Date>(new Date());
  const [season, setSeason] = React.useState<ISeason>({
    year: new Date(),
    seasonNumber: 10,
  });
  const [month, setMonth] = React.useState<Date>(new Date());
  const [date, setDate] = React.useState<IPeriod>({
    fromDate: new Date(),
    toDate: new Date(),
  });
  const id = open ? "simple-popover" : undefined;
  const customMenu = [
    {
      menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
      onClick: () => {
        setShowSearch(!isShowSearch);
      },
    },
    {
      menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
      onClick: () => {
        setShowGroup(!isShowGroup);
      },
    },
    {
      menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
      onClick: () => {
        setShowSort(!isShowSort);
      },
    },
    {
      menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
      onClick: () => {
        setShowFilter(!isShowFilter);
      },
    },
  ];
  React.useEffect(() => {
    setLoading(true);
    homeClient
      ?.roleRightWebGetAll({
        ...new RoleRightWebModel(),
        rolename: menuRoleName,
        menuid: menu?.menuid,
        username: SessionLogin.userName,
      } as RoleRightWebModel)
      .then((res) => {
        setRoles(res);
        setLoading(false);
      });
  }, [menu?.component]);

  React.useEffect(() => {
    setLoading(true);
    kscClient
      .kscPhieuKiemSoatGet({
        ...new KscPhieuKiemSoatModel(),
        tu_ngay: new Date(
          (new Date(filter.fromDate ? filter.fromDate : now()) as any) -
            new Date().getTimezoneOffset() * 1000 * 60
        ),
        den_ngay: new Date(
          (new Date(filter.toDate ? filter.toDate : now()) as any) -
            new Date().getTimezoneOffset() * 1000 * 60
        ),
      } as KscPhieuKiemSoatModel)
      .then((res) => {
        let tempArr: any = [];
        res.forEach((item) => {
          tempArr.push({
            ...item,
            ngay_can_1:
              item?.ngay_can_1 &&
              `${item?.ngay_can_1?.getDate()}/${item?.ngay_can_1?.getMonth()}/${item?.ngay_can_1?.getFullYear()}`,
            ngay_can_2:
              item?.ngay_can_2 &&
              `${item?.ngay_can_2?.getDate()}/${item?.ngay_can_2?.getMonth()}/${item?.ngay_can_2?.getFullYear()}`,
            ngay_tao_phieu:
              item?.ngay_tao_phieu &&
              `${item?.ngay_tao_phieu?.getDate()}/${item?.ngay_tao_phieu?.getMonth()}/${item?.ngay_tao_phieu?.getFullYear()}`,
          });
        });
        setDataTable([...tempArr]);
        setLoading(false);
      });
  }, [filter.fromDate, filter.toDate]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFilter = (tf: number) => {
    switch (tf) {
      case 1:
        dispatch({ type: Action.BYYEAR, payload: year });
        break;
      case 2:
        dispatch({ type: Action.BYMONTH, payload: month });
        break;
      case 3:
        dispatch({ type: Action.BYDAY, payload: date });
        break;
      case 4:
        dispatch({ type: Action.BYSEASON, payload: season });
        break;
      case 5:
        dispatch({ type: Action.BYSTARYEAR, payload: halfYear });
        break;
      case 6:
        dispatch({ type: Action.BYENDYEAR, payload: halfYear });
        break;
      default:
        break;
    }
    handleClose();
  };
  const handleChangeYearAndMonth = (e: any) => {
    const { name, value } = e;
    if (name === "year") {
      setYear(new Date(value));
    } else if (name === "month") {
      setMonth(new Date(value));
    }
  };
  const handleChangeDate = (e: any) => {
    const { name, value } = e;
    setDate({ ...date, [name]: new Date(value) });
  };

  const handleClickAction = (action: RoleRightWebModel) => {
    setLoading(true);
    switch (action.class_name?.toUpperCase()) {
      case "EXPORTEXCEL":
        kscClient
          .exportPhieuKiemSoat({
            ...new KscPhieuKiemSoatModel(),
            tu_ngay: filter.fromDate,
            den_ngay: filter.toDate,
          } as KscPhieuKiemSoatModel)
          .then((response) => {
            setLoading(false);
            window.open(BASE_URL_API + response.path, "_blank ");
          });
        break;
    }
  };

  return (
    <div
      className="container-fluid p-2"
      style={{ maxHeight: "94vh", overflowY: "auto" }}
    >
      <div
        className="d-flex title-color justify-content-between"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          marginTop: 15,
          marginBottom: 15,
          fontSize: 22,
          fontWeight: 550,
        }}
      >
        <div>
          <i
            className="bi bi-arrow-left-short"
            style={{ cursor: "pointer" }}
            onClick={() => history.goBack()}
          ></i>
          &nbsp;{menu?.bar}
        </div>

        <div
          className="d-flex align-items-center justify-content-between"
          style={{ paddingRight: 30 }}
        >
          <Button
            variant="contained"
            endIcon={<FilterAltIcon />}
            onClick={handleClick}
          >
            Lọc
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box sx={{ p: 2, minWidth: 175, width: 200 }}>
              <Box style={{ width: "100%", padding: 5 }}>
                <InputLabel sx={{ fontSize: 12 }}>Điều kiện lọc</InputLabel>
                <TextField
                  select
                  value={type}
                  onChange={(e) => setType(Number(e.target.value))}
                  style={{ width: "100%" }}
                >
                  {filterType.map((type) => (
                    <MenuItem key={`${type.value}`} value={type.value}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              {type < 3 ? (
                <Box style={{ width: "100%", padding: 5 }}>
                  <InputLabel sx={{ fontSize: 12 }}>
                    {type === 1 ? "Chọn năm" : "Chọn tháng"}
                  </InputLabel>
                  <CTXDate
                    name={type === 1 ? "year" : "month"}
                    views={filterType[type - 1].views as CalendarPickerView[]}
                    style={{ width: "100%" }}
                    value={type === 1 ? year : month}
                    onChange={handleChangeYearAndMonth}
                  />
                </Box>
              ) : type === 3 ? (
                <>
                  <Box style={{ width: "100%", padding: 5 }}>
                    <InputLabel sx={{ fontSize: 12 }}>
                      Chọn ngày bắt đầu
                    </InputLabel>
                    <CTXDate
                      name="fromDate"
                      views={filterType[type - 1].views as CalendarPickerView[]}
                      style={{ width: "100%" }}
                      value={date.fromDate}
                      onChange={handleChangeDate}
                    />
                  </Box>
                  <Box style={{ width: "100%", padding: 5 }}>
                    <InputLabel sx={{ fontSize: 12 }}>
                      Chọn ngày kết thúc
                    </InputLabel>
                    <CTXDate
                      name="toDate"
                      views={filterType[type - 1].views as CalendarPickerView[]}
                      style={{ width: "100%" }}
                      value={date.toDate}
                      onChange={handleChangeDate}
                    />
                  </Box>
                </>
              ) : type === 4 ? (
                <>
                  <Box style={{ width: "100%", padding: 5 }}>
                    <InputLabel sx={{ fontSize: 12 }}>Chọn năm</InputLabel>
                    <CTXDate
                      name={"year"}
                      views={["year"]}
                      style={{ width: "100%" }}
                      value={season.year}
                      onChange={(e) =>
                        setSeason({
                          ...season,
                          year: new Date(e.value),
                        })
                      }
                    />
                  </Box>
                  <Box style={{ width: "100%", padding: 5 }}>
                    <InputLabel sx={{ fontSize: 12 }}>Chọn quý</InputLabel>
                    <TextField
                      select
                      value={season.seasonNumber}
                      onChange={(e) =>
                        setSeason({
                          ...season,
                          seasonNumber: Number(e.target.value),
                        })
                      }
                      style={{ width: "100%" }}
                    >
                      {listSeason.map((season) => (
                        <MenuItem key={`${season.value}`} value={season.value}>
                          {season.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                </>
              ) : (
                <>
                  <Box style={{ width: "100%", padding: 5 }}>
                    <InputLabel sx={{ fontSize: 12 }}>Chọn năm</InputLabel>
                    <CTXDate
                      name={"year"}
                      views={["year"]}
                      style={{ width: "100%" }}
                      value={halfYear}
                      onChange={(e) => setHalfYear(new Date(e.value))}
                    />
                  </Box>
                </>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "15px",
                }}
              >
                <Button onClick={(e) => handleFilter(type)}>Xác nhận</Button>
              </Box>
            </Box>
          </Popover>
        </div>
      </div>
      <DHSToolbar
        id={itemSelected}
        list={
          roles
            ? roles.filter(
                (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
              )
            : []
        }
        customMenu={customMenu}
        onClickAction={handleClickAction}
      />
      <DHSGridView
        onSelectRow={(row) => {
          selectItem(row);
        }}
        // onListSelectRow={handleListOnselectRow}
        itemClicked={function (): void {
          throw new Error("Function not implemented.");
        }}
        table_name={menu?.component ? menu.component : ""}
        show_form_list={true}
        showSelectBox={false}
        data={dataTable}
        showSortPanel={isShowSort}
        showSearchPanel={isShowSearch}
        showGroupPanel={isShowGroup}
        showFilterPanel={isShowFilter}
        defaultPageSize={2000}
        grid_name={"Danh sách phiếu kiểm soát cân"}
        excel={false}
      />
      {/* </Route> */}
      {/* {roles &&
          roles.map((role, index) => {
            return (
              role.link &&
              role.link.length > 1 &&
              role.component && (
                <Route
                  key={index}
                  path={role.is_parameter ? role.link + ":_id" : role.link}
                  component={components[role.component.toLocaleUpperCase()]}
                  exact
                ></Route>
              )
            );
          })}
      </Switch> */}
      <BlockUI blocking={isLoading} title={""}></BlockUI>
    </div>
  );
};

export default BaoCaoKSC;
