import Button, { IButtonOptions } from "devextreme-react/button";
import React from "react";

interface IProps extends React.PropsWithChildren<IButtonOptions>{

}

const DHSButton : React.FC<IProps> = (props) => {
    return <Button
                {...props}
              >
                {props?.children}
              </Button>
}

export default DHSButton;