import React, { FC, useEffect, useMemo, useState } from 'react'
import { QLCCClient, QLCCFeedbackDetailModel, QLCCFeedbackModel } from '../../../../shared/service-proxies/api-shared'
import { Avatar, Box, Button, Divider, Grid, IconButton, ImageListItem, InputBase, Paper, Tooltip, Typography } from '@mui/material';
import TextArea from "antd/lib/input/TextArea";

import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { deepOrange } from '@mui/material/colors';
import moment from 'moment';
import _ from 'lodash';
import { Notification } from '../../../../../components/layouts';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../../../common/Interfaces/StatusMessage';
import { AppSession } from '../../../../shared/app-session/app-session';
import BASE_URL_API from '../../../../shared/service-proxies/config';
import { ACTIONS } from '../../../../../common/enums/DHSToolbarRoleAction';
import axios from 'axios';

import './styles.css'

interface IProps {
    idFeedback: string;
    onClose: () => void;
}
var appSession: AppSession;

const FeedbackChatBox2 = (props: IProps) => {


    const [data, setData] = useState<QLCCFeedbackModel>({ ... new QLCCFeedbackModel() } as QLCCFeedbackModel);
    const [data2, setData2] = useState<QLCCFeedbackDetailModel[]>([]);

    const [isView, setIsView] = useState<boolean>(false);

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [messageWritings, setMessageWritings] = useState<string>('')
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    const [path, setPath] = useState<string[]>([]);

    const [checkFeedbackDetailIsset, setCheckFeedbackDetailIsset] = useState<boolean>(false);

    const onload = () => {
        qLCClient
            .qlccFeedbackGet({
                ... new QLCCFeedbackModel(),
                username_login: SessionLogin.userName,
                id: props.idFeedback,
                action_type: 'CONTACT',
            } as QLCCFeedbackModel)
            .then((res) => {
                const listContact = res[0].qLCCFeedbackDetails
                if (res[0] && listContact) {
                    setData(res[0]);
                    setData2(listContact);
                    setCheckFeedbackDetailIsset(listContact.length > 0);
                }
            })
    }

    useEffect(() => {
        if (props.idFeedback) {
            onload()
        }
    }, [props.idFeedback])

    const handleChange = (e: any) => {
        const { value } = e.target;
        setMessageWritings(value)
    }

    const handleSentMessage = async () => {
        if (messageWritings.length > 0 || path.length > 0) {
            await qLCClient.
                qlccFeedbackDetailIns({
                    ...new QLCCFeedbackDetailModel(),
                    resident_id: SessionLogin.userName,
                    content: messageWritings,
                    contact_id: data.contact_id,
                    manager_id: data.manager_id,
                    attach_files: path.join(';')
                } as QLCCFeedbackDetailModel)
                .then(res => {
                    onload();
                    setMessageWritings('')
                    setPath([]);
                }).catch(err => {
                    if (err) {
                        Notification(STATUS_MESSAGE[err.status], err.message)
                    }
                });
        }
    }

    const onUploadFile = (e: any) => {

        const { name, files } = e.target;
        const formData: FormData = new FormData();

        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };

        for (const file of files) {
            formData.append("MyFile", file);
        }

        // Name Folder save in Server
        formData.append("tbName", 'QLCCTaskCheckList');
        formData.append("colName", 'attach_files');

        axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    if (setPath) {
                        // if (props.singer) {
                        // setPath([res.data[0]?.path]);
                        // } else {
                        setPath([...path, res.data[0]?.path]);
                        // }
                    }
                    // reset lại file input
                    e.target.value = '';
                } else Notification(TYPE_MESSAGE['WARNING'], STATUS_MESSAGE['NETWORK_CORRECTION']);
            });

    }

    return (
        <Paper elevation={3} className='p-3'>
            <Box className="d-flex justify-content-between align-items-center">
                <Typography className='m-0' sx={{ color: "#1890ff", }} >  <strong>{data.resident_name}</strong> </Typography>
                <Box>
                    <IconButton onClick={() => { onload(); }} >
                        <RefreshIcon color="primary" />
                    </IconButton>
                    <IconButton onClick={() => { props.onClose(); }} >  <CloseIcon color='error' /> </IconButton>
                </Box>
            </Box>
            <Divider />
            <Box
                // elevation={3}
                className="feedback-forum-around-chat2"
            >
                {
                    checkFeedbackDetailIsset
                        ?
                        data2.map(dataItem => (
                            <span key={dataItem.id}>
                                {
                                    MessageItem(dataItem)
                                }
                            </span>
                        ))
                        :
                        <p className="text-center task-child-forum-content" style={{ userSelect: 'none'}}>Bắt đầu tương tác &#9786;</p>
                }
                {   
                    data.is_lock && <p className="text-center task-child-forum-content" style={{ userSelect: 'none'}}>Đoạn chat đã bị khóa <i className="bi bi-lock"></i></p>
                }
            </Box>
            <Box className="box-writing-message">
                <Box
                    className="around-input-base-child"
                >
                    <InputBase
                        autoFocus={true}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Soạn nội dung ..."
                        // inputProps={{ 'aria-label': 'search google maps' }}
                        name="content"
                        value={messageWritings}
                        onChange={(e) => {
                            handleChange({
                                target: {
                                    name: 'content',
                                    value: e.target.value ?? null
                                }
                            })
                        }}

                        onKeyPress={(event: any) => {
                            if (event.key === 'Enter')
                                handleSentMessage()
                        }}
                        disabled={data?.is_lock}
                    />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <Tooltip title="Tải tập tin">
                        <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" disabled={data?.is_lock}>
                            <label className="m-0 p-0" htmlFor="file_attach_subtask">
                                <AttachFileIcon />
                            </label>
                        </IconButton>
                    </Tooltip>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <Tooltip title="Gửi đi">
                        <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={handleSentMessage} disabled={data?.is_lock}>
                            <SendIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Typography variant="subtitle2" className="pt-2" >
                    <input id="file_attach_subtask" type="file" accept="*" multiple={true} className="d-none" onChange={onUploadFile} />
                    {/* Danh sách tập tin */}
                    <p className="block-file-attach">
                        {
                            path && path.length > 0 && (<p className=''> Danh sách tệp tin: </p>)
                        }
                        {
                            lstFile(path, ACTIONS.ADD, setPath)
                        }
                    </p>
                </Typography>
            </Box>
        </Paper>
    )
}

const lstFile = (path: string[], action: string, setPath: any) => {

    const handleRemoveFile = (filePath: string) => {

        if (window.confirm("Xác nhận xóa tập tin?")) {
            const pathTemp: string[] = path.filter((file: any) => file !== filePath);
            setPath([...pathTemp]);
        }

    }

    return <>
        {
            path.length > 0
            && path.map((pathItem: string, index: Number) => (
                <span className='d-inline'>
                    <p className="file_item_attach" onClick={() => window.open(BASE_URL_API + pathItem)}>
                        {pathItem.split("/").reverse()[0]}
                    </p>
                    <p className="file_item_attach">
                        <CloseIcon
                            className='cursor-close-around'
                            onClick={() => handleRemoveFile(pathItem)}
                        />
                    </p>
                </span>
            ))
        }
    </>
}


const MessageItem = (mess: QLCCFeedbackDetailModel) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const { date_add, attach_files, content } = mess;

    const fullname = mess.resident_name
    const cuser = mess.resident_id;

    const checkSenderOrDev = (userName: string, title: "block" | "username" | "time" | "avatar" | 'content' | "block-text") => {
        const myUsername = SessionLogin.userName;
        if (myUsername && userName.toUpperCase() === myUsername.toUpperCase()) {
            switch (title) {
                case "block":
                    return 'justify-content-end';
                case "block-text":
                    return 'task-my-text'
                case "username":
                    return 'd-none';
                case "time":
                    return 'text-center';
                case 'avatar':
                    return 'd-none';
                case 'content':
                    return '';
            }
        } else {
            switch (title) {
                case "block":
                    return 'justify-content-start';
                case "username":
                    return 'd-flex align-items-center justify-content-start';
                case "time":
                    return 'text-center';
                case 'content':
                    return 'px-2'
                case "block-text":
                    return 'task-your-text'
            }
        }
    }

    const issetTypFile = (lstPath: string, type: "IMAGE" | "OTHER") => {
        const issetOtherFile = (lstPath: string) => {
            const path: string[] = lstPath ? lstPath.split(";") : [];
            const lstOtherFile = path.filter(item => !checkTypeFile(item));
            return lstOtherFile.length > 0
        }

        const issetImageFile = (lstPath: string) => {
            const path: string[] = lstPath ? lstPath.split(";") : [];
            const lstOtherFile = path.filter(item => checkTypeFile(item));
            return lstOtherFile.length > 0
        }

        switch (type) {
            case "IMAGE":
                return issetImageFile(lstPath);
            case "OTHER":
                return issetOtherFile(lstPath);
            default:
                return false;
        }
    }

    return (
        <>
            <Typography variant="body2" className={`${checkSenderOrDev(cuser!, "time")}`} >
                <Typography variant="caption" ><small>{moment(date_add).format('HH:mm DD-MM-YYYY')}</small></Typography>
            </Typography>
            <Grid container spacing={2} className={`d-flex ${checkSenderOrDev(cuser!, "block")}`}>
                <Grid item xs={10}>
                    <Typography
                        variant="body1"
                    >
                        <Box className={`d-flex ${checkSenderOrDev(cuser!, "block")}`} >
                            <Avatar className={`avatar-icon-messager ${checkSenderOrDev(cuser!, "avatar")}`}>{`${fullname ? fullname[0] : cuser ? cuser[0] : "U"}`}</Avatar>
                            <Box>
                                <Typography variant="subtitle2" className={`${checkSenderOrDev(cuser!, "username")}`}>
                                    <Typography variant="subtitle2" className="d-flex align-items-center" >
                                        <Typography variant="subtitle2" typography={'span'} className="comment-notify-daily-username">
                                            {`${fullname ? fullname : cuser}`}
                                        </Typography>
                                    </Typography>
                                </Typography>

                                {/* Danh sách ảnh */}
                                {
                                    lstFileInMessage(attach_files, "IMAGE")
                                }

                                {
                                    content &&
                                    <Typography variant="body2" className={`mt-1 task-forum-child-content px-2 ${checkSenderOrDev(cuser!, "content")} ${checkSenderOrDev(cuser!, "block-text")}`} align='justify'>
                                        {`${content ? content : ''}`}
                                    </Typography>
                                }
                                <Typography variant="body2" className={`mt-1 px-2 ${checkSenderOrDev(cuser!, "block-text")}`}>
                                    {
                                        issetTypFile(attach_files || "", "OTHER")
                                        && <>
                                            {
                                                lstFileInMessage(attach_files, "OTHER_FILE")
                                            }
                                        </>
                                    }
                                </Typography>

                                {/* <hr /> */}
                            </Box>
                        </Box>
                    </Typography >

                </Grid>
            </Grid >

        </>
    )
}


const lstFileInMessage = (pathS: string | undefined, typeFile: "IMAGE" | "OTHER_FILE") => {

    const path: string[] = pathS ? pathS.split(";") : [];

    const getNameFile = (filePath: string) => {
        return filePath.split("/").reverse()[0];
    }

    const issetFile = (lstFile: string[]) => {
        return lstFile.length > 0;
    }

    if (typeFile === 'IMAGE') {
        const lstImageFile = path.filter(itemFile => checkTypeFile(itemFile));

        return <>
            {
                issetFile(lstImageFile)
                && lstImageFile.map((item) => (
                    <ImageListItem key={item} onClick={() => window.open(BASE_URL_API + item)}>
                        <img
                            className='mb-1 w-100'
                            src={`${BASE_URL_API + item}?w=164&h=164&fit=crop&auto=format`}
                            srcSet={`${BASE_URL_API + item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            alt={getNameFile(item)}
                            loading="lazy"
                            style={{
                                borderRadius: '5px'
                            }}
                        />
                    </ImageListItem>
                ))
            }
        </>
    } else { // file other 
        const lstOtherFile = path.filter(itemFile => !checkTypeFile(itemFile));


        return <>
            {
                issetFile(lstOtherFile)
                && lstOtherFile.map((pathItem: string, index: Number) => (
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className="file_item_attach_mess_chat_feedback" >
                            {pathItem.split("/").reverse()[0]}
                        </p>
                        <span className='cursor-pointer'>
                            <ArrowCircleDownIcon onClick={() => window.open(BASE_URL_API + pathItem)} />
                        </span>
                    </div>
                ))
            }
        </>
    }
}


// Type Image File
export const checkTypeFile = (filePath: string) => {

    const getTypeFile = (filePath: string) => {
        const fileName = filePath.split("/").reverse()[0];
        if (fileName && fileName.length > 0) {
            const typeFile = filePath.split(".").reverse()[0];
            return typeFile;
        } else {
            return "";
        }
    }

    const isFileImage = (typeFile: string) => {
        const lstTypeFileImage = ["JPEG", "JPG", "PNG", "GIF"];
        return lstTypeFileImage.includes(typeFile.toLocaleUpperCase());
    }

    const typeFile = getTypeFile(filePath);
    return isFileImage(typeFile);
}

export default FeedbackChatBox2