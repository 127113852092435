import { PUSH_NOTIFICATION, SET_IS_READ_NOTIFICATION, SET_TOTAL_READ_NOTIFICATION, SET_TOTAL_VIEW_NOTIFICATION } from "../constants/ActionTypes"
import { INotificationState } from "../reducer/NotificationReducer"

export const pushNotification= (payload: INotificationState) => (dispatch: any, getState: any) => {
    dispatch({
      type: PUSH_NOTIFICATION,
      payload
    })
  }
export const setIsReadNotification= (payload: boolean) => (dispatch: any, getState: any) => {
    dispatch({
      type: SET_IS_READ_NOTIFICATION,
      payload
    })
  }
export const setTotalReadNotification= (payload: number) => (dispatch: any, getState: any) => {
    dispatch({
      type: SET_TOTAL_READ_NOTIFICATION,
      payload
    })
  }

  export const setTotalViewNotification= (payload: number) => (dispatch: any, getState: any) => {
    dispatch({
      type: SET_TOTAL_VIEW_NOTIFICATION,
      payload
    })
  }