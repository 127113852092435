import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    CommonClient,
    HomeClient,
    QLCCApartmentBuildingModel,
    QLCCClient,
    QLCCVehicleTypeModel,
    RoleRightWebModel,
    SysModel,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import '../ApartmentBuilding.css';
import notification from "../../../../common/notification/notification";
import CurrencyTextField from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";


interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    title?: string,
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (data: SysModel) => void;
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const status_message = ['success', 'error']

var appSession: AppSession;

const VehicleTypeAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    // const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const [_id, setId] = React.useState<string>();

    // 
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);


    const [data, setData] = React.useState<QLCCVehicleTypeModel>({} as QLCCVehicleTypeModel);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [selectedValue, setSelectedValue] = React.useState('a');


    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [apartmentBuildingOption, setApartmentBuildingOption] = React.useState<any>([]);

    // 

    const [action, setAction] = React.useState<IProps>(props);

    React.useEffect(() => {
        setLoading(true)
        qLCClient
            .qlccApartmentBuildingGet({
                ...new QLCCApartmentBuildingModel(),
                ma_cty: SessionLogin.ma_cty,
                action_type: 'GET'
            } as QLCCApartmentBuildingModel)
            .then(res => {
                setLoading(false)
                setApartmentBuildingOption(res);
            })
    }, [])

    React.useEffect(() => {
        if (action.action === ACTIONS.EDIT || action.action === ACTIONS.VIEW) {
            qLCClient
                .qLCCVehicleTypeGet({
                    ...new QLCCVehicleTypeModel(),
                    id: action.itemSelected.vehicle_type_id,
                    ma_cty: SessionLogin?.ma_cty,
                    apartment_building_id: props.itemSelected.apartment_building_id
                } as QLCCVehicleTypeModel)
                .then((res) => {
                    setData(res[0]);
                    if (res[0].is_bike) {
                        setSelectedValue("xe máy")
                    }
                    else if (res[0].is_oto) {
                        setSelectedValue("ô tô")
                    }

                })
                .catch(() => alert("Không lấy được dữ liệu"));
            setId(action.itemSelected);


        } else {
            setData({
                ...new QLCCVehicleTypeModel(),
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: props.itemSelected.apartment_building_id,
                is_bike: false,
                is_oto: false
            } as QLCCVehicleTypeModel);
            setId(undefined)
        };
        // }
    }, [props.action]);


    React.useEffect(() => {

        setLoading(true);
        homeClient
            ?.roleRightWebGetAll({
                ...new RoleRightWebModel(),
                rolename: "CC",
                username: SessionLogin.userName,
                menuid: menu?.menuid,
            } as RoleRightWebModel)
            .then((res) => {
                setRoles(res);
                setLoading(false);
            });
        setLoading(true);

    }, []);

    React.useEffect(() => {
        if (selectedValue === 'ô tô') {
            setData(pre => ({ ...pre, is_oto: true, is_bike: false } as QLCCVehicleTypeModel));
        }
        else if (selectedValue === 'xe máy') {
            setData(pre => ({ ...pre, is_oto: false, is_bike: true } as QLCCVehicleTypeModel));

        }
    }, [selectedValue]);


    const handleSubmit = () => {


        setLoading(true)

        switch (action.action) {
            case ACTIONS.EDIT: {

                qLCClient.qLCCVehicleTypeAction({
                    ...new QLCCVehicleTypeModel(),
                    ...data,
                    action_type: 'UPDATE'
                } as QLCCVehicleTypeModel)
                    .then((response) => {
                        setLoading(false);
                        notification(status_message[response.status!], response.message);
                        if (response.status === 0) {
                            props.onEditSuccess(response);
                            props.onClose()
                        }

                    });
                break;
            }

            case 'ADD': {

                qLCClient.qLCCVehicleTypeAction({
                    ...new QLCCVehicleTypeModel(),
                    ...data,
                    action_type: 'INSERT'
                } as QLCCVehicleTypeModel)
                    .then((response) => {
                        setLoading(false);
                        notification(status_message[response.status!], response.message)

                        if (response.status === 0) {
                            props.onEditSuccess(response);
                            props.onClose()
                            // setId(response.ref_id)
                            // setAction({ ...action, itemSelected: response.ref_id, action: 'EDIT' })
                        }
                    });
                break;
            }


            default:
                break;
        }

    };

    const handleChangeMultipleInput = (e: any) => {
        if (props.action !== ACTIONS.VIEW) {
            const { name, value } = e.target;

            const valueSplit = value.map((value: QLCCApartmentBuildingModel) => value.id).join(";")
            setData({ ...data, [name]: valueSplit } as QLCCVehicleTypeModel);

        }
    }

    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value } as QLCCVehicleTypeModel);
    };
    const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        // const { name, value } = e.target;
        // 
        // setData({ ...data, [name]: checked } as QLCCVehicleTypeModel);
        setSelectedValue(e.target.value)
    };


    const handleChangeCurrencyInput = (
        value: string | undefined, name?: string | undefined, values?: CurrencyInputOnChangeValues | undefined) => {

        setData({ ...data, [name!]: value } as QLCCVehicleTypeModel);
    }

    return (
        <Dialog
            id=""
            open={action.open}
            onClose={action.onClose}
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            TransitionComponent={Transition}
            maxWidth='xs'

        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {`${props.title ?? menu?.bar}
                            ${action.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : action.action === ACTIONS.EDIT || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={action.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >

                <Box style={{ width: "100%", margin: "5px 0px" }}>
                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tên loại xe</InputLabel>
                    <TextField
                        className="cc-input"
                        sx={{
                            width: "100%",
                            marginRight: "5px",
                        }}
                        placeholder="Tên loại xe..."
                        autoFocus
                        name="name"
                        onChange={handleChangeInput}
                        value={data.name}
                        disabled={action.action === ACTIONS.VIEW}
                    />

                </Box>

                {/*Chi phí */}
                <Box style={{ width: "100%", margin: "5px 0px" }}>
                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Chi phí</InputLabel>
                    <CurrencyTextField
                        className="flex-grow-1 form-control currency-input"
                        style={{ width: "100%", textAlign: "right" }}
                        allowDecimals
                        decimalsLimit={2}
                        decimalSeparator="."
                        groupSeparator=","
                        decimalScale={0}
                        placeholder="Chi phí"
                        intlConfig={{ locale: 'vi-VN', currency: 'VND' }}
                        name="amount"
                        onValueChange={handleChangeCurrencyInput}
                        value={data.amount}
                        disabled={action.action === ACTIONS.VIEW}
                    />

                </Box>

                {/* Loại */}
                <Box style={{ width: "100%", margin: "5px 0px" }}>
                    <InputLabel
                        sx={{ fontSize: 14, color: "#333" }}
                    >
                        Loại
                    </InputLabel>
                    <div>
                        <FormControlLabel
                            value={"ô tô"}

                            control={<Radio

                                checked={selectedValue === 'ô tô'}
                                onChange={handleChangeRadio}
                                value="ô tô"
                                name="is_oto"
                                inputProps={{ 'aria-label': 'ô tô' }} />}
                            label={"ô tô"}
                            disabled={action.action === ACTIONS.VIEW} />

                        <FormControlLabel
                            value={"ô tô"}

                            control={<Radio
                                checked={selectedValue === 'xe máy'}
                                onChange={handleChangeRadio}
                                value="xe máy"
                                name="is_bike"
                                inputProps={{ 'aria-label': 'B' }} />}
                            label={"Xe máy"}
                            disabled={action.action === ACTIONS.VIEW} />
                    </div>

                </Box>

                {/* Sự dụng cho dự án */}
                <Box style={{ width: "100%", margin: "5px 0px" }}>
                    <InputLabel
                        sx={{ fontSize: 14, color: "#333" }}
                    >
                        Sử dụng cho dự án
                    </InputLabel>
                    <DHSAutoCompleteWithDataSource
                        id="status-select"
                        className="cc-input"
                        filterSelectedOptions={true}
                        dataSource={apartmentBuildingOption}
                        displayMember="name"
                        valueMember="id"
                        value={data?.apartment_building_id}
                        onValueChanged={(e, newValue: any) => {
                            handleChangeMultipleInput({
                                target: {
                                    name: "apartment_building_id",
                                    value: newValue ?? null,
                                },
                            });
                        }}
                        disabled={props.action === ACTIONS.VIEW}
                        multiple={true}
                    />
                    {/* <Autocomplete
                    <DHSAutoCompleteWithDataSource
                        id="apartment-select"

                        multiple
                        filterSelectedOptions
                        dataSource={apartmentBuildingOption}
                        displayMember="name"
                        valueMember="id"
                        value={data?.apartment_building_id}
                        onValueChanged={(e, newValue: any) => {

                            handleChangeMultipleInput({
                                target: {
                                    name: "apartment_building_id",
                                    value: newValue ?? null,
                                },
                            });
                        }}

                       
                        disabled={props.action === ACTIONS.VIEW}
                    />*/}
                </Box>




            </DialogContent>
            <DialogActions>
                {action.action !== ACTIONS.VIEW && (
                    <Button variant="contained" onClick={handleSubmit}>
                        Lưu
                    </Button>
                )}
                <Button onClick={action.onClose}>Hủy</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default VehicleTypeAction;


