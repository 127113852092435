import React, { forwardRef, useState } from 'react'
import DHSScheduler from '../../../../../../components/DHSComponents/scheduler/DHSScheduler'
import { useForwardRef } from '../../../../../../components/utils/hooks/useForwardRef';
import { Box } from '@mui/material';
import dxScheduler, { AppointmentClickEvent, AppointmentDblClickEvent } from 'devextreme/ui/scheduler';
import { OptionChangedEventInfo } from 'devextreme/core/dom_component';
import { QLCCTaskModel } from '../../../../../shared/service-proxies/api-shared';
import TaskCheckListAction from '../../../../actions/work/task-check-list-action';
import { useSelector } from 'react-redux';
import { IReducer } from '../../../../../../components/layouts';
import { ACTIONS } from '../../../../../../common/enums/DHSToolbarRoleAction';

interface IProps {
    dataSource: any[],
    title: string,
    textExpr: string,
    startDateExpr: string,
    endDateExpr: string,
    startDayHour: number,
    endDayHour: number,
    height: string | number;
    onAppointmentClick?: (value: AppointmentClickEvent) => void
    onOptionChanged?: (e: OptionChangedEventInfo<dxScheduler>) => void;
    statusResource?: any | undefined
}

const WorkReportCalendar = forwardRef<any, IProps>((props, ref) => {

    return (
        <>
            <DHSScheduler
                height={props.height}
                ref={ref}
                dataSource={props?.dataSource}
                title={props?.title}
                textExpr={props?.textExpr}
                startDateExpr={props?.startDateExpr}
                endDateExpr={props?.endDateExpr}
                startDayHour={props?.startDayHour}
                endDayHour={props?.endDayHour}
                onAppointmentClick={props.onAppointmentClick}
                onOptionChanged={props?.onOptionChanged}
                statusResource={props?.statusResource}
                appointmentComponent={AppointmentComponent}
            />
        </>
    )
})


const AppointmentComponent = (props: any) => {
    const data = props.data.targetedAppointmentData as QLCCTaskModel;
    return <Box>
        <Box className="dx-scheduler-appointment-title">{data?.title}</Box>
        <Box className="dx-scheduler-appointment-title">{`Người nhận: ${data?.receiver_name}`}</Box>
        <Box className="dx-scheduler-appointment-title">{`Ngày tạo: ${data?.ngay_ct}`}</Box>
    </Box>
}

export default WorkReportCalendar