import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DHSToolbar from "../../../../DHS/DHS-Toolbar/DHSToolbar";
import { IReducer } from '../../..';
import DHSGridView from '../../Grid/Grid-view/Grid-View';
import { getRoles } from '../../../../../redux/actions/RoleActions';
import { AppSession } from '../../../../../app/shared/app-session/app-session';
import {
    HomeClient,
    QLCCClient,
    HomePageClient,
    RoleRightWebModel,
    UserInfoModel,


} from '../../../../../app/shared/service-proxies/api-shared';
import BASE_URL_API from '../../../../../app/shared/service-proxies/config';
import { useHistory } from 'react-router-dom';
import BlockUI from '../../../../Block-UI/Block-ui';
import notification from "../../../../../common/notification/notification";
import { useParams } from 'react-router'


import UserInfoBlockAction from '../../../../../app/quan-ly-chung-cu/actions/UserInfo-action/UserInfoBlockAction';
import ResidentAccountAction from '../../../../../app/quan-ly-chung-cu/actions/account-action/ResidentAccountAction';

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

var appSession: AppSession;



const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

const roleMenu: string = 'CC';
const status_message = ['success', 'error']


const UserInfoBlock: React.FC<{}> = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [{ _id }, _setId] = React.useState(useParams<{ _id: string }>());
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<any>();
    // const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<UserInfoModel[]>([]);
    const homeClient = new HomeClient(appSession, BASE_URL_API);

    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
    const [isShowSort, setShowSort] = React.useState<boolean>(false);
    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
    const homePageClient = new HomePageClient(appSession, BASE_URL_API);
    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const [action, setAction] = React.useState<IAction>({
        open: false,
        type: "",

        payload: "",
    });
    const customMenu = [
        {
            menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
            onClick: () => {
                setShowSearch(!isShowSearch);
            },
        },
        {
            menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
            onClick: () => {
                setShowGroup(!isShowGroup);
            },
        },
        {
            menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
            onClick: () => {
                setShowSort(!isShowSort);
            },
        },
        {
            menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
            onClick: () => {
                setShowFilter(!isShowFilter);
            },
        },
    ];
    useEffect(() => {
        setLoading(true)
        homeClient.roleRightWebGetAll({
            ...new RoleRightWebModel(),
            menuid: menu?.menuid,
            rolename: roleMenu,
            username: 'DHSOFT',
        } as RoleRightWebModel)
            .then((res) => {
                dispatch(getRoles(res));
                setRoles(res);
                setLoading(false);
            })
    }, [menu?.component])

    React.useEffect(() => {
        setLoading(true);
        // Tải các dòng trong bảng
        homePageClient
            .userInfoWebGet({
                ...new UserInfoModel(),
                isblock: true,
                action_type: "GET"
            } as UserInfoModel)
            .then((res) => {
                setDataTable(res);
                setLoading(false);
            });

    }, [reload, action.open]);


    const handleClickAction = (action: RoleRightWebModel) => {
        switch (action.class_name?.toUpperCase()) {
            case 'DETAIL':
                setActCx({
                    ...actCx,
                    open: true,
                    type: action?.class_name?.toUpperCase() ?? "",
                    payload: idItemSelected
                })
                break;
        }

    }
    return (


        <div className='container-fluid'>

            {/* {
                actCx.open && (
                    <UserInfoBlockAction
                        open={true}
                        onClose={function (): void {
                            setActCx(defaultAction);
                        }}
                        action={actCx.type}

                        itemSelected={actCx.payload}
                        onEditSuccess={function (is_success: boolean): void {
                            setReload(pre => !pre)
                        }} >
                    </UserInfoBlockAction>
                )
            } */}

            {
                actCx.open && <ResidentAccountAction
                    open={actCx.open}
                    onClose={function (): void {
                        setActCx(defaultAction);
                    }}
                    action={actCx.type}
                    itemSelected={actCx.payload}
                    onEditSuccess={function (is_success: boolean): void {
                        setReload(pre => !pre)
                    }} >

                </ResidentAccountAction>
            }

            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <i
                    className="bi bi-arrow-left-short"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.goBack()}
                ></i>
                &nbsp;{menu?.bar}
            </div>

            <DHSToolbar
                id={idItemSelected}
                list={roles
                    ? roles.filter(
                        (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
                    )
                    : []}

                onClickAction={handleClickAction}
                customMenu={customMenu}
            />

            <DHSGridView
                onSelectRow={(row) => {
                    setIdItemSelected(row);
                }}
                // onListSelectRow={handleListOnselectRow}
                itemClicked={function (): void {
                    throw new Error("Function not implemented.");
                }}
                table_name={menu?.component ? menu?.component : ""}
                show_form_list={true}
                showSelectBox={false}
                showSortPanel={isShowSort}
                showSearchPanel={isShowSearch}
                showGroupPanel={isShowGroup}
                showFilterPanel={isShowFilter}
                data={dataTable}
            />
            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default UserInfoBlock;


