import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Grid,
    Divider,
    DialogTitle,
    Autocomplete,
    FormControlLabel,
    Switch,
    styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    ChoThueXeClient,
    CommonClient,
    HomeClient,
    HomePageClient,
    HRClient,
    KiemSoatCanClient,
    KscPhieuKiemSoatModel,
    KscVanDonModel,
    LstInventoryClient,
    UserInfoGroupModel,
    RoleRightWebModel,
} from "../../../shared/service-proxies/api-shared";

import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CTXDateTime from "../../../../components/CTX/DateTimePicker";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

import "../ApartmentBuilding.css";
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import notification from "../../../../common/notification/notification";
interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});



const status_message = ['success', 'error']
var appSession: AppSession;
var htmlString: string;
const UserInfoGroupAction: React.FC<IProps> = (props: IProps) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    //const qLCClient = new QLCClient(appSession, BASE_URL_API);


    const [data, setData] = React.useState<UserInfoGroupModel>({} as UserInfoGroupModel);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const homePageClient = new HomePageClient(appSession, BASE_URL_API);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);


    React.useEffect(() => {
        if (props.action === "EDIT" || props.action === "DETAIL") {
            homePageClient
                .userInfoGroupGet({
                    ...new UserInfoGroupModel(),
                    id: props.itemSelected,
                    action_type: "GET"
                } as UserInfoGroupModel)
                .then((res) => {
                    setData(res[0]);
                    
                })
                .catch(() => alert("Không lấy được dữ liệu"));
            setId(props.itemSelected);
            
        } else {
            
            setData(new UserInfoGroupModel());
            setId(undefined)
        };
    }, [props.action]);

    React.useEffect(() => {
        
        setLoading(true);
        homeClient
            ?.roleRightWebGetAll({
                ...new RoleRightWebModel(),
                rolename: "CC",
                username: SessionLogin.userName,
                menuid: menu?.menuid,
            } as RoleRightWebModel)
            .then((res) => {
                setRoles(res);
                setLoading(false);
            });
        setLoading(true);
    }, []);


    const handleSubmit = (e: any) => {
        if (props.action == "ADD") {
            homePageClient
                .userInfoGroupAction({
                    ...data,
                    action_type: "INSERT"
                } as UserInfoGroupModel)
                .then(response => {
                    props.onEditSuccess(true);
                })
                .catch(err => {
                    alert(err.message);
                })
        } else if (props.action == "EDIT") {
            homePageClient
                .userInfoGroupAction({
                    ...data,
                    id: _id,
                    action_type: "UPDATE"
                } as UserInfoGroupModel)
                .then(response => {
                    props.onEditSuccess(true);
                })
                .catch(err => {
                    alert(err.message);
                })
        }
        
    }

    const handleChangeInput = (e: any) => {
        if (props.action !== "DETAIL") {
            const { name, value } = e.target;
            setData({
                ...data,
                [name]: value
            } as UserInfoGroupModel);
        }
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            TransitionComponent={Transition}
            maxWidth="md"
        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === "DETAIL"
                                ? "[Chi tiết]"
                                : props.action === "UPDATE" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {/* Nhóm người dùng */}
                    <Grid item xs={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mã nhóm quyền</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%" }}
                                name="group_code"
                                placeholder="Nhập mã nhóm quyền ..."
                                onChange={handleChangeInput}
                                value={data.group_code}
                            // disabled={action.action === "DETAIL"}
                            />
                        </Box>
                    </Grid>
                    {/* Nhóm người dùng */}
                    <Grid item xs={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tên nhóm quyền</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%" }}
                                name="group_name"
                                placeholder="Nhập tên nhóm quyền ..."
                                onChange={handleChangeInput}
                                value={data.group_name}
                            // disabled={action.action === "DETAIL"}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {props.action !== "DETAIL" && (
                    <Button variant="contained" onClick={handleSubmit}>
                        Lưu
                    </Button>
                )}
                <Button onClick={props.onClose}>Hủy</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default UserInfoGroupAction;
