import { useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
// import { firebaseConfig } from './constants';

const firebaseConfig = {
  apiKey: "AIzaSyD7muAfodpg3mz02Yj_tFGugjSncUbhShs",
  authDomain: "dh-home-cefe2.firebaseapp.com",
  projectId: "dh-home-cefe2",
  storageBucket: "dh-home-cefe2.appspot.com",
  messagingSenderId: "427534021430",
  appId: "1:427534021430:web:f605b07086d14c9690b997"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

export let messaging: firebase.messaging.Messaging;



if (typeof window !== "undefined") {
  if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
  }
}

export const getMessagingToken = async () => {
  let currentToken = "";
  if (!messaging) return;
  try {
    currentToken = await messaging.getToken({
    //   vapidKey: process.env.REACT_APP_FIREBASE_FCM_VAPID_KEY,
    });
    console.log("FCM registration token", currentToken);
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
  return currentToken;
};

// Hàm này không sử dụng được
export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

  export const onDeleteToken = async() =>{
    return    await messaging.deleteToken();
  }
 

  



