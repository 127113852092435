import { now } from "lodash";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import notification from "../../../../../common/notification/notification";
import DHSDate from "../../../../../components/DHS/DHS-Date/DHSDateComponent";
import DHSDateTime from "../../../../../components/DHS/DHS-DateTime/DHSDateTimeComponent";
import DHSToolbar from "../../../../../components/DHS/DHS-Toolbar/DHSToolbar";
import Select from "../../../../../components/DHS/DHS_Select";
import { AppSession } from "../../../../shared/app-session/app-session";
import {
  HRClient,
  HRWorkShift,
  LeaveForm,
  LstHRLeaveType,
} from "../../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../shared/service-proxies/config";

var appSession: AppSession;
const regard = [
  "- Không được ứng năm.",
  "- Đơn vị nghỉ phép năm nhỏ nhất là 0.5 ngày (nửa buổi đầu ca hoặc nửa buổi cuối ca làm việc).",
  "- Trường hợp nghỉ BHXH phải có chừng từ BHXH.",
  "- Trường hợp nghỉ công tác phải có giấy tờ điều động công tác.",
  "- Trường hợp nghỉ hiếu hỉ/Tang gia phải có giấy tờ xác nhận tương ứng (giấy kết hôn/báo tử).",
  "- Nghỉ tai nạn lao động có giấy nghỉ tai nạn.",
];
const WorkerLeaveForm = () => {
  const hrClient = new HRClient(appSession, BASE_URL_API);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [back, setBack] = React.useState<number>(-1);
  const [data, setData] = React.useState<LeaveForm>({
    ...new LeaveForm(),
    fromdate: new Date(),
    todate: new Date(),
  } as LeaveForm);
  const [{ _id }, setId] = React.useState(useParams<{ _id: string }>());
  const history = useHistory();
  const [typeOptions, setTypeOptions] = React.useState<any[]>([]);

  // const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

  React.useEffect(() => {
    if (_id) {
      hrClient
        .hROnLeaveGet({
          ...new LeaveForm(),
          id: _id,
          ma_cty: SessionLogin.ma_cty,
          sender_code: SessionLogin.employeeCode,
        } as LeaveForm)
        .then((response) => {
          setData(response[0]);
        });
    }
  }, []);
  React.useEffect(() => {
    hrClient
      .lstHRLeaveTypeGet({
        ...new LstHRLeaveType(),
        ma_cty: SessionLogin.ma_cty,
      } as LstHRLeaveType)
      .then((response) => {
        setTypeOptions(response);
      });
  }, []);
  //Set Options

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value } as LeaveForm);
  };
  const handleChangeFromDate = (e: any) => {
    setData({ ...data, fromdate: e } as LeaveForm);
  };
  const handleChangeToDate = (e: any) => {
    setData({ ...data, todate: e } as LeaveForm);
  };
  const handleClickAction = () => {
    if (_id) {
      hrClient
        .hROnLeaveUpd({
          ...data,
          id: _id,
          sender_code: SessionLogin.employeeCode,
        } as LeaveForm)
        .then((res) => {
          setBack((item) => (item -= 1));
          if (res[0].ret === 0) {
            notification("success", res[0].message);
          } else if (res[0].ret === 1) {
            notification("error", res[0].message);
          } else {
            notification("warning", res[0].message);
          }
        });
    } else {
      hrClient
        .hROnLeaveIns({
          ...data,
          ma_cty: SessionLogin.ma_cty,
          sender_code: SessionLogin.employeeCode,
        } as LeaveForm)
        .then((res) => {
          setBack((item) => (item -= 1));
          if (res[0].ret === 0) {
            setId({ _id: res[0].id ? res[0].id : "" });
            notification("success", res[0].message);
          } else if (res[0].ret === 1) {
            notification("error", res[0].message);
          } else {
            notification("warning", res[0].message);
          }
        });
    }
  };
  return (
    <div>
      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          fontSize: 22,
          fontWeight: 550,
          marginTop: 15,
          marginBottom: 15,
        }}
      >
        <i
          className="bi bi-arrow-left"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.go(back);
          }}
        ></i>
        &nbsp;&nbsp;
        {"Đơn nghỉ phép"}
      </div>
      <DHSToolbar
        list={[
          {
            menuid: "04.00.00",
            code: "04.00.02",
            name_vn: "Lưu",
            class_name: "",
            on_form: "Edit",
            icon: "bi bi-save",
            link: "#",
            component: "WorkerLeaveForm",
            enable_default: true,
            is_parameter: false,
            disabled: false,
          },
        ]}
        menuid={""}
        id={""}
        current_form={""}
        onClickAction={handleClickAction}
      />
      <div className="d-flex">
        <div className="w-100 p-4">
          <div style={{ backgroundColor: "white" }} className="p-3 d-flex">
            <div className="w-100 h-100">
              <div className="row">
                <div className="form-group col-xl-12">
                  <label>Người gửi</label>
                  <input
                    className="flex-grow-1 form-control"
                    placeholder="Người gửi"
                    value={
                      data.sender_name
                        ? data.sender_name
                        : SessionLogin.userName
                    }
                    disabled={true}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-3">
                  <Select
                    label="Loại nghỉ phép"
                    value={data.type}
                    options={typeOptions}
                    onChange={handleChangeInput}
                    disabled={false}
                    fieldValue={"leave_type_id"}
                    fieldDisplay={"leave_type_name"}
                    name="type"
                  />
                </div>
                <div className="form-group col-md-3">
                  <DHSDate
                    label="Ngày bắt đầu"
                    className="flex-grow-1 form-control"
                    name="fromdate"
                    onChange={handleChangeFromDate}
                    value={data.fromdate?.toString()}
                    disabled={false}
                    id={data.id ? data.id : ""}
                  />
                </div>
                <div className="form-group col-md-3">
                  <DHSDate
                    label="Ngày kết thúc"
                    className="flex-grow-1 form-control"
                    name="todate"
                    onChange={handleChangeToDate}
                    value={data.todate?.toString()}
                    disabled={false}
                    id={data.id ? data.id : ""}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Tổng ngày nghỉ</label>
                  <input
                    className="flex-grow-1 form-control"
                    type="number"
                    name="sumarytime_onleave"
                    onChange={handleChangeInput}
                    value={data.sumarytime_onleave}
                    disabled={false}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Lí do</label>
                    <textarea
                      className="flex-grow-1 form-control"
                      name="reason"
                      onChange={handleChangeInput}
                      value={data.reason}
                      disabled={false}
                    />
                  </div>
                  <div className="form-group">
                    <label>Ý kiến từ người quản lý</label>
                    <textarea
                      className="flex-grow-1 form-control"
                      name="opinion_from_manager"
                      onChange={handleChangeInput}
                      value={data.opinion_from_manager}
                      disabled={true}
                    />
                  </div>
                  <div className="form-group">
                    <label>Ý kiến từ phòng nhân sự</label>
                    <textarea
                      className="flex-grow-1 form-control"
                      name="opinion_from_hr"
                      onChange={handleChangeInput}
                      value={data.opinion_from_hr}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="col-md-6 p-3" style={{fontSize: 14}}>
                  <i>
                    <b>Lưu ý</b>
                  </i>
                  {regard.map((item) => (
                    <p>{item}</p>
                  ))}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkerLeaveForm;
