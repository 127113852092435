import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Popper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AppSession } from '../../../../shared/app-session/app-session';
import {
    HomeClient,
    QLCCApartmentBuildingModel,
    QLCCBlockModel,
    QLCCClient,
    QLCCDomesticWaterCycleModel,
    QLCCFloorModel,
    QLCCReportServiceInvoiceListModel,
    QLCCRptDashboardPhi,
    QLCCTaskGroupCycleModel,
    SoVch05Client,
    SoVch05M
} from '../../../../shared/service-proxies/api-shared';
import BASE_URL_API from "../../../../shared/service-proxies/config";
import '../../../cost-management/container/domestic-water-floor.css';
import { IReducer, Notification } from '../../../../../components/layouts';
import BlockUI from '../../../../../components/Block-UI/Block-ui';
import ServiceInvoicesAction from '../../../actions/service-action/ServiceInvoicesAction';
import moment from 'moment';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PaidIcon from '@mui/icons-material/Paid';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { ToVietNamCurrency } from '../../../../../components/utils/number.util';
import SelectBox from 'devextreme-react/select-box';
import DHSSelectBox from '../../../../../components/DHS/DHS_SelectBox';
import DHSDateTimeComponentV2 from '../../../../../components/DHS/DHS-DateTime/DHSDateTimeComponentV2';
import { HintCode } from '../../../../../common/enums/DHSInputDetail';
import TaskCheckListNgayCT2 from '../../../actions/work/task-check-list-ngay-ct/task-check-list-ngay-ct-2';
import TaskCheckListNgayCT from '../../../actions/work/task-check-list-ngay-ct/task-check-list-ngay-ct';
import { promises } from 'fs';
import DomesticWaterServiceDataFilterTime from '../../components/service-invoice-apartment-list-filter-time';
import { Popup } from 'devextreme-react/data-grid';
import { Button as DevButton } from 'devextreme-react';
import { setApartmentBuilding } from '../../../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction';
import { CALL_API_LOAD_APARTMENT_BUILDING_LIST } from '../../../apartment-building/apartment-building-list';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
interface IProps {
    isSelected: string;
}

interface IDataFilter {
    apartmentBuildingId?: string | undefined;
    time_from?: Date | undefined;
    time_to?: Date | undefined;
    blockId: string | undefined;
}

let appSession: AppSession;



// styled

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.common.white,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const DomesticWaterService = (Props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const history = useHistory();
    const dispatch = useDispatch();

    // id apartment building
    const [_id, setId] = React.useState<string>("")
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const vch05Client = new SoVch05Client(appSession, BASE_URL_API);
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const [qlccFloorList, setQLCCFloorList] = React.useState<QLCCFloorModel[]>([] as QLCCFloorModel[]);
    const [qlccBlockList, setQLCCBlockList] = React.useState<QLCCBlockModel[]>([] as QLCCBlockModel[]);
    const [apartmentBuildingOptions, setAPartmentBuildingOptions] = React.useState<QLCCApartmentBuildingModel[]>([] as QLCCApartmentBuildingModel[]);
    const [soVch05List, setSoVch05List] = React.useState<SoVch05M[]>([] as SoVch05M[]);
    const [reload, setReload] = React.useState(false);
    /* load hiệu ứng */
    const [refresh, setRefresh] = React.useState(false);
    const [dataFilter, setDataFilter] = useState<IDataFilter>({ time_from: moment().startOf('month').toDate(), time_to: moment().endOf('month').toDate() } as IDataFilter);
    // const [dataFilter, setDataFilter] = useState<IDataFilter>({} as IDataFilter);
    const [dataDashboard, setDataDashboard] = useState<QLCCRptDashboardPhi>();
    const [isPopup, setIsPopup] = useState<boolean>(false);
    const [isLoadingAround, setLoadingAround] = useState<boolean>(false);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
    const [isViewBlock, setIsViewBlock] = React.useState<boolean>(false)


    /* load - danh sách tòa nhà, danh sách các chu kì */

    React.useEffect(() => {
        loadDataSource();
    }, [])

    useEffect(() => {
        loadBlockDataSource(apartment_building_id);
    }, [apartment_building_id])

    useEffect(() => {
        if (dataFilter.blockId !== undefined) {
            onloadFloor(dataFilter.blockId);
        }
    }, [dataFilter.blockId])

    useEffect(() => {
        if (qlccFloorList.length > 0) {
            onload();
        }
    }, [qlccFloorList])

    const [reportServiceInvoiceList, setReportServiceInvoiceList] = React.useState<QLCCReportServiceInvoiceListModel>();

    async function onLoadDataReportInvoice(paramsBlockId: string) {
        const res = await qLCCClient.qLCCReportServiceInvoiceListtGet({
            apartment_building_id: apartment_building_id,
            block_id: paramsBlockId ? paramsBlockId : dataFilter?.blockId,
            ma_ct: 'SO5',
            ma_cty: SessionLogin.ma_cty,
            ngay_ct1: moment(dataFilter?.time_from).startOf('day').utc(true).toDate(),
            ngay_ct2: moment(dataFilter?.time_to).endOf('day').utc(true).toDate(),
        } as QLCCReportServiceInvoiceListModel);

        const getItem = res.pop();
        if (getItem) {
            setReportServiceInvoiceList(getItem);

        }
    }

    const loadDataSource = async () => {
        setLoading(true);

        // CALL_API_LOAD_APARTMENT_BUILDING_LIST().then(res => {
        //     setAPartmentBuildingOptions(res);
        //     setLoading(false);
        // })

        const resApartmentBuilding = CALL_API_LOAD_APARTMENT_BUILDING_LIST();

        const results = await Promise.all([resApartmentBuilding]);
        setAPartmentBuildingOptions([...results[0]]);
    }

    const loadBlockDataSource = (apartmentBuildingID: string) => {
        qLCCClient
            .qlccBlockGet({
                ... new QLCCBlockModel(),
                apartment_building_id: apartmentBuildingID,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCBlockModel)
            .then(res => {
                setQLCCBlockList([...res]);
                const item = res.shift();
                if (item) {
                    setDataFilter({ ...dataFilter, blockId: item.id })
                } else {
                    setSoVch05List([])
                    setQLCCFloorList([])
                }
            });
    }

    /* load - danh sách hóa đơn của tòa nhà */
    const onloadServiceInvoice = (blockID: string) => {
        setLoading(true);
        vch05Client
            .qLCCsoVch05MGet({
                ...new SoVch05M,
                ma_ct: 'SO5',
                ma_cty: SessionLogin.ma_cty,
                block_id: blockID,
                apartment_building_id: apartment_building_id,
                ngay_ct1: moment(dataFilter.time_from).startOf('day').utc(true).toDate(),
                ngay_ct2: moment(dataFilter.time_to).endOf('day').utc(true).toDate(),
            } as SoVch05M)
            .then((res: any) => {
                setSoVch05List(res);
                setLoading(false);
                if (isPopup) setIsPopup(false);
            })
            .catch((err) => {
                Notification('error', err.message)
            })

        qLCCClient.qLCCRptDashboardPhi({
            ...new QLCCRptDashboardPhi(),
            ngay_ct1: moment(dataFilter.time_from).startOf('day').utc(true).toDate(),
            ngay_ct2: moment(dataFilter.time_to).endOf('day').utc(true).toDate(),
            apartment_building_id: apartment_building_id,
            ma_cty: SessionLogin.ma_cty,
        } as QLCCRptDashboardPhi)
            .then(res => {
                const item = res.shift();
                if (item) {
                    setDataDashboard(item);
                    if (isPopup) setIsPopup(false);
                }
            })

            // Không cần sử dụng nữa
        // onLoadDataReportInvoice(blockID)
    }

    /* load - danh sách tầng */
    const onloadFloor = (blockID: string) => {
        qLCCClient
            .qLCCFloorGet({
                ...new QLCCFloorModel(),
                block_id: blockID,
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCFloorModel)
            .then((res) => {
                let arrTemp = res;
                arrTemp.sort((a: any, b: any) => a.number_floor - b.number_floor);
                setQLCCFloorList(arrTemp);
            }).catch((err) => {
            })
    }

    const onload = () => {
        if (qlccFloorList.length > 0) {
            if (dataFilter.blockId) {
                onloadServiceInvoice(dataFilter.blockId);
            }
            else {
                var blockID = qlccFloorList[0].block_id;
                if (blockID) onloadServiceInvoice(blockID);
            }
        }
    }

    const handleClickBlock = (e: any) => {
        const blockID = e.target.id;
        setDataFilter({ ...dataFilter, blockId: blockID })
        // setDataFilter({ ...dataFilter, blockId: e })
    }

    const handleClick = (e: any) => {
        if (e.stt_rec)
            window.open(BASE_URL_API + `/Report/Reports?ma_cty=${SessionLogin.ma_cty}&ma_ct=${'SO5'}&stt_rec=${e.stt_rec}&apartment_building_id=${apartment_building_id}`);
    }

    const handleChangeInput = (e: any) => {
        const { name, value } = e;
        console.log(e)
        setDataFilter({ ...dataFilter, [name]: value })
        if (name === 'apartmentBuildingId') {
            dispatch(setApartmentBuilding({
                    id: value
                } as QLCCApartmentBuildingModel))
        }
    }

    const selectBlock = (floor_id: string) => {
        return `${dataFilter.blockId == floor_id ? "selected" : "unselected"} btn-hover block-btn`;
    }

    const handlerefresh = () => {
        setRefresh(true);
        onload();
        setTimeout(() => {
            Notification("success", "Làm mới lại danh sách");
            setRefresh(false);
        }, 1000);
    }

    const totalPay = (obj: SoVch05M[], id_: string | undefined) => {
        if (id_) {
            const filList = obj.filter(element => element.floor_id === id_ && element.ten_kh_vat !== null);
            let total = 0;
            filList.forEach(element => { if (element.total_old_debt) total += element.total_old_debt })
            return ToVietNamCurrency(total);
        }
        return;
    }

    // Đã thanh toán ghi thành kh thanh toán ghê thiệc á ba
    const totalNotPay = (obj: SoVch05M[], id_: string | undefined) => {
        if (id_) {
            const filList = obj.filter(element => element.floor_id === id_ && element.ten_kh_vat !== null && element.pay_status !== 0);
            let total = 0;
            filList.forEach(element => { if (element.da_tt) total += element.da_tt })
            return ToVietNamCurrency(total);
        }
        return;
    }

    const infoApartmentBuilding = () => {
        const filList = soVch05List.filter(element => element.ten_kh_vat !== null);
        if (filList.length > 0) {
            let totalMoney = 0;
            let totalBill = filList.length;
            let totalPay = 0;
            let old_debt = 0;
            let totalOrderPurchasePay = 0;
            filList.forEach(element => {
                if (element.total_old_debt) totalMoney += element.total_old_debt;
                if (element.pay_status !== 0 && element.da_tt) {
                    totalPay += element.da_tt;
                    totalOrderPurchasePay += 1;
                };
                // if (element.old_debt && element.old_debt !== 0) old_debt += element.old_debt;
                /*
                    SO5	0	Chưa thanh toán	Chưa thanh toán	        0
                    SO5	1	Chưa thanh toán đủ	Chưa thanh toán đủ	0
                    SO5	2	Đã thanh toán	Đã thanh toán	        0
                    SO5	3	Thanh toán dư	Thanh toán dư	        0
                */
            })
            return {
                totalMoney, // Tổng phí
                totalBill,
                totalPay, //  đã thanh toán
                old_debt: totalMoney - totalPay,
                totalOrderPurchasePay
            }
        }
        return {
            totalMoney: 0,
            totalBill: 0,
            totalPay: 0,
            old_debt: 0,
            totalOrderPurchasePay: 0
        }
    }

    const Dashboard = (titles: string, total: number = 0, icon: any, type: string = "", color: string = "black", textColor: string = "white") => {
        return (
            <Grid
                item xs={12} sm={6} md={3} lg={2.9}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Box
                    className='display-none-500'
                    sx={{ width: '100%', padding: '10px', borderRadius: 2, backgroundColor: color, color: textColor }}
                >
                    <Box className='d-flex justify-content-between'>
                        <Typography className="font-weight-600">{titles}</Typography>
                        {icon}
                    </Box>
                    <Box >
                        <Typography sx={{ fontSize: '25px', fontweight: '600', textAlign: "right" }}>
                            {type === 'MONEY' ? ToVietNamCurrency(total) : `${total} ${type}`}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        )
    }

    return (
        <Grid container spacing={1} >
            <Grid item xs={12}  >
                <Grid container spacing={2}
                    className="title-color"
                    sx={
                        {
                            height: 10,
                            color: "rgb(0 78 255 / 88%)",
                            marginTop: 2,
                            marginBottom: 15,
                        }}
                >
                    <Grid item xs={12} sm={12} md={12} lg={3}>
                        <div
                            style={{
                                fontSize: 22,
                                fontWeight: 550,
                            }}
                        >
                            <i
                                className="bi bi-arrow-left-short"
                                style={{ cursor: "pointer" }}
                                onClick={() => history.goBack()}
                            ></i>
                            &nbsp; Doanh số hóa đơn
                            <Button
                                variant="outlined"
                                style={{ marginLeft: 20, paddingTop: 0, paddingBottom: 0, minWidth: 100 }}
                                onClick={handlerefresh}
                            >
                                {refresh ? <CircularProgress size={18} /> : "Làm mới"}
                            </Button>
                        </div>

                    </Grid>

                    {/* Dashboard */}
                    <Grid item xs={12} className='position-relative' sm={12} md={12} lg={8.5} style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Grid container spacing={2}>
                            {
                                Dashboard("Tổng tiền", dataDashboard?.tong_thu ?? 0, <PaidIcon
                                    sx={{
                                        fontSize: "24px",
                                        marginRight: '10px',
                                    }}
                                />, "MONEY", "#0083CE")
                            }
                            {
                                Dashboard(`Đã thanh toán (${infoApartmentBuilding().totalOrderPurchasePay} HĐ)`, dataDashboard?.da_thu ?? 0, <CreditScoreIcon
                                    sx={{
                                        fontSize: "24px",
                                        marginRight: '10px',
                                    }}
                                />, "MONEY", "#FFA300")
                            }
                            {
                                Dashboard("Dư nợ", dataDashboard?.con_no ?? 0, <PriceChangeIcon
                                    sx={{
                                        fontSize: "24px",
                                        marginRight: '10px',
                                    }}
                                />, "MONEY", "#FF6D60")
                            }
                            {
                                Dashboard("Số lượng hóa đơn", infoApartmentBuilding().totalBill, <RequestPageIcon
                                    sx={{
                                        fontSize: "24px",
                                        marginRight: '10px',
                                    }}
                                />, "Hóa đơn", "#00AEEF")
                            }
                            {
                                isViewBlock && <>
                                    {/* Tòa nhà */}
                                    {
                                        Dashboard("Tổng tiền", reportServiceInvoiceList?.t1 ?? 0, <PaidIcon
                                            sx={{
                                                fontSize: "24px",
                                                marginRight: '10px',
                                            }}
                                        />, "MONEY", "#0083CE")
                                    }
                                    {
                                        Dashboard(`Đã thanh toán (${reportServiceInvoiceList?.da_thanh_toan ?? 0} HĐ)`, reportServiceInvoiceList?.da_tt ?? 0, <CreditScoreIcon
                                            sx={{
                                                fontSize: "24px",
                                                marginRight: '10px',
                                            }}
                                        />, "MONEY", "#FFA300")
                                    }
                                    {
                                        Dashboard("Dư nợ", reportServiceInvoiceList?.t2 ?? 0, <PriceChangeIcon
                                            sx={{
                                                fontSize: "24px",
                                                marginRight: '10px',
                                            }}
                                        />, "MONEY", "#FF6D60")
                                    }
                                    {
                                        Dashboard("Số lượng hóa đơn", reportServiceInvoiceList?.so_luong_hd, <RequestPageIcon
                                            sx={{
                                                fontSize: "24px",
                                                marginRight: '10px',
                                            }}
                                        />, "Hóa đơn", "#00AEEF")
                                    }</>
                            }
                            {/* <IconButton className='position-absolute bottom-0 end-0' onClick={() => setIsViewBlock(pre => !pre)} style={{
                                bottom: 0,
                                right: 0
                            }}>
                                {
                                    !isViewBlock ? <ExpandMoreIcon /> : <ExpandLessIcon />
                                }
                            </IconButton> */}
                        </Grid>

                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container spacing={1}>

                            {/* Trái */}
                            <Grid item xs={12} sm={4.5} md={3} lg={3}>
                                <Box
                                    className="d-flex justify-content-center"
                                >
                                    <Box sx={{ width: '80%' }} >
                                        {/* <InputLabel htmlFor="input-with-domestic-water-cycle">
                                            Bước 1. Chọn dự án
                                        </InputLabel>
                                        <SelectBox
                                            dataSource={[...apartmentBuildingOptions]}
                                            valueExpr={'id'}
                                            displayExpr={'name'}
                                            value={apartment_building_id}
                                            onValueChanged={e => handleChangeInput({ name: 'apartmentBuildingId', value: e.value })}
                                            name='apartmentBuildingId'
                                            noDataText={`<div style='color: #a0a0a0; text-align: center;'>Trống</div>`}
                                        /> */}
                                        <InputLabel htmlFor="input-with-domestic-water-cycle">
                                            Bước 1. Thời gian
                                        </InputLabel>

                                        <DevButton
                                            className="w-100"
                                            text={`${moment(dataFilter.time_from).format('DD/MM')} - ${moment(dataFilter.time_to).format('DD/MM')}`}
                                            type="default"
                                            width={300}
                                            onClick={() => setIsPopup(true)}
                                            stylingMode='outlined'
                                        />
                                        <Dialog open={isPopup} onClose={() => setIsPopup(false)} keepMounted>
                                            <DialogTitle>Thời gian</DialogTitle>
                                            <DialogContent>
                                                <DomesticWaterServiceDataFilterTime
                                                    defaultCycle={3}
                                                    timeData={{
                                                        time_from: dataFilter.time_from,
                                                        time_to: dataFilter.time_to,
                                                    }}
                                                    onChangeTimeData={e => {
                                                        const newDateFilter = { ...dataFilter, time_from: moment(e?.time_from).startOf('day').toDate(), time_to: moment(e?.time_to).endOf('day').toDate() } as IDataFilter;
                                                        // console.log('time e', e)
                                                        // console.log('time newDateFilter', newDateFilter)
                                                        setDataFilter({ ...newDateFilter });
                                                    }}
                                                    type='datetime'
                                                    displayFormat='dd/MM/yyyy'
                                                />
                                                <BlockUI blocking={isLoading} title={""}></BlockUI>
                                            </DialogContent>
                                            <DialogActions>
                                                <DevButton
                                                    // className="w-100"
                                                    text={`Xác nhận`}
                                                    type="default"
                                                    width={300}
                                                    onClick={onload}
                                                />
                                                <DevButton
                                                    // className="w-100"
                                                    text={`Đóng`}
                                                    type="normal"
                                                    width={300}
                                                    onClick={() => setIsPopup(false)}
                                                />
                                            </DialogActions>
                                        </Dialog>
                                    </Box>
                                </Box>
                                <Box className="d-flex justify-content-center">
                                    <Box
                                        sx={{
                                            width: '80%'
                                        }}
                                    >
                                        <InputLabel htmlFor="input-with-domestic-water-block">
                                            Bước 2. Chọn tòa nhà ({qlccBlockList && (`${qlccBlockList.length}`)})
                                        </InputLabel>
                                        <Box id="input-with-domestic-water-block" className=' width-scrollbar-hoa-don' >
                                            {
                                                qlccBlockList && qlccBlockList.map((blockItem) => (
                                                    <Button className={`${selectBlock(blockItem.id || "")} w-100`} key={blockItem.id} id={blockItem.id} onClick={handleClickBlock}>
                                                        {/* {(dataFilter.blockId === blockItem.id) ? <CircularProgress size={18} color='inherit' /> : blockItem.name} */}
                                                        {blockItem.name}
                                                    </Button>
                                                ))
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            {/* Phải */}
                            <Grid item xs={12} sm={7.5} md={9} lg={9} >

                                <InputLabel
                                    htmlFor="input-with-domestic-water-block"
                                    className='label-input-domestic-water d-flex justify-content-between align-items-center'
                                >
                                    <Grid container
                                        sx={{
                                            marginRight: '3%',
                                        }}
                                    >
                                        <Grid
                                            item xs={12} sm={5} md={8}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            Bước 3. Danh sách tầng {dataFilter.blockId && qlccFloorList && (`(${qlccFloorList.length})`)}
                                        </Grid>
                                    </Grid>

                                </InputLabel>
                                <Box
                                    // className='hidden-scrollbar width-scrollbar-floor'
                                    className='hidden-scrollbar'
                                    sx={{
                                        height: isViewBlock ? '60vh' : '72vh'
                                    }}
                                >
                                    {
                                        qlccFloorList &&
                                        qlccFloorList.map(item => (
                                            <Accordion
                                                key={item.id}
                                                sx={{
                                                    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;',
                                                    backgroundColor: '#198754'
                                                }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Grid container spacing={2} sx={{ color: 'white' }}>
                                                        <Grid item xs={4} sm={2} md={2}>
                                                            <span className="fontWeight_500" style={{ fontSize: 14 }}>
                                                                {item.name}
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={2}>
                                                            <RequestPageIcon
                                                                sx={{
                                                                    fontSize: "24px",
                                                                    marginRight: '10px',
                                                                }}
                                                            />
                                                            {soVch05List.filter(element => element.floor_id === item.id && element.ten_kh_vat !== null).length &&
                                                                (
                                                                    `(${soVch05List.filter(element => element.floor_id === item.id && element.ten_kh_vat !== null).length})`
                                                                )
                                                            }
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={2}>
                                                            <CreditScoreIcon
                                                                sx={{
                                                                    fontSize: "24px",
                                                                    marginRight: '10px',
                                                                }}
                                                            />
                                                            {soVch05List.filter(element => element.floor_id === item.id && element.ten_kh_vat !== null).length &&
                                                                (
                                                                    `(${soVch05List.filter(element => element.floor_id === item.id && element.ten_kh_vat !== null && element.pay_status !== 0).length})`
                                                                )
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={3}>
                                                            {totalPay(soVch05List, item.id) !== 0
                                                                &&
                                                                (
                                                                    <span
                                                                        className=' icon-inline-block-200'
                                                                    >
                                                                        <span>Tổng tiền: </span>{totalPay(soVch05List, item.id)}
                                                                    </span>
                                                                )
                                                            }</Grid>
                                                        <Grid item xs={12} sm={6} md={3}>
                                                            {totalNotPay(soVch05List, item.id) !== 0
                                                                &&
                                                                (
                                                                    <span
                                                                        className=' icon-inline-block-200'
                                                                    >
                                                                        <span> Đã thanh toán: </span>{totalNotPay(soVch05List, item.id)}
                                                                    </span>
                                                                )
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    sx={{
                                                        backgroundColor: "#F0F5F9"
                                                    }}
                                                >
                                                    <Typography>
                                                        {
                                                            soVch05List
                                                            && soVch05List.filter(element => element.floor_id === item.id).length > 0
                                                            && (
                                                                <div className='container-fluid p-0'>
                                                                    <TableContainer component={Paper} sx={{ maxHeight: '50vh' }}>
                                                                        <Table
                                                                            sx={{
                                                                                maxWidth: '100%',
                                                                            }}
                                                                            stickyHeader
                                                                            aria-label="Table for service invoice list">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <StyledTableCell align="center">STT</StyledTableCell>
                                                                                    <StyledTableCell align="center">Căn hộ</StyledTableCell>
                                                                                    <StyledTableCell align="center">Chủ hộ</StyledTableCell>
                                                                                    <StyledTableCell align="center">Số hóa đơn</StyledTableCell>
                                                                                    <StyledTableCell align="center">Phí tháng này</StyledTableCell>
                                                                                    <StyledTableCell align="center">Tổng phí</StyledTableCell>
                                                                                    <StyledTableCell align="center">Đã thu</StyledTableCell>
                                                                                    <StyledTableCell align="center">Trạng thái</StyledTableCell>
                                                                                    <StyledTableCell align="center">Ngày tạo</StyledTableCell>
                                                                                    {/* <StyledTableCell align="center">Số lần phát hành</StyledTableCell> */}
                                                                                    <StyledTableCell align="center"></StyledTableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {soVch05List.filter(element => element.floor_id === item.id).length > 0
                                                                                    && soVch05List.filter(element => element.floor_id === item.id && element.ten_kh_vat !== null).map((row, i) => (
                                                                                        <StyledTableRow key={row.stt_rec} >
                                                                                            <StyledTableCell align="center">{i + 1}</StyledTableCell>
                                                                                            <StyledTableCell component="th" scope="row">
                                                                                                {row.apartment_name}
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell >{row.ten_kh_vat}</StyledTableCell>
                                                                                            <StyledTableCell >{row.so_ct}</StyledTableCell>
                                                                                            <StyledTableCell align="right" >{new Intl.NumberFormat('vi-VN').format(Number(row.t_tt))}</StyledTableCell>
                                                                                            <StyledTableCell align="right" >{new Intl.NumberFormat('vi-VN').format(Number(row.total_old_debt))}</StyledTableCell>
                                                                                            <StyledTableCell align="right" >{new Intl.NumberFormat('vi-VN').format(Number(row.da_tt))}</StyledTableCell>
                                                                                            <StyledTableCell
                                                                                                align="center"
                                                                                                style={{
                                                                                                    color: !row.pay_status ? "red" : "green",
                                                                                                }}
                                                                                            >
                                                                                                {row.pay_status_name}
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell >{moment(row.cdate).format("DD/MM/YYYY")}</StyledTableCell>
                                                                                            {/* <StyledTableCell align="right">{'0'}</StyledTableCell> */}
                                                                                            <StyledTableCell
                                                                                                style={{
                                                                                                    color: 'blueviolet',
                                                                                                    cursor: 'pointer',
                                                                                                }}
                                                                                                onClick={() => handleClick(row)}>Chi tiết</StyledTableCell>
                                                                                        </StyledTableRow>
                                                                                    ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                    <BlockUI blocking={isLoading} title={""}></BlockUI>
                                                                </div>
                                                            )
                                                            //    qlccDomesticWaterList.filter(element => element.floor_id == item.id).map(e => (
                                                            //     ))
                                                        }
                                                        {
                                                            !soVch05List ||
                                                            (soVch05List &&
                                                                soVch05List.filter(element => element.floor_id === item.id).length === 0) && (
                                                                <div
                                                                    style={{
                                                                        textAlign: 'center'
                                                                    }}
                                                                >
                                                                    {
                                                                        dataFilter.blockId &&
                                                                            dataFilter.blockId === "" ? (
                                                                            <h4 style={{ color: '#D8D8D8' }}>Vui lòng chọn tòa nhà!</h4>
                                                                        ) : (
                                                                            <h5 style={{ color: '#D8D8D8' }}>Không có hóa đơn</h5>
                                                                        )
                                                                    }

                                                                </div>
                                                            )
                                                        }
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))
                                    }
                                    {
                                        (qlccFloorList &&
                                            qlccFloorList.length === 0
                                        ) && (
                                            <div
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {
                                                    dataFilter.blockId &&
                                                    dataFilter.blockId !== "" && (
                                                        <h5 style={{ color: '#D8D8D8' }}>Tầng trống</h5>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        !dataFilter.blockId &&
                                        (
                                            <div
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <h5>Chọn tòa nhà</h5>
                                            </div>
                                        )
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default DomesticWaterService

