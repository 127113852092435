import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "./style.css";
import {
  ChoThueXeClient,
  CtxDanhMucXeModel,
  CtxLoaiXeModel,
} from "../../../app/shared/service-proxies/api-shared";
import { AppSession } from "../../../app/shared/app-session/app-session";
import BASE_URL_API from "../../../app/shared/service-proxies/config";
import { InputLabel, MenuItem } from "@mui/material";
interface IProps {
  open: boolean;
  row: any;
  table?: string;
  title?: any;
  onClose: () => void;
  onSubmit?: (data: any) => void;
}
let appSession: AppSession;

export default function CTXFormDialog(props: IProps) {
  const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);
  const [data, setData] = React.useState("");
  const [data2, setData2] = React.useState<CtxLoaiXeModel>(
    new CtxLoaiXeModel()
  );
  const [hangXeOptions, setHangXeOptions] = React.useState<any[]>([]);

  React.useEffect(() => {
    ctxClient
      .ctxDanhMucXeActions({
        ...new CtxDanhMucXeModel(),
        tbname: "ctxHangXe",
        action: "GET",
      } as CtxDanhMucXeModel)
      .then((res) => {
        setHangXeOptions(res);
      });
    if (props.row && props.title.slice(0, 4).toUpperCase() !== "THÊM") {
      switch (props.table?.toUpperCase()) {
        case "CTXLOAIXE":
          ctxClient
            .ctxLoaiXeGet({
              ...new CtxLoaiXeModel(),
              id: props.row,
            } as CtxLoaiXeModel)
            .then((res) => {
              setData2(res[0]);
            });
          break;
        default:
          ctxClient
            .ctxDanhMucXeActions({
              ...new CtxDanhMucXeModel(),
              id: props.row,
              tbname: props.table,
              action: "GET",
            } as CtxDanhMucXeModel)
            .then((res) => {
              console.log(res)
              if (res[0].name) setData(res[0].name);
            });
          break;
      }
    } else setData("");
  }, [props.row, props.title]);

  const handleChangeInput = (e: any) => {
    setData(e.target.value);
  };
  const handleChangeInput2 = (e: any) => {
    const { name, value } = e.target;
    setData2({ ...data2, [name]: value } as CtxLoaiXeModel);
  };

  return (
    <div>
      <Dialog id="danhmuc" open={props.open} onClose={props.onClose}>
        <DialogTitle>{`${props.title}`}</DialogTitle>
        <DialogContent dividers>
          {props.table?.toUpperCase() === "CTXLOAIXE" ? (
            <>
              <InputLabel>Tên</InputLabel>
              <TextField
                value={data2.name}
                id="name"
                name="name"
                // label="Tên"
                fullWidth
                variant="standard"
                onChange={handleChangeInput2}
              />
              <br />
              <br />
              <InputLabel>Hãng xe</InputLabel>

              <TextField
                value={data2.hang_xe_id ? data2.hang_xe_id : ""}
                id="hang_xe_id"
                select
                name="hang_xe_id"
                // label="Hãng xe"
                fullWidth
                variant="standard"
                onChange={handleChangeInput2}
              >
                <MenuItem key="hang_xe" value="">
                  Chọn
                </MenuItem>
                {hangXeOptions.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </>
          ) : (
            <>
              <InputLabel>Tên</InputLabel>
              <TextField
                value={data}
                autoFocus
                id="name"
                name="name"
                // label="Tên"
                fullWidth
                variant="standard"
                onChange={handleChangeInput}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          {props.onSubmit && (
            <Button
              onClick={() =>
                props.onSubmit &&
                props.onSubmit(
                  props.table?.toUpperCase() === "CTXLOAIXE" ? data2 : data
                )
              }
            >
              Lưu
            </Button>
          )}
          <Button onClick={props.onClose}>Đóng</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
