import axios, { AxiosRequestConfig } from "axios";
import BASE_URL_API from "../../shared/service-proxies/config";


// const BASE_URL_API = "https://localhost:44340/";

let options_: AxiosRequestConfig = {
    baseURL: BASE_URL_API,
    headers: {
        "Content-Type": "application/json",
        "Accept": "text/plain"
    },
};
export default axios.create(options_);
