import { AxiosRequestConfig } from "axios";
import http from "../../routes/http-common"


export const httpGet = <T>(url: string, params?: any, config?: AxiosRequestConfig) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    return http.get<T>(url, {
        ...config,
        headers: {
            ...config?.headers,
            token: SessionLogin.Token,
            params: params,
            
            
        }
    });
}

export const httpPost = <T>(url: string, body: any, config?: AxiosRequestConfig) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    return http.post<T>(url, body, {
        ...config,
        headers: {
            ...config?.headers,
            token: SessionLogin.Token,
        }
    });
}