import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import CTXToolbar from "../../../../components/CTX/Toolbar";
import { Notification } from "../../../../components/layouts";
import DHSGridView from "../../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { getRoles } from "../../../../redux/actions/RoleActions";
import { AppSession } from "../../../shared/app-session/app-session";
import {
    HomeClient,
    QLCCApartmentBuildingModel,
    QLCCBlockModel,
    QLCCClient,

    RoleRightWebModel,
    SysActionType,
    SysModel,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
// import BlockAction from "../../actions/block-action/BlockAction";
import BlockAction from "../../actions/block-action/BlockAction";
import moment from "moment";
import { useHotkeys } from "react-hotkeys-hook";
import DHSBreadcrumb from "../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DataGrid from "devextreme-react/data-grid";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import { EventInfo } from "devextreme/events";
import dxDataGrid, { SelectionChangedInfo } from "devextreme/ui/data_grid";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { getIndexRowSelected, getKeyRowSelected } from "../../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

let appSession: AppSession;
const convert = ["success", "error", "warning"];
const menuRoleName = "CC";
const DanhMucKSC = () => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const location = useLocation();
    const dispatch = useDispatch();
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const [itemSelected, selectItem] = React.useState<string>();
    const [action, setAction] = React.useState<IAction>({
        open: false,
        type: "",
        payload: ""
    });
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<QLCCBlockModel[]>([]);
    const [reload, setReload] = React.useState(false);
    const [actCx, setActCx] = React.useState<IAction>(defaultAction);
    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>();
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    React.useEffect(() => {
        setLoading(true);
        qLCCClient
            ?.qlccBlockGet({
                ...new QLCCBlockModel(),
                action_type: 'GET',
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCBlockModel)
            .then((res) => {
                setDataTable(res);
            });
        setLoading(false);
    }, [reload, apartment_building_id]);

    const handleClickAction = async (action: SysActionType) => {
        switch (action?.action_code?.toUpperCase()) {
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            if (window.confirm("Bạn có chắc muốn xóa")) {
                                setLoading(true)
                                qLCCClient
                                    .qlccBlockAction({
                                        ...new QLCCBlockModel(),
                                        id: itemSelected,
                                        action_type: "DELETE",
                                    } as QLCCBlockModel)
                                    .then((response) => {
                                        Notification(convert[0], response.message);
                                        setReload(!reload);
                                        selectItem("")
                                    })
                                    .catch((error) => {
                                        setLoading(false);
                                        Notification(convert[0], error.message);
                                        setReload(!reload);
                                        selectItem("")
                                    });
                            }
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: action?.action_code?.toUpperCase() ?? "",
                    payload: selectedItemKeys?.id ?? null
                })
                break;
            case ACTIONS.EDIT:
            case ACTIONS.VIEW:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: action?.action_code?.toUpperCase() ?? "",
                                payload: selectedItemKeys?.id ?? null
                            })
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }

                break;
        }
    };

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                console.log(keys);
                setSelectedItemKeys(keys[0]);
                selectItem(keys[0].id);

            });
        }
    };

    const handlePageChange = () => {
        setSelectedItemKeys(undefined);
    };

    return (
        <div
            className="container-fluid p-2"
            style={{ maxHeight: "94vh !important", overflowY: "auto" }}
        >
            {
                actCx.open && <BlockAction
                    open={actCx.open}
                    onClose={function (): void {
                        setActCx(defaultAction);
                    }}
                    action={actCx.type}
                    itemSelected={actCx.payload}
                    onEditSuccess={(is_success: boolean) => {
                        if (is_success) {
                            setActCx({
                                ...actCx,
                                open: false,
                            })
                            setReload(!reload);
                        }
                    }} />
            }
            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location}
                />
            </div>
            <DHSToolbarRole
                id={" "}
                menu={menu}
                customMenu={[]}
                onClickAction={handleClickAction}
            />
            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                // key="so_ct"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handleSelectionChanged={handleSelectionChanged}
                handlePageChange={handlePageChange}

            />
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </div>
    );
};

export default DanhMucKSC;
