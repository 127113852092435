import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,

    Divider,
    DialogTitle,
    Autocomplete,
    Select,
    MenuItem,
    DialogContentText,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Tooltip,
} from "@mui/material";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from '@mui/icons-material/Help';
import {
    HomeClient,
    QLCCClient,
    RoleRightWebModel,
    QLCCApartmentBuildingModel,
    QLCCApartmentTypesModel,
    LstUnitModel,
    SysActionType,
    QLCCUtilityBookingModel,
    QLCCUtilityModel,
    QLCCUtilityBookingStatusModel,
    CommonClient,
    ParaObj,
    QLCCResidentsModel,
    HomePageClient,
    UserInfoModel,
} from "../../../shared/service-proxies/api-shared";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VerifiedIcon from '@mui/icons-material/Verified';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';


import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DoNotTouchIcon from '@mui/icons-material/DoNotTouch';
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { ICustomer, IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../../actions/ApartmentBuilding.css";
import notification from "../../../../common/notification/notification";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import DHSAutoCompleteWithDataGrid from "../../../../components/DHS/DHS_AutoCompleteCustomer/DHSAutoCompleteWithDataGrid";
import { httpPost } from "../../../../common/httpService";
import DateBox from "devextreme-react/date-box";
import { TransDateTime } from "../../../../components/utils/date.util";
import DataGrid from "devextreme-react/data-grid";
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DHSDialogAction2 from "../../../../components/DHS/DHS_DialogAction2/DialogAction2";
import { getListKeyRowSelected } from "../../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import { CellPreparedEvent } from "devextreme/ui/data_grid";
import NumberBox from "devextreme-react/number-box";
import moment from "moment";


interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (id?: string, dataEdited?: QLCCUtilityBookingModel) => void;
    onAddSuccess: (id?: string, dataAdded?: QLCCUtilityBookingModel) => void;
} 

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const status_message = ["success", "error"]

//#region statusName

// 1: Đang đợi duyệt
// 2: Đã đặt thành công
// 5: Bị từ chối

//#endregion

var appSession: AppSession;
var htmlString: string;
const ServiceBookingRejectAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

   
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    // truyền id dự án
    const [data, setData] = React.useState<QLCCUtilityBookingModel>({} as QLCCUtilityBookingModel);
    const [dataTable, setDataTable] = React.useState<QLCCUtilityBookingModel[]>([]);

    const [isLoading, setLoading] = React.useState<boolean>(false);
   

    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    // const [totalApartmentMessage, setTotalApartmentMessage] = React.useState<JSX.Element>();

    const [apartmentBuildingOption, setApartmentBuildingOption] = React.useState<any>([]);



    const [fullScreen, setFullscreen] = React.useState<boolean>(false);

    // 

    const [isExpandUtilityBookingRejectAction, setIsExpandUtilityBookingRejectAction] = React.useState<boolean>(true);

    // 

    const dataGridRef = React.useRef<DataGrid>(null);


    React.useEffect(() => {
        if(props.action === ACTIONS.REJECT || props.action === ACTIONS.CANCELAPPROVAL){
            setDataTable(props.itemSelected?.utility_bookings)
        }
    }, [props.action])

    const handleCancelApproval = async () => {
        setLoading(true)
        qLCClient.qLCCUtilityBookingReject({
            ...new QLCCUtilityBookingModel(),
            ...data,
            apartment_building_id: dataTable[0]?.apartment_building_id,
            start_time: data.start_time ? TransDateTime(data.start_time) : null,
            end_time: data.end_time ? TransDateTime(data.end_time) : null,
            ma_cty: dataTable[0]?.ma_cty,
            id_reject: dataTable.map(x=>x.id).join(";")
        } as QLCCUtilityBookingModel)
            .then(response => {
                setLoading(false);
                notification(status_message[response.status!], STATUS_MESSAGE[`${props.action}_${response.status}`]);
                if (response.status === 0) {
                    props.onEditSuccess(response.id, data);
                    props.onClose();
                }
            })
            .catch(error => {
                setLoading(false)
                notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
            })
    }

    const handleValidateField = (): boolean => {
        if (!data?.reason) {
            return false
        }

        return true;
    }


    const handleSubmit = () => {

        if(!handleValidateField()){
            notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["FIELD_REQUIRED"])
            return;
        }
        setLoading(true)

        handleCancelApproval()

    }


    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;

        setData(pre => ({ ...pre, [name]: value } as QLCCUtilityBookingModel));
    };

    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                handleSubmit();
                // handleOpen()
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;

            default: break;
        }
    }


    const handleChangeCellColor = (e: CellPreparedEvent<QLCCUtilityBookingModel, any>) => {
        // console.log(e.data?.status_id)
        if (e.column.dataField === "status_name" && e.data) {
            switch (e.data?.status_code) {

                case null:
                case "1":
                    e.cellElement.className += "utility-status utility-pending-status"
                    break;
                case "5":
                    e.cellElement.className += "utility-status utility-refuse-status"
                    break;
                case "2":
                    e.cellElement.className += "utility-status utility-approved-status"
                    break;
            }
        }


    }
    return (
        <Dialog
            // id="sysMenu"
            open={props.open}
            onClose={props.onClose}
            // sx={{
            //     display: "flex",
            //     justifyContent: "flex-end",
            // }}
            PaperProps={{
                sx: !fullScreen ? {
                    minHeight: "90vh",
                    height: "90vh"
                } : undefined
            }}
            TransitionComponent={Transition}
            maxWidth="lg"
            fullWidth
            fullScreen={fullScreen}
            className="Asset-control"
        >
            {/* <Dialog
                open={openCamera.open}
                onClose={handleCloseCamera}
                sx={{ height: "inherit" }}
            >
                
            </Dialog> */}

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === ACTIONS.REJECT
                                ? "[Từ chối]"
                                : props.action === ACTIONS.REJECT ? "Hủy duyệt" : ""
                            }`}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1.25 }}>
                        <IconButton
                            edge="end"

                            onClick={() => {
                                setFullscreen(pre => !pre)
                            }}
                            aria-label="full screen"
                        >
                            {fullScreen ? <FullscreenExitIcon sx={{ color: '#1f2937' }} /> : <FullscreenIcon sx={{ color: '#1f2937' }} />}
                        </IconButton>
                        <IconButton
                            edge="end"
                            onClick={() => {
                                props.onClose();
                            }}
                            aria-label="close"
                        >
                            <CloseIcon
                                sx={{ color: '#1f2937' }}
                            />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>

                {(props.action === ACTIONS.REJECT || props.action === ACTIONS.CANCELAPPROVAL) && <Accordion className="Asset-control" expanded={isExpandUtilityBookingRejectAction} onChange={() => { setIsExpandUtilityBookingRejectAction(pre => !pre) }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        sx={{ backgroundColor: "#e0f2fe" }}
                    >

                        <Box className="d-flex flex-row position-relative">
                            <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Đăng ký bị {props.action === ACTIONS.REJECT ? "từ chối" : props.action === ACTIONS.CANCELAPPROVAL ? "hủy duyệt" : ""}</Typography>
                         

                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            data?.status_code !== "2" && <div className="row">
                                {/* <div className="col-12 col-xl-4">
                               
                            </div> */}
                                <div className="col-6">
                                    <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                        <Grid item xs={12} md={4}>
                                            <InputLabel
                                                sx={{ fontSize: 14, color: "#333", }}
                                                required
                                            >
                                                Lý do {props.action === ACTIONS.REJECT ? "từ chối" : props.action === ACTIONS.CANCELAPPROVAL ? "hủy duyệt" : ""}
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                // placeholder="Đơn giá..."
                                                // inputProps={{
                                                //     style: {
                                                //         textAlign: "right"

                                                //     }
                                                // }}
                                                name="reason"
                                                onChange={handleChangeInput}
                                                value={data?.reason ?? null}
                                                type="text"
                                                disabled={props.action === ACTIONS.VIEW}
                                            // inputProps={{
                                            //     readOnly: props.action === ACTIONS.APPROVED
                                            // }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        }

                        <DHSDataGridEdit
                            data={dataTable}
                            table_name={menu?.component}
                            keyExpr="id"
                            mode="popup"
                            dataGridRef={dataGridRef}
                            allowEdit={false}
                            pageSize={menu?.page_size}
                            isPagination={menu?.is_pagination}
                            // isMultiple={true}
                            
                            groupingAutoExpandAll={true}
                        handleCellPrepared={handleChangeCellColor}
                        // handleSelectionChanged={handleSelectionChanged}
                        // handlePageChange={handlePageChange}

                        // handleExporting={handleExporting}
                        />
                    </AccordionDetails>
                </Accordion>}
            </DialogContent>
            <DialogActions>



                {
                    <DHSDialogAction

                        action={props.action}
                        menu={menu}
                        onClickAction={handleClickAction}
                    />
                }


            </DialogActions>


            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default ServiceBookingRejectAction;
