import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,

    Divider,
    DialogTitle,
    Autocomplete,
    FormControlLabel,
    Switch,
    Select,
    MenuItem,
    DialogContentText,
} from "@mui/material";
import CurrencyTextField from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import {
    HomeClient,
    QLCCClient,
    RoleRightWebModel,
    QLCCApartmentBuildingModel,
    QLCCApartmentTypesModel,
    LstUnitModel,
    QLCCApartmentModel,
    SysActionType,
    QLCCUtilityGroupDetailModel,
} from "../../../shared/service-proxies/api-shared";


import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../../actions/ApartmentBuilding.css";
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import notification from "../../../../common/notification/notification";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import { httpPost } from "../../../../common/httpService";
import axios from "axios";
import NumberBox from "devextreme-react/number-box";
import DHSAutocompleteFilterListSelect from "../../../../components/DHSUtilComponent/DHSAutocompleteFilterListSelect/DHSAutocompleteFilterListSelect";
interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (id?: string, dataEdited?: QLCCUtilityGroupDetailModel) => void;
    onAddSuccess: (id?: string, dataAdded?: QLCCUtilityGroupDetailModel) => void;
}
// interface IOpenCamera {
//     pic: any;
//     open: boolean;
// }

// const videoConstraints = {
//     width: 1280,
//     height: 720,
//     facingMode: "user",
// };

// const image_size_citizen_identification_card = {
//     width: 540,
//     height: 360,
// };

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const status_message = ["success", "error"]
var appSession: AppSession;
var htmlString: string;
const ServiceGroupCategoryAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const [_id, setId] = React.useState<string>();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();

    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    // truyền id dự án
    const [data, setData] = React.useState<QLCCUtilityGroupDetailModel>({} as QLCCUtilityGroupDetailModel);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const [isDefaultPre, setIsDefaultPre] = React.useState<boolean>();

    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [totalApartmentMessage, setTotalApartmentMessage] = React.useState<JSX.Element>();
    const [actionUpdateService, setActionUpdateService] = React.useState<string>();

    const [apartmentBuildingOption, setApartmentBuildingOption] = React.useState<any>([]);
    const [serviceGroupOptions, setServiceGroupOptions] = React.useState<{ id: string, name: string }[]>([]);
    const [fullScreen, setFullscreen] = React.useState<boolean>(false);


    // const [windowDirectionOptions, setWindowDirectionOptions] =
    //     React.useState<any>([]);
    // const [elevatorLocationOptions, setElevatorLocationOptions] =
    //     React.useState<any>([]);
    // const [emergencyExitLocationOptions, setEmergencyExitLocationOptions] =
    //     React.useState<any>([]);


    React.useEffect(() => {

        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            qLCClient
                .qLCCUtilityGroupDetailActions({
                    ...new QLCCUtilityGroupDetailModel(),
                    id: props.itemSelected?.id,
                    action_type: "GET"
                } as QLCCUtilityGroupDetailModel)
                .then((res) => {
                    setData(res[0]);


                })
                .catch(() => alert("Không lấy được dữ liệu"));
            setId(props.itemSelected);

        } else {

            setData({
                ...new QLCCUtilityGroupDetailModel(),
            } as QLCCUtilityGroupDetailModel);
            setId(undefined)
        };
    }, [props.action]);


    React.useEffect(() => {

        setLoading(true);
        homeClient
            ?.roleRightWebGetAll({
                ...new RoleRightWebModel(),
                rolename: menu?.moduleid,
                username: SessionLogin.userName,
                menuid: menu?.menuid,
            } as RoleRightWebModel)
            .then((res) => {
                setRoles(res);
                setLoading(false);
            });
        // setLoading(true);
        // qLCClient
        //     .qlccApartmentBuildingGet({
        //         ...new QLCCApartmentBuildingModel(),
        //         ma_cty: SessionLogin.ma_cty,
        //         action_type: 'GET'
        //     } as QLCCApartmentBuildingModel)
        //     .then(res => {
        //         setApartmentBuildingOption(res);
        //     })
        // setLoading(true);

        // qLCClient
        //     .qLCCApartmentTypesActions({
        //         ...new QLCCApartmentTypesModel(),
        //         action_type: 'GET'
        //     } as QLCCApartmentTypesModel)
        //     .then(res => {
        //         setApartmentTypeOptions(res)
        //         setLoading(false);
        //     });

    }, []);



    const handleSubmit = (action?: string) => {
        setLoading(true)
        if (_id || props.action === ACTIONS.EDIT) {
            qLCClient.qLCCUtilityGroupDetailActions({
                ...new QLCCUtilityGroupDetailModel(),
                ...data,
                action_type: "UPDATE",
            } as QLCCUtilityGroupDetailModel)
                .then((response) => {
                    setLoading(false);
                    notification(status_message[response[0].status!], STATUS_MESSAGE[`${props.action}_${response[0].status}`]);
                    if (response[0].status === 0) {
                        props.onEditSuccess(response[0].id, data);
                        props.onClose();
                    }


                });



        }
        else {
            qLCClient.qLCCUtilityGroupDetailActions({
                ...new QLCCUtilityGroupDetailModel(),
                ...data,
                ma_cty: SessionLogin?.ma_cty,
                apartment_building_id: apartment_building_id,
                action_type: "INSERT",
            } as QLCCUtilityGroupDetailModel)
                .then((response) => {
                    setLoading(false);
                    notification(status_message[response[0].status!], STATUS_MESSAGE[`${props.action}_${response[0].status}`])

                    if (response[0].status === 0) {
                        props.onAddSuccess(response[0].id, data);
                        props.onClose();
                    }
                });
        }
    };

    const handleChangeInput = (e: any) => {
        const { name, value, checked, type } = e.target;

        if (type === 'checkbox') {
            setData({ ...data, [name]: checked } as QLCCUtilityGroupDetailModel);

        }
        else setData({ ...data, [name]: value } as QLCCUtilityGroupDetailModel);
    };







    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                handleSubmit();
                // handleOpen()
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
        }
    }

    //#region handleImage

    const handleRemoveImage = (e: any) => {
        const { name } = e;

        if (name) {
            setData({
                ...data,
                [name]: ''
            } as QLCCUtilityGroupDetailModel)
        }
    }

    const handleChangeImage = async (e: any) => {

        const { name, files } = e.target;
        const formData = new FormData();
        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };
        for (const file of files) {
            formData.append("MyFile", file);
        }
        setLoading(true)

        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    setLoading(false)
                    setData(pre => ({ ...pre, [name]: res.data[0].path } as QLCCUtilityGroupDetailModel))
                } else notification("warning", "Không lấy được hình ảnh");
            });

        e.target.value = '';

    }

    const handleAddNewImage = async (e: any) => {

        const { name, files } = e.target;
        const formData = new FormData();
        const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
                Accept: "application/json",
                token: SessionLogin.Token
            },
        };
        for (const file of files) {
            formData.append("MyFile", file);
        }
        setLoading(true)

        await axios
            .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
            .then((res) => {
                if (res.data) {
                    setLoading(false)
                    setData(pre => ({ ...pre, [name]: res.data[0].path } as QLCCUtilityGroupDetailModel))
                } else notification("warning", "Không lấy được hình ảnh");
            });

        e.target.value = '';
    }

    //#endregion

    return (
        <Dialog
            // id="sysMenu"
            open={props.open}
            onClose={props.onClose}
            // sx={{
            //     display: "flex",
            //     justifyContent: "flex-end",
            // }}
            TransitionComponent={Transition}
            maxWidth="lg"
            fullWidth
            fullScreen={fullScreen}
        >
            {/* <Dialog
                open={openCamera.open}
                onClose={handleCloseCamera}
                sx={{ height: "inherit" }}
            >
                
            </Dialog> */}

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === "UPDATE" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1.25 }}>
                        <IconButton
                            edge="end"

                            onClick={() => {
                                setFullscreen(pre => !pre)
                            }}
                            aria-label="full screen"
                        >
                            {fullScreen ? <FullscreenExitIcon sx={{ color: '#1f2937' }} /> : <FullscreenIcon sx={{ color: '#1f2937' }} />}
                        </IconButton>
                        <IconButton
                            edge="end"
                            onClick={() => {
                                props.onClose();
                            }}
                            aria-label="close"
                        >
                            <CloseIcon
                                sx={{ color: '#1f2937' }}
                            />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <div className="row">
                    <div className="col-xl-6">
                        {/* Tên tiện ích */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                required
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Tên nhóm danh mục dịch vụ
                            </InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                autoFocus
                                // placeholder="Tên tiện ích ..."
                                name="name"
                                onChange={handleChangeInput}
                                value={data?.name ?? null}
                                inputProps={{
                                    autoComplete: "new-password",
                                    readOnly: props.action === ACTIONS.VIEW
                                    // type: "search" // disable autocomplete and autofill
                                }}
                            // disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box>
                        {/* Nhóm tiện ích */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                required
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Nhóm danh mục dịch vụ
                            </InputLabel>
                            <Autocomplete
                                id="main-direction-select"
                                sx={{ width: "100%" }}
                                options={serviceGroupOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.group_id
                                        ? serviceGroupOptions.find(
                                            (item: any) => item.id === data.group_id
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "group_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });




                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password",
                                            // type: "search" // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                readOnly={props.action === ACTIONS.VIEW || props.action === ACTIONS.APPROVED}
                            />
                        </Box> */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel
                                required
                                sx={{ fontSize: 14, color: "#333", }}
                            >
                                Nhóm danh mục dịch vụ
                            </InputLabel>
                            <DHSAutocompleteFilterListSelect
                                table_list={"dm_nhom_dich_vu"}
                                value={data?.group_id}
                                onValueChanged={(e, newValue) => {
                                    handleChangeInput({
                                        target: {
                                            name: "group_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}

                                readOnly={props.action === ACTIONS.VIEW || props.action === ACTIONS.APPROVED}
                            />
                        </Box>





                    </div>
                    {/* chỗ này cắt div*/}
                    <div className="col-xl-6">




                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <div className="d-flex align-items-center">
                                <InputLabel sx={{ fontSize: 14, color: "#000" }}>
                                    Tải hình
                                </InputLabel>

                            </div>
                            {data?.images ? (<div style={{
                                position: "relative", width: "213px",
                                maxWidth: "213px",
                                height: "213px",
                                maxHeight: "213px",
                            }}>
                                <img
                                    src={`${BASE_URL_API}${data?.images}`}
                                    alt="Hình"
                                    className="image-shadow"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: 'cover'
                                    }}
                                />
                                <div
                                    className="position-absolute w-100 d-flex justify-content-end"
                                    style={{ top: 1, right: 1, cursor: "pointer" }}
                                >
                                    <IconButton
                                        color="error"
                                        aria-label="upload picture"
                                        component="label"
                                        onClick={() => handleRemoveImage({
                                            name: "images"
                                        })}
                                        disabled={props.action === ACTIONS.VIEW}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </div>
                                <div
                                    className="position-absolute w-100 d-flex justify-content-end"
                                    style={{ top: 27, right: 1, cursor: "pointer" }}
                                >
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="label"
                                        disabled={props.action === ACTIONS.VIEW}
                                    >
                                        <input
                                            id={`uploadImageUpdate`}
                                            className="flex-grow-1 form-control"
                                            type="file"
                                            accept="image/*"
                                            name="images"
                                            onChange={
                                                (e) => handleChangeImage(e)
                                            }
                                            // value={data.anh_xe}
                                            style={{ display: "none" }}
                                        />
                                        <ChangeCircleIcon />
                                    </IconButton>
                                </div>
                            </div>) : (
                                <div className="m-1">
                                    <label htmlFor={`uploadImageAdd`} style={{ width: "100%" }} className="label-file-media">
                                        <div
                                            className="c-img-card"
                                            style={{
                                                width: "213px",
                                                maxWidth: "213px",
                                                height: "213px",
                                                maxHeight: "213px",
                                                borderRadius: '5px'
                                            }}
                                        >
                                            <i className="bi bi-images" />
                                            &nbsp;Thêm ảnh mới...
                                        </div>
                                    </label>
                                    <input
                                        id="uploadImageAdd"
                                        className="flex-grow-1 form-control"
                                        type="file"
                                        accept="image/*"
                                        name="images"
                                        onChange={handleAddNewImage}
                                        // value={data.anh_xe}
                                        style={{ display: "none" }}
                                        disabled={props.action === ACTIONS.VIEW}
                                    />
                                </div>
                            )}
                        </Box>


                    </div>



                </div>
            </DialogContent>
            <DialogActions>

                <DHSDialogAction

                    action={props.action}
                    menu={menu}
                    onClickAction={handleClickAction}
                />


            </DialogActions>

            {/* <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Xác nhận thay đổi phí vận hành
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {totalApartmentMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation sx={{ textTransform: "capitalize" }} autoFocus variant="contained" color="primary" onClick={() => handleSubmit()}>
                        Chập nhận
                    </Button>
                    {
                        actionUpdateService === "UPDATE" && <Button disableElevation sx={{ textTransform: "capitalize" }} color="error" onClick={() => {
                            handleSubmit("UPDATE-BUT-NOT-DELETE");
                        }}>Không</Button>
                    }
                    <Button disableElevation sx={{ textTransform: "capitalize" }} onClick={handleClose}>Hủy</Button>
                </DialogActions>
            </Dialog> */}
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default ServiceGroupCategoryAction;
