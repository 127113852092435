import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DHSToolbar from "../../../../components/DHS/DHS-Toolbar/DHSToolbar";
import { IReducer } from "../../../../components/layouts";
import DHSGridView from "../../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { getRoles } from "../../../../redux/actions/RoleActions";
import { AppSession } from "../../../shared/app-session/app-session";
import {
  HomeClient,
  ItemGroupClient,
  LISTItemKindClient,
  LstItemKind,
  QLCCClient,
  RoleRightWebModel,
  SysActionType,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { useHistory, useLocation } from "react-router-dom";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import InvestorAction from "../../actions/investor-action/InvestorAction";
import notification from "../../../../common/notification/notification";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import DataGrid from "devextreme-react/data-grid";
import dxDataGrid, {
  RowInsertingInfo,
  RowRemovingInfo,
  RowUpdatedInfo,
  RowUpdatingInfo,
  SelectionChangedInfo,
} from "devextreme/ui/data_grid";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { EventInfo } from "devextreme/events";
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import {
  STATUS_MESSAGE,
  TYPE_MESSAGE,
} from "../../../../common/Interfaces/StatusMessage";
import DHSBreadcrumb from "../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import { getIndexRowSelected, onAddRow, onDeleteRow, onEditRow, onViewRow } from "../../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import { TransDateTime } from "../../../../components/utils/date.util";

var appSession: AppSession;

interface IAction {
  open: boolean;
  type: string;
  payload: any;
}

const defaultAction: IAction = {
  open: false,
  type: "",
  payload: null,
};

const roleMenu: string = "CC";
const status_message = ["success", "error"];

const ItemKind: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [idItemSelected, setIdItemSelected] = React.useState<string>("");
  // const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const [reload, setReload] = React.useState<boolean>(false);
  const [dataTable, setDataTable] = React.useState<LstItemKind[]>([]);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
  const itemKindClient = new LISTItemKindClient(appSession, BASE_URL_API);

  const [actCx, setActCx] = useState<IAction>(defaultAction);
  //

  const dataGridRef = React.useRef<DataGrid>(null);
  const [allowEdit, setAllowEdit] = useState<boolean>(true);
  const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

//   useEffect(() => {
//     setLoading(true);

//     homeClient
//       .roleRightWebGetAll({
//         ...new RoleRightWebModel(),
//         menuid: menu?.menuid,
//         rolename: roleMenu,
//         username: "DHSOFT",
//       } as RoleRightWebModel)
//       .then((res) => {
//         dispatch(getRoles(res));
//         setRoles(res);
//         setLoading(false);
//       });
//   }, [menu?.component]);

  useEffect(() => {
    setLoading(true);
    itemKindClient
    .getlstItemKind({
        ...new LstItemKind(),
        ma_cty: SessionLogin.ma_cty
      } as LstItemKind)
      .then((res) => {
        setLoading(false);
        setDataTable(res);
      });
  }, [reload]);

  const handleSelectionChanged = (
    e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
  ) => {
  };

  const handlePageChange = () => {
    setSelectedRowIndex(-1);
    setSelectedItemKeys(undefined);
  };

  const handleRowInserting = async (
    event: EventInfo<dxDataGrid<any, any>> & RowInsertingInfo<any>
  ) => {
    try {
      event.cancel = true;
      setLoading(true);
    //   const idGroup = await itemGroupClient.
      const response = await itemKindClient.lstItemKindIns({
        ...new LstItemKind(),
        ...event.data,
        ksd: event.data?.ksd ? true : false,
        cuser: SessionLogin.userName,
        luser: SessionLogin.userName,
        cdate: TransDateTime(new Date()),
        ldate: TransDateTime(new Date()),
        ma_cty: SessionLogin.ma_cty
        
      } as LstItemKind);
      if (response.status === 0) {
        setDataTable([{ iDkind: response.ref_id, ...event.data }, ...dataTable]);
        dataGridRef.current?.instance.cancelEditData();
        notification(
          TYPE_MESSAGE.SUCCESS,
          STATUS_MESSAGE[`${ACTIONS.ADD}_${response!.status}`]
        );
      } else {
        notification(
          TYPE_MESSAGE.ERRORR,
          STATUS_MESSAGE[`${ACTIONS.ADD}_${response!.status}`]
        );
      }
    } catch (error) {
      notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
    } finally {
      setLoading(false);
    }
  };

  const handleRowUpdating = async (
    event: EventInfo<dxDataGrid<any, any>> & RowUpdatingInfo<any, any>
  ) => {
    try {
      event.cancel = true;
      setLoading(true);
      const response = await itemKindClient.lstItemKindUpd({
        ...new LstItemKind(),
        ...event.oldData,
        ...event.newData,
        ksd: event.newData?.ksd ? true : false,
        luser: SessionLogin.userName,
        ldate: TransDateTime(new Date()),
        ma_cty: SessionLogin.ma_cty,
      } as LstItemKind);
      if (response.status === 0) {
        const dataLastUpdated = dataTable.map((item) => {
          if (item.iDkind === event.key) {
            return {
              ...new LstItemKind(),
              ...event.oldData,
              ...event.newData,
            };
          } else {
            return item;
          }
        });

        setDataTable([...dataLastUpdated]);
        dataGridRef.current?.instance.cancelEditData();
        notification(
          TYPE_MESSAGE.SUCCESS,
          STATUS_MESSAGE[`${ACTIONS.EDIT}_${response!.status}`]
        );
      } else {
        notification(
          TYPE_MESSAGE.ERRORR,
          STATUS_MESSAGE[`${ACTIONS.EDIT}_${response!.status}`]
        );
      }
    } catch (error) {
      notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
    } finally {
      setLoading(false);
    }
  };

  const handleRowRemoving = async (
    event: EventInfo<dxDataGrid<any, any>> & RowRemovingInfo<any, any>
  ) => {
    try {
      event.cancel = true;
      setLoading(true);
      const response = await itemKindClient.lstItemKindDel({
        ...new LstItemKind(),
        iDkind: event.data.iDkind,
        ma_cty: SessionLogin.ma_cty

      } as LstItemKind);

      if (response.status === 0) {
        setDataTable([...dataTable.filter((item) => item.iDkind !== event.key)]);
        notification(
          TYPE_MESSAGE.SUCCESS,
          STATUS_MESSAGE[`${ACTIONS.DELETE}_${response!.status}`]
        );
      } else {
        notification(
          TYPE_MESSAGE.ERRORR,
          STATUS_MESSAGE[`${ACTIONS.DELETE}_${response!.status}`]
        );
      }
    } catch (error) {
      notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
    } finally {
      setLoading(false);
    }
  };

  const handleClickAction = async (action: SysActionType) => {
    switch (action?.action_code?.toLocaleUpperCase()) {
      case ACTIONS.ADD:
        await onAddRow(dataGridRef, setAllowEdit);
        break;
      case ACTIONS.DELETE:
        await onDeleteRow(dataGridRef)
        break;
      case ACTIONS.EDIT:
        await onEditRow(dataGridRef, setAllowEdit);
        break;
      case ACTIONS.VIEW:
        await onViewRow(dataGridRef, setAllowEdit);
        break;

      default:
        break;
    }
  };
  return (
    <div className="container-fluid">
      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          marginTop: 15,
          marginBottom: 15,
          fontSize: 22,
          fontWeight: 550,
        }}
      >
        <DHSBreadcrumb location={location} />
      </div>

      <DHSToolbarRole
        id={' '}
        menu={menu}
        onClickAction={handleClickAction}
      />
      <DHSDataGridEdit
        data={dataTable}
        table_name={menu?.component}
        keyExpr="iDkind"
        mode="popup"
        dataGridRef={dataGridRef}
        allowEdit={allowEdit}
        pageSize={menu?.page_size}
        isPagination={menu?.is_pagination}
        isMultiple={menu?.is_multiple}
        groupingAutoExpandAll={false}
        handlePageChange={handlePageChange}
        handleSelectionChanged={handleSelectionChanged}
        handleRowInserting={handleRowInserting}
        handleRowUpdating={handleRowUpdating}
        handleRowRemoving={handleRowRemoving}
      />
      <BlockUI blocking={loading} title={""}></BlockUI>
    </div>
  );
};

export default ItemKind;
