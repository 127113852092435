// Component
import { Box, IconButton } from '@mui/material';
// API
import { QLCCTaskDetailModel } from '../../../../../shared/service-proxies/api-shared';
// ICON
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TaskChildForumChat from '../task-forum/index2';

interface IProps {
    setBack: () => void;
    task_id: string | undefined,
    action: string;
    open: boolean | undefined
    data: QLCCTaskDetailModel | undefined;
}

const TaskChildForumChatMaster = (props: IProps) => {

    return (
        <Box className="task-child-forum-chat-master-block">
            <Box className="task-child-forum-chat-master-control">
                <IconButton
                    className="task-child-forum-chat-master-task_icon"
                    onClick={() => props.setBack()}>
                    <ArrowBackIcon />
                </IconButton>
                <p className="task-child-forum-chat-master-name_task">
                    {props.data && props.data.title ? props.data.title : props.data && props.data.name ? props.data.name : 'Công việc không xác định'}
                </p>
            </Box>
            <Box className="task-child-forum-chat-master-content">
                <TaskChildForumChat
                    task_id={props.task_id}
                    action={props.action}
                    open={props.open}
                />
            </Box>
        </Box>
    )
}

export default TaskChildForumChatMaster