import React, { Component } from "react";
import { Row, Col, Divider, Tabs, Collapse, Spin, Affix } from "antd";
import {
  ClusterOutlined,
  ToolOutlined,
  LoadingOutlined,
  SettingOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import * as actions from "../../../../redux/actions/Manufacturing/MachineStatus/index";
import * as actionsMachineRpt from "../../../../redux/actions/Manufacturing/MachineRpt/index";
import { FormatMoney } from "../../../controller/Format";
import { connect, useDispatch } from "react-redux";
import "./index.css";
import moment from "moment";
import _ from "lodash";
import * as types from "../../../../redux/constants/ActionTypes";
import BASE_URL_API from "../../../../app/shared/service-proxies/config";
import { LstProductionsMachine, MachineRptClient, PDMachineStatusClient, ModelHandle_PDMachineStatus, ModelHandlePD } from "../../../../app/shared/service-proxies/api-shared";
import { AppSession } from "../../../../app/shared/app-session/app-session";
import { getTimeUpdate } from "../../../../redux/actions/Manufacturing/MachineStatus/index";
import { Route, useHistory } from "react-router-dom";
import { containRptMachine } from "../../../../redux/actions/Manufacturing/MachineRpt/index";
import MachineTimeLine from "../MachineReports/MachineTimeLine/machineTimeLine";
const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
const { TabPane } = Tabs;

let intervalTime: NodeJS.Timeout;
const timeNow = moment().add("7", "hours").toISOString();
const renderTabBar = (props: any, DefaultTabBar: any) => (
  <Affix>
    <DefaultTabBar {...props} className="custom-tab-bar-box-machine" />
  </Affix>
);
const { Panel } = Collapse;
interface IModel {
  listMachine?: any[],
  loading?: boolean,
}
let appSession: AppSession;
const MachineStatus = (props: any) => {
  const [model, setModel] = React.useState<IModel>();
  const [ma_cty, setma_cty] = React.useState<string>(SessionLogin.ma_cty);
  const [listMachine, setlistMachine] = React.useState<any>();
  const [checkNewUpdate, setcheckNewUpdate] = React.useState<any>();
  const [dateUpdate, setdateUpdate] = React.useState<any>();
  const [listMachinesStateColor, setlistMachinesStateColor] = React.useState<any>();
  const [groupMachine, setgroupMachine] = React.useState<any>();
  const machineRptClient = new MachineRptClient(appSession, BASE_URL_API);
  const pDMachineStatusClient = new PDMachineStatusClient(appSession, BASE_URL_API);
  const history = useHistory();
  const dispatch = useDispatch();
  React.useEffect(() => {
    let pr0: ModelHandlePD = new ModelHandlePD();
    pr0.mA_CTY = SessionLogin.ma_cty;
    machineRptClient.getLstMachinesGroup(pr0).then(res => {
      setgroupMachine(res);
    })
    let pr: LstProductionsMachine = new LstProductionsMachine();
    pr.mA_CTY = SessionLogin.ma_cty;
    pDMachineStatusClient.getMachineState(pr).then(res => {
      setlistMachine(res);
      setModel({ ...model, listMachine: res, loading: false });
    })
    let pr2: ModelHandle_PDMachineStatus = new ModelHandle_PDMachineStatus();
    pr2.mA_CTY = SessionLogin.ma_cty;
    pDMachineStatusClient.getLstMachinesState(pr2).then(res => {
      setlistMachinesStateColor(res)
    })
  }, []);

  const onRefesh = () => {
    setModel({ ...model, loading: true });
    setTimeout(() => {
      setModel({ ...model, loading: false });
      let pr2: ModelHandle_PDMachineStatus = new ModelHandle_PDMachineStatus();
      pr2.mA_CTY = SessionLogin.ma_cty;
      pr2.lasT_UPDATE_DATETIME = new Date();
      pDMachineStatusClient.getMachineStateNewUpdate(pr2).then(res => {
        setdateUpdate(res);
      });
    }, 500);
  };
  const onClickCol = (item: any) => {
    dispatch(containRptMachine({
      time_to: moment().set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
      time_from: moment()
        .subtract("1", "days")
        .set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
      machineID: item.machine_id,
      machine_name: item.machine_name,
    }));
    history.push("/admin/MachineStatus/machinereporttimeline");
  };
  const renderByGroup = (listGroup: any, state: any) => {
    var result = [];
    if (!_.isEmpty(listGroup) && listMachine) {
      result = listGroup.map((x: any, index: any) => {
        return (
          <Panel
            header={
              <Divider orientation="left" style={{ fontWeight: "bold" }}>
                {"Nhóm Máy: " + x.machine_group_name}
              </Divider>
            }
            key={index}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {listMachineStatus(
                listMachine,
                state,
                x.machine_group_code
              )}
            </Row>
          </Panel>
        );
      });
    }
    return result;
  };
  const listMachineStatus = (listMachine: any, state: any, idGroup: any) => {
    var result = null;
    if (listMachine.length > 0) {
      result = listMachine.map((item: any, index: any) => {

        if (item.machinE_GROUP_CODE === idGroup) {
          const data = (
            <Col
              key={index}
              className="gutter-row"
              span={6}
              xs={12}
              xl={6}
              onClick={() => onClickCol(item)}
            >
              {renderCol(item)}
            </Col>
          );
          if (state === 0) {
            return data;
          } else if (item.state === state) {
            return data;
          }
        }
      });
    }
    return result;
  }
  const renderCol = (item: any) => {
    const color: any = { color: colorStateDescription(item.state) };
    const background: any = { background: colorState(item.state) }
    return (
      <div>
        <div
          className="boxMachine"
          style={background}
        >
          <h2 style={{ color }}>{item.machinE_CODE}</h2>
          <h3 style={{ color }}>{item.machinE_NAME}</h3>
          <div className="twoColumnTotal">
            <div className="twoColumnLeft">
              <h3 style={{ color }}>{item.state_description}</h3>
            </div>
            <div className="twoColumnRight">
              <h4 style={{ color }}>
                {moment(item.last_update_datetime).format("DD/MM/YYYY HH:mm:ss")}
              </h4>
            </div>
          </div>
          <div className="twoColumnTotal">
            <div className="twoColumnLeft">
              <div>
                <h5>SẢN PHẨM</h5>
                <h3 style={{ color }}>{item.teN_VT}</h3>
              </div>
              <div>
                <h5>SỐ LỆNH SX</h5>
                <h3 style={{ color }}>{item.sO_LSX}</h3>
              </div>
            </div>
            <div className="twoColumnRight">
              <div>
                <h5>SL KẾ HOẠCH</h5>
                <h3 style={{ color }}>{FormatMoney(item.sO_LUONG_KH)}</h3>
              </div>
              <div>
                <h5>SL HOÀN THÀNH</h5>
                <h3 style={{ color }}>{FormatMoney(item.sL_THUC_TE)}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const colorState = (value: any) => {

    var color = "";
    listMachinesStateColor.map((item: any, index: any) => {
      if (item.state === value) {
        color = item.backcolor.toString().trim();
      }
    });
    return color !== "" ? color : {};
  };
  const colorStateDescription = (value: any) => {
    var color = "";
    listMachinesStateColor.map((item: any, index: any) => {
      if (item.state === value) {
        color = item.forecolor.toString().trim();
      }
    });
    return color !== "" ? color : {};
  };
  return (
    <>
      <Route path={'/admin/MachineStatus/machinereporttimeline'}>
        <MachineTimeLine />
      </Route>
      {
        model && !model?.loading ? (
          <div style={{ height: "100%" }} className="machineStatus">
            <Spin spinning={model?.loading}>
              {
                groupMachine.length > 0 ? (
                  <Tabs
                    defaultActiveKey="1"
                    tabBarExtraContent={
                      <SyncOutlined
                        style={{ fontSize: 22 }}
                        onClick={onRefesh}
                        spin={model?.loading}
                      />
                    }
                    renderTabBar={renderTabBar}
                    animated={true}
                  >

                    <TabPane tab="All" key="1">
                      <Collapse
                        expandIconPosition="right"
                        ghost
                        defaultActiveKey={["0", "1", "2"]}
                      >
                        {renderByGroup(groupMachine, 0)}
                      </Collapse>
                    </TabPane>
                    <TabPane tab={<span>Setup</span>} key="2">
                      <Collapse
                        expandIconPosition="right"
                        ghost
                        defaultActiveKey={["0", "1", "2"]}
                      >
                        {renderByGroup(groupMachine, 1)}
                      </Collapse>
                    </TabPane>
                    <TabPane tab="Run" key="3">
                      <Collapse
                        expandIconPosition="right"
                        ghost
                        defaultActiveKey={["0", "1", "2"]}
                      >
                        {renderByGroup(groupMachine, 3)}
                      </Collapse>
                    </TabPane>
                    <TabPane tab={<span>Stop</span>} key="4">
                      <Collapse
                        expandIconPosition="right"
                        ghost
                        defaultActiveKey={["0", "1", "2"]}
                      >
                        {renderByGroup(groupMachine, 4)}
                      </Collapse>
                    </TabPane>
                    <TabPane tab={<span>Maintenance</span>} key="5">
                      <Collapse
                        expandIconPosition="right"
                        ghost
                        defaultActiveKey={["0", "1", "2"]}
                      >
                        {renderByGroup(groupMachine, 2)}
                      </Collapse>
                    </TabPane>
                  </Tabs>
                ) : (<></>)
              }
            </Spin>
          </div>
        ) : ('')
      }
    </>

  );

}
const mapStateToProps = (state: any) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listMachine: state.MachineStatusReducer.listMachine,
    checkNewUpdate: state.MachineStatusReducer.checkNewUpdate,
    dateUpdate: state.MachineStatusReducer.dateUpdate,
    listMachinesStateColor: state.MachineStatusReducer.listMachinesStateColor,
    groupMachine: state.MachineRptReducer.groupMachine,
  };
};
const mapDispatchToProps = (dispatch: any, props: any) => {
  return {
    getMachineState: (ma_cty: any) => {
      dispatch(actions.getMachineState(ma_cty));
    },
    getMachineStateNewUpdate: (query: any) => {
      dispatch(actions.getMachineStateNewUpdate(query));
    },
    changeStateUpdate: () => {
      dispatch(actions.changeStateUpdate());
    },
    getTimeUpdate: (date: any) => {
      dispatch(actions.getTimeUpdate(date));
    },
    containRptMachine: (data: any) => {
      dispatch(actionsMachineRpt.containRptMachine(data));
    },
    getLstMachinesStateColor: (query: any) => {
      dispatch(actions.getLstMachinesStateColor(query));
    },
    GetLstMachinesGroup: (ma_cty: any) => {
      dispatch(actionsMachineRpt.GetLstMachinesGroup(ma_cty));
    },
  };
};

export default MachineStatus;

