import React, { useEffect, useState } from "react";
import "./style.css";

import {
  BaseformClient,
  IParasysActionType,
  IRoleRightWebModel,
  ParaPermissionAction,
  ParasysActionType,
  ParasysSubAction,
  RoleRightWebModel,
  SysActionType,
  SysMenu,
  UserInfo,
} from "../../../app/shared/service-proxies/api-shared";
import { Link, useParams } from "react-router-dom";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Paper } from "@mui/material";
import BASE_URL_API from "../../../app/shared/service-proxies/config";
import { AppSession } from "../../../app/shared/app-session/app-session";
import notification from "../../../common/notification/notification";
import { ACTIONS, ERROR_PERMISSION_MESSAGE } from "../../../common/enums/DHSToolbarRoleAction";
import { IndexedDBService } from "../../../common/indexedDBApi";
import { DB_NAME, DB_VERSION, OBJECTSTORENAME } from "../../../common/indexedDBApi/constant";

interface IListMenu {
  menuName: string;
  onClick?: () => void;
}
interface IProps {
  action_code: string;
  menu?: SysMenu;
  id: string;
  ma_cty?: string,
  apartment_building_id?: string,
  onClickAction: (action: SysActionType, nowTime: Date, id: string, ma_cty?: string, apartment_building_id?: string) => void;
  isDLink?: boolean;
  current_form?: any;
}


const status_message: string[] = ['success', 'error']
const default_hiden = [ACTIONS.VIEW, ACTIONS.EDIT, ACTIONS.DELETE, ACTIONS.PRINT, ACTIONS.COPY, ACTIONS.ATTACHFILE]

var appSession: AppSession;

export const DHSToolbarCheckRole = async (props: IProps) => {

  const baseFormClient = new BaseformClient(appSession, BASE_URL_API);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const indexedDBService = new IndexedDBService(DB_NAME, DB_VERSION, OBJECTSTORENAME);

  let userInfo: UserInfo = {} as UserInfo;
  let actionTypes: SysActionType[] = [] as SysActionType[];

  if (props.isDLink) {
    await baseFormClient.getActionType({
      ...new ParasysActionType(),
      action_type: props?.menu?.action_type3,
      language: 'vi-VN'
    } as ParasysActionType)
      .then(res => {
        actionTypes = res;
      });
  }
  else
    await baseFormClient.getActionType({
      ...new ParasysActionType(),
      action_type: props?.menu?.action_type,
      language: 'vi-VN'
    } as ParasysActionType)
      .then(res => {
        actionTypes = res;
      });

  const db: any = await indexedDBService.openDB().then((db) => {
    return db;
  }).catch((error) => {
    console.log(error);
  });

  userInfo = await indexedDBService.getData(db, SessionLogin.userName).then((data) => {
    return data;
  }).catch((error) => {
    console.log(error);
  });

  const handleClick = (lst: SysActionType) => {
    // debugger
    if ((userInfo?.isadmin || userInfo?.issuperadmin)) {
      props.onClickAction(lst, new Date(), props.id, props?.ma_cty, props?.apartment_building_id);
    }
    else {
      baseFormClient.getUserPermissionAction({

        ...new ParaPermissionAction(),
        menuid: props?.menu?.menuid,
        username: SessionLogin?.userName,
        action_code: lst.action_code,

      } as ParaPermissionAction)
        .then(res => {
          if (res?.grant_access === true) {
            props.onClickAction(lst, new Date(), props.id, props?.ma_cty, props?.apartment_building_id);
          }
          else {
            notification(status_message[1], ERROR_PERMISSION_MESSAGE[lst.action_code!])
          }
        });
    }
  };
  //#region handle_click_action

  if (actionTypes &&
    userInfo) {
    const action: SysActionType | undefined = actionTypes.find(item => item.action_code === props.action_code);
    if (action) {
      handleClick(action);
    } else {
      notification(status_message[1], "Không tìm thấy chức năng này");
    }
  }



};

