import React from 'react';
import Scheduler, { Editing, Resource, Scrolling, } from 'devextreme-react/scheduler';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import SchedulerViewSelect from './components/scheduler-view-select/SchedulerViewSelect';
import dxScheduler, { AppointmentClickEvent, AppointmentDblClickEvent, AppointmentTooltipTemplateData, ViewType } from 'devextreme/ui/scheduler';
import { capitalizeFirstLetter, lowerFirstLetter } from '../../utils/string.util';
import { useForwardRef } from '../../utils/hooks/useForwardRef';
import { formatStartEndDateFromView } from './function-utils/FunctionUtils';
import _ from 'lodash';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import DHSPopup from '../dialogs-and-notifications/DHSPopup/DHSPopup';
import { IAction } from '../../../common/Interfaces/Interfaces';
import { OptionChangedEventInfo } from 'devextreme/core/dom_component';

const defaultAction: IAction = {
    open: false,
    type: "",
    index: 0,
    payload: null,
};


interface IStatusResource {
    enable: boolean,
    maCt: string,
    dataSource: any[],
    fieldExpr: string,
    valueExpr?: string,
    displayExpr: string,
    colorExpr?: string,
    allowMultiple?: boolean,
    label?: string
}
interface IProps {
    dataSource: any[],
    title?: string,
    textExpr?: string,
    startDateExpr?: any,
    endDateExpr?: any,
    currentDate?: Date,
    startDayHour?: number,
    endDayHour?: number,
    groups?: string[],
    groupingResource?: {
        fieldExpr: string,
        allowMultiple?: boolean,
        dataSource: any[]
        label?: string
    },
    height?: string | number | (() => string | number),
    statusResource?: IStatusResource,
    onAppointmentDblClick?: (e: AppointmentDblClickEvent) => void,
    onAppointmentClick?: (e: AppointmentClickEvent) => void,
    appointmentRender?: (...params: any) => React.ReactNode,
    appointmentComponent?: React.ComponentType<any>,
    onCurrentDateChange?: (e: Date) => void;
    onCurrentViewChange?: (e: any) => void;
    onOptionChanged?: (e: OptionChangedEventInfo<dxScheduler>) => void;
}

const currentDate = new Date(2021, 5, 2, 11, 30);
const groups = ['employeeID'];
const defaultCurrentView = "month"
const views: Record<string, string[]> = {
    // "bv": ["day", "week", "workWeek", "month",],
    "bv": ["day", "week", "month",],
    "timeline": ["timelineDay", "timelineWeek", "timelineWorkWeek", "timelineMonth",],
    "agenda": ["agenda"]
};
// let appSession: AppSession;
const DHSScheduler = React.forwardRef<Scheduler, IProps>((props, ref) => {

    const schedulerRef = useForwardRef<Scheduler>(ref);
    const [schedulerView, setSchedulerView] = React.useState<string>();
    const [currentView, setCurrentView] = React.useState<ViewType | undefined>(defaultCurrentView);
    // 
    const [colorStatus, setColorStatus] = React.useState<Record<string, string>>({});
    // 
    const [actZoom, setActZoom] = React.useState<IAction<any>>(defaultAction);

    // Chuyển mảng status về dạng {"1": "#333"} 
    // trong đó: 
    // "1" là value của trang_thai (fieldExpr)
    // "#333" là value của color_code (colorExpr)
    React.useEffect(() => {
        if (props?.statusResource?.enable && props.statusResource.dataSource.length) {
            const { dataSource, colorExpr, fieldExpr } = props.statusResource;
            const result = _.map(dataSource, (obj) => ({ [obj[fieldExpr]]: obj[colorExpr ?? "color"] }));

            const mergedResult = _.merge({}, ...result);
            setColorStatus(mergedResult);
        }
    }, [props.statusResource, props.statusResource?.enable])


    const handleCurrentViewChange = React.useCallback((value: any) => {
        var newCurrentView: string | undefined = undefined;

        switch (schedulerView) {
            case "bv":
                newCurrentView = value;
                break;
            case "timeline":
                newCurrentView = value.replace(/timeline?(.*)/gm, value);
                break;

            case "agenda":
                newCurrentView = "agenda";
                break;
            default: break;
        }
        setCurrentView(newCurrentView as ViewType)
        // if (props.onCurrentViewChange) {
        //     props.onCurrentViewChange(newCurrentView as any);
        // }
    }, [schedulerView])

    const handleSchedulerViewChange = React.useCallback((e: any) => {
        console.log(schedulerRef.current?.instance.option())
        setSchedulerView(e.value);
        var newCurrentView: string | undefined = undefined;
        const oldCurrentView = (currentView as string);
        switch (e.value) {

            case "bv":
                newCurrentView = oldCurrentView === "agenda" ? "week" : lowerFirstLetter(oldCurrentView.replace("timeline", ""));
                break;
            case "timeline":
                newCurrentView = oldCurrentView === "agenda" ? "timelineWeek" : `timeline${capitalizeFirstLetter(oldCurrentView)}`;
                break;

            case "agenda":
                newCurrentView = "agenda" as ViewType;
                break;
            default: break;
        }
        setCurrentView(newCurrentView as ViewType)
    }, [currentView, schedulerRef])

    const DropDownAppointmentComponent = React.useCallback((e: any) => {
        const { data, currentView } = e;
        const start_date = data[props.startDateExpr ?? "start_date"] as Date;
        const end_date = data[props.endDateExpr ?? "end_date"] as Date;
        data.isCompact = true;
        return <div className="dx-tooltip-appointment-item">
            <div className="dx-tooltip-appointment-item-marker">
                <div className="dx-tooltip-appointment-item-marker-body" style={{ backgroundColor: colorStatus[data.trang_thai] }}>
                </div></div><div className="dx-tooltip-appointment-item-content">
                <div className="dx-tooltip-appointment-item-content-subject">{data[props?.textExpr ?? "text"]}</div>
                <div className="dx-tooltip-appointment-item-content-date">{formatStartEndDateFromView(currentView, start_date, end_date)}</div>
            </div>
        </div>
    }, [colorStatus, props.endDateExpr, props.startDateExpr, props?.textExpr])

    // 

    const schedulerRender = React.useCallback((height?: string) => {
        return <>
            <Grid item container xs={12}>
                {/* Chú thích */}
                <Grid item xs={12} sm={7} md={9} lg={10}>
                    <Box margin={"0.5rem 0"} padding={"0 0.5rem"}>
                        <Grid container spacing={{ xs: 1, sm: 2 }}>
                            {
                                props?.statusResource?.enable && props?.statusResource?.dataSource.map(status => (
                                    <Grid item xs={"auto"} sm={6} md={4} lg={2} xl={"auto"}>
                                        <Box display={"flex"} alignItems={"center"} gap={1.5}>
                                            <Box width={15} height={15} bgcolor={status[props?.statusResource?.colorExpr ?? ""] ?? "#333"}></Box>
                                            <Typography variant='body2' className='text-muted'>{status[props?.statusResource?.displayExpr ?? ""]}</Typography>
                                        </Box>
                                    </Grid>
                                ))
                            }

                        </Grid>

                    </Box>
                </Grid>
                <Grid item xs={12} sm={5} md={3} lg={2}>

                    <Box marginLeft={"auto"}>
                        <SchedulerViewSelect
                            hiddenLabel
                            placeholder="Loại xem"
                            defaultValue={(data) => setSchedulerView(data[0].code)}
                            onValueChanged={handleSchedulerViewChange}
                            value={schedulerView} />
                    </Box>
                </Grid>

            </Grid>
            <Grid item xs={12}>
                <Scheduler
                    width={'100%'}
                    ref={schedulerRef}
                    timeZone="Asia/Bangkok"
                    dataSource={props.dataSource}
                    // dataCellComponent={DataCellComponent}
                    firstDayOfWeek={1}
                    textExpr={props?.textExpr}
                    startDateExpr={props?.startDateExpr}
                    endDateExpr={props?.endDateExpr}
                    groups={props.groups}
                    views={views[schedulerView ?? "bv"] as any}
                    currentView={currentView}
                    onCurrentViewChange={handleCurrentViewChange}
                    dropDownAppointmentComponent={(e: any) => <DropDownAppointmentComponent currentView={currentView} {...e} />}
                    // appointmentTooltipTemplate={AppointmentTooltipComponent}
                    appointmentRender={props?.appointmentRender}
                    appointmentComponent={props?.appointmentComponent}
                    maxAppointmentsPerCell={"auto"}
                    // currentDate={props.currentDate}
                    height={height ?? props?.height}
                    showAllDayPanel={true}
                    // firstDayOfWeek={1}
                    crossScrollingEnabled={true}
                    onAppointmentDblClick={props?.onAppointmentDblClick}
                    onAppointmentClick={props?.onAppointmentClick}
                    onAppointmentFormOpening={(e) => {

                        e.popup.option("shading", false)
                        e.popup.option("visible", false)
                    }}
                    startDayHour={props?.startDayHour}
                    endDayHour={props?.endDayHour}
                    // onCurrentDateChange={props?.onCurrentDateChange}
                    onOptionChanged={props?.onOptionChanged}
                >
                    <Scrolling mode={"virtual"} />
                    <Editing allowAdding={false} allowDeleting={false} allowDragging={false} allowUpdating={false} />

                    {
                        props?.groups && <Resource
                            fieldExpr={props?.groupingResource?.fieldExpr}
                            allowMultiple={props?.groupingResource?.allowMultiple ?? false}
                            dataSource={props?.groupingResource?.dataSource}

                            label={props?.groupingResource?.label}
                        />
                    }
                    {
                        props?.statusResource?.enable && <Resource
                            fieldExpr={props?.statusResource?.fieldExpr}
                            allowMultiple={props?.statusResource?.allowMultiple ?? false}
                            dataSource={props?.statusResource?.dataSource}
                            label={props?.statusResource?.label}
                            valueExpr={props?.statusResource?.valueExpr}
                            displayExpr={props?.statusResource?.displayExpr}
                            colorExpr={props?.statusResource?.colorExpr}
                        />
                    }

                </Scheduler>
            </Grid>
        </>
    }, [DropDownAppointmentComponent, currentView, handleCurrentViewChange, handleSchedulerViewChange, props.currentDate, props.dataSource, props?.endDateExpr, props?.endDayHour, props?.groupingResource?.allowMultiple, props?.groupingResource?.dataSource, props?.groupingResource?.fieldExpr, props?.groupingResource?.label, props.groups, props?.height, props?.onAppointmentClick, props?.onAppointmentDblClick, props?.startDateExpr, props?.startDayHour, props.statusResource?.allowMultiple, props.statusResource?.colorExpr, props?.statusResource?.dataSource, props.statusResource?.displayExpr, props.statusResource?.enable, props.statusResource?.fieldExpr, props.statusResource?.label, props.statusResource?.valueExpr, props?.textExpr, schedulerRef, schedulerView])

    // 

    return (
        <Box>
            {
                (React.useMemo(() => (
                    <DHSPopup
                        title={props?.title ?? ""}
                        showTitle
                        onClose={() => { setActZoom(defaultAction) }}
                        defaultFullScreen
                        open={actZoom.open}>
                        <Grid container spacing={1}>
                            {
                                schedulerRender("87vh")
                            }
                        </Grid>
                    </DHSPopup>
                ), [actZoom.open, props?.title, schedulerRender]))
            }
            <Grid container spacing={1}>
                {/* <Grid item xs={12} >
                    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
                        <Typography textAlign={"center"} fontSize={22} fontWeight={600} width={"100%"}>
                            {props?.title ?? ""}
                        </Typography>
                        <Box marginLeft={"auto"}>
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                size="small"
                                onClick={() => {
                                    setActZoom(pre => ({
                                        ...pre,
                                        open: true,
                                        type: "",
                                        payload: null
                                    }))
                                }}
                            >

                                <ZoomOutMapIcon sx={{ fontSize: 28, backgroundColor: "#fff", borderRadius: "50%" }} />
                            </IconButton>
                        </Box>
                    </Stack>
                </Grid> */}
                {
                    schedulerRender()
                }

            </Grid>
        </Box>
    )
})



export default DHSScheduler;