import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { components } from "../../../../common/ComponentsConfig/components";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import notification from "../../../../common/notification/notification";
import DHSToolbar from "../../../../components/DHS/DHS-Toolbar/DHSToolbar";
import DHSGridView from "../../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { getRole, getRoles } from "../../../../redux/actions/RoleActions";
import { AppSession } from "../../../shared/app-session/app-session";
import {
  HomeClient,
  HRClient,
  LeaveForm,
  OvertimeForm,
  RoleRightWebModel,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";

let appSession: AppSession;
const menuRoleName = "HR";
const ApproveGrid = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

  // const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
  const { path, url } = useRouteMatch();
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const role = useSelector((state: IReducer) => state.RoleReducer?.role);
  const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
  const [action, setAction] = React.useState<RoleRightWebModel>();
  const hrClient = new HRClient(appSession, BASE_URL_API);
  const [dataTable, setDataTable] = React.useState<any[]>([]);

  const [itemSelected, selectItem] = React.useState<string>();
  const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
  const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
  const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
  const [isShowSort, setShowSort] = React.useState<boolean>(false);
  const [isShowSelect, setShowSelect] = React.useState<boolean>(false);
  const [back, setBack] = React.useState<number>(-1);
  const [form, setForm] = React.useState<string>("");
  const customMenu = [
    {
      menuName: isShowSelect ? "Ẩn Select Box" : "Hiện Select Box",
      onClick: () => {
        setShowSelect(!isShowSelect);
      },
    },
    {
      menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
      onClick: () => {
        setShowFilter(!isShowFilter);
      },
    },
    {
      menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
      onClick: () => {
        setShowSearch(!isShowSearch);
      },
    },
    {
      menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
      onClick: () => {
        setShowGroup(!isShowGroup);
      },
    },
    {
      menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
      onClick: () => {
        setShowSort(!isShowSort);
      },
    },
  ];

  React.useEffect(() => {
    if (
      role?.component?.toLocaleUpperCase() === "HRAPPROVEGRIDV2" ||
      role?.component?.toLocaleUpperCase() === "HRAPPROVEGRID"
    ) {
      setForm("HRAPPROVEVIEW");
    } else if (
      role?.component?.toLocaleUpperCase() === "APPROVEGRIDV2" ||
      role?.component?.toLocaleUpperCase() === "APPROVEGRID"
    )
      setForm("APPROVEVIEW");
  }, [role]);

  const result = (data: number | undefined, message: string | undefined) => {
    if (data === 0) {
      notification("success", message);
      window.location.reload();
    } else notification("error", message);
  };
  React.useEffect(() => {
    if (menu?.component === "TKOvertimeForm") {
      switch (role?.class_name?.toUpperCase()) {
        case "PENDINGFORM":
          hrClient
            ?.hROvertimeGet({
              ...new OvertimeForm(),
              ma_cty: SessionLogin.ma_cty,
              sender_code: SessionLogin.employeeCode,
              action_type: 2,
            } as OvertimeForm)
            .then((respond) => {
              setDataTable(respond);
            });
          break;
        case "HR":
          hrClient
            ?.hROvertimeGet({
              ...new OvertimeForm(),
              ma_cty: SessionLogin.ma_cty,
              action_type: 3,
            } as OvertimeForm)
            .then((respond) => {
              setDataTable(respond);
            });
          break;
      }
    } else if (menu?.component === "TKLeaveForm") {
      switch (role?.class_name?.toUpperCase()) {
        case "PENDINGFORM":
          hrClient
            ?.hROnLeaveGet({
              ...new LeaveForm(),
              ma_cty: SessionLogin.ma_cty,
              sender_code: SessionLogin.employeeCode,
              action_type: 2,
            } as LeaveForm)
            .then((respond) => {
              setDataTable(respond);
            });
          break;
        case "HR":
          hrClient
            ?.hROnLeaveGet({
              ...new LeaveForm(),
              ma_cty: SessionLogin.ma_cty,
              action_type: 3,
            } as LeaveForm)
            .then((respond) => {
              setDataTable(respond);
            });
          break;
      }
    }
  }, [menu?.component, role?.class_name]);
  return (
    <div>
      <Switch>
        <Route path={path} exact>
          <div
            className="d-flex title-color"
            style={{
              height: 30,
              color: "rgb(0 78 255 / 88%)",
              marginTop: 15,
              marginBottom: 15,
              fontSize: 22,
              fontWeight: 550,
            }}
          >
            <i
              className="bi bi-arrow-left"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.go(back);
              }}
            ></i>
            &nbsp;&nbsp;
            {menu?.bar}
          </div>{" "}
          <DHSToolbar
            id={itemSelected}
            list={
              roles && menu
                ? roles.filter(
                    (item) => item.on_form?.toLocaleUpperCase() === form
                  )
                : []
            }
            onClickAction={(item) => {
              setAction(item);
            }}
            customMenu={customMenu}
          />
          <DHSGridView
            onSelectRow={(row) => {
              selectItem(row);
            }}
            // onListSelectRow={handleListOnselectRow}
            itemClicked={function (): void {
              throw new Error("Function not implemented.");
            }}
            table_name={
              role?.component ? role.component : role?.menuid ? role.menuid : ""
            }
            show_form_list={true}
            showFilterPanel={isShowFilter}
            showSortPanel={isShowSort}
            showSearchPanel={isShowSearch}
            showGroupPanel={isShowGroup}
            showSelectBox={isShowSelect}
            data={dataTable}
          />
        </Route>
        {roles &&
          roles.map((role, index) => {
            return (
              role.link &&
              role.link.length > 1 &&
              role.component && (
                <Route
                  key={index}
                  path={role.is_parameter ? role.link + ":_id" : role.link}
                  component={components[role.component.toLocaleUpperCase()]}
                  exact
                ></Route>
              )
            );
          })}
      </Switch>
    </div>
  );
};

export default ApproveGrid;
