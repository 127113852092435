import React from "react";
import TextBox from "devextreme-react/text-box";
import Validator, { RequiredRule } from "devextreme-react/validator";


type dxTextBoxProps = React.ComponentProps<typeof TextBox>
interface IProps extends dxTextBoxProps {
  required?: boolean,
  requiredMessage?: string

}

const DHSTextBox = React.forwardRef<TextBox, IProps>((props, ref) => {
  return <TextBox
    {...props}
    ref={ref}

  >
    <Validator>
      {props?.required && <RequiredRule message={props?.requiredMessage ?? "Vui lòng không để trống."} />}
    </Validator>
  </TextBox>
})

export default DHSTextBox;