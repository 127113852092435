import { Box } from "@mui/material";
import Popup, { ToolbarItem } from "devextreme-react/popup";
import { EventInfo } from "devextreme/events";
import _ from "lodash";
import React from "react";
import { isNullOrUndefinedOrEmpty } from "../../../utils/object.util";
import "./DHSPopup.css";

interface IProps {
    id?: string,
    open: boolean,
    onClose?: () => void,
    contentRender?: (...params: any) => React.ReactNode,
    onShown?: (e: EventInfo<any>) => void,
    titleRender?: ((...params: any) => React.ReactNode),
    backgroundColor?: string,
    defaultFullScreen?: boolean,
    dragEnabled?: boolean,
    hideOnOutsideClick?: boolean,
    showTitle?: boolean,
    showToolbar?: boolean,
    title?: string,
    width?: string | number | (() => string | number),
    height?: string | number | (() => string | number),
    container?: string,
}

const DHSPopup: React.FC<IProps> = (props) => {
    const [fullScreen, setFullScreen] = React.useState<boolean>(props?.defaultFullScreen ?? false);

    return <Popup
        visible={props.open}
        onHiding={props?.onClose}
        dragEnabled={props?.dragEnabled}
        hideOnOutsideClick={props?.hideOnOutsideClick ?? false}
        showCloseButton={true}
        showTitle={props?.showTitle ?? true}
        fullScreen={fullScreen}
        title={props?.title}
        titleRender={props?.titleRender}
        defaultPosition={"center"}
        position={"center"}
        container={props?.container ?? ".dx-viewport"}
        width={!fullScreen ? `${props?.width ?? "80%"}` : undefined}
        height={!fullScreen ? `${props?.height ?? "80%"}` : undefined}
        contentRender={props?.contentRender}
        onShown={props?.onShown}
        wrapperAttr={{id: props?.id} }

    >

        {
            (isNullOrUndefinedOrEmpty(props?.showToolbar) || props?.showToolbar) && <ToolbarItem
                widget="dxButton"
                toolbar="top"
                location="after"
                visible={true}
                cssClass={"p-0"}
                options={{
                    icon: fullScreen ? "copy" : "fullscreen",
                    text: "",
                    style: { WebkitTextStroke: "1px" },
                    stylingMode: "text",
                    onClick: (e: any) => setFullScreen(pre => !pre)
                }}
            >

            </ToolbarItem>
        }
        {/* <ScrollView width='100%' height='100%'> */}
        <Box width='100%' height='100%' sx={{ backgroundColor: props?.backgroundColor }}>
            {props.children}
        </Box>
        {/* </ScrollView> */}

    </Popup>

}

export default DHSPopup;