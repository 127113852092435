import React from "react";
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
    Grid,
    Stack,
    CircularProgress,
    Link,
} from "@mui/material";
import CurrencyTextField from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import CloseIcon from "@mui/icons-material/Close";

import {
    QLCCClient,
    QLCCApartmentBuildingModel,
    SysActionType,
    CommonClient,
    CaVch01Client,
    LstCurrency,
    ParaObjExchageRate,
    ParaObj,
    QLCCOBCashBank,
    QLCCResidentsModel,
    ApBook,
    ApBookClient,
    LstAccount,
    ParaObjTableList,
} from "../../../shared/service-proxies/api-shared";


import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../ApartmentBuilding.css";
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import CTXDate from "../../../../components/CTX/DatePicker";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import DHSAutoCompleteTableResident from "../../../../components/DHS/DHS_AutoCompleteResident/DHSAutoCompleteTableResident";
import notification from "../../../../common/notification/notification";
import DHSAutoCompleteWithDataGrid from "../../../../components/DHS/DHS_AutoCompleteCustomer/DHSAutoCompleteWithDataGrid";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { callApiToken } from "../../../../routes/configApi";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";
import { httpPost } from "../../../../common/httpService";
import moment from "moment";




interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: () => void;
    obcash?: any;
    apartmentBuildings?: QLCCApartmentBuildingModel[] | undefined;
    payload?: any;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const status_message = ['success', 'error']
var appSession: AppSession;

const PayableAction: React.FC<IProps> = (props: IProps) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const [_id, setId] = React.useState<string>();

    const qLCClient = new QLCCClient(appSession, BASE_URL_API);

    const [isLoading, setLoading] = React.useState<boolean>(false);

    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [payable, setObCashBank] = React.useState<ApBook>({} as ApBook);

    const caVch01Client = new CaVch01Client(appSession, BASE_URL_API);

    const [currencyOptions, setCurrencyOptions] = React.useState<any[]>([]);

    const commonClient = new CommonClient(appSession, BASE_URL_API);

    const apBookClient = new ApBookClient(appSession, BASE_URL_API);

    const [lstAccounts, setLstAccounts] = React.useState<LstAccount[]>([] as LstAccount[]);

    const [residentOptions, setResidentOptions] = React.useState<any>([]);

    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const Token = {
        Token: SessionLogin.Token
    }

    const onLoadFilter = () => {
        const body =
        {
            ma_cty: SessionLogin.ma_cty,
            tablelist: "DM_NCC"
        }

        httpPost<string>('/api/Common/LoadDatasourceBindControlByApartmentBuilding', {
            ma_cty: body.ma_cty,
            tablelist: body.tablelist,
            apartment_building_id: apartment_building_id
        })
            .then(res => {
                console.log(JSON.parse(res.data))
                const serviceGroups: any = JSON.parse(res.data)
                setResidentOptions(serviceGroups?.Table1)
            }).catch((ex) => {
                console.log(ex)
            });

        // callApiToken("api/Common/LoadFilterList", "POST", body, Token)
        //     .then((res) => {
        //         setResidentOptions(res.data)
        //         setLoading(false);
        //     })
        //     .catch((error) => console.log(error));
    }

    React.useEffect(() => {
        setLoading(true);


        caVch01Client.lstCurrencyGet({
            ...new LstCurrency(),
            ma_cty: SessionLogin.ma_cty,
        } as LstCurrency)
            .then(res => {
                setLoading(false);
                setCurrencyOptions(res);
            });

        commonClient.get_VoucherNo({
            ... new ParaObj(),
            ma_cty: SessionLogin.ma_cty,
            ma_ct: "SO5",
            ngay_ct: new Date()
        } as ParaObj)
            .then(res => {
                setObCashBank({
                    ...payable,
                    so_ct: res
                } as ApBook);
            })

        qLCClient.qLCClstAccountGet(
            SessionLogin.ma_cty,
            ""
        )
            .then((res: any) => {

                setLstAccounts(res);
                setLoading(false);

            })
            .catch((err) => {
                setLoading(false);
            })
    }, [])

    React.useEffect(() => {
        if (props.open === true) {
            onLoadFilter();
            if (props.action === ACTIONS.ADD) {
                setLoading(true);
                const newApBook = {
                    ... new ApBook(),
                    ngay_ct: new Date(),
                    ngay_hd: new Date(),
                    ma_nt: "VND",
                    ty_gia: 1,
                    tk_pt: '1111',
                    da_tt: 0,
                    da_tt_nt: 0,
                    du_tt: 0,
                    du_tt_nt: 0,
                    t_thue: 0,
                    t_thue_nt: 0,
                    t_tien0: 0,
                    t_tien_nt0: 0,
                    t_tt: 0,
                    t_tt_nt: 0,
                } as ApBook;
                commonClient.get_VoucherNo({
                    ... new ParaObj(),
                    ma_cty: SessionLogin.ma_cty,
                    ma_ct: "SO5",
                    ngay_ct: new Date()
                } as ParaObj)
                    .then(res => {
                        setObCashBank({
                            ...newApBook,
                            so_ct: res
                        } as ApBook);
                        setLoading(false);
                    }).catch(err => {
                        setLoading(false);
                    })
            } else {
                setObCashBank({
                    ...props.obcash,
                } as ApBook);
                setLoading(false);
            }
        }

    }, [props.open, props.itemSelected]);

    React.useEffect(() => {

        if (payable && payable.ma_nt && props.open === true) {

            commonClient
                .getExchangeRate5({

                    ... new ParaObjExchageRate(),
                    ma_cty: SessionLogin.ma_cty,
                    ma_nt: payable.ma_nt,
                    ngay_ct: payable.ngay_ck || new Date()

                } as ParaObjExchageRate)
                .then(res => {
                    if (res) {

                        const tyGia = Number(res);

                        const da_tt = payable.da_tt_nt && payable.da_tt_nt !== 0 ? Number(payable.da_tt_nt) * tyGia : 0;
                        const du_tt = payable.du_tt_nt && payable.du_tt_nt !== 0 ? Number(payable.du_tt_nt) * tyGia : 0;
                        const t_thue = payable.t_thue_nt && payable.t_thue_nt !== 0 ? Number(payable.t_thue_nt) * tyGia : 0;
                        const t_tien0 = payable.t_tien_nt0 && payable.t_tien_nt0 !== 0 ? Number(payable.t_tien_nt0) * tyGia : 0;
                        const t_tt = payable.t_tt_nt && payable.t_tt_nt !== 0 ? Number(payable.t_tt_nt) * tyGia : 0;

                        setObCashBank({
                            ...payable,
                            ty_gia: tyGia,
                            da_tt: isNaN(da_tt) ? 0 : da_tt,
                            du_tt: isNaN(du_tt) ? 0 : du_tt,
                            t_thue: isNaN(t_thue) ? 0 : t_thue,
                            t_tien0: isNaN(t_tien0) ? 0 : t_tien0,
                            t_tt: isNaN(t_tt) ? 0 : t_tt,
                        } as ApBook)
                    }
                    // debugger
                }).catch(err => {
                    console.log(err)
                })
        }
    }, [payable.ma_nt])


    const handleChange = (e: any) => {

        const { name, value } = e.target;

        if (props.action !== ACTIONS.VIEW) {

            if (name === 'ngay_ct') {

                if (props.obcash && payable.ngay_ct) {
                    commonClient.get_VoucherNo({
                        ... new ParaObj(),
                        ma_cty: SessionLogin.ma_cty,
                        ma_ct: "SO5",
                        ngay_ct: payable.ngay_ct,
                    } as ParaObj)
                        .then(res => {
                            setObCashBank({
                                ...payable,
                                [name]: value,
                                so_ct: res
                            } as ApBook);
                        })
                }

            }
            else {
                setObCashBank({
                    ...payable,
                    [name]: value,
                } as ApBook);
            }

        }
    }

    const handleChangeDate = (e: any) => {
        const { name, value } = e;

        if (props.action !== ACTIONS.VIEW) {

            setObCashBank({
                ...payable,
                [name]: value,
            } as ApBook);

        }
    }

    const handleUpdate = () => {

        const ngayChungTuUTC = payable.ngay_ct ? new Date(payable.ngay_ct) : new Date();

        apBookClient.apBookUpd({
            ... new ApBook(),
            ...payable,
            ma_cty: SessionLogin.ma_cty,
            stt_rec: payable.stt_rec,
            ma_ct: "SO5",
            so_hd: payable.so_ct,
            // ngay_ct: ngayChungTuUTC ? new Date(Date.UTC(ngayChungTuUTC?.getFullYear(), ngayChungTuUTC?.getMonth(), ngayChungTuUTC?.getDate(), 0, 0, 0)) : new Date(),
            // ngay_hd: ngayChungTuUTC ? new Date(Date.UTC(ngayChungTuUTC?.getFullYear(), ngayChungTuUTC?.getMonth(), ngayChungTuUTC?.getDate(), 0, 0, 0)) : new Date(),
            ngay_ct: moment(ngayChungTuUTC).startOf('day').utc(true).toDate(),
            ngay_hd: moment(ngayChungTuUTC).startOf('day').utc(true).toDate(),
            da_tt: Number(payable.da_tt),
            da_tt_nt: Number(payable.da_tt_nt),
            du_tt: Number(payable.du_tt),
            du_tt_nt: Number(payable.du_tt_nt),
            t_thue: Number(payable.t_thue),
            t_thue_nt: Number(payable.t_thue_nt),
            t_tien0: Number(payable.t_tien0),
            t_tien_nt0: Number(payable.t_tien_nt0),
            t_tt: Number(payable.t_tt),
            t_tt_nt: Number(payable.t_tt_nt),
            ty_gia: Number(payable.ty_gia),
            luser: SessionLogin.userName,
            apartment_building_id: apartment_building_id
        } as ApBook | any).then(res => {

            status(res.status === 0 ? res.status : 1, res.message);

        })

    }


    const status = (status: number = 1, message: string = "") => {
        notification(status_message[status], message);
        if (status === 0) {
            props.onEditSuccess();
            props.onClose()
        }

    }

    const handleSubmit = () => {
        const ngayChungTuUTC = payable.ngay_ct ? new Date(payable.ngay_ct) : new Date();
        apBookClient.apBookIns({
            ... new ApBook(),
            ...payable,
            stt_rec: payable.stt_rec,
            ma_cty: SessionLogin.ma_cty,
            ma_ct: "SO5",
            so_hd: payable.so_ct,
            // ngay_ct: ngayChungTuUTC ? new Date(Date.UTC(ngayChungTuUTC?.getFullYear(), ngayChungTuUTC?.getMonth(), ngayChungTuUTC?.getDate(), 0, 0, 0)) : new Date(),
            // ngay_hd: ngayChungTuUTC ? new Date(Date.UTC(ngayChungTuUTC?.getFullYear(), ngayChungTuUTC?.getMonth(), ngayChungTuUTC?.getDate(), 0, 0, 0)) : new Date(),
            ngay_ct: moment(ngayChungTuUTC).startOf('day').utc(true).toDate(),
            ngay_hd: moment(ngayChungTuUTC).startOf('day').utc(true).toDate(),
            da_tt: Number(payable.da_tt),
            da_tt_nt: Number(payable.da_tt_nt),
            du_tt: Number(payable.du_tt),
            du_tt_nt: Number(payable.du_tt_nt),
            t_thue: Number(payable.t_thue),
            t_thue_nt: Number(payable.t_thue_nt),
            t_tien0: Number(payable.t_tien0),
            t_tien_nt0: Number(payable.t_tien_nt0),
            t_tt: Number(payable.t_tt),
            t_tt_nt: Number(payable.t_tt_nt),
            ty_gia: Number(payable.ty_gia),
            luser: SessionLogin.userName,
            cuser: SessionLogin.userName,
            apartment_building_id: apartment_building_id,
        } as ApBook | any).then(res => {
            status(res.status === 0 ? res.status : 1, res.message)
        }).catch(err => {
            console.log(err)
        })
    }

    const handleClickAction = (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code) {
            case ACTIONS.SAVE:

                if (props.action === ACTIONS.ADD) {
                    handleSubmit();

                } else if (props.action === ACTIONS.EDIT) {
                    handleUpdate();
                }

                break;

            case ACTIONS.CLOSE:

                props.onClose();

                break;
        }
    }


    const handleChangeCurrentInput = (

        value: string | undefined, name?: string | undefined, values?: CurrencyInputOnChangeValues | undefined) => {
        if (props.action.toUpperCase() !== ACTIONS.VIEW) {

            if (name !== 't_tien_nt0') {
                const convert = Number(value) !== 0 ? Number(value) * Number(payable.ty_gia) / 1.00 : 0;
                const name2: string | undefined = name?.split("_nt")[0].toString();

                setObCashBank({
                    ...payable,
                    [name!]: value,
                    [name2!]: isNaN(convert) ? 0 : 1.00 * convert,
                } as ApBook);
            } else {
                const convert = Number(value) !== 0 ? Number(value) * Number(payable.ty_gia) / 1.00 : 0;
                const name2: string | undefined = 't_tien0';

                setObCashBank({
                    ...payable,
                    [name!]: value,
                    [name2!]: isNaN(convert) ? 0 : 1.00 * convert,
                } as ApBook);
            }

        }

    }

    const handleChangeCurrentConvertInput = (

        value: string | undefined, name?: string | undefined, values?: CurrencyInputOnChangeValues | undefined) => {
        if (props.action.toUpperCase() !== ACTIONS.VIEW) {


            if (name !== 't_tien0') {

                const convert = Number(value) !== 0 ? Number(value) / Number(payable.ty_gia) : 0;

                const name2 = name?.concat("_nt");

                setObCashBank({
                    ...payable,
                    [name!]: value,
                    [name2!]: isNaN(convert) ? 0 : 1.00 * convert,
                } as ApBook);

            } else {

                const convert = Number(value) !== 0 ? Number(value) / Number(payable.ty_gia) : 0;

                const name2 = 't_tien_nt0'

                setObCashBank({
                    ...payable,
                    [name!]: value,
                    [name2!]: isNaN(convert) ? 0 : 1.00 * convert,
                } as ApBook);

            }

        }

    }



    return (
        <Dialog
            id="sysMenu5"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            TransitionComponent={Transition}
            maxWidth="xs"
        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === "VIEW"
                                ? "[Chi tiết]"
                                : props.action === "EDIT" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={6}>
                                <Typography className="text-start mb-3"><strong>Thông tin chung</strong></Typography>
                                <Box className="mb-2 d-md-flex justify-content-between w-100">
                                    <InputLabel
                                        className="w-100 cc-input"
                                    >
                                        Nhà cung cấp
                                    </InputLabel>
                                    <Box className="w-100">
                                        <DHSAutoCompleteWithDataGrid
                                            id="resident-select"
                                            value={payable.iDcustomer ?? null}
                                            table_name="DM_NCC"
                                            // table_name="DHSGridResidentSearch"
                                            // placeholder="Chọn nhà cung cấp"
                                            filter={props.payload}
                                            name="iDcustomer"
                                            valueExpr={"idcustomer"}
                                            options={residentOptions}
                                            dataGridOnSelectionChanged={(e, newValue: any) => {
                                                handleChange({
                                                    target: {
                                                        name: "iDcustomer",
                                                        value: newValue?.idcustomer ?? null,
                                                    },
                                                });
                                            }}
                                            syncDataGridSelection={function (e): void {
                                                setObCashBank(pre => ({
                                                    ...pre,
                                                    iDcustomer: e.value
                                                } as ApBook));
                                            }}
                                            gridBoxDisplayExpr={function (item: any): string {
                                                return item && `${item?.ten_kh}`;
                                            }}

                                            disabled={props.action === ACTIONS.VIEW}
                                        />
                                        {/* <p className="text-right mb-0">
                                            <Link className="display-block" >
                                                <small>Thêm nhà cung cấp mới</small>
                                            </Link>
                                        </p> */}
                                    </Box>
                                </Box>
                                <Box className="mb-2 d-md-flex justify-content-between w-100">
                                    <InputLabel
                                        className="w-100 cc-input"
                                    >
                                        Tài khoản chi trả
                                    </InputLabel>
                                    <DHSAutoCompleteWithDataSource
                                        id="apartment-select"
                                        className="cc-input"
                                        dataSource={lstAccounts}
                                        getOptionLabel={(option: any) => `${option?.ten_tk}`}
                                        valueMember="tk"
                                        value={payable?.tk_pt}
                                        onValueChanged={(e, newValue: any) => {
                                            handleChange({
                                                target: {
                                                    name: "tk_pt",
                                                    value: newValue?.tk ? newValue.tk : null,
                                                },
                                            });
                                        }}
                                    />
                                    {/* <Autocomplete
                                        id="apartment-select"
                                        sx={{ width: "100%" }}
                                        options={lstAccounts}
                                        autoHighlight
                                        getOptionLabel={(option: any) => `${option?.ten_tk}`}
                                        value={
                                            lstAccounts
                                            && lstAccounts.find(
                                                (item: any) => item.tk === payable.tk_pt
                                            )
                                        }
                                        onChange={(e, newValue: any) => {
                                            handleChange({
                                                target: {
                                                    name: "tk_pt",
                                                    value: newValue?.tk ? newValue.tk : null,
                                                },
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className="cc-input"
                                                placeholder="Tài khoản"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password", // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    /> */}
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography className="text-start mb-3"><strong>Thông tin chứng từ</strong></Typography>

                                <Box className="mb-3 d-md-flex align-items-center justify-content-between">
                                    <InputLabel className="cc-label w-100">
                                        Ngày chứng từ
                                    </InputLabel>
                                    <CTXDate
                                        className=" w-100 cc-input"
                                        value={payable.ngay_ct}
                                        name={"ngay_ct"}
                                        onChange={handleChangeDate}
                                    />
                                </Box>
                                {/* Số chứng từ */}
                                <Box className="mb-3 d-md-flex align-items-center justify-content-between">
                                    <InputLabel className="cc-label w-100">
                                        Số chứng từ
                                    </InputLabel>
                                    <TextField
                                        className="cc-input w-100"
                                        value={payable.so_ct}
                                        name="so_ct"
                                        onChange={handleChange}
                                        placeholder="Nhập số chứng từ"
                                    />
                                </Box>
                                {/* Ngày hóa đơn */}
                                {/* <Box className="mb-3 d-md-flex align-items-center justify-content-between">
                                    <InputLabel className="cc-label w-100">
                                        Ngày hóa đơn
                                    </InputLabel>
                                    <CTXDate
                                        className=" w-100 cc-input"
                                        value={payable.cdate}
                                        name={"date"}
                                        onChange={handleChangeDate}
                                    />
                                </Box> */}
                                {/* Số hóa đơn */}
                                {/* <Box className="mb-3 d-md-flex align-items-center justify-content-between">
                                    <InputLabel className="cc-label w-100">
                                        Số hóa đơn
                                    </InputLabel>
                                    <TextField
                                        className="cc-input w-100"
                                        value={""}
                                        name=""
                                        onChange={handleChangeInput}
                                        placeholder="Nhập số hóa đơn"
                                    />
                                </Box> */}
                                {/* Tỷ giá */}
                                <Typography className="text-start mb-3"><strong>Thông tin tiền tệ</strong></Typography>
                                <Box className="mb-3 d-md-flex align-items-start justify-content-between">
                                    <InputLabel className="cc-label w-100">
                                        Loại tiền
                                    </InputLabel>
                                    <Box className="w-100">
                                        <Stack spacing={2}>
                                            <DHSAutoCompleteWithDataSource
                                                id="apartment-select"
                                                className="cc-input"
                                                dataSource={currencyOptions}
                                                displayMember="ma_nt"
                                                valueMember="ma_nt"
                                                value={payable?.ma_nt}
                                                onValueChanged={(e, newValue: any) => {
                                                    handleChange({
                                                        target: {
                                                            name: "ma_nt",
                                                            value: newValue?.ma_nt ? newValue.ma_nt : null,
                                                        },
                                                    });
                                                }}
                                            />
                                            {/* <Autocomplete
                                                id="apartment-select"
                                                sx={{ width: "100%" }}
                                                options={currencyOptions}
                                                autoHighlight
                                                getOptionLabel={(option: any) => `${option.ma_nt}`}
                                                value={
                                                    payable.ma_nt
                                                        ? currencyOptions.find(
                                                            (item: any) => item.ma_nt === payable.ma_nt
                                                        ) : null
                                                }
                                                onChange={(e, newValue: any) => {
                                                    handleChange({
                                                        target: {
                                                            name: "ma_nt",
                                                            value: newValue?.ma_nt ? newValue.ma_nt : null,
                                                        },
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className="cc-input"
                                                        //placeholder="-- Mã ngoại tệ --"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password", // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                            /> */}
                                            <CurrencyTextField
                                                className="flex-grow-1 form-control currency-input"
                                                style={{ width: "100%", textAlign: "right" }}
                                                allowDecimals
                                                // decimalsLimit={2}
                                                decimalsLimit={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                                decimalSeparator="."
                                                groupSeparator=","
                                                //decimalScale={2}
                                                decimalScale={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                                placeholder="Tỷ giá"
                                                intlConfig={currencyOptions.find(p => p.ma_nt === payable.ma_nt?.toLocaleUpperCase())}
                                                name="ty_gia"
                                                onValueChange={handleChangeCurrentInput}
                                                value={payable.ty_gia}
                                            />
                                        </Stack>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} >
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={6} >
                                <Typography className="text-start mb-3"><strong>Tiền ngoại tệ</strong></Typography>

                                <Stack spacing={2}>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Tiền hàng
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            // decimalsLimit={2}
                                            decimalsLimit={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            //decimalScale={2}
                                            decimalScale={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            placeholder="Tiền hàng ngoại tệ"
                                            intlConfig={currencyOptions.find(p => p.ma_nt === payable.ma_nt?.toLocaleUpperCase())}
                                            name="t_tien_nt0"
                                            onValueChange={handleChangeCurrentInput}
                                            value={payable.t_tien_nt0}
                                        />
                                    </Box>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Tiền thuế
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            // decimalsLimit={2}
                                            decimalsLimit={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            //decimalScale={2}
                                            decimalScale={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            placeholder="Tiền thuế ngoại tệ"
                                            intlConfig={currencyOptions.find(p => p.ma_nt === payable.ma_nt?.toLocaleUpperCase())}
                                            name="t_thue_nt"
                                            onValueChange={handleChangeCurrentInput}
                                            value={payable.t_thue_nt}
                                        />
                                    </Box>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Tổng thanh toán
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            // decimalsLimit={2}
                                            decimalsLimit={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            //decimalScale={2}
                                            decimalScale={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            placeholder="Tổng tiền thanh toán ngoại tệ"
                                            intlConfig={currencyOptions.find(p => p.ma_nt === payable.ma_nt?.toLocaleUpperCase())}
                                            name="t_tt_nt"
                                            onValueChange={handleChangeCurrentInput}
                                            value={payable.t_tt_nt}
                                        />
                                    </Box>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Đã thanh toán
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100%", textAlign: "right" }}
                                            allowDecimals
                                            // decimalsLimit={2}
                                            decimalsLimit={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            //decimalScale={2}
                                            decimalScale={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}

                                            // placeholder="Số tiền đã thanh toán ngoại tệ"
                                            intlConfig={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())}
                                            name="da_tt_nt"
                                            onValueChange={handleChangeCurrentInput}
                                            value={payable.da_tt_nt}
                                        />
                                    </Box>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Còn phải thanh toán
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            // decimalsLimit={2}
                                            decimalsLimit={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            //decimalScale={2}
                                            decimalScale={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            placeholder="Số tiền còn phải thanh toán"
                                            intlConfig={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())}
                                            name="du_tt_nt"
                                            onValueChange={handleChangeCurrentInput}
                                            value={payable.du_tt_nt}
                                        />
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography className="text-start mb-3"><strong>Tiền quy đổi</strong></Typography>

                                <Stack spacing={2}>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Tiền hàng
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            // decimalsLimit={2}
                                            decimalsLimit={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            //decimalScale={2}
                                            decimalScale={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            placeholder="Tiền hàng"
                                            intlConfig={{ locale: 'vi-VN', currency: 'VND' }}

                                            name="t_tien0"
                                            onValueChange={handleChangeCurrentConvertInput}
                                            value={payable.t_tien0}
                                            disabled={true}
                                        />
                                    </Box>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Tiền thuế
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            // decimalsLimit={2}
                                            decimalsLimit={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            //decimalScale={2}
                                            decimalScale={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            placeholder="Tiền thuế"
                                            intlConfig={{ locale: 'vi-VN', currency: 'VND' }}

                                            name="t_thue"
                                            onValueChange={handleChangeCurrentConvertInput}
                                            value={payable.t_thue}
                                            disabled={true}
                                        />
                                    </Box>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Tổng thanh toán
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            // decimalsLimit={2}
                                            decimalsLimit={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            //decimalScale={2}
                                            decimalScale={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            placeholder="Tổng tiền thanh toán"
                                            intlConfig={{ locale: 'vi-VN', currency: 'VND' }}

                                            name="t_tt"
                                            onValueChange={handleChangeCurrentConvertInput}
                                            value={payable.t_tt}
                                            disabled={true}
                                        />
                                    </Box>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Đã thanh toán
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            // decimalsLimit={2}
                                            decimalsLimit={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            //decimalScale={2}
                                            decimalScale={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            placeholder="Số tiền đã thanh toán"
                                            intlConfig={{ locale: 'vi-VN', currency: 'VND' }}

                                            name="da_tt"
                                            onValueChange={handleChangeCurrentConvertInput}
                                            value={payable.da_tt}
                                            disabled={true}
                                        />
                                    </Box>
                                    <Box className="w-100 d-md-flex justify-content-between">
                                        <InputLabel
                                            className="cc-input w-100"
                                        >
                                            Còn phải thanh toán
                                        </InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100    %", textAlign: "right" }}
                                            allowDecimals
                                            // decimalsLimit={2}
                                            decimalsLimit={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            //decimalScale={2}
                                            decimalScale={currencyOptions.find(p => p.currency === payable.ma_nt?.toLocaleUpperCase())?.roundoffamount ?? 0}
                                            placeholder="Số tiền còn phải thanh toán"
                                            intlConfig={{ locale: 'vi-VN', currency: 'VND' }}
                                            name="du_tt"
                                            onValueChange={handleChangeCurrentConvertInput}
                                            value={payable.du_tt}
                                            disabled={true}
                                        />
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>


            {
                props.action
                && props.action !== ACTIONS.VIEW
                && (<>
                    <DialogActions>

                        <DHSDialogAction

                            menu={menu}
                            onClickAction={handleClickAction}
                            action={props.action}
                        />

                    </DialogActions>
                </>)
            }


            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog >
    );
};

export default PayableAction;
