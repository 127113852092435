import { Checkbox, Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { AppSession } from "../../app/shared/app-session/app-session";
import { HomeClient, RoleRightWebModel } from "../../app/shared/service-proxies/api-shared";
import BASE_URL_API from "../../app/shared/service-proxies/config";
import DHSToolbar from "../../components/DHS/DHS-Toolbar/DHSToolbar";
import DHSGridView from "../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { getRoles } from "../../redux/actions/RoleActions";
import { IReducer } from "../Interfaces/Interfaces";

let appSession: AppSession;
const menuRoleName = "CC";
const UserRightComponent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
    const { path, url } = useRouteMatch();
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [itemSelected, selectItem] = React.useState<string>();
    const [open, setOpen] = React.useState<boolean>(false);
    const handleChangeInput = (e: any) => {
        // if (e.target.name === "isUse") {
        //     setData({
        //         ...data,
        //         isUse: Number(e.target.checked),
        //     } as CtxThongTinXeModel);
        // } else {
        //     const { name, value } = e.target;
        //     setData({ ...data, [name]: value } as CtxThongTinXeModel);
        // }
    };
    const onClose = () => {

    }
    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>
    ) {
        return <Slide direction="left" ref={ref} {...props} />;
    });
    React.useEffect(() => {
        homeClient
            ?.roleRightWebGetAll({
                ...new RoleRightWebModel(),
                rolename: menuRoleName,
                menuid: menu?.menuid,
            } as RoleRightWebModel)
            .then((res) => {
                dispatch(getRoles(res));
                setRoles(res);
            });
    }, []);

    const handleClickAction = (action: RoleRightWebModel) => {
        switch (action?.class_name?.toUpperCase()) {
            case "ADD":
                setOpen(true);
                break;
            case "EDIT":
                setOpen(true);
                break;
            case "DELETE":
                break;
            default:
                history.goBack();
                break;
        }
    };

    const handleSubmit = () => {
        setOpen(false);
    };

    return (
        <div className="container-fluid p-2" style={{maxHeight: "94vh", overflowY: "auto"}}>
            <DHSToolbar
                id={itemSelected}
                list={
                    roles
                        ? roles.filter(
                            (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
                        )
                        : []
                }
                onClickAction={handleClickAction}
            />
            <DHSGridView
                onSelectRow={(row) => {
                    selectItem(row);
                }}
                // onListSelectRow={handleListOnselectRow}
                itemClicked={function (): void {
                    throw new Error("Function not implemented.");
                }}
                table_name={
                    menu?.component ? menu.component : menu?.menuid ? menu.menuid : ""
                }
                show_form_list={true}
                data={[]}
            />

        </div >
    );
};

export default UserRightComponent;
