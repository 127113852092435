import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IReducer } from "../../../components/layouts";
import { AppSession } from "../../shared/app-session/app-session";
import {
  QLCCClient,
  QLCCResidentJobsModel,
  SysActionType,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import { useLocation } from "react-router-dom";
import BlockUI from "../../../components/Block-UI/Block-ui";
import notification from "../../../common/notification/notification";
import {
  RowInsertingInfo,
  RowRemovingInfo,
  RowUpdatingInfo,
  SelectionChangedInfo,
} from "devextreme/common/grids";
import dxDataGrid from "devextreme/ui/data_grid";
import DataGrid from "devextreme-react/data-grid";
import { EventInfo } from "devextreme/events";
import {
  STATUS_MESSAGE,
  TYPE_MESSAGE,
} from "../../../common/Interfaces/StatusMessage";
import { ACTIONS } from "../../../common/enums/DHSToolbarRoleAction";
import DHSToolbarRole from "../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DHSBreadcrumb from "../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import { getDataRowSelected, getIndexRowSelected, getKeyRowSelected, getListDataRowSelected, getListIndexRowSelected, getListKeyRowSelected, onAddRow, onDeleteRow, onEditRow, onViewRow } from "../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";

let appSession: AppSession;

const ResidentJobList: React.FC<{}> = () => {
  const location = useLocation();

  const [loading, setLoading] = React.useState<boolean>(false);
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const [dataTable, setDataTable] = React.useState<QLCCResidentJobsModel[]>([]);
  const qLCCClient = new QLCCClient(appSession, BASE_URL_API);

  const dataGridRef = React.useRef<DataGrid>(null);
  const [allowEdit, setAllowEdit] = useState<boolean>(true);
  const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

  async function onLoadDataSource() {
    try {
      setLoading(true);
      const response = await qLCCClient.qLCCResidentJobsActions({
        ...new QLCCResidentJobsModel(),
        action_type: "GET",
        apartment_building_id: apartment_building_id,
        ma_cty: SessionLogin.ma_cty
      } as QLCCResidentJobsModel);
      setDataTable([...response]);
    } catch (error) {
      setDataTable([]);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    onLoadDataSource();
  }, [apartment_building_id]);

  const handleSelectionChanged = async (
    e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
  ) => {
    // if (dataGridRef.current) {

    // }
  };

  const handleRowInserting = async (
    event: EventInfo<dxDataGrid<any, any>> & RowInsertingInfo<any>
  ) => {
    try {
      event.cancel = true;
      setLoading(true);
      const response = await qLCCClient.qLCCResidentJobsActions({
        ...new QLCCResidentJobsModel(),
        ...event.data,
        action_type: "INSERT",
        apartment_building_id: apartment_building_id,
        ma_cty: SessionLogin.ma_cty
      } as QLCCResidentJobsModel);
      if (response[0].status === 0) {
        setDataTable([{ id: response[0].id, ...event.data }, ...dataTable]);
        dataGridRef.current?.instance.cancelEditData();
        notification(
          TYPE_MESSAGE.SUCCESS,
          STATUS_MESSAGE[`${ACTIONS.ADD}_${response[0]!.status}`]
        );
      } else {
        event.cancel = true;
        notification(
          TYPE_MESSAGE.ERRORR,
          STATUS_MESSAGE[`${ACTIONS.ADD}_${response[0]!.status}`]
        );
      }
    } catch (error) {
      notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
    } finally {
      setLoading(false);
    }
  };

  const handleRowUpdating = async (
    event: EventInfo<dxDataGrid<any, any>> & RowUpdatingInfo<any, any>
  ) => {
    try {
      event.cancel = true;
      setLoading(true);
      const response = await qLCCClient.qLCCResidentJobsActions({
        ...new QLCCResidentJobsModel(),
        ...event.oldData,
        ...event.newData,
        action_type: "UPDATE",
        apartment_building_id: apartment_building_id,
      } as QLCCResidentJobsModel);
      if (response[0].status === 0) {
        const dataLastUpdated = dataTable.map((item) => {
          if (item.id === event.key) {
            return {
              ...new QLCCResidentJobsModel(),
              ...event.oldData,
              ...event.newData,
            };
          } else {
            return item;
          }
        });

        setDataTable([...dataLastUpdated]);
        dataGridRef.current?.instance.cancelEditData();
        notification(
          TYPE_MESSAGE.SUCCESS,
          STATUS_MESSAGE[`${ACTIONS.EDIT}_${response[0]!.status}`]
        );
      } else {
        notification(
          TYPE_MESSAGE.ERRORR,
          STATUS_MESSAGE[`${ACTIONS.EDIT}_${response[0]!.status}`]
        );
      }
    } catch (error) {
      notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
    } finally {
      setLoading(false);
    }
  };

  const handleRowRemoving = async (
    event: EventInfo<dxDataGrid<any, any>> & RowRemovingInfo<any, any>
  ) => {
    event.cancel = true;

    try {
      setLoading(true);
      const response = await qLCCClient.qLCCResidentJobsActions({
        ...new QLCCResidentJobsModel(),
        id: event.data.id,
        action_type: "DELETE",
        apartment_building_id: apartment_building_id
      } as QLCCResidentJobsModel);

      if (response[0].status === 0) {
        setDataTable([...dataTable.filter((item) => item.id !== event.key)]);
        notification(
          TYPE_MESSAGE.SUCCESS,
          STATUS_MESSAGE[`${ACTIONS.DELETE}_${response[0]!.status}`]
        );
      } else {
        notification(
          TYPE_MESSAGE.ERRORR,
          STATUS_MESSAGE[`${ACTIONS.DELETE}_${response[0]!.status}`]
        );
      }
    } catch (error) {
      notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
    } finally {
      setLoading(false);
    }
  };

  const handleClickAction = async (action: SysActionType) => {
    switch (action?.action_code?.toLocaleUpperCase()) {
      case ACTIONS.ADD:
        await onAddRow(dataGridRef, setAllowEdit);
        break;
      case ACTIONS.DELETE:
        await onDeleteRow(dataGridRef)
        break;
      case ACTIONS.EDIT:
        await onEditRow(dataGridRef, setAllowEdit);
        break;
      case ACTIONS.VIEW:
        await onViewRow(dataGridRef, setAllowEdit);
        break;

      default:
        break;
    }
  };

  return (
    <div className="container-fluid">
      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          marginTop: 15,
          marginBottom: 15,
          fontSize: 22,
          fontWeight: 550,
        }}
      >
        <DHSBreadcrumb location={location} />
      </div>

      <DHSToolbarRole id={" "} menu={menu} onClickAction={handleClickAction} />
      <DHSDataGridEdit
        data={dataTable}
        table_name={menu?.component}
        keyExpr="id"
        mode="popup"
        dataGridRef={dataGridRef}
        allowEdit={allowEdit}
        pageSize={menu?.page_size}
        isPagination={menu?.is_pagination}
        isMultiple={menu?.is_multiple}
        handleSelectionChanged={handleSelectionChanged}
        handleRowInserting={handleRowInserting}
        handleRowUpdating={handleRowUpdating}
        handleRowRemoving={handleRowRemoving}
        handlePageChange={() => { }}
      />

      <BlockUI blocking={loading} title={""}></BlockUI>
    </div>
  );
};

export default ResidentJobList;
