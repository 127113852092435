import React from "react";
import {
    TextField,
    InputLabel,
    Slide,
    Typography,
    Autocomplete,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
} from "@mui/material";
import {
    ModelProcessca01,
    CaVch01Client,
    CaVch01M,
    CaVch01D0,
    LstBank,
    CaVch01D1,
    CommonClient,
    ParaObjExchageRate,
    ParaObj,
    ParaObjRecNo,
    SysActionType,
    ParaObjCheck,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useDispatch, useSelector } from "react-redux";
import { ICustomer, IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../../actions/ApartmentBuilding.css";
import CTXDate from "../../../../components/CTX/DatePicker";
import ReceiptInvoiceList from "./receipt-invoice";
import CurrencyTextField from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import moment from "moment";
import notification from "../../../../common/notification/notification";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import DHSAutoCompleteWithDataGrid from "../../../../components/DHS/DHS_AutoCompleteCustomer/DHSAutoCompleteWithDataGrid";
import DataGrid from "devextreme-react/data-grid";
import { TransDateTime } from "../../../../components/utils/date.util";
import { saveChangeReceiptD0, saveChangeReceiptM } from "../../../../redux/actions/Service/ServiceBooking/ServiceBookingAction";
import { PushBankOptions } from "../../../../redux/actions/Option/OptionAction";
import { IServiceBookingReceipt } from "../../../../redux/reducer/ServiceBookingReduder/ServiceBookingReceiptReducer";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";
interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: ACTIONS | string;
    payload?: any;
    filter?: IFilter;
    onSubmit?: (data: any, action: any) => void;
    onAddSuccess?: (data_success?: CaVch01M) => void;
    onEditSuccess?: (data_success?: CaVch01M) => void;
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});
interface IAction {
    type: ACTIONS | string;
    open: boolean;
    payload: any;
}

const defaultAction: IAction = {
    type: ACTIONS.EMPTY,
    payload: null,
    open: false,
};

interface IFilter {
    apartment_building_id?: string | null,
    block_id?: string | null,
    floor_id?: string | null,
    apartment_id?: string | null
}


var appSession: AppSession;
const status_message: string[] = ['success', 'error']

const ServiceBookingReceipt: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const dispatch = useDispatch();
    const optionState = useSelector((state: IReducer) => state.OptionsReducer)
    const [_id, setId] = React.useState<string>();
    const [maKH, setMaKh] = React.useState<string>();
    const caVch01Client = new CaVch01Client(appSession, BASE_URL_API);
    const commonClient = new CommonClient(appSession, BASE_URL_API);
    const dataMaster = useSelector((state: IReducer) => state.ServiceBookingMasterReducer);


    // const [setData] = React.useState<ModelProcessca01>({} as ModelProcessca01);
    const data = useSelector((state: IReducer) => state.ServiceBookingReceiptReducer);
    const [data01D0, setData01D0] = React.useState<CaVch01D0[]>([]);
    const [data01D1, setData01D1] = React.useState<CaVch01D1[]>([]);
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const menu = useSelector((state: IReducer) => state.RoleReducer?.listMenu.find(x => x.code_name === "CA1"));

    const [bankName, setBankName] = React.useState<string>();
    const [bankCode, setBankCode] = React.useState<string>();

    const [isExpandReceiptAction, setIsExpandReceiptAction] = React.useState<boolean>(true);
    const [isCalledReceiptAction, setIsCalledReceiptAction] = React.useState<boolean>(true);
    const [isExpandReceiptDAction, setIsExpandReceiptDAction] = React.useState<boolean>(true);
    const [isCalledMember, setIsCalledCaVchD] = React.useState<boolean>(false);

    const [reload, setReload] = React.useState(false);
    const [fullScreen, setFullscreen] = React.useState<boolean>(false);

    const [errors, setErrors] = React.useState<any[]>();
    const [actPrint, setActPrint] = React.useState<IAction>(defaultAction);

    // 

    const [bankOptions, setBankOptions] = React.useState<any[]>([]);
    const [customerOptions, setCustomerOptions] = React.useState<ICustomer[]>([]);

    // 

    const [exchangeOptions, setExchangeOptions] = React.useState<any[]>([]);
    const [currencyOptions, setCurrencyOptions] = React.useState<any[]>([]);

    // 

    const dataGridRef01D1 = React.useRef<DataGrid>(null);
    const dataGridRef01D0 = React.useRef<DataGrid>(null);
    var dataGrid01D0: DataGrid | null = null;

    React.useEffect(() => {
        dataGrid01D0 = dataGridRef01D0.current;
        return () => {
            const tempData01D0 = dataGrid01D0?.instance.getVisibleRows().map<CaVch01D0>(x => x.data);
            if (tempData01D0) {
                dispatch(saveChangeReceiptD0({
                    arrayCt: tempData01D0
                } as IServiceBookingReceipt))
            }
            console.log(dataGrid01D0, "huyhuy")
        }
    }, [])

    React.useEffect(() => {
        if (!props.open) {
            setMaKh(undefined);

        }
    }, [props.open])

    React.useEffect(() => {
        if (dataMaster.id_customer) {
            dispatch(saveChangeReceiptM({
                modelM: {
                    idcustomer: dataMaster.id_customer
                }
            } as IServiceBookingReceipt))
        }
    }, [dataMaster.id_customer])

    React.useEffect(() => {
        // if (dataModelM) {
        //     setData(dataModelM)
        // }
        console.log(data.modelM, "15")
    }, [data])

    //#region handleGetOptions

    React.useEffect(() => {
        console.log(optionState, "optionState")
        if (optionState?.paymentTermOptions.length > 0) {
            dispatch(saveChangeReceiptM({
                modelM: {
                    ma_httt: optionState.paymentTermOptions[0].ma_httt
                },

            } as IServiceBookingReceipt))
        }
        if (optionState?.bankOptions.length > 0) {

        }
        if (optionState?.currencyOptions.length > 0) {

            dispatch(saveChangeReceiptM({
                modelM: {
                    mA_NT: optionState.currencyOptions[optionState.currencyOptions.length - 1].ma_nt
                },

            } as IServiceBookingReceipt))
        }
        if (optionState?.exchangeOptions.length > 0) {

            dispatch(saveChangeReceiptM({
                modelM: {
                    mA_GD: optionState.exchangeOptions[0].ma_gd
                },

            } as IServiceBookingReceipt))
        }
    }, [optionState])

    React.useEffect(() => {
        if (isCalledReceiptAction) {
        }

    }, [props.action]);

    React.useEffect(() => {
        if (data?.modelM?.ma_httt === 'CK' && bankOptions.length <= 0) {
            caVch01Client.lstBankGet({
                ...new LstBank(),
                ma_cty: SessionLogin.ma_cty,

            } as LstBank)
                .then(res => {
                    // setBankOptions(res)
                    dispatch(PushBankOptions(res))
                })
        }
    }, [data?.modelM?.ma_httt])

    // React.useEffect(() => {
    //     dispatch(saveChangeReceiptM(data.modelM))
    // }, [data?.modelM])

    //#endregion

    //#region hanldeFilter
    React.useEffect(() => {
        if (props.filter) {

            // const body = {
            //     ma_cty: SessionLogin.ma_cty,
            //     tablelist: "dm_kh"
            // }
            // httpPost<string>('api/Common/LoadFilterList', body)
            //     .then(res => {

            //         const customers: ICustomer[] = JSON.parse(res.data);
            //         setCustomerOptions(customers)
            //         setLoading(false)

            //     });


        }
    }, [props.filter])


    //#endregion

    //#region handleGetDetailData 

    React.useEffect(() => {


        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            setLoading(true);
            caVch01Client.qLCCCaVch01MGet({
                ...new CaVch01M(),
                mA_CTY: SessionLogin.ma_cty,
                mA_CT: menu?.code_name,
                stT_REC: props.itemSelected.stT_REC
            } as CaVch01M).then(res => {

                // setData({
                //     modelM: res.lstModelMs![0],
                //     arrayCt: res.arrayCt,
                //     arrayCt1: res.arrayCt1
                // } as ModelProcessca01)


                setLoading(false);

            }).catch(error => {
                notification(
                    TYPE_MESSAGE.WARNING,
                    STATUS_MESSAGE[`SERVER_ERROR`]
                );

                setLoading(false)
                // props.onClose();
            })

            setId(props.itemSelected);

        } else if (props.action === ACTIONS.ADD) {
            // setData(new QLCCFloorModel());
            const ngay_ct = new Date();

            commonClient.get_VoucherNo({
                ...new ParaObj(),
                ma_cty: SessionLogin.ma_cty,
                ma_ct: menu?.code_name,
                ngay_ct
            } as ParaObj)
                .then(res => {
                    if (!res) {
                        // props.onClose();
                        notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                        return;
                    }
                    setLoading(false);
                    console.log(data.modelM?.mA_GD, exchangeOptions[0]?.ma_gd, "Xin chào");
                    dispatch(saveChangeReceiptM({
                        modelM: {
                            apartment_building_id: props?.filter?.apartment_building_id,
                            sO_CT: res,
                            mA_CT: menu?.code_name,
                            mA_CTY: SessionLogin.ma_cty,
                            ngaY_CT: ngay_ct,
                            ngaY_LCT: new Date(),
                            cuser: SessionLogin.userName,
                            luser: SessionLogin.userName,
                            // mA_GD: !data.modelM?.mA_GD ?exchangeOptions[0]?.ma_gd :  data.modelM?.mA_GD ,
                            tY_GIA: 1,
                            // mA_NT: !data.modelM?.mA_NT ? data.modelM?.mA_NT : currencyOptions[currencyOptions?.length - 1]?.ma_nt,
                            // ma_httt: !data.modelM?.ma_httt ? paymentTermOptions[0]?.ma_httt :  data.modelM?.ma_httt
                        },

                    } as IServiceBookingReceipt))
                    // setData(pre => ({
                    //     ...pre,
                    //     modelM: {
                    //         ...pre.modelM,
                    //         apartment_building_id: props?.filter?.apartment_building_id,
                    //         sO_CT: res,
                    //         mA_CT: menu?.code_name,
                    //         mA_CTY: SessionLogin.ma_cty,
                    //         ngaY_CT: ngay_ct,
                    //         ngaY_LCT: new Date(),
                    //         cuser: SessionLogin.userName,
                    //         luser: SessionLogin.userName,
                    //         mA_GD: pre.modelM?.mA_GD ? pre.modelM?.mA_GD : exchangeOptions[0]?.ma_gd,
                    //         tY_GIA: 1,
                    //         mA_NT: pre.modelM?.mA_NT ? pre.modelM?.mA_NT : currencyOptions[currencyOptions?.length - 1]?.ma_nt,
                    //         ma_httt: pre.modelM?.ma_httt ? pre.modelM?.ma_httt : paymentTermOptions[0]?.ma_httt
                    //     },
                    // } as ModelProcessca01));

                    commonClient.get_RecNo({
                        ...new ParaObjRecNo(),
                        ma_ct: menu?.code_name,
                        ma_cty: SessionLogin.ma_cty,
                    } as ParaObjRecNo)
                        .then(res => {
                            if (!res) {
                                // props.onClose();
                                notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                                return;
                            }
                            // setData(pre => ({
                            //     ...pre,
                            //     modelM: {
                            //         ...pre.modelM,
                            //         stT_REC: res
                            //     },
                            // } as ModelProcessca01));
                            dispatch(saveChangeReceiptM({
                                modelM: {
                                    stT_REC: res
                                },

                            } as IServiceBookingReceipt))
                        }).catch(error => {

                            // props.onClose();
                            notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                            return;

                        });

                    if (props.itemSelected?.invoice) {
                        const SO5 = props.itemSelected?.invoice;
                        // setData(pre => ({
                        //     ...pre,
                        //     modelM: {
                        //         ...pre.modelM,
                        //         idcustomer: SO5?.iDcustomer,
                        //         mA_KH: SO5?.iDcustomer,
                        //     },
                        // } as ModelProcessca01));
                        dispatch(saveChangeReceiptM({
                            modelM: {
                                idcustomer: SO5?.iDcustomer,
                                mA_KH: SO5?.iDcustomer,
                            },

                        } as IServiceBookingReceipt))
                        setMaKh(SO5?.iDcustomer)
                    }
                }).catch(error => {

                    // props.onClose();
                    notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                    return;

                });




            setId(undefined)


        };

    }, [props.action, props.itemSelected]);

    //#endregion

    //#region handleVoucherNo
    const createVoucherNo = () => {

        commonClient.get_VoucherNo({
            ...new ParaObj(),
            ma_cty: SessionLogin.ma_cty,
            ma_ct: menu?.code_name,
            ngay_ct: data?.modelM?.ngaY_LCT
        } as ParaObj)
            .then(res => {
                // setData(pre => ({
                //     ...pre,
                //     modelM: {
                //         ...pre.modelM,
                //         sO_CT: res,
                //     },
                // } as ModelProcessca01));
                dispatch(saveChangeReceiptM({
                    modelM: {
                        sO_CT: res,
                    },

                } as IServiceBookingReceipt))
            });
    }

    //#endregion

    //#region handleError

    React.useEffect(() => {
        if (!data?.modelM?.idcustomer) {

            // Thêm lỗi khi chưa chọn hóa đơn
            const errorTemp = errors ? [...errors] : [];
            if (errorTemp.length > 0) {
                errorTemp.forEach(error => {
                    if ((error.errors as Array<any>).findIndex((x: any) => x.code === 36) === -1) {
                        errorTemp.push({
                            name: 'modelM',
                            errors: [
                                {
                                    code: 36,
                                    status: 1,
                                    message: 'Bạn chưa chọn khách hàng'
                                }
                            ]
                        });
                    }
                })
            } else {
                errorTemp.push({
                    name: 'modelM',
                    errors: [
                        {
                            code: 36,
                            status: 1,
                            message: 'Bạn chưa chọn khách hàng'
                        }
                    ]
                });
            }



            setErrors(errorTemp)

        }
        else if (data?.modelM?.idcustomer) {
            // Xóa lỗi khi đã có hóa đơn
            const errorTemp = errors ? [...errors] : [];

            errorTemp.forEach((error, idxParent) => {
                if (error.name === 'modelM') {
                    const idx = error.errors.findIndex((x: any) => x.code === 36);

                    if (idx > -1) {
                        errorTemp[idxParent]
                            .errors
                            .splice(idx, 1);
                    }
                }
            });

            setErrors(errorTemp)
        }

    }, [data?.modelM?.idcustomer])

    //#endregion

    //#region HandleSubmit

    const handleSubmit =
        // React.useCallback(
        (data01D1Saved: CaVch01D1[], data01D0Saved: CaVch01D0[]): void => {
            // setLoading(true);



            // if (errors && errors.length > 0) {
            //     let is_error = false;
            //     [...errors].forEach(item => {
            //         if (item.errors.length > 0) {
            //             item.errors.forEach((error: any) => {
            //                 notification(status_message[error.status], error.message)
            //             })
            //             is_error = true;
            //             return;
            //         }
            //     })

            //     if (is_error) {

            //         return;
            //     }

            // }


            const totalTTMoney = [...data01D0Saved].reduce((acc, invoice) => {
                return acc + invoice.pS_CO!;
            }, 0);

            switch (props.action) {
                case ACTIONS.ADD:

                    data01D0Saved?.map(item => {

                        item.stT_REC = data?.modelM?.stT_REC;
                        item.mA_CTY = SessionLogin.ma_cty;
                        // item.tien_tt = Number(itemChange.tien_tt);

                    })

                    data01D1Saved?.map(item => {

                        item.stt_rec = data?.modelM?.stT_REC;
                        item.ma_cty = SessionLogin.ma_cty;
                        // item.ngay_hd = moment(item.ngay_hd!).toDate();
                        // item.ngay_ct = moment(item.ngay_ct).toDate();

                    });




                    caVch01Client.insertCommand({
                        ...new ModelProcessca01(),
                        ...data,
                        modelM: {
                            ...data.modelM,
                            apartment_building_id: props.filter?.apartment_building_id,
                            ngaY_CT: TransDateTime(data.modelM?.ngaY_CT!),
                            ngaY_LCT: TransDateTime(data.modelM?.ngaY_LCT!),
                            t_TIEN: totalTTMoney,
                            t_TIEN_NT: totalTTMoney

                        } as CaVch01M,
                        arrayCt: data01D0Saved,
                        arrayCt1: data01D1Saved
                    } as ModelProcessca01)
                        .then(res => {
                            if (res.status === 0) {
                                // setId(res.ref_id)
                                setLoading(false);

                                props.onAddSuccess && props.onAddSuccess({
                                    ...data.modelM,
                                    apartment_building_id: props.filter?.apartment_building_id,
                                    ngaY_CT: TransDateTime(data.modelM?.ngaY_CT!),
                                    ngaY_LCT: TransDateTime(data.modelM?.ngaY_LCT!),
                                    t_TIEN: totalTTMoney,
                                    t_TIEN_NT: totalTTMoney

                                } as CaVch01M);
                                // props.onClose();
                                notification(status_message[res.status!], STATUS_MESSAGE[`${props.action}_${res.status}`]);

                                // setData(new ModelProcessca01())
                                dispatch(saveChangeReceiptM({} as IServiceBookingReceipt))
                            } else if (res.status === -1) {
                                notification(status_message[1], STATUS_MESSAGE["SAME_NUMBER_VOUCHER_1"]);
                                // Tạo lại số chứng từ
                                createVoucherNo();
                                setLoading(false);

                            }

                            else {
                                notification(status_message[res.status!], STATUS_MESSAGE[`${props.action}_${res.status}`]);

                                setLoading(false);
                            }
                        }).catch((error: any) => {
                            notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                            setLoading(false);
                        })
                    break;
                case ACTIONS.EDIT:
                    // data01D1Saved?.map(item => {


                    // item.ngay_hd = moment(item.ngay_hd!).toDate();
                    // item.ngay_ct = moment(item.ngay_ct!).toDate();
                    // item.tien_tt = Number(itemChange.tien_tt);

                    // });


                    caVch01Client.updateCommand({
                        ...new ModelProcessca01(),
                        ...data,
                        modelM: {
                            ...data.modelM,
                            t_TIEN: totalTTMoney,
                            t_TIEN_NT: totalTTMoney,
                            ngaY_CT: TransDateTime(data.modelM?.ngaY_CT!),
                            ngaY_LCT: TransDateTime(data.modelM?.ngaY_LCT!),
                            apartment_building_id: props.filter?.apartment_building_id

                        } as CaVch01M,
                        arrayCt: data01D0Saved,
                        arrayCt1: data01D1Saved
                    } as ModelProcessca01)
                        .then(res => {
                            notification(status_message[res.status!], STATUS_MESSAGE[`${props.action}_${res.status}`]);

                            if (res.status === 0) {
                                // setId(res.ref_id)
                                setLoading(false);
                                props.onEditSuccess && props.onEditSuccess({
                                    ...data.modelM,
                                    t_TIEN: totalTTMoney,
                                    t_TIEN_NT: totalTTMoney,
                                    ngaY_CT: TransDateTime(data.modelM?.ngaY_CT!),
                                    ngaY_LCT: TransDateTime(data.modelM?.ngaY_LCT!),
                                    apartment_building_id: props.filter?.apartment_building_id

                                } as CaVch01M);
                                // props.onClose();
                                // setData(new ModelProcessca01())
                                dispatch(saveChangeReceiptM({} as IServiceBookingReceipt))
                            } else {
                                notification('error', res.message);
                                setLoading(false);
                            }
                        }).catch((error: any) => {
                            notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                            setLoading(false);
                        })
                    break;
                default:
                    setLoading(false);
            }

            // setOperations([])
            // setIsPost(false)
            // setIsSavedD0("NOT-EXISTS-D0")
            // setIsSavedD1("NOT-EXISTS-D1")



        }
    // , []);

    //#endregion


    // React.useEffect(() => {


    // commonClient.get_VoucherNo({
    //     ...new ParaObj(),
    //     ma_cty: SessionLogin.ma_cty,
    //     ma_ct: 'CA1',
    //     ngay_ct: data?.modelM?.ngaY_CT
    // } as ParaObj)
    //     .then(res => {
    //         setData(pre => ({
    //             ...pre,
    //             modelM: {
    //                 ...pre.modelM,
    //                 sO_CT: res,
    //             },
    //         } as ModelProcessca01));
    //     })

    // }, [data?.modelM?.ngaY_CT])



    //#region handleChange

    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        const date = moment(value).toDate();
        if (date instanceof Date && !isNaN(date.valueOf())) {
            // setData(pre => ({
            //     ...pre, modelM: {
            //         ...pre.modelM,
            //         [name]: new Date(value)
            //     }
            // } as ModelProcessca01));
            dispatch(saveChangeReceiptM({
                modelM: {
                    ...data.modelM,
                    [name]: new Date(value)
                } as CaVch01M,

            } as IServiceBookingReceipt))
        }
        // setData({ ...data, [name]: new Date(value) } as SoVch05M);
    }

    const handleChangeInput = (e: any) => {
        const { name, value, apartment_id } = e.target;



        // if ((Array.isArray(value) && Array.isArray(name))) {
        //     const tempData = { ...data };
        //     name.forEach((name_, idx) => {
        //         Object.assign(tempData, {
        //             ...tempData,
        //             [name_]: value[idx]
        //         })
        //     })

        //     setData(tempData as SoVch05M)

        // }
        // else 
        dispatch(saveChangeReceiptM({
            modelM: {
                ...data.modelM,
                [name]: value
            } as CaVch01M,

        } as IServiceBookingReceipt))
        // setData(pre => ({
        //     ...pre, modelM: {
        //         ...pre.modelM,
        //         [name]: value
        //     }
        // } as ModelProcessca01));

        // Nếu là khách hàng thì set thêm apartment id

        // setData(pre => ({ ...pre, apartment_id } as SoVch05M))

    };



    const handleChangeCurrentInput = (
        value: string | undefined, name?: string | undefined, values?: CurrencyInputOnChangeValues | undefined) => {

        // setData(pre => ({
        //     ...pre, modelM: {
        //         ...pre.modelM,
        //         [name!]: value
        //     }
        // } as ModelProcessca01));
        dispatch(saveChangeReceiptM({
            modelM: {
                ...data.modelM,
                [name!]: value
            } as CaVch01M,

        } as IServiceBookingReceipt))
    }

    // Xử lý Print
    const handlePrintAction = () => {
        setActPrint({
            ...actPrint,
            open: true,
            type: ACTIONS.EMPTY,
            payload: data?.modelM // pass the data here
        })
    }

    //#endregion


    //#region handleClickAction
    const handleClickAction = async (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                if (!data?.modelM?.idcustomer) {
                    notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["CUSTOMER_NULL"])
                    return;
                }

                const result = await commonClient.check_VoucherNo({
                    ...new ParaObjCheck(),
                    ma_ct: menu?.code_name,
                    ngay_ct: TransDateTime(data?.modelM?.ngaY_LCT!),
                    ma_cty: SessionLogin.ma_cty,
                    stt_rec: data?.modelM?.stT_REC,
                    voucherno: data?.modelM?.sO_CT
                } as ParaObjCheck);

                if (!result) {

                    // 
                    var data01D1Saved: CaVch01D1[] = [];
                    var data01D0Saved: CaVch01D0[] = [];
                    if (dataGridRef01D1.current) {
                        await dataGridRef01D1.current.instance.saveEditData();
                        await dataGridRef01D1.current!.instance.refresh();
                        data01D1Saved = dataGridRef01D1.current!.instance.getDataSource().items();

                        // setData01D1(data01D1Saved);
                        // Nếu như 01D0 không focus

                    }
                    else {
                        data01D1Saved = [...data01D1];


                    }

                    if (dataGridRef01D0.current) {
                        await dataGridRef01D0.current.instance.saveEditData();
                        await dataGridRef01D0.current!.instance.refresh();
                        data01D0Saved = dataGridRef01D0.current!.instance.getDataSource().items();



                        // setData01D1(data01D1Saved);

                        // setData(pre => ({
                        //     ...pre, modelM: {
                        //         ...pre.modelM,
                        //         t_TIEN_NT: totalTTMoney,
                        //         t_TIEN: totalTTMoney
                        //     }
                        // } as ModelProcessca01))
                    } else {
                        data01D0Saved = [...data01D0];



                    }

                    //Xữ lý lại trước khi lưu

                    data01D1Saved.map(dt01D1 => {
                        dt01D1.tt = dt01D1.ty_gia! * dt01D1.tt_nt!;
                    });

                    const totalTTMoney = data01D1Saved.reduce((acc: any, invoice: any) => {
                        return acc + invoice.tt_nt!;
                    }, 0);


                    var index01D0 = -1;
                    index01D0 = data01D0Saved.findIndex(x => {
                        if (x.ma_ct_hd && data01D1Saved[0]?.ma_ct && x.ma_ct_hd === data01D1Saved[0]?.ma_ct) {
                            return true;
                        }
                        return false;
                    });

                    if (index01D0 > -1) {
                        data01D0Saved[index01D0].pS_CO_NT = totalTTMoney;
                        data01D0Saved[index01D0].pS_CO = totalTTMoney;
                    }

                    data01D0Saved?.map(dt01D0 => {
                        dt01D0.pS_CO = dt01D0.pS_CO_NT!;
                        dt01D0.pS_NO = 0;
                        dt01D0.pS_NO_NT = 0;
                    });


                    handleSubmit(data01D1Saved, data01D0Saved);

                }
                else {
                    notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SAME_NUMBER_VOUCHER_1"]);
                    // Tạo lại số chứng từ
                    createVoucherNo();
                }



                break;
            case ACTIONS.PRINT:
                handlePrintAction();
                break;

            case ACTIONS.CLOSE:
                // props.onClose();
                // setData(new ModelProcessca01())
                dispatch(saveChangeReceiptM({} as IServiceBookingReceipt))

                break;
        }
    }


    //#endregion


    return (
        <div>



            <Accordion className="Asset-control" color="#1565C0" expanded={isExpandReceiptAction} onChange={() => { setIsCalledReceiptAction(true); setIsExpandReceiptAction(pre => !pre) }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ backgroundColor: "#e0f2fe" }}
                >
                    <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Thông tin chung</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="row gutter-1">
                        <div className="col-xl-4">

                            <div className="card">

                                <div className="card-body pt-2">
                                    <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                        Thông tin chung
                                    </div>
                                    {/* Loại thu */}
                                    <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                        <Grid item xs={12} md={4}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Loại thu</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <DHSAutoCompleteWithDataSource
                                                id="pay-type-select"
                                                className="cc-input"
                                                dataSource={optionState.exchangeOptions}
                                                value={
                                                    data.modelM?.mA_GD
                                                }
                                                displayMember='mo_ta'
                                                valueMember="ma_gd"
                                                onValueChanged={(e, newValue: any) => {
                                                    handleChangeInput({
                                                        target: {
                                                            name: 'mA_GD',
                                                            value: newValue?.ma_gd ?? null
                                                        }
                                                    })
                                                }}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />
                                            {/* <Autocomplete
                                                id="pay-type-select"
                                                sx={{ width: "100%" }}
                                                // defaultValue={exchangeOptions[0]}
                                                options={optionState.exchangeOptions}
                                                autoHighlight
                                                getOptionLabel={(option: any) => option?.mo_ta}
                                                // title={}
                                                value={
                                                    data.modelM?.mA_GD
                                                        ? optionState.exchangeOptions.find(
                                                            (item: any) => item?.ma_gd === data.modelM?.mA_GD
                                                        )
                                                        : null
                                                }
                                                onChange={(e, newValue: any) => {

                                                    handleChangeInput({
                                                        target: {
                                                            name: 'mA_GD',
                                                            value: newValue?.ma_gd ?? null
                                                        }
                                                    })
                                                }}

                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className="cc-input"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password", // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                                disabled={props.action === ACTIONS.VIEW}
                                            /> */}

                                        </Grid>
                                    </Grid>
                                    {/* Khách hàng */}

                                    <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                        <Grid item xs={12} md={4}>
                                            <InputLabel required htmlFor="resident-select" sx={{ fontSize: 14, color: '#333' }}>Khách hàng</InputLabel>
                                            {/* <DHSAutoCompleteResidentAntd
                                                            name="idcustomer"
                                                            onChange={(value, option) => {
                                                                
                                                            }}
                                                            value={data?.modelM?.idcustomer ?? null}
                                                            filter={props.payload} 
                                                            placeholder="Chọn khách hàng"
                                                            disabled={props.action === ACTIONS.VIEW}      
                                                        /> */}
                                        </Grid>
                                        <Grid item xs={12} md={8}>

                                            <DHSAutoCompleteWithDataGrid
                                                id="customer-select"
                                                value={data?.modelM?.idcustomer ?? null}
                                                table_name="DHSGridCustomerSearch"
                                                // placeholder="Chọn khách hàng"
                                                // filter={props.payload}
                                                name="idcustomer"
                                                valueExpr={"idcustomer"}
                                                options={optionState.customerOptions}
                                                dataGridOnSelectionChanged={(e, newValue: any) => {
                                                    handleChangeInput({
                                                        target: {
                                                            name: "idcustomer",
                                                            value: newValue?.idcustomer ?? null,
                                                        },
                                                    });

                                                    setMaKh(newValue?.idcustomer);


                                                    // setData(pre => ({
                                                    //     ...pre, modelM: {
                                                    //         ...pre.modelM,
                                                    //         teN_KH: newValue?.ten_kh ?? null,
                                                    //         mA_KH: newValue?.ma_kh ?? null
                                                    //     }
                                                    // } as ModelProcessca01));
                                                    dispatch(saveChangeReceiptM({
                                                        modelM: {
                                                            teN_KH: newValue?.ten_kh ?? null,
                                                            mA_KH: newValue?.ma_kh ?? null
                                                        } as CaVch01M,

                                                    } as IServiceBookingReceipt))
                                                }}
                                                syncDataGridSelection={function (e): void {
                                                    // setData(pre => ({ ...pre, modelM: { ...pre.modelM, idcustomer: e.value } } as ModelProcessca01));
                                                    dispatch(saveChangeReceiptM({
                                                        modelM: {
                                                            idcustomer: e.value
                                                        } as CaVch01M,

                                                    } as IServiceBookingReceipt))
                                                    setMaKh(e.value);

                                                    // if (!maKH) {
                                                    //     setLoading(true)
                                                    //     const body = {
                                                    //         ma_cty: SessionLogin.ma_cty,
                                                    //         tablelist: "dm_ncc"
                                                    //     }
                                                    //     httpPost<string>('api/Common/LoadFilterList', body)
                                                    //         .then(res => {

                                                    //             const customers: ICustomer[] = JSON.parse(res.data);
                                                    //             setCustomerOptions(customers)
                                                    //             setLoading(false)

                                                    //         });


                                                    // }
                                                }}
                                                gridBoxDisplayExpr={function (item: any): string {
                                                    return item && `${item.ma_kh} - ${item.ten_kh}`;
                                                }}

                                                disabled={props.action === ACTIONS.VIEW}
                                            />
                                            {/* <DHSAutoCompleteWithDataGrid
                                                        id="resident-select"
                                                        value={data?.modelM?.idcustomer ?? null}
                                                        table_name="DHSGridResidentSearch"
                                                        placeholder="Chọn khách hàng"
                                                        // filter={props.payload}
                                                        name="idcustomer"
                                                        valueExpr={"id"}
                                                        options={residentOptions}
                                                        dataGridOnSelectionChanged={(e, newValue: any) => {
                                                            handleChangeInput({
                                                                target: {
                                                                    name: "idcustomer",
                                                                    value: newValue?.id ?? null,
                                                                },
                                                            });

                                                            setMaKh(newValue?.apartment_id);


                                                            setData(pre => ({
                                                                ...pre, modelM: {
                                                                    ...pre.modelM,
                                                                    "teN_KH": newValue?.full_name ?? null
                                                                }
                                                            } as ModelProcessca01));
                                                        }}
                                                        syncDataGridSelection={function (e): void {
                                                            setData(pre => ({
                                                                ...pre, modelM: {
                                                                    ...pre.modelM,
                                                                    idcustomer: e.value
                                                                }
                                                            } as ModelProcessca01));

                                                            if (!maKH) {
                                                                qLCClient.qLCCResidentsGet(
                                                                    {
                                                                        ...new QLCCResidentsModel(),
                                                                        ...props?.filter, // Chỉ lấy tên không lấy apartment_name
                                                                        id: e.value,
                                                                        action_type: 'AUTOCOMPLETE_SEARCH'
                                                                    } as QLCCResidentsModel
                                                                ).then(res => {
                                                                    setMaKh(res[0].apartment_id)
                                                                    // setInputValue(value);


                                                                    console.log(res)
                                                                });
                                                            }
                                                        }}
                                                        gridBoxDisplayExpr={function (item: any): string {
                                                            return item && `${item?.full_name} - ${item?.apartment_name ?? "Chưa đăng kí"}`;
                                                        }}

                                                        disabled={props.action === ACTIONS.VIEW}
                                                    /> */}



                                        </Grid>
                                    </Grid>



                                    {/* Địa chỉ */}

                                    <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                        <Grid item xs={12} md={4}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Địa chỉ</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                title={data?.modelM?.diA_CHI}
                                                // placeholder="Địa chỉ hiện tại"
                                                name="diA_CHI"
                                                onChange={handleChangeInput}
                                                value={data?.modelM?.diA_CHI}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* Người nộp tiền */}

                                    <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                        <Grid item xs={12} md={4}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Người nộp tiền</InputLabel>

                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                name="nguoI_GD"
                                                title={data?.modelM?.nguoI_GD}

                                                // placeholder="Nguyễn Văn A"
                                                onChange={handleChangeInput}
                                                value={data?.modelM?.nguoI_GD}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />

                                        </Grid>
                                    </Grid>
                                    {/* Diễn giải */}

                                    <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                        <Grid item xs={12} md={4}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Diễn giải</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                name="dieN_GIAI"
                                                title={data?.modelM?.dieN_GIAI}

                                                // placeholder="Thu phí chung cư tháng 5/2023"
                                                onChange={handleChangeInput}
                                                value={data?.modelM?.dieN_GIAI}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />

                                        </Grid>
                                    </Grid>
                                    {/* Hình thức thanh toán */}
                                    <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                        <Grid item xs={12} md={4}>
                                            <InputLabel title="Hình thức thanh toán" sx={{ fontSize: 14, color: '#333' }}>Hình thức thanh toán</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <DHSAutoCompleteWithDataSource
                                                id="pay-type-select"
                                                className="cc-input"
                                                dataSource={optionState?.paymentTermOptions}
                                                value={
                                                    data?.modelM?.ma_httt
                                                }
                                                displayMember='ten_httt'
                                                valueMember="ma_httt"
                                                onValueChanged={(e, newValue: any) => {
                                                    handleChangeInput({
                                                        target: {
                                                            name: 'ma_httt',
                                                            value: newValue?.ma_httt ?? null
                                                        }
                                                    });
                                                    if (newValue?.ma_httt === 'TM') {
                                                        dispatch(saveChangeReceiptM({
                                                            modelM: {
                                                                mA_NGH: undefined,
                                                            } as CaVch01M,

                                                        } as IServiceBookingReceipt))
                                                    }
                                                }}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />
                                            {/* <Autocomplete
                                                id="pay-type-select"
                                                sx={{ width: "100%" }}
                                                // defaultValue={paymentTermOptions[0]}
                                                options={optionState?.paymentTermOptions}
                                                autoHighlight
                                                getOptionLabel={(option: any) => option?.ten_httt}
                                                value={
                                                    data?.modelM?.ma_httt
                                                        ? optionState?.paymentTermOptions.find(
                                                            (item: any) => item?.ma_httt === data?.modelM?.ma_httt
                                                        )
                                                        : null
                                                }
                                                onChange={(e, newValue: any) => {

                                                    handleChangeInput({
                                                        target: {
                                                            name: 'ma_httt',
                                                            value: newValue?.ma_httt ?? null
                                                        }
                                                    });
                                                    // setPaymentMethodCode(newValue?.ma_httt);

                                                    if (newValue?.ma_httt === 'TM') {
                                                        // setData(pre => ({
                                                        //     ...pre, modelM: {
                                                        //         ...pre.modelM,
                                                        //         mA_NGH: undefined,

                                                        //     }
                                                        // } as ModelProcessca01));
                                                        dispatch(saveChangeReceiptM({
                                                            modelM: {
                                                                mA_NGH: undefined,
                                                            } as CaVch01M,

                                                        } as IServiceBookingReceipt))
                                                    }
                                                }}

                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className="cc-input"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password", // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                                disabled={props.action === ACTIONS.VIEW}
                                            /> */}

                                        </Grid>
                                    </Grid>

                                    {/* Tài khoản nợ */}

                                    {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tài khoản nợ</InputLabel>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            name="address"
                                            onChange={handleChangeInput}
                                            value={null}
                                            disabled={props.action === ACTIONS.VIEW}
                                        />

                                    </Box> */}
                                    {/*Tiền */}
                                    {/* 
                                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tiền</InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100%", textAlign: "right" }}
                                            allowDecimals
                                            decimalsLimit={2}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            decimalScale={0}
                                            placeholder="Tổng tiền"
                                            intlConfig={{ locale: 'vi-VN', currency: 'VND' }}
                                            name="t_TT"
                                            onValueChange={handleChangeCurrentInput}
                                            value={data.modelM?.t_TIEN_NT}
                                            disabled={true}
                                        />

                                    </Box> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">

                            <div className="card mb-2">

                                <div className="card-body pt-2">
                                    <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                        Thông tin chứng từ
                                    </div>
                                    {/* Ngày hạch toán */}
                                    <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                        <Grid item xs={12} md={4}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày hạch toán</InputLabel>

                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <CTXDate
                                                className="cc-input"
                                                style={{ width: '100%', padding: '0 5px !important' }}
                                                name="ngaY_CT"
                                                onChange={handleChangeDate}
                                                value={data?.modelM?.ngaY_CT}
                                                // title={data?.modelM?.ngaY_CT}

                                                // disabled={props.action === ACTIONS.VIEW}
                                                disabled={true}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* Số chứng từ */}

                                    <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                        <Grid item xs={12} md={4}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số chứng từ</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                inputProps={{
                                                    style: {
                                                        textAlign: 'left'
                                                    }
                                                }}
                                                title={data?.modelM?.sO_CT}
                                                type="text"
                                                name="sO_CT"
                                                onChange={handleChangeInput}
                                                value={data?.modelM?.sO_CT}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />
                                        </Grid>
                                    </Grid>
                                    {/* Ngày chứng từ */}
                                    <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                        <Grid item xs={12} md={4}>
                                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày chứng từ</InputLabel>

                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <CTXDate
                                                className="cc-input"
                                                style={{ width: '100%', padding: '0 5px !important' }}
                                                name="ngaY_LCT"
                                                onChange={(e) => {
                                                    handleChangeDate(e);

                                                    commonClient.get_VoucherNo({
                                                        ...new ParaObj(),
                                                        ma_cty: SessionLogin.ma_cty,
                                                        ma_ct: 'CA2',
                                                        ngay_ct: e?.value
                                                    } as ParaObj)
                                                        .then(res => {
                                                            // setData(pre => ({
                                                            //     ...pre,
                                                            //     modelM: {
                                                            //         ...pre.modelM,
                                                            //         sO_CT: res,
                                                            //     },
                                                            // } as ModelProcessca01));
                                                            dispatch(saveChangeReceiptM({
                                                                modelM: {
                                                                    sO_CT: res,
                                                                } as CaVch01M,

                                                            } as IServiceBookingReceipt))
                                                        })
                                                }}
                                                value={data.modelM?.ngaY_LCT}
                                                disabled={props.action === ACTIONS.VIEW}
                                            // disabled={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>

                            <div className="card mb-2">

                                <div className="card-body pt-2">
                                    <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                        Thông tin tiền tệ
                                    </div>
                                    <div className="d-flex" style={{ gap: 5 }}>
                                        {/* Loại tiền */}
                                        <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                            <Grid item xs={12} md={4}>
                                                <InputLabel id="nt-select-label" sx={{ fontSize: 14, color: '#333' }}>Loại tiền</InputLabel>

                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <DHSAutoCompleteWithDataSource
                                                    id="currency-select"
                                                    className="cc-input"
                                                    dataSource={optionState?.currencyOptions}
                                                    value={
                                                        data?.modelM?.mA_NT
                                                    }
                                                    displayMember='ma_nt'
                                                    valueMember="ma_nt"
                                                    onValueChanged={(e, newValue: any) => {
                                                        handleChangeInput({
                                                            target: {
                                                                name: "mA_NT",
                                                                value: newValue?.ma_nt ?? null,
                                                            },
                                                        });
                                                        commonClient.getExchangeRate5({
                                                            ...new ParaObjExchageRate(),
                                                            ma_cty: SessionLogin.ma_cty,
                                                            ma_nt: newValue?.ma_nt,
                                                            ngay_ct: data?.modelM?.ngaY_CT
                                                        } as ParaObjExchageRate)
                                                            .then(res => {
                                                                if (!res) {
                                                                    // setData(pre => ({ ...pre, modelM: { ...pre.modelM, tY_GIA: 0 } } as ModelProcessca01))
                                                                    dispatch(saveChangeReceiptM({
                                                                        modelM: {
                                                                            tY_GIA: 0
                                                                        },

                                                                    } as IServiceBookingReceipt))

                                                                }
                                                                else {
                                                                    dispatch(saveChangeReceiptM({
                                                                        modelM: {
                                                                            tY_GIA: res
                                                                        },

                                                                    } as IServiceBookingReceipt))
                                                                    // setData(pre => ({ ...pre, modelM: { ...pre.modelM, tY_GIA: res } } as ModelProcessca01))
                                                                }
                                                            })
                                                    }}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                />
                                                {/* <Autocomplete
                                                    id="currency-select"
                                                    sx={{ width: "100%" }}
                                                    options={optionState.currencyOptions}
                                                    autoHighlight
                                                    getOptionLabel={(option: any) => option?.ma_nt}
                                                    value={
                                                        data?.modelM?.mA_NT
                                                            ? optionState.currencyOptions?.find(
                                                                (item: any) => item.ma_nt === data.modelM?.mA_NT
                                                            )
                                                            : null
                                                    }
                                                    onChange={(e, newValue: any) => {
                                                        handleChangeInput({
                                                            target: {
                                                                name: "mA_NT",
                                                                value: newValue?.ma_nt ?? null,
                                                            },
                                                        });
                                                        commonClient.getExchangeRate5({
                                                            ...new ParaObjExchageRate(),
                                                            ma_cty: SessionLogin.ma_cty,
                                                            ma_nt: newValue?.ma_nt,
                                                            ngay_ct: data?.modelM?.ngaY_CT
                                                        } as ParaObjExchageRate)
                                                            .then(res => {
                                                                if (!res) {
                                                                    // setData(pre => ({ ...pre, modelM: { ...pre.modelM, tY_GIA: 0 } } as ModelProcessca01))
                                                                    dispatch(saveChangeReceiptM({
                                                                        modelM: {
                                                                            tY_GIA: 0
                                                                        },

                                                                    } as IServiceBookingReceipt))

                                                                }
                                                                else {
                                                                    dispatch(saveChangeReceiptM({
                                                                        modelM: {
                                                                            tY_GIA: res
                                                                        },

                                                                    } as IServiceBookingReceipt))
                                                                    // setData(pre => ({ ...pre, modelM: { ...pre.modelM, tY_GIA: res } } as ModelProcessca01))
                                                                }
                                                            })

                                                    }}

                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            className="cc-input"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "new-password", // disable autocomplete and autofill
                                                            }}
                                                        />
                                                    )}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                /> */}


                                            </Grid>
                                        </Grid>
                                        {/* Tỷ giá */}
                                        <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 7px" }}>
                                            <Grid item xs={12} md={4}>
                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tỷ giá</InputLabel>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <CurrencyTextField
                                                    className="flex-grow-1 form-control currency-input"
                                                    style={{ width: "100%", textAlign: "right" }}
                                                    allowDecimals
                                                    decimalsLimit={2}
                                                    decimalSeparator="."
                                                    groupSeparator=","
                                                    decimalScale={0}
                                                    // placeholder="Tỷ giá"
                                                    intlConfig={{ locale: 'vi-VN', currency: 'VND' }}
                                                    name="tY_GIA"
                                                    onValueChange={handleChangeCurrentInput}
                                                    value={data?.modelM?.tY_GIA}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                />

                                                {/* <TextField
                                                                className="cc-input"
                                                                sx={{
                                                                    width: "100%",
                                                                    marginRight: "5px",
                                                                }}
                                                                inputProps={{
                                                                    style: {
                                                                        textAlign: 'right'
                                                                    }
                                                                }}
                                                                type="number"
                                                                name="tY_GIA"
                                                                onChange={handleChangeInput}
                                                                value={data?.modelM?.tY_GIA}
                                                                disabled={props.action === ACTIONS.VIEW}
                                                            /> */}
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>


                            </div>



                        </div>

                        <div className="col-xl-4">

                            {
                                data?.modelM?.ma_httt === 'CK' && <div className="card">

                                    <div className="card-body pt-2">
                                        <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                            Thông tin ngân hàng
                                        </div>
                                        {/* Số tài khoản */}
                                        <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                            <Grid item xs={12} md={4}>
                                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số tài khoản</InputLabel>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <DHSAutoCompleteWithDataSource
                                                    id="pay-type-select"
                                                    className="cc-input"
                                                    dataSource={optionState?.paymentTermOptions}
                                                    value={
                                                        data?.modelM?.mA_NGH
                                                    }
                                                    displayMember='banknumber'
                                                    valueMember="banknumber"
                                                    onValueChanged={(e, newValue: any) => {
                                                        handleChangeInput({
                                                            target: {
                                                                name: "mA_NGH",
                                                                value: newValue?.banknumber ?? null,
                                                            },
                                                        });

                                                        setBankCode(newValue?.ma_ngh);
                                                        setBankName(newValue?.ten_ngh);
                                                    }}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                />
                                                {/* <Autocomplete
                                                    id="bank-select"
                                                    sx={{ width: "100%" }}
                                                    options={optionState.bankOptions}
                                                    autoHighlight
                                                    getOptionLabel={(option: any) => option?.banknumber}
                                                    value={
                                                        data?.modelM?.mA_NGH
                                                            ? optionState.bankOptions?.find(
                                                                (item: any) => item.banknumber === data.modelM?.mA_NGH
                                                            )
                                                            : null
                                                    }
                                                    onChange={(e, newValue: any) => {
                                                        handleChangeInput({
                                                            target: {
                                                                name: "mA_NGH",
                                                                value: newValue?.banknumber ?? null,
                                                            },
                                                        });

                                                        setBankCode(newValue?.ma_ngh);
                                                        setBankName(newValue?.ten_ngh);
                                                    }}

                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            className="cc-input"
                                                            // placeholder="Số tài khoản"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "new-password", // disable autocomplete and autofill
                                                            }}
                                                        />
                                                    )}
                                                    disabled={props.action === ACTIONS.VIEW}
                                                /> */}

                                            </Grid>
                                        </Grid>

                                        {/* Ngân hàng */}

                                        <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                            <Grid item xs={12} md={4}>
                                                <InputLabel htmlFor="address" sx={{ fontSize: 14, color: '#333' }}>Ngân hàng</InputLabel>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <div className="row">
                                                    <div className="col-xl-4 pr-0">
                                                        <TextField
                                                            id="bank-code"
                                                            className="cc-input"
                                                            sx={{
                                                                width: "100%",
                                                                marginRight: "5px",
                                                            }}
                                                            name="bank-code"
                                                            onChange={(e) => {
                                                                setBankCode(e.target.value)
                                                            }}
                                                            title={data?.modelM?.mA_NGH}

                                                            // placeholder="Mã ngân hàng"
                                                            value={data?.modelM?.mA_NGH ? bankCode : null}
                                                            disabled
                                                        />

                                                    </div>
                                                    <div className="col-xl-8 pl-1">
                                                        <TextField
                                                            className="cc-input"
                                                            sx={{
                                                                width: "100%",
                                                                marginRight: "5px",
                                                            }}
                                                            name="bank-name"
                                                            // placeholder="Tên ngân hàng"
                                                            onChange={(e) => {
                                                                setBankName(e.target.value)
                                                            }}
                                                            title={bankName}

                                                            value={data?.modelM?.mA_NGH ? bankName : null}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>

                                    </div>

                                </div>
                            }
                        </div>



                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className="Asset-control" expanded={isExpandReceiptDAction} onChange={() => { setIsCalledCaVchD(true); setIsExpandReceiptDAction(pre => !pre) }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    sx={{ backgroundColor: "#e0f2fe" }}
                >
                    <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Thông tin chi tiết</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="row">
                        <div className="col-12 p-0">
                            {<ReceiptInvoiceList
                                action={props.action}
                                arrayCt={data?.arrayCt}
                                arrayCt1={data?.arrayCt1}
                                dataInvoice={props.itemSelected?.invoice && [props.itemSelected?.invoice]}
                                passArrayCT1={(dataCT1: CaVch01D1[]) => setData01D1(pre => ([...dataCT1]))}
                                passArrayCT={(dataCT: CaVch01D0[]) => {
                                    setData01D0(pre => ([...dataCT]));
                                }}
                                maKH={maKH}
                                idcustomer={data?.modelM?.idcustomer}
                                dataGridRef01D1={dataGridRef01D1}
                                dataGridRef01D0={dataGridRef01D0}
                                // onDataGridD0Saved={(saved) => {
                                //     const tempOparations = new Set([...operations]);
                                //     tempOparations.add(saved);
                                //     setOperations(Array.from(tempOparations));
                                //     setIsSavedD0(saved)
                                // }}

                                // onDataGridD1Saved={(saved) => {
                                //     const tempOparations = new Set([...operations]);
                                //     tempOparations.add(saved);
                                //     setOperations(Array.from(tempOparations));
                                //     setIsSavedD1(saved)
                                // }}

                                totalTTMoney={function (totalTTMoney?: number | undefined): void {
                                    // setData(pre => ({
                                    //     ...pre, modelM: {
                                    //         ...pre.modelM,
                                    //         t_TIEN_NT: totalTTMoney,
                                    //         t_TIEN: totalTTMoney
                                    //     }
                                    // } as ModelProcessca01))
                                    dispatch(saveChangeReceiptM({
                                        modelM: {
                                            t_TIEN_NT: totalTTMoney,
                                            t_TIEN: totalTTMoney
                                        },

                                    } as IServiceBookingReceipt))
                                }}
                                stt_rec={data?.modelM?.stT_REC}
                                onError={(errorHelper) => {

                                    errorHelper?.forEach(item => {
                                        item?.errors?.forEach((error: any) => {
                                            notification(status_message[error.status], error.message)
                                        });
                                    })

                                    setErrors(errorHelper)
                                }}
                            />}
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>


            <BlockUI blocking={isLoading} title={""}></BlockUI>

        </div>
    );
};

export default ServiceBookingReceipt;
