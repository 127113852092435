import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,

    Divider,
    DialogTitle,
    Autocomplete,
    FormControlLabel,
    Switch,
    Select,
    MenuItem,
    DialogContentText,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
} from "@mui/material";
import CurrencyTextField from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    HomeClient,
    QLCCServiceModel,
    QLCCClient,
    RoleRightWebModel,
    QLCCApartmentBuildingModel,
    QLCCApartmentTypesModel,
    LstUnitModel,
    QLCCApartmentModel,
    OBCashBankClient,
    QLCCOBCashBank,
    SysActionType,
    LstAccount,
    CommonClient,
    LstExchangeRate,
    CaVch01Client,
    LstCurrency,
} from "../../../shared/service-proxies/api-shared";


import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../ApartmentBuilding.css";
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import notification from "../../../../common/notification/notification";
import ApartmentTypeServiceList from "../../apartment-building/apartment/apartment-type-service-list";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import DHSDate from "../../../../components/DHS/DHS-Date/DHSDateComponent";
import CTXDate from "../../../../components/CTX/DatePicker";
import { Notification } from "../../../../components/layouts";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import moment from "moment";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";
import { setApartmentBuilding } from "../../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction";
interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: () => void;
    obcash?: any;
    apartmentBuildings?: QLCCApartmentBuildingModel[] | undefined;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const status_message = ['success', 'error']

var appSession: AppSession;

const OBCashBankAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const listMenu = useSelector((state: IReducer) => state.RoleReducer?.listMenu);
    const obCashBankClient = new OBCashBankClient(appSession, BASE_URL_API);
    const [obCashBank, setObCashBank] = React.useState<QLCCOBCashBank>({} as QLCCOBCashBank);
    const [lstAccounts, setLstAccounts] = React.useState<LstAccount[]>([] as LstAccount[]);
    const caVch01Client = new CaVch01Client(appSession, BASE_URL_API);
    const [currencyOptions, setCurrencyOptions] = React.useState<any[]>([]);
    const [year, setYear] = React.useState<any>(new Date());
    // const [apartmentBuilding, setApartmentBuilding] = React.useState<QLCCApartmentBuildingModel>({} as QLCCApartmentBuildingModel);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
    const apartment_building_item = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building)
    const dispatch = useDispatch();

    React.useEffect(() => {
        setLoading(true);
        if (props.action === ACTIONS.ADD) {
            if (props.apartmentBuildings && props.apartmentBuildings.length > 0) {
                // setApartmentBuilding(props.apartmentBuildings[0])
                setObCashBank({
                    ... new QLCCOBCashBank(),
                    apartment_building_id: apartment_building_id
                } as QLCCOBCashBank);
            }
        } else {
            setObCashBank({
                ...props.obcash,
            } as QLCCOBCashBank);
        }

        qLCClient.qLCClstAccountGet(SessionLogin.ma_cty, "")
            .then((res: any) => {
                setLstAccounts(res);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            })

        caVch01Client.lstCurrencyGet({
            ...new LstCurrency(),
            ma_cty: SessionLogin.ma_cty,
        } as LstCurrency)
            .then(res => {
                setLoading(false);
                setCurrencyOptions(res)
            });

        // Cập nhật
        if (props.itemSelected && props.itemSelected.apartment_building_id) {
            const temp = props.apartmentBuildings?.find(p => p.id === props.itemSelected.apartment_building_id);
            if (temp) {
                // setApartmentBuilding(temp)
                dispatch(setApartmentBuilding({
                    ...temp
                } as QLCCApartmentBuildingModel))
            }
        }
    }, [props.action, props.itemSelected, props.obcash]);



    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (props.action !== ACTIONS.VIEW) {
            setObCashBank({
                ...obCashBank,
                [name]: value,
            } as QLCCOBCashBank);
        }
    }

    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        if (props.action !== ACTIONS.VIEW) {
            // setObCashBank({
            //     ...obCashBank,
            //     [name]: value,
            // } as QLCCOBCashBank);
            setYear(value);
        }
    }

    const handleUpdate = () => {
        const yearNumber = new Date(year?.toString()).getFullYear();

        obCashBankClient
            .qLCCOBCashBankUpd({
                ... new QLCCOBCashBank(),
                ...obCashBank,
                nam: Number(yearNumber),
                du_co: Number(obCashBank?.du_co),
                du_no: Number(obCashBank?.du_no),
                du_co_nt: Number(obCashBank?.du_co_nt),
                du_no_nt: Number(obCashBank?.du_no_nt),
                ma_cty: SessionLogin.ma_cty,
                luser: SessionLogin.userName,
                cuser: SessionLogin.userName,
                apartment_building_id: obCashBank.apartment_building_id ? obCashBank.apartment_building_id : apartment_building_id,
            } as QLCCOBCashBank)
            .then(res => {
                setLoading(false);

                Notification(status_message[res.status === 0 ? res.status : 1], res.message);

                if (res.status === 0) {
                    props.onEditSuccess();
                    props.onClose();
                }
            })
            .catch(err => {

                setLoading(false);
            })
    }

    const handleSubmit = () => {
        const yearNumber = new Date(year?.toString()).getFullYear();
        obCashBankClient.qLCCOBCashBankIns({
            ... new QLCCOBCashBank(),
            ...obCashBank,
            nam: Number(yearNumber),
            du_co: Number(obCashBank?.du_co),
            du_no: Number(obCashBank?.du_no),
            du_co_nt: Number(obCashBank?.du_co_nt),
            du_no_nt: Number(obCashBank?.du_no_nt),
            ma_cty: SessionLogin.ma_cty,
            luser: SessionLogin.userName,
            cuser: SessionLogin.userName,
            ldate: new Date(),
            apartment_building_id: apartment_building_id,
        } as QLCCOBCashBank)
            .then(res => {
                setLoading(false);
                Notification(status_message[res.status === 0 ? res.status : 1], res.message);

                if (res.status === 0) {
                    props.onEditSuccess();
                    props.onClose();
                }

            })
            .catch(err => {

                setLoading(false);
            })
    }

    const handleClickAction = (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code) {
            case ACTIONS.SAVE:

                if (props.action === ACTIONS.ADD) {
                    handleSubmit();

                } else if (props.action === ACTIONS.EDIT) {
                    handleUpdate();
                }

                break;

            case ACTIONS.CLOSE:

                props.onClose();

                break;
        }
    }


    const handleChangeCurrentInput = (

        value: string | undefined, name?: string | undefined, values?: CurrencyInputOnChangeValues | undefined) => {
        if (props.action.toUpperCase() !== ACTIONS.VIEW) {
            setObCashBank({
                ...obCashBank,
                [name!]: value,
            } as QLCCOBCashBank);

        }

    }

    const handleChangeInput = (e: any) => {

        const { name, value } = e.target;

        if (name === 'apartmentBuilding') {

            const apartmentBuildingSelect: any = props.apartmentBuildings?.find(item => item.id === value);

            if (apartmentBuildingSelect) {
                setApartmentBuilding(apartmentBuildingSelect);
            }

        }
    };

    return (
        <Dialog
            id="sysMenu7"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            TransitionComponent={Transition}
            maxWidth="xs"
        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === "VIEW"
                                ? "[Chi tiết]"
                                : props.action === "EDIT" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        {/* Choose apartment building*/}
                        <Box className='w-100 mt-2'>
                            <InputLabel className='cc-label'>Dự án</InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="apartment-select"
                                className="cc-input"
                                dataSource={props?.apartmentBuildings!}
                                displayMember="name"
                                valueMember="id"
                                disabled={true}
                                // value={obCashBank?.apartment_building_id}
                                value={apartment_building_id}
                                // onValueChanged={(e, newValue: any) => {
                                //     // handleChangeInput!({
                                //     //     target: {
                                //     //         name: "apartmentBuilding",
                                //     //         value: newValue?.id ? newValue.id : null,
                                //     //     },
                                //     // });
                                //     if (newValue && newValue?.id) {
                                //         dispatch(setApartmentBuilding(newValue))
                                //     }
                                // }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {/* Năm */}
                        <InputLabel
                            className="cc-input"
                        >
                            Năm
                        </InputLabel>
                        <CTXDate

                            name="cdate"
                            className="cc-input w-100"
                            onChange={handleChangeDate}
                            views={['year']}
                            value={year}

                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {/* Tài khoản */}
                        <InputLabel
                            className="cc-input"
                        >
                            Tiền mặt/ ngân hàng
                        </InputLabel>
                        <DHSAutoCompleteWithDataSource
                            id="apartment-select"
                            className="cc-input"
                            dataSource={lstAccounts}
                            displayMember="ten_tk"
                            valueMember="tk"
                            value={obCashBank?.tk}
                            onValueChanged={(e, newValue: any) => {
                                handleChange({
                                    target: {
                                        name: "tk",
                                        value: newValue?.tk ? newValue.tk : null,
                                    },
                                });
                            }}
                        />
                        {/* <Autocomplete
                            id="apartment-select"
                            sx={{ width: "100%" }}
                            options={lstAccounts}
                            autoHighlight
                            getOptionLabel={(option: any) => `${option?.ten_tk}`}
                            value={
                                lstAccounts
                                && lstAccounts.find(
                                    (item: any) => item.tk === obCashBank.tk
                                )
                            }
                            onChange={(e, newValue: any) => {
                                handleChange({
                                    target: {
                                        name: "tk",
                                        value: newValue?.tk ? newValue.tk : null,
                                    },
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className="cc-input"
                                    //placeholder="Tài khoản"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        /> */}

                    </Grid>
                    <Grid item xs={12} md={12}>
                        {/* Mã ngoại tệ */}
                        <InputLabel className="cc-input" >
                            Loại tiền
                        </InputLabel>
                        <DHSAutoCompleteWithDataSource
                            id="apartment-select"
                            className="cc-input"
                            dataSource={currencyOptions}
                            getOptionLabel={(option: any) => `${option.ma_nt} - ${option.ten_nt}`}
                            valueMember="ma_nt"
                            value={obCashBank?.ma_nt}
                            onValueChanged={(e, newValue: any) => {
                                handleChange({
                                    target: {
                                        name: "ma_nt",
                                        value: newValue?.ma_nt ? newValue.ma_nt : null,
                                    },
                                });
                            }}
                        />
                        {/* <Autocomplete
                            id="apartment-select"
                            sx={{ width: "100%" }}
                            options={currencyOptions}
                            autoHighlight
                            getOptionLabel={(option: any) => `${option.ma_nt} - ${option.ten_nt}`}
                            value={
                                obCashBank.ma_nt
                                    ? currencyOptions.find(
                                        (item: any) => item.ma_nt === obCashBank.ma_nt
                                    ) : null
                            }
                            onChange={(e, newValue: any) => {
                                handleChange({
                                    target: {
                                        name: "ma_nt",
                                        value: newValue?.ma_nt ? newValue.ma_nt : null,
                                    },
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className="cc-input"
                                    //placeholder="Mã ngoại tệ"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        /> */}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {/* Dự nợ DK NT  */}
                        <InputLabel
                            className="cc-input"
                        >
                            Dư nợ ngoại tệ
                        </InputLabel>
                        <CurrencyTextField
                            className="flex-grow-1 form-control currency-input"
                            style={{ width: "100%", textAlign: "right" }}
                            allowDecimals
                            decimalsLimit={2}
                            decimalSeparator="."
                            groupSeparator=","
                            decimalScale={0}
                            //placeholder="Dư nợ dự kiến ngoại tệ"
                            intlConfig={currencyOptions.find(p => p.currency === obCashBank.ma_nt?.toLocaleUpperCase())}
                            name="du_no_nt"
                            onValueChange={handleChangeCurrentInput}
                            value={obCashBank.du_no_nt}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {/* Dư nợ đầu năm NT */}
                        <InputLabel
                            className="cc-input"
                        >
                            Dư nợ
                        </InputLabel>
                        <CurrencyTextField
                            className="flex-grow-1 form-control currency-input"
                            style={{ width: "100%", textAlign: "right" }}
                            allowDecimals
                            decimalsLimit={2}
                            decimalSeparator="."
                            groupSeparator=","
                            decimalScale={0}
                            //placeholder="Dư nợ đầu năm ngoại tệ"
                            intlConfig={currencyOptions.find(p => p.currency === obCashBank.ma_nt?.toLocaleUpperCase())}
                            name="du_no"
                            onValueChange={handleChangeCurrentInput}
                            value={obCashBank.du_no}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {/* Dư có DK NT */}
                        {/* <InputLabel
                            className="cc-input"
                        >
                            Dư có ngoại tệ
                        </InputLabel>
                        <CurrencyTextField
                            className="flex-grow-1 form-control currency-input"
                            style={{ width: "100%", textAlign: "right" }}
                            allowDecimals
                            decimalsLimit={2}
                            decimalSeparator="."
                            groupSeparator=","
                            decimalScale={0}
                            //placeholder="Dư có dự kiến ngoại tệ"
                            intlConfig={currencyOptions.find(p => p.currency === obCashBank.ma_nt?.toLocaleUpperCase())}
                            name="du_co_nt"
                            onValueChange={handleChangeCurrentInput}
                            value={obCashBank.du_co_nt}
                        /> */}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {/* Dư có đầu năm NT */}
                        {/* <InputLabel

                            className="cc-input"

                        >
                            Dư có
                        </InputLabel>
                        <CurrencyTextField
                            className="flex-grow-1 form-control currency-input"
                            style={{ width: "100%", textAlign: "right" }}
                            allowDecimals
                            decimalsLimit={2}
                            decimalSeparator="."
                            groupSeparator=","
                            decimalScale={0}
                            //placeholder="Dư có đầu năm ngoại tệ"
                            intlConfig={currencyOptions.find(p => p.currency === obCashBank.ma_nt?.toLocaleUpperCase())}
                            name="du_co"
                            onValueChange={handleChangeCurrentInput}
                            value={obCashBank.du_co}
                        /> */}
                    </Grid>
                </Grid>

            </DialogContent>

            <DialogActions>

                <DHSDialogAction

                    menu={menu}
                    onClickAction={handleClickAction}
                    action={props?.action}
                />

            </DialogActions>

            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default OBCashBankAction;
