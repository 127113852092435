import React from "react";
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
    Grid,
    Stack,
    FormControlLabel,
    Switch,
    Tabs,
    Tab,
} from "@mui/material";
import CurrencyTextField from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import CloseIcon from "@mui/icons-material/Close";

import {
    QLCCClient,
    RoleRightWebModel,
    QLCCApartmentBuildingModel,
    OBCashBankClient,
    SysActionType,
    LstAccount,
    CommonClient,
    CaVch01Client,
    LstCurrency,
    LstCustomer,
    ParaObjExchageRate,
    ARBookClient,
    ParaObj,
    QLCCOBCashBank,
    QLCCResidentsModel,
    CustomerClient,
    CustomerGroupClient,
    CustomerKindClient,
    QLCCBlockModel,
    LstDepartment,
    DepartmentClient,
} from "../../../shared/service-proxies/api-shared";


import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../ApartmentBuilding.css";
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import CTXDate from "../../../../components/CTX/DatePicker";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import DHSAutoCompleteTableResident from "../../../../components/DHS/DHS_AutoCompleteResident/DHSAutoCompleteTableResident";
import notification from "../../../../common/notification/notification";
import DHSAutoCompleteWithDataGrid from "../../../../components/DHS/DHS_AutoCompleteCustomer/DHSAutoCompleteWithDataGrid";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: () => void;
    customer?: any;
    apartmentBuildings?: QLCCApartmentBuildingModel[] | undefined;
    payload?: any;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});





const status_message = ['success', 'error', 'warning', 'info'];

var appSession: AppSession;

const CategoryEmployeeAction: React.FC<IProps> = (props: IProps) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const [_id, setId] = React.useState<string>();

    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const customerClient = new CustomerClient(appSession, BASE_URL_API);
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    const departmentClient = new DepartmentClient(appSession, BASE_URL_API);
    const qLCCCustomerClient = new CustomerClient(appSession, BASE_URL_API);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [customer, setCustomer] = React.useState<LstCustomer>({} as LstCustomer);
    const [lstAccounts, setLstAccounts] = React.useState<LstAccount[]>([] as LstAccount[]);
    const caVch01Client = new CaVch01Client(appSession, BASE_URL_API);
    const [currencyOptions, setCurrencyOptions] = React.useState<any[]>([]);
    const [departmentOptions, setDepartmentOptions] = React.useState<LstDepartment[]>([]);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const [value, setValue] = React.useState(0);
    const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        if (props.open) {
            setLoading(true);
            if (props.action === ACTIONS.ADD) {
                setCustomer({
                    ... new LstCustomer(),
                    cdate: new Date(),
                    ldate: new Date(),
                    cuser: SessionLogin.userName,
                    luser: SessionLogin.userName,
                    mA_NT: "VND",
                    apartment_building_id: apartment_building_id,
                } as LstCustomer | any);
                setLoading(false);
            } else {
                if (props.customer) {
                    // Get đối tượng đang được xét
                    customerClient.lstCustomerGet({
                        ... new LstCustomer(),
                        mA_CTY: SessionLogin.ma_cty,
                        ...props.customer,
                        apartment_building_id: apartment_building_id,
                        moduleId: 'CA'
                    } as LstCustomer)
                        .then(res => {
                            if (res[0]) {
                                setCustomer(res[0]);
                            }
                            setLoading(false);
                        })
                        .catch(err => {
                            setLoading(false);
                        })

                    // setCustomer({
                    //     ...props.customer,
                    // } as LstCustomer);
                }
            }

            // Tài khoản
            qLCClient.qLCClstAccountGet(
                SessionLogin.ma_cty,
                ""
            )
                .then((res: any) => {

                    setLstAccounts(res);
                    setLoading(false);

                })
                .catch((err) => {
                    setLoading(false);
                })

            // ngoại tệ
            caVch01Client.lstCurrencyGet({
                ...new LstCurrency(),
                ma_cty: SessionLogin.ma_cty,
            } as LstCurrency)
                .then(res => {
                    setLoading(false);
                    setCurrencyOptions(res)
                });

            departmentClient.get({
                ...new LstDepartment(),
                ma_cty: SessionLogin.ma_cty,
                ma_ct: 'Ma_BP',
                apartment_building_id: apartment_building_id,
            } as LstDepartment | any)
                .then(res => {
                    setDepartmentOptions(res);
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [props.itemSelected, props.action, props.customer, props.open]);

    const handleChange = (e: any) => {

        const { name, value } = e.target;

        if (props.action !== ACTIONS.VIEW) {
            setCustomer({
                ...customer,
                [name]: value,
            } as LstCustomer);
        }
    }

    const handleChangeDate = (e: any) => {
        const { name, value } = e;

        if (props.action !== ACTIONS.VIEW) {

            setCustomer({
                ...customer,
                [name]: new Date(value),
            } as LstCustomer);

        }
    }

    const handleChangeSwitch = (e: any) => {
        const { name, checked } = e.target;


        if (props.action !== ACTIONS.VIEW) {

            setCustomer({
                ...customer,
                [name]: checked,
            } as LstCustomer);

        }
    }

    const handleUpdate = () => {

        qLCCCustomerClient.lstCustomerUpd({
            ... new LstCustomer(),
            ...customer,
            ldate: new Date(),
            luser: SessionLogin.userName,
            isnv: true,
        } as LstCustomer).then(res => {

            status(res.status === 0 ? res.status : 1, res.message);

            if (res.status === 0) {
                props.onEditSuccess();
                props.onClose();
            }
        }).catch(err => {
            console.log(err)
        })

    }


    const status = (status: number = 1, message: string = "") => {
        notification(status_message[status], message);
        if (status === 0) {
            props.onEditSuccess();
            props.onClose()
        }

    }

    const handleSubmit = () => {
        qLCCCustomerClient.lstCustomerIns({
            ... new LstCustomer(),
            ...customer,
            mA_CTY: SessionLogin.ma_cty,
            cuser: SessionLogin.userName,
            cdate: new Date(),
            ldate: new Date(),
            luser: SessionLogin.userName,
            isnv: true,
            apartment_building_id: apartment_building_id,
        } as LstCustomer | any)
            .then(res => {
                status(res.status === 0 ? res.status : 1, res.message)

                if (res.status === 0) {
                    props.onEditSuccess();
                    props.onClose();
                }
            }).catch(err => {
                console.log(err)
            })
    }

    const handleClickAction = (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code) {
            case ACTIONS.SAVE:

                if (props.action === ACTIONS.ADD) {

                    if (customer.mA_KH === undefined
                        || customer.mA_KH.length === 0) {
                        status(1, "Chưa nhập mã khách hàng/ nhà cung cấp")
                        break;
                    } else if (customer.teN_KH === undefined
                        || customer.teN_KH.length === 0) {
                        status(1, "Chưa nhập tên khách hàng")
                        break;
                    }

                    handleSubmit();

                } else if (props.action === ACTIONS.EDIT) {
                    handleUpdate();
                }

                break;

            case ACTIONS.CLOSE:

                props.onClose();

                break;
        }
    }


    const handleChangeCurrentInput = (

        value: string | undefined, name?: string | undefined, values?: CurrencyInputOnChangeValues | undefined) => {
        if (props.action.toUpperCase() !== ACTIONS.VIEW) {
            setCustomer({
                ...customer,
                [name!]: value,
            } as LstCustomer);

        }

    }



    return (
        <Dialog
            id="sysMenu8"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            TransitionComponent={Transition}
        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === "VIEW"
                                ? "[Chi tiết]"
                                : props.action === "EDIT" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent   >

                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChangeTabs} aria-label="basic tabs example">
                            <Tab label="Thông tin chung" {...a11yProps(0)} />
                            <Tab label="Khác" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2} className="d-md-flex justify-content-around">
                                    <Grid item xs={12} md={6} lg={5}>
                                        <Box className="mb-3 d-md-flex justify-content-between w-100">
                                            <InputLabel
                                                className="w-100 cc-label"
                                            >
                                                Mã nhân viên
                                            </InputLabel>
                                            <Box className="w-100">
                                                <TextField
                                                    className="cc-input w-100"
                                                    value={customer.mA_KH}
                                                    name='mA_KH'
                                                    onChange={handleChange}
                                                />
                                            </Box>
                                        </Box>
                                        <Box className="mb-3 d-md-flex align-items-center justify-content-between">
                                            <InputLabel className="cc-label w-100">
                                                Tên nhân viên
                                            </InputLabel>
                                            <TextField
                                                className="cc-input w-100"
                                                value={customer.teN_KH}
                                                name='teN_KH'
                                                onChange={handleChange}
                                            />
                                        </Box>
                                        <Box className="mb-3 d-md-flex align-items-center justify-content-between">
                                            <InputLabel className="cc-label w-100">
                                                Địa chỉ
                                            </InputLabel>
                                            <TextField
                                                className="cc-input w-100"
                                                value={customer.diA_CHI}
                                                name="diA_CHI"
                                                onChange={handleChange}
                                            />
                                        </Box>
                                        <Box className="mb-3 d-md-flex justify-content-between w-100">
                                            <InputLabel className="cc-label w-100"
                                            >
                                                Điện thoại
                                            </InputLabel>
                                            <TextField
                                                className='cc-input w-100'
                                                value={customer.mobile}
                                                // placeholder="Di động"
                                                name="mobile"
                                                onChange={handleChange}
                                            />
                                        </Box>
                                        <Box className="mb-3 d-md-flex justify-content-between w-100">
                                            <InputLabel className="cc-label w-100"
                                            >
                                                Số CMND/CCCD
                                            </InputLabel>
                                            <TextField
                                                className='cc-input w-100'
                                                value={customer.cmnd}
                                                // placeholder="tel"
                                                name="cmnd"
                                                onChange={handleChange}
                                            />
                                        </Box>
                                        <Box className="mb-3 d-md-flex justify-content-between w-100">
                                            <InputLabel className="cc-label w-100"
                                            >
                                                Nơi cấp
                                            </InputLabel>
                                            <TextField
                                                className='cc-input w-100'
                                                value={customer.noI_CAP}
                                                // placeholder="Số nội bộ"
                                                name="noI_CAP"
                                                onChange={handleChange}
                                            />
                                        </Box>
                                        <Box className="mb-3 d-md-flex justify-content-between w-100">
                                            <InputLabel className="cc-label w-100"
                                            >
                                                Số tài khoản
                                            </InputLabel>
                                            <TextField
                                                className='cc-input w-100'
                                                value={customer.sO_TK_NH}
                                                // placeholder="Số nội bộ"
                                                name="sO_TK_NH"
                                                onChange={handleChange}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={5}>
                                        <Box className="mb-3 d-md-flex align-items-start justify-content-between">
                                            <InputLabel className="cc-label w-100">
                                                Mã số thuế
                                            </InputLabel>
                                            <TextField
                                                className="cc-input w-100"
                                                value={customer.mA_SO_THUE}
                                                name="mA_SO_THUE"
                                                onChange={handleChange}
                                            />
                                        </Box>
                                        <Box className="mb-3 d-md-flex align-items-start justify-content-between">
                                            <InputLabel className="cc-label w-100">
                                                Ngoại tệ
                                            </InputLabel>
                                            <DHSAutoCompleteWithDataSource
                                                id="apartment-select"
                                                className="cc-input"
                                                dataSource={currencyOptions}
                                                getOptionLabel={(option: any) => `${option.ma_nt} - ${option.ten_nt}`}
                                                valueMember="ma_nt"
                                                value={customer.mA_NT}
                                                onValueChanged={(e, newValue: any) => {
                                                    handleChange({
                                                        target: {
                                                            name: "mA_NT",
                                                            value: newValue?.ma_nt ? newValue.ma_nt : null,
                                                        },
                                                    });
                                                }}
                                            />
                                            {/* <Autocomplete
                                                id="apartment-select"
                                                sx={{ width: "100%" }}
                                                options={currencyOptions}
                                                autoHighlight
                                                getOptionLabel={(option: any) => `${option.ma_nt} - ${option.ten_nt}`}
                                                value={
                                                    customer.mA_NT
                                                        ? currencyOptions.find(
                                                            (item: any) => item.ma_nt === customer.mA_NT
                                                        ) : null
                                                }
                                                onChange={(e, newValue: any) => {
                                                    handleChange({
                                                        target: {
                                                            name: "mA_NT",
                                                            value: newValue?.ma_nt ? newValue.ma_nt : null,
                                                        },
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className="cc-input"
                                                        placeholder="-- Mã ngoại tệ --"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password", // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                            /> */}
                                        </Box>
                                        <Box className="mb-3 d-md-flex align-items-start justify-content-between">
                                            <InputLabel className="cc-label w-100">
                                                Tài khoản
                                            </InputLabel>
                                            <DHSAutoCompleteWithDataSource
                                                id="apartment-select"
                                                className="cc-input"
                                                dataSource={lstAccounts}
                                                getOptionLabel={(option: any) => `${option?.ten_tk} - ${option?.tk}`}
                                                valueMember="tk"
                                                value={customer.tk}
                                                onValueChanged={(e, newValue: any) => {
                                                    handleChange({
                                                        target: {
                                                            name: "tk",
                                                            value: newValue?.tk ? newValue.tk : null,
                                                        },
                                                    });
                                                }}
                                            />
                                            {/* <Autocomplete
                                                id="apartment-select"
                                                sx={{ width: "100%" }}
                                                options={lstAccounts}
                                                autoHighlight
                                                getOptionLabel={(option: any) => `${option?.ten_tk} - ${option?.tk}`}
                                                value={
                                                    lstAccounts
                                                    && lstAccounts.find(
                                                        (item: any) => item.tk === customer.tk
                                                    )
                                                }
                                                onChange={(e, newValue: any) => {
                                                    handleChange({
                                                        target: {
                                                            name: "tk",
                                                            value: newValue?.tk ? newValue.tk : null,
                                                        },
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}

                                                        className="cc-input"
                                                        placeholder="--Tài khoản--"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password", // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                            /> */}
                                        </Box>
                                        {/* <Box className="mb-3 d-md-flex align-items-start justify-content-between">
                                            <InputLabel className="cc-label w-100">
                                                Client
                                            </InputLabel>
                                            <Autocomplete
                                                id="apartment-select"
                                                sx={{ width: "100%" }}
                                                options={lstClients}
                                                autoHighlight
                                                getOptionLabel={(option: any) => `${option.ten_nt} - ${option.ma_nt}`}
                                                value={
                                                    customer.client
                                                        ? currencyOptions.find(
                                                            (item: any) => item.id === customer.client
                                                        ) : null
                                                }
                                                onChange={(e, newValue: any) => {
                                                    handleChange({
                                                        target: {
                                                            name: "client",
                                                            value: newValue?.ma_nt ? newValue.ma_nt : null,
                                                        },
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className="cc-input"
                                                        placeholder="-- Client --"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password", // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Box> */}
                                        <Box className="mb-3 d-md-flex justify-content-between w-100">
                                            <InputLabel className="cc-label w-100"
                                            >
                                                Email
                                            </InputLabel>
                                            <TextField
                                                type={"email"}
                                                className='cc-input w-100'
                                                value={customer.email}
                                                // placeholder="Email"
                                                name="email"
                                                onChange={handleChange}
                                            />
                                        </Box>
                                        <Box className="mb-3 d-md-flex justify-content-between w-100">
                                            <InputLabel className="cc-label w-100"
                                            >
                                                Ngày cấp
                                            </InputLabel>
                                            <CTXDate
                                                className="cc-input w-100"
                                                name="ngaY_CAP"
                                                value={customer.ngaY_CAP}
                                                onChange={(newValue: any) => handleChangeDate(newValue)}
                                            />
                                        </Box>
                                        <Box className="mb-3 d-md-flex align-items-start justify-content-between">
                                            <InputLabel className="cc-label w-100">
                                                Bộ phận
                                            </InputLabel>
                                            <DHSAutoCompleteWithDataSource
                                                id="apartment-select"
                                                className="cc-input"
                                                dataSource={departmentOptions}
                                                displayMember="ten_bp"
                                                valueMember="ma_bp"
                                                value={customer?.mA_BP}
                                                onValueChanged={(e, newValue: any) => {
                                                    handleChange({
                                                        target: {
                                                            name: "mA_BP",
                                                            value: newValue?.ma_bp ? newValue.ma_bp : null,
                                                        },
                                                    });
                                                }}
                                                disabled={props.action === ACTIONS.VIEW}
                                            />
                                            {/* <Autocomplete
                                                id="apartment-select"
                                                sx={{ width: "100%" }}
                                                options={departmentOptions}
                                                autoHighlight
                                                getOptionLabel={(option: any) => `${option.ten_bp}`}
                                                value={
                                                    customer.mA_BP
                                                        ? departmentOptions.find(
                                                            (item: any) => item.ma_bp === customer.mA_BP
                                                        ) : null
                                                }
                                                onChange={(e, newValue: any) => {
                                                    handleChange({
                                                        target: {
                                                            name: "mA_BP",
                                                            value: newValue?.ma_bp ? newValue.ma_bp : null,
                                                        },
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className="cc-input"
                                                        placeholder="-- Bộ phận --"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password", // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                            /> */}
                                        </Box>
                                        <Box className="mb-3 d-md-flex justify-content-between w-100">
                                            <InputLabel className="cc-label w-100"
                                            >
                                                Ngân hàng
                                            </InputLabel>
                                            <TextField
                                                className='cc-input w-100'
                                                value={customer.teN_NH}
                                                // placeholder="Số nội bộ"
                                                name="teN_NH"
                                                onChange={handleChange}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={5} className="d-md-flex justify-content-between">

                                        <Box className="mb-3 ">
                                            <InputLabel className="cc-label w-100"></InputLabel>
                                            <Box className='w-100'>
                                                <FormControlLabel
                                                    control={<Switch
                                                        name="isncc"
                                                        onChange={handleChangeSwitch}
                                                        checked={customer.isncc}
                                                    />}
                                                    label="Là nhà cung cấp" />

                                            </Box>
                                        </Box>
                                        <Box className="mb-3">
                                            <InputLabel className="cc-label w-100"></InputLabel>
                                            <Box className='w-100'>
                                                <FormControlLabel
                                                    control={<Switch
                                                        name="iskh"
                                                        onChange={handleChangeSwitch}
                                                        checked={customer.iskh} />}
                                                    label="Là khách hàng" />
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Box className="mb-3 w-100">
                                            <InputLabel className="cc-label w-100"
                                            >
                                                Ghi chú
                                            </InputLabel>
                                            <TextField
                                                type={"text"}
                                                multiline
                                                minRows={5}
                                                maxRows={8}
                                                className='cc-input w-100'
                                                value={customer.ghI_CHU}
                                                // placeholder="Email"
                                                name="ghI_CHU"
                                                onChange={handleChange}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>

                        <Box className="mb-3 d-md-flex align-items-start justify-content-between">
                            <InputLabel className="cc-label w-100"></InputLabel>
                            <Box className='w-100'>
                                <FormControlLabel
                                    control={<Switch
                                        name="ksd"
                                        onChange={handleChangeSwitch}
                                        checked={customer.ksd}
                                    />}
                                    label="Không sử dụng" />

                            </Box>
                        </Box>
                        {/* <Box className="mb-3 d-md-flex align-items-start justify-content-between">
                            <InputLabel className="cc-label w-100"></InputLabel>
                            <Box className='w-100'>
                                <FormControlLabel
                                    control={<Switch
                                        name="isnv"
                                        onChange={handleChangeSwitch}
                                        checked={customer.isnv || false}
                                    />}
                                    label="Là nhân viên" />
                            </Box>
                        </Box> */}
                    </TabPanel>
                </Box>


            </DialogContent>


            <DialogActions>

                <DHSDialogAction

                    menu={menu}
                    onClickAction={handleClickAction}
                    action={props.action}
                />

            </DialogActions>


            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog >
    );
};

export default CategoryEmployeeAction;


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}