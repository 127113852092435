import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

interface IProps {
  label?: string;
  disabled?: boolean;
  style?: any;
  format?: string,
  value?: any;
  className?: string;
  type?: string;
  name?: string;
  id?: string;
  minDate?: Date;
  maxDate?: Date;
  onChange?: (event: any) => void;
}
export default function CTXDateTime(props: IProps) {
  const format = props.format ? props.format : "dd/MM/yyyy HH:mm";

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
    >
      <DateTimePicker
        label={props.label}
        value={props.value}
        onChange={(newValue) => props.onChange && props.onChange({name: props.name, value: newValue})}
        inputFormat={format}
        renderInput={(params) => (
          <TextField
            {...params}
            id={props.id}
            className={props.className}
            name={props.name}
            style={props.style}
          />
        )}
        disabled={props.disabled}
        minDate={props.minDate ? props.minDate : null}
        maxDate={props.maxDate ? props.maxDate : null}
      />
    </LocalizationProvider>
  );
}
