import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { QLCCApartmentModel, QLCCClient } from "../../../app/shared/service-proxies/api-shared";
import BASE_URL_API from "../../../app/shared/service-proxies/config";
import { AppSession } from "../../../app/shared/app-session/app-session";
import { debounce } from "@mui/material/utils";
import { useSelector } from "react-redux";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import _ from "lodash";
import notification from "../../../common/notification/notification";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../common/Interfaces/StatusMessage";
interface IOption {
  id: string;
  name: string;
}
interface Iprops {
  id?: string;
  name?: string;
  className?: any;
  style?: any;
  options?: IOption[];
  onChange?: (e: any, newValue: any) => void;
  value?: any;
  disabled?: boolean;
  apartment_id_get?: string
}

var appSession: AppSession;

export default function DHSAutoCompleteApartment(props: Iprops) {

  const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
  const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

  const [apartmentOptions, setApartmentOptions] = React.useState<QLCCApartmentModel[]>([]);
  const [inputValue, setInputValue] = React.useState<string>("");
  const [apartmentObjectValue, setApartmentObjectValue] = React.useState<QLCCApartmentModel>();
  const [isExistsValue, setIsExistsValue] = React.useState<boolean>(false);
  // 

  const { onChange } = props;

  // React.useEffect(() => {
  //   setInputValue(props.value)
  // }, [props.value])

  // 


  React.useEffect(() => {


    if (props.value && !isExistsValue) {
      // setValueGet(props.value)
      qLCCClient.qLCCApartmentsGet(
        {
          ...new QLCCApartmentModel(),
          id: props?.value,
          apartment_building_id: apartment_building_id,
          action_type: 'AUTOCOMPLETE_SEARCH'
        } as QLCCApartmentModel
      ).then(res => {
        setApartmentOptions(res)
        // 
        const value = res.find(x => x.id === props.value)
        setInputValue(value?.name ?? "")
        setApartmentObjectValue(value)
        setIsExistsValue(true)
      });
    }
  }, [apartment_building_id, isExistsValue, props.value]);

  const handleChangeInput = React.useCallback(debounce(async (e: any) => {
    const { value } = e.target;

    if (!isExistsValue || (value && isExistsValue)) {
      qLCCClient.qLCCApartmentsGet(
        {
          ...new QLCCApartmentModel(),
          name: value,
          apartment_building_id: apartment_building_id,
          action_type: 'AUTOCOMPLETE_SEARCH'
        } as QLCCApartmentModel
      ).then(res => {
        setApartmentOptions(res)


        // console.log(res)
      }).catch(error => {
        notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE["SERVER_ERROR"])
      });
    }

    // setData({ ...data, [name]: value } as QLCCVehicleModel);
  }, 500), [apartment_building_id, isExistsValue]);

  return (
    <>
      {
        <Autocomplete
          disablePortal
          id="apartment-select"
          getOptionLabel={((option: any) => option.name)}
          value={(props.value && apartmentOptions.length) ? apartmentOptions.find((apartment: QLCCApartmentModel) => {
            return apartment.id?.trim() === props.value?.trim()
          }) : null}
          options={apartmentOptions}
          onChange={(e, newValue, reason) => {
            // setValueGet(newValue?.id);
            // console.log(reason, "reason")
            if ((reason === "selectOption") || (reason === "clear")) {
              onChange && onChange(e, newValue)
              setInputValue(newValue?.name ?? "")
              setApartmentObjectValue(newValue ?? undefined)

            }
            
          }}
          onBlur={(e) => {
            setInputValue(apartmentObjectValue?.name ?? "")
          }}
          // sx={{ width: 300 }}
          className="cc-input"
          inputValue={inputValue}
          // onMouseUp={(e) => {
          //   if (props.value) {
          //     setValueGet("")
          //   }
          // }}
          onInputChange={React.useMemo(() => (e, value, reason) => {
            // console.log(value, reason, "input")
            // nếu không có props.value
            if (reason === "input") {

              setInputValue(value);
              // if (!_.isEqual(props.value, value)) {

                handleChangeInput({
                  target: {
                    value: value
                  }
                })
              // }
            }
          }, [handleChangeInput])}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option?.id}>
                {option?.name}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name={props.name}
              className={props.className}
              sx={props.style}
              disabled={props.disabled}
            />
          )}
        />
      }
    </>
  );
}
