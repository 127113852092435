import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, InputLabel, Slide, Toolbar, Tooltip, Typography } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import React from 'react'
import TaskFollower from '.';
import { QLCCTaskModel, UserInfoFilterModel, UserInfoModel } from '../../../../../shared/service-proxies/api-shared';
import DHSTagBox from '../../../../../../components/DHS/DHS_TagBox';
import CloseIcon from "@mui/icons-material/Close";
import { ACTIONS } from '../../../../../../common/enums/DHSToolbarRoleAction';

interface IProps {
    follower: UserInfoModel[];
    data: QLCCTaskModel;
    removeFolloer?: (newData: string) => void;
    action: string;
    handleChangeFollowers: (newData: any) => void;
    enabled: string[] //  list action
    onRemoveUser?: (userInfo: UserInfoFilterModel) => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TaskFollowerInput = (props: IProps) => {
    const [openFollower, setOpenFollower] = React.useState<boolean>(false); // load tin nhắn

    const onClose = () => {
        setOpenFollower(pre => !pre)
    }

    const onOpen = () => {
        setOpenFollower(pre => !pre)
    }

    const checkShow = () => {
        const isset = props.enabled.includes(props.action);
        return isset;
    }

    return (
        <>
            {
                checkShow() && (
                    <Tooltip title="Tùy chọn danh sách người theo dõi công việc" arrow>
                        <InputLabel className="text-center input_follower" onClick={onOpen}>Thêm người theo dõi</InputLabel>
                    </Tooltip>
                )
            }
            <Dialog
                open={openFollower}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                sx={{
                    zIndex: 1500
                }}
                maxWidth='xs'
                fullWidth
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >Danh sách người theo dõi</Typography>
                        <IconButton
                            edge="end"
                            color="error"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CloseIcon sx={{ color: '#1f2937' }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent>
                    <TaskFollower
                        follower={props.follower}
                        data={props.data.follower}
                        removeFolloer={props?.removeFolloer}
                        action={props.action}
                        enabled={props.enabled}
                        onRemoveUser={props?.onRemoveUser}
                    />
                    <Tooltip title="Tùy chọn danh sách người theo dõi công việc" arrow>
                        <Box className="box-around">
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Thêm người theo dõi</InputLabel>
                            <DHSTagBox
                                dataSource={props.follower}
                                name="follower"
                                onValueChange={props.handleChangeFollowers}
                                displayExpr="fullname"
                                searchExpr="fullname"
                                valueExpr="username"
                                // defaultValue={data.follower ? data.follower.split(';') : []}
                                value={props.data.follower ? props.data.follower.split(';') : []}
                                multiline={true}
                                maxLength={500}
                            />
                        </Box>
                    </Tooltip>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="outlined">Đóng</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TaskFollowerInput