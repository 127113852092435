import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, Divider, IconButton, Slide, Autocomplete, TextField, Toolbar, Typography, Link, InputAdornment } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import React from 'react'
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from 'react-redux';
import { IReducer } from '../../../../common/Interfaces/Interfaces';
import BlockUI from '../../../../components/Block-UI/Block-ui';
import { ChoThueXeClient, CommonClient, CtxDanhMucXeModel, HRClient, HRWorkShift, KiemSoatCanClient, KscPhieuKiemSoatModel, KscVanDonModel, LstInventory, LstInventoryClient, ParaObj } from '../../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../../shared/service-proxies/config';
import { AppSession } from '../../../shared/app-session/app-session';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import Upload from '../../../../components/DHS/DHS_Upload/Upload';



interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


var appSession: AppSession;

const CanXeTest: React.FC<IProps> = (props) => {

    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [_id, setId] = React.useState<string>(props.itemSelected);

    const [data, setData] = React.useState<KscPhieuKiemSoatModel>(
        new KscPhieuKiemSoatModel()
    );

    // Option AutoComplete

    const [khachHangOptions, setKhachHangOptions] = React.useState<any>([]);
    const [loaiHangOptions, setLoaiHangOptions] = React.useState<any>([]);
    const [loaiKhoOptions, setLoaiKhoOptions] = React.useState<any>([]);
    const [kieuCanOptions, setKieuCanOptions] = React.useState<any>([]);
    const [taiXeOptions, setTaiXeOptions] = React.useState<any>([]);
    const [bienSoOptions, setBienSoOptions] = React.useState<any>([]);
    const [remoocOptions, setRemoocOptions] = React.useState<any>([]);
    const [nhomTauOptions, setNhomTauOptions] = React.useState<any>([]);
    const [caOptions, setCaOptions] = React.useState<any>([]);



    const [soVanDonOptions, setSoVanDonOptions] = React.useState<
        KscVanDonModel[]
    >([]);
    // 

    const commonClient = new CommonClient(appSession, BASE_URL_API);
    const kscClient = new KiemSoatCanClient(appSession, BASE_URL_API);
    const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);
    const invClient = new LstInventoryClient(appSession, BASE_URL_API);
    const hrClient = new HRClient(appSession, BASE_URL_API);

    // 

    React.useEffect(() => {
        // commonClient.getSoCT("KSC", "DHS").then((sct) => {
        //     setData({ ...data, so_phieu: sct } as KscPhieuKiemSoatModel);
        //   });

        // Khách hàng
        kscClient
            .kscPhieuKiemSoatDanhMucGet({
                ...new KscPhieuKiemSoatModel(),
                action_type: "KHACH_HANG",
            } as KscPhieuKiemSoatModel)
            .then((response) => {
                console.log(response, "response khách hàng")
                let tempArr: any = [];
                response.map(item => {
                    tempArr.push({
                        id: item.khach_hang_id,
                        name: item.khach_hang_name
                    })
                })

                setKhachHangOptions(tempArr);
            });

        // Loại kho

        invClient.getLstInventory(
            {
                ...new LstInventory(),
                mA_CTY: 'DHS'
            } as LstInventory
        ).then((response) => {
            console.log(response, "loại kho")
            let tempArr: any = [];
            response.map(item => {
                tempArr.push({
                    id: item.mA_KHO,
                    name: item.teN_KHO
                })
            })
            setLoaiKhoOptions([...tempArr]);
        })

        // Loại hàng

        ctxClient.ctxDanhMucXeActions({
            ...new CtxDanhMucXeModel(),
            tbname: "kscLoaiHang",
            action: "GET",
        } as CtxDanhMucXeModel)
            .then((response) => {
                console.log(response, "response danh mục xe")
                setLoaiHangOptions(response);
            });

        // Kiểu cân

        ctxClient.ctxDanhMucXeActions({
            ...new CtxDanhMucXeModel(),
            tbname: "kscKieuCan",
            action: "GET",
        } as CtxDanhMucXeModel)
            .then((res) => {
                setKieuCanOptions(res);
            });

        // Số vận đơn

        kscClient.kscVanDonGet(new KscVanDonModel()).then((response) => {
            setSoVanDonOptions(response);
            setLoading(false);
        });

        // Tài xế

        kscClient.kscPhieuKiemSoatDanhMucGet({
            ...new KscPhieuKiemSoatModel(),
            action_type: 'TAI_XE'
        } as KscPhieuKiemSoatModel)
            .then(response => {
                console.log(response, "tài xế")
                let tempArr: any = [];
                response.map(item => {
                    tempArr.push({
                        id: item.ma_tai_xe,
                        name: item.ten_tai_xe
                    })
                })
                setTaiXeOptions([...tempArr]);
            });


        // Biển số xe

        kscClient.kscPhieuKiemSoatDanhMucGet({
            ...new KscPhieuKiemSoatModel(),
            action_type: 'BIEN_SO'
        } as KscPhieuKiemSoatModel)
            .then(response => {
                console.log(response, "biển số")
                let tempArr: any = [];
                response.map(item => {
                    tempArr.push(item.bien_so)
                })
                setBienSoOptions([...tempArr]);
            });

        // Số Remooc

        kscClient
            .kscPhieuKiemSoatDanhMucGet({
                ...new KscPhieuKiemSoatModel(),
                action_type: "REMOOC",
            } as KscPhieuKiemSoatModel)
            .then((response) => {
                let tempArr: any = [];
                response.forEach((item) => {
                    tempArr.push({
                        id: item.ma_remooc,
                        name: item.so_remooc,
                    });
                });
                setRemoocOptions(tempArr);
                setLoading(false);
            });

        // Nhóm tàu

        kscClient.kscVanDonGet(new KscVanDonModel()).then((response) => {
            setSoVanDonOptions(response);
            setLoading(false);
        });
        setLoading(true);
        ctxClient
            .ctxDanhMucXeActions({
                ...new CtxDanhMucXeModel(),
                tbname: "kscNhomTau",
                action: "GET",
            } as CtxDanhMucXeModel)
            .then((res) => {
                setNhomTauOptions(res);
                setLoading(false);
            });
        setLoading(true);

        //   Ca giám sát


        hrClient
            .list_work_shift_get({
                ...new HRWorkShift(),
                ma_cty: "DHS",
            } as HRWorkShift)
            .then((res) => {
                const tempArr: any = [];
                res.forEach((item) => {
                    tempArr.push({
                        name: item.work_shift_name,
                        id: item.work_shift_id,
                    });
                });
                setCaOptions([...tempArr]);
                setLoading(false);
            });
        setLoading(true);

    }, [])

    const taoMoiSoChungTu = () => {
        commonClient.get_VoucherNo({
            ...new ParaObj(),
            ma_cty: "DHS",
            ma_ct: "KSC",
            ngay_ct: new Date()
        } as ParaObj).then((sct) => {
            setData({ ...data, so_phieu: sct } as KscPhieuKiemSoatModel);
        });
    }

    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        console.log(name, value, "handle change input date")
        setData({ ...data, [name]: new Date(value) } as KscPhieuKiemSoatModel);
    }

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log(event, "event")
        kscClient
        .kscPhieuKiemSoatIns({
          ...data,
          ngay_can_1:
            data.ngay_can_1 &&
            new Date(
              (new Date(data.ngay_can_1) as any) -
                new Date().getTimezoneOffset() * 1000 * 60
            ),
          ngay_can_2:
            data.ngay_can_2 &&
            new Date(
              (new Date(data.ngay_can_2) as any) -
                new Date().getTimezoneOffset() * 1000 * 60
            ),
        //   hinh_luc_vao: listHinhLucVao.join(";"),
        //   hinh_luc_ra: listHinhLucRa.join(";"),
        } as KscPhieuKiemSoatModel)
        .then((response) => {
        //   setId(response.id);
          setLoading(false);
          alert(response.message);
        });
    }

    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        } as KscPhieuKiemSoatModel)
        // console.log(data.so_phieu)
        console.log(name, value, "target")
    }
    return (
        <div>
            <Dialog
                id="sysMenu"
                open={props.open}
                onClose={props.onClose}
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
                TransitionComponent={Transition}
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            {menu?.bar +
                                " " +
                                `${props.action === "DETAIL"
                                    ? "[Chi tiết]"
                                    : props.action === "UPDATE" || _id
                                        ? "[Cập nhật]"
                                        : "[Thêm mới]"
                                }`}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>


                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent>
                    <div className="row p-2">
                        {/* Column 1 */}
                        <div className="col-xl-4">
                            {/* số phiếu */}
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <TextField
                                    sx={{
                                        width: props.action === "ADD" ? "75%" : "100%",
                                        marginRight: "5px",
                                    }}
                                    name="so_phieu"
                                    label="Số phiếu"
                                    type="text"
                                    variant="filled"
                                    size='small'
                                    value={data.so_phieu}
                                    onChange={handleChangeInput}
                                    InputLabelProps={{ shrink: true }}
                                />
                                {props.action === "ADD" && (
                                    <Link sx={{ fontSize: "14px" }} onClick={taoMoiSoChungTu}>
                                        Làm mới
                                    </Link>
                                )}
                            </Box>
                            {/* khách hàng  */}

                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <Autocomplete
                                    size='small'
                                    // value={data.ham_hang}
                                    disablePortal
                                    id="combo-box-demo"
                                    freeSolo
                                    options={khachHangOptions}
                                    onChange={(e, newValue: any) => {
                                        setData({
                                            ...data,
                                            khach_hang_id: newValue.id,
                                            khach_hang_name: newValue.name
                                        } as KscPhieuKiemSoatModel)
                                    }}
                                    getOptionLabel={(option: any) => option.name}
                                    value={{
                                        id: data?.khach_hang_id ? data.khach_hang_id : "",
                                        name: data?.khach_hang_name ? data.khach_hang_name : "",
                                    }}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Khách hàng" variant='filled' />}
                                />
                            </Box>

                            {/* Số vận đơn */}

                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <Autocomplete
                                    size='small'
                                    // value={data.ham_hang}
                                    disablePortal
                                    id="combo-box-demo"
                                    freeSolo
                                    options={soVanDonOptions}
                                    getOptionLabel={(option: any) => option.so_van_don}
                                    value={
                                        data.so_bill ? soVanDonOptions.find((item: any) => {
                                            return item.id === data.so_bill
                                        }) : null
                                    }
                                    sx={{ width: 300 }}
                                    onChange={(e: any, newValue: any) => {
                                        handleChangeInput({
                                            target: {
                                                value: newValue.id ?? null,
                                                name: "so_bill"
                                            }
                                        })
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Số vận đơn" variant='filled' />}
                                />
                            </Box>

                            {/*  */}

                          

                            {/* Loại hàng */}

                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <Autocomplete
                                    size='small'
                                    // value={data.ham_hang}
                                    disablePortal
                                    id="combo-box-demo"
                                    freeSolo
                                    options={loaiHangOptions}
                                    getOptionLabel={(option: any) => option.name}
                                    value={
                                        data.loai_hang_id ? loaiHangOptions.find((item: any) => {
                                            return item.id === data.loai_hang_id
                                        }) : null
                                    }
                                    sx={{ width: 300 }}
                                    onChange={(e: any, newValue: any) => {
                                        handleChangeInput({
                                            target: {
                                                value: newValue.id ?? null,
                                                name: "loai_hang_id"
                                            }
                                        })
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Loại hàng" variant='filled' />}
                                />
                            </Box>

                            {/* chủ hàng */}
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <TextField
                                    fullWidth
                                    name="chu_hang"
                                    label="Chủ hàng"
                                    type="text"
                                    variant="filled"
                                    size='small'
                                    value={data.chu_hang}
                                    onChange={handleChangeInput}
                                />
                            </Box>

                            {/* người nhận */}
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <TextField
                                    fullWidth
                                    name="nguoi_nhan"
                                    label="Người nhận"
                                    type="text"
                                    variant="filled"
                                    size='small'
                                    value={data.nguoi_nhan}
                                    onChange={handleChangeInput}
                                />
                            </Box>

                            {/* Tên tàu */}
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <Autocomplete
                                    size='small'
                                    // value={data.ham_hang}
                                    disablePortal
                                    id="combo-box-demo"
                                    freeSolo
                                    options={loaiHangOptions}
                                    getOptionLabel={(option: any) => option.name}
                                    value={
                                        data.loai_hang_id ? loaiHangOptions.find((item: any) => {
                                            return item.id === data.loai_hang_id
                                        }) : null
                                    }
                                    sx={{ width: 300, marginRight: props.action === 'ADD' || 'DETAILS' ? '0.25rem' : 0 }}
                                    onChange={(e: any, newValue: any) => {
                                        handleChangeInput({
                                            target: {
                                                value: newValue.id ?? null,
                                                name: "loai_hang_id"
                                            }
                                        })
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Loại hàng" variant='filled' />}
                                />
                                <InputAdornment position="start">
                                    <AddCircleOutlinedIcon
                                        color='info'
                                        style={{ height: "28px", width: "28px", }}
                                    />
                                </InputAdornment>

                            </Box>

                            {/* Nhóm tàu */}
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <Autocomplete
                                    id="country-select-demo"

                                    options={nhomTauOptions}
                                    autoHighlight
                                    getOptionLabel={(option: any) => option?.name}
                                    value={
                                        data?.nhom_tau_id
                                            ? nhomTauOptions.find(
                                                (item: any) => item.id === data.nhom_tau_id
                                            )
                                            : null
                                    }
                                    onChange={(e, newValue: any) => {
                                        handleChangeInput({
                                            target: {
                                                name: "nhom_tau_id",
                                                value: newValue?.id ? newValue.id : null,
                                            },
                                        });
                                    }}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                            label="Nhóm tàu"
                                            variant='filled'
                                        />
                                    )}
                                    disabled={props.action === "DETAIL"}
                                />

                            </Box>

                        </div>

                        {/* Column 2 */}
                        <div className="col-xl-4">

                            {/* Hầm hàng  */}

                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <TextField
                                    name="ham_hang"
                                    label="Hầm hàng"
                                    type="text"
                                    variant="filled"
                                    size='small'
                                    value={data.ham_hang}
                                    onChange={handleChangeInput}
                                    fullWidth
                                />
                            </Box>

                            {/* Loại kho */}

                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <Autocomplete
                                    size='small'
                                    // value={data.ham_hang}
                                    disablePortal
                                    id="combo-box-demo"
                                    freeSolo
                                    options={loaiKhoOptions}
                                    getOptionLabel={(option: any) => option?.name}
                                    value={
                                        data.kho_id ? loaiKhoOptions.find((item: any) => {
                                            return item.id === data.kho_id
                                        }) : null
                                    }
                                    sx={{ width: 300 }}
                                    onChange={(e: any, newValue: any) => {
                                        handleChangeInput({
                                            target: {
                                                value: newValue.id,
                                                name: "kho_id"
                                            }
                                        })
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Loại kho" variant='filled' />}
                                />
                            </Box>

                            {/* Tên Kho */}

                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <TextField
                                    fullWidth
                                    name="kho_name"
                                    label="Tên kho"
                                    type="text"
                                    variant="filled"
                                    size='small'
                                    value={data.kho_name}
                                    onChange={handleChangeInput}
                                    disabled={props.action === "DETAIL"}
                                />

                            </Box>

                            {/* Tên tài xế */}

                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <Autocomplete
                                    size='small'
                                    // value={data.ham_hang}
                                    disablePortal
                                    id="combo-box-demo"
                                    freeSolo
                                    options={taiXeOptions}
                                    getOptionLabel={(option: any) => option?.name}
                                    value={{
                                        id: data.ma_tai_xe ?? "",
                                        name: data.ten_tai_xe ?? ""
                                    }}
                                    sx={{ width: 300 }}
                                    onChange={(e: any, newValue: any) => {
                                        setData({
                                            ...data,
                                            ma_tai_xe: newValue.id,
                                            ten_tai_xe: newValue.name,
                                        } as KscPhieuKiemSoatModel);
                                    }}
                                    inputValue={data.ten_tai_xe ?? ""}
                                    onInputChange={(e, value) => {
                                        console.log(value, "value on input change")
                                        setData({
                                            ...data,
                                            ten_tai_xe: value
                                        } as KscPhieuKiemSoatModel)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Tên tài xế" variant='filled' />}
                                />
                            </Box>

                            {/* Biển số xe */}

                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <Autocomplete
                                    size='small'
                                    // value={data.ham_hang}
                                    disablePortal
                                    id="combo-box-demo"
                                    freeSolo
                                    options={bienSoOptions}
                                    // getOptionLabel={(option: any) => option?.name}
                                    value={
                                        data.bien_so ?? ""
                                    }
                                    sx={{ width: 300 }}
                                    onInputChange={(e: any, newValue: any) => {
                                        setData({
                                            ...data,
                                            bien_so: newValue
                                        } as KscPhieuKiemSoatModel)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Biển số xe" name="bien_so" variant='filled' />}
                                />
                            </Box>

                            {/* Số Remooc */}

                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <Autocomplete
                                    size='small'
                                    // value={data.ham_hang}
                                    disablePortal
                                    id="combo-box-demo"
                                    freeSolo
                                    options={remoocOptions}
                                    getOptionLabel={(option: any) => option?.name}
                                    value={{
                                        id: data.ma_remooc ?? "",
                                        name: data.so_remooc ?? ""
                                    }}
                                    sx={{ width: 300 }}
                                    onChange={(e: any, newValue: any) => {
                                        setData({
                                            ...data,
                                            ma_remooc: newValue.id,
                                            so_remooc: newValue.name,
                                        } as KscPhieuKiemSoatModel);
                                    }}
                                    inputValue={data.so_remooc ?? ""}
                                    onInputChange={(e, value) => {
                                        console.log(value, "value on input change")
                                        setData({
                                            ...data,
                                            so_remooc: value
                                        } as KscPhieuKiemSoatModel)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Số Remooc" variant='filled' />}
                                />
                            </Box>

                            {/* Ngày chứng từ */}
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker

                                        label="Ngày chứng từ"
                                        inputFormat={"DD/MM/YYYY"}

                                        value={data.ngay_chung_tu ?? null}
                                        onChange={(value) => {
                                            handleChangeDate({
                                                value: value,
                                                name: 'ngay_chung_tu'
                                            })
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                            size='small'
                                            variant='filled'
                                            fullWidth
                                            placeholder='DD/MM/YYYY'
                                            name="ngay_chung_tu" />}
                                    />
                                </LocalizationProvider>
                            </Box>

                            {/* Ca giám sát */}
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>

                                <Autocomplete
                                    id="country-select-demo"
                                    sx={{ width: "100%" }}
                                    options={caOptions}
                                    autoHighlight
                                    getOptionLabel={(option: any) => option?.name}
                                    value={
                                        data?.ma_ca_giam_sat
                                            ? caOptions.find(
                                                (item: any) => item.id === data.ma_ca_giam_sat
                                            )
                                            : null
                                    }
                                    onChange={(e, newValue: any) => {
                                        handleChangeInput({
                                            target: {
                                                name: "ma_ca_giam_sat",
                                                value: newValue?.id ? newValue.id : null,
                                            },
                                        });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                            variant='filled'
                                            label="Ca giám sát"
                                        />
                                    )}
                                    disabled={props.action === "DETAIL"}
                                />
                            </Box>
                        </div>

                        {/* Column 3 */}

                        <div className="col-xl-4">
                            {/* số phiếu cân */}


                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <TextField
                                    name="so_phieu_can"
                                    label="Số phiếu cân"
                                    type="text"
                                    variant="filled"
                                    size='small'
                                    value={data.so_phieu_can}
                                    onChange={handleChangeInput}
                                    fullWidth
                                />
                            </Box>

                            {/* Kiểu cân */}

                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <Autocomplete
                                    size='small'
                                    // value={data.ham_hang}
                                    disablePortal
                                    id="combo-box-demo"
                                    freeSolo
                                    options={kieuCanOptions}
                                    getOptionLabel={(option: any) => option.name}
                                    value={
                                        data.kieu_can_id ? loaiHangOptions.find((item: any) => {
                                            return item.id === data.kieu_can_id
                                        }) : null
                                    }
                                    sx={{ width: 300 }}
                                    onChange={(e: any, newValue: any) => {
                                        handleChangeInput({
                                            target: {
                                                value: newValue.id ?? null,
                                                name: "kieu_can_id"
                                            }
                                        })
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Kiểu cân" variant='filled' />}
                                />
                            </Box>

                            {/* Khối lượng bì */}

                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <TextField
                                    sx={{ textAlign: 'end' }}
                                    fullWidth
                                    name="khoi_luong_can_lan_1"
                                    label="K.L bì"
                                    type="tel"
                                    variant="filled"
                                    size='small'
                                    value={data.khoi_luong_can_lan_1}
                                    onChange={handleChangeInput}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                                    }}
                                    disabled={props.action === "DETAIL"}

                                />

                            </Box>

                            {/* Thời gian cân bì */}


                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker

                                        label="Thời gian cân bì"
                                        inputFormat={"DD/MM/YYYY"}

                                        value={data.ngay_can_1 ?? null}
                                        onChange={(value) => {
                                            handleChangeDate({
                                                value: value,
                                                name: 'ngay_can_1'
                                            })
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                            size='small'
                                            variant='filled'
                                            fullWidth
                                            placeholder='DD/MM/YYY'
                                            name="ngay_can_1" />}
                                    />
                                </LocalizationProvider>
                            </Box>
                            {/* Khối lượng tổng */}

                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <TextField
                                    sx={{ textAlign: 'end' }}
                                    fullWidth
                                    name="khoi_luong_can_lan_2"
                                    label="K.L tổng"
                                    type="tel"
                                    variant="filled"
                                    size='small'
                                    value={data.khoi_luong_can_lan_2}
                                    onChange={handleChangeInput}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                                    }}
                                    disabled={props.action === "DETAIL"}

                                />

                            </Box>

                            {/* Thời gian cân tổng */}


                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker

                                        label="Thời gian cân tổng"
                                        inputFormat={"DD/MM/YYYY"}

                                        value={data.ngay_can_2 ?? null}
                                        onChange={(value) => {
                                            handleChangeDate({
                                                value: value,
                                                name: 'ngay_can_2'
                                            })
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                            size='small'
                                            variant='filled'
                                            fullWidth
                                            placeholder='DD/MM/YYY'
                                            name="ngay_can_2"
                                        />}

                                        disabled={props.action === "DETAIL"}
                                    />
                                </LocalizationProvider>
                            </Box>

                            {/* Khối lượng hàng */}

                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px 0' }}>
                                <TextField
                                    sx={{ textAlign: 'end' }}
                                    fullWidth
                                    name="khoi_luong_hang"
                                    label="Khối lượng hàng"
                                    type="tel"
                                    variant="filled"
                                    size='small'
                                    value={data.khoi_luong_hang}
                                    onChange={handleChangeInput}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                                    }}
                                    disabled={props.action === "DETAIL"}

                                />

                            </Box>
                        </div>

                    </div>
                </DialogContent>
                <DialogActions>
                    {props.action !== "DETAIL" && (
                        <Button variant="contained" onClick={handleSubmit}>
                            Lưu
                        </Button>
                    )}
                    <Button onClick={props.onClose}>Hủy</Button>
                </DialogActions>
                <BlockUI blocking={loading} title={""}></BlockUI>
            </Dialog>
        </div >
    )
}

export default CanXeTest;