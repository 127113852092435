const _ = require("lodash");

export const FORMINPUT = [
  {
    TAGID: "1",
    NAME: "TITLE",
    PLACEHOLDER: "Title",
    INPUTTYPE: "input",
  },
  {
    TAGID: "2",
    NAME: "TITLECOLOR",
    PLACEHOLDER: "Title color",
    INPUTTYPE: "input",
  },
  {
    TAGID: "3",
    NAME: "CONTENT",
    PLACEHOLDER: "Content",
    INPUTTYPE: "textarea",
  },
  {
    TAGID: "4",
    NAME: "CONTENTCOLOR",
    PLACEHOLDER: "Content color",
    INPUTTYPE: "input",
  },
  {
    TAGID: "5",
    NAME: "SHOWBTN",
    PLACEHOLDER: "Show button",
    INPUTTYPE: "boolean",
  },
  {
    TAGID: "6",
    NAME: "BTNCOLOR",
    PLACEHOLDER: "Button color",
    INPUTTYPE: "input",
  },
  {
    TAGID: "7",
    NAME: "BTNTEXT",
    PLACEHOLDER: "Button text",
    INPUTTYPE: "input",
  },
  {
    TAGID: "8",
    NAME: "BTNLINK",
    PLACEHOLDER: "Link",
    INPUTTYPE: "input",
  },
  {
    TAGID: "9",
    NAME: "IMGURL",
    PLACEHOLDER: "Image URL",
    INPUTTYPE: "file",
  },
  {
    TAGID: "10",
    NAME: "TAG",
    PLACEHOLDER: "Tag",
    INPUTTYPE: "input",
  },
  {
    TAGID: "11",
    NAME: "POSITIONCONTENT",
    PLACEHOLDER: "Position content",
    INPUTTYPE: "input",
  },
  {
    TAGID: "12",
    NAME: "IMGTYPE",
    PLACEHOLDER: "Image type",
    INPUTTYPE: "input",
  },
  {
    TAGID: "13",
    NAME: "LOGOURL",
    PLACEHOLDER: "Logo URL",
    INPUTTYPE: "file",
  },
  {
    TAGID: "14",
    NAME: "LANGUAGEID",
    PLACEHOLDER: "Language",
    INPUTTYPE: "SelectOption",
  },
  {
    TAGID: "15",
    NAME: "TEXTEDITOR",
    PLACEHOLDER: "",
    INPUTTYPE: "TEXTEDITOR",
  },
  {
    TAGID: "16",
    NAME: "ROWEXPAND",
    PLACEHOLDER: "Row Expand",
    INPUTTYPE: "HIDECHECKBOX",
  },
];

export const FORMBYTYPEID = [
  {
    TYPEID: "1",
    LSTTAGID: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "11"],
  },
  {
    TYPEID: "2",
    LSTTAGID: ["1", "2", "16"],
    FORMCHILD: ["1", "2", "8", "9"],
  },
  {
    TYPEID: "3",
    LSTTAGID: ["1", "2", "16"],
    FORMCHILD: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
  },
  {
    TYPEID: "4",
    LSTTAGID: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "11", "12"],
  },
  {
    TYPEID: "5",
    LSTTAGID: ["1", "4", "16"],
    FORMCHILD: ["1", "2", "3", "4"],
  },
  {
    TYPEID: "6",
    LSTTAGID: ["1", "2", "3", "4", "9", "11", "12"],
  },
  {
    TYPEID: "7",
    LSTTAGID: ["3", "4", "9"],
  },
  {
    TYPEID: "9",
    LSTTAGID: ["1", "2", "3", "4", "9", "13"],
  },
  {
    TYPEID: "8",
    LSTTAGID: ["15"],
  },
  {
    TYPEID: "10",
    LSTTAGID: ["1", "2", "3", "9"],
  },
  {
    TYPEID: "11",
    LSTTAGID: ["9"],
  },
  {
    TYPEID: "12",
    LSTTAGID: ["1", "2","4", "16"],
    FORMCHILD: ["1", "2", "3", "4", "8"],
  },
  {
    TYPEID: "13",
    LSTTAGID: ["1", "2", "3", "4", "16"],
    FORMCHILD: ["1", "2", "3", "4", "6", "7", "8", "16"],
    FORMCHILDLV2: ["1", "2"],
  },
  {
    TYPEID: "14",
    LSTTAGID: ["1", "16"],
    FORMCHILD: ["1", "2", "3", "4", "8", "9"],
  },
  {
    TYPEID: "15",
    LSTTAGID: ["1"],
  },
  {
    TYPEID: "16",
    LSTTAGID: ["1", "2", "3", "4", "16"],
    FORMCHILD: ["1", "2", "3", "4", "6", "8", "9"],
  },
  {
    TYPEID: "17",
    LSTTAGID: ["1", "2", "15"],
  },
  {
    TYPEID: "18",
    LSTTAGID: ["1", "2", "6", "7", "8"],
  },
];

export const LIST_MODULE = [
  "Sales",
  "ERP",
  "Warehouse",
  "HR",
  "Forwarding",
  "Financial",
];
export const increaseDetailID = (detailID) => {
  var str = (parseInt(detailID || 0) + 1).toString();
  var pad = "00";
  var ans = pad.substring(0, pad.length - str.length) + str;
  return ans + ".00";
};

export const increaseDetailChildID = (detailID) => {
  var str = (parseFloat(detailID) + 0.01).toString();
  var pad = "00000";
  var ans = pad.substring(0, pad.length - str.length) + str;
  return "0" + parseFloat(ans).toFixed(2);
};
export const increaseMenuChildID = (MenuId) => {
  let subStr1 = MenuId.substring(0, 3);
  let subStr2 = MenuId.substring(3, 8);
  var str = (parseFloat(subStr2) + 0.02).toString();
  var pad = "00000";
  var ans = pad.substring(0, pad.length - str.length) + str;
  return subStr1 + "0" + parseFloat(ans).toFixed(2);
};
export const increaseMenuID = (MenuId) => {
  var str = (parseInt(MenuId || 0) + 1).toString();
  var pad = "00";
  var ans = pad.substring(0, pad.length - str.length) + str;
  return ans + ".00";
};
export const toSlug = (str) => {
  // Chuyển hết sang chữ thường
  str = str.toLowerCase();

  // xóa dấu
  str = str
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

  // Thay ký tự đĐ
  str = str.replace(/[đĐ]/g, "d");

  // Xóa ký tự đặc biệt
  str = str.replace(/([^0-9a-z-\s])/g, "");

  // Xóa khoảng trắng thay bằng ký tự -
  str = str.replace(/(\s+)/g, "-");

  // Xóa ký tự - liên tiếp
  str = str.replace(/-+/g, "-");

  // xóa phần dư - ở đầu & cuối
  str = str.replace(/^-+|-+$/g, "");

  // return
  return str;
};

export const toUpperPropertyNameByArray = (array) => {
  if (!_.isEmpty(array)) {
    _.filter(array, (item) => {
      for (var key in item) {
        var upper = key.toUpperCase();
        if (upper !== key) {
          item[upper] = item[key];
          delete item[key];
        }
      }
    });
  }
  return array;
};