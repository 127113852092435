import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Select from "../../../../../components/DHS/DHS_Select";
import DHSDate from "../../../../../components/DHS/DHS-Date/DHSDateComponent";
import { TransitionProps } from "@mui/material/transitions";
import "./OvertimeForm.css";
import { AppSession } from "../../../../shared/app-session/app-session";
import {
  HRClient,
  OvertimeForm,
  HRWorkShift,
} from "../../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../shared/service-proxies/config";
import { AppBar, Button, IconButton, Toolbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import notification from "../../../../../common/notification/notification";
import DHSDateTime from "../../../../../components/DHS/DHS-DateTime/DHSDateTimeComponent";

interface IProps {
  open: boolean;
  actionType: string;
  itemClicked: any;
  onClose: (open: any) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});
var appSession: AppSession;

export default function OvertimeFormDialog(props: IProps) {
  const hrClient = new HRClient(appSession, BASE_URL_API);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [_id, setId] = React.useState<string | undefined>();
  const [data, setData] = React.useState<OvertimeForm>({
    ...new OvertimeForm(),
    fromtime: new Date(),
    totime: new Date(),
  } as OvertimeForm);
  const [isViewDetail, activeViewDetail] = React.useState<boolean>(false);
  const [workshiftOptions, setWorkshiftOptions] = React.useState<any[]>([])

  React.useEffect(() => {
    if (props.actionType === "EDIT" || props.actionType === "VIEWDETAIL") {
      hrClient
        .hROnLeaveGet({
          ...new OvertimeForm(),
          id: props.itemClicked,
          ma_cty: SessionLogin.ma_cty,
          sender_code: SessionLogin.employeeCode,
        } as OvertimeForm)
        .then((response) => {
          setData(response[0]);
        });
      if (props.actionType === "VIEWDETAIL") {
        activeViewDetail(true);
      }
    }
  }, [props.actionType]);
  React.useEffect(() => {
    setId(props.itemClicked);
  }, [props.itemClicked]);
  React.useEffect(() => {
    hrClient.list_work_shift_get({
      ...new HRWorkShift(),
      ma_cty: SessionLogin.ma_cty,
    } as HRWorkShift).then((response) => {
      setWorkshiftOptions(response);
    });
  }, []);
  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value } as OvertimeForm);
  };
  const handleChangeFrom = (e: any) => {
    setData({ ...data, fromtime: e } as OvertimeForm);
  };
  const handleChangeTo = (e: any) => {
    setData({ ...data, totime: e } as OvertimeForm);
  };
  const handleSubmit = () => {
    if (_id && _id.length > 0) {
      hrClient
        .hROnLeaveUpd({
          ...data,
          id: _id,
          sender_code: SessionLogin.employeeCode,
        } as OvertimeForm)
        .then((res) => {
          if (res[0].ret === 0) {
            notification("success", res[0].message);
          } else if (res[0].ret === 1) {
            notification("error", res[0].message);
          } else {
            notification("warning", res[0].message);
          }
        });
    } else {
      hrClient
        .hROnLeaveIns({
          ...data,
          ma_cty: SessionLogin.ma_cty,
          sender_code: SessionLogin.employeeCode,
        } as OvertimeForm)
        .then((res) => {
          if (res[0].ret === 0) {
            setId(res[0].id);
            notification("success", res[0].message);
          } else if (res[0].ret === 1) {
            notification("error", res[0].message);
          } else {
            notification("warning", res[0].message);
          }
        });
    }
  };
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        TransitionComponent={Transition}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {props.actionType === "ADD"
                ? "Tạo phiếu đăng ký tăng ca"
                : props.actionType === "EDIT"
                ? "Cập nhật phiếu đăng ký tăng ca"
                : props.actionType === "VIEWDETAIL"
                ? "Chi tiết phiếu đăng ký tăng ca"
                : "Đang tải nội dung"}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSubmit}>
              Lưu
            </Button>
          </Toolbar>
        </AppBar>
        <div className="d-flex">
        <div className="w-100 p-4">
          <div style={{ backgroundColor: "white" }} className="p-3 d-flex">
            <div className="w-100 h-100">
              <div className="row ">
                <div className="form-group col-xl-6">
                  <label>Người gửi</label>
                  <input
                    className="flex-grow-1 form-control"
                    placeholder="Người gửi"
                    value={
                      data.sender_name
                        ? data.sender_name
                        : SessionLogin.userName
                    }
                    disabled={true}
                  />
                </div>
                <div className="form-group col-xl-6">
                  <Select
                    label="Ca làm việc"
                    value={data.work_shift_id}
                    options={workshiftOptions}
                    onChange={handleChangeInput}
                    disabled={isViewDetail}
                    fieldValue={"work_shift_id"}
                    fieldDisplay={"work_shift_name"}
                    name="work_shift_id"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col">
                  <DHSDateTime
                    label="Từ"
                    className="flex-grow-1 form-control"
                    name="fromtime"
                    onChange={handleChangeFrom}
                    value={data.fromtime?.toString()}
                    disabled={isViewDetail}
                  />
                </div>
                <div className="form-group col">
                  <DHSDateTime
                    label="Đến"
                    className="flex-grow-1 form-control"
                    name="totime"
                    onChange={handleChangeTo}
                    value={data.totime?.toString()}
                    disabled={isViewDetail}
                  />
                </div>
                <div className="form-group col">
                  <label>Tổng giờ</label>
                  <input
                    className="flex-grow-1 form-control"
                    type="number"
                    name="sumarytime_overtime"
                    onChange={handleChangeInput}
                    value={data.sumarytime_overtime}
                    disabled={isViewDetail}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Ghi chú</label>
                <textarea
                  className="flex-grow-1 form-control"
                  name="note"
                  onChange={handleChangeInput}
                  value={data.note}
                  disabled={isViewDetail}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      </Dialog>
    </div>
  );
}
