import { AutoComplete, Button, Card, Col, Form, Input, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import "./index.css";
import { debounce } from "lodash";
import { HomeClient, QLCCApartmentBuildingModel, QLCCApartmentModel, QLCCBlockModel, QLCCClient, SysModel, UserInfoModel } from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import { AppSession } from "../../shared/app-session/app-session";
import http from "./http-common";
import { Link } from "react-router-dom";
import { CloseOutlined } from '@ant-design/icons'

var appSession: AppSession;


const status_message: string[] = ['success', 'error']
type NotificationType = 'success' | 'info' | 'warning' | 'error';


const ResidentRegister: React.FC<{}> = () => {

    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    // const [requiredMark, setRequiredMarkType] = useState<RequiredMark>('optional');

    // const onRequiredTypeChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
    //     setRequiredMarkType(requiredMarkValue);
    // };

    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);

    //   

    const [apartmentBuildingOptions, setApartmentBuildingOptions] = React.useState<any[]>([]);
    const [apartmentOptions, setApartmentOptions] = React.useState<any[]>([]);


    // 

    const [data, setData] = useState<UserInfoModel>({

        employeecode: "",
        group_id: "BCE0CDF9-CD2D-4659-A4BE-2C2F967D50FE",
        isadmin: false,
    } as UserInfoModel);



    // 

    const GetBlock = React.useCallback(async () => {
        var response = await http.post<QLCCApartmentBuildingModel[]>(`Home/qlccApartmentBuildingGet`, { ...new QLCCApartmentBuildingModel(), action_type: 'SEARCH' } as QLCCApartmentBuildingModel);



        let tempData: any = [];
        tempData = response.data.map(apartmentBuilding => {
            return {
                key: apartmentBuilding.id,
                value: apartmentBuilding.name
            }
        })
        setApartmentBuildingOptions(tempData)
        console.log(response)

    }, []);

    useEffect(() => {
        GetBlock();

    }, [GetBlock]);

    const onFinish = React.useCallback(async (values: UserInfoModel) => {
        // console.log('Success:', values);

        // const body = Object.assign({}, data,);

        var response = await http.post<SysModel>(`Home/Register`, {
            ...new UserInfoModel(),
            ...data,
            username: data?.username?.toUpperCase(),
            employeecode: "",
            title_name: "Cư dân",
            group_id: "BCE0CDF9-CD2D-4659-A4BE-2C2F967D50FE",
            isadmin: false,
            cuser: data?.username?.toUpperCase(),
            luser: data?.username?.toUpperCase(),
            isblock: true
        } as UserInfoModel
        );

        if (response.data.status === 0) {
            api["success"]({
                message: "Đăng ký thành công",
                description: "Bạn đã đăng ký thành công! Vui lòng chờ duyệt"

            });
        }
        else {
            api["error"]({
                message: "Đăng ký không thành công",
                description: response.data.message

            });
        }
    }, [data]);

    const onFinishFailed = (errorInfo: any) => {

        // api["error"]({
        //     message: "Đăng ký không thành công",
        //     description: "Vui lòng xem lại các ô nhập!"

        // });

    }
    const ApartmentBuildingSearchInput = debounce(async (text: any) => {

        var response = await http.post<QLCCBlockModel[]>(`Home/qlccApartmentBuildingGet`, { ...new QLCCApartmentBuildingModel(), name: text } as QLCCApartmentBuildingModel);



        let tempData: any = [];
        tempData = response.data.map(apartmentBulding => {
            return {
                key: apartmentBulding.id,
                value: apartmentBulding.name
            }
        })
        setApartmentBuildingOptions(tempData)
        console.log(response)

        // setData({ ...data, [name]: value } as QLCCVehicleModel);
    }, 500);

    const ApartmentSearchInput = React.useCallback(debounce(async (text: any) => {

        var response = await http.post<QLCCApartmentModel[]>(`Home/QLCCApartmentsGet`, {
            ...new QLCCApartmentModel(),
            name: text,
            apartment_building_id: data?.apartment_building_id,
            // action_type: 'SEARCH_BY_BLOCK'

        } as QLCCApartmentModel);

        let tempData: any = [];
        tempData = response.data.map(apartment => {
            return {
                key: apartment.id,
                value: apartment.name
            }
        })
        setApartmentOptions(tempData)
        // qLCCClient.qLCCApartmentsGet(
        //     {
        //         ...new QLCCApartmentModel(),
        //         name: text,
        //         apartment_building_id: data?.apartment_building_id,
        //         action_type: 'SEARCH_BY_BLOCK'
        //     } as QLCCApartmentModel
        // ).then(res => {
        //     let tempData: any = [];
        //     tempData = res.map(apartment => {
        //         return {
        //             key: apartment.id,
        //             value: apartment.name
        //         }
        //     })
        //     setApartmentOptions(tempData)
        //     console.log(res)
        // });

        // setData({ ...data, [name]: value } as QLCCVehicleModel);
    }, 500), []);

    const onApartmentBuildingSelect = async (data: string, option: any) => {

        setData(pre => ({ ...pre, apartment_building_id: option.key } as UserInfoModel));

        var response = await http.post<QLCCApartmentModel[]>(`Home/QLCCApartmentsGet`, {
            ...new QLCCApartmentModel(),
            apartment_building_id: option.key,
            // action_type: "SEARCH_BY_BLOCK"
        } as QLCCApartmentModel);

        let tempData: any = [];
        tempData = response.data.map(apartment => {
            return {
                key: apartment.id,
                value: apartment.name
            }
        })
        setApartmentOptions(tempData)



    };

    const onApartmentBuildingChange = (data: any, option: any) => {
        // console.log(option, "option")
    };

    const onApartmentSelect = (data: string, option: any) => {

        setData(pre => ({ ...pre, apartment_id: option.key } as UserInfoModel));



    };

    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;


        setData(pre => ({ ...pre, [name]: value } as UserInfoModel));
    };


    const onApartmentChange = (data: any, option: any) => {
        // console.log(option, "option")
    };

    return (

        <div className="position-relative container-fluid">
            {contextHolder}
            <div className="register-body" > </div>
            <div className="row">
                <div className="col-xl-4 mx-auto ">

                    <Card
                        bordered={false}
                        className="my-3"
                        title={<div className="d-flex justify-content-between align-items-center " >
                            <span className="card-title ">Đăng ký tài khoản Cư dân</span>
                            <Link className="position-absolute"  style={{top: "15px", right: "5%"}} to={"/admin/login"}><CloseOutlined style={{ fontSize: "22px", color: '#1890ff' }} /></Link>
                        </div>}
                        style={{ borderRadius: "25px", padding: "1rem", }}>
                        <Form
                            name="basic"
                            form={form}
                            layout="vertical"
                            labelAlign="left"
                            // initialValues={{ requiredMarkValue: requiredMark }}
                            // onValuesChange={onRequiredTypeChange}

                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        // requiredMark={requiredMark}
                        // labelCol={{ span: 8 }}
                        >


                            <Form.Item
                                label="Chọn dự án"
                                name="apartment_building_id"
                                hasFeedback
                                colon={false}
                                // required 
                                // tooltip="Thông tin mã khách hàng là bắt buộc"
                                rules={[
                                    { required: true, message: 'Toà nhà là bắt buộc!' },
                                    {
                                        validator(rule, value, callback) {

                                            if (apartmentBuildingOptions.find((apartmentBuilding) => apartmentBuilding.value === value)) {
                                                callback();
                                            }
                                            else {
                                                callback("Giá trị hiện tại không phải dự án")
                                            }

                                        },
                                    }
                                ]}

                            >

                                <AutoComplete

                                    size="large"
                                    value={data?.apartment_building_id}
                                    options={apartmentBuildingOptions}
                                    onSelect={onApartmentBuildingSelect}
                                    onSearch={ApartmentBuildingSearchInput}
                                    onChange={onApartmentBuildingChange}
                                    placeholder="Chọn dự án"
                                />
                            </Form.Item>
                            <Form.Item
                                label="Chọn căn hộ"
                                name="apartment_id"
                                hasFeedback
                                // required 
                                // tooltip="Thông tin mã khách hàng là bắt buộc"
                                rules={[
                                    { required: true, message: 'Căn hộ là bắt buộc!' },
                                    {
                                        validator(rule, value, callback) {

                                            if (apartmentOptions.find((apartment) => apartment.value === value)) {
                                                callback();
                                            }
                                            else {
                                                callback("Giá trị hiện tại không phải căn hộ")
                                            }

                                        },
                                    }
                                ]}

                            >

                                <AutoComplete

                                    size="large"
                                    value={data?.apartment_id}
                                    options={apartmentOptions}
                                    onSelect={onApartmentSelect}
                                    onSearch={ApartmentSearchInput}
                                    onChange={onApartmentChange}
                                    placeholder="Chọn căn hộ"
                                />
                            </Form.Item>

                            {/* <Form.Item
                                label="Mã căn hộ"
                                name="apartment_id"
                                hasFeedback
                                // required 
                                // tooltip="Thông tin Mã căn hộ là bắt buộc"
                                rules={[{ required: true, message: 'Thông tin Mã căn hộ là bắt buộc!' }]}

                            >

                                <Input
                                    placeholder="Mã căn hộ trên thông báo phí"
                                    size="large"
                                    name="apartment_id"
                                    onChange={handleChangeInput}
                                />
                            </Form.Item> */}
                            {/* <Form.Item
                                label="Mã khách hàng"
                                name="customer_id"
                                hasFeedback
                                // required 
                                // tooltip="Thông tin mã khách hàng là bắt buộc"
                                rules={[{ required: true, message: 'Thông tin mã khách hàng là bắt buộc!' }]}

                            >

                                <Input
                                    placeholder="Mã khách hàng trên thông báo phí"
                                    size="large"
                                    name="customer_id"
                                    onChange={handleChangeInput}
                                />
                            </Form.Item> */}
                            <Form.Item
                                label="Tên đăng nhập"
                                name="username"
                                hasFeedback
                                
                                // required
                                // tooltip={{ title: 'Tên đăng nhập không thể trống', icon: <InfoCircleOutlined /> }}
                                rules={[{ required: true, message: 'Tên đăng nhập không thể trống!' }]}
                            >
                                <Input autoComplete="off" placeholder="Tên đăng nhập" size="large" value={data.username} name="username" onChange={handleChangeInput} />
                            </Form.Item>

                            <Form.Item
                                label="Mật khẩu"
                                name="password"
                                hasFeedback
                                // required
                                // tooltip={{ title: 'Mật khẩu không thể trống', icon: <InfoCircleOutlined /> }}
                                rules={[{ required: true, message: 'Mật khẩu không thể trống!' }]}

                            >
                                <Input.Password autoComplete="off"  placeholder="Mật khẩu" value={data.password} name="password" size="large" />
                            </Form.Item>
                            <Form.Item
                                label="Nhập lại mật khẩu"
                                name="re-password"
                                hasFeedback
                                dependencies={['password']}
                                // required
                                // tooltip={{ title: 'Mật khẩu nhập lại không thể trống', icon: <InfoCircleOutlined /> }}
                                rules={[
                                    { required: true, message: 'Mật khẩu nhập lại không thể trống!' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Mật khẩu nhập lại không chính xác!'));
                                        },
                                    }),
                                ]}

                            >
                                <Input.Password placeholder="Nhập lại mật khẩu" size="large"  name="password" onChange={handleChangeInput} />
                            </Form.Item>

                            <Form.Item
                                label="Họ tên"
                                required
                                name="fullname"
                                hasFeedback
                                // tooltip={{ title: 'Họ tên không thể trống', icon: <InfoCircleOutlined /> }}
                                rules={[{ required: true, message: 'Họ tên không thể trống!' }]}

                            >
                                <Input autoComplete="off"  placeholder="Họ tên" size="large" name="fullname" value={data.fullname} onChange={handleChangeInput} />
                            </Form.Item>

                            <Form.Item
                                label="Số điện thoại"
                                required
                                name="phone"
                                hasFeedback
                                // tooltip={{ title: 'Họ tên không thể trống', icon: <InfoCircleOutlined /> }}
                                rules={[
                                    { required: true, message: 'Số điện thoại không thể trống!' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            const regex = /(03|05|07|08|09)+([0-9]{8})\b/;
                                            if (value && !regex.test(value)) {
                                                return Promise.reject(new Error('Số điện thoại không hợp lệ'));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}

                            >
                                <Input autoComplete="off"  placeholder="Số điện thoại" size="large" name="phone" value={data.phone} onChange={handleChangeInput} />
                            </Form.Item>

                            <Form.Item
                                label="Email"
                                name="email"
                                hasFeedback

                            // required
                            // tooltip={{ title: 'Email không thể trống', icon: <InfoCircleOutlined /> }}
                            // rules={[
                            //     { required: true, message: 'Email không thể trống!' },
                            //     {
                            //         type: "email",
                            //         message: "Email không đúng định dạng!"
                            //     }
                            // ]}

                            >
                                <Input autoComplete="off" placeholder="Email" size="large" type="email" value={data.email} name="email" onChange={handleChangeInput} />
                            </Form.Item>
                            <Form.Item className="pt-3">
                                <Button type="primary" style={{ borderRadius: 5, backgroundColor: "#1890ff" }} size="large" className="w-100" htmlType="submit">Đăng ký</Button>
                            </Form.Item>
                        </Form>

                        <div className="d-flex justify-content-end">
                            <span className="px-2">Bạn đã có tài khoản?</span>
                            <Link to={"/admin/login"}>Đăng nhập</Link>
                        </div>
                    </Card>
                </div>
            </div>
        </div>


    )
}

export default ResidentRegister;