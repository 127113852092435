import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { AppSession } from "../../../../shared/app-session/app-session";
import {
  HRClient,
  HRWorkShift,
  OvertimeForm,
  RoleRightWebModel,
} from "../../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../shared/service-proxies/config";
import Select from "../../../../../components/DHS/DHS_Select";
import notification from "../../../../../common/notification/notification";
import DHSDateTime from "../../../../../components/DHS/DHS-DateTime/DHSDateTimeComponent";
import { now } from "lodash";

interface IProps {
  id?: string;
  action: RoleRightWebModel | undefined;
  nowTime: Date;
  onDestroyAction?: (isDestroy: boolean, goBack?: number) => void;
}
var appSession: AppSession;

const OvertimeFormActionPage = (props: IProps) => {
  const hrClient = new HRClient(appSession, BASE_URL_API);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [back, setBack] = React.useState<number>(-1);
  const [data, setData] = React.useState<OvertimeForm>({
    ...new OvertimeForm(),
    fromtime: new Date(),
    totime: new Date(),
  } as OvertimeForm);
  const [{ _id }, setId] = React.useState(useParams<{ _id: string }>());
  const history = useHistory();
  const [isViewDetail, activeViewDetail] = React.useState<boolean>(false);
  const [workshiftOptions, setWorkshiftOptions] = React.useState<any[]>([])
  // const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

  React.useEffect(() => {
    if (
      props.action &&
      props.action.class_name?.toLocaleUpperCase() === "VIEWDETAIL"
    ) {
      activeViewDetail(true);
    } else if (props.action && !props.action.class_name) {
      history.push("/admin");
    }
    if (_id) {
      hrClient
        .hROvertimeGet({
          ...new OvertimeForm(),
          id: _id,
          ma_cty: SessionLogin.ma_cty,
          sender_code: SessionLogin.employeeCode,
        } as OvertimeForm)
        .then((response) => {
          setData(response[0]);
        });
    }
  }, []);
  //Set Options
  React.useEffect(() => {
    hrClient.list_work_shift_get({
      ...new HRWorkShift(),
      ma_cty: SessionLogin.ma_cty,
    } as HRWorkShift).then((response) => {
      setWorkshiftOptions(response);
    });
  }, []);
  React.useEffect(() => {
    if (
      props.action &&
      props.action.class_name?.toLocaleUpperCase() === "SAVE"
    ) {
      try {
        if (_id) {
          hrClient
            .hROvertimeUpd({
              ...data,
              id: _id,
              sender_code: SessionLogin.employeeCode,
              fromtime: new Date(
                (new Date(data.fromtime ? data.fromtime : now()) as any) -
                  new Date().getTimezoneOffset() * 1000 * 60
              ),
              totime: new Date(
                (new Date(data.totime ? data.totime : now()) as any) -
                  new Date().getTimezoneOffset() * 1000 * 60
              ),
            } as OvertimeForm)
            .then((res) => {
              setBack((item) => (item -= 1));
              if (res[0].ret === 0) {
                notification("success", res[0].message);
              } else if (res[0].ret === 1) {
                notification("error", res[0].message);
              } else {
                notification("warning", res[0].message);
              }
            });
        } else {
          hrClient
            .hROvertimeIns({
              ...data,
              ma_cty: SessionLogin.ma_cty,
              sender_code: SessionLogin.employeeCode,
              fromtime: new Date(
                (new Date(data.fromtime ? data.fromtime : now()) as any) -
                  new Date().getTimezoneOffset() * 1000 * 60
              ),
              totime: new Date(
                (new Date(data.totime ? data.totime : now()) as any) -
                  new Date().getTimezoneOffset() * 1000 * 60
              ),
            } as OvertimeForm)
            .then((res) => {
              setBack((item) => (item -= 1));
              if (res[0].ret === 0) {
                setId({ _id: res[0].id ? res[0].id : "" });
                notification("success", res[0].message);
              } else if (res[0].ret === 1) {
                notification("error", res[0].message);
              } else {
                notification("warning", res[0].message);
              }
            });
        }
        if (props.onDestroyAction) props.onDestroyAction(true, back - 1);
      } catch (error) {
        console.log(error);
      }
    }
  }, [props.nowTime]);

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value } as OvertimeForm);
  };
  const handleChangeFrom = (e: any) => {
    setData({ ...data, fromtime: e.toDate() } as OvertimeForm);
  };
  const handleChangeTo = (e: any) => {
    setData({ ...data, totime: e.toDate() } as OvertimeForm);
  };

  return (
    <>
      <div className="d-flex">
        <div className="w-100 p-4">
          <div style={{ backgroundColor: "white" }} className="p-3 d-flex">
            <div className="w-100 h-100">
              <div className="row ">
                <div className="form-group col-xl-6">
                  <label>Người gửi</label>
                  <input
                    className="flex-grow-1 form-control"
                    placeholder="Người gửi"
                    value={
                      data.sender_name
                        ? data.sender_name
                        : SessionLogin.userName
                    }
                    disabled={true}
                  />
                </div>
                <div className="form-group col-xl-6">
                  <Select
                    label="Ca làm việc"
                    value={data.work_shift_id}
                    options={workshiftOptions}
                    onChange={handleChangeInput}
                    disabled={isViewDetail}
                    fieldValue={"work_shift_id"}
                    fieldDisplay={"work_shift_name"}
                    name="work_shift_id"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col">
                  <DHSDateTime
                    label="Từ"
                    className="flex-grow-1 form-control"
                    name="fromtime"
                    onChange={handleChangeFrom}
                    value={data.fromtime?.toString()}
                    disabled={isViewDetail}
                  />
                </div>
                <div className="form-group col">
                  <DHSDateTime
                    label="Đến"
                    className="flex-grow-1 form-control"
                    name="totime"
                    onChange={handleChangeTo}
                    value={data.totime?.toString()}
                    disabled={isViewDetail}
                  />
                </div>
                <div className="form-group col">
                  <label>Tổng giờ</label>
                  <input
                    className="flex-grow-1 form-control"
                    type="number"
                    name="sumarytime_overtime"
                    onChange={handleChangeInput}
                    value={data.sumarytime_overtime}
                    disabled={isViewDetail}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Ghi chú</label>
                <textarea
                  className="flex-grow-1 form-control"
                  name="note"
                  onChange={handleChangeInput}
                  value={data.note}
                  disabled={isViewDetail}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OvertimeFormActionPage;
