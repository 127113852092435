import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {
  Autocomplete,
  Box,
  Dialog,
  IconButton,
  InputBase,
  InputLabel,
  Paper,
  TextField,
} from "@mui/material";
import { HomeClient, ParaSysColumnByTablenameModel, SysColumnByTablenameModel, SysGenRowTableModel } from "../../shared/service-proxies/api-shared";
import { AppSession } from "../../shared/app-session/app-session";
import BASE_URL_API from "../../shared/service-proxies/config";
import { Notification } from "../../../components/layouts";

function not(
  a: readonly SysColumnByTablenameModel[],
  b: readonly SysColumnByTablenameModel[]
) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(
  a: readonly SysColumnByTablenameModel[],
  b: readonly SysColumnByTablenameModel[]
) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(
  a: readonly SysColumnByTablenameModel[],
  b: readonly SysColumnByTablenameModel[]
) {
  return [...a, ...not(b, a)];
}

type IProp = {
  visibleDialogCreateTable: boolean;
  setVisibleDialogCreateTable: React.Dispatch<React.SetStateAction<boolean>>;
};
let appSession: AppSession;
export default function DialogCreateTable(props: IProp) {
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const [tableName, setTableName] = React.useState<string>("");
  const [checked, setChecked] = React.useState<
    readonly SysColumnByTablenameModel[]
  >([]);
  const [left, setLeft] = React.useState<readonly SysColumnByTablenameModel[]>(
    []
  );
  const [right, setRight] = React.useState<
    readonly SysColumnByTablenameModel[]
  >([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: SysColumnByTablenameModel) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly SysColumnByTablenameModel[]) =>
    intersection(checked, items).length;

  const handleToggleAll =
    (items: readonly SysColumnByTablenameModel[]) => () => {
      if (numberOfChecked(items) === items.length) {
        setChecked(not(checked, items));
      } else {
        setChecked(union(checked, items));
      }
    };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (
    title: React.ReactNode,
    items: readonly SysColumnByTablenameModel[]
  ) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: SysColumnByTablenameModel) => {
          const labelId = `transfer-list-all-item-${value.column_name}-label`;

          return (
            <ListItem
              key={value.column_name}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.column_name} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  const handleSearchByTableName = async () => {
    const res = await homeClient.sysGetColumnByTableName({
      ...new ParaSysColumnByTablenameModel(),
      table_name: tableName,

    } as ParaSysColumnByTablenameModel);
    console.log(res);

    setLeft([...res]);
    setRight([]);
  };

  const handleCreateMultilpleRow = async () => {
    if (right.length > 0) {
      const body = right.map((item) => {
        const temp = new SysGenRowTableModel({
          ...new SysGenRowTableModel(),
          table_name: item.table_name,
          column_name: item.column_name,
          number_order: item.position,
          menuid: ''
        } as SysGenRowTableModel);
        return temp;
      });

      const res = await homeClient.sysGenRowTableMultiIns(body);

      if (res) {
        Notification("success", "Thêm thành công", "");
        onClose()
      } else {
        Notification("error", "Thêm thất bại", "");
      }
    } else {
      Notification("warning", "Vui lòng chọn cột", "");
    }
  };

  const onClose = () => {
    props.setVisibleDialogCreateTable(false)
    setTableName('')
    setLeft([]);
    setRight([]);
  }

  return (
    <Dialog open={props.visibleDialogCreateTable}>
      <Box style={{ padding: 25 }}>
        <Box sx={{ display: "flex", mb: 3 }}>
          <Paper
            component="form"
            sx={{
              p: "8px 10px",
              display: "flex",
              alignItems: "center",
              width: 600,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Nhập tên bảng"
              inputProps={{ "aria-label": "search google maps" }}
              value={tableName}
              onChange={(e) => setTableName(e.target.value)}
            />
            <Button onClick={handleSearchByTableName} variant="contained">
              Tìm kiếm
            </Button>
          </Paper>
        </Box>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>{customList("Tất cả cột", left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList("Cột đã chọn", right)}</Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "end", mt: 4 }}>
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{ mx: 3 }}
          >
            Đóng
          </Button>
          <Button disabled={right.length <= 0} onClick={handleCreateMultilpleRow} variant="contained">
            Xác nhận
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
