import React from "react";
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
    QLCCClient,
    CaVch01Client,
    LstBank,
    SysExchange,
    PaymentTerm,
    LstCurrency,
    CommonClient,
    ParaObjExchageRate,
    ParaObj,
    ParaObjRecNo,
    CaVch02Client,
    SoVch02Client,
    ModelProcessca02,
    CaVch02M,
    CaVch02D1,
    CaVch02D0,
    SysActionType,
    ParaObjCheck,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { ICustomer, IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../../actions/ApartmentBuilding.css";
import CTXDate from "../../../../components/CTX/DatePicker";
import CurrencyTextField from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import moment from "moment";
import notification from "../../../../common/notification/notification";
import ReceiptPrintAction from "./PaymentCashPrintAction";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import PaymentCashD from "../payment-cashD";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import DHSAutoCompleteWithDataGrid from "../../../../components/DHS/DHS_AutoCompleteCustomer/DHSAutoCompleteWithDataGrid";
import { httpPost } from "../../../../common/httpService";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import DataGrid from "devextreme-react/data-grid";
import { TransDateTime } from "../../../../components/utils/date.util";
import NumberBox from "devextreme-react/number-box";
import DHSDataGridFilterListByProjectSelect from "../../../../components/DHSUtilComponent/DHSDataGridFilterListByProjectSelect/DHSDataGridFilterListByProjectSelect";
import DHSAutocompleteFilterListSelect from "../../../../components/DHSUtilComponent/DHSAutocompleteFilterListSelect/DHSAutocompleteFilterListSelect";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";

interface IFilter {
    apartment_building_id?: string | null,
    block_id?: string | null,
    floor_id?: string | null,
    apartment_id?: string | null
}

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: ACTIONS | string;
    payload?: any;
    filter?: IFilter;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onAddSuccess: (data_success: CaVch02M) => void;
    onEditSuccess: (data_success: CaVch02M) => void;
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});
interface IAction {
    type: ACTIONS | string;
    open: boolean;
    payload: any;
}

const defaultAction: IAction = {
    type: ACTIONS.EMPTY,
    payload: null,
    open: false,
};

var appSession: AppSession;
const status_message: string[] = ['success', 'error']

const PaymentCashAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [apartmentId, setApartmentId] = React.useState<string>();
    const caVch01Client = new CaVch01Client(appSession, BASE_URL_API);
    const caVch02Client = new CaVch02Client(appSession, BASE_URL_API);
    const commonClient = new CommonClient(appSession, BASE_URL_API);

    const [data, setData] = React.useState<ModelProcessca02>({} as ModelProcessca02);
    const [data02D0, setData02D0] = React.useState<CaVch02D0[]>([]);
    const [data02D1, setData02D1] = React.useState<CaVch02D1[]>([]);
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [bankName, setBankName] = React.useState<string>();
    const [bankCode, setBankCode] = React.useState<string>();

    const [isExpandPaymentCashAction, setIsExpandPaymentCashAction] = React.useState<boolean>(true);
    const [isCalledPaymentCashAction, setIsCalledPaymentCashAction] = React.useState<boolean>(true);
    const [isExpandReceiptDAction, setIsExpandReceiptDAction] = React.useState<boolean>(true);
    const [isCalledMember, setIsCalledCaVchD] = React.useState<boolean>(false);

    const [errors, setErrors] = React.useState<any[]>();
    const [actPrint, setActPrint] = React.useState<IAction>(defaultAction);
    const [fullScreen, setFullscreen] = React.useState<boolean>(false);

    // 

    const [bankOptions, setBankOptions] = React.useState<any[]>([]);
    const [customerOptions, setCustomerOptions] = React.useState<ICustomer[]>([]);

    // 

    const [exchangeOptions, setExchangeOptions] = React.useState<any[]>([]);
    const [paymentTermOptions, setPaymentTermOptions] = React.useState<any[]>([]);
    const [currencyOptions, setCurrencyOptions] = React.useState<any[]>([]);

    // 

    const dataGridRef02D0 = React.useRef<DataGrid>(null);

    React.useEffect(() => {
        if (!props.open) {
            setApartmentId(undefined);

        }
    }, [props.open])


    //#region handleGetOptions
    React.useEffect(() => {
        if (isCalledPaymentCashAction) {
            setLoading(true)
            caVch01Client.sysExchangeGet({
                ...new SysExchange(),
                ma_ct: menu?.code_name,

            } as SysExchange)
                .then(res => {

                    setLoading(false);
                    setExchangeOptions(res)

                });

            // 

            setLoading(true)
            caVch01Client.lstPaymentTermGet({
                ...new PaymentTerm(),
                ma_cty: SessionLogin.ma_cty,
                moduleid: 'CA'
            } as PaymentTerm)
                .then(res => {

                    setLoading(false);
                    setPaymentTermOptions(res)
                });

            // 
            // setLoading(true)
            // caVch01Client.lstCurrencyGet({
            //     ...new LstCurrency(),
            //     ma_cty: SessionLogin.ma_cty,
            // } as LstCurrency)
            //     .then(res => {

            //         setLoading(false);
            //         setCurrencyOptions(res)
            //     });
        }

        // 
        // setLoading(true)
        // const body = {
        //     ma_cty: SessionLogin.ma_cty,
        //     tablelist: "dm_ncc"
        // }
        // httpPost<string>('api/Common/LoadFilterList', body)
        //     .then(res => {

        //         const customers: ICustomer[] = JSON.parse(res.data);
        //         setCustomerOptions(customers)
        //         setLoading(false)

        //     });


    }, []);

    React.useEffect(() => {
        if (data?.modelM?.mA_HTTT === 'CK' && bankOptions.length <= 0) {
            caVch01Client.lstBankGet({
                ...new LstBank(),
                ma_cty: SessionLogin.ma_cty,
                apartment_building_id: props.filter?.apartment_building_id,

            } as LstBank)
                .then(res => {
                    setBankOptions(res)
                })
        }
    }, [data?.modelM?.mA_HTTT])

    //#endregion

    //#region hanldeGetDetailData

    React.useEffect(() => {


        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            setLoading(true);
            caVch02Client.caVch02Get({
                ...new ModelProcessca02(),
                modelM: {
                    mA_CTY: SessionLogin.ma_cty,
                    mA_CT: menu?.code_name,
                    stT_REC: props.itemSelected.stT_REC
                }
            } as ModelProcessca02).then(res => {
                const modelM = res.listCaVch02Ms![0];

                setData({
                    modelM: modelM,
                    arrayCt: res.arrayCt,
                    arrayCt1: res.arrayCt1
                } as ModelProcessca02)

                setLoading(false);

            }).catch(error => {
                notification(
                    TYPE_MESSAGE.WARNING,
                    STATUS_MESSAGE[`SERVER_ERROR`]
                );

                setLoading(false)
                props.onClose();
            })

            setId(props.itemSelected);

        } else if (props.action === ACTIONS.ADD) {
            // setData(new QLCCFloorModel());
            setLoading(true);
            const ngay_ct = new Date();

            commonClient.get_VoucherNo({
                ...new ParaObj(),
                ma_cty: SessionLogin.ma_cty,
                ma_ct: menu?.code_name,
                ngay_ct
            } as ParaObj)
                .then(res => {
                    setData(pre => ({
                        ...pre,
                        modelM: {
                            ...pre.modelM,
                            sO_CT: res,
                            mA_CT: menu?.code_name,
                            apartment_building_id: props.filter?.apartment_building_id,
                            mA_CTY: SessionLogin.ma_cty,
                            ngaY_CT: ngay_ct,
                            ngaY_LCT: new Date(),
                            cuser: SessionLogin.userName,
                            luser: SessionLogin.userName,
                            mA_GD: exchangeOptions.length > 0 ? exchangeOptions[0]?.ma_gd : null,
                            tY_GIA: 1,
                            // mA_NT: pre?.modelM?.mA_NT ? pre?.modelM?.mA_NT : currencyOptions[currencyOptions.length - 1]?.ma_nt,
                            mA_HTTT: paymentTermOptions.length > 0 ? paymentTermOptions[0]?.ma_httt : null
                        },
                    } as ModelProcessca02));

                    if (!res) {
                        props.onClose();
                        notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                        return;
                    }
                    setLoading(false)

                    // lấy stt_rec

                    // commonClient.get_RecNo({
                    //     ...new ParaObjRecNo(),
                    //     ma_ct: menu?.code_name,
                    //     ma_cty: SessionLogin.ma_cty,
                    // } as ParaObjRecNo)
                    //     .then(res => {
                    //         if (!res) {
                    //             props.onClose();
                    //             notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                    //             return;

                    //         }
                    //         setLoading(false);
                    //         setData(pre => ({
                    //             ...pre,
                    //             modelM: {
                    //                 ...pre.modelM,
                    //                 stT_REC: res
                    //             },
                    //         } as ModelProcessca02))

                    //     }).catch(error => {
                    //         props.onClose();
                    //         notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                    //     })
                }).catch(error => {
                    notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                    props.onClose()
                });




            setId(undefined)
        };

    }, [props.action, props.itemSelected]);


    //#endregion

    // React.useEffect(() => {
    //     if (!data?.modelM?.dieN_GIAI) {

    //         // Thêm lỗi khi chưa chọn hóa đơn
    //         const errorTemp = errors ? [...errors] : [];
    //         if (errorTemp.length > 0) {
    //             errorTemp.forEach(error => {
    //                 if ((error.errors as Array<any>).findIndex((x: any) => x.code === 36) === -1) {
    //                     errorTemp.push({
    //                         name: 'modelM',
    //                         errors: [
    //                             {
    //                                 code: 36,
    //                                 status: 1,
    //                                 message: 'Bạn chưa nhập diễn giải'
    //                             }
    //                         ]
    //                     });
    //                 }
    //             })
    //         } else {
    //             errorTemp.push({
    //                 name: 'modelM',
    //                 errors: [
    //                     {
    //                         code: 36,
    //                         status: 1,
    //                         message: 'Bạn chưa nhập diễn giải'
    //                     }
    //                 ]
    //             });
    //         }



    //         setErrors(errorTemp)

    //     }
    //     else if (data?.modelM?.dieN_GIAI) {
    //         // Xóa lỗi khi đã có hóa đơn
    //         const errorTemp = errors ? [...errors] : [];

    //         errorTemp.forEach((error, idxParent) => {
    //             if (error.name === 'modelM') {
    //                 const idx = error.errors.findIndex((x: any) => x.code === 36);

    //                 errorTemp[idxParent]
    //                     .errors
    //                     .splice(idx, 1);
    //             }
    //         });

    //         setErrors(errorTemp)
    //     }
    // }, [data?.modelM])

    //#region handleVoucherNo
    const createVoucherNo = () => {

        commonClient.get_VoucherNo({
            ...new ParaObj(),
            ma_cty: SessionLogin.ma_cty,
            ma_ct: menu?.code_name,
            ngay_ct: data?.modelM?.ngaY_LCT
        } as ParaObj)
            .then(res => {
                setData(pre => ({
                    ...pre,
                    modelM: {
                        ...pre.modelM,
                        sO_CT: res,
                    },
                } as ModelProcessca02));
            });
    }

    //#endregion



    //#region handleSubmit

    const handleSubmit = (data02D0Saved: CaVch02D0[], stt_rec?: string): void => {
        // setLoading(true);
        // if (errors && errors.length > 0) {
        //     let is_error = false;
        //     [...errors].forEach(item => {
        //         if (item.errors.length > 0) {
        //             item.errors.forEach((error: any) => {
        //                 notification(status_message[error.status], error.message)
        //             })
        //             is_error = true;
        //             return;
        //         }
        //     })

        //     if (is_error) {

        //         return;
        //     }

        // }

        if (!data?.modelM?.idcustomer) {
            notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["CUSTOMER_NULL"])
            return;
        }
        const totalTTMoney = [...data02D0Saved].reduce((acc, invoice) => {
            return acc + invoice.pS_NO!;
        }, 0);


        switch (props.action) {
            case ACTIONS.ADD:

                data02D0Saved?.map(item => {

                    item.stT_REC = stt_rec;
                    item.mA_CTY = SessionLogin.ma_cty;
                    // item.tien_tt = Number(itemChange.tien_tt);

                })

                data02D1?.map(item => {

                    item.stt_rec = stt_rec;
                    item.ngay_hd = moment(item.ngay_hd!).toDate();
                    item.ma_cty = SessionLogin.ma_cty;
                    // item.tien_tt = Number(itemChange.tien_tt);

                });
                caVch02Client.insertCommand2({
                    ...new ModelProcessca02(),
                    ...data,
                    modelM: {
                        ...data.modelM,
                        stT_REC: stt_rec,
                        ngaY_CT: TransDateTime(data.modelM?.ngaY_CT!),
                        ngaY_LCT: TransDateTime(data.modelM?.ngaY_LCT!),
                        t_TIEN_NT: totalTTMoney,
                        t_TIEN: totalTTMoney,
                        t_TT: totalTTMoney + (data.modelM?.t_THUE ?? 0),
                        apartment_building_id: props.filter?.apartment_building_id
                    },
                    arrayCt: data02D0Saved,
                    arrayCt1: data02D1
                } as ModelProcessca02)
                    .then(res => {
                        notification(status_message[res.status!], STATUS_MESSAGE[`${props.action}_${res.status}`]);
                        if (res.status === 0) {
                            // setId(res.ref_id)
                            setLoading(false);
                            props.onAddSuccess({
                                ...data.modelM,
                                stT_REC: stt_rec,
                                ngaY_CT: TransDateTime(data.modelM?.ngaY_CT!),
                                ngaY_LCT: TransDateTime(data.modelM?.ngaY_LCT!),
                                t_TIEN_NT: totalTTMoney,
                                t_TIEN: totalTTMoney,
                                apartment_building_id: props.filter?.apartment_building_id
                            } as CaVch02M);
                            props.onClose();
                            setData(new ModelProcessca02())
                        } else {
                            notification('error', res.message);
                            setLoading(false);
                        }
                    }).catch((error: any) => {
                        notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                        setLoading(false);
                    })
                break;
            case ACTIONS.EDIT:
                data02D1?.map(item => {


                    item.ngay_hd = moment(item.ngay_hd!).toDate();
                    // item.ngay_ct = new Date(item.ngay_ct!);
                    // item.tien_tt = Number(itemChange.tien_tt);

                });
                caVch02Client.updateCommand2({
                    ...new ModelProcessca02(),
                    ...data,
                    modelM: {
                        ...data.modelM,
                        ngaY_CT: TransDateTime(data.modelM?.ngaY_CT!),
                        ngaY_LCT: TransDateTime(data.modelM?.ngaY_LCT!),
                        t_TIEN_NT: totalTTMoney,
                        t_TIEN: totalTTMoney,
                        t_TT: totalTTMoney + (data.modelM?.t_THUE ?? 0),
                        apartment_building_id: props.filter?.apartment_building_id
                    },
                    arrayCt: data02D0Saved,
                    arrayCt1: data02D1
                } as ModelProcessca02)
                    .then(res => {
                        notification(status_message[res.status!], STATUS_MESSAGE[`${props.action}_${res.status}`]);
                        if (res.status === 0) {
                            // setId(res.ref_id)
                            setLoading(false);
                            props.onEditSuccess({
                                ...data.modelM,
                                ngaY_CT: TransDateTime(data.modelM?.ngaY_CT!),
                                ngaY_LCT: TransDateTime(data.modelM?.ngaY_LCT!),
                                t_TIEN_NT: totalTTMoney,
                                t_TIEN: totalTTMoney,
                                apartment_building_id: props.filter?.apartment_building_id
                            } as CaVch02M);
                            props.onClose();
                            setData(new ModelProcessca02())
                        } else {
                            notification('error', res.message);
                            setLoading(false);
                        }
                    }).catch((error: any) => {
                        notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                        setLoading(false);
                    })
                break;
            default:
                setLoading(false);
        }

        // setOperations([])
        // setIsPost(false)
        // setIsSaved("NOT-EXISTS")
    };

    //#endregion


    // React.useEffect(() => {


    // commonClient.get_VoucherNo({
    //     ...new ParaObj(),
    //     ma_cty: SessionLogin.ma_cty,
    //     ma_ct: 'CA2',
    //     ngay_ct: data?.modelM?.ngaY_CT
    // } as ParaObj)
    //     .then(res => {
    //         setData(pre => ({
    //             ...pre,
    //             modelM: {
    //                 ...pre.modelM,
    //                 sO_CT: res,
    //             },
    //         } as ModelProcessca02));
    //     })

    // }, [data?.modelM?.ngaY_CT])

    //#region handleChangeInput
    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        const date = moment(value).toDate();
        if (date instanceof Date && !isNaN(date.valueOf())) {
            setData(pre => ({
                ...pre, modelM: {
                    ...pre.modelM,
                    [name]: new Date(value)
                }
            } as ModelProcessca02));
        }
        // setData({ ...data, [name]: new Date(value) } as SoVch05M);
    }

    const handleChangeInput = (e: any) => {
        const { name, value, apartment_id } = e.target;



        // if ((Array.isArray(value) && Array.isArray(name))) {
        //     const tempData = { ...data };
        //     name.forEach((name_, idx) => {
        //         Object.assign(tempData, {
        //             ...tempData,
        //             [name_]: value[idx]
        //         })
        //     })

        //     setData(tempData as SoVch05M)

        // }
        // else 
        setData(pre => ({
            ...pre, modelM: {
                ...pre.modelM,
                [name]: value
            }
        } as ModelProcessca02));

        // Nếu là khách hàng thì set thêm apartment id

        // setData(pre => ({ ...pre, apartment_id } as SoVch05M))

    };



    const handleChangeCurrentInput = (
        value: string | undefined, name?: string | undefined, values?: CurrencyInputOnChangeValues | undefined) => {

        setData(pre => ({
            ...pre, modelM: {
                ...pre.modelM,
                [name!]: value
            }
        } as ModelProcessca02));
    }

    //#endregion

    //#region handlePrint
    const handlePrintAction = () => {
        setActPrint({
            ...actPrint,
            open: true,
            type: ACTIONS.EMPTY,
            payload: data?.modelM // pass the data here
        })
    }

    //#endregion

    //#region handleClickAction

    const handleClickAction = async (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                let stt_rec = data?.modelM?.stT_REC;
                if (props?.action === ACTIONS.ADD) {
                    stt_rec = await commonClient.get_RecNo({
                        ...new ParaObjRecNo(),
                        ma_ct: menu?.code_name,
                        ma_cty: SessionLogin.ma_cty,
                    } as ParaObjRecNo)
                    if (!stt_rec) {
                        props.onClose();
                        notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SERVER_ERROR"])
                        return;
                    }
                }

                var data02D0Saved: CaVch02D0[] = [];
                const result = await commonClient.check_VoucherNo({
                    ...new ParaObjCheck(),
                    ma_ct: menu?.code_name,
                    ngay_ct: TransDateTime(data?.modelM?.ngaY_LCT!),
                    ma_cty: SessionLogin.ma_cty,
                    stt_rec: stt_rec,
                    voucherno: data?.modelM?.sO_CT
                } as ParaObjCheck);

                if (!result) {
                    if (dataGridRef02D0.current) {
                        await dataGridRef02D0.current.instance.saveEditData();
                        await dataGridRef02D0.current!.instance.refresh();
                        data02D0Saved = dataGridRef02D0.current!.instance.getDataSource().items();
                        data02D0Saved.map(dt02D0 => {
                            dt02D0.pS_NO = dt02D0.pS_NO_NT;
                            dt02D0.pS_CO_NT = 0;
                            dt02D0.pS_CO = 0;
                        });
                        // setData01D1(data01D1Saved);
                    } else {
                        data02D0Saved = [...data02D0];
                    }

                    // handleSubmit(data02D1Saved, data02D0Saved);

                    handleSubmit(data02D0Saved, stt_rec);
                }
                else {
                    notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["SAME_NUMBER_VOUCHER_1"]);
                    // Tạo lại số chứng từ
                    createVoucherNo();
                }
                break;
            case ACTIONS.PRINT:
                handlePrintAction();
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
        }
    }


    //#endregion

    return (
        <div>

            {
                actPrint.open && <ReceiptPrintAction
                    open={actPrint.open}
                    onClose={function (): void {
                        setActPrint(defaultAction)
                    }}
                    onEditSuccess={function (is_success): void {
                        // setReload(pre => !pre)
                    }}
                    action={actPrint.type}
                    itemSelected={actPrint.payload} >

                </ReceiptPrintAction>

            }
            <Dialog
                // id="sysMenu84"
                fullScreen={fullScreen}
                open={props.open}
                onClose={() => {
                    props.onClose()
                    setData(new ModelProcessca02())
                }}
                // sx={{
                //     display: "flex",
                //     justifyContent: "center",
                // }}
                PaperProps={{
                    sx: !fullScreen ? {
                        minHeight: "90vh",
                        height: "90vh"
                    } : undefined
                }}
                className="Asset-control"
                TransitionComponent={Transition}
                maxWidth="xl"
                fullWidth

            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            {
                                menu?.bar +
                                " " +
                                `${props.action === ACTIONS.VIEW
                                    ? "[Xem chi tiết]"
                                    : props.action === ACTIONS.EDIT || _id
                                        ? "[Cập nhật]"
                                        : "[Thêm mới]"
                                }`
                            }
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1.25 }}>


                            <IconButton
                                edge="end"

                                onClick={() => {
                                    setFullscreen(pre => !pre)
                                }}
                                aria-label="full screen"
                            >
                                {fullScreen ? <FullscreenExitIcon sx={{ color: '#1f2937' }} /> : <FullscreenIcon sx={{ color: '#1f2937' }} />}
                            </IconButton>
                            <IconButton
                                edge="end"
                                onClick={() => {
                                    props.onClose();
                                    setData(new ModelProcessca02())
                                }}
                                aria-label="close"
                            >
                                <CloseIcon
                                    sx={{ color: '#1f2937' }}
                                />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent>
                    <Accordion className="Asset-control" expanded={isExpandPaymentCashAction} onChange={() => { setIsCalledPaymentCashAction(true); setIsExpandPaymentCashAction(pre => !pre) }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ backgroundColor: "#e0f2fe" }}
                        >
                            <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Thông tin chung</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="row gutter-1">
                                <div className="col-xl-4">

                                    <div className="card">

                                        <div className="card-body pt-2">
                                            <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                                Thông tin chung
                                            </div>
                                            {/* Loại thu */}
                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Loại chi</InputLabel>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <DHSAutoCompleteWithDataSource
                                                        id="pay-type-select"
                                                        className="cc-input"
                                                        dataSource={exchangeOptions}
                                                        value={
                                                            data?.modelM?.mA_GD
                                                        }
                                                        displayMember='mo_ta'
                                                        valueMember="ma_gd"
                                                        onValueChanged={(e, newValue: any) => {
                                                            handleChangeInput({
                                                                target: {
                                                                    name: 'mA_GD',
                                                                    value: newValue?.ma_gd ?? null
                                                                }
                                                            })
                                                        }}
                                                        readOnly={props.action === ACTIONS.VIEW}
                                                    />
                                                    {/* <Autocomplete
                                                        id="pay-type-select"
                                                        sx={{ width: "100%" }}
                                                        // defaultValue={exchangeOptions[0]}
                                                        options={exchangeOptions}
                                                        autoHighlight
                                                        getOptionLabel={(option: any) => option?.mo_ta}
                                                        value={
                                                            data?.modelM?.mA_GD
                                                                ? exchangeOptions.find(
                                                                    (item: any) => item?.ma_gd === data?.modelM?.mA_GD
                                                                )
                                                                : null
                                                        }
                                                        onChange={(e, newValue: any) => {

                                                            handleChangeInput({
                                                                target: {
                                                                    name: 'mA_GD',
                                                                    value: newValue?.ma_gd ?? null
                                                                }
                                                            })
                                                        }}

                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                className="cc-input"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    autoComplete: "new-password", // disable autocomplete and autofill
                                                                }}
                                                            />
                                                        )}
                                                        readOnly={props.action === ACTIONS.VIEW}
                                                    /> */}

                                                </Grid>
                                            </Grid>
                                            {/* Tên khách hàng */}

                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel required htmlFor="resident-select" sx={{ fontSize: 14, color: '#333' }}>Tên NCC/NV</InputLabel>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <DHSDataGridFilterListByProjectSelect
                                                        value={data?.modelM?.idcustomer ?? null}
                                                        table_list="dm_ncc"
                                                        // placeholder="Chọn NCC/NV"
                                                        // filter={props.payload}
                                                        name="idcustomer"
                                                        dataGridOnSelectionChanged={(e, newValue: any) => {
                                                            handleChangeInput({
                                                                target: {
                                                                    name: "idcustomer",
                                                                    value: newValue?.idcustomer ?? null,
                                                                },
                                                            });

                                                            setData(pre => ({
                                                                ...pre, modelM: {
                                                                    ...pre.modelM,
                                                                    teN_KH: newValue?.ten_kh ?? null,
                                                                    mA_KH: newValue?.ma_kh ?? null,
                                                                    nguoI_GD: newValue.ten_kh ?? null
                                                                }
                                                            } as ModelProcessca02));
                                                        }}
                                                        syncDataGridSelection={function (e): void {
                                                            setData(pre => ({ ...pre, modelM: { ...pre.modelM, idcustomer: e.value } } as ModelProcessca02));
                                                        }}
                                                        gridBoxDisplayExpr={function (item: any): string {
                                                            return item && `${item.ma_kh} - ${item.ten_kh}`;
                                                        }}

                                                        readOnly={props.action === ACTIONS.VIEW}
                                                    />


                                                </Grid>
                                            </Grid>



                                            {/* Số điện thoại */}

                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số điện thoại</InputLabel>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <TextField
                                                        className="cc-input"
                                                        sx={{
                                                            width: "100%",
                                                            marginRight: "5px",
                                                        }}
                                                        // placeholder="Địa chỉ hiện tại"
                                                        name="so_dt"
                                                        onChange={handleChangeInput}
                                                        title={data?.modelM?.so_dt}
                                                        value={data?.modelM?.so_dt}
                                                        inputProps={{
                                                            readOnly: props.action === ACTIONS.VIEW

                                                        }}
                                                    />

                                                </Grid>
                                            </Grid>
                                            {/* Địa chỉ */}

                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Địa chỉ</InputLabel>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <TextField
                                                        className="cc-input"
                                                        sx={{
                                                            width: "100%",
                                                            marginRight: "5px",
                                                        }}
                                                        // placeholder="Địa chỉ hiện tại"
                                                        name="diA_CHI"
                                                        onChange={handleChangeInput}
                                                        title={data?.modelM?.diA_CHI}
                                                        value={data?.modelM?.diA_CHI}
                                                        inputProps={{
                                                            readOnly: props.action === ACTIONS.VIEW

                                                        }}
                                                    />

                                                </Grid>
                                            </Grid>
                                            {/* Người nhận tiền */}

                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Người nhận tiền</InputLabel>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <TextField
                                                        className="cc-input"
                                                        sx={{
                                                            width: "100%",
                                                            marginRight: "5px",
                                                        }}
                                                        name="nguoI_GD"
                                                        // placeholder="Nguyễn Văn A"
                                                        title={data?.modelM?.nguoI_GD}

                                                        onChange={handleChangeInput}
                                                        value={data?.modelM?.nguoI_GD}
                                                        inputProps={{
                                                            readOnly: props.action === ACTIONS.VIEW

                                                        }}
                                                    />

                                                </Grid>
                                            </Grid>
                                            {/* Diễn giải */}

                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Diễn giải</InputLabel>

                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <TextField
                                                        className="cc-input"
                                                        sx={{
                                                            width: "100%",
                                                            marginRight: "5px",
                                                        }}
                                                        name="dieN_GIAI"
                                                        title={data?.modelM?.dieN_GIAI}
                                                        // placeholder="Thu phí chung cư tháng 5/2023"
                                                        onChange={handleChangeInput}
                                                        value={data?.modelM?.dieN_GIAI}
                                                        inputProps={{
                                                            readOnly: props.action === ACTIONS.VIEW

                                                        }}
                                                    />

                                                </Grid>
                                            </Grid>
                                            {/* Hình thức thanh toán */}
                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Hình thức thanh toán</InputLabel>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <DHSAutoCompleteWithDataSource

                                                        dataSource={paymentTermOptions}
                                                        displayMember="ten_httt"
                                                        valueMember="ma_httt"
                                                        value={data?.modelM?.mA_HTTT}
                                                        onValueChanged={(e, newValue: any) => {

                                                            handleChangeInput({
                                                                target: {
                                                                    name: 'mA_HTTT',
                                                                    value: newValue?.ma_httt ?? null
                                                                }
                                                            });
                                                            // setPaymentMethodCode(newValue?.ma_httt);

                                                            if (newValue?.ma_httt === 'TM') {
                                                                setData(pre => ({
                                                                    ...pre, modelM: {
                                                                        ...pre.modelM,
                                                                        mA_NGH: undefined,

                                                                    }
                                                                } as ModelProcessca02));
                                                            }
                                                        }}
                                                        readOnly={props.action === ACTIONS.VIEW}
                                                    />

                                                </Grid>
                                            </Grid>

                                            {/* Tài khoản nợ */}

                                            {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tài khoản nợ</InputLabel>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            name="address"
                                            onChange={handleChangeInput}
                                            value={null}
                                            readOnly={props.action === ACTIONS.VIEW}
                                        />

                                    </Box> */}
                                            {/*Tiền */}
                                            {/* 
                                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tiền</InputLabel>
                                        <CurrencyTextField
                                            className="flex-grow-1 form-control currency-input"
                                            style={{ width: "100%", textAlign: "right" }}
                                            allowDecimals
                                            decimalsLimit={2}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            decimalScale={0}
                                            placeholder="Tổng tiền"
                                            intlConfig={{ locale: 'vi-VN', currency: 'VND' }}
                                            name="t_TT"
                                            onValueChange={handleChangeCurrentInput}
                                            value={data.modelM?.t_TIEN_NT}
                                            disabled={true}
                                        />

                                    </Box> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4">

                                    <div className="card mb-2">

                                        <div className="card-body pt-2">
                                            <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                                Thông tin chứng từ
                                            </div>
                                            {/* Ngày hạch toán */}
                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày hạch toán</InputLabel>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <CTXDate
                                                        className="cc-input"
                                                        style={{ width: '100%', padding: '0 5px !important' }}
                                                        name="ngaY_CT"
                                                        onChange={(e) => {
                                                            handleChangeDate(e);
                                                            commonClient.get_VoucherNo({
                                                                ...new ParaObj(),
                                                                ma_cty: SessionLogin.ma_cty,
                                                                ma_ct: menu?.code_name,
                                                                ngay_ct: e?.value
                                                            } as ParaObj)
                                                                .then(res => {
                                                                    setData(pre => ({
                                                                        ...pre,
                                                                        modelM: {
                                                                            ...pre.modelM,
                                                                            sO_CT: res,
                                                                        },
                                                                    } as ModelProcessca02));
                                                                })
                                                        }}
                                                        value={data?.modelM?.ngaY_CT}
                                                        title={moment(data?.modelM?.ngaY_LCT).format("DD/MM/yyyy")}

                                                        readOnly={props.action === ACTIONS.VIEW}

                                                    />
                                                </Grid>
                                            </Grid>

                                            {/* Số chứng từ */}

                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số chứng từ</InputLabel>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <TextField
                                                        className="cc-input"
                                                        sx={{
                                                            width: "100%",
                                                            marginRight: "5px",
                                                        }}
                                                        inputProps={{
                                                            style: {
                                                                textAlign: 'left'
                                                            },
                                                            readOnly: props.action === ACTIONS.VIEW
                                                        }}
                                                        type="text"
                                                        name="sO_CT"
                                                        title={data?.modelM?.sO_CT}
                                                        onChange={handleChangeInput}
                                                        value={data?.modelM?.sO_CT}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {/* Ngày chứng từ */}
                                            <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                <Grid item xs={12} md={4}>

                                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày chứng từ</InputLabel>
                                                </Grid>
                                                <Grid item xs={12} md={8}>

                                                    <CTXDate
                                                        className="cc-input"
                                                        style={{ width: '100%', padding: '0 5px !important' }}
                                                        name="ngaY_LCT"
                                                        title={moment(data?.modelM?.ngaY_LCT).format("DD/MM/yyyy").toString()}
                                                        onChange={(e) => {
                                                            handleChangeDate(e);


                                                        }}
                                                        value={data.modelM?.ngaY_LCT}
                                                        readOnly={props.action === ACTIONS.VIEW}
                                                    // disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>



                                    <div className="card mb-2">

                                        <div className="card-body pt-2">
                                            <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                                Thông tin tiền tệ
                                            </div>
                                            <div className="d-flex" style={{ gap: 5 }}>
                                                {/* Loại tiền */}
                                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                    <Grid item xs={12} md={4}>
                                                        <InputLabel id="nt-select-label" sx={{ fontSize: 14, color: '#333' }}>Loại tiền</InputLabel>

                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <DHSAutocompleteFilterListSelect
                                                            table_list="dm_nt"
                                                            value={data?.modelM?.mA_NT}
                                                            defaultValue={(data) => {
                                                                if (props.action === ACTIONS.ADD) {
                                                                    setData(pre => ({
                                                                        ...pre,
                                                                        modelM: {
                                                                            ...pre.modelM,
                                                                            mA_NT: data[data.length - 1]?.ma_nt
                                                                        }
                                                                    } as ModelProcessca02))
                                                                }
                                                            }}
                                                            onValueChanged={(e, newValue: any) => {
                                                                handleChangeInput({
                                                                    target: {
                                                                        name: "mA_NT",
                                                                        value: newValue?.ma_nt ?? null,
                                                                    },
                                                                });
                                                                commonClient.getExchangeRate5({
                                                                    ...new ParaObjExchageRate(),
                                                                    ma_cty: SessionLogin.ma_cty,
                                                                    ma_nt: newValue?.ma_nt,
                                                                    ngay_ct: data?.modelM?.ngaY_CT
                                                                } as ParaObjExchageRate)
                                                                    .then(res => {
                                                                        if (!res) {
                                                                            setData(pre => ({ ...pre, modelM: { ...pre.modelM, tY_GIA: 0 } } as ModelProcessca02))

                                                                        }
                                                                        else setData(pre => ({ ...pre, modelM: { ...pre.modelM, tY_GIA: res } } as ModelProcessca02))
                                                                    })

                                                            }}
                                                            readOnly={props.action === ACTIONS.VIEW}
                                                        />


                                                    </Grid>
                                                </Grid>
                                                {/* Tỷ giá */}
                                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                    <Grid item xs={12} md={4}>
                                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tỷ giá</InputLabel>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <NumberBox
                                                            // className="cc-input"
                                                            className="cc-input w-100 flex-grow-1 form-control currency-input p-0 pr-2"

                                                            format="0,###"
                                                            inputAttr={{ 'aria-label': 'Fixed Poing Format', 'style': 'text-align:right' }}
                                                            defaultValue={data?.modelM?.tY_GIA}
                                                            value={data?.modelM?.tY_GIA}

                                                            // style={{
                                                            //     textAlign: "right"
                                                            // }}
                                                            onValueChange={(value) => {
                                                                handleChangeInput({
                                                                    target: {
                                                                        name: "tY_GIA",
                                                                        value: value
                                                                    }
                                                                });


                                                            }}

                                                            readOnly={props.action === ACTIONS.VIEW}

                                                        />
                                                        {/* <CurrencyTextField
                                                            className="flex-grow-1 form-control currency-input"
                                                            style={{ width: "100%", textAlign: "right" }}
                                                            allowDecimals
                                                            decimalsLimit={2}
                                                            decimalSeparator="."
                                                            groupSeparator=","
                                                            decimalScale={0}
                                                            // placeholder="Tỷ giá"
                                                            intlConfig={{ locale: 'vi-VN', currency: 'VND' }}
                                                            name="tY_GIA"
                                                            onValueChange={handleChangeCurrentInput}
                                                            title={data?.modelM?.tY_GIA?.toString()}
                                                            value={data?.modelM?.tY_GIA}
                                                            readOnly={props.action === ACTIONS.VIEW}
                                                        /> */}
                                                        {/* <TextField
                                                className="cc-input"
                                                sx={{
                                                    width: "100%",
                                                    marginRight: "5px",
                                                }}
                                                inputProps={{
                                                    style: {
                                                        textAlign: 'right'
                                                    }
                                                }}
                                                type="number"
                                                name="tY_GIA"
                                                onChange={handleChangeInput}
                                                value={data?.modelM?.tY_GIA}
                                                readOnly={props.action === ACTIONS.VIEW}
                                            /> */}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>


                                    </div>

                                </div>

                                <div className="col-xl-4">
                                    {
                                        data?.modelM?.mA_HTTT === 'CK' && <div className="card">

                                            <div className="card-body pt-2">
                                                <div className="pb-0" style={{ fontSize: "16px", fontWeight: 700 }}>
                                                    Thông tin ngân hàng
                                                </div>
                                                {/* Số tài khoản */}
                                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                    <Grid item xs={12} md={4}>
                                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số tài khoản</InputLabel>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <DHSAutoCompleteWithDataSource
                                                            id="bank-select"
                                                            className="cc-input"
                                                            dataSource={bankOptions}
                                                            value={
                                                                data?.modelM?.mA_NGH
                                                            }
                                                            displayMember='banknumber'
                                                            valueMember="banknumber"
                                                            onValueChanged={(e, newValue: any) => {
                                                                handleChangeInput({
                                                                    target: {
                                                                        name: "mA_NGH",
                                                                        value: newValue?.banknumber ?? null,
                                                                    },
                                                                });

                                                                setBankCode(newValue?.ma_ngh);
                                                                setBankName(newValue?.ten_ngh);
                                                            }}
                                                            readOnly={props.action === ACTIONS.VIEW}
                                                        />
                                                        {/* <Autocomplete
                                                            id="bank-select"
                                                            sx={{ width: "100%" }}
                                                            options={bankOptions}
                                                            autoHighlight
                                                            getOptionLabel={(option: any) => option?.banknumber}
                                                            value={
                                                                data?.modelM?.mA_NGH
                                                                    ? bankOptions?.find(
                                                                        (item: any) => item.banknumber === data.modelM?.mA_NGH
                                                                    )
                                                                    : null
                                                            }
                                                            onChange={(e, newValue: any) => {
                                                                handleChangeInput({
                                                                    target: {
                                                                        name: "mA_NGH",
                                                                        value: newValue?.banknumber ?? null,
                                                                    },
                                                                });

                                                                setBankCode(newValue?.ma_ngh);
                                                                setBankName(newValue?.ten_ngh);
                                                            }}

                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    className="cc-input"
                                                                    // placeholder="Số tài khoản"
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        autoComplete: "new-password", // disable autocomplete and autofill
                                                                    }}
                                                                />
                                                            )}
                                                            readOnly={props.action === ACTIONS.VIEW}
                                                        /> */}

                                                    </Grid>
                                                </Grid>

                                                {/* Ngân hàng */}

                                                <Grid container alignItems="center" spacing={0} sx={{ margin: "7px 0px" }}>
                                                    <Grid item xs={12} md={4}>
                                                        <InputLabel htmlFor="address" sx={{ fontSize: 14, color: '#333' }}>Ngân hàng</InputLabel>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <div className="row">
                                                            <div className="col-xl-4 pr-0">
                                                                <TextField
                                                                    id="bank-code"
                                                                    className="cc-input"
                                                                    sx={{
                                                                        width: "100%",
                                                                        marginRight: "5px",
                                                                    }}
                                                                    name="bank-code"
                                                                    onChange={(e) => {
                                                                        setBankCode(e.target.value)
                                                                    }}
                                                                    // placeholder="Mã ngân hàng"
                                                                    title={data?.modelM?.mA_NGH ? bankCode : ""}
                                                                    value={data?.modelM?.mA_NGH ? bankCode : null}
                                                                    inputProps={{
                                                                        readOnly: props.action === ACTIONS.VIEW

                                                                    }}
                                                                />

                                                            </div>
                                                            <div className="col-xl-8 pl-1">
                                                                <TextField
                                                                    className="cc-input"
                                                                    sx={{
                                                                        width: "100%",
                                                                        marginRight: "5px",
                                                                    }}
                                                                    name="bank-name"
                                                                    title={data?.modelM?.mA_NGH ? bankName : ""}
                                                                    // placeholder="Tên ngân hàng"
                                                                    onChange={(e) => {
                                                                        setBankName(e.target.value)
                                                                    }}
                                                                    value={data?.modelM?.mA_NGH ? bankName : null}
                                                                    inputProps={{
                                                                        readOnly: props.action === ACTIONS.VIEW

                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>

                                            </div>

                                        </div>
                                    }
                                </div>



                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="Asset-control" expanded={isExpandReceiptDAction} onChange={() => { setIsCalledCaVchD(true); setIsExpandReceiptDAction(pre => !pre) }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            sx={{ backgroundColor: "#e0f2fe" }}
                        >
                            <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Thông tin chi tiết</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="row">
                                <div className="col-12 p-0">
                                    {
                                        <PaymentCashD
                                            action={props.action}
                                            arrayCt={data?.arrayCt}
                                            arrayCt1={data?.arrayCt1}
                                            dataInvoice={props.itemSelected?.invoice && [props.itemSelected?.invoice]}
                                            passArrayCT1={(dataCT1: CaVch02D1[]) => setData02D1(pre => ([...dataCT1]))}
                                            passArrayCT={(dataCT: CaVch02D0[]) => setData02D0(pre => ([...dataCT]))}
                                            dataGridRef02D0={dataGridRef02D0}
                                            // onDataGridSaved={(saved) => {
                                            //     const tempOparations = new Set([...operations]);
                                            //     tempOparations.add(saved);
                                            //     setOperations(Array.from(tempOparations));
                                            //     setIsSaved(saved)
                                            // }}
                                            apartment_id={apartmentId}

                                            // onChangeTT={(dataChange: CaVch01D1[]) => {
                                            //     _dataChange = dataChange;
                                            // }}
                                            totalTTMoney={function (totalTTMoney?: number | undefined): void {
                                                setData(pre => ({
                                                    ...pre, modelM: {
                                                        ...pre.modelM,
                                                        t_TIEN_NT: totalTTMoney,
                                                        t_TIEN: totalTTMoney
                                                    }
                                                } as ModelProcessca02))
                                            }}
                                            stt_rec={data?.modelM?.stT_REC}
                                            onError={(errorHelper) => {

                                                errorHelper?.forEach(item => {
                                                    item?.errors?.forEach((error: any) => {
                                                        notification(status_message[error.status], error.message)
                                                    });
                                                })

                                                setErrors(errorHelper)
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </DialogContent>
                <DialogActions>

                    <DHSDialogAction

                        action={props.action}
                        menu={menu}
                        onClickAction={handleClickAction}
                        isDialogAction

                    />

                </DialogActions>
                <BlockUI blocking={isLoading} title={""}></BlockUI>
            </Dialog>
        </div >
    );
};

export default PaymentCashAction;
