import React from "react";
import SelectBox, { ISelectBoxOptions } from "devextreme-react/select-box";
import { Template } from "devextreme-react/core/template";
import LoadIndicator from "devextreme-react/load-indicator";
import Validator, { RequiredRule } from "devextreme-react/validator";


interface IProps extends React.PropsWithChildren<ISelectBoxOptions>{
  required?: boolean
  requiredMessage?: string
}

const DHSSelectBox = React.forwardRef<SelectBox, IProps>((props, ref) => {
    return <SelectBox
                {...props}
                ref={ref}

              >
                {props?.children}
                {
                  props?.required && <Validator>
                  <RequiredRule message={props?.requiredMessage} />
                </Validator>

                }
              </SelectBox>
})

export default DHSSelectBox;