import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Select from "../../../../../components/DHS/DHS_Select";
import DHSDate from "../../../../../components/DHS/DHS-Date/DHSDateComponent";
import { TransitionProps } from "@mui/material/transitions";
import "./LeaveForm.css";
import { AppSession } from "../../../../shared/app-session/app-session";
import {
  HRClient,
  LeaveForm,
  LstHRLeaveType,
} from "../../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../shared/service-proxies/config";
import { AppBar, Button, IconButton, Toolbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import notification from "../../../../../common/notification/notification";

interface IProps {
  open: boolean;
  actionType: string;
  itemClicked: any;
  onClose: (open: any) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});
var appSession: AppSession;
const regard = [
  "- Không được ứng năm.",
  "- Đơn vị nghỉ phép năm nhỏ nhất là 0.5 ngày (nửa buổi đầu ca hoặc nửa buổi cuối ca làm việc).",
  "- Trường hợp nghỉ BHXH phải có chừng từ BHXH.",
  "- Trường hợp nghỉ công tác phải có giấy tờ điều động công tác.",
  "- Trường hợp nghỉ hiếu hỉ/Tang gia phải có giấy tờ xác nhận tương ứng (giấy kết hôn/báo tử).",
  "- Nghỉ tai nạn lao động có giấy nghỉ tai nạn.",
];
export default function LeaveFormDialog(props: IProps) {
  const hrClient = new HRClient(appSession, BASE_URL_API);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [_id, setId] = React.useState<string | undefined>();
  const [data, setData] = React.useState<LeaveForm>({
    ...new LeaveForm(),
    fromdate: new Date(),
    todate: new Date(),
  } as LeaveForm);
  const [isViewDetail, activeViewDetail] = React.useState<boolean>(false);
  const [typeOptions, setTypeOptions] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (props.actionType === "EDIT" || props.actionType === "VIEWDETAIL") {
      hrClient
        .hROnLeaveGet({
          ...new LeaveForm(),
          id: props.itemClicked,
          ma_cty: SessionLogin.ma_cty,
          sender_code: SessionLogin.employeeCode,
        } as LeaveForm)
        .then((response) => {
          setData(response[0]);
        });
      if (props.actionType === "VIEWDETAIL") {
        activeViewDetail(true);
      }
    }
  }, [props.actionType]);
  React.useEffect(() => {
    setId(props.itemClicked);
  }, [props.itemClicked]);
  React.useEffect(() => {
    hrClient
      .lstHRLeaveTypeGet({
        ...new LstHRLeaveType(),
        ma_cty: SessionLogin.ma_cty,
      } as LstHRLeaveType)
      .then((response) => {
        setTypeOptions(response);
      });
  }, []);
  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value } as LeaveForm);
  };
  const handleChangeFromDate = (e: any) => {
    setData({ ...data, fromdate: e } as LeaveForm);
  };
  const handleChangeToDate = (e: any) => {
    setData({ ...data, todate: e } as LeaveForm);
  };
  const handleSubmit = () => {
    if (_id && _id.length > 0) {
      hrClient
        .hROnLeaveUpd({
          ...data,
          id: _id,
          sender_code: SessionLogin.employeeCode,
        } as LeaveForm)
        .then((res) => {
          if (res[0].ret === 0) {
            notification("success", res[0].message);
          } else if (res[0].ret === 1) {
            notification("error", res[0].message);
          } else {
            notification("warning", res[0].message);
          }
        });
    } else {
      hrClient
        .hROnLeaveIns({
          ...data,
          ma_cty: SessionLogin.ma_cty,
          sender_code: SessionLogin.employeeCode,
        } as LeaveForm)
        .then((res) => {
          if (res[0].ret === 0) {
            setId(res[0].id);
            notification("success", res[0].message);
          } else if (res[0].ret === 1) {
            notification("error", res[0].message);
          } else {
            notification("warning", res[0].message);
          }
        });
    }
  };
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        TransitionComponent={Transition}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {props.actionType === "ADD"
                ? "Tạo đơn nghỉ phép"
                : props.actionType === "EDIT"
                ? "Cập nhật đơn nghỉ phép"
                : props.actionType === "VIEWDETAIL"
                ? "Chi tiết đơn nghỉ phép"
                : "Đang tải nội dung"}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSubmit}>
              Lưu
            </Button>
          </Toolbar>
        </AppBar>
        <div className="d-flex">
          <div className="w-100 p-4">
            <div style={{ backgroundColor: "white" }} className="p-3 d-flex">
              <div className="w-100 h-100">
                <div className="row">
                  <div className="form-group col-xl-12">
                    <label>Người gửi</label>
                    <input
                      className="flex-grow-1 form-control"
                      placeholder="Người gửi"
                      value={
                        data.sender_name
                          ? data.sender_name
                          : SessionLogin.userName
                      }
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-3">
                    <Select
                      label="Loại nghỉ phép"
                      value={data.type}
                      options={typeOptions}
                      onChange={handleChangeInput}
                      disabled={isViewDetail}
                      fieldValue={"leave_type_id"}
                      fieldDisplay={"leave_type_name"}
                      name="type"
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <DHSDate
                      label="Ngày bắt đầu"
                      className="flex-grow-1 form-control"
                      name="fromdate"
                      onChange={handleChangeFromDate}
                      value={data.fromdate?.toString()}
                      disabled={isViewDetail}
                      id={data.id ? data.id : ""}
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <DHSDate
                      label="Ngày kết thúc"
                      className="flex-grow-1 form-control"
                      name="todate"
                      onChange={handleChangeToDate}
                      value={data.todate?.toString()}
                      disabled={isViewDetail}
                      id={data.id ? data.id : ""}
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>Tổng ngày nghỉ</label>
                    <input
                      className="flex-grow-1 form-control"
                      type="number"
                      name="sumarytime_onleave"
                      onChange={handleChangeInput}
                      value={data.sumarytime_onleave}
                      disabled={isViewDetail}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Lí do</label>
                      <textarea
                        className="flex-grow-1 form-control"
                        name="reason"
                        onChange={handleChangeInput}
                        value={data.reason}
                        disabled={isViewDetail}
                      />
                    </div>
                    <div className="form-group">
                      <label>Ý kiến từ người quản lý</label>
                      <textarea
                        className="flex-grow-1 form-control"
                        name="opinion_from_manager"
                        onChange={handleChangeInput}
                        value={data.opinion_from_manager}
                        disabled={true}
                      />
                    </div>
                    <div className="form-group">
                      <label>Ý kiến từ phòng nhân sự</label>
                      <textarea
                        className="flex-grow-1 form-control"
                        name="opinion_from_hr"
                        onChange={handleChangeInput}
                        value={data.opinion_from_hr}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 p-3" style={{ fontSize: 14 }}>
                    <i>
                      <b>Lưu ý</b>
                    </i>
                    {regard.map((item) => (
                      <p>{item}</p>
                    ))}
                  </div>
                </div>

                {/* <div className="form-group">
                <label>Chữ ký từ trưởng bộ phận</label>
                <input
                  className="flex-grow-1 form-control"
                  type="text"
                  name="receihead_departmentver"
                  placeholder="Trưởng bộ phận"
                  onChange={handleChangeInput}
                  value={data.head_department}
                />
              </div>
              <div className="form-group">
                <label>Chữ ký từ phòng nhân sự</label>
                <input
                  className="flex-grow-1 form-control"
                  type="text"
                  name="hr"
                  placeholder="Phòng nhân sự"
                  onChange={handleChangeInput}
                  value={data.hr}
                />
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
