/* eslint-disable no-eval */
import _ from "lodash";
import { SysGenRowTableModel } from "../../../../app/shared/service-proxies/api-shared";
import DHSMathUtils from "../../../../utils/DHSMathUtils";

enum ROUND_CURRENCY_TYPES {
  // HTML_EDITOR = "html editor",
  round_of_price = "round_of_price",
  round_of_amount = "round_of_amount"
}

const DHSRoundAmount = DHSMathUtils.DigitRoundAmountValue;
const DHSRoundPrice = DHSMathUtils.DigitRoundPriceValue;

//dung 1 truong

//CALCULATE_EDIT_CELL
export const handleFormulerDataGridEdit = (
  item: SysGenRowTableModel,
  newData: any,
  value: any,
  object: any,
  currency?: string,
  ma_cty?: string
) => {
  if (item.column_name) {
    try {
      try {
        if (item.formule_column_name1 && item.formule_column_name1 !== "") {
          if (item.formule_logic1 && item.formule_logic1 !== "") {
            if((item.formule_logic1.includes('DHSRoundAmount') || item.formule_logic1.includes('DHSRoundPrice')) && currency && currency !== '' && ma_cty && ma_cty !=="") {
              let temp = item.formule_logic1.replace('@ma_cty', ma_cty)
              temp = temp.replace('@ma_nt', currency)
              newData[item.formule_column_name1] = eval(`${temp}`);
            } 
            else {
              newData[item.formule_column_name1] = eval(`${item.formule_logic1}`);
            }
          } else {
            newData[item.formule_column_name1] = object[item.formule_column_name1];
          }
        }
      } catch (error) {
        alert(`Lỗi config Formuler 1: 
        column name: ${item.formule_column_name1} 
        logic: ${item.formule_logic1}
        lỗi: ${error}` );
        
        console.log(error);
        
      }

      try {
        if (item.formule_column_name2 && item.formule_column_name2 !== "") {
          if (item.formule_logic2 && item.formule_logic2 !== "") {
            if((item.formule_logic2.includes('DHSRoundAmount') || item.formule_logic2.includes('DHSRoundPrice')) && currency && currency !== '' && ma_cty && ma_cty !=="") {
              let temp = item.formule_logic2.replace('@ma_cty', ma_cty)
              temp = temp.replace('@ma_nt', currency)
              newData[item.formule_column_name2] = eval(`${temp}`);
            } 
            else {
              newData[item.formule_column_name2] = eval(`${item.formule_logic2}`);
            }
          } else {
            newData[item.formule_column_name2] = object[item.formule_column_name2];;
          }
        }
      } catch (error) {
        alert(`Lỗi config Formuler 2: 
        column name: ${item.formule_column_name2}
        logic: ${item.formule_logic2}` );
      }

      try {
        if (item.formule_column_name3 && item.formule_column_name3 !== "") {
          if (item.formule_logic3 && item.formule_logic3 !== "") {
            if((item.formule_logic3.includes('DHSRoundAmount') || item.formule_logic3.includes('DHSRoundPrice')) && currency && currency !== '' && ma_cty && ma_cty !=="") {
              let temp = item.formule_logic3.replace('@ma_cty', ma_cty)
              temp = temp.replace('@ma_nt', currency)
              newData[item.formule_column_name3] = eval(`${temp}`);
            } 
            else {
              newData[item.formule_column_name3] = eval(`${item.formule_logic3}`);
            }
          } else {
            newData[item.formule_column_name3] = object[item.formule_column_name3];;
          }
        }
      } catch (error) {
        alert(`Lỗi config Formuler 3: 
            column name: ${item.formule_column_name3} 
            logic: ${item.formule_logic3}` );
      }

      try {
        if (item.formule_column_name4 && item.formule_column_name4 !== "") {
          if (item.formule_logic4 && item.formule_logic4 !== "") {
            if((item.formule_logic4.includes('DHSRoundAmount') || item.formule_logic4.includes('DHSRoundPrice')) && currency && currency !== '' && ma_cty && ma_cty !=="") {
              let temp = item.formule_logic4.replace('@ma_cty', ma_cty)
              temp = temp.replace('@ma_nt', currency)
              newData[item.formule_column_name4] = eval(`${temp}`);
            } 
            else {
              newData[item.formule_column_name4] = eval(`${item.formule_logic4}`);
            }
          } else {
            newData[item.formule_column_name4] = object[item.formule_column_name4];;
          }
        }
      } catch (error) {
        alert(`Lỗi config Formuler 4: 
            column name: ${item.formule_column_name4} 
            logic: ${item.formule_logic4}` );
      }

      try {
        if (item.formule_column_name5 && item.formule_column_name5 !== "") {
          if (item.formule_logic5 && item.formule_logic5 !== "") {
            if((item.formule_logic5.includes('DHSRoundAmount') || item.formule_logic5.includes('DHSRoundPrice')) && currency && currency !== '' && ma_cty && ma_cty !=="") {
              let temp = item.formule_logic5.replace('@ma_cty', ma_cty)
              temp = temp.replace('@ma_nt', currency)
              newData[item.formule_column_name5] = eval(`${temp}`);
            } 
            else {
              newData[item.formule_column_name5] = eval(`${item.formule_logic5}`);
            }
          } else {
            newData[item.formule_column_name5] = object[item.formule_column_name5];;
          }
        }
      } catch (error) {
        alert(`Lỗi config Formuler 5: 
            column name: ${item.formule_column_name5} 
            logic: ${item.formule_logic5}` );
      }

      try {
        if (item.formule_column_name6 && item.formule_column_name6 !== "") {
          if (item.formule_logic6 && item.formule_logic6 !== "") {
            if((item.formule_logic6.includes('DHSRoundAmount') || item.formule_logic6.includes('DHSRoundPrice')) && currency && currency !== '' && ma_cty && ma_cty !=="") {
              let temp = item.formule_logic6.replace('@ma_cty', ma_cty)
              temp = temp.replace('@ma_nt', currency)
              newData[item.formule_column_name6] = eval(`${temp}`);
            } 
            else {
              newData[item.formule_column_name6] = eval(`${item.formule_logic6}`);
            }
          } else {
            newData[item.formule_column_name6] = object[item.formule_column_name6];;
          }
        }
      } catch (error) {
        alert(`Lỗi config Formuler 6: 
            column name: ${item.formule_column_name6} 
            logic: ${item.formule_logic6}` );
      }
    } catch (error) {
      alert("Lỗi config fomuler");
    } finally {
      newData[item.column_name] = value;
    }
  }
};
