import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Switch,
    styled,
    TableContainer,
    Paper,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    tableCellClasses,
    Table,
    Alert,
    AlertTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    CommonClient,
    HomeClient,
    QLCCApartmentModel,
    QLCCClient,
    RoleRightWebModel,
    SoVch05Client,
} from "../../../shared/service-proxies/api-shared";



import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import CTXDateTime from "../../../../components/CTX/DateTimePicker";
import '../ApartmentBuilding.css';
import { SoVch05M } from "../../../shared/service-proxies/api-shared";
import { FormatDate, FormatMoney } from "../../../../components/controller/Format";
import { useWindowSize } from "../../../../common/useWindowWide";

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    qLCCSoVch05MLogs?: any;
    cycleName?: any;
    dataMaster?: QLCCApartmentModel
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


var appSession: AppSession;
const ServiceInvoiceDetailAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#94a3b8',
            color: 'black',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    // React.useEffect(() => {
    //     setLoading(true);
    //     homeClient
    //         ?.roleRightWebGetAll({
    //             ...new RoleRightWebModel(),
    //             rolename: "CC",
    //             username: SessionLogin.userName,
    //             menuid: menu?.menuid,
    //         } as RoleRightWebModel)
    //         .then((res) => {
    //             setRoles(res);
    //             setLoading(false);
    //         });
    //     setLoading(true);
    // }, []);

    React.useEffect(() => {

    }, [props.open, props.itemSelected])

    const sizeWindow = useWindowSize();

    return (
        <Dialog
            // id="sysMenu4"
            open={props.open}
            onClose={props.onClose}
            // sx={{
            //     display: "flex",
            //     justifyContent: "flex-end",
            // }}
            TransitionComponent={Transition}
            fullWidth
            fullScreen={sizeWindow.width < 600}
            maxWidth='xl'
        >
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        Lịch sử gửi thông báo phí
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >
                <div className="d-flex w-100 justify-content-center">
                    <div className="m-2"><div style={{ height: '10px', width: '10px', background: 'rgba(53, 146, 57,0.3)', display: "inline-block", margin: '0 3px' }}></div>Đã xem</div>
                    <div className="m-2"><div style={{ height: '10px', width: '10px', background: '#fff4e5', display: "inline-block", margin: '0 3px' }}></div>Chưa xem</div>
                </div>
                {
                    props.qLCCSoVch05MLogs
                    && props.qLCCSoVch05MLogs?.length > 0
                    && props.qLCCSoVch05MLogs.map((item: any, index: any) => (
                        <Box className="my-3 px-3 py-2" style={{ width: '100%', borderRadius: '7px', backgroundColor: item.is_read ? 'rgba(53, 146, 57,0.3)' : '#fff4e5', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                            <div style={{ width: '100%' }} className="d-sm-flex justify-content-between align-items-center">
                                {
                                    !item.is_read
                                        ? (
                                            <div>
                                                <p className="m-0"><strong>Thông báo thu phí tháng {props.cycleName}</strong></p>
                                                <p className="m-0">Căn hộ <strong>{item.apartment_name}</strong></p>
                                                <p className="m-0">Chủ hộ: <div style={{ minWidth: '100px', display: 'inline-block' }}><strong>{props.dataMaster?.owner_resident_name ?? ""}</strong></div>, Điện thoại: </p>
                                                <p className="m-0">Tổng tiền: <strong>{`${FormatMoney(item?.total_old_debt ?? 0) ?? 0} đ`}</strong></p>
                                            </div>
                                        )
                                        : (
                                            <div>
                                                <p className="m-0"><strong>Thông báo thu phí tháng {props.cycleName}</strong></p>
                                                <p className="m-0">Căn hộ <strong>{item.apartment_name}</strong></p>
                                                <p className="m-0">Chủ hộ: <div style={{ minWidth: '100px', display: 'inline-block' }}><strong>{props.dataMaster?.owner_resident_name ?? ""}</strong></div>, Điện thoại: </p>
                                                <p className="m-0">Tổng tiền: <strong>{`${FormatMoney(item?.total_old_debt ?? 0) ?? 0} đ`}</strong></p>
                                            </div>
                                        )
                                }
                                <div>
                                    <p className="m-0">Lần {props.qLCCSoVch05MLogs.length - index > 0 ? props.qLCCSoVch05MLogs.length - index : 0}</p>
                                    <p className="m-0"><i>{item?.date_add_string}</i></p>
                                </div>
                            </div>
                        </Box>
                    ))
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Đóng</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default ServiceInvoiceDetailAction;


