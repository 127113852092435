import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  TextField,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  InputLabel,
  Box,
  Slide,
  Link,
  MenuList,
  Autocomplete,
  IconButton,
  InputAdornment,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  TextareaAutosize,
} from "@mui/material";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

import "./ThongTinTau.css";
import {
  ChoThueXeClient,
  CtxDanhMucXeModel,
  KiemSoatCanClient,
  KscThongTinTauModel,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";

import { TransitionProps } from "@mui/material/transitions";

import BlockUI from "../../../../components/Block-UI/Block-ui";

interface IProps {
  open: boolean;
  itemSelected?: any;
  action?: any;
  title?: string;
  onClose: () => void;
  onSubmit?: (data: any, action: any) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});
var appSession: AppSession;
const ThongTinTau = (props: IProps) => {
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

  const [_id, setId] = React.useState<string>();
  const kscClient = new KiemSoatCanClient(appSession, BASE_URL_API);
  const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);

  const [data, setData] = React.useState<KscThongTinTauModel>(
    new KscThongTinTauModel()
  );
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const [hangTauOptions, setHangTauOptions] = React.useState<any>([]);

  React.useEffect(() => {
    if (props.action === "UPDATE" || props.action === "DETAIL") {
      setLoading(true);
      kscClient
        .kscThongTinTauActions({
          ...new KscThongTinTauModel(),
          id: props.itemSelected,
          action_type: "GET",
        } as KscThongTinTauModel)
        .then((res) => {
          setData(res[0]);
          setLoading(false);
        })
        .catch((err) => alert("Không lấy được dữ liệu"));
      setId(props.itemSelected);
    } else setId(undefined);
  }, [props.action]);

  React.useEffect(() => {
    setLoading(true);
    ctxClient
      ?.ctxDanhMucXeActions({
        ...new CtxDanhMucXeModel(),
        tbname: "kscHangTau",
        action: "GET",
      } as CtxDanhMucXeModel)
      .then((res) => {
        setHangTauOptions(res);
        setLoading(false);
      });
  }, []);
  const handleSubmit = () => {
    if (_id || props.action === "UPDATE") {
      setLoading(true);
      kscClient
        .kscThongTinTauActions({
          ...data,
          action_type: "UPDATE",
        } as KscThongTinTauModel)
        .then((response) => {
          setLoading(false);
          alert(response[0].message);
        });
    } else {
      setLoading(true);
      kscClient
        .kscThongTinTauActions({
          ...data,
          action_type: "INSERT",
        } as KscThongTinTauModel)
        .then((response) => {
          setId(response[0].id);
          setLoading(false);
          alert(response[0].message);
        });
    }
  };

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value } as KscThongTinTauModel);
  };

  return (
    <Dialog
      id="sysMenu2"
      open={props.open}
      onClose={props.onClose}
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          backgroundColor: "#FFF",
          color: "#1890ff",
          boxShadow: 0,
        }}
      >
        <Toolbar>
          <Typography
            sx={{ flex: 1, fontWeight: 700 }}
            variant="h6"
            component="div"
          >
            {props?.title
              ? props.title
              : menu?.bar +
                " " +
                `${
                  props.action === "DETAIL"
                    ? "[Chi tiết]"
                    : props.action === "UPDATE" || _id
                    ? "[Cập nhật]"
                    : "[Thêm mới]"
                }`}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Divider />
      <DialogContent>
        <Box style={{ width: "100%", margin: "5px 0px" }}>
          <InputLabel sx={{ fontSize: 14 }}>Tên tàu</InputLabel>
          <TextField
            className="ksc-input"
            sx={{ width: "100%" }}
            name="ten_tau"
            onChange={handleChangeInput}
            value={data?.ten_tau ? data.ten_tau : ""}
            disabled={props.action === "DETAIL"}
          />
        </Box>
        <Box style={{ width: "100%", margin: "5px 0px" }}>
          <InputLabel sx={{ fontSize: 14 }}>Hãng tàu</InputLabel>
          <TextField
            className="ksc-input"
            sx={{ width: "100%" }}
            name="hang_tau_id"
            onChange={handleChangeInput}
            value={data?.hang_tau_id ? data.hang_tau_id : ""}
            disabled={props.action === "DETAIL"}
            select
          >
            <MenuItem key="loai_cho_thue" value="">
              --Chọn--
            </MenuItem>
            {hangTauOptions.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box style={{ width: "100%", margin: "5px 0px" }}>
          <InputLabel sx={{ fontSize: 14 }}>Biển số tàu</InputLabel>
          <TextField
            className="ksc-input"
            sx={{ width: "100%" }}
            name="bien_so_tau"
            onChange={handleChangeInput}
            value={data?.bien_so_tau ? data.bien_so_tau : ""}
            disabled={props.action === "DETAIL"}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSubmit}>
          Lưu
        </Button>
        <Button onClick={props.onClose}>Đóng</Button>
      </DialogActions>
      <BlockUI blocking={isLoading} title={""}></BlockUI>
    </Dialog>
  );
};

export default ThongTinTau;
