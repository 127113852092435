import * as types from "../constants/ActionTypes";
import { AnyAction } from "redux";
var initialState = {
  detailIns: null,
  detailUpdate: null,
  detailDelete: null,
};

var DetailPageReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.DHS_DETAIL_INS: {
      return {
        ...state,
        detailIns: action.payload,
      };
    }
    case types.DHS_DETAIL_UPD: {
      return {
        ...state,
        detailUpdate: action.payload,
      };
    }
    case types.DHS_DETAIL_DEL: {
      return { ...state, detailDelete: action.payload };
    }
    case types.CLEAR_DETAIL: {
      return {
        ...state,
        detailIns: null,
        detailUpdate: null,
        detailDelete: null,
      };
    }
    default:
      return state;
  }
};

export default DetailPageReducer;
