import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "../../../components/layouts";
import { getRoles } from "../../../redux/actions/RoleActions";
import { AppSession } from "../../shared/app-session/app-session";
import {
  ChoThueXeClient,
  CtxDanhMucXeModel,
  HomeClient,
  OBItemClient,
  ObItem,
  QLCCClient,
  QLCCUtilityGroupModel,
  RoleRightWebModel,
  SysActionType,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import { useHistory, useLocation } from "react-router-dom";
import BlockUI from "../../../components/Block-UI/Block-ui";
import notification from "../../../common/notification/notification";
import DHSToolbarRole from "../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import DataGrid from "devextreme-react/data-grid";
import dxDataGrid, {
  RowInsertingInfo,
  RowRemovingInfo,
  RowUpdatingInfo,
  SelectionChangedInfo,
} from "devextreme/ui/data_grid";
import { ACTIONS } from "../../../common/enums/DHSToolbarRoleAction";
import { EventInfo } from "devextreme/events";
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import {
  STATUS_MESSAGE,
  TYPE_MESSAGE,
} from "../../../common/Interfaces/StatusMessage";
import DHSBreadcrumb from "../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import { getDataRowSelected, getIndexRowSelected, onAddRow, onDeleteRow, onEditRow, onViewRow } from "../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import ItemBalanceAction from "./action/ItemBalanceAction";

var appSession: AppSession;

interface IAction {
  open: boolean;
  type: string;
  payload: any;
}

const defaultAction: IAction = {
  open: false,
  type: "",
  payload: null,
};

const roleMenu: string = "CC";
const status_message = ["success", "error"];

const ItemBalance: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");


  const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [idItemSelected, setIdItemSelected] = React.useState<string>("");
  // const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const [reload, setReload] = React.useState<boolean>(false);
  const [dataTable, setDataTable] = React.useState<ObItem[]>([]);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const qlccClient = new QLCCClient(appSession, BASE_URL_API);
  const obClient = new OBItemClient(appSession, BASE_URL_API);

  const [actCx, setActCx] = useState<IAction>(defaultAction);
  //

  const dataGridRef = React.useRef<DataGrid>(null);
  const [allowEdit, setAllowEdit] = useState<boolean>(true);
  const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
  const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

  useEffect(() => {
    setLoading(true);

    homeClient
      .roleRightWebGetAll({
        ...new RoleRightWebModel(),
        menuid: menu?.menuid,
        rolename: roleMenu,
        username: SessionLogin.userName,
      } as RoleRightWebModel)
      .then((res) => {
        dispatch(getRoles(res));
        setRoles(res);
        setLoading(false);
      });
  }, [menu?.component]);

  useEffect(() => {
    setLoading(true);
    obClient
      .obItemGet({
        ...new ObItem(),
        ma_cty: SessionLogin.ma_cty,
        apartment_building_id: apartment_building_id,
      } as ObItem | any)
      .then((res) => {
        setLoading(false);
        setDataTable(res);
      });
  }, [reload, apartment_building_id]);

  const handleSelectionChanged = (
    e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
  ) => {
  };

  const handlePageChange = () => {
    setSelectedRowIndex(-1);
    setSelectedItemKeys(undefined);
  };

  const handleRowInserting = async (
    event: EventInfo<dxDataGrid<any, any>> & RowInsertingInfo<any>
  ) => {
    try {
      event.cancel = true;
      setLoading(true);
      const response = await obClient.obItemIns({
        ...new ObItem(),
        ...event.data,
      } as ObItem);
      if (response.status === 0) {
        setDataTable([{ id: response.id, ...event.data }, ...dataTable]);
        dataGridRef.current?.instance.cancelEditData();
        notification(
          TYPE_MESSAGE.SUCCESS,
          STATUS_MESSAGE[`${ACTIONS.ADD}_${response.status!}`]
        );
      } else {
        notification(
          TYPE_MESSAGE.ERRORR,
          STATUS_MESSAGE[`${ACTIONS.ADD}_${response!.status}`]
        );
      }
    } catch (error) {
      notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
    } finally {
      setLoading(false);
    }
  };

  const handleRowUpdating = async (
    event: EventInfo<dxDataGrid<ObItem, any>> & RowUpdatingInfo<ObItem, any>
  ) => {
    try {
      event.cancel = true;
      setLoading(true);
      const response = await obClient.obItemUpd({
        ...new ObItem(),
        ...event.oldData,
        ...event.newData,
      } as ObItem);
      if (response.status === 0) {
        const dataLastUpdated: ObItem[] = dataTable.map((item) => {
          if (item.idob === event.key) {
            return {
              ...new ObItem(),
              ...event.oldData,
              ...event.newData,
            } as ObItem;
          } else {
            return item;
          }
        });

        setDataTable([...dataLastUpdated]);
        dataGridRef.current?.instance.cancelEditData();
        notification(
          TYPE_MESSAGE.SUCCESS,
          STATUS_MESSAGE[`${ACTIONS.EDIT}_${response.status!}`]
        );
      } else {
        notification(
          TYPE_MESSAGE.ERRORR,
          STATUS_MESSAGE[`${ACTIONS.EDIT}_${response.status!}`]
        );
      }
    } catch (error) {
      notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
    } finally {
      setLoading(false);
    }
  };

  const handleRowRemoving = async (
    event: EventInfo<dxDataGrid<ObItem, any>> & RowRemovingInfo<ObItem, any>
  ) => {
    try {
      event.cancel = true;
      setLoading(true);
      const response = await qlccClient.qLCCUtilityGroupActions({
        ...new ObItem(),
        idob: event.data.idob,
      } as ObItem);

      if (response[0].status === 0) {
        setDataTable([...dataTable.filter((item) => item.idob !== event.key)]);
        notification(
          TYPE_MESSAGE.SUCCESS,
          STATUS_MESSAGE[`${ACTIONS.DELETE}_${response[0].status}`]
        );
      } else {
        notification(
          TYPE_MESSAGE.ERRORR,
          STATUS_MESSAGE[`${ACTIONS.DELETE}_${response[0].status}`]
        );
      }
    } catch (error) {
      notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
    } finally {
      setLoading(false);
    }
  };

  // const handleClickAction = async (action: SysActionType) => {
  //   switch (action?.action_code?.toLocaleUpperCase()) {
  //     case ACTIONS.ADD:
  //       await onAddRow(dataGridRef, setAllowEdit);
  //       break;
  //     case ACTIONS.DELETE:
  //       await onDeleteRow(dataGridRef)
  //       break;
  //     case ACTIONS.EDIT:
  //       await onEditRow(dataGridRef, setAllowEdit);
  //       break;
  //     case ACTIONS.VIEW:
  //       await onViewRow(dataGridRef, setAllowEdit);
  //       break;

  //     default:
  //       break;
  //   }
  // };

  const handleClickAction = async (action: SysActionType) => {

    // if (!apartmentBuildingSelected?.key) {
    //     notification(convert[1], "Vui lòng chọn dự án!")
    // }
    // else {
    switch (action?.action_code?.toLocaleUpperCase()) {
      // case ACTIONS.FILTER:
      //     setFilter({ ...filter, open: true })

      //     break;
      case ACTIONS.DELETE:
        if (dataGridRef.current) {
          try {
            const dataSelected = await getDataRowSelected<ObItem>(dataGridRef);
            const index = await getIndexRowSelected(dataGridRef);

            if (dataGridRef.current && dataSelected && index >= 0) {
              if (window.confirm("Bạn có chắc muốn xóa")) {
                setLoading(true)
                const response = await obClient.obItemDel({
                  ...new ObItem(),
                  idob: dataSelected.idob,
                  ma_cty: SessionLogin.ma_cty,
                  nam: dataSelected.nam

                } as ObItem);
                if (response.status === 0) {
                  setReload(pre => !pre)
                  notification(
                    TYPE_MESSAGE.SUCCESS,
                    STATUS_MESSAGE[`${ACTIONS.DELETE}_${response.status}`]
                  );
                } else {
                  notification(
                    TYPE_MESSAGE.ERRORR,
                    STATUS_MESSAGE[`${ACTIONS.DELETE}_${response.status}`]
                  );
                }


              }
            } else {
              notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
              );
            }
          } catch (error) {
            notification(
              TYPE_MESSAGE.WARNING,
              STATUS_MESSAGE[`WARNING_SELECT_ROW`]
            );
          }
        }
        break;
      case ACTIONS.ADD:
        setActCx({
          ...actCx,
          open: true,
          type: action?.action_code?.toUpperCase() ?? "",
          payload: {
            // service_id: selectedItemKeys?.id,
            // apartment_building_id: apartmentBuildingSelected?.key
          }
        });
        break;
      case ACTIONS.VIEW:
      case ACTIONS.EDIT:
        if (dataGridRef.current) {
          try {
            const dataSelected = await getDataRowSelected<ObItem>(dataGridRef);
            const index = await getIndexRowSelected(dataGridRef);

            if (dataGridRef.current && dataSelected && index >= 0) {
              setActCx({
                ...actCx,
                open: true,
                type: action?.action_code?.toUpperCase() ?? "",
                payload: dataSelected
              });
            }
            else {
              notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
              );
            }
          } catch (error) {
            notification(
              TYPE_MESSAGE.WARNING,
              STATUS_MESSAGE[`WARNING_SELECT_ROW`]
            );
          }
        }
        break;
    }
    // }


  };

  return (
    <div className="container-fluid">
      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          marginTop: 15,
          marginBottom: 15,
          fontSize: 22,
          fontWeight: 550,
        }}
      >
        {

          React.useMemo(() => <ItemBalanceAction
            open={actCx.open}
            onClose={function (): void {
              setActCx(defaultAction)
            }}
            onEditSuccess={function (idEdited, dataEdited): void {
              // if (idEdited) {
              //     const tempData = [...dataTable];
              //     const index = tempData.findIndex(x => x.id === idEdited);
              //     if (index >= 0 && dataEdited) {
              //         tempData[index] = dataEdited;
              //         setDataTable(tempData);
              //     }
              // }
              setReload(pre => !pre)
            }}
            onAddSuccess={(idAdded, dataAdded) => {
              // if (idAdded) {
              //     const tempData = [...dataTable];
              //     if (dataAdded) {
              //         tempData.unshift({...dataAdded, id: idAdded} as QLCCServiceModel)
              //         setDataTable(tempData);
              //     }
              // }
              setReload(pre => !pre)
            }}
            action={actCx.type}
            itemSelected={actCx.payload} >

          </ItemBalanceAction>, [actCx.open, actCx.payload, actCx.type])

        }
        <DHSBreadcrumb location={location} />
      </div>

      <DHSToolbarRole
        id={' '}
        menu={menu}
        onClickAction={handleClickAction}
      />
      <DHSDataGridEdit
        data={dataTable}
        table_name={menu?.component}
        keyExpr="idob"
        mode="popup"
        dataGridRef={dataGridRef}
        allowEdit={allowEdit}
        pageSize={menu?.page_size}
        isPagination={menu?.is_pagination}
        isMultiple={menu?.is_multiple}
        groupingAutoExpandAll={false}
        handlePageChange={handlePageChange}
        handleSelectionChanged={handleSelectionChanged}
        handleRowInserting={handleRowInserting}
        handleRowUpdating={handleRowUpdating}
        handleRowRemoving={handleRowRemoving}
      />
      <BlockUI blocking={loading} title={""}></BlockUI>
    </div>
  );
};

export default ItemBalance;
