import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer, Notification } from '../../../../components/layouts';
import { AppSession } from '../../../shared/app-session/app-session';
import { QLCCApartmentBuildingModel, QLCCClient, QLCCTaskModel, SysActionType, } from '../../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../../shared/service-proxies/config';
import { useLocation } from 'react-router-dom';
import DHSBreadcrumb from '../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb';
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { EventInfo } from "devextreme/events";
import DataGrid from 'devextreme-react/data-grid';
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import { getIndexRowSelected, getKeyRowSelected } from '../../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../../common/Interfaces/StatusMessage';
import TaskCheckListAction from '../../actions/work/task-check-list-action';
import TaskSearcher from './task-search';
import moment from 'moment';
import { autoCheckPermissionTaskOfAccount } from './task-list';
import { MESSAGE_TYPE } from '../../../../common/Interfaces/StatusMessage';
import { setApartmentBuilding } from '../../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction';

var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any,
    taskData: any;
}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null,
    taskData: {},
}

const TYPE_CODE_OF_REPORT: string = "3";
const TaskCheckList: React.FC<{}> = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const qlccClient = new QLCCClient(appSession, BASE_URL_API);
    const [dataTable, setDataTable] = React.useState<QLCCTaskModel[]>([] as QLCCTaskModel[]);
    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [openSearch, setOpenSearch] = React.useState<boolean>(false);
    const [data, setData] = useState<QLCCTaskModel>({} as QLCCTaskModel);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const onloadData = () => {
        qlccClient.qLCCTaskGet({
            ...new QLCCTaskModel(),
            type_code: TYPE_CODE_OF_REPORT,
            ma_cty: SessionLogin.ma_cty,
            username_login: SessionLogin.userName,
            ...data,
            start_time: moment(data.start_time).utc(true).toDate(),
            end_time: moment(data.end_time).utc(true).toDate(),
            apartment_building_id: apartment_building_id,
        } as QLCCTaskModel)
            .then(res => {
                setDataTable(res);
            }).catch(err => console.log(err))
    }

    React.useEffect(() => {
        onloadData()
    }, [apartment_building_id])

    React.useEffect(() => {
        if (reload) {
            onloadData()
        }
    }, [reload])

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code) {
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: action?.action_code,
                    payload: idItemSelected,
                })
                break;
            case ACTIONS.VIEW:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: action?.action_code,
                                payload: idItemSelected,
                                taskData: dataTable.find(item => item.id === selectedItemKeys)
                            });
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.EDIT:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key) {
                            if (autoCheckPermissionTaskOfAccount(dataTable, idItemSelected, SessionLogin.userName)) {

                                setActCx({
                                    ...actCx,
                                    open: true,
                                    type: action?.action_code,
                                    payload: idItemSelected,
                                    taskData: dataTable.find(item => item.id === selectedItemKeys)
                                });
                            } else {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`NO_PERMISSIONS`]
                                );
                            }
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }

                break;
            case ACTIONS.UPDATETASK:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key) {
                            if (autoCheckPermissionTaskOfAccount(dataTable, idItemSelected, SessionLogin.userName)) {

                                setActCx({
                                    ...actCx,
                                    open: true,
                                    type: action?.action_code,
                                    payload: idItemSelected,
                                    taskData: dataTable.find(item => item.id === selectedItemKeys)
                                });
                            } else {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`NO_PERMISSIONS`]
                                );
                            }
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }

                break;
            case ACTIONS.COPY:
                break;
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const key = await getKeyRowSelected(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        if (dataGridRef.current && key) {
                            if (window.confirm("Xác nhận xóa")) {
                                const getItem = dataTable.find(item => item.id === key);
                                if (getItem) {
                                    qlccClient.qLCCTaskDel({
                                        ...new QLCCTaskModel(),
                                        ...getItem,
                                        username_login: SessionLogin.userName
                                    } as QLCCTaskModel)
                                        .then(res => {
                                            if (res) {
                                                Notification(
                                                    MESSAGE_TYPE[res.status!],
                                                    res.message
                                                );
                                                if (res.status === 0) {
                                                    onloadData();
                                                }
                                            } else {
                                                Notification(
                                                    TYPE_MESSAGE.ERRORR,
                                                    STATUS_MESSAGE[`DELETE_1`]
                                                );
                                            }
                                        })
                                } else {
                                    Notification(
                                        TYPE_MESSAGE.ERRORR,
                                        STATUS_MESSAGE[`DELETE_1`]
                                    );
                                }
                            }
                        } else {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.CLOSE:
                break;
            case ACTIONS.SEARCH:
                setOpenSearch(pre => !pre);
                break;
            case ACTIONS.SAVE:
                break;
        }

        setReload(false);
    }

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>();

    const handlePageChange = () => {
        setSelectedItemKeys(undefined);
    };

    const handleSelectionChanged = (e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                setSelectedItemKeys(keys[0]);
                setIdItemSelected(keys[0] || "");
            });
        }
    };

    return (
        <div className='container-fluid'>
            <TaskCheckListAction
                open={actCx.open}
                onClose={function (): void {
                    setActCx(defaultAction)
                    onloadData()
                }}
                onEditSuccess={function (is_success): void {
                    onloadData()
                }}
                action={actCx.type}
                itemSelected={actCx.payload}
                data={actCx.taskData}
                apartmentBuildingId={apartment_building_id}
            />

            <TaskSearcher
                open={openSearch}
                onClose={() => setOpenSearch(pre => !pre)}
                action_type={"checkList"}
                handleSearch={(e) => {
                    if (e) {
                        setDataTable([...e])
                        if(e && e.length > 0) setOpenSearch(false)
                    }
                }}
                setData={(dataN: QLCCTaskModel) => setData({ ...data, ...dataN } as QLCCTaskModel)}
                setApartmentBuilding={(id: string, name?: string) => {
                    dispatch(setApartmentBuilding({
                        id: id,
                        name: name
                    } as QLCCApartmentBuildingModel))
                }}
            />

            <div className="d-flex title-color" style={{ height: 30, color: "rgb(0 78 255 / 88%)", marginTop: 15, marginBottom: 15, fontSize: 22, fontWeight: 550, }}>
                <DHSBreadcrumb location={location} />
            </div>

            <DHSToolbarRole id={" "} menu={menu} customMenu={[]} onClickAction={handleClickAction} />
            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                // key="id"
                keyExpr='id'
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
                handlePageChange={handlePageChange}
                handleSelectionChanged={handleSelectionChanged}
            />
        </div>
    )
}

export default TaskCheckList;


