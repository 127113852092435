import { MenuItem, TextField } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import SelectChip from "../DHS_SelectChip";
import "./style.css";

export type DHS_SelectOption = {
  value: string;
  label: string;
};
interface IProps {
  onChange?: (value: any) => void;
  label?: string;
  fieldValue: string;
  fieldDisplay: string;
  options: any[];
  className?: string;
  disabled?: boolean | false;
  value?: string;
  multiple?: boolean;
  name?: string;
  loading?: boolean;
  clearable?: boolean;
  searchable?: boolean;
  id?: string;
  placeholder?: string;
}
interface IOption {
  value: string;
  label: string;
}
const DHS_Select: React.FC<IProps> = (props) => {
  const {
    id,
    label,
    searchable = true,
    placeholder,
    clearable = true,
    loading,
    options,
    onChange,
    className,
    value,
    disabled,
    multiple,
    name,
    fieldValue,
    fieldDisplay,
  } = props;

  const selectDropdownRef = React.useRef<HTMLDivElement | null>(null);
  const dropdownRef = React.useRef<HTMLDivElement | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [currentValue, setCurrentValue] = React.useState<
    DHS_SelectOption | DHS_SelectOption[]
  >(value ? options.find((item) => item.value === value) || [] : []);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value.toLowerCase());
  };
  const handleOpenDropdown = () => {
    if (!open) {
      setOpen(true);
    }
  };
  const handleCloseDropdown = () => {
    setOpen(false);
  };
  const setOnChangeProps = (
    newValue: DHS_SelectOption | DHS_SelectOption[],
  ) => {
    if (onChange) {
      if (multiple) {
        onChange((newValue as DHS_SelectOption[]).map((item) => item.value));
      } else {
        if (Array.isArray(newValue)) {
          onChange(
            (newValue as DHS_SelectOption[])
              .map((item) => item.value)
              .join(","),
          );
        } else {
          onChange((newValue as DHS_SelectOption).value);
        }
      }
    }
  };
  const checkHasItem = (item: DHS_SelectOption): boolean => {
    return (currentValue as DHS_SelectOption[]).some(
      (value) => value.value === item.value,
    );
  };

  const removeItem = (e: React.MouseEvent, item: DHS_SelectOption) => {
    setCurrentValue(
      (currentValue as DHS_SelectOption[]).filter(
        (value) => value.value !== item.value,
      ),
    );
    setOnChangeProps(
      (currentValue as DHS_SelectOption[]).filter(
        (value) => value.value !== item.value,
      ),
    );
  };
  const removeAllItem = () => {
    setCurrentValue([]);
    setOnChangeProps([]);
  };

  const handleOptionClick = (e: any) => {
    if (onChange) onChange(e);
    // if (multiple) {
    //     e.stopPropagation()
    //     e.preventDefault()
    // }
    // let newValue: DHS_SelectOption | DHS_SelectOption[]
    // if (multiple) {
    //     if (checkHasItem(item)) {
    //         newValue = (currentValue as DHS_SelectOption[]).filter(value => value.value !== item.value)
    //     } else {
    //         newValue = [...currentValue as DHS_SelectOption[], item]
    //     }
    // } else {
    //     newValue = item
    // }
    // setCurrentValue(newValue)
    // setOnChangeProps(newValue)
    // handleCloseDropdown()
  };

  useEffect(() => {
    if (selectDropdownRef && selectDropdownRef.current) {
      const clearInput = () => {
        setInputValue("");
      };
      selectDropdownRef.current.addEventListener("click", clearInput);
      return () => {
        selectDropdownRef.current?.removeEventListener("click", clearInput);
      };
    }
  }, [selectDropdownRef]);

  useEffect(() => {
    setCurrentValue(
      value ? options.find((item) => item.value === value) || [] : [],
    );
  }, [value]);
  useEffect(() => {
    if (!multiple) {
      if (dropdownRef && dropdownRef.current && selectedIndex >= 0) {
        const dropdownItem = dropdownRef.current?.querySelectorAll(
          ".dropdown-item",
        )[selectedIndex] as HTMLSpanElement;
        dropdownRef.current.scrollTo(0, dropdownItem.offsetTop - 100);
      }
    }
  }, [currentValue, dropdownRef, selectedIndex, open]);
  useEffect(() => {
    const f = (e: MouseEvent) => {
      if (!selectDropdownRef.current?.contains(e.target as Node) && open) {
        setOpen(false);
      }
    };
    document.addEventListener("click", f);
    return () => {
      document.removeEventListener("click", f);
    };
  }, [open]);

  return (
    // <div className='bg-white'>
    //     {label && <label className='form-label w-100'>{label}</label>}
    //     <div ref={selectDropdownRef} onClick={handleOpenDropdown} className={`select-container d-flex justify-content-end align-items-center border position-relative rounded ${disabled ? 'bg-secondary' : ''}`}>
    //         <div className='flex-grow-1 p-1 d-flex flex-wrap value-container'>
    //             {
    //                 multiple ?
    //                     (currentValue as DHS_SelectOption[]).map(item => {
    //                         return <SelectChip key={item.value} value={item.label} onRemove={(e) => removeItem(e, item)} />
    //                     })
    //                     : <span className='p-1'>{(currentValue as DHS_SelectOption).label}</span>
    //             }
    //             {
    //                 (currentValue as DHS_SelectOption[]).length <= 0 && <span className='text-black-50'>{placeholder}</span>
    //             }
    //         </div>
    //         {!disabled && <div className='form-icon'>
    //             {clearable && <span className='select-item btn-remove'>
    //                 <i className="bi bi-x-lg btn" onClick={removeAllItem}></i></span>}
    //                 <span className='indicator'></span>
    //                 <i className="bi bi-caret-down btn"></i>
    //         </div>}
    //         {!disabled && open && <div className="dropdown-menu show w-100" ref={dropdownRef}>
    //             {searchable && <div className='p-2 bg-white position-sticky top-0' >
    //                 <input type="text" className='form-control ' placeholder='Tìm kiếm...' value={inputValue} onChange={onInputChange} />
    //             </div>}
    //             {
    //                 options.map((item, index) => {
    //                     if (multiple && checkHasItem(item)) {
    //                         return null
    //                     }
    //                     if ((currentValue as DHS_SelectOption).value === item.value && (selectedIndex < 0 || selectedIndex !== index)) {
    //                         setSelectedIndex(index)
    //                     }
    //                     if (item.value.toLowerCase().includes(inputValue) || item.label.toLowerCase().includes(inputValue)) {
    //                         return <span key={item.value} className={`dropdown-item ${!multiple && item.value === (currentValue as DHS_SelectOption).value ? 'bg-primary text-white' : ''}`} onClick={(e) => handleOptionClick(e, item)}>{item.label}</span>
    //                     }
    //                 })
    //             }
    //         </div>}
    //     </div>
    // </div>
    <>
      {label && <label className="form-label w-100 label-input">{label}</label>}
      {options ? (
        <TextField
          disabled={disabled}
          margin="normal"
          label={""}
          select
          value={value ? value : ""}
          onChange={handleOptionClick}
          name={props.name}
        >
          <MenuItem key={'-1'} value={''}>
            {'---Chọn---'}
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option[fieldValue]} value={option[fieldValue]}>
              {option[fieldDisplay]}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <></>
      )}
    </>
  );
};

export default DHS_Select;
