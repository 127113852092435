import { Layout, Menu } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { AppSession } from "../../../../../app/shared/app-session/app-session";
import {
  LoginClient,
  LoginModel,
} from "../../../../../app/shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../../app/shared/service-proxies/config";
// import "./index.css";

interface IProps {
  onLogout?: () => void;
  userInfo?: any;
}

let appSession: AppSession;
const UpdatePasswordPage = (props: IProps) => {
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const loginClient = new LoginClient(appSession, BASE_URL_API);
  const [data, setData] = React.useState<LoginModel>(new LoginModel());
  const history = useHistory();
  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value } as LoginModel);
  };
  const OnSubmit = () => {
    if (!data.password_new || !data.confirm_password_new) {
      alert("Mật khẩu mới không được phép trống");
      return;
    }
    if (data.password_new != data.confirm_password_new) {
      alert("Mật khẩu mới không khớp");
      return;
    }
    let pr = data;
    pr.userName = SessionLogin.userName;
    loginClient.userInfoUpdPassword(pr).then((res: LoginModel[]) => {
      if (res[0].status == 0) {
        alert(res[0].message);
        history.push("/");
      } else alert(res[0].message);
    });
  };
  return (
    <div className="d-flex flex-direction-column justify-content-center align-items-center h-100 w-100 dhs-background">
      <form onSubmit={OnSubmit} className="dhs-form">
        <div>
          <div
            style={{ fontSize: 35, fontWeight: "bold", marginBottom: "75px" }}
          >
            Đổi mật khẩu
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1" style={{ fontWeight: "normal" }}>
            Mật khẩu cũ
          </label>
          <input
            className="flex-grow-1 form-control"
            type="password"
            name="password"
            onChange={handleChangeInput}
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="exampleInputPassword1"
            style={{ fontWeight: "normal" }}
          >
            Mật khẩu mới
          </label>
          <input
            className="flex-grow-1 form-control"
            type="password"
            name="password_new"
            onChange={handleChangeInput}
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="exampleInputPassword1"
            style={{ fontWeight: "normal" }}
          >
            Xác nhận mật khẩu mới
          </label>
          <input
            className="flex-grow-1 form-control"
            type="password"
            name="confirm_password_new"
            onChange={handleChangeInput}
          />
        </div>
        <br />
        <div>
          <button
            type="submit"
            className="btn btn-primary"
            style={{ width: "150px" }}
          >
            Xác nhận
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdatePasswordPage;
