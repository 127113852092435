// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import DHSToolbar from '../../../../components/DHS/DHS-Toolbar/DHSToolbar';
// import { IReducer } from '../../../../components/layouts';
// import DHSGridView from '../../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
// import { getRoles } from '../../../../redux/actions/RoleActions';
// import { AppSession } from '../../../shared/app-session/app-session';
// import {
//     BaseformClient,
//     CaVch01Client,
//     CaVch01M,
//     HomeClient,
//     ParasysActionType,
//     RoleRightWebModel,
//     RptCATMNH01,
//     RptCATMNH01Model,
//     SoVch01Client,
//     SoVch05M,
//     SysActionType,
// } from '../../../shared/service-proxies/api-shared';
// import BASE_URL_API from '../../../shared/service-proxies/config';

// import { useHistory } from 'react-router-dom';
// import BlockUI from '../../../../components/Block-UI/Block-ui';
// import notification from '../../../../common/notification/notification';
// import { SoVch05Client } from '../../../shared/service-proxies/api-shared';
// import moment from 'moment';
// import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputLabel, MenuItem, Modal, Select, Slide, TextField, Toolbar, Typography } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import CTXDate from '../../../../components/CTX/DatePicker';
// import BetweenTwoDate from './component/between-two-date-X';
// import { callApiToken } from '../../../../routes/configApi';
// import '../../actions/ApartmentBuilding.css';
// import CCGridView from './component/CCGridView';
// import { TransitionProps } from '@mui/material/transitions';
// import SearchIcon from '@mui/icons-material/Search';
// import DHSToolbarRole from '../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole';
// import { ACTIONS } from '../../../../common/enums/DHSToolbarRoleAction';
// import { useHotkeys } from 'react-hotkeys-hook';
// import DHSDialogAction from '../../../../components/DHS/DHS_DialogAction/DialogAction';

// const convert = ["success", "error", "warning"];
// var appSession: AppSession;

// interface IAction {
//     open: boolean,
//     type: string,
//     payload: any

// }

// interface IPayment {
//     paymentAmount: string | undefined,
//     paymentType: string | undefined
// }

// const defaultAction: IAction = {
//     open: false,
//     type: "",
//     payload: null
// }

// interface IReportInfo {
//     spname: string | undefined,
//     title: string | undefined,
//     header: string[] | undefined,
// }

// const Transition = React.forwardRef(function Transition(
//     props: TransitionProps & {
//         children: React.ReactElement<any, any>;
//     },
//     ref: React.Ref<unknown>,
// ) {
//     return <Slide direction="left" ref={ref} {...props} />;
// });

// const roleMenu: string = 'CC';
// const CashBookReport: React.FC<{}> = () => {

//     const dispatch = useDispatch();
//     const history = useHistory();
//     const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
//     const [loading, setLoading] = React.useState<boolean>(false);
//     const [idItemSelected, setIdItemSelected] = React.useState<string>("");
//     const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
//     const [reload, setReload] = React.useState<boolean>(false);
//     const vch05Client = new SoVch05Client(appSession, BASE_URL_API);
//     const vch01Client = new SoVch01Client(appSession, BASE_URL_API);
//     const caVch01Client = new CaVch01Client(appSession, BASE_URL_API);
//     const homeClient = new HomeClient(appSession, BASE_URL_API);
//     const [dataTable, setDataTable] = React.useState<RptCATMNH01[]>([] as RptCATMNH01[]);
//     const [actCx, setActCx] = useState<IAction>(defaultAction);
//     const [isShowModal, setIsShowModal] = React.useState<boolean>(false);
//     const [payment, setPayment] = React.useState<IPayment>({} as IPayment);
//     const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

//     const Token = {
//         Token: SessionLogin.Token
//     }
//     const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
//     const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
//     const [isShowSort, setShowSort] = React.useState<boolean>(false);
//     const [isShowFilter, setShowFilter] = React.useState<boolean>(false);

//     const [isShowDate, setIsShowDate] = React.useState<boolean>(true);
//     const [fromDate, setFromDate] = React.useState<Date>(new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), 2));
//     const [toDate, setToDate] = React.useState<Date>(new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 1));
//     const customMenu = [
//         {
//             menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
//             onClick: () => {
//                 setShowSearch(!isShowSearch);
//             },
//         },
//         {
//             menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
//             onClick: () => {
//                 setShowGroup(!isShowGroup);
//             },
//         },
//         {
//             menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
//             onClick: () => {
//                 setShowSort(!isShowSort);
//             },
//         },
//         {
//             menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
//             onClick: () => {
//                 setShowFilter(!isShowFilter);
//             },
//         },
//     ];

//     const [reportInfoList, setReportInfoList] = React.useState<any>([]);
//     const [reportInfo, setReportInfo] = React.useState<IReportInfo>({} as IReportInfo)
//     const [isOpen, setIsOpen] = React.useState<boolean>(false);

//     const [actionTypes, setActionTypes] = React.useState<ParasysActionType[]>();
//     const baseFormClient = new BaseformClient(appSession, BASE_URL_API);

//     const [isExport, setIsExport] = React.useState<boolean>(false);

//     useHotkeys<HTMLParagraphElement>('ctrl+f1', () => setIsOpen(!isOpen));

//     useHotkeys<HTMLParagraphElement>('alt+1', () => setShowSearch(!isShowSearch));

//     useHotkeys<HTMLParagraphElement>('alt+2', () => setShowGroup(!isShowGroup));

//     useHotkeys<HTMLParagraphElement>('alt+3', () => setShowSort(!isShowSort));

//     useHotkeys<HTMLParagraphElement>('alt+4', () => setShowFilter(!isShowFilter));

//     const onloadData = () => {
//         setLoading(true);
//         callApiToken("api/caReport/CashBookReport", "POST", {
//             ma_cty: SessionLogin.ma_cty,
//             tk: '',
//             ngay_Ct1: fromDate,
//             ngay_ct2: toDate,
//         }, Token)
//             .then(response => {
//                 setDataTable(response.data)
//                 setLoading(false);
//             }).catch(error => {
//                 console.log(error)
//             })
//     }

//     React.useEffect(() => {
//         setLoading(true);
//         homeClient
//             .roleRightWebGetAll({
//                 ...new RoleRightWebModel(),
//                 menuid: menu?.menuid,
//                 rolename: roleMenu,
//                 username: SessionLogin.userName,
//             } as RoleRightWebModel)
//             .then((res) => {
//                 dispatch(getRoles(res));
//                 setRoles(res);
//                 setLoading(false);
//             })
//             .catch((err) => {
//                 setLoading(false)
//             })

//         baseFormClient.getActionType({
//             ...new ParasysActionType(),
//             action_type: menu?.action_type,
//             language: 'vi-VN'
//         } as ParasysActionType)
//             .then(res => {
//                 setActionTypes(res);
//             })

//         callApiToken("api/Common/ReportInfoGet", "POST", {
//             menuid: menu?.menuid,
//             language: "vi-VN"
//         }, Token)
//             .then(response => {
//                 setReportInfoList(response.data);
//                 if (response.data.length > 0) {
//                     setReportInfo({
//                         ...reportInfo,
//                         ...response.data[0],
//                         header: response.data[0]?.formateD_COL_LIST.split(',')
//                     } as IReportInfo);
//                 }
//             }).catch(error => {
//                 console.log(error)
//             })

//     }, [menu?.component])

//     React.useEffect(() => {
//         onloadData();
//     }, [reload]);

//     const handleClickAction = (action: SysActionType, nowTime: Date) => {
//         if (action.action_code === ACTIONS.SEARCH) {
//             setIsOpen(true);
//         } else if (action.action_code === ACTIONS.EXPORTEXCEL) {
//             setIsExport(!isExport);
//         }else if(action.action_code === ACTIONS.OK){
//             onloadData()
//         }else if(action.action_code === ACTIONS.CLOSE){
//             setIsOpen(false)
//         }
//     }

//     const handleChange = (e: any) => {
//         const { name, value } = e.target;
//         if (name === 'reportInfo') {
//             const item = reportInfoList.find((e: any) => e.spname === value);
//             if (item) {
//                 setReportInfo({
//                     ...reportInfo,
//                     spname: value,
//                     header: item.formateD_COL_LIST.split(','),
//                     title: item.title,
//                 } as IReportInfo);
//             }
//         }
//     }

//     const handleSubmit = () => {
//         onloadData();
//     }

//     return (
//         <div className='container-fluid'>
//             {/* Hiển thị loại báo cáo */}
//             <Dialog
//                 id='sysMenu3'
//                 open={isOpen}
//                 TransitionComponent={Transition}
//                 onClose={() => setIsOpen(false)}
//                 aria-describedby="alert-dialog-slide-description"
//                 className='d-flex justify-content-end'
//             >
//                 <AppBar
//                     sx={{
//                         position: "relative",
//                         backgroundColor: "#FFF",
//                         color: "#1890ff",
//                         boxShadow: 0,
//                     }}
//                 >
//                     <Toolbar>
//                         <Typography
//                             sx={{ flex: 1, fontWeight: 700 }}
//                             variant="h6"
//                             component="div"
//                         >
//                             Lọc báo cáo
//                         </Typography>
//                         <IconButton
//                             edge="end"
//                             color="inherit"
//                             onClick={() => setIsOpen(false)}
//                             aria-label="close"
//                         >
//                             <CloseIcon />
//                         </IconButton>
//                     </Toolbar>
//                 </AppBar>
//                 <Divider />
//                 <DialogContent>

//                     {/* Mẫu báo cáo */}
//                     <Box className='w-100 mt-2'>
//                         <InputLabel className='cc-label'>Mẫu báo cáo</InputLabel>
//                         <TextField
//                             className='cc-input'
//                             select
//                             style={{ width: '100%' }}
//                             name='reportInfo'
//                             value={reportInfo?.spname}
//                             onChange={handleChange}
//                         >
//                             {
//                                 reportInfoList
//                                 && reportInfoList.map((row: any) => (
//                                     <MenuItem value={row.spname}>{row.ten_mau}</MenuItem>
//                                 ))
//                             }
//                         </TextField>
//                     </Box>
//                     {/* Tiêu đề */}
//                     <Box className='w-100 mt-4'>
//                         <InputLabel className='cc-label'>Tiêu đề</InputLabel>
//                         <TextField
//                             className='cc-input'
//                             style={{ width: '100%' }}
//                             value={reportInfo?.title}
//                         />
//                     </Box>

//                     {/* Giữa 2 ngày */}
//                     <Box className='w-100 mt-4'>
//                         <BetweenTwoDate
//                             fromDate={(newDate: any) => setFromDate(newDate)}
//                             toDate={(newDate: any) => setToDate(newDate)}
//                             reload={() => setReload(!reload)}
//                         />
//                     </Box>

//                 </DialogContent>
//                 <DialogActions>

//                     <DHSDialogAction

//                         menu={menu}
//                         onClickAction={handleClickAction}

//                     />

//                 </DialogActions>
//             </Dialog>

//             <div
//                 className="d-flex title-color"
//                 style={{
//                     height: 30,
//                     color: "rgb(0 78 255 / 88%)",
//                     marginTop: 15,
//                     marginBottom: 15,
//                     fontSize: 22,
//                     fontWeight: 550,
//                 }}
//             >
//                 <i
//                     className="bi bi-arrow-left-short"
//                     style={{ cursor: "pointer" }}
//                     onClick={() => history.push("/admin/quan-ly-quy")}
//                 ></i>
//                 &nbsp;{menu?.bar}
//             </div>

//             {/* Control */}
//             <DHSToolbarRole
//                 id={idItemSelected as string}
//                 menu={menu}
//                 customMenu={customMenu}
//                 onClickAction={handleClickAction}
//             />

//             <CCGridView
//                 dataSource={dataTable}
//                 selection={false} // ẩn chon nhiều đi
//                 id='stt_rec'
//                 header={reportInfo.header || []}
//                 setIdItemSelected={(lstId: string) => setIdItemSelected(lstId)}
//                 isShowFilter={isShowFilter}
//                 isShowGroup={isShowGroup}
//                 isShowSearch={isShowSearch}
//                 isShowSort={isShowSort}
//                 isExport={isExport} // xuất excel
//                 setIsExport={() => setIsExport(!isExport)} // tự động tắt xuất excel
//                 table_name='Sổ quỹ tiền mặt'
//             />

//             <BlockUI blocking={loading} title={""}></BlockUI>
//         </div>
//     )
// }

// export default CashBookReport;

import React, { useEffect, useRef, useState } from "react";

import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { EventInfo } from "devextreme/events";
import dxDataGrid, { SelectionChangedInfo } from "devextreme/ui/data_grid";
import DataGrid from "devextreme-react/data-grid";

import { IReducer, Notification } from "../../../../components/layouts";
import {
  ReportInfo,
  SYS_Token_AuthenticationModel,
  SysActionType,
} from "../../../shared/service-proxies/api-shared";
import { httpPost } from "../../../../common/httpService";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { exportExcelReport } from "../../../../components/DHS/DHS_ExportExcelReport/exportExcelReport";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import DHSPopupReport from "../../../../components/DHS/DHS_Popup_Report/DHSPopupReport";
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import DHSBreadcrumb from "../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";

type Props = {};

const CashBookReport = (props: Props) => {
  const SessionLogin: SYS_Token_AuthenticationModel = JSON.parse(
    localStorage.getItem("SessionLogin") || "{}"
  );
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const location = useLocation();

  const dataGridRef = useRef<DataGrid>(null);
  const [data, setData] = useState<any[]>([]);

  const [fromDate, setFromDate] = React.useState<Date>(
    new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1, 0, 0, 0))
  );
  const [toDate, setToDate] = React.useState<Date>(
    new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 0, 23, 59, 59))
  );

  const [reportInfo, setReportInfo] = React.useState<ReportInfo>();
  const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

  useEffect(() => {
    (async () => {
      try {
        if (reportInfo && reportInfo.spname) {
          setLoading(true);
          const res = await httpPost<string>("api/caReport/CAReport", {
            ma_cty: SessionLogin.ma_cty,
            tk: "",
            ma_nt: "vnd",
            ngay_Ct1: fromDate,
            ngay_ct2: toDate,
            spname: reportInfo?.spname || "",
            apartment_building_id: apartment_building_id
          });
          const dataAfterParse = JSON.parse(res.data);
          setData([...dataAfterParse.Table]);
        } else {
          setData([]);
        }
      } catch (error) {
        setData([]);
      } finally {
        setLoading(false);
      }
    })();
  }, [reload, reportInfo]);

  const refreshData = async () => {
    setReload(!reload);
  };

  const handleSelectionChanged = (
    e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
  ) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
        console.log(keys);

        const index = e.component.getRowIndexByKey(keys[0]);

        setSelectedRowIndex(index >= 0 ? index : -1);
        setSelectedItemKeys(keys[0]);
      });
    }
  };

  const handlePageChange = () => {
    setSelectedRowIndex(-1);
    setSelectedItemKeys(undefined);
  };

  const handleClickAction = (action: SysActionType, nowTime: Date) => {
    switch (action?.action_code?.toLocaleUpperCase()) {
      case ACTIONS.SEARCH:
        setIsOpen(true);
        break;
      case ACTIONS.EXPORTEXCEL:
        console.log(dataGridRef.current);

        if (dataGridRef.current && reportInfo) {
          if (reportInfo) {
            exportExcelReport(dataGridRef, reportInfo, fromDate, toDate);
          } else {
            Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
          }
          //   exportExcelReport(dataGridRef, reportInfo, fromDate, toDate);
        }
        break;
      case ACTIONS.PRINT:
        break;

      default:
        break;
    }
  };

  const handleExporting = (e: any) => {
    if (reportInfo) {
      try {
        exportExcelReport(dataGridRef, reportInfo, fromDate, toDate);
      } catch (error) {
        Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
      }
    } else {
      Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          marginTop: 15,
          marginBottom: 15,
          fontSize: 22,
          fontWeight: 550,
        }}
      >
        <DHSBreadcrumb
          location={location}
        />
      </div>
      <DHSToolbarRole
        id={selectedItemKeys ? selectedItemKeys?.id : undefined}
        menu={menu}
        onClickAction={handleClickAction}
      />
      <DHSDataGridEdit
        data={data}
        table_name={menu?.menuid}
        // key="so_ct"
        mode="popup"
        dataGridRef={dataGridRef}
        allowEdit={false}
        pageSize={menu?.page_size}
        isPagination={menu?.is_pagination}
        isMultiple={menu?.is_multiple}
        groupingAutoExpandAll={false}
        handlePageChange={handlePageChange}
        handleSelectionChanged={handleSelectionChanged}
        handleExporting={handleExporting}
      />

      <DHSPopupReport
        setReportInfo={setReportInfo}
        reportInfo={reportInfo}
        refreshData={refreshData}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      <BlockUI blocking={loading} title={""}></BlockUI>
    </Box>
  );
};

export default CashBookReport;
