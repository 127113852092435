import React, { useEffect } from "react";
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
    Grid,
    Stack,
    CircularProgress,
    Link,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
} from "@mui/material";
import CurrencyTextField from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import CloseIcon from "@mui/icons-material/Close";

import {
    QLCCClient,
    QLCCApartmentBuildingModel,
    SysActionType,
    CommonClient,
    CaVch01Client,
    LstCurrency,
    ParaObjExchageRate,
    ParaObj,
    QLCCOBCashBank,
    QLCCResidentsModel,
    ApBookClient,
    LstAccount,
    QLCCVehicleCardModel,
    QLCCVehicleModel,
    VehicleClient,
} from "../../../shared/service-proxies/api-shared";


import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../ApartmentBuilding.css";
import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import CTXDate from "../../../../components/CTX/DatePicker";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import DHSAutoCompleteTableResident from "../../../../components/DHS/DHS_AutoCompleteResident/DHSAutoCompleteTableResident";
import notification from "../../../../common/notification/notification";
import DHSAutoCompleteWithDataGrid from "../../../../components/DHS/DHS_AutoCompleteCustomer/DHSAutoCompleteWithDataGrid";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import VehicleInfo from "./vehicle-info";
import { BreakStyle } from "devextreme-react/chart";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";




interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: () => void;
    vehicleCard?: any;
    apartmentBuildings?: QLCCApartmentBuildingModel[] | undefined;
    payload?: any;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const actionsTodo = [
    {
        id: "is_cancel",
        title: "1. Thẻ hủy",
        titleDate: "Ngày hủy",
        nameDate: "date_cancel",
        isADD: false
    },
    {
        id: "is_return",
        title: "2. Thẻ trả lại",
        titleDate: "Ngày trả",
        nameDate: "date_return",
        isADD: false

    },
    // {
    //     id: "is_vip",
    //     title: "3. Thẻ VIP",
    //     titleDate: "Ngày cấp",
    //     nameDate: "date_add",
    //     isADD: null
    // },
    {
        id: "is_renew",
        title: "4. Thẻ cấp lại",
        titleDate: "Ngày cấp lại",
        nameDate: "date_add",
        isADD: false
    },
    {
        id: "is_sell",
        title: "5. Thẻ bán",
        titleDate: "Ngày bán",
        nameDate: "date_add",
        isADD: true
    },
    {
        id: "is_sell_available",
        title: "6. Thẻ bán ( thẻ có sẵn)",
        titleDate: "Ngày bán",
        nameDate: "date_add",
        isADD: true
    },
    {
        id: "is_change",
        title: "7. Thẻ đổi",
        titleDate: "Ngày đổi",
        nameDate: "date_add",
        isADD: false
    },
]


const status_message = ['success', 'error']
var appSession: AppSession;

const VehicleBrokenCardAction: React.FC<IProps> = (props: IProps) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const [_id, setId] = React.useState<string>();

    const qLCClient = new QLCCClient(appSession, BASE_URL_API);

    const [isLoading, setLoading] = React.useState<boolean>(false);

    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [vehicleCard, setVehicleCard] = React.useState<QLCCVehicleCardModel>({} as QLCCVehicleCardModel);

    const qlccClient = new QLCCClient(appSession, BASE_URL_API);

    const [actionTodo, setActionToDo] = React.useState<string>("");

    const [vehicleInfo, setVehicleInfo] = React.useState<QLCCVehicleModel>({} as QLCCVehicleModel);

    const [openVehicleInfo, isOpenVehicleInfo] = React.useState<boolean>(false);

    const [vehicleList, setVehicleList] = React.useState<QLCCVehicleModel[]>([]);

    const [apartmentBuildingOptions, setApartmentBuildingOptions] = React.useState<QLCCApartmentBuildingModel[]>([]);

    const [apartmentBuilding, setApartmentBuilding] = React.useState<QLCCApartmentBuildingModel>({} as QLCCApartmentBuildingModel);


    React.useEffect(() => {
        setLoading(true);

        qLCClient.qlccApartmentBuildingGet({
            ...new QLCCApartmentBuildingModel(),
            ma_cty: SessionLogin.ma_cty,
            action_type: 'GET'
        } as QLCCApartmentBuildingModel)
            .then((res: any) => {
                setApartmentBuildingOptions(res);
                if (res) {
                    setApartmentBuilding(res[0]);
                }
                setLoading(false);
            });


    }, [])

    React.useEffect(() => {
        if (props.open === true) {

            setVehicleInfo({
                ...new QLCCVehicleModel(),
            } as QLCCVehicleModel)

            if (props.action === ACTIONS.ADD) {

                setLoading(true);

                setVehicleCard({
                    ... new QLCCVehicleCardModel(),
                } as QLCCVehicleCardModel);

                setActionToDo("");

                setLoading(false);

            } else {

                const itemVehicleInfo = vehicleList.find(p => p.id === props.vehicleCard.vehicle_id);

                if (itemVehicleInfo) {

                    setVehicleCard({
                        ...props.vehicleCard,
                        vehicle_info: itemVehicleInfo,
                    } as QLCCVehicleCardModel);

                    setVehicleInfo({
                        ...itemVehicleInfo
                    } as QLCCVehicleModel)

                } else {
                    setVehicleCard({
                        ...props.vehicleCard,
                    } as QLCCVehicleCardModel);
                }

                if (props.vehicleCard.is_cancel) {
                    setActionToDo("is_cancel");
                } else if (props.vehicleCard.is_return) {
                    setActionToDo("is_return");
                } else if (props.vehicleCard.is_renew) {
                    setActionToDo("is_renew");
                } else if (props.vehicleCard.is_sell) {
                    setActionToDo("is_sell");
                } else if (props.vehicleCard.is_broken) {
                    setActionToDo("is_broken");
                } else if (props.vehicleCard.is_sell_available) {
                    setActionToDo("is_sell_available");
                } else if (props.vehicleCard.is_change) {
                    setActionToDo("is_change");
                }

                setLoading(false);

            }
        }

    }, [props.open, props.itemSelected, props.vehicleCard]);


    React.useEffect(() => {
        if (apartmentBuilding && props.action === ACTIONS.ADD) {
            qLCClient.qLCCVehicleCardNewNumberGet(apartmentBuilding.id)
                .then(res => {
                    setVehicleCard({
                        ...vehicleCard,
                        card_number: res
                    } as QLCCVehicleCardModel)
                }).catch(err => {
                    console.log(err)
                });

        }
    }, [apartmentBuilding, props.open])

    const handleChange = (e: any) => {

        if (props.action !== ACTIONS.VIEW) {

            const { name, value } = e.target;

            setVehicleCard({
                ...vehicleCard,
                [name]: value,
            } as QLCCVehicleCardModel);
        }
    }

    const handleChangeDate = (e: any) => {
        const { name, value } = e;

        if (props.action !== ACTIONS.VIEW) {

            setVehicleCard({
                ...vehicleCard,
                [name]: value,
            } as QLCCVehicleCardModel);

        }
    }

    const handleSelect = (e: any) => {
        const { name, value } = e.target;
        setActionToDo(value);
    }

    const handleUpdate = () => {
        const newVehicleCard = {
            ...vehicleCard,
            is_cancel: actionTodo == "is_cancel" ? true : false,
            is_return: actionTodo == "is_return" ? true : false,
            is_renew: actionTodo == "is_renew" ? true : false,
            is_sell: actionTodo == "is_sell" ? true : false,
            is_broken: actionTodo == "is_broken" ? true : false,
            is_change: actionTodo == "is_change" ? true : false,
            is_sell_available: actionTodo == "is_sell_available" ? true : false,
            id: props.vehicleCard.id,
            card_number: vehicleCard.card_number,
            vehicle_info: vehicleInfo ? vehicleInfo : undefined,
            vehicle_id: vehicleInfo.id,
            vehicle_name: vehicleInfo.vehicle_name,
            luser: SessionLogin.userName,
            cuser: SessionLogin.userName,
            apartment_building_id: vehicleInfo.apartment_building_id ? vehicleInfo.apartment_building_id : apartmentBuilding.id,
            is_cancel_return_card: actionTodo === "is_cancel" ? vehicleCard.is_cancel_return_card : false,
        } as QLCCVehicleCardModel;

        qlccClient.qLCCVehicleCardUpd({
            ...new QLCCVehicleCardModel(),
            ...newVehicleCard
        } as QLCCVehicleCardModel)
            .then((res: any) => {
                notification(status_message[res.status], res.message)
                if (res.status === 0) {
                    props.onEditSuccess();
                    props.onClose();
                }
            }).catch(err => {
                console.log(err)
            })

    }


    const status = (status: number = 1, message: string = "") => {
        notification(status_message[status], message);
        if (status === 0) {
            props.onEditSuccess();
            props.onClose()
        }
    }

    const handleSubmit = () => {
        const newVehicleCard = {
            ...vehicleCard,
            is_cancel: actionTodo == "is_cancel" ? true : false,
            is_return: actionTodo == "is_return" ? true : false,
            is_renew: actionTodo == "is_renew" ? true : false,
            is_sell: actionTodo == "is_sell" ? true : false,
            is_broken: actionTodo == "is_broken" ? true : false,
            is_change: actionTodo == "is_change" ? true : false,
            is_sell_available: actionTodo == "is_sell_available" ? true : false,
            vehicle_info: vehicleInfo ? vehicleInfo : undefined,
            vehicle_id: vehicleInfo.id,
            vehicle_name: vehicleInfo.vehicle_name,
            cdate: new Date(),
            ldate: new Date(),
            luser: SessionLogin.userName,
            cuser: SessionLogin.userName,
            apartment_building_id: vehicleInfo.apartment_building_id ? vehicleInfo.apartment_building_id : apartmentBuilding.id,
            is_cancel_return_card: actionTodo === "is_cancel" ? vehicleCard.is_cancel_return_card : false,
        } as QLCCVehicleCardModel;

        qLCClient.qLCCVehicleCardIns({
            ...new QLCCVehicleCardModel(),
            ...newVehicleCard
        } as QLCCVehicleCardModel)
            .then((res: any) => {
                notification(status_message[res.status], res.message)
                if (res.status === 0) {
                    props.onEditSuccess();
                    props.onClose();
                }
            }).catch(err => {
                console.log(err)
            })
    }

    const handleClickAction = (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code) {
            case ACTIONS.SAVE:

                if (props.action === ACTIONS.ADD) {
                    handleSubmit();

                } else if (props.action === ACTIONS.EDIT) {
                    handleUpdate();
                }

                break;

            case ACTIONS.CLOSE:

                props.onClose();

                break;
        }
    }


    const handleChangeCurrentInput = (

        value: string | undefined, name?: string | undefined, values?: CurrencyInputOnChangeValues | undefined) => {

        if (props.action !== ACTIONS.VIEW) {
            setVehicleCard({
                ...vehicleCard,
                [name!]: value,
            } as QLCCVehicleCardModel);
        }

    }


    const handleChangeSwitch = (e: any) => {
        const { name, checked } = e.target;


        if (props.action !== ACTIONS.VIEW) {

            setVehicleCard({
                ...vehicleCard,
                [name]: checked,
            } as QLCCVehicleCardModel);

        }
    }

    const handleChangeInput = (e: any) => {

        const { name, value } = e.target;

        const item = apartmentBuildingOptions.find(p => p.id === value);

        if (item) {
            setApartmentBuilding(item);
            if (item.id !== vehicleInfo.apartment_building_id) {
                setVehicleInfo({} as QLCCVehicleModel);
                setVehicleCard({
                    ...vehicleCard,
                    vehicle_info: {} as QLCCVehicleModel
                } as QLCCVehicleCardModel)

            }
        }
    };

    return (

        <>

            <Dialog
                // id="sysMenu"

                open={props.open}
                onClose={props.onClose}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
                TransitionComponent={Transition}
                maxWidth="md"
                fullWidth
            >

                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            {menu?.bar +
                                " " +
                                `${props.action === "VIEW"
                                    ? "[Chi tiết]"
                                    : props.action === "EDIT" || _id
                                        ? "[Cập nhật]"
                                        : "[Thêm mới]"
                                }`}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} >
                            <Box className="w-100 d-md-flex justify-content-between">
                                <InputLabel className="cc-label w-100">Dự án</InputLabel>
                                <DHSAutoCompleteWithDataSource
                                    id="apartment-building-select"
                                    className="cc-input"
                                    dataSource={apartmentBuildingOptions}
                                    displayMember="name"
                                    valueMember="id"
                                    value={apartmentBuilding.id}
                                    onValueChanged={(e, newValue: any) => {
                                        handleChangeInput({
                                            target: {
                                                name: "apartment_building_id",
                                                value: newValue?.id ?? null,
                                            },
                                        });
                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option?.id}>
                                                {option?.name}
                                            </li>
                                        );
                                    }}
                                    disabled={props.action === ACTIONS.VIEW}
                                />
                                {/* <Autocomplete
                                    id="apartment-building-select"
                                    sx={{ width: "100%" }}
                                    options={apartmentBuildingOptions}
                                    autoHighlight
                                    getOptionLabel={(option: any) => `${option?.name}`}
                                    value={
                                        apartmentBuilding.id
                                            ? apartmentBuildingOptions.find(
                                                (item: any) => item.id === apartmentBuilding.id
                                            )
                                            : null
                                    }
                                    onChange={(e, newValue: any) => {
                                        handleChangeInput({
                                            target: {
                                                name: "apartment_building_id",
                                                value: newValue?.id ?? null,
                                            },
                                        });
                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option?.id}>
                                                {option?.name}
                                            </li>
                                        );
                                    }}

                                    renderInput={(params) => (
                                        <TextField
                                            {...params}

                                            placeholder='Chọn chung cư'
                                            className="cc-input"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                        />
                                    )}

                                /> */}
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <Box className="w-100 d-md-flex justify-content-between">
                                <InputLabel className="cc-label w-100">Số thẻ</InputLabel>
                                <TextField
                                    className="cc-input w-100"
                                    placeholder="Mã số thẻ xe"
                                    value={vehicleCard.card_number}
                                    name="card_number"
                                    onChange={handleChange}
                                // disabled={true}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <Box className="w-100 d-md-flex justify-content-between">
                                <InputLabel className="cc-label w-100">Trạng thái</InputLabel>
                                <Box className='w-100'>
                                    <Select
                                        className="cc-input w-100"
                                        value={actionTodo}
                                        onChange={handleSelect}
                                    >
                                        {
                                            actionsTodo
                                            && (
                                                props.action === ACTIONS.ADD
                                                    ? actionsTodo.filter(p => p.isADD !== false).map(todo =>
                                                    (
                                                        <MenuItem key={todo.id} value={todo.id}>{todo.title}</MenuItem>
                                                    ))
                                                    : actionsTodo.map(todo =>
                                                    (
                                                        <MenuItem key={todo.id} value={todo.id}>{todo.title}</MenuItem>
                                                    ))
                                            )
                                        }
                                    </Select>
                                    {
                                        actionTodo === actionsTodo[0].id
                                        && (
                                            <FormControlLabel
                                                control={<Switch
                                                    name="is_cancel_return_card"
                                                    onChange={handleChangeSwitch}
                                                    checked={vehicleCard.is_cancel_return_card || false}
                                                />}
                                                label="Trả thẻ xe" />

                                        )
                                    }
                                    <FormControlLabel
                                        control={<Switch
                                            name="is_vip"
                                            onChange={handleChangeSwitch}
                                            checked={vehicleCard.is_vip || false}
                                        />}
                                        label="Thẻ VIP" />
                                </Box>
                            </Box>
                        </Grid>
                        {
                            actionTodo
                            && (
                                <Grid item xs={12} md={12} >
                                    <Box className="d-md-flex justify-content-between">
                                        <InputLabel className="cc-label w-100">{actionsTodo.find(p => p.id === actionTodo)?.titleDate}</InputLabel>
                                        <CTXDate
                                            className="cc-input w-100"
                                            value={actionTodo === 'is_cancel' ? vehicleCard.date_cancel : actionTodo === 'is_return' ? vehicleCard.date_return : vehicleCard.date_add}
                                            name={actionsTodo.find(p => p.id === actionTodo)?.nameDate}
                                            onChange={handleChangeDate}
                                        />
                                    </Box>
                                </Grid>
                            )
                        }
                        <Grid item xs={12} md={12} >
                            <Box className="w-100 d-md-flex justify-content-between">
                                <InputLabel className="cc-label w-100">Khách hàng</InputLabel>
                                <TextField
                                    className="cc-input w-100"
                                    placeholder="Khách hàng"
                                    value={vehicleInfo.owner_name && vehicleInfo.apartment_name ? `${vehicleInfo.owner_name} - ${vehicleInfo.apartment_name}` : null}
                                    onClick={() => isOpenVehicleInfo(true)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <Box className="d-md-flex justify-content-between">
                                <InputLabel className="cc-label w-100">Ngày cấp</InputLabel>
                                <CTXDate
                                    className="cc-input w-100"
                                    value={vehicleCard.ldate}
                                    name="ldate"
                                    onChange={handleChangeDate}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12} >
                            <InputLabel className="cc-label w-100">Ghi chú</InputLabel>
                            <Box className="w-100 d-md-flex justify-content-between">

                                <TextField
                                    className="cc-input w-100"
                                    placeholder="Nội dung"
                                    rows={4}
                                    multiline
                                    name="notes"
                                    value={vehicleCard.notes}
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>


                {
                    props.action !== ACTIONS.VIEW
                    && (<>
                        <DialogActions>

                            <DHSDialogAction

                                menu={menu}
                                onClickAction={handleClickAction}
                                action={props.action}
                            />

                        </DialogActions>
                    </>)
                }


                <BlockUI blocking={isLoading} title={""}></BlockUI>
            </Dialog >

            <VehicleInfo
                open={openVehicleInfo}
                setVehicleInfo={(data: QLCCVehicleModel) => setVehicleInfo(data)}
                onClose={() => isOpenVehicleInfo(!openVehicleInfo)}
                setVehicle={(data: QLCCVehicleModel[]) => setVehicleList(data)}
            />

        </>
    );
};

export default VehicleBrokenCardAction;
