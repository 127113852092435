import * as types from "../constants/ActionTypes";
import { AnyAction } from "redux";
import {
  RoleRightWebModel,
  SysMenu,
} from "../../app/shared/service-proxies/api-shared";

const initialState = {
  listMenu: Array<SysMenu>(),
  listRole: Array<RoleRightWebModel>(),
  menu: SysMenu,
  menuid: "30.00.00",
  role: RoleRightWebModel,
  roleid: ""
};
var RoleReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.GET_ALL_MENU: {
      return {
        ...state,
        listMenu: action.payload
      };
    }
    case types.GET_MENU: {
      return {
        ...state,
        menuid: action.payload,
      };
    }
    case types.GET_MENU_BY_WORKER: {
      return {
        ...state,
        menu: action.payload,
      };
    }
    case types.GET_MENU_SHOW_GRID: {

      return {
        ...state,
        menu: action.payload
      }
    }
    case types.CLEAR_MENU: {
      return {
        ...state,
        menuid: "",
      };
    }
    case types.GET_ROLE: {
      return {
        ...state,
        role: action.payload
      }
    }
    case types.GET_ROLES: {
      return {
        ...state,
        listRole: action.payload
      }
    }
    case types.CLEAR_ROLE: {
      return {
        ...state,
        role: null
      }
    }
    case types.CLEAR_ROLES: {
      return {
        ...state,
        role: null
      }
    }
    default:
      return state;
  }
};

export default RoleReducer;
