import React from "react";
import TagBox, { ITagBoxOptions } from "devextreme-react/tag-box";

interface IProps extends ITagBoxOptions{

}

const DHSTagBox : React.FC<IProps> = (props) => {
    return <TagBox
                {...props}
              />
}

export default DHSTagBox;