import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    HomeClient,
    QLCCApartmentBuildingModel,
    QLCCClient,
    QLCCInvestorModel,
    QLCCResidentsModel,
    RoleRightWebModel,
} from "../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../shared/service-proxies/config";
import { AppSession } from "../../shared/app-session/app-session";
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../components/Block-UI/Block-ui";
import '../actions/ApartmentBuilding.css';

import DHSAutoCompleteWithDataGrid from "../../../components/DHS/DHS_AutoCompleteCustomer/DHSAutoCompleteWithDataGrid";
import BetweenTwoDate from "../report/cash-book-report/component/between-two-date-X";
import { httpPost } from "../../../common/httpService";
import moment from "moment";
import { setApartmentBuilding } from "../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction";
import DHSAutoCompleteWithDataSource from "../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";
import DHSDataGridFilterListByProjectSelect from "../../../components/DHSUtilComponent/DHSDataGridFilterListByProjectSelect/DHSDataGridFilterListByProjectSelect";


interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onApply: (filterString: string) => void;
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

interface IFilter {
    apartment_building_id?: string | null,
    idcustomer?: string | null,
    ngay_ct?: string | null,
}

const defaultFilter: IFilter = {
    apartment_building_id: null,
    idcustomer: null,
    ngay_ct: null,
}


var appSession: AppSession;
const ReceiptSearchAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const dispatch = useDispatch()
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);


    const [data, setData] = React.useState<QLCCInvestorModel>({} as QLCCInvestorModel);
    const [isLoading, setLoading] = React.useState<boolean>(false);



    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    // 
    const [filter, setFilter] = React.useState<IFilter>(defaultFilter);
    const [filterString, setFilterString] = React.useState<string>("");
    const [fromDateFilter, setFromDateFilter] = React.useState<string>("");
    const [toDateFilter, setToDateFilter] = React.useState<string>("");

    // 

    const [apartmentBuildingOptions, setApartmentBuildingOptions] = React.useState<any[]>([]);
    const [residentOptions, setResidentOptions] = React.useState<QLCCResidentsModel[]>([]);

    const [open, setOpen] = React.useState<boolean>(false);
    // 

    React.useEffect(() => {
        setOpen(props.open)
    }, [props.open]);


    // React.useEffect(() => {

    //     setLoading(true);
    //     homeClient
    //         ?.roleRightWebGetAll({
    //             ...new RoleRightWebModel(),
    //             rolename: "CC",
    //             username: SessionLogin.userName,
    //             menuid: menu?.menuid,
    //         } as RoleRightWebModel)
    //         .then((res) => {
    //             setRoles(res);
    //             setLoading(false);
    //         });
    //     setLoading(true);

    // }, []);

    //#region handleGetOptions

    React.useEffect(() => {
        // setLoading(true);
        // qLCClient.qlccApartmentBuildingGet({
        //     ...new QLCCApartmentBuildingModel(),
        //     ma_cty: SessionLogin.ma_cty,
        //     action_type: 'GET'
        // } as QLCCApartmentBuildingModel)
        //     .then(response => {


        //         setApartmentBuildingOptions(response)
        //         if (response) {
        //             // setFilter(pre => ({ ...pre, apartment_building_id: response[0].id ?? null }));
        //             // handleChangeFilterInput({
        //             //     target: {
        //             //         name: "apartment_building_id",
        //             //         value: response[0].id ?? null,
        //             //     },
        //             // });
        //         }
        //         setLoading(false);
        //     });

        // // 

        // const body = {
        //     ma_cty: SessionLogin.ma_cty,
        //     tablelist: "dm_kh"
        // }
        // httpPost<string>('api/Common/LoadFilterList', body)
        //     .then(res => {
        //         const customers: QLCCResidentsModel[] = JSON.parse(res.data);
        //         setResidentOptions(customers)

        //     });
    }, []);

    React.useEffect(() => {
        setFilter(pre => ({ ...pre, apartment_building_id: apartment_building_id } as IFilter))
    }, [apartment_building_id])

    //#endregion

    //#region hanldeWhenFilterChange
    React.useEffect(() => {
        const arrayFilter = Object.entries(filter).map(([key, value]) => ({ [key]: value }));
        var tempFilterArray: string[] = [];
        arrayFilter.forEach(filter => {
            const keys = Object.keys(filter);
            if (filter[keys[0]] !== null) {
                if (keys[0] === 'ngay_ct') {
                    tempFilterArray.push(filter[keys[0]]);

                }
                else tempFilterArray.push(`${keys[0]} LIKE '${filter[keys[0]]}'`);

            }
        })
        setFilterString(tempFilterArray.join(" AND "));

    }, [filter]);

    //#endregion

    //#region handleSubmit


    const handleSubmit = () => {

        console.log(filterString, "filter string")
        props.onApply(filterString.length > 0 ? filterString : "1 = 0")
        props.onClose()

    };

    //#endregion

    //#region handleFilterString
    const handleChangeFilterInput = (e: any) => {
        const { name, value } = e.target;

        setFilter(pre => ({ ...pre, [name]: value } as IFilter));

        // 

        // updateFilterString(name, value);

    };

    const updateFilterString = (name: string, value: any) => {
        var tempFilterString = filterString;
        setFilterString(tempFilterString.trim());
    }

    //#endregion

    //#region handleWhenDateChange

    React.useEffect(() => {
        if (fromDateFilter && toDateFilter) {
            updateFilterDateBetween("ngay_ct")
        }

    }, [fromDateFilter, toDateFilter])


    const updateFilterDateBetween = (name: string) => {

        setFilter(pre => ({ ...pre, [name]: `${name} BETWEEN '${fromDateFilter}' AND '${toDateFilter}'` }))
    }

    //#endregion


    return (
        <Dialog
            id="sysMenu6"
            open={open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "end",
                overflow: "visible !important",

            }}
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    height: "100vh !important",
                    minHeight: "100vh !important"
                }
            }}
            maxWidth='xs'
            fullScreen
            keepMounted
        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {"Tìm phiếu thu nâng cao"
                            // menu?.bar +
                            //     " " +
                            //     `${action.action === "DETAIL"
                            //         ? "[Chi tiết]"
                            //         : action.action === "UPDATE" || _id
                            //             ? "[Cập nhật]"
                            //             : "[Thêm mới]"
                            //     }`
                        }
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent sx={{ overflow: 'visible' }}>

                <div className="row">
                    <div className="col-md-12" >
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Chung cư</InputLabel>
                            {
                                <DHSAutoCompleteWithDataSource
                                    id="apartment-building-select"
                                    dataSource={apartmentBuildingOptions}
                                    displayMember="name"
                                    valueMember="id"
                                    value={filter?.apartment_building_id}
                                    placeholder='Chọn chung cư'
                                    onValueChanged={(e, newValue: any) => {
                                        // handleChangeFilterInput({
                                        //     target: {
                                        //         name: "apartment_building_id",
                                        //         value: newValue?.id ?? null,
                                        //     },
                                        // });
                                        dispatch(setApartmentBuilding(newValue?.id ?? ""))

                                        // 



                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option?.id}>
                                                {option?.name}
                                            </li>
                                        );
                                    }}

                                />
                            }
                        </Box> */}

                        {/*  */}

                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel htmlFor="customer-select" sx={{ fontSize: 14, color: '#333' }}>Khách hàng</InputLabel>

                            <DHSDataGridFilterListByProjectSelect
                                id="customer-select"
                                value={filter?.idcustomer ?? null}
                                table_list="dm_kh"
                                placeholder="Chọn khách hàng"
                                // filter={props.payload}
                                name="idcustomer"
                                dataGridOnSelectionChanged={(e, newValue: any) => {
                                    handleChangeFilterInput({
                                        target: {
                                            name: "idcustomer",
                                            value: newValue?.idcustomer ?? null,
                                        },
                                    });
                                }}
                                syncDataGridSelection={function (e): void {
                                    setFilter(pre => ({ ...pre, idcustomer: e.value } as IFilter));
                                    // updateFilterString("idcustomer", e.value);

                                }}
                                gridBoxDisplayExpr={function (item: any): string {
                                    return item && `${item.ma_kh} - ${item.ten_kh}`;
                                }}

                            // disabled={props.action === ACTIONS.VIEW}
                            />


                        </Box>
                    </div>
                </div>

                <Box style={{ width: "100%", margin: "5px 0px" }}>
                    {/* <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mã code</InputLabel> */}
                    <div className="card">

                        <div className="card-body">
                            <div className="" style={{ fontSize: "14px", fontWeight: 500 }}>
                                Kỳ báo cáo
                            </div>
                            <BetweenTwoDate

                                fromDate={function (data: any): void {
                                    setFromDateFilter(moment(data).format("yyyy/MM/DD"))

                                }}
                                toDate={function (data: any): void {
                                    setToDateFilter(moment(data).format("yyyy/MM/DD"))

                                }}
                                reload={function (): void {
                                    throw new Error("Function not implemented.");
                                }} />
                        </div>
                    </div>

                </Box>
                {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mã code</InputLabel>
                    <TextField
                        className="cc-input"
                        sx={{
                            width: "100%",
                            marginRight: "5px",
                        }}
                        autoFocus
                        name="code"
                        onChange={handleChangeInput}
                        value={data.code}
                        disabled={action.action === "DETAIL"}
                    />

                </Box> */}





            </DialogContent>
            <DialogActions>
                {props.action !== "DETAIL" && (
                    <Button variant="contained" onClick={handleSubmit}>
                        Áp dụng
                    </Button>
                )}
                <Button onClick={props.onClose}>Bỏ lọc</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default ReceiptSearchAction;


