import React, { useRef, useState } from "react";

import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { EventInfo } from "devextreme/events";
import dxDataGrid, { SelectionChangedInfo } from "devextreme/ui/data_grid";
import DataGrid from "devextreme-react/data-grid";

import { IReducer, Notification } from "../../../../components/layouts";
import {
  ReportInfo,
  SYS_Token_AuthenticationModel,
  SysActionType,
} from "../../../shared/service-proxies/api-shared";
import { httpPost } from "../../../../common/httpService";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { exportExcelReport } from "../../../../components/DHS/DHS_ExportExcelReport/exportExcelReport";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import { AppSession } from "../../../shared/app-session/app-session";
import DHSBreadcrumb from "../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import SearchOperation from "../../actions/report-action/searchOperationAction";


interface ICAReport {
  ma_cty: string | undefined,
  ngay_Ct1: Date,
  ngay_ct2: Date,
  tk: string | undefined,
  ma_nt: string | undefined,
  apartment_building_id: string | undefined,
  spname: string | undefined
}

let appSession: AppSession;

const QLCCBanDongPhi = () => {
  const SessionLogin: SYS_Token_AuthenticationModel = JSON.parse(
    localStorage.getItem("SessionLogin") || "{}"
  );
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const location = useLocation();

  const dataGridRef = useRef<DataGrid>(null);
  const [data, setData] = useState<any[]>([]);


  const [filter, setFilter] = useState<ICAReport>({
    ngay_Ct1: new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1, 0, 0, 0)),
    ngay_ct2: new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 0, 23, 0, 0)),
    apartment_building_id: '',
    ma_cty: '',
    ma_nt: '',
    spname: '',
    tk: ''
  })

  const [reportInfo, setReportInfo] = React.useState<ReportInfo>();

  const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

  React.useEffect(() => {
    (async () => {
      try {
        if (reportInfo) {
          setLoading(true);

          if (filter.apartment_building_id !== apartment_building_id) {
            setFilter({
              ...filter,
              apartment_building_id: apartment_building_id
            })
          }

          if (reportInfo.spname) {
            const res = await httpPost<string>("api/caReport/CAReport", {
              ma_cty: SessionLogin.ma_cty,
              apartment_building_id: apartment_building_id,
              tk: "",
              ma_nt: "vnd",
              ngay_Ct1: new Date(filter.ngay_Ct1),
              ngay_ct2: new Date(filter.ngay_ct2),
              spname: reportInfo?.spname ?? '',
            });
            const dataAfterParse = JSON.parse(res.data);
            setData([...dataAfterParse.Table]);
          } else {
            setData([]);
          }
        }
      } catch (error) {
        setData([]);
      } finally {
        setLoading(false);
      }
    })();
  }, [reload, reportInfo]);

  const handleSelectionChanged = (
    e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
  ) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
        console.log(keys);

        const index = e.component.getRowIndexByKey(keys[0]);

        setSelectedRowIndex(index >= 0 ? index : -1);
        setSelectedItemKeys(keys[0]);
      });
    }
  };

  const handlePageChange = () => {
    setSelectedRowIndex(-1);
    setSelectedItemKeys(undefined);
  };

  const handleClickAction = (action: SysActionType, nowTime: Date) => {
    switch (action?.action_code?.toLocaleUpperCase()) {
      case ACTIONS.SEARCH:
        setIsOpen(true);
        break;
      case ACTIONS.EXPORTEXCEL:
        console.log(dataGridRef.current);

        if (dataGridRef.current && reportInfo) {
          if (reportInfo) {
            exportExcelReport(dataGridRef, reportInfo, filter.ngay_Ct1, filter.ngay_ct2);
          } else {
            Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
          }
          //   exportExcelReport(dataGridRef, reportInfo, fromDate, toDate);
        }
        break;
      case ACTIONS.PRINT:
        break;
      default:
        break;
    }
  };

  const handleExporting = (e: any) => {
    if (reportInfo) {
      try {
        exportExcelReport(dataGridRef, reportInfo, filter.ngay_Ct1, filter.ngay_ct2);
      } catch (error) {
        Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
      }
    } else {
      Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          marginTop: 15,
          marginBottom: 15,
          fontSize: 22,
          fontWeight: 550,
        }}
      >
        <DHSBreadcrumb
          location={location}
        />
      </div>
      <DHSToolbarRole
        id={selectedItemKeys ? selectedItemKeys?.id : undefined}
        menu={menu}
        onClickAction={handleClickAction}
      />
      <DHSDataGridEdit
        data={data}
        table_name={menu?.menuid}
        // key="so_ct"
        mode="popup"
        dataGridRef={dataGridRef}
        allowEdit={false}
        pageSize={menu?.page_size}
        isPagination={menu?.is_pagination}
        isMultiple={menu?.is_multiple}
        groupingAutoExpandAll={false}
        handlePageChange={handlePageChange}
        handleSelectionChanged={handleSelectionChanged}
        handleExporting={handleExporting}
      />

      <SearchOperation
        isOpen={isOpen}
        setIsOpen={(status: any) => setIsOpen(status)}
        reportInfo={reportInfo}
        setReportInfo={(data: ReportInfo) => {
          setReportInfo(data as ReportInfo)
        }}
        setReload={() => setReload(!reload)}
        data={filter}
        setData={(data: any) => {
          console.log(data);
          setFilter({
            ...data
          } as ICAReport)
        }}
      />
      <BlockUI blocking={loading} title={""}></BlockUI>
    </Box>
  );
};

export default QLCCBanDongPhi;
