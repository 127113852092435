import { createContext, useContext } from "react";
import { QLCCDomesticWaterModel } from "../../../../../../shared/service-proxies/api-shared";

export interface IUpdateDomesticWaterContext {
    dataSource?: QLCCDomesticWaterModel[],
    setDataSource?: React.Dispatch<React.SetStateAction<QLCCDomesticWaterModel[]>>
}

export const ProviderUpdateDomesticWaterMoreThanOneMeter = createContext<IUpdateDomesticWaterContext | undefined>(undefined);

export function UseContextUpdateDomesticWaterMoreThanOneMeterDataSourceGet() {
    const context = useContext(ProviderUpdateDomesticWaterMoreThanOneMeter);
    if (!context) {
        throw new Error('UseContextUpdateDomesticWaterMoreThanOneMeter must be used within a ProfileProvider');
    }
    return context.dataSource
}

export function UseContextUpdateDomesticWaterMoreThanOneMeterDataSourceSet() {
    const context = useContext(ProviderUpdateDomesticWaterMoreThanOneMeter);
    if (!context) {
        throw new Error('UseContextUpdateDomesticWaterMoreThanOneMeter must be used within a ProfileProvider');
    }
    return context.setDataSource
}