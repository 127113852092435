// import { QLCCFloorsModel } from './../../app/shared/service-proxies/api-shared';
import SchedulerEmployeeTimesheet from "../../app/erp/hr/employee/employee-timesheet/employee-timesheet";
import SchedulerTimesheet from "../../app/erp/hr/employee/timesheet/timesheet";
import FeaturePage from "../../app/website/PortalDoc/Childs/Features";
import ModulePage from "../../app/website/PortalDoc/Childs/Modules";
import ProductPage from "../../app/website/PortalDoc/Childs/Products";
import TagPage from "../../app/website/PortalDoc/Childs/Tags";
import PortalDocGrid from "../../app/website/PortalDoc/PortalDocGrid";
import ContactPersonPosition from "../../app/website/PortalSale/Childs/ContactPersonPosition";
import CustomerInfomation from "../../app/website/PortalSale/Childs/CustomerInfomation";
import CustomerStatus from "../../app/website/PortalSale/Childs/CustomerStatus";
import Major from "../../app/website/PortalSale/Childs/Major";
import PayupPage from "../../app/website/PortalSale/Childs/Payup/Payup";
import SaleSupport from "../../app/website/PortalSale/SaleSupport/Childs";
import PortalSaleGrid from "../../app/website/PortalSale/PortalSaleGrid";
import LeaveForm from "../../app/website/PortalTimeKeeping/Childs/LeaveForm";
import OvertimeForm from "../../app/website/PortalTimeKeeping/Childs/OvertimeForm";
import PortalTimeKeepingGrid from "../../app/website/PortalTimeKeeping/PortalTimeKeepingGrid";
import CheckInOut from "../../components/layouts/CheckInOut/CheckInOut";
import Role from "../../components/layouts/Role/Role";
import Notfound from "../notfound/Notfound";
import SaleSupportGrid from "../../app/website/PortalSale/SaleSupport/SaleSupportGrid";
import ApproveGrid from "../../app/website/PortalTimeKeeping/Childs/ApproveGrid";
import ApproveForm from "../../app/website/PortalTimeKeeping/Childs/ApproveForm";
import Dashboard from "../../components/layouts/Admin/Dashboard/Dashboard";
import PortalSaleGridOnlyView from "../../app/website/PortalSale/PortalSaleGridOnlyView";
import ApproveFormV2 from "../../app/website/PortalTimeKeeping/Childs/ApproveFormV2";
import MachineStatus from "../../components/layouts/Manufacturing/MachineStatus";
import CostCenterReport from "../../app/erp/general-ledger/management-reports/cost-center-report";
import ProfitLoss from "../../app/erp/general-ledger/profit-loss/profit-loss";
import WorkerGrid from "../../app/website/PortalTimeKeeping/Worker/WorkerGrid";
import WorkerOvertimeForm from "../../app/website/PortalTimeKeeping/Worker/Overtime/WorkerOvertimeForm";
import WorkerLeaveForm from "../../app/website/PortalTimeKeeping/Worker/Leave/WorkerLeaveForm";
import RequestApproveGrid from "../../app/erp/request-approve/RequestApproveGrid";
import ThongTinXe from "../../app/cho-thue-xe/nghiep-vu/thong-tin-xe/thong-tin-xe";
import DanhSach from "../../app/cho-thue-xe/cai-dat/danh-sach";
import UserRightComponent from "../UserRight/UserRight";
import UserRightListComponent from "../UserRight/UserRightList";
import DanhSachNghiepVu from "../../app/cho-thue-xe/nghiep-vu/danh-sach/danh-sach";
// import ThongTinThue from "../../app/cho-thue-xe/nghiep-vu/cho-thue/cho-thue";
import BaoCao from "../../app/cho-thue-xe/bao-cao/bao-cao";
import BaoCaoDoanhSo from "../../app/cho-thue-xe/bao-cao/bao-cao-doanh-so";
import CanXe from "./../../app/kiem-soat-can/nghiep-vu/can-xe/CanXe";
import DanhSachKSC from "../../app/kiem-soat-can/nghiep-vu/DanhSachKSC";
import DanhSachTestKSC from "../../app/kiem-soat-can/nghiep-vu/DanhSachTestKSC";
import DanhMucKSC from "../../app/kiem-soat-can/danh-muc/DanhMucKSC";
import BaoCaoKSC from "../../app/kiem-soat-can/bao-cao/BaoCaoKSC";
// quan li chung cu
import QLCCResident from "../../app/quan-ly-chung-cu/resident/resident";
import QLCCCitizenIdentificationCard from "../../app/quan-ly-chung-cu/resident/citizen-identification-card";
import QLCCFeedback from "../../app/quan-ly-chung-cu/resident/feedback-list";
import QLCCResidentJob from "../../app/quan-ly-chung-cu/resident/resident-job";
import QLCCBlock from "../../app/quan-ly-chung-cu/apartment-building/block/block-list";
import QLCCApartmentBuilding from "../../app/quan-ly-chung-cu/apartment-building/apartment-building-list";
import QLCCApartment from "../../app/quan-ly-chung-cu/apartment-building/apartment/apartment-list";
import QLCCCategory from "../../app/quan-ly-chung-cu/notification-news/category/category-list";
import QLCCNews from "../../app/quan-ly-chung-cu/notification-news/news/news-list";
import QLCCNotification from "../../app/quan-ly-chung-cu/notification-news/notification/notification-list";
import QLCCFloor from "../../app/quan-ly-chung-cu/apartment-building/floor-list";
import QLCCInvestor from "../../app/quan-ly-chung-cu/apartment-building/investor";
import QLCCRegulation from "../../app/quan-ly-chung-cu/apartment-building/regulation";
import QLCCApartmentBuildingDomesticWater from "../../app/quan-ly-chung-cu/cost-management/apartment-building-domestic-water";
import QLCCVehicle from "../../app/quan-ly-chung-cu/resident/vehicle";
import QLCCVehicleType from "../../app/quan-ly-chung-cu/resident/vehicle-type";
import QLCCApartmentDomesticWater from "../../app/quan-ly-chung-cu/cost-management/container/domestic-water-floor";
import QLCCDomesticWaterNorm from "../../app/quan-ly-chung-cu/cost-management/domestic-water-norm";
import ResidentAccount from "../../app/quan-ly-chung-cu/resident-account/ResidentAccount";
import EmployeeAccount from "../../components/layouts/Admin/HomePage/Account/EmployeeAccountList";
import Purpose from "../../app/quan-ly-chung-cu/apartment-building/purpose-use-list";
import UserInfoGroup from "../../components/layouts/Admin/HomePage/UserInfoGroup/UserInfoGroup";
import UserInfoType from "../../components/layouts/Admin/HomePage/UserInfo/UserInfo";
import UserInfoApproved from "../../components/layouts/Admin/HomePage/UserInfo/UserInfoApproved";
import UserInfoBlock from "../../components/layouts/Admin/HomePage/UserInfo/UserInfoBlock";
import QLCCApartmentVrII from "../../app/quan-ly-chung-cu/apartment-building/apartment/apartmentVrII";
import QLCCApartmentListVrII from "../../app/quan-ly-chung-cu/apartment-building/apartment/container/apartment-listVrII";
// import Dashboard_right from "../../components/layouts/Dashbroad/Navbar/dashboardRight/Dashboard_right";
import QLCCReceiptList from "../../app/quan-ly-chung-cu/receipt/receipt";
import QLCCPaymentCashM from "../../app/quan-ly-chung-cu/payment-cash/payment-cashM";
import QLCCDashboard from "../../components/layouts/Dashbroad/Navbar/QLCCDashboard";
import OperatingCost from "../../app/quan-ly-chung-cu/service/operating-cost";
import QLCCServiceInvoice from "../../app/quan-ly-chung-cu/service/service-invoice-domestic-water/container/service-invoice-apartment-list";
import QLCCServiceInvoiceDetail from "../../app/quan-ly-chung-cu/service/service-invoice-domestic-water/container/service-invoice-detail-apartment-list";
import ReceiptInvoiceList from "../../app/quan-ly-chung-cu/receipt/receipt-invoice";
// import UtilityList from "../../app/quan-ly-chung-cu/utility/utility-list";
import ServiceInvoiceClient from "../../app/quan-ly-chung-cu/service/service-invoice-client-list"
import FeedBackList from "../../app/quan-ly-chung-cu/feedback/feedback-list";
import TypeFeedBack from "../../app/quan-ly-chung-cu/feedback/type-feedback";
import CashBookReport from "../../app/quan-ly-chung-cu/report/cash-book-report/cash-book-report";
import DomesticWaterCycle from "../../app/quan-ly-chung-cu/cost-management/domestic-water-cycle";
import LockedList from "../../app/quan-ly-chung-cu/locked/locked-list";
import RoleMenuList from "../../app/quan-ly-chung-cu/role/RoleMenu";
import RoleMenuPermission from "../../app/quan-ly-chung-cu/role-info/action/RoleMenuPermission";
import RoleInfo from "../../app/quan-ly-chung-cu/role-info/RoleInfo";
import CashBookRecord from "../../app/quan-ly-chung-cu/report/cash-book-record/cash-book-record";
import CashExpensesBook from "../../app/quan-ly-chung-cu/report/cash-expenses-book/cash-expenses-book";
import HistoryDomesticWater from "../../app/quan-ly-chung-cu/report/history-domestic-water/history-domestic-water";
import CashByExpenditureReport from "../../app/quan-ly-chung-cu/report/cash-by-expenditure-report/cash-by-expenditure-report";
import InvoiceBalanceReport from "../../app/quan-ly-chung-cu/report/invoice-balance-report/invoice-balance-report";
import OperationFeeReport from "../../app/quan-ly-chung-cu/report/operation-fee-report/operation-fee-report";
import OBCashBank from "../../app/quan-ly-chung-cu/ob-cash-bank/opening-balance";
import Receivable from "../../app/quan-ly-chung-cu/ob-cash-bank/Receivable";
import Payable from "../../app/quan-ly-chung-cu/ob-cash-bank/Payable";
import ReceivableCustomer from "../../app/quan-ly-chung-cu/ob-customer/Receivable-customer";
import PayableCustomer from "../../app/quan-ly-chung-cu/ob-customer/Payable-customer";
import UserInfo from "../../app/quan-ly-chung-cu/user-info/UserInfo";
import UserInfoPermission from "../../app/quan-ly-chung-cu/user-info/action/UserInfoPermission";
import CategoryCustomer from "../../app/quan-ly-chung-cu/ob-cash-bank/Category-customer";
import AllVehicleCard from "../../app/quan-ly-chung-cu/category-vehicle-card/list-category-vehicle-card/all-vehicle-card";
import VehicleCancelCard from "../../app/quan-ly-chung-cu/category-vehicle-card/list-category-vehicle-card/vehicle-cancel-card";
import VehicleSellCard from "../../app/quan-ly-chung-cu/category-vehicle-card/list-category-vehicle-card/vehicle-sell-card";
import VehicleBrokenCard from "../../app/quan-ly-chung-cu/category-vehicle-card/list-category-vehicle-card/vehicle-broken-card";
import VehicleVipCard from "../../app/quan-ly-chung-cu/category-vehicle-card/list-category-vehicle-card/vehicle-vip-card";
import VehicleCardList from "../../app/quan-ly-chung-cu/category-vehicle-card/report-category-vehicle-card/category-list-vehicle-card";
import VehicleReturnCard from "../../app/quan-ly-chung-cu/category-vehicle-card/list-category-vehicle-card/vehicle-return-card";
import VehicleRenewCard from "../../app/quan-ly-chung-cu/category-vehicle-card/list-category-vehicle-card/vehicle-renew-card";
import VehicleChangeCard from "../../app/quan-ly-chung-cu/category-vehicle-card/list-category-vehicle-card/vehicle-change-card";
import DataGridSetting from "../../app/quan-ly-chung-cu/data-grid-setting/DataGridSetting";
import SystemDataGridSetting from "../../app/quan-ly-chung-cu/system-data-grid-setting/DataGridSetting";
import VehicleCardReport from "../../app/quan-ly-chung-cu/report/vehicle-card-report/vehicle-card-report";
import VehicleCardInOutReport from "../../app/quan-ly-chung-cu/report/vehicle-card-in-out-report/vehicle-card-in-out-report";
import QLCCBanDongPhi from "../../app/quan-ly-chung-cu/report/qlcc-bandongphi/qlcc-bandongphi";
import NotifyDaily from "../../app/quan-ly-chung-cu/notification-news/notify-daily/notify-daily";
import CategoryEmployee from "../../app/quan-ly-chung-cu/ob-cash-bank/danh-muc-nhan-vien";
import Department from "../../app/quan-ly-chung-cu/ob-cash-bank/department";
import UtilityList from "../../app/quan-ly-chung-cu/utility/utility-list";
import UtilityBooking from "../../app/quan-ly-chung-cu/utility-booking/utility-booking";
import ServiceList from "../../app/quan-ly-chung-cu/service2/service-list";
import ServiceBooking from "../../app/quan-ly-chung-cu/service-booking2/service-booking";
import TaskList from "../../app/quan-ly-chung-cu/work/task/task-list";
import TaskGroup from "../../app/quan-ly-chung-cu/work/task-group";
import TaskType from "../../app/quan-ly-chung-cu/work/task-type";
import TaskScript from "../../app/quan-ly-chung-cu/work/task/task-script";
import UtilityServiceGroup from "../../app/quan-ly-chung-cu/utility-service-group/utility-service-group";
import TaskGANTT from "../../app/quan-ly-chung-cu/work/task-gantt/task-gantt";
import CompanyInfo from "../../app/quan-ly-chung-cu/company-info/CompanyInfo";
import VehicleCardData from "../../app/quan-ly-chung-cu/vehicle-card-data/vehicle-card-data";
import UtilityGroupCategory from "../../app/quan-ly-chung-cu/utility-group-category/service-group-category";
import ItemBalance from "../../app/quan-ly-chung-cu/item-balance/item-balance";
import TaskCheckList from "../../app/quan-ly-chung-cu/work/task/task-check-list";
import ItemGroup from "../../app/quan-ly-chung-cu/item/group-item/group-item";
import ItemKind from "../../app/quan-ly-chung-cu/item/item-kind/item-kind";
import Inventory from "../../app/quan-ly-chung-cu/inventory/inventory";
import Unit from "../../app/quan-ly-chung-cu/unit/unit";
import Location from "../../app/quan-ly-chung-cu/location/location";
import Site from "../../app/quan-ly-chung-cu/site/site";
import InVch01 from "../../app/quan-ly-chung-cu/receiving-voucher/receiving-voucher";
import ImportExportSummaryReport from "../../app/quan-ly-chung-cu/report/import-export-summary-report/import-export-summary-report";
import EndingInventoryReport from "../../app/quan-ly-chung-cu/report/ending-inventory-report/ending-inventory-report";
import VehicleCardReportMonth from "../../app/quan-ly-chung-cu/report/vehicle-card-report-month/vehicle-card-report-month";
import TaskTimeLine from "../../app/quan-ly-chung-cu/work/task-time-line";
import ElevatorReport from "../../app/quan-ly-chung-cu/report/elevator-report";
import TaskCalendarLayout from "../../app/quan-ly-chung-cu/work/task-gantt/index-2";
import WorkReport from "../../app/quan-ly-chung-cu/work/report/task-report";
import WorkReportFeedBack from "../../app/quan-ly-chung-cu/work/report/report-task-feed-back";

export  const components: any = {
    DASHBOARD: Dashboard,
    //Portal Sale
    CRMROLE: Role,
    CRMCUSTOMERINFOMATION: PortalSaleGrid,
    CRMCUSTOMERSEARCH: PortalSaleGridOnlyView,
    CRMCUSTOMERSTATUS: PortalSaleGrid,
    CRMCONTACTPERSONPOSITION: PortalSaleGrid,
    CRMMAJOR: PortalSaleGrid,
    CUSTOMERINFOMATION: CustomerInfomation,
    CUSTOMERSTATUS: CustomerStatus,
    CONTACTPERSONPOSITION: ContactPersonPosition,
    PAYUP: PayupPage,
    //Customer Care
    CSKHROLE: Role,
    CSKHSALESUPPORT: SaleSupportGrid,
    SALESUPPORT: SaleSupport,
    //Portal Doc
    DOCUSERGUIDEPRODUCTION: PortalDocGrid,
    DOCUSERGUIDEPRODUCTIONMODULE: PortalDocGrid,
    DOCUSERGUIDEPRODUCTIONFEATURE: PortalDocGrid,
    DOCUSERGUIDEPRODUCTIONTAG: PortalDocGrid,
    MAJOR: Major,
    PRODUCT: ProductPage,
    MODULE: ModulePage,
    FEATURE: FeaturePage,
    TAG: TagPage,
    // Portal TimeKeeping
    TKROLE: Role,
    TKLEAVEFORM: PortalTimeKeepingGrid,
    TKOVERTIMEFORM: PortalTimeKeepingGrid,
    APPROVEGRID: ApproveGrid,
    HRAPPROVEGRID: ApproveGrid,
    APPROVEGRIDV2: ApproveGrid,
    HRAPPROVEGRIDV2: ApproveGrid,
    APPROVEFORM: ApproveForm,
    HRAPPROVEFORM: ApproveForm,
    APPROVEFORMV2: ApproveFormV2,
    HRAPPROVEFORMV2: ApproveFormV2,
    LEAVEFORM: LeaveForm,
    OVERTIMEFORM: OvertimeForm,
    SCHEDULERTIMESHEET: SchedulerTimesheet,
    SCHEDULEREMPLOYEETIMESHEET: SchedulerEmployeeTimesheet,
    CHECKIN: CheckInOut,
    TIMESHEET: SchedulerEmployeeTimesheet,
    WORKERLEAVEGRID: WorkerGrid,
    WORKEROVERTIMEGRID: WorkerGrid,
    WORKERLEAVEFORM: WorkerLeaveForm,
    WORKEROVERTIMEFORM: WorkerOvertimeForm,
    //Notfound
    NOTFOUND: Notfound,
    MACHINESTATUS: MachineStatus,
    //ERP
    MANUFACTURING: Role,
    GENERAL_LEDGER: Role,
    COSTCENTERREPORT: CostCenterReport,
    PROFITLOSS: ProfitLoss,
    ERPSUPPLYREQUEST: RequestApproveGrid,
    ERPSURFACETREATMENTREQUEST: RequestApproveGrid,
    //ChoThueXe
    CTXDANHMUC: Role,

    CTXMAUSON: DanhSach,
    CTXNOISANXUATXE: DanhSach,
    CTXLOAICHOTHUE: DanhSach,
    CTXLOAIXE: DanhSach,
    CTXKIEUXE: DanhSach,
    CTXHANGXE: DanhSach,
    CTXTRANGTHAI: DanhSach,
    CTXTRANGTHAIXE: DanhSach,
    CTXLOAIBAODUONG: DanhSach,

    CTXDANHSACHTHUE: DanhSachNghiepVu,
    CTXDANHSACHXE: DanhSachNghiepVu,
    CTXDANHSACHGIAODICH: DanhSachNghiepVu,
    // CTXTHONGTINTHUE: ThongTinThue,
    CTXTHONGTINXE: ThongTinXe,
    SETTING: Role,
    USERRIGHTCOMPONENT: UserRightComponent,
    // USERINFO: UserRightListComponent,
    CTXBAOCAO: Role,
    CTXBAOCAODOANHSO: BaoCao,
    CTXBAOCAOSOLANTHUE: BaoCaoDoanhSo,
    //KiemSoatCan
    KSCROLE: Role,
    KSCDANHSACHPHIEU: DanhSachKSC,
    KSCDANHSACHPHIEUTEST: DanhSachTestKSC,
    KSCDANHSACHVANDON: DanhSachKSC,
    KSCDANHSACHKHACHHANG: DanhSachKSC,
    KSCDANHSACHTAU: DanhSachKSC,
    KSCCANXE: CanXe,
    KSCLOAIHANG: DanhMucKSC,
    KSCKIEUCAN: DanhMucKSC,
    KSCNHOMTAU: DanhMucKSC,
    KSCHANGTAU: DanhMucKSC,
    KSCDANHSACHKHO: DanhMucKSC,
    KSCDANHSACHCA: DanhMucKSC,
    KSCBAOCAOXUATFILE: BaoCaoKSC,
    // Quan li chung cu
    QLCCSYSTEM: Role,
    QLCCAPARTMENTBUILDINGMANAGERMENT: Role,
    QLCCCOST: Role,
    QLCCCATEGORY: Role,
    QLCCCSERVICE: Role,
    QLCCFEEDBACKMANAGERMENT: Role,
    QLCCNOTIFICATIONMANAGERMENT: Role,
    QLCCBANKMANAGERMENT: Role,
    QLCCOBCASHBANKROLE: Role, // Khánh 22 05 2023 15h52
    QLCCVEHICLECARD: Role, // Khánh 26 05 2023
    QLCCFEEDBACKMANAGEMENT: Role, // Khánh 27 06 2023
    QLCCTASKMANAGERMENT: Role, // Khánh 27 06 2023


    QLCCBLOCK: QLCCBlock,
    QLCCAPARTMENTBUILDING: QLCCApartmentBuilding,
    QLCCRESIDENT: QLCCResident,
    QLCCCITIZENIDENTIFICATIONCARD: QLCCCitizenIdentificationCard,
    QLCCFEEDBACK: QLCCFeedback,
    QLCCRESIDENTJOB: QLCCResidentJob,

    QLCCAPARTMENT: QLCCApartment,
    QLCCOPERATINGCOST: OperatingCost,//lỗi
    QLCCSERVICEINVOICE: QLCCServiceInvoice,
    QLCCSERVICEINVOICEDETAIL: QLCCServiceInvoiceDetail,

    QLCCCATEGORIE: QLCCCategory,
    QLCCNEWS: QLCCNews,
    QLCCNOTIFICATION: QLCCNotification,
    QLCCFLOOR: QLCCFloor,
    
    QLCCINVESTOR: QLCCInvestor,
    QLCCREGULATION: QLCCRegulation,

    // Cập nhật chỉ số nước - Rút ngắn bước chọn giao diện dự án
    // QLCCAPARTMENTBUILDINGDOMESTICWATER: QLCCApartmentBuildingDomesticWater,
    QLCCAPARTMENTBUILDINGDOMESTICWATER: QLCCApartmentDomesticWater,
    // QLCCAPARTMENTDOMESTICWATER: QLCCApartmentDomesticWater,

    QLCCDOMESTICWATERNORM: QLCCDomesticWaterNorm,
    QLCCVEHICLE: QLCCVehicle,
    QLCCVEHICLETYPE: QLCCVehicleType,
    RESIDENTACCOUNT: ResidentAccount,
    EMPLOYEEACCOUNT: EmployeeAccount,
    //Dăng 22-03-2023
    QLCCPURPOSEOFUSE: Purpose,
    USERINFOGROUP: UserInfoGroup,
    USERINFOTYPE: UserInfoType,
    USERINFOAPPROVED: UserInfoApproved,
    USERINFOBLOCK: UserInfoBlock,
    QLCCAPARTMENTV2: QLCCApartmentListVrII,
    // add DASHBOARDQLCC
    // DASHBOARDQLCC: Dashboard_right,

    DATAGRIDSETTING: DataGridSetting,
    SYSTEMDATAGRIDSETTING: SystemDataGridSetting,
    "69.10.01_CAVCH01M": QLCCReceiptList,
    "69.20.01_CAVCH02M": QLCCPaymentCashM,
    QLCCDASHBOARD: QLCCDashboard,//lỗi
    QLCCRECIEPTINVOICE: ReceiptInvoiceList,
    QLCCUTILITY: UtilityList,

    QLCCSERVICEINVOICECLIENT: ServiceInvoiceClient,
    QLCCFEEDBACKS: FeedBackList,
    QLCCTYPEFEEDBACK: TypeFeedBack,

    QLCCCASHREPORT: CashBookReport,
    QLCCDOMESTICWATERCYCLE: DomesticWaterCycle,
    QLCCLOCKEDINVOICE: LockedList,
    
    ROLEMENU: RoleMenuList,
    ROLEMENUPERMISSION: RoleMenuPermission,
    ROLEINFO: RoleInfo,

    QLCCCASHRECORD: CashBookRecord,
    QLCCCASHEXPENSESBOOK: CashExpensesBook,
    QLCCHISTORYDOMESTICWATER: HistoryDomesticWater,
    QLCCCASHEXPENDITUREREPORT: CashByExpenditureReport,
    QLCCINVOICEBALANCEREPORT: InvoiceBalanceReport,
    QLCCOPERATIONFEEREPORT: OperationFeeReport,
    QLCCOBCASHBANK: OBCashBank,
    QLCCOBCASHBANK2: Receivable,
    QLCCPAYABLE: Payable, // 24 05 2023 khanh
    QLCCRECEIVABLECUSTOMER: ReceivableCustomer, // 25 05 2023 khanh
    QLCCPAYABLECUSTOMER: PayableCustomer,
    QLCCCATEGORYCUSTOMER: CategoryCustomer,
    // 25-05-2023
    USERINFO: UserInfo,
    USERINFOPERMISSION: UserInfoPermission,
    QLCCVEHICLECARDALL: AllVehicleCard,
    // 30 05 2023
    QLCCVEHICLECANCEL: VehicleCancelCard,
    QLCCVEHICLESELL: VehicleSellCard,
    QLCCVEHICLEBROKEN: VehicleBrokenCard,
    QLCCVEHICLEVIP: VehicleVipCard,
    QLCCVEHICLECARDLIST: VehicleCardList,
    QLCCVEHICLERETURN: VehicleReturnCard,
    QLCCVEHICLERENEW: VehicleRenewCard,
    QLCCVEHICLECHANGE: VehicleChangeCard,
    //01 06 2023
    QLCCVEHICLECARDREPORT: VehicleCardReport,
    QLCCVEHICLECARDINOUTREPORT: VehicleCardInOutReport,
    //4/6/2023
    QLCCBANDONGPHI: QLCCBanDongPhi,
    // 15 06 2023
    //  NOTIFYDAILY: NotifyDaily // quản lý thông báo hàng ngày
    QLCCCATEGORYEMPLOYEE: CategoryEmployee,
    QLCCDEPARTMENT: Department,
    // 17/06/2023
    QLCCUTILITYBOOKING: UtilityBooking,
    //24/06/2023
    QLCCSERVICE2: ServiceList,
    QLCCSERVICEBOOKING: ServiceBooking,
    // 22 06 2023
    QLCCTASKLIST: TaskList,
    //29 06 2023
    QLCCTASKGROUP: TaskGroup,
    QLCCTASKTYPE: TaskType,
    QLCCTASKSCRIPT: TaskScript,
    //01/07/2023
    QLCCUTILITYSERVICEGROUP: UtilityServiceGroup,
    QLCCTASKGANTT: WorkReport,
    //03/07/2022
    COMPANYINFO: CompanyInfo,
    //06/06/2023
    VEHICLECARDDATA: VehicleCardData,
    //07/07/2023
    QLCCUTILITYSERVICEGROUPCATEGORY: UtilityGroupCategory,
    ITEMBALANCE: ItemBalance,
    QLCCTASKCHECKLIST: TaskCheckList,
    // 12/07/2023
    ITEMGROUP: ItemGroup,
    ITEMKIND: ItemKind,
    INVENTORY: Inventory,
    UNIT: Unit,
    LOCATION: Location,
    SITE: Site,
    // 18/07/2023
    INVCH01: InVch01,
    //24/07/2023
    QLCCIMPORTEXPORTSUMMARYREPORT: ImportExportSummaryReport,
    QLCCENDINGINVENTORYREPORT: EndingInventoryReport,
    //02/08/2023
    QLCCVEHICLECARDREPORTMONTH: VehicleCardReportMonth,
    // 11 08 2023
    QLCCTASKTIMELINECONTROL: TaskTimeLine,

    //16-12-2023
    ElevatorReport: ElevatorReport,
    //25-12-2023
    WORKREPORTFEEDBACK: WorkReportFeedBack
};
