import React from "react";
import "./style.css";

import {
  IRoleRightWebModel,
  RoleRightWebModel,
  SysMenu,
} from "../../../app/shared/service-proxies/api-shared";
import { Link, useParams } from "react-router-dom";

interface IListMenu {
  menuName: string;
  onClick?: () => void;
}
interface IProps {
  list: IRoleRightWebModel[];
  menuid?: string;
  id?: string;
  onClickAction: (action: RoleRightWebModel, nowTime: Date) => void;
  customMenu?: IListMenu[];

  current_form?: any;
}
const DHSToolbar: React.FC<IProps> = (props: IProps) => {
  const handleClick = (lst: RoleRightWebModel) => {
    props.onClickAction(lst, new Date());
  };

  return (
    <div>
      <div className="topnav d-flex">
        {props.list
          .filter((item) => item.link && item.link.length > 0)
          .map((itemFiltered, index) => (
            <div
              key={itemFiltered.menuid ? itemFiltered.menuid + index : index}
            >
              {
                <Link
                  to={
                    itemFiltered.link +
                    (itemFiltered.is_parameter ? `${props.id}` : ``)
                  }
                  key={itemFiltered.menuid ? itemFiltered.menuid : ""}
                  id={itemFiltered.code}
                  className={
                    itemFiltered.class_name +
                    // " mr-1 px-3 py-2 " +
                    " " +
                    (itemFiltered.disabled === true ? "disabled" : "")
                  }
                  style={{
                    pointerEvents: (
                      itemFiltered.enable_default === false &&
                        itemFiltered.disabled === false
                        ? props.id
                          ? true
                          : false
                        : true
                    )
                      ? "auto"
                      : "none",
                    color: (
                      itemFiltered.enable_default === false &&
                        itemFiltered.disabled === false
                        ? props.id
                          ? true
                          : false
                        : true
                    )
                      ? ""
                      : "#626262",
                    // : "#fff",
                    textDecoration: "none",
                    borderRadius: "4px",

                    // backgroundColor: 

                    //     (
                    //       itemFiltered.class_name?.toLocaleLowerCase() === "ADD".toLocaleLowerCase() ? "#337ab7" :
                    //         itemFiltered.class_name?.toLocaleLowerCase() === "EDIT".toLocaleLowerCase() ? "#5bc0de" :
                    //           itemFiltered.class_name?.toLocaleLowerCase() === "DETAIL".toLocaleLowerCase() ? "#94a3b8" :
                    //             itemFiltered.class_name?.toLocaleLowerCase() === "DELETE".toLocaleLowerCase() ? "#d9534f" : ""
                    //     )

                  }}
                  onClick={() => handleClick(itemFiltered as RoleRightWebModel)}
                >
                  <i className={itemFiltered.icon}></i><span style={{ fontFamily: 'Helvetica' }}> {itemFiltered.name_vn}</span>
                </Link>
              }
            </div>
          ))}
        <div
          className="setting-icon"
          style={{ position: "absolute", right: 15, color: "#FFF" }}
        >
          {props.customMenu && props.customMenu.length > 0 && (
            <a
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              className="bi bi-three-dots-vertical"
              style={{ position: "relative" }}
            ></a>
          )}

          <div className="dropdown-menu dropdown-menu-right">
            {props.customMenu &&
              props.customMenu.map((item) => {
                return (
                  <button
                    className="dropdown-item"
                    type="button"
                    key={item.menuName}
                    onClick={item.onClick}
                  >
                    {item.menuName}
                  </button>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

DHSToolbar.propTypes = {};

// const mapStateToProps = (state:IReducer) => ({
//     list: state.ToolbarReducer?.list
// })

// export default connect(mapStateToProps, {} ) (DHSToolbar)
export default DHSToolbar;
