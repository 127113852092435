import React, { useEffect } from "react";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField } from "@mui/material";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { DateTimePicker } from "@mui/lab";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// import 'jquery.ui.datetimepicker'j
interface IProps {
  label: string;
  disabled: boolean;
  value?: any;
  className: string;
  type?: string;
  name: string;
  onChange?: (v: any) => void;
}
const DHSDateTime = (props: IProps) => {
  const format = "DD/MM/YYYY HH:mm";

  const handleChangeDate = (e: any) => {
    // e = new Date().toLocaleString();
    props.onChange && props.onChange(e);
  };

  return (
    <div className="form-group">
      <label className="label-input">{props.label}</label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          renderInput={(params: any) => <TextField margin="normal" {...params} />}
          value={props.value}
          onChange={handleChangeDate}
          inputFormat={format}
          disabled={props.disabled}
        />
      </LocalizationProvider>
      {/* <br />
      <div className="input-group date" id="datepicker1">
        <input
          type="text"
          data-date-format={format}
          className="form-control"
          id={columnName}
          readOnly={disabled}
          defaultValue={myDate}
          style={{
            borderRadius: "5px",
            color: "black",
            textAlign: "left",
          }}
          disabled={props.disabled}
        ></input>

        <span className="input-group-addon" style={{ display: "none" }}>
          <span className="glyphicon glyphicon-calendar"></span>
        </span>
      </div> */}
    </div>
  );
};
export default DHSDateTime;
