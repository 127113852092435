import { Template, TemplatePlaceholder } from "@devexpress/dx-react-core";
import Select from "antd/lib/select";
import {
    Plugin
} from '@devexpress/dx-react-core';
export const ToolbarFilterComponent: React.FC<{ param: string }> = (param) => {
    return (
        <Plugin>
            <Template name="toolbarContent">
                <TemplatePlaceholder />
                <Select value="" />
            </Template>
        </Plugin>
    );
};