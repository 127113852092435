import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { AppSession } from "../../../../shared/app-session/app-session";
import {
  HRClient,
  HREmployee,
  OvertimeForm,
  LstHRDepartments,
  LstHRPositions,
  RoleRightWebModel,
} from "../../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../shared/service-proxies/config";
import Select from "../../../../../components/DHS/DHS_Select";
import notification from "../../../../../common/notification/notification";
import DHSDate from "../../../../../components/DHS/DHS-Date/DHSDateComponent";
import DHSDateTime from "../../../../../components/DHS/DHS-DateTime/DHSDateTimeComponent";
import { now } from "lodash";

interface IProps {
  id?: string;
  action: RoleRightWebModel | undefined;
  nowTime: Date;
  onDestroyAction?: (isDestroy: boolean, goBack?: number) => void;
}
var appSession: AppSession;

const ApproveFormActionV2 = (props: IProps) => {
  const hrClient = new HRClient(appSession, BASE_URL_API);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [goBack, setGoBack] = React.useState<number>(-1);
  const [data, setData] = React.useState<OvertimeForm>({
    ...new OvertimeForm(),
    sender_name: SessionLogin.userName,
  } as OvertimeForm);
  const [{ _id }, setId] = React.useState(useParams<{ _id: string }>());
  const history = useHistory();
  const [isViewDetail, activeViewDetail] = React.useState<boolean>(false);
  const [receiverOptions, setReceiverOptions] = React.useState<any[]>([]);
  const [positionOptions, setPositionOptions] = React.useState<any[]>([]);
  const [unitOptions, setUnitOptions] = React.useState<any[]>([]);
  const [typeOptions, setTypeOptions] = React.useState<any[]>([]);
  // const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

  React.useEffect(() => {
    if (
      props.action &&
      props.action.class_name?.toLocaleUpperCase() === "VIEWDETAIL"
    ) {
      activeViewDetail(true);
    } else if (props.action && !props.action.class_name) {
      history.push("/admin");
    }
    if (_id) {
      hrClient
        .hROvertimeGet({
          ...new OvertimeForm(),
          id: _id,
          ma_cty: SessionLogin.ma_cty,
          receiver_code: SessionLogin.employeeCode,
        } as OvertimeForm)
        .then((response) => {
          setData(response[0]);
        });
    }
  }, []);
  //Set Options
  React.useEffect(() => {
    hrClient
      .lstHRPositionsGet({
        ...new LstHRPositions(),
        ma_cty: SessionLogin.ma_cty,
      } as LstHRPositions)
      .then((response) => {
        setPositionOptions(response);
      });
    hrClient
      .lstHRDepartmentsGet({
        ...new LstHRDepartments(),
        ma_cty: SessionLogin.ma_cty,
      } as LstHRDepartments)
      .then((response) => setUnitOptions(response));
    hrClient
      .list_employee_v2({
        ...new HREmployee(),
        ma_cty: SessionLogin.ma_cty,
      } as HREmployee)
      .then((response) => {
        setReceiverOptions(response);
      });
  }, []);

  React.useEffect(() => {
    if (_id && props.action) {
      switch (props.action.class_name?.toLocaleUpperCase()) {
        case "APPROVE":
          hrClient
            .hROvertimeUpd({
              ...data,
              id: _id,
              approve_status_code: 1,
              sender_code: SessionLogin.employeeCode,
              fromtime: new Date(
                (new Date(data.fromtime ? data.fromtime : now()) as any) -
                  new Date().getTimezoneOffset() * 1000 * 60
              ),
              totime: new Date(
                (new Date(data.totime ? data.totime : now()) as any) -
                  new Date().getTimezoneOffset() * 1000 * 60
              ),
            } as OvertimeForm)
            .then((res) => {
              setGoBack((item) => (item -= 1));
              if (res[0].ret === 0) {
                notification("success", res[0].message);
              } else if (res[0].ret === 1) {
                notification("error", res[0].message);
              } else {
                notification("warning", res[0].message);
              }
            })
            .catch((err) => console.log(err));
          break;
        case "HRAPPROVE":
          hrClient
            .hROvertimeUpd({
              ...data,
              id: _id,
              approve_status_code: 2,
              sender_code: SessionLogin.employeeCode,
              fromtime: new Date(
                (new Date(data.fromtime ? data.fromtime : now()) as any) -
                  new Date().getTimezoneOffset() * 1000 * 60
              ),
              totime: new Date(
                (new Date(data.totime ? data.totime : now()) as any) -
                  new Date().getTimezoneOffset() * 1000 * 60
              ),
            } as OvertimeForm)
            .then((res) => {
              setGoBack((item) => (item -= 1));
              if (res[0].ret === 0) {
                notification("success", res[0].message);
              } else if (res[0].ret === 1) {
                notification("error", res[0].message);
              } else {
                notification("warning", res[0].message);
              }
            })
            .catch((err) => console.log(err));
          break;
        case "REJECT":
          hrClient
            .hROvertimeUpd({
              ...data,
              id: _id,
              approve_status_code: 3,
              sender_code: SessionLogin.employeeCode,
              fromtime: new Date(
                (new Date(data.fromtime ? data.fromtime : now()) as any) -
                  new Date().getTimezoneOffset() * 1000 * 60
              ),
              totime: new Date(
                (new Date(data.totime ? data.totime : now()) as any) -
                  new Date().getTimezoneOffset() * 1000 * 60
              ),
            } as OvertimeForm)
            .then((res) => {
              setGoBack((item) => (item -= 1));
              if (res[0].ret === 0) {
                notification("success", res[0].message);
              } else if (res[0].ret === 1) {
                notification("error", res[0].message);
              } else {
                notification("warning", res[0].message);
              }
            })
            .catch((err) => console.log(err));
          break;
      }
    }

    if (props.onDestroyAction) props.onDestroyAction(true, goBack - 1);
  }, [props.nowTime]);

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value } as OvertimeForm);
  };

  return (
    <>
      <div className="d-flex">
        <div className="w-100 p-4">
          <div style={{ backgroundColor: "white" }} className="p-3 d-flex">
            <div className="w-100 h-100">
              <div className="row ">
                <div className="form-group col-xl-12">
                  <label>Người gửi</label>
                  <input
                    className="flex-grow-1 form-control"
                    placeholder="Người gửi"
                    value={data.sender_name}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col">
                  <DHSDateTime
                    label="Từ"
                    className="flex-grow-1 form-control"
                    name="fromtime"
                    value={data.fromtime?.toString()}
                    disabled={true}
                  />
                </div>
                <div className="form-group col">
                  <DHSDateTime
                    label="Đến"
                    className="flex-grow-1 form-control"
                    name="totime"
                    value={data.totime?.toString()}
                    disabled={true}
                  />
                </div>
                <div className="form-group col">
                  <label>Tổng giờ</label>
                  <input
                    className="flex-grow-1 form-control"
                    type="number"
                    name="sumarytime_overtime"
                    value={data.sumarytime_overtime}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Ghi chú</label>
                <textarea
                  className="flex-grow-1 form-control"
                  name="note"
                  onChange={handleChangeInput}
                  value={data.note}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApproveFormActionV2;
