import * as moment from 'moment';

export class User {
    id?: number;
    userName?: string | undefined;
    email?: string | undefined;
    fullName?: string | undefined;
    employeeCode?: string | undefined;
    ma_cty?: string | undefined;
    ten_cty?: string | undefined;
    password?: string | undefined;
    confirm_password?: string | undefined;
    token?: string | undefined;
    avatar?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    roleID?: number | undefined;
    nam_tc?: number | undefined;
    roleName?: string | undefined;
    language?: string | undefined;
    code?: string | undefined;
    Token?: string | undefined;
    Token_expiry_date?: Date | undefined;
}