import React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Box,
    Paper,
    InputLabel,
    InputBase,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    HomeClient,
    QLCCClient,
    QLCCTaskModel,
    QLCCTaskDetailModel,
    ReportInfo,
    SysActionType,
    LstDepartment,
    SysModel,
    UserInfo,
    ParaObjTableList,
    QLCCTaskStatusModel,
    QLCCTaskGroupModel,
    QLCCTaskTypeModel,
    HomePageClient,
    UserInfoModel,
    QLCCTaskGroupCycleModel,
    UserInfoFilterModel,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import { Notification } from "../../../../components/layouts";
import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { EventInfo } from "devextreme/events";
import DataGrid from 'devextreme-react/data-grid';
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { MESSAGE_TYPE, STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import { getIndexRowSelected, getKeyRowSelected } from "../../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import notification from "../../../../common/notification/notification";
import DHSSelectBox from "../../../../components/DHS/DHS_SelectBox";
import TaskChild, { NEWID } from "./task-child/task-child";
import { TransDateTime } from "../../../../components/utils/date.util";
import moment from "moment";
import DHSDateTimeComponentV2 from "../../../../components/DHS/DHS-DateTime/DHSDateTimeComponentV2";

import './index.css'
import Slider, { Tooltip } from "devextreme-react/slider";
import { httpPost } from "../../../../common/httpService";
// import DHSTagBox from "../../../../components/DHS/DHS_TagBox";

import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
// import TaskCheckListNgayCT from "./task-check-list-ngay-ct/task-check-list-ngay-ct";
// import { UploadFile } from "../notification-action/UploadFile";
import TaskCheckListNgayCT2 from "./task-check-list-ngay-ct/task-check-list-ngay-ct-2";
import TaskForum from "./conponent-task/task-forum";
import { TaskFileAttach } from "./conponent-task/task-file-attach";
import DHSToolbarRoleActionType2 from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRoleChild";
import TaskFollower from "./conponent-task/task-follower";
// import { Tooltip as TooltipMUI } from '@mui/material';
import TaskChildForum from "./conponent-task/task-child-forum";
// import CheckBox from "devextreme-react/check-box";
import { HintCode } from "../../../../common/enums/DHSInputDetail";
import TaskFollowerInput from "./conponent-task/task-follower/index2";


interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    data?: any;
    // datas?: any;
    onClose: () => void;
    onEditSuccess: (is_success: boolean) => void;
    titleFeedBack?: string | undefined;
    contentFeedBack?: string | undefined;
    feedback_id?: string | undefined;
    apartmentBuildingId: string | undefined;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface IActions {
    open: boolean;
    id: string;
    actions: string;
    data: QLCCTaskDetailModel,
    start_time: Date;
}

const defaultAction = {
    open: false,
    id: "",
    actions: "",
    data: {} as QLCCTaskDetailModel,
    start_time: new Date(),
};

// hàm get date min or max in lst task detail
const GET_DATE = (data: QLCCTaskDetailModel[], EQUAL: number) => {
    /**
     * EQUAL = 0 EQUAL 
     * EQUAL < LESS THAN
     * EQUAL > 0 MORE THAN
     */

    if (data.length === 1) {
        const item = data[0];
        if (EQUAL > 0) {
            return new Date(item.end_time + '');
        } else if (EQUAL < 0) {
            return new Date(item.start_time + '');
        }
    }
    else if (data.length > 1) {
        if (EQUAL > 0) {
            const d: any = data.flatMap(item => item.end_time)
            const maxDate = d.reduce((a: Date, b: Date) => a > b ? a : b)
            return new Date(maxDate.toString());
        } else if (EQUAL < 0) {
            const d: any = data.flatMap(item => item.start_time)
            const minDate = d.reduce((a: Date, b: Date) => a < b ? a : b)
            return new Date(minDate.toString());
        }
    }
    return new Date();
}

var appSession: AppSession;
var htmlString: string;
const TYPE_CODE_OF_REPORT: string = "3";
const TYPE_CODE_TASK_SCRIPT: string = "2";
const TASK_STATUS_DONE = 3;
const TaskCheckListAction = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    const qLCCHome = new HomeClient(appSession, BASE_URL_API);
    const qlccHomePage = new HomePageClient(appSession, BASE_URL_API);

    const [data, setData] = React.useState<QLCCTaskModel>({} as QLCCTaskModel);
    const [dataDetails, setDataDetails] = React.useState<QLCCTaskDetailModel[]>([] as QLCCTaskDetailModel[]);
    const [dataDetail, setDataDetail] = React.useState<QLCCTaskDetailModel[]>([] as QLCCTaskDetailModel[]);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [departments, setDepartments] = React.useState<LstDepartment[]>([]);
    const [users, setUsers] = React.useState<UserInfo[]>([]);
    const [actionPopup, setActionPopup] = React.useState<IActions>({ ...defaultAction });
    const [isFull, setIsFull] = React.useState<boolean>(true);
    const [statusList, setStatusList] = React.useState<QLCCTaskStatusModel[]>([]);
    const [Groups, setGroups] = React.useState<QLCCTaskGroupModel[]>([]);
    const [Types, setTypes] = React.useState<QLCCTaskTypeModel[]>([]);
    const [taskScripts, setTaskScripts] = React.useState<QLCCTaskModel[]>([]);

    const [reload, setReload] = React.useState<boolean>(false);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const disable = props.action === ACTIONS.VIEW ? true : false;

    const enabled = (actions: string[]) => {
        const action = props.action;
        return !actions.includes(action);
    }

    const [bindata, setdBindata] = React.useState<boolean>(false);

    const [Cycle, setCycle] = React.useState<QLCCTaskGroupCycleModel[]>([])


    const [loadDataContact, setLoadDataContact] = React.useState<boolean>(false); // load tin nhắn
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)



    const onloadUser = () => {
        qlccHomePage.userInfoWebFilterAdmin({
            ... new UserInfo(),
            department_id: data.department_id,
            apartment_building_id: apartment_building_id,
            ma_cty: SessionLogin.ma_cty,
        } as UserInfo)
            .then(res => {
                setUsers(res);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const [path, setPath] = React.useState<string[]>([]);
    const [follower, setFollower] = React.useState<UserInfoModel[]>([]);


    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const body = {
                    ... new ParaObjTableList(),
                    ma_cty: SessionLogin.ma_cty,
                    tablelist: 'dm_bp'
                } as ParaObjTableList

                httpPost<string>('/api/Common/LoadDatasourceBindControlByApartmentBuilding', {
                    ma_cty: SessionLogin?.ma_cty,
                    tablelist: body?.tablelist,
                    apartment_building_id: apartment_building_id
                })
                    .then(res => {
                        console.log(JSON.parse(res.data))
                        const serviceGroups: any = JSON.parse(res.data)
                        if (serviceGroups) {
                            setDepartments([...serviceGroups?.Table1]);
                        }
                    })
                    .catch((ex) => {
                        console.log(ex)
                    })


                // httpPost<string>('api/Common/LoadFilterList', body)
                //     .then(result => {
                //         const deapartmentData: LstDepartment[] = JSON.parse(result.data);
                //         setDepartments([...deapartmentData]);
                //     });

                const ResType = await qLCClient.qLCCTaskTypeGet({
                    ...new QLCCTaskTypeModel(),
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty,
                } as QLCCTaskTypeModel);

                const ResGroup = await qLCClient.qLCCTaskGroupGet({
                    ...new QLCCTaskGroupModel(),
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty,
                } as QLCCTaskGroupModel);

                const ResStatus = await qLCClient.qLCCTaskStatusGet({
                    ...new QLCCTaskStatusModel(),
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty,
                } as QLCCTaskStatusModel);

                const ResScript = await qLCClient.qLCCTaskGet({
                    ...new QLCCTaskModel(),
                    type_code: TYPE_CODE_TASK_SCRIPT,
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty,
                    username_login: SessionLogin.userName,
                    action_type: '246',
                } as QLCCTaskModel);

                const ResCycle = await qLCClient.qLCCTaskGroupCycleGet({
                    ... new QLCCTaskGroupCycleModel(),
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty,
                    action_type: "GET"
                } as QLCCTaskGroupCycleModel)

                setTypes(ResType);
                setGroups(ResGroup);
                setStatusList(ResStatus);
                setTaskScripts(ResScript);
                setCycle([...ResCycle])

            } catch (error) {
                setDepartments([]);
            } finally {
                setLoading(false);
            }
        })();
    }, [])


    React.useEffect(() => {
        if (props.open) {

            (async () => {
                // Thông tin người theo dỗi của một dự án
                // const ResFollowInApartmentBuilding = await qlccHomePage.userInfoWebGet({
                //     ... new UserInfo(),
                //     ma_cty: SessionLogin.ma_cty,
                //     user_login: SessionLogin.userName,
                //     username: SessionLogin.userName,
                //     apartment_building_id: props.apartmentBuildingId ? props.apartmentBuildingId : '',
                //     action_type: "GET_USER_MANAGER"
                // } as UserInfo)

                const ResFollowInApartmentBuilding = await qlccHomePage.userInfoWebFilterAdmin({
                    ... new UserInfoFilterModel(),
                    ma_cty: SessionLogin.ma_cty,
                    // user_login: SessionLogin.userName,
                    // username: SessionLogin.userName,
                    apartment_building_id: apartment_building_id,
                    // action_type: "GET_USER_MANAGER"
                } as UserInfoFilterModel)

                if (ResFollowInApartmentBuilding) {
                    setFollower(ResFollowInApartmentBuilding);
                }
            })();

            if (props.action === ACTIONS.ADD) {

                (async () => {

                    const ResMyInfo = await qlccHomePage.userInfoWebFilterAdmin({
                        ... new UserInfoFilterModel(),
                        ma_cty: SessionLogin.ma_cty,
                        username: SessionLogin.userName,
                        apartment_building_id: apartment_building_id,
                        // action_type: "GET-USER"
                    } as UserInfoFilterModel)

                    const myInfo = ResMyInfo[0];
                    try {
                        setUsers([...ResMyInfo])

                        setData({
                            ... new QLCCTaskModel(),
                            ma_cty: SessionLogin.ma_cty,
                            cuser: SessionLogin.userName,
                            cdate: moment().toDate(),
                            date_done: moment().toDate(),
                            ldate: moment().toDate(),
                            start_time: moment(new Date()).startOf('day').toDate(),
                            end_time: moment(new Date()).endOf('day').toDate(),
                            luser: SessionLogin.userName,
                            sender: SessionLogin.userName,
                            percent: 0,
                            is_done: false,
                            type_code: TYPE_CODE_OF_REPORT,
                            title: props.titleFeedBack ? props.titleFeedBack : "",
                            feedback_id: props.feedback_id ? props.feedback_id : "",
                            description: props.contentFeedBack ? props.contentFeedBack : "",
                            status_id: statusList && statusList.length > 0 ? statusList[0].id! : "",
                            department_id: myInfo && myInfo.department_id ? myInfo.department_id : '',
                            department_name: myInfo && myInfo.department_id ? myInfo.department_name : '',
                            receiver: myInfo && myInfo.department_id ? myInfo.username : '',
                            apartment_building_id: apartment_building_id,
                            cycle_id: Cycle && Cycle.length > 0 ? Cycle[0].id : "",
                        } as QLCCTaskModel);
                        setId(undefined);
                        setDataDetails([] as QLCCTaskDetailModel[]);

                        htmlString = props.contentFeedBack ? props.contentFeedBack : "";
                        setPath([]); // tập tin đính kèm
                    }
                    catch (ex) {
                        console.error(ex);
                        alert("Vui lòng tải lại trang")
                    }

                    setLoadDataContact(pre => !pre);
                })();
            } else {
                setId(props.itemSelected);
                setdBindata(pre => !pre); // đang tải dữ liệu vào data
                qLCClient
                    .qLCCTaskGet({
                        ... new QLCCTaskModel(),
                        ...props.data,
                        apartment_building_id: apartment_building_id,
                        ma_cty: SessionLogin.ma_cty,
                        cuser: SessionLogin.userName,
                        luser: SessionLogin.userName,
                        username_login: SessionLogin.userName,
                        action_type: "339",

                    } as QLCCTaskModel)
                    .then(async (res) => {
                        if (res[0]) {
                            try {
                                setData({
                                    ...res[0],
                                    ldate: new Date(),
                                    luser: SessionLogin.userName,
                                    type_code: TYPE_CODE_OF_REPORT,
                                } as QLCCTaskModel)

                                onloadUser();
                            } catch (e) {
                                console.log(e, 'Xảy ra lỗi khi load đối tượng có sẵn')
                            }

                            if (res[0].qlccTaskDetails) {
                                setDataDetails(res[0].qlccTaskDetails);
                                setDataDetail(res[0].qlccTaskDetails);

                            } else {
                                setDataDetails([] as QLCCTaskDetailModel[])
                                setDataDetail([] as QLCCTaskDetailModel[])
                            }

                            const file = res[0].attach_files;

                            if (file) {
                                setPath(file.split(';'))
                            } else {
                                setPath([]); // tập tin đính kèm
                            }
                            // const scriptIdTmp = res[0].script_id;
                            // if(scriptIdTmp) {
                            //     setScriptId(scriptIdTmp)
                            // }

                            const resContent = res[0].description;
                            if (resContent) {
                                htmlString = resContent;
                            }

                        }
                        setLoadDataContact(pre => !pre);
                        setdBindata(pre => !pre); // ngưng tải dữ liệu vào data

                    })
                    .catch(() => { });
            }

        }
    }, [props.itemSelected, props.action, props.open]);


    React.useEffect(() => {
        if (actionPopup.open) {
            if (dataDetails.length > 0) {
                setData({
                    ...data,
                    start_time: GET_DATE(dataDetails, -1),
                    end_time: GET_DATE(dataDetails, 1)
                } as QLCCTaskModel)
            }
        } else {
            ChangeIsDone();
        }



    }, [actionPopup.open]);

    // TODO: THAY ĐỔI TRẠNG THÁI CÔNG VIỆC
    /**
     *  NẾU CÔNG VIỆC HOÀN THÀNH THÌ => CÔNG VIỆC CON SẼ HOÀN THÀNH
     *  NGƯỢC LẠI => CHƯA CÓ
     */
    React.useEffect(() => {
        if (data.status_id) {
            const status = statusList.find(item => item.stt! === TASK_STATUS_DONE);
            if (status &&
                data.status_id === status.id) {
                const lst = dataDetails;
                const updTaskDetails: QLCCTaskDetailModel[] = [];
                lst.map(item => {
                    updTaskDetails.push({
                        ...item,
                        is_done: true,
                        percent: 100
                    } as QLCCTaskDetailModel)
                })
                setDataDetails([...updTaskDetails]);
                ChangeIsDone();
            }
        }

    }, [data.status_id])

    // TODO: Thay đổi trạng thái công việc khi check đã hoàn thành
    React.useEffect(() => {
        if (data.is_done) {
            const status = statusList.find(item => item.stt! === TASK_STATUS_DONE)
            if (status) {
                setData({
                    ...data,
                    status_id: status.id,
                } as QLCCTaskModel)
            }
        } else {
            const status = statusList.find(item => item.stt! !== TASK_STATUS_DONE)
            if (status) {
                setData({
                    ...data,
                    status_id: status.id,
                } as QLCCTaskModel)
            }
        }
    }, [data.is_done])

    //TODO: CẬP NHẬT DANH SÁCH KHI HOÀN THÀNH HAY CHƯA - DỰA VÀO 'TIẾN ĐỘ CÔNG VIỆC'
    const ChangeIsDone = async () => {
        if (dataDetails.length > 0) {

            const lstDetails = await dataDetails.flatMap(item => item.percent);
            let totalProgress = 0;

            lstDetails.map(item => totalProgress += item!);

            const PERCENT = isNaN(totalProgress) ? 0 : totalProgress / lstDetails.length;

            // const dataDetailsLength = dataDetails.length;
            // const dataDetailsDone = dataDetails.filter(item => item.is_done === true).length || 0;
            // const percent = dataDetailsLength !== 0 ? dataDetailsDone / dataDetailsLength * 100 : 0;

            // Lấy trạng thái của công việc
            const getStatus = GetStatus(PERCENT);

            setData({
                ...data,
                percent: Number(PERCENT.toFixed(2)),
                // is_done: dataDetailsDone === dataDetailsLength
                is_done: PERCENT === 100,
                status_code: getStatus && getStatus.stt ? getStatus.stt : undefined,
                status_id: getStatus && getStatus.id ? getStatus.id : undefined,
                status_name: getStatus && getStatus.name ? getStatus.name : undefined
            } as QLCCTaskModel)
        }
    }

    const GetStatus = (percent: number) => {
        const CHUA_LAM = 1;
        const DANG_LAM = 2;
        const HOAN_THANH = 3;

        const TIEN_DO_CHUA_LAM = 0;
        const TIEN_DO_HOAN_THANH = 100;

        const IS_CHUA_LAM = percent === TIEN_DO_CHUA_LAM;
        const IS_HOAN_THANH = percent === TIEN_DO_HOAN_THANH;



        if (statusList && statusList.length > 0) {
            const STATUS_DEFAULT = statusList[0];
            if (IS_CHUA_LAM) {
                return statusList.find(item => item.stt === CHUA_LAM) || STATUS_DEFAULT;
            }
            else if (IS_HOAN_THANH) {
                return statusList.find(item => item.stt === HOAN_THANH) || STATUS_DEFAULT;
            } else {
                return statusList.find(item => item.stt === DANG_LAM) || STATUS_DEFAULT;
            }
        } else {
            return { ... new QLCCTaskStatusModel() } as QLCCTaskStatusModel;
        }

    }

    // DONE CÔNG VIỆC KHI CHECK ĐÃ HOÀN THÀNH
    const doneTask = (value: boolean) => {

        const lst: QLCCTaskDetailModel[] = [];
        const lstDetails: QLCCTaskDetailModel[] = dataDetails;
        lstDetails.map(item => {
            lst.push({
                ...item,
                is_done: value
            } as QLCCTaskDetailModel)
        })

        setDataDetails(lst);

    }

    const handleChangeInput = async (e: any) => {
        const { name, value } = e.target;
        const DONE_PERCENT = 100;
        const DOING_PERCENT = 0;

        if (name === 'is_done') {
            doneTask(value);
            const findStatus = statusList.find(item => item.stt === (value ? 3 : 2));
            setData({
                ...data,
                [name]: value,
                percent: value ? DONE_PERCENT : DOING_PERCENT,
                status_id: findStatus ? findStatus.id : data.status_id,
                status_code: findStatus ? findStatus.stt : data.status_code,
                status_name: findStatus ? findStatus.name : data.status_name
            } as QLCCTaskModel);

        } else if (name === 'task_script_id') {
            setData({ ...data, [name]: value, follower: '' } as QLCCTaskModel);
        }
        else {
            setData({ ...data, [name]: value } as QLCCTaskModel);
        }
    };

    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        setData({ ...data, [name]: new Date(value) } as QLCCTaskModel);
    }

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>(-1);
    const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>();
    const [reportInfo, setReportInfo] = React.useState<ReportInfo>();

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };

    // TODO: CHECK LIST
    const handleRowUpdating = async (e: any) => {
        if (!disable) {

            const PERCENT_DONE = 100;
            const PERCENT_DOING = 0;
            const DONE_CODE_STATUS = 3;
            const DOING_CODE_STATUS = 2;

            const { key, newData } = e;

            console.log(key, 'key')
            if (key) {
                const taskDetail = dataDetails.find(item => item.id === key);
                const taskDetailIndex = dataDetails.findIndex(item => item.id === key);
                const tempLst = dataDetails; // copy 

                const statusFilter = statusList.find(item => item.stt === (newData.is_done ? DONE_CODE_STATUS : DOING_CODE_STATUS));
                const dateDone: Date | undefined = newData.date_done;
                if (taskDetail) {

                    const taskDetailUpd = await {
                        ...taskDetail,
                        ...newData,
                        percent: newData.is_done ? PERCENT_DONE : PERCENT_DOING,
                        status_id: statusFilter ? statusFilter.id : taskDetail.status_id,
                        status_name: statusFilter ? statusFilter.name : taskDetail.status_name,
                        date_done: dateDone ? dateDone : taskDetail.date_done,
                    } as QLCCTaskDetailModel;

                    await tempLst.splice(taskDetailIndex, 1, taskDetailUpd);

                    // console.log(tempLst)
                    await setDataDetails(tempLst);

                    if (newData.is_done !== undefined) {
                        await ChangeIsDone();
                    }
                }
            }
        } else {
            Notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`UNAVAILABLE_FEATURE`]
            );
        }
    }

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {

        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {

                const index = e.component.getRowIndexByKey(keys[0]);

                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
                // setDataDetail(keys[0])
                // setIdItemSelected(keys[0].id || "");
            });
        }
    };

    const handleClickAction = async (action: SysActionType, nowTime: Date) => {

        const ItemChoose = dataDetails.find(item => item.id === selectedItemKeys);

        if (props.action !== ACTIONS.VIEW) {
            switch (action?.action_code) {
                case ACTIONS.VIEW:
                    if (dataGridRef.current) {
                        try {
                            const key = await getKeyRowSelected(dataGridRef);
                            const index = await getIndexRowSelected(dataGridRef);
                            if (dataGridRef.current && key && index >= 0) {
                                setActionPopup({
                                    ...actionPopup,
                                    open: true,
                                    actions: action?.action_code,
                                    data: ItemChoose || {} as QLCCTaskDetailModel
                                })
                            } else {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                );
                            }
                        } catch (error) {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    }
                    break;
                case ACTIONS.INSERT:
                    if (dataGridRef.current) {
                        try {
                            const key = await getKeyRowSelected(dataGridRef);
                            const index = await getIndexRowSelected(dataGridRef);
                            if (dataGridRef.current && key && index >= 0) {
                                const detailSelect = dataDetails[selectedRowIndex];
                                setActionPopup({
                                    ...actionPopup,
                                    open: true,
                                    actions: action?.action_code,
                                    start_time: GET_DATE([detailSelect], -1) || new Date()
                                })
                            } else {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                );
                            }
                        } catch (error) {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    }

                    break;
                case ACTIONS.ADD:
                    setActionPopup({
                        ...actionPopup,
                        open: true,
                        actions: action?.action_code,
                        start_time: GET_DATE(dataDetails, 1) || new Date()
                    })
                    break;
                case ACTIONS.DELETE:
                    if (dataGridRef.current) {
                        try {
                            const key = await getKeyRowSelected(dataGridRef);
                            const index = await getIndexRowSelected(dataGridRef);
                            if (dataGridRef.current && key && index >= 0) {
                                const newDatas = dataDetails;
                                newDatas.splice(index, 1)
                                setDataDetails([...newDatas]);
                                console.log(newDatas)
                            } else {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                );
                            }
                        } catch (error) {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    }
                    break;
                case ACTIONS.COPY:
                    if (dataGridRef.current) {
                        try {
                            const key = await getKeyRowSelected(dataGridRef);
                            const index = await getIndexRowSelected(dataGridRef);
                            if (dataGridRef.current && key && index >= 0) {
                                setActionPopup({
                                    ...actionPopup,
                                    open: true,
                                    actions: action?.action_code,
                                    data: ItemChoose || {} as QLCCTaskDetailModel,
                                    start_time: GET_DATE(dataDetails, 1) || new Date()
                                })
                            } else {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                );
                            }
                        } catch (error) {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    }
                    break;
                case ACTIONS.EDIT:
                    if (dataGridRef.current) {
                        try {
                            const key = await getKeyRowSelected(dataGridRef);
                            const index = await getIndexRowSelected(dataGridRef);
                            if (dataGridRef.current && key && index >= 0) {
                                setActionPopup({
                                    ...actionPopup,
                                    open: true,
                                    actions: action?.action_code,
                                    data: ItemChoose || {} as QLCCTaskDetailModel
                                })
                            } else {
                                Notification(
                                    TYPE_MESSAGE.WARNING,
                                    STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                                );
                            }
                        } catch (error) {
                            Notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    }
                    break;
                case ACTIONS.SAVE:
                case ACTIONS.CLOSE:
                case ACTIONS.SEARCH:
                    Notification(
                        TYPE_MESSAGE.INFO,
                        "Chức năng không khả dụng"
                    );
            }
        } else if (action.action_code === ACTIONS.VIEW) {
            if (dataGridRef.current) {
                try {
                    const key = await getKeyRowSelected(dataGridRef);
                    const index = await getIndexRowSelected(dataGridRef);
                    if (dataGridRef.current && key && index >= 0) {
                        setActionPopup({
                            ...actionPopup,
                            open: true,
                            actions: ACTIONS.VIEW,
                            data: ItemChoose || {} as QLCCTaskDetailModel
                        })
                    } else {
                        Notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                } catch (error) {
                    Notification(
                        TYPE_MESSAGE.WARNING,
                        STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                    );
                }
            }
        } else {
            Notification(
                TYPE_MESSAGE.INFO,
                "Chức năng không khả dụng trong trường hợp này"
            );
        }
    }

    const _validate = () => {
        const checkRevecie = data && (data.receiver === undefined || data.receiver?.length === 0);
        let messageRevecie = "Chưa phân công cho nhân viên";
        if (!data) {
            notification(MESSAGE_TYPE[1] || 'error', STATUS_MESSAGE['SERVER_ERROR']);
            return false;
        }
        if (checkRevecie) {
            notification(MESSAGE_TYPE[1] || 'error', messageRevecie);
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {
        if (_validate()) {
            const TranslateTime: QLCCTaskDetailModel[] = [];
            dataDetails.map(item => {

                // const startTime: Date = data.ngay_ct ? moment(`${moment(data.ngay_ct).format('yyyy-MM-dd')} ${moment(item.start_time).format('HH:mm')}`).utc(true).toDate() : moment(item.start_time).utc(true).toDate();
                // const endTime: Date = data.end_time ? moment(`${moment(data.end_time).format('yyyy-MM-dd')} ${moment(item.end_time).format('HH:mm')}`).utc(true).toDate() : moment(item.end_time).utc(true).toDate();

                const endTime = moment(data.end_time).utc(true).toDate();
                endTime.setUTCHours(23, 59, 59, 0);

                TranslateTime.push({
                    ...item,
                    // start_time: TransDateTime(item.start_time || new Date()),
                    // end_time: TransDateTime(item.end_time || new Date()),

                    // start_time: startTime,
                    // end_time: endTime,
                    start_time: moment(data.start_time).utc(true).toDate(),
                    end_time: endTime,
                    sender: SessionLogin.userName,
                    sender_name: SessionLogin.fullName,
                    receiver: data.receiver ? data.receiver : undefined,
                    receiver_name: users.find(item => item.username === data.receiver)?.fullname || undefined,
                    cdate: moment().utc(true).toDate(),
                    date_done: moment(item.date_done).utc(true).toDate(),
                } as QLCCTaskDetailModel);
            })

            const task_script_name = taskScripts.find(item => item.id === data.task_script_id);

            switch (props.action) {
                case ACTIONS.ADD:

                    const result: SysModel = await qLCClient.qLCCTaskIns({
                        ...new QLCCTaskModel(),
                        ...data,
                        qlccTaskDetails: [...TranslateTime],
                        ldate: TransDateTime(new Date()),
                        cdate: TransDateTime(data.cdate || new Date),
                        start_time: TransDateTime(data.start_time || new Date()),
                        end_time: TransDateTime(data.end_time || new Date()),
                        date_done: TransDateTime(new Date()),
                        ngay_ct: TransDateTime(data.ngay_ct || new Date()),
                        type_code: TYPE_CODE_OF_REPORT,
                        sender_name: SessionLogin.fullName,
                        receiver_name: users.find(item => item.username === data.receiver)?.fullname || undefined,
                        title: task_script_name && task_script_name.title ? task_script_name.title : `Công việc của ${SessionLogin.fullName}`,
                        name: task_script_name && task_script_name.title ? task_script_name.title : `Công việc của ${SessionLogin.fullName}`,
                        // cycle_code: task_script_name && task_script_name.type_code ? task_script_name.type_code : undefined,
                        // cycle_id: task_script_name && task_script_name.cycle_id ? task_script_name.cycle_id : undefined,
                        cycle_code: data.cycle_id ? Cycle.find(item => item.id === data.cycle_id)?.code! : undefined,
                        attach_files: path.join(';'),
                        apartment_building_id: apartment_building_id,
                        ma_cty: SessionLogin.ma_cty,
                    } as QLCCTaskModel);

                    if (result) {
                        notification(MESSAGE_TYPE[result.status ?? 0], result.message);
                        if (result.status === 0) {
                            props.onEditSuccess(true);
                            props.onClose();
                        }
                    }

                    break;
                case ACTIONS.EDIT:
                case ACTIONS.UPDATETASK:
                    const resultUpd = await qLCClient.qLCCTaskUpd({
                        ...new QLCCTaskModel(),
                        ...props.data,
                        ...data,
                        date_done: TransDateTime(data.date_done || new Date()),
                        ldate: TransDateTime(new Date()),
                        cdate: TransDateTime(data.cdate || new Date),
                        start_time: TransDateTime(data.start_time || new Date()),
                        end_time: TransDateTime(data.end_time || new Date()),
                        ngay_ct: TransDateTime(data.ngay_ct || new Date()),
                        qlccTaskDetails: [...TranslateTime],
                        sender_name: SessionLogin.fullName,
                        receiver_name: users.find(item => item.username === data.receiver)?.fullname || undefined,
                        title: task_script_name && task_script_name.title ? task_script_name.title : `Công việc của ${SessionLogin.fullName}`,
                        name: task_script_name && task_script_name.title ? task_script_name.title : `Công việc của ${SessionLogin.fullName}`,
                        // cycle_code: task_script_name && task_script_name.type_code ? task_script_name.type_code : undefined,
                        // cycle_id: task_script_name && task_script_name.cycle_id ? task_script_name.cycle_id : undefined,
                        cycle_code: data.cycle_id ? Cycle.find(item => item.id === data.cycle_id)?.code! : undefined,
                        attach_files: path.join(';'),
                        ma_cty: SessionLogin.ma_cty,
                        username_login: SessionLogin.userName,
                        apartment_building_id: apartment_building_id,
                    } as QLCCTaskModel)

                    if (resultUpd) {
                        notification(MESSAGE_TYPE[resultUpd.status ?? 0], resultUpd.message);
                        if (resultUpd.status === 0) {
                            props.onEditSuccess(true);
                            props.onClose();
                        }
                    }
                    break;
            }
        }
    }

    const handleTadChild = (e: any, actions: string) => {
        switch (actions.toLocaleUpperCase()) {
            case ACTIONS.ADD:
            case ACTIONS.COPY:
                setDataDetails([...dataDetails, e])
                break;
            case ACTIONS.EDIT:
                const dataEdit = dataDetails;
                const newData = {
                    ...dataEdit[selectedRowIndex],
                    ...e
                } as QLCCTaskDetailModel;
                const NUMBER_DELETE = 1;
                dataEdit.splice(selectedRowIndex, NUMBER_DELETE, newData)
                setDataDetails([...dataEdit]);
                break;
            case ACTIONS.INSERT:
                const data = e;
                const INDEX = selectedRowIndex;
                const dataInsert = dataDetails;
                setDataDetails([...dataInsert.splice(INDEX, 0, data)]);
                break;
        }
    }

    //   TODO: CHỌN KẾ HOẠCH CÔNG VIỆC - load dữ liệu công việc đã chọn
    const handleChangeTaskOne = async (id: string) => {
        const value = id;
        const lstDetails: QLCCTaskDetailModel[] = dataDetails;
        const lst: QLCCTaskDetailModel[] = []
        // LỌC LẠI DANH SÁCH -> BỎ NHỮNG TASK_ID KHÔNG TỒN TẠI TRONG @VALUE
        lst.push(
            ...lstDetails
                .filter(
                    item =>
                        !item.task_id
                        || (
                            props.action === ACTIONS.EDIT
                            && item.task_id === data.id
                        )
                )
        );

        const resData = await qLCClient.qLCCTaskGet({
            ... new QLCCTaskModel(),
            id: value,
            ma_cty: SessionLogin.ma_cty,
            type_code: TYPE_CODE_TASK_SCRIPT,
            username_login: SessionLogin.userName,
            // action_type: "FILTER_DM",
            action_type: "938",
            apartment_building_id: apartment_building_id,
        } as QLCCTaskModel);


        if (resData.length > 0) {

            const lstTaskDetail: QLCCTaskDetailModel[] = [];

            resData.map(p => {
                if (p.qlccTaskDetails) {
                    p.qlccTaskDetails.map(taskDetail => {
                        lstTaskDetail.push({
                            ...taskDetail,
                            receiver: data && data.receiver ? data.receiver : SessionLogin.userName,
                            receiver_name: users.find(item => item.username === data.receiver)?.fullname || undefined,
                            sender: SessionLogin.userName,
                            sender_name: SessionLogin.fullName,
                            id: NEWID()
                        } as QLCCTaskDetailModel);
                    })
                }
            })

            setDataDetail([...lst, ...lstTaskDetail]);
        } else {
            setDataDetail([...lst]);
        }

        if (resData && resData[0]) {
            return ({
                ...data,
                description: resData[0].description!,
                title: resData[0].title!,
                name: resData[0].name!
            } as QLCCTaskModel);
        }
        else {
            return data;
        }

    }

    React.useEffect(() => {
        (async () => {
            if (data.task_script_id && bindata === false) {
                const newData = await handleChangeTaskOne(data.task_script_id); //  trả về công việc có thông tin của title, name , description
                const getTaskScript = taskScripts.find(item => item.id === data.task_script_id);
                if (getTaskScript && getTaskScript.follower) {
                    setData({
                        ...newData,
                        follower: data.follower ? `${data.follower};${getTaskScript.follower}` : getTaskScript.follower,
                    } as QLCCTaskModel)
                }
            }
        })();
    }, [data.task_script_id])

    React.useEffect(() => {
        filterTaskDetails()
    }, [data.cycle_id, dataDetail])

    // TODO:    LỌC DANH SÁCH CÔNG VIỆC CHI TIẾT THEO CHU KỲ

    const filterTaskDetails = () => {
        if (data && data.cycle_id && dataDetail && dataDetail.length > 0) {
            const lstTaskFilter: QLCCTaskDetailModel[] = []; // lọc theo chu kỳ

            try {
                dataDetail.map(taskItem => {
                    if (taskItem.cycle_id && taskItem.cycle_id === data.cycle_id) {
                        lstTaskFilter.push(taskItem);
                    }
                })

                setDataDetails(lstTaskFilter);
            } catch (ex) {
                console.log(ex);
                setDataDetail([]);
            }
        } else {
            setDataDetails(dataDetail);
        }
    }

    //   TODO: CHỌN KẾ HOẠCH CÔNG VIỆC - CHỌN NHIỀU

    const handleChangeTask = async (e: any) => {
        setLoading(true);
        const { value } = e.target;
        const lstDetails: QLCCTaskDetailModel[] = dataDetails;
        const lst: QLCCTaskDetailModel[] = []

        // LỌC LẠI DANH SÁCH -> BỎ NHỮNG TASK_ID KHÔNG TỒN TẠI TRONG @VALUE
        lst.push(
            ...lstDetails
                .filter(
                    item =>
                        !item.task_id
                        || (
                            props.action === ACTIONS.EDIT
                            && item.task_id === data.id
                        )
                )
        );

        if (value && value.length > 0) {

            const Resdata = await qLCClient.qLCCTaskGet({
                ... new QLCCTaskModel(),
                id: [...value].join(';'),
                apartment_building_id: apartment_building_id,
                ma_cty: SessionLogin.ma_cty,
                type_code: TYPE_CODE_TASK_SCRIPT,
                username_login: SessionLogin.userName,
                action_type: "INFO_DETAIL"
            } as QLCCTaskModel);

            if (Resdata.length > 0) {

                const lstTaskDetail: QLCCTaskDetailModel[] = [];

                Resdata.map(p => {
                    if (p.qlccTaskDetails) {
                        p.qlccTaskDetails.map(taskDetail => {
                            lstTaskDetail.push({ ...taskDetail, id: NEWID() } as QLCCTaskDetailModel);
                        })
                    }
                })

                setDataDetails([...lst, ...lstTaskDetail]);
            }
        } else {
            setDataDetails([...lst]);
        }

        setLoading(false);
    }

    React.useEffect(() => {


        if (data.group_id &&
            data.group_id !== '' &&
            data.department_id &&
            data.department_id !== ''
        ) {
            (async () => {
                const ResTaskScript = await qLCClient.qLCCTaskGet({
                    ...new QLCCTaskModel(),
                    action_type: "FILTER_DM",
                    department_id: data.department_id,
                    group_id: data.group_id,
                    type_code: '2',
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty,
                    username_login: SessionLogin.userName,
                } as QLCCTaskModel);

                setTaskScripts([...ResTaskScript]);

            })();
            console.log('group_id,department_id')

        } else if (data.department_id && data.department_id !== '') {
            onloadUser()
            console.log('group_id,department_id')

        }


    }, [data.group_id, data.department_id])

    // TODO: xử lý trường hợp xem không load được nhân viên thực hiện công việc đó
    React.useEffect(() => {


        if (data.department_id && data.department_id !== '') {
            onloadUser()
            console.log('group_id,department_id')
        }
    }, [data.department_id])

    // TODO: Người theo dõi
    const handleChangeFollowers = async (e: any) => {
        const { value } = e.target;
        setData({
            ...data,
            follower: value.join(';')
        } as QLCCTaskModel)
    }

    const [steps, setSteps] = React.useState<number>(props.action === ACTIONS.ADD ? 0 : 100)

    const handleSteps = async (e: any) => {
        setSteps(steps + 1);
    }

    const [openTaskInfo, setTaskInfo] = React.useState<boolean>(false)

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            TransitionComponent={Transition}
            maxWidth="xl"
            fullWidth
            fullScreen={isFull}
            keepMounted
        >
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === ACTIONS.EDIT || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        className="mr-2"
                        edge="end"
                        color="info"
                        onClick={() => setIsFull(pre => !pre)}
                        aria-label="close"
                    >
                        {isFull ? <CloseFullscreenIcon sx={{ color: '#1f2937' }} /> : <FullscreenIcon sx={{ color: '#1f2937' }} />}
                    </IconButton>
                    <IconButton
                        edge="end"
                        color="error"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon sx={{ color: '#1f2937' }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent className="task-DialogContent" >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9} lg={9.5} className='task-left'>
                        <Paper
                            variant="outlined"
                            className="shadow-taskK paper-taskK mb-3"
                        >
                            <InputLabel className="text-center text-uppercase" sx={{ fontSize: 18, color: '#333' }}>Công việc</InputLabel>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <Box className="box-around">
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>{LABEL_TASK.DEPARTMENT}</InputLabel>
                                        <DHSSelectBox
                                            dataSource={departments}
                                            name="department_id"
                                            onValueChanged={handleChangeInput}
                                            displayExpr="ten_bp"
                                            searchExpr="ten_bp"
                                            valueExpr="ma_bp"
                                            value={data?.department_id}
                                            hint={HintCode.DEPARTMENT}
                                            disabled={enabled([ACTIONS.ADD, ACTIONS.EDIT])}
                                        />
                                    </Box>

                                    <Box className="box-around">
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>{LABEL_TASK.WORKER}</InputLabel>
                                        <DHSSelectBox
                                            dataSource={users}
                                            name="receiver"
                                            onValueChanged={handleChangeInput}
                                            displayExpr="fullname"
                                            searchExpr="fullname"
                                            valueExpr="username"
                                            value={data?.receiver}
                                            hint={HintCode.TASK_RECEIVER}
                                            disabled={enabled([ACTIONS.ADD, ACTIONS.EDIT])}
                                        />
                                    </Box>

                                    <Box className="box-around">
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>{LABEL_TASK.TASK_OF_DEPARTMENT}</InputLabel>
                                        <DHSSelectBox
                                            dataSource={Groups}
                                            name="group_id"
                                            onValueChanged={handleChangeInput}
                                            displayExpr="name"
                                            searchExpr="name"
                                            valueExpr="id"
                                            value={data?.group_id}
                                            hint={HintCode.TASK_GROUP}
                                            disabled={enabled([ACTIONS.ADD, ACTIONS.EDIT])}
                                        />
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>{LABEL_TASK.SCHEDULE_TASK}</InputLabel>
                                        {/* <DHSTagBox
                                            dataSource={taskScripts}
                                            name="title"
                                            onValueChanged={handleChangeTask}
                                            displayExpr="title"
                                            searchExpr="title"
                                            valueExpr="id"
                                            defaultValue={taskScriptId}
                                            value={taskScriptId}
                                            disabled={disable}
                                        /> */}
                                        <DHSSelectBox
                                            dataSource={taskScripts}
                                            name="task_script_id"
                                            onValueChanged={handleChangeInput}
                                            displayExpr="title"
                                            searchExpr="title"
                                            valueExpr="id"
                                            value={data.task_script_id}
                                            hint={HintCode.TASK_SCRIPT}
                                            disabled={enabled([ACTIONS.ADD, ACTIONS.EDIT])}

                                        />
                                    </Box>
                                    <Box className="box-around">
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>{LABEL_TASK.CYCLE_TASK}</InputLabel>
                                        <DHSSelectBox
                                            dataSource={Cycle}
                                            name="cycle_id"
                                            onValueChanged={handleChangeInput}
                                            displayExpr="name"
                                            searchExpr="name"
                                            valueExpr="id"
                                            value={data?.cycle_id}
                                            disabled={enabled([ACTIONS.ADD, ACTIONS.EDIT])}

                                            hint={HintCode.TASK_CYCLE}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box className="box-around">
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày báo cáo</InputLabel>
                                        <TaskCheckListNgayCT2
                                            // cycle_code={data.task_script_id ? taskScripts.find(item => item.id === data.task_script_id)?.cycle_code! : 1}
                                            cycle_code={data.cycle_id ? Cycle.find(itemCycle => itemCycle.id === data.cycle_id)?.code! : 1}
                                            // cycle_code={data.cycle_code?? 1}
                                            value={data.ngay_ct}
                                            setValue={(value: Date) => {
                                                setData({
                                                    ...data,
                                                    ngay_ct: value
                                                } as QLCCTaskModel)
                                            }}
                                            onSubmit={(e: any) => {
                                                setData({
                                                    ...data,
                                                    start_time: e.startTime,
                                                    end_time: e.endTime
                                                } as QLCCTaskModel)
                                            }}
                                            case="SET_DATA"
                                            disable={enabled([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.UPDATETASK])}

                                        />
                                    </Box>
                                    <Box className="box-around">
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày hoàn thành</InputLabel>
                                        <DHSDateTimeComponentV2
                                            name="date_done"
                                            type="datetime"
                                            value={data?.date_done}
                                            onChange={(e: any) => {
                                                handleChangeDate(e)
                                            }}
                                            disabled={enabled([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.UPDATETASK])}
                                            hint={HintCode.TASK_DATE_DONE}
                                        />
                                    </Box>
                                    <Box className="box-around">
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tập tin đính kèm</InputLabel>
                                        <TaskFileAttach
                                            setPath={function (path_: []): void {
                                                setPath([...path_]);
                                            }}
                                            path={path}
                                            action={props.action}
                                            title="Tập tin"
                                            disabled={enabled([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.UPDATETASK])}
                                            hint={HintCode.TASK_ATTACH_FILE}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box className="box-around">
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>{LABEL_TASK.START_TIME}</InputLabel>
                                        <DHSDateTimeComponentV2
                                            name="start_time"
                                            type="datetime"
                                            value={data?.start_time}
                                            onChange={(e: any) => {
                                                handleChangeDate(e)
                                            }}
                                            disabled={enabled([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.UPDATETASK])}
                                            maxDate={data?.end_time}
                                            hint={HintCode['TASK_START_TIME']}
                                        />
                                    </Box>
                                    <Box className="box-around">
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>{LABEL_TASK.END_TIME}</InputLabel>
                                        <DHSDateTimeComponentV2
                                            name="end_time"
                                            type="datetime"
                                            value={data?.end_time}
                                            onChange={(e: any) => {
                                                handleChangeDate(e)
                                            }}
                                            disabled={enabled([ACTIONS.ADD, ACTIONS.EDIT, ACTIONS.UPDATETASK])}
                                            minDate={data?.start_time}
                                            hint={HintCode['TASK_END_TIME']}
                                        />
                                    </Box>

                                    {/* <Box className="box-around pt-2">
                                        <CheckBox
                                            // defaultValue={data.is_done ? data.is_done : false}
                                            value={data.is_done}
                                            id="is_done"
                                            name="is_done"
                                            onValueChange={e => {
                                                handleChangeInput({
                                                    target: {
                                                        name: "is_done",
                                                        value: e
                                                    }
                                                })
                                            }}
                                            disabled={disable}
                                        />
                                        <InputLabel htmlFor="is_done" className="cc-label ml-2">Đã hoàn thành</InputLabel>
                                    </Box> */}
                                    <Box className="box-around pt-2">
                                        <InputLabel className="cc-label">{`Tiến độ công việc (${data.percent}%)`}</InputLabel>
                                        <Slider
                                            min={0}
                                            max={100}
                                            defaultValue={data.percent}
                                            value={data.percent}
                                            onValueChange={(e: number) => handleChangeInput({
                                                target: {
                                                    name: 'percent',
                                                    value: e
                                                }
                                            })}
                                            disabled={true}

                                        >
                                            <Tooltip enabled={true} position="bottom" format={(e: number) => `${e}%`} />
                                        </Slider>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>

                        <Paper
                            variant="outlined"
                            className="shadow-taskK paper-taskK mt-3"
                        >
                            <InputLabel sx={{ fontSize: 18, color: '#333' }} className="mb-2">Danh sách công việc</InputLabel>
                            <DHSToolbarRoleActionType2
                                id={" "}
                                menu={menu}
                                customMenu={[]}
                                onClickAction={handleClickAction}
                            />
                            <DHSDataGridEdit
                                data={dataDetails}
                                table_name={"QLCCTaskDetailCheckList"}
                                key="id"
                                keyExpr="id"
                                mode="cell"
                                // mode="bath"
                                dataGridRef={dataGridRef}
                                allowEdit={true}
                                pageSize={menu?.page_size}
                                isPagination={menu?.is_pagination}
                                isMultiple={false}
                                groupingAutoExpandAll={false}
                                handlePageChange={handlePageChange}
                                handleSelectionChanged={handleSelectionChanged}
                                height={30}
                                handleRowUpdating={handleRowUpdating}
                            />
                        </Paper>

                        {/* <Paper
                            variant="outlined"
                            className="shadow-taskK paper-taskK mt-3"
                        >
                            <Box className="box-around w-100">
                                <InputLabel sx={{ fontSize: 24, color: '#333' }}>Tập tin đính kèm</InputLabel>
                                <TaskFileAttach
                                    setPath={function (path_: []): void {
                                        setPath([...path_]);
                                    }}
                                    path={path}
                                    action={props.action}
                                    title="Tập tin"
                                    disabled={disable}
                                />
                            </Box>
                        </Paper> */}
                        {
                            props.action !== ACTIONS.ADD
                            && (
                                <Paper
                                    variant="outlined"
                                    className="shadow-taskK paper-taskK mt-3"
                                >
                                    <TaskForum
                                        task_id={data.id}
                                        action={props.action}
                                        open={loadDataContact}
                                    />
                                </Paper>
                            )
                        }

                        {/* <Paper
                            variant="outlined"
                            className="shadow-taskK paper-taskK mt-3"
                        >

                        </Paper> */}
                    </Grid>
                    <Grid item xs={12} md={3} lg={2.5} className='task-right'>

                        <Accordion
                            defaultExpanded={openTaskInfo}
                            onClick={() => setTaskInfo(pre => !pre)}
                            className="shadow-taskK"
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='task-AccordionSummary-icon-color' />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                // sx={{ backgroundColor: "#e0f2fe" }}
                                className="shadow-taskK task-AccordionSummary text-white"
                            >
                                <Typography sx={{ fontWeight: 600 }}>Thông tin chi tiết</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    data && data.cdate &&
                                    (<>
                                        <p className="text-right m-0"><small>Ngày tạo: {moment(data.cdate).format('DD-MM-yyyy HH:mm')}</small></p>
                                    </>
                                    )
                                }
                                {
                                    data && data.ldate &&
                                    (<>
                                        <p className="text-right m-0"><small>Chỉnh sửa: {moment(data.ldate).format('DD-MM-yyyy HH:mm')}</small></p>
                                    </>
                                    )
                                }
                                <InputLabel sx={{ fontSize: 15, color: '#333', fontWeight: 600 }}>Tiêu đề</InputLabel>
                                {
                                    data && data.title ?
                                        (<>
                                            <p className="text-start">{data.title}</p>
                                        </>
                                        ) :
                                        (<>
                                            <p className="text-start"> Không có tiêu đề</p>
                                        </>)
                                }
                                {
                                    data && data.sender && data.receiver && data.sender !== data.receiver && data.sender_name && data.receiver_name &&
                                    (<>
                                        <InputLabel sx={{ fontSize: 15, color: '#333', fontWeight: 600 }}>Người giao việc</InputLabel>
                                        <p className="text-start px-4">{data.sender_name}</p>
                                    </>
                                    )
                                }
                                <InputLabel sx={{ fontSize: 15, color: '#333', fontWeight: 600 }}>Mô tả công việc</InputLabel>
                                {
                                    data && data.description ?
                                        (<>
                                            <div className="overflow-hidden overflow-x-scroll overflow-y-scroll">
                                                <div dangerouslySetInnerHTML={{ __html: data.description }} />
                                            </div>
                                        </>
                                        ) :
                                        (<>
                                            <p className="text-start"> Không có mô tả</p>
                                        </>)
                                }

                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='task-AccordionSummary-icon-color' />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                // sx={{ backgroundColor: "#FBBC05" }}
                                className="task-AccordionSummary "

                            >
                                {/* <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Trạng thái</Typography> */}
                                <Typography sx={{ fontWeight: 600, color: "#fff" }}>Trạng thái</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box className="box-around">
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Trạng thái</InputLabel>
                                    <DHSSelectBox
                                        dataSource={statusList}
                                        name="status_id"
                                        onValueChanged={handleChangeInput}
                                        displayExpr="name"
                                        searchExpr="name"
                                        valueExpr="id"
                                        value={data?.status_id}
                                        disabled={true}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='task-AccordionSummary-icon-color' />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                // sx={{ backgroundColor: "#e0f2fe" }}
                                // sx={{ backgroundColor: "#FBBC05" }}
                                className="task-AccordionSummary "
                            >
                                <Typography sx={{ fontWeight: 600, color: "#fff" }}>{LABEL_TASK.LIST_FOLLOWER}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TaskFollower
                                    follower={follower}
                                    data={data.follower}
                                    removeFolloer={(newdata: string) => {
                                        if (props.action !== ACTIONS.VIEW) {
                                            setData({ ...data, follower: newdata } as QLCCTaskModel)
                                        }
                                    }}
                                    action={props.action}
                                    enabled={[ACTIONS.ADD, ACTIONS.EDIT]}
                                />
                                <TaskFollowerInput
                                    follower={follower}
                                    data={data}
                                    removeFolloer={(newdata: string) => {
                                        if (props.action !== ACTIONS.VIEW) {
                                            setData({ ...data, follower: newdata } as QLCCTaskModel)
                                        }
                                    }}
                                    action={props.action}
                                    handleChangeFollowers={handleChangeFollowers}
                                    enabled={[ACTIONS.ADD, ACTIONS.EDIT]}
                                />
                            </AccordionDetails>
                        </Accordion>

                        {
                            props.action !== ACTIONS.ADD && <>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className='task-AccordionSummary-icon-color' />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                        // sx={{ backgroundColor: "#FBBC05" }}
                                        className="task-AccordionSummary "
                                    >
                                        {/* <Typography sx={{ fontWeight: 600, color: "#1565C0" }}>Trạng thái</Typography> */}
                                        <Typography sx={{ fontWeight: 600, color: "#fff" }}>Trao đổi công việc</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="task-child-forum-chat-master-block">
                                        <TaskChildForum
                                            data={data}
                                            action={props.action}
                                            open={loadDataContact}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        }

                    </Grid>
                </Grid>
                <TaskChild
                    open={actionPopup.open}
                    onClose={() => setActionPopup({
                        ...actionPopup,
                        open: false
                    })}
                    data={actionPopup.data}
                    action={actionPopup.actions}
                    onEditSuccess={() => setReload(pre => !pre)}
                    onSubmit={handleTadChild}
                    start_time={actionPopup.start_time}
                    ma_bp={data.department_id}
                    receiver={data.receiver}
                    groups={Groups}
                    types={Types}
                    statusList={statusList}
                    typeCode={data.type_code}
                    groupTask={data.group_id}
                    apartmentBuildingId={apartment_building_id}
                />
            </DialogContent>
            <DialogActions>
                {props.action !== ACTIONS.VIEW && (
                    <Button variant="contained" onClick={handleSubmit}>
                        Lưu
                    </Button>
                )}
                <Button onClick={props.onClose}>Hủy</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog >
    );
};

export default TaskCheckListAction;

export enum LABEL_TASK{
    TASK_ASSIGNMENT = "Phân công nhiệm vụ",
    DEPARTMENT = 'Phòng ban',
    WORKER = "Người thực hiện",
    START_TIME = "Bắt đầu từ ngày",
    END_TIME = "Hạn chót",
    LIST_FOLLOWER = 'Danh sách người theo dõi',
    TASK_CHILD = '',
    TASK_OF_DEPARTMENT = "Công việc của phòng ban",
    SCHEDULE_TASK = "Chọn kế hoạch",
    CYCLE_TASK = "Chọn kỳ công việc",
}