import moment from "moment";

export const TransDateTime = (date?: Date) => {
    if (date) {
        // return new Date(Date.UTC(date.getFullYear()!, date.getMonth()!, date.getDate()!, date.getHours(), date.getMinutes(), date.getSeconds()));
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    }
    return undefined;
}

export const getWeekFromCurrentDate = () => {
    // Lấy ngày hiện tại
    const currentDate = new Date();

    // Tính ngày bắt đầu của tuần (Thứ Hai là ngày đầu tiên của tuần)
    const firstDayOfWeek = new Date(currentDate);
    firstDayOfWeek.setDate(currentDate.getDate() - (currentDate.getDay() - 1 + 7) % 7);

    // Tính ngày cuối tuần (Chủ Nhật là ngày cuối cùng của tuần)
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

    return { firstDayOfWeek, lastDayOfWeek }

}

export const getYearFromCurrentDate = () => {
    const currentDate = new Date();
    const fullYear = currentDate.getFullYear();
    // 
    const firstDayOfYear = new Date(fullYear, 0, 1, 0, 0, 0);
    const lastDayOfYear = new Date(fullYear, 12, 0, 0, 0, 0);

    return { firstDayOfYear, lastDayOfYear }

}

export const hasTime = (dateObject: Date) => {
    // Kiểm tra xem có giờ, phút, giây hay không
    const hasTime = dateObject.getHours() !== 0 || dateObject.getMinutes() !== 0 || dateObject.getSeconds() !== 0 || dateObject.getMilliseconds() !== 0;

    return hasTime;
}


export const compareTimes = (time_from?: Date, time_to?: Date) => {
    if (moment.isDate(time_from) && moment.isDate(time_to)) {
        const from = moment(time_from).milliseconds(0);
        const to = moment(time_to).milliseconds(0);

        if (from.isBefore(to)) {
            return -1;
        } else if (from.isAfter(to)) {
            return 1;
        } else {
            return 0;
        }
    }
    else{
        return undefined;
    }
}