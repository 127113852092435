import CheckBox, { ICheckBoxOptions } from "devextreme-react/check-box"

interface IProps extends ICheckBoxOptions{

}

const DHSCheckBox : React.FC<IProps> = (props) => {
    return <CheckBox
                {...props}
              />
}

export default DHSCheckBox;