import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
    Switch,
    styled,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
    HomeClient,
    QLCCApartmentBuildingModel,
    QLCCClient,
    QLCCInvestorModel,
    RoleRightWebModel,
    SysActionType,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import '../ApartmentBuilding.css';
import CTXDatePicker from "../../../../components/CTX/DatePicker";
import notification from "../../../../common/notification/notification";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";


interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
}
const convert = ["success", "error", "warning"];

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


var appSession: AppSession;
const ApartmentBuildingActions: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const [data, setData] = React.useState<QLCCApartmentBuildingModel>({} as QLCCApartmentBuildingModel);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    // 

    const [investorOptions, setInvestorOptions] = React.useState<QLCCInvestorModel[]>([]);


    React.useEffect(() => {
        if (props.action === "EDIT" || props.action === ACTIONS.VIEW) {
            qLCCClient
                .qlccApartmentBuildingGet({
                    ...new QLCCApartmentBuildingModel(),
                    id: props.itemSelected,
                    ma_cty: SessionLogin.ma_cty,
                    action_type: "GET"
                } as QLCCApartmentBuildingModel)
                .then((res) => {
                    setData(res[0]);

                })
                .catch(() => alert("Không lấy được dữ liệu"));
            setId(props.itemSelected);
        } else {
            setData(new QLCCApartmentBuildingModel());
            setId(undefined)
        };
    }, [props.action]);

    React.useEffect(() => {
        if (props.open) {
            setLoading(true);
            qLCCClient.qlccInvestorGet({
                ...new QLCCInvestorModel(),
                action_type: 'GET',
                ma_cty: SessionLogin.ma_cty,
                apartment_building_id: apartment_building_id
            } as QLCCInvestorModel)
                .then(res => {
                    const newArr: QLCCInvestorModel[] = [];
                    res.map(item => {
                        if (item.ma_cty !== null) {
                            newArr.push(item)
                        }
                    })
                    console.log(newArr)
                    setInvestorOptions(newArr)
                    setLoading(false);
                })
        }
    }, [props.open]);

    const handleSubmit = () => {
        // setLoading(true);
        if (props.action === ACTIONS.ADD) {
            qLCCClient
                ?.qlccApartmentBuildingIns({
                    ...data,
                    ma_cty: SessionLogin.ma_cty
                } as QLCCApartmentBuildingModel)
                .then((res) => {
                    props.onEditSuccess(true);
                    notification(convert[res.status!], res?.message);
                    setLoading(false);
                    if (res.status === 0) props.onClose();
                })
                .catch(() => {
                    setLoading(false);
                });
        } else if (props.action === ACTIONS.EDIT) {
            qLCCClient
                ?.qlccApartmentBuildingUpd({
                    ...data,
                    id: props.itemSelected
                } as QLCCApartmentBuildingModel)
                .then((res) => {
                    notification(convert[res.status!], res?.message);
                    if (res.status === 0) {
                        props.onEditSuccess(true);
                        props.onClose();
                    }

                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
        // setLoading(false);

    };

    const handleChangeInput = (e: any) => {
        if (props.action !== ACTIONS.VIEW) {
            const { name, value, type } = e.target;
            if (type === 'number') {
                setData({ ...data, [name]: Number.parseFloat(value) } as QLCCApartmentBuildingModel);


            }

            else setData({ ...data, [name]: value } as QLCCApartmentBuildingModel);



        }
    };

    const handleChangeDate = (e: any) => {
        if (props.action !== ACTIONS.VIEW) {
            const { name, value } = e;
            setData({ ...data, [name]: new Date(value) } as QLCCApartmentBuildingModel);
        }
    }

    const handleChangeMultipleInput = (e: any) => {
        if (props.action !== ACTIONS.VIEW) {
            const { name, value } = e.target;
            const valueSplit = value.map((value: QLCCInvestorModel) => value.id).join(";")
            setData({ ...data, [name]: valueSplit } as QLCCApartmentBuildingModel);
        }
    }

    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                // handleSubmit();             
                handleSubmit()
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
        }
    }

    return (
        <Dialog
            id="sysMenu"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "flex-end",
            }}
            TransitionComponent={Transition}
            maxWidth='xs'
        >
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === ACTIONS.EDIT || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={8} md={8} xl={8}>
                        <Box style={{ width: "100%", }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tên dự án</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%", fontWeight: "500", fontSize: 13 }}
                                name="name"
                                value={data.name}
                                onChange={handleChangeInput}
                                placeholder={"Tên dự án..."}
                                disabled={props.action === ACTIONS.VIEW}

                            />
                        </Box>

                    </Grid>
                    {/* Nhà đầu tư */}

                    <Grid item xs={12} sm={4} md={4} xl={4}>
                        <Box style={{ width: "100%", }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Nhà đầu tư</InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="apartment-select"
                                filterSelectedOptions={true}
                                multiple={true}
                                dataSource={investorOptions}
                                displayMember="company_name"
                                valueMember="id"
                                value={data?.investor_id}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeMultipleInput({
                                        target: {
                                            name: "investor_id",
                                            value: newValue ?? null,
                                        },
                                    });
                                }}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                            {/* <Autocomplete
                                id="apartment-select"
                                sx={{ width: "100%" }}
                                multiple
                                filterSelectedOptions
                                options={investorOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.company_name}
                                value={
                                    data?.investor_id
                                        ? investorOptions.filter(
                                            (item: any) => {
                                                return data.investor_id?.split(";").includes(item.id)
                                            }

                                        )
                                        : []
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeMultipleInput({
                                        target: {
                                            name: "investor_id",
                                            value: newValue ?? null,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password"// disable autocomplete and autofill
                                        }}
                                        placeholder="Nhà đầu tư"
                                    />
                                )}
                                disabled={props.action === ACTIONS.VIEW}
                            /> */}
                        </Box>
                    </Grid>

                    {/* Email */}
                    <Grid item xs={12} sm={4} md={4} xl={4}>
                        <Box style={{ width: "100%", }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Email</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%", fontWeight: "500", fontSize: 13 }}
                                name="email"
                                value={data.email}
                                onChange={handleChangeInput}
                                placeholder={"Email..."}
                                disabled={props.action === ACTIONS.VIEW}

                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} xl={4}>
                        {/* Hotline */}

                        <Box style={{ width: "100%", }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Hotline</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%", fontWeight: "500", fontSize: 13 }}
                                name="hotline"
                                value={data.hotline}
                                onChange={handleChangeInput}
                                placeholder={"Hotline..."}
                                disabled={props.action === ACTIONS.VIEW}

                            />
                        </Box>
                    </Grid>
                    {/* Thời gian hoạt động */}

                    <Grid item xs={12} sm={4} md={4} xl={4}>

                        <Box style={{ width: "100%", }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Thời gian hoạt động</InputLabel>
                            <CTXDatePicker
                                style={{ width: "100%" }}
                                className="cc-input"
                                name="start_date"
                                onChange={handleChangeDate}
                                value={data.start_date ? data.start_date : Date.now()}
                                disabled={props.action === ACTIONS.VIEW}

                            />
                        </Box>
                    </Grid>

                    {/* Địa chỉ chi tiết */}

                    <Grid item xs={12} sm={4} md={4} xl={4}>

                        <Box style={{ width: "100%", }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Địa chỉ chi tiết</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%", fontWeight: "500", fontSize: 13, }}
                                name="address"
                                value={data.address}
                                onChange={handleChangeInput}
                                placeholder={"Số nhà, tổ, đường, ..."}
                                disabled={props.action === ACTIONS.VIEW}

                            />
                        </Box>

                    </Grid>

                    {/* Số lượng block  */}

                    <Grid item xs={12} sm={4} md={4} xl={4}>

                        <Box style={{ width: "100%", }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số lượng Block</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%", fontWeight: "500", fontSize: 13 }}
                                name="block_total"
                                value={data.block_total}
                                onChange={handleChangeInput}
                                placeholder={"Số lượng Block..."}
                                disabled={props.action === ACTIONS.VIEW}

                            // inputProps={{
                            //     style: {
                            //         cursor: 'not-allowed'
                            //     }
                            // }}
                            />

                        </Box>
                    </Grid>

                    {/* Số lượng căn hộ */}

                    <Grid item xs={12} sm={4} md={4} xl={4}>

                        <Box style={{ width: "100%", }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số lượng căn hộ</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%", fontWeight: "500", fontSize: 13, }}
                                name="apartment_total"
                                value={data.apartment_total}
                                onChange={handleChangeInput}
                                placeholder={"Số lượng căn hộ..."}
                                disabled={props.action === ACTIONS.VIEW}

                            // inputProps={{
                            //     style: {
                            //         cursor: 'not-allowed'
                            //     }
                            // }}
                            />
                        </Box>

                    </Grid>

                    {/* Tỉnh/Thành phố */}

                    <Grid item xs={12} sm={4} md={4} xl={4}>

                        <Box style={{ width: "100%", }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tỉnh/Thành Phố</InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="combo-box-demo"
                                className="cc-input"
                                dataSource={["TP Cao Lãnh", "TP Hồ Chính Minh", "Tỉnh Kiến Giang"]}
                                displayMember="loc_province_id"
                                valueMember="id"
                                value={data?.loc_province_id}

                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "loc_province_id",
                                            value: newValue ? newValue : null
                                        }
                                    })
                                }}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                            {/* <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={["TP Cao Lãnh", "TP Hồ Chính Minh", "Tỉnh Kiến Giang"]}
                                className="cc-input"
                                value={data.loc_province_id}
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "loc_province_id",
                                            value: newValue ? newValue : null
                                        }
                                    })
                                }}


                                renderInput={(params) => <TextField
                                    className="cc-input"
                                    sx={{ width: "100%", fontWeight: "500", fontSize: 13 }}
                                    {...params}
                                    placeholder={"Thành phố/Tỉnh..."}
                                    name="loc_province_id"

                                />}
                                disabled={props.action === ACTIONS.VIEW}

                            /> */}
                        </Box>

                    </Grid>

                    {/* huyện/quận */}

                    <Grid item xs={12} sm={4} md={4} xl={4}>

                        <Box style={{ width: "100%", }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Quận/Huyện</InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="combo-box-demo"
                                className="cc-input"
                                dataSource={["Gò Vấp", "Bình Chánh", "Quận 10"]}
                                displayMember="loc_district_id"
                                valueMember="id"
                                value={data?.loc_district_id}

                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "loc_district_id",
                                            value: newValue ? newValue : null
                                        }
                                    })
                                }}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                            {/* <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={["Gò Vấp", "Bình Chánh", "Quận 10"]}
                                className="cc-input"
                                value={data.loc_district_id}
                                onChange={(e, newValue: any) => {
                                }}

                                renderInput={(params) => <TextField
                                    className="cc-input"
                                    sx={{ width: "100%", fontWeight: "500", fontSize: 13 }}
                                    name="loc_district_id"
                                    {...params}
                                    placeholder={"Quận/ huyện..."}
                                    disabled={props.action === ACTIONS.VIEW}

                                />}
                            /> */}
                        </Box>

                    </Grid>

                    {/* xã/phường */}

                    <Grid item xs={12} sm={4} md={4} xl={4}>

                        <Box style={{ width: "100%", }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Xã/Phường</InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="combo-box-demo"
                                className="cc-input"
                                dataSource={["Phường 10"]}
                                displayMember="loc_district_id"
                                valueMember="id"
                                value={data?.loc_ward_id}

                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "loc_ward_id",
                                            value: newValue ? newValue : null
                                        }
                                    })
                                }}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                            {/* <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={["Phường 10"]}
                                className="cc-input"
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "loc_ward_id",
                                            value: newValue ? newValue : null
                                        }
                                    })
                                }}
                                value={data.loc_ward_id}

                                renderInput={(params) => <TextField
                                    className="cc-input"
                                    sx={{ width: "100%", fontWeight: "500", fontSize: 13 }}
                                    name="loc_ward_id"
                                    {...params}
                                    placeholder={"Xã/ phường..."}
                                    disabled={props.action === ACTIONS.VIEW}

                                />}
                            /> */}
                        </Box>
                    </Grid>

                    {/* Kinh độ */}

                    <Grid item xs={12} sm={4} md={4} xl={4}>

                        <Box style={{ width: "100%", }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Kinh độ</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%", fontWeight: "500", fontSize: 13 }}
                                type="number"
                                name="loc_gps_long"
                                value={data.loc_gps_long}
                                onChange={handleChangeInput}
                                placeholder={"Kinh độ google map..."}
                                disabled={props.action === ACTIONS.VIEW}

                            />
                        </Box>
                    </Grid>

                    {/* Vĩ độ */}

                    <Grid item xs={12} sm={4} md={4} xl={4}>

                        <Box style={{ width: "100%", }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Vĩ độ</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%", fontWeight: "500", fontSize: 13 }}
                                name="loc_gps_lat"
                                value={data.loc_gps_lat}
                                type="number"
                                onChange={handleChangeInput}
                                placeholder={"Vĩ độ google map..."}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                        </Box>

                    </Grid>



                    <Grid item xs={12} sm={4} md={4} xl={4}>
                        {/* Google map link */}

                        <Box style={{ width: "100%", }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Đường dẫn Google Map</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%", fontWeight: "500", fontSize: 13 }}
                                name="loc_google_map_link"
                                value={data.loc_google_map_link}
                                onChange={handleChangeInput}
                                placeholder={"Link nhúng Google Map..."}

                            />
                        </Box>

                    </Grid>

                    {/* kind apartment building id */}
                    {/* <Grid item xs={4}>
                        <Box style={{ width: "100%", }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333'}}>Loại hình chung cư</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{ width: "100%", fontWeight: "500", fontSize: 13 }}
                                name="kind_apartment_id"
                                value={data.kind_apartment_id}
                                onChange={handleChangeInput}
                                placeholder={"Căn hộ thông thường, Shophouse,..."}

                            />
                        </Box>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} xl={12} mt={3}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography >Dịch vụ</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography >
                                    Bảng dịch vụ
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography >Qui định</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Bảng qui định
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>



                </Grid>
            </DialogContent>

            <DialogActions>

                <DHSDialogAction

                    action={props.action}
                    menu={menu}
                    onClickAction={handleClickAction}
                />
            </DialogActions>

            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default ApartmentBuildingActions;


