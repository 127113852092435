import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { components } from "../../../common/ComponentsConfig/components";
import { IReducer } from "../../../common/Interfaces/Interfaces";

import DHSToolbar from "../../../components/DHS/DHS-Toolbar/DHSToolbar";
import { Notification } from "../../../components/layouts";
import DHSGridView from "../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { getRole, getRoles } from "../../../redux/actions/RoleActions";
import { AppSession } from "../../shared/app-session/app-session";
import {
  ChoThueXeClient,
  KiemSoatCanClient,
  HomeClient,
  RoleRightWebModel,
  KscPhieuKiemSoatModel,
  CtxDanhMucXeModel,
  KscVanDonModel,
  KscThongTinTauModel,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import CanXe from "./can-xe/CanXe";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { QrReader } from "react-qr-reader";
import BlockUI from "../../../components/Block-UI/Block-ui";
import VanDon from "./van-don/VanDon";
import notification from "../../../common/notification/notification";
import ThongTinTau from "./thong-tin-tau/ThongTinTau";

interface IOpenCamera {
  pic: any;
  open: boolean;
}
interface IAction {
  type: string;
  open: boolean;
  payload: any;
}
let appSession: AppSession;
const convert = ["success", "error", "warning"];
const menuRoleName = "KS";
const defaultAction: IAction = {
  type: "",
  payload: null,
  open: false,
};
const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};
const DanhSachKSC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
  const webcamRef = React.useRef<any>(null);

  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const kscClient = new KiemSoatCanClient(appSession, BASE_URL_API);
  const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);

  const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
  const [actCx, setActCx] = React.useState<IAction>(defaultAction);
  const [actVd, setActVd] = React.useState<IAction>(defaultAction);
  const [actTtt, setActTtt] = React.useState<IAction>(defaultAction);

  const [itemSelected, selectItem] = React.useState<string>();
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
  const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
  const [isShowSort, setShowSort] = React.useState<boolean>(false);
  const [isShowFilter, setShowFilter] = React.useState<boolean>(false);

  const [reload, setReload] = React.useState<boolean>(false);
  const [dataTable, setDataTable] = React.useState<any[]>([]);
  const [openCamera, setOpenCamera] = React.useState<IOpenCamera>({
    pic: "",
    open: false,
  });
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const customMenu = [
    {
      menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
      onClick: () => {
        setShowSearch(!isShowSearch);
      },
    },
    {
      menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
      onClick: () => {
        setShowGroup(!isShowGroup);
      },
    },
    {
      menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
      onClick: () => {
        setShowSort(!isShowSort);
      },
    },
    {
      menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
      onClick: () => {
        setShowFilter(!isShowFilter);
      },
    },
  ];
  React.useEffect(() => {
    setLoading(true);
    homeClient
      ?.roleRightWebGetAll({
        ...new RoleRightWebModel(),
        rolename: menuRoleName,
        menuid: "30.20.06",
        // menuid: menu?.menuid,
        username: SessionLogin.userName,
      } as RoleRightWebModel)
      .then((res) => {
        dispatch(getRoles(res));
        setRoles(res);
        setLoading(false);
      });
  }, [menu?.component]);

  React.useEffect(() => {
    if (actCx.open === false) {
      switch (menu?.component?.toUpperCase()) {
        case "KSCDANHSACHPHIEU":
          setLoading(true);
          kscClient
            .kscPhieuKiemSoatGet(new KscPhieuKiemSoatModel())
            .then((res) => {
              let tempArr: any = [];
              res.forEach((item) => {
                tempArr.push({
                  ...item,
                  ngay_can_1:
                    item?.ngay_can_1 &&
                    `${item?.ngay_can_1?.getDate()}/${item?.ngay_can_1?.getMonth()}/${item?.ngay_can_1?.getFullYear()}`,
                  ngay_can_2:
                    item?.ngay_can_2 &&
                    `${item?.ngay_can_2?.getDate()}/${item?.ngay_can_2?.getMonth()}/${item?.ngay_can_2?.getFullYear()}`,
                  ngay_tao_phieu:
                    item?.ngay_tao_phieu &&
                    `${item?.ngay_tao_phieu?.getDate()}/${item?.ngay_tao_phieu?.getMonth()}/${item?.ngay_tao_phieu?.getFullYear()}`,
                });
              });
              setDataTable([...tempArr]);
              setLoading(false);
            });
          break;
        default:
          break;
      }
    }
  }, [actCx.open, reload]);

  React.useEffect(() => {
    if (actVd.open === false) {
      switch (menu?.component?.toUpperCase()) {
        case "KSCDANHSACHVANDON":
          setLoading(true);
          kscClient.kscVanDonGet(new KscVanDonModel()).then((res) => {
            setDataTable(res);
            setLoading(false);
          });
          break;
        default:
          break;
      }
    }
  }, [actVd.open, reload]);

  React.useEffect(() => {
    if (actVd.open === false) {
      switch (menu?.component?.toUpperCase()) {
        case "KSCDANHSACHTAU":
          setLoading(true);
          kscClient
            .kscThongTinTauActions({
              ...new KscThongTinTauModel(),
              action_type: "GET",
            } as KscThongTinTauModel)
            .then((res) => {
              setDataTable(res);
              setLoading(false);
            });
          break;
        default:
          break;
      }
    }
  }, [actTtt.open, reload]);

  const handleClickAction = (action: RoleRightWebModel) => {
    switch (menu?.component?.toUpperCase()) {
      case "KSCDANHSACHPHIEU":
        if (action?.class_name?.toUpperCase() === "DELETE") {
          if (window.confirm("Bạn có chắc muốn xóa")) {
            ctxClient
              .ctxDanhMucXeActions({
                ...new CtxDanhMucXeModel(),
                id: itemSelected,
                tbname: "kscPhieuKiemSoat",
                action: "DELETE",
              } as CtxDanhMucXeModel)
              .then((response) => {
                Notification(
                  convert[Number(response[0].ret)],
                  response[0].message
                );
                setReload(!reload);
              });

            // window.location.reload();
          }
        } else if (action?.class_name?.toUpperCase() === "SCAN") {
          showCamera("", "");
        } else if (action?.class_name?.toUpperCase() === "PRINT") {
          setLoading(true);
          kscClient
            .inPhieuKiemSoat({
              ...new KscVanDonModel(),
              id: itemSelected,
            } as KscVanDonModel)
            .then((response) => {
              window.open(BASE_URL_API + response.path, "_blank ");
              setLoading(false);
            });
        } else {
          setActCx({
            ...actCx,
            type: action?.class_name?.toUpperCase()
              ? action?.class_name?.toUpperCase()
              : "",
            payload: itemSelected,
            open: true,
          });
        }
        break;
      case "KSCDANHSACHVANDON":
        if (action?.class_name?.toUpperCase() === "DELETE") {
          if (window.confirm("Bạn có chắc muốn xóa")) {
            setLoading(true);
            kscClient
              .kscVanDonAction({
                ...new KscVanDonModel(),
                id: itemSelected,
                action_type: "DELETE",
              } as KscVanDonModel)
              .then((response) => {
                setLoading(false);
                if (response.status === 0) {
                  notification("success", response.message);
                } else notification("error", response.message);
                setReload(!reload);
              });
          }
        } else {
          setActVd({
            ...actVd,
            type: action?.class_name?.toUpperCase()
              ? action?.class_name?.toUpperCase()
              : "",
            payload: itemSelected,
            open: true,
          });
        }
        break;
      case "KSCDANHSACHTAU":
        if (action?.class_name?.toUpperCase() === "DELETE") {
          if (window.confirm("Bạn có chắc muốn xóa")) {
            setLoading(true);
            kscClient
              .kscThongTinTauActions({
                ...new KscThongTinTauModel(),
                id: itemSelected,
                action_type: "DELETE",
              } as KscThongTinTauModel)
              .then((response) => {
                setLoading(false);
                if (response[0].status === 0) {
                  notification("success", response[0].message);
                } else notification("error", response[0].message);
                setReload(!reload);
              });
          }
        } else {
          setActTtt({
            ...actTtt,
            type: action?.class_name?.toUpperCase()
              ? action?.class_name?.toUpperCase()
              : "",
            payload: itemSelected,
            open: true,
          });
        }
        break;
    }
  };
  const handleCloseCamera = () => {
    setOpenCamera({ ...openCamera, pic: "", open: false });
  };

  const showCamera = (e: any, file: any) => {
    setOpenCamera({ ...openCamera, pic: file, open: true });
  };

  return (
    <div
      className="container-fluid p-2"
      style={{ maxHeight: "94vh", overflowY: "auto" }}
    >
      {/* <Switch> */}
      {/* <Route path={path} exact> */}
      <Dialog
        open={openCamera.open}
        onClose={handleCloseCamera}
        sx={{ height: "inherit" }}
      >
        <DialogTitle>Quét mã QR</DialogTitle>
        <DialogContent style={{ width: "400px", height: "400px" }}>
          <QrReader
            constraints={{ facingMode: "user" }}
            onResult={(result, error) => {
              if (result) {
                return setActCx({
                  ...actCx,
                  type: "DETAIL",
                  payload: result,
                  open: true,
                });
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={capture}>Chụp</Button> */}
          <Button onClick={handleCloseCamera}>Đóng</Button>
        </DialogActions>
      </Dialog>

      {React.useMemo(() => {
        return (
          <ThongTinTau
            open={actTtt.open}
            onClose={() => {
              setActTtt(defaultAction);
            }}
            itemSelected={actTtt.payload}
            action={actTtt.type}
          />
        );
      }, [actTtt.open])}

      {React.useMemo(() => {
        return (
          <CanXe
            open={actCx.open}
            onClose={() => {
              setActCx(defaultAction);
            }}
            itemSelected={actCx.payload}
            action={actCx.type}
          />
        );
      }, [actCx.open])}

      {React.useMemo(() => {
        return (
          <VanDon
            open={actVd.open}
            onClose={() => {
              setActVd(defaultAction);
            }}
            itemSelected={actVd.payload}
            action={actVd.type}
          />
        );
      }, [actVd.open])}

      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          marginTop: 15,
          marginBottom: 15,
          fontSize: 22,
          fontWeight: 550,
        }}
      >
        <i
          className="bi bi-arrow-left-short"
          style={{ cursor: "pointer" }}
          onClick={() => history.goBack()}
        ></i>
        &nbsp;{menu?.bar}
      </div>
      <DHSToolbar
        id={itemSelected}
        list={
          roles
            ? roles.filter(
              (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
            )
            : []
        }
        customMenu={customMenu}
        onClickAction={handleClickAction}
      />
      <DHSGridView
        onSelectRow={(row) => {
          selectItem(row);
        }}
        // onListSelectRow={handleListOnselectRow}
        itemClicked={function (): void {
          throw new Error("Function not implemented.");
        }}
        table_name={menu?.component ? menu.component : ""}
        show_form_list={true}
        showSelectBox={false}
        data={dataTable}
        showSortPanel={isShowSort}
        showSearchPanel={isShowSearch}
        showGroupPanel={isShowGroup}
        showFilterPanel={isShowFilter}
        defaultPageSize={2000}
        grid_name={"Danh sách phiếu kiểm soát cân"}
        excel={false}
      />
      {/* </Route> */}
      {/* {roles &&
          roles.map((role, index) => {
            return (
              role.link &&
              role.link.length > 1 &&
              role.component && (
                <Route
                  key={index}
                  path={role.is_parameter ? role.link + ":_id" : role.link}
                  component={components[role.component.toLocaleUpperCase()]}
                  exact
                ></Route>
              )
            );
          })}
      </Switch> */}
      <BlockUI blocking={isLoading} title={""}></BlockUI>
    </div>
  );
};

export default DanhSachKSC;
