import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    CtxDanhMucXeModel,
    HomeClient,
    KhoanCangClient,
    QLCCClient,
    QLCCResidentsModel,
    QLCCVehicleModel,
    QLCCVehicleTypeModel,
    RoleRightWebModel,
    SysActionType,
    SysModel,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import '../ApartmentBuilding.css';
import notification from "../../../../common/notification/notification";
import CurrencyTextField from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import VehicleTypeAction from "./VehicleTypeAction";
import DHSAutoCompleteApartment from "../../../../components/DHS/DHS_AutoCompleteApartment/DHSAutoCompleteApartment";
import DHSDateTimeComponentV2 from "../../../../components/DHS/DHS-DateTime/DHSDateTimeComponentV2";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import moment from "moment";
import { Translate } from "@mui/icons-material";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import { TransDateTime } from "../../../../components/utils/date.util";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    action_type?: string,
    title?: string,
    payload?: any,
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (data: SysModel) => void;
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

interface IAction {
    type: string;
    open: boolean;
    payload: any;
    itemSelected: any;
}
const defaultAction: IAction = {
    type: "",
    payload: null,
    open: false,
    itemSelected: null
};

const status_message = ['success', 'error']

var appSession: AppSession;

const VehicleAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    // 
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const khoangCangClient = new KhoanCangClient(appSession, BASE_URL_API);


    const [data, setData] = React.useState<QLCCVehicleModel>({} as QLCCVehicleModel);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [reload, setReload] = React.useState<boolean>(false);



    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    //
    const [vehicleTypeOptions, setVehicleTypeOptions] = React.useState<any[]>([]);
    const [vehicleStatusOptions, setVehicleStatusOptions] = React.useState<any>([]);
    const [memberApartmentOptions, setMemberApartmentOptions] = React.useState<any>([]);


    // 

    const [action, setAction] = React.useState<IProps>(props);
    const [actVt, setActVt] = React.useState<IAction>(defaultAction);

    React.useEffect(() => {

        setData(pre => ({ ...pre, apartment_id: props.payload?.apartment_id } as QLCCVehicleModel));

    }, [props.payload?.apartment_id])


    React.useEffect(() => {
        if (props.action === ACTIONS.EDIT || props.action === ACTIONS.VIEW) {
            qLCCClient
                .qLCCVehicleGet({
                    ...new QLCCVehicleModel(),
                    id: props.payload?.vehicle_id,
                    ma_cty: props?.payload?.ma_cty,
                    apartment_building_id: apartment_building_id,
                } as QLCCVehicleModel)
                .then((res) => {
                    setData(res[0]);

                })
                .catch(() => alert("Không lấy được dữ liệu"));


            setId(props.payload?.vehicle_id);
        } else {
            setData({
                ...new QLCCVehicleModel(),
                ma_cty: SessionLogin?.ma_cty,
                apartment_id: props.payload?.apartment_id,
                apartment_building_id: apartment_building_id,
                quantity: 1,
                date_add: new Date()
            } as QLCCVehicleModel);
            setId(undefined)
        };
    }, [props.action, props.payload?.apartment_building_id, apartment_building_id]);


    React.useEffect(() => {

        setLoading(true);
        khoangCangClient.kcDanhMucActions({
            ...new CtxDanhMucXeModel(),
            tbname: "QLCCVehicleStatus",
            action: "GET"
        } as CtxDanhMucXeModel)
            .then(res => {
                setVehicleStatusOptions(res)
                if (props.action === ACTIONS.ADD) {
                    setData(pre => ({
                        ...pre,
                        status_id: res[0]?.id
                    } as QLCCVehicleModel));
                }
                setLoading(false);
            });


    }, []);

    React.useMemo(() => {

        if (props.action_type === "OWNER_APARTMENT") {

            setData(pre => ({ ...pre, apartment_id: props.payload?.apartment_id } as QLCCVehicleModel));

        }


    }, [props])


    // 

    React.useEffect(() => {

        setLoading(true);
        qLCCClient.qLCCResidentsGet({
            ...new QLCCResidentsModel(),
            ma_cty: SessionLogin?.ma_cty,
            apartment_id: props.payload?.apartment_id ?? data?.apartment_id,
            apartment_building_id: apartment_building_id,
            action_type: "APARTMENT_MEMBER"
        } as QLCCResidentsModel)
            .then(res => {
                setMemberApartmentOptions(res)
                setLoading(false);
            });

    }, [data?.apartment_id, apartment_building_id])

    // Sử dụng cho chức năng thêm mới
    React.useMemo(() => {

        setLoading(true);
        qLCCClient.qLCCVehicleTypeGet({
            ...new QLCCVehicleTypeModel(),
            ma_cty: SessionLogin?.ma_cty,
            apartment_building_id: apartment_building_id,
            action_type: 'GET'
        } as QLCCVehicleTypeModel)
            .then(res => {
                setVehicleTypeOptions(res)

                setLoading(false);
            });

        // setLoading(true);
        // qLCCClient.qlccApartmentBuildingGet({
        //     ...new QLCCApartmentBuildingModel(),
        //     action_type: 'GET'
        // } as QLCCApartmentBuildingModel)
        //     .then(res => {
        //         setApartmentBuildingOptions(res)
        //         setLoading(false);
        //     });
    }, [reload,]);


    // React.useEffect(() =>{
    //     const vehicle_type = vehicleTypeOptions.find(x => x.id === data?.vehicle_type_id);
    //     setData(pre => ({...pre, amount: vehicle_type?.amount} as QLCCVehicleModel));
    // }, [data?.vehicle_type_id])


    const handleSubmit = () => {
        if (data?.status_id === 2 && !data?.date_cancel) {
            notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["DATE_CANCLE_NOT_EMPTY_WHEN_STATUS_IS_NO_USE"]);
            return;
        }


        setLoading(true)

        switch (props.action) {
            case ACTIONS.EDIT: {

                qLCCClient.qLCCVehicleUpd({
                    ...new QLCCVehicleModel(),
                    ...data,
                    date_add: data?.date_add ? TransDateTime(data?.date_add) : null,
                    date_cancel: data?.date_cancel ? TransDateTime(data?.date_cancel) : null,
                    action_type: 'UPDATE'
                } as QLCCVehicleModel)
                    .then((response) => {
                        setLoading(false);
                        notification(status_message[response.status!], response.message);
                        if (response.status === 0) {
                            props.onEditSuccess(response);
                            props.onClose();
                        }
                    });
                break;
            }

            case ACTIONS.ADD: {

                qLCCClient.qLCCVehicleIns({
                    ...new QLCCVehicleModel(),
                    ...data,
                    ma_cty: SessionLogin?.ma_cty,
                    apartment_building_id: apartment_building_id,
                    date_add: data?.date_add ? TransDateTime(data?.date_add) : null,
                    date_cancel: data?.date_cancel ? TransDateTime(data?.date_cancel) : null,
                    action_type: 'INSERT'
                } as QLCCVehicleModel)
                    .then((response) => {
                        setLoading(false);
                        notification(status_message[response.status!], response.message)

                        if (response.status === 0) {
                            props.onEditSuccess(response);
                            setId(response.ref_id)
                            props.onClose();
                            // setAction({ ...action, itemSelected: response.ref_id, action: 'EDIT' })
                        }
                    });
                break;
            }



            default:
                break;
        }

    };

    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value } as QLCCVehicleModel);
    };


    const handleChangeCurrentInput = (
        value: string | undefined, name?: string | undefined, values?: CurrencyInputOnChangeValues | undefined) => {

        setData({ ...data, [name!]: value } as QLCCVehicleModel);
    }

    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        const date = moment(value).toDate();
        if (date instanceof Date && !isNaN(date.valueOf())) {
            setData(pre => ({
                ...pre,
                [name]: new Date(value)

            } as QLCCVehicleModel));
        }
        // setData({ ...data, [name]: new Date(value) } as SoVch05M);
    }

    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                // handleSubmit();             
                handleSubmit()
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
        }
    }
    return (
        <Dialog
            id="sysMenu"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "flex-end",
            }}
            TransitionComponent={Transition}
            maxWidth='xs'
        >

            {
                actVt.open && <VehicleTypeAction
                    open={actVt.open}
                    onClose={function (): void {
                        setActVt(defaultAction);
                    }}
                    title={"Loại xe"}
                    action={actVt.type}
                    itemSelected={actVt.payload}
                    onEditSuccess={function (data_response: SysModel): void {
                        setReload(pre => !pre)

                        setData(pre => ({ ...pre, vehicle_type_id: data_response.ref_id } as QLCCVehicleModel));
                    }} >

                </VehicleTypeAction>
            }

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {`${props.title ?? menu?.bar}
                            
                            ${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === ACTIONS.EDIT || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >

                <div className="row">
                    <div className="col-4">
                        {
                            props.action_type !== "OWNER_APARTMENT" && <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel sx={{ fontSize: 14, color: '#333' }} >Căn hộ</InputLabel>
                                <DHSAutoCompleteApartment
                                    name="apartment_id"
                                    className="cc-input"
                                    value={data?.apartment_id ?? ''}
                                    onChange={(e: any, newValue: any) => {
                                        // 
                                        handleChangeInput({
                                            target: {
                                                name: "apartment_id",
                                                value: newValue.id ? newValue.id : null
                                            }
                                        })
                                    }} />

                            </Box>
                        }

                        {/*  Tên chủ xe trên cavet */}

                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Tên chủ xe trên cavet</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                // placeholder="Tên chủ xe trên cavet..."
                                name="owner_name"
                                onChange={handleChangeInput}
                                value={data?.owner_name}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box>

                        {/* Người sử dụng */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Người sử dụng</InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="status-select"
                                className="cc-input"
                                dataSource={memberApartmentOptions}
                                displayMember="full_name"
                                valueMember="id"
                                value={data?.resident_id}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "resident_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                            {/* <Autocomplete
                                id="status-select"
                                sx={{ width: "100%" }}
                                options={memberApartmentOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.full_name}
                                value={
                                    data?.resident_id
                                        ? memberApartmentOptions.find(
                                            (item: any) => item.id === data.resident_id
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "resident_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === ACTIONS.VIEW}
                            /> */}

                        </Box>

                        {/* Biển số xe */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Biển số xe</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                // placeholder="Biển số xe..."
                                name="vehicle_no"
                                onChange={handleChangeInput}
                                value={data.vehicle_no}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box>

                    </div>

                    <div className="col-4">


                        {/* Tên xe */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tên xe</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                // placeholder="Nhập tên xe"
                                name="vehicle_name"
                                onChange={handleChangeInput}
                                value={data.vehicle_name}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box>

                        {/* Loại xe */}
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Box style={{ width: "100%" }}>
                                <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Loại xe</InputLabel>
                                <DHSAutoCompleteWithDataSource
                                    id="status-select"
                                    className="cc-input"
                                    dataSource={vehicleTypeOptions}
                                    displayMember="name"
                                    valueMember="id"
                                    value={data?.vehicle_type_id}
                                    onValueChanged={(e, newValue: any) => {
                                        handleChangeInput({
                                            target: {
                                                name: "vehicle_type_id",
                                                value: newValue?.id ? newValue.id : null,
                                            },
                                        });

                                        // if(newValue?.amount){

                                        setData(pre => ({ ...pre, amount: newValue?.amount } as QLCCVehicleModel))
                                        // }
                                    }}
                                    disabled={props.action === ACTIONS.VIEW}
                                />
                                {/* <Autocomplete
                                    id="vehicle-type-select"
                                    sx={{ width: "100%" }}
                                    options={vehicleTypeOptions}
                                    autoHighlight
                                    getOptionLabel={(option: any) => option?.name}
                                    value={
                                        data?.vehicle_type_id
                                            ? vehicleTypeOptions.find(
                                                (item: any) => item.id === data.vehicle_type_id
                                            )
                                            : null
                                    }
                                    onChange={(e, newValue: any) => {
                                        handleChangeInput({
                                            target: {
                                                name: "vehicle_type_id",
                                                value: newValue?.id ? newValue.id : null,
                                            },
                                        });

                                        // if(newValue?.amount){

                                        setData(pre => ({ ...pre, amount: newValue?.amount } as QLCCVehicleModel))
                                        // }
                                    }}

                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className="cc-input"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                    disabled={props.action === ACTIONS.VIEW}
                                /> */}

                            </Box>
                            <IconButton
                                style={{ height: "24px", width: "24px", color: "#1890ff", padding: "0 1rem", transform: "translateY(12px)" }}
                                onClick={() =>
                                    setActVt({
                                        ...actVt,
                                        type: "ADD",
                                        payload: {
                                            apartment_building_id: props.payload?.apartment_building_id
                                        },
                                        open: true,
                                    })
                                }
                                disabled={props.action === ACTIONS.VIEW}
                            >
                                <AddCircleOutlinedIcon
                                    style={{ height: "inherit", width: "inherit" }}
                                />
                            </IconButton>
                        </Box>

                        {/*Chi phí */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Chi phí</InputLabel>
                            <CurrencyTextField
                                className="flex-grow-1 form-control currency-input"
                                style={{ width: "100%", textAlign: "right" }}
                                allowDecimals
                                decimalsLimit={2}
                                decimalSeparator="."
                                groupSeparator=","
                                decimalScale={0}
                                // placeholder="Chi phí"
                                intlConfig={{ locale: 'vi-VN', currency: 'VND' }}
                                name="amount"
                                onValueChange={handleChangeCurrentInput}
                                value={data.amount}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box>

                        {/* Số lượng */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số lượng</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                // placeholder="Số lượng..."
                                name="quantity"
                                onChange={handleChangeInput}
                                value={data.quantity}
                                type="number"
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box>
                    </div>

                    <div className="col-4">


                        {/* Ngày thêm */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày thêm</InputLabel>
                            <DHSDateTimeComponentV2
                                value={data?.date_add}
                                name={"date_add"}
                                onChange={handleChangeDate}
                                disabled={props.action === ACTIONS.VIEW}
                            />

                        </Box>

                        {/* Trạng thái */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Trạng thái</InputLabel>
                            <DHSAutoCompleteWithDataSource
                                id="status-select"
                                className="cc-input"
                                dataSource={vehicleStatusOptions}
                                displayMember="name"
                                valueMember="id"
                                value={data?.status_id?.toString()}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "status_id",
                                            value: newValue?.id ? parseInt(newValue?.id) : null,
                                        },
                                    });
                                }}
                                disabled={props.action === ACTIONS.VIEW}
                            />
                            {/* <Autocomplete
                                id="status-select"
                                sx={{ width: "100%" }}
                                options={vehicleStatusOptions}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    data?.status_id
                                        ? vehicleStatusOptions.find(
                                            (item: any) => item.id === data.status_id?.toString()
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "status_id",
                                            value: newValue?.id ? parseInt(newValue?.id) : null,
                                        },
                                    });
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={props.action === ACTIONS.VIEW}
                            /> */}

                        </Box>

                        {/* Ngày hủy */}
                        {
                            data?.status_id === 2 && <Box style={{ width: "100%", margin: "5px 0px" }}>
                                <InputLabel required sx={{ fontSize: 14, color: '#333' }}>Ngày hủy</InputLabel>
                                <DHSDateTimeComponentV2
                                    value={data?.date_cancel}
                                    name={"date_cancel"}
                                    onChange={handleChangeDate}
                                    disabled={props.action === ACTIONS.VIEW}
                                />

                            </Box>
                        }

                        {/* Ghi chú */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ghi chú</InputLabel>
                            <textarea
                                name="notes"
                                // placeholder="Ghi chú"
                                value={data?.notes ?? ""}
                                className="cc-input p-2"
                                style={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                rows={4}
                                onChange={handleChangeInput}
                            >

                            </textarea>

                        </Box>




                    </div>
                </div>





            </DialogContent>
            <DialogActions>
                <DHSDialogAction

                    action={props.action}
                    menu={menu}
                    onClickAction={handleClickAction}
                />
                {/* {props.action !== ACTIONS.VIEW && (
                    <Button variant="contained" onClick={handleSubmit}>
                        Lưu
                    </Button>
                )}
                <Button onClick={props.onClose}>Hủy</Button> */}
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default VehicleAction;


