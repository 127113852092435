import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, Divider, IconButton, InputLabel, MenuItem, Select, Slide, TextField, Toolbar, Typography } from '@mui/material'
import React from 'react'
import BetweenTwoDate from '../../report/cash-book-report/component/between-two-date-X'
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { QLCCApartmentBuildingModel, QLCCClient, ReportInfo, SysActionType } from '../../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../../shared/service-proxies/config';
import { AppSession } from '../../../shared/app-session/app-session';
import DHSDialogAction from '../../../../components/DHS/DHS_DialogAction/DialogAction';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from '../../../../components/layouts';
import { ACTIONS } from '../../../../common/enums/DHSToolbarRoleAction';
import { callApiToken } from '../../../../routes/configApi';
import { setApartmentBuilding } from '../../../../redux/actions/ApartmentBuilding/ApartmentBuildingAction';
import { CALL_API_LOAD_APARTMENT_BUILDING_LIST } from '../../apartment-building/apartment-building-list';
import DHSTextBox from '../../../../components/DHSComponents/editors/DHSTextBox/DHSTextBox';

var appSession: AppSession;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


interface IProps {
    isOpen: boolean;
    setIsOpen: (data: boolean) => void;
    setReload: () => void;
    data: any;
    reportInfo: any;
    setData: (data: any) => void;
    setReportInfo: (data: any) => void
}

const SearchOperation = (props: IProps) => {

    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    const {
        isOpen,
        setIsOpen,
        reportInfo,
        setReload,
    } = props;
    const [reportInfoList, setReportInfoList] = React.useState<any>([]);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    // const [apartmentBuildings, setApartmentBuildings] = React.useState<QLCCApartmentBuildingModel[]>([] as QLCCApartmentBuildingModel[]);
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const Token = {
        Token: SessionLogin.Token
    }
    const [fromDate, setFromDate] = React.useState<Date>(
        new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1, 0, 0, 0))
    );
    const [toDate, setToDate] = React.useState<Date>(
        new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 0, 23, 0, 0))
    );

    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
    const dispatch = useDispatch();

    React.useEffect(() => {
        // CALL_API_LOAD_APARTMENT_BUILDING_LIST().then(res => {
        //     setApartmentBuildings(res);
        // })
        callApiToken("api/Common/ReportInfoGet", "POST", {
            menuid: menu?.menuid,
            language: "vi-VN",
            apartment_building_id: apartment_building_id,
            ma_cty: SessionLogin.ma_cty,
        }, Token)
            .then(response => {
                setReportInfoList(response.data);
                if (response.data.length > 0) {
                    props.setReportInfo({
                        ...reportInfo,
                        ...response.data[0],
                        header: response.data[0]?.formateD_COL_LIST.split(',')
                    } as ReportInfo);
                } else {
                    props.setReportInfo({} as ReportInfo);
                }
            }).catch(error => {
                console.log(error)
            })
    }, [apartment_building_id])

    React.useEffect(() => {
        if (props.isOpen) {
            if (props.data && props.data.apartment_building_id && props.data.apartment_building_id !== apartment_building_id) {
                props.setData({
                    ...props.data,
                    apartment_building_id: apartment_building_id
                })
            }
        }
    }, [props.isOpen])

    const handleClickAction = (action: string) => {
        switch (action.toLocaleUpperCase()) {
            case ACTIONS.OK:
                handleSubmit();
                break;
            case ACTIONS.CLOSE:
                setIsOpen(false);
                break;
        }
    }
    const handleChangeSelect = (e: any) => {
        const { name, value } = e.target;
        props.setData({
            ...props.data,
            [name]: value
        })
    }

    const handleSubmit = async () => {
        props.setData({
            ...props.data,
            ngay_Ct1: new Date(fromDate),
            ngay_ct2: new Date(toDate),
        });
        if (props.data && props.data.apartment_building_id && props.data.apartment_building_id !== apartment_building_id) {
            dispatch(setApartmentBuilding({ id: props.data.apartment_building_id } as QLCCApartmentBuildingModel ?? ''))
        }
        setReload();
        setIsOpen(false);
    }

    const handleChange = async (e: any) => {
        const { name, value } = e.target;
        props.setReportInfo({
            ...reportInfo,
            [name]: value,
        })
    }

    return (
        <Dialog
            id='sysMenu3'
            open={isOpen}
            TransitionComponent={Transition}
            onClose={() => setIsOpen(false)}
            aria-describedby="alert-dialog-slide-description"
            className='d-flex justify-content-end'
            keepMounted
        >
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        Lọc báo cáo
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setIsOpen(false)}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>

                {
                    /*
                <Box className='w-100 mt-2'>
                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Dự án</InputLabel>
                    <Select
                        className="cc-input"
                        sx={{
                            width: "100%",
                            marginRight: "5px",
                        }}
                        placeholder="Dự án"
                        value={props.data.apartment_building_id || ""}
                        name='apartment_building_id'
                        onChange={handleChangeSelect}
                    >
                        {
                            apartmentBuildings
                            && apartmentBuildings.map(item => (
                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                            ))
                        }
                    </Select>
                </Box>
                */
                }

                {/* Mẫu báo cáo */}
                <Box className='w-100 mt-2'>
                    <InputLabel className='cc-label'>Mẫu báo cáo</InputLabel>
                    <TextField
                        className='cc-input'
                        select
                        style={{ width: '100%' }}
                        name='spname'
                        value={props.reportInfo?.spname || null}
                        onChange={handleChange}
                    >
                        {
                            reportInfoList
                            && reportInfoList.map((row: any) => (
                                <MenuItem key={row.spname} value={row.spname}>{row.ten_mau}</MenuItem>
                            ))
                        }
                    </TextField>
                </Box>
                {/* Tiêu đề */}
                <Box className='w-100 mt-2'>
                    <InputLabel className='cc-label'>Tiêu đề</InputLabel>
                    <DHSTextBox
                        value={props.reportInfo?.ten_mau}
                    />
                    {/* <TextField
                        className='cc-input'
                        style={{ width: '100%' }}
                        value={props.reportInfo?.ten_mau}
                    /> */}
                </Box>
                {/* Giữa 2 ngày */}
                <Box className='w-100 mt-2'>
                    <BetweenTwoDate
                        fromDate={(newDate: any) => setFromDate(newDate)}
                        toDate={(newDate: any) => setToDate(newDate)}
                        reload={() => setReload()}
                    />
                </Box>
            </DialogContent>
            <DialogActions>


                <Button
                    variant='outlined'
                    onClick={() => handleClickAction(ACTIONS.CLOSE)}
                >
                    Hủy
                </Button>
                <Button
                    variant='contained'
                    onClick={() => handleClickAction(ACTIONS.OK)}
                >
                    Thực hiện
                </Button>

            </DialogActions>
        </Dialog>
    )
}

export default SearchOperation