import moment from 'moment';
import { useSelector } from 'react-redux';
import Button from 'devextreme-react/button'
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from 'react'
import { httpPost } from '../../../../common/httpService';
import { TransitionProps } from '@mui/material/transitions'
import BASE_URL_API from '../../../shared/service-proxies/config'
import { HintCode } from '../../../../common/enums/DHSInputDetail';
import DHSSelectBox from '../../../../components/DHS/DHS_SelectBox'
import { AppSession } from '../../../shared/app-session/app-session'
import { IReducer, Notification } from '../../../../components/layouts';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../../common/Interfaces/StatusMessage';
import TaskCheckListNgayCT from '../../actions/work/task-check-list-ngay-ct/task-check-list-ngay-ct';
import DHSDateTimeComponentV2 from '../../../../components/DHS/DHS-DateTime/DHSDateTimeComponentV2'
import { AppBar, Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputLabel, Slide, Toolbar, Typography } from '@mui/material'
import { DepartmentClient, HomeClient, HomePageClient, LstDepartment, ParaObjTableList, QLCCApartmentBuildingModel, QLCCClient, QLCCTaskGroupCycleModel, QLCCTaskModel, QLCCTaskStatusModel, UserInfoModel } from '../../../shared/service-proxies/api-shared'

interface IProps {
    action_type: 'taskScript' | 'taskList' | 'checkList' | 'taskGantt',
    setData?: (data: QLCCTaskModel) => void,
    setApartmentBuilding?: (data: string) => void;
    handleSearch: (dataSourceTask: QLCCTaskModel[], dataSourceUser: UserInfoModel[]) => void,
    open: boolean,
    onClose: () => void,
    type?: 'FILTER' | 'FILTER_DM' | 'TOTAL_TIME_HOUR' | 'FILTER_USER'
}

const typeItem = {
    taskScript: "2",
    taskList: "1",
    checkList: "3",
    taskGantt: "1"
}

var appSession: AppSession;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const TaskSearcherForReport = (props: IProps) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const qlccClient = new QLCCClient(appSession, BASE_URL_API);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qlccHomePage = new HomePageClient(appSession, BASE_URL_API);
    // const departmentClient = new DepartmentClient(appSession, BASE_URL_API);

    const [data, setData] = useState<QLCCTaskModel>({
        ...new QLCCTaskModel(),
        start_time: moment().startOf('month').toDate(),
        end_time: moment().endOf('month').toDate(),
        ngay_ct: moment().utc(true).toDate(),
    } as QLCCTaskModel)

    // const [apartmentBuildings, setApartmentBuildings] = useState<QLCCApartmentBuildingModel[]>([]);
    const [departments, setDepartments] = useState<LstDepartment[]>([]);
    const [users, setUsers] = useState<UserInfoModel[]>([]);
    const [taskStatus, setTaskStatus] = React.useState<QLCCTaskStatusModel[]>([]);
    const [Cycle, setCycle] = React.useState<QLCCTaskGroupCycleModel[]>([])
    const [check, setCheck] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
    const options = [
        {
            id: "cdate",
            title: "Ngày tạo"
        },
        {
            id: "date_done",
            title: "Ngày hoàn thành"
        },
        {
            id: "ngay_ct",
            title: "Ngày báo cáo"
        },
    ]
    const [option, setOption] = React.useState<string>();

    useEffect(() => {
        (async () => {

            setLoading(true);

            const body = {
                ... new ParaObjTableList(),
                tablelist: 'dm_bp'
            } as ParaObjTableList

            httpPost<string>('/api/Common/LoadDatasourceBindControlByApartmentBuilding', {
                ma_cty: SessionLogin?.ma_cty,
                tablelist: body?.tablelist,
                apartment_building_id: apartment_building_id
            })
                .then(res => {
                    // console.log(JSON.parse(res.data))
                    const serviceGroups: any = JSON.parse(res.data)
                    if (serviceGroups) {
                        const dataDepartment = serviceGroups?.Table1;

                        if (dataDepartment) {
                            setDepartments([...dataDepartment])
                            const department1 = dataDepartment[0].ma_bp;

                            if (department1) {
                                setData({
                                    ...data,
                                    status_code: department1
                                } as QLCCTaskModel)
                            }
                        }
                    }
                })
                .catch((ex) => {
                    console.log(ex)
                })

            // httpPost<string>('api/Common/LoadFilterList', body)
            //     .then(async result => {
            //         const dataDepartment = JSON.parse(result.data);

            //         if (dataDepartment) {
            //             setDepartments([...dataDepartment])
            //             const department1 = dataDepartment[0].ma_bp;

            //             if (department1) {
            //                 setData({
            //                     ...data,
            //                     status_code: department1
            //                 } as QLCCTaskModel)
            //             }
            //         }
            //     });

            const ResApartmentBuilding = await qlccClient.qlccApartmentBuildingGet({
                ...new QLCCApartmentBuildingModel(),
                action_type: "GET",
                ma_cty: SessionLogin.ma_cty,
                apartment_building_id: apartment_building_id,
            } as QLCCApartmentBuildingModel);

            const ResStatus = await qlccClient.qLCCTaskStatusGet({
                ...new QLCCTaskStatusModel(),
                ma_cty: SessionLogin.ma_cty,
                apartment_building_id: apartment_building_id,
            } as QLCCTaskStatusModel);

            const ResCycle = await qlccClient.qLCCTaskGroupCycleGet({
                ... new QLCCTaskGroupCycleModel(),
                ma_cty: SessionLogin.ma_cty,
                apartment_building_id: apartment_building_id,
                action_type: "GET"
            } as QLCCTaskGroupCycleModel)

            if (ResStatus) {
                setTaskStatus(ResStatus);
            }

            if (ResApartmentBuilding) {
                // setApartmentBuildings(ResApartmentBuilding);
            }

            if (ResCycle) {
                setCycle(ResCycle);
            }

            const apartmentBuildingFirstItem: QLCCApartmentBuildingModel = ResApartmentBuilding[0];
            const cycleCode1: QLCCTaskGroupCycleModel = ResCycle[2];
            const statusCode1 = ResStatus[0].stt;

            try {
                setData({
                    cycle_id: cycleCode1.id,
                    cycle_code: cycleCode1.code,
                    apartment_building_id: apartment_building_id,
                    status_code: statusCode1?.toString(),
                    type_code: typeItem[`${props.action_type}`]
                } as QLCCTaskModel)
            } catch {
                alert('du lieu khong du')
            }
            setCheck(pre => !pre)
            setLoading(false);
        })();

        setOption("ngay_ct");

    }, [])

    useEffect(() => {
        if (check) {
            handleSearch();
        }
    }, [check])

    async function onLoadDataSourceUser(department_id_params?: string | undefined) {
        // if (data.department_id !== undefined || department_id_params !== undefined) {
        const dataUsers = await qlccHomePage.userInfoWebFilterAdmin({
            ... new UserInfoModel(),
            ma_cty: SessionLogin.ma_cty,
            apartment_building_id: apartment_building_id,
            department_id: department_id_params ? department_id_params : data.department_id,
            user_login: SessionLogin.userName
        } as UserInfoModel);

        if (dataUsers.length > 0) {
            setUsers([...dataUsers]);
        }
        // }
    }

    useEffect(() => {
        onLoadDataSourceUser();
    }, [])

    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        setData({
            ...data,
            [name]: value
        } as QLCCTaskModel);
    }

    const handleChange = async (e: any) => {
        const { name, value } = e.target;
        switch (name) {
            case 'department_id':
                onLoadDataSourceUser(value);
                // đặt lại data
                setData({
                    ...data,
                    [name]: value
                } as QLCCTaskModel);
                break;
            case 'cycle_id':
                const cycle_code_ = Cycle.find(item => item.id === value);
                if (cycle_code_) {
                    setData({
                        ...data,
                        [name]: value,
                        cycle_code: cycle_code_.code
                    } as QLCCTaskModel);
                }
                break;
            default:
                setData({
                    ...data,
                    [name]: value
                } as QLCCTaskModel);
        }
    }

    const handleSearch = async () => {
        const status = taskStatus.find(item => item.id === data.status_id);
        const newData = {
            ...new QLCCTaskModel(),
            ...data,
            status_code: status && status.stt ? status.stt.toString() : data.status_code,
            type_code: data?.type_code ? data.type_code : typeItem[`${props.action_type}`],
            ma_cty: SessionLogin.ma_cty,
            username_login: props.action_type === 'taskGantt' ? data.receiver && data.receiver !== '' ? data.receiver : SessionLogin.userName : SessionLogin.userName,
            follower: SessionLogin.userName,  // mới
            action_type: props.type ? props.type : data.receiver && data.receiver !== '' ? 'FILTER_USER' : 'FILTER'
        } as QLCCTaskModel;

        const lstUser = [...users]; // danh sách tài khoản

        // console.log(newData)

        const Res = await qlccClient.qLCCTaskGet({
            ...new QLCCTaskModel(),
            ...newData,
            ngay_ct: option === 'ngay_ct' ? props.action_type !== 'taskScript' ? moment(newData.ngay_ct).utc(true).toDate() : undefined : undefined,
            cdate: option === 'cdate' ? props.action_type !== 'taskScript' ? moment(newData.ngay_ct).utc(true).toDate() : undefined : undefined,
            date_done: option === 'date_done' ? props.action_type !== 'taskScript' ? moment(newData.ngay_ct).utc(true).toDate() : undefined : undefined,
            start_time: props.action_type !== 'taskScript' ? moment(newData.start_time).utc(true).toDate() : undefined,
            end_time: moment(newData.end_time).utc(true).toDate(),
        } as QLCCTaskModel);


        // Danh sách user muốn tìm kiếm
        if (newData.receiver) {
            const findUserInfo = users.find(item => item.username === newData.receiver);
            if (findUserInfo) {
                lstUser.length = 0;
                lstUser.push(findUserInfo)
            }
        }

        try {
            // console.log(Res)
            // console.log(lstUser)
            props.handleSearch(Res, lstUser);
        }
        catch (e) {
            // console.log(e);
            Notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE[`TRY_ANOTHER`]);
        }

        if (props.setData) {
            props.setData({
                ...newData,
                ngay_ct: props.action_type !== 'taskScript' ? moment(newData.ngay_ct).utc(true).toDate() : undefined,
                start_time: props.action_type !== 'taskScript' ? moment(newData.start_time).utc(true).toDate() : undefined,
                action_type: 'FILTER'
            } as QLCCTaskModel)
        }
    }

    useEffect(() => {
        (async () => {
            if (data.apartment_building_id) {
                if (props.setApartmentBuilding) {
                    props.setApartmentBuilding(data.apartment_building_id);
                }
            }
        })();
    }, [data.apartment_building_id])

    return (

        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.onClose}
                aria-describedby="alert-dialog-slide-description"
                className='d-flex justify-content-end'
                fullScreen
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFF",
                        color: "#1890ff",
                        boxShadow: 0,
                        minWidth: '400px'
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ flex: 1, fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            Tìm kiếm
                        </Typography>
                        <IconButton edge="end" color="error" onClick={props.onClose} aria-label="close"                        >
                            <CloseIcon sx={{ color: '#1f2937' }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {/* <Box className='w-100'>
                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Dự án</InputLabel>
                        <DHSSelectBox
                            dataSource={apartmentBuildings}
                            name="apartment_building_id"
                            onValueChanged={handleChange}
                            displayExpr="name"
                            searchExpr="name"
                            valueExpr="id"
                            value={data?.apartment_building_id}
                            clearBtn={true}
                            hint={HintCode.APARTMENT_BUILDING}
                        // disabled={disable}
                        />
                    </Box> */}
                    {
                        props.action_type === 'taskGantt' && (
                            <Box className='w-100'>
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Loại công việc</InputLabel>
                                <DHSSelectBox
                                    dataSource={[{ code: "1", name: "Công việc phát sinh" }, { code: "3", name: "Báo cáo tiến độ hằng ngày" }]}
                                    name="type_code"
                                    onValueChanged={handleChange}
                                    displayExpr="name"
                                    searchExpr="name"
                                    valueExpr="code"
                                    value={data?.type_code}
                                    clearBtn={false}
                                    // disabled={disable}
                                    hint={HintCode.TASK_TYPE}
                                />
                            </Box>
                        )
                    }
                    <Box className='w-100'>
                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Bộ phận</InputLabel>
                        <DHSSelectBox
                            dataSource={departments}
                            name="department_id"
                            onValueChanged={handleChange}
                            displayExpr="ten_bp"
                            searchExpr="ten_bp"
                            valueExpr="ma_bp"
                            value={data?.department_id}
                            clearBtn={true}
                            // disabled={disable}
                            hint={HintCode.DEPARTMENT}
                        />
                    </Box>
                    {
                        props.action_type !== 'taskScript'
                        && <>
                            <Box className='w-100'>
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Nhân viên</InputLabel>
                                <DHSSelectBox
                                    dataSource={users}
                                    name="receiver"
                                    onValueChanged={handleChange}
                                    displayExpr="fullname"
                                    searchExpr="fullname"
                                    valueExpr="username"
                                    value={data?.receiver}
                                    clearBtn={true}
                                    // disabled={disable}
                                    hint={HintCode.TASK_RECEIVER}
                                />
                            </Box>
                            <Box className='w-100'>
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Trạng thái</InputLabel>
                                <DHSSelectBox
                                    dataSource={taskStatus}
                                    name="status_id"
                                    onValueChanged={handleChange}
                                    displayExpr="name"
                                    searchExpr="name"
                                    valueExpr="id"
                                    value={data?.status_id}
                                    clearBtn={true}
                                    hint={HintCode.TASK_STATUS}
                                />
                            </Box>
                            <Box className='w-100'>
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tùy chỉnh</InputLabel>
                                <DHSSelectBox
                                    dataSource={options}
                                    name="option"
                                    onValueChanged={(e: any) => setOption(e.target.value)}
                                    displayExpr="title"
                                    searchExpr="title"
                                    valueExpr="id"
                                    value={option}
                                    hint={HintCode.TASK_OPTION_FIND}
                                />
                            </Box>
                            <Box className='w-100'>
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Chu kỳ công việc</InputLabel>
                                <DHSSelectBox
                                    dataSource={Cycle}
                                    name="cycle_id"
                                    onValueChanged={handleChange}
                                    displayExpr="name"
                                    searchExpr="name"
                                    valueExpr="id"
                                    value={data?.cycle_id}
                                    hint={HintCode.TASK_CYCLE}
                                />
                            </Box>
                            <Box className='w-100'>
                                <TaskCheckListNgayCT
                                    cycle_code={data.cycle_code ? data.cycle_code : 1}
                                    value={data.ngay_ct}
                                    // value={Object.values(data)[Object.keys(data).indexOf(option ? option : '')]}
                                    setValue={(value: Date) => {
                                        setData({
                                            ...data,
                                            ngay_ct: value
                                        } as QLCCTaskModel)
                                    }}
                                    onSubmit={(e: any) => {
                                        setData({
                                            ...data,
                                            start_time: e.startTime,
                                            end_time: e.endTime
                                        } as QLCCTaskModel)
                                    }}
                                    case="SET_DATA"
                                />
                            </Box>
                            <Box className="w-100">
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Từ ngày</InputLabel>
                                <DHSDateTimeComponentV2
                                    name="start_time"
                                    value={data?.start_time}
                                    type='datetime'
                                    onChange={(e: any) => {
                                        handleChangeDate(e)
                                    }}
                                    disabled={false}
                                    maxDate={data?.end_time}
                                    hint={HintCode.TASK_START_TIME}
                                    applyValueMode='useButtons'
                                />
                            </Box>
                            <Box className="w-100">
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Đến ngày</InputLabel>
                                <DHSDateTimeComponentV2
                                    name="end_time"
                                    type="datetime"
                                    value={data?.end_time}
                                    onChange={(e: any) => {
                                        handleChangeDate(e)
                                    }}
                                    disabled={false}
                                    minDate={data?.start_time}
                                    hint={HintCode.TASK_END_TIME}
                                />
                            </Box>
                        </>
                    }

                </DialogContent>
                <DialogActions>
                    <Button
                        width={120}
                        text="Tìm Kiếm"
                        type="default"
                        stylingMode="contained"
                        onClick={handleSearch}
                        hint={HintCode.BUTTON_FIND}
                    />
                    <Button width={120} text="Đóng" type="normal" stylingMode="contained" onClick={props.onClose} hint={HintCode.BUTTON_CLOSE} />
                </DialogActions>
            </Dialog>
            {/* <BlockUI blocking={loading} title={"Vui lòng chờ"}></BlockUI> */}

        </div>
    )
}

export default TaskSearcherForReport