import { Grid } from "@mui/material";
import React, { Ref } from "react"
import DHSSelectBox from "../../DHSComponents/editors/DHSSelectBox/DHSSelectBox";
import DHSDateBox from "../../DHSComponents/editors/DHSDateBox/DHSDateBox";
import { SYS_Token_AuthenticationModel } from "../../../app/shared/service-proxies/api-shared";
import { ValueChangedInfo } from "devextreme/ui/editor/editor";
import dxDateBox from "devextreme/ui/date_box";
import { NativeEventInfo } from "devextreme/events";
import moment from "moment";
import _ from "lodash";
import { SelectBoxInstance } from "devextreme/ui/select_box";
import { getTimeValues } from "./functions/function";
import ObjectUtility from "../../../utils/object.util";
import { useForwardRef } from "../../utils/hooks/useForwardRef";
import { useSelector } from "react-redux";
import { IReducer } from "../../layouts";


interface IDataSelect {
    cycle?: number,
    year?: Date,
    fullYear?: number,
    quarter?: number,
    month?: number,
    week?: number
}
interface IData {
    dataSelect: IDataSelect,
    timeData: ITimeData,
    timeDataProps: ITimeData,
    countT: number
}

interface ITimeData {
    time_from?: Date,
    time_to?: Date
}


interface IQuater {
    quarter?: number,
    dateByQuaterStart?: Date,
    dateByQuaterEnd?: Date,
}
interface IMonth {
    month?: number,
    dateByMonthStart?: Date,
    dateByMonthEnd?: Date,
}

interface IWeek {
    week?: number,
    weekByYearStart?: Date,
    weekByYearEnd?: Date,
}

interface ICycle {
    code: number,
    name: string,
    code_2: string
}

interface IRef {
    getTimeData: () => ITimeData
}

const defaultCycle: ICycle[] = [
    { code: 9, name: 'Hôm nay', code_2: 'now' },
    { code: 10, name: 'Hôm qua', code_2: 'yesterday' },
    { code: 11, name: 'Tuần này', code_2: 'thisWeek' },
    { code: 1, name: 'Theo ngày', code_2: 'date' },
    { code: 2, name: 'Theo tuần', code_2: 'week' },
    { code: 3, name: 'Theo tháng', code_2: 'month' },
    { code: 4, name: 'Theo quý', code_2: 'quarter' },
    { code: 5, name: 'Theo Năm', code_2: 'year' },
    { code: 7, name: '6 Tháng đầu năm', code_2: 'sixMonthsFirst' },
    { code: 8, name: '6 Tháng cuối năm', code_2: 'sixMonthsSecond' },
    { code: 6, name: 'Tùy chỉnh', code_2: 'date' },
]


interface IProps {
    defaultDataType?: "obtained" | "unavailable",
    /** default show cycle by code (either single or multiple) */
    defaultShowCycles?: number[],
    /** default cycle (default on selectBox) */
    defaultCycle?: number,
    /** the main data includes time_from and time_to */
    timeData: ITimeData,
    /** the event for main data change */
    onChangeTimeData?: (timeData?: ITimeData) => void,
    /**
     * type for main data
     * - datetime "return" time_from (00:00:00) - time_to (29:59:59)
     * - date "return" time_from (00:00:00) - time_to (00:00:00)
     */
    type: "datetime" | "date",
    /**
     * display format for time from and time to
     */
    displayFormat?: 'dd/MM/yyyy' | "yyyy-MM-dd HH:mm:ss"
}


const visiableCycleByDate = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const visiableCycleByYear = [2, 3, 4, 5, 7, 8]
const visiableCycleByQuarter = [4]
const visiableCycleByMonth = [3]
const visiableCycleByWeek = [2, 11]
/**
 * Component DHSDateFilterV2
 *
 * @interface IProps
 * @default Data source:
 * - 2/ Theo tuần
 * - 3/ Theo tháng
 * - 4/ Theo quý
 * - 5/ Theo năm
 * - 6/ Tùy chỉnh
 * - 7/ 6 tháng đầu năm
 * - 8/ 6 tháng cuối năm
 * - 9/ Hôm nay
 * - 10/ Hôm qua
 * - 11/ Tuần nay    
 * 
 */
const DHSDateFilterV2 = React.memo(React.forwardRef<IRef, React.PropsWithChildren<IProps>>((props, ref) => {

    const sessionLogin: SYS_Token_AuthenticationModel = JSON.parse(
        localStorage.getItem("SessionLogin") || "{}"
    );
    const language = 'vi-VN'; // Hi hi ha ha 

    const [data, setData] = React.useState<IData>();
    // 

    const [quarter, setQuater] = React.useState<IQuater[]>();
    const [month, setMonth] = React.useState<IMonth[]>();
    const [week, setWeek] = React.useState<IWeek[]>();

    // 

    const { hours, minutes, seconds } = getTimeValues(props.type);

    // 

    const [cycle, setCycle] = React.useState<ICycle[]>(defaultCycle)
    // 
    const defaultDataType = React.useRef<"obtained" | "unavailable">(props?.defaultDataType ?? "unavailable")

    const preTimeData = React.useRef<IData | null>(null);



    React.useEffect(() => {

        if (props?.defaultShowCycles) {
            const tempCycle = [...cycle].filter(x => props.defaultShowCycles?.includes(x.code));
            setCycle(tempCycle)

        }

    }, [props?.defaultShowCycles])


    // React.useEffect(() => {
    //     setData(pre => ({
    //         ...pre,
    //         dataSelect: {
    //             ...pre?.dataSelect,
    //             cycle: props.defaultCycle
    //         }
    //     } as IData))

    // }, [props.defaultCycle]);

    // React.useEffect(() => {
    //     if (defaultDataType.current === "unavailable") {
    //         setData(pre => ({
    //             ...pre,
    //             dataSelect: {
    //                 ...pre?.dataSelect,
    //                 cycle: props.defaultCycle
    //             }
    //         } as IData))
    //     }

    // }, [props.defaultCycle, defaultDataType]);


    React.useEffect(() => {



        if (props?.timeData?.time_from && props?.timeData?.time_to) {
            // Kiểm tra có bằng nhau hay không

            const fullYear = props?.timeData?.time_from?.getFullYear();
            const year = new Date(fullYear, 0, 1);
            console.log(year, "year")
            // preTimeData.current = _.cloneDeep(Object.assign({}, {...props?.timeData}));

            // setData(pre => {
            const { timeData, ...dataRef } = preTimeData.current ?? {} as IData;


            const time_from = timeData?.time_from ? moment(timeData?.time_from).format("DD/MM/yyyy") : undefined;
            const time_to = timeData?.time_to ? moment(timeData?.time_to).format("DD/MM/yyyy HH") : undefined;
            const time_from_props = props?.timeData?.time_from ? moment(props?.timeData?.time_from).format("DD/MM/yyyy") : undefined;
            const time_to_props = props?.timeData?.time_to ? moment(props?.timeData?.time_to).format("DD/MM/yyyy HH") : undefined;
            // 

            const time_from_month = props?.timeData?.time_from?.getMonth();
            const isEqual = _.isEqual(time_from_props, time_from) &&
                _.isEqual(time_to_props, time_to)
            // 


            if (!isEqual) {
                const time_to = _.cloneDeep(props?.timeData?.time_to);
                time_to?.setHours(hours, minutes, seconds);
                preTimeData.current = {
                    ...dataRef,
                    dataSelect: {
                        ...dataRef?.dataSelect,
                        cycle: dataRef?.dataSelect?.cycle ?? props.defaultCycle,
                        fullYear: dataRef?.dataSelect?.fullYear !== fullYear ? fullYear : dataRef?.dataSelect?.fullYear,
                        year: dataRef?.dataSelect?.fullYear !== fullYear ? year : dataRef?.dataSelect?.year,
                        quarter: dataRef?.dataSelect?.cycle === 4 ? Math.floor(time_from_month! / 3) : dataRef?.dataSelect?.quarter,
                        month: dataRef?.dataSelect?.cycle === 3 ? time_from_month : dataRef?.dataSelect?.month,
                        week: dataRef?.dataSelect?.cycle === 2 ? getWeekNumber(props?.timeData?.time_from!) : dataRef?.dataSelect?.week
                    },
                    // ...ObjectUtility.isNullOrUndefinedOrEmpty(dataRef.dataSelect?.cycle) && ({
                    timeData: {
                        time_from: props?.timeData?.time_from,
                        time_to: time_to,
                    }
                    // })
                } as IData
                // return preTimeData.current
                setData({
                    ...preTimeData.current,
                    ...ObjectUtility.isNullOrUndefinedOrEmpty(dataRef?.dataSelect?.cycle) && ({
                        timeData: {
                            time_from: props?.timeData?.time_from,
                            time_to: time_to,
                        }
                    })
                } as IData);
            }
            // return pre;

            // });
        }


        else if (!props?.timeData?.time_from && !props?.timeData?.time_to) {

            const fullYear = sessionLogin.nam_tc!;
            const month = new Date().getMonth();
            const date = new Date().getDate();
            const year = new Date(fullYear, month, date, 0, 0, 0);
            // 
            loadDateInfoForYear(fullYear)
            // 
            setData(pre => {
                preTimeData.current = {
                    ...pre,
                    dataSelect: {
                        ...(pre?.dataSelect), // Giữ nguyên các giá trị trong dataSelect của pre
                        ...(defaultDataType.current === "unavailable" && { // Nếu defaultDataType.current là "obtained" thì thêm các giá trị mới
                            fullYear: fullYear,
                            year: year, // Giả sử year là một giá trị được khai báo trước đó
                            cycle: pre?.dataSelect?.cycle ?? props?.defaultCycle // Thiết lập giá trị cho cycle

                        }),
                    },
                    ...(defaultDataType.current === "unavailable" && ({
                        timeData: {
                            time_from: new Date(fullYear, month, date, 0, 0, 0)
                        }
                    }))
                } as IData; // Ép kiểu thành IData nếu cần thiết
                return preTimeData.current;
            });


        }


        console.log(props.timeData?.time_from?.toISOString(), "year in timeFrom")


    }, [props.timeData?.time_from, props.timeData?.time_to, props?.defaultCycle, defaultDataType, hours, minutes, seconds]);

    // React.useEffect(() => {
    //     console.log(data?.timeData?.time_from?.toISOString(), "time_from")
    //     console.log(data?.timeData?.time_to?.toISOString(), "time_to")
    //     // setData(pre => {

    //     const time_from = data?.timeData?.time_from ? moment(data?.timeData?.time_from).format("DD/MM/yyyy") : undefined;
    //     const time_to = data?.timeData?.time_to ? moment(data?.timeData?.time_to).format("DD/MM/yyyy HH") : undefined;
    //     const time_from_props = props?.timeData?.time_from ? moment(props?.timeData?.time_from).format("DD/MM/yyyy") : undefined;
    //     const time_to_props = props?.timeData?.time_to ? moment(props?.timeData?.time_to).format("DD/MM/yyyy HH") : undefined;
    //     const isEqual = _.isEqual(time_from, time_from_props) && _.isEqual(time_to, time_to_props)

    //     // Kiểm tra có bằng nhau hay không
    //     if (time_from && time_to && time_from_props && time_to_props && props.onChangeTimeData && !isEqual) {
    //         // alert(data?.timeData?.time_from?.toISOString())
    //         // props.onChangeTimeData({ time_from: data?.timeData?.time_from, time_to: data?.timeData?.time_to } as ITimeData);
    //         // preTimeData.current = _.cloneDeep(Object.assign({}, {
    //         //     time_from: _.cloneDeep(data?.timeData?.time_from),
    //         //     time_to: _.cloneDeep(data?.timeData?.time_to)
    //         // } as ITimeData));

    //         console.log(preTimeData.current, data?.timeData, "ref in v2")

    //     }

    //     //     return pre;
    //     // })


    // }, [data?.timeData?.time_from, data?.timeData?.time_to, props?.timeData?.time_from, props?.timeData?.time_to])


    const onChangeTimeData = React.useCallback((timeData: ITimeData) => {
        props?.onChangeTimeData && props.onChangeTimeData(timeData)
    }, [])

    React.useImperativeHandle(ref, () => ({
        getTimeData: () => {
            return { ...data?.timeData }
        }
    } as IRef), [data?.timeData]);


    const loadDateInfoForYear = (fullYear: number) => {
        // set quarter

        const quartersByYear: IQuater[] = getQuartersFromYear(fullYear);

        // console.log(quartersByYear, "quarter")

        setQuater(quartersByYear)

        // set month

        const dateByMonth: IMonth[] = getMonthsFromYear(fullYear)
        // console.log(dateByMonth, "month")
        setMonth(dateByMonth);

        // setWeek

        const weeks = getWeekFromYear(fullYear);

        setWeek(weeks);
    }

    const handleChangeYear = (e: NativeEventInfo<dxDateBox, Event> & ValueChangedInfo) => {

        const currentDate = new Date();
        const fullYear = (e.value as Date)?.getFullYear();
        const { timeData, ...dataRef } = preTimeData.current ?? {} as IData;

        // console.log(data?.time_from?.toISOString(), time_from.toISOString())
        // console.log(!_.isEqual(data?.time_from, TransDateTime(time_from)), "year")

        loadDateInfoForYear(fullYear)

        //

        // setData(pre => {
        const time_from = timeData?.time_from
        if (time_from) {
            const time_data = new Date(fullYear, time_from.getMonth(), time_from.getDate(), 0, 0, 0);
            const time_to_for_year = new Date(fullYear, 11, 31, hours, minutes, seconds);
            switch (dataRef?.dataSelect?.cycle) {
                case 2:

                    const timeDataWeek = handleChangeWeekByYear(time_data, fullYear)
                    if (timeDataWeek && ObjectUtility.checkRequiredField(timeDataWeek, ["week", "weekByYearStart", "weekByYearEnd"])) {


                        preTimeData.current = {
                            ...dataRef,
                            dataSelect: {
                                ...dataRef?.dataSelect,
                                fullYear: fullYear,
                                year: new Date(fullYear, 0, 1),
                                week: timeDataWeek?.week
                            },
                            timeData: {
                                time_from: timeDataWeek?.weekByYearStart,
                                time_to: timeDataWeek?.weekByYearEnd

                            }
                        } as IData
                        setData(preTimeData.current)
                        props.onChangeTimeData && props.onChangeTimeData({
                            time_from: timeDataWeek?.weekByYearStart,
                            time_to: timeDataWeek?.weekByYearEnd
                        })
                    }

                    break;

                case 3:

                    const timeDataMonth = handleChangeMonthByYear(time_data, fullYear);
                    if (timeDataMonth) {
                        preTimeData.current = {
                            ...dataRef,
                            dataSelect: {
                                ...dataRef?.dataSelect,
                                fullYear: dataRef?.dataSelect?.fullYear !== fullYear ? fullYear : dataRef?.dataSelect?.fullYear,
                                year: dataRef?.dataSelect?.fullYear !== fullYear ? new Date(fullYear, 0, 1) : dataRef?.dataSelect?.year,
                                month: timeDataMonth.time_from?.getMonth()
                            },
                            timeData: timeDataMonth
                        } as IData
                        setData(preTimeData.current);
                        props.onChangeTimeData && props.onChangeTimeData(timeDataMonth)

                    }
                    break;
                case 4:

                    const timeDataQuarter = handleChangeQuarterByYear(time_data, fullYear);
                    const monthOfQuarter = timeDataQuarter?.time_from?.getMonth();
                    if (timeDataQuarter) {

                        preTimeData.current = {
                            ...dataRef,
                            dataSelect: {
                                ...dataRef?.dataSelect,
                                fullYear: fullYear,
                                year: new Date(fullYear, 0, 1),
                                quarter: monthOfQuarter ? Math.floor(monthOfQuarter / 3) : 0,
                            },
                            timeData: timeDataQuarter
                        } as IData
                        setData(preTimeData.current)
                        props.onChangeTimeData && props.onChangeTimeData(timeDataQuarter)

                    }
                    break;
                case 5:

                    // if (pre?.dataSelect?.fullYear !== fullYear 
                    //     || props?.defaultCycle === 5) { // khi default cycle là "năm"


                    preTimeData.current = {
                        ...dataRef,
                        dataSelect: {
                            ...dataRef?.dataSelect,
                            fullYear: fullYear,
                            year: new Date(fullYear, 0, 1),

                        },
                        timeData: {
                            time_from: new Date(fullYear, 0, 1, 0, 0, 0),
                            time_to: new Date(fullYear, 12, 0, hours, minutes, seconds)
                        }
                    } as IData
                    setData(preTimeData.current)
                    props.onChangeTimeData && props.onChangeTimeData({
                        time_from: new Date(fullYear, 0, 1, 0, 0, 0),
                        time_to: new Date(fullYear, 12, 0, hours, minutes, seconds)
                    } as ITimeData)
                    break;
                // }
                // return pre;
                case 7: case 8:

                    const time_from_period = timeData?.time_from && new Date(timeData?.time_from);
                    const time_to_period = timeData?.time_to && new Date(timeData?.time_to);

                    if (time_from_period && time_to_period) {
                        time_from_period.setFullYear(fullYear);
                        time_to_period.setFullYear(fullYear)
                        preTimeData.current = {
                            ...dataRef,
                            dataSelect: {
                                ...dataRef.dataSelect,
                                fullYear: fullYear,
                                year: new Date(fullYear, 0, 1),
                            },
                            timeData: {
                                time_from: time_from_period,
                                time_to: time_to_period
                            }
                        } as IData
                        setData(preTimeData.current)
                        props.onChangeTimeData && props.onChangeTimeData({
                            time_from: time_from_period,
                            time_to: time_to_period
                        } as ITimeData)
                    }
                    break
                default: break;
            }
        }
        // })
    }

    // Cycle

    const handleChangeCycle = (e: NativeEventInfo<SelectBoxInstance, Event | KeyboardEvent | MouseEvent | PointerEvent> & ValueChangedInfo) => {
        const year = preTimeData.current?.dataSelect?.fullYear;
        const { timeData, ...dataRef } = preTimeData.current ?? {} as IData;
        // alert(e.value)
        // setData(pre => {
        switch (e.value) {
            // case null:
            case 2:
                const timeDataWeek = handleChangeWeekByCycle(timeData?.time_from);
                if (timeDataWeek) {

                    const newWeek = timeDataWeek?.week;
                    const currentWeek = dataRef?.dataSelect?.week;
                    preTimeData.current = {
                        ...dataRef,
                        dataSelect: {
                            ...dataRef?.dataSelect,
                            cycle: e.value,
                            week: newWeek !== currentWeek ? newWeek : currentWeek
                        },
                        timeData: {
                            time_from: timeDataWeek?.weekByYearStart,
                            time_to: timeDataWeek?.weekByYearEnd
                        }
                    } as IData
                    setData(preTimeData.current)
                    props.onChangeTimeData && props.onChangeTimeData({
                        time_from: timeDataWeek?.weekByYearStart,
                        time_to: timeDataWeek?.weekByYearEnd
                    })
                }
                break;

            case 3:
                const timeDateMonth = handleChangeMonthByCycle(timeData?.time_from);
                if (timeDateMonth) {
                    const newMonth = timeDateMonth?.time_from?.getMonth();
                    preTimeData.current = {
                        ...dataRef,
                        dataSelect: {
                            ...dataRef?.dataSelect,
                            cycle: e.value,
                            month: newMonth

                        },
                        timeData: timeDateMonth
                    } as IData
                    setData(preTimeData.current);
                    props.onChangeTimeData && props.onChangeTimeData(timeDateMonth);

                }
                break
            case 4:

                const timeDataQuarter = handleChangeQuarterByCycle(timeData?.time_from)
                if (timeDataQuarter) {

                    // const monthOfQuarter = timeDataQuarter?.time_from?.getMonth();
                    const newQuarter = timeDataQuarter?.quarter;
                    preTimeData.current = {
                        ...dataRef,
                        dataSelect: {
                            ...dataRef?.dataSelect,
                            cycle: e.value,
                            quarter: newQuarter
                        },
                        timeData: {
                            time_from: timeDataQuarter?.dateByQuaterStart,
                            time_to: timeDataQuarter?.dateByQuaterEnd
                        }
                    } as IData
                    setData(preTimeData.current)
                    props.onChangeTimeData && props.onChangeTimeData({
                        time_from: timeDataQuarter?.dateByQuaterStart,
                        time_to: timeDataQuarter?.dateByQuaterEnd
                    })
                }
                break;
            case 5:
                if (year) {
                    const time_from = new Date(year, 0, 1, 0, 0, 0);
                    const time_to = new Date(year, 11, 31, hours, minutes, seconds);
                    const newYear = new Date(year, 0, 1);

                    preTimeData.current = {
                        ...dataRef,
                        dataSelect: {
                            ...dataRef?.dataSelect,
                            cycle: e.value,
                            fullYear: year,
                            year: newYear
                        },
                        timeData: {
                            time_from,
                            time_to
                        }
                    } as IData
                    setData(preTimeData.current);
                    props.onChangeTimeData && props.onChangeTimeData({ time_from, time_to })


                }
                break
            case 7:

                if (year) {
                    const startOfPeriod = new Date(year, 0, 1, 0, 0, 0); // Ngày bắt đầu của năm
                    const endOfPeriod = new Date(year, 5, 30, hours, minutes, seconds); // Ngày kết thúc của 6 tháng đầu năm
                    const timeDataPeriod = { time_from: startOfPeriod, time_to: endOfPeriod } as ITimeData;

                    preTimeData.current = {
                        ...dataRef,
                        dataSelect: {
                            ...dataRef?.dataSelect,
                            cycle: e.value
                        },
                        timeData: {
                            time_from: startOfPeriod,
                            time_to: endOfPeriod,
                        }
                    } as IData
                    setData(preTimeData.current)
                    props.onChangeTimeData && props.onChangeTimeData(timeDataPeriod)

                }

                break;
            case 8:

                if (year) {
                    const startOfPeriod = new Date(year, 6, 1, 0, 0, 0); // Ngày bắt đầu của năm
                    const endOfPeriod = new Date(year, 12, 0, hours, minutes, seconds); // Ngày kết thúc của 6 tháng cuối năm

                    const timeDataPeriod2 = { time_from: startOfPeriod, time_to: endOfPeriod } as ITimeData;

                    preTimeData.current = {
                        ...dataRef,
                        dataSelect: {
                            ...dataRef?.dataSelect,
                            cycle: e.value
                        },
                        timeData: {
                            time_from: startOfPeriod,
                            time_to: endOfPeriod,
                        }
                    } as IData
                    setData(preTimeData.current)
                    props.onChangeTimeData && props.onChangeTimeData(timeDataPeriod2)


                }

                break
            case 1:
            case 6: {
                const currentDate = new Date();
                const time_from = timeData?.time_from;
                const time_to = timeData?.time_to;
                if (time_from) {
                    const start_year = _.cloneDeep(time_from)?.getFullYear()
                    const start_day = _.cloneDeep(time_from)?.getDate()
                    const start_month = _.cloneDeep(time_from)?.getMonth()
                    const startDate = new Date(start_year, start_month, start_day, 0, 0, 0); // Ngày bắt đầu của năm

                    const endDate = _.cloneDeep(time_to);
                    endDate?.setHours(hours, minutes, seconds); // Ngày kết thúc của 6 tháng cuối năm

                    preTimeData.current = {
                        ...dataRef,
                        dataSelect: {
                            ...dataRef?.dataSelect,
                            cycle: e.value,
                        },
                        timeData: {
                            time_from: startDate,
                            time_to: endDate,
                        }
                    } as IData
                    setData(preTimeData.current);
                    props.onChangeTimeData && props.onChangeTimeData({ time_from: startDate, time_to: endDate } as ITimeData);

                }
            }
                break;
            case 9:
                {
                    const yearNow = new Date().getFullYear();
                    const dayNow = new Date().getDate();
                    const monthNow = new Date().getMonth();
                    const startDateNow = new Date(yearNow, monthNow, dayNow, 0, 0, 0); // Ngày bắt đầu của năm
                    const endDateNow = new Date(yearNow, monthNow, dayNow, hours, minutes, seconds); // Ngày kết thúc của 6 tháng cuối năm

                    preTimeData.current = {
                        ...dataRef,
                        dataSelect: {
                            ...dataRef?.dataSelect,
                            cycle: e.value,
                        },
                        timeData: {
                            time_from: startDateNow,
                            time_to: endDateNow,
                        }
                    } as IData
                    setData(preTimeData.current)
                    props.onChangeTimeData && props.onChangeTimeData({ time_from: startDateNow, time_to: endDateNow } as ITimeData)

                }

                break;
            case 10:
                {
                    const yearNow = new Date().getFullYear();
                    const dayNow = new Date().getDate() - 1;
                    const monthNow = new Date().getMonth();
                    const startDateNow = new Date(yearNow, monthNow, dayNow, 0, 0, 0); // Ngày bắt đầu của năm
                    const endDateNow = new Date(yearNow, monthNow, dayNow, hours, minutes, seconds); // Ngày kết thúc của 6 tháng cuối năm
                    // 

                    preTimeData.current = {
                        ...dataRef,
                        dataSelect: {
                            ...dataRef?.dataSelect,
                            cycle: e.value
                        },
                        timeData: {
                            time_from: startDateNow,
                            time_to: endDateNow,
                        }
                    } as IData
                    setData(preTimeData.current);
                    props.onChangeTimeData && props.onChangeTimeData({ time_from: startDateNow, time_to: endDateNow } as ITimeData)
                }



                break;
            case 11:

                const currentDate = new Date();

                // const weeks = getWeekFromYear(currentDate.getFullYear());

                // setWeek(weeks);
                const fullYear = currentDate.getFullYear();
                const weekOfYear = (week && week.length > 0) ? week : getWeekFromYear(fullYear);
                if(!week || week?.length <= 0) setWeek(weekOfYear);

                const weekSelected = [...weekOfYear].find(x => x.week === getWeekNumber(currentDate));
                const startOfWeek = weekSelected?.weekByYearStart && new Date(weekSelected?.weekByYearStart);
                const endOfWeek = weekSelected?.weekByYearEnd && new Date(weekSelected?.weekByYearEnd);

                // console.log(startOfWeek, endOfWeek?.toISOString())
                if (startOfWeek && endOfWeek) {
                    // if (pre?.dataSelect?.fullYear == startOfWeek.getFullYear()) {
                    const newWeek = getWeekNumber(startOfWeek);
                    startOfWeek.setFullYear(currentDate.getFullYear())
                    endOfWeek.setFullYear(currentDate.getFullYear())
                    preTimeData.current = {
                        ...dataRef,
                        dataSelect: {
                            ...dataRef?.dataSelect,
                            cycle: e.value,
                            week: newWeek
                        },
                        timeData: {
                            time_from: startOfWeek,
                            time_to: endOfWeek
                        }
                    } as IData
                    setData(preTimeData.current)
                    props.onChangeTimeData && props.onChangeTimeData({ time_from: startOfWeek, time_to: endOfWeek } as ITimeData)

                    // }
                    // return pre;
                }
                // }
                break;
            default: break;
        }
        // });
    }

    // quarter
    const handleChangeQuarterByYear = (time_from: Date, fullYear: number): ITimeData | undefined => {
        const quarterOfYear = (quarter && quarter.length > 0
            && quarter[0].dateByQuaterStart?.getFullYear() === fullYear) ? quarter : getQuartersFromYear(fullYear);

        // 
        const quarterSelected = [...quarterOfYear].find(x => x.quarter === Math.floor(time_from.getMonth() / 3));
        const startOfQuarter = quarterSelected?.dateByQuaterStart && new Date(quarterSelected?.dateByQuaterStart);
        const endOfQuarter = quarterSelected?.dateByQuaterEnd && new Date(quarterSelected?.dateByQuaterEnd);

        // 

        startOfQuarter?.setFullYear(fullYear);
        endOfQuarter?.setFullYear(fullYear);
        return {
            time_from: startOfQuarter,
            time_to: endOfQuarter
        } as ITimeData


        // }
    }
    const handleChangeQuarterByCycle = (time_from?: Date): IQuater | undefined => {
        const fullYear = time_from ? new Date(time_from).getFullYear() : undefined;
        if (fullYear) {
            const quarterOfYear = (quarter && quarter.length > 0
                && quarter[0].dateByQuaterStart?.getFullYear() === fullYear) ? quarter : getQuartersFromYear(fullYear);
            if (!quarter || quarter?.length <= 0) setQuater(quarterOfYear)

            const currentDate = new Date();
            const currentMonth = new Date(time_from ?? Date.now()).getMonth()
            const currentYear = new Date(time_from ?? Date.now()).getFullYear()
            const timeFrom = new Date(currentYear, currentMonth, 1)
            // 
            const quarterSelected = [...quarterOfYear].find(x => x.quarter === Math.floor(timeFrom.getMonth() / 3))
            const startOfQuarter = quarterSelected?.dateByQuaterStart;
            const endOfQuarter = quarterSelected?.dateByQuaterEnd;
            return {
                quarter: quarterSelected?.quarter,
                dateByQuaterStart: startOfQuarter,
                dateByQuaterEnd: endOfQuarter


            } as IQuater
        }
        return undefined;

        // }
    }

    const getQuartersFromYear = (fullYear: number) => {
        const quartersByYear: IQuater[] = [];
        for (let quarter = 0; quarter < 4; quarter++) {
            const startOfQuarter = new Date(fullYear, quarter * 3, 1, 0, 0, 0);
            const endOfQuarter = new Date(fullYear, quarter * 3 + 3, 0, hours, minutes, seconds);

            quartersByYear.push({ quarter: quarter, dateByQuaterStart: startOfQuarter, dateByQuaterEnd: endOfQuarter } as IQuater);
        }
        return quartersByYear;
    }

    const onChangeQuarter = (e: NativeEventInfo<SelectBoxInstance, Event | KeyboardEvent | MouseEvent | PointerEvent> & ValueChangedInfo) => {

        // if (quarter && quarter.length > 0) {
        const fullYear = preTimeData?.current?.timeData?.time_from?.getFullYear();
        if (fullYear) {
            const quarterOfYear = (quarter && quarter.length > 0
                && quarter[0].dateByQuaterStart?.getFullYear() === fullYear) ? quarter : getQuartersFromYear(fullYear);
            const quarterSelected = [...quarterOfYear].find(x => x.quarter === e.value as number);
            const startOfQuarter = quarterSelected?.dateByQuaterStart;
            const endOfQuarter = quarterSelected?.dateByQuaterEnd;
            if (startOfQuarter && endOfQuarter) {
                const { timeData, ...dataRef } = preTimeData.current ?? {} as IData;


                // setData(pre => {
                if (dataRef?.dataSelect?.fullYear === startOfQuarter.getFullYear()) {

                    if (dataRef?.dataSelect?.quarter !== quarterSelected?.quarter) {
                        preTimeData.current = {
                            ...dataRef,
                            dataSelect: {
                                ...dataRef.dataSelect,
                                quarter: quarterSelected.quarter
                            },
                            timeData: {
                                time_from: startOfQuarter,
                                time_to: endOfQuarter
                            }
                        } as IData
                        setData(preTimeData.current)
                        props.onChangeTimeData && props.onChangeTimeData({ time_from: startOfQuarter, time_to: endOfQuarter } as ITimeData)

                    } else {
                        preTimeData.current = {
                            ...dataRef,
                            timeData: {
                                time_from: startOfQuarter,
                                time_to: endOfQuarter
                            }
                        } as IData
                        setData(preTimeData.current)
                        props.onChangeTimeData && props.onChangeTimeData({ time_from: startOfQuarter, time_to: endOfQuarter } as ITimeData)

                    }

                }
            }

            // });


        }

        // }
    }
    //

    const handleChangeMonthByYear = (time_from: Date, fullYear: number): ITimeData | undefined => {
        const monthOfYear = (month && month.length > 0
            && month[0].dateByMonthStart?.getFullYear() === fullYear)
            ? month
            : getMonthsFromYear(fullYear);
        const monthSelected = [...monthOfYear].find(x => x.month === time_from.getMonth());
        const startOfMonth = monthSelected?.dateByMonthStart && new Date(monthSelected?.dateByMonthStart);
        const endOfMonth = monthSelected?.dateByMonthEnd && new Date(monthSelected?.dateByMonthEnd);
        // 
        startOfMonth?.setFullYear(fullYear);
        endOfMonth?.setFullYear(fullYear);

        return {
            time_from: startOfMonth,
            time_to: endOfMonth
        } as ITimeData

        // }
    }
    const handleChangeMonthByCycle = (time_from?: Date): ITimeData | undefined => {
        const fullYear = time_from ? new Date(time_from).getFullYear() : undefined;

        if (fullYear) {
            const monthOfYear = (month && month.length > 0
                && month[0].dateByMonthStart?.getFullYear() === fullYear)
                ? month : getMonthsFromYear(fullYear);
            if (!month || month?.length <= 0) setMonth(monthOfYear);

            const currentDate = new Date();
            if (time_from) {
                const currentMonth = new Date(time_from).getMonth();
                const currentYear = new Date(time_from).getFullYear();
                const timeFrom = new Date(currentYear, currentMonth, 1)
                // 
                const monthSelected = [...monthOfYear].find(x => x.month === timeFrom.getMonth());
                const startOfMonth = monthSelected?.dateByMonthStart;
                const endOfMonth = monthSelected?.dateByMonthEnd;
                return {
                    time_from: startOfMonth,
                    time_to: endOfMonth
                } as ITimeData
            }
            return {
                time_from: undefined,
                time_to: undefined
            } as ITimeData

        }
        return undefined;
    }

    const getMonthsFromYear = (fullYear: number) => {
        const dateByMonth: IMonth[] = [];
        for (let month = 0; month < 12; month++) {
            const startOfMonth = new Date(fullYear, month, 1, 0, 0, 0);
            const endOfMonth = new Date(fullYear, month + 1, 0, hours, minutes, seconds);
            // startOfMonth.setHours(-7)
            // endOfMonth.setHours(-7)
            dateByMonth.push({
                month: month,
                dateByMonthStart: startOfMonth,
                dateByMonthEnd: endOfMonth
            });

        }

        return dateByMonth;
    }

    const onChangeMonth = (e: NativeEventInfo<SelectBoxInstance, Event | KeyboardEvent | MouseEvent | PointerEvent> & ValueChangedInfo) => {
        //

        const fullYear = preTimeData.current?.timeData?.time_from?.getFullYear();
        if (fullYear) {
            const monthOfYear = (month && month.length > 0
                && month[0].dateByMonthStart?.getFullYear() === fullYear) ? month : getMonthsFromYear(fullYear);

            const monthSelected = [...monthOfYear].find(x => x.month === e.value as number);
            const startOfMonth = monthSelected?.dateByMonthStart;
            const endOfMonth = monthSelected?.dateByMonthEnd;
            // console.log(startOfMonth, data?.dataSelect?.fullYear, "month change")
            if (startOfMonth && endOfMonth) {
                const { timeData, ...dataRef } = preTimeData.current ?? {} as IData;

                // setData(pre => {
                if (preTimeData.current?.dataSelect?.fullYear === startOfMonth.getFullYear()) {
                    const timeData = {
                        time_from: startOfMonth,
                        time_to: endOfMonth
                    };
                    if (preTimeData.current?.dataSelect?.month !== monthSelected?.month) {
                        preTimeData.current = {
                            ...dataRef,
                            dataSelect: {
                                ...dataRef?.dataSelect,
                                month: monthSelected.month
                            },
                            timeData: timeData
                        } as IData
                        setData(preTimeData.current)
                        onChangeTimeData(timeData);

                    } else {
                        onChangeTimeData(timeData);
                        preTimeData.current = {
                            ...dataRef,
                            timeData: timeData
                        } as IData;
                        setData(preTimeData.current)
                        onChangeTimeData(timeData);

                    }
                }
            }
            // return pre;
            // });


        }

    }

    // 



    const getWeekFromYear = (year: number) => {


        // 
        const weeks: IWeek[] = [];
        const firstDayOfYear = new Date(year, 0, 1, 0, 0, 0);
        let currentDate = new Date(firstDayOfYear);

        while (currentDate.getFullYear() === year) {
            const startOfWeek = new Date(currentDate);
            const endOfWeek = new Date(currentDate);
            endOfWeek.setDate(endOfWeek.getDate() + 6);
            endOfWeek.setHours(hours, minutes, seconds)

            weeks.push({
                week: getWeekNumber(currentDate),
                weekByYearStart: startOfWeek,
                weekByYearEnd: endOfWeek
            } as IWeek)
            //   weeks.push({
            //     week: getWeekNumber(currentDate),
            //     start: startOfWeek,
            //     end: endOfWeek,
            //   });

            currentDate.setDate(currentDate.getDate() + 7);
        }

        return weeks;
    };

    const getWeekNumber = (date: Date) => {
        const oneJan = new Date(date.getFullYear(), 0, 1);
        const daysOffset = oneJan.getDay() > 4 ? 1 : 0;
        const days = Math.floor((date.getTime() - oneJan.getTime()) / 86400000);
        const weekNumber = Math.floor((days + daysOffset + 1) / 7);
        return weekNumber;
    };

    //   week

    const handleChangeWeekByCycle = (time_from?: Date): IWeek | undefined => {
        if (time_from) {
            const fullYear = time_from?.getFullYear();
            const weekOfYear = (week && week.length > 0
                && week[0].weekByYearStart?.getFullYear() === fullYear) ? week : getWeekFromYear(fullYear);
            if (!week || week?.length <= 0) setWeek(weekOfYear)
            const currentDate = new Date(Date.now());
            const currentDay = time_from?.getDate();
            const currentMonth = time_from?.getMonth();
            const currentYear = time_from?.getFullYear();
            const timeFrom = new Date(currentYear, currentMonth, currentDay);
            // 
            if (timeFrom) {
                const weekSelected = [...weekOfYear].find(x => x.week === getWeekNumber(timeFrom));
                const startOfWeek = weekSelected?.weekByYearStart;
                const endOfWeek = weekSelected?.weekByYearEnd;

                return {
                    week: weekSelected?.week,
                    weekByYearStart: startOfWeek,
                    weekByYearEnd: endOfWeek
                } as IWeek


            }
        }
        return undefined;
        // }
    }

    const handleChangeWeekByYear = (time_from: Date, fullYear: number): IWeek | undefined => {
        const weekOfYear = (week && week.length > 0
            && week[0].weekByYearStart?.getFullYear() === fullYear) ? week : getWeekFromYear(fullYear);
        const weekSelected = [...weekOfYear].find(x => {
            return x.week === getWeekNumber(new Date(time_from))
        });
        const startOfWeek = weekSelected?.weekByYearStart && new Date(weekSelected?.weekByYearStart!);
        const endOfWeek = weekSelected?.weekByYearEnd && new Date(weekSelected?.weekByYearEnd!);
        startOfWeek?.setFullYear(fullYear);
        endOfWeek?.setFullYear(fullYear);

        return {
            week: weekSelected?.week,
            weekByYearStart: startOfWeek,
            weekByYearEnd: endOfWeek
        } as IWeek
        // }
    }

    const onChangeWeek = (e: NativeEventInfo<SelectBoxInstance, Event | KeyboardEvent | MouseEvent | PointerEvent> & ValueChangedInfo) => {

        // 
        const fullYear = preTimeData.current?.timeData?.time_from?.getFullYear();
        if (fullYear) {
            const weekOfYear = (week && week.length > 0
                && week[0].weekByYearStart?.getFullYear() === fullYear) ? week : getWeekFromYear(fullYear);

            // 
            const weekSelected = [...weekOfYear].find(x => x.week === e.value as number);
            const startOfWeek = weekSelected?.weekByYearStart;
            const endOfWeek = weekSelected?.weekByYearEnd;
            // console.log(startOfWeek, endOfWeek?.toISOString())
            if (startOfWeek && endOfWeek) {
                const { timeData, ...dataRef } = preTimeData.current ?? {} as IData;

                // setData(pre => {
                if (dataRef?.dataSelect?.fullYear === startOfWeek.getFullYear()) {
                    const newWeek = weekSelected?.week;
                    const currentWeek = dataRef?.dataSelect?.week;
                    if (newWeek !== currentWeek) {
                        preTimeData.current = {
                            ...dataRef,
                            dataSelect: {
                                ...dataRef?.dataSelect,
                                week: weekSelected?.week // tránh set week quá nhiều
                            },
                            timeData: {
                                time_from: startOfWeek,
                                time_to: endOfWeek
                            }
                        } as IData
                        setData(preTimeData.current)
                        onChangeTimeData({
                            time_from: startOfWeek,
                            time_to: endOfWeek
                        });

                    }
                    else {
                        preTimeData.current = {
                            ...dataRef,
                            timeData: {
                                time_from: startOfWeek,
                                time_to: endOfWeek
                            }
                        } as IData
                        setData(preTimeData.current)
                        onChangeTimeData({
                            time_from: startOfWeek,
                            time_to: endOfWeek
                        });
                    }

                }
            }
            // return pre;
            // });
        }

        // }
    }

    // 

    const handleChangeTimeFrom = React.useCallback((e: NativeEventInfo<dxDateBox, Event> & ValueChangedInfo) => {
        const value = (e.value as Date)
        const { timeData, ...dataRef } = preTimeData.current ?? {} as IData;

        if (value && dataRef?.dataSelect?.cycle === 6) {
            const fullYear = value.getFullYear();
            // const year = new Date(fullYear, 0, 1);

            // const month = value.getMonth();
            // const week = getWeekNumber(value);

            // setData(pre => {

            // if (data?.dataSelect?.fullYear === fullYear) {
            // props.onChangeTimeData && props.onChangeTimeData({ time_from: value, time_to: data?.timeData?.time_to } as ITimeData)
            // setData(pre => {

            // if (pre?.dataSelect?.fullYear === fullYear) {
            // props.onChangeTimeData && props.onChangeTimeData({ time_from: pre?.timeData?.time_from, time_to: value } as ITimeData)

            preTimeData.current = {
                ...dataRef,
                timeData: {
                    ...timeData,
                    time_from: value
                }
            } as IData;
            setData(preTimeData.current)
            onChangeTimeData({
                ...timeData,
                time_from: value
            });

            // })
            return;
        }
        if (value && dataRef?.dataSelect?.cycle === 1) {
            const time_to = new Date(value);
            time_to.setHours(hours, minutes, seconds)
            // setData(pre => {

            if (dataRef?.dataSelect?.cycle === 1) {
                preTimeData.current = {
                    ...dataRef,
                    timeData: {
                        ...timeData,
                        time_from: value,
                        time_to: time_to,
                    }
                } as IData;
                setData(preTimeData.current)
                onChangeTimeData({
                    time_from: value,
                    time_to: time_to,
                });

            }
            // return pre;


            // })
        }
        // setData(pre => {
        //     if (value && pre?.dataSelect?.cycle === 1) {
        //         const time_to = new Date(value);
        //         time_to.setHours(hours, minutes, seconds)
        //         return {
        //             ...pre,
        //             timeData: {
        //                 ...pre?.timeData,
        //                 time_from: value,
        //                 time_to: time_to,
        //             }
        //         } as IData;
        //     }
        //     return pre;


        // })


        // }

    }, [hours, minutes, seconds])

    const handleChangeTimeTo = (e: NativeEventInfo<dxDateBox, Event> & ValueChangedInfo) => {
        // console.log((e.value as Date).toISOString())
        const value = e.value as Date;
        if (value) {
            // const fullYear = value.getFullYear();
            const { timeData, ...dataRef } = preTimeData.current ?? {} as IData;
            // setData(pre => {
            if (dataRef?.dataSelect?.cycle === 6) {

                // if (dataRef?.dataSelect?.fullYear === fullYear) {
                // props.onChangeTimeData && props.onChangeTimeData({ time_from: dataRef?.timeData?.time_from, time_to: value } as ITimeData)

                preTimeData.current = {
                    ...dataRef,
                    timeData: {
                        ...timeData,
                        time_to: value
                    }
                } as IData;
                setData(preTimeData.current)
                onChangeTimeData({
                    ...timeData,
                    time_to: value
                });
            }
            else if (dataRef?.dataSelect?.cycle === 1) {
                const time_to = new Date(timeData?.time_from ?? Date.now());
                time_to.setHours(hours, minutes, seconds)

                preTimeData.current = {
                    ...dataRef,
                    timeData: {
                        ...timeData,
                        time_to: time_to
                    }
                } as IData;
                setData(preTimeData.current)
                onChangeTimeData({
                    ...timeData,
                    time_to: time_to
                });
            }


            // }
            // return pre;

            // })
        }
    }

    // 


    const allowHandleChanage = (cycle: number) => {
        const dataRef = preTimeData.current;
        return dataRef?.dataSelect?.cycle === cycle
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12 / 3} md={12 / 4} lg={12 / 6} xl={12 / 8}>
                <DHSSelectBox
                    label={language === 'vi-VN' ? 'Kỳ' : 'Cycle'}
                    labelMode="floating"
                    dataSource={cycle}
                    valueExpr={"code"}
                    displayExpr={"name"}
                    searchEnabled={false}
                    value={data?.dataSelect?.cycle}
                    onValueChanged={handleChangeCycle}
                />
            </Grid>
            {
                (!data?.dataSelect?.cycle || visiableCycleByYear.includes(data?.dataSelect?.cycle ?? 0)) ? <Grid item xs={12} sm={12 / 3} md={12 / 7} lg={12 / 8} xl={1}>
                    <DHSDateBox
                        label={language === 'vi-VN' ? 'Năm' : 'Year'}
                        labelMode="floating"
                        value={data?.dataSelect?.year}
                        name={"year"}
                        type="date"
                        calendarOptions={
                            {
                                zoomLevel: "year",
                                maxZoomLevel: "decade"
                            }
                        }
                        pickerType="calendar"
                        displayFormat="yyyy"
                        onValueChanged={(e) => handleChangeYear(e)}
                        applyValueMode="instantly"
                    // visible={visiableCycleByYear.includes(data?.dataSelect?.cycle ?? 0)}
                    />
                </Grid> : <></>
            }

            {
                visiableCycleByQuarter.includes(data?.dataSelect?.cycle ?? 0) && <Grid item xs={12} sm={12 / 3} md={12 / 6} lg={12 / 6} xl={1}>
                    <DHSSelectBox
                        value={data?.dataSelect?.quarter}
                        label={language === 'vi-VN' ? 'Quý' : 'Quarter'}
                        labelMode="floating"
                        dataSource={quarter}
                        valueExpr={"quarter"}
                        searchEnabled={false}
                        displayExpr={(quarter) => quarter && `Quý ${quarter.quarter + 1}`}
                        onValueChanged={(e) => data?.dataSelect?.cycle === 4 && onChangeQuarter(e)}

                    />
                </Grid>
            }
            {
                visiableCycleByMonth.includes(data?.dataSelect?.cycle ?? 0) && <Grid item xs={12} sm={12 / 3} md={12 / 5} lg={12 / 6} xl={12 / 9}>
                    <DHSSelectBox
                        value={data?.dataSelect?.month}
                        label={language === 'vi-VN' ? 'Tháng' : 'Month'}
                        labelMode="floating"
                        dataSource={month}
                        valueExpr={"month"}
                        searchEnabled={false}

                        displayExpr={(month) => month && `Tháng ${month.month + 1}`}
                        onValueChanged={(e) => allowHandleChanage(3) && onChangeMonth(e)}

                    />
                </Grid>
            }
            {
                visiableCycleByWeek.includes(data?.dataSelect?.cycle ?? 0) && <Grid item xs={12} sm={12 / 3} md={12 / 5} lg={12 / 7} xl={1}>
                    <DHSSelectBox
                        label={language === 'vi-VN' ? 'Tuần' : 'Week'}
                        labelMode="floating"
                        value={data?.dataSelect?.week}
                        dataSource={week}
                        valueExpr={"week"}
                        searchEnabled={false}

                        displayExpr={(week) => week && `Tuần ${week.week + 1}`}
                        onValueChanged={(e) => data?.dataSelect?.cycle === 2 && onChangeWeek(e)}

                    />
                </Grid>
            }

            <Grid item xs={12} sm={12 / 2} md={12 / 4} lg={12 / 5} xl={12 / 8}>
                <DHSDateBox
                    label={language === 'vi-VN' ? 'Từ ngày' : 'Time from'}
                    labelMode="floating"
                    value={data?.timeData?.time_from}
                    name={"time_from"}
                    type="date"
                    pickerType="calendar"
                    displayFormat={props.displayFormat ?? "dd/MM/yyyy HH:mm:ss"}
                    onValueChanged={handleChangeTimeFrom}
                    readOnly={![1, 6].includes(data?.dataSelect?.cycle ?? 0)}
                // applyValueMode="instantly"
                />
            </Grid>

            {
                <Grid item xs={12} sm={12 / 2} md={12 / 4} lg={12 / 5} xl={12 / 8}>
                    <DHSDateBox
                        label="Đến ngày"
                        labelMode="floating"
                        value={data?.timeData?.time_to}
                        name={"time_to"}
                        type="date"
                        pickerType="calendar"
                        displayFormat={props.displayFormat ?? "dd/MM/yyyy HH:mm:ss"}
                        onValueChanged={handleChangeTimeTo}
                        readOnly={data?.dataSelect?.cycle !== 6}

                    // applyValueMode="instantly"
                    />
                </Grid>
            }
            {props?.children}
        </Grid>
    )
}));


export default DHSDateFilterV2;