import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import notification from "../../../common/notification/notification";
import BlockUI from "../../../components/Block-UI/Block-ui";
import { AppSession } from "../../shared/app-session/app-session";
import {
    HomeClient,
    QLCCClient,
    QLCCApartmentBuildingModel,
    QLCCDomesticWaterCycleModel,
    SysActionType,
    QLCCUtilityModel,
    QLCCUtilityBookingModel,
    QLCCUtilityBookingFilterModel,
    QLCCUtilityBookingModev2
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import ServicesAction from '../actions/service-action/ServicesAction'
import { AutoComplete, Form } from "antd";
import { useHotkeys } from "react-hotkeys-hook";
import DHSBreadcrumb from "../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import DataGrid from "devextreme-react/data-grid";
import { EventInfo } from "devextreme/events";
import dxDataGrid, { CellPreparedEvent, SelectionChangedInfo } from "devextreme/ui/data_grid";
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import { ACTIONS } from "../../../common/enums/DHSToolbarRoleAction";
import DHSToolbarRole from "../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import { getDataRowSelected, getIndexRowSelected, getListDataRowSelected } from "../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../common/Interfaces/StatusMessage";
import UtilityBookingAction from "./action/ServiceBookingAction";
import { getMenuShowGrid } from "../../../redux/actions/RoleActions";
import ServiceBookingRejectAction from "./action/ServiceBookingRejectAction";
import ServiceBookingSearchAction from "./action/ServiceBookingSearchAction";
import { TransDateTime } from "../../../components/utils/date.util";


// import "./style.css";
interface IAction {
    open: boolean; //Mở Dialog
    type: string | undefined; //Loại action (keyword)
    // name: string | undefined; //Tên action (thêm, sửa, xóa)
    // table: string | undefined; //Tên bảng thay đổi khi thực hiện action
    payload: any | undefined;
}

interface IFilter {
    apartment_building_id?: string | null,
    utility_id?: string | null,
    status_id?: string | null,
    nonDuplicateDate?: boolean | undefined,
    start_time?: Date | null,
    end_time?: Date | null,
}

const defaultFilter: IFilter = {
    apartment_building_id: null,
    utility_id: null,
    status_id: null,
    nonDuplicateDate: undefined,
    start_time: null,
    end_time: null,
}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

let appSession: AppSession;
const convert = ["success", "error", "warning"];
const menuRoleName = "CC";
const ServiceBooking = () => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const dispatch = useDispatch();
    const location = useLocation();
    const { search } = useLocation();
    const menus = useSelector((state: IReducer) => state.RoleReducer?.listMenu);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    // const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
    // const hrClient = new HRClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);
    // const invClient = new LstInventoryClient(appSession, BASE_URL_API);

    const [isLoading, setLoading] = React.useState<boolean>(false);

    const [dataTable, setDataTable] = React.useState<QLCCUtilityBookingModel[]>([]);
    const [form] = Form.useForm();


    const [reload, setReload] = React.useState(false);

    // 

    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>();
    const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>(-1);

    //  

    const [actCx, setActCx] = React.useState<IAction>(defaultAction);
    const [actRj, setActRj] = React.useState<IAction>(defaultAction);
    const [actSearch, setActSearch] = React.useState<IAction>(defaultAction);
    const [filter, setFilter] = React.useState<IFilter>(defaultFilter);

    const [apartmentBuildingList, setApartmentBuildingList] = React.useState<any[]>([] as any[]);
    const [apartmentBuildingSelected, setApartmentBuildingSelected] = React.useState<any>();

    // React.useEffect(() => {

    //     /* tải danh sách dự án */

    //     qLCCClient
    //         .qlccApartmentBuildingGet({
    //             ...new QLCCApartmentBuildingModel(),
    //             ma_cty: SessionLogin.ma_cty,

    //             action_type: "GET"
    //         } as QLCCApartmentBuildingModel)
    //         .then(response => {
    //             let tempData: any = [];
    //             tempData = response.map(apartment_building => {
    //                 return {
    //                     key: apartment_building.id,
    //                     value: apartment_building.name
    //                 }
    //             })
    //             setApartmentBuildingList(tempData);
    //             setApartmentBuildingSelected(tempData[0])
    //         });
    // }, [])



    React.useEffect(() => {
        // Tải các dòng trong bảng

        const searchParams = new URLSearchParams(search);
        const id = searchParams.get('id');
        const getMenuWhenLinkTo = menus?.find(x => x.link_web?.toLowerCase() === window.location.pathname.toLowerCase());

        if (id && getMenuWhenLinkTo) {
            dispatch(getMenuShowGrid(getMenuWhenLinkTo))
            setLoading(true);

            setActCx({
                ...actCx,
                open: true,
                type: ACTIONS.APPROVED as string,
                payload: {
                    utility_booking_id: id,
                    apartment_building_id: apartment_building_id
                } as any,
            })
            // qLCCClient
            //     .qLCCUtilityBookingGet({
            //         ...new QLCCUtilityBookingModel(),
            //         ma_cty: SessionLogin?.ma_cty,
            //         username_login: SessionLogin.userName,
            //         apartment_building_id: apartment_building_id,
            //         type_id: "814C6394-7E12-4605-A865-070F3448D817",
            //         // action_type: "GET"
            //     } as QLCCUtilityBookingModel)
            //     .then((res) => {
            //         if (res) {
            //             // open action
            //             setDataTable(res);

            //             setActCx({
            //                 ...actCx,
            //                 open: true,
            //                 type: ACTIONS.APPROVED as string,
            //                 payload: res[0],
            //             })
            //         }
            //         setLoading(false);

            //     })
            //     .catch((err) => {

            //     })
        }

        // else if (filter) {
        //     onHandleFilter(filter);
        // }

        // else {
        //     qLCCClient
        //         .qLCCUtilityBookingGet({
        //             ...new QLCCUtilityBookingModel(),
        //             username_login: SessionLogin.userName,
        //             ma_cty: SessionLogin?.ma_cty,
        //             apartment_building_id: apartment_building_id,
        //             type_id: "814C6394-7E12-4605-A865-070F3448D817",
        //             // action_type: "GET"
        //         } as QLCCUtilityBookingModel)
        //         .then((res) => {
        //             setLoading(false);
        //             setDataTable(res);

        //         });
        // }



    }, [search, reload, apartment_building_id]);

    const handleApprovedMultiable = async (dataSelected: QLCCUtilityBookingModel[]) => {
        setLoading(true)
        const response = await qLCCClient.qLCCUtilityBookingApprovev2({
            ...new QLCCUtilityBookingModev2(),
            apartment_building_id: dataSelected[0]?.apartment_building_id,
            id: dataSelected.map(x => x.id).join(";"),
            luser: SessionLogin.userName,
            ldate: TransDateTime(new Date()),
            ma_cty: SessionLogin.ma_cty,
            username: SessionLogin.userName
        } as QLCCUtilityBookingModev2)
        setLoading(false)
        let isSuccessAll = true;
        response.every(res => {
            if (res.status === 1) {
                notification(convert[res.status!], res.message);

                isSuccessAll = false;
                return false;
            }

        })
        // Nếu duyệt tất cả đều thành công
        if (isSuccessAll) {
            notification(TYPE_MESSAGE.SUCCESS, STATUS_MESSAGE['APPROVED_0']);

            setReload(pre => !pre)
        }
    }



    const handleClickAction = async (action: SysActionType) => {

        // if (!apartmentBuildingSelected?.key) {
        //     notification(convert[1], "Vui lòng chọn dự án!")
        // }
        // else {
        switch (action?.action_code?.toLocaleUpperCase()) {
            // case ACTIONS.FILTER:
            //     setFilter({ ...filter, open: true })

            //     break;
            case ACTIONS.DELETE:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<QLCCUtilityBookingModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            // if (window.confirm("Bạn có chắc muốn xóa")) {
                            //     setLoading(true)
                            //     qLCCClient
                            //         .qLCCUtilityDel({
                            //             ...new QLCCUtilityModel(),
                            //             id: dataSelected?.id,
                            //             // tbname: menu?.component,
                            //             // action_type: "DELETE"
                            //         } as QLCCUtilityModel)
                            //         .then((res) => {
                            //             setLoading(false)
                            //             notification(convert[Number(res.status)], res.message);
                            //             if (res.status === 0) {
                            //                 const tempData = [...dataTable];
                            //                 const index = tempData.findIndex(x => x.id === res.id);
                            //                 if (index >= 0) {
                            //                     tempData.splice(index, 1);
                            //                     setDataTable(tempData);
                            //                 }
                            //             }
                            //             // setReload(!reload)
                            //         });
                            // }
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`DELETE_UNAVAILABLE`]
                            );
                        } else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.ADD:
                setActCx({
                    ...actCx,
                    open: true,
                    type: action?.action_code?.toUpperCase() ?? "",
                    payload: {
                        // service_id: selectedItemKeys?.id,
                        apartment_building_id: apartment_building_id
                    }
                });
                break;
            case ACTIONS.VIEW:
            case ACTIONS.EDIT:
            case ACTIONS.APPROVED:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getListDataRowSelected<QLCCUtilityBookingModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            if (dataSelected.length === 1) {
                                setActCx({
                                    ...actCx,
                                    open: true,
                                    type: action?.action_code?.toUpperCase() ?? "",
                                    payload: {
                                        utility_booking_id: dataSelected[0]?.id,
                                        ma_cty: dataSelected[0]?.ma_cty,
                                        apartment_building_id: apartment_building_id
                                    }
                                });
                            }
                            else if (dataSelected.length > 1) {
                                handleApprovedMultiable(dataSelected)
                            }
                        }
                        else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.REJECT:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getListDataRowSelected<QLCCUtilityBookingModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        var isValid_RJ = true;
                        if (dataGridRef.current && dataSelected && index >= 0) {
                            dataSelected.forEach(data => {
                                if (data?.status_code !== "1") {
                                    notification(
                                        TYPE_MESSAGE.WARNING,
                                        STATUS_MESSAGE[`UTILITY_REJECT_INVALID`]
                                    );
                                    isValid_RJ = false
                                }
                            });

                            if (isValid_RJ) {
                                setActRj({
                                    ...actRj,
                                    open: true,
                                    type: action?.action_code?.toUpperCase() ?? "",
                                    payload: {
                                        utility_bookings: dataSelected,
                                        apartment_building_id: apartment_building_id
                                    }
                                });
                            }

                        }
                        else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;

            case ACTIONS.CANCELAPPROVAL:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getListDataRowSelected<QLCCUtilityBookingModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);
                        var isValid_CA = true;
                        if (dataGridRef.current && dataSelected && index >= 0) {
                            dataSelected.forEach(data => {
                                if (data?.status_code !== "2") {
                                    notification(
                                        TYPE_MESSAGE.WARNING,
                                        STATUS_MESSAGE[`UTILITY_CANCEL_APPROVAL_INVALID`]
                                    );
                                    isValid_CA = false
                                }
                            });

                            if (isValid_CA) {
                                setActRj({
                                    ...actRj,
                                    open: true,
                                    type: action?.action_code?.toUpperCase() ?? "",
                                    payload: {
                                        utility_bookings: dataSelected,
                                        apartment_building_id: apartment_building_id
                                    }
                                });
                            }

                        }
                        else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;

            case ACTIONS.SEARCH:

                setActSearch({
                    ...actSearch,
                    open: true,
                    type: ACTIONS.EMPTY,
                    // payload: dataSelected // pass the data here
                });

                break;
        }
        // }


    };

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                console.log(keys);

                const index = e.component.getRowIndexByKey(keys[0]);

                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
            });
        }
    };

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };

    const handleChangeCellColor = (e: CellPreparedEvent<QLCCUtilityBookingModel, any>) => {
        // console.log(e.data?.status_id)
        if (e.column.dataField === "status_name" && e.data) {
            switch (e.data?.status_code) {

                case null:
                case "1":
                    e.cellElement.className += " utility-status utility-pending-status "
                    break;
                case "5":
                    e.cellElement.className += " utility-status utility-refuse-status "
                    break;
                case "2":
                    e.cellElement.className += " utility-status utility-approved-status "
                    break;
            }
        }


    }

    const onHandleFilter = async (filtered: IFilter) => {
        setLoading(true)
        const response = await qLCCClient.qLCCUtilityBookingFilter({
            ...new QLCCUtilityBookingFilterModel(),
            ...filtered,
            ma_cty: SessionLogin?.ma_cty,
            apartment_building_id: apartment_building_id,
            start_time: filtered?.start_time ? TransDateTime(filtered?.start_time) : null,
            end_time: filtered?.end_time ? TransDateTime(filtered?.end_time) : null,
            type_id: "814C6394-7E12-4605-A865-070F3448D817",
            username_login: SessionLogin.userName,
        } as QLCCUtilityBookingFilterModel);
        setLoading(false)
        setDataTable(response);

    }
    return (
        <div
            className="container-fluid p-2"
            style={{ maxHeight: "94vh !important", overflowY: "auto" }}
        >
            {
                actCx.open && <UtilityBookingAction
                    open={actCx.open}
                    onClose={function (): void {
                        setActCx(defaultAction)
                    }}
                    onEditSuccess={function (idEdited, dataEdited): void {
                        // if (idEdited) {
                        //     const tempData = [...dataTable];
                        //     const index = tempData.findIndex(x => x.id === idEdited);
                        //     if (index >= 0 && dataEdited) {
                        //         tempData[index] = dataEdited;
                        //         setDataTable(tempData);
                        //     }
                        // }
                        setReload(pre => !pre)
                    }}
                    onAddSuccess={(idAdded, dataAdded) => {
                        // if (idAdded) {
                        //     const tempData = [...dataTable];
                        //     if (dataAdded) {
                        //         tempData.unshift({...dataAdded, id: idAdded} as QLCCUtilityBookingModel)
                        //         setDataTable(tempData);
                        //     }
                        // }
                        setReload(pre => !pre)
                    }}
                    action={actCx.type}
                    itemSelected={actCx.payload} >

                </UtilityBookingAction>

            }
            {
                actRj.open && <ServiceBookingRejectAction
                    open={actRj.open}
                    onClose={function (): void {
                        setActRj(defaultAction)
                    }}
                    onEditSuccess={function (idEdited, dataEdited): void {
                        // if (idEdited) {
                        //     const tempData = [...dataTable];
                        //     const index = tempData.findIndex(x => x.id === idEdited);
                        //     if (index >= 0 && dataEdited) {
                        //         tempData[index] = dataEdited;
                        //         setDataTable(tempData);
                        //     }
                        // }
                        setReload(pre => !pre)
                    }}
                    onAddSuccess={(idAdded, dataAdded) => {
                        // if (idAdded) {
                        //     const tempData = [...dataTable];
                        //     if (dataAdded) {
                        //         tempData.unshift({...dataAdded, id: idAdded} as QLCCUtilityBookingModel)
                        //         setDataTable(tempData);
                        //     }
                        // }
                        setReload(pre => !pre)
                    }}
                    action={actRj.type}
                    itemSelected={actRj.payload} >

                </ServiceBookingRejectAction>

            }
            {/* Tìm dịch vụ action */}
            {
                (React.useMemo(() => (
                    <ServiceBookingSearchAction
                        open={actSearch.open}
                        onClose={function (): void {
                            setActSearch(pre => ({
                                ...pre,
                                open: false
                            }));
                            // setReload(pre => !pre);

                        }}
                        onApply={function (filtered): void {
                            onHandleFilter(filtered);
                            setFilter(filtered)
                            setActSearch(pre => ({ ...pre, open: false }));
                        }}

                        action={actSearch.type}
                        itemSelected={actSearch.payload} >

                    </ServiceBookingSearchAction>



                ), [actSearch.open, actSearch.payload, actSearch.type]))
            }




            <div className='row no-gutters my-1 align-items-center '>
                {/* Go back */}

                <div
                    className="d-flex col-md-12 col-xl-9 title-color"
                    style={{
                        height: 30,
                        color: "rgb(0 78 255 / 88%)",
                        marginTop: 15,
                        marginBottom: 15,
                        fontSize: 22,
                        fontWeight: 550,
                    }}
                >
                    <DHSBreadcrumb
                        location={location} />

                </div>
                {/* <div className="col-md-12 col-xl-3 pr-1 " style={{ marginLeft: 'auto' }}>

                    <Form
                        name="basic"
                        form={form}
                        layout="horizontal"
                        labelAlign="left"
                        // initialValues={{ requiredMarkValue: requiredMark }}
                        // onValuesChange={onRequiredTypeChange}

                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    // requiredMark={requiredMark}
                    // labelCol={{ span: 8 }}
                    >
                        <Form.Item
                            label={<span style={{ fontWeight: 600, fontSize: 14 }}>Chọn dự án</span>}
                            name="apartment_building_id"
                            hasFeedback
                            // required 
                            // tooltip="Thông tin mã khách hàng là bắt buộc"
                            rules={[
                                { required: true, message: 'Dự án là bắt buộc!' },
                            ]}

                        >

                            {
                                apartmentBuildingSelected && <AutoComplete

                                    size="middle"
                                    value={apartmentBuildingSelected ? apartmentBuildingList.find(item => item.key === apartmentBuildingSelected?.key) : null}
                                    defaultValue={apartmentBuildingSelected}
                                    options={apartmentBuildingList}
                                    onSelect={onApartmentBuildingSelect}
                                    // onSearch={ApartmentSearchInput}
                                    onChange={onApartmentBuildingChange}
                                    placeholder="Chọn dự án"
                                />
                            }
                        </Form.Item>
                    </Form>



                </div> */}
            </div>
            <DHSToolbarRole
                id={' '}
                menu={menu}
                customMenu={[]}
                onClickAction={handleClickAction}
            />

            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                keyExpr="id"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={true}
                handleSelectionChanged={handleSelectionChanged}
                handlePageChange={handlePageChange}
                handleCellPrepared={handleChangeCellColor}

            // handleExporting={handleExporting}
            />
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </div>
    );
};

export default ServiceBooking;
