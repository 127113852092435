import React from 'react'
import axios from 'axios';
import BASE_URL_API from '../../../../../shared/service-proxies/config';
import { Notification } from '../../../../../../components/layouts';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import AttachmentIcon from '@mui/icons-material/Attachment';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileSaver from 'file-saver';

import './style.css';
import { Tooltip } from '@mui/material';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../../../../common/Interfaces/StatusMessage';
import { ACTIONS } from '../../../../../../common/enums/DHSToolbarRoleAction';

interface IProps {
    action: string;
    path: any;
    setPath: (path_: any) => void; // link đường dẫn tập tin
    title?: string | undefined;
    singer?: boolean;
    disabled?: boolean;
    hint?: string;
}

export const TaskFileAttach: React.FC<IProps> = (props: IProps) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const { setPath, path } = props;

    const onChangeInput = (e: any) => {

        if (props.action !== "DETAIL" && props.action !== ACTIONS.VIEW && !props.disabled) {
            const { name, files } = e.target;
            const formData: FormData = new FormData();

            const options = {
                observe: "response",
                // responseType: "blob",
                headers: {
                    Accept: "application/json",
                    token: SessionLogin.Token
                },
            };

            for (const file of files) {
                formData.append("MyFile", file);
            }

            // Name Folder save in Server
            formData.append("tbName", 'QLCCTaskCheckList');
            formData.append("colName", 'attach_files');

            setLoading(true)

            axios
                .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
                .then((res) => {
                    if (res.data) {
                        if (setPath) {
                            // if (props.singer) {
                            // setPath([res.data[0]?.path]);
                            // } else {
                            setPath([...path, res.data[0]?.path]);
                            // }
                        }
                        setLoading(false)
                        // reset lại file input
                        e.target.value = '';
                    } else Notification(TYPE_MESSAGE.WARNING, "Không lấy được hình ảnh");
                    setLoading(false);
                });
        } else {
            Notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`UNAVAILABLE_FEATURE`]
            );
        }

    }

    const handleRemoveImage = (filePath: string) => {

        if (props.action !== "DETAIL" && props.action !== 'VIEW') {
            if (window.confirm("Xác nhận xóa tập tin?")) {
                const pathTemp: string[] = path.filter((file: any) => file !== filePath);
                setPath([...pathTemp]);
            }
        } else {
            Notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`UNAVAILABLE_FEATURE`]
            );
        }
    }

    const saveFile = (file: string) => {
        if (file) {
            const nameFile = file.split('/').reverse()[0];
            FileSaver.saveAs(
                BASE_URL_API + file,
                nameFile ? nameFile : "file-download.u",
                { autoBom: true }
            );
        }
    };

    return (
        <div>
            {
                props.action !== ACTIONS.VIEW && <>
                    <div title={props.hint ? props.hint : "Tập tin đính kèm"}>
                        <label htmlFor="uploadFile" className="attach-file-input-label">
                            <AttachmentIcon />
                            <span className="ps-2">Đính kèm tập tin</span>
                        </label>
                    </div>
                </>
            }

            <input
                id="uploadFile"
                style={{ display: 'none' }}
                accept="*"
                multiple
                type="file"
                onChange={onChangeInput}
                disabled={props.action === "DETAIL"}

            />
            {
                path.length > 0
                && path.map((pathItem: string, index: Number) => (
                    <div className='d-flex justify-content-between mt-2 p-2 file-attach-item-block'>
                        <Tooltip title={pathItem.split("/").reverse()[0]} arrow>
                            <div className='attach-file-item'>
                                <InsertDriveFileIcon />
                                <small
                                    className="cursor-pointer"
                                >
                                    {pathItem.split("/").reverse()[0]}
                                </small>
                            </div>
                        </Tooltip>
                        <div className="file-attach-item-right d-flex justify-content-between">
                            <Tooltip title="Tải xuống" arrow>
                                <FileDownloadIcon
                                    className="cursor-download-item"
                                    onClick={() => saveFile(pathItem)}
                                />
                            </Tooltip>
                            <Tooltip title="Xóa đi tập tin này" arrow>
                                <CloseIcon
                                    className='cursor-close-around'
                                    onClick={() => handleRemoveImage(pathItem)}
                                />
                            </Tooltip>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
