import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { components } from "../../../common/ComponentsConfig/components";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import notification from "../../../common/notification/notification";
import DHSToolbar from "../../../components/DHS/DHS-Toolbar/DHSToolbar";
import DHSGridView from "../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { getRole, getRoles } from "../../../redux/actions/RoleActions";
import { AppSession } from "../../shared/app-session/app-session";
import {
  DOCClient,
  HomeClient,
  RoleRightWebModel,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";

let appSession: AppSession;
const menuRoleName = "CRM";
const PortalDocGrid = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
  const { path, url } = useRouteMatch();
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const docClient = new DOCClient(appSession, BASE_URL_API);
  const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
  const [itemSelected, selectItem] = React.useState<string>();
  const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
  const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
  const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
  const [isShowSort, setShowSort] = React.useState<boolean>(false);
  const [isShowSelect, setShowSelect] = React.useState<boolean>(false);
  const [back, setBack] = React.useState<number>(-1);
  const customMenu = [
    {
      menuName: isShowSelect ? "Ẩn Select Box" : "Hiện Select Box",
      onClick: () => {
        setShowSelect(!isShowSelect);
      },
    },
    {
      menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
      onClick: () => {
        setShowFilter(!isShowFilter);
      },
    },
    {
      menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
      onClick: () => {
        setShowSearch(!isShowSearch);
      },
    },
    {
      menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
      onClick: () => {
        setShowGroup(!isShowGroup);
      },
    },
    {
      menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
      onClick: () => {
        setShowSort(!isShowSort);
      },
    },
  ];

  React.useEffect(() => {
    homeClient
      ?.roleRightWebGetAll({
        ...new RoleRightWebModel(),
        rolename: menuRoleName,
        menuid: menu?.menuid,
      } as RoleRightWebModel)
      .then((res) => {
        dispatch(getRoles(res));
        setRoles(res);
      });
  }, []);

  const handleClickAction = (action: RoleRightWebModel) => {
    if (action.class_name?.toLocaleUpperCase() === "DELETE") {
      if (window.confirm("Bạn chắc chắn muốn thực hiện hành động này chứ")) {
        switch (action.component) {
          case "Product":
            docClient.docUserGuideProductionDel(itemSelected).then((res) => {
              result(res.ret);
            });
            break;
          case "Module":
            docClient
              .docUserGuideProductionModuleDel(itemSelected)
              .then((res) => {
                result(res.ret);
              });
            break;
          case "Feature":
            docClient
              .docUserGuideProductionFeatureDel(itemSelected)
              .then((res) => {
                result(res.ret);
              });
            break;
          case "Tag":
            docClient.docUserGuideProductionTagDel(itemSelected).then((res) => {
              result(res.ret);
            });
            break;
          default:
            result(0);
            break;
        }
      }
    } else dispatch(getRole(action));
  };
  const result = (data: number | undefined) => {
    if (data === 0) {
      setBack((item) => (item -= 1));
      notification("success", "Thành công");
      window.location.reload();
    } else notification("error", "Có lỗi xảy ra");
  };

  return (
    <div className="container-fluid p-1">
      <Switch>
        <Route path={path} exact>
          <div
            className="d-flex title-color"
            style={{
              height: 30,
              color: "rgb(0 78 255 / 88%)",
              marginTop: 15,
              marginBottom: 15,
              fontSize: 22,
              fontWeight: 550,
            }}
          >
            <i
              className="bi bi-arrow-left"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.go(back);
              }}
            ></i>
            &nbsp;&nbsp;
            {menu?.bar}
          </div>{" "}
          <DHSToolbar
            id={itemSelected}
            list={
              roles
                ? roles.filter(
                    (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
                  )
                : []
            }
            onClickAction={handleClickAction}
            customMenu={customMenu}
          />
          {/* <DHSGridView
            onSelectRow={(row) => {
              selectItem(row);
            }}
            // onListSelectRow={handleListOnselectRow}
            itemClicked={function (): void {
              throw new Error("Function not implemented.");
            }}
            table_name={
              menu?.component ? menu.component : menu?.menuid ? menu.menuid : ""
            }
            show_form_list={true}
            showFilterPanel={isShowFilter}
            showSortPanel={isShowSort}
            showSearchPanel={isShowSearch}
            showGroupPanel={isShowGroup}
            showSelectBox={isShowSelect}
          /> */}
        </Route>
        {roles &&
          roles.map((role, index) => {
            return (
              role.link &&
              role.link.length > 1 &&
              role.component && (
                <Route
                  key={index}
                  path={role.is_parameter ? role.link + ":_id" : role.link}
                  component={components[role.component.toLocaleUpperCase()]}
                  exact
                ></Route>
              )
            );
          })}
      </Switch>
    </div>
  );
};

export default PortalDocGrid;
