import React from 'react';

import BarChart, { ArgumentAxis, CommonSeriesSettings, Crosshair, Export, Font, Format, IChartOptions, Label, Legend, ScrollBar, Series, SeriesTemplate, Title, Tooltip, ZoomAndPan } from 'devextreme-react/chart';
import { Palette } from 'devextreme/common/charts';
import { NativeEventInfo } from 'devextreme/events';
import { PointInteractionInfo } from 'devextreme/viz/chart_components/base_chart';
import dxChart from 'devextreme/viz/chart';
import { Box } from '@mui/material';
import DHSButton from '../../actions-and-list/DHSButton/DHSButton';
import _ from 'lodash';


interface ISeries {
    valueField: string,
    axis?: string,
    type?: "area" | "bar" | "bubble" | "candlestick" | "fullstackedarea" | "fullstackedbar" | "fullstackedline" | "fullstackedspline" | "fullstackedsplinearea" | "line" | "rangearea" | "rangebar" | "scatter" | "spline" | "splinearea" | "stackedarea" | "stackedbar" | "stackedline" | "stackedspline" | "stackedsplinearea" | "steparea" | "stepline" | "stock",
    color?: string,
    name: string
}
interface IProps {
    dataSource: any[],
    title?: string,
    palette?: string[] | Palette,
    argData: any,
    valueData: any | ISeries[],
    parentIDData: any,
    name?: any,
    color?: any,
    labelVisiable?: boolean,
    customizeTooltip?: any,
    legendHorizontalAlignment?: "left" | "right" | "center",
    legendVerticalAlignment?: "top" | "bottom"
    exportEnable?: boolean,
    tooltipEnable?: boolean,
    legendEnable?: boolean,
    customizeSeries?: any,
    customizeText?: any,
    height?: string | number | (() => string | number),
    customizeDatasource?: (data: IDHSBarChartDrillDown, dataSource: any[], valueField: string) => void
    onPointClick?: (e: NativeEventInfo<dxChart, MouseEvent | PointerEvent> & PointInteractionInfo) => void

}

interface IDHSBarChartDrillDown {
    arg: any,
    parentID: any,
    value: any,
    [key: string]: any,
}

const DHSBarChartDrillDown: React.FC<IProps> = (props) => {

    const [originDataSource, setOriginDataSource] = React.useState<any[]>([]);
    const [dataSourceDrillDown, setDataSourceDrillDown] = React.useState<IDHSBarChartDrillDown[]>([]);
    const [valueData, setValueData] = React.useState<any | ISeries[]>(props?.valueData);
    //

    const [isFirstLevel, setIsFirstLevel] = React.useState<boolean>(true);
    const [seriesFilter, setSeriesFilter] = React.useState<string>();

    React.useEffect(() => {
        console.log(dataSourceDrillDown)
    }, [dataSourceDrillDown])
    React.useEffect(() => {
        setSeriesFilter(props?.valueData[0].valueField)
        setValueData(props?.valueData)
    }, [])

    React.useEffect(() => {
        if (isFirstLevel) {
            setValueData(props?.valueData)
        }
    }, [isFirstLevel])
    React.useEffect(() => {
        if (props?.dataSource?.length > 0) {
            const tempDataDrillDown: IDHSBarChartDrillDown[] = [];
            for (const data of props?.dataSource) {
                const newData = {
                    arg: data[props.argData],
                    parentID: data[props.parentIDData].toString(),
                } as IDHSBarChartDrillDown;

                if (Array.isArray(props?.valueData)) {
                    props?.valueData?.map((series: ISeries) => {
                        newData[series.valueField] = data[series.valueField];
                    })
                } else {
                    newData["value"] = data[props.valueData];

                }
                tempDataDrillDown.push(newData)
            }

            const dataSourceGrouped = _.groupBy(props?.dataSource, props.parentIDData);
            for (const key in dataSourceGrouped) {
                const newData = {
                    arg: key,
                    parentID: "",
                } as IDHSBarChartDrillDown;
                if (Array.isArray(props?.valueData)) {
                    props?.valueData?.map((series: ISeries) => {
                        if (props?.customizeDatasource) {
                            props?.customizeDatasource(newData, dataSourceGrouped[key], series.valueField)
                        } else {

                            newData[series.valueField] = _.sumBy(dataSourceGrouped[key], series.valueField);
                        }
                    })
                } else {
                    newData["value"] = _.sumBy(dataSourceGrouped[key], props?.valueData);

                }
                tempDataDrillDown.push(newData)
            }
            console.log(tempDataDrillDown, "tem")
            setOriginDataSource(tempDataDrillDown);
            setDataSourceDrillDown(tempDataDrillDown?.filter(x => x.parentID === ""))
            setIsFirstLevel(true)
        }
    }, [props?.dataSource])


    return <Box sx={{ position: "relative" }}><BarChart
        dataSource={dataSourceDrillDown}
        // title={props?.title}
        palette={props?.palette ?? "Material"}
        onPointClick={(e) => {
            try {
                setSeriesFilter(e.target.series.name)
                if (Array.isArray(valueData)) {
                    const tempValueData = [...valueData].filter(x => x.name === e.target.series.name)
                    setValueData(tempValueData)

                }
                if (isFirstLevel) {
                    setDataSourceDrillDown([...originDataSource].filter(x => x.parentID === e.target.originalArgument))
                    setIsFirstLevel(false)
                }
            } catch (error) {
                console.error(error)
            }
        }}
        height={props?.height}
    >
        {props?.children}
        <Title
            horizontalAlignment={"left"}
            text={props?.title}>
            <Font color={"#94a3b8"} size={"18px"} weight={700} />

        </Title>

        <CommonSeriesSettings
            argumentField={"arg"}
            valueField={!Array.isArray(props?.valueData) ? "value" : undefined}
            type="bar"
            ignoreEmptyPoints={true}
        >
            <Label visible={props?.labelVisiable ?? false} textOverflow={"none"}
                customizeText={props?.customizeText}>

                <Format type="fixedPoint" precision={0} />
            </Label>
        </CommonSeriesSettings>
        {(Array.isArray(valueData)) ? valueData?.map((series: ISeries) => (
            <Series
                key={series.valueField}
                argumentField={"arg"}
                valueField={series.valueField}
                name={series.name}
                type={series.type}
                axis={series.axis}
                color={series.color}
                tag={{
                    valueField: series
                }}

            />
        )) : <SeriesTemplate nameField={"arg"} customizeSeries={props?.customizeSeries} />}



        <ArgumentAxis>
            <Label
                staggeringSpacing={1}
                rotationAngle={45}
                displayMode={"rotate"}
            />
        </ArgumentAxis>
        <Crosshair enabled={true} label={{ visible: true }}   />
        <ScrollBar visible={true} color={"#ddd"} opacity={0.7} width={5}  />

        <ZoomAndPan argumentAxis="both" allowTouchGestures={true} />
        <Tooltip
            enabled={props?.tooltipEnable ?? false}
            location="edge"
            customizeTooltip={props?.customizeTooltip}
        />
        <Legend
            visible={props?.legendEnable ?? false}
            margin={0}
            horizontalAlignment={props?.legendHorizontalAlignment}
            verticalAlignment={props?.legendVerticalAlignment}
            itemsAlignment={"left"}
        />
        <Export enabled={props?.exportEnable ?? false} />
    </BarChart>
        {
            !isFirstLevel && <DHSButton style={{
                textAlign: "center",
                height: "40px",
                position: "absolute",
                top: "0.25rem",
                left: "1rem",
            }}
                text="Back"
                icon="chevronleft"
                // visible={!this.state.isFirstLevel}
                onClick={() => {
                    try {
                        setDataSourceDrillDown([...originDataSource].filter(x => x.parentID === ""))
                        setIsFirstLevel(true)
                    } catch (error) {
                        console.error(error)
                    }
                }}
            />
        }
    </Box>
}

export default DHSBarChartDrillDown;