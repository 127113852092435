import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { AppBar, Autocomplete, Box, Divider, FormControl, Grid, IconButton, Paper, TextField, Toolbar, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import BASE_URL_API from '../../../shared/service-proxies/config';
import { useSelector } from 'react-redux';
import { IReducer, Notification } from '../../../../components/layouts';
import DHSGridView from '../../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
import { QLCCApartmentBuildingModel, QLCCClient, QLCCVehicleCardModel, QLCCVehicleInOutModel } from '../../../shared/service-proxies/api-shared';
import { AppSession } from '../../../shared/app-session/app-session';
import CloseIcon from "@mui/icons-material/Close";
import BlockUI from '../../../../components/Block-UI/Block-ui';
import { debug } from 'console';
import DHSAutoCompleteWithDataSource from '../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource';



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    onClose: () => void;
    onEditSuccess: () => void;
    // uploadFile: (data: any) => void;
    apartmentBuildings: QLCCApartmentBuildingModel[];
}
var appSession: AppSession;


export default function VehicleCardImportFileAction(props: IProps) {

    const [file, setFile] = React.useState<any>();
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [dataTable, setDateTable] = React.useState<any>([]);
    const [isLoading, setLoading] = React.useState<boolean>(false)
    const qlccClient = new QLCCClient(appSession, BASE_URL_API);

    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
    const [isShowSort, setShowSort] = React.useState<boolean>(false);
    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
    const customMenu = [
        {
            menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
            onClick: () => {
                setShowSearch(!isShowSearch);
            },
        },
        {
            menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
            onClick: () => {
                setShowGroup(!isShowGroup);
            },
        },
        {
            menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
            onClick: () => {
                setShowSort(!isShowSort);
            },
        },
        {
            menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
            onClick: () => {
                setShowFilter(!isShowFilter);
            },
        },
    ];

    const [apartmentBuilding, setApartmentBuilding] = React.useState<QLCCApartmentBuildingModel>({} as QLCCApartmentBuildingModel);

    const refFile = React.useRef<any>();

    const handleChangeInput = (e: any) => {

        const { name, value } = e.target;

        if (name === 'apartmentBuilding') {

            const apartmentBuildingSelect: any = props.apartmentBuildings.find(item => item.id === value);

            if (apartmentBuildingSelect) {
                setApartmentBuilding(apartmentBuildingSelect);
            }

        }

    };

    const handleClose = () => {
        props.onClose();
    };

    const handleChangeFile = (e: any) => {
        const { files } = e.target;

        if (files) {
            setFile(files[0]);
            uploadFile(files[0]);
        }
    }


    const uploadFile = (file: any) => {

        setLoading(true);
        const formData = new FormData();

        const options = {
            observe: "response",
            headers: {
                Accept: "application/json",
            },
        };

        formData.append("file", file);
        formData.append("tbName", "QLCCVehicleInOut");
        formData.append("menuid", menu?.menuid!);

        axios
            .post(
                `${BASE_URL_API}/api/Management/ProcessExcelFile`,
                formData,
                options,
            )
            .then((res) => {
                console.log(res.data)
                const newArr = JSON.parse(res.data);
                console.log(newArr);
                setDateTable([...newArr]);
                setLoading(false);

            }).catch(err => {
                console.log(err)
                setLoading(false);
            });
    };

    const handleSubmit = () => {
        if (dataTable.length > 0) {

            setLoading(true);
            const newArr: QLCCVehicleInOutModel[] = [];

            dataTable.map((row: any) => {
                const newRow: QLCCVehicleInOutModel = {
                    ... new QLCCVehicleInOutModel(),
                    ...row,
                    time_in: new Date(row.time_in),
                    time_out: new Date(row.time_out),

                } as QLCCVehicleInOutModel;
                newArr.push(newRow);
            })

            qlccClient.qLCCVehicleCardInOutIns(newArr)
                .then(res => {
                    setLoading(false);

                    Notification('success', "Đã thêm dữ liệu vào hệ thống")
                    props.onEditSuccess();
                    props.onClose();
                })

                .catch(err => {
                    setLoading(false);
                    console.log(err);
                });
        } else {
            Notification('warning', "Không có dữ liệu để thêm")
        }
    }

    return (
        <Dialog
            // id="sysMenu6"
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            fullScreen

        >
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        Thêm dữ liệu từ tập tin
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent className='d-flex flex-column align-items-end'>
                <Box className="w-100 mb-2">
                    <Grid container spacing={2} className='d-sm-flex justify-content-end'>
                        <Grid item xs={12} sm={6} md={4} xl={2}>
                            <DHSAutoCompleteWithDataSource
                                id="apartment-building-select"
                                className="cc-input"
                                dataSource={props.apartmentBuildings}
                                displayMember="name"
                                valueMember="id"
                                value={apartmentBuilding?.id}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "apartmentBuilding",
                                            value: newValue?.id ?? null,
                                        },
                                    });
                                }}
                            />
                            {/* <Autocomplete
                                id="gender-select"
                                sx={{ width: "100%" }}
                                options={props.apartmentBuildings}
                                autoHighlight
                                getOptionLabel={(option: any) => option?.name}
                                value={
                                    apartmentBuilding?.id
                                        ? props.apartmentBuildings.find(
                                            (item: any) => item.id === apartmentBuilding.id
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "apartmentBuilding",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        // label="Dự án"
                                        placeholder='Chọn dự án'
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            /> */}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} xl={2}>
                            <FormControl >
                                <Button variant='contained' className="p-0">
                                    <label htmlFor='file' className='w-100 h-100 m-0 pt-1 pb-1 p-2'>
                                        Chọn tệp tin
                                    </label>
                                </Button>
                                <input
                                    style={{
                                        display: "none"
                                    }}
                                    type="file"
                                    id="file"
                                    accept=".xlsx, .xls, .csv"
                                    ref={refFile}
                                    onClick={() => refFile.current.value = ""}
                                    onChange={handleChangeFile}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>

                <DHSGridView

                    onSelectRow={(row) => console.log(row)}
                    itemClicked={function (): void {
                        throw new Error("Function not implemented.");
                    }}
                    table_name={menu?.component ?? ''}
                    show_form_list={true}
                    showSelectBox={false}
                    data={dataTable}
                    showSortPanel={isShowSort}
                    showSearchPanel={isShowSearch}
                    showGroupPanel={isShowGroup}
                    showFilterPanel={isShowFilter}
                    defaultPageSize={100}
                    grid_name={"Danh sách phản ánh"}
                    excel={false}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Đóng</Button>
                <Button onClick={handleSubmit} variant="contained">Thêm</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
}