import React, { Component } from "react";
import { Row, Col } from "antd";
import Pie from "../../../ChartType/pieChart";
import Bar from "../../../ChartType/bar";
import { toLowerPropertyNameByArray } from "../../../../../controller/Format";
import * as actions from "../../../../../../redux/actions/Manufacturing/MachineRpt/index";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";

const Reports = (props: any) => {
    const [model, setModel] = React.useState<any>({
        fullDayPie: [],
        totalDuration: 1,
        arrayShift1: [],
        arrayShift2: [],
        arrayShift3: [],
    });
    const calcTotalDuration = (array: any) => {
        var value = 0;
        array.map((item: any) => {
            value += item.duration;
        });
        return value;
    };
    const getFullDayPie = (arr: any) => {
        const object = _.groupBy(arr, "state_description");
        var total = 0;
        var array = [];
        for (var key in object) {
            const value = _.sumBy(object[key], "duration");
            total += value;
            array.push({
                state_description: key,
                machine_code: object[key][0].machine_code,
                duration: value,
            });
        }
        setModel({ ...model, totalDuration: total, fullDayPie: array });
    };
    const getShiftPie = (array: any) => {
        const filter = (shift: any) => {
            const value = _.filter(array, (item) => {
                return item.shift === shift;
            });
            return value;
        };
        const arrayShift1 = filter(1);
        const arrayShift2 = filter(2);
        const arrayShift3 = filter(3);
        setModel({ ...model, arrayShift1, arrayShift2, arrayShift3 });
    };
    const componentDidUpdate = (prevProps: any) => {
        if (!_.isEqual(props.machineWorkShift, prevProps.machineWorkShift)) {
            toLowerPropertyNameByArray(props.machineWorkShift);
            getFullDayPie(props.machineWorkShift);
            getShiftPie(props.machineWorkShift);
        }
    };
    // const { machineName, machineWorkShift } = this.props;
    // const {
    //     fullDayPie,
    //     totalDuration,
    //     arrayShift1,
    //     arrayShift2,
    //     arrayShift3,
    // } = this.state;
    const tranferToHours = (value: any) => {
        let convert = (value: any) => {
            return value >= 10 ? value : "0" + value;
        };
        let hours = Math.floor(value / 60 / 60);
        let minutes = Math.floor((value / 60) % 60);
        let seconds = value % 60;
        return convert(hours) + ":" + convert(minutes) + ":" + convert(seconds);
    };
    const customerText = (item: any) => {
        return tranferToHours(item.value);
    };
    const customizeTooltip = (arg: any) => {
        return {
            text: `${arg.argument}
      ${arg.seriesName} Time: ${tranferToHours(arg.originalValue)}
      ${arg.seriesName}:${arg.percentText}
      `,
        };
    };
    return (
        <div className="reportsMachineTimeLine">
            <div>
                <p className="text" style={{ color: '#fff' }}>Shift Reports</p>
                <p className="textChild">{props.machineName}</p>
            </div>
            <Row gutter={[16, 16]} justify="space-around">
                <Col className="gutter-row" xs={24} md={12} xl={8}>
                    <Pie
                        legendVisible={false}
                        title="Full Day"
                        dataSource={model.fullDayPie.length > 0 ? model.fullDayPie : []}
                        totalDuration={model.totalDuration}
                    />
                </Col>
                <Col className="gutter-row" xs={24} md={12} xl={7}>
                    <Bar
                        dataSource={model.fullDayPie}
                        type="stackedBar"
                        title="Run Time vs Stop Time"
                        descriptionField="state_description"
                        argumentField="machine_code"
                        valueField="duration"
                        scroll={false}
                        legendVisible={true}
                        customerText={customerText}
                        customizeTooltip={customizeTooltip}
                    />
                </Col>
                <Col className="gutter-row" xs={24} md={12} xl={8}>
                    <Bar
                        dataSource={props.machineWorkShift}
                        type="stackedBar"
                        title="Run Time vs Stop Time"
                        descriptionField="STATE_DESCRIPTION"
                        argumentField="SHIFT"
                        valueField="DURATION"
                        scroll={false}
                        legendVisible={true}
                        customerText={customerText}
                        customizeTooltip={customizeTooltip}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]} justify="space-around">
                <Col className="gutter-row" xs={24} md={12} xl={8}>
                    <Pie
                        title="Shift 1"
                        legendVisible={false}
                        dataSource={model.arrayShift1}
                        totalDuration={calcTotalDuration(model.arrayShift1)}
                    />
                </Col>
                <Col className="gutter-row" xs={24} md={12} xl={7}>
                    <Pie
                        title="Shift 2"
                        legendVisible={false}
                        dataSource={model.arrayShift2}
                        totalDuration={calcTotalDuration(model.arrayShift2)}
                    />
                </Col>
                <Col className="gutter-row" xs={24} md={12} xl={8}>
                    <Pie
                        title="Shift 3"
                        legendVisible={false}
                        dataSource={model.arrayShift3}
                        totalDuration={calcTotalDuration(model.arrayShift3)}
                    />
                </Col>
            </Row>
        </div>
    );

}


export default Reports;
