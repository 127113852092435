import React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,

    Divider,
    DialogTitle,
    DialogContentText,
    StepIconProps,
    styled,
    Stepper,
    Step,
    StepLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    HomeClient,
    QLCCClient,
    RoleRightWebModel,
    SysActionType,
    QLCCUtilityBookingModel,
    CaVch01Client,
    PaymentTerm,
    SysExchange,
    LstCurrency,
    LstUnitModel,
    QLCCUtilityBookingStatusModel,
    QLCCUtilityModel,
} from "../../../shared/service-proxies/api-shared";


import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useDispatch, useSelector } from "react-redux";
import { ICustomer, IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import "../../actions/ApartmentBuilding.css";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { ArrowBack, ArrowForward, ConfirmationNumberRounded, EventSeatRounded, PaymentRounded } from "@mui/icons-material"
import UtilityBookingMaster from "./ServiceBookingMaster";

import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import UtilityBookingReceipt from "../receipt/ServiceBookingReceipt";
import { PushCurrencyOptions, PushCustomerOptions, PushExchangeOptions, PushPaymentTermOptions, PushUnitOptions, PushUtilityOptions } from "../../../../redux/actions/Option/OptionAction";
import { httpPost } from "../../../../common/httpService";

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (id?: string, dataEdited?: QLCCUtilityBookingModel) => void;
    onAddSuccess: (id?: string, dataAdded?: QLCCUtilityBookingModel) => void;
}
// interface IOpenCamera {
//     pic: any;
//     open: boolean;
// }

// const videoConstraints = {
//     width: 1280,
//     height: 720,
//     facingMode: "user",
// };

// const image_size_citizen_identification_card = {
//     width: 540,
//     height: 360,
// };

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

//#region styled stepper

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)',
    }),
}));


function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <MiscellaneousServicesIcon />,
        2: <PointOfSaleIcon />,
        3: <PaymentRounded />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}


//#endregion


const status_message = ["success", "error"]
var appSession: AppSession;
var htmlString: string;
const ServiceBookingAction: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();

    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const [isDefaultPre, setIsDefaultPre] = React.useState<boolean>();

    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);

    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [totalApartmentMessage, setTotalApartmentMessage] = React.useState<JSX.Element>();
    const [actionUpdateService, setActionUpdateService] = React.useState<string>();
    const [fullScreen, setFullscreen] = React.useState<boolean>(false);
    const [paymentTermOptions, setPaymentTermOptions] = React.useState<any[]>([]);
    const caVch01Client = new CaVch01Client(appSession, BASE_URL_API);



    //

    const [preventNext, setPreventNext] = React.useState(false);
    const [activeError, setActiveError] = React.useState(0);
    const [steps, setSteps] = React.useState([] as { label: string, component: React.ReactNode }[]);
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});


    React.useEffect(() => {
        setSteps([{
            label: 'Đặt dịch vụ',
            component: <UtilityBookingMaster {...props} />
        }, {
            label: 'Thu tiền',
            component: <UtilityBookingReceipt open={props.open} action={props.action} />
        }, {
            label: 'Kiểm tra và xác nhận',
            component: <></>
        }])
        // 

        setLoading(true)
        caVch01Client.lstPaymentTermGet({
            ...new PaymentTerm(),
            ma_cty: SessionLogin.ma_cty,
            moduleid: 'CA'
        } as PaymentTerm)
            .then(res => {

                setLoading(false);
                dispatch(PushPaymentTermOptions(res))


            });

        setLoading(true)
        caVch01Client.sysExchangeGet({
            ...new SysExchange(),
            ma_ct: "CA1",

        } as SysExchange)
            .then(res => {

                setLoading(false);
                dispatch(PushExchangeOptions(res))

            });

        // 

        // setLoading(true)
        // caVch01Client.lstPaymentTermGet({
        //     ...new PaymentTerm(),
        //     ma_cty: SessionLogin.ma_cty,
        //     moduleid: 'CA'
        // } as PaymentTerm)
        //     .then(res => {

        //         setLoading(false);
        //         setPaymentTermOptions(res)

        //         // setData(pre => ({
        //         //     ...pre,
        //         //     modelM: {
        //         //         ...pre.modelM,
        //         //         ma_httt: res[0].ma_httt
        //         //     },
        //         // } as ModelProcessca01));
        //         dispatch(saveChangeReceiptM({
        //             modelM: {
        //                 ma_httt: res[0].ma_httt
        //             },

        //         } as ModelProcessca01))
        //     });

        // 
        setLoading(true)
        caVch01Client.lstCurrencyGet({
            ...new LstCurrency(),
            ma_cty: SessionLogin.ma_cty,
        } as LstCurrency)
            .then(res => {

                setLoading(false);
                dispatch(PushCurrencyOptions(res))


            });


        // 

        setLoading(true)
        const body = {
            ma_cty: SessionLogin.ma_cty,
            tablelist: "dm_kh"
        }
        httpPost<string>('api/Common/LoadFilterList', body)
            .then(res => {

                const customers: ICustomer[] = JSON.parse(res.data);
                dispatch(PushCustomerOptions(customers))
                setLoading(false)

            });
        httpPost<string>('api/Common/LoadFilterList', {
            ma_cty: SessionLogin.ma_cty,
            tablelist: "dm_dich_vu"
        })
            .then(res => {

                const utilities: QLCCUtilityModel[] = JSON.parse(res.data);
                dispatch(PushUtilityOptions(utilities))
                setLoading(false)

            });


        qLCClient.qlccLstUnitGet({
            ...new LstUnitModel(),
            ma_cty: SessionLogin.ma_cty
        } as LstUnitModel)
            .then(res => {
                setLoading(false);
                dispatch(PushUnitOptions(res))
            })

    }, [])

    // const handleSubmit = (action?: string) => {
    //     setLoading(true)
    //     if (_id || props.action === ACTIONS.EDIT) {

    //         qLCClient.qLCCUtilityBookingUpd({
    //             ...new QLCCUtilityBookingModel(),
    //             ...data,
    //             start_time: data.start_time ? TransDateTime(data.start_time) : null,
    //             end_time: data.end_time ? TransDateTime(data.end_time) : null,
    //             // description: htmlString,
    //         } as QLCCUtilityBookingModel)
    //             .then((response) => {
    //                 setLoading(false);
    //                 notification(status_message[response.status!], STATUS_MESSAGE[`${props.action}_${response.status}`]);
    //                 if (response.status === 0) {
    //                     props.onEditSuccess(response.id, data);
    //                     props.onClose();
    //                 }


    //             });



    //     }
    //     else {
    //         qLCClient.qLCCUtilityBookingIns({
    //             ...new QLCCUtilityBookingModel(),
    //             ...data,
    //             start_time: data.start_time ? TransDateTime(data.start_time) : null,
    //             end_time: data.end_time ? TransDateTime(data.end_time) : null,
    //             // description: htmlString,
    //         } as QLCCUtilityBookingModel)
    //             .then((response) => {
    //                 setLoading(false);
    //                 notification(status_message[response.status!], STATUS_MESSAGE[`${props.action}_${response.status}`])

    //                 if (response.status === 0) {
    //                     props.onAddSuccess(response.id, data);
    //                     props.onClose();
    //                 }
    //             });
    //     }
    // };



    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = () => {


        // if (isDefaultPre === true && data?.is_default === false) {

        //     qLCClient.qLCCApartmentsGet({
        //         ...new QLCCApartmentModel(),
        //         service_id: data?.id,
        //         apartment_building_id: data?.apartment_building_code,
        //         apartment_type_id: data?.apartment_type_id,
        //         action_type: 'GET_TOTAL_SERVICE_APARTMENT_BY_SERVICEID'
        //     } as QLCCApartmentModel)
        //         .then(res => {
        //             setLoading(false);
        //             const totalApartmentMessage = (
        //                 <div>
        //                     Có tổng <span style={{ fontWeight: 700, fontSize: "16px", color: 'red' }}>
        //                         {Number.parseInt(res[0].message!)}
        //                     </span> căn hộ đang sử dụng phí này. Bạn chăc chắn muốn xóa mặt định?
        //                 </div>
        //             );
        //             setActionUpdateService("UPDATE")

        //             setTotalApartmentMessage(totalApartmentMessage)
        //         });
        // }
        // else {

        setTotalApartmentMessage(<>Bạn chắc chắn muốn lưu</>);
        setActionUpdateService("UPDATE-BUT-NOT-DELETE")

        // }

        setOpen(true)
    }

    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:

                // handleSubmit();             
                handleOpen()
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
        }
    }

    //#region stepper

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        console.log(activeStep, "active step next")
        if (activeStep === 1) {
            // làm gì đó here
        }

        // không next đến bước 3 nếu như chọn ghế không đúng
        if (!preventNext && activeStep !== 1) {
            const newActiveStep =
                isLastStep() && !allStepsCompleted()
                    ? // It's the last step, but not all steps have been completed,
                    // find the first step that has been completed
                    steps.findIndex((step, i) => !(i in completed))
                    : activeStep + 1;
            setActiveStep(newActiveStep);
        }

    };

    const handleBack = () => {

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        console.log(activeStep, "active step back")

    };

    const handleStep = (step: number) => {
        console.log(step, "handle step")

        if (step === 2) {
            // làm gì đó here
            return;
            console.log(preventNext, "in check select")

        }
        // không next đến bước 3 nếu như chọn ghế không đúng

        console.log(preventNext, step !== 2, "!preventNext && step !== 2")

        if (preventNext && step !== 2) {
            console.log(preventNext, "prevent next true")

            setActiveStep(step);

            return;

        }
        console.log(preventNext, step !== 2, "prevent after reuturn")

        if (!preventNext) {
            setActiveStep(step);
        }



    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    //#endregion
    return (
        <Dialog
            // id={!fullScreen ? "sysMenu84" : ""}
            fullScreen={fullScreen}
            open={props.open}
            onClose={() => {
                props.onClose()
                // setData(new ModelProcessca01())
            }}
            PaperProps={{
                sx: fullScreen ? undefined : {
                    minHeight: "80vh",
                    height: "80vh"
                }
            }}

            TransitionComponent={Transition}
            // fullWidth
            maxWidth="xl"
            fullWidth
            className="Asset-control"

        >
            {/* <Dialog
                open={openCamera.open}
                onClose={handleCloseCamera}
                sx={{ height: "inherit" }}
            >
                
            </Dialog> */}

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${props.action === ACTIONS.VIEW
                                ? "[Chi tiết]"
                                : props.action === "UPDATE" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1.25 }}>
                        <IconButton
                            edge="end"

                            onClick={() => {
                                setFullscreen(pre => !pre)
                            }}
                            aria-label="full screen"
                        >
                            {fullScreen ? <FullscreenExitIcon sx={{ color: '#1f2937' }} /> : <FullscreenIcon sx={{ color: '#1f2937' }} />}
                        </IconButton>
                        <IconButton
                            edge="end"
                            onClick={() => {
                                props.onClose();
                                // setData(new ModelProcessca01())
                            }}
                            aria-label="close"
                        >
                            <CloseIcon
                                sx={{ color: '#1f2937' }}
                            />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <Box sx={{ width: '100%' }} >
                    <Stepper nonLinear activeStep={activeStep} >
                        {steps.map((step, index) => (
                            <Step key={step.label} completed={completed[index]}>
                                <StepLabel StepIconComponent={ColorlibStepIcon} color="inherit" onClick={() => handleStep(index)} sx={{ cursor: preventNext ? "not-allowed" : "" }}>
                                    {step.label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        {allStepsCompleted() ? (
                            <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    Các bước đặt của bạn đã hoàn thành
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleReset}>Đặt lại</Button>
                                </Box>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Box sx={{ mt: 2, mb: 1, py: 1 }}>
                                    {steps[activeStep].component}
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button
                                        variant="outlined"
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        startIcon={<ArrowBack />}
                                        sx={{ mr: 1 }}

                                    >

                                        Quay lại
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button
                                        variant="outlined"
                                        onClick={handleNext}
                                        sx={{ mr: 1 }}
                                        startIcon={<ArrowForward />}>

                                        Kế tiếp
                                    </Button>
                                    {activeStep !== steps.length &&
                                        (completed[activeStep] ? (
                                            <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                                Bước {activeStep + 1} đã hoàn thành
                                            </Typography>
                                        ) : (
                                            <Button
                                                variant='outlined'
                                                onClick={handleComplete}>
                                                {completedSteps() === totalSteps() - 1
                                                    ? 'Kết thúc'
                                                    : 'Hoàn thành'}
                                            </Button>
                                        ))}
                                </Box>
                            </React.Fragment>
                        )}
                    </div>
                </Box>

            </DialogContent>
            {/* <DialogActions>

                <DHSDialogAction

                    action={props.action}
                    menu={menu}
                    onClickAction={handleClickAction}
                />


            </DialogActions> */}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Xác nhận thay đổi
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {totalApartmentMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button disableElevation sx={{ textTransform: "capitalize" }} autoFocus variant="contained" color="primary" onClick={() => handleSubmit()}>
                        Chập nhận
                    </Button>
                    {
                        actionUpdateService === "UPDATE" && <Button disableElevation sx={{ textTransform: "capitalize" }} color="error" onClick={() => {
                            // handleSubmit("UPDATE-BUT-NOT-DELETE");
                        }}>Không</Button>
                    }
                    <Button disableElevation sx={{ textTransform: "capitalize" }} onClick={handleClose}>Hủy</Button> */}
                </DialogActions>
            </Dialog>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default ServiceBookingAction;
