import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IReducer } from "../../../../../common/Interfaces/Interfaces";
import DHSToolbar from "../../../../../components/DHS/DHS-Toolbar/DHSToolbar";
import {
  IRoleRightWebModel,
  RoleRightWebModel,
} from "../../../../shared/service-proxies/api-shared";
import LeaveFormAction from "./LeaveForm";

interface IProps {
  listToolbar?: RoleRightWebModel[];
  rowSelected?: string | undefined;
}

const LeaveFormPage = (props: IProps) => {
  const role = useSelector((state: IReducer) => state.RoleReducer?.role);
  const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
  const [action, setAction] = React.useState<RoleRightWebModel | undefined>(
    role
  );
  const [nowTime, setNowTime] = React.useState<Date>(new Date());
  const history = useHistory();
  const handleClickAction = (action: RoleRightWebModel, nowTime: Date) => {
    setAction(action);
    setNowTime(nowTime);
  };
  const [back, setBack] = React.useState<number>(-1)
  const handleDestroyAction = (isDestroy: boolean, goBack?: number) => {
    if (goBack) { setBack(goBack); }
    if (isDestroy) setAction(undefined);
  };
  const [title, setTitle] = React.useState<string>("Đang tải nội dung");

  React.useEffect(() => {
    switch (action?.class_name?.toLocaleUpperCase()) {
      case "ADD":
        setTitle("Tạo phiếu nghỉ phép");
        break;
      case "EDIT":
        setTitle("Cập nhật phiếu nghỉ phép");
        break;
      case "VIEWDETAIL":
        setTitle("Xem chi tiết phiếu nghỉ phép");
        break;
    }
  }, [action]);
  
  return (
    <>
      <div
        className="d-flex title-color"
        style={{
          height: 30,
          color: "rgb(0 78 255 / 88%)",
          fontSize: 22,
          fontWeight: 550,
          marginTop: 15,
          marginBottom: 15,
        }}
      >
        <i
          className="bi bi-arrow-left"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.go(back);
          }}
        ></i>
        &nbsp;&nbsp;
        {title}
      </div>{" "}
      <DHSToolbar
        list={
          roles
            ? roles.filter(
              (item) => item.on_form?.toLocaleUpperCase() === "EDIT"
            )
            : []
        }
        menuid={""}
        id={""}
        current_form={""}
        onClickAction={handleClickAction}
      />
      <LeaveFormAction
        id={props.rowSelected}
        action={action as RoleRightWebModel}
        nowTime={nowTime}
        onDestroyAction={handleDestroyAction}
      />
    </>
  );
};

export default LeaveFormPage;
