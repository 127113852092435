export const getFileExtension = (fileName: string): string => {
  // Sử dụng regex để tìm phần mở rộng của tệp
  const regex = /(?:\.([^.]+))?$/;
  const extension = regex.exec(fileName)?.[1];

  // Trả về phần mở rộng (nếu có) hoặc một chuỗi trống
  return extension || '';
}

export const isImageFile = (fileUrl: string) => {
  const lowerCaseFileUrl = fileUrl.toLowerCase();
  return (
    lowerCaseFileUrl.endsWith('.jpg') ||
    lowerCaseFileUrl.endsWith('.png') ||
    lowerCaseFileUrl.endsWith('.jpeg') ||
    lowerCaseFileUrl.endsWith('.gif')
  );
};

export const isPDFFile = (fileUrl: string): boolean => {
  const lowercaseUrl = fileUrl.toLowerCase();
  return lowercaseUrl.endsWith('.pdf');
};

export const getFileNameFromUrl = (fileUrl: string): string | null => {
  // Tách URL thành các phần để lấy tên file
  const urlParts = fileUrl.split("/");
  const fileName = urlParts[urlParts.length - 1];

  // Kiểm tra xem tên file có tồn tại không
  if (fileName) {
    return fileName;
  } else {
    return null;
  }
}
