import {
  AppBar,
  Button,
  Checkbox,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  Slide,
  TextField,
  TextFieldProps,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import CTXDate from "../../../../components/CTX/DatePicker";
import {
  ChoThueXeClient,
  CtxDanhMucXeModel,
  CtxThongTinXeBaoDuongModel,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import DHS_Select from "../../../../components/DHS/DHS_Select";
import CurrencyTextField from "react-currency-input-field";
import "./style.css";
interface IProps {
  open: boolean;
  itemSelected?: any;
  action?: any;
  onClose: () => void;
  onSubmit?: (data: any, action: any) => void;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});
var appSession: AppSession;

const BaoDuongDialog = ({
  open,
  itemSelected,
  action,
  onClose,
  onSubmit,
}: IProps) => {
  const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);

  const [data, setData] = React.useState<CtxThongTinXeBaoDuongModel>(
    new CtxThongTinXeBaoDuongModel()
  );
  const [loaiBaoDuongOptions, setLoaiBaoDuongOptions] = React.useState<any[]>(
    []
  );
  const [donViOptions, setDonViOptions] = React.useState<any[]>([]);
  const [trangThaiBaoDuongOptions, setTrangThaiBaoDuongOptions] =
    React.useState<any[]>([]);

  React.useEffect(() => {
    ctxClient
      .ctxDanhMucXeActions({
        ...new CtxDanhMucXeModel(),
        tbname: "ctxLoaiBaoDuong",
        action: "GET",
      } as CtxDanhMucXeModel)
      .then((res) => setLoaiBaoDuongOptions(res));
    ctxClient
      .ctxDanhMucXeActions({
        ...new CtxDanhMucXeModel(),
        tbname: "ctxTrangThaiBaoDuong",
        action: "GET",
      } as CtxDanhMucXeModel)
      .then((res) => setTrangThaiBaoDuongOptions(res));
    ctxClient
      .ctxDanhMucXeActions({
        ...new CtxDanhMucXeModel(),
        tbname: "ctxDonVi",
        action: "GET",
      } as CtxDanhMucXeModel)
      .then((res) => setDonViOptions(res));
  }, []);

  React.useEffect(() => {
    if (itemSelected && action === "UPDATE") {
      ctxClient
        .ctxThongTinXeBaoDuongGet({
          ...new CtxThongTinXeBaoDuongModel(),
          id: itemSelected,
        } as CtxThongTinXeBaoDuongModel)
        .then((response) => setData(response[0]));
    } else setData(new CtxThongTinXeBaoDuongModel());
  }, [itemSelected]);

  React.useMemo(() => {
    if (data.don_gia && data.so_luong)
      setData({
        ...data,
        thanh_tien: data.don_gia * data.so_luong,
      } as CtxThongTinXeBaoDuongModel);
    else
      setData({
        ...data,
        thanh_tien: 0,
      } as CtxThongTinXeBaoDuongModel);
  }, [data.don_gia, data.so_luong]);

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    if (e.target.id === "currency") {
      setData({
        ...data,
        [name]: Number(value.split(",").join("")),
      } as CtxThongTinXeBaoDuongModel);
    } else setData({ ...data, [name]: value } as CtxThongTinXeBaoDuongModel);
  };

  return (
    <Dialog
      id="sysMenu"
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <AppBar sx={{ width: "inherit", position: "fixed" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Bảo dưỡng xe
          </Typography>
          {onSubmit && (
            <Button
              autoFocus
              color="inherit"
              onClick={() =>
                onSubmit(
                  {
                    ...data,
                    trang_thai_name:
                      data.trang_thai_id &&
                      trangThaiBaoDuongOptions.filter(
                        (item) => item.id === data.trang_thai_id
                      )[0].name,
                    loai_name:
                      data.loai_id &&
                      loaiBaoDuongOptions.filter(
                        (item) => item.id === data.loai_id
                      )[0].name,
                    don_vi_name:
                      data.don_vi_id &&
                      donViOptions.filter(
                        (item) => item.id === data.don_vi_id
                      )[0].name,
                  },
                  action
                )
              }
            >
              Lưu
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <div className="d-flex" style={{ marginTop: 30 }}>
        <div className="w-100 p-4">
          <div style={{ backgroundColor: "white" }} className="p-3 d-flex">
            <div className="w-100 h-100">
              <div className="row">
                <div className="form-group col-md-6">
                  <label className="ctx-label">Ngày</label>
                  <CTXDate
                    style={{ width: "100%" }}
                    name="ngay"
                    onChange={handleChangeInput}
                    value={data.ngay}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="ctx-label">Trạng thái</label>
                  <TextField
                    sx={{ width: "100%" }}
                    name="trang_thai_id"
                    onChange={handleChangeInput}
                    value={data.trang_thai_id}
                    select
                  >
                    <MenuItem key="trang_thai" value="">
                      Chọn
                    </MenuItem>
                    {trangThaiBaoDuongOptions.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="form-group col-md-6">
                  <label className="ctx-label">Loại</label>
                  <TextField
                    sx={{ width: "100%" }}
                    name="loai_id"
                    onChange={handleChangeInput}
                    value={data.loai_id}
                    select
                  >
                    <MenuItem key="loai" value="">
                      Chọn
                    </MenuItem>
                    {loaiBaoDuongOptions.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="form-group col-md-6">
                  <label className="ctx-label">Đơn vị</label>
                  <TextField
                    sx={{ width: "100%" }}
                    name="don_vi_id"
                    onChange={handleChangeInput}
                    value={data.don_vi_id}
                    select
                  >
                    <MenuItem key="don_vi_id" value="">
                      Chọn
                    </MenuItem>
                    {donViOptions.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="form-group col-md-4">
                  <label className="ctx-label">Số lượng</label>
                  <TextField
                    id="so_luong"
                    sx={{ width: "100%" }}
                    name="so_luong"
                    type="number"
                    onChange={handleChangeInput}
                    value={data.so_luong ? data.so_luong : 0}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="ctx-label">Đơn giá</label>
                  <CurrencyTextField
                    id="currency"
                    className="flex-grow-1 form-control"
                    allowDecimals
                    decimalsLimit={2}
                    decimalSeparator="."
                    groupSeparator=","
                    style={{ width: "100%", textAlign: "right" }}
                    name="don_gia"
                    // type="number"
                    onChange={handleChangeInput}
                    value={data.don_gia ? data.don_gia : 0}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="ctx-label">Thành tiền</label>
                  <CurrencyTextField
                    id="currency"
                    className="flex-grow-1 form-control"
                    allowDecimals
                    decimalsLimit={2}
                    decimalSeparator="."
                    groupSeparator=","
                    style={{ width: "100%", textAlign: "right" }}
                    name="thanh_tien"
                    disabled
                    // type="number"
                    onChange={handleChangeInput}
                    value={data.thanh_tien ? data.thanh_tien : 0}
                  />
                </div>
                <div className="form-group col-md-12">
                  <label className="ctx-label">Diễn giải</label>
                  <textarea
                    style={{ width: "100%" }}
                    name="dien_giai"
                    onChange={handleChangeInput}
                    value={data.dien_giai}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default BaoDuongDialog;
