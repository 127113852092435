import React from "react";
import { useHistory } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Box, Grid, InputLabel, MenuItem, Popover, TextField } from "@mui/material";
import {
    Button,
} from "@mui/material";
import { CalendarPickerView } from "@mui/lab";
import CTXDate from "../../../components/CTX/DatePicker";
import { now } from "lodash";
import styled from "styled-components";
import { Height } from "devextreme-react/chart";
import SearchIcon from '@mui/icons-material/Search';
import moment from "moment";
enum Action {
    BYYEAR = "BYYEAR",
    BYMONTH = "BYMONTH",
    BYDAY = "BYDAY",
    BYSEASON = "BYSEASON",
    BYSTARYEAR = "BYSTARYEAR",
    BYENDYEAR = "BYENDYEAR",
}

interface IAction {
    type: Action;
    payload: any;
}
interface IPeriod {
    fromDate: Date;
    toDate: Date;
}
interface ISeason {
    year: Date;
    seasonNumber: number;
}
const filterType = [
    {
        name: "Theo Quý",
        value: 1,
        action: "BYSEASON",
    },
    {
        name: "6 tháng đầu năm",
        value: 2,
        action: "BYSTARYEAR",
    },
    {
        name: "6 tháng cuối năm",
        value: 3,
        action: "BYENDYEAR",
    },
    {
        name: "Theo Năm",
        value: 4,
        views: ["year"],
        action: "BYYEAR",
    },

    {
        name: "Theo Tháng",
        value: 5,
        views: ["year", "month"],
        action: "BYMONTH",
    },
    {
        name: "Từ ngày ... đến ngày",
        value: 6,
        views: ["year", "month", "day"],
        action: "BYDAY",
    },
];
const listSeason = [
    {
        name: "Quý 1",
        value: 10,
    },
    {
        name: "Quý 2",
        value: 20,
    },
    {
        name: "Quý 3",
        value: 30,
    },
    {
        name: "Quý 4",
        value: 40,
    },
];
const listMonth = [
    {
        name: "Tháng 1",
        value: 1,
    },
    {
        name: "Tháng 2",
        value: 2,
    },
    {
        name: "Tháng 3",
        value: 3,
    },
    {
        name: "Tháng 4",
        value: 4,
    },
    {
        name: "Tháng 5",
        value: 5,
    },
    {
        name: "Tháng 6",
        value: 6,
    },
    {
        name: "Tháng 7",
        value: 7,
    },
    {
        name: "Tháng 8",
        value: 8,
    },
    {
        name: "Tháng 9",
        value: 9,
    },
    {
        name: "Tháng 1",
        value: 10,
    },
    {
        name: "Tháng 11",
        value: 11,
    },
    {
        name: "Tháng 12",
        value: 12,
    },
];


function filterReducer(period: IPeriod, action: IAction) {
    const { type, payload } = action;

    switch (type) {
        case Action.BYYEAR:
            return {
                ...period,
                fromDate: new Date(payload.getFullYear(), 0, 1),
                toDate: new Date(payload.getFullYear(), 11, 31),
            };
        case Action.BYMONTH:
            return {
                ...period,
                fromDate: new Date(payload.getFullYear(), payload.getMonth(), 1),
                toDate: new Date(payload.getFullYear(), payload.getMonth() + 1, 0),
            };
        case Action.BYDAY:
            return {
                ...period,
                fromDate: new Date(Date.UTC(payload.fromDate.getFullYear(), payload.fromDate.getMonth(), payload.fromDate.getDate(), -7, 0)),
                toDate: new Date(Date.UTC(payload.toDate.getFullYear(), payload.toDate.getMonth(), payload.toDate.getDate(), -7, 0)),
            };
        case Action.BYSEASON:
            var from: Date = new Date();
            var to: Date = new Date();
            var y = payload.year.getFullYear();
            if (payload.seasonNumber === 10) {
                from = new Date(y, 0, 1);
                to = new Date(y, 3, 0);
            } else if (payload.seasonNumber === 20) {
                from = new Date(y, 3, 1);
                to = new Date(y, 6, 0);
            } else if (payload.seasonNumber === 30) {
                from = new Date(y, 6, 1);
                to = new Date(y, 9, 0);
            } else if (payload.seasonNumber === 40) {
                from = new Date(y, 9, 1);
                to = new Date(y, 12, 0);
            }
            return {
                ...period,
                fromDate: from,
                toDate: to,
            };
        case Action.BYSTARYEAR:
            return {
                ...period,
                fromDate: new Date(payload.getFullYear(), 0, 1),
                toDate: new Date(payload.getFullYear(), 5, 30),
            };
        case Action.BYENDYEAR:
            return {
                ...period,
                fromDate: new Date(payload.getFullYear(), 6, 1),
                toDate: new Date(payload.getFullYear(), 11, 31),
            };
        default:
            return period;
    }
}

interface IProps {
    fromDate: (data: any) => void;
    toDate: (data: any) => void;
    reload: () => void;
    type?: "days" | "weeks" | "months" | "square" | "sixMonths" | "years"
}



const BetweenTwoDate: React.FC<IProps> = (props: IProps) => {

    // 6 tháng cuối năm hoặc
    const checkSixMonth = () => {
        const SIX_MONTH = 6;
        const monthNow = moment().month();
        return monthNow < SIX_MONTH;
    }


    const typeGetDate = (): number => {
        const typeGet = {
            "days": "days",
            "weeks": "weeks",
            "months": "months",
            "square": "square",
            "sixMonths": "sixMonths",
            "years": "years",
        }

        if (props.type) {
            switch (props.type) {
                case typeGet.days:
                    return 6;
                case typeGet.weeks:
                    return 5;
                case typeGet.months:
                    return 5;
                case typeGet.square:
                    return 1;
                case typeGet.sixMonths:
                    return checkSixMonth() ? 2 : 3;
                case typeGet.years:
                    return 4;
            }
        }
        return 5;
    }


    const [filter, dispatch] = React.useReducer(filterReducer, {
        fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        toDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    });
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    
    const [type, setType] = React.useState<number>(props.type ? typeGetDate() : 5); // theo tháng
    const [year, setYear] = React.useState<Date>(new Date());
    const [halfYear, setHalfYear] = React.useState<Date>(new Date());
    const [season, setSeason] = React.useState<ISeason>({
        year: new Date(),
        seasonNumber: 10,
    });
    const [month, setMonth] = React.useState<Date>(new Date());
    const [date, setDate] = React.useState<IPeriod>({
        fromDate: new Date(),
        toDate: new Date(),
    });

    const handleFilter = async (tf: number) => {
        switch (tf) {
            case 1:
                await dispatch({ type: Action.BYSEASON, payload: season });
                break;
            case 2:
                await dispatch({ type: Action.BYSTARYEAR, payload: halfYear });
                break;
            case 3:
                await dispatch({ type: Action.BYENDYEAR, payload: halfYear });
                break;
            case 4:
                await dispatch({ type: Action.BYYEAR, payload: year });
                break;
            case 5:
                await dispatch({ type: Action.BYMONTH, payload: month });
                break;
            case 6:
                await dispatch({ type: Action.BYDAY, payload: date });
                break;
            default:
                break;
        }
        // props.reload();
    };

    const handleChangeYearAndMonth = (e: any) => {
        const { name, value } = e;
        if (name === "year") {
            setYear(new Date(value));
        } else if (name === "month") {
            setMonth(new Date(value));
        }
    };

    const handleChangeDate = (e: any) => {
        const { name, value } = e;
        setDate({ ...date, [name]: new Date(value) });
    };

    React.useEffect(() => {
        console.log('one')
    }, [])

    React.useEffect(() => {
        console.log(type)
    }, [type])



    React.useEffect(() => {
        props.fromDate(new Date(
            (new Date(filter.fromDate ? filter.fromDate : now()) as any) -
            new Date().getTimezoneOffset() * 1000 * 60
        ));
        props.toDate(new Date(
            (new Date(filter.toDate ? filter.toDate : now()) as any) -
            new Date().getTimezoneOffset() * 1000 * 60
        ));
    }, [filter.fromDate, filter.toDate])

    React.useEffect(() => {
        if (type) handleFilter(type);
    }, [type, year, halfYear, season, month, date])

    return (
        <div className="mt-1">
            <div>
                <Grid container spacing={1} className="d-lg-flex justify-content-lg-end">
                    <Grid item xs={12} >
                        <InputLabel className='cc-label'>Điều kiện lọc</InputLabel>
                        <TextField
                            select
                            value={type}
                            onChange={(e) => setType(Number(e.target.value))}
                            style={{ width: "100%" }}
                            className="cc-input"
                        >
                            {filterType.map((type) => (
                                <MenuItem key={`${type.value}`} value={type.value}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    {type > 3 && type < 6 ? (
                        <Grid item xs={12} >
                            <InputLabel className='cc-label'>{type === 4 ? "Chọn năm" : "Chọn tháng"}</InputLabel>
                            <CTXDate
                                className="cc-input"
                                name={type === 4 ? "year" : "month"}
                                views={filterType[type - 1].views as CalendarPickerView[]}
                                style={{ width: "100%" }}
                                value={type === 4 ? year : month}
                                onChange={handleChangeYearAndMonth}
                            />
                        </Grid>
                    ) : type === 6 ? (
                        <>
                            <Grid item xs={12} sm={6} md={6} >
                                <InputLabel className='cc-label'>Chọn ngày bắt đầu</InputLabel>
                                <CTXDate
                                    className="cc-input"
                                    name="fromDate"
                                    views={filterType[type - 1].views as CalendarPickerView[]}
                                    style={{ width: "100%" }}
                                    value={date.fromDate}
                                    onChange={handleChangeDate}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} >
                                <InputLabel className='cc-label'>Chọn ngày kết thúc</InputLabel>
                                <CTXDate
                                    className="cc-input"
                                    name="toDate"
                                    views={filterType[type - 1].views as CalendarPickerView[]}
                                    style={{ width: "100%" }}
                                    value={date.toDate}
                                    onChange={handleChangeDate}
                                />
                            </Grid>
                        </>
                    ) : type === 1 ? (
                        <>
                            <Grid item xs={12} sm={6} md={6} >
                                <InputLabel className='cc-label'>Chọn năm</InputLabel>
                                <CTXDate
                                    className="cc-input"
                                    name={"year"}
                                    views={["year"]}
                                    style={{ width: "100%" }}
                                    value={season.year}
                                    onChange={(e: any) => {
                                        setSeason({
                                            ...season,
                                            year: new Date(e.value),
                                        })
                                    }


                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}  >
                                <InputLabel className='cc-label'>Chọn quý</InputLabel>
                                <TextField
                                    className="cc-input"
                                    select
                                    value={season.seasonNumber}
                                    onChange={(e) =>
                                        setSeason({
                                            ...season,
                                            seasonNumber: Number(e.target.value),
                                        })
                                    }
                                    style={{ width: "100%" }}
                                >
                                    {listSeason.map((season) => (
                                        <MenuItem key={`${season.value}`} value={season.value}>
                                            {season.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={12}  >
                                <InputLabel className='cc-label'>Chọn năm</InputLabel>
                                <CTXDate
                                    className="cc-input"
                                    name={"year"}
                                    views={["year"]}
                                    style={{ width: "100%" }}
                                    value={halfYear}
                                    onChange={(e: any) => setHalfYear(new Date(e.value))}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </div>
        </div>
    );
};

export default BetweenTwoDate;
