import React from 'react'
import { SESSION_LOGIN } from '../../../../redux/constants/ActionTypes';
import BASE_URL_API from '../../../shared/service-proxies/config';
import axios from 'axios';
import { Notification } from '../../../../components/layouts';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import './upaloadAvatar.css'
import { ACTIONS } from '../../../../common/enums/DHSToolbarRoleAction';

interface IProps {
    action: string;
    path: any;
    setPath: (path_: any) => void; // link đường dẫn tập tin
    title?: string | undefined;
    singer?: boolean;
}

export const UpdateAvatar: React.FC<IProps> = (props: IProps) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const { setPath, path } = props;

    const onChangeInput = (e: any) => {

        if (props.action !== "DETAIL" && props.action !== 'VIEW') {
            const { name, files } = e.target;
            const formData: FormData = new FormData();

            const options = {
                observe: "response",
                // responseType: "blob",
                headers: {
                    Accept: "application/json",
                    token: SessionLogin.Token
                },
            };

            for (const file of files) {
                formData.append("MyFile", file);
            }
            setLoading(true)
            axios
                .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
                .then((res) => {
                    if (res.data) {
                        if (setPath) {
                            if (props.singer) {
                                setPath([res.data[0]?.path]);
                            }
                        }
                        setLoading(false)
                        // reset lại file input
                        e.target.value = '';
                    } else Notification("warning", "Không lấy được hình ảnh");
                    setLoading(false);
                });
        }

    }

    const handleRemoveImage = (filePath: string) => {

        if (props.action !== "DETAIL" && props.action !== 'VIEW') {
            if (window.confirm("Xác nhận xóa tập tin?")) {
                const pathTemp: string[] = path.filter((file: any) => file !== filePath);
                setPath([...pathTemp]);
            }
        }

    }

    return (
        <div>
            {
                props.action !== ACTIONS.VIEW && <>
                    <label htmlFor="uploadAvatar" className="around-up-file w-100" >
                        <FileUploadIcon />
                        {
                            props.title ? props.title : "Tải tập tin"
                        }
                    </label>
                </>
            }
            <input
                id="uploadAvatar"
                style={{ display: 'none' }}
                accept="image/*"
                type="file"
                onChange={onChangeInput}
                disabled={props.action === "DETAIL"}
            />
            {
                React.useMemo(() => {
                    return <>
                        {
                            path.length > 0
                            && path.map((pathItem: string, index: Number) => (
                                <div key={`${12}-${index}${pathItem}`} className='borderFileImage '>
                                    <div className='cursor-pointer-hover position-relative w-100 h-100'>
                                        <img src={BASE_URL_API + pathItem} alt="pathItem" />
                                        <CloseIcon
                                            className='cursor-close-around position-absolute icon-uploadImage'
                                            onClick={() => handleRemoveImage(pathItem)}
                                        />
                                    </div>
                                </div>
                            ))
                        }
                    </>
                }, [path])
            }
            {
                props.action === ACTIONS.VIEW && (path.length === 0 || path === undefined) && <>
                    <p className='text-center'>Trống </p>
                </>
            }
        </div>
    )
}
