import { Grid } from "@mui/material";
import React, { Ref } from "react"
import { ValueChangedInfo } from "devextreme/ui/editor/editor";
import dxDateBox from "devextreme/ui/date_box";
import { NativeEventInfo } from "devextreme/events";
import moment from "moment";
import _ from "lodash";
import { SelectBoxInstance } from "devextreme/ui/select_box";
import { SYS_Token_AuthenticationModel } from "../../../../shared/service-proxies/api-shared";
import { checkRequiredField } from "../../../../../components/utils/object.util";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";


interface IDataSelect {
    cycle?: number,
    year?: Date,
    fullYear?: number,
    quarter?: number,
    month?: number,
    week?: number
}
interface IData {
    dataSelect: IDataSelect,
    timeData: ITimeData
}

interface ITimeData {
    time_from?: Date,
    time_to?: Date
}


interface IQuater {
    quarter?: number,
    dateByQuaterStart?: Date,
    dateByQuaterEnd?: Date,
}
interface IMonth {
    month?: number,
    dateByMonthStart?: Date,
    dateByMonthEnd?: Date,
}

interface IWeek {
    week?: number,
    weekByYearStart?: Date,
    weekByYearEnd?: Date,
}

interface ICycle {
    code: number,
    name: string,
    code_2: string
}

interface IRef {
    getTimeData: () => ITimeData
}

const defaultCycle: ICycle[] = [
    { code: 9, name: 'Hôm nay', code_2: 'now' },
    { code: 10, name: 'Hôm qua', code_2: 'yesterday' },
    { code: 11, name: 'Tuần này', code_2: 'thisWeek' },
    // { code: 1, name: 'Theo ngày', code_2: 'date' },
    { code: 2, name: 'Theo tuần', code_2: 'week' },
    { code: 3, name: 'Theo tháng', code_2: 'month' },
    { code: 4, name: 'Theo quý', code_2: 'quarter' },
    { code: 5, name: 'Theo Năm', code_2: 'year' },
    { code: 7, name: '6 Tháng đầu năm', code_2: 'sixMonthsFirst' },
    { code: 8, name: '6 Tháng cuối năm', code_2: 'sixMonthsSecond' },
    { code: 6, name: 'Tùy chỉnh', code_2: 'date' },
]


interface IProps {
    /** default show cycle by code (either single or multiple) */
    defaultShowCycles?: number[],
    /** default cycle (default on selectBox) */
    defaultCycle?: number,
    /** the main data includes time_from and time_to */
    timeData: ITimeData,
    /** the event for main data change */
    onChangeTimeData?: (timeData?: ITimeData) => void,
    /**
     * type for main data
     * - datetime "return" time_from (00:00:00) - time_to (29:59:59)
     * - date "return" time_from (00:00:00) - time_to (00:00:00)
     */
    type: "datetime" | "date",
    /**
     * display format for time from and time to
     */
    displayFormat?: 'dd/MM/yyyy' | "yyyy-MM-dd HH:mm:ss"
}


// const visiableCycleByDate = [1, 6, 9, 10]
const visiableCycleByYear = [1, 2, 3, 4, 5, 7, 8]
const visiableCycleByQuarter = [4]
const visiableCycleByMonth = [3]
const visiableCycleByWeek = [2, 11]
/**
 * Component DomesticWaterServiceDataFitlerTime
 *
 * @interface IProps
 * @default Data source:
 * - 2/ Theo tuần
 * - 3/ Theo tháng
 * - 4/ Theo quý
 * - 5/ Theo năm
 * - 6/ Tùy chỉnh
 * - 7/ 6 tháng đầu năm
 * - 8/ 6 tháng cuối năm
 * - 9/ Hôm nay
 * - 10/ Hôm qua
 * - 11/ Tuần nay    
 * 
 */
const DomesticWaterServiceDataFilterTime = React.memo(React.forwardRef<IRef, React.PropsWithChildren<IProps>>((props, ref) => {

    const getTimeValues = (type: "datetime" | "date") => {
        if (type === "datetime") {
            return { hours: 23, minutes: 59, seconds: 59 };
        } else if (type === "date") {
            return { hours: 0, minutes: 0, seconds: 0 };
        }
        return { hours: 0, minutes: 0, seconds: 0 };
    }

    const sessionLogin: SYS_Token_AuthenticationModel = JSON.parse(
        localStorage.getItem("SessionLogin") || "{}"
    );
    const [data, setData] = React.useState<IData>();
    // 

    const [quarter, setQuater] = React.useState<IQuater[]>();
    const [month, setMonth] = React.useState<IMonth[]>();
    const [week, setWeek] = React.useState<IWeek[]>();

    // 

    const { hours, minutes, seconds } = getTimeValues(props.type);

    // 

    const [cycle, setCycle] = React.useState<ICycle[]>(defaultCycle)
    // 

    const preTimeData = React.useRef<ITimeData | null>(null);

    React.useEffect(() => {

        if (props?.defaultShowCycles) {
            const tempCycle = [...cycle].filter(x => props.defaultShowCycles?.includes(x.code));
            setCycle(tempCycle)

        }

    }, [props?.defaultShowCycles])


    React.useEffect(() => {
        setData(pre => ({
            ...pre,
            dataSelect: {
                ...pre?.dataSelect,
                cycle: props.defaultCycle
            }
        } as IData))

    }, [props.defaultCycle]);


    React.useEffect(() => {



        if (props?.timeData?.time_from && props?.timeData?.time_to) {
            // Kiểm tra có bằng nhau hay không

            const fullYear = props?.timeData?.time_from?.getFullYear();
            const year = new Date(fullYear, 0, 1);
            console.log(year, "year")

            setData(pre => {

                const time_from = moment(pre?.timeData?.time_from).format("DD/MM/yyyy");
                const time_to = moment(pre?.timeData?.time_to).format("DD/MM/yyyy");
                const time_from_props = moment(props?.timeData?.time_from).format("DD/MM/yyyy");
                const time_to_props = moment(props?.timeData?.time_to).format("DD/MM/yyyy");
                // 

                const time_from_month = props?.timeData?.time_from?.getMonth();
                const isEqual = _.isEqual(time_from_props, time_from) &&
                    _.isEqual(time_to_props, time_to)
                if (!isEqual) {
                    preTimeData.current = props?.timeData;

                    return {
                        ...pre,
                        dataSelect: {
                            ...pre?.dataSelect,
                            fullYear: pre?.dataSelect?.fullYear !== fullYear ? fullYear : pre?.dataSelect?.fullYear,
                            year: pre?.dataSelect?.fullYear !== fullYear ? year : pre?.dataSelect?.year,
                            quarter: pre?.dataSelect.cycle === 4 ? Math.floor(time_from_month! / 3) : pre?.dataSelect?.quarter,
                            month: pre?.dataSelect.cycle === 3 ? time_from_month : pre?.dataSelect?.month,
                            week: pre?.dataSelect.cycle === 2 ? getWeekNumber(props?.timeData?.time_from!) : pre?.dataSelect?.week
                        },
                        timeData: {
                            time_from: props?.timeData?.time_from,
                            time_to: props?.timeData?.time_to,
                        }
                    } as IData
                }
                return pre;

            });
        }


        else {

            const fullYear = sessionLogin.nam_tc!;
            const month = new Date().getMonth();
            const date = new Date().getDate();
            const year = new Date(fullYear, month, date, 0, 0, 0);
            // 
            loadDateInfoForYear(fullYear)
            // 
            setData(pre => ({
                ...pre,
                dataSelect: {
                    ...pre?.dataSelect,
                    fullYear: fullYear,
                    year: year,
                }
            } as IData))

        }


        console.log(props.timeData?.time_from?.toISOString(), "year in timeFrom")


    }, [props.timeData?.time_from, props.timeData?.time_to, props?.defaultCycle]);

    React.useEffect(() => {
        console.log(data?.timeData?.time_from?.toISOString(), "time_from")
        console.log(data?.timeData?.time_to?.toISOString(), "time_to")
        // setData(pre => {

        const time_from = data?.timeData?.time_from ? moment(data?.timeData?.time_from).format("DD/MM/yyyy") : undefined;
        const time_to = data?.timeData?.time_to ? moment(data?.timeData?.time_to).format("DD/MM/yyyy") : undefined;
        const time_from_props = preTimeData?.current?.time_from ? moment(preTimeData?.current?.time_from).format("DD/MM/yyyy") : undefined;
        const time_to_props = preTimeData?.current?.time_to ? moment(preTimeData?.current?.time_to).format("DD/MM/yyyy") : undefined;
        const isEqual = _.isEqual(time_from, time_from_props) && _.isEqual(time_to, time_to_props)

        // Kiểm tra có bằng nhau hay không
        // if (time_from && time_to && props.onChangeTimeData && !isEqual) {
        if (time_from && time_to && props.onChangeTimeData && true) {
            // alert(data?.timeData?.time_from?.toISOString())
            props.onChangeTimeData({ time_from: data?.timeData?.time_from, time_to: data?.timeData?.time_to } as ITimeData);
            console.log(preTimeData.current, data?.timeData, "ref in v2")
        }

        //     return pre;
        // })


    }, [data?.timeData?.time_from, data?.timeData?.time_to])

    React.useImperativeHandle(ref, () => ({
        getTimeData: () => {
            return { ...data?.timeData }
        }
    } as IRef), [data?.timeData]);


    const loadDateInfoForYear = (fullYear: number) => {
        // set quarter

        const quartersByYear: IQuater[] = getQuartersFromYear(fullYear);

        // console.log(quartersByYear, "quarter")

        setQuater(quartersByYear)

        // set month

        const dateByMonth: IMonth[] = getMonthsFromYear(fullYear)
        // console.log(dateByMonth, "month")
        setMonth(dateByMonth);

        // setWeek

        const weeks = getWeekFromYear(fullYear);

        setWeek(weeks);
    }

    const handleChangeYear = (e: NativeEventInfo<dxDateBox, Event> & ValueChangedInfo) => {

        const currentDate = new Date();
        const fullYear = (e.value as Date)?.getFullYear();

        // console.log(data?.time_from?.toISOString(), time_from.toISOString())
        // console.log(!_.isEqual(data?.time_from, TransDateTime(time_from)), "year")

        loadDateInfoForYear(fullYear)

        //

        setData(pre => {
            const time_from = pre?.timeData?.time_from
            const time_data = new Date(fullYear, time_from ? time_from.getMonth() : currentDate.getMonth(), time_from ? time_from.getDate() : currentDate.getDate(), 0, 0, 0);
            const time_to_for_year = new Date(fullYear, 11, 31, hours, minutes, seconds);
            switch (pre?.dataSelect?.cycle) {
                case 2:

                    const timeDataWeek = handleChangeWeekByYear(time_data, fullYear)
                    if (timeDataWeek && checkRequiredField(timeDataWeek, ["week", "weekByYearStart", "weekByYearEnd"])) {
                        // props.onChangeTimeData && props.onChangeTimeData(timeDataWeek)

                        return {
                            ...pre,
                            dataSelect: {
                                ...pre?.dataSelect,
                                fullYear: fullYear,
                                year: new Date(fullYear, 0, 1),
                                week: timeDataWeek?.week
                            },
                            timeData: {
                                time_from: timeDataWeek?.weekByYearStart,
                                time_to: timeDataWeek?.weekByYearEnd

                            }
                        } as IData
                    }

                    return pre;

                case 3:

                    const timeDataMonth = handleChangeMonthByYear(time_data, fullYear);
                    if (timeDataMonth) {
                        // props.onChangeTimeData && props.onChangeTimeData(timeDataMonth)

                        return {
                            ...pre,
                            dataSelect: {
                                ...pre.dataSelect,
                                fullYear: pre?.dataSelect?.fullYear !== fullYear ? fullYear : pre?.dataSelect?.fullYear,
                                year: pre?.dataSelect?.fullYear !== fullYear ? new Date(fullYear, 0, 1) : pre?.dataSelect?.year,
                                month: timeDataMonth.time_from?.getMonth()
                            },
                            timeData: timeDataMonth
                        } as IData
                    }
                    return pre;
                case 4:

                    const timeDataQuarter = handleChangeQuarterByYear(time_data, fullYear);
                    const monthOfQuarter = timeDataQuarter?.time_from?.getMonth();
                    if (timeDataQuarter) {
                        // props.onChangeTimeData && props.onChangeTimeData(timeDataQuarter)

                        return {
                            ...pre,
                            dataSelect: {
                                ...pre.dataSelect,
                                fullYear: fullYear,
                                year: new Date(fullYear, 0, 1),
                                quarter: monthOfQuarter ? Math.floor(monthOfQuarter / 3) : 0,
                            },
                            timeData: timeDataQuarter
                        } as IData
                    }
                    return pre;
                case 5:

                    // if (pre?.dataSelect?.fullYear !== fullYear 
                    //     || props?.defaultCycle === 5) { // khi default cycle là "năm"
                    // props.onChangeTimeData && props.onChangeTimeData({ time_from: time_from, time_to: time_to } as ITimeData)

                    return {
                        ...pre,
                        dataSelect: {
                            ...pre.dataSelect,
                            fullYear: fullYear,
                            year: new Date(fullYear, 0, 1),

                        },
                        timeData: {
                            time_from: new Date(fullYear, 0, 1, 0, 0, 0),
                            time_to: new Date(fullYear, 12, 0, hours, minutes, seconds)
                        }
                    } as IData
                // }
                // return pre;
                case 7: case 8:

                    const time_from_period = pre?.timeData?.time_from && new Date(pre?.timeData?.time_from);
                    const time_to_period = pre?.timeData?.time_to && new Date(pre?.timeData?.time_to);

                    if (time_from_period && time_to_period) {
                        time_from_period.setFullYear(fullYear);
                        time_to_period.setFullYear(fullYear)
                        return {
                            ...pre,
                            dataSelect: {
                                ...pre.dataSelect,
                                fullYear: fullYear,
                                year: new Date(fullYear, 0, 1),
                            },
                            timeData: {
                                time_from: time_from_period,
                                time_to: time_to_period
                            }
                        } as IData
                    }
                    return pre;
                default: return pre;
            }
        })
    }

    // Cycle

    const handleChangeCycle = (e: NativeEventInfo<SelectBoxInstance, Event | KeyboardEvent | MouseEvent | PointerEvent> & ValueChangedInfo) => {
        const year = data?.dataSelect?.fullYear;
        // alert(e.value)
        setData(pre => {
            switch (e.value) {
                // case null:
                case 1:
                case 2:
                    const timeDataWeek = handleChangeWeekByCycle(pre?.timeData?.time_from);
                    if (timeDataWeek) {
                        // props.onChangeTimeData && props.onChangeTimeData(timeDataWeek)
                        const newWeek = timeDataWeek?.week;
                        const currentWeek = pre?.dataSelect?.week;
                        return {
                            ...pre,
                            dataSelect: {
                                ...pre?.dataSelect,
                                cycle: e.value,
                                week: newWeek !== currentWeek ? newWeek : currentWeek
                            },
                            timeData: {
                                time_from: timeDataWeek?.weekByYearStart,
                                time_to: timeDataWeek?.weekByYearEnd
                            }
                        } as IData
                    }
                    return pre;
                case 3:
                    const timeDateMonth = handleChangeMonthByCycle(pre?.timeData?.time_from);
                    if (timeDateMonth) {
                        // props.onChangeTimeData && props.onChangeTimeData(timeDateMonth);
                        const newMonth = timeDateMonth?.time_from?.getMonth();
                        return {
                            ...pre,
                            dataSelect: {
                                ...pre?.dataSelect,
                                cycle: e.value,
                                month: newMonth

                            },
                            timeData: timeDateMonth
                        } as IData
                    }
                    return pre;
                case 4:

                    const timeDataQuarter = handleChangeQuarterByCycle(pre?.timeData?.time_from)
                    if (timeDataQuarter) {
                        // props.onChangeTimeData && props.onChangeTimeData(timeDataQuarter)
                        // const monthOfQuarter = timeDataQuarter?.time_from?.getMonth();
                        const newQuarter = timeDataQuarter?.quarter;
                        return {
                            ...pre,
                            dataSelect: {
                                ...pre?.dataSelect,
                                cycle: e.value,
                                quarter: newQuarter
                            },
                            timeData: {
                                time_from: timeDataQuarter?.dateByQuaterStart,
                                time_to: timeDataQuarter?.dateByQuaterEnd
                            }
                        } as IData
                    }
                    return pre;
                case 5:
                    if (year) {
                        const time_from = new Date(year, 0, 1, 0, 0, 0);
                        const time_to = new Date(year, 11, 31, hours, minutes, seconds);
                        const newYear = new Date(year, 0, 1);
                        return {
                            ...pre,
                            dataSelect: {
                                ...pre?.dataSelect,
                                cycle: e.value,
                                fullYear: year,
                                year: newYear
                            },
                            timeData: {
                                time_from,
                                time_to
                            }
                        } as IData
                    }
                    return pre;
                case 7:

                    if (year) {
                        const startOfPeriod = new Date(year, 0, 1, 0, 0, 0); // Ngày bắt đầu của năm
                        const endOfPeriod = new Date(year, 5, 30, hours, minutes, seconds); // Ngày kết thúc của 6 tháng đầu năm
                        // const timeDataPeriod = { time_from: startOfPeriod, time_to: endOfPeriod } as ITimeData;
                        // props.onChangeTimeData && props.onChangeTimeData(timeDataPeriod)

                        return {
                            ...pre,
                            dataSelect: {
                                ...pre?.dataSelect,
                                cycle: e.value
                            },
                            timeData: {
                                time_from: startOfPeriod,
                                time_to: endOfPeriod,
                            }
                        } as IData

                    }

                    return pre;
                case 8:

                    if (year) {
                        const startOfPeriod = new Date(year, 6, 1, 0, 0, 0); // Ngày bắt đầu của năm
                        const endOfPeriod = new Date(year, 12, 0, hours, minutes, seconds); // Ngày kết thúc của 6 tháng cuối năm

                        // const timeDataPeriod2 = { time_from: startOfPeriod, time_to: endOfPeriod } as ITimeData;
                        // props.onChangeTimeData && props.onChangeTimeData(timeDataPeriod2)

                        return {
                            ...pre,
                            dataSelect: {
                                ...pre?.dataSelect,
                                cycle: e.value
                            },
                            timeData: {
                                time_from: startOfPeriod,
                                time_to: endOfPeriod,
                            }
                        } as IData


                    }

                    return pre;
                case 6:
                case 9:
                    {
                        const yearNow = new Date().getFullYear();
                        const dayNow = new Date().getDate();
                        const monthNow = new Date().getMonth();
                        const startDateNow = new Date(yearNow, monthNow, dayNow, 0, 0, 0); // Ngày bắt đầu của năm
                        const endDateNow = new Date(yearNow, monthNow, dayNow, hours, minutes, seconds); // Ngày kết thúc của 6 tháng cuối năm
                        // props.onChangeTimeData && props.onChangeTimeData({ time_from: startDateNow, time_to: endDateNow } as ITimeData)

                        return {
                            ...pre,
                            dataSelect: {
                                ...pre?.dataSelect,
                                cycle: e.value,
                            },
                            timeData: {
                                time_from: startDateNow,
                                time_to: endDateNow,
                            }
                        } as IData

                    }


                case 10:
                    {
                        const yearNow = new Date().getFullYear();
                        const dayNow = new Date().getDate() - 1;
                        const monthNow = new Date().getMonth();
                        const startDateNow = new Date(yearNow, monthNow, dayNow, 0, 0, 0); // Ngày bắt đầu của năm
                        const endDateNow = new Date(yearNow, monthNow, dayNow, hours, minutes, seconds); // Ngày kết thúc của 6 tháng cuối năm
                        // 
                        // props.onChangeTimeData && props.onChangeTimeData({ time_from: startDateNow, time_to: endDateNow } as ITimeData)

                        return {
                            ...pre,
                            dataSelect: {
                                ...pre?.dataSelect,
                                cycle: e.value
                            },
                            timeData: {
                                time_from: startDateNow,
                                time_to: endDateNow,
                            }
                        } as IData
                    }



                case 11:

                    const currentDate = new Date();

                    // const weeks = getWeekFromYear(currentDate.getFullYear());

                    // setWeek(weeks);
                    const fullYear = currentDate.getFullYear();
                    const weekOfYear = (week && week.length > 0) ? week : getWeekFromYear(fullYear);

                    const weekSelected = [...weekOfYear].find(x => x.week === getWeekNumber(currentDate));
                    const startOfWeek = weekSelected?.weekByYearStart && new Date(weekSelected?.weekByYearStart);
                    const endOfWeek = weekSelected?.weekByYearEnd && new Date(weekSelected?.weekByYearEnd);

                    // console.log(startOfWeek, endOfWeek?.toISOString())
                    if (startOfWeek && endOfWeek) {
                        // props.onChangeTimeData && props.onChangeTimeData({ time_from: startOfWeek, time_to: endOfWeek } as ITimeData)
                        // if (pre?.dataSelect?.fullYear == startOfWeek.getFullYear()) {
                        const newWeek = getWeekNumber(startOfWeek);
                        startOfWeek.setFullYear(currentDate.getFullYear())
                        endOfWeek.setFullYear(currentDate.getFullYear())
                        return {
                            ...pre,
                            dataSelect: {
                                ...pre?.dataSelect,
                                cycle: e.value,
                                week: newWeek
                            },
                            timeData: {
                                time_from: startOfWeek,
                                time_to: endOfWeek
                            }
                        } as IData
                        // }
                        // return pre;
                    }
                    // }
                    return pre;
                default: return pre;
            }
        });
    }

    // quarter
    const handleChangeQuarterByYear = (time_from: Date, fullYear: number): ITimeData | undefined => {
        const quarterOfYear = (quarter && quarter.length > 0) ? quarter : getQuartersFromYear(fullYear);

        // 
        const quarterSelected = [...quarterOfYear].find(x => x.quarter === Math.floor(time_from.getMonth() / 3));
        const startOfQuarter = quarterSelected?.dateByQuaterStart && new Date(quarterSelected?.dateByQuaterStart);
        const endOfQuarter = quarterSelected?.dateByQuaterEnd && new Date(quarterSelected?.dateByQuaterEnd);

        // 

        startOfQuarter?.setFullYear(fullYear);
        endOfQuarter?.setFullYear(fullYear);
        return {
            time_from: startOfQuarter,
            time_to: endOfQuarter
        } as ITimeData


        // }
    }
    const handleChangeQuarterByCycle = (time_from?: Date): IQuater | undefined => {
        const fullYear = time_from?.getFullYear() ?? new Date().getFullYear();
        const quarterOfYear = (quarter && quarter.length > 0) ? quarter : getQuartersFromYear(fullYear);
        const currentDate = new Date();
        const currentMonth = time_from?.getMonth() ?? currentDate.getMonth();
        const currentYear = time_from?.getFullYear() ?? currentDate.getFullYear();
        const timeFrom = new Date(currentYear, currentMonth, 1)
        // 
        const quarterSelected = [...quarterOfYear].find(x => x.quarter === Math.floor(timeFrom.getMonth() / 3))
        const startOfQuarter = quarterSelected?.dateByQuaterStart;
        const endOfQuarter = quarterSelected?.dateByQuaterEnd;
        return {
            quarter: quarterSelected?.quarter,
            dateByQuaterStart: startOfQuarter,
            dateByQuaterEnd: endOfQuarter


        } as IQuater


        // }
    }

    const getQuartersFromYear = (fullYear: number) => {
        const quartersByYear: IQuater[] = [];
        for (let quarter = 0; quarter < 4; quarter++) {
            const startOfQuarter = new Date(fullYear, quarter * 3, 1, 0, 0, 0);
            const endOfQuarter = new Date(fullYear, quarter * 3 + 3, 0, hours, minutes, seconds);

            quartersByYear.push({ quarter: quarter, dateByQuaterStart: startOfQuarter, dateByQuaterEnd: endOfQuarter } as IQuater);
        }
        return quartersByYear;
    }

    const onChangeQuarter = (e: NativeEventInfo<SelectBoxInstance, Event | KeyboardEvent | MouseEvent | PointerEvent> & ValueChangedInfo) => {

        // if (quarter && quarter.length > 0) {
        const fullYear = data?.timeData?.time_from?.getFullYear() ?? new Date().getFullYear();
        const quarterOfYear = (quarter && quarter.length > 0) ? quarter : getQuartersFromYear(fullYear);
        const quarterSelected = [...quarterOfYear].find(x => x.quarter === e.value as number);
        const startOfQuarter = quarterSelected?.dateByQuaterStart;
        const endOfQuarter = quarterSelected?.dateByQuaterEnd;
        if (startOfQuarter && endOfQuarter) {

            setData(pre => {
                if (pre?.dataSelect?.fullYear === startOfQuarter.getFullYear()) {

                    // props.onChangeTimeData && props.onChangeTimeData({ time_from: startOfQuarter, time_to: endOfQuarter } as ITimeData)
                    if (pre?.dataSelect?.quarter !== quarterSelected?.quarter) {
                        return {
                            ...pre,
                            dataSelect: {
                                ...pre.dataSelect,
                                quarter: quarterSelected.quarter
                            },
                            timeData: {
                                time_from: startOfQuarter,
                                time_to: endOfQuarter
                            }
                        } as IData
                    } else {
                        return {
                            ...pre,
                            timeData: {
                                time_from: startOfQuarter,
                                time_to: endOfQuarter
                            }
                        } as IData
                    }

                }
                return pre;
            });


        }

        // }
    }
    //

    const handleChangeMonthByYear = (time_from: Date, fullYear: number): ITimeData | undefined => {
        const monthOfYear = (month && month.length > 0) ? month : getMonthsFromYear(fullYear);
        const monthSelected = [...monthOfYear].find(x => x.month === time_from.getMonth());
        const startOfMonth = monthSelected?.dateByMonthStart && new Date(monthSelected?.dateByMonthStart);
        const endOfMonth = monthSelected?.dateByMonthEnd && new Date(monthSelected?.dateByMonthEnd);
        // 
        startOfMonth?.setFullYear(fullYear);
        endOfMonth?.setFullYear(fullYear);

        return {
            time_from: startOfMonth,
            time_to: endOfMonth
        } as ITimeData

        // }
    }
    const handleChangeMonthByCycle = (time_from?: Date): ITimeData | undefined => {
        const fullYear = time_from?.getFullYear() ?? new Date().getFullYear();
        const monthOfYear = (month && month.length > 0) ? month : getMonthsFromYear(fullYear);
        const currentDate = new Date();
        const currentMonth = time_from?.getMonth() ?? currentDate.getMonth();
        const currentYear = time_from?.getFullYear() ?? currentDate.getFullYear();
        const timeFrom = new Date(currentYear, currentMonth, 1)
        // 
        const monthSelected = [...monthOfYear].find(x => x.month === timeFrom.getMonth());
        const startOfMonth = monthSelected?.dateByMonthStart;
        const endOfMonth = monthSelected?.dateByMonthEnd;
        return {
            time_from: startOfMonth,
            time_to: endOfMonth
        } as ITimeData

        // }
    }

    const getMonthsFromYear = (fullYear: number) => {
        const dateByMonth: IMonth[] = [];
        for (let month = 0; month < 12; month++) {
            const startOfMonth = new Date(fullYear, month, 1, 0, 0, 0);
            const endOfMonth = new Date(fullYear, month + 1, 0, hours, minutes, seconds);
            // startOfMonth.setHours(-7)
            // endOfMonth.setHours(-7)
            dateByMonth.push({
                month: month,
                dateByMonthStart: startOfMonth,
                dateByMonthEnd: endOfMonth
            });

        }

        return dateByMonth;
    }

    const onChangeMonth = (e: NativeEventInfo<SelectBoxInstance, Event | KeyboardEvent | MouseEvent | PointerEvent> & ValueChangedInfo) => {
        //
        const fullYear = data?.timeData?.time_from?.getFullYear() ?? new Date().getFullYear();
        const monthOfYear = (month && month.length > 0) ? month : getMonthsFromYear(fullYear);


        const monthSelected = [...monthOfYear].find(x => x.month === e.value as number);
        const startOfMonth = monthSelected?.dateByMonthStart;
        const endOfMonth = monthSelected?.dateByMonthEnd;
        // console.log(startOfMonth, data?.dataSelect?.fullYear, "month change")
        if (startOfMonth && endOfMonth) {
            setData(pre => {
                if (pre?.dataSelect?.fullYear === startOfMonth.getFullYear()) {
                    // props.onChangeTimeData && props.onChangeTimeData({ time_from: startOfMonth, time_to: endOfMonth } as ITimeData)
                    if (pre?.dataSelect?.month !== monthSelected?.month) {
                        return {
                            ...pre,
                            dataSelect: {
                                ...pre?.dataSelect,
                                month: monthSelected.month
                            },
                            timeData: {
                                time_from: startOfMonth,
                                time_to: endOfMonth
                            }
                        } as IData
                    }
                    else {
                        return {
                            ...pre,
                            timeData: {
                                time_from: startOfMonth,
                                time_to: endOfMonth
                            }
                        } as IData
                    }


                }
                return pre;
            });

        }

    }

    // 



    const getWeekFromYear = (year: number) => {


        // 
        const weeks: IWeek[] = [];
        const firstDayOfYear = new Date(year, 0, 1, 0, 0, 0);
        let currentDate = new Date(firstDayOfYear);

        while (currentDate.getFullYear() === year) {
            const startOfWeek = new Date(currentDate);
            const endOfWeek = new Date(currentDate);
            endOfWeek.setDate(endOfWeek.getDate() + 6);
            endOfWeek.setHours(hours, minutes, seconds)

            weeks.push({
                week: getWeekNumber(currentDate),
                weekByYearStart: startOfWeek,
                weekByYearEnd: endOfWeek
            } as IWeek)
            //   weeks.push({
            //     week: getWeekNumber(currentDate),
            //     start: startOfWeek,
            //     end: endOfWeek,
            //   });

            currentDate.setDate(currentDate.getDate() + 7);
        }

        return weeks;
    };

    const getWeekNumber = (date: Date) => {
        const oneJan = new Date(date.getFullYear(), 0, 1);
        const daysOffset = oneJan.getDay() > 4 ? 1 : 0;
        const days = Math.floor((date.getTime() - oneJan.getTime()) / 86400000);
        const weekNumber = Math.floor((days + daysOffset + 1) / 7);
        return weekNumber;
    };

    //   week

    const handleChangeWeekByCycle = (time_from?: Date): IWeek | undefined => {
        const fullYear = time_from?.getFullYear() ?? new Date().getFullYear();
        const weekOfYear = (week && week.length > 0) ? week : getWeekFromYear(fullYear);
        const currentDate = new Date();
        const currentDay = currentDate.getDate();
        const currentMonth = time_from?.getMonth() ?? currentDate.getMonth();
        const currentYear = time_from?.getFullYear() ?? currentDate.getFullYear();
        const timeFrom = new Date(currentYear, currentMonth, currentDay)
        // 
        const weekSelected = [...weekOfYear].find(x => x.week === getWeekNumber(timeFrom));
        const startOfWeek = weekSelected?.weekByYearStart;
        const endOfWeek = weekSelected?.weekByYearEnd;

        return {
            week: weekSelected?.week,
            weekByYearStart: startOfWeek,
            weekByYearEnd: endOfWeek
        } as IWeek
        // }
    }

    const handleChangeWeekByYear = (time_from: Date, fullYear: number): IWeek | undefined => {
        const weekOfYear = (week && week.length > 0) ? week : getWeekFromYear(fullYear);
        const weekSelected = [...weekOfYear].find(x => {
            return x.week === getWeekNumber(new Date(time_from))
        });
        const startOfWeek = weekSelected?.weekByYearStart && new Date(weekSelected?.weekByYearStart!);
        const endOfWeek = weekSelected?.weekByYearEnd && new Date(weekSelected?.weekByYearEnd!);
        startOfWeek?.setFullYear(fullYear);
        endOfWeek?.setFullYear(fullYear);

        return {
            week: weekSelected?.week,
            weekByYearStart: startOfWeek,
            weekByYearEnd: endOfWeek
        } as IWeek
        // }
    }

    const onChangeWeek = (e: NativeEventInfo<SelectBoxInstance, Event | KeyboardEvent | MouseEvent | PointerEvent> & ValueChangedInfo) => {

        // 
        const fullYear = data?.timeData?.time_from?.getFullYear() ?? new Date().getFullYear();
        const weekOfYear = (week && week.length > 0) ? week : getWeekFromYear(fullYear);
        // 
        const weekSelected = [...weekOfYear].find(x => x.week === e.value as number);
        const startOfWeek = weekSelected?.weekByYearStart;
        const endOfWeek = weekSelected?.weekByYearEnd;
        // console.log(startOfWeek, endOfWeek?.toISOString())
        if (startOfWeek && endOfWeek) {

            setData(pre => {
                if (pre?.dataSelect?.fullYear === startOfWeek.getFullYear()) {
                    const newWeek = weekSelected?.week;
                    const currentWeek = pre?.dataSelect?.week;
                    if (newWeek !== currentWeek) {
                        return {
                            ...pre,
                            dataSelect: {
                                ...pre?.dataSelect,
                                week: weekSelected?.week // tránh set week quá nhiều
                            },
                            timeData: {
                                time_from: startOfWeek,
                                time_to: endOfWeek
                            }
                        } as IData
                    }
                    else {
                        return {
                            ...pre,
                            timeData: {
                                time_from: startOfWeek,
                                time_to: endOfWeek
                            }
                        } as IData
                    }

                }
                return pre;
            });
        }

        // }
    }

    // 

    const handleChangeTimeFrom = (e: NativeEventInfo<dxDateBox, Event> & ValueChangedInfo) => {
        const value = (e.value as Date)

        if (value && data?.dataSelect?.cycle === 6) {
            const fullYear = value.getFullYear();
            // const year = new Date(fullYear, 0, 1);

            // const month = value.getMonth();
            // const week = getWeekNumber(value);

            // setData(pre => {

            // if (data?.dataSelect?.fullYear === fullYear) {
            // props.onChangeTimeData && props.onChangeTimeData({ time_from: value, time_to: data?.timeData?.time_to } as ITimeData)
            setData(pre => {

                // if (pre?.dataSelect?.fullYear === fullYear) {
                // props.onChangeTimeData && props.onChangeTimeData({ time_from: pre?.timeData?.time_from, time_to: value } as ITimeData)

                return {
                    ...pre,
                    timeData: {
                        ...pre?.timeData,
                        time_from: value
                    }
                } as IData;

            })
        }

        // }

    }

    const handleChangeTimeTo = (e: NativeEventInfo<dxDateBox, Event> & ValueChangedInfo) => {
        // console.log((e.value as Date).toISOString())
        const value = e.value as Date;
        if (value) {
            // const fullYear = value.getFullYear();

            setData(pre => {
                if (pre?.dataSelect?.cycle === 6) {

                    // if (pre?.dataSelect?.fullYear === fullYear) {
                    // props.onChangeTimeData && props.onChangeTimeData({ time_from: pre?.timeData?.time_from, time_to: value } as ITimeData)

                    return {
                        ...pre,
                        timeData: {
                            ...pre?.timeData,
                            time_to: value
                        }
                    } as IData;
                }


                // }
                return pre;

            })
        }
    }

    // 


    const allowHandleChanage = (cycle: number) => {
        return data?.dataSelect?.cycle === cycle
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <SelectBox
                    label="Kỳ"
                    labelMode="floating"
                    dataSource={cycle}
                    valueExpr={"code"}
                    displayExpr={"name"}
                    value={data?.dataSelect?.cycle}
                    onValueChanged={handleChangeCycle}
                />
            </Grid>
            {
                (!data?.dataSelect?.cycle || visiableCycleByYear.includes(data?.dataSelect?.cycle ?? 0)) ? <Grid item xs={12}>
                    <DateBox
                        label="Năm"
                        labelMode="floating"
                        value={data?.dataSelect?.year}
                        name={"year"}
                        type="date"
                        calendarOptions={
                            {
                                zoomLevel: "year",
                                maxZoomLevel: "decade"
                            }
                        }
                        pickerType="calendar"
                        displayFormat="yyyy"
                        onValueChanged={(e) => handleChangeYear(e)}
                        applyValueMode="instantly"
                    // visible={visiableCycleByYear.includes(data?.dataSelect?.cycle ?? 0)}
                    />
                </Grid> : <></>
            }

            {
                visiableCycleByQuarter.includes(data?.dataSelect?.cycle ?? 0) && <Grid item xs={12}>
                    <SelectBox
                        value={data?.dataSelect?.quarter}
                        label="Quý"
                        labelMode="floating"
                        dataSource={quarter}
                        valueExpr={"quarter"}
                        displayExpr={(quarter) => quarter && `Quý ${quarter.quarter + 1}`}
                        onValueChanged={(e) => data?.dataSelect?.cycle === 4 && onChangeQuarter(e)}

                    />
                </Grid>
            }
            {
                visiableCycleByMonth.includes(data?.dataSelect?.cycle ?? 0) && <Grid item xs={12}>
                    <SelectBox
                        value={data?.dataSelect?.month}
                        label="Tháng"
                        labelMode="floating"
                        dataSource={month}
                        valueExpr={"month"}
                        displayExpr={(month) => month && `Tháng ${month.month + 1}`}
                        onValueChanged={(e) => allowHandleChanage(3) && onChangeMonth(e)}

                    />
                </Grid>
            }
            {
                visiableCycleByWeek.includes(data?.dataSelect?.cycle ?? 0) && <Grid item xs={12}>
                    <SelectBox
                        label="Tuần"
                        labelMode="floating"
                        value={data?.dataSelect?.week}
                        dataSource={week}
                        valueExpr={"week"}
                        displayExpr={(week) => week && `Tuần ${week.week + 1}`}
                        onValueChanged={(e) => data?.dataSelect?.cycle === 2 && onChangeWeek(e)}

                    />
                </Grid>
            }

            <Grid item xs={12}>
                <DateBox
                    label="Từ ngày"
                    labelMode="floating"
                    value={data?.timeData?.time_from}
                    name={"time_from"}
                    type="date"
                    pickerType="calendar"
                    displayFormat={props.displayFormat ?? "dd/MM/yyyy HH:mm:ss"}
                    onValueChanged={handleChangeTimeFrom}
                    readOnly={data?.dataSelect?.cycle !== 6}
                // applyValueMode="instantly"
                />
            </Grid>

            <Grid item xs={12}>
                <DateBox
                    label="Đến ngày"
                    labelMode="floating"
                    value={data?.timeData?.time_to}
                    name={"time_to"}
                    type="date"
                    pickerType="calendar"
                    displayFormat={props.displayFormat ?? "dd/MM/yyyy HH:mm:ss"}
                    onValueChanged={handleChangeTimeTo}
                    readOnly={data?.dataSelect?.cycle !== 6}

                // applyValueMode="instantly"
                />
            </Grid>
            {props?.children}
        </Grid>
    )
}));

export default DomesticWaterServiceDataFilterTime;