import axios from "axios";
import React from "react";

import { AppSession } from "../../../app/shared/app-session/app-session";
import {
  FileManagerClient,
  FileParameter,
  InsertFile,
} from "../../../app/shared/service-proxies/api-shared";
import BASE_URL_API from "../../../app/shared/service-proxies/config";
import { Notification } from "../../layouts";
//interface
interface props {
  multiple?: boolean;
  maxFileSize?: number;
  fileExtension?: string;
  value?: string;
  haveIcon?: boolean;
  callbackURL: (url: string) => void;
}


const Upload: React.FC<props> = (props) => {
  //parameters
  const byte = 1024;
  const imgExtension = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

  //states
  const [fileUpload, setFileUpload] = React.useState<any[]>([]);
  const [value, setValue] = React.useState<string>();
  //hooks
  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  //functions
  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    const formData = new FormData();
    let checkOverload = false;
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        // if (!fileList[i].name.endsWith(".rar")) {
        //   Notification("warning", "Chỉ có thể tải lên file .rar");
        //   break;
        // }
        if (props.maxFileSize && fileList[i].size > props.maxFileSize) {
          checkOverload = true;
        }
        if (!checkOverload) {
          formData.append("MyFile", fileList[i]);
          const options = {
            observe: "response",
            // responseType: "blob",
            headers: {
              Accept: "application/json",
            },
          };
          axios
            .post(
              `${BASE_URL_API}/api/FileManager/PostFile_v3`,
              formData,
              options,
            )
            .then((res) => {
              props.callbackURL(
                `${BASE_URL_API}${res.data[0]?.path.replace("\\", "/")}`,
              );
              const tempArr = new Array<any>();
              setFileUpload(tempArr.concat(res.data));

            });
        } else
          Notification(
            "warning",
            "Chỉ chứa file tối đa là " + formatBytes(props.maxFileSize),
          );
      }
    }
  };

  const formatBytes = function (bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(byte));

    return parseFloat((bytes / Math.pow(byte, i)).toFixed(dm)) + " " + sizes[i];
  };

  //template
  return (
    <>
      <div className="d-flex p-1" style={{ alignItems: "center" }}>
        <input
          accept={props.fileExtension}
          id="input-file"
          type="file"
          onChange={uploadFile}
          multiple={props.multiple ? props.multiple : false}
        />
      </div>
      <div
        style={{
          overflow: fileUpload.length > 1 ? "scroll" : "hidden",
          height: "auto",
        }}
      >
        {!props.haveIcon && fileUpload && fileUpload.length > 0
          ? fileUpload?.map((item, index) => {
              return (
                <div
                  className="border p-1 mb-1"
                  key={`${index} ${new Date()}`}
                  style={{ height: 300 }}
                >
                  <img
                    style={{ height: "100%" }}
                    src={`${BASE_URL_API}${item.data[0]?.path.replace(
                      "\\",
                      "/",
                    )}`}
                    alt={item.fileName}
                  />
                </div>
              );
            })
          : value && (
              <div className="border p-1 mb-1" style={{ height: 300 }}>
                <img style={{ height: "100%" }} src={value} alt={value} />
              </div>
            )}
      </div>
    </>
  );
};

export default Upload;
