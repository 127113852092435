import {
  AppBar,
  Button,
  Checkbox,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  Slide,
  TextField,
  TextFieldProps,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import CTXDate from "../../../../components/CTX/DatePicker";
import {
  ChoThueXeClient,
  CtxThongTinXeHinhAnhModel,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import DHS_Select from "../../../../components/DHS/DHS_Select";
import ChangeIcon from "../../../../Asset/image/change.png";

interface IProps {
  open: boolean;
  itemSelected?: any;
  action?: any;
  onClose: () => void;
  onSubmit?: (data: any, action: any) => void;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});
var appSession: AppSession;

const HinhXeDialog = ({
  open,
  itemSelected,
  action,
  onClose,
  onSubmit,
}: IProps) => {
  const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);

  const [data, setData] = React.useState<CtxThongTinXeHinhAnhModel>(
    new CtxThongTinXeHinhAnhModel()
  );

  React.useEffect(() => {
    if (itemSelected && action === "UPDATE") {
      ctxClient
        .ctxThongTinXeHinhAnhGet({
          ...new CtxThongTinXeHinhAnhModel(),
          id: itemSelected,
        } as CtxThongTinXeHinhAnhModel)
        .then((response) => setData(response[0]));
    } else setData(new CtxThongTinXeHinhAnhModel());
  }, [itemSelected, open]);

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value } as CtxThongTinXeHinhAnhModel);
  };
  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const handleUploadFile = (e: any) => {
    const files = e.target.files;
    getBase64(files[0], (result: any) => {
      setData({
        ...data,
        hinh_anh: result,
      } as CtxThongTinXeHinhAnhModel);
    });
  };
  return (
    <Dialog
      id="sysMenu"
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <AppBar sx={{ width: "inherit", position: "fixed" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Danh sách hình xe
          </Typography>
          {onSubmit && (
            <Button
              autoFocus
              color="inherit"
              onClick={() =>
                onSubmit(
                  {
                    ...data,
                  },
                  action
                )
              }
            >
              Lưu
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <div className="d-flex" style={{ marginTop: 30 }}>
        <div className="w-100 p-4">
          <div style={{ backgroundColor: "white" }} className="p-3 d-flex">
            <div className="w-100 h-100">
              <div className="row">
                <div className="form-group col-md-12">
                  <label className = "ctx-label">Mô tả</label>
                  <TextField
                    sx={{ width: "100%" }}
                    name="mo_ta"
                    onChange={handleChangeInput}
                    value={data.mo_ta}
                  />
                </div>
                <div className="form-group col-md-12">
                  <label className = "ctx-label">Hình ảnh</label>
                  <label htmlFor="uploadImage2" style={{ width: "100%" }}>
                    {data.hinh_anh ? (
                      <>
                        <img
                          src={data.hinh_anh}
                          alt={"hinh"}
                          style={{
                            width: "auto",
                            height: "300px",
                          }}
                        />
                        <div
                          className="position-absolute w-100 d-flex justify-content-end"
                          style={{ top: 5, right: 5, cursor: "pointer" }}
                        >
                          <img
                            src={ChangeIcon}
                            alt="change cccd"
                            style={{ height: 32, width: 32 }}
                          />
                        </div>
                      </>
                    ) : (
                      <div
                        className="c-img-card"
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                      >
                        <i className="bi bi-images" />
                        &nbsp;Thêm ảnh mới...
                      </div>
                    )}
                  </label>
                  <input
                    id="uploadImage2"
                    className="flex-grow-1 form-control"
                    type="file"
                    name="anh_xe"
                    onChange={handleUploadFile}
                    // value={data.anh_xe}
                    style={{ display: "none" }}
                  />
                </div>
                <div className="form-group col-md-12">
                  <label className = "ctx-label">Diễn giải</label>
                  <TextField
                    sx={{ width: "100%" }}
                    name="dien_giai"
                    onChange={handleChangeInput}
                    value={data.dien_giai}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default HinhXeDialog;
