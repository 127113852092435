import React from "react";
import { useParams } from "react-router-dom";
import Select from "../../../../../components/DHS/DHS_Select";
import { AppSession } from "../../../../shared/app-session/app-session";
import {
  CRMClient,
  SaleSupport,
  RoleRightWebModel,
  CustomerInfomation,
} from "../../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../shared/service-proxies/config";

import DateTime from "../../../../../components/DHS/DHS-DateTime/DHSDateTimeComponent";
import notification from "../../../../../common/notification/notification";

interface IProps {
  id?: string;
  action: RoleRightWebModel | undefined;
  nowTime: Date;
  onDestroyAction?: (isDestroy: boolean, goBack?: number) => void;
}

var appSession: AppSession;
const SaleSupportActionPage = (props: IProps) => {
  const crmClient = new CRMClient(appSession, BASE_URL_API);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [data, setData] = React.useState<SaleSupport>(
    new SaleSupport() as SaleSupport
  );
  const [{ _id }, setId] = React.useState(useParams<{ _id: string }>());
  const [isViewDetail, activeViewDetail] = React.useState<boolean>(false);
  const [customerOptions, setCustomerOptions] = React.useState<any[]>([]);
  const [back, setBack] = React.useState<number>(-1);
  React.useEffect(() => {
    if (
      props.action &&
      props.action.class_name?.toLocaleUpperCase() === "VIEWDETAIL"
    ) {
      activeViewDetail(true);
    }
    if (_id) {
      crmClient
        .crmSaleSupportGet({
          ...new SaleSupport(),
          id: _id,
          supported_person: SessionLogin.userName,
        } as SaleSupport)
        .then((response) => {
          setData(response[0] as SaleSupport);
        });
    }
  }, []);
  React.useEffect(() => {
    crmClient
      .crmCustomerInfomationGet(new CustomerInfomation())
      .then((response) => setCustomerOptions(response));
  }, []);

  React.useEffect(() => {
    if (
      props.action &&
      props.action.class_name?.toLocaleUpperCase() === "SAVE"
    ) {  
      try {
        if (_id) {
          crmClient
            .crmSaleSupportUpd({
              ...data,
              id: _id,
              supported_person: SessionLogin.userName,
            } as SaleSupport)
            .then((res) => {
              setBack((item) => (item -= 1));
              notification(
                res[0].ret === 0
                  ? "success"
                  : res[0].ret === 1
                  ? "error"
                  : "warning",
                res[0].message
              );
            });
        } else {
          crmClient
            .crmSaleSupportIns({
              ...data,
              supported_person: SessionLogin.userName,
            } as SaleSupport)
            .then((res) => {
              if (res[0].id) {
                setId({ _id: res[0].id });
              }
              notification(
                res[0].ret === 0
                  ? "success"
                  : res[0].ret === 1
                  ? "error"
                  : "warning",
                res[0].message
              );
            });
        }
        if (props.onDestroyAction) props.onDestroyAction(true, back - 1);
      } catch (error) {
        console.log(error);
      }
    }
  }, [props.nowTime]);

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value } as SaleSupport);
  };
  const handleChangeCustomer = (e: any) => {
    setData({ ...data, customer: e } as SaleSupport);
  };
  const handleChangeCalendar = (e: any) => {
    setData({ ...data, supported_date: e } as SaleSupport);
  };

  return (
    <>
      <div className="d-flex" style={{ padding: 20 }}>
        <div className="w-100" style={{ maxWidth: 800 }}>
          <div className="form-group">
            <Select
              label="Khách hàng*"
              options={customerOptions}
              value={data.customer}
              onChange={handleChangeCustomer}
              fieldValue={"ma_kh"}
              fieldDisplay={"ma_kh"}
              disabled={isViewDetail}
            />
          </div>

          <div className="form-group">
            <label>Thời gian (giờ)*</label>
            <input
              className="flex-grow-1 form-control"
              type="number"
              name="period"
              placeholder="Thời gian"
              onChange={handleChangeInput}
              value={data.period}
              disabled={isViewDetail}
            />
          </div>
          {/* <div className="form-group">
            <Date
              label="Ngày hỗ trợ"
              className="flex-grow-1 form-control"
              type="text"
              name="supported_date"
              onChange={handleChangeCalendar}
              value={data.supported_date?.toString()}
              disabled={isViewDetail}
            />
          </div> */}
          <div className="form-group">
            <DateTime
              label="Ngày hỗ trợ"
              className="flex-grow-1 form-control"
              type="text"
              name="supported_date"
              onChange={handleChangeCalendar}
              value={data.supported_date?.toString()}
              disabled={isViewDetail}
            />
          </div>
          <div className="form-group">
            <label>Kênh hỗ trợ</label>
            <input
              type="text"
              className="flex-grow-1 form-control"
              name="receipt_channel"
              placeholder="Kênh hỗ trợ"
              onChange={handleChangeInput}
              value={data.receipt_channel}
              disabled={isViewDetail}
            />
          </div>
          <div className="form-group">
            <label>Nội dung</label>
            <textarea
              className="flex-grow-1 form-control"
              name="supported_content"
              placeholder="Nội dung"
              onChange={handleChangeInput}
              value={data.supported_content}
              disabled={isViewDetail}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SaleSupportActionPage;
