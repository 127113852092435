import React, { useEffect } from "react";
import "./style.css";

import {
  BaseformClient,
  ParaPermissionAction,
  ParasysActionType,
  ParasysSubAction,
  SysActionType,
  SysMenu,
  UserInfo,
} from "../../../app/shared/service-proxies/api-shared";
import { ListItemIcon, ListItemText, Menu, MenuItem, useMediaQuery } from "@mui/material";
import BASE_URL_API from "../../../app/shared/service-proxies/config";
import { AppSession } from "../../../app/shared/app-session/app-session";
import notification from "../../../common/notification/notification";
import { ACTIONS, ERROR_PERMISSION_MESSAGE } from "../../../common/enums/DHSToolbarRoleAction";
import { IndexedDBService } from "../../../common/indexedDBApi";
import { DB_NAME, DB_VERSION, OBJECTSTORENAME } from "../../../common/indexedDBApi/constant";
import { Button } from "devextreme-react/button";
import { ClickEvent } from "devextreme/ui/button";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../common/Interfaces/StatusMessage";

interface IListMenu {
  menuName: string;
  onClick?: () => void;
}
interface IProps {
  menu?: SysMenu;
  id?: string;
  onClickAction: (action: SysActionType, nowTime: Date) => void;
  customMenu?: IListMenu[];
  isDLink?: boolean;
  current_form?: any;
  isDialogAction?: boolean;

}


const status_message: string[] = ['success', 'error']
const default_hiden = [
  ACTIONS.VIEW,
  ACTIONS.EDIT,
  ACTIONS.DELETE,
  ACTIONS.PRINT,
  ACTIONS.COPY,
  ACTIONS.ATTACHFILE,
  ACTIONS.PERMISSION,
  ACTIONS.LOCK,
  ACTIONS.UNLOCK
]


var appSession: AppSession;
const DHSToolbarRole: React.FC<IProps> = (props: IProps) => {
  const baseFormClient = new BaseformClient(appSession, BASE_URL_API);

  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [actionTypes, setActionTypes] = React.useState<SysActionType[]>();
  const [subActionTypes, setSubActionTypes] = React.useState<SysActionType[]>();
  const indexedDBService = new IndexedDBService(DB_NAME, DB_VERSION, OBJECTSTORENAME);
  const [userInfo, setUserInfo] = React.useState<UserInfo>();
  const [anchorEls, setAnchorEls] = React.useState<{ [key: string]: null | HTMLElement }>();

  // 

  const isSmallScreen = useMediaQuery('(max-width: 600px)');
  // const isMediumScreen = useMediaQuery('(min-width: 601px) and (max-width: 960px)');
  // const isLargeScreen = useMediaQuery('(min-width: 961px)');



  const handleClickItemMenu = (event: ClickEvent, action_code?: string) => {
    if (action_code) {
      setAnchorEls(pre => ({
        ...pre,
        [action_code]: event.event?.currentTarget as HTMLElement
      }));
    }


    baseFormClient.getSubAction({
      ...new ParasysSubAction(),
      action_code: action_code,
      language: 'vi-VN'
    } as ParasysSubAction)
      .then(res => {
        setSubActionTypes(res);
      });
  };
  const handleClose = (action_code?: string) => {
    if (action_code) {
      setAnchorEls(pre => ({
        ...pre,
        [action_code]: null
      }));
    }

  };

  useEffect(() => {
    if (props.isDLink) {
      baseFormClient.getActionType({
        ...new ParasysActionType(),
        action_type: props?.menu?.action_type3,
        language: 'vi-VN'
      } as ParasysActionType)
        .then(res => {
          setActionTypes(res);
        });
    }
    else baseFormClient.getActionType({
      ...new ParasysActionType(),
      action_type: props?.menu?.action_type,
      language: 'vi-VN'
    } as ParasysActionType)
      .then(res => {
        setActionTypes(res);
      });

    indexedDBService.openDB().then((db) => {
      // const data = { id: 1, name: 'John Doe', age: 25 };

      // indexedDBService.addData(db, userInfo).then(() => {
      //   console.log('Data added to object store');

      indexedDBService.getData(db, SessionLogin.userName).then((data) => {
        setUserInfo(data);
      }).catch((error) => {
        console.log(error);
      });
      // }).catch((error) => {
      //   console.log(error);
      // });
    }).catch((error) => {
      console.log(error);
    });
  }, [])

  //#region handle_click_action
  const handleClick = (lst: SysActionType) => {

    if ((userInfo?.isadmin || userInfo?.issuperadmin)) {
      props.onClickAction(lst, new Date());
    }
    else {
      baseFormClient.getUserPermissionAction({
        ...new ParaPermissionAction(),
        menuid: props?.menu?.menuid,
        username: SessionLogin.userName,
        action_code: lst.action_code,

      } as ParaPermissionAction)
        .then(res => {
          if (res?.grant_access === true) {
            props.onClickAction(lst, new Date());
          }
          else {
            if (ERROR_PERMISSION_MESSAGE[lst.action_code!])
              notification(status_message[1], ERROR_PERMISSION_MESSAGE[lst.action_code!])
            else
              notification(status_message[1], ERROR_PERMISSION_MESSAGE['NOT_PERMISSION'])
          }
        }).catch(error => {
          notification(status_message[1], "Server bị lỗi vui lòng thử lại sau hoặc liên hệ người quản trị.")

        });
    }


    // props.onClickAction(lst, new Date());
    // }
  };

  //#endregion


  return (
    <div>
      <div className="row no-gutters position-relative" >
        {actionTypes && actionTypes

          .map((itemFiltered, index) => (
            <div

              key={itemFiltered.action_code ? itemFiltered.action_code + index : index}
            >
              {
                !itemFiltered.subaction ?
                  (<div className={`col-12 ${!isSmallScreen ? "p-0" : "px-2"}`}>
                    <Button
                      stylingMode="text"
                      // stylingMode="contained"
                      // type="default"


                      disabled={default_hiden.includes(itemFiltered.action_code as ACTIONS) && !props.id}
                      key={itemFiltered.action_code ? itemFiltered.action_code : ""}
                      id={itemFiltered.action_code}
                      icon={itemFiltered.iconweb}
                      className={
                        // itemFiltered.class_name +
                        "toolbar-button"
                        // " " +
                        // (itemFiltered.disabled === true ? "disabled" : "")
                      }

                      style={{
                        // padding: '0 0.75rem !important',
                        pointerEvents: 'auto',
                        fontSize: 14,
                        textTransform: 'capitalize',
                        color: props.isDialogAction ? itemFiltered.backgroupcolorweb : itemFiltered.fontcolorweb,
                        fontWeight: 500,
                        // backgroundColor: 'transparent',
                        cursor: default_hiden.includes(itemFiltered.action_code as ACTIONS) && !props.id ? 'not-allowed' : 'pointer'
                        //     (
                        //       itemFiltered.class_name?.toLocaleLowerCase() === "ADD".toLocaleLowerCase() ? "#337ab7" :
                        //         itemFiltered.class_name?.toLocaleLowerCase() === "EDIT".toLocaleLowerCase() ? "#5bc0de" :
                        //           itemFiltered.class_name?.toLocaleLowerCase() === "DETAIL".toLocaleLowerCase() ? "#94a3b8" :
                        //             itemFiltered.class_name?.toLocaleLowerCase() === "DELETE".toLocaleLowerCase() ? "#d9534f" : ""
                        //     )

                      }}
                      text={!isSmallScreen ? itemFiltered.action_name : ""}
                      onClick={() => handleClick(itemFiltered as SysActionType)}
                      hint={!isSmallScreen ? `Chức năng ${itemFiltered.action_name}` : ""}
                    />
                  </div>) : (
                    <div className={`col-12 ${!isSmallScreen ? "p-0" : "px-2"}`}>
                      <Button
                        stylingMode="text"
                        // stylingMode="contained"
                        // type="default"
                        disabled={default_hiden.includes(itemFiltered.action_code as ACTIONS) && !props.id}
                        key={itemFiltered.action_code ? itemFiltered.action_code : ""}
                        id={itemFiltered.action_code}
                        icon={itemFiltered.iconweb}
                        className={
                          // itemFiltered.class_name +
                          "toolbar-button toolbar-dropdown-button"
                          // " " +
                          // (itemFiltered.disabled === true ? "disabled" : "")
                        }
                        style={{
                          // padding: '0 0.75rem',
                          pointerEvents: 'auto',
                          fontSize: 14,
                          textTransform: 'capitalize',
                          color: itemFiltered.fontcolorweb,
                          fontWeight: 500,
                          // backgroundColor: 'transparent',
                          cursor: default_hiden.includes(itemFiltered.action_code as ACTIONS) && !props.id ? 'not-allowed' : 'pointer'

                        }}
                        text={!isSmallScreen ? itemFiltered.action_name : ""}
                        onClick={(e) => handleClickItemMenu(e, itemFiltered.action_code)}
                        aria-controls={anchorEls && Boolean(anchorEls![itemFiltered.action_code!]) ? `basic-menu-${itemFiltered.action_code}` : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorEls && Boolean(anchorEls![itemFiltered.action_code!]) ? 'true' : undefined}
                        hint={!isSmallScreen ? itemFiltered.action_name : ""}
                      />

                      <Menu
                        id={`basic-menu-${itemFiltered.action_code}`}
                        anchorEl={anchorEls && anchorEls![itemFiltered.action_code!]}
                        open={anchorEls ? Boolean(anchorEls![itemFiltered.action_code!]) : false}
                        onClose={() => handleClose(itemFiltered.action_code)}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                        sx={{
                          padding: '0 0.75rem',

                        }}
                      >
                        {
                          subActionTypes && subActionTypes.map((action, index) => {
                            return <MenuItem onClick={(e) => {
                              handleClick(action);
                              handleClose(itemFiltered.action_code);
                            }}
                            >
                              <ListItemIcon
                              >
                                <i className={action.iconweb} style={{
                                  color: "#3787D7"
                                }}></i>
                              </ListItemIcon>
                              <ListItemText ><span style={{ fontWeight: 500, color: "#3787D7" }}>{action.action_name}</span></ListItemText>
                            </MenuItem>

                          })
                        }


                      </Menu>
                    </div>

                  )
              }
            </div>
          ))}
        {/* <div
          className="setting-icon dropdown"
          style={{ position: "absolute", right: 15, color: "#FFF" }}
        >
          {props.customMenu && props.customMenu.length > 0 && (
            <a
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              className="bi bi-three-dots-vertical"
              style={{ position: "relative", fontSize: 18 }}
              href="."
            ></a>
          )}

          <div className="dropdown-menu dropdown-menu-right" >
            {props.customMenu &&
              props.customMenu.map((item) => {
                return (
                  <button
                    className="dropdown-item"
                    type="button"
                    key={item.menuName}
                    onClick={item.onClick}
                  >
                    {item.menuName}
                  </button>
                );
              })}
          </div>
        </div> */}
      </div>
    </div>
  );
};

DHSToolbarRole.propTypes = {};

// const mapStateToProps = (state:IReducer) => ({
//     list: state.ToolbarReducer?.list
// })

// export default connect(mapStateToProps, {} ) (DHSToolbarRole)
export default DHSToolbarRole;
