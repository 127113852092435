import React, { useEffect, useRef, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { EventInfo } from "devextreme/events";
import dxDataGrid, {
  DataChangeInfo,
  RowInsertedInfo,
  RowInsertingInfo,
  RowRemovedInfo,
  RowRemovingInfo,
  RowUpdatedInfo,
  RowUpdatingInfo,
  SavingInfo,
  SelectionChangedInfo,
} from "devextreme/ui/data_grid";
import DataGrid from "devextreme-react/data-grid";
// import { AppSession } from "../../../../shared/app-session/app-session";
import {
  HomeClient,
  QLCCClient,
  SetupModule,
  SysActionType,
  SysGenRowTableModel,
  SysMenu,
  SystemClient,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import { IReducer, Notification } from "../../../components/layouts";
import { ACTIONS } from "../../../common/enums/DHSToolbarRoleAction";
import DHSToolbarRole from "../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
// import DHSDataGridEdit from "../../../DHS/DHS_DataGridEdit/DataGridEdit";
// import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
// import DialogCreateTable from "./DialogCreateTable";
import BlockUI from "../../../components/Block-UI/Block-ui";
import DHSBreadcrumb from "../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import {
  getDataRowSelected,
  getIndexRowSelected,
  onAddRow,
  onCoppyRow,
  onDeleteRow,
  onEditRow,
  onViewRow,
} from "../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";
import notification from "../../../common/notification/notification";
import {
  STATUS_MESSAGE,
  TYPE_MESSAGE,
} from "../../../common/Interfaces/StatusMessage";
import { IAction } from "../../../common/Interfaces/Interfaces";
import DataGridSetingAction from "./action/DataGridSetingAction";
import { checkRequiredField } from "../../../components/utils/object.util";
// import DialogCreateGenRowByParamsPro from "./DialogCreateGenRowByParamsPro/DialogCreateGenRowByParamsPro";
// import DialogCreateGenRowByFormatReportInfo from "./DialogCreateGenRowByFormatReportInfo";
// import DHSButton from "../../../components/DHSComponents/actions-and-list/DHSButton/DHSButton";
// import DHSDataGridEdit from "../../../components/DHSComponents/data-grid/DHS_DataGridEdit/DHSDataGridEdit";
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
// import CreateGenRowBySP from "./DialogCreateGenRowByParamsPro/CreateGenRowBySP";
// import DHSTextBox from "../../../components/DHSComponents/editors/DHSTextBox/DHSTextBox";
// import DialogCreateGenRowByTableName from "./DialogCreateGenRowByTableName/DialogCreateGenRowByTableName";
// import DialogCreateGenRowBySPName from "./DialogCeateGenRowBySPName/DialogCreateGenRowBySPName";
// import DialogCreateDictionaryByMenuID from "./DialogCreateDictionaryByMenuID/DialogCreateDictionaryByMenuID";
// import ConfigMenu from "./ConfigMenu/ConfigMenu";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { AppSession } from "../../shared/app-session/app-session";
import DHSSelectBox from "../../../components/DHSComponents/editors/DHSSelectBox/DHSSelectBox";
import DHSButton from "../../../components/DHSComponents/actions-and-list/DHSButton/DHSButton";
import DHSTextBox from "../../../components/DHSComponents/editors/DHSTextBox/DHSTextBox";
type Props = {};

type FilterType = {
  isShowSearch: boolean;
  isShowGroup: boolean;
  isShowSort: boolean;
  isShowFilter: boolean;
  isShowHideColumn: boolean;
  isShowFixing: boolean;
};

const defaultAction: IAction<any> = {
  open: false,
  type: "",
  index: 0,
  payload: null,
};

let appSession: AppSession;
const SystemDataGridSetting = (props: Props) => {
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const systemClient = new SystemClient(appSession, BASE_URL_API);
  const dataGridRef = useRef<DataGrid>(null);
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const [dataSource, setDataSource] = useState<SysGenRowTableModel[]>([]);
  const location = useLocation();
  const [allowEdit, setAllowEdit] = useState<boolean>(true);
  const [visibleDialogCreateTable, setVisibleDialogCreateTable] =
    useState<boolean>(false);
  const [visibleDialogCreateByPro, setVisibleDialogCreateByPro] =
    useState<boolean>(false);
  const [visibleDialogCreateByFormat, setVisibleDialogCreateByFormat] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [menuSelected, setMenuSelected] = useState<string>();
  const [menuObjectSelected, setMenuObjectSelected] = useState<SysMenu>();
  const [tableName, setTableName] = useState<string>("");
  const [setupModules, setSetupModules] = useState<SetupModule[]>([]);
  const [setupModuleIdSelected, setSetupModuleIdSelected] = useState<string>();
  const [sysMenus, setSysMenus] = useState<SysMenu[]>([]);
  // const [sysMenuSelected, setSysMenuSeleted] = useState<SysMenu>();
  const [sysMenusLv2, setSysMenusLv2] = useState<SysMenu[]>([]);
  const [menuLv2Selected, setMenuLv2Selected] = useState<string>();

  const listMenu = useSelector(
    (state: IReducer) => state.RoleReducer?.listMenu
  );

  const [actCx, setActCx] = React.useState<IAction<any>>(defaultAction);
  const [actCx2, setActCx2] = React.useState<IAction<any>>(defaultAction);
  const [actCx3, setActCx3] = React.useState<IAction<any>>(defaultAction);
  const [actCx4, setActCx4] = React.useState<IAction<any>>(defaultAction);
  const [actCx5, setActCx5] = React.useState<IAction<any>>(defaultAction);

  useEffect(() => {
    (async () => {
      const res = await systemClient.moduleGet({
        ...new SetupModule(),
      } as SetupModule);
      setSetupModules([...res]);
    })();
  }, []);

  useEffect(() => {
    if (setupModuleIdSelected) {
      const temp: SysMenu[] = listMenu
        ? listMenu?.filter(
            (item) =>
              item.moduleid === setupModuleIdSelected &&
              item.menuid &&
              item.menuid.endsWith(".00") &&
              !item.menuid.endsWith(".00.00")
          )
        : [];
      setSysMenusLv2([...temp]);
    } else {
      const temp: SysMenu[] = listMenu
        ? listMenu?.filter(
            (item) =>
              item.menuid &&
              item.menuid.endsWith(".00") &&
              !item.menuid.endsWith(".00.00")
          )
        : [];
      setSysMenusLv2([...temp]);
    }
  }, [setupModuleIdSelected]);

  useEffect(() => {
    console.log(menuLv2Selected);

    if (menuLv2Selected) {
      // console.log(menuLv2Selected.substring(0, 5));

      const temp: SysMenu[] = listMenu
        ? listMenu?.filter(
            (item) =>
              item.menuid &&
              item.menuid.startsWith(menuLv2Selected.substring(0, 5)) &&
              !item.menuid.endsWith(".00")
          )
        : [];
      setSysMenus([...temp]);
    } else if (setupModuleIdSelected) {
      const temp: SysMenu[] = listMenu
        ? listMenu?.filter(
            (item) =>
              item.moduleid === setupModuleIdSelected &&
              item.menuid &&
              !item.menuid.endsWith(".00")
          )
        : [];
      setSysMenus([...temp]);
    } else {
      if (listMenu) {
        setSysMenus([...listMenu]);
      } else {
        setSysMenus([]);
      }
    }
  }, [menuLv2Selected, setupModuleIdSelected]);

  const handleGetData = async () => {
    // const res = await homeClient.sysGenRowTableGet({
    //   ...new SysGenRowTableModel(),
    //   menuid: menuSelected || "",
    //   table_name: tableName || "",
    // } as SysGenRowTableModel).catch(error => {
    //   console.log(error.status);

    // })
    (async () => {
      try {
        setLoading(true);
        const res = await homeClient.sysGenRowTableGet({
          ...new SysGenRowTableModel(),
          menuid: menuSelected || "",
          table_name: tableName || "",
        } as SysGenRowTableModel);
        setDataSource([...res]);
      } catch (error) {
        setDataSource([]);
      } finally {
        setLoading(false);
      }
    })();
  };

  const handleClickAction = async (action: SysActionType) => {
    switch (action?.action_code?.toLocaleUpperCase()) {
      case ACTIONS.ADD:
        if (menuSelected) {
          setActCx({
            ...actCx,
            open: true,
            type: action?.action_code?.toUpperCase() ?? "",
            payload: Object.assign({}, {
              menuid: menuSelected,
              table_name: tableName,
              width: 150,
            } as SysGenRowTableModel),
          });
        } else {
          notification(TYPE_MESSAGE.WARNING, "Vui lòng chọn tính năng");
        }
        break;
      case ACTIONS.DELETE:
        if (dataGridRef.current) {
          try {
            setLoading(true);
            const dataSelected = await getDataRowSelected<SysGenRowTableModel>(
              dataGridRef
            );
            const index = await getIndexRowSelected(dataGridRef);

            if (dataGridRef.current && dataSelected && index >= 0) {
              if (window.confirm("Bạn có chắc chắn muốn xóa?")) {
                const response = await homeClient.sysGenRowTableDel({
                  id: dataSelected?.id,
                } as SysGenRowTableModel);

                if (response[0].status === 0) {
                  const newData = dataSource.filter(
                    (item) => item.id !== dataSelected.id
                  );
                  setDataSource([...newData]);
                  notification(
                    TYPE_MESSAGE.SUCCESS,
                    STATUS_MESSAGE[`DELETE_0`]
                  );
                } else {
                  notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`DELETE_1`]);
                }
              }
            } else {
              notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
              );
            }
          } catch (error) {
            notification(
              TYPE_MESSAGE.WARNING,
              STATUS_MESSAGE[`WARNING_SELECT_ROW`]
            );
          } finally {
            setLoading(false);
          }
        }
        break;
      case ACTIONS.VIEW:
      case ACTIONS.EDIT:
        if (dataGridRef.current) {
          try {
            const dataSelected = await getDataRowSelected<SysGenRowTableModel>(
              dataGridRef
            );
            const index = await getIndexRowSelected(dataGridRef);

            if (dataGridRef.current && dataSelected && index >= 0) {
              setActCx({
                ...actCx,
                open: true,
                type: action?.action_code?.toUpperCase() ?? "",
                payload: dataSelected ?? null,
              });
            } else {
              notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
              );
            }
          } catch (error) {
            notification(
              TYPE_MESSAGE.WARNING,
              STATUS_MESSAGE[`WARNING_SELECT_ROW`]
            );
          }
        }
        break;
      case ACTIONS.COPY:
        if (dataGridRef.current) {
          try {
            const dataSelected = await getDataRowSelected<SysGenRowTableModel>(
              dataGridRef
            );
            const index = await getIndexRowSelected(dataGridRef);

            if (dataGridRef.current && dataSelected && index >= 0) {
              // dataSelected.id = undefined;
              setActCx({
                ...actCx,
                open: true,
                type: action?.action_code?.toUpperCase() ?? "",
                payload: { ...dataSelected },
              });
            } else {
              notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
              );
            }
          } catch (error) {
            notification(
              TYPE_MESSAGE.WARNING,
              STATUS_MESSAGE[`WARNING_SELECT_ROW`]
            );
          }
        }
        break;
      default:
        break;
    }
  };

  // const handleChangeColorCell = (e: CellPreparedEvent<any, any>) => {
  //   if(e.data && e.column && e.column.dataField === 'column_name') {
  //     switch (e.data['column_name']) {
  //       case "approve_status_name":
  //         // e.cellElement.className += 'backgound-color-row-datagrid2'
  //         e.cellElement.className += 'backgound-color-row-datagrid2'
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // };

  const handleChangePage = () => {

    if( dataGridRef && dataGridRef.current && dataGridRef.current?.instance) {
      const newIndex: any = dataGridRef.current?.instance.pageIndex() + 1;

      if(newIndex === dataGridRef.current?.instance.pageCount() ){
  
        dataGridRef.current?.instance.pageIndex(0);
      }
      else {
        dataGridRef.current.instance.pageIndex(newIndex)
      }

    }
    
  }

  // const intervalRef = React.useRef<any | null>(null);
  // useEffect(() => {
  //   intervalRef.current = setInterval(handleChangePage, 2000)

  //   return () => {
  //     clearInterval(intervalRef.current);
  // };
  // }, [])

  return (
    <Box sx={{ p: 1 }}>
      {/* <DHSButton text="click" onClick={handleChangePage}/> */}
      <Grid
        container
        spacing={2}
        style={{ flexDirection: "row", alignItems: "end", marginBottom: 10 }}
      >
        <Grid item xs={12}>
          <DHSBreadcrumb location={location} />
        </Grid>
        <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
          <Box>
            <InputLabel className="cc-input">Module</InputLabel>
            <DHSSelectBox
              dataSource={setupModules || []}
              displayExpr={(item: SetupModule) =>
                item && `${item.moduleid} - ${item?.description}`
              }
              valueExpr={"moduleid"}
              // value={props?.filter?.menuid}
              showClearButton
              searchEnabled
              noDataText="Không tìm thấy"
              placeholder="Tìm Module ..."
              onValueChanged={(e) => {
                setSetupModuleIdSelected(e.value);
                // setMenuSelected(e.value);
                // setMenuObjectSelected(
                //   listMenu?.find((item) => item.menuid === e.value)
                // );
              }}
            />
          </Box>
        </Grid>
        <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
          <Box>
            <InputLabel className="cc-input">Tính năng LV2</InputLabel>
            <DHSSelectBox
              dataSource={sysMenusLv2 || []}
              displayExpr={(item: SysMenu) =>
                item && `${item.menuid} - ${item?.bar}`
              }
              valueExpr={"menuid"}
              // value={props?.filter?.menuid}
              showClearButton
              searchEnabled
              noDataText="Không tìm thấy"
              placeholder="Tìm tính năng ..."
              onValueChanged={(e) => {
                setMenuLv2Selected(e.value);
                // setMenuSelected(e.value);
                // setMenuObjectSelected(
                //   sysMenus?.find((item) => item.menuid === e.value)
                // );
              }}
            />
          </Box>
        </Grid>
        <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
          <Box>
            <InputLabel className="cc-input">Tính năng</InputLabel>
            <DHSSelectBox
              dataSource={sysMenus || []}
              displayExpr={(item: SysMenu) =>
                item && `${item.menuid} - ${item?.bar}`
              }
              valueExpr={"menuid"}
              // value={props?.filter?.menuid}
              showClearButton
              searchEnabled
              noDataText="Không tìm thấy"
              placeholder="Tìm tính năng ..."
              onValueChanged={(e) => {
                setMenuSelected(e.value);
                setMenuObjectSelected(
                  sysMenus?.find((item) => item.menuid === e.value)
                );
              }}
            />
          </Box>
        </Grid>
        <Grid item xl={1.5} lg={2} md={3} sm={6} xs={12}>
          <Box>
            <InputLabel className="cc-input">Tên bảng</InputLabel>
            <DHSTextBox
              value={tableName}
              onValueChanged={(e) => setTableName(e.value)}
              placeholder="Nhập tên bảng"
            />
            {/* <TextField
              style={{ width: "100%" }}
              value={tableName}
              onChange={(e) => setTableName(e.target.value)}
              placeholder="Nhập tên bảng"
            /> */}
          </Box>
        </Grid>
        <Grid item xl={5} lg={5} md={5} sm={10} xs={12}>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <DHSButton type="default" onClick={handleGetData}>
              Tìm kiếm
            </DHSButton>
          
          </Box>
        </Grid>
        {/* <Grid item md={1.6} xs={4}>
        </Grid> */}
      </Grid>

      <DHSToolbarRole id={" "} menu={menu} onClickAction={handleClickAction} />
      
      <DHSDataGridEdit
        data={dataSource}
        table_name="sysGenRowTable"
        keyExpr="id"
        mode="popup"
        dataGridRef={dataGridRef}
        allowEdit={allowEdit}
        pageSize={menu?.page_size}
        isPagination={menu?.is_pagination}
        isMultiple={menu?.is_multiple}
        groupingAutoExpandAll={false}
        height={70}
     
        // handleCellPrepared={handleChangeColorCell}
      />
      {/* <DialogCreateTable
        visibleDialogCreateTable={visibleDialogCreateTable}
        setVisibleDialogCreateTable={setVisibleDialogCreateTable}
      /> */}
{/* 
      <DialogCreateGenRowByTableName
        open={actCx3.open}
        action={""}
        data={actCx3.payload}
        onClose={function (): void {
          setActCx3(defaultAction);
        }}
      />

      <DialogCreateGenRowByParamsPro
        visibleDialogCreateTable={visibleDialogCreateByPro}
        setVisibleDialogCreateTable={setVisibleDialogCreateByPro}
      />

      <DialogCreateGenRowByFormatReportInfo
        visibleDialogCreateTable={visibleDialogCreateByFormat}
        setVisibleDialogCreateTable={setVisibleDialogCreateByFormat}
      />

      <CreateGenRowBySP
        open={actCx2.open}
        action={""}
        data={actCx2.payload}
        onClose={function (): void {
          setActCx2(defaultAction);
        }}
      />

      <DialogCreateGenRowBySPName
        open={actCx4.open}
        action={""}
        data={actCx4.payload}
        onClose={function (): void {
          setActCx4(defaultAction);
        }}
      /> */}

      {/* <DialogCreateDictionaryByMenuID
        open={actCx5.open}
        action={""}
        data={actCx5.payload}
        onClose={function (): void {
          setActCx5(defaultAction);
        }}
      /> */}

      <BlockUI blocking={loading} title={""}></BlockUI>

      <DataGridSetingAction
        open={actCx.open}
        action={actCx.type}
        data={actCx.payload}
        index={actCx.index}
        onEditSuccess={(data) => {
          const dataTemp = dataSource.map((item) => {
            if (item.id === data.id) {
              return data;
            } else {
              return item;
            }
          });
          setDataSource([...dataTemp]);

          // const tempDataSource = [...dataSource];

          // const updatedIndex = tempDataSource.findIndex(
          //   (x) => x.id === data?.id
          // );
          // if (updatedIndex > 0) {
          //   tempDataSource[updatedIndex] = data;
          //   setDataSource(tempDataSource);
          // }
        }}
        onAddSuccess={(data) => {
          if (data) {
            console.log(data);

            // const tempDataSource = [data, ...dataSource];
            // tempDataSource.unshift(data);
            setDataSource([data, ...dataSource]);
          }
        }}
        onClose={() => {
          setActCx(defaultAction);
        }}
      />

      {/* <ConfigMenu
        open={actCx5.open}
        action={actCx5.type}
        data={actCx5.payload}
        index={actCx5.index}
        onEditSuccess={(data) => {
          // const dataTemp = dataSource.map((item) => {
          //   if (item.id === data.id) {
          //     return data;
          //   } else {
          //     return item;
          //   }
          // });
          // setDataSource([...dataTemp]);
        }}
        onAddSuccess={(data) => {
          // if (data) {
          //   console.log(data);
          //   // const tempDataSource = [data, ...dataSource];
          //   // tempDataSource.unshift(data);
          //   setDataSource([data, ...dataSource]);
          // }
        }}
        onClose={() => {
          setActCx5(defaultAction);
        }}
      /> */}
    </Box>
  );
};

export default SystemDataGridSetting;


const CardStatsVertical = (props: any) => {
  // ** Props
  const { title, subtitle, color, icon, stats, trend, trendNumber } = props

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', marginBottom: 5.5, alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <Avatar sx={{ boxShadow: 3, marginRight: 4, color: 'common.white', backgroundColor: `${color}.main` }}>
            {icon}
          </Avatar>
          {/* <IconButton size='small' aria-label='settings' className='card-more-options' sx={{ color: 'text.secondary' }}>
            <DotsVertical />
          </IconButton> */}
        </Box>
        <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>{title}</Typography>
        <Box sx={{ marginTop: 1.5, display: 'flex', flexWrap: 'wrap', marginBottom: 1.5, alignItems: 'flex-start' }}>
          <Typography variant='h6' sx={{ mr: 2 }}>
            {stats}
          </Typography>
          <Typography
            component='sup'
            variant='caption'
            sx={{ color: trend === 'positive' ? 'success.main' : 'error.main' }}
          >
            {trendNumber}
          </Typography>
        </Box>
        <Typography variant='caption'>{subtitle}</Typography>
      </CardContent>
    </Card>
  )
}