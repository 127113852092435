import React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    CaVch01Client,
    CustTransOpen,
    ObjParaCustTransOpen,
    QLCCClient,
    QLCCVehicleCardModel,
    SoVch05Client,
    SoVch05M,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import '../../actions/ApartmentBuilding.css';
import { generateUUID } from "../../../../components/utils/generationUuid";
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DataGrid from "devextreme-react/data-grid";
import { EventInfo } from "devextreme/events";
import dxDataGrid, { SelectionChangedInfo } from "devextreme/ui/data_grid";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { useSelector } from "react-redux";


interface IProps {
    open: boolean;
    payload?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (response?: any[]) => void; // any là SoVch05M
    stt_rec?: string,
    exchange?: number
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const keyExpr: { [key: number]: string } = {
    1: "stt_rec",
    2: "stt_rec",
    3: "stt_rec",
    4: "stt_rec",
    5: "stt_rec",
    6: "id",
}

var appSession: AppSession;
const ReceiptInvoiceAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const menu = useSelector((state: IReducer) => state.RoleReducer?.listMenu.find(x => x.code_name === "CA1"));
    // 
    // const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const caVch01Client = new CaVch01Client(appSession, BASE_URL_API);
    const soVch05Client = new SoVch05Client(appSession, BASE_URL_API);
    const qlccClient = new QLCCClient(appSession, BASE_URL_API);


    const [data, setData] = React.useState<CustTransOpen[]>([]);
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const dataGridRef = React.useRef<DataGrid>(null);

    const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>([]);
    const [selectedRowDatas, setSelectedRowDatas] = React.useState<CustTransOpen[]>();
    const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>(-1);
    //  

    React.useEffect(() => {

        if (props.payload.data) {
            // setSelectedRowKeys((props.payload.data as CaVch01D1[]).map((invoice: CaVch01D1) => invoice.stt_rec_hd!));
        }

        if (props.action === "SELECTINVOICE") {
            caVch01Client
                .getCustTransOpenInvoice({
                    ...new ObjParaCustTransOpen(),
                    idcustomer: props.payload?.idcustomer,
                    ma_cty: SessionLogin.ma_cty,
                } as ObjParaCustTransOpen)
                .then((res) => {

                    const tempData: any[] = [];
                    // Sắp xếp theo tháng từ lớn đến bé
                    const sortedData = res.sort((a, b) => {
                        const dateA = a.ngay_ct;
                        const dateB = b.ngay_ct;

                        if (dateA && dateB) {
                            return dateB.getMonth() - dateA.getMonth();
                        }

                        if (dateA) {
                            return -1;
                        }

                        return 1;
                    });

                    sortedData.forEach(data => {
                        tempData.push({
                            ...data,
                            key: data.stt_rec
                        })
                    })
                    setData(tempData);

                })
                .catch(() => alert("Không lấy được dữ liệu"));
            // setId(props.itemSelected);
        }
        else if (props.action === "SELECTUTILITY") {
            soVch05Client.qLCCsoVch05MGet({
                ma_cty: SessionLogin.ma_cty,
                ma_ct: "DK1",
                iDcustomer: props.payload?.idcustomer
            } as SoVch05M)
                .then((res) => {

                    const tempData: any[] = [];
                    res.forEach(data => {
                        tempData.push({
                            ...data,
                            key: data.stt_rec,
                            tt: data.t_tt,
                            tt_nt: data.t_tt_nt,
                            ps_no_nt: data.t_tien_nt2,
                            ps_no: data.t_tien2
                        })
                    })
                    setData(tempData);

                })
                .catch(() => alert("Không lấy được dữ liệu"));
        }
        else if (props.action === "SELECTCARNUMBER") {
            qlccClient.qLCCVehicleCardGet({
                ...new QLCCVehicleCardModel(),
                is_sell_available: true,
                apartment_building_id: props.payload?.apartment_building_id
            } as QLCCVehicleCardModel)
                .then((res) => {

                    const tempData: any[] = [];
                    console.log(res, "vehicle")
                    res.forEach(data => {
                        tempData.push({
                            ...data,
                            key: data.id,
                            stt_rec: data.id,
                            dien_giai: "Hóa đơn thẻ xe " + data?.card_number,
                            tt: data.amount,
                            tt_nt: data.amount,
                            ps_no_nt: data.amount,
                            ps_no: data.amount
                        })
                    })
                    setData(tempData);

                })
                .catch(() => alert("Không lấy được dữ liệu"));
        }
        else {
            setData([]);
            setId(undefined)
        };
        // }
    }, [props.action]);


    // React.useEffect(() => {
    //     
    //     setLoading(true);
    //     homeClient
    //         ?.roleRightWebGetAll({
    //             ...new RoleRightWebModel(),
    //             rolename: "CC",
    //             username: SessionLogin.userName,
    //             menuid: menu?.menuid,
    //         } as RoleRightWebModel)
    //         .then((res) => {
    //             setRoles(res);
    //             setLoading(false);
    //         });
    //     setLoading(true);

    // }, []);

    // React.useEffect(() => {
    //     const dataTemp = [...data];
    //     if (selectedRowKeys.length > 0 && data.length > 0) {

    //         const selectedRowTemp = selectedRows && [...selectedRows];
    //         selectedRowKeys.forEach(rowKey => {
    //             const index = dataTemp.findIndex(x => x.stt_rec === rowKey)
    //             if (selectedRows.length <= 0) {
    //                 selectedRowTemp.push(dataTemp[index]);
    //             }
    //         });

    //         setSelectedRows(selectedRowTemp)
    //     }
    // }, [selectedRowKeys, data])


    const handleSubmit = () => {

        const tempSelectedRows: any[] = selectedRowDatas ? [...selectedRowDatas].map(item => {

            return {
                ...item,
                id: generateUUID(),
                stt_rec_hd: item.stt_rec,
                idcustomer: item.idCustomer,
                t_tt: item.tt,
                tien_tt_nt: item.ps_no_nt,
                tien_tt: item.ps_no,
                tt_nt: item.tt_nt,
                tt: item.tt,
                ma_nt: item.ma_nt,
                ty_gia: item.ty_gia,
                stt_rec: item.stt_rec,
                so_ct: item.so_ct,
                so_hd: item.so_hd,
                ma_ct_hd: item.ma_ct,
                ma_ct: item.ma_ct,
                ngay_ct: item.ngay_ct,
                ngay_hd: item.ngay_hd,
                tk_pt: item.tk_pt,
            };
        }) : [];

        props.onEditSuccess(tempSelectedRows);
        props.onClose()

    };

    // const handleChangeInput = (e: any) => {
    //     const { name, value } = e.target;
    //     setData({ ...data, [name]: value } as QLCCServiceModel);
    // };

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
                console.log(keys);

                // const index = e.component.getRowIndexByKey(keys[0]);

                // setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys);
            });

            dataGridRef.current.instance.getSelectedRowsData()
                .then(data => {
                    setSelectedRowDatas(data);

                })
        }
    };


    return (
        <Dialog
            id="sysMenu"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "flex",
                justifyContent: "right",
            }}
            TransitionComponent={Transition}
            maxWidth='xs'

        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {"Hóa đơn căn hộ (Chọn)"
                            //  +
                            //     " " +
                            //     `${props.action === "DETAIL"
                            //         ? "[Chi tiết]"
                            //         : props.action === "UPDATE" || _id
                            //             ? "[Cập nhật]"
                            //             : "[Thêm mới]"
                            //     }`
                        }

                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >

                <div>

                    <DHSDataGridEdit
                        data={data}
                        table_name={"CustomerTrans"}
                        keyExpr={keyExpr[props.exchange!]}
                        mode="popup"
                        dataGridRef={dataGridRef}
                        allowEdit={false}
                        pageSize={undefined}
                        isPagination={undefined}
                        isMultiple={true}
                        height={50}
                        groupingAutoExpandAll={false}
                        handleSelectionChanged={handleSelectionChanged}
                    // handlePageChange={handlePageChange}

                    // handleExporting={handleExporting}
                    />
                </div>


            </DialogContent>
            <DialogActions>
                {props.action !== "DETAIL" && (
                    <Button variant="contained" onClick={handleSubmit}>
                        Đồng ý
                    </Button>
                )}
                <Button onClick={props.onClose}>Hủy</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog>
    );
};

export default ReceiptInvoiceAction;


