import { AnyAction } from "redux";
import { CaVch01D0, CaVch01D1, CaVch01M, ModelProcessca01 } from "../../../app/shared/service-proxies/api-shared";
import { SAVE_CHANGE_SERVICE_RECEIPTM, SAVE_CHANGE_SERVICE_RECEIPTD0, SAVE_CHANGE_SERVICE_RECEIPTD1, CLEAR_SERVICE_BOOKING_MASTER, CLEAR_SERVICE_BOOKING_RECEIPT } from "../../constants/ActionTypes";

export interface IServiceBookingReceipt {
    arrayCt: CaVch01D0[],
    arrayCt1: CaVch01D1[],
    modelM: CaVch01M
}

const initialState: IServiceBookingReceipt = {
    arrayCt: [],
    arrayCt1: [],
    modelM: {
        ...new CaVch01M({
            luser: "",
            cuser: "",
            mA_CT: "",
            mA_CTY: "",
            mA_GD: "",
            ma_httt: "",
            mA_NGH: "",
            mA_NT: "",
            mA_KH: "",
            dieN_GIAI: "",
            diA_CHI: "",
            idcustomer: "",
            ngaY_CT: new Date(),
            ngaY_LCT: new Date()
        })
    } as CaVch01M
}



var ServiceBookingReceiptReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SAVE_CHANGE_SERVICE_RECEIPTM:
            return {
                ...state,

                modelM: {
                    ...state.modelM,
                    ...action.payload.modelM
                }

            }
        case SAVE_CHANGE_SERVICE_RECEIPTD0:
            return {
                ...state,

                arrayCt: [

                    ...action.payload.arrayCt
                ]

            }
        case SAVE_CHANGE_SERVICE_RECEIPTD1:
            return {
                ...state,

                arrayCt1: [

                    ...action.payload.arrayCt1
                ]

            }
        // return new ModelProcessca01();
       
        case CLEAR_SERVICE_BOOKING_RECEIPT:
            return {
                ...state,
                modelM: {},
                arrayCt: [],
                arrayCt1: []
            }
        default:
            return state;

    }

}

export default ServiceBookingReceiptReducer;