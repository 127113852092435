import React from "react";
import {
    Slide,
    Typography,
} from "@mui/material";

import {
    BaseformClient,
    HomeClient,
    ParaObjUserPermission,
    ProcessUserPermissionModel,
    RolePermission,
    SysActionType,
    SysModel,
    UserPermission,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import '../../actions/ApartmentBuilding.css';
import notification from "../../../../common/notification/notification";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Checkbox } from "antd";
import { STATUS_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import _ from "lodash";
import DHSBreadcrumb from "../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DataGrid from 'devextreme-react/data-grid';
import { EventInfo } from 'devextreme/events';
import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid';

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    title?: string,
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (data: SysModel) => void;
}

interface RoleMenu {
    id?: string,
    action?: string,
    is_allow?: boolean
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IParams {
    _id?: string
}

interface IModelDel {
    rolename?: string,
    menuid?: string
}

interface IRoleMenuPermission {
    modeldel?: IModelDel[],
    lstModelIns?: RolePermission[]
}


const status_message = ['success', 'error']

var appSession: AppSession;

const UserInfoPermission: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    // const [_id, setId] = React.useState<string>();
    const history = useHistory();

    const { _id } = useParams<IParams>();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    // 

    // const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    // const [roleMenu, setRoleMenu] = React.useState<Record<string, RolePermission[]>>();

    const [data, setData] = React.useState<RolePermission[]>([]);
    const [userPermissionData, setUserPermissionData] = React.useState<RolePermission[]>([]);
    const [updateData, setUpdateData] = React.useState<ProcessUserPermissionModel>(); // any là RoleMenu
    const [updateDataPre, setUpdateDataPre] = React.useState<ProcessUserPermissionModel>(); // any là RoleMenu
    const [isLoading, setLoading] = React.useState<boolean>(false);


    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const baseFormClient = new BaseformClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [checkAll, setCheckAll] = React.useState<boolean>(false);
    const [checkMaxAll, setCheckMaxAll] = React.useState<boolean>(false);
    const [reload, setReload] = React.useState<boolean>(false);

    // 

    // const [selectedIndex, setSelectedIndex] = React.useState<number>();
    // const [defaultExpandedGroups, setDefaultExpandedGroups] = React.useState<string[]>()

    //


    const dataGridRef = React.useRef<DataGrid>(null);
    const dataGridActionRef = React.useRef<DataGrid>(null);
    const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>();
    const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>(-1);


    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>();




    // #region init



    React.useEffect(() => {
        setLoading(true);

        baseFormClient.getUserPermission({
            ...new ParaObjUserPermission(),
            username: _id
        } as ParaObjUserPermission)
            .then(res => {

                const data = res.filter((item, index) => {
                    return (
                        index ===
                        res!.findIndex((obj) => {
                            return obj.menuid === item.menuid;
                        })
                    )
                })
                setData(data);
                setUserPermissionData([])
                setUpdateData(pre => ({ ...pre, lstModelIns: [...res] } as ProcessUserPermissionModel));
                setUpdateDataPre(pre => ({ ...pre, lstModelIns: JSON.parse(JSON.stringify(res)) } as ProcessUserPermissionModel));


            });

        //paging this array res

    }, []);


    // #endregion


    // #region listAction

    const handleListItemClick = (
        rolePermission: any,
        index?: number,
    ) => {
        // setSelectedIndex(index);
        let countUserGrandAccess = 0;
        const tempData: RolePermission[] = [...updateData?.lstModelIns!].filter((item) => {
            if (item.grant_access === true && rolePermission.menuid === item.menuid) {
                ++countUserGrandAccess;
            }
            return rolePermission.menuid === item.menuid
        });

        if (countUserGrandAccess === tempData.length) {
            setCheckAll(true)
        }
        else {
            setCheckAll(false)
        }
        setUserPermissionData(tempData)

    };

    React.useEffect(() => {

    }, [updateData?.lstModelIns])
    // #endregion

    // #region table
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: RoleMenu[]) => {
            setSelectedRowKeys(selectedRowKeys)
        },
        getCheckboxProps: (record: RoleMenu) => ({
            // disabled: !record.vehicle_name, // Column configuration not to be checked
            name: record.action,
        }),
    };


    // #endregion


    const handleSubmit = () => {
        setLoading(true);
        var countDiff = 0;
        const userPermission: UserPermission[] = []
        const tempUpdateDataPre = [...updateDataPre?.lstModelIns!]
        const grantAccessFilter = [...updateData?.lstModelIns!].filter((x, index) => {
            x.username = _id;
            x.cuser = SessionLogin.userName;
            x.luser = SessionLogin.userName;
            if (x.grant_access !== tempUpdateDataPre[index].grant_access) {
                countDiff++;
            }
            if (x.grant_access === true) {
                userPermission.push({
                    username: _id,
                    ...x
                } as UserPermission)
            }
            return x.grant_access === true;
        });
        const delFilter = {
            username: _id,
        } as ParaObjUserPermission

        // if (countDiff > 0) {
            baseFormClient.updateUserPermission({
                ...new ProcessUserPermissionModel(),
                modeldel: delFilter,
                lstModelIns: [
                    ...userPermission
                ] as UserPermission[]
            } as ProcessUserPermissionModel)
                .then(res => {
                    setLoading(false);
                    notification(status_message[res ? 0 : 1], STATUS_MESSAGE[`SAVE_${res ? 0 : 1}`])
                    setReload(pre => !pre)
                })
                .catch(error => {
                    setLoading(false);
                    notification(status_message[1], "Có lỗi xảy ra" + error.status_code)
                })
        // }
    }
    const onChangeCheckAll = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            const tempUserPermission = [...userPermissionData];
            tempUserPermission.map(role => {
                role.grant_access = true;
            });


            setUserPermissionData(tempUserPermission);
            setCheckAll(true);
            // setIndeterminate(false)
        }
        else {
            const tempUserPermission = [...userPermissionData];
            tempUserPermission.map(role => {
                role.grant_access = false;
            });

            setUserPermissionData(tempUserPermission);
            setCheckAll(false);
            // setIndeterminate(false)

        }
    }

    const onChangeCheckMaxAll = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            const tempUserPermission = updateData?.lstModelIns && [...updateData?.lstModelIns];
            if (tempUserPermission) {
                tempUserPermission.map(role => {
                    role.grant_access = true;
                });
                // Khi checkAllMax thì userpermission đang chọn cũng check theo luôn

                const tempUserPermissionData = [...userPermissionData];
                tempUserPermissionData.map(role => {
                    role.grant_access = true;
                });


                setUserPermissionData(tempUserPermissionData);

                // setUpdateData(pre => ({
                //     lstModelIns: [
                //         ...tempUserPermission
                //     ],
                // } as ProcessRolePermissionModel));
                setCheckAll(true);
                setCheckMaxAll(true);
            }

            // setIndeterminate(false)
        }
        else {
            const tempUserPermission = updateData?.lstModelIns && [...updateData?.lstModelIns];
            if (tempUserPermission) {
                tempUserPermission.map(role => {
                    role.grant_access = false;
                });
                // Khi unCheckAllMax thì userpermission đang chọn cũng uncheck theo luôn
                const tempUserPermissionData = [...userPermissionData];
                tempUserPermissionData.map(role => {
                    role.grant_access = false;
                });


                setUserPermissionData(tempUserPermissionData);
                // setRolePermissionData(tempUserPermission);
                setCheckAll(false);
                setCheckMaxAll(false);

            }

            // setIndeterminate(false)

        }
    }

    const handleClickAction = (action: SysActionType, nowTime: Date) => {
        switch (action?.action_code?.toUpperCase()) {

            case ACTIONS.SAVE:
                handleSubmit();
                break;
        }
    }

    const handleSelectionChanged = (
        e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
    ) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {

                const index = e.component.getRowIndexByKey(keys[0]);
                handleListItemClick(keys[0], index);
                setSelectedRowIndex(index >= 0 ? index : -1);
                setSelectedItemKeys(keys[0]);
            });
        }
    };

    const handlePageChange = () => {
        setSelectedRowIndex(-1);
        setSelectedItemKeys(undefined);
    };

    const handleRowUpdating = (e: any) => {
        console.log(e, "eeee")
    }

    return (
        <div className='container-fluid'>


            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location}
                    is_paramater
                    textExpand={`(${urlParams.get("fullname")})` ?? undefined}
                />
            </div>

            <div className="row mb-4">
                <div className="col-12">
                    {/* <Button startIcon={<SaveIcon />} variant="contained" onClick={handleSubmit}>Lưu</Button> */}
                    <DHSToolbarRole
                        id={"true"}
                        menu={menu}
                        customMenu={[]}
                        onClickAction={handleClickAction}
                        isDLink
                        isDialogAction
                    />
                </div>
            </div>



            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="d-flex align-items-center pb-2" style={{ gap: 10, justifyContent: 'space-between' }}>

                        <Typography variant="subtitle1" sx={{ fontSize: 16, fontWeight: 600, marginBottom: '0.325rem', color: '#333' }}>
                            Danh sách chức năng
                        </Typography>

                        <Checkbox checked={checkMaxAll} onChange={onChangeCheckMaxAll}>
                            <span style={{ fontWeight: 600 }}>Phân quyền tất cả</span>
                        </Checkbox>

                    </div>
                    <DHSDataGridEdit
                        data={data}
                        table_name={"RoleMenuPermission"}
                        // key="so_ct"
                        mode="popup"
                        dataGridRef={dataGridRef}
                        allowEdit={false}
                        pageSize={menu?.page_size}
                        isPagination={menu?.is_pagination}
                        isMultiple={menu?.is_multiple}
                        groupingAutoExpandAll={true}

                        handleSelectionChanged={handleSelectionChanged}
                        handlePageChange={handlePageChange}

                    // handleExporting={handleExporting}
                    />


                </div>

                <div className="col-md-5 col-12 mt-3 mt-md-0">
                    <div>
                        <div className="d-flex align-items-center pb-2" style={{ gap: 10, justifyContent: 'space-between' }}>
                            <Typography variant="subtitle1" sx={{ fontSize: 16, fontWeight: 600, marginBottom: '0.325rem', color: '#333' }}>
                                Cấp quyền
                            </Typography>

                            <Checkbox checked={checkAll} onChange={onChangeCheckAll}>
                                <span style={{ fontWeight: 600 }}>Phân quyền tất cả</span>
                            </Checkbox>
                        </div>

                        <DHSDataGridEdit
                            data={userPermissionData}
                            table_name={"ActionRolePermission"}
                            // key="so_ct"
                            mode="cell"
                            dataGridRef={dataGridActionRef}
                            allowEdit={true}
                            isPagination={false}
                            // handleSelectionChanged={handleSelectionActionChanged}
                            // handlePageChange={handlePageActionChange}
                            handleRowUpdating={handleRowUpdating}

                        // handleExporting={handleExporting}
                        />


                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserInfoPermission;


