import { Box, Button, DialogActions, DialogContent, Grid, IconButton, InputLabel, TextField } from '@mui/material'
import React from 'react'
import DHSDateTimeComponentV2 from '../../../../../../components/DHS/DHS-DateTime/DHSDateTimeComponentV2'
import DHSNumberBox from '../../../../../../components/DHSComponents/editors/DHSNumberBox/DHSNumberBox'
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { FileManagerClient, QLCCClient, QLCCDomesticWaterCycleModel, QLCCDomesticWaterModel } from '../../../../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../../../../shared/service-proxies/config';
import { Notification } from '../../../../../../components/layouts';
import axios from 'axios';
import { AppSession } from '../../../../../shared/app-session/app-session';

interface IProps {
    dataSource: QLCCDomesticWaterModel,
    setLoading: () => void;
    domesWaterCycleDefault: QLCCDomesticWaterCycleModel;
    SessionLogin: any;
    active: boolean; // kích hoạt
    qLCCClient: QLCCClient,
    apartment_building_id: string,
    action: string,
    domesticWaterCyclePrevious: QLCCDomesticWaterCycleModel;
    onSubmit: (dataSource: QLCCDomesticWaterModel) => void;
    onCloseWindow: () => void;
}

var appSession: AppSession;
const UpdateDomesticWaterOneMeter = (props: IProps) => {

    const { domesWaterCycleDefault, SessionLogin, active, apartment_building_id, qLCCClient } = props;

    const [frontSideImage, setFrontSideImage] = React.useState<string[]>();
    const [data, setData] = React.useState<QLCCDomesticWaterModel>();
    const fileManagerClient = new FileManagerClient(appSession, BASE_URL_API)

    async function OnloadPreDomestic(dataParams: QLCCDomesticWaterModel) {
        try {
            if (dataParams && dataParams?.apartment_id && props.domesticWaterCyclePrevious && !dataParams.enter_status) {
                const res3 = await qLCCClient.qLCCDomesticWaterGet({
                    ...new QLCCDomesticWaterModel(),
                    apartment_id: dataParams.apartment_id,
                    domestic_water_cycle_id: props.domesticWaterCyclePrevious.id,
                    action_type: 'BY-CYCLE',
                    apartment_building_id: apartment_building_id,
                    ma_cty: SessionLogin.ma_cty,
                } as QLCCDomesticWaterModel);


                const getItemByCycle = res3.pop();
                if (getItemByCycle) {
                    /* chỉ số mới của tháng trước là chỉ số cũ của tháng này */
                    setData({
                        ...dataParams,
                        previous: getItemByCycle.latest ? getItemByCycle.latest : dataParams.previous,

                    } as QLCCDomesticWaterModel);
                }
                else {
                    setData({
                        ...dataParams,
                        previous: 0,
                    } as QLCCDomesticWaterModel);
                }
            } else if (dataParams) {
                setData({
                    ...dataParams
                } as QLCCDomesticWaterModel);
            }
        } catch (ex) {
            console.log(ex)
        }
    }

    React.useEffect(() => {
        if (active) {
            const propsDataSource = props.dataSource;
            OnloadPreDomestic(propsDataSource);
        }
    }, [active])

    const handleRemoveImage = (e: any) => {
        const { name, value } = e;
        if (data) {
            const images = data.attach_files?.split(';').filter(employee => employee !== value);

            if (images && images.length > 0) {
                setData({
                    ...data,
                    [name]: images.join(';')
                } as QLCCDomesticWaterModel)
            }
            else {
                setData({
                    ...data,
                    [name]: ''
                } as QLCCDomesticWaterModel)
            }
        }
    }

    const toBase64 = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });

    const handleAddNewImage = async (e: any) => {
        if (data) {
            const { name, files } = e.target;
            const formData: FormData = new FormData();

            const options = {
                observe: "response",
                // responseType: "blob",
                headers: {
                    Accept: "application/json",
                    token: SessionLogin.Token
                },
            };

            formData.append("tbName", 'QLCCDomesticWater');
            formData.append("colName", 'attach_files');

            const listFile: string[] = []
            const reader = new FileReader();
            reader.onloadend = () => {
                console.log(reader.result)

            };

            for (const file of files) {
                const data: any = await toBase64(file);
                listFile.push(data)
            }

            fileManagerClient.postFile_v5_for_web(
                'QLCCDomesticWater',
                'attach_files',
                listFile,
            ).then((res: any) => {
                if (res) {
                    const resData = res.join(';');
                    if (resData) {
                        setData({
                            ...data,
                            [name]: data.attach_files?.concat(";", resData) || resData
                        } as QLCCDomesticWaterModel);
                        e.target.value = '';
                    }
                } else Notification("warning", "Không tải được hình ảnh");
            })
        }
    };
    React.useMemo(() => {
        if (data && data.attach_files && active) {
            setFrontSideImage(data?.attach_files.split(';'))
        }
    }, [data])

    const handleChangeInput = (e: any) => {
        if (data) {
            const { name, value } = e.target;

            const newValue = isNaN(value) ? 0 : value;

            setData({
                ...data,
                [name]: newValue,
            } as QLCCDomesticWaterModel);
        }
    }

    const handleChangeText = (e: any) => {
        if (data) {
            if (!payStatus(data)) {
                const { name, value } = e.target;

                setData({
                    ...data,
                    [name]: value,
                } as QLCCDomesticWaterModel);
            }
        }
    }

    React.useEffect(() => {
        if (data) {
            let consume = 0;
            let consume_total = 0;
            if (data.previous !== undefined && data.latest !== undefined && data.loss !== undefined) {
                consume = data.latest - data.previous;
                consume_total = isNaN(consume) ? 0 : consume - consume * data.loss;
            } else if (data.previous !== undefined && data.latest !== undefined) {
                consume = data.latest - data.previous;
                consume_total = consume;
            }
            setData({
                ...data,
                consume: consume < 0 ? 0 : consume,
                consume_total: consume_total < 0 ? 0 : consume_total
            } as QLCCDomesticWaterModel);
        }
    }, [data?.previous, data?.latest, data?.loss])

    const handleChangeDate = (e: any) => {
        if (props.action !== "DETAIL") {
            const { name, value } = e;
            setData({ ...data, [name]: new Date(value) } as QLCCDomesticWaterModel);
        }
    }

    const payStatus = (data: QLCCDomesticWaterModel) => {
        return data && data.pay_status;
    }

    function onDisableInput() {
        // return data && data.pay_status ;
        if (data === undefined || (data && data.pay_status === undefined || domesWaterCycleDefault?.is_block === undefined))
            return false
        return data.pay_status || domesWaterCycleDefault?.is_block
    }

    const handleChangeImage = async (e: any, url: string) => {
        if (data) {
            const { name, files } = e.target;
            const formData: FormData = new FormData();

            let images = data.attach_files?.split(';')
            const options = {
                observe: "response",
                // responseType: "blob",
                headers: {
                    Accept: "application/json",
                    token: SessionLogin.Token
                },
            };

            for (const file of files) {
                formData.append("MyFile", file);
            }
            //setLoading(true)
            await axios
                .post(`${BASE_URL_API}/api/FileManager/PostFile_v3`, formData, options)
                .then((res) => {
                    if (res.data) {

                        if (images && images.length === 0) {

                            setData({
                                ...data,
                                [name]: data.attach_files?.concat(res.data[0].path, ';'),
                            } as QLCCDomesticWaterModel);

                        } else if (images && images.length > 0) {
                            let indexImage = images.findIndex(item => item === url);
                            images[indexImage] = res.data[0].path;
                            setData({
                                ...data,
                                [name]: images.join(';'),
                            } as QLCCDomesticWaterModel);

                        }

                        //setLoading(false)
                        // reset lại file input
                        e.target.value = '';
                    } else Notification("warning", "Không lấy được hình ảnh");
                    //setLoading(false);
                });
        }
    }

    async function handleSubmit() {
        if (data) {
            const newData = data;
            props.onSubmit(newData);
        }
    }

    return (
        <>
            <DialogContent className='p-2 p-md-4'>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                    <Grid item xs={12}
                        sx={{
                            fontSize: 14, color: '#333', fontWeight: '500'
                        }}
                    >
                        {
                            `${data?.floor_name}/ căn hộ ${data?.apartment_name}`
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Chu Kỳ</InputLabel>
                            <TextField
                                className="ksc-input"
                                sx={{ width: "100%" }}
                                name="domestic_water_cycle_id"
                                value={domesWaterCycleDefault?.title || ""}
                                disabled={domesWaterCycleDefault && domesWaterCycleDefault?.is_block ? true : false}
                            >

                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} lg={8}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ngày nhập</InputLabel>
                            <DHSDateTimeComponentV2
                                style={{ width: "100%" }}
                                className="cc-input"
                                name="enter_date"
                                onChange={handleChangeDate}
                                value={data?.enter_date || Date.now()}
                                disabled={domesWaterCycleDefault && domesWaterCycleDefault?.is_block ? true : false}
                                type="date"
                                applyValueMode="instantly"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số mới</InputLabel>
                            <DHSNumberBox
                                value={data?.latest}
                                onValueChange={(e) => {
                                    handleChangeInput({
                                        target: {
                                            name: 'latest',
                                            value: e
                                        }
                                    })
                                }}
                                disabled={domesWaterCycleDefault && domesWaterCycleDefault?.is_block ? true : false}
                                min={data?.previous}
                                focusStateEnabled={true}
                                format={'#,##0.##'}
                            />
                            {/* <TextField
                                autoFocus
                                className="ksc-input"
                                inputProps={{ style: { textAlign: "right" } }}
                                sx={{ width: "100%" }}
                                name="latest"
                                onChange={handleChangeInput}
                                value={data.latest}
                                disabled={payStatus(data)}
                                type="number"
                            /> */}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số cũ</InputLabel>
                            <DHSNumberBox
                                value={data?.previous}
                                onValueChange={(e) => {
                                    handleChangeInput({
                                        target: {
                                            name: 'previous',
                                            value: e
                                        }
                                    })
                                }}
                                disabled={domesWaterCycleDefault && domesWaterCycleDefault?.is_block ? true : false}
                                min={0}
                                format={'#,##0.##'}
                            />
                            {/* <TextField
                                className="ksc-input"
                                inputProps={{ style: { textAlign: "right" } }}
                                sx={{ width: "100%", }}
                                name="previous"
                                onChange={handleChangeInput}
                                value={data.previous}
                                disabled={payStatus(data)}
                                type="number"
                            /> */}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Hao hụt (%)</InputLabel>
                            <DHSNumberBox
                                value={data?.loss}
                                onValueChange={(e) => {
                                    handleChangeInput({
                                        target: {
                                            name: 'loss',
                                            value: e
                                        }
                                    })
                                }}
                                disabled={domesWaterCycleDefault && domesWaterCycleDefault?.is_block ? true : false}
                                min={0}
                                format={'#0.#%'}
                            />
                            {/* <TextField
                                className="ksc-input"
                                inputProps={{ style: { textAlign: "right" } }}
                                sx={{ width: "100%" }}
                                name="loss"
                                onChange={handleChangeInput}
                                value={data.loss}
                                disabled={payStatus(data)}
                                type="number"
                            /> */}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tiêu thụ</InputLabel>
                            <DHSNumberBox
                                value={data?.consume}
                                // disabled={true}
                                min={0}
                                disabled={domesWaterCycleDefault && domesWaterCycleDefault?.is_block ? true : false}
                                format='#,##0.##'
                            />
                            {/* <TextField
                                className="ksc-input"
                                inputProps={{ style: { textAlign: "right" } }}
                                sx={{ width: "100%" }}
                                name="consume"
                                value={data.consume}
                                // disabled={payStatus(data)}
                                disabled={true}
                                type="number"
                            /> */}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tổng tiêu thụ</InputLabel>
                            <DHSNumberBox
                                value={data?.consume_total}
                                // disabled={true}
                                min={0}
                                disabled={domesWaterCycleDefault && domesWaterCycleDefault?.is_block ? true : false}
                                format='#,##0.###'
                            />
                            {/* <TextField
                                className="ksc-input"
                                inputProps={{ style: { textAlign: "right" } }}
                                sx={{ width: "100%" }}
                                name="consume_total"
                                value={data.consume_total}
                                // disabled={payStatus(data)}
                                disabled={true}
                                type="number"
                            /> */}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Ghi chú</InputLabel>
                            <TextField
                                type="text"
                                className="ksc-input"
                                sx={{ width: "100%" }}
                                name="notes"
                                value={data?.notes}
                                onChange={handleChangeText}
                                disabled={domesWaterCycleDefault && domesWaterCycleDefault?.is_block ? true : false}
                                multiline
                                rows={4}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <div className="d-flex align-items-center">
                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>
                                    Hình ảnh
                                </InputLabel>
                            </div>
                            {data?.attach_files &&
                                frontSideImage?.map(item =>
                                    item !== ""
                                    && (<div style={{
                                        position: "relative", width: "324px",
                                        maxWidth: "360px",
                                        height: "204px",
                                        maxHeight: "240px",
                                    }}>
                                        <img
                                            src={
                                                `${BASE_URL_API}${item}`
                                            }
                                            alt="Hình chụp mặt trước"
                                            className="image-shadow"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        />
                                        <div
                                            className="position-absolute w-100 d-flex justify-content-end"
                                            style={{ top: 1, right: 1, cursor: "pointer" }}
                                        >
                                            <IconButton
                                                color={"error"}
                                                aria-label="upload picture"
                                                component="label"
                                                onClick={() => handleRemoveImage({
                                                    name: "attach_files",
                                                    value: item
                                                })
                                                }
                                                disabled={onDisableInput()}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </div>
                                        <div
                                            className="position-absolute w-100 d-flex justify-content-end"
                                            style={{ top: 27, right: 1, cursor: "pointer" }}
                                        >
                                            <IconButton
                                                color="primary"
                                                aria-label="upload picture"
                                                component="label"
                                                disabled={domesWaterCycleDefault.is_block}
                                            >
                                                <input
                                                    id={`uploadFrontImageUpdate`}
                                                    className="flex-grow-1 form-control"
                                                    type="file"
                                                    accept="image/*"
                                                    name="attach_files"
                                                    onChange={
                                                        (e) => handleChangeImage(e, item)
                                                    }
                                                    // value={data.anh_xe}
                                                    style={{ display: "none" }}
                                                />
                                                <ChangeCircleIcon />
                                            </IconButton>
                                        </div>
                                    </div>))
                            }
                            <div className="m-1">
                                <label htmlFor={`uploadFrontImageAdd1`} style={{ width: "100%" }}>
                                    <div
                                        className="c-img-card"
                                        style={{
                                            width: "324px",
                                            maxWidth: "360px",
                                            height: "204px",
                                            maxHeight: "240px",
                                            borderRadius: '12px'
                                        }}
                                    >
                                        <i className="bi bi-images" />
                                        &nbsp;Thêm ảnh mới...
                                    </div>
                                </label>
                                <input
                                    id="uploadFrontImageAdd1"
                                    className="flex-grow-1 form-control"
                                    type="file"
                                    // accept="image/*"
                                    accept=".jpg, .jpeg, .png"
                                    name="attach_files"
                                    onChange={handleAddNewImage}
                                    // value={data.front_side_image}
                                    style={{ display: "none" }}
                                    disabled={domesWaterCycleDefault && domesWaterCycleDefault?.is_block ? true : false}
                                />
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {
                    (domesWaterCycleDefault && !domesWaterCycleDefault.is_block) &&
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Lưu
                    </Button>
                }
                <Button onClick={props.onCloseWindow}>Đóng</Button>
            </DialogActions>
        </>
    )
}

export default UpdateDomesticWaterOneMeter