import React, { ChangeEvent, useEffect, useState } from 'react'
import "./BlockUI.css";
interface IProps {
    blocking: boolean;
    title: string
}
const BlockUI: React.FC<IProps> = props => {
    const { blocking, title } = props;
    return (
        <div>
            {
                blocking ? (
                    <div className="block-ui-container">
                        <div className="block-ui-overlay" />
                        <div className="block-ui-message-container">
                            <div className="block-ui-message">
                                <h4>{title}</h4>
                                <div className="loading-indicator">
                                    <svg id="indicator" viewBox="0 0 100 100">
                                        <circle id="circle" cx="50" cy="50" r="45" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ''
            }
        </div>

    );
};

// BlockUI.defaultProps = {
//   blocking: false,
//   title: "Loading"
// };

export default BlockUI;
