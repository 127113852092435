import React,
{
    useEffect,
    useState
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import DHSToolbar from '../../../../components/DHS/DHS-Toolbar/DHSToolbar';
import { IReducer } from '../../../../components/layouts';
import DHSGridView from '../../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
import { getRoles } from '../../../../redux/actions/RoleActions';
import { AppSession } from '../../../shared/app-session/app-session';
import {
    HomeClient,
    QLCCApartmentModel,
    QLCCClient,
    RoleRightWebModel
} from '../../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../../shared/service-proxies/config';
import { useHistory } from 'react-router-dom';
import BlockUI from '../../../../components/Block-UI/Block-ui';
import ApartmentAction from '../../actions/apartment-action/ApartmentAction';
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

const roleMenu: string = 'CC';
const ApartmentList: React.FC<{}> = () => {

    const dispatch = useDispatch();
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const history = useHistory();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    // const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<QLCCApartmentModel[]>([]);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);

    const [actCx, setActCx] = useState<IAction>(defaultAction);

    // 

    const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
    const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
    const [isShowSort, setShowSort] = React.useState<boolean>(false);
    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);

    const customMenu = [
        {
          menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
          onClick: () => {
            setShowSearch(!isShowSearch);
          },
        },
        {
          menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
          onClick: () => {
            setShowGroup(!isShowGroup);
          },
        },
        {
          menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
          onClick: () => {
            setShowSort(!isShowSort);
          },
        },
        {
          menuName: isShowFilter ? "Ẩn Filter" : "Hiện Filter",
          onClick: () => {
            setShowFilter(!isShowFilter);
          },
        },
      ];


    // 

    useEffect(() => {
        setLoading(true)
        
        homeClient.roleRightWebGetAll({
            ...new RoleRightWebModel(),
            menuid: menu?.menuid,
            rolename: roleMenu,
            username: 'DHSOFT',
        } as RoleRightWebModel)
            .then((res) => {
                
                dispatch(getRoles(res));
                setRoles(res);
                setLoading(false);
            })

    }, [menu?.component])

    useEffect(() => {
        setLoading(true)
        qLCCClient.qLCCApartmentsGet({
            ...new QLCCApartmentModel(),
            apartment_building_id: apartment_building_id,
        } as QLCCApartmentModel).then((res) => {
            
            setLoading(false)
            setDataTable(res)
        })
    }, [apartment_building_id, reload])

    const handleClickAction = (action: RoleRightWebModel) => {
        
        if (action?.class_name?.toUpperCase() === "DELETE") {
            if (window.confirm("Bạn có chắc chắn muốn xóa?")) {
                // setLoading(true)
                // ctxClient.ctxDanhMucXeActions({
                //     ...new CtxDanhMucXeModel(),
                //     id: idItemSelected,
                //     action: action.class_name.toUpperCase(),
                //     tbname: 'kscPhieuKiemSoat'
                // } as CtxDanhMucXeModel)
                //     .then((res) => {
                //         setLoading(false)
                //         

                //         Notification(convert[Number(res[0].ret)], res[0].message);
                //         setReload(!reload)
                //     });
            }
        }
        else {
            setActCx({
                ...actCx,
                open: true,
                type: action?.class_name?.toUpperCase() ?? "",
                payload: idItemSelected
            })
        }
    }
    return (


        <div className='container-fluid'>

            {
               actCx.open && <ApartmentAction
                        open={actCx.open}
                        onClose={function (): void {
                            setActCx(defaultAction);
                        } }
                        action={actCx.type}
                        itemSelected={actCx.payload} onEditSuccess={function (is_success: boolean): void {
                            setReload(pre => !pre)
                        } } >
                    </ApartmentAction>
            }

            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <i
                    className="bi bi-arrow-left-short"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/admin/quan-ly-chung-cu")}
                ></i>
                &nbsp;{menu?.bar}
            </div>

            <DHSToolbar
                id={idItemSelected}
                list={roles
                    ? roles.filter(
                        (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
                    )
                    : []}
                customMenu={customMenu}
                onClickAction={handleClickAction}
            />

            <DHSGridView
                onSelectRow={(row) => {
                    
                    setIdItemSelected(row);
                }}
                // onListSelectRow={handleListOnselectRow}
                itemClicked={function (): void {
                    throw new Error("Function not implemented.");
                }}
                table_name={menu?.component ?? ''}
                show_form_list={true}
                showSelectBox={false}
                data={dataTable}
                showSortPanel={isShowSort}
                showSearchPanel={isShowSearch}
                showGroupPanel={isShowGroup}
                showFilterPanel={isShowFilter}
                defaultPageSize={2000}
                grid_name={"Danh sách CCCD/CMT"}
                excel={false}
            />
            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default ApartmentList;


