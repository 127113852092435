
import React from "react";
import Form from 'devextreme-react/form';
export interface IProps extends React.ComponentProps<typeof Form> {

}

const DHSForm = React.forwardRef<Form, IProps>((props, ref) => {
    return <Form
        {...props}
        ref={ref}>
        {props?.children}
    </Form>
})

export default DHSForm;