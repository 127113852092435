import { combineReducers } from "redux";
import HomePageReducer from "./HomePageReducer";
import AdminPageReducer from "./AdminPageReducer";
import DetailPageReducer from "./DetailPageReducer";
import RoleReducer from "./RoleReducer";
import MachineRptReducer from "./MachineRpt";
import UrlReducer from "./UrlReducer";
import NotificationReducer from "./NotificationReducer";
import ServiceBookingReceiptReducer from "./ServiceBookingReduder/ServiceBookingReceiptReducer";
import OptionsReducer from "./OptionReducer/OptionReducer";
import ServiceBookingMasterReducer from "./ServiceBookingReduder/ServiceBookingMasterReducer";
import ApartmentBuildingReducer from "./ApartmentBuildingReducer/ApartmentBuildingReducer";
const appReducer = combineReducers({
  HomePageReducer,
  AdminPageReducer,
  DetailPageReducer,
  RoleReducer,
  MachineRptReducer,
  UrlReducer,
  NotificationReducer,
  ServiceBookingReceiptReducer,
  ServiceBookingMasterReducer,
  OptionsReducer,
  ApartmentBuildingReducer
});

// const rootReducer = (state, action) => {
//   //Clear all data in redux store to initial.
//   if (action.type === types.DESTROY_SESSION) state = undefined;
//   return appReducer(state, action);
// };

export default appReducer;
