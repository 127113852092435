import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DHSToolbar from '../../../components/DHS/DHS-Toolbar/DHSToolbar';
import { IReducer } from '../../../components/layouts';
import DHSGridView from '../../../components/layouts/Admin/Grid/Grid-view/Grid-View';
import { getRoles } from '../../../redux/actions/RoleActions';
import { AppSession } from '../../shared/app-session/app-session';
import { ChoThueXeClient, CtxDanhMucXeModel, HomeClient, KiemSoatCanClient, KscPhieuKiemSoatModel, RoleRightWebModel } from '../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../shared/service-proxies/config';
import Notification from '../../../common/notification/notification';
import { useHistory } from 'react-router-dom';
import BlockUI from '../../../components/Block-UI/Block-ui';
import CanXeTest from './can-xe-test/CanXeTest';
const convert = ["success", "error", "warning"];
var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}
const DanhSachTestKSC = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    // const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<any[]>([]);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const KSCClient = new KiemSoatCanClient(appSession, BASE_URL_API);
    const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);

    const [actCx, setActCx] = useState<IAction>(defaultAction);

    useEffect(() => {
        homeClient.roleRightWebGetAll({
            ...new RoleRightWebModel(),
            menuid: '30.20.04',
            rolename: 'KS',
            username: 'DHSOFT',
        } as RoleRightWebModel)
            .then((res) => {
                console.log(res, "res")
                dispatch(getRoles(res));
                setRoles(res);
                setLoading(true);
            })

    }, [])

    useEffect(() => {
        setLoading(true)
        KSCClient.kscPhieuKiemSoatGet({
            ...new KscPhieuKiemSoatModel()
        } as KscPhieuKiemSoatModel).then((res) => {
            console.log(res, "phiếu kiểm soát")
            setLoading(false)
            setDataTable(res)
        })
    }, [reload])

    const handleClickAction = (action: RoleRightWebModel, nowTime: Date) => {
        console.log(action, "action")
        if (action?.class_name?.toUpperCase() === "DELETE") {
            if(window.confirm("Bạn có chắc chắn muốn xóa?")){
                setLoading(true)
                ctxClient.ctxDanhMucXeActions({
                    ...new CtxDanhMucXeModel(),
                    id: idItemSelected,
                    action: action.class_name.toUpperCase(),
                    tbname: 'kscPhieuKiemSoat'
                } as CtxDanhMucXeModel)
                    .then((res) => {
                        setLoading(false)
                        console.log(res)
    
                        Notification(convert[Number(res[0].ret)], res[0].message);
                        setReload(!reload)
                    });
            }
        }
        else{
            setActCx({
                ...actCx,
                open: true,
                type: action?.class_name?.toUpperCase() ?? "",
                payload: idItemSelected
            })
        }
    }
    return (

        
        <div className='container-fluid'>

            {
                (React.useMemo(() => {
                    return <CanXeTest 
                    open={actCx.open} 
                    onClose={function (): void {
                       setActCx(defaultAction)
                    } }
                    action={actCx.type}
                    itemSelected={actCx.payload} >
                        
                    </CanXeTest>
                }, [actCx.open]))
            }

            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <i
                    className="bi bi-arrow-left-short"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.goBack()}
                ></i>
                &nbsp;{menu?.bar}
            </div>

            <DHSToolbar
                id={idItemSelected}
                list={roles
                    ? roles.filter(
                        (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
                    )
                    : []}
                // customMenu={customMenu}
                onClickAction={handleClickAction}
            />

            <DHSGridView
                onSelectRow={(row) => {
                    console.log(row, "row selected")
                    setIdItemSelected(row);
                }}
                // onListSelectRow={handleListOnselectRow}
                itemClicked={function (): void {
                    throw new Error("Function not implemented.");
                }}
                table_name={'kscDanhSachPhieu'}
                show_form_list={true}
                showSelectBox={false}
                data={dataTable}
                // showSortPanel={isShowSort}
                // showSearchPanel={isShowSearch}
                // showGroupPanel={isShowGroup}
                // showFilterPanel={isShowFilter}
                defaultPageSize={2000}
                grid_name={"Danh sách phiếu kiểm soát cân"}
                excel={false}
            />
            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default DanhSachTestKSC;


