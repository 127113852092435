import { SelectBox } from 'devextreme-react'
import { ISelectBoxOptions } from 'devextreme-react/select-box'
import React, { forwardRef } from 'react'

interface IProps extends ISelectBoxOptions {

}

const DHSSelectBoxDev = forwardRef((props: IProps) => {
    return (
        <>
            <SelectBox
                {...props}
                placeholder={props.placeholder ? props.placeholder : ''}
            />
        </>
    )
})

export default DHSSelectBoxDev