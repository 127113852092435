import {
  RoleRightWebModel,
  SysMenu,
} from "../../app/shared/service-proxies/api-shared";
import * as types from "../constants/ActionTypes";

export const getAllMenu = (listMenu: SysMenu[]) => (dispatch: any) => {
  dispatch({
    type: types.GET_ALL_MENU,
    payload: listMenu,
  });
};
export const getMenu =
  (menuid: string | undefined) => (dispatch: any, getState: any) => {
    dispatch({
      type: types.GET_MENU,
      payload: menuid,
    });
  };
  export const getMenuByWorker =
  (menu: any) => (dispatch: any, getState: any) => {
    dispatch({
      type: types.GET_MENU_BY_WORKER,
      payload: menu,
    });
  };
export const getMenuShowGrid = (menu: SysMenu) => (dispatch: any) => {
  dispatch({
    type: types.GET_MENU_SHOW_GRID,
    payload: menu,
  });
};
export const clearMenu =
  (menuid: string | undefined) => (dispatch: any, getState: any) => {
    dispatch({
      type: types.CLEAR_MENU,
      payload: menuid,
    });
  };
export const getRole =
  (role: RoleRightWebModel) => (dispatch: any, getState: any) => {
    dispatch({
      type: types.GET_ROLE,
      payload: role,
    });
  };
export const getRoles =
  (roles: RoleRightWebModel[]) => (dispatch: any, getState: any) => {
    dispatch({
      type: types.GET_ROLES,
      payload: roles,
    });
  };
export const clearRoles = () => (dispatch: any, getState: any) => {
  dispatch({
    type: types.CLEAR_ROLES,
  })
}
