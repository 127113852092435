import { Paper } from "@mui/material";
import { Format } from "devextreme-react/chart";
import DataGrid, { SearchPanel, ColumnChooser, HeaderFilter, FilterPanel, FilterBuilderPopup, ColumnFixing, Column, Lookup, FilterRow, Grouping, GroupPanel, Editing, Summary, TotalItem, Selection, CustomRule } from "devextreme-react/data-grid";
import { DataType } from "devextreme/common";
import React from "react";
import themes from "devextreme/ui/themes";
import { HomeClient, SysGenRowTableModel } from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import { AppSession } from "../../shared/app-session/app-session";
import dxDataGrid, { RowRemovedInfo, RowUpdatedInfo, RowUpdatingInfo } from "devextreme/ui/data_grid";
import { EventInfo } from "devextreme/events";
import { IReducer } from "../../../common/Interfaces/Interfaces";
import { useSelector } from "react-redux";
import { ACTIONS } from "../../../common/enums/DHSToolbarRoleAction";
import NumberBox from 'devextreme-react/number-box';
interface IProps {
    data: any,
    action?: ACTIONS,
    exchange?: number,
    onRowUpdated?: (e: EventInfo<dxDataGrid<any, any>> & RowUpdatedInfo<any, any>) => void,
    onRowUpdating?: (e: EventInfo<dxDataGrid<any, any>> & RowUpdatingInfo<any, any>) => void,
    onRowRemoved?: (e: EventInfo<dxDataGrid<any, any>> & RowRemovedInfo<any, any>) => void,
    dataGridRef: React.RefObject<DataGrid<any, any>>,
    selectedRowKeys?: (selectedRowKeys: any[]) => void,
    selectedRowIndexs?: (selectedRowIndex: number[]) => void

}



let appSession: AppSession;

const Receipt01D1: React.FC<IProps> = (props) => {

    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    // 
    const [isHiddenGroup, setIsHiddenGroup] = React.useState<boolean>(false);
    const [isHiddenSearch, setIsHiddenSearch] = React.useState<boolean>(false);
    const [isHiddenFilter, setIsHiddenFilter] = React.useState<boolean>(false);
    const [isHiddenAdvantageFilter, setIsHiddenAdvantageFilter] = React.useState<boolean>(false);
    const [isHiddenColumnChooser, setIsHiddenColumnChooser] = React.useState<boolean>(false);

    // 

    const homeClient = new HomeClient(appSession, BASE_URL_API);

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };
    const [columns, setColumns] = React.useState<SysGenRowTableModel[]>([]);
    const [sumaryColumns, setSumaryColumns] = React.useState<string[]>([]);

    const [allMode] = React.useState<string>('allPages');
    const [checkBoxesMode] = React.useState<string>(themes.current().startsWith('material') ? 'always' : 'onClick');
    const [editRowKey, setEditRowKey] = React.useState<string | null>(null);

    //#region DataGrid
    React.useEffect(() => {

        const tempSumaryColumns: string[] = [];
        let pr = new SysGenRowTableModel();
        pr.table_name = '69.10.01_CaVch01D1'; //props.table_name;
        pr.menuid = menu?.menuid;
        pr.show_form_list = true // props.show_form_list;
        homeClient?.sysGenRowTable(pr).then((res) => {

            res.map((row) => {

                if (row.is_summary) {
                    tempSumaryColumns.push(row.column_name ?? "");
                }
                return row;
            });

            setSumaryColumns(tempSumaryColumns);
            setColumns(res);
        });
    }, []);

    const handleContextMenuItems = (items: any) => {
        if (!items) {
            return;
        }

        items.forEach((item: any) => {
            if (item.items) {
                // Xử lý mục menu lồng nhau
                handleContextMenuItems(item.items);
            }

            switch (item.text) {
                case "Sort Ascending":
                    item.text = 'Sắp xếp tăng dần';
                    break;
                case "Sort Descending":
                    item.text = 'Sắp xếp giảm dần';
                    break;
                case "Clear Sorting":
                    item.text = 'Hủy sắp xếp';
                    break;
                case "Group by This Column":
                    item.text = 'Group cột này';
                    break;
                case "Ungroup All":
                    item.text = 'Hủy bỏ group tất cả';
                    break;
                case "Fix":
                    item.text = 'Cố định';
                    break;
                case "To the left":
                    item.text = 'Từ trái sang';
                    break;
                case "To the right":
                    item.text = 'Từ phải sang';
                    break;
                case "Unfix":
                    item.text = 'Không cố định';
                    break;
            }
        });
    };


    const handleContextMenuPreparing = (e: any) => {
        const { row, column } = e;

        // Xác định loại ContextMenu (dành cho hàng hoặc cột)
        if (row) {
            // ContextMenu cho hàng
            const rowData = row.data;
            // Tùy chỉnh ContextMenu cho hàng dựa trên rowData
            e.items?.push(
                {
                    text: isHiddenGroup ? "Ẩn group" : "Hiện group",
                    onItemClick: () => setIsHiddenGroup(pre => !pre),
                    beginGroup: true,
                },
                {
                    text: isHiddenSearch ? "Ẩn tìm kiếm" : "Hiện tìm kiếm",
                    onItemClick: () => setIsHiddenSearch(pre => !pre),
                },
                {
                    text: isHiddenFilter ? "Ẩn lọc" : "Hiện lọc",
                    onItemClick: () => setIsHiddenFilter(pre => !pre),
                },
                {
                    text: isHiddenAdvantageFilter ? "Ẩn lọc nâng cao" : "Hiện lọc nâng cao",
                    onItemClick: () => setIsHiddenAdvantageFilter(pre => !pre),
                },
                {
                    text: isHiddenColumnChooser ? "Ẩn ẩn cột" : "Hiện ẩn cột",
                    onItemClick: () => setIsHiddenColumnChooser(pre => !pre),

                },
                // Các mục menu khác cho hàng
            );
        } else if (column) {
            // ContextMenu cho cột
            const columnData = column.dataField;
            // Tùy chỉnh ContextMenu cho cột dựa trên columnData
            e.items.push(
                {
                    text: isHiddenGroup ? "Ẩn group" : "Hiện group",
                    onItemClick: () => setIsHiddenGroup(pre => !pre),
                },
                // Các mục menu khác cho cột
            );
        }

        // Thay đổi văn bản của các mục menu trong e.items
        handleContextMenuItems(e.items);


    };

    const ValidationCallback = (e: any) => {
        return e.data.tien_tt >= e.value;
    };
    // const NegativeValidationCallback = (e: any) => {
    //     return e.value > 0;
    // };

    //#endregion
    return (
        <Paper>
            <DataGrid
                ref={props.dataGridRef}
                dataSource={props.data}
                // allowColumnReordering={true}
                allowColumnResizing={true}
                showBorders={true}
                noDataText='Không có dữ liệu'
                repaintChangesOnly={true}
                keyExpr={"stt_rec_hd"}
                // columnHidingEnabled={true}
                // onFocusedRowChanged={(e) => console.log("focus row")}
                columnMinWidth={50}
                columnAutoWidth={true}
                onEditingStart={(e) => {

                    console.log('EditingStart')
                }}
                // onInitialized={(e) => {
                //     setTimeout(() => {


                //         if(props.dataGridRef.current){
                //             props.dataGridRef.current!.instance.closeEditCell();
                //         props.dataGridRef.current!.instance.saveEditData();
                //         }
                //       }, 500);
                // }}
                onInitNewRow={() => console.log('InitNewRow')}
                onRowInserting={(e) => {

                }}
                onRowInserted={(e) => {
                    // const tempData = [...dataTable01D0]
                    // tempData.push(e.data)
                    // setDataTable01D0(tempData)
                }}

                onRowUpdating={(e) => console.log(e, "updating")}
                onRowUpdated={props.onRowUpdated}
                onRowRemoving={() => console.log('RowRemoving')}
                onRowRemoved={props.onRowRemoved}
                onSaving={() => {

                    console.log('Saving')
                    // savedDT01D0();
                }}
                onSaved={(e) => {
                    console.log(e, "saved")

                    // savedDT01D0();
                }}
                // selectedRowKeys={selectedRowKeys}
                // onSelectedRowKeysChange={setSelectedRowKeys}
                onEditCanceling={() => console.log('EditCanceling')}
                onEditCanceled={() => console.log('EditCanceled')}
                // onSelectionChanged={(params) => {
                //     const tempIndexs: number[] = []
                //     if (dataGridRef.current) {
                //         dataGridRef.current.instance.getSelectedRowKeys()
                //             .then(keys => {
                //                 setSelectedRowKeys(keys)
                //                 keys.forEach(key => {

                //                     tempIndexs.push(dataGridRef.current!.instance.getRowIndexByKey(key))
                //                 })
                //             });

                //         setSelectedRowIndexs(tempIndexs);

                //     }

                // }}
                onContextMenuPreparing={handleContextMenuPreparing}
                // onEditorPreparing = {(e) => {  
                //         if (e.parentType === "dataRow" && e.dataField === "tt_nt") {  

                //             e.editorOptions.onValueChanged = (e : any) => {
                //                 console.log("45465")
                //                 e.setValue(e.value)
                //             } 
                //         }  
                //     } 
                // }

                // onCe={(e) => console.log(e, "121312")}
                onSelectionChanged={(e) => {
                    const tempIndexs: number[] = []
                    if (props.dataGridRef.current) {
                        props.dataGridRef.current.instance.getSelectedRowKeys()
                            .then(keys => {
                                props.selectedRowKeys && props.selectedRowKeys(keys)
                                keys.forEach(key => {

                                    tempIndexs.push(props.dataGridRef.current!.instance.getRowIndexByKey(key))
                                })
                            });

                        props.selectedRowIndexs && props.selectedRowIndexs(tempIndexs);

                    }
                }}

            >
                <HeaderFilter visible={true} allowSearch={true}  >

                </HeaderFilter>
                <FilterPanel visible={isHiddenAdvantageFilter} />
                <FilterBuilderPopup title={"Tạo bộ lọc cho cột"} position={filterBuilderPopupPosition} />

                <ColumnChooser enabled={isHiddenColumnChooser} emptyPanelText={"Kéo và thả cột muốn ẩn vào đây"} title={"Chọn cột ẩn"} />
                <ColumnFixing enabled={true} />
                {
                    columns.map((row, index) => {
                        if (row.data_type === "number") {
                            return <Column
                                key={index}
                                caption={row.name_vn}
                                dataField={row.column_name ? row.column_name : ""}
                                dataType={row.data_type as DataType}
                                // format={(value: any) => formatDataType(row.format_type, value)}
                                width={row.width ? row.width : 150}
                                visible={row?.show_form_list}
                                allowEditing={row?.column_name === 'tt_nt'}
                                editCellComponent={(props) => {

                                    return <NumberBox
                                        format="#,###"
                                        style={{ textAlign: "right" }}
                                        onValueChange={(value) => props.data.setValue(value)}
                                        value={props.data.value} />


                                }}
                            >
                                {/* {row.column_name === 'mA_PHI' && (
                            <Lookup
                                dataSource={chargeOptions}
                                displayExpr="ten_phi"
                                valueExpr="ma_phi"
                            />
                        )} */}

                                {/* {
                                    row.column_name === 'tt_nt' && <CustomRule validationCallback={ValidationCallback} message="Số tiền thanh toán lớn hơn số tiền cần thanh toán" />

                                } */}
                                {/* {
                                    row.column_name === 'tt_nt' && <CustomRule validationCallback={NegativeValidationCallback} message="Số tiền thanh toán lớn hơn 0" />

                                } */}

                                {/* <RequiredRule message="Name is required" /> */}



                                {
                                    row?.format_type && <Format
                                        type={row.format_type}
                                        precision={0}
                                    />
                                }
                            </Column>
                        }

                        else return <Column
                            key={index}
                            caption={row.name_vn}
                            dataField={row.column_name ? row.column_name : ""}
                            dataType={row.data_type as DataType}
                            // format={(value: any) => formatDataType(row.format_type, value)}
                            width={row.width ? row.width : 150}
                            visible={row?.show_form_list}
                            allowEditing={row?.column_name === 'tt_nt'}
                        >
                            {/* {row.column_name === 'mA_PHI' && (
                            <Lookup
                                dataSource={chargeOptions}
                                displayExpr="ten_phi"
                                valueExpr="ma_phi"
                            />
                        )} */}
{/* 
                            {
                                row.column_name === 'tt_nt' && <CustomRule validationCallback={ValidationCallback} message="Số tiền thanh toán lớn hơn số tiền cần thanh toán" />

                            } */}
                            {/* {
                                row.column_name === 'tt_nt' && <CustomRule validationCallback={NegativeValidationCallback} message="Số tiền thanh toán lớn hơn 0" />

                            } */}

                            {/* <RequiredRule message="Name is required" /> */}



                            {
                                row?.format_type && <Format
                                    type={row.format_type}
                                    precision={0}
                                />
                            }
                        </Column>
                    })
                }
                <Selection
                    mode="multiple"
                    selectAllMode={allMode}
                    showCheckBoxesMode={checkBoxesMode}
                    deferred={true}


                />
                <FilterRow visible={isHiddenFilter} />
                <SearchPanel visible={isHiddenSearch} placeholder={"Tìm ..."} />
                <Grouping contextMenuEnabled={true} allowCollapsing={true} />
                <GroupPanel visible={isHiddenGroup} emptyPanelText={"Kéo vào thả cột muốn group vào đây"} />
                {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}


                <Editing
                    mode="cell"
                    allowUpdating={props.action !== ACTIONS.VIEW}
                    allowDeleting={false}
                    allowAdding={false}
                    startEditAction={"click"}
                    // useIcons={true}
                    // changes={changes}
                    // onChangesChange={setChanges}
                    // onEditColumnNameChange={(value) => console.log(value, "change")}
                    editRowKey={editRowKey}
                    onEditRowKeyChange={setEditRowKey}
                    newRowPosition={"last"}
                    confirmDelete={true}
                    selectTextOnEditStart={true}


                // texts={{
                //     confirmDeleteMessage: "Bạn chắn chắn muốn xóa hàng này?",
                // }}
                >
                    {/* <Texts confirmDeleteMessage="Bạn chắn chắn muốn xóa hàng này?" addRow={"Thêm dòng mới"}></Texts> */}
                </Editing>

                {/* <Paging defaultPageSize={1} /> */}

                <Summary>
                    {
                        sumaryColumns.map(column_name => {
                            return <TotalItem
                                column={column_name}
                                summaryType="sum"

                                valueFormat={"fixedPoint"}
                                displayFormat={"Tổng: {0}"}
                            />

                        })
                    }
                </Summary>

            </DataGrid>


        </Paper>
    )
}

export default Receipt01D1;
