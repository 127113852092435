import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DHSToolbar from "../../../../DHS/DHS-Toolbar/DHSToolbar";
import { IReducer, Notification } from '../../..';
import DHSGridView from '../../Grid/Grid-view/Grid-View';
import { getRoles } from '../../../../../redux/actions/RoleActions';
import { AppSession } from '../../../../../app/shared/app-session/app-session';
import {
    HomeClient,
    QLCCClient,
    HomePageClient,
    RoleRightWebModel,
    UserInfo,
    SysActionType,
    SiUserInfoClient,
    LoginModel,
    UserInfoModel,


} from '../../../../../app/shared/service-proxies/api-shared';
import BASE_URL_API from '../../../../../app/shared/service-proxies/config';
import { useHistory, useLocation } from 'react-router-dom';
import BlockUI from '../../../../Block-UI/Block-ui';
import notification from "../../../../../common/notification/notification";
import { useParams } from 'react-router'
import DHSDataGridEdit from "../../../../DHS/DHS_DataGridEdit/DataGridEdit";


import UserInfoAction from '../../../../../app/quan-ly-chung-cu/actions/UserInfo-action/UserInfoAction';
import moment from 'moment';
import { data } from 'jquery';
import ResidentAccountAction from '../../../../../app/quan-ly-chung-cu/actions/account-action/ResidentAccountAction';
import DHSToolbarRole from '../../../../DHS/DHS-Toolbar-Role/DHSToolbarRole';
import { ACTIONS } from '../../../../../common/enums/DHSToolbarRoleAction';
import DHSBreadcrumb from '../../../../DHS/DHS_Breadcrumb/DHSBreadcrumb';
import DataGrid from 'devextreme-react/data-grid';
import { getDataRowSelected, getIndexRowSelected } from '../../../../DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row';
import { STATUS_MESSAGE, TYPE_MESSAGE } from '../../../../../common/Interfaces/StatusMessage';

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

var appSession: AppSession;



const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

const roleMenu: string = 'CC';
const status_message = ['success', 'error']

const convert = ["success", "error", "warning"];

const UserInfoType: React.FC<{}> = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

    const [{ _id }, _setId] = React.useState(useParams<{ _id: string }>());
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<any>("");
    // const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
    const [reload, setReload] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<UserInfoModel[]>([]);
    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const siUserInfoClient = new SiUserInfoClient(appSession, BASE_URL_API);

    const [isShowFilter, setShowFilter] = React.useState<boolean>(false);
    const homePageClient = new HomePageClient(appSession, BASE_URL_API);
    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const [action, setAction] = React.useState<IAction>({
        open: false,
        type: "",

        payload: "",
    });

    // 


    const dataGridRef = React.useRef<DataGrid>(null);
    const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

    // 

    // useEffect(() => {
    //     setLoading(true)
    //     homeClient.roleRightWebGetAll({
    //         ...new RoleRightWebModel(),
    //         menuid: menu?.menuid,
    //         rolename: roleMenu,
    //         username: 'DHSOFT',
    //     } as RoleRightWebModel)
    //         .then((res) => {
    //             dispatch(getRoles(res));
    //             setRoles(res);
    //             setLoading(false);
    //         })
    // }, [menu?.component])

    React.useEffect(() => {
        setLoading(true);
        // Tải các dòng trong bảng
        onLoad();

    }, [reload, action.open]);


    const onLoad =  async () => {
       const response = await homePageClient.userInfoWebGet({
        ...new UserInfoModel(),
        ma_cty: SessionLogin.ma_cty,
        disabled: true,
        action_type: "GET"
       } as UserInfoModel);
       setLoading(false)
       setDataTable(response);
    }

    const handleClickAction = async (action: SysActionType) => {
        switch (action.action_code?.toUpperCase()) {
            case ACTIONS.VIEW:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<UserInfoModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            setActCx({
                                ...actCx,
                                open: true,
                                type: action?.action_code?.toUpperCase() ?? "",
                                payload: dataSelected
                            })
                        }
                        else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
            case ACTIONS.APPROVED:
                if (dataGridRef.current) {
                    try {
                        const dataSelected = await getDataRowSelected<UserInfoModel>(dataGridRef);
                        const index = await getIndexRowSelected(dataGridRef);

                        if (dataGridRef.current && dataSelected && index >= 0) {
                            homePageClient.userInfoWebUpd({
                                
                               ...dataSelected,
                               action_type: 'APPROVE'
                            } as UserInfoModel)
                                .then((res) => {
                                    Notification(convert[res ? 0 : 1], STATUS_MESSAGE[`${ACTIONS.APPROVED}_${res ? 0 : 1}`]);
                                    onLoad();
                                })
                        }
                        else {
                            notification(
                                TYPE_MESSAGE.WARNING,
                                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                            );
                        }
                    } catch (error) {
                        notification(
                            TYPE_MESSAGE.WARNING,
                            STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                        );
                    }
                }
                break;
        }
    }
    return (
        <div className='container-fluid'>
            {/* {
                actCx.open && (
                    <UserInfoAction
                        open={true}
                        onClose={function (): void {
                            setActCx(defaultAction);
                        }}
                        action={actCx.type}

                        itemSelected={actCx.payload}
                        onEditSuccess={function (is_success: boolean): void {
                            setReload(pre => !pre)
                        }} >
                    </UserInfoAction>
                )
            } */}

            {
                React.useMemo(() => <ResidentAccountAction
                    open={actCx.open}
                    onClose={function (): void {
                        setActCx(defaultAction);
                    }}
                    action={actCx.type}
                    itemSelected={actCx.payload}
                    onEditSuccess={function (is_success: boolean): void {
                        setReload(pre => !pre)
                    }} >

                </ResidentAccountAction>, [actCx.open, actCx.payload, actCx.type])
            }


            <div
                className="d-flex title-color"
                style={{
                    height: 30,
                    color: "rgb(0 78 255 / 88%)",
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 22,
                    fontWeight: 550,
                }}
            >
                <DHSBreadcrumb
                    location={location}
                />
            </div>

            {/* <DHSToolbar
                id={idItemSelected}
                list={roles
                    ? roles.filter(
                        (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
                    )
                    : []}

                onClickAction={handleClickAction}
                customMenu={[]}
            /> */}

            <DHSToolbarRole
                id=' '
                menu={menu}
                onClickAction={handleClickAction}
            />

            <DHSDataGridEdit
                data={dataTable}
                table_name={menu?.component}
                keyExpr="username"
                mode="popup"
                dataGridRef={dataGridRef}
                allowEdit={false}
                pageSize={menu?.page_size}
                isPagination={menu?.is_pagination}
                isMultiple={menu?.is_multiple}
                groupingAutoExpandAll={false}
            // handleSelectionChanged={handleSelectionChanged}
            // handlePageChange={handlePageChange}

            // handleExporting={handleExporting}
            />

            {/* <DHSGridView
                onSelectRow={(row) => {
                    setIdItemSelected(row);
                }}
                // onListSelectRow={handleListOnselectRow}
                itemClicked={function (): void {
                    throw new Error("Function not implemented.");
                }}
                table_name={menu?.component ? menu?.component : ""}
                show_form_list={true}
                showSelectBox={false}
                showSortPanel={isShowSort}
                showSearchPanel={isShowSearch}
                showGroupPanel={isShowGroup}
                showFilterPanel={isShowFilter}
                data={dataTable}
            /> */}

            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default UserInfoType;


