import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Autocomplete,
    FormControlLabel,
    styled,
    Switch,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    HomeClient,
    RoleRightWebModel,
    HomePageClient,
    UserInfoModel,
    UserInfoGroupModel,
    IUserInfoModel,
} from "../../../shared/service-proxies/api-shared";

import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import '../ApartmentBuilding.css';
import notification from "../../../../common/notification/notification";
import DHSAutoCompleteWithDataSource from "../../../../components/DHSUtilComponent/DHSAutocompleteWithDataSource/DHSAutocompleteWithDataSource";

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

interface IAction {
    type: string;
    open: boolean;
    payload: any;
}
const defaultAction: IAction = {
    type: "",
    payload: null,
    open: false,
};


const status_message = ['success', 'error']

var appSession: AppSession;

const EmployeeAccountAction: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();

    // 
    const [roles, setRoles] = React.useState<RoleRightWebModel[]>();


    const [data, setData] = React.useState<UserInfoModel>({} as UserInfoModel);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [reload, setReload] = React.useState<boolean>(false);



    const homeClient = new HomeClient(appSession, BASE_URL_API);
    const homePageClient = new HomePageClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);


    // 

    const [groupOptions, setGroupOptions] = React.useState<any>([]);


    // 

    const [action, setAction] = React.useState<IProps>(props);
    const [actVt, setActVt] = React.useState<IAction>(defaultAction);


    const [isShowPassword, setIsShowPassword] = React.useState<boolean>(false);




    React.useEffect(() => {
        if (action.action === "EDITACCOUNT" || action.action === "DETAILACCOUNT") {
            homePageClient
                .userInfoWebGet({
                    ...new UserInfoModel(),
                    username: action.itemSelected,
                } as UserInfoModel)
                .then((res) => {
                    setData(res[0]);

                })
                .catch(() => alert("Không lấy được dữ liệu"));


            setId(action.itemSelected);
        } else {
            setData(new UserInfoModel());
            setId(undefined)
        };
        // }
    }, [action.action]);


    React.useEffect(() => {

        setData({ ...data, cuser: SessionLogin.userName, luser: SessionLogin.userName } as UserInfoModel)

        setLoading(true);
        homeClient
            ?.roleRightWebGetAll({
                ...new RoleRightWebModel(),
                rolename: "CC",
                username: SessionLogin.userName,
                menuid: menu?.menuid,
            } as RoleRightWebModel)
            .then((res) => {
                setRoles(res);
                setLoading(false);
            });
        setLoading(true);

    }, []);

    // React.useEffect(() => {




    // }, [])

    React.useMemo(() => {
        setLoading(true);
        homePageClient.userInfoGroupGet({
            ...new UserInfoGroupModel(),
            action_type: 'GET'
        } as UserInfoGroupModel)
            .then(res => {
                setGroupOptions(res)
                setLoading(false);
            });


    }, [reload]);


    const handleSubmit = () => {
        setLoading(true)

        switch (action.action) {
            case "EDITACCOUNT": {
                homePageClient.userInfoWebUpd({
                    ...new UserInfoModel(),
                    ...data,
                    username: action.itemSelected,
                    action_type: isShowPassword ? "RESET-PASSWORD" : "UPDATE"
                } as UserInfoModel)
                    .then((response) => {
                        setLoading(false);
                        notification(status_message[response.status!], response.message);
                        response.status === 0 && action.onEditSuccess(true);

                    });
                break;
            }

            case 'ADDACCOUNT': {
                homePageClient.userInfoWebAdminIns({
                    ...new UserInfoModel({ isadmin: false } as IUserInfoModel),
                    ...data,
                    disabled: false,
                } as UserInfoModel)
                    .then((response) => {
                        setLoading(false);
                        notification(status_message[response.status!], response.message)
                        if (response.status === 0) {
                            action.onEditSuccess(true);
                            setId(response.ref_id)
                            setAction({ ...action, itemSelected: { ...action.itemSelected, username: response.ref_id }, action: 'EDITACCOUNT' })
                        }
                    });
                break;
            }


            default:
                break;
        }

    };

    const handleChangeInput = (e: any) => {
        const { name, value, checked, type } = e.target;

        if (type === 'checkbox') {
            setData({ ...data, [name]: checked } as UserInfoModel);

        }
        else setData({ ...data, [name]: value } as UserInfoModel);
    };



    return (
        <Dialog
            id="sysMenu"
            open={action.open}
            onClose={action.onClose}
            sx={{
                display: "flex",
                justifyContent: "flex-end",
            }}
            TransitionComponent={Transition}
            maxWidth='xs'

        >

            {/* {
                actVt.open && <VehicleTypeAction
                    open={actVt.open}
                    onClose={function (): void {
                        setActVt(defaultAction);
                    }}
                    action={actVt.type}
                    itemSelected={actVt.payload}
                    onEditSuccess={function (is_success: boolean): void {
                        setReload(pre => !pre)
                    }} >

                </VehicleTypeAction>
            } */}

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar +
                            " " +
                            `${action.action === ""
                                ? "[Chi tiết]"
                                : action.action === "EDITACCOUNT" || _id
                                    ? "[Cập nhật]"
                                    : "[Thêm mới]"
                            }`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={action.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >


                <div className="row">
                    <div className="col-4">
                        {/* Tài khoản (username) */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tài khoản (username)</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                placeholder="Tài khoản (username)..."
                                name="username"
                                onChange={(e: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: e.target.name,
                                            value: e.target.value.toLocaleUpperCase()
                                        }
                                    })
                                }}
                                value={data.username}
                                disabled={action.action === ("DETAILACCOUNT" || "EDITACCOUNT")}
                            />

                        </Box>


                        {
                            (
                                props.action === 'ADDACCOUNT'
                                || isShowPassword
                            )
                            && (
                                <>
                                    {/* Mật khẩu (password) */}
                                    <Box style={{ width: "100%", margin: "5px 0px" }}>
                                        <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mật khẩu (password)</InputLabel>
                                        <TextField
                                            className="cc-input"
                                            sx={{
                                                width: "100%",
                                                marginRight: "5px",
                                            }}
                                            placeholder="Mật khẩu (password)"
                                            name="password"
                                            onChange={(e: any) => {
                                                handleChangeInput({
                                                    target: {
                                                        name: e.target.name,
                                                        value: e.target.value.toLocaleUpperCase()
                                                    }
                                                })
                                            }}
                                            value={data.password}
                                            disabled={action.action === ("DETAILACCOUNT" || "EDITACCOUNT")}
                                        />

                                    </Box>
                                </>
                            )
                        }

                        {
                            props.action === 'EDITACCOUNT'
                            && roles
                            && roles.filter(item => item.on_form === 'changePassword').map((role) => (
                                <Button
                                    className={role.class_name}
                                    onClick={() => setIsShowPassword(!isShowPassword)}
                                    style={{ width: "100%" }}

                                >
                                    {`${isShowPassword ? "Tắt" : ""} ${role.name_vn}`}
                                </Button>
                            ))
                        }


                    </div>

                    <div className="col-4">
                        {/* Mã nhân viên */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mã nhân viên</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                placeholder="Mã nhân viên..."
                                name="employeecode"
                                onChange={handleChangeInput}
                                value={data.employeecode}
                                disabled={action.action === "DETAILACCOUNT"}
                            />

                        </Box> */}

                        {/* Họ tên đầy đủ */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Họ tên đầy đủ</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                placeholder="Họ tên đầy đủ..."
                                name="fullname"
                                autoComplete="off"
                                onChange={handleChangeInput}
                                value={data.fullname}
                                disabled={action.action === "DETAILACCOUNT"}
                            />

                        </Box>

                        {/* Số điện thoại */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Số điện thoại</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                placeholder="Số điện thoại..."
                                type={'tel'}
                                name="phone"
                                autoComplete="off"
                                onChange={handleChangeInput}
                                value={data.phone}
                                disabled={action.action === "DETAILACCOUNT"}
                            />

                        </Box>


                        {/* Email */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Email</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                placeholder="Email..."
                                type={'email'}
                                name="email"
                                autoComplete="off"
                                onChange={handleChangeInput}
                                value={data.email}
                                disabled={action.action === "DETAILACCOUNT"}
                            />

                        </Box>

                        {/* Nhóm */}

                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Nhóm</InputLabel>
                            <DHSAutoCompleteWithDataSource
                                dataSource={groupOptions}
                                displayMember="group_name"
                                defaultValue={groupOptions[0]}
                                value={data?.group_id}
                                valueMember="id"
                                disabled={action.action === "DETAILACCOUNT"}
                                onValueChanged={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "group_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}
                            />
                            {/* <Autocomplete
                                id="vehicle-type-select"
                                sx={{ width: "100%" }}
                                options={groupOptions}
                                autoHighlight
                                defaultValue={groupOptions[0]}
                                getOptionLabel={(option: any) => option?.group_name}
                                value={
                                    data?.group_id
                                        ? groupOptions.find(
                                            (item: any) => item.id === data.group_id
                                        )
                                        : null
                                }
                                onChange={(e, newValue: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: "group_id",
                                            value: newValue?.id ? newValue.id : null,
                                        },
                                    });
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="cc-input"
                                        placeholder="Nhóm"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                disabled={action.action === "DETAILACCOUNT"}
                            /> */}

                        </Box>
                    </div>

                    <div className="col-4">
                        {/* Admin */}
                        <Box className="row">
                            <Box className="col-6">


                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Admin</InputLabel>

                                <FormControlLabel control={
                                    <Switch
                                        color="primary"
                                        onChange={handleChangeInput}
                                        name="isadmin"
                                        value={data.isadmin}
                                        checked={data.isadmin}
                                        disabled={action.action === "DETAILACCOUNT"}
                                    />}
                                    label={
                                        <Typography
                                            sx={{ fontSize: 14, color: '#333' }}>
                                            Không/Có
                                        </Typography>} />


                            </Box>


                            {/* <Box className="col-6">


                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Vô hiệu hóa</InputLabel>

                                <FormControlLabel control={
                                    <Switch
                                        color="primary"
                                        onChange={handleChangeInput}
                                        name="disabled"
                                        value={data.disabled}
                                        checked={data.disabled}
                                        disabled={action.action === "DETAILACCOUNT"} />}
                                    label={
                                        <Typography
                                            sx={{ fontSize: 14, color: '#333' }}
                                        >
                                            Không/Có
                                        </Typography>} />

                            </Box> */}

                        </Box>

                        {/* Khoá */}
                        <Box className="row">
                            <Box className="col-6">

                                <InputLabel sx={{ fontSize: 14, color: '#333' }}>Khoá</InputLabel>

                                <FormControlLabel control={
                                    <Switch
                                        color="primary"
                                        onChange={handleChangeInput}
                                        name="isblock"
                                        value={data.isblock}
                                        checked={data.isblock}
                                        disabled={action.action === "DETAILACCOUNT"}
                                    />}
                                    label={
                                        <Typography
                                            sx={{ fontSize: 14, color: '#333' }}>
                                            Không/Có
                                        </Typography>} />


                            </Box>




                        </Box>

                    </div>
                </div>





            </DialogContent>
            <DialogActions>
                {action.action !== "" && (
                    <Button variant="contained" onClick={handleSubmit}>
                        Lưu
                    </Button>
                )}
                <Button onClick={action.onClose}>Hủy</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog >
    );
};

export default EmployeeAccountAction;
