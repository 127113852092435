import { CookieService } from "ngx-cookie";
import { AppSession } from "../app-session/app-session";
let dataUploads: any[] = [];

// const BASE_URL_API = "http://192.168.1.254:8084";
//  const BASE_URL_API = "http://45.119.81.198:8091";
// const BASE_URL_API = "http://192.168.1.254:8089";
// const BASE_URL_API = "https://76d7-14-241-224-96.ngrok-free.app";
 const BASE_URL_API = "https://api.mdhome.vn";
// const BASE_URL_API = "http://192.168.1.254:9000";
// const BASE_URL_API = "https://localhost:44340";  

export default BASE_URL_API;    
