import React, { useEffect, useState } from 'react'
import DHSDateTimeComponentV2 from '../../../../../components/DHS/DHS-DateTime/DHSDateTimeComponentV2';
import moment from 'moment';
import DHSSelectBox from '../../../../../components/DHS/DHS_SelectBox';

interface IProps {
    cycle_code: number;
    // case default but you need choose case order. Why? program is show error
    value?: Date;
    setValue?: (value: Date) => void;
    //case 1 : setData
    onSubmit?: (e: IDateCheckListModel) => void;
    //case 2: set start - end time
    setToDate?: (e: any) => void;
    setFromDate?: (e: any) => void;

    case: 'SET_DATA' | 'SET_START_END_TIME'; // option change date

    disable?: boolean;
}

export interface IDateCheckListModel {
    startTime: Date,
    endTime: Date,
}

const cycle = [
    { code: 1, name: 'Theo ngày', code_2: 'date' },
    { code: 2, name: 'Theo tuần', code_2: 'week' },
    { code: 3, name: 'Theo tháng', code_2: 'month' },
    { code: 4, name: 'Theo quý', code_2: 'month' },
    { code: 5, name: 'Theo Năm', code_2: 'year' }
]

const QUYS = [
    {
        code: 41,
        name: 'Quý 1'
    },
    {
        code: 44,
        name: 'Quý 2'
    },
    {
        code: 47,
        name: 'Quý 3'
    },
    {
        code: 50,
        name: 'Quý 4'
    },
]

// GET TOTAL WEEKS IN A YEAR
function GetISOWeeks(y: number) {

    const MIN_YEAR = 1990;
    const MAX_YEAR = 2100;
    const MONTH_INDEX_0 = 0;
    const MONTH_INDEX_1 = 1;
    const DATE_INDEX_0 = 29
    const DATE_INDEX_1 = 1;
    const DAY_INDEX_3 = 3;
    const DAY_INDEX_4 = 4;
    const WEEK_INEX_LEAD = 53;
    const WEEK_INEX_UNLEAD = 52;

    if (y >= MIN_YEAR && y <= MAX_YEAR) {
        var d: Date,
            isLeap: boolean;

        d = new Date(y, MONTH_INDEX_0, DATE_INDEX_1);
        isLeap = new Date(y, MONTH_INDEX_1, DATE_INDEX_0).getMonth() === MONTH_INDEX_1;

        // check for a Jan 1 that's a Thursday or a leap year that has a 
        // Wednesday jan 1. Otherwise it's 52
        return d.getDay() === DAY_INDEX_4 || isLeap && d.getDay() === DAY_INDEX_3 ? WEEK_INEX_LEAD : WEEK_INEX_UNLEAD
    } else {
        return 0;
    }
}

const ConvertDateStart = (value: Date, type: 'month' | 'year' | 'week' | 'date' | string) => {

    if (type === 'week') {
        return moment(value).startOf('week').set('millisecond', 0).toDate();
    } else if (type === 'month') {
        return moment(value).startOf('month').set('millisecond', 0).toDate();
    } else if (type === 'year') {
        return moment(value).startOf('year').set('millisecond', 0).toDate();
    } else {
        return moment(value).startOf('day').set('millisecond', 0).toDate();
    }

}
const ConvertDateEnd = (value: Date, type: 'month' | 'year' | 'week' | 'date' | string) => {
    if (type === 'week') {
        return moment(value).endOf('week').set('millisecond', 0).toDate();
    } else if (type === 'month') {
        return moment(value).endOf('month').set('millisecond', 0).toDate();
    } else if (type === 'year') {
        return moment(value).endOf('year').set('millisecond', 0).toDate();
    } else {
        return moment(value).endOf('day').set('millisecond', 0).toDate();
    }
}

const DEFAULT_NUMBER_QUYS = 40;
const TaskCheckListNgayCT2 = (props: IProps) => {

    const { cycle_code } = props;

    const [fromDate, setFromDate] = useState<Date>()
    const [toDate, setToDate] = useState<Date>()
    const [date, setDate] = useState<Date>(new Date())
    const [quy, setQuy] = useState<number>(DEFAULT_NUMBER_QUYS)
    const [reload, setReload] = useState<boolean>(false)

    const onChangeDate = (date: Date, type: string) => {
        setFromDate(ConvertDateStart(date, type));
        setToDate(ConvertDateEnd(date, type));
    }

    //Thang
    const handleChangeDate = (e: any) => {
        if (props.disable) return;
        const { name, value } = e;
        setDate(value);
        if (cycle_code === 4) setReload(pre => !pre);
    }

    const handleChangeInput = (e: any) => {
        if (props.disable) return;
        const { name, value } = e.target;
        setQuy(value);
    }

    useEffect(() => {
        const type = cycle.find(item => item.code === cycle_code);
        onChangeDate(date, type ? type.code_2! : 'date');

        (async () => {
            if (props.setValue) {
                await props.setValue(date)
            }
        })();

    }, [date])

    useEffect(() => {
        (async () => {
            if (props.value) {
                await setDate(props.value);
            }
            else {
                await setDate(new Date());
            }
            const type = cycle.find(item => item.code === cycle_code);
            onChangeDate(date, type ? type.code_2! : 'date');
            if (cycle_code === 4) {

                switch (moment(date).quarter()) {
                    case 1:
                        setQuy(41);
                        break;
                    case 2:
                        setQuy(44);
                        break;
                    case 3:
                        setQuy(47);
                        break;
                    case 4:
                        setQuy(50);
                        break
                }

                setReload(pre => !pre);
            }
        })();
    }, [cycle_code])

    useEffect(() => {
        if (date && quy) {
            var newFromDate = new Date();
            var newToDate = new Date();

            const year = date.getFullYear();
            const _number = 41;
            if (quy === 41) {
                newFromDate = new Date(year, quy - _number, 1, 0, 0, 0);
                newToDate = new Date(year, (quy - _number) + 3, -1, 0, 0);
            } else if (quy === 44) {
                newFromDate = new Date(year, quy - _number, 1, 0, 0, 0);
                newToDate = new Date(year, (quy - _number) + 3, -1, 0, 0);
            } else if (quy === 47) {
                newFromDate = new Date(year, quy - _number, 1, 0, 0, 0);
                newToDate = new Date(year, (quy - _number) + 3, -1, 0, 0);
            } else if (quy === 50) {
                newFromDate = new Date(year, quy - _number, 1, 0, 0, 0);
                newToDate = new Date(year, (quy - _number) + 3, -1, 0, 0);
            }
            setFromDate(ConvertDateStart(newFromDate, 'month'));
            setToDate(ConvertDateEnd(newToDate, 'month'));
        }
    }, [quy, reload])

    useEffect(() => {
        if (props.case === 'SET_START_END_TIME') {
            if (props.setFromDate) {
                props.setFromDate(fromDate);
            }
            if (props.setToDate) {
                props.setToDate(fromDate);
            }
        } else if (props.case === 'SET_DATA') {
            if (props.onSubmit) {
                props.onSubmit({
                    startTime: fromDate,
                    endTime: toDate
                } as IDateCheckListModel)
            }
        } else {
            console.log('PLEASE TEST CASE SELECT!')
        }
    }, [fromDate, toDate])

    return (
        <>
            {
                cycle_code > 0 && cycle_code < 3 ?
                    (
                        <>
                            <DHSDateTimeComponentV2
                                name="week"
                                type="date"
                                // format={'dd-MM-yyyy'}
                                value={date}
                                onChange={cycle_code === 1 ? handleChangeDate : handleChangeDate}
                                disabled={props.disable ? props.disable : false}
                                applyValueMode='instantly'
                                hint={"Chọn thời gian"}
                            />
                        </>
                    ) :
                    cycle_code === 3 ?
                        (
                            <>
                                <DHSDateTimeComponentV2
                                    name="cdate"
                                    type="date"
                                    format={'dd-MM-yyyy'}
                                    value={date}
                                    onChange={handleChangeDate}
                                    disabled={props.disable ? props.disable : false}
                                    hint='Chọn thời gian'
                                />
                            </>
                        ) :
                        cycle_code === 4 ?
                            (
                                <>
                                    <DHSDateTimeComponentV2
                                        name="cdate"
                                        type="date"
                                        format={'yyyy'}
                                        value={date}
                                        onChange={handleChangeDate}
                                        disabled={props.disable ? props.disable : false}
                                        hint='Chọn thời gian'
                                    />
                                    <div className='mt-1'>
                                        <DHSSelectBox
                                            dataSource={QUYS}
                                            name=""
                                            onValueChanged={handleChangeInput}
                                            displayExpr="name"
                                            searchExpr="name"
                                            valueExpr="code"
                                            value={quy}
                                            disabled={props.disable ? props.disable : false}
                                            hint='Chọn quý'
                                        />
                                    </div>
                                </>
                            ) :
                            cycle_code === 5 ?
                                (
                                    <>
                                        <DHSDateTimeComponentV2
                                            name="cdate"
                                            type="date"
                                            format={'yyyy'}
                                            value={date}
                                            onChange={handleChangeDate}
                                            disabled={props.disable ? props.disable : false}
                                            hint='Chọn thời gian'
                                        />
                                    </>
                                ) :
                                (
                                    <></>
                                )
            }
        </>
    )
}

export default TaskCheckListNgayCT2