import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Box,
    Avatar,
    // Chip,
    Tooltip,
    Tabs,
    Tab,
    Paper,
    Badge,
} from "@mui/material";
// import CurrencyTextField from "react-currency-input-field";
// import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    HomeClient,
    QLCCClient,
    RoleRightWebModel,
    QLCCFeedbackModel,
    QLCCFeedbackDetailModel,
    QLCCTaskModel,
} from "../../../shared/service-proxies/api-shared";
// import CancelIcon from "@mui/icons-material/Cancel";
// import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import CTXDateTime from "../../../../components/CTX/DateTimePicker";
import "../ApartmentBuilding.css";
// import RichTextEditor from "../../../../components/DHS/DHS_RichTextEditor";
// import notification from "../../../../common/notification/notification";
import TextArea from "antd/lib/input/TextArea";
import { deepOrange } from "@mui/material/colors";
import "./feedback-action.css";
import { Notification } from "../../../../components/layouts";
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import moment from "moment";

import TaskListAction from "../work/task-list-action"
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import TaskMiniSize from "./task-minisize";
import { MESSAGE_TYPE, STATUS_MESSAGE } from "../../../../common/Interfaces/StatusMessage";

import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import feedbackChatBox from "./chat-forum";
import FeedbackChatBox from "./chat-forum";
import MailIcon from '@mui/icons-material/Mail';
import ConfirmWithNote from "./common/confirm";

interface IProps {
    open: boolean;
    itemSelected?: any;
    action?: any;
    feedBackItem?: any;
    onClose: () => void;
    onEditSuccess: (is_success: boolean) => void;
}

interface IButtonItem {
    id: string;
    title: string;
    code?: string | undefined;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const status_message = ['success', 'error']
var appSession: AppSession;
var htmlString: string;



const btnListItem: IButtonItem[] = [
    {
        id: "is_read",
        title: "Đã đọc",
        code: undefined
    }, {
        id: "is_lock",
        title: "Khóa phản ánh",
        code: undefined
    }
]

const TASK_STATUS_SENT_TASK = 5;

const FeedBackAction = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const [_id, setId] = React.useState<string>();
    const qLCClient = new QLCCClient(appSession, BASE_URL_API);
    const [feedBack, setFeedBack] = React.useState<QLCCFeedbackModel>({} as QLCCFeedbackModel);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    /* để tạm test */
    const userName_login_test = SessionLogin.userName;
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);

    const [attach_files, setAttachFiles] = React.useState<string[]>([]);
    const [feedbackDetails, setFeedbackDetails] = React.useState<QLCCFeedbackDetailModel[]>([] as QLCCFeedbackDetailModel[]);
    const [task, setTask] = React.useState<QLCCTaskModel[]>([]);
    // GIAO VIEC

    const [openTask, setOpenTask] = React.useState<boolean>(false);
    const [indexTabs, setIndexTabs] = React.useState(0);
    const [isFull, setFull] = useState<boolean>(false);
    const [isViewMessage, setViewMessage] = useState<boolean>(false);
    const [isConfirm, setConfirm] = useState<boolean>(false);
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const onload = () => {
        qLCClient
            .qlccFeedbackGet({
                ... new QLCCFeedbackModel(),
                username_login: userName_login_test,
                id: props.feedBackItem?.id ?? props?.itemSelected,
                action_type: 'CONTACT',
                // apartment_building_id: apartment_building_id  ,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCFeedbackModel)
            .then((res) => {
                if (res[0] && res[0].qLCCFeedbackDetails) {
                    setFeedbackDetails(res[0].qLCCFeedbackDetails);
                }
            })
    }

    React.useEffect(() => {
        if (props.action) {
            if (props.action === 'ADD') {
                setFeedBack({
                    ... new QLCCFeedbackModel(),
                    apartment_building_id: apartment_building_id  ,
                    ma_cty: SessionLogin.ma_cty,
                } as QLCCFeedbackModel);
                setId(undefined)
            } else {
                setId(props.itemSelected);
                setFeedBack(props.feedBackItem);
                if (props.feedBackItem && props.feedBackItem.attach_files) {
                    setAttachFiles(props.feedBackItem.attach_files.split(';'));
                }
                onload();
            }
            // if (props.action === ACTIONS.SENTTASK_2) {
            //     setOpenTask(pre => !pre)
            // }
        }
    }, [props.action, props.itemSelected]);

    const onloadTasks = async () => {

        const TYPE_CODE_TASK: string = "1";

        if (props.itemSelected) {
            const resResult = await qLCClient.qLCCTaskGet({
                ...new QLCCTaskModel(),
                feedback_id: props.itemSelected,
                type_code: TYPE_CODE_TASK,
                action_type: 'FEEDBACK_GET',
                apartment_building_id: apartment_building_id  ,
                ma_cty: SessionLogin.ma_cty,
            } as QLCCTaskModel);

            if (resResult) {
                setTask([...resResult]);
            }
        }
    }

    const [loadTask, setReloadTask] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (props.itemSelected) {
            onloadTasks();
            if (feedBack.status_code === 1) {
                setFeedBack({ ...feedBack, status_code: 2 } as QLCCTaskModel);
            }
        }
    }, [loadTask, props.itemSelected])

    const [idTask, setTaskId] = React.useState<string>("");

    const handleView = (id: string) => {
        setOpenTask(pre => !pre);
        setTaskId(id);
    }

    const handleClock = () => {
        setConfirm(pre => !pre);
    }

    const onHandleClock = async (isConfirm: boolean, notes: string) => {
        if (isConfirm) {
            try {
                const result = await qLCClient.qlccFeedbackUpdByAction({
                    ... new QLCCFeedbackModel(),
                    ...feedBack,
                    notes: notes,
                    ma_cty: SessionLogin.ma_cty,
                    action_type: 'DONE-TASK'
                } as QLCCFeedbackModel);

                if (result) {
                    Notification(MESSAGE_TYPE[result.status!], result.message)
                    if (result.status === 0) {
                        props.onClose();
                        props.onEditSuccess(true);
                    }
                }
            } catch (e) {
                alert(STATUS_MESSAGE['SERVER_ERROR']);
            }
        }
        setConfirm(pre => !pre);
    }

    const onChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
        setIndexTabs(newValue);
    };

    // Xem Tin nhắn
    const onChangeViewMessage = () => {
        setViewMessage(pre => !pre);
        setFeedBack({
            ...feedBack,
            counter_message: 0
        } as QLCCFeedbackModel);
    }

    const checkViewMessage: number = isViewMessage ? 8 : 12;
    const customerViewMessage = () => {
        if (!isViewMessage) {
            return {
                display: 'none'
            }
        }
    }


    const styleBoxTab = {
        minHeight: '68vh',
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            TransitionComponent={Transition}
            maxWidth="lg"
            fullWidth
            fullScreen={isFull}
        >
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                    >
                        {menu?.bar}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setFull(pre => !pre)}
                        aria-label="full"
                    >
                        {
                            isFull ? <ZoomInMapIcon /> : <ZoomOutMapIcon />
                        }
                    </IconButton>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent sx={{
                minHeight: '70vh',
                backgroundColor: '#e2e8f0'
            }}>
                {
                    isViewMessage === false
                    && <>
                        <Button
                            sx={{
                                position: 'absolute',
                                right: 0,
                                padding: '5px 10px',
                                // backgroundColor: 'blue',
                                // color: 'white'
                                zIndex: 1100
                            }}
                            onClick={onChangeViewMessage}
                            variant="contained"
                        >
                            Tin nhắn
                            <Badge badgeContent={feedBack?.counter_message} color="error" className="ml-2">
                                <MailIcon />
                            </Badge>
                        </Button>
                    </>
                }
                <Grid container spacing={2}>
                    <Grid item xs={12} md={checkViewMessage}>
                        <Paper elevation={2} className="p-3">
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={indexTabs} onChange={onChangeTabs} aria-label="basic tabs example" scrollButtons={true}>
                                    <Tab label="Thông tin chi tiết" {...a11yProps(0)} />
                                    <Tab label="Nhân viên xử lý" {...a11yProps(1)} />
                                    <Tab label="Hình ảnh" {...a11yProps(2)} />
                                    <Tab label="Khác" {...a11yProps(4)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={indexTabs} index={0}>
                                <Grid container spacing={2} sx={{
                                    minHeight: '70vh',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                    <Grid item xs={12} md={6}>
                                        <Typography>
                                            Tiêu đề: <strong>{feedBack?.title}</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography>
                                            Loại phản ánh: <strong>{feedBack?.type_name}</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography>
                                            Căn hộ: <strong>{feedBack?.apartment_name}</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Người gửi: <strong>{feedBack?.resident_name}</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography>
                                            Số điện thoại: <strong>{feedBack?.phone}</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography>
                                            Email: <strong>{feedBack?.email}</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>Nội dung</Typography>
                                        <TextArea
                                            value={feedBack?.content}
                                            placeholder="Nội dung phản ánh"
                                            autoSize={{ minRows: 3, maxRows: 30 }}
                                        />
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                            <CustomTabPanel value={indexTabs} index={1}>
                                <Box sx={styleBoxTab}>
                                    <Typography className="mb-3">
                                        Danh sách nhân viên được giao việc
                                    </Typography>
                                    {
                                        feedBack?.status_code !== 1 && task ? task.map(taskItem => (
                                            <div key={taskItem.id || `${moment().toDate()}`}>
                                                <TaskMiniSize
                                                    task={taskItem}
                                                    handleView={handleView}
                                                />
                                            </div>
                                        ))
                                            :
                                            <div className=" h-100 d-flex align-items-center justify-content-center">
                                                <p className='text-center'>Không có</p>
                                            </div>
                                    }
                                </Box>
                            </CustomTabPanel>
                            <CustomTabPanel value={indexTabs} index={2}>
                                <Box sx={styleBoxTab}>
                                    <Typography className="mb-3">
                                        Danh sách hình ảnh
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {
                                            feedBack?.attach_files
                                                && feedBack?.attach_files?.length > 0
                                                ? attach_files.map((item, i) => (
                                                    <Grid item xs={12} sm={4} md={3} sx={{ marginRight: 10 }}>
                                                        <img
                                                            src={
                                                                `${BASE_URL_API}${item}`
                                                            }
                                                            alt="Hình chụp phản ánh"
                                                            className="image-shadow"
                                                            style={{
                                                                width: "324px",
                                                                maxWidth: "100%",
                                                                minHeight: "240px",
                                                                maxHeight: "620px",
                                                            }}
                                                        />
                                                        <p className="text-center">Hình ảnh {i + 1}</p>
                                                    </Grid>
                                                ))
                                                : <>
                                                    <Box className="text-center w-100">
                                                        No Image Upload
                                                    </Box>
                                                </>
                                        }
                                    </Grid>
                                </Box>
                            </CustomTabPanel>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ ...customerViewMessage() }}>
                        <FeedbackChatBox
                            data={feedBack}
                            data2={feedbackDetails}
                            onload={() => onload()}
                            setViewMessage={() => setViewMessage(pre => !pre)}
                        />
                    </Grid>
                </Grid>
                {
                    openTask && <TaskListAction
                        open={openTask}
                        onClose={function (): void {
                            setOpenTask(pre => !pre)
                            setTaskId("");
                        }}
                        onEditSuccess={(is_success): void => {
                            // props.onClose();
                            props.onEditSuccess(is_success);
                            setReloadTask(pre => !pre)
                        }}
                        action={idTask ? ACTIONS.VIEW : ACTIONS.ADD}
                        titleFeedBack={createTitle(feedBack?.title)}
                        contentFeedBack={CreateContent(feedBack?.title, feedBack?.content, SessionLogin.fullName)}
                        feedback_id={feedBack?.id}
                        apartmentBuildingId={feedBack?.apartment_building_id}
                        data={idTask ? task.find(item => item.id === idTask)! : undefined}
                        status={TASK_STATUS_SENT_TASK}
                    />

                }
                <ConfirmWithNote
                    onClosed={() => setConfirm(pre => !pre)}
                    open={isConfirm}
                    isConfirm={(isConfirm, notes) => {
                        (async () => {
                            await onHandleClock(isConfirm, notes);
                        })();
                    }}
                />


            </DialogContent>
            <DialogActions>
                {
                    !feedBack?.is_lock && (
                        <>
                            {/* <Button variant="contained" color="primary" onClick={() => setOpenTask(pre => !pre)}>Giao việc</Button> */}
                            <Tooltip title="Thông báo phản ánh đã được giải quyết xong và khóa phản ánh này" arrow>
                                <Button variant="contained" color="warning" onClick={handleClock}>Khóa</Button>
                            </Tooltip>
                        </>
                    )
                }

                <Button variant="outlined" onClick={props.onClose}>Đóng</Button>
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog >
    );
};

export default FeedBackAction;

const createTitle = (title: string | undefined) => {
    return `Phản ánh ${title}`;
}

const CreateContent = (title: string | undefined, content: string | undefined, Sentder: string | undefined) => {
    return `<div style="font-size: 14px;">` +
        `<p style="text-align: left"><strong>Tiêu đề: ${title}</strong></p>` +
        `<p style="text-align: left">` +
        `<b>Nội dung: </b>${content}</p>` +
        `<p><b>Ghi chú: </b></p>` +
        `<p style="text-align: right">` +
        `Người giao <b>${Sentder}</b>` +
        `</p>` +
        `</div>`
}