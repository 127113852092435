import { Box, Card, Grid, Typography } from "@mui/material";
// import { Bar, Line, Radar } from "react-chartjs-2";
import React, { useEffect, useState } from "react";

import { AppSession } from "../../../../app/shared/app-session/app-session";
import {
  FinanceDashBoardChartGetDTO,
  FinanceDashBoardChartGetParams,
  FinanceDashBoardGetDTO,
  FinanceDashBoardGetParams,
  QLCCDashBoardClient,
} from "../../../../app/shared/service-proxies/api-shared";
import BASE_URL_API from "../../../../app/shared/service-proxies/config";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import DHSBarChartDrillDown from "../../../DHSComponents/charts/DHSBarChartDrillDown/DHSBarChartDrillDown";
import { formatNumber } from "devextreme/localization";
import DHSButton from "../../../DHSComponents/actions-and-list/DHSButton/DHSButton";
import DHSDateFilterV2 from "../../../DHSUtilComponent/DHSDateFilterV2/DHSDateFilterV2";
import _, { set } from "lodash";
import GeneralInfoDashboard from "./components/GeneralInfoDashboard";
import GeneralInfoPriceDashboard from "./components/GeneralInfoPriceDashboard";
import DHSAccordion from "../../../DHSComponents/navigation/DHSAccordion/DHSAccordion";
import { Item } from "devextreme-react/accordion";
import BlockUI from "../../../Block-UI/Block-ui";
import DHSDateFilterV3 from "../../../DHSUtilComponent/DHSDateFilterV3/DHSDateFilterV3";

type FilterI = {
  time_from: Date;
  time_to: Date;
};
let appSession: AppSession;
const QLCCDashboard: React.FC<{}> = () => {
  // const location = useLocation();
  const qLCCClient = new QLCCDashBoardClient(appSession, BASE_URL_API);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const apartment_building_id = useSelector(
    (state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id
  );
  const ref = React.useRef<any>(null);
  const [isLoading, setLoading] = React.useState(false);
  let [shouldSearch, setShouldSearch] = React.useState(0);

  // const ref2 = React.useRef<any>(null);
  const [selectedItems, setSelectedItems] = React.useState<any[]>([]);
  const [finance, setFinance] = useState<FinanceDashBoardGetDTO>(
    {} as FinanceDashBoardGetDTO
  );
  const [financeChart, setFinanceChart] = useState<
    FinanceDashBoardChartGetDTO[]
  >([] as FinanceDashBoardChartGetDTO[]);
  const [filter, setFilter] = useState<FilterI>();
  const [filterChart, setFilterChart] = useState<FilterI>();

  useEffect(() => {
    search();
  }, [apartment_building_id, shouldSearch]);

  const customizeTooltip = (arg: any) => {
    return {
      text: formatNumber(arg.originalValue, "fixedPoint") + "₫",
    };
  };

  const search = async () => {
    setLoading(true);
    try {
      const [financeResponse, financeChartResponse] = await Promise.all([
        qLCCClient.financeDashBoardGet({
          ma_cty: SessionLogin.ma_cty,
          apartment_building_id: apartment_building_id,
          ngay_ct1: filter?.time_from,
          ngay_ct2: filter?.time_to,
        } as FinanceDashBoardGetParams),
        qLCCClient.financeDashBoardChartGet({
          ma_cty: SessionLogin.ma_cty,
          apartment_building_id: apartment_building_id,
          ngay_ct1: filterChart?.time_from,
          ngay_ct2: filterChart?.time_to,
        } as FinanceDashBoardChartGetParams),
      ]);
      setFinance(financeResponse);
      setFinanceChart(financeChartResponse);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handleChangeTimeData = (e: any) => {
    setFilter(
      (pre: any) =>
        ({
          ...pre,
          time_to: e?.time_to,
          time_from: e?.time_from,
        } as any)
    );
    const year = e?.time_from?.getFullYear();
    setFilterChart((pre) => {
      return {
        ...pre,
        time_from: new Date(year!, 0, 1),
        time_to: new Date(year!, 11, 31, 23, 59, 59),
      };
    });
    setShouldSearch((prep) => {
      if(prep === 10){
        return 0;
      }
      return prep + 1;
    });
  };

  return (
    <>
      <Box sx={{ padding: "0.55rem" }}>
        {/* <Box marginBottom={"0.5rem"} marginTop={"0.25rem"}>
          <DHSBreadcrumb location={location} />
        </Box> */}
        <Grid container spacing={1}>
          <Grid item xs={12} paddingTop={"0x !important"}>
            <DHSAccordion
              collapsible
              // onSelectionChanged={(e) => {
              //     setItemClose(e.removedItems)
              // }}
              selectedItems={selectedItems}
              defaultShowFirstItem
              noDataText="Loading ..."
              setSelectedItems={(value) => {
                setSelectedItems(value);
              }}
              className="item-accordion-no-padding"
            >
              <Item
                titleComponent={() => {
                  return selectedItems.length <= 0 ? (
                    <span style={{ fontWeight: 500, fontSize: 14 }}>
                      {"Điều kiện lọc"}
                    </span>
                  ) : (
                    <></>
                  );
                }}
              >
                <Card elevation={0} sx={{ marginBottom: "0.2rem" }}>
                  <DHSDateFilterV2
                    ref={ref}
                    timeData={{
                      time_from: filter?.time_from,
                      time_to: filter?.time_to,
                    }}
                    onChangeTimeData={(e) => {
                      handleChangeTimeData(e);
                    }}
                    type={"datetime"}
                    defaultCycle={3}
                    key={"1"}
                  />
                  {/* <DHSButton 
                    text="Tìm kiếm"
                    onClick={() => {
                      console.log(filter);
                      console.log(filterChart);
                      
                      search();
                    }}
                  /> */}
                </Card>
              </Item>
            </DHSAccordion>
          </Grid>
          <Grid item xs={12} sx={{ p: 1 }}>
            <Typography fontSize={17} fontWeight={700} color={"#D3D3D3"} pb={1}>Thông tin dự án</Typography>
            <GeneralInfoDashboard dataSource={finance} />
          </Grid>
          <Grid item xs={12} sx={{ p: 1 }}>
            <Typography fontSize={17} fontWeight={700} color={"#D3D3D3"} pb={1}>Thông tin tài chính</Typography>
            <GeneralInfoPriceDashboard dataSource={finance} />
          </Grid>
          <Grid item xs={12} pt={"0 !important"}>
            <Card sx={{ p: 1, mt: 2 }}>
              <DHSBarChartDrillDown
                dataSource={financeChart || []}
                // dataSource={arrChart}
                // palette={["#f99a5a", "#0ab4d7"]}
                argData={"ngay_ct_display"}
                valueData={[
                  {
                    valueField: "value_tt_nt",
                    name: "Thu tiền",
                    color: "#508D4E",
                  },
                  {
                    valueField: "value_ct_nt",
                    name: "Chi tiền",
                    color: "#f99a5a",
                  },
                  {
                    valueField: "value_dt_nt",
                    name: "Doanh thu",
                    color: "#0ab4d7",
                    type: "spline",
                  },
                ]}
                parentIDData={"thang_display"}
                name={""}
                title={``}
                legendEnable={true}
                legendHorizontalAlignment="center"
                legendVerticalAlignment="bottom"
                tooltipEnable
                customizeDatasource={(newData, dataSource, valueField) => {
                  if (valueField === "profit_margin") {
                    newData[valueField] = _.meanBy(dataSource, valueField);
                  } else {
                    newData[valueField] = _.sumBy(dataSource, valueField);
                  }
                }}
                customizeTooltip={customizeTooltip}
                height={"50vh"}
              ></DHSBarChartDrillDown>
            </Card>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Box>
      <BlockUI blocking={isLoading} title={""}></BlockUI>
    </>
  );
};

export default QLCCDashboard;
