import { AnyAction } from "redux";
import { CLEAR_SERVICE_BOOKING_MASTER, SAVE_CHANGE_SERVICE_BOOKING_MASTER } from "../../constants/ActionTypes";


export interface IQLCCServiceBooking {
    ma_cty: string;
    id?: string | undefined;
    title: string;
    id_customer: string;
    name_customer?: string | undefined;
    utility_id: string;
    utility_name?: string | undefined;
    start_time: Date;
    end_time: Date;
    amount: number;
    prices?: number;
    unit: string;
    status_id: string;
    status_name?: string | undefined;
    status_code?: string | undefined;
    apartment_building_id?: string | undefined;
    apartment_id?: string | undefined;
    apartment_name?: string | undefined;
    cuser?: string | undefined;
    luser?: string | undefined;
    cdate?: Date | undefined;
    ldate?: Date | undefined;
    active?: boolean | undefined;
}

const initialState: IQLCCServiceBooking = {
    ma_cty: "",
    title: "",
    id_customer: "",
    utility_id: "",
    start_time: new Date(),
    end_time: new Date(),
    amount: 0,
    unit: "",
    status_id: "",
    prices: 0
}

var ServiceBookingMasterReducer = (state = initialState, action: AnyAction) => {
    console.log(action.payload, "payload");

    switch (action.type) {
        case SAVE_CHANGE_SERVICE_BOOKING_MASTER:

            return {
                ...state,
                ...action.payload
            }
        case CLEAR_SERVICE_BOOKING_MASTER:
            return {
                
            }
        default: return state;
    }
}

export default ServiceBookingMasterReducer;