import React from "react";
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    InputLabel,
    Box,
    Slide,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    UserInfo,
    SysActionType,
    SiUserInfoClient,
    ParamsChangePasswordUserInfo,
} from "../../../shared/service-proxies/api-shared";


import BASE_URL_API from "../../../shared/service-proxies/config";
import { AppSession } from "../../../shared/app-session/app-session";
import { useSelector } from "react-redux";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import { TransitionProps } from "@mui/material/transitions";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import notification from "../../../../common/notification/notification";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import DHSDialogAction from "../../../../components/DHS/DHS_DialogAction/DialogAction";
import { ERROR_CODE } from "../../../../common/errorCode";
import { STATUS_MESSAGE, TYPE_MESSAGE } from "../../../../common/Interfaces/StatusMessage";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

interface IProps {
    open: boolean;
    itemSelected?: UserInfo;
    action?: any;
    onClose: () => void;
    onSubmit?: (data: any, action: any) => void;
    onEditSuccess: (is_success: boolean) => void;
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});





const status_message = ['success', 'error']

var appSession: AppSession;

const UserInfoChangePassword: React.FC<IProps> = (props: IProps) => {
    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}")
    const [_id, setId] = React.useState<string>();

    // 
    // const [roles, setRoles] = React.useState<RoleRightWebModel[]>();


    const [data, setData] = React.useState<ParamsChangePasswordUserInfo>({} as ParamsChangePasswordUserInfo);
    // const [data, setData] = React.useState<any>({});
    const [rePassword, setRePassword] = React.useState<string>("");
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [reload, setReload] = React.useState<boolean>(false);

    const siUserInfoClient = new SiUserInfoClient(appSession, BASE_URL_API);
    const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);


    // 


    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [fullScreen, setFullscreen] = React.useState<boolean>(false);


    React.useEffect(() => {
        setData(pre => ({
            ...new ParamsChangePasswordUserInfo(),
            username: props?.itemSelected?.username
        } as ParamsChangePasswordUserInfo))
        setRePassword("")
    }, [props.open])

    React.useEffect(() => {
        if (data?.new_password
            && data?.new_password.length !== rePassword.length
            && data?.new_password?.toLocaleLowerCase() !== rePassword.toLocaleLowerCase()) {

            setErrors(pre => ({
                ...pre,
                "INCORRECT_PASSWORD_CONFIRMATION": "Mật khẩu nhập lại không chính xác"
            }));
        }
        else if (data?.new_password
            && data?.new_password.length === rePassword.length
            && data?.new_password?.toLocaleLowerCase() === rePassword.toLocaleLowerCase()) {

            const { INCORRECT_PASSWORD_CONFIRMATION, ...tempErrors } = errors;
            setErrors(tempErrors);
        }
    }, [data?.new_password, rePassword]);



    //#endregion

    const handleSubmit = async () => {


        if (Object.keys(errors).length > 0) {
            Object.values(errors).forEach(error => {
                notification(status_message[1], error);

            });
            return;
        }
        try {
            setLoading(true)
            const response = await siUserInfoClient.changePasswordUserInfo({
                ...data
            } as ParamsChangePasswordUserInfo)
            notification(status_message[response.ret ?? 1], STATUS_MESSAGE[`${ACTIONS.CHANGE_PASSWORD}_${response.ret ?? 1}`]);

            setLoading(false)
            if (response.ret === 0) {
                props.onEditSuccess(true)
                props.onClose();
            }
        } catch (error) {

            notification(TYPE_MESSAGE.ERRORR, STATUS_MESSAGE[`SERVER_ERROR`]);
            setLoading(false)
        }

    };



    const handleChangeInput = (e: any) => {
        const { name, value, checked, type } = e.target;

        if (type === 'checkbox') {
            setData({ ...data, [name]: checked } as UserInfo);

        }
        else setData({ ...data, [name]: value } as UserInfo);
    };


    const handleClickAction = (action: SysActionType) => {
        switch (action.action_code) {
            case ACTIONS.SAVE:
                handleSubmit();
                break;

            case ACTIONS.CLOSE:
                props.onClose()
                break;
        }
    }




    return (
        <Dialog
            // id="sysMenu"
            open={props.open}
            onClose={props.onClose}
            // PaperProps={{
            //     sx: !fullScreen ? {
            //         minHeight: "90vh",
            //         height: "90vh"
            //     } : undefined
            // }}
            sx={{
                // display: "flex",
                // justifyContent: "flex-end",
            }}
            TransitionComponent={Transition}
            maxWidth='sm'
            fullWidth
            fullScreen={fullScreen}


            className="Common-control"
        >

            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "#FFF",
                    color: "#1890ff",
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ flex: 1, fontWeight: 700 }}
                        variant="h6"
                        component="div"
                        className="app-bar-dialog"
                    >
                        {`${menu?.bar} ${data?.username && data?.username} [Đổi mật khẩu]`}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1.25 }}>
                        <IconButton
                            edge="end"

                            onClick={() => {
                                // setFullscreen(pre => !pre)
                                notification(TYPE_MESSAGE.WARNING, STATUS_MESSAGE["UNAVAILABLE_FEATURE"])
                            }}
                            aria-label="full screen"
                        >
                            {fullScreen ? <FullscreenExitIcon sx={{ color: '#1f2937' }} /> : <FullscreenIcon sx={{ color: '#1f2937' }} />}
                        </IconButton>
                        <IconButton
                            edge="end"
                            onClick={() => {
                                props.onClose();
                            }}
                            aria-label="close"
                        >
                            <CloseIcon
                                sx={{ color: '#1f2937' }}
                            />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent >


                <div className="row gutter-1">

                    <div className="col-12">

                        {/*  */}

                        {/* <div className="card">

                            <div className="card-body">
                                <div>
                                    Đổi mật khẩu ()
                                </div> */}
                        {/* Tài khoản (username) */}
                        {/* <Box style={{ width: "100%", margin: "5px 0px" }}>
                                    <InputLabel sx={{ fontSize: 14, color: '#333' }}>Tài khoản (username)</InputLabel>
                                    <TextField
                                        className="cc-input"
                                        sx={{
                                            width: "100%",
                                            marginRight: "5px",
                                        }}
                                        placeholder="Tài khoản (username)..."
                                        name="username"
                                        onChange={(e: any) => {
                                            handleChangeInput({
                                                target: {
                                                    name: e.target.name,
                                                    value: e.target.value.toLocaleUpperCase()
                                                }
                                            })
                                        }}
                                        value={data.username}
                                        disabled={props.action === (ACTIONS.VIEW || "EDITACCOUNT")}
                                    />

                                </Box> */}
                        {/* Mật khẩu (password) */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Mật khẩu mới (New password)</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                // placeholder="Mật khẩu mới (New password)"
                                name="new_password"
                                onChange={(e: any) => {
                                    handleChangeInput({
                                        target: {
                                            name: e.target.name,
                                            value: e.target.value.toLocaleUpperCase()
                                        }
                                    })
                                }}
                                type="password"
                                value={data.new_password}
                                inputProps={{
                                    autoComplete: "new-password"
                                }}
                                disabled={props.action === (ACTIONS.VIEW || "EDITACCOUNT")}
                            />

                        </Box>

                        {/* Nhập lại Mật khẩu (password) */}
                        <Box style={{ width: "100%", margin: "5px 0px" }}>
                            <InputLabel sx={{ fontSize: 14, color: '#333' }}>Nhập lại mật khẩu (re-password)</InputLabel>
                            <TextField
                                className="cc-input"
                                sx={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                                // placeholder="Nhập lại mật khẩu (password)"
                                name="re-password"
                                onChange={(e: any) => {
                                    setRePassword(e.target.value)


                                }}
                                type="password"
                                value={rePassword}
                                inputProps={{
                                    autoComplete: "new-password"
                                }}
                                helperText={errors[ERROR_CODE["INCORRECT_PASSWORD_CONFIRMATION"]]}
                                disabled={props.action === (ACTIONS.VIEW || "EDITACCOUNT")}
                            />

                        </Box>

                    </div>
                </div>
                {/* </div>

                </div> */}




            </DialogContent>
            <DialogActions>
                <DHSDialogAction

                    action={props.action}
                    menu={menu}
                    onClickAction={handleClickAction}
                    isDialogAction
                />
            </DialogActions>
            <BlockUI blocking={isLoading} title={""}></BlockUI>
        </Dialog >
    );
};

export default UserInfoChangePassword;
