import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { components } from "../../../../common/ComponentsConfig/components";
import { IReducer } from "../../../../common/Interfaces/Interfaces";
import notification from "../../../../common/notification/notification";
import CTXFormDialog from "../../../../components/CTX/Dialog";
import CTXToolbar from "../../../../components/CTX/Toolbar";
import DHSToolbar from "../../../../components/DHS/DHS-Toolbar/DHSToolbar";
import { Notification } from "../../../../components/layouts";
import DHSGridView from "../../../../components/layouts/Admin/Grid/Grid-view/Grid-View";
import { getRole, getRoles } from "../../../../redux/actions/RoleActions";
import { AppSession } from "../../../shared/app-session/app-session";
import {
  ChoThueXeClient,
  CtxDanhMucXeModel,
  CtxGiaoDichTienModel,
  CtxPhieuThueXeModel,
  CtxThongTinXeModel,
  HomeClient,
  RoleRightWebModel,
} from "../../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../../shared/service-proxies/config";

let appSession: AppSession;
const convert = ["success", "error", "warning"];
const menuRoleName = "KS";
const DanhSachNghiepVu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const menuid = useSelector((state: IReducer) => state.RoleReducer?.menuid);
  const { path, url } = useRouteMatch();
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const ctxClient = new ChoThueXeClient(appSession, BASE_URL_API);
  const [roles, setRoles] = React.useState<RoleRightWebModel[]>();
  const [itemSelected, selectItem] = React.useState<string>();
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
  const [isShowSearch, setShowSearch] = React.useState<boolean>(false);
  const [isShowGroup, setShowGroup] = React.useState<boolean>(false);
  const [isShowSort, setShowSort] = React.useState<boolean>(false);
  const [dataTable, setDataTable] = React.useState<any[]>([]);
  const [reload, setReload] = React.useState(false);
  const customMenu = [
    {
      menuName: isShowSearch ? "Ẩn thanh Search" : "Hiện thanh Search",
      onClick: () => {
        setShowSearch(!isShowSearch);
      },
    },
    {
      menuName: isShowGroup ? "Ẩn Group" : "Hiện Group",
      onClick: () => {
        setShowGroup(!isShowGroup);
      },
    },
    {
      menuName: isShowSort ? "Ẩn Sort" : "Hiện Sort",
      onClick: () => {
        setShowSort(!isShowSort);
      },
    },
  ];
  React.useEffect(() => {
    homeClient
      ?.roleRightWebGetAll({
        ...new RoleRightWebModel(),
        rolename: menuRoleName,
        menuid: menu?.menuid,
        username: SessionLogin.userName,
      } as RoleRightWebModel)
      .then((res) => {
        dispatch(getRoles(res));
        setRoles(res);
      });
    switch (menu?.component?.toUpperCase()) {
      case "CTXDANHSACHXE":
        ctxClient
          .ctxThongTinXeGet(new CtxThongTinXeModel())
          .then((res) => setDataTable(res));
        break;
      case "CTXDANHSACHTHUE":
        ctxClient?.ctxPhieuThueXeGet(new CtxPhieuThueXeModel()).then((res) => {
          let tempArr: any[] = [];
          res.forEach((item) => {
            tempArr.push({
              id: item.id,
              cccd: item.cccd,
              cccd_file1: item.cccd_file1,
              cccd_file2: item.cccd_file2,
              cccd_ngay_cap: item.cccd_ngay_cap?.toString(),
              cccd_noi_cap: item.cccd_noi_cap,
              dien_giai: item.dien_giai,
              dien_thoai: item.dien_thoai,
              ghi_chu_nhan_hang: item.ghi_chu_nhan_hang,
              ghi_chu_noi_bo: item.ghi_chu_noi_bo,
              loai_cho_thue_id: item.loai_cho_thue_id,
              loai_cho_thue_name: item.loai_cho_thue_name,
              ngay_du_tra:
                item.ngay_du_tra &&
                `${item.ngay_du_tra.getDate()}/${
                  item.ngay_du_tra.getMonth() + 1
                }/${item.ngay_du_tra.getFullYear()} ${item.ngay_du_tra.getHours()}:${item.ngay_du_tra.getMinutes()}`,
              ngay_thue:
                item.ngay_thue &&
                `${item.ngay_thue?.getDate()}/${
                  item.ngay_thue?.getMonth() + 1
                }/${item.ngay_thue?.getFullYear()} ${item.ngay_thue?.getHours()}:${item.ngay_thue?.getMinutes()}`,
              ngay_tra:
                item.ngay_tra &&
                `${item.ngay_tra?.getDate()}/${
                  item.ngay_tra?.getMonth() + 1
                }/${item.ngay_tra?.getFullYear()} ${item.ngay_tra?.getHours()}:${item.ngay_tra?.getMinutes()}`,
              so_phieu: item.so_phieu,
              ten_nguoi_thue: item.ten_nguoi_thue,
              // tien_den_bu: item.tien_den_bu,
              tien_phai_thu: item.tien_phai_thu,
              tien_phai_tra: item.tien_phai_tra,
              // tien_phu_thu: item.tien_phu_thu,
              tien_tam_ung: item.tien_tam_ung,
              // tien_thue: item.tien_thue,
              tong_tien: item.tong_tien,
              trang_thai_thue_id: item.trang_thai_thue_id,
              trang_thai_thue_name: item.trang_thai_thue_name,
            });
          });
          setDataTable(tempArr);
        });
        break;
      case "CTXDANHSACHGIAODICH":
        ctxClient.ctxGiaoDichTienGet(new CtxGiaoDichTienModel()).then((res) => {
          let tempArr: any[] = [];
          res.forEach((item) => {
            tempArr.push({
              ngay_gd: `${item.ngay_gd?.getDay()}/${item.ngay_gd?.getMonth()}/${item.ngay_gd?.getFullYear()}`,
              ly_do: item.ly_do,
              tien_thu: item.tien_thu,
              tien_chi: item.tien_chi,
              tong_tien: item.tong_tien,
            });
          });
          setDataTable(tempArr);
        });
        break;
    }
  }, [menu?.component]);

  const handleClickAction = (action: RoleRightWebModel) => {
    if (action?.class_name?.toUpperCase() === "DELETE") {
      if (window.confirm("Bạn có chắc muốn xóa")) {
        switch (menu?.component?.toUpperCase()) {
          case "CTXDANHSACHXE":
            ctxClient
              .ctxThongTinXeDel({
                ...new CtxThongTinXeModel(),
                id: itemSelected,
              } as CtxThongTinXeModel)
              .then((response) => {
                Notification(
                  convert[Number(response[0].ret)],
                  response[0].message
                );
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              });
            break;
          case "CTXDANHSACHTHUE":
            ctxClient
              .ctxPhieuThueXeDel({
                ...new CtxPhieuThueXeModel(),
                id: itemSelected,
              } as CtxPhieuThueXeModel)
              .then((response) => {
                Notification(
                  convert[Number(response[0].ret)],
                  response[0].message
                );
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              });
            break;
          default:
            ctxClient
              .ctxDanhMucXeActions({
                ...new CtxDanhMucXeModel(),
                id: itemSelected,
                tbname: menu?.component,
                action: "DELETE",
              } as CtxDanhMucXeModel)
              .then((response) => {
                Notification(
                  convert[Number(response[0].ret)],
                  response[0].message
                );
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              });
            break;
        }

        setReload(!reload);
        // window.location.reload();
      }
    } else if (action?.class_name?.toUpperCase() !== "BACK") {
    } else history.goBack();
  };

  return (
    <div
      className="container-fluid p-2"
      style={{ maxHeight: "94vh", overflowY: "auto" }}
    >
      <Switch>
        <Route path={path} exact>
          <div
            className="d-flex title-color"
            style={{
              height: 30,
              color: "rgb(0 78 255 / 88%)",
              marginTop: 15,
              marginBottom: 15,
              fontSize: 22,
              fontWeight: 550,
            }}
          >
            <i
              className="bi bi-arrow-left-short"
              style={{ cursor: "pointer" }}
              onClick={() => history.goBack()}
            ></i>
            &nbsp;{menu?.bar}
          </div>
          <CTXToolbar
            id={itemSelected}
            list={
              roles
                ? roles.filter(
                    (item) => item.on_form?.toLocaleUpperCase() === "VIEW"
                  )
                : []
            }
            customMenu={customMenu}
            onClickAction={handleClickAction}
          />
          <DHSGridView
            onSelectRow={(row) => {
              selectItem(row);
            }}
            // onListSelectRow={handleListOnselectRow}
            itemClicked={function (): void {
              throw new Error("Function not implemented.");
            }}
            table_name={menu?.component ? menu.component : ""}
            show_form_list={true}
            showSelectBox={false}
            // showFilterPanel={true}
            data={dataTable}
            showSortPanel={isShowSort}
            showSearchPanel={isShowSearch}
            showGroupPanel={isShowGroup}
          />
        </Route>
        {roles &&
          roles.map((role, index) => {
            return (
              role.link &&
              role.link.length > 1 &&
              role.component && (
                <Route
                  key={index}
                  path={role.is_parameter ? role.link + ":_id" : role.link}
                  component={components[role.component.toLocaleUpperCase()]}
                  exact
                ></Route>
              )
            );
          })}
      </Switch>
    </div>
  );
};

export default DanhSachNghiepVu;
