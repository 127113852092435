import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "../../../components/layouts";
import { getRoles } from "../../../redux/actions/RoleActions";
import { AppSession } from "../../shared/app-session/app-session";
import {
  HomeClient,
  QLCCVehicleTypeModel,
  QLCCClient,
  RoleRightWebModel,
  SysMenu,
  QLCCApartmentBuildingModel,
  SysActionType,
} from "../../shared/service-proxies/api-shared";
import BASE_URL_API from "../../shared/service-proxies/config";
import { useHistory, useLocation } from "react-router-dom";
import BlockUI from "../../../components/Block-UI/Block-ui";
import VehicleTypeAction from "../actions/vehicle-action/VehicleTypeAction";
import { Form, AutoComplete } from "antd";
import notification from "../../../common/notification/notification";
import DHSBreadcrumb from "../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import DHSDataGridEdit from "../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import DataGrid from "devextreme-react/data-grid";
import { EventInfo } from "devextreme/events";
import dxDataGrid, { SelectionChangedInfo } from "devextreme/ui/data_grid";
import DHSToolbarRole from "../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import { ACTIONS } from "../../../common/enums/DHSToolbarRoleAction";
import {
  STATUS_MESSAGE,
  TYPE_MESSAGE,
} from "../../../common/Interfaces/StatusMessage";
import { getIndexRowSelected, getKeyRowSelected } from "../../../components/DHS/DHS_DataGridEdit/helper-datagrid-edit-get-row";

const convert = ["success", "error", "warning"];
var appSession: AppSession;

interface IAction {
  open: boolean;
  type: string;
  payload: any;
}

const defaultAction: IAction = {
  open: false,
  type: "",
  payload: null,
};

const roleMenu: string = "CC";
const VehicleTypeList: React.FC<{}> = () => {
  const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

  const location = useLocation();
  const [loading, setLoading] = React.useState<boolean>(false);
  // const roles = useSelector((state: IReducer) => state.RoleReducer?.listRole);
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const [reload, setReload] = React.useState<boolean>(false);
  const [dataTable, setDataTable] = React.useState<QLCCVehicleTypeModel[]>([]);
  const homeClient = new HomeClient(appSession, BASE_URL_API);
  const qLCClient = new QLCCClient(appSession, BASE_URL_API);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");

  const [actCx, setActCx] = useState<IAction>(defaultAction);

  //

  const [apartmentBuildingOptions, setApartmentBuildingOptions] =
    React.useState<any[]>([]);
  const [form] = Form.useForm();

  //

  const dataGridRef = React.useRef<DataGrid>(null);
  const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>();
  const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>(-1);
  //

  const [apartmentBuildingSelected, setApartmentBuildingSelected] =
    React.useState<any>();

 
  //

  // useEffect(() => {
  //   setLoading(true);
  //   qLCClient
  //     .qlccApartmentBuildingGet({
  //       ...new QLCCApartmentBuildingModel(),
  //       ma_cty: SessionLogin.ma_cty,
  //       action_type: "GET",
  //     } as QLCCApartmentBuildingModel)
  //     .then((response) => {
  //       let tempData: any = [];
  //       tempData = response.map((apartment_building) => {
  //         return {
  //           key: apartment_building.id,
  //           value: apartment_building.name,
  //         };
  //       });
  //       setApartmentBuildingOptions(tempData);
  //       setApartmentBuildingSelected(tempData[0]);
  //       setLoading(false);
  //     });
  // }, []);

  React.useEffect(() => {
    setLoading(true);
    qLCClient
      .qLCCVehicleTypeGet({
        ...new QLCCVehicleTypeModel(),
        ma_cty: SessionLogin?.ma_cty,
        apartment_building_id: apartment_building_id,
      } as QLCCVehicleTypeModel)
      .then((res) => {
        setLoading(false);
        setDataTable(res);
      });
  }, [reload, apartment_building_id]);


  const handleClickAction = async (action: SysActionType, nowTime: Date) => {
    if (!apartment_building_id) {
      notification(convert[1], "Vui lòng chọn dự án!");
    } else {
      switch (action?.action_code?.toLocaleUpperCase()) {
        case ACTIONS.DELETE:
          if (dataGridRef.current) {
            try {
              const key = await getKeyRowSelected(dataGridRef);
              const index  = await getIndexRowSelected(dataGridRef);
              if (dataGridRef.current && key && index >= 0) {
                if (window.confirm("Bạn có chắc chắn muốn xóa?")) {
                  setLoading(true);
                  qLCClient
                    .qLCCVehicleTypeAction({
                      ...new QLCCVehicleTypeModel(),
                      id: key ?? null,
                      action_type: action.action_code.toUpperCase(),
                    } as QLCCVehicleTypeModel)
                    .then((res) => {
                      setLoading(false);
                      notification(convert[Number(res.status!)], res.message);
                    //   setReload(!reload);
                        if (res.status === 0) {
                            const tempData = [...dataTable];
                            const index = tempData.findIndex(x => x.id === key);
                            tempData.splice(index, 1);
                            setDataTable(tempData)
                        }
                    
                    });
                }
              } else {
                notification(
                  TYPE_MESSAGE.WARNING,
                  STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                );
              }
            } catch (error) {
              notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
              );
            }
          }

          break;
        case ACTIONS.ADD:
          setActCx({
            ...actCx,
            open: true,
            type: action?.action_code?.toUpperCase() ?? "",
            payload: {
              vehicle_type_id: selectedItemKeys?.id ?? null,
              apartment_building_id: apartment_building_id,
            },
          });
          break;
        case ACTIONS.EDIT:
        case ACTIONS.VIEW:
          if (dataGridRef.current) {
            try {
              const key = await getKeyRowSelected(dataGridRef);
              const index  = await getIndexRowSelected(dataGridRef);
              if (dataGridRef.current && key && index >= 0) {
                setActCx({
                  ...actCx,
                  open: true,
                  type: action?.action_code?.toUpperCase() ?? "",
                  payload: {
                    vehicle_type_id: key ?? null,
                    apartment_building_id: apartment_building_id,
                  },
                });
              } else {
                notification(
                  TYPE_MESSAGE.WARNING,
                  STATUS_MESSAGE[`WARNING_SELECT_ROW`]
                );
              }
            } catch (error) {
              notification(
                TYPE_MESSAGE.WARNING,
                STATUS_MESSAGE[`WARNING_SELECT_ROW`]
              );
            }
          }

          break;

        default:
          break;
      }
    }
  };

  const onFinish = React.useCallback(async (values: QLCCVehicleTypeModel) => {
    //
    // const body = Object.assign({}, data,);
  }, []);

  const onFinishFailed = (errorInfo: any) => {
    // api["error"]({
    //     message: "Đăng ký không thành công",
    //     description: "Vui lòng xem lại các ô nhập!"
    // });
  };

  const onApartmentBuildingSelect = (data: string, option: any) => {
    // setApartmentBuildingSelected({
    //     key: option.key,
    //     value: option.value
    // });
  };

  const onApartmentBuildingChange = (data: any, option: any) => {
    setApartmentBuildingSelected({
      key: option.key,
      value: option.value,
    });
  };

  const handleSelectionChanged = (
    e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
  ) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
        console.log(keys);

        const index = e.component.getRowIndexByKey(keys[0]);

        setSelectedRowIndex(index >= 0 ? index : -1);
        setSelectedItemKeys(keys[0]);
      });
    }
  };

  const handlePageChange = () => {
    setSelectedRowIndex(-1);
    setSelectedItemKeys(undefined);
  };
  return (
    <div className="container-fluid">
      {actCx.open && (
        <VehicleTypeAction
          open={actCx.open}
          onClose={function (): void {
            setActCx(defaultAction);
          }}
          action={actCx.type}
          itemSelected={actCx.payload}
          onEditSuccess={function (data_response: SysMenu): void {
            setReload((pre) => !pre);
          }}
        ></VehicleTypeAction>
      )}

      <div className="row no-gutters my-1 align-items-center ">
        {/* Go back */}

        <div
          className="d-flex col-md-12 col-xl-9 title-color"
          style={{
            height: 30,
            color: "rgb(0 78 255 / 88%)",
            marginTop: 15,
            marginBottom: 15,
            fontSize: 22,
            fontWeight: 550,
          }}
        >
          <DHSBreadcrumb location={location} />
        </div>

        {/* <div
          className="col-md-12 col-xl-3 pr-1 "
          style={{ marginLeft: "auto" }}
        >
          <Form
            name="basic"
            form={form}
            layout="horizontal"
            labelAlign="left"
            // initialValues={{ requiredMarkValue: requiredMark }}
            // onValuesChange={onRequiredTypeChange}

            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            // requiredMark={requiredMark}
            // labelCol={{ span: 8 }}
          >
            <Form.Item
              label={
                <span style={{ fontWeight: 600, fontSize: 14 }}>
                  Chọn dự án
                </span>
              }
              name="apartment_building_id"
              hasFeedback
              // required
              // tooltip="Thông tin mã khách hàng là bắt buộc"
              rules={[
                { required: true, message: "Dự án là bắt buộc!" },
                // {
                //     validator(rule, value, callback) {

                //         if (apartmentBuildingOptions.find((item: QLCCApartmentBuildingModel) => item.id === value)) {
                //             callback();
                //         }
                //         else {
                //             callback("Giá trị hiện tại không phải Dự án")
                //         }

                //     },
                // }
              ]}
            >
              {apartmentBuildingSelected && (
                <AutoComplete
                  size="middle"
                  defaultValue={apartmentBuildingSelected ?? null}
                  value={
                    apartmentBuildingSelected
                      ? apartmentBuildingOptions.find((item) => {
                          return item.key === apartment_building_id;
                        })
                      : null
                  }
                  options={apartmentBuildingOptions}
                  onSelect={onApartmentBuildingSelect}
                  // onSearch={ApartmentSearchInput}
                  onChange={onApartmentBuildingChange}
                  placeholder="Chọn dự án"
                />
              )}
            </Form.Item>
          </Form>
        </div> */}
      </div>

      <DHSToolbarRole
        id={" "}
        menu={menu}
        customMenu={[]}
        onClickAction={handleClickAction}
      />

      <DHSDataGridEdit
        data={dataTable}
        table_name={menu?.component}
        keyExpr="id"
        mode="popup"
        dataGridRef={dataGridRef}
        allowEdit={false}
        pageSize={menu?.page_size}
        isPagination={menu?.is_pagination}
        isMultiple={menu?.is_multiple}
        groupingAutoExpandAll={false}
        handleSelectionChanged={handleSelectionChanged}
        handlePageChange={handlePageChange}

        // handleExporting={handleExporting}
      />

      <BlockUI blocking={loading} title={""}></BlockUI>
    </div>
  );
};

export default VehicleTypeList;
