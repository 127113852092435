import React, { useEffect, useRef, useState } from "react";

import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Grid, InputLabel, TextField } from "@mui/material";
import { EventInfo } from "devextreme/events";
import dxDataGrid, { SelectionChangedInfo } from "devextreme/ui/data_grid";
import DataGrid from "devextreme-react/data-grid";

import { IReducer, Notification } from "../../../../components/layouts";
import {
  ReportInfo,
  SYS_Token_AuthenticationModel,
  SysActionType,
} from "../../../shared/service-proxies/api-shared";
import { httpPost } from "../../../../common/httpService";
import { ACTIONS } from "../../../../common/enums/DHSToolbarRoleAction";
import { exportExcelReport } from "../../../../components/DHS/DHS_ExportExcelReport/exportExcelReport";
import DHSToolbarRole from "../../../../components/DHS/DHS-Toolbar-Role/DHSToolbarRole";
import DHSPopupReport from "../../../../components/DHS/DHS_Popup_Report/DHSPopupReport";
import DHSDataGridEdit from "../../../../components/DHS/DHS_DataGridEdit/DataGridEdit";
import BlockUI from "../../../../components/Block-UI/Block-ui";
import DHSBreadcrumb from "../../../../components/DHS/DHS_Breadcrumb/DHSBreadcrumb";
import { TransDateTime } from "../../../../components/utils/date.util";
import DateBox from "devextreme-react/date-box";

type Props = {};

const ImportExportSummaryReport: React.FC<Props> = (props: Props) => {
  const SessionLogin: SYS_Token_AuthenticationModel = JSON.parse(
    localStorage.getItem("SessionLogin") || "{}"
  );

  const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)
  const menu = useSelector((state: IReducer) => state.RoleReducer?.menu);
  const location = useLocation();

  const dataGridRef = useRef<DataGrid>(null);
  const [data, setData] = useState<any[]>([]);

  const [fromDate, setFromDate] = React.useState<Date>(
    new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1, 0, 0, 0))
  );
  const [toDate, setToDate] = React.useState<Date>(
    new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 0, 23, 59, 59))
  );

  const [reportInfo, setReportInfo] = React.useState<ReportInfo>();
  const [selectedItemKeys, setSelectedItemKeys] = useState<any>();
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        if (reportInfo?.spname) {
          setLoading(true);
          const res = await httpPost<string>("api/caReport/CAReport", {
            ma_cty: SessionLogin.ma_cty,
            tk: "",
            ma_nt: "vnd",
            ngay_ct1: TransDateTime(fromDate),
            ngay_ct2: TransDateTime(toDate),
            apartment_building_id: apartment_building_id,
            spname: reportInfo?.spname || "",
          });
          const dataAfterParse = JSON.parse(res.data);
          setData([...dataAfterParse.Table]);
        }
      } catch (error) {
        setData([]);
      } finally {
        setLoading(false);
      }
    })();
  }, [reload, reportInfo?.spname, apartment_building_id]);

  const refreshData = async () => {
    setReload(!reload);
  };

  const handleSelectionChanged = (
    e: EventInfo<dxDataGrid<any, any>> & SelectionChangedInfo<any, any>
  ) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.getSelectedRowKeys().then((keys) => {
        console.log(keys);

        const index = e.component.getRowIndexByKey(keys[0]);

        setSelectedRowIndex(index >= 0 ? index : -1);
        setSelectedItemKeys(keys[0]);
      });
    }
  };

  const handlePageChange = () => {
    setSelectedRowIndex(-1);
    setSelectedItemKeys(undefined);
  };

  const handleClickAction = (action: SysActionType, nowTime: Date) => {
    switch (action?.action_code?.toLocaleUpperCase()) {
      case ACTIONS.SEARCH:
        setIsOpen(true);
        break;
      case ACTIONS.EXPORTEXCEL:
        console.log(dataGridRef.current);

        if (dataGridRef.current && reportInfo) {
          if (reportInfo) {
            exportExcelReport(dataGridRef, reportInfo, fromDate, toDate);
          } else {
            Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
          }
          //   exportExcelReport(dataGridRef, reportInfo, fromDate, toDate);
        }
        break;
      case ACTIONS.PRINT:
        break;

      default:
        break;
    }
  };

  const handleExporting = (e: any) => {
    if (reportInfo) {
      try {
        exportExcelReport(dataGridRef, reportInfo, fromDate, toDate);
      } catch (error) {
        Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
      }
    } else {
      Notification("warning", "Vui lòng chọn mẫu báo cáo", "");
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <div className='row no-gutters my-1 align-items-center'>
        <div
          className="col-auto d-flex title-color"
          style={{
            height: 30,
            color: "rgb(0 78 255 / 88%)",
            marginTop: 15,
            marginBottom: 15,
            fontSize: 22,
            fontWeight: 550,
            marginRight: "auto"
          }}
        >
          <DHSBreadcrumb
            location={location}
          />
        </div>

        <div className="col-md-12 col-xl-3 pr-2">
        <Grid container alignItems="center" spacing={1} sx={{ margin: "7px 0px" }}>
            <Grid item xs={12} md={2} xl={"auto"}>
              <InputLabel

                sx={{ fontSize: 14, color: "#333", fontWeight: 600 }}
              >
                Mẫu
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={10}>
          <TextField
            className="cc-input"
            sx={{
              width: "100%",
              marginRight: "5px",
            }}
            autoFocus
            // placeholder="Tiêu đề ..."
            title={reportInfo?.ten_mau ?? ""}
            name="ten_mau"
            value={reportInfo?.ten_mau ?? null}
            inputProps={{
              readOnly: true
            }}

          />
          </Grid>
          </Grid>
        </div>

        <div className="col-md-12 col-xl-2 pr-2" >
          {/*Sử dụng từ */}
          <Grid container alignItems="center" spacing={1} sx={{ margin: "7px 0px" }}>
            <Grid item xs={12} md={2} xl={"auto"}>
              <InputLabel

                sx={{ fontSize: 14, color: "#333", fontWeight: 600 }}
              >
                Từ
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={10}>
              <DateBox applyValueMode="useButtons"
                defaultValue={fromDate ?? undefined}
                value={fromDate ?? undefined}
                type="date"
                displayFormat={"dd/MM/yyyy"}
                // min={this.min}
                // max={this.now}
                inputAttr={{ 'aria-label': 'Date', }}


                readOnly={true} />
            </Grid>
          </Grid>

        </div>
        <div className="col-md-12 col-xl-2 pr-1">
          {/*Sử dụng đến */}
          <Grid container alignItems="center" spacing={1} sx={{ margin: "7px 0px" }}>
            <Grid item xs={12} md={2} xl={"auto"}>
              <InputLabel

                sx={{ fontSize: 14, color: "#333", fontWeight: 600}}
              >
                Đến
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={10}>
              <DateBox
                applyValueMode="useButtons"
                defaultValue={toDate ?? undefined}
                value={toDate ?? undefined}
                type="date"
                displayFormat={"dd/MM/yyyy"}

                // min={this.min}
                // max={this.now}
                pickerType="calendar"
                inputAttr={{ 'aria-label': 'Date', }}

                readOnly={true}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <DHSToolbarRole
        id={selectedItemKeys ? selectedItemKeys?.id : undefined}
        menu={menu}
        onClickAction={handleClickAction}
      />
      <DHSDataGridEdit
        data={data}
        table_name={menu?.component}
        // key="so_ct"
        mode="popup"
        dataGridRef={dataGridRef}
        allowEdit={false}
        pageSize={menu?.page_size}
        isPagination={menu?.is_pagination}
        isMultiple={menu?.is_multiple}
        groupingAutoExpandAll={false}
        handlePageChange={handlePageChange}
        handleSelectionChanged={handleSelectionChanged}
        handleExporting={handleExporting}
      />

      <DHSPopupReport
        setReportInfo={setReportInfo}
        reportInfo={reportInfo}
        refreshData={refreshData}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      <BlockUI blocking={loading} title={""}></BlockUI>
    </Box>
  );
};

export default ImportExportSummaryReport;