import React, { useEffect, useState } from 'react';
import { AppSession } from '../../../shared/app-session/app-session';
import { QLCCClient, QLCCServiceModel } from '../../../shared/service-proxies/api-shared';
import BASE_URL_API from '../../../shared/service-proxies/config';
import BlockUI from '../../../../components/Block-UI/Block-ui';
import { Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';
import { ToVietNamCurrency } from '../../../../components/utils/number.util';
import { useSelector } from 'react-redux';
import { IReducer } from '../../../../common/Interfaces/Interfaces';


var appSession: AppSession;

interface IAction {
    open: boolean,
    type: string,
    payload: any

}

const defaultAction: IAction = {
    open: false,
    type: "",
    payload: null
}

// styled

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.common.white,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

// 

interface IProps {
    service_id?: string,
    apartment_id?: string,
    ma_cty?: string,
    apartment_building_id?: string,
   
    isUpdateServiceApartment?: boolean,
}

// 


const ApartmentServiceList: React.FC<IProps> = (props) => {

    const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin") || "{}");
    const apartment_building_id = useSelector((state: IReducer) => state.ApartmentBuildingReducer.apartment_building_id)

    const [loading, setLoading] = React.useState<boolean>(false);
    const [idItemSelected, setIdItemSelected] = React.useState<string>("");
    // const roles = useSelector((state: IReducer) => state.RoleReducer?.ApartmentServicelistRole);

    const [reload, setReload] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<any[]>([]);

    const qLCCClient = new QLCCClient(appSession, BASE_URL_API);

    const [actCx, setActCx] = useState<IAction>(defaultAction);
    const [serviceTotal, setServiceTotal] = useState<number>(0);


    useEffect(() => {
        
           if(props.apartment_building_id){
            
            setLoading(true)
            // Duyệt qua từng service_id
            qLCCClient.qlccServicesGet({
                ...new QLCCServiceModel(),
                id: props.service_id,
                apartment_id: props.apartment_id,
                ma_cty: props?.ma_cty,
                apartment_building_id: props.apartment_building_id,
                action_type: 'APARTMENT'
            } as QLCCServiceModel)
                .then(res => {
                    var tempServiceTotal = 0;

                    res.forEach(service => {
                        tempServiceTotal += service.service_prices!;
                    

                    })
                    setLoading(false)
                    setDataTable(res);
                    setServiceTotal(tempServiceTotal);
                    
                })
           }
           


            
        
    }, [props.service_id, props.apartment_building_id])

   
    return (


        <div className='container-fluid p-0'>

            <TableContainer component={Paper} sx={{ padding: '1rem 0' }}>
                <Table sx={{ maxWidth: '35%' }} aria-label="customized table">
                    <TableHead >
                        <TableRow>
                            <StyledTableCell style={{ backgroundColor: "#2590c3", color: "#fff", padding: "7px 16px"}}>Tên dịch vụ</StyledTableCell>
                            <StyledTableCell align="right" style={{ backgroundColor: "#2590c3", color: "#fff", padding: "7px 16px"}}>Đơn giá</StyledTableCell>
                            {/* <StyledTableCell align="right">Hình thức thanh toán</StyledTableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataTable.length > 0 && dataTable.map((row) => (
                            <StyledTableRow key={row.service_name}>
                                <StyledTableCell component="th" scope="row" sx={{ padding: "7px 16px"}}>
                                    {row.service_name}
                                </StyledTableCell>
                                <StyledTableCell align="right" sx={{ padding: "7px 16px"}}>{ToVietNamCurrency(row.service_prices)}</StyledTableCell>
                                {/* <StyledTableCell align="right">{row.payment_type}</StyledTableCell> */}
                            </StyledTableRow>
                        ))}
                        <StyledTableRow sx={{ backgroundColor: "#fff !important" }}>
                            <StyledTableCell component="th" scope="row" sx={{ color: "#333", fontWeight: 700, }}>
                                {"Tổng"}
                            </StyledTableCell>
                            <StyledTableCell align="right"sx={{ color: "#333", fontWeight: 700 }}>{ToVietNamCurrency(serviceTotal)}</StyledTableCell>
                            {/* <StyledTableCell align="right" sx={{ color: "#fff" }}></StyledTableCell> */}
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>



            <BlockUI blocking={loading} title={""}></BlockUI>
        </div>
    )
}

export default ApartmentServiceList;


